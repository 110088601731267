:root {
  --pf-v5-global--FontSize--md: 14px;
  --pf-v5-global--FontSize--sm: 13px;
}

.ocs-log-snippet {
  border-left: 2px solid #c9190b;
  display: flex;
  flex-direction: column;
  margin-left: 6px;
  padding: 10px 0 10px 13px;
}

.ocs-log-snippet__status-message {
  margin-bottom: 10px;
}

.ocs-log-snippet__log-snippet {
  margin: 0;
}


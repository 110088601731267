.co-toggle-play.pf-v5-c-button {
  background-color: var(--pf-v5-global--BackgroundColor--100);
  border: var(--pf-v5-global--BorderWidth--lg) solid var(--pf-v5-global--BorderColor--100);
  border-radius: 50%;
  height: 32px;
  margin-right: 15px;
  padding: 0;
  width: 32px;

  &.co-toggle-play--active {
    border-color: var(
      --pf-v5-global--palette--green-300
    ); // brighter green that works for both light and dark themes
  }
}

// Use this file to override styles from 3rd party dependencies

form.pf-v5-c-form {
  --pf-v5-c-form__helper-text--Color: var(--pf-v5-global--Color--200);
}

// Until Patternfly-React-Extensions is updated: https://github.com/patternfly/patternfly-react/issues/1146
.catalog-tile-pf-title {
  @include co-break-word;
}

.co-catalog-tile {
  .catalog-tile-pf-icon {
    font-size: unset !important;
  }
  .co-catalog--logo {
    height: 100%;
    max-width: 80px;
    min-width: auto;
    object-fit: contain;
    width: 100%;
  }
  .catalog-tile-pf-icon {
    @include catalog-logo-background(var(--pf-v5-global--spacer--sm));
    display: block;
    height: 50px;
    margin-bottom: calc(-1 * var(--pf-v5-global--spacer--sm)); // Cancels bottom padding
    margin-left: calc(-1 * var(--pf-v5-global--spacer--sm)); // Align logo with left edge
    margin-top: calc(-1 * var(--pf-v5-global--spacer--sm)); // Align logo with top edge
    max-width: 100%;
    min-width: 50px; // prevent collapsed state before img loads
    width: fit-content;
    :where(.pf-v5-theme-dark) & {
      margin-left: 0;
      margin-top: 0;
    }
  }
  .pf-v5-c-card__actions {
    gap: 0;
    label {
      margin-bottom: 0;
    }
  }
  .pf-v5-c-card__selectable-actions .pf-v5-c-radio__label:hover {
    --pf-v5-c-card--m-selectable--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  }
}

.odc-catalog-tile {
  // Get rid of weird stretching of catalog grid items
  .pf-v5-c-card__header-main {
    flex: unset;

    // When images are too wide, scale them instead of squishing
    img {
      object-fit: contain;
    }
  }
}

.catalog-item-header-pf-icon {
  @include catalog-logo-background(var(--pf-v5-global--spacer--sm));
}

.co-catalog-page__overlay-header {
  .catalog-item-header-pf-icon {
    max-width: 80px;
    width: auto;
  }
}

// Override to remove accordion left border and prevent overlap https://bugzilla.redhat.com/show_bug.cgi?id=1942193
.co-create-operand__form--toggle-no-border {
  .pf-v5-c-accordion__toggle.pf-m-expanded {
    --pf-v5-c-accordion__toggle--before--BackgroundColor: transparent;
  }
}

.co-installed-operators .pf-v5-c-table tbody > tr > * {
  vertical-align: top; // PF defaults to baseline which doesn't align correctly when Operator logos are within the table
}

.co-toolbar-empty-state .pf-v5-c-toolbar__content {
  --pf-v5-c-toolbar__content--PaddingLeft: 0;
  --pf-v5-c-toolbar__content--PaddingRight: 0;
}

.co-toolbar-no-padding .pf-v5-c-toolbar__content {
  // use negative margins so that pf-v5-c-toolbar__expandable-content extends to the edges at mobile
  margin-left: -$pf-v5-global-gutter;
  margin-right: -$pf-v5-global-gutter;
  --pf-v5-c-toolbar--PaddingTop: 0;
  --pf-v5-c-toolbar--RowGap: var(--pf-v5-global--spacer--md);
}

// fix bug where monaco-aria-container is visible in Firefox but shouldn't be
// bug occurs only if the suggestions overlay has been enabled
.monaco-aria-container {
  top: -999em;
}

// PatternFly overrides

.pf-v5-c-about-modal-box {
  --pf-v5-c-about-modal-box__brand-image--Height: auto !important; // allow logo to be taller

  &__brand {
    align-items: flex-start; // so logos don't stretch vertically
    min-width: 220px;
  }
}

// Webpack will not properly bundle the background-image from PatternFly
@media only screen and (min-width: 576px) {
  .pf-v5-c-about-modal-box {
    background-image: url('../imgs/pfbg_992.jpg') !important;
    background-position: 400px !important;
    background-size: cover !important;
  }
}

.pf-v5-c-about-modal-box__brand {
  background-color: var(--pf-v5-global--palette--black-1000);
}

.pf-v5-c-about-modal-box__brand-image,
.pf-v5-c-brand {
  max-width: 100%;
}

.pf-v5-c-about-modal-box__content {
  background-color: var(--pf-v5-global--palette--black-1000);
  padding: var(--pf-v5-global--spacer--2xl) var(--pf-v5-global--spacer--3xl);
  --pf-v5-c-about-modal-box__content--MarginBottom: 0;
  --pf-v5-c-about-modal-box__content--MarginLeft: 0;
  --pf-v5-c-about-modal-box__content--MarginRight: 0;
  --pf-v5-c-about-modal-box__content--MarginTop: 0;
}

.pf-v5-c-alert--top-margin {
  margin-top: var(--pf-v5-global--spacer--lg);
}

.pf-v5-c-app-launcher__group-title {
  // Reset font to RedHatText so it doesn't appear too bold
  font-family: var(--pf-v5-global--FontFamily--text);
}

.pf-v5-c-button--align-right {
  margin-left: auto !important;
}

.pf-v5-c-button {
  &:hover,
  &:focus {
    .pf-v5-c-button-icon--plain {
      color: var(--pf-v5-c-button--m-plain--hover--Color);
    }
  }
}

.pf-v5-c-button-icon--plain {
  color: var(--pf-v5-c-button--m-plain--Color);
}

.pf-v5-c-button.pf-v5-c-button--no-default-values {
  --pf-v5-c-button--PaddingTop: 0;
  --pf-v5-c-button--PaddingRight: 0;
  --pf-v5-c-button--PaddingBottom: 0;
  --pf-v5-c-button--PaddingLeft: 0;

  border: 0;
  font-size: unset;
  font-weight: unset;
  line-height: unset;
  text-align: unset;
  white-space: normal;
}

.pf-v5-c-button.pf-m-inline {
  text-align: left; // override default .pf-v5-c-button text centering
}

.pf-v5-c-button.pf-m-link--align-left {
  --pf-v5-c-button--PaddingLeft: 0;
}

.pf-v5-c-button.pf-m-link--align-right {
  --pf-v5-c-button--PaddingRight: 0;
}

// Override label in _bootstrap-residual.scss
.pf-v5-c-check__label {
  margin-bottom: 0;
}

@media print {
  .pf-v5-c-data-list__cell {
    padding: 1px !important;
  }
}

//set pf datalist item background to transparent
.pf-v5-c-data-list__item--transparent-bg {
  --pf-v5-c-data-list__item--BackgroundColor: transparent;
}

// PF components that calculate their correct height based on --pf-v5-global--FontSize--md: 1rem
.pf-v5-c-modal-box,
.pf-v5-c-switch {
  --pf-v5-global--FontSize--md: 1rem;
}

// Drawer
.pf-v5-c-drawer__body {
  height: 100%;
}

// Page
.pf-v5-c-page {
  // Positions fullscreen terminal on top of mast header
  &__main.default-overflow {
    z-index: calc(var(--pf-v5-c-page__header--ZIndex) + 50);
  }

  // Default light background for our content
  .co-page-backdrop {
    background-color: var(--pf-v5-global--BackgroundColor--100) !important;
  }

  // Restore the max-height from PageHeader to maintain backwards compatibility
  .pf-v5-c-brand {
    max-height: 60px;
  }

  // Apply to primary content section to prevent yaml editor and topology sections from collapsing
  .pf-v5-page__main-section--flex {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

// specificity targeting form elements to override --pf-v5-global--FontSize--md
.pf-v5-c-page,
.modal-dialog {
  .pf-v5-c-app-launcher,
  .pf-v5-c-button,
  .pf-v5-c-dropdown,
  .pf-v5-c-dropdown__menu-item,
  .pf-v5-c-dropdown__toggle,
  .pf-v5-c-form-control {
    font-size: $font-size-base;
    height: auto;
  }

  .pf-v5-l-stack {
    font-size: $font-size-base;
  }

  .pf-v5-c-form-control.pf-m-success,
  .pf-v5-c-form-control[aria-invalid='true'] {
    --pf-v5-global--FontSize--md: #{$font-size-base};
  }
}

// reset toggles to original size
// overriding .pf-v5-c-button rule above
.pf-v5-c-masthead__content {
  .pf-v5-c-app-launcher__toggle,
  .pf-v5-c-dropdown__toggle,
  .pf-v5-c-menu-toggle,
  .pf-v5-c-notification-badge {
    font-size: $pf-v5-header-icon-fontsize !important;
  }
}

// reset toggle to original size
// overriding .pf-v5-c-button rule above
.pf-v5-c-masthead__toggle .pf-v5-c-button {
  font-size: var(--pf-v5-c-masthead__toggle--c-button--FontSize);
}

:where(:root:not(.pf-v5-theme-dark)) .pf-v5-c-page {
  --pf-v5-c-page__sidebar--BoxShadow: none;
}

.pf-v5-c-page__sidebar {
  @media print {
    display: none !important;
  }

  // Perspective switcher
  &.pf-m-dark .pf-v5-c-dropdown {
    --pf-v5-global--Color--100: var(--pf-v5-global--Color--dark-100);
  }

  .pf-v5-c-nav {
    --pf-v5-c-nav__list-link--PaddingRight: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-nav__list-link--PaddinLeft: var(--pf-v5-global--spacer--md);
    --pf-v5-c-nav__list-link--after--Left: var(--pf-v5-global--spacer--md);
    --pf-v5-c-nav__link--FontSize: #{$co-side-nav-section-font-size};
    --pf-v5-c-nav__subnav__link--FontSize: #{$co-side-nav-font-size};

    @media screen and (min-width: $pf-v5-global--breakpoint--xl) {
      --pf-v5-c-nav__list-link--PaddingRight: var(--pf-v5-global--spacer--md);
      --pf-v5-c-nav__list-link--PaddinLeft: var(--pf-v5-global--spacer--lg);
      --pf-v5-c-nav__list-link--after--Left: var(--pf-v5-global--spacer--lg);
      --pf-v5-c-nav__simple-list--nested__separator--MarginLeft: var(--pf-v5-global--spacer--xl);
      --pf-v5-c-nav__simple-list-link--nested--PaddingLeft: var(--pf-v5-global--spacer--xl);
    }

    .pf-v5-c-nav__link {
      font-size: $co-side-nav-section-font-size;
    }

    .pf-v5-c-nav__subnav {
      .pf-v5-c-nav__link {
        font-size: $co-side-nav-font-size;
      }
    }
  }
}

// Reset our tables to our base font-size
.pf-v5-c-table {
  tr > * {
    --pf-v5-c-table--cell--FontSize: $font-size-base;
  }
}

// Remove when upstream issue is addressed. https://github.com/patternfly/patternfly/issues/4889
.pf-v5-theme-dark .pf-v5-c-card {
  --pf-v5-c-card--BoxShadow: var(--pf-v5-global--BoxShadow--md);
}

.table {
  margin-bottom: 0;
  &--layout-fixed {
    table-layout: fixed;
  }
  td {
    vertical-align: middle !important;
  }
  th {
    font-weight: var(--pf-v5-global--FontWeight--bold);
    padding-top: 0 !important;
  }
  thead > tr > th {
    border-bottom: 0;
  }
  tr:last-child {
    border-bottom: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--300);
  }
}

.xterm .xterm-screen canvas {
  // workaround bug where 1px white stripe appears atop terminal in Firefox for MacOS
  // see https://github.com/xtermjs/xterm.js/issues/1974
  border-right: 1px solid transparent;
}

button.pf-v5-c-dropdown__menu-item.pf-m-disabled {
  // enables tooltips for disabled menu items
  pointer-events: auto;
}

#modal-container .pf-v5-c-backdrop {
  position: absolute !important;
}

.form-group--doubled-bottom-margin {
  margin-bottom: ($form-group-margin-bottom * 2);
}

// Patternfly defaults to globally removing the list element bullets via ul {list-style:none} and selectively applies it using their List component via ul.pf-v5-c-list. We chose to override this because of the amount of ul elements in our codebase that expect the default to be ul {list-style:disc}
ul {
  list-style: disc;
}
// And here we explicitly remove it from PF components, except for the List component.
:where([class^='pf-']:not(.pf-v5-c-list, .pf-c-list)) {
  @at-root :is(ul, ol)#{&} {
    list-style: none !important;
  }
}

.pfext-quick-start-content {
  font-size: $font-size-base !important;
}

.odc-topology {
  .pf-topology-content {
    background-color: var(--pf-v5-global--BackgroundColor--200);
  }
}

.pfext-quick-start-footer {
  background-color: var(--pf-v5-global--BackgroundColor--100);
}

// TODO Remove this override when skip to content CSS is figured out.
.pf-v5-c-skip-to-content {
  position: absolute !important;
}

.properties-side-panel-pf-property-value {
  .pf-v5-c-alert__title,
  .pf-v5-c-menu__item-text,
  .pf-v5-c-menu-toggle {
    font-size: $font-size-base;
  }
}

:where(.pf-theme-dark) .pfext-quick-start-task__content .pfext-markdown-view pre code {
  color: var(--pf-v5-global--Color--100);
  background-color: var(--pf-v5-global--palette--black-600);
}

// PF5 Popover component applies an new inline width:auto style that overrides it's own pf-v5-c-popover min-width: var(--pf-v5-c-popover--MinWidth). So the pf-v5-c-popover container is not a consistent size and can resize when additional content is loaded which causes the alignment to be incorrect.
.pf-v5-c-popover {
  min-width: var(--pf-v5-c-popover--MinWidth) !important;
}

.pf-v5-c-truncate--no-min-width {
  --pf-v5-c-truncate--MinWidth: 0 !important;
}

:root {
  --pf-v5-global--FontSize--md: 14px;
  --pf-v5-global--FontSize--sm: 13px;
}

.co-dashboard-body {
  background-color: var(--pf-v5-global--BackgroundColor--200);
  flex: 1 0 auto;
  padding: 1rem;
}

@media (min-width: 1200px) {
  .co-dashboard-body {
    padding: 1.5rem;
  }
}

.co-dashboard-grid {
  grid-gap: 1rem;
}


:root {
  --pf-v5-global--FontSize--md: 14px;
  --pf-v5-global--FontSize--sm: 13px;
}

.osc-yaml-editor {
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
  margin-right: -1rem;
}

@media (max-width: 768px) {
  .osc-yaml-editor {
    margin-left: -1rem;
    margin-top: -1.5rem;
  }
}

@media (min-width: 1200px) {
  .osc-yaml-editor {
    margin-right: -1.5rem;
  }
}

.osc-yaml-editor__editor {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
}

@media (min-width: 768px) {
  .osc-yaml-editor__editor {
    margin-right: 1rem;
  }
}

@media (min-width: 1200px) {
  .osc-yaml-editor__editor {
    margin-right: 1.5rem;
  }
}

.osc-yaml-editor__sidebar {
  display: flex;
  width: 34%;
  margin-top: -1.5rem;
}

@media screen and (max-width: 992px) {
  .osc-yaml-editor__sidebar {
    display: none;
  }
}


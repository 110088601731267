.ocs-drawer {
  position: relative;
  overflow: hidden;
  flex-grow: 0;
  flex-shrink: 0;
  transition: opacity 175ms ease-out, transform 225ms ease-out;
  box-shadow: var(--pf-v5-global--BoxShadow--sm-top);
  display: flex;
  flex-direction: column;

  &__drag-handle {
    width: 100%;
    height: 6px;
    cursor: ns-resize;
    position: absolute;
    background-color: transparent;
    &:hover {
      background-color: var(--pf-v5-global--palette--black-300);
    }
  }

  &__header {
    height: var(--pf-v5-global--target-size--MinHeight);
    background-color: var(--pf-v5-global--BackgroundColor--200);
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  &__body {
    position: relative;
    flex-grow: 1;
    overflow: auto;
    height: 100%;
  }

  &-appear {
    opacity: 0;
    transform: translatey(10%);
  }
  &-appear-active {
    opacity: 1;
    transform: translatey(0);
  }
  &-exit {
    opacity: 1;
    transform: translatey(0);
  }
  &-exit-active {
    opacity: 0;
    transform: translatey(10%);
  }
}

.pf-v5-c-table,
.pf-v5-c-select,
.pf-v5-c-page__sidebar.pf-m-light,
.pf-v5-c-menu,
.pf-v5-c-masthead.pf-m-light-200,
.pf-v5-c-masthead.pf-m-light,
.pf-v5-c-form-control,
.pf-v5-c-data-list,
.pf-v5-c-context-selector__menu,
.pf-v5-c-chip-group,
.pf-v5-c-chip,
.pf-v5-c-calendar-month,
.pf-v5-c-banner.pf-m-gold,
.pf-v5-c-banner.pf-m-blue,
.pf-v5-c-alert,
.pf-v5-c-accordion,
.pf-v5-t-light {
  --pf-v5-global--Color--100: var(--pf-v5-global--Color--dark-100);
  --pf-v5-global--Color--200: var(--pf-v5-global--Color--dark-200);
  --pf-v5-global--BorderColor--100: var(--pf-v5-global--BorderColor--dark-100);
  --pf-v5-global--primary-color--100: var(--pf-v5-global--primary-color--dark-100);
  --pf-v5-global--link--Color: var(--pf-v5-global--link--Color--dark);
  --pf-v5-global--link--Color--hover: var(--pf-v5-global--link--Color--dark--hover);
  --pf-v5-global--BackgroundColor--100: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-global--icon--Color--light: var(--pf-v5-global--icon--Color--light--dark);
  --pf-v5-global--icon--Color--dark: var(--pf-v5-global--icon--Color--dark--dark);
}

.pf-v5-c-wizard__header,
.pf-v5-c-page__main-section[class*=pf-m-dark-],
.pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector,
.pf-v5-c-page__sidebar,
.pf-v5-c-page__header,
.pf-v5-c-masthead .pf-v5-c-button.pf-m-plain,
.pf-v5-c-masthead,
.pf-v5-c-login__footer,
.pf-v5-c-login__header,
.pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main,
.pf-v5-c-banner,
.pf-v5-c-about-modal-box,
.pf-v5-t-dark {
  --pf-v5-global--Color--100: var(--pf-v5-global--Color--light-100);
  --pf-v5-global--Color--200: var(--pf-v5-global--Color--light-200);
  --pf-v5-global--BorderColor--100: var(--pf-v5-global--BorderColor--light-100);
  --pf-v5-global--primary-color--100: var(--pf-v5-global--primary-color--light-100);
  --pf-v5-global--link--Color: var(--pf-v5-global--link--Color--light);
  --pf-v5-global--link--Color--hover: var(--pf-v5-global--link--Color--light);
  --pf-v5-global--BackgroundColor--100: var(--pf-v5-global--BackgroundColor--dark-100);
  --pf-v5-global--icon--Color--light: var(--pf-v5-global--icon--Color--light--light);
  --pf-v5-global--icon--Color--dark: var(--pf-v5-global--icon--Color--dark--light);
}

.pf-v5-c-wizard__header .pf-v5-c-button,
.pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
.pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
.pf-v5-c-page__sidebar .pf-v5-c-button,
.pf-v5-c-page__header .pf-v5-c-button,
.pf-v5-c-masthead .pf-v5-c-button.pf-m-plain .pf-v5-c-button,
.pf-v5-c-masthead .pf-v5-c-button,
.pf-v5-c-login__footer .pf-v5-c-button,
.pf-v5-c-login__header .pf-v5-c-button,
.pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
.pf-v5-c-banner .pf-v5-c-button,
.pf-v5-c-about-modal-box .pf-v5-c-button,
.pf-v5-t-dark .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--dark-100);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr)>*,
.pf-v5-c-page__header-tools-item,
.pf-v5-c-page__header-tools-group,
.pf-v5-c-menu__group,
.pf-v5-c-menu__list-item,
.pf-v5-c-menu__list,
.pf-v5-c-divider,
.pf-v5-c-toolbar__content-section,
.pf-v5-c-toolbar__content,
.pf-v5-c-toolbar__item,
.pf-v5-c-toolbar__group,
.pf-v5-c-data-list__item-action {
  --pf-v5-hidden-visible--hidden--Display: none;
  --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  display: var(--pf-v5-hidden-visible--Display);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-hidden,
.pf-m-hidden.pf-v5-c-page__header-tools-item,
.pf-m-hidden.pf-v5-c-page__header-tools-group,
.pf-m-hidden.pf-v5-c-menu__group,
.pf-m-hidden.pf-v5-c-menu__list-item,
.pf-m-hidden.pf-v5-c-menu__list,
.pf-m-hidden.pf-v5-c-divider,
.pf-m-hidden.pf-v5-c-toolbar__content-section,
.pf-m-hidden.pf-v5-c-toolbar__content,
.pf-m-hidden.pf-v5-c-toolbar__item,
.pf-m-hidden.pf-v5-c-toolbar__group,
.pf-m-hidden.pf-v5-c-data-list__item-action {
  --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
}

@media screen and (min-width: 576px) {
  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-hidden-on-sm,
  .pf-m-hidden-on-sm.pf-v5-c-page__header-tools-item,
  .pf-m-hidden-on-sm.pf-v5-c-page__header-tools-group,
  .pf-m-hidden-on-sm.pf-v5-c-menu__group,
  .pf-m-hidden-on-sm.pf-v5-c-menu__list-item,
  .pf-m-hidden-on-sm.pf-v5-c-menu__list,
  .pf-m-hidden-on-sm.pf-v5-c-divider,
  .pf-m-hidden-on-sm.pf-v5-c-toolbar__content-section,
  .pf-m-hidden-on-sm.pf-v5-c-toolbar__content,
  .pf-m-hidden-on-sm.pf-v5-c-toolbar__item,
  .pf-m-hidden-on-sm.pf-v5-c-toolbar__group,
  .pf-m-hidden-on-sm.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-visible-on-sm,
  .pf-m-visible-on-sm.pf-v5-c-page__header-tools-item,
  .pf-m-visible-on-sm.pf-v5-c-page__header-tools-group,
  .pf-m-visible-on-sm.pf-v5-c-menu__group,
  .pf-m-visible-on-sm.pf-v5-c-menu__list-item,
  .pf-m-visible-on-sm.pf-v5-c-menu__list,
  .pf-m-visible-on-sm.pf-v5-c-divider,
  .pf-m-visible-on-sm.pf-v5-c-toolbar__content-section,
  .pf-m-visible-on-sm.pf-v5-c-toolbar__content,
  .pf-m-visible-on-sm.pf-v5-c-toolbar__item,
  .pf-m-visible-on-sm.pf-v5-c-toolbar__group,
  .pf-m-visible-on-sm.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-hidden-on-md,
  .pf-m-hidden-on-md.pf-v5-c-page__header-tools-item,
  .pf-m-hidden-on-md.pf-v5-c-page__header-tools-group,
  .pf-m-hidden-on-md.pf-v5-c-menu__group,
  .pf-m-hidden-on-md.pf-v5-c-menu__list-item,
  .pf-m-hidden-on-md.pf-v5-c-menu__list,
  .pf-m-hidden-on-md.pf-v5-c-divider,
  .pf-m-hidden-on-md.pf-v5-c-toolbar__content-section,
  .pf-m-hidden-on-md.pf-v5-c-toolbar__content,
  .pf-m-hidden-on-md.pf-v5-c-toolbar__item,
  .pf-m-hidden-on-md.pf-v5-c-toolbar__group,
  .pf-m-hidden-on-md.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-visible-on-md,
  .pf-m-visible-on-md.pf-v5-c-page__header-tools-item,
  .pf-m-visible-on-md.pf-v5-c-page__header-tools-group,
  .pf-m-visible-on-md.pf-v5-c-menu__group,
  .pf-m-visible-on-md.pf-v5-c-menu__list-item,
  .pf-m-visible-on-md.pf-v5-c-menu__list,
  .pf-m-visible-on-md.pf-v5-c-divider,
  .pf-m-visible-on-md.pf-v5-c-toolbar__content-section,
  .pf-m-visible-on-md.pf-v5-c-toolbar__content,
  .pf-m-visible-on-md.pf-v5-c-toolbar__item,
  .pf-m-visible-on-md.pf-v5-c-toolbar__group,
  .pf-m-visible-on-md.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-hidden-on-lg,
  .pf-m-hidden-on-lg.pf-v5-c-page__header-tools-item,
  .pf-m-hidden-on-lg.pf-v5-c-page__header-tools-group,
  .pf-m-hidden-on-lg.pf-v5-c-menu__group,
  .pf-m-hidden-on-lg.pf-v5-c-menu__list-item,
  .pf-m-hidden-on-lg.pf-v5-c-menu__list,
  .pf-m-hidden-on-lg.pf-v5-c-divider,
  .pf-m-hidden-on-lg.pf-v5-c-toolbar__content-section,
  .pf-m-hidden-on-lg.pf-v5-c-toolbar__content,
  .pf-m-hidden-on-lg.pf-v5-c-toolbar__item,
  .pf-m-hidden-on-lg.pf-v5-c-toolbar__group,
  .pf-m-hidden-on-lg.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-visible-on-lg,
  .pf-m-visible-on-lg.pf-v5-c-page__header-tools-item,
  .pf-m-visible-on-lg.pf-v5-c-page__header-tools-group,
  .pf-m-visible-on-lg.pf-v5-c-menu__group,
  .pf-m-visible-on-lg.pf-v5-c-menu__list-item,
  .pf-m-visible-on-lg.pf-v5-c-menu__list,
  .pf-m-visible-on-lg.pf-v5-c-divider,
  .pf-m-visible-on-lg.pf-v5-c-toolbar__content-section,
  .pf-m-visible-on-lg.pf-v5-c-toolbar__content,
  .pf-m-visible-on-lg.pf-v5-c-toolbar__item,
  .pf-m-visible-on-lg.pf-v5-c-toolbar__group,
  .pf-m-visible-on-lg.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-hidden-on-xl,
  .pf-m-hidden-on-xl.pf-v5-c-page__header-tools-item,
  .pf-m-hidden-on-xl.pf-v5-c-page__header-tools-group,
  .pf-m-hidden-on-xl.pf-v5-c-menu__group,
  .pf-m-hidden-on-xl.pf-v5-c-menu__list-item,
  .pf-m-hidden-on-xl.pf-v5-c-menu__list,
  .pf-m-hidden-on-xl.pf-v5-c-divider,
  .pf-m-hidden-on-xl.pf-v5-c-toolbar__content-section,
  .pf-m-hidden-on-xl.pf-v5-c-toolbar__content,
  .pf-m-hidden-on-xl.pf-v5-c-toolbar__item,
  .pf-m-hidden-on-xl.pf-v5-c-toolbar__group,
  .pf-m-hidden-on-xl.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-visible-on-xl,
  .pf-m-visible-on-xl.pf-v5-c-page__header-tools-item,
  .pf-m-visible-on-xl.pf-v5-c-page__header-tools-group,
  .pf-m-visible-on-xl.pf-v5-c-menu__group,
  .pf-m-visible-on-xl.pf-v5-c-menu__list-item,
  .pf-m-visible-on-xl.pf-v5-c-menu__list,
  .pf-m-visible-on-xl.pf-v5-c-divider,
  .pf-m-visible-on-xl.pf-v5-c-toolbar__content-section,
  .pf-m-visible-on-xl.pf-v5-c-toolbar__content,
  .pf-m-visible-on-xl.pf-v5-c-toolbar__item,
  .pf-m-visible-on-xl.pf-v5-c-toolbar__group,
  .pf-m-visible-on-xl.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-hidden-on-2xl,
  .pf-m-hidden-on-2xl.pf-v5-c-page__header-tools-item,
  .pf-m-hidden-on-2xl.pf-v5-c-page__header-tools-group,
  .pf-m-hidden-on-2xl.pf-v5-c-menu__group,
  .pf-m-hidden-on-2xl.pf-v5-c-menu__list-item,
  .pf-m-hidden-on-2xl.pf-v5-c-menu__list,
  .pf-m-hidden-on-2xl.pf-v5-c-divider,
  .pf-m-hidden-on-2xl.pf-v5-c-toolbar__content-section,
  .pf-m-hidden-on-2xl.pf-v5-c-toolbar__content,
  .pf-m-hidden-on-2xl.pf-v5-c-toolbar__item,
  .pf-m-hidden-on-2xl.pf-v5-c-toolbar__group,
  .pf-m-hidden-on-2xl.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-visible-on-2xl,
  .pf-m-visible-on-2xl.pf-v5-c-page__header-tools-item,
  .pf-m-visible-on-2xl.pf-v5-c-page__header-tools-group,
  .pf-m-visible-on-2xl.pf-v5-c-menu__group,
  .pf-m-visible-on-2xl.pf-v5-c-menu__list-item,
  .pf-m-visible-on-2xl.pf-v5-c-menu__list,
  .pf-m-visible-on-2xl.pf-v5-c-divider,
  .pf-m-visible-on-2xl.pf-v5-c-toolbar__content-section,
  .pf-m-visible-on-2xl.pf-v5-c-toolbar__content,
  .pf-m-visible-on-2xl.pf-v5-c-toolbar__item,
  .pf-m-visible-on-2xl.pf-v5-c-toolbar__group,
  .pf-m-visible-on-2xl.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

.pf-v5-c-number-input .pf-v5-c-form-control>:is(input),
.pf-v5-c-pagination__nav-page-select .pf-v5-c-form-control>:is(input) {
  appearance: textfield;
}

.pf-v5-c-number-input .pf-v5-c-form-control>:is(input)::-webkit-inner-spin-button,
.pf-v5-c-pagination__nav-page-select .pf-v5-c-form-control>:is(input)::-webkit-inner-spin-button,
.pf-v5-c-number-input .pf-v5-c-form-control>:is(input)::-webkit-outer-spin-button,
.pf-v5-c-pagination__nav-page-select .pf-v5-c-form-control>:is(input)::-webkit-outer-spin-button {
  margin: 0;
  appearance: none;
}

:root {
  --pf-v5-global--FontSize--md: 14px;
  --pf-v5-global--FontSize--sm: 13px;
}

:root {
  --vertical-tab-pf-color: var(--pf-v5-global--Color--100);
  --vertical-tab-pf-active-color: var(--pf-v5-global--active-color--100);
}

.catalog-item-header-pf {
  display: flex;
  align-items: center;
}

.catalog-item-header-pf-icon {
  font-size: 60px;
  max-height: 60px;
  width: 60px;
}

.catalog-item-header-pf-text {
  margin-left: 20px;
}

.catalog-item-header-pf-title {
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 0;
}

.catalog-item-header-pf-subtitle {
  color: var(--pf-v5-global--Color--200);
  margin-bottom: 0;
}

.catalog-tile-pf.featured {
  border-top: 2px solid var(--pf-v5-global--active-color--100);
}

.catalog-tile-pf:active,
.catalog-tile-pf:hover,
.catalog-tile-pf:focus,
.catalog-tile-pf:visited {
  color: inherit;
  text-decoration: none;
}

.catalog-tile-pf .pf-v5-c-card__actions {
  padding-left: 5px;
}

.catalog-tile-pf-header {
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 16px;
}

.catalog-tile-pf-header .catalog-tile-pf-title {
  font-size: 15px;
  font-weight: 400;
}

.catalog-tile-pf-header .catalog-tile-pf-subtitle {
  color: var(--pf-v5-global--Color--200);
  font-size: 13px;
  font-weight: initial;
}

.catalog-tile-pf-header .catalog-tile-pf-subtitle a,
.catalog-tile-pf-header .catalog-tile-pf-subtitle a:hover {
  text-decoration: none;
}

.catalog-tile-pf-icon {
  font-size: 40px;
  height: 40px;
  max-width: 60px;
  min-width: 40px;
}

.catalog-tile-pf-badge-container {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.catalog-tile-pf-badge-container .catalog-tile-pf-badge {
  font-size: 16px;
  margin-left: 5px;
}

.catalog-tile-pf-description {
  margin-top: 0;
}

.catalog-tile-pf-description span {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.catalog-tile-pf-description .has-footer {
  -webkit-line-clamp: 1;
}

.filter-panel-pf {
  margin: 0 0 30px;
  padding: 0 15px;
}

.filter-panel-pf-category {
  margin-top: 20px;
}

.filter-panel-pf-category:first-of-type {
  margin-top: 0;
}

.filter-panel-pf-category-title {
  border: 0;
  font-size: inherit;
  margin: 0 0 8px 0;
  font-weight: 700;
}

.filter-panel-pf-category-items {
  margin-top: 0;
  margin-bottom: 0;
}

.filter-panel-pf-category-item {
  margin-top: 5px;
}

.filter-panel-pf-category-item:first-of-type {
  margin-top: 0;
}

.filter-panel-pf-category-item .pf-v5-c-check__label {
  min-height: 23px;
}

.filter-panel-pf-category-item .item-icon {
  padding-right: 3px;
}

.filter-panel-pf-category-item .item-count {
  padding-left: 3px;
}

.properties-side-panel-pf {
  width: 165px;
}

.properties-side-panel-pf-property {
  margin-top: 24px;
}

.properties-side-panel-pf-property:first-of-type {
  margin-top: 0;
}

.properties-side-panel-pf-property-label {
  font-weight: 700 !important;
  font-size: 14px !important;
  margin: 0 !important;
}

.properties-side-panel-pf-property-value {
  font-size: 14px !important;
  margin-top: 8px;
  word-break: break-word;
}

.vertical-tabs-pf {
  list-style: none;
  margin: 0 0 30px;
  padding: 0;
}

.vertical-tabs-pf .vertical-tabs-pf {
  margin-bottom: 0;
}

.vertical-tabs-pf-tab {
  margin-top: 4px;
  position: relative;
}

.vertical-tabs-pf-tab>a {
  color: var(--vertical-tab-pf-color);
  display: inline-block;
  font-size: 13px;
  padding: 3px 6px 3px 15px;
  vertical-align: top;
  width: 100%;
  word-break: break-word;
}

.vertical-tabs-pf-tab>a:hover,
.vertical-tabs-pf-tab>a:focus {
  color: var(--vertical-tab-pf-active-color);
  text-decoration: none;
}

.vertical-tabs-pf-tab>a.no-wrap {
  overflow-x: hidden;
  white-space: nowrap;
}

.vertical-tabs-pf-tab>a.truncate {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vertical-tabs-pf-tab.active>a {
  color: var(--vertical-tab-pf-active-color);
}

.vertical-tabs-pf-tab.active>a::before {
  background: var(--vertical-tab-pf-active-color);
  content: " ";
  left: 0;
  position: absolute;
  width: 3px;
}

.vertical-tabs-pf-tab:first-of-type {
  margin-top: 0;
}

.vertical-tabs-pf-tab>.vertical-tabs-pf>.vertical-tabs-pf-tab {
  position: initial;
  padding-left: 15px;
}

.vertical-tabs-pf.restrict-tabs .vertical-tabs-pf-tab {
  display: none;
}

.vertical-tabs-pf.restrict-tabs .vertical-tabs-pf-tab.active,
.vertical-tabs-pf.restrict-tabs .vertical-tabs-pf-tab.active-descendant,
.vertical-tabs-pf.restrict-tabs .vertical-tabs-pf-tab.shown {
  display: block;
}

.vertical-tabs-pf.restrict-tabs.active-tab>.vertical-tabs-pf-tab {
  display: block;
}

.vertical-tabs-pf.restrict-tabs .vertical-tabs-pf-tab.active>.vertical-tabs-pf>.vertical-tabs-pf-tab {
  display: block;
}

.pf-v5-c-table,
.pf-v5-c-select,
.pf-v5-c-page__sidebar.pf-m-light,
.pf-v5-c-menu,
.pf-v5-c-masthead.pf-m-light-200,
.pf-v5-c-masthead.pf-m-light,
.pf-v5-c-form-control,
.pf-v5-c-data-list,
.pf-v5-c-context-selector__menu,
.pf-v5-c-chip-group,
.pf-v5-c-chip,
.pf-v5-c-calendar-month,
.pf-v5-c-banner.pf-m-gold,
.pf-v5-c-banner.pf-m-blue,
.pf-v5-c-alert,
.pf-v5-c-accordion,
.pf-v5-t-light {
  --pf-v5-global--Color--100: var(--pf-v5-global--Color--dark-100);
  --pf-v5-global--Color--200: var(--pf-v5-global--Color--dark-200);
  --pf-v5-global--BorderColor--100: var(--pf-v5-global--BorderColor--dark-100);
  --pf-v5-global--primary-color--100: var(--pf-v5-global--primary-color--dark-100);
  --pf-v5-global--link--Color: var(--pf-v5-global--link--Color--dark);
  --pf-v5-global--link--Color--hover: var(--pf-v5-global--link--Color--dark--hover);
  --pf-v5-global--BackgroundColor--100: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-global--icon--Color--light: var(--pf-v5-global--icon--Color--light--dark);
  --pf-v5-global--icon--Color--dark: var(--pf-v5-global--icon--Color--dark--dark);
}

.pf-v5-c-wizard__header,
.pf-v5-c-page__main-section[class*=pf-m-dark-],
.pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector,
.pf-v5-c-page__sidebar,
.pf-v5-c-page__header,
.pf-v5-c-masthead .pf-v5-c-button.pf-m-plain,
.pf-v5-c-masthead,
.pf-v5-c-login__footer,
.pf-v5-c-login__header,
.pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main,
.pf-v5-c-banner,
.pf-v5-c-about-modal-box,
.pf-v5-t-dark {
  --pf-v5-global--Color--100: var(--pf-v5-global--Color--light-100);
  --pf-v5-global--Color--200: var(--pf-v5-global--Color--light-200);
  --pf-v5-global--BorderColor--100: var(--pf-v5-global--BorderColor--light-100);
  --pf-v5-global--primary-color--100: var(--pf-v5-global--primary-color--light-100);
  --pf-v5-global--link--Color: var(--pf-v5-global--link--Color--light);
  --pf-v5-global--link--Color--hover: var(--pf-v5-global--link--Color--light);
  --pf-v5-global--BackgroundColor--100: var(--pf-v5-global--BackgroundColor--dark-100);
  --pf-v5-global--icon--Color--light: var(--pf-v5-global--icon--Color--light--light);
  --pf-v5-global--icon--Color--dark: var(--pf-v5-global--icon--Color--dark--light);
}

.pf-v5-c-wizard__header .pf-v5-c-button,
.pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
.pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
.pf-v5-c-page__sidebar .pf-v5-c-button,
.pf-v5-c-page__header .pf-v5-c-button,
.pf-v5-c-masthead .pf-v5-c-button.pf-m-plain .pf-v5-c-button,
.pf-v5-c-masthead .pf-v5-c-button,
.pf-v5-c-login__footer .pf-v5-c-button,
.pf-v5-c-login__header .pf-v5-c-button,
.pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
.pf-v5-c-banner .pf-v5-c-button,
.pf-v5-c-about-modal-box .pf-v5-c-button,
.pf-v5-t-dark .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--dark-100);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr)>*,
.pf-v5-c-page__header-tools-item,
.pf-v5-c-page__header-tools-group,
.pf-v5-c-menu__group,
.pf-v5-c-menu__list-item,
.pf-v5-c-menu__list,
.pf-v5-c-divider,
.pf-v5-c-toolbar__content-section,
.pf-v5-c-toolbar__content,
.pf-v5-c-toolbar__item,
.pf-v5-c-toolbar__group,
.pf-v5-c-data-list__item-action {
  --pf-v5-hidden-visible--hidden--Display: none;
  --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  display: var(--pf-v5-hidden-visible--Display);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-hidden,
.pf-m-hidden.pf-v5-c-page__header-tools-item,
.pf-m-hidden.pf-v5-c-page__header-tools-group,
.pf-m-hidden.pf-v5-c-menu__group,
.pf-m-hidden.pf-v5-c-menu__list-item,
.pf-m-hidden.pf-v5-c-menu__list,
.pf-m-hidden.pf-v5-c-divider,
.pf-m-hidden.pf-v5-c-toolbar__content-section,
.pf-m-hidden.pf-v5-c-toolbar__content,
.pf-m-hidden.pf-v5-c-toolbar__item,
.pf-m-hidden.pf-v5-c-toolbar__group,
.pf-m-hidden.pf-v5-c-data-list__item-action {
  --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
}

@media screen and (min-width: 576px) {
  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-hidden-on-sm,
  .pf-m-hidden-on-sm.pf-v5-c-page__header-tools-item,
  .pf-m-hidden-on-sm.pf-v5-c-page__header-tools-group,
  .pf-m-hidden-on-sm.pf-v5-c-menu__group,
  .pf-m-hidden-on-sm.pf-v5-c-menu__list-item,
  .pf-m-hidden-on-sm.pf-v5-c-menu__list,
  .pf-m-hidden-on-sm.pf-v5-c-divider,
  .pf-m-hidden-on-sm.pf-v5-c-toolbar__content-section,
  .pf-m-hidden-on-sm.pf-v5-c-toolbar__content,
  .pf-m-hidden-on-sm.pf-v5-c-toolbar__item,
  .pf-m-hidden-on-sm.pf-v5-c-toolbar__group,
  .pf-m-hidden-on-sm.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-visible-on-sm,
  .pf-m-visible-on-sm.pf-v5-c-page__header-tools-item,
  .pf-m-visible-on-sm.pf-v5-c-page__header-tools-group,
  .pf-m-visible-on-sm.pf-v5-c-menu__group,
  .pf-m-visible-on-sm.pf-v5-c-menu__list-item,
  .pf-m-visible-on-sm.pf-v5-c-menu__list,
  .pf-m-visible-on-sm.pf-v5-c-divider,
  .pf-m-visible-on-sm.pf-v5-c-toolbar__content-section,
  .pf-m-visible-on-sm.pf-v5-c-toolbar__content,
  .pf-m-visible-on-sm.pf-v5-c-toolbar__item,
  .pf-m-visible-on-sm.pf-v5-c-toolbar__group,
  .pf-m-visible-on-sm.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-hidden-on-md,
  .pf-m-hidden-on-md.pf-v5-c-page__header-tools-item,
  .pf-m-hidden-on-md.pf-v5-c-page__header-tools-group,
  .pf-m-hidden-on-md.pf-v5-c-menu__group,
  .pf-m-hidden-on-md.pf-v5-c-menu__list-item,
  .pf-m-hidden-on-md.pf-v5-c-menu__list,
  .pf-m-hidden-on-md.pf-v5-c-divider,
  .pf-m-hidden-on-md.pf-v5-c-toolbar__content-section,
  .pf-m-hidden-on-md.pf-v5-c-toolbar__content,
  .pf-m-hidden-on-md.pf-v5-c-toolbar__item,
  .pf-m-hidden-on-md.pf-v5-c-toolbar__group,
  .pf-m-hidden-on-md.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-visible-on-md,
  .pf-m-visible-on-md.pf-v5-c-page__header-tools-item,
  .pf-m-visible-on-md.pf-v5-c-page__header-tools-group,
  .pf-m-visible-on-md.pf-v5-c-menu__group,
  .pf-m-visible-on-md.pf-v5-c-menu__list-item,
  .pf-m-visible-on-md.pf-v5-c-menu__list,
  .pf-m-visible-on-md.pf-v5-c-divider,
  .pf-m-visible-on-md.pf-v5-c-toolbar__content-section,
  .pf-m-visible-on-md.pf-v5-c-toolbar__content,
  .pf-m-visible-on-md.pf-v5-c-toolbar__item,
  .pf-m-visible-on-md.pf-v5-c-toolbar__group,
  .pf-m-visible-on-md.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-hidden-on-lg,
  .pf-m-hidden-on-lg.pf-v5-c-page__header-tools-item,
  .pf-m-hidden-on-lg.pf-v5-c-page__header-tools-group,
  .pf-m-hidden-on-lg.pf-v5-c-menu__group,
  .pf-m-hidden-on-lg.pf-v5-c-menu__list-item,
  .pf-m-hidden-on-lg.pf-v5-c-menu__list,
  .pf-m-hidden-on-lg.pf-v5-c-divider,
  .pf-m-hidden-on-lg.pf-v5-c-toolbar__content-section,
  .pf-m-hidden-on-lg.pf-v5-c-toolbar__content,
  .pf-m-hidden-on-lg.pf-v5-c-toolbar__item,
  .pf-m-hidden-on-lg.pf-v5-c-toolbar__group,
  .pf-m-hidden-on-lg.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-visible-on-lg,
  .pf-m-visible-on-lg.pf-v5-c-page__header-tools-item,
  .pf-m-visible-on-lg.pf-v5-c-page__header-tools-group,
  .pf-m-visible-on-lg.pf-v5-c-menu__group,
  .pf-m-visible-on-lg.pf-v5-c-menu__list-item,
  .pf-m-visible-on-lg.pf-v5-c-menu__list,
  .pf-m-visible-on-lg.pf-v5-c-divider,
  .pf-m-visible-on-lg.pf-v5-c-toolbar__content-section,
  .pf-m-visible-on-lg.pf-v5-c-toolbar__content,
  .pf-m-visible-on-lg.pf-v5-c-toolbar__item,
  .pf-m-visible-on-lg.pf-v5-c-toolbar__group,
  .pf-m-visible-on-lg.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-hidden-on-xl,
  .pf-m-hidden-on-xl.pf-v5-c-page__header-tools-item,
  .pf-m-hidden-on-xl.pf-v5-c-page__header-tools-group,
  .pf-m-hidden-on-xl.pf-v5-c-menu__group,
  .pf-m-hidden-on-xl.pf-v5-c-menu__list-item,
  .pf-m-hidden-on-xl.pf-v5-c-menu__list,
  .pf-m-hidden-on-xl.pf-v5-c-divider,
  .pf-m-hidden-on-xl.pf-v5-c-toolbar__content-section,
  .pf-m-hidden-on-xl.pf-v5-c-toolbar__content,
  .pf-m-hidden-on-xl.pf-v5-c-toolbar__item,
  .pf-m-hidden-on-xl.pf-v5-c-toolbar__group,
  .pf-m-hidden-on-xl.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-visible-on-xl,
  .pf-m-visible-on-xl.pf-v5-c-page__header-tools-item,
  .pf-m-visible-on-xl.pf-v5-c-page__header-tools-group,
  .pf-m-visible-on-xl.pf-v5-c-menu__group,
  .pf-m-visible-on-xl.pf-v5-c-menu__list-item,
  .pf-m-visible-on-xl.pf-v5-c-menu__list,
  .pf-m-visible-on-xl.pf-v5-c-divider,
  .pf-m-visible-on-xl.pf-v5-c-toolbar__content-section,
  .pf-m-visible-on-xl.pf-v5-c-toolbar__content,
  .pf-m-visible-on-xl.pf-v5-c-toolbar__item,
  .pf-m-visible-on-xl.pf-v5-c-toolbar__group,
  .pf-m-visible-on-xl.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-hidden-on-2xl,
  .pf-m-hidden-on-2xl.pf-v5-c-page__header-tools-item,
  .pf-m-hidden-on-2xl.pf-v5-c-page__header-tools-group,
  .pf-m-hidden-on-2xl.pf-v5-c-menu__group,
  .pf-m-hidden-on-2xl.pf-v5-c-menu__list-item,
  .pf-m-hidden-on-2xl.pf-v5-c-menu__list,
  .pf-m-hidden-on-2xl.pf-v5-c-divider,
  .pf-m-hidden-on-2xl.pf-v5-c-toolbar__content-section,
  .pf-m-hidden-on-2xl.pf-v5-c-toolbar__content,
  .pf-m-hidden-on-2xl.pf-v5-c-toolbar__item,
  .pf-m-hidden-on-2xl.pf-v5-c-toolbar__group,
  .pf-m-hidden-on-2xl.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-visible-on-2xl,
  .pf-m-visible-on-2xl.pf-v5-c-page__header-tools-item,
  .pf-m-visible-on-2xl.pf-v5-c-page__header-tools-group,
  .pf-m-visible-on-2xl.pf-v5-c-menu__group,
  .pf-m-visible-on-2xl.pf-v5-c-menu__list-item,
  .pf-m-visible-on-2xl.pf-v5-c-menu__list,
  .pf-m-visible-on-2xl.pf-v5-c-divider,
  .pf-m-visible-on-2xl.pf-v5-c-toolbar__content-section,
  .pf-m-visible-on-2xl.pf-v5-c-toolbar__content,
  .pf-m-visible-on-2xl.pf-v5-c-toolbar__item,
  .pf-m-visible-on-2xl.pf-v5-c-toolbar__group,
  .pf-m-visible-on-2xl.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

.pf-v5-c-number-input .pf-v5-c-form-control>:is(input),
.pf-v5-c-pagination__nav-page-select .pf-v5-c-form-control>:is(input) {
  appearance: textfield;
}

.pf-v5-c-number-input .pf-v5-c-form-control>:is(input)::-webkit-inner-spin-button,
.pf-v5-c-pagination__nav-page-select .pf-v5-c-form-control>:is(input)::-webkit-inner-spin-button,
.pf-v5-c-number-input .pf-v5-c-form-control>:is(input)::-webkit-outer-spin-button,
.pf-v5-c-pagination__nav-page-select .pf-v5-c-form-control>:is(input)::-webkit-outer-spin-button {
  margin: 0;
  appearance: none;
}

.pf-v5-c-table,
.pf-v5-c-select,
.pf-v5-c-page__sidebar.pf-m-light,
.pf-v5-c-menu,
.pf-v5-c-masthead.pf-m-light-200,
.pf-v5-c-masthead.pf-m-light,
.pf-v5-c-form-control,
.pf-v5-c-data-list,
.pf-v5-c-context-selector__menu,
.pf-v5-c-chip-group,
.pf-v5-c-chip,
.pf-v5-c-calendar-month,
.pf-v5-c-banner.pf-m-gold,
.pf-v5-c-banner.pf-m-blue,
.pf-v5-c-alert,
.pf-v5-c-accordion,
.pf-v5-t-light {
  --pf-v5-global--Color--100: var(--pf-v5-global--Color--dark-100);
  --pf-v5-global--Color--200: var(--pf-v5-global--Color--dark-200);
  --pf-v5-global--BorderColor--100: var(--pf-v5-global--BorderColor--dark-100);
  --pf-v5-global--primary-color--100: var(--pf-v5-global--primary-color--dark-100);
  --pf-v5-global--link--Color: var(--pf-v5-global--link--Color--dark);
  --pf-v5-global--link--Color--hover: var(--pf-v5-global--link--Color--dark--hover);
  --pf-v5-global--BackgroundColor--100: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-global--icon--Color--light: var(--pf-v5-global--icon--Color--light--dark);
  --pf-v5-global--icon--Color--dark: var(--pf-v5-global--icon--Color--dark--dark);
}

.pf-v5-c-wizard__header,
.pf-v5-c-page__main-section[class*=pf-m-dark-],
.pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector,
.pf-v5-c-page__sidebar,
.pf-v5-c-page__header,
.pf-v5-c-masthead .pf-v5-c-button.pf-m-plain,
.pf-v5-c-masthead,
.pf-v5-c-login__footer,
.pf-v5-c-login__header,
.pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main,
.pf-v5-c-banner,
.pf-v5-c-about-modal-box,
.pf-v5-t-dark {
  --pf-v5-global--Color--100: var(--pf-v5-global--Color--light-100);
  --pf-v5-global--Color--200: var(--pf-v5-global--Color--light-200);
  --pf-v5-global--BorderColor--100: var(--pf-v5-global--BorderColor--light-100);
  --pf-v5-global--primary-color--100: var(--pf-v5-global--primary-color--light-100);
  --pf-v5-global--link--Color: var(--pf-v5-global--link--Color--light);
  --pf-v5-global--link--Color--hover: var(--pf-v5-global--link--Color--light);
  --pf-v5-global--BackgroundColor--100: var(--pf-v5-global--BackgroundColor--dark-100);
  --pf-v5-global--icon--Color--light: var(--pf-v5-global--icon--Color--light--light);
  --pf-v5-global--icon--Color--dark: var(--pf-v5-global--icon--Color--dark--light);
}

.pf-v5-c-wizard__header .pf-v5-c-button,
.pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
.pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
.pf-v5-c-page__sidebar .pf-v5-c-button,
.pf-v5-c-page__header .pf-v5-c-button,
.pf-v5-c-masthead .pf-v5-c-button.pf-m-plain .pf-v5-c-button,
.pf-v5-c-masthead .pf-v5-c-button,
.pf-v5-c-login__footer .pf-v5-c-button,
.pf-v5-c-login__header .pf-v5-c-button,
.pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
.pf-v5-c-banner .pf-v5-c-button,
.pf-v5-c-about-modal-box .pf-v5-c-button,
.pf-v5-t-dark .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--dark-100);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr)>*,
.pf-v5-c-page__header-tools-item,
.pf-v5-c-page__header-tools-group,
.pf-v5-c-menu__group,
.pf-v5-c-menu__list-item,
.pf-v5-c-menu__list,
.pf-v5-c-divider,
.pf-v5-c-toolbar__content-section,
.pf-v5-c-toolbar__content,
.pf-v5-c-toolbar__item,
.pf-v5-c-toolbar__group,
.pf-v5-c-data-list__item-action {
  --pf-v5-hidden-visible--hidden--Display: none;
  --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  display: var(--pf-v5-hidden-visible--Display);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-hidden,
.pf-m-hidden.pf-v5-c-page__header-tools-item,
.pf-m-hidden.pf-v5-c-page__header-tools-group,
.pf-m-hidden.pf-v5-c-menu__group,
.pf-m-hidden.pf-v5-c-menu__list-item,
.pf-m-hidden.pf-v5-c-menu__list,
.pf-m-hidden.pf-v5-c-divider,
.pf-m-hidden.pf-v5-c-toolbar__content-section,
.pf-m-hidden.pf-v5-c-toolbar__content,
.pf-m-hidden.pf-v5-c-toolbar__item,
.pf-m-hidden.pf-v5-c-toolbar__group,
.pf-m-hidden.pf-v5-c-data-list__item-action {
  --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
}

@media screen and (min-width: 576px) {
  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-hidden-on-sm,
  .pf-m-hidden-on-sm.pf-v5-c-page__header-tools-item,
  .pf-m-hidden-on-sm.pf-v5-c-page__header-tools-group,
  .pf-m-hidden-on-sm.pf-v5-c-menu__group,
  .pf-m-hidden-on-sm.pf-v5-c-menu__list-item,
  .pf-m-hidden-on-sm.pf-v5-c-menu__list,
  .pf-m-hidden-on-sm.pf-v5-c-divider,
  .pf-m-hidden-on-sm.pf-v5-c-toolbar__content-section,
  .pf-m-hidden-on-sm.pf-v5-c-toolbar__content,
  .pf-m-hidden-on-sm.pf-v5-c-toolbar__item,
  .pf-m-hidden-on-sm.pf-v5-c-toolbar__group,
  .pf-m-hidden-on-sm.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-visible-on-sm,
  .pf-m-visible-on-sm.pf-v5-c-page__header-tools-item,
  .pf-m-visible-on-sm.pf-v5-c-page__header-tools-group,
  .pf-m-visible-on-sm.pf-v5-c-menu__group,
  .pf-m-visible-on-sm.pf-v5-c-menu__list-item,
  .pf-m-visible-on-sm.pf-v5-c-menu__list,
  .pf-m-visible-on-sm.pf-v5-c-divider,
  .pf-m-visible-on-sm.pf-v5-c-toolbar__content-section,
  .pf-m-visible-on-sm.pf-v5-c-toolbar__content,
  .pf-m-visible-on-sm.pf-v5-c-toolbar__item,
  .pf-m-visible-on-sm.pf-v5-c-toolbar__group,
  .pf-m-visible-on-sm.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-hidden-on-md,
  .pf-m-hidden-on-md.pf-v5-c-page__header-tools-item,
  .pf-m-hidden-on-md.pf-v5-c-page__header-tools-group,
  .pf-m-hidden-on-md.pf-v5-c-menu__group,
  .pf-m-hidden-on-md.pf-v5-c-menu__list-item,
  .pf-m-hidden-on-md.pf-v5-c-menu__list,
  .pf-m-hidden-on-md.pf-v5-c-divider,
  .pf-m-hidden-on-md.pf-v5-c-toolbar__content-section,
  .pf-m-hidden-on-md.pf-v5-c-toolbar__content,
  .pf-m-hidden-on-md.pf-v5-c-toolbar__item,
  .pf-m-hidden-on-md.pf-v5-c-toolbar__group,
  .pf-m-hidden-on-md.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-visible-on-md,
  .pf-m-visible-on-md.pf-v5-c-page__header-tools-item,
  .pf-m-visible-on-md.pf-v5-c-page__header-tools-group,
  .pf-m-visible-on-md.pf-v5-c-menu__group,
  .pf-m-visible-on-md.pf-v5-c-menu__list-item,
  .pf-m-visible-on-md.pf-v5-c-menu__list,
  .pf-m-visible-on-md.pf-v5-c-divider,
  .pf-m-visible-on-md.pf-v5-c-toolbar__content-section,
  .pf-m-visible-on-md.pf-v5-c-toolbar__content,
  .pf-m-visible-on-md.pf-v5-c-toolbar__item,
  .pf-m-visible-on-md.pf-v5-c-toolbar__group,
  .pf-m-visible-on-md.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-hidden-on-lg,
  .pf-m-hidden-on-lg.pf-v5-c-page__header-tools-item,
  .pf-m-hidden-on-lg.pf-v5-c-page__header-tools-group,
  .pf-m-hidden-on-lg.pf-v5-c-menu__group,
  .pf-m-hidden-on-lg.pf-v5-c-menu__list-item,
  .pf-m-hidden-on-lg.pf-v5-c-menu__list,
  .pf-m-hidden-on-lg.pf-v5-c-divider,
  .pf-m-hidden-on-lg.pf-v5-c-toolbar__content-section,
  .pf-m-hidden-on-lg.pf-v5-c-toolbar__content,
  .pf-m-hidden-on-lg.pf-v5-c-toolbar__item,
  .pf-m-hidden-on-lg.pf-v5-c-toolbar__group,
  .pf-m-hidden-on-lg.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-visible-on-lg,
  .pf-m-visible-on-lg.pf-v5-c-page__header-tools-item,
  .pf-m-visible-on-lg.pf-v5-c-page__header-tools-group,
  .pf-m-visible-on-lg.pf-v5-c-menu__group,
  .pf-m-visible-on-lg.pf-v5-c-menu__list-item,
  .pf-m-visible-on-lg.pf-v5-c-menu__list,
  .pf-m-visible-on-lg.pf-v5-c-divider,
  .pf-m-visible-on-lg.pf-v5-c-toolbar__content-section,
  .pf-m-visible-on-lg.pf-v5-c-toolbar__content,
  .pf-m-visible-on-lg.pf-v5-c-toolbar__item,
  .pf-m-visible-on-lg.pf-v5-c-toolbar__group,
  .pf-m-visible-on-lg.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-hidden-on-xl,
  .pf-m-hidden-on-xl.pf-v5-c-page__header-tools-item,
  .pf-m-hidden-on-xl.pf-v5-c-page__header-tools-group,
  .pf-m-hidden-on-xl.pf-v5-c-menu__group,
  .pf-m-hidden-on-xl.pf-v5-c-menu__list-item,
  .pf-m-hidden-on-xl.pf-v5-c-menu__list,
  .pf-m-hidden-on-xl.pf-v5-c-divider,
  .pf-m-hidden-on-xl.pf-v5-c-toolbar__content-section,
  .pf-m-hidden-on-xl.pf-v5-c-toolbar__content,
  .pf-m-hidden-on-xl.pf-v5-c-toolbar__item,
  .pf-m-hidden-on-xl.pf-v5-c-toolbar__group,
  .pf-m-hidden-on-xl.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-visible-on-xl,
  .pf-m-visible-on-xl.pf-v5-c-page__header-tools-item,
  .pf-m-visible-on-xl.pf-v5-c-page__header-tools-group,
  .pf-m-visible-on-xl.pf-v5-c-menu__group,
  .pf-m-visible-on-xl.pf-v5-c-menu__list-item,
  .pf-m-visible-on-xl.pf-v5-c-menu__list,
  .pf-m-visible-on-xl.pf-v5-c-divider,
  .pf-m-visible-on-xl.pf-v5-c-toolbar__content-section,
  .pf-m-visible-on-xl.pf-v5-c-toolbar__content,
  .pf-m-visible-on-xl.pf-v5-c-toolbar__item,
  .pf-m-visible-on-xl.pf-v5-c-toolbar__group,
  .pf-m-visible-on-xl.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-hidden-on-2xl,
  .pf-m-hidden-on-2xl.pf-v5-c-page__header-tools-item,
  .pf-m-hidden-on-2xl.pf-v5-c-page__header-tools-group,
  .pf-m-hidden-on-2xl.pf-v5-c-menu__group,
  .pf-m-hidden-on-2xl.pf-v5-c-menu__list-item,
  .pf-m-hidden-on-2xl.pf-v5-c-menu__list,
  .pf-m-hidden-on-2xl.pf-v5-c-divider,
  .pf-m-hidden-on-2xl.pf-v5-c-toolbar__content-section,
  .pf-m-hidden-on-2xl.pf-v5-c-toolbar__content,
  .pf-m-hidden-on-2xl.pf-v5-c-toolbar__item,
  .pf-m-hidden-on-2xl.pf-v5-c-toolbar__group,
  .pf-m-hidden-on-2xl.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-visible-on-2xl,
  .pf-m-visible-on-2xl.pf-v5-c-page__header-tools-item,
  .pf-m-visible-on-2xl.pf-v5-c-page__header-tools-group,
  .pf-m-visible-on-2xl.pf-v5-c-menu__group,
  .pf-m-visible-on-2xl.pf-v5-c-menu__list-item,
  .pf-m-visible-on-2xl.pf-v5-c-menu__list,
  .pf-m-visible-on-2xl.pf-v5-c-divider,
  .pf-m-visible-on-2xl.pf-v5-c-toolbar__content-section,
  .pf-m-visible-on-2xl.pf-v5-c-toolbar__content,
  .pf-m-visible-on-2xl.pf-v5-c-toolbar__item,
  .pf-m-visible-on-2xl.pf-v5-c-toolbar__group,
  .pf-m-visible-on-2xl.pf-v5-c-data-list__item-action {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

.pf-v5-c-number-input .pf-v5-c-form-control>:is(input),
.pf-v5-c-pagination__nav-page-select .pf-v5-c-form-control>:is(input) {
  appearance: textfield;
}

.pf-v5-c-number-input .pf-v5-c-form-control>:is(input)::-webkit-inner-spin-button,
.pf-v5-c-pagination__nav-page-select .pf-v5-c-form-control>:is(input)::-webkit-inner-spin-button,
.pf-v5-c-number-input .pf-v5-c-form-control>:is(input)::-webkit-outer-spin-button,
.pf-v5-c-pagination__nav-page-select .pf-v5-c-form-control>:is(input)::-webkit-outer-spin-button {
  margin: 0;
  appearance: none;
}

:where(:root) {
  --pf-v5-global--palette--black-100: #fafafa;
  --pf-v5-global--palette--black-150: #f5f5f5;
  --pf-v5-global--palette--black-200: #f0f0f0;
  --pf-v5-global--palette--black-300: #d2d2d2;
  --pf-v5-global--palette--black-400: #b8bbbe;
  --pf-v5-global--palette--black-500: #8a8d90;
  --pf-v5-global--palette--black-600: #6a6e73;
  --pf-v5-global--palette--black-700: #4f5255;
  --pf-v5-global--palette--black-800: #3c3f42;
  --pf-v5-global--palette--black-850: #212427;
  --pf-v5-global--palette--black-900: #151515;
  --pf-v5-global--palette--black-1000: #030303;
  --pf-v5-global--palette--blue-50: #e7f1fa;
  --pf-v5-global--palette--blue-100: #bee1f4;
  --pf-v5-global--palette--blue-200: #73bcf7;
  --pf-v5-global--palette--blue-300: #2b9af3;
  --pf-v5-global--palette--blue-400: #06c;
  --pf-v5-global--palette--blue-500: #004080;
  --pf-v5-global--palette--blue-600: #002952;
  --pf-v5-global--palette--blue-700: #001223;
  --pf-v5-global--palette--cyan-50: #f2f9f9;
  --pf-v5-global--palette--cyan-100: #a2d9d9;
  --pf-v5-global--palette--cyan-200: #73c5c5;
  --pf-v5-global--palette--cyan-300: #009596;
  --pf-v5-global--palette--cyan-400: #005f60;
  --pf-v5-global--palette--cyan-500: #003737;
  --pf-v5-global--palette--cyan-600: #002323;
  --pf-v5-global--palette--cyan-700: #000f0f;
  --pf-v5-global--palette--gold-50: #fdf7e7;
  --pf-v5-global--palette--gold-100: #f9e0a2;
  --pf-v5-global--palette--gold-200: #f6d173;
  --pf-v5-global--palette--gold-300: #f4c145;
  --pf-v5-global--palette--gold-400: #f0ab00;
  --pf-v5-global--palette--gold-500: #c58c00;
  --pf-v5-global--palette--gold-600: #795600;
  --pf-v5-global--palette--gold-700: #3d2c00;
  --pf-v5-global--palette--green-50: #f3faf2;
  --pf-v5-global--palette--green-100: #bde5b8;
  --pf-v5-global--palette--green-200: #95d58e;
  --pf-v5-global--palette--green-300: #6ec664;
  --pf-v5-global--palette--green-400: #5ba352;
  --pf-v5-global--palette--green-500: #3e8635;
  --pf-v5-global--palette--green-600: #1e4f18;
  --pf-v5-global--palette--green-700: #0f280d;
  --pf-v5-global--palette--light-blue-100: #beedf9;
  --pf-v5-global--palette--light-blue-200: #7cdbf3;
  --pf-v5-global--palette--light-blue-300: #35caed;
  --pf-v5-global--palette--light-blue-400: #00b9e4;
  --pf-v5-global--palette--light-blue-500: #008bad;
  --pf-v5-global--palette--light-blue-600: #005c73;
  --pf-v5-global--palette--light-blue-700: #002d39;
  --pf-v5-global--palette--light-green-100: #e4f5bc;
  --pf-v5-global--palette--light-green-200: #c8eb79;
  --pf-v5-global--palette--light-green-300: #ace12e;
  --pf-v5-global--palette--light-green-400: #92d400;
  --pf-v5-global--palette--light-green-500: #6ca100;
  --pf-v5-global--palette--light-green-600: #486b00;
  --pf-v5-global--palette--light-green-700: #253600;
  --pf-v5-global--palette--orange-50: #fff6ec;
  --pf-v5-global--palette--orange-100: #f4b678;
  --pf-v5-global--palette--orange-200: #ef9234;
  --pf-v5-global--palette--orange-300: #ec7a08;
  --pf-v5-global--palette--orange-400: #c46100;
  --pf-v5-global--palette--orange-500: #8f4700;
  --pf-v5-global--palette--orange-600: #773d00;
  --pf-v5-global--palette--orange-700: #3b1f00;
  --pf-v5-global--palette--purple-50: #f2f0fc;
  --pf-v5-global--palette--purple-100: #cbc1ff;
  --pf-v5-global--palette--purple-200: #b2a3ff;
  --pf-v5-global--palette--purple-300: #a18fff;
  --pf-v5-global--palette--purple-400: #8476d1;
  --pf-v5-global--palette--purple-500: #6753ac;
  --pf-v5-global--palette--purple-600: #40199a;
  --pf-v5-global--palette--purple-700: #1f0066;
  --pf-v5-global--palette--red-50: #faeae8;
  --pf-v5-global--palette--red-100: #c9190b;
  --pf-v5-global--palette--red-200: #a30000;
  --pf-v5-global--palette--red-300: #7d1007;
  --pf-v5-global--palette--red-400: #470000;
  --pf-v5-global--palette--red-500: #2c0000;
  --pf-v5-global--palette--white: #fff;
  --pf-v5-global--BackgroundColor--100: #fff;
  --pf-v5-global--BackgroundColor--150: #fafafa;
  --pf-v5-global--BackgroundColor--200: #f0f0f0;
  --pf-v5-global--BackgroundColor--light-100: #fff;
  --pf-v5-global--BackgroundColor--light-200: #fafafa;
  --pf-v5-global--BackgroundColor--light-300: #f0f0f0;
  --pf-v5-global--BackgroundColor--dark-100: #151515;
  --pf-v5-global--BackgroundColor--dark-200: #3c3f42;
  --pf-v5-global--BackgroundColor--dark-300: #212427;
  --pf-v5-global--BackgroundColor--dark-400: #4f5255;
  --pf-v5-global--BackgroundColor--dark-transparent-100: rgba(3, 3, 3, 0.62);
  --pf-v5-global--BackgroundColor--dark-transparent-200: rgba(3, 3, 3, 0.32);
  --pf-v5-global--Color--100: #151515;
  --pf-v5-global--Color--200: #6a6e73;
  --pf-v5-global--Color--300: #3c3f42;
  --pf-v5-global--Color--400: #8a8d90;
  --pf-v5-global--Color--light-100: #fff;
  --pf-v5-global--Color--light-200: #f0f0f0;
  --pf-v5-global--Color--light-300: #d2d2d2;
  --pf-v5-global--Color--dark-100: #151515;
  --pf-v5-global--Color--dark-200: #6a6e73;
  --pf-v5-global--active-color--100: #06c;
  --pf-v5-global--active-color--200: #bee1f4;
  --pf-v5-global--active-color--300: #2b9af3;
  --pf-v5-global--active-color--400: #73bcf7;
  --pf-v5-global--disabled-color--100: #6a6e73;
  --pf-v5-global--disabled-color--200: #d2d2d2;
  --pf-v5-global--disabled-color--300: #f0f0f0;
  --pf-v5-global--primary-color--100: #06c;
  --pf-v5-global--primary-color--200: #004080;
  --pf-v5-global--primary-color--light-100: #73bcf7;
  --pf-v5-global--primary-color--dark-100: #06c;
  --pf-v5-global--secondary-color--100: #6a6e73;
  --pf-v5-global--custom-color--100: #73c5c5;
  --pf-v5-global--custom-color--200: #009596;
  --pf-v5-global--custom-color--300: #003737;
  --pf-v5-global--success-color--100: #3e8635;
  --pf-v5-global--success-color--200: #1e4f18;
  --pf-v5-global--info-color--100: #2b9af3;
  --pf-v5-global--info-color--200: #002952;
  --pf-v5-global--warning-color--100: #f0ab00;
  --pf-v5-global--warning-color--200: #795600;
  --pf-v5-global--danger-color--100: #c9190b;
  --pf-v5-global--danger-color--200: #a30000;
  --pf-v5-global--danger-color--300: #470000;
  --pf-v5-global--BoxShadow--sm: 0 0.0625rem 0.125rem 0 rgba(3, 3, 3, 0.12), 0 0 0.125rem 0 rgba(3, 3, 3, 0.06);
  --pf-v5-global--BoxShadow--sm-top: 0 -0.125rem 0.25rem -0.0625rem rgba(3, 3, 3, 0.16);
  --pf-v5-global--BoxShadow--sm-right: 0.125rem 0 0.25rem -0.0625rem rgba(3, 3, 3, 0.16);
  --pf-v5-global--BoxShadow--sm-bottom: 0 0.125rem 0.25rem -0.0625rem rgba(3, 3, 3, 0.16);
  --pf-v5-global--BoxShadow--sm-left: -0.125rem 0 0.25rem -0.0625rem rgba(3, 3, 3, 0.16);
  --pf-v5-global--BoxShadow--md: 0 0.25rem 0.5rem 0rem rgba(3, 3, 3, 0.12), 0 0 0.25rem 0 rgba(3, 3, 3, 0.06);
  --pf-v5-global--BoxShadow--md-top: 0 -0.5rem 0.5rem -0.375rem rgba(3, 3, 3, 0.18);
  --pf-v5-global--BoxShadow--md-right: 0.5rem 0 0.5rem -0.375rem rgba(3, 3, 3, 0.18);
  --pf-v5-global--BoxShadow--md-bottom: 0 0.5rem 0.5rem -0.375rem rgba(3, 3, 3, 0.18);
  --pf-v5-global--BoxShadow--md-left: -0.5rem 0 0.5rem -0.375rem rgba(3, 3, 3, 0.18);
  --pf-v5-global--BoxShadow--lg: 0 0.5rem 1rem 0 rgba(3, 3, 3, 0.16), 0 0 0.375rem 0 rgba(3, 3, 3, 0.08);
  --pf-v5-global--BoxShadow--lg-top: 0 -0.75rem 0.75rem -0.5rem rgba(3, 3, 3, 0.18);
  --pf-v5-global--BoxShadow--lg-right: 0.75rem 0 0.75rem -0.5rem rgba(3, 3, 3, 0.18);
  --pf-v5-global--BoxShadow--lg-bottom: 0 0.75rem 0.75rem -0.5rem rgba(3, 3, 3, 0.18);
  --pf-v5-global--BoxShadow--lg-left: -0.75rem 0 0.75rem -0.5rem rgba(3, 3, 3, 0.18);
  --pf-v5-global--BoxShadow--xl: 0 1rem 2rem 0 rgba(3, 3, 3, 0.16), 0 0 0.5rem 0 rgba(3, 3, 3, 0.1);
  --pf-v5-global--BoxShadow--xl-top: 0 -1rem 1rem -0.5rem rgba(3, 3, 3, 0.2);
  --pf-v5-global--BoxShadow--xl-right: 1rem 0 1rem -0.5rem rgba(3, 3, 3, 0.2);
  --pf-v5-global--BoxShadow--xl-bottom: 0 1rem 1rem -0.5rem rgba(3, 3, 3, 0.2);
  --pf-v5-global--BoxShadow--xl-left: -1rem 0 1rem -0.5rem rgba(3, 3, 3, 0.2);
  --pf-v5-global--BoxShadow--inset: inset 0 0 0.625rem 0 rgba(3, 3, 3, 0.25);
  --pf-v5-global--font-path: "~@patternfly/patternfly/assets/fonts";
  --pf-v5-global--fonticon-path: "./assets/pficon";
  --pf-v5-global--spacer--xs: 0.25rem;
  --pf-v5-global--spacer--sm: 0.5rem;
  --pf-v5-global--spacer--md: 1rem;
  --pf-v5-global--spacer--lg: 1.5rem;
  --pf-v5-global--spacer--xl: 2rem;
  --pf-v5-global--spacer--2xl: 3rem;
  --pf-v5-global--spacer--3xl: 4rem;
  --pf-v5-global--spacer--4xl: 5rem;
  --pf-v5-global--spacer--form-element: 0.375rem;
  --pf-v5-global--gutter: 1rem;
  --pf-v5-global--gutter--md: 1.5rem;
  --pf-v5-global--ZIndex--xs: 100;
  --pf-v5-global--ZIndex--sm: 200;
  --pf-v5-global--ZIndex--md: 300;
  --pf-v5-global--ZIndex--lg: 400;
  --pf-v5-global--ZIndex--xl: 500;
  --pf-v5-global--ZIndex--2xl: 600;
  --pf-v5-global--breakpoint--xs: 0;
  --pf-v5-global--breakpoint--sm: 576px;
  --pf-v5-global--breakpoint--md: 768px;
  --pf-v5-global--breakpoint--lg: 992px;
  --pf-v5-global--breakpoint--xl: 1200px;
  --pf-v5-global--breakpoint--2xl: 1450px;
  --pf-v5-global--height-breakpoint--sm: 0;
  --pf-v5-global--height-breakpoint--md: 40rem;
  --pf-v5-global--height-breakpoint--lg: 48rem;
  --pf-v5-global--height-breakpoint--xl: 60rem;
  --pf-v5-global--height-breakpoint--2xl: 80rem;
  --pf-v5-global--link--Color: #06c;
  --pf-v5-global--link--Color--hover: #004080;
  --pf-v5-global--link--Color--light: #2b9af3;
  --pf-v5-global--link--Color--light--hover: #73bcf7;
  --pf-v5-global--link--Color--dark: #06c;
  --pf-v5-global--link--Color--dark--hover: #004080;
  --pf-v5-global--link--Color--visited: #40199a;
  --pf-v5-global--link--TextDecoration: none;
  --pf-v5-global--link--TextDecoration--hover: underline;
  --pf-v5-global--BorderWidth--sm: 1px;
  --pf-v5-global--BorderWidth--md: 2px;
  --pf-v5-global--BorderWidth--lg: 3px;
  --pf-v5-global--BorderWidth--xl: 4px;
  --pf-v5-global--BorderColor--100: #d2d2d2;
  --pf-v5-global--BorderColor--200: #8a8d90;
  --pf-v5-global--BorderColor--300: #f0f0f0;
  --pf-v5-global--BorderColor--dark-100: #d2d2d2;
  --pf-v5-global--BorderColor--light-100: #b8bbbe;
  --pf-v5-global--BorderRadius--sm: 3px;
  --pf-v5-global--BorderRadius--lg: 30em;
  --pf-v5-global--icon--Color--light: #6a6e73;
  --pf-v5-global--icon--Color--dark: #151515;
  --pf-v5-global--icon--Color--light--light: #f0f0f0;
  --pf-v5-global--icon--Color--dark--light: #fff;
  --pf-v5-global--icon--Color--light--dark: #6a6e73;
  --pf-v5-global--icon--Color--dark--dark: #151515;
  --pf-v5-global--icon--FontSize--sm: 0.75rem;
  --pf-v5-global--icon--FontSize--md: 1rem;
  --pf-v5-global--icon--FontSize--lg: 1.5rem;
  --pf-v5-global--icon--FontSize--xl: 3.375rem;
  --pf-v5-global--FontFamily--text: "RedHatText", helvetica, arial, sans-serif;
  --pf-v5-global--FontFamily--heading: "RedHatDisplay", helvetica, arial, sans-serif;
  --pf-v5-global--FontFamily--monospace: "RedHatMono", "Liberation Mono", consolas, "SFMono-Regular", menlo, monaco, "Courier New", monospace;
  --pf-v5-global--FontFamily--text--vf: "RedHatTextVF", "RedHatText", helvetica, arial, sans-serif;
  --pf-v5-global--FontFamily--heading--vf: "RedHatDisplayVF", "RedHatDisplay", helvetica, arial, sans-serif;
  --pf-v5-global--FontFamily--monospace--vf: "RedHatMonoVF", "RedHatMono", "Liberation Mono", consolas, "SFMono-Regular", menlo, monaco, "Courier New", monospace;
  --pf-v5-global--FontSize--4xl: 2.25rem;
  --pf-v5-global--FontSize--3xl: 1.75rem;
  --pf-v5-global--FontSize--2xl: 1.5rem;
  --pf-v5-global--FontSize--xl: 1.25rem;
  --pf-v5-global--FontSize--lg: 1.125rem;
  --pf-v5-global--FontSize--md: 1rem;
  --pf-v5-global--FontSize--sm: 0.875rem;
  --pf-v5-global--FontSize--xs: 0.75rem;
  --pf-v5-global--FontWeight--normal: 400;
  --pf-v5-global--FontWeight--bold: 700;
  --pf-v5-global--LineHeight--sm: 1.3;
  --pf-v5-global--LineHeight--md: 1.5;
  --pf-v5-global--ListStyle: disc outside;
  --pf-v5-global--Transition: all 250ms cubic-bezier(0.42, 0, 0.58, 1);
  --pf-v5-global--TimingFunction: cubic-bezier(0.645, 0.045, 0.355, 1);
  --pf-v5-global--TransitionDuration: 250ms;
  --pf-v5-global--arrow--width: 0.9375rem;
  --pf-v5-global--arrow--width-lg: 1.5625rem;
  --pf-v5-global--target-size--MinWidth: 44px;
  --pf-v5-global--target-size--MinHeight: 44px;
  --pf-v5-global--inverse--multiplier: 1;
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) {
  --pf-v5-global--inverse--multiplier: -1;
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) {
  --pf-v5-global--palette--black-50: #e0e0e0;
  --pf-v5-global--palette--black-100: #c6c7c8;
  --pf-v5-global--palette--black-200: #aaabac;
  --pf-v5-global--palette--black-300: #868789;
  --pf-v5-global--palette--black-400: #57585a;
  --pf-v5-global--palette--black-500: #444548;
  --pf-v5-global--palette--black-600: #36373a;
  --pf-v5-global--palette--black-700: #26292d;
  --pf-v5-global--palette--black-800: #1b1d21;
  --pf-v5-global--palette--black-900: #0f1214;
  --pf-v5-global--palette--red-9999: #fe5142;
  --pf-v5-global--palette--red-8888: #ff7468;
  --pf-v5-global--palette--blue-300: #1fa7f8;
  --pf-v5-global--BackgroundColor--100: #1b1d21;
  --pf-v5-global--BackgroundColor--150: #212427;
  --pf-v5-global--BackgroundColor--200: #0f1214;
  --pf-v5-global--BackgroundColor--300: #26292d;
  --pf-v5-global--BackgroundColor--400: #36373a;
  --pf-v5-global--BorderColor--100: #444548;
  --pf-v5-global--BorderColor--200: #444548;
  --pf-v5-global--BorderColor--300: #57585a;
  --pf-v5-global--BorderColor--400: #aaabac;
  --pf-v5-global--Color--100: #e0e0e0;
  --pf-v5-global--Color--200: #aaabac;
  --pf-v5-global--active-color--100: #1fa7f8;
  --pf-v5-global--primary-color--100: #1fa7f8;
  --pf-v5-global--primary-color--300: #06c;
  --pf-v5-global--primary-color--400: #fff;
  --pf-v5-global--custom-color--200: #73c5c5;
  --pf-v5-global--success-color--100: #5ba352;
  --pf-v5-global--warning-color--100: #f0ab00;
  --pf-v5-global--warning-color--200: #f4c145;
  --pf-v5-global--danger-color--100: #fe5142;
  --pf-v5-global--danger-color--200: #ff7468;
  --pf-v5-global--link--Color: #1fa7f8;
  --pf-v5-global--link--Color--hover: #73bcf7;
  --pf-v5-global--link--Color--visited: #a18fff;
  --pf-v5-global--disabled-color--100: #868789;
  --pf-v5-global--disabled-color--200: #444548;
  --pf-v5-global--disabled-color--300: #aaabac;
  --pf-v5-global--icon--Color--light: #aaabac;
  --pf-v5-global--icon--Color--dark: #e0e0e0;
  --pf-v5-global--Color--dark-100: #e0e0e0;
  --pf-v5-global--Color--dark-200: #aaabac;
  --pf-v5-global--Color--light-100: #e0e0e0;
  --pf-v5-global--Color--light-200: #aaabac;
  --pf-v5-global--Color--light-300: #3c3f42;
  --pf-v5-global--BorderColor--dark-100: #444548;
  --pf-v5-global--BorderColor--light-100: #444548;
  --pf-v5-global--primary-color--light-100: #1fa7f8;
  --pf-v5-global--primary-color--dark-100: #1fa7f8;
  --pf-v5-global--link--Color--light: #1fa7f8;
  --pf-v5-global--link--Color--light--hover: #73bcf7;
  --pf-v5-global--link--Color--dark: #1fa7f8;
  --pf-v5-global--link--Color--dark--hover: #73bcf7;
  --pf-v5-global--icon--Color--light--light: #aaabac;
  --pf-v5-global--icon--Color--dark--light: #e0e0e0;
  --pf-v5-global--icon--Color--light--dark: #aaabac;
  --pf-v5-global--icon--Color--dark--dark: #e0e0e0;
  --pf-v5-global--BackgroundColor--light-100: #1b1d21;
  --pf-v5-global--BackgroundColor--light-200: #0f1214;
  --pf-v5-global--BackgroundColor--light-300: #26292d;
  --pf-v5-global--BackgroundColor--dark-100: #1b1d21;
  --pf-v5-global--BackgroundColor--dark-200: #0f1214;
  --pf-v5-global--BackgroundColor--dark-300: #26292d;
  --pf-v5-global--BackgroundColor--dark-400: #36373a;
  --pf-v5-global--BoxShadow--sm: 0 0.0625rem 0.125rem 0 rgba(3, 3, 3, 0.48), 0 0 0.125rem 0 rgba(3, 3, 3, 0.24);
  --pf-v5-global--BoxShadow--sm-top: 0 -0.125rem 0.25rem -0.0625rem rgba(3, 3, 3, 0.64);
  --pf-v5-global--BoxShadow--sm-right: 0.125rem 0 0.25rem -0.0625rem rgba(3, 3, 3, 0.64);
  --pf-v5-global--BoxShadow--sm-bottom: 0 0.125rem 0.25rem -0.0625rem rgba(3, 3, 3, 0.64);
  --pf-v5-global--BoxShadow--sm-left: -0.125rem 0 0.25rem -0.0625rem rgba(3, 3, 3, 0.64);
  --pf-v5-global--BoxShadow--md: 0 0.25rem 0.5rem 0rem rgba(3, 3, 3, 0.48), 0 0 0.25rem 0 rgba(3, 3, 3, 0.24);
  --pf-v5-global--BoxShadow--md-top: 0 -0.5rem 0.5rem -0.375rem rgba(3, 3, 3, 0.72);
  --pf-v5-global--BoxShadow--md-right: 0.5rem 0 0.5rem -0.375rem rgba(3, 3, 3, 0.72);
  --pf-v5-global--BoxShadow--md-bottom: 0 0.5rem 0.5rem -0.375rem rgba(3, 3, 3, 0.72);
  --pf-v5-global--BoxShadow--md-left: -0.5rem 0 0.5rem -0.375rem rgba(3, 3, 3, 0.72);
  --pf-v5-global--BoxShadow--lg: 0 0.5rem 1rem 0 rgba(3, 3, 3, 0.64), 0 0 0.375rem 0 rgba(3, 3, 3, 0.32);
  --pf-v5-global--BoxShadow--lg-top: 0 -0.75rem 0.75rem -0.5rem rgba(3, 3, 3, 0.72);
  --pf-v5-global--BoxShadow--lg-right: 0.75rem 0 0.75rem -0.5rem rgba(3, 3, 3, 0.72);
  --pf-v5-global--BoxShadow--lg-bottom: 0 0.75rem 0.75rem -0.5rem rgba(3, 3, 3, 0.72);
  --pf-v5-global--BoxShadow--lg-left: -0.75rem 0 0.75rem -0.5rem rgba(3, 3, 3, 0.72);
  --pf-v5-global--BoxShadow--xl: 0 1rem 2rem 0 rgba(3, 3, 3, 0.64), 0 0 0.5rem 0 rgba(3, 3, 3, 0.4);
  --pf-v5-global--BoxShadow--xl-top: 0 -1rem 1rem -0.5rem rgba(3, 3, 3, 0.8);
  --pf-v5-global--BoxShadow--xl-right: 1rem 0 1rem -0.5rem rgba(3, 3, 3, 0.8);
  --pf-v5-global--BoxShadow--xl-bottom: 0 1rem 1rem -0.5rem rgba(3, 3, 3, 0.8);
  --pf-v5-global--BoxShadow--xl-left: -1rem 0 1rem -0.5rem rgba(3, 3, 3, 0.8);
  --pf-v5-global--BoxShadow--inset: inset 0 0 0.625rem 0 #030303;
}

@font-face {
  font-family: RedHatDisplay;
  font-style: normal;
  font-weight: 400;
  src: url("~@patternfly/patternfly/assets/fonts/RedHatDisplay/RedHatDisplay-Medium.woff2") format("woff2");
  font-display: fallback;
}

@font-face {
  font-family: RedHatDisplay;
  font-style: italic;
  font-weight: 400;
  src: url("~@patternfly/patternfly/assets/fonts/RedHatDisplay/RedHatDisplay-MediumItalic.woff2") format("woff2");
  font-display: fallback;
}

@font-face {
  font-family: RedHatDisplay;
  font-style: normal;
  font-weight: 700;
  src: url("~@patternfly/patternfly/assets/fonts/RedHatDisplay/RedHatDisplay-Bold.woff2") format("woff2");
  font-display: fallback;
}

@font-face {
  font-family: RedHatDisplay;
  font-style: italic;
  font-weight: 700;
  src: url("~@patternfly/patternfly/assets/fonts/RedHatDisplay/RedHatDisplay-BoldItalic.woff2") format("woff2");
  font-display: fallback;
}

@font-face {
  font-family: RedHatText;
  font-style: normal;
  font-weight: 400;
  src: url("~@patternfly/patternfly/assets/fonts/RedHatText/RedHatText-Regular.woff2") format("woff2");
  font-display: fallback;
}

@font-face {
  font-family: RedHatText;
  font-style: italic;
  font-weight: 400;
  src: url("~@patternfly/patternfly/assets/fonts/RedHatText/RedHatText-Italic.woff2") format("woff2");
  font-display: fallback;
}

@font-face {
  font-family: RedHatText;
  font-style: normal;
  font-weight: 700;
  src: url("~@patternfly/patternfly/assets/fonts/RedHatText/RedHatText-Medium.woff2") format("woff2");
  font-display: fallback;
}

@font-face {
  font-family: RedHatText;
  font-style: italic;
  font-weight: 700;
  src: url("~@patternfly/patternfly/assets/fonts/RedHatText/RedHatText-MediumItalic.woff2") format("woff2");
  font-display: fallback;
}

@font-face {
  font-family: RedHatMono;
  font-style: normal;
  font-weight: 400;
  src: url("~@patternfly/patternfly/assets/fonts/RedHatMono/RedHatMono-Regular.woff2") format("woff2");
  font-display: fallback;
}

@font-face {
  font-family: RedHatMono;
  font-style: italic;
  font-weight: 400;
  src: url("~@patternfly/patternfly/assets/fonts/RedHatMono/RedHatMono-Italic.woff2") format("woff2");
  font-display: fallback;
}

@font-face {
  font-family: RedHatDisplayVF;
  font-style: normal;
  font-weight: 400 700;
  src: url("~@patternfly/patternfly/assets/fonts/RedHatDisplay/RedHatDisplayVF.woff2") format("woff2-variations");
  font-display: fallback;
}

@font-face {
  font-family: RedHatDisplayVF;
  font-style: italic;
  font-weight: 400 700;
  src: url("~@patternfly/patternfly/assets/fonts/RedHatDisplay/RedHatDisplayVF-Italic.woff2") format("woff2-variations");
  font-display: fallback;
}

@font-face {
  font-family: RedHatTextVF;
  font-style: normal;
  font-weight: 400 500;
  src: url("~@patternfly/patternfly/assets/fonts/RedHatText/RedHatTextVF.woff2") format("woff2-variations");
  font-display: fallback;
}

@font-face {
  font-family: RedHatTextVF;
  font-style: italic;
  font-weight: 400 500;
  src: url("~@patternfly/patternfly/assets/fonts/RedHatText/RedHatTextVF-Italic.woff2") format("woff2-variations");
  font-display: fallback;
}

@font-face {
  font-family: RedHatMonoVF;
  font-style: normal;
  font-weight: 400;
  src: url("~@patternfly/patternfly/assets/fonts/RedHatMono/RedHatMonoVF.woff2") format("woff2-variations");
  font-display: fallback;
}

@font-face {
  font-family: RedHatMonoVF;
  font-style: italic;
  font-weight: 400;
  src: url("~@patternfly/patternfly/assets/fonts/RedHatMono/RedHatMonoVF-Italic.woff2") format("woff2-variations");
  font-display: fallback;
}

.pf-v5-screen-reader {
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.pf-v5-screen-reader.pf-m-full-size {
  width: 100%;
  height: 100%;
}

.pf-v5-screen-reader.pf-m-absolute {
  position: absolute;
}

.pf-v5-m-tabular-nums {
  font-variant-numeric: tabular-nums;
}

.pf-v5-m-vf-font {
  --pf-v5-global--FontFamily--text: var(--pf-v5-global--FontFamily--text--vf);
  --pf-v5-global--FontFamily--heading: var(--pf-v5-global--FontFamily--heading--vf);
  --pf-v5-global--FontFamily--monospace: var(--pf-v5-global--FontFamily--monospace--vf);
}

.pf-v5-m-dir-rtl {
  --pf-v5-global--inverse--multiplier: -1;
  direction: rtl;
}

.pf-v5-m-dir-ltr {
  --pf-v5-global--inverse--multiplier: 1;
  direction: ltr;
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-m-mirror-inline-rtl {
  scale: -1 1;
}

:where(html,
    body,
    p,
    ol,
    ul,
    li,
    dl,
    dt,
    dd,
    blockquote,
    figure,
    fieldset,
    legend,
    textarea,
    pre,
    iframe,
    hr,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6) {
  padding: 0;
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:where(html,
    body) {
  height: 100%;
}

:where(body) {
  font-family: var(--pf-v5-global--FontFamily--text);
  font-size: var(--pf-v5-global--FontSize--md);
  font-weight: var(--pf-v5-global--FontWeight--normal);
  line-height: var(--pf-v5-global--LineHeight--md);
}

:where(h1,
    h2,
    h3,
    h4,
    h5,
    h6) {
  font-size: 100%;
  font-weight: var(--pf-v5-global--FontWeight--normal);
}

:where(ul) {
  list-style: none;
}

:where(button,
    input,
    optgroup,
    select,
    textarea) {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: var(--pf-v5-global--LineHeight--md);
  color: var(--pf-v5-global--Color--100);
}

:where(img,
    embed,
    iframe,
    object,
    audio,
    video) {
  max-width: 100%;
  height: auto;
}

:where(iframe) {
  border: 0;
}

:where(table) {
  border-spacing: 0;
  border-collapse: collapse;
}

:where(td,
    th) {
  padding: 0;
  text-align: start;
}

:where(code,
    pre) {
  font-family: var(--pf-v5-global--FontFamily--monospace);
}

:where(a) {
  font-weight: var(--pf-v5-global--link--FontWeight);
  color: var(--pf-v5-global--link--Color);
  text-decoration: var(--pf-v5-global--link--TextDecoration);
}

:where(a:hover) {
  --pf-v5-global--link--Color: var(--pf-v5-global--link--Color--hover);
  --pf-v5-global--link--TextDecoration: var(--pf-v5-global--link--TextDecoration--hover);
}

:where(a,
    button) {
  cursor: pointer;
}

:where(.pf-v5-theme-dark) {
  color-scheme: dark;
}

.pf-v5-t-dark.pf-m-transparent {
  background-color: transparent;
}

.pf-v5-t-dark.pf-m-transparent-100 {
  background-color: rgba(3,3,3,.42);
}

.pf-v5-t-dark.pf-m-transparent-200 {
  background-color: rgba(3,3,3,.6);
}

.pf-v5-t-dark.pf-m-opaque-100 {
  background-color: #1b1d21;
}

.pf-v5-t-dark.pf-m-opaque-200 {
  background-color: #0f1214;
}

.pf-v5-t-light.pf-m-transparent {
  background-color: transparent;
}

.pf-v5-t-light.pf-m-opaque-100 {
  background-color: #fff;
}

.pf-v5-t-light.pf-m-opaque-200 {
  background-color: #c6c7c8;
}

.pf-v5-t-light.pf-m-opaque-300 {
  background-color: #aaabac;
}

* .fa,
* .fas,
* .far,
* .fal,
* .fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

* .fa-lg {
  font-size: 1.3333333333em;
  line-height: .75em;
  vertical-align: -0.0667em;
}

* .fa-xs {
  font-size: .75em;
}

* .fa-sm {
  font-size: .875em;
}

* .fa-1x {
  font-size: 1em;
}

* .fa-2x {
  font-size: 2em;
}

* .fa-3x {
  font-size: 3em;
}

* .fa-4x {
  font-size: 4em;
}

* .fa-5x {
  font-size: 5em;
}

* .fa-6x {
  font-size: 6em;
}

* .fa-7x {
  font-size: 7em;
}

* .fa-8x {
  font-size: 8em;
}

* .fa-9x {
  font-size: 9em;
}

* .fa-10x {
  font-size: 10em;
}

* .fa-fw {
  text-align: center;
  width: 1.25em;
}

* .fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}

* .fa-ul>li {
  position: relative;
}

* .fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}

* .fa-border {
  border: solid .08em #eee;
  border-radius: .1em;
  padding: .2em .25em .15em;
}

* .fa-pull-left {
  float: left;
}

* .fa-pull-right {
  float: right;
}

* .fa.fa-pull-left,
* .fas.fa-pull-left,
* .far.fa-pull-left,
* .fal.fa-pull-left,
* .fab.fa-pull-left {
  margin-right: .3em;
}

* .fa.fa-pull-right,
* .fas.fa-pull-right,
* .far.fa-pull-right,
* .fal.fa-pull-right,
* .fab.fa-pull-right {
  margin-left: .3em;
}

* .fa-spin {
  animation: fa-spin 2s infinite linear;
}

* .fa-pulse {
  animation: fa-spin 1s infinite steps(8);
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

* .fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg);
}

* .fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg);
}

* .fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg);
}

* .fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scale(-1, 1);
}

* .fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(1, -1);
}

* .fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(-1, -1);
}

* :root .fa-rotate-90,
* :root .fa-rotate-180,
* :root .fa-rotate-270,
* :root .fa-flip-horizontal,
* :root .fa-flip-vertical {
  filter: none;
}

* .fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}

* .fa-stack-1x,
* .fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

* .fa-stack-1x {
  line-height: inherit;
}

* .fa-stack-2x {
  font-size: 2em;
}

* .fa-inverse {
  color: #fff;
}

* .fa-500px:before {
  content: "";
}

* .fa-accessible-icon:before {
  content: "";
}

* .fa-accusoft:before {
  content: "";
}

* .fa-acquisitions-incorporated:before {
  content: "";
}

* .fa-ad:before {
  content: "";
}

* .fa-address-book:before {
  content: "";
}

* .fa-address-card:before {
  content: "";
}

* .fa-adjust:before {
  content: "";
}

* .fa-adn:before {
  content: "";
}

* .fa-adobe:before {
  content: "";
}

* .fa-adversal:before {
  content: "";
}

* .fa-affiliatetheme:before {
  content: "";
}

* .fa-air-freshener:before {
  content: "";
}

* .fa-algolia:before {
  content: "";
}

* .fa-align-center:before {
  content: "";
}

* .fa-align-justify:before {
  content: "";
}

* .fa-align-left:before {
  content: "";
}

* .fa-align-right:before {
  content: "";
}

* .fa-alipay:before {
  content: "";
}

* .fa-allergies:before {
  content: "";
}

* .fa-amazon:before {
  content: "";
}

* .fa-amazon-pay:before {
  content: "";
}

* .fa-ambulance:before {
  content: "";
}

* .fa-american-sign-language-interpreting:before {
  content: "";
}

* .fa-amilia:before {
  content: "";
}

* .fa-anchor:before {
  content: "";
}

* .fa-android:before {
  content: "";
}

* .fa-angellist:before {
  content: "";
}

* .fa-angle-double-down:before {
  content: "";
}

* .fa-angle-double-left:before {
  content: "";
}

* .fa-angle-double-right:before {
  content: "";
}

* .fa-angle-double-up:before {
  content: "";
}

* .fa-angle-down:before {
  content: "";
}

* .fa-angle-left:before {
  content: "";
}

* .fa-angle-right:before {
  content: "";
}

* .fa-angle-up:before {
  content: "";
}

* .fa-angry:before {
  content: "";
}

* .fa-angrycreative:before {
  content: "";
}

* .fa-angular:before {
  content: "";
}

* .fa-ankh:before {
  content: "";
}

* .fa-app-store:before {
  content: "";
}

* .fa-app-store-ios:before {
  content: "";
}

* .fa-apper:before {
  content: "";
}

* .fa-apple:before {
  content: "";
}

* .fa-apple-alt:before {
  content: "";
}

* .fa-apple-pay:before {
  content: "";
}

* .fa-archive:before {
  content: "";
}

* .fa-archway:before {
  content: "";
}

* .fa-arrow-alt-circle-down:before {
  content: "";
}

* .fa-arrow-alt-circle-left:before {
  content: "";
}

* .fa-arrow-alt-circle-right:before {
  content: "";
}

* .fa-arrow-alt-circle-up:before {
  content: "";
}

* .fa-arrow-circle-down:before {
  content: "";
}

* .fa-arrow-circle-left:before {
  content: "";
}

* .fa-arrow-circle-right:before {
  content: "";
}

* .fa-arrow-circle-up:before {
  content: "";
}

* .fa-arrow-down:before {
  content: "";
}

* .fa-arrow-left:before {
  content: "";
}

* .fa-arrow-right:before {
  content: "";
}

* .fa-arrow-up:before {
  content: "";
}

* .fa-arrows-alt:before {
  content: "";
}

* .fa-arrows-alt-h:before {
  content: "";
}

* .fa-arrows-alt-v:before {
  content: "";
}

* .fa-artstation:before {
  content: "";
}

* .fa-assistive-listening-systems:before {
  content: "";
}

* .fa-asterisk:before {
  content: "";
}

* .fa-asymmetrik:before {
  content: "";
}

* .fa-at:before {
  content: "";
}

* .fa-atlas:before {
  content: "";
}

* .fa-atlassian:before {
  content: "";
}

* .fa-atom:before {
  content: "";
}

* .fa-audible:before {
  content: "";
}

* .fa-audio-description:before {
  content: "";
}

* .fa-autoprefixer:before {
  content: "";
}

* .fa-avianex:before {
  content: "";
}

* .fa-aviato:before {
  content: "";
}

* .fa-award:before {
  content: "";
}

* .fa-aws:before {
  content: "";
}

* .fa-baby:before {
  content: "";
}

* .fa-baby-carriage:before {
  content: "";
}

* .fa-backspace:before {
  content: "";
}

* .fa-backward:before {
  content: "";
}

* .fa-balance-scale:before {
  content: "";
}

* .fa-ban:before {
  content: "";
}

* .fa-band-aid:before {
  content: "";
}

* .fa-bandcamp:before {
  content: "";
}

* .fa-barcode:before {
  content: "";
}

* .fa-bars:before {
  content: "";
}

* .fa-baseball-ball:before {
  content: "";
}

* .fa-basketball-ball:before {
  content: "";
}

* .fa-bath:before {
  content: "";
}

* .fa-battery-empty:before {
  content: "";
}

* .fa-battery-full:before {
  content: "";
}

* .fa-battery-half:before {
  content: "";
}

* .fa-battery-quarter:before {
  content: "";
}

* .fa-battery-three-quarters:before {
  content: "";
}

* .fa-bed:before {
  content: "";
}

* .fa-beer:before {
  content: "";
}

* .fa-behance:before {
  content: "";
}

* .fa-behance-square:before {
  content: "";
}

* .fa-bell:before {
  content: "";
}

* .fa-bell-slash:before {
  content: "";
}

* .fa-bezier-curve:before {
  content: "";
}

* .fa-bible:before {
  content: "";
}

* .fa-bicycle:before {
  content: "";
}

* .fa-bimobject:before {
  content: "";
}

* .fa-binoculars:before {
  content: "";
}

* .fa-biohazard:before {
  content: "";
}

* .fa-birthday-cake:before {
  content: "";
}

* .fa-bitbucket:before {
  content: "";
}

* .fa-bitcoin:before {
  content: "";
}

* .fa-bity:before {
  content: "";
}

* .fa-black-tie:before {
  content: "";
}

* .fa-blackberry:before {
  content: "";
}

* .fa-blender:before {
  content: "";
}

* .fa-blender-phone:before {
  content: "";
}

* .fa-blind:before {
  content: "";
}

* .fa-blog:before {
  content: "";
}

* .fa-blogger:before {
  content: "";
}

* .fa-blogger-b:before {
  content: "";
}

* .fa-bluetooth:before {
  content: "";
}

* .fa-bluetooth-b:before {
  content: "";
}

* .fa-bold:before {
  content: "";
}

* .fa-bolt:before {
  content: "";
}

* .fa-bomb:before {
  content: "";
}

* .fa-bone:before {
  content: "";
}

* .fa-bong:before {
  content: "";
}

* .fa-book:before {
  content: "";
}

* .fa-book-dead:before {
  content: "";
}

* .fa-book-open:before {
  content: "";
}

* .fa-book-reader:before {
  content: "";
}

* .fa-bookmark:before {
  content: "";
}

* .fa-bowling-ball:before {
  content: "";
}

* .fa-box:before {
  content: "";
}

* .fa-box-open:before {
  content: "";
}

* .fa-boxes:before {
  content: "";
}

* .fa-braille:before {
  content: "";
}

* .fa-brain:before {
  content: "";
}

* .fa-briefcase:before {
  content: "";
}

* .fa-briefcase-medical:before {
  content: "";
}

* .fa-broadcast-tower:before {
  content: "";
}

* .fa-broom:before {
  content: "";
}

* .fa-brush:before {
  content: "";
}

* .fa-btc:before {
  content: "";
}

* .fa-bug:before {
  content: "";
}

* .fa-building:before {
  content: "";
}

* .fa-bullhorn:before {
  content: "";
}

* .fa-bullseye:before {
  content: "";
}

* .fa-burn:before {
  content: "";
}

* .fa-buromobelexperte:before {
  content: "";
}

* .fa-bus:before {
  content: "";
}

* .fa-bus-alt:before {
  content: "";
}

* .fa-business-time:before {
  content: "";
}

* .fa-buysellads:before {
  content: "";
}

* .fa-calculator:before {
  content: "";
}

* .fa-calendar:before {
  content: "";
}

* .fa-calendar-alt:before {
  content: "";
}

* .fa-calendar-check:before {
  content: "";
}

* .fa-calendar-day:before {
  content: "";
}

* .fa-calendar-minus:before {
  content: "";
}

* .fa-calendar-plus:before {
  content: "";
}

* .fa-calendar-times:before {
  content: "";
}

* .fa-calendar-week:before {
  content: "";
}

* .fa-camera:before {
  content: "";
}

* .fa-camera-retro:before {
  content: "";
}

* .fa-campground:before {
  content: "";
}

* .fa-canadian-maple-leaf:before {
  content: "";
}

* .fa-candy-cane:before {
  content: "";
}

* .fa-cannabis:before {
  content: "";
}

* .fa-capsules:before {
  content: "";
}

* .fa-car:before {
  content: "";
}

* .fa-car-alt:before {
  content: "";
}

* .fa-car-battery:before {
  content: "";
}

* .fa-car-crash:before {
  content: "";
}

* .fa-car-side:before {
  content: "";
}

* .fa-caret-down:before {
  content: "";
}

* .fa-caret-left:before {
  content: "";
}

* .fa-caret-right:before {
  content: "";
}

* .fa-caret-square-down:before {
  content: "";
}

* .fa-caret-square-left:before {
  content: "";
}

* .fa-caret-square-right:before {
  content: "";
}

* .fa-caret-square-up:before {
  content: "";
}

* .fa-caret-up:before {
  content: "";
}

* .fa-carrot:before {
  content: "";
}

* .fa-cart-arrow-down:before {
  content: "";
}

* .fa-cart-plus:before {
  content: "";
}

* .fa-cash-register:before {
  content: "";
}

* .fa-cat:before {
  content: "";
}

* .fa-cc-amazon-pay:before {
  content: "";
}

* .fa-cc-amex:before {
  content: "";
}

* .fa-cc-apple-pay:before {
  content: "";
}

* .fa-cc-diners-club:before {
  content: "";
}

* .fa-cc-discover:before {
  content: "";
}

* .fa-cc-jcb:before {
  content: "";
}

* .fa-cc-mastercard:before {
  content: "";
}

* .fa-cc-paypal:before {
  content: "";
}

* .fa-cc-stripe:before {
  content: "";
}

* .fa-cc-visa:before {
  content: "";
}

* .fa-centercode:before {
  content: "";
}

* .fa-centos:before {
  content: "";
}

* .fa-certificate:before {
  content: "";
}

* .fa-chair:before {
  content: "";
}

* .fa-chalkboard:before {
  content: "";
}

* .fa-chalkboard-teacher:before {
  content: "";
}

* .fa-charging-station:before {
  content: "";
}

* .fa-chart-area:before {
  content: "";
}

* .fa-chart-bar:before {
  content: "";
}

* .fa-chart-line:before {
  content: "";
}

* .fa-chart-pie:before {
  content: "";
}

* .fa-check:before {
  content: "";
}

* .fa-check-circle:before {
  content: "";
}

* .fa-check-double:before {
  content: "";
}

* .fa-check-square:before {
  content: "";
}

* .fa-chess:before {
  content: "";
}

* .fa-chess-bishop:before {
  content: "";
}

* .fa-chess-board:before {
  content: "";
}

* .fa-chess-king:before {
  content: "";
}

* .fa-chess-knight:before {
  content: "";
}

* .fa-chess-pawn:before {
  content: "";
}

* .fa-chess-queen:before {
  content: "";
}

* .fa-chess-rook:before {
  content: "";
}

* .fa-chevron-circle-down:before {
  content: "";
}

* .fa-chevron-circle-left:before {
  content: "";
}

* .fa-chevron-circle-right:before {
  content: "";
}

* .fa-chevron-circle-up:before {
  content: "";
}

* .fa-chevron-down:before {
  content: "";
}

* .fa-chevron-left:before {
  content: "";
}

* .fa-chevron-right:before {
  content: "";
}

* .fa-chevron-up:before {
  content: "";
}

* .fa-child:before {
  content: "";
}

* .fa-chrome:before {
  content: "";
}

* .fa-church:before {
  content: "";
}

* .fa-circle:before {
  content: "";
}

* .fa-circle-notch:before {
  content: "";
}

* .fa-city:before {
  content: "";
}

* .fa-clipboard:before {
  content: "";
}

* .fa-clipboard-check:before {
  content: "";
}

* .fa-clipboard-list:before {
  content: "";
}

* .fa-clock:before {
  content: "";
}

* .fa-clone:before {
  content: "";
}

* .fa-closed-captioning:before {
  content: "";
}

* .fa-cloud:before {
  content: "";
}

* .fa-cloud-download-alt:before {
  content: "";
}

* .fa-cloud-meatball:before {
  content: "";
}

* .fa-cloud-moon:before {
  content: "";
}

* .fa-cloud-moon-rain:before {
  content: "";
}

* .fa-cloud-rain:before {
  content: "";
}

* .fa-cloud-showers-heavy:before {
  content: "";
}

* .fa-cloud-sun:before {
  content: "";
}

* .fa-cloud-sun-rain:before {
  content: "";
}

* .fa-cloud-upload-alt:before {
  content: "";
}

* .fa-cloudscale:before {
  content: "";
}

* .fa-cloudsmith:before {
  content: "";
}

* .fa-cloudversify:before {
  content: "";
}

* .fa-cocktail:before {
  content: "";
}

* .fa-code:before {
  content: "";
}

* .fa-code-branch:before {
  content: "";
}

* .fa-codepen:before {
  content: "";
}

* .fa-codiepie:before {
  content: "";
}

* .fa-coffee:before {
  content: "";
}

* .fa-cog:before {
  content: "";
}

* .fa-cogs:before {
  content: "";
}

* .fa-coins:before {
  content: "";
}

* .fa-columns:before {
  content: "";
}

* .fa-comment:before {
  content: "";
}

* .fa-comment-alt:before {
  content: "";
}

* .fa-comment-dollar:before {
  content: "";
}

* .fa-comment-dots:before {
  content: "";
}

* .fa-comment-slash:before {
  content: "";
}

* .fa-comments:before {
  content: "";
}

* .fa-comments-dollar:before {
  content: "";
}

* .fa-compact-disc:before {
  content: "";
}

* .fa-compass:before {
  content: "";
}

* .fa-compress:before {
  content: "";
}

* .fa-compress-arrows-alt:before {
  content: "";
}

* .fa-concierge-bell:before {
  content: "";
}

* .fa-confluence:before {
  content: "";
}

* .fa-connectdevelop:before {
  content: "";
}

* .fa-contao:before {
  content: "";
}

* .fa-cookie:before {
  content: "";
}

* .fa-cookie-bite:before {
  content: "";
}

* .fa-copy:before {
  content: "";
}

* .fa-copyright:before {
  content: "";
}

* .fa-couch:before {
  content: "";
}

* .fa-cpanel:before {
  content: "";
}

* .fa-creative-commons:before {
  content: "";
}

* .fa-creative-commons-by:before {
  content: "";
}

* .fa-creative-commons-nc:before {
  content: "";
}

* .fa-creative-commons-nc-eu:before {
  content: "";
}

* .fa-creative-commons-nc-jp:before {
  content: "";
}

* .fa-creative-commons-nd:before {
  content: "";
}

* .fa-creative-commons-pd:before {
  content: "";
}

* .fa-creative-commons-pd-alt:before {
  content: "";
}

* .fa-creative-commons-remix:before {
  content: "";
}

* .fa-creative-commons-sa:before {
  content: "";
}

* .fa-creative-commons-sampling:before {
  content: "";
}

* .fa-creative-commons-sampling-plus:before {
  content: "";
}

* .fa-creative-commons-share:before {
  content: "";
}

* .fa-creative-commons-zero:before {
  content: "";
}

* .fa-credit-card:before {
  content: "";
}

* .fa-critical-role:before {
  content: "";
}

* .fa-crop:before {
  content: "";
}

* .fa-crop-alt:before {
  content: "";
}

* .fa-cross:before {
  content: "";
}

* .fa-crosshairs:before {
  content: "";
}

* .fa-crow:before {
  content: "";
}

* .fa-crown:before {
  content: "";
}

* .fa-css3:before {
  content: "";
}

* .fa-css3-alt:before {
  content: "";
}

* .fa-cube:before {
  content: "";
}

* .fa-cubes:before {
  content: "";
}

* .fa-cut:before {
  content: "";
}

* .fa-cuttlefish:before {
  content: "";
}

* .fa-d-and-d:before {
  content: "";
}

* .fa-d-and-d-beyond:before {
  content: "";
}

* .fa-dashcube:before {
  content: "";
}

* .fa-database:before {
  content: "";
}

* .fa-deaf:before {
  content: "";
}

* .fa-delicious:before {
  content: "";
}

* .fa-democrat:before {
  content: "";
}

* .fa-deploydog:before {
  content: "";
}

* .fa-deskpro:before {
  content: "";
}

* .fa-desktop:before {
  content: "";
}

* .fa-dev:before {
  content: "";
}

* .fa-deviantart:before {
  content: "";
}

* .fa-dharmachakra:before {
  content: "";
}

* .fa-dhl:before {
  content: "";
}

* .fa-diagnoses:before {
  content: "";
}

* .fa-diaspora:before {
  content: "";
}

* .fa-dice:before {
  content: "";
}

* .fa-dice-d20:before {
  content: "";
}

* .fa-dice-d6:before {
  content: "";
}

* .fa-dice-five:before {
  content: "";
}

* .fa-dice-four:before {
  content: "";
}

* .fa-dice-one:before {
  content: "";
}

* .fa-dice-six:before {
  content: "";
}

* .fa-dice-three:before {
  content: "";
}

* .fa-dice-two:before {
  content: "";
}

* .fa-digg:before {
  content: "";
}

* .fa-digital-ocean:before {
  content: "";
}

* .fa-digital-tachograph:before {
  content: "";
}

* .fa-directions:before {
  content: "";
}

* .fa-discord:before {
  content: "";
}

* .fa-discourse:before {
  content: "";
}

* .fa-divide:before {
  content: "";
}

* .fa-dizzy:before {
  content: "";
}

* .fa-dna:before {
  content: "";
}

* .fa-dochub:before {
  content: "";
}

* .fa-docker:before {
  content: "";
}

* .fa-dog:before {
  content: "";
}

* .fa-dollar-sign:before {
  content: "";
}

* .fa-dolly:before {
  content: "";
}

* .fa-dolly-flatbed:before {
  content: "";
}

* .fa-donate:before {
  content: "";
}

* .fa-door-closed:before {
  content: "";
}

* .fa-door-open:before {
  content: "";
}

* .fa-dot-circle:before {
  content: "";
}

* .fa-dove:before {
  content: "";
}

* .fa-download:before {
  content: "";
}

* .fa-draft2digital:before {
  content: "";
}

* .fa-drafting-compass:before {
  content: "";
}

* .fa-dragon:before {
  content: "";
}

* .fa-draw-polygon:before {
  content: "";
}

* .fa-dribbble:before {
  content: "";
}

* .fa-dribbble-square:before {
  content: "";
}

* .fa-dropbox:before {
  content: "";
}

* .fa-drum:before {
  content: "";
}

* .fa-drum-steelpan:before {
  content: "";
}

* .fa-drumstick-bite:before {
  content: "";
}

* .fa-drupal:before {
  content: "";
}

* .fa-dumbbell:before {
  content: "";
}

* .fa-dumpster:before {
  content: "";
}

* .fa-dumpster-fire:before {
  content: "";
}

* .fa-dungeon:before {
  content: "";
}

* .fa-dyalog:before {
  content: "";
}

* .fa-earlybirds:before {
  content: "";
}

* .fa-ebay:before {
  content: "";
}

* .fa-edge:before {
  content: "";
}

* .fa-edit:before {
  content: "";
}

* .fa-eject:before {
  content: "";
}

* .fa-elementor:before {
  content: "";
}

* .fa-ellipsis-h:before {
  content: "";
}

* .fa-ellipsis-v:before {
  content: "";
}

* .fa-ello:before {
  content: "";
}

* .fa-ember:before {
  content: "";
}

* .fa-empire:before {
  content: "";
}

* .fa-envelope:before {
  content: "";
}

* .fa-envelope-open:before {
  content: "";
}

* .fa-envelope-open-text:before {
  content: "";
}

* .fa-envelope-square:before {
  content: "";
}

* .fa-envira:before {
  content: "";
}

* .fa-equals:before {
  content: "";
}

* .fa-eraser:before {
  content: "";
}

* .fa-erlang:before {
  content: "";
}

* .fa-ethereum:before {
  content: "";
}

* .fa-ethernet:before {
  content: "";
}

* .fa-etsy:before {
  content: "";
}

* .fa-euro-sign:before {
  content: "";
}

* .fa-exchange-alt:before {
  content: "";
}

* .fa-exclamation:before {
  content: "";
}

* .fa-exclamation-circle:before {
  content: "";
}

* .fa-exclamation-triangle:before {
  content: "";
}

* .fa-expand:before {
  content: "";
}

* .fa-expand-arrows-alt:before {
  content: "";
}

* .fa-expeditedssl:before {
  content: "";
}

* .fa-external-link-alt:before {
  content: "";
}

* .fa-external-link-square-alt:before {
  content: "";
}

* .fa-eye:before {
  content: "";
}

* .fa-eye-dropper:before {
  content: "";
}

* .fa-eye-slash:before {
  content: "";
}

* .fa-facebook:before {
  content: "";
}

* .fa-facebook-f:before {
  content: "";
}

* .fa-facebook-messenger:before {
  content: "";
}

* .fa-facebook-square:before {
  content: "";
}

* .fa-fantasy-flight-games:before {
  content: "";
}

* .fa-fast-backward:before {
  content: "";
}

* .fa-fast-forward:before {
  content: "";
}

* .fa-fax:before {
  content: "";
}

* .fa-feather:before {
  content: "";
}

* .fa-feather-alt:before {
  content: "";
}

* .fa-fedex:before {
  content: "";
}

* .fa-fedora:before {
  content: "";
}

* .fa-female:before {
  content: "";
}

* .fa-fighter-jet:before {
  content: "";
}

* .fa-figma:before {
  content: "";
}

* .fa-file:before {
  content: "";
}

* .fa-file-alt:before {
  content: "";
}

* .fa-file-archive:before {
  content: "";
}

* .fa-file-audio:before {
  content: "";
}

* .fa-file-code:before {
  content: "";
}

* .fa-file-contract:before {
  content: "";
}

* .fa-file-csv:before {
  content: "";
}

* .fa-file-download:before {
  content: "";
}

* .fa-file-excel:before {
  content: "";
}

* .fa-file-export:before {
  content: "";
}

* .fa-file-image:before {
  content: "";
}

* .fa-file-import:before {
  content: "";
}

* .fa-file-invoice:before {
  content: "";
}

* .fa-file-invoice-dollar:before {
  content: "";
}

* .fa-file-medical:before {
  content: "";
}

* .fa-file-medical-alt:before {
  content: "";
}

* .fa-file-pdf:before {
  content: "";
}

* .fa-file-powerpoint:before {
  content: "";
}

* .fa-file-prescription:before {
  content: "";
}

* .fa-file-signature:before {
  content: "";
}

* .fa-file-upload:before {
  content: "";
}

* .fa-file-video:before {
  content: "";
}

* .fa-file-word:before {
  content: "";
}

* .fa-fill:before {
  content: "";
}

* .fa-fill-drip:before {
  content: "";
}

* .fa-film:before {
  content: "";
}

* .fa-filter:before {
  content: "";
}

* .fa-fingerprint:before {
  content: "";
}

* .fa-fire:before {
  content: "";
}

* .fa-fire-alt:before {
  content: "";
}

* .fa-fire-extinguisher:before {
  content: "";
}

* .fa-firefox:before {
  content: "";
}

* .fa-first-aid:before {
  content: "";
}

* .fa-first-order:before {
  content: "";
}

* .fa-first-order-alt:before {
  content: "";
}

* .fa-firstdraft:before {
  content: "";
}

* .fa-fish:before {
  content: "";
}

* .fa-fist-raised:before {
  content: "";
}

* .fa-flag:before {
  content: "";
}

* .fa-flag-checkered:before {
  content: "";
}

* .fa-flag-usa:before {
  content: "";
}

* .fa-flask:before {
  content: "";
}

* .fa-flickr:before {
  content: "";
}

* .fa-flipboard:before {
  content: "";
}

* .fa-flushed:before {
  content: "";
}

* .fa-fly:before {
  content: "";
}

* .fa-folder:before {
  content: "";
}

* .fa-folder-minus:before {
  content: "";
}

* .fa-folder-open:before {
  content: "";
}

* .fa-folder-plus:before {
  content: "";
}

* .fa-font:before {
  content: "";
}

* .fa-font-awesome:before {
  content: "";
}

* .fa-font-awesome-alt:before {
  content: "";
}

* .fa-font-awesome-flag:before {
  content: "";
}

* .fa-font-awesome-logo-full:before {
  content: "";
}

* .fa-fonticons:before {
  content: "";
}

* .fa-fonticons-fi:before {
  content: "";
}

* .fa-football-ball:before {
  content: "";
}

* .fa-fort-awesome:before {
  content: "";
}

* .fa-fort-awesome-alt:before {
  content: "";
}

* .fa-forumbee:before {
  content: "";
}

* .fa-forward:before {
  content: "";
}

* .fa-foursquare:before {
  content: "";
}

* .fa-free-code-camp:before {
  content: "";
}

* .fa-freebsd:before {
  content: "";
}

* .fa-frog:before {
  content: "";
}

* .fa-frown:before {
  content: "";
}

* .fa-frown-open:before {
  content: "";
}

* .fa-fulcrum:before {
  content: "";
}

* .fa-funnel-dollar:before {
  content: "";
}

* .fa-futbol:before {
  content: "";
}

* .fa-galactic-republic:before {
  content: "";
}

* .fa-galactic-senate:before {
  content: "";
}

* .fa-gamepad:before {
  content: "";
}

* .fa-gas-pump:before {
  content: "";
}

* .fa-gavel:before {
  content: "";
}

* .fa-gem:before {
  content: "";
}

* .fa-genderless:before {
  content: "";
}

* .fa-get-pocket:before {
  content: "";
}

* .fa-gg:before {
  content: "";
}

* .fa-gg-circle:before {
  content: "";
}

* .fa-ghost:before {
  content: "";
}

* .fa-gift:before {
  content: "";
}

* .fa-gifts:before {
  content: "";
}

* .fa-git:before {
  content: "";
}

* .fa-git-square:before {
  content: "";
}

* .fa-github:before {
  content: "";
}

* .fa-github-alt:before {
  content: "";
}

* .fa-github-square:before {
  content: "";
}

* .fa-gitkraken:before {
  content: "";
}

* .fa-gitlab:before {
  content: "";
}

* .fa-gitter:before {
  content: "";
}

* .fa-glass-cheers:before {
  content: "";
}

* .fa-glass-martini:before {
  content: "";
}

* .fa-glass-martini-alt:before {
  content: "";
}

* .fa-glass-whiskey:before {
  content: "";
}

* .fa-glasses:before {
  content: "";
}

* .fa-glide:before {
  content: "";
}

* .fa-glide-g:before {
  content: "";
}

* .fa-globe:before {
  content: "";
}

* .fa-globe-africa:before {
  content: "";
}

* .fa-globe-americas:before {
  content: "";
}

* .fa-globe-asia:before {
  content: "";
}

* .fa-globe-europe:before {
  content: "";
}

* .fa-gofore:before {
  content: "";
}

* .fa-golf-ball:before {
  content: "";
}

* .fa-goodreads:before {
  content: "";
}

* .fa-goodreads-g:before {
  content: "";
}

* .fa-google:before {
  content: "";
}

* .fa-google-drive:before {
  content: "";
}

* .fa-google-play:before {
  content: "";
}

* .fa-google-plus:before {
  content: "";
}

* .fa-google-plus-g:before {
  content: "";
}

* .fa-google-plus-square:before {
  content: "";
}

* .fa-google-wallet:before {
  content: "";
}

* .fa-gopuram:before {
  content: "";
}

* .fa-graduation-cap:before {
  content: "";
}

* .fa-gratipay:before {
  content: "";
}

* .fa-grav:before {
  content: "";
}

* .fa-greater-than:before {
  content: "";
}

* .fa-greater-than-equal:before {
  content: "";
}

* .fa-grimace:before {
  content: "";
}

* .fa-grin:before {
  content: "";
}

* .fa-grin-alt:before {
  content: "";
}

* .fa-grin-beam:before {
  content: "";
}

* .fa-grin-beam-sweat:before {
  content: "";
}

* .fa-grin-hearts:before {
  content: "";
}

* .fa-grin-squint:before {
  content: "";
}

* .fa-grin-squint-tears:before {
  content: "";
}

* .fa-grin-stars:before {
  content: "";
}

* .fa-grin-tears:before {
  content: "";
}

* .fa-grin-tongue:before {
  content: "";
}

* .fa-grin-tongue-squint:before {
  content: "";
}

* .fa-grin-tongue-wink:before {
  content: "";
}

* .fa-grin-wink:before {
  content: "";
}

* .fa-grip-horizontal:before {
  content: "";
}

* .fa-grip-lines:before {
  content: "";
}

* .fa-grip-lines-vertical:before {
  content: "";
}

* .fa-grip-vertical:before {
  content: "";
}

* .fa-gripfire:before {
  content: "";
}

* .fa-grunt:before {
  content: "";
}

* .fa-guitar:before {
  content: "";
}

* .fa-gulp:before {
  content: "";
}

* .fa-h-square:before {
  content: "";
}

* .fa-hacker-news:before {
  content: "";
}

* .fa-hacker-news-square:before {
  content: "";
}

* .fa-hackerrank:before {
  content: "";
}

* .fa-hammer:before {
  content: "";
}

* .fa-hamsa:before {
  content: "";
}

* .fa-hand-holding:before {
  content: "";
}

* .fa-hand-holding-heart:before {
  content: "";
}

* .fa-hand-holding-usd:before {
  content: "";
}

* .fa-hand-lizard:before {
  content: "";
}

* .fa-hand-paper:before {
  content: "";
}

* .fa-hand-peace:before {
  content: "";
}

* .fa-hand-point-down:before {
  content: "";
}

* .fa-hand-point-left:before {
  content: "";
}

* .fa-hand-point-right:before {
  content: "";
}

* .fa-hand-point-up:before {
  content: "";
}

* .fa-hand-pointer:before {
  content: "";
}

* .fa-hand-rock:before {
  content: "";
}

* .fa-hand-scissors:before {
  content: "";
}

* .fa-hand-spock:before {
  content: "";
}

* .fa-hands:before {
  content: "";
}

* .fa-hands-helping:before {
  content: "";
}

* .fa-handshake:before {
  content: "";
}

* .fa-hanukiah:before {
  content: "";
}

* .fa-hashtag:before {
  content: "";
}

* .fa-hat-wizard:before {
  content: "";
}

* .fa-haykal:before {
  content: "";
}

* .fa-hdd:before {
  content: "";
}

* .fa-heading:before {
  content: "";
}

* .fa-headphones:before {
  content: "";
}

* .fa-headphones-alt:before {
  content: "";
}

* .fa-headset:before {
  content: "";
}

* .fa-heart:before {
  content: "";
}

* .fa-heart-broken:before {
  content: "";
}

* .fa-heartbeat:before {
  content: "";
}

* .fa-helicopter:before {
  content: "";
}

* .fa-highlighter:before {
  content: "";
}

* .fa-hiking:before {
  content: "";
}

* .fa-hippo:before {
  content: "";
}

* .fa-hips:before {
  content: "";
}

* .fa-hire-a-helper:before {
  content: "";
}

* .fa-history:before {
  content: "";
}

* .fa-hockey-puck:before {
  content: "";
}

* .fa-holly-berry:before {
  content: "";
}

* .fa-home:before {
  content: "";
}

* .fa-hooli:before {
  content: "";
}

* .fa-hornbill:before {
  content: "";
}

* .fa-horse:before {
  content: "";
}

* .fa-horse-head:before {
  content: "";
}

* .fa-hospital:before {
  content: "";
}

* .fa-hospital-alt:before {
  content: "";
}

* .fa-hospital-symbol:before {
  content: "";
}

* .fa-hot-tub:before {
  content: "";
}

* .fa-hotel:before {
  content: "";
}

* .fa-hotjar:before {
  content: "";
}

* .fa-hourglass:before {
  content: "";
}

* .fa-hourglass-end:before {
  content: "";
}

* .fa-hourglass-half:before {
  content: "";
}

* .fa-hourglass-start:before {
  content: "";
}

* .fa-house-damage:before {
  content: "";
}

* .fa-houzz:before {
  content: "";
}

* .fa-hryvnia:before {
  content: "";
}

* .fa-html5:before {
  content: "";
}

* .fa-hubspot:before {
  content: "";
}

* .fa-i-cursor:before {
  content: "";
}

* .fa-icicles:before {
  content: "";
}

* .fa-id-badge:before {
  content: "";
}

* .fa-id-card:before {
  content: "";
}

* .fa-id-card-alt:before {
  content: "";
}

* .fa-igloo:before {
  content: "";
}

* .fa-image:before {
  content: "";
}

* .fa-images:before {
  content: "";
}

* .fa-imdb:before {
  content: "";
}

* .fa-inbox:before {
  content: "";
}

* .fa-indent:before {
  content: "";
}

* .fa-industry:before {
  content: "";
}

* .fa-infinity:before {
  content: "";
}

* .fa-info:before {
  content: "";
}

* .fa-info-circle:before {
  content: "";
}

* .fa-instagram:before {
  content: "";
}

* .fa-intercom:before {
  content: "";
}

* .fa-internet-explorer:before {
  content: "";
}

* .fa-invision:before {
  content: "";
}

* .fa-ioxhost:before {
  content: "";
}

* .fa-italic:before {
  content: "";
}

* .fa-itunes:before {
  content: "";
}

* .fa-itunes-note:before {
  content: "";
}

* .fa-java:before {
  content: "";
}

* .fa-jedi:before {
  content: "";
}

* .fa-jedi-order:before {
  content: "";
}

* .fa-jenkins:before {
  content: "";
}

* .fa-jira:before {
  content: "";
}

* .fa-joget:before {
  content: "";
}

* .fa-joint:before {
  content: "";
}

* .fa-joomla:before {
  content: "";
}

* .fa-journal-whills:before {
  content: "";
}

* .fa-js:before {
  content: "";
}

* .fa-js-square:before {
  content: "";
}

* .fa-jsfiddle:before {
  content: "";
}

* .fa-kaaba:before {
  content: "";
}

* .fa-kaggle:before {
  content: "";
}

* .fa-key:before {
  content: "";
}

* .fa-keybase:before {
  content: "";
}

* .fa-keyboard:before {
  content: "";
}

* .fa-keycdn:before {
  content: "";
}

* .fa-khanda:before {
  content: "";
}

* .fa-kickstarter:before {
  content: "";
}

* .fa-kickstarter-k:before {
  content: "";
}

* .fa-kiss:before {
  content: "";
}

* .fa-kiss-beam:before {
  content: "";
}

* .fa-kiss-wink-heart:before {
  content: "";
}

* .fa-kiwi-bird:before {
  content: "";
}

* .fa-korvue:before {
  content: "";
}

* .fa-landmark:before {
  content: "";
}

* .fa-language:before {
  content: "";
}

* .fa-laptop:before {
  content: "";
}

* .fa-laptop-code:before {
  content: "";
}

* .fa-laravel:before {
  content: "";
}

* .fa-lastfm:before {
  content: "";
}

* .fa-lastfm-square:before {
  content: "";
}

* .fa-laugh:before {
  content: "";
}

* .fa-laugh-beam:before {
  content: "";
}

* .fa-laugh-squint:before {
  content: "";
}

* .fa-laugh-wink:before {
  content: "";
}

* .fa-layer-group:before {
  content: "";
}

* .fa-leaf:before {
  content: "";
}

* .fa-leanpub:before {
  content: "";
}

* .fa-lemon:before {
  content: "";
}

* .fa-less:before {
  content: "";
}

* .fa-less-than:before {
  content: "";
}

* .fa-less-than-equal:before {
  content: "";
}

* .fa-level-down-alt:before {
  content: "";
}

* .fa-level-up-alt:before {
  content: "";
}

* .fa-life-ring:before {
  content: "";
}

* .fa-lightbulb:before {
  content: "";
}

* .fa-line:before {
  content: "";
}

* .fa-link:before {
  content: "";
}

* .fa-linkedin:before {
  content: "";
}

* .fa-linkedin-in:before {
  content: "";
}

* .fa-linode:before {
  content: "";
}

* .fa-linux:before {
  content: "";
}

* .fa-lira-sign:before {
  content: "";
}

* .fa-list:before {
  content: "";
}

* .fa-list-alt:before {
  content: "";
}

* .fa-list-ol:before {
  content: "";
}

* .fa-list-ul:before {
  content: "";
}

* .fa-location-arrow:before {
  content: "";
}

* .fa-lock:before {
  content: "";
}

* .fa-lock-open:before {
  content: "";
}

* .fa-long-arrow-alt-down:before {
  content: "";
}

* .fa-long-arrow-alt-left:before {
  content: "";
}

* .fa-long-arrow-alt-right:before {
  content: "";
}

* .fa-long-arrow-alt-up:before {
  content: "";
}

* .fa-low-vision:before {
  content: "";
}

* .fa-luggage-cart:before {
  content: "";
}

* .fa-lyft:before {
  content: "";
}

* .fa-magento:before {
  content: "";
}

* .fa-magic:before {
  content: "";
}

* .fa-magnet:before {
  content: "";
}

* .fa-mail-bulk:before {
  content: "";
}

* .fa-mailchimp:before {
  content: "";
}

* .fa-male:before {
  content: "";
}

* .fa-mandalorian:before {
  content: "";
}

* .fa-map:before {
  content: "";
}

* .fa-map-marked:before {
  content: "";
}

* .fa-map-marked-alt:before {
  content: "";
}

* .fa-map-marker:before {
  content: "";
}

* .fa-map-marker-alt:before {
  content: "";
}

* .fa-map-pin:before {
  content: "";
}

* .fa-map-signs:before {
  content: "";
}

* .fa-markdown:before {
  content: "";
}

* .fa-marker:before {
  content: "";
}

* .fa-mars:before {
  content: "";
}

* .fa-mars-double:before {
  content: "";
}

* .fa-mars-stroke:before {
  content: "";
}

* .fa-mars-stroke-h:before {
  content: "";
}

* .fa-mars-stroke-v:before {
  content: "";
}

* .fa-mask:before {
  content: "";
}

* .fa-mastodon:before {
  content: "";
}

* .fa-maxcdn:before {
  content: "";
}

* .fa-medal:before {
  content: "";
}

* .fa-medapps:before {
  content: "";
}

* .fa-medium:before {
  content: "";
}

* .fa-medium-m:before {
  content: "";
}

* .fa-medkit:before {
  content: "";
}

* .fa-medrt:before {
  content: "";
}

* .fa-meetup:before {
  content: "";
}

* .fa-megaport:before {
  content: "";
}

* .fa-meh:before {
  content: "";
}

* .fa-meh-blank:before {
  content: "";
}

* .fa-meh-rolling-eyes:before {
  content: "";
}

* .fa-memory:before {
  content: "";
}

* .fa-mendeley:before {
  content: "";
}

* .fa-menorah:before {
  content: "";
}

* .fa-mercury:before {
  content: "";
}

* .fa-meteor:before {
  content: "";
}

* .fa-microchip:before {
  content: "";
}

* .fa-microphone:before {
  content: "";
}

* .fa-microphone-alt:before {
  content: "";
}

* .fa-microphone-alt-slash:before {
  content: "";
}

* .fa-microphone-slash:before {
  content: "";
}

* .fa-microscope:before {
  content: "";
}

* .fa-microsoft:before {
  content: "";
}

* .fa-minus:before {
  content: "";
}

* .fa-minus-circle:before {
  content: "";
}

* .fa-minus-square:before {
  content: "";
}

* .fa-mitten:before {
  content: "";
}

* .fa-mix:before {
  content: "";
}

* .fa-mixcloud:before {
  content: "";
}

* .fa-mizuni:before {
  content: "";
}

* .fa-mobile:before {
  content: "";
}

* .fa-mobile-alt:before {
  content: "";
}

* .fa-modx:before {
  content: "";
}

* .fa-monero:before {
  content: "";
}

* .fa-money-bill:before {
  content: "";
}

* .fa-money-bill-alt:before {
  content: "";
}

* .fa-money-bill-wave:before {
  content: "";
}

* .fa-money-bill-wave-alt:before {
  content: "";
}

* .fa-money-check:before {
  content: "";
}

* .fa-money-check-alt:before {
  content: "";
}

* .fa-monument:before {
  content: "";
}

* .fa-moon:before {
  content: "";
}

* .fa-mortar-pestle:before {
  content: "";
}

* .fa-mosque:before {
  content: "";
}

* .fa-motorcycle:before {
  content: "";
}

* .fa-mountain:before {
  content: "";
}

* .fa-mouse-pointer:before {
  content: "";
}

* .fa-mug-hot:before {
  content: "";
}

* .fa-music:before {
  content: "";
}

* .fa-napster:before {
  content: "";
}

* .fa-neos:before {
  content: "";
}

* .fa-network-wired:before {
  content: "";
}

* .fa-neuter:before {
  content: "";
}

* .fa-newspaper:before {
  content: "";
}

* .fa-nimblr:before {
  content: "";
}

* .fa-nintendo-switch:before {
  content: "";
}

* .fa-node:before {
  content: "";
}

* .fa-node-js:before {
  content: "";
}

* .fa-not-equal:before {
  content: "";
}

* .fa-notes-medical:before {
  content: "";
}

* .fa-npm:before {
  content: "";
}

* .fa-ns8:before {
  content: "";
}

* .fa-nutritionix:before {
  content: "";
}

* .fa-object-group:before {
  content: "";
}

* .fa-object-ungroup:before {
  content: "";
}

* .fa-odnoklassniki:before {
  content: "";
}

* .fa-odnoklassniki-square:before {
  content: "";
}

* .fa-oil-can:before {
  content: "";
}

* .fa-old-republic:before {
  content: "";
}

* .fa-om:before {
  content: "";
}

* .fa-opencart:before {
  content: "";
}

* .fa-openid:before {
  content: "";
}

* .fa-opera:before {
  content: "";
}

* .fa-optin-monster:before {
  content: "";
}

* .fa-osi:before {
  content: "";
}

* .fa-otter:before {
  content: "";
}

* .fa-outdent:before {
  content: "";
}

* .fa-page4:before {
  content: "";
}

* .fa-pagelines:before {
  content: "";
}

* .fa-paint-brush:before {
  content: "";
}

* .fa-paint-roller:before {
  content: "";
}

* .fa-palette:before {
  content: "";
}

* .fa-palfed:before {
  content: "";
}

* .fa-pallet:before {
  content: "";
}

* .fa-paper-plane:before {
  content: "";
}

* .fa-paperclip:before {
  content: "";
}

* .fa-parachute-box:before {
  content: "";
}

* .fa-paragraph:before {
  content: "";
}

* .fa-parking:before {
  content: "";
}

* .fa-passport:before {
  content: "";
}

* .fa-pastafarianism:before {
  content: "";
}

* .fa-paste:before {
  content: "";
}

* .fa-patreon:before {
  content: "";
}

* .fa-pause:before {
  content: "";
}

* .fa-pause-circle:before {
  content: "";
}

* .fa-paw:before {
  content: "";
}

* .fa-paypal:before {
  content: "";
}

* .fa-peace:before {
  content: "";
}

* .fa-pen:before {
  content: "";
}

* .fa-pen-alt:before {
  content: "";
}

* .fa-pen-fancy:before {
  content: "";
}

* .fa-pen-nib:before {
  content: "";
}

* .fa-pen-square:before {
  content: "";
}

* .fa-pencil-alt:before {
  content: "";
}

* .fa-pencil-ruler:before {
  content: "";
}

* .fa-penny-arcade:before {
  content: "";
}

* .fa-people-carry:before {
  content: "";
}

* .fa-percent:before {
  content: "";
}

* .fa-percentage:before {
  content: "";
}

* .fa-periscope:before {
  content: "";
}

* .fa-person-booth:before {
  content: "";
}

* .fa-phabricator:before {
  content: "";
}

* .fa-phoenix-framework:before {
  content: "";
}

* .fa-phoenix-squadron:before {
  content: "";
}

* .fa-phone:before {
  content: "";
}

* .fa-phone-slash:before {
  content: "";
}

* .fa-phone-square:before {
  content: "";
}

* .fa-phone-volume:before {
  content: "";
}

* .fa-php:before {
  content: "";
}

* .fa-pied-piper:before {
  content: "";
}

* .fa-pied-piper-alt:before {
  content: "";
}

* .fa-pied-piper-hat:before {
  content: "";
}

* .fa-pied-piper-pp:before {
  content: "";
}

* .fa-piggy-bank:before {
  content: "";
}

* .fa-pills:before {
  content: "";
}

* .fa-pinterest:before {
  content: "";
}

* .fa-pinterest-p:before {
  content: "";
}

* .fa-pinterest-square:before {
  content: "";
}

* .fa-place-of-worship:before {
  content: "";
}

* .fa-plane:before {
  content: "";
}

* .fa-plane-arrival:before {
  content: "";
}

* .fa-plane-departure:before {
  content: "";
}

* .fa-play:before {
  content: "";
}

* .fa-play-circle:before {
  content: "";
}

* .fa-playstation:before {
  content: "";
}

* .fa-plug:before {
  content: "";
}

* .fa-plus:before {
  content: "";
}

* .fa-plus-circle:before {
  content: "";
}

* .fa-plus-square:before {
  content: "";
}

* .fa-podcast:before {
  content: "";
}

* .fa-poll:before {
  content: "";
}

* .fa-poll-h:before {
  content: "";
}

* .fa-poo:before {
  content: "";
}

* .fa-poo-storm:before {
  content: "";
}

* .fa-poop:before {
  content: "";
}

* .fa-portrait:before {
  content: "";
}

* .fa-pound-sign:before {
  content: "";
}

* .fa-power-off:before {
  content: "";
}

* .fa-pray:before {
  content: "";
}

* .fa-praying-hands:before {
  content: "";
}

* .fa-prescription:before {
  content: "";
}

* .fa-prescription-bottle:before {
  content: "";
}

* .fa-prescription-bottle-alt:before {
  content: "";
}

* .fa-print:before {
  content: "";
}

* .fa-procedures:before {
  content: "";
}

* .fa-product-hunt:before {
  content: "";
}

* .fa-project-diagram:before {
  content: "";
}

* .fa-pushed:before {
  content: "";
}

* .fa-puzzle-piece:before {
  content: "";
}

* .fa-python:before {
  content: "";
}

* .fa-qq:before {
  content: "";
}

* .fa-qrcode:before {
  content: "";
}

* .fa-question:before {
  content: "";
}

* .fa-question-circle:before {
  content: "";
}

* .fa-quidditch:before {
  content: "";
}

* .fa-quinscape:before {
  content: "";
}

* .fa-quora:before {
  content: "";
}

* .fa-quote-left:before {
  content: "";
}

* .fa-quote-right:before {
  content: "";
}

* .fa-quran:before {
  content: "";
}

* .fa-r-project:before {
  content: "";
}

* .fa-radiation:before {
  content: "";
}

* .fa-radiation-alt:before {
  content: "";
}

* .fa-rainbow:before {
  content: "";
}

* .fa-random:before {
  content: "";
}

* .fa-raspberry-pi:before {
  content: "";
}

* .fa-ravelry:before {
  content: "";
}

* .fa-react:before {
  content: "";
}

* .fa-reacteurope:before {
  content: "";
}

* .fa-readme:before {
  content: "";
}

* .fa-rebel:before {
  content: "";
}

* .fa-receipt:before {
  content: "";
}

* .fa-recycle:before {
  content: "";
}

* .fa-red-river:before {
  content: "";
}

* .fa-reddit:before {
  content: "";
}

* .fa-reddit-alien:before {
  content: "";
}

* .fa-reddit-square:before {
  content: "";
}

* .fa-redhat:before {
  content: "";
}

* .fa-redo:before {
  content: "";
}

* .fa-redo-alt:before {
  content: "";
}

* .fa-registered:before {
  content: "";
}

* .fa-renren:before {
  content: "";
}

* .fa-reply:before {
  content: "";
}

* .fa-reply-all:before {
  content: "";
}

* .fa-replyd:before {
  content: "";
}

* .fa-republican:before {
  content: "";
}

* .fa-researchgate:before {
  content: "";
}

* .fa-resolving:before {
  content: "";
}

* .fa-restroom:before {
  content: "";
}

* .fa-retweet:before {
  content: "";
}

* .fa-rev:before {
  content: "";
}

* .fa-ribbon:before {
  content: "";
}

* .fa-ring:before {
  content: "";
}

* .fa-road:before {
  content: "";
}

* .fa-robot:before {
  content: "";
}

* .fa-rocket:before {
  content: "";
}

* .fa-rocketchat:before {
  content: "";
}

* .fa-rockrms:before {
  content: "";
}

* .fa-route:before {
  content: "";
}

* .fa-rss:before {
  content: "";
}

* .fa-rss-square:before {
  content: "";
}

* .fa-ruble-sign:before {
  content: "";
}

* .fa-ruler:before {
  content: "";
}

* .fa-ruler-combined:before {
  content: "";
}

* .fa-ruler-horizontal:before {
  content: "";
}

* .fa-ruler-vertical:before {
  content: "";
}

* .fa-running:before {
  content: "";
}

* .fa-rupee-sign:before {
  content: "";
}

* .fa-sad-cry:before {
  content: "";
}

* .fa-sad-tear:before {
  content: "";
}

* .fa-safari:before {
  content: "";
}

* .fa-sass:before {
  content: "";
}

* .fa-satellite:before {
  content: "";
}

* .fa-satellite-dish:before {
  content: "";
}

* .fa-save:before {
  content: "";
}

* .fa-schlix:before {
  content: "";
}

* .fa-school:before {
  content: "";
}

* .fa-screwdriver:before {
  content: "";
}

* .fa-scribd:before {
  content: "";
}

* .fa-scroll:before {
  content: "";
}

* .fa-sd-card:before {
  content: "";
}

* .fa-search:before {
  content: "";
}

* .fa-search-dollar:before {
  content: "";
}

* .fa-search-location:before {
  content: "";
}

* .fa-search-minus:before {
  content: "";
}

* .fa-search-plus:before {
  content: "";
}

* .fa-searchengin:before {
  content: "";
}

* .fa-seedling:before {
  content: "";
}

* .fa-sellcast:before {
  content: "";
}

* .fa-sellsy:before {
  content: "";
}

* .fa-server:before {
  content: "";
}

* .fa-servicestack:before {
  content: "";
}

* .fa-shapes:before {
  content: "";
}

* .fa-share:before {
  content: "";
}

* .fa-share-alt:before {
  content: "";
}

* .fa-share-alt-square:before {
  content: "";
}

* .fa-share-square:before {
  content: "";
}

* .fa-shekel-sign:before {
  content: "";
}

* .fa-shield-alt:before {
  content: "";
}

* .fa-ship:before {
  content: "";
}

* .fa-shipping-fast:before {
  content: "";
}

* .fa-shirtsinbulk:before {
  content: "";
}

* .fa-shoe-prints:before {
  content: "";
}

* .fa-shopping-bag:before {
  content: "";
}

* .fa-shopping-basket:before {
  content: "";
}

* .fa-shopping-cart:before {
  content: "";
}

* .fa-shopware:before {
  content: "";
}

* .fa-shower:before {
  content: "";
}

* .fa-shuttle-van:before {
  content: "";
}

* .fa-sign:before {
  content: "";
}

* .fa-sign-in-alt:before {
  content: "";
}

* .fa-sign-language:before {
  content: "";
}

* .fa-sign-out-alt:before {
  content: "";
}

* .fa-signal:before {
  content: "";
}

* .fa-signature:before {
  content: "";
}

* .fa-sim-card:before {
  content: "";
}

* .fa-simplybuilt:before {
  content: "";
}

* .fa-sistrix:before {
  content: "";
}

* .fa-sitemap:before {
  content: "";
}

* .fa-sith:before {
  content: "";
}

* .fa-skating:before {
  content: "";
}

* .fa-sketch:before {
  content: "";
}

* .fa-skiing:before {
  content: "";
}

* .fa-skiing-nordic:before {
  content: "";
}

* .fa-skull:before {
  content: "";
}

* .fa-skull-crossbones:before {
  content: "";
}

* .fa-skyatlas:before {
  content: "";
}

* .fa-skype:before {
  content: "";
}

* .fa-slack:before {
  content: "";
}

* .fa-slack-hash:before {
  content: "";
}

* .fa-slash:before {
  content: "";
}

* .fa-sleigh:before {
  content: "";
}

* .fa-sliders-h:before {
  content: "";
}

* .fa-slideshare:before {
  content: "";
}

* .fa-smile:before {
  content: "";
}

* .fa-smile-beam:before {
  content: "";
}

* .fa-smile-wink:before {
  content: "";
}

* .fa-smog:before {
  content: "";
}

* .fa-smoking:before {
  content: "";
}

* .fa-smoking-ban:before {
  content: "";
}

* .fa-sms:before {
  content: "";
}

* .fa-snapchat:before {
  content: "";
}

* .fa-snapchat-ghost:before {
  content: "";
}

* .fa-snapchat-square:before {
  content: "";
}

* .fa-snowboarding:before {
  content: "";
}

* .fa-snowflake:before {
  content: "";
}

* .fa-snowman:before {
  content: "";
}

* .fa-snowplow:before {
  content: "";
}

* .fa-socks:before {
  content: "";
}

* .fa-solar-panel:before {
  content: "";
}

* .fa-sort:before {
  content: "";
}

* .fa-sort-alpha-down:before {
  content: "";
}

* .fa-sort-alpha-up:before {
  content: "";
}

* .fa-sort-amount-down:before {
  content: "";
}

* .fa-sort-amount-up:before {
  content: "";
}

* .fa-sort-down:before {
  content: "";
}

* .fa-sort-numeric-down:before {
  content: "";
}

* .fa-sort-numeric-up:before {
  content: "";
}

* .fa-sort-up:before {
  content: "";
}

* .fa-soundcloud:before {
  content: "";
}

* .fa-sourcetree:before {
  content: "";
}

* .fa-spa:before {
  content: "";
}

* .fa-space-shuttle:before {
  content: "";
}

* .fa-speakap:before {
  content: "";
}

* .fa-spider:before {
  content: "";
}

* .fa-spinner:before {
  content: "";
}

* .fa-splotch:before {
  content: "";
}

* .fa-spotify:before {
  content: "";
}

* .fa-spray-can:before {
  content: "";
}

* .fa-square:before {
  content: "";
}

* .fa-square-full:before {
  content: "";
}

* .fa-square-root-alt:before {
  content: "";
}

* .fa-squarespace:before {
  content: "";
}

* .fa-stack-exchange:before {
  content: "";
}

* .fa-stack-overflow:before {
  content: "";
}

* .fa-stamp:before {
  content: "";
}

* .fa-star:before {
  content: "";
}

* .fa-star-and-crescent:before {
  content: "";
}

* .fa-star-half:before {
  content: "";
}

* .fa-star-half-alt:before {
  content: "";
}

* .fa-star-of-david:before {
  content: "";
}

* .fa-star-of-life:before {
  content: "";
}

* .fa-staylinked:before {
  content: "";
}

* .fa-steam:before {
  content: "";
}

* .fa-steam-square:before {
  content: "";
}

* .fa-steam-symbol:before {
  content: "";
}

* .fa-step-backward:before {
  content: "";
}

* .fa-step-forward:before {
  content: "";
}

* .fa-stethoscope:before {
  content: "";
}

* .fa-sticker-mule:before {
  content: "";
}

* .fa-sticky-note:before {
  content: "";
}

* .fa-stop:before {
  content: "";
}

* .fa-stop-circle:before {
  content: "";
}

* .fa-stopwatch:before {
  content: "";
}

* .fa-store:before {
  content: "";
}

* .fa-store-alt:before {
  content: "";
}

* .fa-strava:before {
  content: "";
}

* .fa-stream:before {
  content: "";
}

* .fa-street-view:before {
  content: "";
}

* .fa-strikethrough:before {
  content: "";
}

* .fa-stripe:before {
  content: "";
}

* .fa-stripe-s:before {
  content: "";
}

* .fa-stroopwafel:before {
  content: "";
}

* .fa-studiovinari:before {
  content: "";
}

* .fa-stumbleupon:before {
  content: "";
}

* .fa-stumbleupon-circle:before {
  content: "";
}

* .fa-subscript:before {
  content: "";
}

* .fa-subway:before {
  content: "";
}

* .fa-suitcase:before {
  content: "";
}

* .fa-suitcase-rolling:before {
  content: "";
}

* .fa-sun:before {
  content: "";
}

* .fa-superpowers:before {
  content: "";
}

* .fa-superscript:before {
  content: "";
}

* .fa-supple:before {
  content: "";
}

* .fa-surprise:before {
  content: "";
}

* .fa-suse:before {
  content: "";
}

* .fa-swatchbook:before {
  content: "";
}

* .fa-swimmer:before {
  content: "";
}

* .fa-swimming-pool:before {
  content: "";
}

* .fa-synagogue:before {
  content: "";
}

* .fa-sync:before {
  content: "";
}

* .fa-sync-alt:before {
  content: "";
}

* .fa-syringe:before {
  content: "";
}

* .fa-table:before {
  content: "";
}

* .fa-table-tennis:before {
  content: "";
}

* .fa-tablet:before {
  content: "";
}

* .fa-tablet-alt:before {
  content: "";
}

* .fa-tablets:before {
  content: "";
}

* .fa-tachometer-alt:before {
  content: "";
}

* .fa-tag:before {
  content: "";
}

* .fa-tags:before {
  content: "";
}

* .fa-tape:before {
  content: "";
}

* .fa-tasks:before {
  content: "";
}

* .fa-taxi:before {
  content: "";
}

* .fa-teamspeak:before {
  content: "";
}

* .fa-teeth:before {
  content: "";
}

* .fa-teeth-open:before {
  content: "";
}

* .fa-telegram:before {
  content: "";
}

* .fa-telegram-plane:before {
  content: "";
}

* .fa-temperature-high:before {
  content: "";
}

* .fa-temperature-low:before {
  content: "";
}

* .fa-tencent-weibo:before {
  content: "";
}

* .fa-tenge:before {
  content: "";
}

* .fa-terminal:before {
  content: "";
}

* .fa-text-height:before {
  content: "";
}

* .fa-text-width:before {
  content: "";
}

* .fa-th:before {
  content: "";
}

* .fa-th-large:before {
  content: "";
}

* .fa-th-list:before {
  content: "";
}

* .fa-the-red-yeti:before {
  content: "";
}

* .fa-theater-masks:before {
  content: "";
}

* .fa-themeco:before {
  content: "";
}

* .fa-themeisle:before {
  content: "";
}

* .fa-thermometer:before {
  content: "";
}

* .fa-thermometer-empty:before {
  content: "";
}

* .fa-thermometer-full:before {
  content: "";
}

* .fa-thermometer-half:before {
  content: "";
}

* .fa-thermometer-quarter:before {
  content: "";
}

* .fa-thermometer-three-quarters:before {
  content: "";
}

* .fa-think-peaks:before {
  content: "";
}

* .fa-thumbs-down:before {
  content: "";
}

* .fa-thumbs-up:before {
  content: "";
}

* .fa-thumbtack:before {
  content: "";
}

* .fa-ticket-alt:before {
  content: "";
}

* .fa-times:before {
  content: "";
}

* .fa-times-circle:before {
  content: "";
}

* .fa-tint:before {
  content: "";
}

* .fa-tint-slash:before {
  content: "";
}

* .fa-tired:before {
  content: "";
}

* .fa-toggle-off:before {
  content: "";
}

* .fa-toggle-on:before {
  content: "";
}

* .fa-toilet:before {
  content: "";
}

* .fa-toilet-paper:before {
  content: "";
}

* .fa-toolbox:before {
  content: "";
}

* .fa-tools:before {
  content: "";
}

* .fa-tooth:before {
  content: "";
}

* .fa-torah:before {
  content: "";
}

* .fa-torii-gate:before {
  content: "";
}

* .fa-tractor:before {
  content: "";
}

* .fa-trade-federation:before {
  content: "";
}

* .fa-trademark:before {
  content: "";
}

* .fa-traffic-light:before {
  content: "";
}

* .fa-train:before {
  content: "";
}

* .fa-tram:before {
  content: "";
}

* .fa-transgender:before {
  content: "";
}

* .fa-transgender-alt:before {
  content: "";
}

* .fa-trash:before {
  content: "";
}

* .fa-trash-alt:before {
  content: "";
}

* .fa-tree:before {
  content: "";
}

* .fa-trello:before {
  content: "";
}

* .fa-tripadvisor:before {
  content: "";
}

* .fa-trophy:before {
  content: "";
}

* .fa-truck:before {
  content: "";
}

* .fa-truck-loading:before {
  content: "";
}

* .fa-truck-monster:before {
  content: "";
}

* .fa-truck-moving:before {
  content: "";
}

* .fa-truck-pickup:before {
  content: "";
}

* .fa-tshirt:before {
  content: "";
}

* .fa-tty:before {
  content: "";
}

* .fa-tumblr:before {
  content: "";
}

* .fa-tumblr-square:before {
  content: "";
}

* .fa-tv:before {
  content: "";
}

* .fa-twitch:before {
  content: "";
}

* .fa-twitter:before {
  content: "";
}

* .fa-twitter-square:before {
  content: "";
}

* .fa-typo3:before {
  content: "";
}

* .fa-uber:before {
  content: "";
}

* .fa-ubuntu:before {
  content: "";
}

* .fa-uikit:before {
  content: "";
}

* .fa-umbrella:before {
  content: "";
}

* .fa-umbrella-beach:before {
  content: "";
}

* .fa-underline:before {
  content: "";
}

* .fa-undo:before {
  content: "";
}

* .fa-undo-alt:before {
  content: "";
}

* .fa-uniregistry:before {
  content: "";
}

* .fa-universal-access:before {
  content: "";
}

* .fa-university:before {
  content: "";
}

* .fa-unlink:before {
  content: "";
}

* .fa-unlock:before {
  content: "";
}

* .fa-unlock-alt:before {
  content: "";
}

* .fa-untappd:before {
  content: "";
}

* .fa-upload:before {
  content: "";
}

* .fa-ups:before {
  content: "";
}

* .fa-usb:before {
  content: "";
}

* .fa-user:before {
  content: "";
}

* .fa-user-alt:before {
  content: "";
}

* .fa-user-alt-slash:before {
  content: "";
}

* .fa-user-astronaut:before {
  content: "";
}

* .fa-user-check:before {
  content: "";
}

* .fa-user-circle:before {
  content: "";
}

* .fa-user-clock:before {
  content: "";
}

* .fa-user-cog:before {
  content: "";
}

* .fa-user-edit:before {
  content: "";
}

* .fa-user-friends:before {
  content: "";
}

* .fa-user-graduate:before {
  content: "";
}

* .fa-user-injured:before {
  content: "";
}

* .fa-user-lock:before {
  content: "";
}

* .fa-user-md:before {
  content: "";
}

* .fa-user-minus:before {
  content: "";
}

* .fa-user-ninja:before {
  content: "";
}

* .fa-user-plus:before {
  content: "";
}

* .fa-user-secret:before {
  content: "";
}

* .fa-user-shield:before {
  content: "";
}

* .fa-user-slash:before {
  content: "";
}

* .fa-user-tag:before {
  content: "";
}

* .fa-user-tie:before {
  content: "";
}

* .fa-user-times:before {
  content: "";
}

* .fa-users:before {
  content: "";
}

* .fa-users-cog:before {
  content: "";
}

* .fa-usps:before {
  content: "";
}

* .fa-ussunnah:before {
  content: "";
}

* .fa-utensil-spoon:before {
  content: "";
}

* .fa-utensils:before {
  content: "";
}

* .fa-vaadin:before {
  content: "";
}

* .fa-vector-square:before {
  content: "";
}

* .fa-venus:before {
  content: "";
}

* .fa-venus-double:before {
  content: "";
}

* .fa-venus-mars:before {
  content: "";
}

* .fa-viacoin:before {
  content: "";
}

* .fa-viadeo:before {
  content: "";
}

* .fa-viadeo-square:before {
  content: "";
}

* .fa-vial:before {
  content: "";
}

* .fa-vials:before {
  content: "";
}

* .fa-viber:before {
  content: "";
}

* .fa-video:before {
  content: "";
}

* .fa-video-slash:before {
  content: "";
}

* .fa-vihara:before {
  content: "";
}

* .fa-vimeo:before {
  content: "";
}

* .fa-vimeo-square:before {
  content: "";
}

* .fa-vimeo-v:before {
  content: "";
}

* .fa-vine:before {
  content: "";
}

* .fa-vk:before {
  content: "";
}

* .fa-vnv:before {
  content: "";
}

* .fa-volleyball-ball:before {
  content: "";
}

* .fa-volume-down:before {
  content: "";
}

* .fa-volume-mute:before {
  content: "";
}

* .fa-volume-off:before {
  content: "";
}

* .fa-volume-up:before {
  content: "";
}

* .fa-vote-yea:before {
  content: "";
}

* .fa-vr-cardboard:before {
  content: "";
}

* .fa-vuejs:before {
  content: "";
}

* .fa-walking:before {
  content: "";
}

* .fa-wallet:before {
  content: "";
}

* .fa-warehouse:before {
  content: "";
}

* .fa-water:before {
  content: "";
}

* .fa-weebly:before {
  content: "";
}

* .fa-weibo:before {
  content: "";
}

* .fa-weight:before {
  content: "";
}

* .fa-weight-hanging:before {
  content: "";
}

* .fa-weixin:before {
  content: "";
}

* .fa-whatsapp:before {
  content: "";
}

* .fa-whatsapp-square:before {
  content: "";
}

* .fa-wheelchair:before {
  content: "";
}

* .fa-whmcs:before {
  content: "";
}

* .fa-wifi:before {
  content: "";
}

* .fa-wikipedia-w:before {
  content: "";
}

* .fa-wind:before {
  content: "";
}

* .fa-window-close:before {
  content: "";
}

* .fa-window-maximize:before {
  content: "";
}

* .fa-window-minimize:before {
  content: "";
}

* .fa-window-restore:before {
  content: "";
}

* .fa-windows:before {
  content: "";
}

* .fa-wine-bottle:before {
  content: "";
}

* .fa-wine-glass:before {
  content: "";
}

* .fa-wine-glass-alt:before {
  content: "";
}

* .fa-wix:before {
  content: "";
}

* .fa-wizards-of-the-coast:before {
  content: "";
}

* .fa-wolf-pack-battalion:before {
  content: "";
}

* .fa-won-sign:before {
  content: "";
}

* .fa-wordpress:before {
  content: "";
}

* .fa-wordpress-simple:before {
  content: "";
}

* .fa-wpbeginner:before {
  content: "";
}

* .fa-wpexplorer:before {
  content: "";
}

* .fa-wpforms:before {
  content: "";
}

* .fa-wpressr:before {
  content: "";
}

* .fa-wrench:before {
  content: "";
}

* .fa-x-ray:before {
  content: "";
}

* .fa-xbox:before {
  content: "";
}

* .fa-xing:before {
  content: "";
}

* .fa-xing-square:before {
  content: "";
}

* .fa-y-combinator:before {
  content: "";
}

* .fa-yahoo:before {
  content: "";
}

* .fa-yandex:before {
  content: "";
}

* .fa-yandex-international:before {
  content: "";
}

* .fa-yarn:before {
  content: "";
}

* .fa-yelp:before {
  content: "";
}

* .fa-yen-sign:before {
  content: "";
}

* .fa-yin-yang:before {
  content: "";
}

* .fa-yoast:before {
  content: "";
}

* .fa-youtube:before {
  content: "";
}

* .fa-youtube-square:before {
  content: "";
}

* .fa-zhihu:before {
  content: "";
}

* .sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

* .sr-only-focusable:active,
* .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 900;
  src: url("../node_modules/@patternfly/patternfly/assets/fonts/webfonts/fa-solid-900.woff2") format("woff2");
}

.fa,
.fas {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

@font-face {
  font-family: "pf-v5-pficon";
  src: url("../node_modules/@patternfly/patternfly/assets/pficon/pf-v5-pficon.woff2") format("woff2");
}

.pf-v5-pficon-zone:before,
.pf-v5-pficon-warning-triangle:before,
.pf-v5-pficon-volume:before,
.pf-v5-pficon-virtual-machine:before,
.pf-v5-pficon-users:before,
.pf-v5-pficon-user:before,
.pf-v5-pficon-unplugged:before,
.pf-v5-pficon-unlocked:before,
.pf-v5-pficon-unknown:before,
.pf-v5-pficon-trend-up:before,
.pf-v5-pficon-trend-down:before,
.pf-v5-pficon-treeview:before,
.pf-v5-pficon-topology:before,
.pf-v5-pficon-thumb-tack:before,
.pf-v5-pficon-tenant:before,
.pf-v5-pficon-task:before,
.pf-v5-pficon-storage-domain:before,
.pf-v5-pficon-spinner2:before,
.pf-v5-pficon-spinner:before,
.pf-v5-pficon-severity-undefined:before,
.pf-v5-pficon-severity-none:before,
.pf-v5-pficon-severity-moderate:before,
.pf-v5-pficon-severity-minor:before,
.pf-v5-pficon-severity-important:before,
.pf-v5-pficon-severity-critical:before,
.pf-v5-pficon-services:before,
.pf-v5-pficon-service:before,
.pf-v5-pficon-service-catalog:before,
.pf-v5-pficon-server:before,
.pf-v5-pficon-server-group:before,
.pf-v5-pficon-security:before,
.pf-v5-pficon-screen:before,
.pf-v5-pficon-save:before,
.pf-v5-pficon-running:before,
.pf-v5-pficon-resources-full:before,
.pf-v5-pficon-resources-empty:before,
.pf-v5-pficon-resources-almost-full:before,
.pf-v5-pficon-resources-almost-empty:before,
.pf-v5-pficon-resource-pool:before,
.pf-v5-pficon-repository:before,
.pf-v5-pficon-replicator:before,
.pf-v5-pficon-remove2:before,
.pf-v5-pficon-registry:before,
.pf-v5-pficon-regions:before,
.pf-v5-pficon-rebooting:before,
.pf-v5-pficon-rebalance:before,
.pf-v5-pficon-project:before,
.pf-v5-pficon-process-automation:before,
.pf-v5-pficon-private:before,
.pf-v5-pficon-print:before,
.pf-v5-pficon-port:before,
.pf-v5-pficon-plugged:before,
.pf-v5-pficon-pficon-vcenter:before,
.pf-v5-pficon-pficon-template:before,
.pf-v5-pficon-pficon-sort-common-desc:before,
.pf-v5-pficon-pficon-sort-common-asc:before,
.pf-v5-pficon-pficon-satellite:before,
.pf-v5-pficon-pficon-network-range:before,
.pf-v5-pficon-pficon-history:before,
.pf-v5-pficon-pficon-dragdrop:before,
.pf-v5-pficon-pending:before,
.pf-v5-pficon-paused:before,
.pf-v5-pficon-panel-open:before,
.pf-v5-pficon-panel-close:before,
.pf-v5-pficon-package:before,
.pf-v5-pficon-os-image:before,
.pf-v5-pficon-orders:before,
.pf-v5-pficon-optimize:before,
.pf-v5-pficon-openstack:before,
.pf-v5-pficon-openshift:before,
.pf-v5-pficon-open-drawer-right:before,
.pf-v5-pficon-on:before,
.pf-v5-pficon-on-running:before,
.pf-v5-pficon-ok:before,
.pf-v5-pficon-off:before,
.pf-v5-pficon-not-started:before,
.pf-v5-pficon-new-process:before,
.pf-v5-pficon-network:before,
.pf-v5-pficon-namespaces:before,
.pf-v5-pficon-multicluster:before,
.pf-v5-pficon-monitoring:before,
.pf-v5-pficon-module:before,
.pf-v5-pficon-migration:before,
.pf-v5-pficon-middleware:before,
.pf-v5-pficon-messages:before,
.pf-v5-pficon-memory:before,
.pf-v5-pficon-maintenance:before,
.pf-v5-pficon-locked:before,
.pf-v5-pficon-key:before,
.pf-v5-pficon-integration:before,
.pf-v5-pficon-infrastructure:before,
.pf-v5-pficon-info:before,
.pf-v5-pficon-in-progress:before,
.pf-v5-pficon-import:before,
.pf-v5-pficon-home:before,
.pf-v5-pficon-history:before,
.pf-v5-pficon-help:before,
.pf-v5-pficon-globe-route:before,
.pf-v5-pficon-folder-open:before,
.pf-v5-pficon-folder-close:before,
.pf-v5-pficon-flavor:before,
.pf-v5-pficon-filter:before,
.pf-v5-pficon-export:before,
.pf-v5-pficon-error-circle-o:before,
.pf-v5-pficon-equalizer:before,
.pf-v5-pficon-enterprise:before,
.pf-v5-pficon-enhancement:before,
.pf-v5-pficon-edit:before,
.pf-v5-pficon-domain:before,
.pf-v5-pficon-disconnected:before,
.pf-v5-pficon-degraded:before,
.pf-v5-pficon-data-source:before,
.pf-v5-pficon-data-sink:before,
.pf-v5-pficon-data-processor:before,
.pf-v5-pficon-critical-risk:before,
.pf-v5-pficon-cpu:before,
.pf-v5-pficon-container-node:before,
.pf-v5-pficon-connected:before,
.pf-v5-pficon-cluster:before,
.pf-v5-pficon-cloud-tenant:before,
.pf-v5-pficon-cloud-security:before,
.pf-v5-pficon-close:before,
.pf-v5-pficon-chat:before,
.pf-v5-pficon-catalog:before,
.pf-v5-pficon-bundle:before,
.pf-v5-pficon-builder-image:before,
.pf-v5-pficon-build:before,
.pf-v5-pficon-blueprint:before,
.pf-v5-pficon-bell:before,
.pf-v5-pficon-automation:before,
.pf-v5-pficon-attention-bell:before,
.pf-v5-pficon-asleep:before,
.pf-v5-pficon-arrow:before,
.pf-v5-pficon-applications:before,
.pf-v5-pficon-ansible-tower:before,
.pf-v5-pficon-add-circle-o:before {
  font-family: "pf-v5-pficon";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
}

.pf-v5-pficon-add-circle-o:before {
  content: "";
}

.pf-v5-pficon-ansible-tower:before {
  content: "";
}

.pf-v5-pficon-applications:before {
  content: "";
}

.pf-v5-pficon-arrow:before {
  content: "";
}

.pf-v5-pficon-asleep:before {
  content: "";
}

.pf-v5-pficon-attention-bell:before {
  content: "";
}

.pf-v5-pficon-automation:before {
  content: "";
}

.pf-v5-pficon-bell:before {
  content: "";
}

.pf-v5-pficon-blueprint:before {
  content: "";
}

.pf-v5-pficon-build:before {
  content: "";
}

.pf-v5-pficon-builder-image:before {
  content: "";
}

.pf-v5-pficon-bundle:before {
  content: "";
}

.pf-v5-pficon-catalog:before {
  content: "";
}

.pf-v5-pficon-chat:before {
  content: "";
}

.pf-v5-pficon-close:before {
  content: "";
}

.pf-v5-pficon-cloud-security:before {
  content: "";
}

.pf-v5-pficon-cloud-tenant:before {
  content: "";
}

.pf-v5-pficon-cluster:before {
  content: "";
}

.pf-v5-pficon-connected:before {
  content: "";
}

.pf-v5-pficon-container-node:before {
  content: "";
}

.pf-v5-pficon-cpu:before {
  content: "";
}

.pf-v5-pficon-critical-risk:before {
  content: "";
}

.pf-v5-pficon-data-processor:before {
  content: "";
}

.pf-v5-pficon-data-sink:before {
  content: "";
}

.pf-v5-pficon-data-source:before {
  content: "";
}

.pf-v5-pficon-degraded:before {
  content: "";
}

.pf-v5-pficon-disconnected:before {
  content: "";
}

.pf-v5-pficon-domain:before {
  content: "";
}

.pf-v5-pficon-edit:before {
  content: "";
}

.pf-v5-pficon-enhancement:before {
  content: "";
}

.pf-v5-pficon-enterprise:before {
  content: "";
}

.pf-v5-pficon-equalizer:before {
  content: "";
}

.pf-v5-pficon-error-circle-o:before {
  content: "";
}

.pf-v5-pficon-export:before {
  content: "";
}

.pf-v5-pficon-filter:before {
  content: "";
}

.pf-v5-pficon-flavor:before {
  content: "";
}

.pf-v5-pficon-folder-close:before {
  content: "";
}

.pf-v5-pficon-folder-open:before {
  content: "";
}

.pf-v5-pficon-globe-route:before {
  content: "";
}

.pf-v5-pficon-help:before {
  content: "";
}

.pf-v5-pficon-history:before {
  content: "";
}

.pf-v5-pficon-home:before {
  content: "";
}

.pf-v5-pficon-import:before {
  content: "";
}

.pf-v5-pficon-in-progress:before {
  content: "";
}

.pf-v5-pficon-info:before {
  content: "";
}

.pf-v5-pficon-infrastructure:before {
  content: "";
}

.pf-v5-pficon-integration:before {
  content: "";
}

.pf-v5-pficon-key:before {
  content: "";
}

.pf-v5-pficon-locked:before {
  content: "";
}

.pf-v5-pficon-maintenance:before {
  content: "";
}

.pf-v5-pficon-memory:before {
  content: "";
}

.pf-v5-pficon-messages:before {
  content: "";
}

.pf-v5-pficon-middleware:before {
  content: "";
}

.pf-v5-pficon-migration:before {
  content: "";
}

.pf-v5-pficon-module:before {
  content: "";
}

.pf-v5-pficon-monitoring:before {
  content: "";
}

.pf-v5-pficon-multicluster:before {
  content: "";
}

.pf-v5-pficon-namespaces:before {
  content: "";
}

.pf-v5-pficon-network:before {
  content: "";
}

.pf-v5-pficon-new-process:before {
  content: "";
}

.pf-v5-pficon-not-started:before {
  content: "";
}

.pf-v5-pficon-off:before {
  content: "";
}

.pf-v5-pficon-ok:before {
  content: "";
}

.pf-v5-pficon-on-running:before {
  content: "";
}

.pf-v5-pficon-on:before {
  content: "";
}

.pf-v5-pficon-open-drawer-right:before {
  content: "";
}

.pf-v5-pficon-openshift:before {
  content: "";
}

.pf-v5-pficon-openstack:before {
  content: "";
}

.pf-v5-pficon-optimize:before {
  content: "";
}

.pf-v5-pficon-orders:before {
  content: "";
}

.pf-v5-pficon-os-image:before {
  content: "";
}

.pf-v5-pficon-package:before {
  content: "";
}

.pf-v5-pficon-panel-close:before {
  content: "";
}

.pf-v5-pficon-panel-open:before {
  content: "";
}

.pf-v5-pficon-paused:before {
  content: "";
}

.pf-v5-pficon-pending:before {
  content: "";
}

.pf-v5-pficon-pficon-dragdrop:before {
  content: "";
}

.pf-v5-pficon-pficon-history:before {
  content: "";
}

.pf-v5-pficon-pficon-network-range:before {
  content: "";
}

.pf-v5-pficon-pficon-satellite:before {
  content: "";
}

.pf-v5-pficon-pficon-sort-common-asc:before {
  content: "";
}

.pf-v5-pficon-pficon-sort-common-desc:before {
  content: "";
}

.pf-v5-pficon-pficon-template:before {
  content: "";
}

.pf-v5-pficon-pficon-vcenter:before {
  content: "";
}

.pf-v5-pficon-plugged:before {
  content: "";
}

.pf-v5-pficon-port:before {
  content: "";
}

.pf-v5-pficon-print:before {
  content: "";
}

.pf-v5-pficon-private:before {
  content: "";
}

.pf-v5-pficon-process-automation:before {
  content: "";
}

.pf-v5-pficon-project:before {
  content: "";
}

.pf-v5-pficon-rebalance:before {
  content: "";
}

.pf-v5-pficon-rebooting:before {
  content: "";
}

.pf-v5-pficon-regions:before {
  content: "";
}

.pf-v5-pficon-registry:before {
  content: "";
}

.pf-v5-pficon-remove2:before {
  content: "";
}

.pf-v5-pficon-replicator:before {
  content: "";
}

.pf-v5-pficon-repository:before {
  content: "";
}

.pf-v5-pficon-resource-pool:before {
  content: "";
}

.pf-v5-pficon-resources-almost-empty:before {
  content: "";
}

.pf-v5-pficon-resources-almost-full:before {
  content: "";
}

.pf-v5-pficon-resources-empty:before {
  content: "";
}

.pf-v5-pficon-resources-full:before {
  content: "";
}

.pf-v5-pficon-running:before {
  content: "";
}

.pf-v5-pficon-save:before {
  content: "";
}

.pf-v5-pficon-screen:before {
  content: "";
}

.pf-v5-pficon-security:before {
  content: "";
}

.pf-v5-pficon-server-group:before {
  content: "";
}

.pf-v5-pficon-server:before {
  content: "";
}

.pf-v5-pficon-service-catalog:before {
  content: "";
}

.pf-v5-pficon-service:before {
  content: "";
}

.pf-v5-pficon-services:before {
  content: "";
}

.pf-v5-pficon-severity-critical:before {
  content: "";
}

.pf-v5-pficon-severity-important:before {
  content: "";
}

.pf-v5-pficon-severity-minor:before {
  content: "";
}

.pf-v5-pficon-severity-moderate:before {
  content: "";
}

.pf-v5-pficon-severity-none:before {
  content: "";
}

.pf-v5-pficon-severity-undefined:before {
  content: "";
}

.pf-v5-pficon-spinner:before {
  content: "";
}

.pf-v5-pficon-spinner2:before {
  content: "";
}

.pf-v5-pficon-storage-domain:before {
  content: "";
}

.pf-v5-pficon-task:before {
  content: "";
}

.pf-v5-pficon-tenant:before {
  content: "";
}

.pf-v5-pficon-thumb-tack:before {
  content: "";
}

.pf-v5-pficon-topology:before {
  content: "";
}

.pf-v5-pficon-treeview:before {
  content: "";
}

.pf-v5-pficon-trend-down:before {
  content: "";
}

.pf-v5-pficon-trend-up:before {
  content: "";
}

.pf-v5-pficon-unknown:before {
  content: "";
}

.pf-v5-pficon-unlocked:before {
  content: "";
}

.pf-v5-pficon-unplugged:before {
  content: "";
}

.pf-v5-pficon-user:before {
  content: "";
}

.pf-v5-pficon-users:before {
  content: "";
}

.pf-v5-pficon-virtual-machine:before {
  content: "";
}

.pf-v5-pficon-volume:before {
  content: "";
}

.pf-v5-pficon-warning-triangle:before {
  content: "";
}

.pf-v5-pficon-zone:before {
  content: "";
}

.pf-v5-svg {
  width: 1em;
  height: 1em;
  vertical-align: -0.125em;
}

.pf-v5-c-about-modal-box {
  --pf-v5-c-about-modal-box--BackgroundImage: none;
  --pf-v5-c-about-modal-box--BackgroundColor: var(--pf-v5-global--palette--black-1000);
  --pf-v5-c-about-modal-box--BackgroundPosition: bottom right;
  --pf-v5-c-about-modal-box--BackgroundSize--min-width: 200px;
  --pf-v5-c-about-modal-box--BackgroundSize--width: 60%;
  --pf-v5-c-about-modal-box--BackgroundSize--max-width: 600px;
  --pf-v5-c-about-modal-box--BackgroundSize: clamp(var(--pf-v5-c-about-modal-box--BackgroundSize--min-width), var(--pf-v5-c-about-modal-box--BackgroundSize--width), var(--pf-v5-c-about-modal-box--BackgroundSize--max-width));
  --pf-v5-c-about-modal-box--Height: 100%;
  --pf-v5-c-about-modal-box--lg--Height: 47.625rem;
  --pf-v5-c-about-modal-box--Width: 100%;
  --pf-v5-c-about-modal-box--sm--GridTemplateColumns: 5fr 1fr;
  --pf-v5-c-about-modal-box--lg--GridTemplateColumns: 1fr .6fr;
  --pf-v5-c-about-modal-box--BackgroundPosition: bottom right;
  --pf-v5-c-about-modal-box__brand--PaddingTop: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-about-modal-box__brand--PaddingRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-about-modal-box__brand--PaddingLeft: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-about-modal-box__brand--PaddingBottom: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-about-modal-box__brand--sm--PaddingRight: var(--pf-v5-global--spacer--3xl);
  --pf-v5-c-about-modal-box__brand--sm--PaddingLeft: var(--pf-v5-global--spacer--3xl);
  --pf-v5-c-about-modal-box__brand--sm--PaddingBottom: var(--pf-v5-global--spacer--3xl);
  --pf-v5-c-about-modal-box__close--ZIndex: var(--pf-v5-global--ZIndex--2xl);
  --pf-v5-c-about-modal-box__close--PaddingTop: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-about-modal-box__close--PaddingRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-about-modal-box__close--PaddingBottom: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-about-modal-box__close--sm--PaddingBottom: var(--pf-v5-global--spacer--3xl);
  --pf-v5-c-about-modal-box__close--lg--PaddingRight: var(--pf-v5-global--spacer--3xl);
  --pf-v5-c-about-modal-box__close--c-button--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-about-modal-box__close--c-button--FontSize: var(--pf-v5-global--FontSize--xl);
  --pf-v5-c-about-modal-box__close--c-button--BorderRadius: var(--pf-v5-global--BorderRadius--lg);
  --pf-v5-c-about-modal-box__close--c-button--Width: calc(var(--pf-v5-c-about-modal-box__close--c-button--FontSize) * 2);
  --pf-v5-c-about-modal-box__close--c-button--Height: calc(var(--pf-v5-c-about-modal-box__close--c-button--FontSize) * 2);
  --pf-v5-c-about-modal-box__close--c-button--BackgroundColor: var(--pf-v5-global--palette--black-1000);
  --pf-v5-c-about-modal-box__close--c-button--hover--BackgroundColor: rgba(3, 3, 3, 0.4);
  --pf-v5-c-about-modal-box__brand-image--Height: 2.5rem;
  --pf-v5-c-about-modal-box__header--PaddingRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-about-modal-box__header--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-about-modal-box__header--PaddingLeft: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-about-modal-box__header--sm--PaddingRight: var(--pf-v5-global--spacer--3xl);
  --pf-v5-c-about-modal-box__header--sm--PaddingLeft: var(--pf-v5-global--spacer--3xl);
  --pf-v5-c-about-modal-box__strapline--PaddingTop: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-about-modal-box__strapline--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-about-modal-box__strapline--sm--PaddingTop: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-about-modal-box__content--MarginTop: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-about-modal-box__content--MarginRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-about-modal-box__content--MarginBottom: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-about-modal-box__content--MarginLeft: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-about-modal-box__content--sm--MarginTop: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-about-modal-box__content--sm--MarginRight: var(--pf-v5-global--spacer--3xl);
  --pf-v5-c-about-modal-box__content--sm--MarginBottom: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-about-modal-box__content--sm--MarginLeft: var(--pf-v5-global--spacer--3xl);
  color: var(--pf-v5-global--Color--100);
  position: relative;
  display: grid;
  grid-template-areas: "brand close" "header header" "content content";
  grid-template-rows: max-content max-content auto;
  width: var(--pf-v5-c-about-modal-box--Width);
  height: var(--pf-v5-c-about-modal-box--Height);
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--pf-v5-c-about-modal-box--BackgroundColor);
  background-image: var(--pf-v5-c-about-modal-box--BackgroundImage);
  background-repeat: no-repeat;
  background-position: var(--pf-v5-c-about-modal-box--BackgroundPosition);
  background-size: var(--pf-v5-c-about-modal-box--BackgroundSize);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-about-modal-box {
  --pf-v5-c-about-modal-box--BackgroundPosition: bottom left;
}

@media screen and (min-width: 576px) {
  .pf-v5-c-about-modal-box {
    --pf-v5-c-about-modal-box__brand--PaddingRight: var(--pf-v5-c-about-modal-box__brand--sm--PaddingRight);
    --pf-v5-c-about-modal-box__brand--PaddingLeft: var(--pf-v5-c-about-modal-box__brand--sm--PaddingLeft);
    --pf-v5-c-about-modal-box__brand--PaddingBottom: var(--pf-v5-c-about-modal-box__brand--sm--PaddingBottom);
  }
}

@media only screen and (min-width: 576px) {
  .pf-v5-c-about-modal-box {
    --pf-v5-c-about-modal-box__close--PaddingBottom: var(--pf-v5-c-about-modal-box__close--sm--PaddingBottom);
  }
}

@media only screen and (min-width: 992px) {
  .pf-v5-c-about-modal-box {
    --pf-v5-c-about-modal-box__close--PaddingRight: var(--pf-v5-c-about-modal-box__close--lg--PaddingRight);
  }
}

@media only screen and (min-width: 576px) {
  .pf-v5-c-about-modal-box {
    --pf-v5-c-about-modal-box__header--PaddingRight: var(--pf-v5-c-about-modal-box__header--sm--PaddingRight);
    --pf-v5-c-about-modal-box__header--PaddingLeft: var(--pf-v5-c-about-modal-box__header--sm--PaddingLeft);
  }
}

@media only screen and (min-width: 576px) {
  .pf-v5-c-about-modal-box {
    --pf-v5-c-about-modal-box__strapline--PaddingTop: var(--pf-v5-c-about-modal-box__strapline--sm--PaddingTop);
  }
}

@media only screen and (min-width: 576px) {
  .pf-v5-c-about-modal-box {
    --pf-v5-c-about-modal-box__content--MarginTop: var(--pf-v5-c-about-modal-box__content--sm--MarginTop);
    --pf-v5-c-about-modal-box__content--MarginRight: var(--pf-v5-c-about-modal-box__content--sm--MarginRight);
    --pf-v5-c-about-modal-box__content--MarginBottom: var(--pf-v5-c-about-modal-box__content--sm--MarginBottom);
    --pf-v5-c-about-modal-box__content--MarginLeft: var(--pf-v5-c-about-modal-box__content--sm--MarginLeft);
  }
}

@media only screen and (min-width: 576px) {
  .pf-v5-c-about-modal-box {
    grid-template-areas: "brand          close" "header         close" "content        close";
    grid-template-columns: var(--pf-v5-c-about-modal-box--sm--GridTemplateColumns);
  }
}

@media only screen and (min-width: 992px) {
  .pf-v5-c-about-modal-box {
    --pf-v5-c-about-modal-box--Height: var(--pf-v5-c-about-modal-box--lg--Height);
    --pf-v5-c-about-modal-box--Width: var(--pf-v5-c-about-modal-box--lg--Width);
    grid-template-rows: max-content max-content auto;
    grid-template-columns: var(--pf-v5-c-about-modal-box--lg--GridTemplateColumns);
  }
}

.pf-v5-c-about-modal-box__brand {
  display: flex;
  grid-area: brand;
  padding-block-start: var(--pf-v5-c-about-modal-box__brand--PaddingTop);
  padding-block-end: var(--pf-v5-c-about-modal-box__brand--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-about-modal-box__brand--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-about-modal-box__brand--PaddingRight);
}

.pf-v5-c-about-modal-box__brand-image {
  height: var(--pf-v5-c-about-modal-box__brand-image--Height);
}

.pf-v5-c-about-modal-box__header {
  display: flex;
  flex-direction: column;
  grid-area: header;
  padding-block-end: var(--pf-v5-c-about-modal-box__header--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-about-modal-box__header--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-about-modal-box__header--PaddingRight);
}

.pf-v5-c-about-modal-box__strapline {
  padding-block-start: var(--pf-v5-c-about-modal-box__strapline--PaddingTop);
  margin-block-start: auto;
  font-size: var(--pf-v5-c-about-modal-box__strapline--FontSize);
}

.pf-v5-c-about-modal-box__content {
  display: flex;
  flex-direction: column;
  grid-area: content;
  margin-block-start: var(--pf-v5-c-about-modal-box__content--MarginTop);
  margin-block-end: var(--pf-v5-c-about-modal-box__content--MarginBottom);
  margin-inline-start: var(--pf-v5-c-about-modal-box__content--MarginLeft);
  margin-inline-end: var(--pf-v5-c-about-modal-box__content--MarginRight);
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;
  word-break: break-word;
  -webkit-overflow-scrolling: touch;
}

@media screen and (min-width: 576px) {
  .pf-v5-c-about-modal-box__content {
    overflow: visible;
    overscroll-behavior: auto;
  }
}

.pf-v5-c-about-modal-box__close {
  position: sticky;
  inset-block-start: 0;
  display: flex;
  grid-area: close;
  align-items: flex-start;
  justify-content: flex-end;
  padding-block-start: var(--pf-v5-c-about-modal-box__close--PaddingTop);
  padding-block-end: var(--pf-v5-c-about-modal-box__close--PaddingBottom);
  padding-inline-end: var(--pf-v5-c-about-modal-box__close--PaddingRight);
}

@media only screen and (min-width: 576px) {
  .pf-v5-c-about-modal-box__close {
    grid-area: 1/2;
    justify-content: center;
  }
}

@media only screen and (min-width: 992px) {
  .pf-v5-c-about-modal-box__close {
    justify-content: flex-end;
  }
}

.pf-v5-c-about-modal-box__close .pf-v5-c-button.pf-m-plain {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--pf-v5-c-about-modal-box__close--c-button--Width);
  height: var(--pf-v5-c-about-modal-box__close--c-button--Height);
  padding: 0;
  font-size: var(--pf-v5-c-about-modal-box__close--c-button--FontSize);
  color: var(--pf-v5-c-about-modal-box__close--c-button--Color);
  background-color: var(--pf-v5-c-about-modal-box__close--c-button--BackgroundColor);
  border-radius: var(--pf-v5-c-about-modal-box__close--c-button--BorderRadius);
}

.pf-v5-c-about-modal-box__close .pf-v5-c-button.pf-m-plain:hover {
  --pf-v5-c-about-modal-box__close--c-button--BackgroundColor: var(--pf-v5-c-about-modal-box__close--c-button--hover--BackgroundColor);
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box {
  color: var(--pf-v5-global--Color--100);
}

.pf-v5-c-accordion {
  --pf-v5-c-accordion--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-accordion__toggle--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-accordion__toggle--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-accordion__toggle--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-accordion__toggle--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-accordion__toggle--before--Top: 0;
  --pf-v5-c-accordion__toggle--after--Top: 0;
  --pf-v5-c-accordion__toggle--after--BackgroundColor: transparent;
  --pf-v5-c-accordion__toggle--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-accordion__toggle--focus--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-accordion__toggle--active--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-accordion__toggle--before--Width: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-accordion__toggle--after--Width: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-accordion__toggle--m-expanded--after--BackgroundColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-accordion__toggle--BackgroundColor: transparent;
  --pf-v5-c-accordion__toggle--JustifyContent: space-between;
  --pf-v5-c-accordion__toggle--ColumnGap: 0;
  --pf-v5-c-accordion--m-toggle-start__toggle--JustifyContent: start;
  --pf-v5-c-accordion--m-toggle-start__toggle--ColumnGap: var(--pf-v5-global--spacer--md);
  --pf-v5-c-accordion__toggle-text--MaxWidth: calc(100% - var(--pf-v5-global--spacer--lg));
  --pf-v5-c-accordion__toggle--hover__toggle-text--Color: var(--pf-v5-global--link--Color);
  --pf-v5-c-accordion__toggle--active__toggle-text--Color: var(--pf-v5-global--link--Color);
  --pf-v5-c-accordion__toggle--active__toggle-text--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-accordion__toggle--focus__toggle-text--Color: var(--pf-v5-global--link--Color);
  --pf-v5-c-accordion__toggle--focus__toggle-text--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-accordion__toggle--m-expanded__toggle-text--Color: var(--pf-v5-global--link--Color);
  --pf-v5-c-accordion__toggle--m-expanded__toggle-text--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-accordion__toggle-icon--Transition: .2s ease-in 0s;
  --pf-v5-c-accordion__toggle--m-expanded__toggle-icon--Rotate: 90deg;
  --pf-v5-c-accordion__expandable-content--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-accordion__expandable-content--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-accordion__expandable-content--m-expanded__expandable-content-body--after--BackgroundColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-accordion__expandable-content--m-fixed--MaxHeight: 9.375rem;
  --pf-v5-c-accordion__expandable-content-body--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-accordion__expandable-content-body--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-accordion__expandable-content-body--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-accordion__expandable-content-body--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-accordion__expandable-content-body--expandable-content-body--PaddingTop: 0;
  --pf-v5-c-accordion__expandable-content-body--after--BackgroundColor: transparent;
  --pf-v5-c-accordion__expandable-content-body--after--Width: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-accordion__expandable-content-body--before--Top: 0;
  --pf-v5-c-accordion--m-display-lg__toggle--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-accordion--m-display-lg__toggle--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-accordion--m-display-lg__toggle--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-accordion--m-display-lg__toggle--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-accordion--m-display-lg__toggle--FontFamily: var(--pf-v5-global--FontFamily--heading);
  --pf-v5-c-accordion--m-display-lg__toggle--FontSize: var(--pf-v5-global--FontSize--xl);
  --pf-v5-c-accordion--m-display-lg__toggle--hover__toggle-text--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-accordion--m-display-lg__toggle--active__toggle-text--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-accordion--m-display-lg__toggle--active__toggle-text--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-accordion--m-display-lg__toggle--focus__toggle-text--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-accordion--m-display-lg__toggle--focus__toggle-text--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-accordion--m-display-lg__toggle--m-expanded__toggle-text--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-accordion--m-display-lg__toggle--m-expanded__toggle-text--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-accordion--m-display-lg__expandable-content--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-accordion--m-display-lg__expandable-content--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-accordion--m-display-lg__expandable-content-body--PaddingTop: 0;
  --pf-v5-c-accordion--m-display-lg__expandable-content-body--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-accordion--m-display-lg__expandable-content-body--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-accordion--m-display-lg__expandable-content-body--last-child--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-accordion--m-display-lg__expandable-content-body--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-accordion--m-bordered--BorderTopWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-accordion--m-bordered--BorderTopColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-accordion--m-bordered__toggle--after--Top: calc(-1 * var(--pf-v5-global--BorderWidth--sm));
  --pf-v5-c-accordion--m-bordered__toggle--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-accordion--m-bordered__toggle--before--BorderTopWidth: 0;
  --pf-v5-c-accordion--m-bordered__toggle--before--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-accordion--m-bordered__expandable-content--m-expanded__expandable-content-body--last-child--before--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-accordion--m-bordered__expandable-content--m-expanded__expandable-content-body--last-child--before--BorderBottomColor: var(--pf-v5-global--BorderColor--100);
  color: var(--pf-v5-global--Color--100);
  background-color: var(--pf-v5-c-accordion--BackgroundColor);
}

.pf-v5-c-accordion.pf-m-toggle-start {
  --pf-v5-c-accordion__toggle--JustifyContent: var(--pf-v5-c-accordion--m-toggle-start__toggle--JustifyContent);
  --pf-v5-c-accordion__toggle--ColumnGap: var(--pf-v5-c-accordion--m-toggle-start__toggle--ColumnGap);
}

.pf-v5-c-accordion.pf-m-display-lg {
  --pf-v5-c-accordion__toggle--PaddingTop: var(--pf-v5-c-accordion--m-display-lg__toggle--PaddingTop);
  --pf-v5-c-accordion__toggle--PaddingRight: var(--pf-v5-c-accordion--m-display-lg__toggle--PaddingRight);
  --pf-v5-c-accordion__toggle--PaddingBottom: var(--pf-v5-c-accordion--m-display-lg__toggle--PaddingBottom);
  --pf-v5-c-accordion__toggle--PaddingLeft: var(--pf-v5-c-accordion--m-display-lg__toggle--PaddingLeft);
  --pf-v5-c-accordion__toggle--FontFamily: var(--pf-v5-c-accordion--m-display-lg__toggle--FontFamily);
  --pf-v5-c-accordion__toggle--FontSize: var(--pf-v5-c-accordion--m-display-lg__toggle--FontSize);
  --pf-v5-c-accordion__toggle--hover__toggle-text--Color: var(--pf-v5-c-accordion--m-display-lg__toggle--hover__toggle-text--Color);
  --pf-v5-c-accordion__toggle--active__toggle-text--Color: var(--pf-v5-c-accordion--m-display-lg__toggle--active__toggle-text--Color);
  --pf-v5-c-accordion__toggle--active__toggle-text--FontWeight: var(--pf-v5-c-accordion--m-display-lg__toggle--active__toggle-text--FontWeight);
  --pf-v5-c-accordion__toggle--focus__toggle-text--Color: var(--pf-v5-c-accordion--m-display-lg__toggle--focus__toggle-text--Color);
  --pf-v5-c-accordion__toggle--focus__toggle-text--FontWeight: var(--pf-v5-c-accordion--m-display-lg__toggle--focus__toggle-text--FontWeight);
  --pf-v5-c-accordion__toggle--m-expanded__toggle-text--Color: var(--pf-v5-c-accordion--m-display-lg__toggle--m-expanded__toggle-text--Color);
  --pf-v5-c-accordion__toggle--m-expanded__toggle-text--FontWeight: var(--pf-v5-c-accordion--m-display-lg__toggle--m-expanded__toggle-text--FontWeight);
  --pf-v5-c-accordion__expandable-content-body--PaddingTop: var(--pf-v5-c-accordion--m-display-lg__expandable-content-body--PaddingTop);
  --pf-v5-c-accordion__expandable-content-body--PaddingRight: var(--pf-v5-c-accordion--m-display-lg__expandable-content-body--PaddingRight);
  --pf-v5-c-accordion__expandable-content-body--PaddingBottom: var(--pf-v5-c-accordion--m-display-lg__expandable-content-body--PaddingBottom);
  --pf-v5-c-accordion__expandable-content-body--PaddingLeft: var(--pf-v5-c-accordion--m-display-lg__expandable-content-body--PaddingLeft);
  --pf-v5-c-accordion__expandable-content--FontSize: var(--pf-v5-c-accordion--m-display-lg__expandable-content--FontSize);
  --pf-v5-c-accordion__expandable-content--Color: var(--pf-v5-c-accordion--m-display-lg__expandable-content--Color);
}

.pf-v5-c-accordion.pf-m-display-lg .pf-v5-c-accordion__expandable-content-body:last-child {
  --pf-v5-c-accordion__expandable-content-body--PaddingBottom: var(--pf-v5-c-accordion--m-display-lg__expandable-content-body--last-child--PaddingBottom);
}

.pf-v5-c-accordion.pf-m-bordered {
  --pf-v5-c-accordion__toggle--after--Top: var(--pf-v5-c-accordion--m-bordered__toggle--after--Top);
  border-block-start: var(--pf-v5-c-accordion--m-bordered--BorderTopWidth) solid var(--pf-v5-c-accordion--m-bordered--BorderTopColor);
}

.pf-v5-c-accordion.pf-m-bordered .pf-v5-c-accordion__toggle::before {
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  content: "";
  border-color: var(--pf-v5-c-accordion--m-bordered__toggle--before--BorderColor);
  border-style: solid;
  border-block-start-width: var(--pf-v5-c-accordion--m-bordered__toggle--before--BorderTopWidth);
  border-block-end-width: var(--pf-v5-c-accordion--m-bordered__toggle--before--BorderBottomWidth);
  border-inline-start-width: 0;
  border-inline-end-width: 0;
}

.pf-v5-c-accordion.pf-m-bordered .pf-v5-c-accordion__toggle.pf-m-expanded {
  --pf-v5-c-accordion--m-bordered__toggle--before--BorderBottomWidth: 0;
}

.pf-v5-c-accordion.pf-m-bordered .pf-v5-c-accordion__expandable-content.pf-m-expanded .pf-v5-c-accordion__expandable-content-body:last-child::before {
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  pointer-events: none;
  content: "";
  border-block-end: var(--pf-v5-c-accordion--m-bordered__expandable-content--m-expanded__expandable-content-body--last-child--before--BorderBottomWidth) solid var(--pf-v5-c-accordion--m-bordered__expandable-content--m-expanded__expandable-content-body--last-child--before--BorderBottomColor);
}

.pf-v5-c-accordion__toggle {
  position: relative;
  display: flex;
  column-gap: var(--pf-v5-c-accordion__toggle--ColumnGap);
  align-items: center;
  justify-content: var(--pf-v5-c-accordion__toggle--JustifyContent);
  width: 100%;
  padding-block-start: var(--pf-v5-c-accordion__toggle--PaddingTop);
  padding-block-end: var(--pf-v5-c-accordion__toggle--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-accordion__toggle--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-accordion__toggle--PaddingRight);
  font-family: var(--pf-v5-c-accordion__toggle--FontFamily, inherit);
  font-size: var(--pf-v5-c-accordion__toggle--FontSize, inherit);
  background-color: var(--pf-v5-c-accordion__toggle--BackgroundColor);
  border: 0;
}

.pf-v5-c-accordion__toggle::after {
  position: absolute;
  inset-block-start: var(--pf-v5-c-accordion__toggle--after--Top);
  inset-block-end: 0;
  inset-inline-start: 0;
  width: var(--pf-v5-c-accordion__toggle--after--Width);
  content: "";
  background-color: var(--pf-v5-c-accordion__toggle--after--BackgroundColor);
}

.pf-v5-c-accordion__toggle.pf-m-expanded {
  --pf-v5-c-accordion__toggle--after--BackgroundColor: var(--pf-v5-c-accordion__toggle--m-expanded--after--BackgroundColor);
}

.pf-v5-c-accordion__toggle.pf-m-expanded .pf-v5-c-accordion__toggle-text {
  font-weight: var(--pf-v5-c-accordion__toggle--m-expanded__toggle-text--FontWeight);
  color: var(--pf-v5-c-accordion__toggle--m-expanded__toggle-text--Color);
}

.pf-v5-c-accordion__toggle.pf-m-expanded .pf-v5-c-accordion__toggle-icon {
  transform: rotate(var(--pf-v5-c-accordion__toggle--m-expanded__toggle-icon--Rotate));
}

.pf-v5-c-accordion__toggle:hover {
  background-color: var(--pf-v5-c-accordion__toggle--hover--BackgroundColor);
}

.pf-v5-c-accordion__toggle:hover .pf-v5-c-accordion__toggle-text {
  color: var(--pf-v5-c-accordion__toggle--hover__toggle-text--Color);
}

.pf-v5-c-accordion__toggle:focus {
  background-color: var(--pf-v5-c-accordion__toggle--focus--BackgroundColor);
}

.pf-v5-c-accordion__toggle:focus .pf-v5-c-accordion__toggle-text {
  font-weight: var(--pf-v5-c-accordion__toggle--focus__toggle-text--FontWeight);
  color: var(--pf-v5-c-accordion__toggle--focus__toggle-text--Color);
}

.pf-v5-c-accordion__toggle:active {
  background-color: var(--pf-v5-c-accordion__toggle--active--BackgroundColor);
}

.pf-v5-c-accordion__toggle:active .pf-v5-c-accordion__toggle-text {
  font-weight: var(--pf-v5-c-accordion__toggle--active__toggle-text--FontWeight);
  color: var(--pf-v5-c-accordion__toggle--active__toggle-text--Color);
}

.pf-v5-c-accordion__toggle-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: var(--pf-v5-c-accordion__toggle-text--MaxWidth);
}

.pf-v5-c-accordion__toggle-icon {
  transition: var(--pf-v5-c-accordion__toggle-icon--Transition);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-accordion__toggle-icon {
  scale: -1 1;
}

.pf-v5-c-accordion__expandable-content {
  font-size: var(--pf-v5-c-accordion__expandable-content--FontSize);
  color: var(--pf-v5-c-accordion__expandable-content--Color);
}

.pf-v5-c-accordion__expandable-content.pf-m-fixed {
  max-height: var(--pf-v5-c-accordion__expandable-content--m-fixed--MaxHeight);
  overflow-y: auto;
}

.pf-v5-c-accordion__expandable-content.pf-m-expanded {
  --pf-v5-c-accordion__expandable-content-body--after--BackgroundColor: var(--pf-v5-c-accordion__expandable-content--m-expanded__expandable-content-body--after--BackgroundColor);
}

.pf-v5-c-accordion__expandable-content-body {
  position: relative;
  padding-block-start: var(--pf-v5-c-accordion__expandable-content-body--PaddingTop);
  padding-block-end: var(--pf-v5-c-accordion__expandable-content-body--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-accordion__expandable-content-body--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-accordion__expandable-content-body--PaddingRight);
}

.pf-v5-c-accordion__expandable-content-body::after {
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  width: var(--pf-v5-c-accordion__expandable-content-body--after--Width);
  content: "";
  background-color: var(--pf-v5-c-accordion__expandable-content-body--after--BackgroundColor);
}

.pf-v5-c-accordion__expandable-content-body+.pf-v5-c-accordion__expandable-content-body {
  --pf-v5-c-accordion__expandable-content-body--PaddingTop: var(--pf-v5-c-accordion__expandable-content-body--expandable-content-body--PaddingTop);
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-accordion {
  --pf-v5-c-accordion__toggle--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-accordion__toggle--focus--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-accordion__toggle--active--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
}

.pf-v5-c-action-list {
  --pf-v5-c-action-list--m-icon--spacer: 0;
  --pf-v5-c-action-list--child--spacer-base: var(--pf-v5-global--spacer--md);
  --pf-v5-c-action-list--group--spacer-base: var(--pf-v5-global--spacer--2xl);
}

.pf-v5-c-action-list,
.pf-v5-c-action-list__group {
  --pf-v5-c-action-list--child--spacer: var(--pf-v5-c-action-list--child--spacer-base);
  --pf-v5-c-action-list--group--spacer: var(--pf-v5-c-action-list--group--spacer-base);
  display: flex;
  align-items: center;
}

.pf-v5-c-action-list>*+*,
.pf-v5-c-action-list__group>*+* {
  margin-inline-start: var(--pf-v5-c-action-list--child--spacer);
}

.pf-v5-c-action-list>*+.pf-v5-c-action-list__group,
.pf-v5-c-action-list .pf-v5-c-action-list__group+*,
.pf-v5-c-action-list__group>*+.pf-v5-c-action-list__group,
.pf-v5-c-action-list__group .pf-v5-c-action-list__group+* {
  margin-inline-start: var(--pf-v5-c-action-list--group--spacer);
}

.pf-v5-c-action-list.pf-m-icons,
.pf-v5-c-action-list__group.pf-m-icons {
  --pf-v5-c-action-list--child--spacer: var(--pf-v5-c-action-list--m-icon--spacer);
}

.pf-v5-c-alert {
  --pf-v5-c-alert--BoxShadow: var(--pf-v5-global--BoxShadow--lg);
  --pf-v5-c-alert--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-alert--GridTemplateColumns: max-content 1fr max-content;
  --pf-v5-c-alert--GridTemplateAreas: "icon title action" ". description description" ". actiongroup actiongroup";
  --pf-v5-c-alert--BorderTopWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-alert--BorderTopColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-alert--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-alert--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-alert--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-alert--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-alert__FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-alert__toggle--MarginTop: calc(-1 * var(--pf-v5-global--spacer--form-element) - 0.0625rem);
  --pf-v5-c-alert__toggle--MarginBottom: calc(-1 * var(--pf-v5-global--spacer--form-element));
  --pf-v5-c-alert__toggle--MarginLeft: calc(-1 * var(--pf-v5-global--spacer--md));
  --pf-v5-c-alert__toggle-icon--Rotate: 0;
  --pf-v5-c-alert__toggle-icon--Transition: var(--pf-v5-global--Transition);
  --pf-v5-c-alert__icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-alert__icon--MarginTop: 0.0625rem;
  --pf-v5-c-alert__icon--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-alert__icon--FontSize: var(--pf-v5-global--icon--FontSize--md);
  --pf-v5-c-alert__title--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-alert__title--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-alert__title--max-lines: 1;
  --pf-v5-c-alert__action--MarginTop: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-alert__action--MarginBottom: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-alert__action--TranslateY: 0.125rem;
  --pf-v5-c-alert__action--MarginRight: calc(var(--pf-v5-global--spacer--sm) * -1);
  --pf-v5-c-alert__description--PaddingTop: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-alert__action-group--PaddingTop-base: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-alert__action-group--PaddingTop: var(--pf-v5-c-alert__action-group--PaddingTop-base);
  --pf-v5-c-alert__description--action-group--PaddingTop-base: var(--pf-v5-global--spacer--md);
  --pf-v5-c-alert__description--action-group--PaddingTop: var(--pf-v5-c-alert__description--action-group--PaddingTop-base);
  --pf-v5-c-alert__action-group__c-button--not-last-child--MarginRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-alert--m-custom--BorderTopColor: var(--pf-v5-global--custom-color--200);
  --pf-v5-c-alert--m-custom__icon--Color: var(--pf-v5-global--custom-color--200);
  --pf-v5-c-alert--m-custom__title--Color: var(--pf-v5-global--custom-color--300);
  --pf-v5-c-alert--m-success--BorderTopColor: var(--pf-v5-global--success-color--100);
  --pf-v5-c-alert--m-success__icon--Color: var(--pf-v5-global--success-color--100);
  --pf-v5-c-alert--m-success__title--Color: var(--pf-v5-global--success-color--200);
  --pf-v5-c-alert--m-danger--BorderTopColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-alert--m-danger__icon--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-alert--m-danger__title--Color: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-alert--m-warning--BorderTopColor: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-alert--m-warning__icon--Color: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-alert--m-warning__title--Color: var(--pf-v5-global--warning-color--200);
  --pf-v5-c-alert--m-info--BorderTopColor: var(--pf-v5-global--info-color--100);
  --pf-v5-c-alert--m-info__icon--Color: var(--pf-v5-global--info-color--100);
  --pf-v5-c-alert--m-info__title--Color: var(--pf-v5-global--info-color--200);
  --pf-v5-c-alert--m-inline--BoxShadow: none;
  --pf-v5-c-alert--m-inline--m-custom--BackgroundColor: var(--pf-v5-global--palette--cyan-50);
  --pf-v5-c-alert--m-inline--m-success--BackgroundColor: var(--pf-v5-global--palette--green-50);
  --pf-v5-c-alert--m-inline--m-danger--BackgroundColor: var(--pf-v5-global--palette--red-50);
  --pf-v5-c-alert--m-inline--m-warning--BackgroundColor: var(--pf-v5-global--palette--gold-50);
  --pf-v5-c-alert--m-inline--m-info--BackgroundColor: var(--pf-v5-global--palette--blue-50);
  --pf-v5-c-alert--m-inline--m-plain--BorderTopWidth: 0;
  --pf-v5-c-alert--m-inline--m-plain--BackgroundColor: transparent;
  --pf-v5-c-alert--m-inline--m-plain--PaddingTop: 0;
  --pf-v5-c-alert--m-inline--m-plain--PaddingRight: 0;
  --pf-v5-c-alert--m-inline--m-plain--PaddingBottom: 0;
  --pf-v5-c-alert--m-inline--m-plain--PaddingLeft: 0;
  --pf-v5-c-alert--m-expandable--GridTemplateColumns: auto max-content 1fr max-content;
  --pf-v5-c-alert--m-expandable--GridTemplateAreas: "toggle icon title action" ". . description description" ". . actiongroup actiongroup";
  --pf-v5-c-alert--m-expandable__description--action-group--PaddingTop: var(--pf-v5-c-alert__action-group--PaddingTop-base);
  --pf-v5-c-alert--m-expanded__toggle-icon--Rotate: 90deg;
  --pf-v5-c-alert--m-expanded__description--action-group--PaddingTop: var(--pf-v5-c-alert__description--action-group--PaddingTop-base);
  color: var(--pf-v5-global--Color--100);
  position: relative;
  display: grid;
  grid-template-areas: var(--pf-v5-c-alert--GridTemplateAreas);
  grid-template-columns: var(--pf-v5-c-alert--GridTemplateColumns);
  padding-block-start: var(--pf-v5-c-alert--PaddingTop);
  padding-block-end: var(--pf-v5-c-alert--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-alert--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-alert--PaddingRight);
  font-size: var(--pf-v5-c-alert__FontSize);
  background-color: var(--pf-v5-c-alert--BackgroundColor);
  border-block-start: var(--pf-v5-c-alert--BorderTopWidth) solid var(--pf-v5-c-alert--BorderTopColor);
  box-shadow: var(--pf-v5-c-alert--BoxShadow);
}

.pf-v5-c-alert.pf-m-custom {
  --pf-v5-c-alert--BorderTopColor: var(--pf-v5-c-alert--m-custom--BorderTopColor);
  --pf-v5-c-alert__icon--Color: var(--pf-v5-c-alert--m-custom__icon--Color);
  --pf-v5-c-alert__title--Color: var(--pf-v5-c-alert--m-custom__title--Color);
  --pf-v5-c-alert--m-inline--BackgroundColor: var(--pf-v5-c-alert--m-inline--m-custom--BackgroundColor);
}

.pf-v5-c-alert.pf-m-success {
  --pf-v5-c-alert--BorderTopColor: var(--pf-v5-c-alert--m-success--BorderTopColor);
  --pf-v5-c-alert__icon--Color: var(--pf-v5-c-alert--m-success__icon--Color);
  --pf-v5-c-alert__title--Color: var(--pf-v5-c-alert--m-success__title--Color);
  --pf-v5-c-alert--m-inline--BackgroundColor: var(--pf-v5-c-alert--m-inline--m-success--BackgroundColor);
}

.pf-v5-c-alert.pf-m-danger {
  --pf-v5-c-alert--BorderTopColor: var(--pf-v5-c-alert--m-danger--BorderTopColor);
  --pf-v5-c-alert__icon--Color: var(--pf-v5-c-alert--m-danger__icon--Color);
  --pf-v5-c-alert__title--Color: var(--pf-v5-c-alert--m-danger__title--Color);
  --pf-v5-c-alert--m-inline--BackgroundColor: var(--pf-v5-c-alert--m-inline--m-danger--BackgroundColor);
}

.pf-v5-c-alert.pf-m-warning {
  --pf-v5-c-alert--BorderTopColor: var(--pf-v5-c-alert--m-warning--BorderTopColor);
  --pf-v5-c-alert__icon--Color: var(--pf-v5-c-alert--m-warning__icon--Color);
  --pf-v5-c-alert__title--Color: var(--pf-v5-c-alert--m-warning__title--Color);
  --pf-v5-c-alert--m-inline--BackgroundColor: var(--pf-v5-c-alert--m-inline--m-warning--BackgroundColor);
}

.pf-v5-c-alert.pf-m-info {
  --pf-v5-c-alert--BorderTopColor: var(--pf-v5-c-alert--m-info--BorderTopColor);
  --pf-v5-c-alert__icon--Color: var(--pf-v5-c-alert--m-info__icon--Color);
  --pf-v5-c-alert__title--Color: var(--pf-v5-c-alert--m-info__title--Color);
  --pf-v5-c-alert--m-inline--BackgroundColor: var(--pf-v5-c-alert--m-inline--m-info--BackgroundColor);
}

.pf-v5-c-alert.pf-m-inline {
  --pf-v5-c-alert--BoxShadow: var(--pf-v5-c-alert--m-inline--BoxShadow);
  --pf-v5-c-alert--BackgroundColor: var(--pf-v5-c-alert--m-inline--BackgroundColor);
}

.pf-v5-c-alert.pf-m-plain {
  --pf-v5-c-alert--BorderTopWidth: var(--pf-v5-c-alert--m-inline--m-plain--BorderTopWidth);
  --pf-v5-c-alert--BackgroundColor: var(--pf-v5-c-alert--m-inline--m-plain--BackgroundColor);
  --pf-v5-c-alert--PaddingTop: var(--pf-v5-c-alert--m-inline--m-plain--PaddingTop);
  --pf-v5-c-alert--PaddingRight: var(--pf-v5-c-alert--m-inline--m-plain--PaddingRight);
  --pf-v5-c-alert--PaddingBottom: var(--pf-v5-c-alert--m-inline--m-plain--PaddingBottom);
  --pf-v5-c-alert--PaddingLeft: var(--pf-v5-c-alert--m-inline--m-plain--PaddingLeft);
}

.pf-v5-c-alert.pf-m-expandable {
  --pf-v5-c-alert--GridTemplateColumns: var(--pf-v5-c-alert--m-expandable--GridTemplateColumns);
  --pf-v5-c-alert--GridTemplateAreas: var(--pf-v5-c-alert--m-expandable--GridTemplateAreas);
  --pf-v5-c-alert__description--action-group--PaddingTop: var(--pf-v5-c-alert--m-expandable__description--action-group--PaddingTop);
}

.pf-v5-c-alert.pf-m-expanded {
  --pf-v5-c-alert__toggle-icon--Rotate: var(--pf-v5-c-alert--m-expanded__toggle-icon--Rotate);
  --pf-v5-c-alert__description--action-group--PaddingTop: var(--pf-v5-c-alert--m-expanded__description--action-group--PaddingTop);
}

.pf-v5-c-alert__toggle {
  margin-block-start: var(--pf-v5-c-alert__toggle--MarginTop);
  margin-block-end: var(--pf-v5-c-alert__toggle--MarginBottom);
  margin-inline-start: var(--pf-v5-c-alert__toggle--MarginLeft);
}

.pf-v5-c-alert__toggle-icon {
  display: inline-block;
  transition: var(--pf-v5-c-alert__toggle-icon--Transition);
  transform: rotate(var(--pf-v5-c-alert__toggle-icon--Rotate));
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-alert__toggle-icon {
  scale: -1 1;
}

.pf-v5-c-alert__icon {
  display: flex;
  grid-area: icon;
  margin-block-start: var(--pf-v5-c-alert__icon--MarginTop);
  margin-inline-end: var(--pf-v5-c-alert__icon--MarginRight);
  font-size: var(--pf-v5-c-alert__icon--FontSize);
  color: var(--pf-v5-c-alert__icon--Color);
}

.pf-v5-c-alert__title {
  grid-area: title;
  font-weight: var(--pf-v5-c-alert__title--FontWeight);
  color: var(--pf-v5-c-alert__title--Color);
  word-break: break-word;
}

.pf-v5-c-alert__title.pf-m-truncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--pf-v5-c-alert__title--max-lines);
  overflow: hidden;
}

.pf-v5-c-alert__description {
  grid-area: description;
  padding-block-start: var(--pf-v5-c-alert__description--PaddingTop);
  word-break: break-word;
}

.pf-v5-c-alert__description+.pf-v5-c-alert__action-group {
  --pf-v5-c-alert__action-group--PaddingTop: var(--pf-v5-c-alert__description--action-group--PaddingTop);
}

.pf-v5-c-alert__action {
  grid-area: action;
  margin-block-start: var(--pf-v5-c-alert__action--MarginTop);
  margin-block-end: var(--pf-v5-c-alert__action--MarginBottom);
  margin-inline-end: var(--pf-v5-c-alert__action--MarginRight);
  transform: translateY(var(--pf-v5-c-alert__action--TranslateY));
}

.pf-v5-c-alert__action>.pf-v5-c-button {
  --pf-v5-c-button--LineHeight: 1;
}

.pf-v5-c-alert__action-group {
  grid-area: actiongroup;
  padding-block-start: var(--pf-v5-c-alert__action-group--PaddingTop);
}

.pf-v5-c-alert__action-group>.pf-v5-c-button {
  --pf-v5-c-button--m-link--m-inline--hover--TextDecoration: none;
}

.pf-v5-c-alert__action-group>.pf-v5-c-button:not(:last-child) {
  margin-inline-end: var(--pf-v5-c-alert__action-group__c-button--not-last-child--MarginRight);
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-alert {
  --pf-v5-c-alert--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-alert--m-custom__title--Color: var(--pf-v5-global--custom-color--200);
  --pf-v5-c-alert--m-success__title--Color: var(--pf-v5-global--success-color--100);
  --pf-v5-c-alert--m-danger__title--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-alert--m-warning__title--Color: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-alert--m-info__title--Color: var(--pf-v5-global--info-color--100);
  --pf-v5-c-alert--m-inline--m-custom--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-alert--m-inline--m-success--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-alert--m-inline--m-danger--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-alert--m-inline--m-warning--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-alert--m-inline--m-info--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
}

.pf-v5-c-alert-group {
  --pf-v5-c-alert-group__item--MarginTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-alert-group--m-toast--Top: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-alert-group--m-toast--Right: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-alert-group--m-toast--MaxWidth: 37.5rem;
  --pf-v5-c-alert-group--m-toast--ZIndex: var(--pf-v5-global--ZIndex--2xl);
  --pf-v5-c-alert-group__overflow-button--BorderWidth: 0;
  --pf-v5-c-alert-group__overflow-button--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-alert-group__overflow-button--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-alert-group__overflow-button--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-alert-group__overflow-button--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-alert-group__overflow-button--Color: var(--pf-v5-global--link--Color);
  --pf-v5-c-alert-group__overflow-button--BoxShadow: var(--pf-v5-global--BoxShadow--lg);
  --pf-v5-c-alert-group__overflow-button--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-alert-group__overflow-button--hover--Color: var(--pf-v5-global--link--Color--hover);
  --pf-v5-c-alert-group__overflow-button--hover--BoxShadow: var(--pf-v5-global--BoxShadow--lg), var(--pf-v5-global--BoxShadow--lg-bottom);
  --pf-v5-c-alert-group__overflow-button--focus--Color: var(--pf-v5-global--link--Color--hover);
  --pf-v5-c-alert-group__overflow-button--focus--BoxShadow: var(--pf-v5-global--BoxShadow--lg), var(--pf-v5-global--BoxShadow--lg-bottom);
  --pf-v5-c-alert-group__overflow-button--active--Color: var(--pf-v5-global--link--Color--hover);
  --pf-v5-c-alert-group__overflow-button--active--BoxShadow: var(--pf-v5-global--BoxShadow--lg), var(--pf-v5-global--BoxShadow--lg-bottom);
}

.pf-v5-c-alert-group>*+* {
  margin-block-start: var(--pf-v5-c-alert-group__item--MarginTop);
}

.pf-v5-c-alert-group.pf-m-toast {
  position: fixed;
  inset-block-start: var(--pf-v5-c-alert-group--m-toast--Top);
  inset-inline-end: var(--pf-v5-c-alert-group--m-toast--Right);
  z-index: var(--pf-v5-c-alert-group--m-toast--ZIndex);
  width: calc(100% - var(--pf-v5-c-alert-group--m-toast--Right)*2);
  max-width: var(--pf-v5-c-alert-group--m-toast--MaxWidth);
}

.pf-v5-c-alert-group__overflow-button {
  position: relative;
  width: 100%;
  padding-block-start: var(--pf-v5-c-alert-group__overflow-button--PaddingTop);
  padding-block-end: var(--pf-v5-c-alert-group__overflow-button--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-alert-group__overflow-button--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-alert-group__overflow-button--PaddingRight);
  color: var(--pf-v5-c-alert-group__overflow-button--Color);
  background-color: var(--pf-v5-c-alert-group__overflow-button--BackgroundColor);
  border-width: var(--pf-v5-c-alert-group__overflow-button--BorderWidth);
  box-shadow: var(--pf-v5-c-alert-group__overflow-button--BoxShadow);
}

.pf-v5-c-alert-group__overflow-button:hover {
  --pf-v5-c-alert-group__overflow-button--Color: var(--pf-v5-c-alert-group__overflow-button--hover--Color);
  --pf-v5-c-alert-group__overflow-button--BoxShadow: var(--pf-v5-c-alert-group__overflow-button--hover--BoxShadow);
}

.pf-v5-c-alert-group__overflow-button:focus {
  --pf-v5-c-alert-group__overflow-button--Color: var(--pf-v5-c-alert-group__overflow-button--focus--Color);
  --pf-v5-c-alert-group__overflow-button--BoxShadow: var(--pf-v5-c-alert-group__overflow-button--focus--BoxShadow);
}

.pf-v5-c-alert-group__overflow-button:active {
  --pf-v5-c-alert-group__overflow-button--Color: var(--pf-v5-c-alert-group__overflow-button--active--Color);
  --pf-v5-c-alert-group__overflow-button--BoxShadow: var(--pf-v5-c-alert-group__overflow-button--active--BoxShadow);
}

.pf-v5-c-app-launcher {
  --pf-v5-c-app-launcher__menu--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-c-app-launcher__menu--BoxShadow: var(--pf-v5-global--BoxShadow--md);
  --pf-v5-c-app-launcher__menu--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-app-launcher__menu--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-app-launcher__menu--Top: calc(100% + var(--pf-v5-global--spacer--xs));
  --pf-v5-c-app-launcher__menu--ZIndex: var(--pf-v5-global--ZIndex--sm);
  --pf-v5-c-app-launcher--m-top__menu--Top: 0;
  --pf-v5-c-app-launcher--m-top__menu--TranslateY: calc(-100% - var(--pf-v5-global--spacer--xs));
  --pf-v5-c-app-launcher__toggle--PaddingTop: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-app-launcher__toggle--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-app-launcher__toggle--PaddingBottom: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-app-launcher__toggle--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-app-launcher__toggle--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-app-launcher__toggle--hover--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-app-launcher__toggle--active--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-app-launcher__toggle--focus--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-app-launcher__toggle--disabled--Color: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-app-launcher__toggle--m-expanded--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-app-launcher__toggle--BackgroundColor: transparent;
  --pf-v5-c-app-launcher__menu-search--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-app-launcher__menu-search--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-app-launcher__menu-search--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-app-launcher__menu-search--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-app-launcher__menu-search--BottomBorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-app-launcher__menu-search--BottomBorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-app-launcher__menu-search--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-app-launcher__menu-item--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-app-launcher__menu-item--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-app-launcher__menu-item--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-app-launcher__menu-item--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-app-launcher__menu-item--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-app-launcher__menu-item--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-app-launcher__menu-item--Width: 100%;
  --pf-v5-c-app-launcher__menu-item--disabled--Color: var(--pf-v5-global--Color--dark-200);
  --pf-v5-c-app-launcher__menu-item--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-300);
  --pf-v5-c-app-launcher__menu-item--m-link--PaddingRight: 0;
  --pf-v5-c-app-launcher__menu-item--m-link--hover--BackgroundColor: transparent;
  --pf-v5-c-app-launcher__menu-item--m-action--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-app-launcher__menu-item--m-action--Width: auto;
  --pf-v5-c-app-launcher__menu-item--m-action--FontSize: var(--pf-v5-global--icon--FontSize--sm);
  --pf-v5-c-app-launcher__menu-item--m-action--hover--BackgroundColor: transparent;
  --pf-v5-c-app-launcher__menu-item--hover__menu-item--m-action--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-app-launcher__menu-item--m-action--hover--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-app-launcher__menu-item--m-action--disabled--Color: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-app-launcher__menu-item--m-favorite__menu-item--m-action--Color: var(--pf-v5-global--palette--gold-400);
  --pf-v5-c-app-launcher__menu-item--m-favorite__menu-item--m-action--hover--Color: var(--pf-v5-global--palette--gold-500);
  --pf-v5-c-app-launcher__menu-item-icon--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-app-launcher__menu-item-icon--Width: var(--pf-v5-global--icon--FontSize--lg);
  --pf-v5-c-app-launcher__menu-item-icon--Height: var(--pf-v5-global--icon--FontSize--lg);
  --pf-v5-c-app-launcher__menu-item-external-icon--Color: var(--pf-v5-global--link--Color);
  --pf-v5-c-app-launcher__menu-item-external-icon--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-app-launcher__menu-item-external-icon--TranslateY: -0.0625rem;
  --pf-v5-c-app-launcher__menu-item-external-icon--FontSize: var(--pf-v5-global--icon--FontSize--sm);
  --pf-v5-c-app-launcher__group--group--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-app-launcher__group-title--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-app-launcher__group-title--PaddingRight: var(--pf-v5-c-app-launcher__menu-item--PaddingRight);
  --pf-v5-c-app-launcher__group-title--PaddingBottom: var(--pf-v5-c-app-launcher__menu-item--PaddingBottom);
  --pf-v5-c-app-launcher__group-title--PaddingLeft: var(--pf-v5-c-app-launcher__menu-item--PaddingLeft);
  --pf-v5-c-app-launcher__group-title--FontSize: var(--pf-v5-global--FontSize--xs);
  --pf-v5-c-app-launcher__group-title--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-app-launcher__group-title--Color: var(--pf-v5-global--Color--dark-200);
  --pf-v5-c-app-launcher--c-divider--MarginTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-app-launcher--c-divider--MarginBottom: var(--pf-v5-global--spacer--sm);
  position: relative;
  display: inline-block;
  max-width: 100%;
}

.pf-v5-c-app-launcher.pf-m-expanded>.pf-v5-c-app-launcher__toggle {
  color: var(--pf-v5-c-app-launcher__toggle--m-expanded--Color);
}

.pf-v5-c-app-launcher .pf-v5-c-divider {
  margin-block-start: var(--pf-v5-c-app-launcher--c-divider--MarginTop);
  margin-block-end: var(--pf-v5-c-app-launcher--c-divider--MarginBottom);
}

.pf-v5-c-app-launcher .pf-v5-c-divider:last-child {
  --pf-v5-c-app-launcher--c-divider--MarginBottom: 0;
}

.pf-v5-c-app-launcher__toggle {
  padding-block-start: var(--pf-v5-c-app-launcher__toggle--PaddingTop);
  padding-block-end: var(--pf-v5-c-app-launcher__toggle--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-app-launcher__toggle--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-app-launcher__toggle--PaddingRight);
  color: var(--pf-v5-c-app-launcher__toggle--Color);
  background-color: var(--pf-v5-c-app-launcher__toggle--BackgroundColor);
  border: none;
}

.pf-v5-c-app-launcher__toggle:hover {
  --pf-v5-c-app-launcher__toggle--Color: var(--pf-v5-c-app-launcher__toggle--hover--Color);
}

.pf-v5-c-app-launcher__toggle:active,
.pf-v5-c-app-launcher__toggle.pf-m-active {
  --pf-v5-c-app-launcher__toggle--Color: var(--pf-v5-c-app-launcher__toggle--active--Color);
}

.pf-v5-c-app-launcher__toggle:focus {
  --pf-v5-c-app-launcher__toggle--Color: var(--pf-v5-c-app-launcher__toggle--focus--Color);
}

.pf-v5-c-app-launcher__toggle:disabled {
  --pf-v5-c-app-launcher__toggle--Color: var(--pf-v5-c-app-launcher__toggle--disabled--Color);
  pointer-events: none;
}

.pf-v5-c-app-launcher__menu {
  position: absolute;
  inset-block-start: var(--pf-v5-c-app-launcher__menu--Top);
  z-index: var(--pf-v5-c-app-launcher__menu--ZIndex);
  min-width: 100%;
  padding-block-start: var(--pf-v5-c-app-launcher__menu--PaddingTop);
  padding-block-end: var(--pf-v5-c-app-launcher__menu--PaddingBottom);
  background-color: var(--pf-v5-c-app-launcher__menu--BackgroundColor);
  background-clip: padding-box;
  box-shadow: var(--pf-v5-c-app-launcher__menu--BoxShadow);
}

.pf-v5-c-app-launcher__menu.pf-m-align-right {
  inset-inline-end: 0;
}

.pf-v5-c-app-launcher.pf-m-top .pf-v5-c-app-launcher__menu {
  --pf-v5-c-app-launcher__menu--Top: var(--pf-v5-c-app-launcher--m-top__menu--Top);
  transform: translateY(var(--pf-v5-c-app-launcher--m-top__menu--TranslateY));
}

.pf-v5-c-app-launcher__menu.pf-m-static {
  --pf-v5-c-app-launcher--m-top__menu--TranslateY: 0;
  position: static;
  inset-block-start: auto;
  inset-block-end: auto;
  inset-inline-start: auto;
  inset-inline-end: auto;
  z-index: auto;
  min-width: min-content;
}

.pf-v5-c-app-launcher__menu-search {
  padding-block-start: var(--pf-v5-c-app-launcher__menu-search--PaddingTop);
  padding-block-end: var(--pf-v5-c-app-launcher__menu-search--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-app-launcher__menu-search--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-app-launcher__menu-search--PaddingRight);
  margin-block-end: var(--pf-v5-c-app-launcher__menu-search--MarginBottom);
  border-block-end: var(--pf-v5-c-app-launcher__menu-search--BottomBorderWidth) solid var(--pf-v5-c-app-launcher__menu-search--BottomBorderColor);
}

.pf-v5-c-app-launcher__menu-wrapper {
  display: flex;
}

.pf-v5-c-app-launcher__menu-wrapper.pf-m-favorite {
  --pf-v5-c-app-launcher__menu-item--m-action--Color: var(--pf-v5-c-app-launcher__menu-item--m-favorite__menu-item--m-action--Color);
  --pf-v5-c-app-launcher__menu-item--m-action--hover--Color: var(--pf-v5-c-app-launcher__menu-item--m-favorite__menu-item--m-action--hover--Color);
}

.pf-v5-c-app-launcher__menu-item {
  display: flex;
  align-items: center;
  width: var(--pf-v5-c-app-launcher__menu-item--Width);
  padding-block-start: var(--pf-v5-c-app-launcher__menu-item--PaddingTop);
  padding-block-end: var(--pf-v5-c-app-launcher__menu-item--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-app-launcher__menu-item--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-app-launcher__menu-item--PaddingRight);
  font-weight: var(--pf-v5-c-app-launcher__menu-item--FontWeight);
  color: var(--pf-v5-c-app-launcher__menu-item--Color);
  white-space: nowrap;
  background: none;
  border: 0;
}

.pf-v5-c-app-launcher__menu-item:hover,
.pf-v5-c-app-launcher__menu-item:focus {
  --pf-v5-c-app-launcher__menu-item--m-action--Color: var(--pf-v5-c-app-launcher__menu-item--hover__menu-item--m-action--Color);
  text-decoration: none;
}

.pf-v5-c-app-launcher__menu-wrapper:hover,
.pf-v5-c-app-launcher__menu-wrapper:focus-within,
.pf-v5-c-app-launcher__menu-wrapper.pf-m-focus,
.pf-v5-c-app-launcher__menu-item:hover,
.pf-v5-c-app-launcher__menu-item:focus {
  background-color: var(--pf-v5-c-app-launcher__menu-item--hover--BackgroundColor);
}

.pf-v5-c-app-launcher__menu-item:disabled,
.pf-v5-c-app-launcher__menu-item.pf-m-disabled {
  --pf-v5-c-app-launcher__menu-item--Color: var(--pf-v5-c-app-launcher__menu-item--disabled--Color);
  --pf-v5-c-app-launcher__menu-item--m-action--Color: var(--pf-v5-c-app-launcher__menu-item--m-action--disabled--Color);
  pointer-events: none;
}

.pf-v5-c-app-launcher__menu-wrapper:disabled,
.pf-v5-c-app-launcher__menu-wrapper.pf-m-disabled,
.pf-v5-c-app-launcher__menu-item:disabled,
.pf-v5-c-app-launcher__menu-item.pf-m-disabled {
  background-color: transparent;
}

.pf-v5-c-app-launcher__menu-wrapper.pf-m-external:hover .pf-v5-c-app-launcher__menu-item-external-icon,
.pf-v5-c-app-launcher__menu-wrapper.pf-m-external:focus .pf-v5-c-app-launcher__menu-item-external-icon,
.pf-v5-c-app-launcher__menu-item.pf-m-external:hover .pf-v5-c-app-launcher__menu-item-external-icon,
.pf-v5-c-app-launcher__menu-item.pf-m-external:focus .pf-v5-c-app-launcher__menu-item-external-icon {
  opacity: 1;
}

.pf-v5-c-app-launcher__menu-item.pf-m-link {
  --pf-v5-c-app-launcher__menu-item--PaddingRight: var(--pf-v5-c-app-launcher__menu-item--m-link--PaddingRight);
  --pf-v5-c-app-launcher__menu-item--hover--BackgroundColor: var(--pf-v5-c-app-launcher__menu-item--m-link--hover--BackgroundColor);
}

.pf-v5-c-app-launcher__menu-item.pf-m-action {
  --pf-v5-c-app-launcher__menu-item--Color: var(--pf-v5-c-app-launcher__menu-item--m-action--Color);
  --pf-v5-c-app-launcher__menu-item--Width: var(--pf-v5-c-app-launcher__menu-item--m-action--Width);
  --pf-v5-c-app-launcher__menu-item--hover--BackgroundColor: var(--pf-v5-c-app-launcher__menu-item--m-action--hover--BackgroundColor);
  font-size: var(--pf-v5-c-app-launcher__menu-item--m-action--FontSize);
}

.pf-v5-c-app-launcher__menu-item.pf-m-action:hover,
.pf-v5-c-app-launcher__menu-item.pf-m-action:focus {
  --pf-v5-c-app-launcher__menu-item--m-action--Color: var(--pf-v5-c-app-launcher__menu-item--m-action--hover--Color);
}

.pf-v5-c-app-launcher__menu-item-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--pf-v5-c-app-launcher__menu-item-icon--Width);
  height: var(--pf-v5-c-app-launcher__menu-item-icon--Height);
  margin-inline-end: var(--pf-v5-c-app-launcher__menu-item-icon--MarginRight);
}

.pf-v5-c-app-launcher__menu-item-icon>* {
  max-width: 100%;
  max-height: 100%;
}

.pf-v5-c-app-launcher__menu-item-external-icon {
  padding-inline-start: var(--pf-v5-c-app-launcher__menu-item-external-icon--PaddingLeft);
  margin-inline-start: auto;
  font-size: var(--pf-v5-c-app-launcher__menu-item-external-icon--FontSize);
  color: var(--pf-v5-c-app-launcher__menu-item-external-icon--Color);
  opacity: 0;
  transform: translateY(var(--pf-v5-c-app-launcher__menu-item-external-icon--TranslateY));
}

.pf-v5-c-app-launcher__group+.pf-v5-c-app-launcher__group {
  padding-block-start: var(--pf-v5-c-app-launcher__group--group--PaddingTop);
}

.pf-v5-c-app-launcher__group-title {
  padding-block-start: var(--pf-v5-c-app-launcher__group-title--PaddingTop);
  padding-block-end: var(--pf-v5-c-app-launcher__group-title--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-app-launcher__group-title--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-app-launcher__group-title--PaddingRight);
  font-size: var(--pf-v5-c-app-launcher__group-title--FontSize);
  font-weight: var(--pf-v5-c-app-launcher__group-title--FontWeight);
  color: var(--pf-v5-c-app-launcher__group-title--Color);
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-app-launcher {
  --pf-v5-c-app-launcher__menu--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-app-launcher__menu--Top: 100%;
  --pf-v5-c-app-launcher__menu-item--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-app-launcher__menu-item--disabled--Color: var(--pf-v5-global--palette--black-300);
  --pf-v5-c-app-launcher__menu-item--m-action--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-app-launcher__menu-item--m-favorite__menu-item--m-action--Color: var(--pf-v5-global--palette--gold-300);
  --pf-v5-c-app-launcher__menu-item--m-favorite__menu-item--m-action--hover--Color: var(--pf-v5-global--palette--gold-100);
}

.pf-v5-c-avatar {
  --pf-v5-c-avatar--BorderColor: transparent;
  --pf-v5-c-avatar--BorderWidth: 0;
  --pf-v5-c-avatar--BorderRadius: var(--pf-v5-global--BorderRadius--lg);
  --pf-v5-c-avatar--Width: 2.25rem;
  --pf-v5-c-avatar--Height: 2.25rem;
  --pf-v5-c-avatar--m-sm--Width: 1.5rem;
  --pf-v5-c-avatar--m-sm--Height: 1.5rem;
  --pf-v5-c-avatar--m-md--Width: 2.25rem;
  --pf-v5-c-avatar--m-md--Height: 2.25rem;
  --pf-v5-c-avatar--m-lg--Width: 4.5rem;
  --pf-v5-c-avatar--m-lg--Height: 4.5rem;
  --pf-v5-c-avatar--m-xl--Width: 8rem;
  --pf-v5-c-avatar--m-xl--Height: 8rem;
  --pf-v5-c-avatar--m-light--BorderColor: var(--pf-v5-global--BorderColor--dark-100);
  --pf-v5-c-avatar--m-light--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-avatar--m-dark--BorderColor: var(--pf-v5-global--palette--black-700);
  --pf-v5-c-avatar--m-dark--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  width: var(--pf-v5-c-avatar--Width);
  height: var(--pf-v5-c-avatar--Height);
  border: var(--pf-v5-c-avatar--BorderWidth) solid var(--pf-v5-c-avatar--BorderColor);
  border-radius: var(--pf-v5-c-avatar--BorderRadius);
}

.pf-v5-c-avatar.pf-m-light {
  --pf-v5-c-avatar--BorderColor: var(--pf-v5-c-avatar--m-light--BorderColor);
  --pf-v5-c-avatar--BorderWidth: var(--pf-v5-c-avatar--m-light--BorderWidth);
}

.pf-v5-c-avatar.pf-m-dark {
  --pf-v5-c-avatar--BorderColor: var(--pf-v5-c-avatar--m-dark--BorderColor);
  --pf-v5-c-avatar--BorderWidth: var(--pf-v5-c-avatar--m-dark--BorderWidth);
}

.pf-v5-c-avatar.pf-m-sm {
  --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-sm--Width);
  --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-sm--Height);
}

@media (min-width: 576px) {
  .pf-v5-c-avatar.pf-m-sm {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-sm--Width-on-sm, var(--pf-v5-c-avatar--m-sm--Width));
  }
}

@media (min-width: 768px) {
  .pf-v5-c-avatar.pf-m-sm {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-sm--Width-on-md, var(--pf-v5-c-avatar--m-sm--Width-on-sm, var(--pf-v5-c-avatar--m-sm--Width)));
  }
}

@media (min-width: 992px) {
  .pf-v5-c-avatar.pf-m-sm {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-sm--Width-on-lg, var(--pf-v5-c-avatar--m-sm--Width-on-md, var(--pf-v5-c-avatar--m-sm--Width-on-sm, var(--pf-v5-c-avatar--m-sm--Width))));
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-avatar.pf-m-sm {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-sm--Width-on-xl, var(--pf-v5-c-avatar--m-sm--Width-on-lg, var(--pf-v5-c-avatar--m-sm--Width-on-md, var(--pf-v5-c-avatar--m-sm--Width-on-sm, var(--pf-v5-c-avatar--m-sm--Width)))));
  }
}

@media (min-width: 1450px) {
  .pf-v5-c-avatar.pf-m-sm {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-sm--Width-on-2xl, var(--pf-v5-c-avatar--m-sm--Width-on-xl, var(--pf-v5-c-avatar--m-sm--Width-on-lg, var(--pf-v5-c-avatar--m-sm--Width-on-md, var(--pf-v5-c-avatar--m-sm--Width-on-sm, var(--pf-v5-c-avatar--m-sm--Width))))));
  }
}

@media (min-width: 576px) {
  .pf-v5-c-avatar.pf-m-sm {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-sm--Height-on-sm, var(--pf-v5-c-avatar--m-sm--Height));
  }
}

@media (min-width: 768px) {
  .pf-v5-c-avatar.pf-m-sm {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-sm--Height-on-md, var(--pf-v5-c-avatar--m-sm--Height-on-sm, var(--pf-v5-c-avatar--m-sm--Height)));
  }
}

@media (min-width: 992px) {
  .pf-v5-c-avatar.pf-m-sm {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-sm--Height-on-lg, var(--pf-v5-c-avatar--m-sm--Height-on-md, var(--pf-v5-c-avatar--m-sm--Height-on-sm, var(--pf-v5-c-avatar--m-sm--Height))));
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-avatar.pf-m-sm {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-sm--Height-on-xl, var(--pf-v5-c-avatar--m-sm--Height-on-lg, var(--pf-v5-c-avatar--m-sm--Height-on-md, var(--pf-v5-c-avatar--m-sm--Height-on-sm, var(--pf-v5-c-avatar--m-sm--Height)))));
  }
}

@media (min-width: 1450px) {
  .pf-v5-c-avatar.pf-m-sm {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-sm--Height-on-2xl, var(--pf-v5-c-avatar--m-sm--Height-on-xl, var(--pf-v5-c-avatar--m-sm--Height-on-lg, var(--pf-v5-c-avatar--m-sm--Height-on-md, var(--pf-v5-c-avatar--m-sm--Height-on-sm, var(--pf-v5-c-avatar--m-sm--Height))))));
  }
}

.pf-v5-c-avatar.pf-m-md {
  --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-md--Width);
  --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-md--Height);
}

@media (min-width: 576px) {
  .pf-v5-c-avatar.pf-m-md {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-md--Width-on-sm, var(--pf-v5-c-avatar--m-md--Width));
  }
}

@media (min-width: 768px) {
  .pf-v5-c-avatar.pf-m-md {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-md--Width-on-md, var(--pf-v5-c-avatar--m-md--Width-on-sm, var(--pf-v5-c-avatar--m-md--Width)));
  }
}

@media (min-width: 992px) {
  .pf-v5-c-avatar.pf-m-md {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-md--Width-on-lg, var(--pf-v5-c-avatar--m-md--Width-on-md, var(--pf-v5-c-avatar--m-md--Width-on-sm, var(--pf-v5-c-avatar--m-md--Width))));
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-avatar.pf-m-md {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-md--Width-on-xl, var(--pf-v5-c-avatar--m-md--Width-on-lg, var(--pf-v5-c-avatar--m-md--Width-on-md, var(--pf-v5-c-avatar--m-md--Width-on-sm, var(--pf-v5-c-avatar--m-md--Width)))));
  }
}

@media (min-width: 1450px) {
  .pf-v5-c-avatar.pf-m-md {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-md--Width-on-2xl, var(--pf-v5-c-avatar--m-md--Width-on-xl, var(--pf-v5-c-avatar--m-md--Width-on-lg, var(--pf-v5-c-avatar--m-md--Width-on-md, var(--pf-v5-c-avatar--m-md--Width-on-sm, var(--pf-v5-c-avatar--m-md--Width))))));
  }
}

@media (min-width: 576px) {
  .pf-v5-c-avatar.pf-m-md {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-md--Height-on-sm, var(--pf-v5-c-avatar--m-md--Height));
  }
}

@media (min-width: 768px) {
  .pf-v5-c-avatar.pf-m-md {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-md--Height-on-md, var(--pf-v5-c-avatar--m-md--Height-on-sm, var(--pf-v5-c-avatar--m-md--Height)));
  }
}

@media (min-width: 992px) {
  .pf-v5-c-avatar.pf-m-md {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-md--Height-on-lg, var(--pf-v5-c-avatar--m-md--Height-on-md, var(--pf-v5-c-avatar--m-md--Height-on-sm, var(--pf-v5-c-avatar--m-md--Height))));
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-avatar.pf-m-md {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-md--Height-on-xl, var(--pf-v5-c-avatar--m-md--Height-on-lg, var(--pf-v5-c-avatar--m-md--Height-on-md, var(--pf-v5-c-avatar--m-md--Height-on-sm, var(--pf-v5-c-avatar--m-md--Height)))));
  }
}

@media (min-width: 1450px) {
  .pf-v5-c-avatar.pf-m-md {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-md--Height-on-2xl, var(--pf-v5-c-avatar--m-md--Height-on-xl, var(--pf-v5-c-avatar--m-md--Height-on-lg, var(--pf-v5-c-avatar--m-md--Height-on-md, var(--pf-v5-c-avatar--m-md--Height-on-sm, var(--pf-v5-c-avatar--m-md--Height))))));
  }
}

.pf-v5-c-avatar.pf-m-lg {
  --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-lg--Width);
  --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-lg--Height);
}

@media (min-width: 576px) {
  .pf-v5-c-avatar.pf-m-lg {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-lg--Width-on-sm, var(--pf-v5-c-avatar--m-lg--Width));
  }
}

@media (min-width: 768px) {
  .pf-v5-c-avatar.pf-m-lg {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-lg--Width-on-md, var(--pf-v5-c-avatar--m-lg--Width-on-sm, var(--pf-v5-c-avatar--m-lg--Width)));
  }
}

@media (min-width: 992px) {
  .pf-v5-c-avatar.pf-m-lg {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-lg--Width-on-lg, var(--pf-v5-c-avatar--m-lg--Width-on-md, var(--pf-v5-c-avatar--m-lg--Width-on-sm, var(--pf-v5-c-avatar--m-lg--Width))));
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-avatar.pf-m-lg {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-lg--Width-on-xl, var(--pf-v5-c-avatar--m-lg--Width-on-lg, var(--pf-v5-c-avatar--m-lg--Width-on-md, var(--pf-v5-c-avatar--m-lg--Width-on-sm, var(--pf-v5-c-avatar--m-lg--Width)))));
  }
}

@media (min-width: 1450px) {
  .pf-v5-c-avatar.pf-m-lg {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-lg--Width-on-2xl, var(--pf-v5-c-avatar--m-lg--Width-on-xl, var(--pf-v5-c-avatar--m-lg--Width-on-lg, var(--pf-v5-c-avatar--m-lg--Width-on-md, var(--pf-v5-c-avatar--m-lg--Width-on-sm, var(--pf-v5-c-avatar--m-lg--Width))))));
  }
}

@media (min-width: 576px) {
  .pf-v5-c-avatar.pf-m-lg {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-lg--Height-on-sm, var(--pf-v5-c-avatar--m-lg--Height));
  }
}

@media (min-width: 768px) {
  .pf-v5-c-avatar.pf-m-lg {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-lg--Height-on-md, var(--pf-v5-c-avatar--m-lg--Height-on-sm, var(--pf-v5-c-avatar--m-lg--Height)));
  }
}

@media (min-width: 992px) {
  .pf-v5-c-avatar.pf-m-lg {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-lg--Height-on-lg, var(--pf-v5-c-avatar--m-lg--Height-on-md, var(--pf-v5-c-avatar--m-lg--Height-on-sm, var(--pf-v5-c-avatar--m-lg--Height))));
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-avatar.pf-m-lg {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-lg--Height-on-xl, var(--pf-v5-c-avatar--m-lg--Height-on-lg, var(--pf-v5-c-avatar--m-lg--Height-on-md, var(--pf-v5-c-avatar--m-lg--Height-on-sm, var(--pf-v5-c-avatar--m-lg--Height)))));
  }
}

@media (min-width: 1450px) {
  .pf-v5-c-avatar.pf-m-lg {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-lg--Height-on-2xl, var(--pf-v5-c-avatar--m-lg--Height-on-xl, var(--pf-v5-c-avatar--m-lg--Height-on-lg, var(--pf-v5-c-avatar--m-lg--Height-on-md, var(--pf-v5-c-avatar--m-lg--Height-on-sm, var(--pf-v5-c-avatar--m-lg--Height))))));
  }
}

.pf-v5-c-avatar.pf-m-xl {
  --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-xl--Width);
  --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-xl--Height);
}

@media (min-width: 576px) {
  .pf-v5-c-avatar.pf-m-xl {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-xl--Width-on-sm, var(--pf-v5-c-avatar--m-xl--Width));
  }
}

@media (min-width: 768px) {
  .pf-v5-c-avatar.pf-m-xl {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-xl--Width-on-md, var(--pf-v5-c-avatar--m-xl--Width-on-sm, var(--pf-v5-c-avatar--m-xl--Width)));
  }
}

@media (min-width: 992px) {
  .pf-v5-c-avatar.pf-m-xl {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-xl--Width-on-lg, var(--pf-v5-c-avatar--m-xl--Width-on-md, var(--pf-v5-c-avatar--m-xl--Width-on-sm, var(--pf-v5-c-avatar--m-xl--Width))));
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-avatar.pf-m-xl {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-xl--Width-on-xl, var(--pf-v5-c-avatar--m-xl--Width-on-lg, var(--pf-v5-c-avatar--m-xl--Width-on-md, var(--pf-v5-c-avatar--m-xl--Width-on-sm, var(--pf-v5-c-avatar--m-xl--Width)))));
  }
}

@media (min-width: 1450px) {
  .pf-v5-c-avatar.pf-m-xl {
    --pf-v5-c-avatar--Width: var(--pf-v5-c-avatar--m-xl--Width-on-2xl, var(--pf-v5-c-avatar--m-xl--Width-on-xl, var(--pf-v5-c-avatar--m-xl--Width-on-lg, var(--pf-v5-c-avatar--m-xl--Width-on-md, var(--pf-v5-c-avatar--m-xl--Width-on-sm, var(--pf-v5-c-avatar--m-xl--Width))))));
  }
}

@media (min-width: 576px) {
  .pf-v5-c-avatar.pf-m-xl {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-xl--Height-on-sm, var(--pf-v5-c-avatar--m-xl--Height));
  }
}

@media (min-width: 768px) {
  .pf-v5-c-avatar.pf-m-xl {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-xl--Height-on-md, var(--pf-v5-c-avatar--m-xl--Height-on-sm, var(--pf-v5-c-avatar--m-xl--Height)));
  }
}

@media (min-width: 992px) {
  .pf-v5-c-avatar.pf-m-xl {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-xl--Height-on-lg, var(--pf-v5-c-avatar--m-xl--Height-on-md, var(--pf-v5-c-avatar--m-xl--Height-on-sm, var(--pf-v5-c-avatar--m-xl--Height))));
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-avatar.pf-m-xl {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-xl--Height-on-xl, var(--pf-v5-c-avatar--m-xl--Height-on-lg, var(--pf-v5-c-avatar--m-xl--Height-on-md, var(--pf-v5-c-avatar--m-xl--Height-on-sm, var(--pf-v5-c-avatar--m-xl--Height)))));
  }
}

@media (min-width: 1450px) {
  .pf-v5-c-avatar.pf-m-xl {
    --pf-v5-c-avatar--Height: var(--pf-v5-c-avatar--m-xl--Height-on-2xl, var(--pf-v5-c-avatar--m-xl--Height-on-xl, var(--pf-v5-c-avatar--m-xl--Height-on-lg, var(--pf-v5-c-avatar--m-xl--Height-on-md, var(--pf-v5-c-avatar--m-xl--Height-on-sm, var(--pf-v5-c-avatar--m-xl--Height))))));
  }
}

.pf-v5-c-backdrop {
  --pf-v5-c-backdrop--Position: fixed;
  --pf-v5-c-backdrop--ZIndex: var(--pf-v5-global--ZIndex--lg);
  --pf-v5-c-backdrop--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-transparent-100);
  position: var(--pf-v5-c-backdrop--Position);
  inset-block-start: 0;
  inset-inline-start: 0;
  z-index: var(--pf-v5-c-backdrop--ZIndex);
  width: 100%;
  height: 100%;
  background-color: var(--pf-v5-c-backdrop--BackgroundColor);
}

.pf-v5-c-backdrop__open {
  overflow: hidden;
}

.pf-v5-c-background-image {
  --pf-v5-c-background-image--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-100);
  --pf-v5-c-background-image--BackgroundImage: none;
  --pf-v5-c-background-image--BackgroundSize--min-width: 200px;
  --pf-v5-c-background-image--BackgroundSize--width: 60%;
  --pf-v5-c-background-image--BackgroundSize--max-width: 600px;
  --pf-v5-c-background-image--BackgroundSize: clamp(var(--pf-v5-c-background-image--BackgroundSize--min-width), var(--pf-v5-c-background-image--BackgroundSize--width), var(--pf-v5-c-background-image--BackgroundSize--max-width));
  --pf-v5-c-background-image--BackgroundPosition: bottom right;
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: var(--pf-v5-c-background-image--BackgroundColor);
  background-image: var(--pf-v5-c-background-image--BackgroundImage);
  background-repeat: no-repeat;
  background-position: var(--pf-v5-c-background-image--BackgroundPosition);
  background-size: var(--pf-v5-c-background-image--BackgroundSize);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-background-image {
  --pf-v5-c-background-image--BackgroundPosition: bottom left;
}

.pf-v5-c-back-to-top {
  --pf-v5-c-back-to-top--Right: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-back-to-top--Bottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-back-to-top--md--Bottom: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-back-to-top--c-button--FontSize: var(--pf-v5-global--FontSize--xs);
  --pf-v5-c-back-to-top--c-button--BorderRadius: var(--pf-v5-global--BorderRadius--lg);
  --pf-v5-c-back-to-top--c-button--PaddingTop: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-back-to-top--c-button--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-back-to-top--c-button--PaddingBottom: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-back-to-top--c-button--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-back-to-top--c-button--BoxShadow: var(--pf-v5-global--BoxShadow--lg-bottom);
  position: absolute;
  inset-block-end: var(--pf-v5-c-back-to-top--Bottom);
  inset-inline-end: var(--pf-v5-c-back-to-top--Right);
}

@media (min-width: 768px) {
  .pf-v5-c-back-to-top {
    --pf-v5-c-back-to-top--Bottom: var(--pf-v5-c-back-to-top--md--Bottom);
  }
}

.pf-v5-c-back-to-top.pf-m-hidden {
  display: none;
}

.pf-v5-c-back-to-top .pf-v5-c-button {
  --pf-v5-c-button--FontSize: var(--pf-v5-c-back-to-top--c-button--FontSize);
  --pf-v5-c-button--BorderRadius: var(--pf-v5-c-back-to-top--c-button--BorderRadius);
  --pf-v5-c-button--PaddingTop: var(--pf-v5-c-back-to-top--c-button--PaddingTop);
  --pf-v5-c-button--PaddingRight: var(--pf-v5-c-back-to-top--c-button--PaddingRight);
  --pf-v5-c-button--PaddingBottom: var(--pf-v5-c-back-to-top--c-button--PaddingBottom);
  --pf-v5-c-button--PaddingLeft: var(--pf-v5-c-back-to-top--c-button--PaddingLeft);
  box-shadow: var(--pf-v5-c-back-to-top--c-button--BoxShadow);
}

.pf-v5-c-badge {
  --pf-v5-c-badge--BorderRadius: var(--pf-v5-global--BorderRadius--lg);
  --pf-v5-c-badge--FontSize: var(--pf-v5-global--FontSize--xs);
  --pf-v5-c-badge--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-badge--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-badge--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-badge--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-badge--MinWidth: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-badge--m-read--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-badge--m-read--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-badge--m-unread--BackgroundColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-badge--m-unread--Color: var(--pf-v5-global--Color--light-100);
  display: inline-block;
  min-width: var(--pf-v5-c-badge--MinWidth);
  padding-inline-start: var(--pf-v5-c-badge--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-badge--PaddingRight);
  font-size: var(--pf-v5-c-badge--FontSize);
  font-weight: var(--pf-v5-c-badge--FontWeight);
  color: var(--pf-v5-c-badge--Color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--pf-v5-c-badge--BackgroundColor);
  border-radius: var(--pf-v5-c-badge--BorderRadius);
}

.pf-v5-c-badge.pf-m-read {
  --pf-v5-c-badge--Color: var(--pf-v5-c-badge--m-read--Color);
  --pf-v5-c-badge--BackgroundColor: var(--pf-v5-c-badge--m-read--BackgroundColor);
}

.pf-v5-c-badge.pf-m-unread {
  --pf-v5-c-badge--Color: var(--pf-v5-c-badge--m-unread--Color);
  --pf-v5-c-badge--BackgroundColor: var(--pf-v5-c-badge--m-unread--BackgroundColor);
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-badge {
  --pf-v5-c-badge--m-read--BackgroundColor: var(--pf-v5-global--palette--black-500);
  --pf-v5-c-badge--m-unread--Color: var(--pf-v5-global--primary-color--400);
  --pf-v5-c-badge--m-unread--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

.pf-v5-c-banner {
  --pf-v5-c-banner--PaddingTop: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-banner--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-banner--md--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-banner--PaddingBottom: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-banner--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-banner--md--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-banner--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-banner--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-banner--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-400);
  --pf-v5-c-banner--link--Color: var(--pf-v5-c-banner--Color);
  --pf-v5-c-banner--link--TextDecoration: underline;
  --pf-v5-c-banner--link--hover--Color: var(--pf-v5-c-banner--Color);
  --pf-v5-c-banner--link--hover--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-banner--link--disabled--Color: var(--pf-v5-c-banner--Color);
  --pf-v5-c-banner--link--disabled--TextDecoration: none;
  --pf-v5-c-banner--m-blue--BackgroundColor: var(--pf-v5-global--palette--blue-200);
  --pf-v5-c-banner--m-red--BackgroundColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-banner--m-green--BackgroundColor: var(--pf-v5-global--success-color--100);
  --pf-v5-c-banner--m-gold--BackgroundColor: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-banner--m-sticky--ZIndex: var(--pf-v5-global--ZIndex--md);
  --pf-v5-c-banner--m-sticky--BoxShadow: var(--pf-v5-global--BoxShadow--md-bottom);
  color: var(--pf-v5-global--Color--100);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-shrink: 0;
  padding-block-start: var(--pf-v5-c-banner--PaddingTop);
  padding-block-end: var(--pf-v5-c-banner--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-banner--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-banner--PaddingRight);
  font-size: var(--pf-v5-c-banner--FontSize);
  color: var(--pf-v5-c-banner--Color);
  white-space: nowrap;
  background-color: var(--pf-v5-c-banner--BackgroundColor);
}

@media (min-width: 768px) {
  .pf-v5-c-banner {
    --pf-v5-c-banner--PaddingRight: var(--pf-v5-c-banner--md--PaddingRight);
    --pf-v5-c-banner--PaddingLeft: var(--pf-v5-c-banner--md--PaddingLeft);
  }
}

.pf-v5-c-banner.pf-m-blue {
  --pf-v5-c-banner--BackgroundColor: var(--pf-v5-c-banner--m-blue--BackgroundColor);
}

.pf-v5-c-banner.pf-m-red {
  --pf-v5-c-banner--BackgroundColor: var(--pf-v5-c-banner--m-red--BackgroundColor);
}

.pf-v5-c-banner.pf-m-green {
  --pf-v5-c-banner--BackgroundColor: var(--pf-v5-c-banner--m-green--BackgroundColor);
}

.pf-v5-c-banner.pf-m-gold {
  --pf-v5-c-banner--BackgroundColor: var(--pf-v5-c-banner--m-gold--BackgroundColor);
}

.pf-v5-c-banner.pf-m-sticky {
  position: sticky;
  inset-block-start: 0;
  z-index: var(--pf-v5-c-banner--m-sticky--ZIndex);
  box-shadow: var(--pf-v5-c-banner--m-sticky--BoxShadow);
}

.pf-v5-c-banner a {
  color: var(--pf-v5-c-banner--link--Color);
  text-decoration: var(--pf-v5-c-banner--link--TextDecoration);
}

.pf-v5-c-banner a:hover:not(.pf-m-disabled) {
  --pf-v5-c-banner--link--Color: var(--pf-v5-c-banner--link--hover--Color);
  font-weight: var(--pf-v5-c-banner--link--hover--FontWeight);
}

.pf-v5-c-banner a.pf-m-disabled {
  --pf-v5-c-banner--link--Color: var(--pf-v5-c-banner--link--disabled--Color);
  --pf-v5-c-banner--link--TextDecoration: var(--pf-v5-c-banner--link--disabled--TextDecoration);
  cursor: not-allowed;
}

.pf-v5-c-banner .pf-v5-c-button.pf-m-inline {
  --pf-v5-c-button--m-link--Color: var(--pf-v5-c-banner--link--Color);
  --pf-v5-c-button--m-link--m-inline--hover--Color: var(--pf-v5-c-banner--link--hover--Color);
  --pf-v5-c-button--disabled--Color: var(--pf-v5-c-banner--link--disabled--Color);
  text-decoration: var(--pf-v5-c-banner--link--TextDecoration);
}

.pf-v5-c-banner .pf-v5-c-button.pf-m-inline:hover {
  --pf-v5-c-banner--link--Color: var(--pf-v5-c-banner--link--hover--Color);
  font-weight: var(--pf-v5-c-banner--link--hover--FontWeight);
}

.pf-v5-c-banner .pf-v5-c-button.pf-m-inline:disabled,
.pf-v5-c-banner .pf-v5-c-button.pf-m-inline.pf-m-disabled {
  --pf-v5-c-banner--link--TextDecoration: var(--pf-v5-c-banner--link--disabled--TextDecoration);
  cursor: not-allowed;
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-banner.pf-m-blue,
:where(.pf-v5-theme-dark) .pf-v5-c-banner.pf-m-red,
:where(.pf-v5-theme-dark) .pf-v5-c-banner.pf-m-green,
:where(.pf-v5-theme-dark) .pf-v5-c-banner.pf-m-gold {
  --pf-v5-c-banner--Color: var(--pf-v5-global--palette--black-900);
}

.pf-v5-c-brand {
  --pf-v5-c-brand--Width: auto;
  --pf-v5-c-brand--Height: auto;
  width: var(--pf-v5-c-brand--Width--base);
  height: var(--pf-v5-c-brand--Height--base);
  --pf-v5-c-brand--Width--base: var(--pf-v5-c-brand--Width);
  --pf-v5-c-brand--Height--base: var(--pf-v5-c-brand--Height);
}

@media (min-width: 576px) {
  .pf-v5-c-brand {
    --pf-v5-c-brand--Width--base: var(--pf-v5-c-brand--Width-on-sm, var(--pf-v5-c-brand--Width));
  }
}

@media (min-width: 768px) {
  .pf-v5-c-brand {
    --pf-v5-c-brand--Width--base: var(--pf-v5-c-brand--Width-on-md, var(--pf-v5-c-brand--Width-on-sm, var(--pf-v5-c-brand--Width)));
  }
}

@media (min-width: 992px) {
  .pf-v5-c-brand {
    --pf-v5-c-brand--Width--base: var(--pf-v5-c-brand--Width-on-lg, var(--pf-v5-c-brand--Width-on-md, var(--pf-v5-c-brand--Width-on-sm, var(--pf-v5-c-brand--Width))));
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-brand {
    --pf-v5-c-brand--Width--base: var(--pf-v5-c-brand--Width-on-xl, var(--pf-v5-c-brand--Width-on-lg, var(--pf-v5-c-brand--Width-on-md, var(--pf-v5-c-brand--Width-on-sm, var(--pf-v5-c-brand--Width)))));
  }
}

@media (min-width: 1450px) {
  .pf-v5-c-brand {
    --pf-v5-c-brand--Width--base: var(--pf-v5-c-brand--Width-on-2xl, var(--pf-v5-c-brand--Width-on-xl, var(--pf-v5-c-brand--Width-on-lg, var(--pf-v5-c-brand--Width-on-md, var(--pf-v5-c-brand--Width-on-sm, var(--pf-v5-c-brand--Width))))));
  }
}

@media (min-width: 576px) {
  .pf-v5-c-brand {
    --pf-v5-c-brand--Height--base: var(--pf-v5-c-brand--Height-on-sm, var(--pf-v5-c-brand--Height));
  }
}

@media (min-width: 768px) {
  .pf-v5-c-brand {
    --pf-v5-c-brand--Height--base: var(--pf-v5-c-brand--Height-on-md, var(--pf-v5-c-brand--Height-on-sm, var(--pf-v5-c-brand--Height)));
  }
}

@media (min-width: 992px) {
  .pf-v5-c-brand {
    --pf-v5-c-brand--Height--base: var(--pf-v5-c-brand--Height-on-lg, var(--pf-v5-c-brand--Height-on-md, var(--pf-v5-c-brand--Height-on-sm, var(--pf-v5-c-brand--Height))));
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-brand {
    --pf-v5-c-brand--Height--base: var(--pf-v5-c-brand--Height-on-xl, var(--pf-v5-c-brand--Height-on-lg, var(--pf-v5-c-brand--Height-on-md, var(--pf-v5-c-brand--Height-on-sm, var(--pf-v5-c-brand--Height)))));
  }
}

@media (min-width: 1450px) {
  .pf-v5-c-brand {
    --pf-v5-c-brand--Height--base: var(--pf-v5-c-brand--Height-on-2xl, var(--pf-v5-c-brand--Height-on-xl, var(--pf-v5-c-brand--Height-on-lg, var(--pf-v5-c-brand--Height-on-md, var(--pf-v5-c-brand--Height-on-sm, var(--pf-v5-c-brand--Height))))));
  }
}

.pf-v5-c-brand.pf-m-picture {
  display: inline-flex;
  max-width: 100%;
}

.pf-v5-c-breadcrumb {
  --pf-v5-c-breadcrumb__item--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-breadcrumb__item--LineHeight: var(--pf-v5-global--LineHeight--sm);
  --pf-v5-c-breadcrumb__item--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-breadcrumb__item-divider--Color: var(--pf-v5-global--icon--Color--dark);
  --pf-v5-c-breadcrumb__item-divider--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-breadcrumb__item-divider--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-breadcrumb__link--Color: var(--pf-v5-global--link--Color);
  --pf-v5-c-breadcrumb__link--TextDecoration: var(--pf-v5-global--link--TextDecoration);
  --pf-v5-c-breadcrumb__link--hover--Color: var(--pf-v5-global--link--Color--hover);
  --pf-v5-c-breadcrumb__link--hover--TextDecoration: var(--pf-v5-global--link--TextDecoration--hover);
  --pf-v5-c-breadcrumb__link--m-current--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-breadcrumb__link--BackgroundColor: transparent;
  --pf-v5-c-breadcrumb__heading--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-breadcrumb__dropdown--MarginTop: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-breadcrumb__dropdown--MarginRight: calc(var(--pf-v5-c-breadcrumb__item--MarginRight) * -1);
  --pf-v5-c-breadcrumb__dropdown--MarginBottom: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-breadcrumb__dropdown--MarginLeft: calc(var(--pf-v5-c-breadcrumb__item-divider--MarginRight) * -1);
  --pf-v5-c-breadcrumb__dropdown--c-dropdown__toggle--LineHeight: var(--pf-v5-global--LineHeight--sm);
  display: inline-flex;
}

.pf-v5-c-breadcrumb__list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.pf-v5-c-breadcrumb__item {
  display: flex;
  align-items: baseline;
  font-size: var(--pf-v5-c-breadcrumb__item--FontSize);
  font-weight: var(--pf-v5-c-breadcrumb__item--FontWeight);
  line-height: var(--pf-v5-c-breadcrumb__item--LineHeight);
  white-space: nowrap;
  list-style: none;
}

.pf-v5-c-breadcrumb__item:not(:last-child) {
  margin-inline-end: var(--pf-v5-c-breadcrumb__item--MarginRight);
}

.pf-v5-c-breadcrumb__item-divider {
  margin-inline-end: var(--pf-v5-c-breadcrumb__item-divider--MarginRight);
  font-size: var(--pf-v5-c-breadcrumb__item-divider--FontSize);
  line-height: 1;
  color: var(--pf-v5-c-breadcrumb__item-divider--Color);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-breadcrumb__item-divider {
  scale: -1 1;
}

.pf-v5-c-breadcrumb__link {
  font-size: inherit;
  font-weight: var(--pf-v5-c-breadcrumb__link--FontWeight);
  line-height: inherit;
  color: var(--pf-v5-c-breadcrumb__link--Color);
  text-decoration: var(--pf-v5-c-breadcrumb__link--TextDecoration);
  word-break: break-word;
  background-color: var(--pf-v5-c-breadcrumb__link--BackgroundColor);
}

.pf-v5-c-breadcrumb__link:hover {
  --pf-v5-c-breadcrumb__link--Color: var(--pf-v5-c-breadcrumb__link--hover--Color);
  --pf-v5-c-breadcrumb__link--TextDecoration: var(--pf-v5-c-breadcrumb__link--hover--TextDecoration);
}

.pf-v5-c-breadcrumb__link.pf-m-current {
  cursor: default;
}

.pf-v5-c-breadcrumb__link.pf-m-current,
.pf-v5-c-breadcrumb__link.pf-m-current:hover {
  color: var(--pf-v5-c-breadcrumb__link--m-current--Color);
  text-decoration: none;
}

button.pf-v5-c-breadcrumb__link {
  border: none;
}

.pf-v5-c-breadcrumb__dropdown {
  margin-block-start: var(--pf-v5-c-breadcrumb__dropdown--MarginTop);
  margin-block-end: var(--pf-v5-c-breadcrumb__dropdown--MarginBottom);
  margin-inline-start: var(--pf-v5-c-breadcrumb__dropdown--MarginLeft);
  margin-inline-end: var(--pf-v5-c-breadcrumb__dropdown--MarginRight);
}

.pf-v5-c-breadcrumb__dropdown .pf-v5-c-dropdown__toggle {
  line-height: var(--pf-v5-c-breadcrumb__dropdown--c-dropdown__toggle--LineHeight);
}

.pf-v5-c-breadcrumb__heading {
  display: inline;
  font-size: var(--pf-v5-c-breadcrumb__heading--FontSize);
}

.pf-v5-c-breadcrumb__link,
.pf-v5-c-breadcrumb__heading {
  white-space: normal;
}

.pf-v5-c-breadcrumb__list>:first-child .pf-v5-c-breadcrumb__item-divider {
  display: none;
}

.pf-v5-c-button {
  --pf-v5-c-button--PaddingTop: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-button--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-button--PaddingBottom: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-button--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-button--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-button--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-button--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-button--BackgroundColor: transparent;
  --pf-v5-c-button--BorderRadius: var(--pf-v5-global--BorderRadius--sm);
  --pf-v5-c-button--after--BorderRadius: var(--pf-v5-global--BorderRadius--sm);
  --pf-v5-c-button--after--BorderColor: transparent;
  --pf-v5-c-button--after--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-button--hover--after--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-button--focus--after--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-button--active--after--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-button--disabled--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-button--disabled--BackgroundColor: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-button--disabled--after--BorderColor: transparent;
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-button--m-primary--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-button--m-primary--hover--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-button--m-primary--hover--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-button--m-primary--focus--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-button--m-primary--focus--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-button--m-primary--active--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-button--m-primary--active--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-button--m-secondary--BackgroundColor: transparent;
  --pf-v5-c-button--m-secondary--after--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-button--m-secondary--Color: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-button--m-secondary--hover--BackgroundColor: transparent;
  --pf-v5-c-button--m-secondary--hover--after--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-button--m-secondary--hover--Color: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-button--m-secondary--focus--BackgroundColor: transparent;
  --pf-v5-c-button--m-secondary--focus--after--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-button--m-secondary--focus--Color: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-button--m-secondary--active--BackgroundColor: transparent;
  --pf-v5-c-button--m-secondary--active--after--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-button--m-secondary--active--Color: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-button--m-secondary--m-danger--BackgroundColor: transparent;
  --pf-v5-c-button--m-secondary--m-danger--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-button--m-secondary--m-danger--after--BorderColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-button--m-secondary--m-danger--hover--BackgroundColor: transparent;
  --pf-v5-c-button--m-secondary--m-danger--hover--Color: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-button--m-secondary--m-danger--hover--after--BorderColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-button--m-secondary--m-danger--focus--BackgroundColor: transparent;
  --pf-v5-c-button--m-secondary--m-danger--focus--Color: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-button--m-secondary--m-danger--focus--after--BorderColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-button--m-secondary--m-danger--active--BackgroundColor: transparent;
  --pf-v5-c-button--m-secondary--m-danger--active--Color: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-button--m-secondary--m-danger--active--after--BorderColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-button--m-tertiary--BackgroundColor: transparent;
  --pf-v5-c-button--m-tertiary--after--BorderColor: var(--pf-v5-global--Color--100);
  --pf-v5-c-button--m-tertiary--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-button--m-tertiary--hover--BackgroundColor: transparent;
  --pf-v5-c-button--m-tertiary--hover--after--BorderColor: var(--pf-v5-global--Color--100);
  --pf-v5-c-button--m-tertiary--hover--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-button--m-tertiary--focus--BackgroundColor: transparent;
  --pf-v5-c-button--m-tertiary--focus--after--BorderColor: var(--pf-v5-global--Color--100);
  --pf-v5-c-button--m-tertiary--focus--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-button--m-tertiary--active--BackgroundColor: transparent;
  --pf-v5-c-button--m-tertiary--active--after--BorderColor: var(--pf-v5-global--Color--100);
  --pf-v5-c-button--m-tertiary--active--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-button--m-warning--BackgroundColor: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-button--m-warning--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-button--m-warning--hover--BackgroundColor: var(--pf-v5-global--palette--gold-500);
  --pf-v5-c-button--m-warning--hover--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-button--m-warning--focus--BackgroundColor: var(--pf-v5-global--palette--gold-500);
  --pf-v5-c-button--m-warning--focus--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-button--m-warning--active--BackgroundColor: var(--pf-v5-global--palette--gold-500);
  --pf-v5-c-button--m-warning--active--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-button--m-danger--BackgroundColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-button--m-danger--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-button--m-danger--hover--BackgroundColor: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-button--m-danger--hover--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-button--m-danger--focus--BackgroundColor: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-button--m-danger--focus--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-button--m-danger--active--BackgroundColor: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-button--m-danger--active--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-button--m-link--BackgroundColor: transparent;
  --pf-v5-c-button--m-link--Color: var(--pf-v5-global--link--Color);
  --pf-v5-c-button--m-link--hover--BackgroundColor: transparent;
  --pf-v5-c-button--m-link--hover--Color: var(--pf-v5-global--link--Color--hover);
  --pf-v5-c-button--m-link--focus--BackgroundColor: transparent;
  --pf-v5-c-button--m-link--focus--Color: var(--pf-v5-global--link--Color--hover);
  --pf-v5-c-button--m-link--active--BackgroundColor: transparent;
  --pf-v5-c-button--m-link--active--Color: var(--pf-v5-global--link--Color--hover);
  --pf-v5-c-button--m-link--disabled--BackgroundColor: transparent;
  --pf-v5-c-button--m-link--disabled--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-button--m-link--m-inline--FontSize: inherit;
  --pf-v5-c-button--m-link--m-inline--hover--TextDecoration: var(--pf-v5-global--link--TextDecoration--hover);
  --pf-v5-c-button--m-link--m-inline--hover--Color: var(--pf-v5-global--link--Color--hover);
  --pf-v5-c-button--m-link--m-inline--PaddingTop: 0;
  --pf-v5-c-button--m-link--m-inline--PaddingRight: 0;
  --pf-v5-c-button--m-link--m-inline--PaddingBottom: 0;
  --pf-v5-c-button--m-link--m-inline--PaddingLeft: 0;
  --pf-v5-c-button--m-link--m-inline__progress--Left: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-button--m-link--m-inline--m-in-progress--PaddingLeft: calc(var(--pf-v5-c-button--m-link--m-inline__progress--Left) + 1rem + var(--pf-v5-global--spacer--sm));
  --pf-v5-c-button--m-link--m-danger--BackgroundColor: transparent;
  --pf-v5-c-button--m-link--m-danger--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-button--m-link--m-danger--hover--BackgroundColor: transparent;
  --pf-v5-c-button--m-link--m-danger--hover--Color: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-button--m-link--m-danger--focus--BackgroundColor: transparent;
  --pf-v5-c-button--m-link--m-danger--focus--Color: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-button--m-link--m-danger--active--BackgroundColor: transparent;
  --pf-v5-c-button--m-link--m-danger--active--Color: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-button--m-plain--BackgroundColor: transparent;
  --pf-v5-c-button--m-plain--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-button--m-plain--hover--BackgroundColor: transparent;
  --pf-v5-c-button--m-plain--hover--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-button--m-plain--focus--BackgroundColor: transparent;
  --pf-v5-c-button--m-plain--focus--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-button--m-plain--active--BackgroundColor: transparent;
  --pf-v5-c-button--m-plain--active--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-button--m-plain--disabled--Color: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-button--m-plain--disabled--BackgroundColor: transparent;
  --pf-v5-c-button--m-control--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-button--m-control--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-button--m-control--BorderRadius: 0;
  --pf-v5-c-button--m-control--after--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-button--m-control--after--BorderTopColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-button--m-control--after--BorderRightColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-button--m-control--after--BorderBottomColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-button--m-control--after--BorderLeftColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-button--m-control--disabled--BackgroundColor: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-button--m-control--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-button--m-control--hover--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-button--m-control--hover--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-button--m-control--hover--after--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-button--m-control--active--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-button--m-control--active--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-button--m-control--active--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-button--m-control--active--after--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-button--m-control--focus--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-button--m-control--focus--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-button--m-control--focus--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-button--m-control--focus--after--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-button--m-control--m-expanded--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-button--m-control--m-expanded--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-button--m-control--m-expanded--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-button--m-control--m-expanded--after--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-button--m-small--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-button--m-display-lg--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-button--m-display-lg--PaddingRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-button--m-display-lg--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-button--m-display-lg--PaddingLeft: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-button--m-display-lg--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-button--m-link--m-display-lg--FontSize: var(--pf-v5-global--FontSize--lg);
  --pf-v5-c-button__icon--m-start--MarginRight: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-button__icon--m-end--MarginLeft: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-button__progress--width: calc(var(--pf-v5-global--icon--FontSize--md) + var(--pf-v5-global--spacer--sm));
  --pf-v5-c-button__progress--Opacity: 0;
  --pf-v5-c-button__progress--TranslateY: -50%;
  --pf-v5-c-button__progress--TranslateX: 0;
  --pf-v5-c-button__progress--Top: 50%;
  --pf-v5-c-button__progress--Left: var(--pf-v5-global--spacer--md);
  --pf-v5-c-button--m-progress--TransitionProperty: padding;
  --pf-v5-c-button--m-progress--TransitionDuration: var(--pf-v5-global--TransitionDuration);
  --pf-v5-c-button--m-progress--PaddingRight: calc(var(--pf-v5-global--spacer--md) + var(--pf-v5-c-button__progress--width) / 2);
  --pf-v5-c-button--m-progress--PaddingLeft: calc(var(--pf-v5-global--spacer--md) + var(--pf-v5-c-button__progress--width) / 2);
  --pf-v5-c-button--m-in-progress--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-button--m-in-progress--PaddingLeft: calc(var(--pf-v5-global--spacer--md) + var(--pf-v5-c-button__progress--width));
  --pf-v5-c-button--m-in-progress--m-plain--Color: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-button--m-in-progress--m-plain__progress--Left: 50%;
  --pf-v5-c-button--m-in-progress--m-plain__progress--TranslateX: -50%;
  --pf-v5-c-button__count--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-button--disabled__c-badge--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-button--disabled__c-badge--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-button--m-primary__c-badge--BorderColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-button--m-primary__c-badge--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  position: relative;
  display: inline-block;
  padding-block-start: var(--pf-v5-c-button--PaddingTop);
  padding-block-end: var(--pf-v5-c-button--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-button--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-button--PaddingRight);
  font-size: var(--pf-v5-c-button--FontSize);
  font-weight: var(--pf-v5-c-button--FontWeight);
  line-height: var(--pf-v5-c-button--LineHeight);
  text-align: center;
  white-space: nowrap;
  user-select: none;
  border: 0;
  border-radius: var(--pf-v5-c-button--BorderRadius);
}

.pf-v5-c-button::after {
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  pointer-events: none;
  content: "";
  border: var(--pf-v5-c-button--after--BorderWidth) solid;
  border-color: var(--pf-v5-c-button--after--BorderColor);
  border-radius: var(--pf-v5-c-button--after--BorderRadius);
}

.pf-v5-c-button:hover {
  --pf-v5-c-button--after--BorderWidth: var(--pf-v5-c-button--hover--after--BorderWidth);
  text-decoration: none;
}

.pf-v5-c-button:focus {
  --pf-v5-c-button--after--BorderWidth: var(--pf-v5-c-button--focus--after--BorderWidth);
}

.pf-v5-c-button:active,
.pf-v5-c-button.pf-m-active {
  --pf-v5-c-button--after--BorderWidth: var(--pf-v5-c-button--active--after--BorderWidth);
}

.pf-v5-c-button.pf-m-block {
  display: block;
  width: 100%;
}

.pf-v5-c-button.pf-m-small {
  --pf-v5-c-button--FontSize: var(--pf-v5-c-button--m-small--FontSize);
}

.pf-v5-c-button.pf-m-primary.pf-m-display-lg,
.pf-v5-c-button.pf-m-secondary.pf-m-display-lg,
.pf-v5-c-button.pf-m-tertiary.pf-m-display-lg,
.pf-v5-c-button.pf-m-link.pf-m-display-lg {
  --pf-v5-c-button--PaddingTop: var(--pf-v5-c-button--m-display-lg--PaddingTop);
  --pf-v5-c-button--PaddingRight: var(--pf-v5-c-button--m-display-lg--PaddingRight);
  --pf-v5-c-button--PaddingBottom: var(--pf-v5-c-button--m-display-lg--PaddingBottom);
  --pf-v5-c-button--PaddingLeft: var(--pf-v5-c-button--m-display-lg--PaddingLeft);
  --pf-v5-c-button--FontWeight: var(--pf-v5-c-button--m-display-lg--FontWeight);
}

.pf-v5-c-button.pf-m-primary {
  color: var(--pf-v5-c-button--m-primary--Color);
  background-color: var(--pf-v5-c-button--m-primary--BackgroundColor);
}

.pf-v5-c-button.pf-m-primary:hover {
  --pf-v5-c-button--m-primary--Color: var(--pf-v5-c-button--m-primary--hover--Color);
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-c-button--m-primary--hover--BackgroundColor);
}

.pf-v5-c-button.pf-m-primary:focus {
  --pf-v5-c-button--m-primary--Color: var(--pf-v5-c-button--m-primary--focus--Color);
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-c-button--m-primary--focus--BackgroundColor);
}

.pf-v5-c-button.pf-m-primary:active,
.pf-v5-c-button.pf-m-primary.pf-m-active {
  --pf-v5-c-button--m-primary--Color: var(--pf-v5-c-button--m-primary--active--Color);
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-c-button--m-primary--active--BackgroundColor);
}

.pf-v5-c-button.pf-m-primary .pf-v5-c-badge.pf-m-unread {
  border: var(--pf-v5-c-button--m-primary__c-badge--BorderWidth) solid var(--pf-v5-c-button--m-primary__c-badge--BorderColor);
}

.pf-v5-c-button.pf-m-secondary {
  --pf-v5-c-button--after--BorderColor: var(--pf-v5-c-button--m-secondary--after--BorderColor);
  color: var(--pf-v5-c-button--m-secondary--Color);
  background-color: var(--pf-v5-c-button--m-secondary--BackgroundColor);
}

.pf-v5-c-button.pf-m-secondary:hover {
  --pf-v5-c-button--m-secondary--Color: var(--pf-v5-c-button--m-secondary--hover--Color);
  --pf-v5-c-button--m-secondary--BackgroundColor: var(--pf-v5-c-button--m-secondary--hover--BackgroundColor);
  --pf-v5-c-button--after--BorderColor: var(--pf-v5-c-button--m-secondary--hover--after--BorderColor);
}

.pf-v5-c-button.pf-m-secondary:focus {
  --pf-v5-c-button--m-secondary--Color: var(--pf-v5-c-button--m-secondary--focus--Color);
  --pf-v5-c-button--m-secondary--BackgroundColor: var(--pf-v5-c-button--m-secondary--focus--BackgroundColor);
  --pf-v5-c-button--after--BorderColor: var(--pf-v5-c-button--m-secondary--focus--after--BorderColor);
}

.pf-v5-c-button.pf-m-secondary.pf-m-active,
.pf-v5-c-button.pf-m-secondary:active {
  --pf-v5-c-button--m-secondary--Color: var(--pf-v5-c-button--m-secondary--active--Color);
  --pf-v5-c-button--m-secondary--BackgroundColor: var(--pf-v5-c-button--m-secondary--active--BackgroundColor);
  --pf-v5-c-button--after--BorderColor: var(--pf-v5-c-button--m-secondary--active--after--BorderColor);
}

.pf-v5-c-button.pf-m-tertiary {
  --pf-v5-c-button--after--BorderColor: var(--pf-v5-c-button--m-tertiary--after--BorderColor);
  color: var(--pf-v5-c-button--m-tertiary--Color);
  background-color: var(--pf-v5-c-button--m-tertiary--BackgroundColor);
}

.pf-v5-c-button.pf-m-tertiary:hover {
  --pf-v5-c-button--m-tertiary--Color: var(--pf-v5-c-button--m-tertiary--hover--Color);
  --pf-v5-c-button--m-tertiary--BackgroundColor: var(--pf-v5-c-button--m-tertiary--hover--BackgroundColor);
  --pf-v5-c-button--after--BorderColor: var(--pf-v5-c-button--m-tertiary--hover--after--BorderColor);
}

.pf-v5-c-button.pf-m-tertiary:focus {
  --pf-v5-c-button--m-tertiary--Color: var(--pf-v5-c-button--m-tertiary--focus--Color);
  --pf-v5-c-button--m-tertiary--BackgroundColor: var(--pf-v5-c-button--m-tertiary--focus--BackgroundColor);
  --pf-v5-c-button--after--BorderColor: var(--pf-v5-c-button--m-tertiary--focus--after--BorderColor);
}

.pf-v5-c-button.pf-m-tertiary:active,
.pf-v5-c-button.pf-m-tertiary.pf-m-active {
  --pf-v5-c-button--m-tertiary--Color: var(--pf-v5-c-button--m-tertiary--active--Color);
  --pf-v5-c-button--m-tertiary--BackgroundColor: var(--pf-v5-c-button--m-tertiary--active--BackgroundColor);
  --pf-v5-c-button--after--BorderColor: var(--pf-v5-c-button--m-tertiary--active--after--BorderColor);
}

.pf-v5-c-button.pf-m-link {
  --pf-v5-c-button--disabled--BackgroundColor: var(--pf-v5-c-button--m-link--disabled--BackgroundColor);
  --pf-v5-c-button--disabled--Color: var(--pf-v5-c-button--m-link--disabled--Color);
  color: var(--pf-v5-c-button--m-link--Color);
  background-color: var(--pf-v5-c-button--m-link--BackgroundColor);
}

.pf-v5-c-button.pf-m-link:not(.pf-m-inline):hover {
  --pf-v5-c-button--m-link--Color: var(--pf-v5-c-button--m-link--hover--Color);
  --pf-v5-c-button--m-link--BackgroundColor: var(--pf-v5-c-button--m-link--hover--BackgroundColor);
}

.pf-v5-c-button.pf-m-link:not(.pf-m-inline):focus {
  --pf-v5-c-button--m-link--Color: var(--pf-v5-c-button--m-link--focus--Color);
  --pf-v5-c-button--m-link--BackgroundColor: var(--pf-v5-c-button--m-link--focus--BackgroundColor);
}

.pf-v5-c-button.pf-m-link:not(.pf-m-inline):active,
.pf-v5-c-button.pf-m-link:not(.pf-m-inline).pf-m-active {
  --pf-v5-c-button--m-link--Color: var(--pf-v5-c-button--m-link--active--Color);
  --pf-v5-c-button--m-link--BackgroundColor: var(--pf-v5-c-button--m-link--active--BackgroundColor);
}

.pf-v5-c-button.pf-m-link.pf-m-inline {
  --pf-v5-c-button--FontSize: var(--pf-v5-c-button--m-link--m-inline--FontSize);
  --pf-v5-c-button__progress--Left: var(--pf-v5-c-button--m-link--m-inline__progress--Left);
  display: inline;
  padding-block-start: var(--pf-v5-c-button--m-link--m-inline--PaddingTop);
  padding-block-end: var(--pf-v5-c-button--m-link--m-inline--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-button--m-link--m-inline--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-button--m-link--m-inline--PaddingRight);
  text-align: start;
  white-space: normal;
  cursor: pointer;
}

.pf-v5-c-button.pf-m-link.pf-m-inline:hover {
  --pf-v5-c-button--m-link--Color: var(--pf-v5-c-button--m-link--m-inline--hover--Color);
  text-decoration: var(--pf-v5-c-button--m-link--m-inline--hover--TextDecoration);
}

.pf-v5-c-button.pf-m-link.pf-m-display-lg {
  --pf-v5-c-button--FontSize: var(--pf-v5-c-button--m-link--m-display-lg--FontSize);
}

.pf-v5-c-button.pf-m-danger {
  color: var(--pf-v5-c-button--m-danger--Color);
  background-color: var(--pf-v5-c-button--m-danger--BackgroundColor);
}

.pf-v5-c-button.pf-m-danger:hover {
  --pf-v5-c-button--m-danger--Color: var(--pf-v5-c-button--m-danger--hover--Color);
  --pf-v5-c-button--m-danger--BackgroundColor: var(--pf-v5-c-button--m-danger--hover--BackgroundColor);
}

.pf-v5-c-button.pf-m-danger:focus {
  --pf-v5-c-button--m-danger--Color: var(--pf-v5-c-button--m-danger--focus--Color);
  --pf-v5-c-button--m-danger--BackgroundColor: var(--pf-v5-c-button--m-danger--focus--BackgroundColor);
}

.pf-v5-c-button.pf-m-danger:active,
.pf-v5-c-button.pf-m-danger.pf-m-active {
  --pf-v5-c-button--m-danger--Color: var(--pf-v5-c-button--m-danger--active--Color);
  --pf-v5-c-button--m-danger--BackgroundColor: var(--pf-v5-c-button--m-danger--active--BackgroundColor);
}

.pf-v5-c-button.pf-m-danger.pf-m-secondary {
  --pf-v5-c-button--m-danger--Color: var(--pf-v5-c-button--m-secondary--m-danger--Color);
  --pf-v5-c-button--m-danger--BackgroundColor: var(--pf-v5-c-button--m-secondary--m-danger--BackgroundColor);
  --pf-v5-c-button--after--BorderColor: var(--pf-v5-c-button--m-secondary--m-danger--after--BorderColor);
}

.pf-v5-c-button.pf-m-danger.pf-m-secondary:hover {
  --pf-v5-c-button--m-secondary--m-danger--Color: var(--pf-v5-c-button--m-secondary--m-danger--hover--Color);
  --pf-v5-c-button--m-secondary--m-danger--BackgroundColor: var(--pf-v5-c-button--m-secondary--m-danger--hover--BackgroundColor);
  --pf-v5-c-button--after--BorderColor: var(--pf-v5-c-button--m-secondary--m-danger--hover--after--BorderColor);
}

.pf-v5-c-button.pf-m-danger.pf-m-secondary:focus {
  --pf-v5-c-button--m-secondary--m-danger--Color: var(--pf-v5-c-button--m-secondary--m-danger--focus--Color);
  --pf-v5-c-button--m-secondary--m-danger--BackgroundColor: var(--pf-v5-c-button--m-secondary--m-danger--focus--BackgroundColor);
  --pf-v5-c-button--after--BorderColor: var(--pf-v5-c-button--m-secondary--m-danger--focus--after--BorderColor);
}

.pf-v5-c-button.pf-m-danger.pf-m-secondary:active,
.pf-v5-c-button.pf-m-danger.pf-m-secondary.pf-m-active {
  --pf-v5-c-button--m-secondary--m-danger--Color: var(--pf-v5-c-button--m-secondary--m-danger--active--Color);
  --pf-v5-c-button--m-secondary--m-danger--BackgroundColor: var(--pf-v5-c-button--m-secondary--m-danger--active--BackgroundColor);
  --pf-v5-c-button--after--BorderColor: var(--pf-v5-c-button--m-secondary--m-danger--active--after--BorderColor);
}

.pf-v5-c-button.pf-m-danger.pf-m-link {
  --pf-v5-c-button--m-danger--Color: var(--pf-v5-c-button--m-link--m-danger--Color);
  --pf-v5-c-button--m-danger--BackgroundColor: var(--pf-v5-c-button--m-link--m-danger--BackgroundColor);
}

.pf-v5-c-button.pf-m-danger.pf-m-link:hover {
  --pf-v5-c-button--m-link--m-danger--Color: var(--pf-v5-c-button--m-link--m-danger--hover--Color);
  --pf-v5-c-button--m-link--m-danger--BackgroundColor: var(--pf-v5-c-button--m-link--m-danger--hover--BackgroundColor);
}

.pf-v5-c-button.pf-m-danger.pf-m-link:focus {
  --pf-v5-c-button--m-link--m-danger--Color: var(--pf-v5-c-button--m-link--m-danger--focus--Color);
  --pf-v5-c-button--m-link--m-danger--BackgroundColor: var(--pf-v5-c-button--m-link--m-danger--focus--BackgroundColor);
}

.pf-v5-c-button.pf-m-danger.pf-m-link:active,
.pf-v5-c-button.pf-m-danger.pf-m-link.pf-m-active {
  --pf-v5-c-button--m-link--m-danger--Color: var(--pf-v5-c-button--m-link--m-danger--active--Color);
  --pf-v5-c-button--m-link--m-danger--BackgroundColor: var(--pf-v5-c-button--m-link--m-danger--active--BackgroundColor);
}

.pf-v5-c-button.pf-m-warning {
  color: var(--pf-v5-c-button--m-warning--Color);
  background-color: var(--pf-v5-c-button--m-warning--BackgroundColor);
}

.pf-v5-c-button.pf-m-warning:hover {
  --pf-v5-c-button--m-warning--Color: var(--pf-v5-c-button--m-warning--hover--Color);
  --pf-v5-c-button--m-warning--BackgroundColor: var(--pf-v5-c-button--m-warning--hover--BackgroundColor);
}

.pf-v5-c-button.pf-m-warning:focus {
  --pf-v5-c-button--m-warning--Color: var(--pf-v5-c-button--m-warning--focus--Color);
  --pf-v5-c-button--m-warning--BackgroundColor: var(--pf-v5-c-button--m-warning--focus--BackgroundColor);
}

.pf-v5-c-button.pf-m-warning:active,
.pf-v5-c-button.pf-m-warning.pf-m-active {
  --pf-v5-c-button--m-warning--Color: var(--pf-v5-c-button--m-warning--active--Color);
  --pf-v5-c-button--m-warning--BackgroundColor: var(--pf-v5-c-button--m-warning--active--BackgroundColor);
}

.pf-v5-c-button.pf-m-control {
  --pf-v5-c-button--BorderRadius: var(--pf-v5-c-button--m-control--BorderRadius);
  --pf-v5-c-button--disabled--BackgroundColor: var(--pf-v5-c-button--m-control--disabled--BackgroundColor);
  --pf-v5-c-button--after--BorderWidth: var(--pf-v5-c-button--m-control--after--BorderWidth);
  --pf-v5-c-button--after--BorderColor: var(--pf-v5-c-button--m-control--after--BorderTopColor) var(--pf-v5-c-button--m-control--after--BorderRightColor) var(--pf-v5-c-button--m-control--after--BorderBottomColor) var(--pf-v5-c-button--m-control--after--BorderLeftColor);
  color: var(--pf-v5-c-button--m-control--Color);
  background-color: var(--pf-v5-c-button--m-control--BackgroundColor);
}

.pf-v5-c-button.pf-m-control::after {
  border-radius: initial;
}

.pf-v5-c-button.pf-m-control:hover {
  --pf-v5-c-button--m-control--Color: var(--pf-v5-c-button--m-control--hover--Color);
  --pf-v5-c-button--m-control--BackgroundColor: var(--pf-v5-c-button--m-control--hover--BackgroundColor);
  --pf-v5-c-button--m-control--after--BorderBottomColor: var(--pf-v5-c-button--m-control--hover--after--BorderBottomColor);
}

.pf-v5-c-button.pf-m-control:hover::after {
  border-block-end-width: var(--pf-v5-c-button--m-control--hover--after--BorderBottomWidth);
}

.pf-v5-c-button.pf-m-control:active,
.pf-v5-c-button.pf-m-control.pf-m-active {
  --pf-v5-c-button--m-control--Color: var(--pf-v5-c-button--m-control--active--Color);
  --pf-v5-c-button--m-control--BackgroundColor: var(--pf-v5-c-button--m-control--active--BackgroundColor);
  --pf-v5-c-button--m-control--after--BorderBottomColor: var(--pf-v5-c-button--m-control--active--after--BorderBottomColor);
}

.pf-v5-c-button.pf-m-control:active::after,
.pf-v5-c-button.pf-m-control.pf-m-active::after {
  border-block-end-width: var(--pf-v5-c-button--m-control--active--after--BorderBottomWidth);
}

.pf-v5-c-button.pf-m-control:focus {
  --pf-v5-c-button--m-control--Color: var(--pf-v5-c-button--m-control--focus--Color);
  --pf-v5-c-button--m-control--BackgroundColor: var(--pf-v5-c-button--m-control--focus--BackgroundColor);
  --pf-v5-c-button--m-control--after--BorderBottomColor: var(--pf-v5-c-button--m-control--focus--after--BorderBottomColor);
}

.pf-v5-c-button.pf-m-control:focus::after {
  border-block-end-width: var(--pf-v5-c-button--m-control--focus--after--BorderBottomWidth);
}

.pf-v5-c-button.pf-m-control.pf-m-expanded {
  --pf-v5-c-button--m-control--Color: var(--pf-v5-c-button--m-control--m-expanded--Color);
  --pf-v5-c-button--m-control--BackgroundColor: var(--pf-v5-c-button--m-control--m-expanded--BackgroundColor);
  --pf-v5-c-button--m-control--after--BorderBottomColor: var(--pf-v5-c-button--m-control--m-expanded--after--BorderBottomColor);
}

.pf-v5-c-button.pf-m-control.pf-m-expanded::after {
  border-block-end-width: var(--pf-v5-c-button--m-control--m-expanded--after--BorderBottomWidth);
}

.pf-v5-c-button.pf-m-plain {
  --pf-v5-c-button--disabled--Color: var(--pf-v5-c-button--m-plain--disabled--Color);
  --pf-v5-c-button--disabled--BackgroundColor: var(--pf-v5-c-button--m-plain--disabled--BackgroundColor);
  color: var(--pf-v5-c-button--m-plain--Color);
  background-color: var(--pf-v5-c-button--m-plain--BackgroundColor);
}

.pf-v5-c-button.pf-m-plain.pf-m-no-padding {
  padding: 0;
}

.pf-v5-c-button.pf-m-plain:hover {
  --pf-v5-c-button--m-plain--Color: var(--pf-v5-c-button--m-plain--hover--Color);
  --pf-v5-c-button--m-plain--BackgroundColor: var(--pf-v5-c-button--m-plain--hover--BackgroundColor);
}

.pf-v5-c-button.pf-m-plain:active,
.pf-v5-c-button.pf-m-plain.pf-m-active {
  --pf-v5-c-button--m-plain--Color: var(--pf-v5-c-button--m-plain--active--Color);
  --pf-v5-c-button--m-plain--BackgroundColor: var(--pf-v5-c-button--m-plain--active--BackgroundColor);
}

.pf-v5-c-button.pf-m-plain:focus {
  --pf-v5-c-button--m-plain--Color: var(--pf-v5-c-button--m-plain--focus--Color);
  --pf-v5-c-button--m-plain--BackgroundColor: var(--pf-v5-c-button--m-plain--focus--BackgroundColor);
}

.pf-v5-c-button:disabled,
.pf-v5-c-button.pf-m-disabled {
  pointer-events: none;
}

.pf-v5-c-button:disabled,
.pf-v5-c-button.pf-m-disabled,
.pf-v5-c-button.pf-m-aria-disabled {
  color: var(--pf-v5-c-button--disabled--Color);
  background-color: var(--pf-v5-c-button--disabled--BackgroundColor);
}

.pf-v5-c-button:disabled::after,
.pf-v5-c-button.pf-m-disabled::after,
.pf-v5-c-button.pf-m-aria-disabled::after {
  border-color: var(--pf-v5-c-button--disabled--after--BorderColor);
}

.pf-v5-c-button:disabled .pf-v5-c-badge,
.pf-v5-c-button.pf-m-disabled .pf-v5-c-badge,
.pf-v5-c-button.pf-m-aria-disabled .pf-v5-c-badge {
  --pf-v5-c-badge--m-unread--Color: var(--pf-v5-c-button--disabled__c-badge--Color);
  --pf-v5-c-badge--m-unread--BackgroundColor: var(--pf-v5-c-button--disabled__c-badge--BackgroundColor);
  --pf-v5-c-button--m-primary__c-badge--BorderWidth: 0;
}

.pf-v5-c-button.pf-m-aria-disabled {
  --pf-v5-c-button--after--BorderWidth: 0;
  --pf-v5-c-button--m-link--m-inline--hover--TextDecoration: none;
  cursor: default;
}

.pf-v5-c-button.pf-m-progress {
  --pf-v5-c-button--PaddingRight: var(--pf-v5-c-button--m-progress--PaddingRight);
  --pf-v5-c-button--PaddingLeft: var(--pf-v5-c-button--m-progress--PaddingLeft);
  transition: var(--pf-v5-c-button--m-progress--TransitionProperty) var(--pf-v5-c-button--m-progress--TransitionDuration);
}

.pf-v5-c-button.pf-m-in-progress {
  --pf-v5-c-button--m-link--m-inline--PaddingLeft: var(--pf-v5-c-button--m-link--m-inline--m-in-progress--PaddingLeft);
}

.pf-v5-c-button.pf-m-in-progress:not(.pf-m-plain) {
  --pf-v5-c-button--PaddingRight: var(--pf-v5-c-button--m-in-progress--PaddingRight);
  --pf-v5-c-button--PaddingLeft: var(--pf-v5-c-button--m-in-progress--PaddingLeft);
}

.pf-v5-c-button.pf-m-in-progress.pf-m-plain {
  --pf-v5-c-button--m-plain--Color: var(--pf-v5-c-button--m-in-progress--m-plain--Color);
  --pf-v5-c-button__progress--Left: var(--pf-v5-c-button--m-in-progress--m-plain__progress--Left);
  --pf-v5-c-button__progress--TranslateX: var(--pf-v5-c-button--m-in-progress--m-plain__progress--TranslateX);
}

.pf-v5-c-button.pf-m-in-progress.pf-m-plain>:not(.pf-v5-c-button__progress) {
  opacity: 0;
}

.pf-v5-c-button__icon.pf-m-start {
  margin-inline-end: var(--pf-v5-c-button__icon--m-start--MarginRight);
}

.pf-v5-c-button__icon.pf-m-end {
  margin-inline-start: var(--pf-v5-c-button__icon--m-end--MarginLeft);
}

.pf-v5-c-button__progress {
  position: absolute;
  inset-block-start: var(--pf-v5-c-button__progress--Top);
  inset-inline-start: var(--pf-v5-c-button__progress--Left);
  line-height: 1;
  transform: translate(var(--pf-v5-c-button__progress--TranslateX), var(--pf-v5-c-button__progress--TranslateY));
}

.pf-v5-c-button__progress .pf-v5-c-spinner {
  --pf-v5-c-spinner--Color: currentcolor;
}

.pf-v5-c-button__count {
  display: inline-flex;
  align-items: center;
  margin-inline-start: var(--pf-v5-c-button__count--MarginLeft);
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-button {
  --pf-v5-c-button--disabled--Color: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
  --pf-v5-c-button--m-primary--Color: var(--pf-v5-global--primary-color--400);
  --pf-v5-c-button--m-tertiary--after--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-button--m-tertiary--Color: var(--pf-v5-global--palette--black-100);
  --pf-v5-c-button--m-tertiary--hover--after--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-button--m-tertiary--hover--Color: var(--pf-v5-global--palette--black-100);
  --pf-v5-c-button--m-tertiary--focus--after--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-button--m-tertiary--focus--Color: var(--pf-v5-global--palette--black-100);
  --pf-v5-c-button--m-tertiary--active--after--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-button--m-tertiary--active--Color: var(--pf-v5-global--palette--black-100);
  --pf-v5-c-button--m-warning--Color: var(--pf-v5-global--palette--black-900);
  --pf-v5-c-button--m-warning--hover--Color: var(--pf-v5-global--palette--black-900);
  --pf-v5-c-button--m-warning--focus--Color: var(--pf-v5-global--palette--black-900);
  --pf-v5-c-button--m-warning--active--Color: var(--pf-v5-global--palette--black-900);
  --pf-v5-c-button--m-warning--hover--BackgroundColor: var(--pf-v5-global--warning-color--200);
  --pf-v5-c-button--m-warning--focus--BackgroundColor: var(--pf-v5-global--warning-color--200);
  --pf-v5-c-button--m-warning--active--BackgroundColor: var(--pf-v5-global--warning-color--200);
  --pf-v5-c-button--m-danger--Color: var(--pf-v5-global--palette--black-900);
  --pf-v5-c-button--m-danger--hover--Color: var(--pf-v5-global--palette--black-900);
  --pf-v5-c-button--m-danger--focus--Color: var(--pf-v5-global--palette--black-900);
  --pf-v5-c-button--m-danger--active--Color: var(--pf-v5-global--palette--black-900);
  --pf-v5-c-button--m-link--disabled--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-button--m-control--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-button--m-control--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-button--m-control--active--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-button--m-control--focus--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-button--m-control--m-expanded--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-button--m-control--after--BorderTopColor: transparent;
  --pf-v5-c-button--m-control--after--BorderRightColor: transparent;
  --pf-v5-c-button--m-control--after--BorderBottomColor: var(--pf-v5-global--BorderColor--400);
  --pf-v5-c-button--m-control--after--BorderLeftColor: transparent;
  --pf-v5-c-button--m-control--hover--after--BorderBottomColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-button--m-control--active--after--BorderBottomColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-button--m-control--focus--after--BorderBottomColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-button--m-control--m-expanded--after--BorderBottomColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-button--m-control--disabled--BackgroundColor: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-button--m-primary__c-badge--BorderColor: var(--pf-v5-global--Color--100);
}

:where(.pf-v5-theme-dark) .pf-v5-c-button.pf-m-control:disabled::after {
  border: 0;
  border-block-end: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--palette--black-700);
}

.pf-v5-c-calendar-month {
  --pf-v5-c-calendar-month--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-calendar-month--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-calendar-month--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-calendar-month--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-calendar-month--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-calendar-month--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-calendar-month__header--MarginBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-calendar-month__header-year--Width: 8.5ch;
  --pf-v5-c-calendar-month__header-nav-control--MarginRight: 0;
  --pf-v5-c-calendar-month__header-nav-control--MarginLeft: 0;
  --pf-v5-c-calendar-month__header-nav-control--m-prev-month--MarginRight: 0;
  --pf-v5-c-calendar-month__header-nav-control--m-prev-month--MarginLeft: 0;
  --pf-v5-c-calendar-month__header-nav-control--m-next-month--MarginRight: 0;
  --pf-v5-c-calendar-month__header-nav-control--m-next-month--MarginLeft: 0;
  --pf-v5-c-calendar-month__days--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-calendar-month__days--BorderBottomColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-calendar-month__day--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-calendar-month__day--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-calendar-month__dates-cell--PaddingTop: 0.125rem;
  --pf-v5-c-calendar-month__dates-cell--PaddingRight: 0.125rem;
  --pf-v5-c-calendar-month__dates-cell--PaddingBottom: 0.125rem;
  --pf-v5-c-calendar-month__dates-cell--PaddingLeft: 0.125rem;
  --pf-v5-c-calendar-month__dates-row--first-child__dates-cell--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-calendar-month__dates-cell--m-current__date--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-calendar-month__dates-cell--m-selected__date--BackgroundColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-calendar-month__dates-cell--m-selected__date--hover--BackgroundColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-calendar-month__dates-cell--m-selected__date--focus--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-calendar-month__dates-cell--m-selected__date--focus--after--BorderColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-calendar-month__dates-cell--m-selected__date--focus--BoxShadow: 0 0 0.3125rem var(--pf-v5-global--primary-color--100);
  --pf-v5-c-calendar-month__dates-cell--m-selected__date--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-calendar-month__dates-cell--before--BackgroundColor: transparent;
  --pf-v5-c-calendar-month__dates-cell--before--Top: 0;
  --pf-v5-c-calendar-month__dates-cell--before--Right: 0;
  --pf-v5-c-calendar-month__dates-cell--before--Bottom: var(--pf-v5-c-calendar-month__dates-cell--PaddingBottom);
  --pf-v5-c-calendar-month__dates-cell--before--Left: 0;
  --pf-v5-c-calendar-month__dates-cell--m-in-range--before--BackgroundColor: var(--pf-v5-global--palette--blue-50);
  --pf-v5-c-calendar-month__dates-cell--m-in-range--m-start-range--before--Left: 50%;
  --pf-v5-c-calendar-month__dates-cell--m-in-range--m-end-range--before--Right: 50%;
  --pf-v5-c-calendar-month__dates-cell--m-in-range__date--hover--BackgroundColor: var(--pf-v5-global--palette--blue-100);
  --pf-v5-c-calendar-month__dates-cell--m-in-range__date--focus--BackgroundColor: var(--pf-v5-global--palette--blue-100);
  --pf-v5-c-calendar-month__dates-cell--m-adjacent-month__date--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-calendar-month__date--Width: 4ch;
  --pf-v5-c-calendar-month__date--Height: 4ch;
  --pf-v5-c-calendar-month__date--BorderRadius: var(--pf-v5-global--BorderRadius--lg);
  --pf-v5-c-calendar-month__date--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-calendar-month__date--BackgroundColor: transparent;
  --pf-v5-c-calendar-month__date--disabled--Color: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-calendar-month__date--after--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-calendar-month__date--after--BorderColor: transparent;
  --pf-v5-c-calendar-month__date--hover--BackgroundColor: var(--pf-v5-global--palette--blue-50);
  --pf-v5-c-calendar-month__date--focus--BackgroundColor: var(--pf-v5-global--palette--blue-50);
  --pf-v5-c-calendar-month__date--focus--after--BorderColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-calendar-month__date--focus--BoxShadow: none;
  color: var(--pf-v5-global--Color--100);
  display: inline-flex;
  flex-direction: column;
  padding-block-start: var(--pf-v5-c-calendar-month--PaddingTop);
  padding-block-end: var(--pf-v5-c-calendar-month--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-calendar-month--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-calendar-month--PaddingRight);
  font-size: var(--pf-v5-c-calendar-month--FontSize);
  background-color: var(--pf-v5-c-calendar-month--BackgroundColor);
}

.pf-v5-c-calendar-month__header {
  display: flex;
  margin-block-end: var(--pf-v5-c-calendar-month__header--MarginBottom);
}

.pf-v5-c-calendar-month__header-nav-control {
  margin-inline-start: var(--pf-v5-c-calendar-month__header-nav-control--MarginLeft);
  margin-inline-end: var(--pf-v5-c-calendar-month__header-nav-control--MarginRight);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-calendar-month__header-nav-control {
  scale: -1 1;
}

.pf-v5-c-calendar-month__header-nav-control.pf-m-prev-month {
  --pf-v5-c-calendar-month__header-nav-control--MarginRight: var(--pf-v5-c-calendar-month__header-nav-control--m-prev-month--MarginRight);
  --pf-v5-c-calendar-month__header-nav-control--MarginLeft: var(--pf-v5-c-calendar-month__header-nav-control--m-prev-month--MarginLeft);
}

.pf-v5-c-calendar-month__header-nav-control.pf-m-next-month {
  --pf-v5-c-calendar-month__header-nav-control--MarginRight: var(--pf-v5-c-calendar-month__header-nav-control--m-next-month--MarginRight);
  --pf-v5-c-calendar-month__header-nav-control--MarginLeft: var(--pf-v5-c-calendar-month__header-nav-control--m-next-month--MarginLeft);
}

.pf-v5-c-calendar-month__header-month {
  flex-grow: 1;
}

.pf-v5-c-calendar-month__header-year {
  width: var(--pf-v5-c-calendar-month__header-year--Width);
}

.pf-v5-c-calendar-month__calendar {
  table-layout: fixed;
}

.pf-v5-c-calendar-month__days {
  border-block-end: var(--pf-v5-c-calendar-month__days--BorderBottomWidth) solid var(--pf-v5-c-calendar-month__days--BorderBottomColor);
}

.pf-v5-c-calendar-month__day {
  padding-block-end: var(--pf-v5-c-calendar-month__day--PaddingBottom);
  font-weight: var(--pf-v5-c-calendar-month__day--FontWeight);
  text-align: center;
}

.pf-v5-c-calendar-month__dates-row:first-child {
  --pf-v5-c-calendar-month__dates-cell--PaddingTop: var(--pf-v5-c-calendar-month__dates-row--first-child__dates-cell--PaddingTop);
}

.pf-v5-c-calendar-month__dates-cell {
  --pf-v5-c-calendar-month__dates-cell--before--Top: var(--pf-v5-c-calendar-month__dates-cell--PaddingTop);
  position: relative;
  padding-block-start: var(--pf-v5-c-calendar-month__dates-cell--PaddingTop);
  padding-block-end: var(--pf-v5-c-calendar-month__dates-cell--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-calendar-month__dates-cell--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-calendar-month__dates-cell--PaddingRight);
  text-align: center;
}

.pf-v5-c-calendar-month__dates-cell::before {
  position: absolute;
  inset-block-start: var(--pf-v5-c-calendar-month__dates-cell--before--Top);
  inset-block-end: var(--pf-v5-c-calendar-month__dates-cell--before--Bottom);
  inset-inline-start: var(--pf-v5-c-calendar-month__dates-cell--before--Left);
  inset-inline-end: var(--pf-v5-c-calendar-month__dates-cell--before--Right);
  content: "";
  background-color: var(--pf-v5-c-calendar-month__dates-cell--before--BackgroundColor);
}

.pf-v5-c-calendar-month__dates-cell.pf-m-current {
  --pf-v5-c-calendar-month__date--BackgroundColor: var(--pf-v5-c-calendar-month__dates-cell--m-current__date--BackgroundColor);
}

.pf-v5-c-calendar-month__dates-cell.pf-m-in-range {
  --pf-v5-c-calendar-month__dates-cell--before--BackgroundColor: var(--pf-v5-c-calendar-month__dates-cell--m-in-range--before--BackgroundColor);
  --pf-v5-c-calendar-month__date--hover--BackgroundColor: var(--pf-v5-c-calendar-month__dates-cell--m-in-range__date--hover--BackgroundColor);
  --pf-v5-c-calendar-month__date--focus--BackgroundColor: var(--pf-v5-c-calendar-month__dates-cell--m-in-range__date--focus--BackgroundColor);
}

.pf-v5-c-calendar-month__dates-cell.pf-m-start-range {
  --pf-v5-c-calendar-month__dates-cell--before--Left: var(--pf-v5-c-calendar-month__dates-cell--m-in-range--m-start-range--before--Left);
}

.pf-v5-c-calendar-month__dates-cell.pf-m-end-range {
  --pf-v5-c-calendar-month__dates-cell--before--Right: var(--pf-v5-c-calendar-month__dates-cell--m-in-range--m-end-range--before--Right);
}

.pf-v5-c-calendar-month__dates-cell.pf-m-adjacent-month {
  --pf-v5-c-calendar-month__date--Color: var(--pf-v5-c-calendar-month__dates-cell--m-adjacent-month__date--Color);
}

.pf-v5-c-calendar-month__dates-cell.pf-m-selected {
  --pf-v5-c-calendar-month__date--BackgroundColor: var(--pf-v5-c-calendar-month__dates-cell--m-selected__date--BackgroundColor);
  --pf-v5-c-calendar-month__date--hover--BackgroundColor: var(--pf-v5-c-calendar-month__dates-cell--m-selected__date--hover--BackgroundColor);
  --pf-v5-c-calendar-month__date--focus--BackgroundColor: var(--pf-v5-c-calendar-month__dates-cell--m-selected__date--focus--BackgroundColor);
  --pf-v5-c-calendar-month__date--focus--after--BorderColor: var(--pf-v5-c-calendar-month__dates-cell--m-selected__date--focus--after--BorderColor);
  --pf-v5-c-calendar-month__date--focus--BoxShadow: var(--pf-v5-c-calendar-month__dates-cell--m-selected__date--focus--BoxShadow);
  --pf-v5-c-calendar-month__date--Color: var(--pf-v5-c-calendar-month__dates-cell--m-selected__date--Color);
}

.pf-v5-c-calendar-month__dates-cell.pf-m-disabled {
  --pf-v5-c-calendar-month__dates-cell--before--BackgroundColor: transparent;
  --pf-v5-c-calendar-month__date--BackgroundColor: transparent;
}

.pf-v5-c-calendar-month__date {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--pf-v5-c-calendar-month__date--Width);
  height: var(--pf-v5-c-calendar-month__date--Height);
  line-height: 1;
  color: var(--pf-v5-c-calendar-month__date--Color);
  background-color: var(--pf-v5-c-calendar-month__date--BackgroundColor);
  border: 0;
}

.pf-v5-c-calendar-month__date::after {
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  content: "";
  border: var(--pf-v5-c-calendar-month__date--after--BorderWidth) solid var(--pf-v5-c-calendar-month__date--after--BorderColor);
}

.pf-v5-c-calendar-month__date,
.pf-v5-c-calendar-month__date::after {
  border-radius: var(--pf-v5-c-calendar-month__date--BorderRadius);
}

.pf-v5-c-calendar-month__date:hover,
.pf-v5-c-calendar-month__date.pf-m-hover {
  --pf-v5-c-calendar-month__date--BackgroundColor: var(--pf-v5-c-calendar-month__date--hover--BackgroundColor);
}

.pf-v5-c-calendar-month__date:focus,
.pf-v5-c-calendar-month__date.pf-m-focus {
  --pf-v5-c-calendar-month__date--BackgroundColor: var(--pf-v5-c-calendar-month__date--focus--BackgroundColor);
  --pf-v5-c-calendar-month__date--after--BorderColor: var(--pf-v5-c-calendar-month__date--focus--after--BorderColor);
  outline: 0;
  box-shadow: var(--pf-v5-c-calendar-month__date--focus--BoxShadow);
}

.pf-v5-c-calendar-month__date:disabled {
  --pf-v5-c-calendar-month__date--Color: var(--pf-v5-c-calendar-month__date--disabled--Color);
  --pf-v5-c-calendar-month__date--hover--focus--BorderColor: transparent;
  pointer-events: none;
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-calendar-month {
  --pf-v5-c-calendar-month--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-calendar-month__dates-cell--m-current__date--BackgroundColor: var(--pf-v5-global--palette--black-500);
  --pf-v5-c-calendar-month__dates-cell--m-selected__date--BackgroundColor: var(--pf-v5-global--primary-color--300);
  --pf-v5-c-calendar-month__dates-cell--m-selected__date--Color: var(--pf-v5-global--primary-color--400);
  --pf-v5-c-calendar-month__dates-cell--m-selected__date--hover--BackgroundColor: var(--pf-v5-global--palette--black-900);
  --pf-v5-c-calendar-month__dates-cell--m-selected__date--focus--BackgroundColor: var(--pf-v5-global--palette--blue-400);
  --pf-v5-c-calendar-month__dates-cell--m-in-range--before--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-calendar-month__dates-cell--m-in-range__date--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-calendar-month__dates-cell--m-in-range__date--focus--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-calendar-month__date--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-calendar-month__date--focus--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-calendar-month__date--focus--after--BorderColor: transparent;
}

:where(.pf-v5-theme-dark) .pf-v5-c-calendar-month__date:hover,
:where(.pf-v5-theme-dark) .pf-v5-c-calendar-month__date.pf-m-hover {
  --pf-v5-c-calendar-month__date--after--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-calendar-month__date--after--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
}

:where(.pf-v5-theme-dark) .pf-v5-c-calendar-month__date:focus,
:where(.pf-v5-theme-dark) .pf-v5-c-calendar-month__date.pf-m-focus {
  --pf-v5-c-calendar-month__date--after--BorderColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-calendar-month__date--after--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-calendar-month__date--BackgroundColor: var(--pf-v5-global--primary-color--300);
  --pf-v5-c-calendar-month__date--Color: var(--pf-v5-global--primary-color--400);
}

.pf-v5-c-card {
  --pf-v5-c-card--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-card--BoxShadow: var(--pf-v5-global--BoxShadow--sm);
  --pf-v5-c-card--first-child--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-card--child--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-card--child--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-card--child--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-card--c-divider--child--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-card__title--not--last-child--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-card__title-text--FontFamily: var(--pf-v5-global--FontFamily--heading);
  --pf-v5-c-card__title-text--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-card__title-text--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-card__title-text--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-card__body--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-card__footer--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-card__actions--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-card__actions--Gap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-card__actions--MarginTop: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-card__actions--MarginBottom: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-card__header-toggle--MarginTop: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-card__header-toggle--MarginRight: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-card__header-toggle--MarginBottom: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-card__header-toggle--MarginLeft: calc(var(--pf-v5-global--spacer--md) * -1);
  --pf-v5-c-card__header-toggle-icon--Transition: var(--pf-v5-global--Transition);
  --pf-v5-c-card--m-expanded__header-toggle-icon--Rotate: 90deg;
  --pf-v5-c-card--m-selectable--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-card--m-selectable--m-selected--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-card--m-selectable--m-selected--BorderColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-card--m-selectable--hover--BackgroundColor: var(--pf-v5-global--palette--black-150);
  --pf-v5-c-card--m-selectable--hover--BoxShadow: var(--pf-v5-global--BoxShadow--lg);
  --pf-v5-c-card--m-selectable--focus--BackgroundColor: var(--pf-v5-global--palette--blue-100);
  --pf-v5-c-card--m-selectable--focus--BorderColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-card--m-selectable--m-selected--focus--BorderColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-card--m-selectable--m-disabled--BackgroundColor: var(--pf-v5-global--palette--black-100);
  --pf-v5-c-card--m-selectable--m-disabled--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-card--m-selectable--m-disabled--BoxShadow: var(--pf-v5-global--BoxShadow--sm);
  --pf-v5-c-card--m-selectable--m-clickable--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-card--m-selectable--m-clickable--hover--BoxShadow: var(--pf-v5-global--BoxShadow--sm);
  --pf-v5-c-card--m-selectable--m-clickable--m-selected--BorderColor: transparent;
  --pf-v5-c-card--m-selectable--m-clickable--m-selected--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-card--m-selectable--m-clickable--m-current--BackgroundColor: var(--pf-v5-global--palette--black-150);
  --pf-v5-c-card--m-selectable--m-clickable--m-current--BorderColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-card--m-hoverable-raised--hover--BoxShadow: var(--pf-v5-global--BoxShadow--md);
  --pf-v5-c-card--m-hoverable-raised--hover--before--BackgroundColor: var(--pf-v5-global--active-color--400);
  --pf-v5-c-card--m-selectable-raised--before--Right: 0;
  --pf-v5-c-card--m-selectable-raised--before--Bottom: 0;
  --pf-v5-c-card--m-selectable-raised--before--Left: 0;
  --pf-v5-c-card--m-flat--m-selectable-raised--before--Right: calc(-1 * var(--pf-v5-c-card--m-flat--BorderWidth));
  --pf-v5-c-card--m-flat--m-selectable-raised--before--Bottom: calc(-1 * var(--pf-v5-c-card--m-flat--BorderWidth));
  --pf-v5-c-card--m-flat--m-selectable-raised--before--Left: calc(-1 * var(--pf-v5-c-card--m-flat--BorderWidth));
  --pf-v5-c-card--m-selectable-raised--before--Height: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-card--m-selectable-raised--before--BackgroundColor: transparent;
  --pf-v5-c-card--m-selectable-raised--before--Transition: none;
  --pf-v5-c-card--m-selectable-raised--before--ScaleY: 1;
  --pf-v5-c-card--m-selectable-raised--before--TranslateY: 0;
  --pf-v5-c-card--m-selectable-raised--hover--BoxShadow: var(--pf-v5-global--BoxShadow--md);
  --pf-v5-c-card--m-selectable-raised--hover--before--BackgroundColor: var(--pf-v5-global--active-color--400);
  --pf-v5-c-card--m-selectable-raised--focus--BoxShadow: var(--pf-v5-global--BoxShadow--md);
  --pf-v5-c-card--m-selectable-raised--focus--before--BackgroundColor: var(--pf-v5-global--active-color--400);
  --pf-v5-c-card--m-selectable-raised--active--BoxShadow: var(--pf-v5-global--BoxShadow--md);
  --pf-v5-c-card--m-selectable-raised--active--before--BackgroundColor: var(--pf-v5-global--active-color--400);
  --pf-v5-c-card--m-selectable-raised--m-selected-raised--before--BackgroundColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-card--m-selectable-raised--m-selected-raised--BoxShadow: var(--pf-v5-global--BoxShadow--lg);
  --pf-v5-c-card--m-selectable-raised--m-selected-raised--TranslateY--base: -0.5rem;
  --pf-v5-c-card--m-selectable-raised--m-selected-raised--TranslateY: var(--pf-v5-c-card--m-selectable-raised--m-selected-raised--TranslateY--base);
  --pf-v5-c-card--m-flat--m-selectable-raised--m-selected-raised--TranslateY: calc(var(--pf-v5-c-card--m-selectable-raised--m-selected-raised--TranslateY--base) + var(--pf-v5-c-card--m-flat--BorderWidth));
  --pf-v5-c-card--m-rounded--m-selectable-raised--m-selected-raised--TranslateY: calc(var(--pf-v5-c-card--m-selectable-raised--m-selected-raised--TranslateY--base) + var(--pf-v5-c-card--m-rounded--BorderRadius));
  --pf-v5-c-card--m-selectable-raised--m-selected-raised--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-card--m-selectable-raised--m-selected-raised--Transition: transform .25s linear, box-shadow .25s linear;
  --pf-v5-c-card--m-selectable-raised--m-selected-raised--before--Transition: transform .25s linear;
  --pf-v5-c-card--m-selectable-raised--m-selected-raised--before--TranslateY: calc(var(--pf-v5-c-card--m-selectable-raised--m-selected-raised--TranslateY) * -1);
  --pf-v5-c-card--m-selectable-raised--m-selected-raised--before--ScaleY: 2;
  --pf-v5-c-card--m-non-selectable-raised--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-200);
  --pf-v5-c-card--m-non-selectable-raised--before--BackgroundColor: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-card--m-non-selectable-raised--before--ScaleY: 2;
  --pf-v5-c-card--m-flat--m-non-selectable-raised--before--BorderColor: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-card--m-compact__body--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-card--m-compact__footer--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-card--m-compact--first-child--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-card--m-compact--child--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-card--m-compact--child--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-card--m-compact--child--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-card--m-compact--c-divider--child--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-card--m-compact__title--not--last-child--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-card--m-display-lg__title-text--FontSize: var(--pf-v5-global--FontSize--xl);
  --pf-v5-c-card--m-display-lg--first-child--PaddingTop: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-card--m-display-lg--child--PaddingRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-card--m-display-lg--child--PaddingBottom: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-card--m-display-lg--child--PaddingLeft: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-card--m-display-lg--c-divider--child--PaddingTop: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-card--m-display-lg__title--not--last-child--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-card--m-flat--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-card--m-flat--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-card--m-rounded--BorderRadius: var(--pf-v5-global--BorderRadius--sm);
  --pf-v5-c-card--m-full-height--Height: 100%;
  --pf-v5-c-card--m-plain--BoxShadow: none;
  --pf-v5-c-card--m-plain--BackgroundColor: transparent;
  --pf-v5-c-card__header--m-toggle-right--toggle--MarginRight: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-card__header--m-toggle-right--toggle--MarginLeft: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-card__input--focus--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-card__input--focus--BorderColor: var(--pf-v5-global--primary-color--100);
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--pf-v5-c-card--BackgroundColor);
  box-shadow: var(--pf-v5-c-card--BoxShadow);
}

.pf-v5-c-card.pf-m-selectable .pf-v5-c-card__selectable-actions :is(.pf-v5-c-check,.pf-v5-c-radio) {
  gap: 0;
}

.pf-v5-c-card.pf-m-selectable,
.pf-v5-c-card.pf-m-clickable {
  isolation: isolate;
  box-shadow: none;
}

.pf-v5-c-card.pf-m-selectable.pf-m-clickable .pf-v5-c-card__selectable-actions .pf-v5-c-check__label,
.pf-v5-c-card.pf-m-selectable.pf-m-clickable .pf-v5-c-card__selectable-actions .pf-v5-c-radio__label {
  position: unset;
}

.pf-v5-c-card.pf-m-selectable.pf-m-clickable .pf-v5-c-card__selectable-actions .pf-v5-c-check__label::before,
.pf-v5-c-card.pf-m-selectable.pf-m-clickable .pf-v5-c-card__selectable-actions .pf-v5-c-radio__label::before {
  position: absolute;
  inset: 0;
  cursor: pointer;
}

.pf-v5-c-card.pf-m-selectable.pf-m-clickable .pf-v5-c-card__selectable-actions .pf-v5-c-check__input:where(:focus-visible)~.pf-v5-c-check__label,
.pf-v5-c-card.pf-m-selectable.pf-m-clickable .pf-v5-c-card__selectable-actions .pf-v5-c-radio__input:where(:focus-visible)~.pf-v5-c-radio__label {
  --pf-v5-c-card--BackgroundColor: unset;
  --pf-v5-c-card--BorderColor: unset;
}

.pf-v5-c-card.pf-m-selectable.pf-m-clickable .pf-v5-c-card__selectable-actions .pf-v5-c-check__input:where(:checked)~.pf-v5-c-check__label,
.pf-v5-c-card.pf-m-selectable.pf-m-clickable .pf-v5-c-card__selectable-actions .pf-v5-c-radio__input:where(:checked)~.pf-v5-c-radio__label,
.pf-v5-c-card.pf-m-selectable.pf-m-clickable.pf-m-selected {
  --pf-v5-c-card--BackgroundColor: var(--pf-v5-c-card--m-selectable--m-clickable--m-selected--BackgroundColor);
  --pf-v5-c-card--BorderColor: var(--pf-v5-c-card--m-selectable--m-clickable--m-selected--BorderColor);
}

.pf-v5-c-card.pf-m-selectable.pf-m-clickable.pf-m-current {
  --pf-v5-c-card--BackgroundColor: var(--pf-v5-c-card--m-selectable--m-clickable--m-current--BackgroundColor);
  --pf-v5-c-card--BorderColor: var(--pf-v5-c-card--m-selectable--m-clickable--m-current--BorderColor);
}

.pf-v5-c-card.pf-m-selectable.pf-m-clickable.pf-m-current.pf-m-selected,
.pf-v5-c-card.pf-m-selectable.pf-m-clickable.pf-m-current .pf-v5-c-card__selectable-actions .pf-v5-c-check__input:where(:checked)~.pf-v5-c-check__label,
.pf-v5-c-card.pf-m-selectable.pf-m-clickable.pf-m-current .pf-v5-c-card__selectable-actions .pf-v5-c-radio__input:where(:checked)~.pf-v5-c-radio__label {
  --pf-v5-c-card--BackgroundColor: var(--pf-v5-c-card--m-selectable--m-clickable--m-current--BackgroundColor);
  --pf-v5-c-card--BorderColor: var(--pf-v5-c-card--m-selectable--m-clickable--m-current--BorderColor);
}

.pf-v5-c-card.pf-m-selectable.pf-m-clickable .pf-v5-c-card__selectable-actions .pf-v5-c-check__input:where(:disabled)~.pf-v5-c-check__label,
.pf-v5-c-card.pf-m-selectable.pf-m-clickable .pf-v5-c-card__selectable-actions .pf-v5-c-radio__input:where(:disabled)~.pf-v5-c-radio__label,
.pf-v5-c-card.pf-m-selectable.pf-m-clickable.pf-m-disabled {
  --pf-v5-c-card--BackgroundColor: var(--pf-v5-c-card--m-selectable--m-disabled--BackgroundColor);
  --pf-v5-c-card--BorderColor: var(--pf-v5-c-card--m-selectable--m-disabled--BorderColor);
  --pf-v5-c-card--BoxShadow: var(--pf-v5-c-card--m-selectable--m-disabled--BoxShadow);
}

.pf-v5-c-card.pf-m-hoverable-raised::before,
.pf-v5-c-card.pf-m-selectable-raised::before,
.pf-v5-c-card.pf-m-non-selectable-raised::before {
  position: absolute;
  inset-block-end: var(--pf-v5-c-card--m-selectable-raised--before--Bottom);
  inset-inline-start: var(--pf-v5-c-card--m-selectable-raised--before--Left);
  inset-inline-end: var(--pf-v5-c-card--m-selectable-raised--before--Right);
  height: var(--pf-v5-c-card--m-selectable-raised--before--Height);
  content: "";
  background-color: var(--pf-v5-c-card--m-selectable-raised--before--BackgroundColor);
  transition: var(--pf-v5-c-card--m-selectable-raised--before--Transition);
  transform: translateY(var(--pf-v5-c-card--m-selectable-raised--before--TranslateY)) scaleY(var(--pf-v5-c-card--m-selectable-raised--before--ScaleY));
  transform-origin: center bottom;
}

.pf-v5-c-card.pf-m-hoverable-raised:hover {
  --pf-v5-c-card--BoxShadow: var(--pf-v5-c-card--m-hoverable-raised--hover--BoxShadow);
  --pf-v5-c-card--m-selectable-raised--before--BackgroundColor: var(--pf-v5-c-card--m-hoverable-raised--hover--before--BackgroundColor);
}

.pf-v5-c-card.pf-m-selectable-raised {
  cursor: pointer;
}

.pf-v5-c-card.pf-m-selectable-raised:hover {
  --pf-v5-c-card--BoxShadow: var(--pf-v5-c-card--m-selectable-raised--hover--BoxShadow);
  --pf-v5-c-card--m-selectable-raised--before--BackgroundColor: var(--pf-v5-c-card--m-selectable-raised--hover--before--BackgroundColor);
}

.pf-v5-c-card.pf-m-selectable-raised:focus {
  --pf-v5-c-card--BoxShadow: var(--pf-v5-c-card--m-selectable-raised--focus--BoxShadow);
  --pf-v5-c-card--m-selectable-raised--before--BackgroundColor: var(--pf-v5-c-card--m-selectable-raised--focus--before--BackgroundColor);
}

.pf-v5-c-card.pf-m-selectable-raised:active {
  --pf-v5-c-card--BoxShadow: var(--pf-v5-c-card--m-selectable-raised--active--BoxShadow);
  --pf-v5-c-card--m-selectable-raised--before--BackgroundColor: var(--pf-v5-c-card--m-selectable-raised--active--before--BackgroundColor);
}

.pf-v5-c-card.pf-m-selectable-raised.pf-m-selected-raised {
  --pf-v5-c-card--m-selectable-raised--before--BackgroundColor: var(--pf-v5-c-card--m-selectable-raised--m-selected-raised--before--BackgroundColor);
  --pf-v5-c-card--m-selectable-raised--before--Transition: var(--pf-v5-c-card--m-selectable-raised--m-selected-raised--before--Transition);
  --pf-v5-c-card--m-selectable-raised--before--TranslateY: var(--pf-v5-c-card--m-selectable-raised--m-selected-raised--before--TranslateY);
  --pf-v5-c-card--m-selectable-raised--before--ScaleY: var(--pf-v5-c-card--m-selectable-raised--m-selected-raised--before--ScaleY);
  z-index: var(--pf-v5-c-card--m-selectable-raised--m-selected-raised--ZIndex);
  box-shadow: var(--pf-v5-c-card--m-selectable-raised--m-selected-raised--BoxShadow);
  transition: var(--pf-v5-c-card--m-selectable-raised--m-selected-raised--Transition);
  transform: translateY(var(--pf-v5-c-card--m-selectable-raised--m-selected-raised--TranslateY));
}

.pf-v5-c-card.pf-m-non-selectable-raised {
  --pf-v5-c-card--BackgroundColor: var(--pf-v5-c-card--m-non-selectable-raised--BackgroundColor);
  --pf-v5-c-card--BoxShadow: none;
  --pf-v5-c-card--m-flat--BorderColor: var(--pf-v5-c-card--m-flat--m-non-selectable-raised--before--BorderColor);
  --pf-v5-c-card--m-selectable-raised--before--BackgroundColor: var(--pf-v5-c-card--m-non-selectable-raised--before--BackgroundColor);
  --pf-v5-c-card--m-selectable-raised--before--ScaleY: var(--pf-v5-c-card--m-non-selectable-raised--before--ScaleY);
}

.pf-v5-c-card.pf-m-compact {
  --pf-v5-c-card__body--FontSize: var(--pf-v5-c-card--m-compact__body--FontSize);
  --pf-v5-c-card__footer--FontSize: var(--pf-v5-c-card--m-compact__footer--FontSize);
  --pf-v5-c-card--first-child--PaddingTop: var(--pf-v5-c-card--m-compact--first-child--PaddingTop);
  --pf-v5-c-card--child--PaddingRight: var(--pf-v5-c-card--m-compact--child--PaddingRight);
  --pf-v5-c-card--child--PaddingBottom: var(--pf-v5-c-card--m-compact--child--PaddingBottom);
  --pf-v5-c-card--child--PaddingLeft: var(--pf-v5-c-card--m-compact--child--PaddingLeft);
  --pf-v5-c-card--c-divider--child--PaddingTop: var(--pf-v5-c-card--m-compact--c-divider--child--PaddingTop);
  --pf-v5-c-card__title--not--last-child--PaddingBottom: var(--pf-v5-c-card--m-compact__title--not--last-child--PaddingBottom);
}

.pf-v5-c-card.pf-m-display-lg {
  --pf-v5-c-card__title-text--FontSize: var(--pf-v5-c-card--m-display-lg__title-text--FontSize);
  --pf-v5-c-card--first-child--PaddingTop: var(--pf-v5-c-card--m-display-lg--first-child--PaddingTop);
  --pf-v5-c-card--child--PaddingRight: var(--pf-v5-c-card--m-display-lg--child--PaddingRight);
  --pf-v5-c-card--child--PaddingBottom: var(--pf-v5-c-card--m-display-lg--child--PaddingBottom);
  --pf-v5-c-card--child--PaddingLeft: var(--pf-v5-c-card--m-display-lg--child--PaddingLeft);
  --pf-v5-c-card--c-divider--child--PaddingTop: var(--pf-v5-c-card--m-display-lg--c-divider--child--PaddingTop);
  --pf-v5-c-card__title--not--last-child--PaddingBottom: var(--pf-v5-c-card--m-display-lg__title--not--last-child--PaddingBottom);
}

.pf-v5-c-card.pf-m-flat {
  --pf-v5-c-card--BoxShadow: none;
  --pf-v5-c-card--m-selectable-raised--before--Right: var(--pf-v5-c-card--m-flat--m-selectable-raised--before--Right);
  --pf-v5-c-card--m-selectable-raised--before--Bottom: var(--pf-v5-c-card--m-flat--m-selectable-raised--before--Bottom);
  --pf-v5-c-card--m-selectable-raised--before--Left: var(--pf-v5-c-card--m-flat--m-selectable-raised--before--Left);
  --pf-v5-c-card--m-selectable-raised--m-selected-raised--TranslateY: var(--pf-v5-c-card--m-flat--m-selectable-raised--m-selected-raised--TranslateY);
  border: var(--pf-v5-c-card--m-flat--BorderWidth) solid var(--pf-v5-c-card--m-flat--BorderColor);
}

.pf-v5-c-card.pf-m-plain {
  --pf-v5-c-card--BoxShadow: var(--pf-v5-c-card--m-plain--BoxShadow);
  --pf-v5-c-card--BackgroundColor: var(--pf-v5-c-card--m-plain--BackgroundColor);
}

.pf-v5-c-card.pf-m-rounded {
  --pf-v5-c-card--m-selectable-raised--m-selected-raised--TranslateY: var(--pf-v5-c-card--m-rounded--m-selectable-raised--m-selected-raised--TranslateY);
  border-radius: var(--pf-v5-c-card--m-rounded--BorderRadius);
}

.pf-v5-c-card.pf-m-expanded .pf-v5-c-card__header-toggle-icon {
  transform: rotate(var(--pf-v5-c-card--m-expanded__header-toggle-icon--Rotate));
}

.pf-v5-c-card.pf-m-full-height {
  height: var(--pf-v5-c-card--m-full-height--Height);
}

.pf-v5-c-card>.pf-v5-c-divider+.pf-v5-c-card__header,
.pf-v5-c-card>.pf-v5-c-divider+.pf-v5-c-card__title,
.pf-v5-c-card>.pf-v5-c-divider+.pf-v5-c-card__body,
.pf-v5-c-card>.pf-v5-c-divider+.pf-v5-c-card__footer {
  padding-block-start: var(--pf-v5-c-card--c-divider--child--PaddingTop);
}

.pf-v5-c-card__header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pf-v5-c-card__header .pf-v5-c-card__title {
  --pf-v5-c-card--first-child--PaddingTop: 0;
  --pf-v5-c-card__title--not--last-child--PaddingBottom: 0;
  padding: 0;
}

.pf-v5-c-card__header.pf-m-toggle-right {
  --pf-v5-c-card__header-toggle--MarginRight: var(--pf-v5-c-card__header--m-toggle-right--toggle--MarginRight);
  --pf-v5-c-card__header-toggle--MarginLeft: var(--pf-v5-c-card__header--m-toggle-right--toggle--MarginLeft);
}

.pf-v5-c-card__header.pf-m-toggle-right .pf-v5-c-card__header-toggle {
  order: 2;
}

.pf-v5-c-card__header-main {
  flex: 1;
}

.pf-v5-c-card__header-toggle {
  align-self: flex-start;
  margin-block-start: var(--pf-v5-c-card__header-toggle--MarginTop);
  margin-block-end: var(--pf-v5-c-card__header-toggle--MarginBottom);
  margin-inline-start: var(--pf-v5-c-card__header-toggle--MarginLeft);
  margin-inline-end: var(--pf-v5-c-card__header-toggle--MarginRight);
}

.pf-v5-c-card__header-toggle-icon {
  display: inline-block;
  transition: var(--pf-v5-c-card__header-toggle-icon--Transition);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-card__header-toggle-icon {
  scale: -1 1;
}

.pf-v5-c-card__title-text {
  font-family: var(--pf-v5-c-card__title-text--FontFamily);
  font-size: var(--pf-v5-c-card__title-text--FontSize);
  font-weight: var(--pf-v5-c-card__title-text--FontWeight);
  line-height: var(--pf-v5-c-card__title-text--LineHeight);
}

.pf-v5-c-card__actions {
  display: flex;
  gap: var(--pf-v5-c-card__actions--Gap);
  align-items: center;
  align-self: flex-start;
  order: 1;
  padding-inline-start: var(--pf-v5-c-card__actions--PaddingLeft);
  margin-block-start: var(--pf-v5-c-card__actions--MarginTop);
  margin-block-end: var(--pf-v5-c-card__actions--MarginBottom);
  margin-inline-start: auto;
}

.pf-v5-c-card__actions+.pf-v5-c-card__title,
.pf-v5-c-card__actions+.pf-v5-c-card__body,
.pf-v5-c-card__actions+.pf-v5-c-card__footer {
  padding: 0;
}

.pf-v5-c-card__actions.pf-m-no-offset {
  --pf-v5-c-card__actions--MarginTop: 0;
  --pf-v5-c-card__actions--MarginBottom: 0;
}

.pf-v5-c-card__selectable-actions .pf-v5-c-check__label,
.pf-v5-c-card__selectable-actions .pf-v5-c-radio__label {
  position: absolute;
  inset: 0;
  justify-self: auto;
  cursor: pointer;
}

.pf-v5-c-card__selectable-actions .pf-v5-c-check__label::before,
.pf-v5-c-card__selectable-actions .pf-v5-c-radio__label::before {
  position: absolute;
  inset: 0;
  z-index: -1;
  content: "";
  background-color: var(--pf-v5-c-card--BackgroundColor, transparent);
  border: var(--pf-v5-c-card--m-selectable--BorderWidth) solid var(--pf-v5-c-card--BorderColor, transparent);
  box-shadow: var(--pf-v5-c-card--BoxShadow, none);
}

.pf-v5-c-card__selectable-actions .pf-v5-c-check__label:hover,
.pf-v5-c-card__selectable-actions .pf-v5-c-radio__label:hover {
  --pf-v5-c-card--BackgroundColor: var(--pf-v5-c-card--m-selectable--hover--BackgroundColor);
  --pf-v5-c-card--BoxShadow: var(--pf-v5-c-card--m-selectable--hover--BoxShadow);
}

.pf-v5-c-card__selectable-actions .pf-v5-c-check__input:where(:checked)~.pf-v5-c-check__label,
.pf-v5-c-card__selectable-actions .pf-v5-c-radio__input:where(:checked)~.pf-v5-c-radio__label,
.pf-v5-c-card.pf-m-selected {
  --pf-v5-c-card--BorderColor: var(--pf-v5-c-card--m-selectable--m-selected--BorderColor);
  --pf-v5-c-card--BackgroundColor: var(--pf-v5-c-card--m-selectable--m-selected--BackgroundColor);
}

.pf-v5-c-card__selectable-actions .pf-v5-c-check__input:where(:focus-visible)~.pf-v5-c-check__label,
.pf-v5-c-card__selectable-actions .pf-v5-c-radio__input:where(:focus-visible)~.pf-v5-c-radio__label {
  --pf-v5-c-card--BorderColor: var(--pf-v5-c-card--m-selectable--focus--BorderColor);
  --pf-v5-c-card--BackgroundColor: var(--pf-v5-c-card--m-selectable--focus--BackgroundColor);
}

.pf-v5-c-card__selectable-actions .pf-v5-c-check__input:where(:focus-visible):where(:checked)~.pf-v5-c-check__label,
.pf-v5-c-card__selectable-actions .pf-v5-c-radio__input:where(:focus-visible):where(:checked)~.pf-v5-c-radio__label {
  --pf-v5-c-card--BorderColor: var(--pf-v5-c-card--m-selectable--m-selected--focus--BorderColor);
}

.pf-v5-c-card__selectable-actions .pf-v5-c-check__input:where(:disabled)~.pf-v5-c-check__label,
.pf-v5-c-card__selectable-actions .pf-v5-c-radio__input:where(:disabled)~.pf-v5-c-radio__label,
.pf-v5-c-card.pf-m-disabled {
  --pf-v5-c-card--BackgroundColor: var(--pf-v5-c-card--m-selectable--m-disabled--BackgroundColor);
  --pf-v5-c-card--BorderColor: var(--pf-v5-c-card--m-selectable--m-disabled--BorderColor);
  --pf-v5-c-card--BoxShadow: var(--pf-v5-c-card--m-selectable--m-disabled--BoxShadow);
}

.pf-v5-c-card__header,
.pf-v5-c-card__title,
.pf-v5-c-card__body,
.pf-v5-c-card__footer {
  padding-block-end: var(--pf-v5-c-card--child--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-card--child--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-card--child--PaddingRight);
}

.pf-v5-c-card__header:first-child,
.pf-v5-c-card__title:first-child,
.pf-v5-c-card__body:first-child,
.pf-v5-c-card__footer:first-child {
  padding-block-start: var(--pf-v5-c-card--first-child--PaddingTop);
}

.pf-v5-c-card__header:not(:last-child),
.pf-v5-c-card__title:not(:last-child) {
  padding-block-end: var(--pf-v5-c-card__title--not--last-child--PaddingBottom);
}

.pf-v5-c-card__expandable-content {
  --pf-v5-c-card--first-child--PaddingTop: 0;
}

.pf-v5-c-card__body:not(.pf-m-no-fill) {
  flex: 1 1 auto;
}

.pf-v5-c-card__body {
  font-size: var(--pf-v5-c-card__body--FontSize);
}

.pf-v5-c-card__footer {
  font-size: var(--pf-v5-c-card__footer--FontSize);
}

.pf-v5-c-card__sr-input:focus+.pf-v5-c-card::after {
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  content: "";
  border: var(--pf-v5-c-card__input--focus--BorderWidth) solid var(--pf-v5-c-card__input--focus--BorderColor);
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-card,
:where(.pf-v5-theme-dark) .pf-v5-c-card.pf-m-non-selectable-raised {
  --pf-v5-c-card--BoxShadow: var(--pf-v5-global--BoxShadow--md);
  --pf-v5-c-card--m-selectable--m-disabled--BackgroundColor: var(--pf-v5-global--palette--black-700);
  --pf-v5-c-card--m-selectable--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--150);
  --pf-v5-c-card--m-selectable--m-clickable--m-current--BackgroundColor: var(--pf-v5-global--BackgroundColor--150);
  --pf-v5-c-card--m-selectable--focus--BackgroundColor: var(--pf-v5-global--palette--blue-600);
  --pf-v5-c-card--m-hoverable-raised--hover--BoxShadow: var(--pf-v5-global--BoxShadow--lg);
  --pf-v5-c-card--m-selectable-raised--hover--BoxShadow: var(--pf-v5-global--BoxShadow--lg);
  --pf-v5-c-card--m-selectable-raised--focus--BoxShadow: var(--pf-v5-global--BoxShadow--lg);
  --pf-v5-c-card--m-selectable-raised--active--BoxShadow: var(--pf-v5-global--BoxShadow--lg);
}

.pf-v5-c-check {
  --pf-v5-c-check--GridGap: var(--pf-v5-global--spacer--xs) var(--pf-v5-global--spacer--sm);
  --pf-v5-c-check__label--disabled--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-check__label--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-check__label--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-check__label--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-check__label--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-check__input--TranslateY: calc((var(--pf-v5-c-check__label--LineHeight) * var(--pf-v5-c-check__label--FontSize) / 2 ) - 50%);
  --pf-v5-c-check__input--TranslateY--moz: var(--pf-v5-c-check__input--TranslateY);
  --pf-v5-c-check__description--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-check__description--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-check__body--MarginTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-check__label-required--MarginLeft: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-check__label-required--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-check__label-required--Color: var(--pf-v5-global--danger-color--100);
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: var(--pf-v5-c-check--GridGap);
  align-items: baseline;
}

.pf-v5-c-check.pf-m-standalone {
  --pf-v5-c-check--GridGap: 0;
  --pf-v5-c-check__input--TranslateY: none;
  display: inline-grid;
}

.pf-v5-c-check__input {
  align-self: start;
  -moz-transform: translateY(var(--pf-v5-c-check__input--TranslateY--moz));
  transform: translateY(var(--pf-v5-c-check__input--TranslateY));
}

.pf-v5-c-check__label {
  font-size: var(--pf-v5-c-check__label--FontSize);
  font-weight: var(--pf-v5-c-check__label--FontWeight);
  line-height: var(--pf-v5-c-check__label--LineHeight);
  color: var(--pf-v5-c-check__label--Color);
}

.pf-v5-c-check__description {
  grid-column: 2;
  font-size: var(--pf-v5-c-check__description--FontSize);
  color: var(--pf-v5-c-check__description--Color);
}

.pf-v5-c-check__body {
  grid-column: 2;
  margin-block-start: var(--pf-v5-c-check__body--MarginTop);
}

.pf-v5-c-check__label,
.pf-v5-c-check__input {
  justify-self: start;
}

label.pf-v5-c-check,
.pf-v5-c-check__label,
.pf-v5-c-check__input {
  cursor: pointer;
}

.pf-v5-c-check__label:disabled,
.pf-v5-c-check__label.pf-m-disabled,
.pf-v5-c-check__input:disabled,
.pf-v5-c-check__input.pf-m-disabled {
  --pf-v5-c-check__label--Color: var(--pf-v5-c-check__label--disabled--Color);
  cursor: not-allowed;
}

.pf-v5-c-check__label-required {
  margin-inline-start: var(--pf-v5-c-check__label-required--MarginLeft);
  font-size: var(--pf-v5-c-check__label-required--FontSize);
  color: var(--pf-v5-c-check__label-required--Color);
}

.pf-v5-c-chip {
  --pf-v5-c-chip--PaddingTop: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-chip--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-chip--PaddingBottom: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-chip--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-chip--BackgroundColor: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-chip--BorderRadius: var(--pf-v5-global--BorderRadius--sm);
  --pf-v5-c-chip--before--BorderColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-chip--before--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-chip--before--BorderRadius: var(--pf-v5-c-chip--BorderRadius);
  --pf-v5-c-chip--m-overflow__text--Color: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-chip--m-draggable--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-chip--m-draggable--BoxShadow: var(--pf-v5-global--BoxShadow--sm);
  --pf-v5-c-chip--m-draggable__icon--FontSize: var(--pf-v5-global--icon--FontSize--sm);
  --pf-v5-c-chip__content--FontSize: var(--pf-v5-global--FontSize--xs);
  --pf-v5-c-chip__content--ColumnGap: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-chip__text--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-chip__text--MaxWidth: 16ch;
  --pf-v5-c-chip__c-badge--MarginLeft: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-chip__actions--FontSize: var(--pf-v5-global--FontSize--xs);
  --pf-v5-c-chip__actions--c-button--PaddingTop: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-chip__actions--c-button--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-chip__actions--c-button--PaddingBottom: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-chip__actions--c-button--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-chip__actions--c-button--MarginTop: calc(var(--pf-v5-c-chip--PaddingTop) * -1);
  --pf-v5-c-chip__actions--c-button--MarginRight: calc(var(--pf-v5-c-chip--PaddingRight) / 2 * -1);
  --pf-v5-c-chip__actions--c-button--MarginBottom: calc(var(--pf-v5-c-chip--PaddingBottom) * -1);
  --pf-v5-c-chip__actions--c-button--FontSize: var(--pf-v5-global--FontSize--xs);
  --pf-v5-c-chip__icon--MarginLeft: var(--pf-v5-global--spacer--sm);
  color: var(--pf-v5-global--Color--100);
  position: relative;
  display: inline-flex;
  align-items: center;
  min-width: 0;
  padding-block-start: var(--pf-v5-c-chip--PaddingTop);
  padding-block-end: var(--pf-v5-c-chip--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-chip--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-chip--PaddingRight);
  list-style: none;
  background-color: var(--pf-v5-c-chip--BackgroundColor);
  border-radius: var(--pf-v5-c-chip--BorderRadius);
}

.pf-v5-c-chip::before {
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  content: "";
  border: var(--pf-v5-c-chip--before--BorderWidth) solid var(--pf-v5-c-chip--before--BorderColor);
  border-radius: var(--pf-v5-c-chip--before--BorderRadius);
}

.pf-v5-c-chip.pf-m-overflow {
  border: 0;
}

.pf-v5-c-chip.pf-m-overflow .pf-v5-c-chip__text {
  color: var(--pf-v5-c-chip--m-overflow__text--Color);
}

.pf-v5-c-chip.pf-m-draggable {
  --pf-v5-c-chip--BackgroundColor: var(--pf-v5-c-chip--m-draggable--BackgroundColor);
  box-shadow: var(--pf-v5-c-chip--m-draggable--BoxShadow);
}

.pf-v5-c-chip.pf-m-draggable .pf-v5-c-chip__icon {
  font-size: var(--pf-v5-c-chip--m-draggable__icon--FontSize);
}

.pf-v5-c-chip__text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  max-width: var(--pf-v5-c-chip__text--MaxWidth);
  color: var(--pf-v5-c-chip__text--Color);
}

.pf-v5-c-chip__text .pf-v5-c-badge {
  margin-inline-start: var(--pf-v5-c-chip__c-badge--MarginLeft);
}

.pf-v5-c-chip__icon+.pf-v5-c-chip__text,
.pf-v5-c-chip__text+.pf-v5-c-chip__icon {
  margin-inline-start: var(--pf-v5-c-chip__icon--MarginLeft);
}

.pf-v5-c-chip__content {
  display: flex;
  column-gap: var(--pf-v5-c-chip__content--ColumnGap);
  align-items: center;
  font-size: var(--pf-v5-c-chip__content--FontSize);
}

.pf-v5-c-chip__actions {
  font-size: var(--pf-v5-c-chip__actions--FontSize);
}

.pf-v5-c-chip__actions .pf-v5-c-button {
  --pf-v5-c-button--PaddingTop: var(--pf-v5-c-chip__actions--c-button--PaddingTop);
  --pf-v5-c-button--PaddingRight: var(--pf-v5-c-chip__actions--c-button--PaddingRight);
  --pf-v5-c-button--PaddingBottom: var(--pf-v5-c-chip__actions--c-button--PaddingBottom);
  --pf-v5-c-button--PaddingLeft: var(--pf-v5-c-chip__actions--c-button--PaddingLeft);
  --pf-v5-c-button--FontSize: var(--pf-v5-c-chip__actions--c-button--FontSize);
  margin-block-start: var(--pf-v5-c-chip__actions--c-button--MarginTop);
  margin-block-end: var(--pf-v5-c-chip__actions--c-button--MarginBottom);
  margin-inline-end: var(--pf-v5-c-chip__actions--c-button--MarginRight);
  line-height: 1;
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-chip {
  --pf-v5-c-chip--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-chip--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-chip--m-draggable--BackgroundColor: var(--pf-v5-global--palette--black-600);
}

.pf-v5-c-chip-group {
  color: var(--pf-v5-global--Color--100);
  --pf-v5-c-chip-group--PaddingTop: 0;
  --pf-v5-c-chip-group--PaddingRight: 0;
  --pf-v5-c-chip-group--PaddingBottom: 0;
  --pf-v5-c-chip-group--PaddingLeft: 0;
  --pf-v5-c-chip-group--RowGap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-chip-group--ColumnGap: 0;
  --pf-v5-c-chip-group__main--RowGap: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-chip-group__main--ColumnGap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-chip-group__list--RowGap: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-chip-group__list--ColumnGap: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-chip-group--m-category--PaddingTop: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-chip-group--m-category--PaddingRight: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-chip-group--m-category--PaddingBottom: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-chip-group--m-category--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-chip-group--m-category--BorderRadius: var(--pf-v5-global--BorderRadius--sm);
  --pf-v5-c-chip-group--m-category--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-chip-group__label--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-chip-group__label--MaxWidth: 18ch;
  --pf-v5-c-chip-group__close--MarginTop: calc(var(--pf-v5-global--spacer--xs) * -1);
  --pf-v5-c-chip-group__close--MarginBottom: calc(var(--pf-v5-global--spacer--xs) * -1);
  row-gap: var(--pf-v5-c-chip-group--RowGap);
  column-gap: var(--pf-v5-c-chip-group--ColumnGap);
  max-width: 100%;
  padding-block-start: var(--pf-v5-c-chip-group--PaddingTop);
  padding-block-end: var(--pf-v5-c-chip-group--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-chip-group--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-chip-group--PaddingRight);
}

.pf-v5-c-chip-group.pf-m-category {
  --pf-v5-c-chip-group--PaddingTop: var(--pf-v5-c-chip-group--m-category--PaddingTop);
  --pf-v5-c-chip-group--PaddingRight: var(--pf-v5-c-chip-group--m-category--PaddingRight);
  --pf-v5-c-chip-group--PaddingBottom: var(--pf-v5-c-chip-group--m-category--PaddingBottom);
  --pf-v5-c-chip-group--PaddingLeft: var(--pf-v5-c-chip-group--m-category--PaddingLeft);
  background-color: var(--pf-v5-c-chip-group--m-category--BackgroundColor);
  border-radius: var(--pf-v5-c-chip-group--m-category--BorderRadius);
}

.pf-v5-c-chip-group__main {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  row-gap: var(--pf-v5-c-chip-group__main--RowGap);
  column-gap: var(--pf-v5-c-chip-group__main--ColumnGap);
  align-items: baseline;
  min-width: 0;
}

.pf-v5-c-chip-group,
.pf-v5-c-chip-group__list {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  min-width: 0;
}

.pf-v5-c-chip-group__list {
  row-gap: var(--pf-v5-c-chip-group__list--RowGap);
  column-gap: var(--pf-v5-c-chip-group__list--ColumnGap);
}

.pf-v5-c-chip-group__list-item {
  display: inline-flex;
  min-width: 0;
}

.pf-v5-c-chip-group__label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: var(--pf-v5-c-chip-group__label--MaxWidth);
  font-size: var(--pf-v5-c-chip-group__label--FontSize);
}

.pf-v5-c-chip-group__close {
  display: flex;
  align-self: flex-start;
  margin-block-start: var(--pf-v5-c-chip-group__close--MarginTop);
  margin-block-end: var(--pf-v5-c-chip-group__close--MarginBottom);
}

.pf-v5-c-clipboard-copy {
  --pf-v5-c-clipboard-copy__toggle-icon--Transition: .2s ease-in 0s;
  --pf-v5-c-clipboard-copy--m-expanded__toggle-icon--Rotate: 90deg;
  --pf-v5-c-clipboard-copy__expandable-content--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-clipboard-copy__expandable-content--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-clipboard-copy__expandable-content--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-clipboard-copy__expandable-content--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-clipboard-copy__expandable-content--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-c-clipboard-copy__expandable-content--BorderTopWidth: 0;
  --pf-v5-c-clipboard-copy__expandable-content--BorderRightWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-clipboard-copy__expandable-content--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-clipboard-copy__expandable-content--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-clipboard-copy__expandable-content--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-clipboard-copy__expandable-content--OutlineOffset: calc(-1 * var(--pf-v5-global--spacer--xs));
  --pf-v5-c-clipboard-copy--m-inline--PaddingTop: 0;
  --pf-v5-c-clipboard-copy--m-inline--PaddingBottom: 0;
  --pf-v5-c-clipboard-copy--m-inline--PaddingLeft: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-clipboard-copy--m-inline--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-clipboard-copy__text--m-code--FontFamily: var(--pf-v5-global--FontFamily--monospace);
  --pf-v5-c-clipboard-copy__text--m-code--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-clipboard-copy__actions-item--MarginTop: calc(-1 * var(--pf-v5-global--spacer--form-element));
  --pf-v5-c-clipboard-copy__actions-item--MarginBottom: calc(-1 * var(--pf-v5-global--spacer--form-element));
  --pf-v5-c-clipboard-copy__actions-item--button--PaddingTop: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-clipboard-copy__actions-item--button--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-clipboard-copy__actions-item--button--PaddingBottom: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-clipboard-copy__actions-item--button--PaddingLeft: var(--pf-v5-global--spacer--sm);
}

.pf-v5-c-clipboard-copy.pf-m-expanded .pf-v5-c-clipboard-copy__toggle-icon {
  transform: rotate(var(--pf-v5-c-clipboard-copy--m-expanded__toggle-icon--Rotate));
}

.pf-v5-c-clipboard-copy.pf-m-inline {
  display: inline;
  padding-block-start: var(--pf-v5-c-clipboard-copy--m-inline--PaddingTop);
  padding-block-end: var(--pf-v5-c-clipboard-copy--m-inline--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-clipboard-copy--m-inline--PaddingLeft);
  white-space: nowrap;
  background-color: var(--pf-v5-c-clipboard-copy--m-inline--BackgroundColor);
}

.pf-v5-c-clipboard-copy.pf-m-inline.pf-m-block {
  display: block;
}

.pf-v5-c-clipboard-copy__group {
  display: flex;
}

.pf-v5-c-clipboard-copy__group>*+* {
  margin-inline-start: -1px;
}

.pf-v5-c-clipboard-copy__toggle-icon {
  transition: var(--pf-v5-c-clipboard-copy__toggle-icon--Transition);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-clipboard-copy__toggle-icon {
  scale: -1 1;
}

.pf-v5-c-clipboard-copy__expandable-content {
  padding-block-start: var(--pf-v5-c-clipboard-copy__expandable-content--PaddingTop);
  padding-block-end: var(--pf-v5-c-clipboard-copy__expandable-content--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-clipboard-copy__expandable-content--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-clipboard-copy__expandable-content--PaddingRight);
  word-wrap: break-word;
  background-color: var(--pf-v5-c-clipboard-copy__expandable-content--BackgroundColor);
  background-clip: padding-box;
  border: solid var(--pf-v5-c-clipboard-copy__expandable-content--BorderColor);
  border-block-start-width: var(--pf-v5-c-clipboard-copy__expandable-content--BorderTopWidth);
  border-block-end-width: var(--pf-v5-c-clipboard-copy__expandable-content--BorderBottomWidth);
  border-inline-start-width: var(--pf-v5-c-clipboard-copy__expandable-content--BorderLeftWidth);
  border-inline-end-width: var(--pf-v5-c-clipboard-copy__expandable-content--BorderRightWidth);
  box-shadow: var(--pf-v5-c-clipboard-copy__expandable-content--BoxShadow);
}

.pf-v5-c-clipboard-copy__expandable-content pre {
  white-space: pre-wrap;
}

.pf-v5-c-clipboard-copy__text {
  word-break: break-word;
  white-space: normal;
}

.pf-v5-c-clipboard-copy__text.pf-m-code {
  font-family: var(--pf-v5-c-clipboard-copy__text--m-code--FontFamily);
  font-size: var(--pf-v5-c-clipboard-copy__text--m-code--FontSize);
}

.pf-v5-c-clipboard-copy__actions {
  display: inline-flex;
}

.pf-v5-c-clipboard-copy__actions-item {
  margin-block-start: calc(-1*var(--pf-v5-c-clipboard-copy__actions-item--button--PaddingTop));
  margin-block-end: calc(-1*var(--pf-v5-c-clipboard-copy__actions-item--button--PaddingBottom));
}

.pf-v5-c-clipboard-copy__actions-item .pf-v5-c-button {
  --pf-v5-c-button--PaddingTop: var(--pf-v5-c-clipboard-copy__actions-item--button--PaddingTop);
  --pf-v5-c-button--PaddingRight: var(--pf-v5-c-clipboard-copy__actions-item--button--PaddingRight);
  --pf-v5-c-button--PaddingBottom: var(--pf-v5-c-clipboard-copy__actions-item--button--PaddingBottom);
  --pf-v5-c-button--PaddingLeft: var(--pf-v5-c-clipboard-copy__actions-item--button--PaddingLeft);
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-clipboard-copy {
  --pf-v5-c-clipboard-copy__expandable-content--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
}

:where(.pf-v5-theme-dark) .pf-v5-c-clipboard-copy__group>*+* {
  margin-inline-start: 0;
  border-inline-start: 1px solid var(--pf-v5-global--palette--black-700) !important;
}

:where(.pf-v5-theme-dark) .pf-v5-c-clipboard-copy__expandable-content {
  border: 0;
}

.pf-v5-c-code-block {
  --pf-v5-c-code-block--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-code-block__header--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-code-block__header--BorderBottomColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-code-block__content--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-code-block__content--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-code-block__content--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-code-block__content--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-code-block__pre--FontFamily: var(--pf-v5-global--FontFamily--monospace);
  --pf-v5-c-code-block__pre--FontSize: var(--pf-v5-global--FontSize--sm);
  background-color: var(--pf-v5-c-code-block--BackgroundColor);
}

.pf-v5-c-code-block__header {
  display: flex;
  border-block-end: var(--pf-v5-c-code-block__header--BorderBottomWidth) solid var(--pf-v5-c-code-block__header--BorderBottomColor);
}

.pf-v5-c-code-block__actions {
  display: flex;
  margin-inline-start: auto;
}

.pf-v5-c-code-block__content {
  padding-block-start: var(--pf-v5-c-code-block__content--PaddingTop);
  padding-block-end: var(--pf-v5-c-code-block__content--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-code-block__content--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-code-block__content--PaddingRight);
}

.pf-v5-c-code-block__pre {
  font-family: var(--pf-v5-c-code-block__pre--FontFamily);
  font-size: var(--pf-v5-c-code-block__pre--FontSize);
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.pf-v5-c-code-block__code {
  font-family: var(--pf-v5-c-code-block__code--FontFamily, inherit);
}

.pf-v5-c-code-editor {
  --pf-v5-c-code-editor__controls--c-button--m-control--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-code-editor__controls--c-button--m-control--hover--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-code-editor__controls--c-button--m-control--focus--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-code-editor__controls--c-button--m-control--disabled--after--BorderBottomColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-code-editor__header--before--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-code-editor__header--before--BorderBottomColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-code-editor__main--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-code-editor__main--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-code-editor__main--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-code-editor--m-read-only__main--BackgroundColor: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-code-editor__main--m-drag-hover--before--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-code-editor__main--m-drag-hover--before--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-code-editor__main--m-drag-hover--after--BackgroundColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-code-editor__main--m-drag-hover--after--Opacity: .1;
  --pf-v5-c-code-editor__code--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-code-editor__code--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-code-editor__code--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-code-editor__code--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-code-editor__code-pre--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-code-editor__code-pre--FontFamily: var(--pf-v5-global--FontFamily--monospace);
  --pf-v5-c-code-editor__header-main--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-code-editor__header-main--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-code-editor__tab--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-code-editor__tab--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-code-editor__tab--PaddingTop: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-code-editor__tab--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-code-editor__tab--PaddingBottom: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-code-editor__tab--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-code-editor__tab--BorderTopWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-code-editor__tab--BorderRightWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-code-editor__tab--BorderBottomWidth: 0;
  --pf-v5-c-code-editor__tab--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-code-editor__tab--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-code-editor__tab-icon--text--MarginLeft: var(--pf-v5-global--spacer--sm);
}

.pf-v5-c-code-editor.pf-m-read-only {
  --pf-v5-c-code-editor__main--BackgroundColor: var(--pf-v5-c-code-editor--m-read-only__main--BackgroundColor);
}

.pf-v5-c-code-editor__header {
  position: relative;
  display: flex;
  align-items: flex-end;
}

.pf-v5-c-code-editor__header::before {
  position: absolute;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  pointer-events: none;
  content: "";
  border-block-end: var(--pf-v5-c-code-editor__header--before--BorderBottomWidth) solid var(--pf-v5-c-code-editor__header--before--BorderBottomColor);
}

.pf-v5-c-code-editor__controls {
  display: flex;
}

.pf-v5-c-code-editor__controls .pf-v5-c-button.pf-m-control {
  --pf-v5-c-button--m-control--Color: var(--pf-v5-c-code-editor__controls--c-button--m-control--Color);
}

.pf-v5-c-code-editor__controls .pf-v5-c-button.pf-m-control:hover {
  --pf-v5-c-code-editor__controls--c-button--m-control--Color: var(--pf-v5-c-code-editor__controls--c-button--m-control--hover--Color);
}

.pf-v5-c-code-editor__controls .pf-v5-c-button.pf-m-control:focus {
  --pf-v5-c-code-editor__controls--c-button--m-control--Color: var(--pf-v5-c-code-editor__controls--c-button--m-control--focus--Color);
}

.pf-v5-c-code-editor__controls .pf-v5-c-button.pf-m-control:disabled::after {
  border-block-end-color: var(--pf-v5-c-code-editor__controls--c-button--m-control--disabled--after--BorderBottomColor);
}

.pf-v5-c-code-editor__header-main {
  flex-grow: 1;
  padding-inline-start: var(--pf-v5-c-code-editor__header-main--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-code-editor__header-main--PaddingRight);
}

.pf-v5-c-code-editor__main {
  position: relative;
  color: var(--pf-v5-c-code-editor__main--Color, inherit);
  background-color: var(--pf-v5-c-code-editor__main--BackgroundColor);
  border: var(--pf-v5-c-code-editor__main--BorderWidth) solid;
  border-color: var(--pf-v5-c-code-editor__main--BorderColor);
}

.pf-v5-c-code-editor__main.pf-m-drag-hover::after {
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  content: "";
  background-color: var(--pf-v5-c-code-editor__main--m-drag-hover--after--BackgroundColor);
  opacity: var(--pf-v5-c-code-editor__main--m-drag-hover--after--Opacity);
}

.pf-v5-c-code-editor__main.pf-m-drag-hover::before {
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  content: "";
  border: var(--pf-v5-c-code-editor__main--m-drag-hover--before--BorderWidth) solid var(--pf-v5-c-code-editor__main--m-drag-hover--before--BorderColor);
}

.pf-v5-c-code-editor__main .monaco-editor {
  background-color: var(--pf-v5-c-code-editor__main--BackgroundColor);
}

.pf-v5-c-code-editor__header+.pf-v5-c-code-editor__main {
  border-block-start-width: 0;
}

.pf-v5-c-code-editor__code {
  position: relative;
  padding-block-start: var(--pf-v5-c-code-editor__code--PaddingTop);
  padding-block-end: var(--pf-v5-c-code-editor__code--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-code-editor__code--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-code-editor__code--PaddingRight);
}

.pf-v5-c-code-editor__code .pf-v5-c-code-editor__code-pre {
  font-family: var(--pf-v5-c-code-editor__code-pre--FontFamily);
  font-size: var(--pf-v5-c-code-editor__code-pre--FontSize);
  white-space: pre-wrap;
}

.pf-v5-c-code-editor__tab {
  position: relative;
  display: flex;
  align-items: center;
  padding-block-start: var(--pf-v5-c-code-editor__tab--PaddingTop);
  padding-block-end: var(--pf-v5-c-code-editor__tab--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-code-editor__tab--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-code-editor__tab--PaddingRight);
  margin-inline-start: auto;
  color: var(--pf-v5-c-code-editor__tab--Color);
  background-color: var(--pf-v5-c-code-editor__tab--BackgroundColor);
  border-color: var(--pf-v5-c-code-editor__tab--BorderColor);
  border-style: solid;
  border-block-start-width: var(--pf-v5-c-code-editor__tab--BorderTopWidth);
  border-block-end-width: var(--pf-v5-c-code-editor__tab--BorderBottomWidth);
  border-inline-start-width: var(--pf-v5-c-code-editor__tab--BorderLeftWidth);
  border-inline-end-width: var(--pf-v5-c-code-editor__tab--BorderRightWidth);
}

.pf-v5-c-code-editor__tab-icon+.pf-v5-c-code-editor__tab-text {
  margin-inline-start: var(--pf-v5-c-code-editor__tab-icon--text--MarginLeft);
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-code-editor {
  --pf-v5-c-code-editor--m-read-only__main--Color: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-code-editor--m-read-only__main--BackgroundColor: var(--pf-v5-global--disabled-color--200);
}

:where(.pf-v5-theme-dark) .pf-v5-c-code-editor__controls>*+* {
  border-inline-start: 1px solid var(--pf-v5-global--palette--black-700);
}

.pf-v5-c-content {
  --pf-v5-c-content--MarginBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-content--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-content--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-content--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-content--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-content--heading--FontFamily: var(--pf-v5-global--FontFamily--heading);
  --pf-v5-c-content--h1--MarginTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-content--h1--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-content--h1--LineHeight: var(--pf-v5-global--LineHeight--sm);
  --pf-v5-c-content--h1--FontSize: var(--pf-v5-global--FontSize--2xl);
  --pf-v5-c-content--h1--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-content--h2--MarginTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-content--h2--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-content--h2--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-content--h2--FontSize: var(--pf-v5-global--FontSize--xl);
  --pf-v5-c-content--h2--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-content--h3--MarginTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-content--h3--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-content--h3--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-content--h3--FontSize: var(--pf-v5-global--FontSize--lg);
  --pf-v5-c-content--h3--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-content--h4--MarginTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-content--h4--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-content--h4--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-content--h4--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-content--h4--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-content--h5--MarginTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-content--h5--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-content--h5--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-content--h5--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-content--h5--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-content--h6--MarginTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-content--h6--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-content--h6--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-content--h6--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-content--h6--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-content--small--MarginBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-content--small--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-content--small--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-content--small--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-content--a--Color: var(--pf-v5-global--link--Color);
  --pf-v5-c-content--a--TextDecoration: var(--pf-v5-global--link--TextDecoration);
  --pf-v5-c-content--a--hover--Color: var(--pf-v5-global--link--Color--hover);
  --pf-v5-c-content--a--hover--TextDecoration: var(--pf-v5-global--link--TextDecoration--hover);
  --pf-v5-c-content--a--visited--Color: var(--pf-v5-global--link--Color--visited);
  --pf-v5-c-content--blockquote--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-content--blockquote--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-content--blockquote--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-content--blockquote--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-content--blockquote--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-content--blockquote--BorderLeftColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-content--blockquote--BorderLeftWidth: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-content--ol--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-content--ol--MarginLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-content--ol--nested--MarginTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-content--ol--nested--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-content--ul--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-content--ul--MarginLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-content--ul--nested--MarginTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-content--ul--nested--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-content--ul--ListStyle: var(--pf-v5-global--ListStyle);
  --pf-v5-c-content--li--MarginTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-content--dl--ColumnGap: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-content--dl--RowGap: var(--pf-v5-global--spacer--md);
  --pf-v5-c-content--dt--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-content--dt--MarginTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-content--dt--sm--MarginTop: 0;
  --pf-v5-c-content--hr--Height: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-content--hr--BackgroundColor: var(--pf-v5-global--BorderColor--100);
  font-size: var(--pf-v5-c-content--FontSize);
  line-height: var(--pf-v5-c-content--LineHeight);
  color: var(--pf-v5-c-content--Color);
}

.pf-v5-c-content a {
  color: var(--pf-v5-c-content--a--Color);
  text-decoration: var(--pf-v5-c-content--a--TextDecoration);
}

.pf-v5-c-content a:hover {
  --pf-v5-c-content--a--Color: var(--pf-v5-c-content--a--hover--Color);
  --pf-v5-c-content--a--TextDecoration: var(--pf-v5-c-content--a--hover--TextDecoration);
}

.pf-v5-c-content.pf-m-visited a:visited,
.pf-v5-c-content a.pf-m-visited:visited {
  color: var(--pf-v5-c-content--a--visited--Color);
}

.pf-v5-c-content li+li {
  margin-block-start: var(--pf-v5-c-content--li--MarginTop);
}

.pf-v5-c-content p:not(:last-child),
.pf-v5-c-content dl:not(:last-child),
.pf-v5-c-content ol:not(:last-child),
.pf-v5-c-content ul:not(:last-child),
.pf-v5-c-content blockquote:not(:last-child),
.pf-v5-c-content small:not(:last-child),
.pf-v5-c-content pre:not(:last-child),
.pf-v5-c-content table:not(:last-child),
.pf-v5-c-content hr:not(:last-child) {
  margin-block-end: var(--pf-v5-c-content--MarginBottom);
}

.pf-v5-c-content h1,
.pf-v5-c-content h2,
.pf-v5-c-content h3,
.pf-v5-c-content h4,
.pf-v5-c-content h5,
.pf-v5-c-content h6 {
  margin: 0;
  font-family: var(--pf-v5-c-content--heading--FontFamily);
}

.pf-v5-c-content h1:first-child,
.pf-v5-c-content h2:first-child,
.pf-v5-c-content h3:first-child,
.pf-v5-c-content h4:first-child,
.pf-v5-c-content h5:first-child,
.pf-v5-c-content h6:first-child {
  margin-block-start: 0;
}

.pf-v5-c-content h1:last-child,
.pf-v5-c-content h2:last-child,
.pf-v5-c-content h3:last-child,
.pf-v5-c-content h4:last-child,
.pf-v5-c-content h5:last-child,
.pf-v5-c-content h6:last-child {
  margin-block-end: 0;
}

.pf-v5-c-content ol,
.pf-v5-c-content ul {
  margin: 0;
}

.pf-v5-c-content ol.pf-m-plain,
.pf-v5-c-content ul.pf-m-plain {
  padding-inline-start: 0;
  margin-inline-start: 0;
  list-style: none;
}

.pf-v5-c-content h1 {
  margin-block-start: var(--pf-v5-c-content--h1--MarginTop);
  margin-block-end: var(--pf-v5-c-content--h1--MarginBottom);
  font-size: var(--pf-v5-c-content--h1--FontSize);
  font-weight: var(--pf-v5-c-content--h1--FontWeight);
  line-height: var(--pf-v5-c-content--h1--LineHeight);
}

.pf-v5-c-content h2 {
  margin-block-start: var(--pf-v5-c-content--h2--MarginTop);
  margin-block-end: var(--pf-v5-c-content--h2--MarginBottom);
  font-size: var(--pf-v5-c-content--h2--FontSize);
  font-weight: var(--pf-v5-c-content--h2--FontWeight);
  line-height: var(--pf-v5-c-content--h2--LineHeight);
}

.pf-v5-c-content h3 {
  margin-block-start: var(--pf-v5-c-content--h3--MarginTop);
  margin-block-end: var(--pf-v5-c-content--h3--MarginBottom);
  font-size: var(--pf-v5-c-content--h3--FontSize);
  font-weight: var(--pf-v5-c-content--h3--FontWeight);
  line-height: var(--pf-v5-c-content--h3--LineHeight);
}

.pf-v5-c-content h4 {
  margin-block-start: var(--pf-v5-c-content--h4--MarginTop);
  margin-block-end: var(--pf-v5-c-content--h4--MarginBottom);
  font-size: var(--pf-v5-c-content--h4--FontSize);
  font-weight: var(--pf-v5-c-content--h4--FontWeight);
  line-height: var(--pf-v5-c-content--h4--LineHeight);
}

.pf-v5-c-content h5 {
  margin-block-start: var(--pf-v5-c-content--h5--MarginTop);
  margin-block-end: var(--pf-v5-c-content--h5--MarginBottom);
  font-size: var(--pf-v5-c-content--h5--FontSize);
  font-weight: var(--pf-v5-c-content--h5--FontWeight);
  line-height: var(--pf-v5-c-content--h5--LineHeight);
}

.pf-v5-c-content h6 {
  margin-block-start: var(--pf-v5-c-content--h6--MarginTop);
  margin-block-end: var(--pf-v5-c-content--h6--MarginBottom);
  font-size: var(--pf-v5-c-content--h6--FontSize);
  font-weight: var(--pf-v5-c-content--h6--FontWeight);
  line-height: var(--pf-v5-c-content--h6--LineHeight);
}

.pf-v5-c-content small {
  display: block;
  font-size: var(--pf-v5-c-content--small--FontSize);
  line-height: var(--pf-v5-c-content--small--LineHeight);
  color: var(--pf-v5-c-content--small--Color);
}

.pf-v5-c-content small:not(:last-child) {
  margin-block-end: var(--pf-v5-c-content--small--MarginBottom);
}

.pf-v5-c-content blockquote {
  padding-block-start: var(--pf-v5-c-content--blockquote--PaddingTop);
  padding-block-end: var(--pf-v5-c-content--blockquote--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-content--blockquote--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-content--blockquote--PaddingRight);
  color: var(--pf-v5-c-content--blockquote--Color);
  border-inline-start: var(--pf-v5-c-content--blockquote--BorderLeftWidth) solid var(--pf-v5-c-content--blockquote--BorderLeftColor);
}

.pf-v5-c-content hr {
  height: var(--pf-v5-c-content--hr--Height);
  background-color: var(--pf-v5-c-content--hr--BackgroundColor);
  border: none;
}

.pf-v5-c-content ol {
  padding-inline-start: var(--pf-v5-c-content--ol--PaddingLeft);
  margin-inline-start: var(--pf-v5-c-content--ol--MarginLeft);
}

.pf-v5-c-content ol ul {
  --pf-v5-c-content--ul--MarginLeft: var(--pf-v5-c-content--ul--nested--MarginLeft);
  margin-block-start: var(--pf-v5-c-content--ul--nested--MarginTop);
}

.pf-v5-c-content ol ol {
  --pf-v5-c-content--ol--MarginLeft: var(--pf-v5-c-content--ol--nested--MarginLeft);
  margin-block-start: var(--pf-v5-c-content--ol--nested--MarginTop);
}

.pf-v5-c-content ul {
  padding-inline-start: var(--pf-v5-c-content--ul--PaddingLeft);
  margin-inline-start: var(--pf-v5-c-content--ul--MarginLeft);
  list-style: var(--pf-v5-c-content--ul--ListStyle);
}

.pf-v5-c-content ul ul {
  --pf-v5-c-content--ul--MarginLeft: var(--pf-v5-c-content--ul--nested--MarginLeft);
  margin-block-start: var(--pf-v5-c-content--ul--nested--MarginTop);
}

.pf-v5-c-content ul ol {
  --pf-v5-c-content--ol--MarginLeft: var(--pf-v5-c-content--ol--nested--MarginLeft);
  margin-block-start: var(--pf-v5-c-content--ol--nested--MarginTop);
}

.pf-v5-c-content dl {
  display: grid;
  grid-template-columns: 1fr;
}

@media screen and (min-width: 576px) {
  .pf-v5-c-content dl {
    grid-template: auto/auto 1fr;
    grid-row-gap: var(--pf-v5-c-content--dl--RowGap);
    grid-column-gap: var(--pf-v5-c-content--dl--ColumnGap);
  }
}

.pf-v5-c-content dt {
  font-weight: var(--pf-v5-c-content--dt--FontWeight);
}

.pf-v5-c-content dt:not(:first-child) {
  margin-block-start: var(--pf-v5-c-content--dt--MarginTop);
}

@media screen and (min-width: 576px) {
  .pf-v5-c-content dt:not(:first-child) {
    --pf-v5-c-content--dt--MarginTop: var(--pf-v5-c-content--dt--sm--MarginTop);
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-c-content dt {
    grid-column: 1;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-c-content dd {
    grid-column: 2;
  }
}

.pf-v5-c-context-selector {
  --pf-v5-c-context-selector--Width: 15.625rem;
  --pf-v5-c-context-selector__toggle--PaddingTop: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-context-selector__toggle--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-context-selector__toggle--PaddingBottom: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-context-selector__toggle--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-context-selector__toggle--ColumnGap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-context-selector__toggle--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-context-selector__toggle--BorderTopColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-context-selector__toggle--BorderRightColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-context-selector__toggle--BorderBottomColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-context-selector__toggle--BorderLeftColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-context-selector__toggle--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-context-selector__toggle--hover--BorderBottomWidth: var(--pf-v5-c-context-selector__toggle--BorderWidth);
  --pf-v5-c-context-selector__toggle--hover--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-context-selector__toggle--active--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-context-selector__toggle--active--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-context-selector__toggle--expanded--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-context-selector__toggle--expanded--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-context-selector__toggle--BackgroundColor: transparent;
  --pf-v5-c-context-selector__toggle--m-plain--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-context-selector__toggle--m-plain--hover--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-context-selector__toggle--m-plain--disabled--Color: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-context-selector__toggle--m-plain--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-context-selector__toggle--m-plain--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-context-selector__toggle--m-plain--m-text--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-context-selector__toggle--m-plain--m-text--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-context-selector__toggle-text--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-context-selector__toggle-text--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-context-selector__toggle-text--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-context-selector__toggle-icon--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-context-selector__toggle-icon--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-context-selector--m-plain__toggle-icon--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-context-selector--m-plain--hover__toggle-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-context-selector__menu--Top: calc(100% + var(--pf-v5-global--spacer--xs));
  --pf-v5-c-context-selector__menu--ZIndex: var(--pf-v5-global--ZIndex--sm);
  --pf-v5-c-context-selector__menu--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-context-selector__menu--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-c-context-selector__menu--BoxShadow: var(--pf-v5-global--BoxShadow--md);
  --pf-v5-c-context-selector__menu-search--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-context-selector__menu-search--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-context-selector__menu-search--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-context-selector__menu-search--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-context-selector__menu-search--BorderBottomColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-context-selector__menu-search--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-context-selector__menu-footer--BoxShadow: var(--pf-v5-global--BoxShadow--sm-top);
  --pf-v5-c-context-selector__menu-footer--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-context-selector__menu-footer--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-context-selector__menu-footer--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-context-selector__menu-footer--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-context-selector__menu-list--MaxHeight: 12.5rem;
  --pf-v5-c-context-selector__menu-list--PaddingTop: var(--pf-v5-c-context-selector__menu--PaddingTop);
  --pf-v5-c-context-selector__menu-list--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-context-selector__menu-list-item--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-context-selector__menu-list-item--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-context-selector__menu-list-item--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-context-selector__menu-list-item--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-context-selector__menu-list-item--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-context-selector__menu-list-item--BackgroundColor: transparent;
  --pf-v5-c-context-selector__menu-list-item--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-300);
  --pf-v5-c-context-selector__menu-list-item--disabled--Color: var(--pf-v5-global--Color--dark-200);
  --pf-v5-c-context-selector__menu-item-icon--Color: var(--pf-v5-global--active-color--100);
  --pf-v5-c-context-selector__menu-item-icon--FontSize: var(--pf-v5-global--icon--FontSize--sm);
  --pf-v5-c-context-selector__menu-item-icon--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-context-selector--m-full-height__toggle--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-context-selector--m-full-height__toggle--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-context-selector--m-full-height__toggle--before--BorderTopWidth: 0;
  --pf-v5-c-context-selector--m-full-height__toggle--hover--BorderBottomWidth: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-context-selector--m-full-height__toggle--active--BorderBottomWidth: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-context-selector--m-full-height__toggle--expanded--BorderBottomWidth: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-context-selector--m-large__toggle--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-context-selector--m-large__toggle--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-context-selector--m-large__toggle--hover--BorderBottomWidth: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-context-selector--m-large__toggle--active--BorderBottomWidth: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-context-selector--m-large__toggle--expanded--BorderBottomWidth: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-context-selector--m-page-insets__toggle--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-context-selector--m-page-insets__toggle--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-context-selector--m-page-insets__toggle--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-context-selector--m-page-insets__toggle--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-context-selector--m-page-insets__menu-list-item--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-context-selector--m-page-insets__menu-list-item--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-context-selector--m-page-insets__menu-list-item--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-context-selector--m-page-insets__menu-list-item--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-context-selector--m-page-insets__menu-search--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-context-selector--m-page-insets__menu-search--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-context-selector--m-page-insets__menu-search--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-context-selector--m-page-insets__menu-search--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-context-selector--m-page-insets__menu-footer--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-context-selector--m-page-insets__menu-footer--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-context-selector--m-page-insets__menu-footer--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-context-selector--m-page-insets__menu-footer--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-context-selector--m-page-insets__toggle--m-plain--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-context-selector--m-page-insets__toggle--m-plain--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-context-selector--m-page-insets__toggle--m-plain--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-context-selector--m-page-insets__toggle--m-plain--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-context-selector--m-page-insets__toggle--m-plain--m-text--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-context-selector--m-page-insets__toggle--m-plain--m-text--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-context-selector--m-page-insets__toggle--m-plain--m-text--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-context-selector--m-page-insets__toggle--m-plain--m-text--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  position: relative;
  display: inline-block;
  width: var(--pf-v5-c-context-selector--Width);
  max-width: 100%;
}

@media screen and (min-width: 1200px) {
  .pf-v5-c-context-selector {
    --pf-v5-c-context-selector--m-page-insets__toggle--PaddingRight: var(--pf-v5-c-context-selector--m-page-insets__toggle--xl--PaddingRight);
    --pf-v5-c-context-selector--m-page-insets__toggle--PaddingLeft: var(--pf-v5-c-context-selector--m-page-insets__toggle--xl--PaddingLeft);
    --pf-v5-c-context-selector--m-page-insets__menu-list-item--PaddingRight: var(--pf-v5-c-context-selector--m-page-insets__menu-list-item--xl--PaddingRight);
    --pf-v5-c-context-selector--m-page-insets__menu-list-item--PaddingLeft: var(--pf-v5-c-context-selector--m-page-insets__menu-list-item--xl--PaddingLeft);
    --pf-v5-c-context-selector--m-page-insets__menu-search--PaddingRight: var(--pf-v5-c-context-selector--m-page-insets__menu-search--xl--PaddingRight);
    --pf-v5-c-context-selector--m-page-insets__menu-search--PaddingLeft: var(--pf-v5-c-context-selector--m-page-insets__menu-search--xl--PaddingLeft);
    --pf-v5-c-context-selector--m-page-insets__menu-footer--PaddingRight: var(--pf-v5-c-context-selector--m-page-insets__menu-footer--xl--PaddingRight);
    --pf-v5-c-context-selector--m-page-insets__menu-footer--PaddingLeft: var(--pf-v5-c-context-selector--m-page-insets__menu-footer--xl--PaddingLeft);
    --pf-v5-c-context-selector--m-page-insets__toggle--m-plain--PaddingRight: var(--pf-v5-c-context-selector--m-page-insets__toggle--m-plain--xl--PaddingRight);
    --pf-v5-c-context-selector--m-page-insets__toggle--m-plain--PaddingLeft: var(--pf-v5-c-context-selector--m-page-insets__toggle--m-plain--xl--PaddingLeft);
    --pf-v5-c-context-selector--m-page-insets__toggle--m-plain--m-text--PaddingRight: var(--pf-v5-c-context-selector--m-page-insets__toggle--m-plain--m-text--xl--PaddingRight);
    --pf-v5-c-context-selector--m-page-insets__toggle--m-plain--m-text--PaddingLeft: var(--pf-v5-c-context-selector--m-page-insets__toggle--m-plain--m-text--xl--PaddingLeft);
  }
}

.pf-v5-c-context-selector.pf-m-full-height {
  --pf-v5-c-context-selector__toggle--active--BorderBottomWidth: var(--pf-v5-c-context-selector--m-full-height__toggle--active--BorderBottomWidth);
  --pf-v5-c-context-selector__toggle--expanded--BorderBottomWidth: var(--pf-v5-c-context-selector--m-full-height__toggle--expanded--BorderBottomWidth);
  --pf-v5-c-context-selector__toggle--PaddingRight: var(--pf-v5-c-context-selector--m-full-height__toggle--PaddingRight);
  --pf-v5-c-context-selector__toggle--PaddingLeft: var(--pf-v5-c-context-selector--m-full-height__toggle--PaddingLeft);
  display: inline-flex;
  align-items: center;
  height: 100%;
}

.pf-v5-c-context-selector.pf-m-full-height .pf-v5-c-context-selector__toggle {
  align-self: stretch;
}

.pf-v5-c-context-selector.pf-m-full-height .pf-v5-c-context-selector__toggle::before {
  border-block-start-width: var(--pf-v5-c-context-selector--m-full-height__toggle--before--BorderTopWidth);
}

.pf-v5-c-context-selector.pf-m-full-height:hover .pf-v5-c-context-selector__toggle::before {
  border-block-end-width: var(--pf-v5-c-context-selector--m-full-height__toggle--hover--BorderBottomWidth);
}

.pf-v5-c-context-selector.pf-m-large {
  --pf-v5-c-context-selector__toggle--PaddingTop: var(--pf-v5-c-context-selector--m-large__toggle--PaddingTop);
  --pf-v5-c-context-selector__toggle--PaddingBottom: var(--pf-v5-c-context-selector--m-large__toggle--PaddingBottom);
  --pf-v5-c-context-selector__toggle--hover--BorderBottomWidth: var(--pf-v5-c-context-selector--m-large__toggle--hover--BorderBottomWidth);
  --pf-v5-c-context-selector__toggle--active--BorderBottomWidth: var(--pf-v5-c-context-selector--m-large__toggle--active--BorderBottomWidth);
  --pf-v5-c-context-selector__toggle--expanded--BorderBottomWidth: var(--pf-v5-c-context-selector--m-large__toggle--expanded--BorderBottomWidth);
}

.pf-v5-c-context-selector.pf-m-page-insets {
  --pf-v5-c-context-selector__toggle--PaddingRight: var(--pf-v5-c-context-selector--m-page-insets__toggle--PaddingRight);
  --pf-v5-c-context-selector__toggle--PaddingLeft: var(--pf-v5-c-context-selector--m-page-insets__toggle--PaddingLeft);
  --pf-v5-c-context-selector__menu-list-item--PaddingRight: var(--pf-v5-c-context-selector--m-page-insets__menu-list-item--PaddingRight);
  --pf-v5-c-context-selector__menu-list-item--PaddingLeft: var(--pf-v5-c-context-selector--m-page-insets__menu-list-item--PaddingLeft);
  --pf-v5-c-context-selector__menu-search--PaddingRight: var(--pf-v5-c-context-selector--m-page-insets__menu-search--PaddingRight);
  --pf-v5-c-context-selector__menu-search--PaddingLeft: var(--pf-v5-c-context-selector--m-page-insets__menu-search--PaddingLeft);
  --pf-v5-c-context-selector__menu-footer--PaddingRight: var(--pf-v5-c-context-selector--m-page-insets__menu-footer--PaddingRight);
  --pf-v5-c-context-selector__menu-footer--PaddingLeft: var(--pf-v5-c-context-selector--m-page-insets__menu-footer--PaddingLeft);
  --pf-v5-c-context-selector__toggle--m-plain--PaddingRight: var(--pf-v5-c-context-selector--m-page-insets__toggle--m-plain--PaddingRight);
  --pf-v5-c-context-selector__toggle--m-plain--PaddingLeft: var(--pf-v5-c-context-selector--m-page-insets__toggle--m-plain--PaddingLeft);
  --pf-v5-c-context-selector__toggle--m-plain--m-text--PaddingRight: var(--pf-v5-c-context-selector--m-page-insets__toggle--m-plain--m-text--PaddingRight);
  --pf-v5-c-context-selector__toggle--m-plain--m-text--PaddingLeft: var(--pf-v5-c-context-selector--m-page-insets__toggle--m-plain--m-text--PaddingLeft);
}

.pf-v5-c-context-selector__toggle {
  position: relative;
  display: flex;
  column-gap: var(--pf-v5-c-context-selector__toggle--ColumnGap);
  align-items: center;
  width: 100%;
  padding-block-start: var(--pf-v5-c-context-selector__toggle--PaddingTop);
  padding-block-end: var(--pf-v5-c-context-selector__toggle--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-context-selector__toggle--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-context-selector__toggle--PaddingRight);
  color: var(--pf-v5-c-context-selector__toggle--Color);
  white-space: nowrap;
  cursor: pointer;
  background-color: var(--pf-v5-c-context-selector__toggle--BackgroundColor);
  border: none;
}

.pf-v5-c-context-selector__toggle::before {
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  content: "";
  border: var(--pf-v5-c-context-selector__toggle--BorderWidth) solid;
  border-block-start-color: var(--pf-v5-c-context-selector__toggle--BorderTopColor);
  border-block-end-color: var(--pf-v5-c-context-selector__toggle--BorderBottomColor);
  border-inline-start-color: var(--pf-v5-c-context-selector__toggle--BorderLeftColor);
  border-inline-end-color: var(--pf-v5-c-context-selector__toggle--BorderRightColor);
}

.pf-v5-c-context-selector__toggle:hover::before {
  --pf-v5-c-context-selector__toggle--BorderBottomColor: var(--pf-v5-c-context-selector__toggle--hover--BorderBottomColor);
  border-block-end-width: var(--pf-v5-c-context-selector__toggle--hover--BorderBottomWidth);
}

.pf-v5-c-context-selector__toggle:active::before,
.pf-v5-c-context-selector__toggle.pf-m-active::before,
.pf-v5-c-context-selector__toggle:focus-within::before {
  --pf-v5-c-context-selector__toggle--BorderBottomColor: var(--pf-v5-c-context-selector__toggle--active--BorderBottomColor);
  border-block-end-width: var(--pf-v5-c-context-selector__toggle--active--BorderBottomWidth);
}

.pf-m-expanded>.pf-v5-c-context-selector__toggle::before {
  --pf-v5-c-context-selector__toggle--BorderBottomColor: var(--pf-v5-c-context-selector__toggle--expanded--BorderBottomColor);
  border-block-end-width: var(--pf-v5-c-context-selector__toggle--expanded--BorderBottomWidth);
}

.pf-v5-c-context-selector__toggle.pf-m-plain {
  --pf-v5-c-context-selector__toggle--PaddingRight: var(--pf-v5-c-context-selector__toggle--m-plain--PaddingRight);
  --pf-v5-c-context-selector__toggle--PaddingLeft: var(--pf-v5-c-context-selector__toggle--m-plain--PaddingLeft);
  --pf-v5-c-context-selector__toggle-icon--Color: var(--pf-v5-c-context-selector--m-plain__toggle-icon--Color);
}

.pf-v5-c-context-selector__toggle.pf-m-plain.pf-m-text {
  --pf-v5-c-context-selector__toggle--PaddingRight: var(--pf-v5-c-context-selector__toggle--m-plain--m-text--PaddingRight);
  --pf-v5-c-context-selector__toggle--PaddingLeft: var(--pf-v5-c-context-selector__toggle--m-plain--m-text--PaddingLeft);
}

.pf-v5-c-context-selector__toggle.pf-m-plain:not(.pf-m-text) {
  display: inline-block;
  width: auto;
  color: var(--pf-v5-c-context-selector__toggle--m-plain--Color);
}

.pf-v5-c-context-selector__toggle.pf-m-plain.pf-m-disabled,
.pf-v5-c-context-selector__toggle.pf-m-plain:disabled {
  --pf-v5-c-context-selector__toggle--m-plain--Color: var(--pf-v5-c-context-selector__toggle--m-plain--disabled--Color);
}

.pf-v5-c-context-selector__toggle.pf-m-plain:hover,
.pf-v5-c-context-selector__toggle.pf-m-plain:active,
.pf-v5-c-context-selector__toggle.pf-m-plain.pf-m-active,
.pf-v5-c-context-selector__toggle.pf-m-plain:focus,
.pf-v5-c-context-selector.pf-m-expanded>.pf-v5-c-context-selector__toggle.pf-m-plain {
  --pf-v5-c-context-selector__toggle--m-plain--Color: var(--pf-v5-c-context-selector__toggle--m-plain--hover--Color);
  --pf-v5-c-context-selector--m-plain__toggle-icon--Color: var(--pf-v5-c-context-selector--m-plain--hover__toggle-icon--Color);
}

.pf-v5-c-context-selector__toggle.pf-m-plain::before {
  border: 0;
}

.pf-v5-c-context-selector__toggle .pf-v5-c-context-selector__toggle-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1 1 auto;
  font-size: var(--pf-v5-c-context-selector__toggle-text--FontSize);
  font-weight: var(--pf-v5-c-context-selector__toggle-text--FontWeight);
  line-height: var(--pf-v5-c-context-selector__toggle-text--LineHeight);
  text-align: start;
}

.pf-v5-c-context-selector__toggle-icon {
  padding-inline-start: var(--pf-v5-c-context-selector__toggle-icon--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-context-selector__toggle-icon--PaddingRight);
  color: var(--pf-v5-c-context-selector__toggle-icon--Color, inherit);
}

.pf-v5-c-context-selector__menu {
  color: var(--pf-v5-global--Color--100);
  position: absolute;
  inset-block-start: var(--pf-v5-c-context-selector__menu--Top);
  z-index: var(--pf-v5-c-context-selector__menu--ZIndex);
  min-width: 100%;
  background-color: var(--pf-v5-c-context-selector__menu--BackgroundColor);
  background-clip: padding-box;
  box-shadow: var(--pf-v5-c-context-selector__menu--BoxShadow);
}

.pf-v5-c-context-selector__menu.pf-m-static {
  position: static;
  inset-block-start: auto;
  inset-block-end: auto;
  inset-inline-start: auto;
  inset-inline-end: auto;
  z-index: auto;
  min-width: min-content;
}

.pf-v5-c-context-selector__menu-search {
  position: relative;
  padding-block-start: var(--pf-v5-c-context-selector__menu-search--PaddingTop);
  padding-block-end: var(--pf-v5-c-context-selector__menu-search--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-context-selector__menu-search--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-context-selector__menu-search--PaddingRight);
  border-block-end: var(--pf-v5-c-context-selector__menu-search--BorderBottomWidth) solid var(--pf-v5-c-context-selector__menu-search--BorderBottomColor);
}

.pf-v5-c-context-selector__menu-footer {
  padding-block-start: var(--pf-v5-c-context-selector__menu-footer--PaddingTop);
  padding-block-end: var(--pf-v5-c-context-selector__menu-footer--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-context-selector__menu-footer--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-context-selector__menu-footer--PaddingRight);
  box-shadow: var(--pf-v5-c-context-selector__menu-footer--BoxShadow);
}

.pf-v5-c-context-selector__menu-list {
  max-height: var(--pf-v5-c-context-selector__menu-list--MaxHeight);
  padding-block-start: var(--pf-v5-c-context-selector__menu-list--PaddingTop);
  padding-block-end: var(--pf-v5-c-context-selector__menu-list--PaddingBottom);
  overflow-y: auto;
}

.pf-v5-c-context-selector__menu-list-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding-block-start: var(--pf-v5-c-context-selector__menu-list-item--PaddingTop);
  padding-block-end: var(--pf-v5-c-context-selector__menu-list-item--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-context-selector__menu-list-item--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-context-selector__menu-list-item--PaddingRight);
  color: var(--pf-v5-c-context-selector__menu-list-item--Color);
  white-space: nowrap;
  background-color: var(--pf-v5-c-context-selector__menu-list-item--BackgroundColor);
  border: none;
}

.pf-v5-c-context-selector__menu-list-item:hover,
.pf-v5-c-context-selector__menu-list-item:focus {
  --pf-v5-c-context-selector__menu-list-item--BackgroundColor: var(--pf-v5-c-context-selector__menu-list-item--hover--BackgroundColor);
  text-decoration: none;
}

.pf-v5-c-context-selector__menu-list-item.pf-m-disabled,
.pf-v5-c-context-selector__menu-list-item:disabled {
  --pf-v5-c-context-selector__menu-list-item--Color: var(--pf-v5-c-context-selector__menu-list-item--disabled--Color);
  pointer-events: none;
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-context-selector {
  --pf-v5-c-context-selector__menu--Top: 100%;
  --pf-v5-c-context-selector__menu-list-item--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
}

:where(.pf-v5-theme-dark) .pf-v5-c-context-selector__toggle {
  background: var(--pf-v5-global--BackgroundColor--400);
}

:where(.pf-v5-theme-dark) .pf-v5-c-context-selector__toggle.pf-m-plain {
  background: transparent;
}

:where(.pf-v5-theme-dark) .pf-v5-c-context-selector__menu {
  background: var(--pf-v5-global--BackgroundColor--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-context-selector__menu-footer {
  border-block-start: 1px solid var(--pf-v5-global--BorderColor--300);
}

@media screen and (min-width: 768px) {
  .pf-v5-c-data-list:not([class*=pf-m-grid]) {
    --pf-v5-c-data-list__cell--cell--PaddingTop: var(--pf-v5-c-data-list__cell--cell--md--PaddingTop);
    --pf-v5-c-data-list__cell--PaddingBottom: var(--pf-v5-c-data-list__cell--md--PaddingBottom);
    --pf-v5-c-data-list__item-control--MarginRight: var(--pf-v5-c-data-list__item-control--md--MarginRight);
    --pf-v5-c-data-list__item-action--MarginLeft: var(--pf-v5-c-data-list__item-action--md--MarginLeft);
    --pf-v5-c-data-list__expandable-content-body--PaddingTop: var(--pf-v5-c-data-list__expandable-content-body--md--PaddingTop);
    --pf-v5-c-data-list__expandable-content-body--PaddingBottom: var(--pf-v5-c-data-list__expandable-content-body--md--PaddingBottom);
    --pf-v5-c-data-list--m-compact__cell--PaddingBottom: var(--pf-v5-c-data-list--m-compact__cell--md--PaddingBottom);
    --pf-v5-c-data-list--m-compact__cell-cell--PaddingTop: var(--pf-v5-c-data-list--m-compact__cell-cell--md--PaddingTop);
  }
}

@media screen and (min-width: 768px)and (min-width: 1200px) {
  .pf-v5-c-data-list:not([class*=pf-m-grid]) {
    --pf-v5-c-data-list__item-row--PaddingRight: var(--pf-v5-c-data-list__item-row--xl--PaddingRight);
    --pf-v5-c-data-list__item-row--PaddingLeft: var(--pf-v5-c-data-list__item-row--xl--PaddingLeft);
    --pf-v5-c-data-list__expandable-content-body--PaddingRight: var(--pf-v5-c-data-list__expandable-content-body--xl--PaddingRight);
    --pf-v5-c-data-list__expandable-content-body--PaddingLeft: var(--pf-v5-c-data-list__expandable-content-body--xl--PaddingLeft);
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-c-data-list:not([class*=pf-m-grid]) .pf-v5-c-data-list__item-content {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    padding-block-end: var(--pf-v5-c-data-list__item-content--md--PaddingBottom);
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-c-data-list:not([class*=pf-m-grid]) .pf-v5-c-data-list__cell:not(:last-child):not(.pf-m-icon) {
    margin-inline-end: var(--pf-v5-c-data-list__cell--MarginRight);
  }

  .pf-v5-c-data-list:not([class*=pf-m-grid]) .pf-v5-c-data-list__cell+.pf-v5-c-data-list__cell {
    flex: 1;
    order: initial;
  }

  .pf-v5-c-data-list:not([class*=pf-m-grid]) .pf-v5-c-data-list__cell.pf-m-align-right {
    margin-inline-start: auto;
  }

  .pf-v5-c-data-list:not([class*=pf-m-grid]) .pf-v5-c-data-list__cell.pf-m-no-fill {
    flex: none;
  }

  .pf-v5-c-data-list:not([class*=pf-m-grid]) .pf-v5-c-data-list__cell.pf-m-flex-2 {
    flex-grow: 2;
  }

  .pf-v5-c-data-list:not([class*=pf-m-grid]) .pf-v5-c-data-list__cell.pf-m-flex-3 {
    flex-grow: 3;
  }

  .pf-v5-c-data-list:not([class*=pf-m-grid]) .pf-v5-c-data-list__cell.pf-m-flex-4 {
    flex-grow: 4;
  }

  .pf-v5-c-data-list:not([class*=pf-m-grid]) .pf-v5-c-data-list__cell.pf-m-flex-5 {
    flex-grow: 5;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-c-data-list:not([class*=pf-m-grid]) .pf-v5-c-data-list__expandable-content {
    max-height: initial;
    overflow-y: visible;
  }
}

@media screen and (min-width: 0) {
  .pf-v5-c-data-list.pf-m-grid-none {
    --pf-v5-c-data-list__cell--cell--PaddingTop: var(--pf-v5-c-data-list__cell--cell--md--PaddingTop);
    --pf-v5-c-data-list__cell--PaddingBottom: var(--pf-v5-c-data-list__cell--md--PaddingBottom);
    --pf-v5-c-data-list__item-control--MarginRight: var(--pf-v5-c-data-list__item-control--md--MarginRight);
    --pf-v5-c-data-list__item-action--MarginLeft: var(--pf-v5-c-data-list__item-action--md--MarginLeft);
    --pf-v5-c-data-list__expandable-content-body--PaddingTop: var(--pf-v5-c-data-list__expandable-content-body--md--PaddingTop);
    --pf-v5-c-data-list__expandable-content-body--PaddingBottom: var(--pf-v5-c-data-list__expandable-content-body--md--PaddingBottom);
    --pf-v5-c-data-list--m-compact__cell--PaddingBottom: var(--pf-v5-c-data-list--m-compact__cell--md--PaddingBottom);
    --pf-v5-c-data-list--m-compact__cell-cell--PaddingTop: var(--pf-v5-c-data-list--m-compact__cell-cell--md--PaddingTop);
  }
}

@media screen and (min-width: 0)and (min-width: 1200px) {
  .pf-v5-c-data-list.pf-m-grid-none {
    --pf-v5-c-data-list__item-row--PaddingRight: var(--pf-v5-c-data-list__item-row--xl--PaddingRight);
    --pf-v5-c-data-list__item-row--PaddingLeft: var(--pf-v5-c-data-list__item-row--xl--PaddingLeft);
    --pf-v5-c-data-list__expandable-content-body--PaddingRight: var(--pf-v5-c-data-list__expandable-content-body--xl--PaddingRight);
    --pf-v5-c-data-list__expandable-content-body--PaddingLeft: var(--pf-v5-c-data-list__expandable-content-body--xl--PaddingLeft);
  }
}

@media screen and (min-width: 0) {
  .pf-v5-c-data-list.pf-m-grid-none .pf-v5-c-data-list__item-content {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    padding-block-end: var(--pf-v5-c-data-list__item-content--md--PaddingBottom);
  }
}

@media screen and (min-width: 0) {
  .pf-v5-c-data-list.pf-m-grid-none .pf-v5-c-data-list__cell:not(:last-child):not(.pf-m-icon) {
    margin-inline-end: var(--pf-v5-c-data-list__cell--MarginRight);
  }

  .pf-v5-c-data-list.pf-m-grid-none .pf-v5-c-data-list__cell+.pf-v5-c-data-list__cell {
    flex: 1;
    order: initial;
  }

  .pf-v5-c-data-list.pf-m-grid-none .pf-v5-c-data-list__cell.pf-m-align-right {
    margin-inline-start: auto;
  }

  .pf-v5-c-data-list.pf-m-grid-none .pf-v5-c-data-list__cell.pf-m-no-fill {
    flex: none;
  }

  .pf-v5-c-data-list.pf-m-grid-none .pf-v5-c-data-list__cell.pf-m-flex-2 {
    flex-grow: 2;
  }

  .pf-v5-c-data-list.pf-m-grid-none .pf-v5-c-data-list__cell.pf-m-flex-3 {
    flex-grow: 3;
  }

  .pf-v5-c-data-list.pf-m-grid-none .pf-v5-c-data-list__cell.pf-m-flex-4 {
    flex-grow: 4;
  }

  .pf-v5-c-data-list.pf-m-grid-none .pf-v5-c-data-list__cell.pf-m-flex-5 {
    flex-grow: 5;
  }
}

@media screen and (min-width: 0) {
  .pf-v5-c-data-list.pf-m-grid-none .pf-v5-c-data-list__expandable-content {
    max-height: initial;
    overflow-y: visible;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-c-data-list.pf-m-grid-sm {
    --pf-v5-c-data-list__cell--cell--PaddingTop: var(--pf-v5-c-data-list__cell--cell--md--PaddingTop);
    --pf-v5-c-data-list__cell--PaddingBottom: var(--pf-v5-c-data-list__cell--md--PaddingBottom);
    --pf-v5-c-data-list__item-control--MarginRight: var(--pf-v5-c-data-list__item-control--md--MarginRight);
    --pf-v5-c-data-list__item-action--MarginLeft: var(--pf-v5-c-data-list__item-action--md--MarginLeft);
    --pf-v5-c-data-list__expandable-content-body--PaddingTop: var(--pf-v5-c-data-list__expandable-content-body--md--PaddingTop);
    --pf-v5-c-data-list__expandable-content-body--PaddingBottom: var(--pf-v5-c-data-list__expandable-content-body--md--PaddingBottom);
    --pf-v5-c-data-list--m-compact__cell--PaddingBottom: var(--pf-v5-c-data-list--m-compact__cell--md--PaddingBottom);
    --pf-v5-c-data-list--m-compact__cell-cell--PaddingTop: var(--pf-v5-c-data-list--m-compact__cell-cell--md--PaddingTop);
  }
}

@media screen and (min-width: 576px)and (min-width: 1200px) {
  .pf-v5-c-data-list.pf-m-grid-sm {
    --pf-v5-c-data-list__item-row--PaddingRight: var(--pf-v5-c-data-list__item-row--xl--PaddingRight);
    --pf-v5-c-data-list__item-row--PaddingLeft: var(--pf-v5-c-data-list__item-row--xl--PaddingLeft);
    --pf-v5-c-data-list__expandable-content-body--PaddingRight: var(--pf-v5-c-data-list__expandable-content-body--xl--PaddingRight);
    --pf-v5-c-data-list__expandable-content-body--PaddingLeft: var(--pf-v5-c-data-list__expandable-content-body--xl--PaddingLeft);
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-c-data-list.pf-m-grid-sm .pf-v5-c-data-list__item-content {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    padding-block-end: var(--pf-v5-c-data-list__item-content--md--PaddingBottom);
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-c-data-list.pf-m-grid-sm .pf-v5-c-data-list__cell:not(:last-child):not(.pf-m-icon) {
    margin-inline-end: var(--pf-v5-c-data-list__cell--MarginRight);
  }

  .pf-v5-c-data-list.pf-m-grid-sm .pf-v5-c-data-list__cell+.pf-v5-c-data-list__cell {
    flex: 1;
    order: initial;
  }

  .pf-v5-c-data-list.pf-m-grid-sm .pf-v5-c-data-list__cell.pf-m-align-right {
    margin-inline-start: auto;
  }

  .pf-v5-c-data-list.pf-m-grid-sm .pf-v5-c-data-list__cell.pf-m-no-fill {
    flex: none;
  }

  .pf-v5-c-data-list.pf-m-grid-sm .pf-v5-c-data-list__cell.pf-m-flex-2 {
    flex-grow: 2;
  }

  .pf-v5-c-data-list.pf-m-grid-sm .pf-v5-c-data-list__cell.pf-m-flex-3 {
    flex-grow: 3;
  }

  .pf-v5-c-data-list.pf-m-grid-sm .pf-v5-c-data-list__cell.pf-m-flex-4 {
    flex-grow: 4;
  }

  .pf-v5-c-data-list.pf-m-grid-sm .pf-v5-c-data-list__cell.pf-m-flex-5 {
    flex-grow: 5;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-c-data-list.pf-m-grid-sm .pf-v5-c-data-list__expandable-content {
    max-height: initial;
    overflow-y: visible;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-c-data-list.pf-m-grid-md {
    --pf-v5-c-data-list__cell--cell--PaddingTop: var(--pf-v5-c-data-list__cell--cell--md--PaddingTop);
    --pf-v5-c-data-list__cell--PaddingBottom: var(--pf-v5-c-data-list__cell--md--PaddingBottom);
    --pf-v5-c-data-list__item-control--MarginRight: var(--pf-v5-c-data-list__item-control--md--MarginRight);
    --pf-v5-c-data-list__item-action--MarginLeft: var(--pf-v5-c-data-list__item-action--md--MarginLeft);
    --pf-v5-c-data-list__expandable-content-body--PaddingTop: var(--pf-v5-c-data-list__expandable-content-body--md--PaddingTop);
    --pf-v5-c-data-list__expandable-content-body--PaddingBottom: var(--pf-v5-c-data-list__expandable-content-body--md--PaddingBottom);
    --pf-v5-c-data-list--m-compact__cell--PaddingBottom: var(--pf-v5-c-data-list--m-compact__cell--md--PaddingBottom);
    --pf-v5-c-data-list--m-compact__cell-cell--PaddingTop: var(--pf-v5-c-data-list--m-compact__cell-cell--md--PaddingTop);
  }
}

@media screen and (min-width: 768px)and (min-width: 1200px) {
  .pf-v5-c-data-list.pf-m-grid-md {
    --pf-v5-c-data-list__item-row--PaddingRight: var(--pf-v5-c-data-list__item-row--xl--PaddingRight);
    --pf-v5-c-data-list__item-row--PaddingLeft: var(--pf-v5-c-data-list__item-row--xl--PaddingLeft);
    --pf-v5-c-data-list__expandable-content-body--PaddingRight: var(--pf-v5-c-data-list__expandable-content-body--xl--PaddingRight);
    --pf-v5-c-data-list__expandable-content-body--PaddingLeft: var(--pf-v5-c-data-list__expandable-content-body--xl--PaddingLeft);
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-c-data-list.pf-m-grid-md .pf-v5-c-data-list__item-content {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    padding-block-end: var(--pf-v5-c-data-list__item-content--md--PaddingBottom);
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-c-data-list.pf-m-grid-md .pf-v5-c-data-list__cell:not(:last-child):not(.pf-m-icon) {
    margin-inline-end: var(--pf-v5-c-data-list__cell--MarginRight);
  }

  .pf-v5-c-data-list.pf-m-grid-md .pf-v5-c-data-list__cell+.pf-v5-c-data-list__cell {
    flex: 1;
    order: initial;
  }

  .pf-v5-c-data-list.pf-m-grid-md .pf-v5-c-data-list__cell.pf-m-align-right {
    margin-inline-start: auto;
  }

  .pf-v5-c-data-list.pf-m-grid-md .pf-v5-c-data-list__cell.pf-m-no-fill {
    flex: none;
  }

  .pf-v5-c-data-list.pf-m-grid-md .pf-v5-c-data-list__cell.pf-m-flex-2 {
    flex-grow: 2;
  }

  .pf-v5-c-data-list.pf-m-grid-md .pf-v5-c-data-list__cell.pf-m-flex-3 {
    flex-grow: 3;
  }

  .pf-v5-c-data-list.pf-m-grid-md .pf-v5-c-data-list__cell.pf-m-flex-4 {
    flex-grow: 4;
  }

  .pf-v5-c-data-list.pf-m-grid-md .pf-v5-c-data-list__cell.pf-m-flex-5 {
    flex-grow: 5;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-c-data-list.pf-m-grid-md .pf-v5-c-data-list__expandable-content {
    max-height: initial;
    overflow-y: visible;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-c-data-list.pf-m-grid-lg {
    --pf-v5-c-data-list__cell--cell--PaddingTop: var(--pf-v5-c-data-list__cell--cell--md--PaddingTop);
    --pf-v5-c-data-list__cell--PaddingBottom: var(--pf-v5-c-data-list__cell--md--PaddingBottom);
    --pf-v5-c-data-list__item-control--MarginRight: var(--pf-v5-c-data-list__item-control--md--MarginRight);
    --pf-v5-c-data-list__item-action--MarginLeft: var(--pf-v5-c-data-list__item-action--md--MarginLeft);
    --pf-v5-c-data-list__expandable-content-body--PaddingTop: var(--pf-v5-c-data-list__expandable-content-body--md--PaddingTop);
    --pf-v5-c-data-list__expandable-content-body--PaddingBottom: var(--pf-v5-c-data-list__expandable-content-body--md--PaddingBottom);
    --pf-v5-c-data-list--m-compact__cell--PaddingBottom: var(--pf-v5-c-data-list--m-compact__cell--md--PaddingBottom);
    --pf-v5-c-data-list--m-compact__cell-cell--PaddingTop: var(--pf-v5-c-data-list--m-compact__cell-cell--md--PaddingTop);
  }
}

@media screen and (min-width: 992px)and (min-width: 1200px) {
  .pf-v5-c-data-list.pf-m-grid-lg {
    --pf-v5-c-data-list__item-row--PaddingRight: var(--pf-v5-c-data-list__item-row--xl--PaddingRight);
    --pf-v5-c-data-list__item-row--PaddingLeft: var(--pf-v5-c-data-list__item-row--xl--PaddingLeft);
    --pf-v5-c-data-list__expandable-content-body--PaddingRight: var(--pf-v5-c-data-list__expandable-content-body--xl--PaddingRight);
    --pf-v5-c-data-list__expandable-content-body--PaddingLeft: var(--pf-v5-c-data-list__expandable-content-body--xl--PaddingLeft);
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-c-data-list.pf-m-grid-lg .pf-v5-c-data-list__item-content {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    padding-block-end: var(--pf-v5-c-data-list__item-content--md--PaddingBottom);
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-c-data-list.pf-m-grid-lg .pf-v5-c-data-list__cell:not(:last-child):not(.pf-m-icon) {
    margin-inline-end: var(--pf-v5-c-data-list__cell--MarginRight);
  }

  .pf-v5-c-data-list.pf-m-grid-lg .pf-v5-c-data-list__cell+.pf-v5-c-data-list__cell {
    flex: 1;
    order: initial;
  }

  .pf-v5-c-data-list.pf-m-grid-lg .pf-v5-c-data-list__cell.pf-m-align-right {
    margin-inline-start: auto;
  }

  .pf-v5-c-data-list.pf-m-grid-lg .pf-v5-c-data-list__cell.pf-m-no-fill {
    flex: none;
  }

  .pf-v5-c-data-list.pf-m-grid-lg .pf-v5-c-data-list__cell.pf-m-flex-2 {
    flex-grow: 2;
  }

  .pf-v5-c-data-list.pf-m-grid-lg .pf-v5-c-data-list__cell.pf-m-flex-3 {
    flex-grow: 3;
  }

  .pf-v5-c-data-list.pf-m-grid-lg .pf-v5-c-data-list__cell.pf-m-flex-4 {
    flex-grow: 4;
  }

  .pf-v5-c-data-list.pf-m-grid-lg .pf-v5-c-data-list__cell.pf-m-flex-5 {
    flex-grow: 5;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-c-data-list.pf-m-grid-lg .pf-v5-c-data-list__expandable-content {
    max-height: initial;
    overflow-y: visible;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-c-data-list.pf-m-grid-xl {
    --pf-v5-c-data-list__cell--cell--PaddingTop: var(--pf-v5-c-data-list__cell--cell--md--PaddingTop);
    --pf-v5-c-data-list__cell--PaddingBottom: var(--pf-v5-c-data-list__cell--md--PaddingBottom);
    --pf-v5-c-data-list__item-control--MarginRight: var(--pf-v5-c-data-list__item-control--md--MarginRight);
    --pf-v5-c-data-list__item-action--MarginLeft: var(--pf-v5-c-data-list__item-action--md--MarginLeft);
    --pf-v5-c-data-list__expandable-content-body--PaddingTop: var(--pf-v5-c-data-list__expandable-content-body--md--PaddingTop);
    --pf-v5-c-data-list__expandable-content-body--PaddingBottom: var(--pf-v5-c-data-list__expandable-content-body--md--PaddingBottom);
    --pf-v5-c-data-list--m-compact__cell--PaddingBottom: var(--pf-v5-c-data-list--m-compact__cell--md--PaddingBottom);
    --pf-v5-c-data-list--m-compact__cell-cell--PaddingTop: var(--pf-v5-c-data-list--m-compact__cell-cell--md--PaddingTop);
  }
}

@media screen and (min-width: 1200px)and (min-width: 1200px) {
  .pf-v5-c-data-list.pf-m-grid-xl {
    --pf-v5-c-data-list__item-row--PaddingRight: var(--pf-v5-c-data-list__item-row--xl--PaddingRight);
    --pf-v5-c-data-list__item-row--PaddingLeft: var(--pf-v5-c-data-list__item-row--xl--PaddingLeft);
    --pf-v5-c-data-list__expandable-content-body--PaddingRight: var(--pf-v5-c-data-list__expandable-content-body--xl--PaddingRight);
    --pf-v5-c-data-list__expandable-content-body--PaddingLeft: var(--pf-v5-c-data-list__expandable-content-body--xl--PaddingLeft);
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-c-data-list.pf-m-grid-xl .pf-v5-c-data-list__item-content {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    padding-block-end: var(--pf-v5-c-data-list__item-content--md--PaddingBottom);
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-c-data-list.pf-m-grid-xl .pf-v5-c-data-list__cell:not(:last-child):not(.pf-m-icon) {
    margin-inline-end: var(--pf-v5-c-data-list__cell--MarginRight);
  }

  .pf-v5-c-data-list.pf-m-grid-xl .pf-v5-c-data-list__cell+.pf-v5-c-data-list__cell {
    flex: 1;
    order: initial;
  }

  .pf-v5-c-data-list.pf-m-grid-xl .pf-v5-c-data-list__cell.pf-m-align-right {
    margin-inline-start: auto;
  }

  .pf-v5-c-data-list.pf-m-grid-xl .pf-v5-c-data-list__cell.pf-m-no-fill {
    flex: none;
  }

  .pf-v5-c-data-list.pf-m-grid-xl .pf-v5-c-data-list__cell.pf-m-flex-2 {
    flex-grow: 2;
  }

  .pf-v5-c-data-list.pf-m-grid-xl .pf-v5-c-data-list__cell.pf-m-flex-3 {
    flex-grow: 3;
  }

  .pf-v5-c-data-list.pf-m-grid-xl .pf-v5-c-data-list__cell.pf-m-flex-4 {
    flex-grow: 4;
  }

  .pf-v5-c-data-list.pf-m-grid-xl .pf-v5-c-data-list__cell.pf-m-flex-5 {
    flex-grow: 5;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-c-data-list.pf-m-grid-xl .pf-v5-c-data-list__expandable-content {
    max-height: initial;
    overflow-y: visible;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-c-data-list.pf-m-grid-2xl {
    --pf-v5-c-data-list__cell--cell--PaddingTop: var(--pf-v5-c-data-list__cell--cell--md--PaddingTop);
    --pf-v5-c-data-list__cell--PaddingBottom: var(--pf-v5-c-data-list__cell--md--PaddingBottom);
    --pf-v5-c-data-list__item-control--MarginRight: var(--pf-v5-c-data-list__item-control--md--MarginRight);
    --pf-v5-c-data-list__item-action--MarginLeft: var(--pf-v5-c-data-list__item-action--md--MarginLeft);
    --pf-v5-c-data-list__expandable-content-body--PaddingTop: var(--pf-v5-c-data-list__expandable-content-body--md--PaddingTop);
    --pf-v5-c-data-list__expandable-content-body--PaddingBottom: var(--pf-v5-c-data-list__expandable-content-body--md--PaddingBottom);
    --pf-v5-c-data-list--m-compact__cell--PaddingBottom: var(--pf-v5-c-data-list--m-compact__cell--md--PaddingBottom);
    --pf-v5-c-data-list--m-compact__cell-cell--PaddingTop: var(--pf-v5-c-data-list--m-compact__cell-cell--md--PaddingTop);
  }
}

@media screen and (min-width: 1450px)and (min-width: 1200px) {
  .pf-v5-c-data-list.pf-m-grid-2xl {
    --pf-v5-c-data-list__item-row--PaddingRight: var(--pf-v5-c-data-list__item-row--xl--PaddingRight);
    --pf-v5-c-data-list__item-row--PaddingLeft: var(--pf-v5-c-data-list__item-row--xl--PaddingLeft);
    --pf-v5-c-data-list__expandable-content-body--PaddingRight: var(--pf-v5-c-data-list__expandable-content-body--xl--PaddingRight);
    --pf-v5-c-data-list__expandable-content-body--PaddingLeft: var(--pf-v5-c-data-list__expandable-content-body--xl--PaddingLeft);
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-c-data-list.pf-m-grid-2xl .pf-v5-c-data-list__item-content {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    padding-block-end: var(--pf-v5-c-data-list__item-content--md--PaddingBottom);
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-c-data-list.pf-m-grid-2xl .pf-v5-c-data-list__cell:not(:last-child):not(.pf-m-icon) {
    margin-inline-end: var(--pf-v5-c-data-list__cell--MarginRight);
  }

  .pf-v5-c-data-list.pf-m-grid-2xl .pf-v5-c-data-list__cell+.pf-v5-c-data-list__cell {
    flex: 1;
    order: initial;
  }

  .pf-v5-c-data-list.pf-m-grid-2xl .pf-v5-c-data-list__cell.pf-m-align-right {
    margin-inline-start: auto;
  }

  .pf-v5-c-data-list.pf-m-grid-2xl .pf-v5-c-data-list__cell.pf-m-no-fill {
    flex: none;
  }

  .pf-v5-c-data-list.pf-m-grid-2xl .pf-v5-c-data-list__cell.pf-m-flex-2 {
    flex-grow: 2;
  }

  .pf-v5-c-data-list.pf-m-grid-2xl .pf-v5-c-data-list__cell.pf-m-flex-3 {
    flex-grow: 3;
  }

  .pf-v5-c-data-list.pf-m-grid-2xl .pf-v5-c-data-list__cell.pf-m-flex-4 {
    flex-grow: 4;
  }

  .pf-v5-c-data-list.pf-m-grid-2xl .pf-v5-c-data-list__cell.pf-m-flex-5 {
    flex-grow: 5;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-c-data-list.pf-m-grid-2xl .pf-v5-c-data-list__expandable-content {
    max-height: initial;
    overflow-y: visible;
  }
}

.pf-v5-c-data-list {
  --pf-v5-c-data-list--FontSize: 1em;
  --pf-v5-c-data-list--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-data-list--BorderTopColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-data-list--BorderTopWidth: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-data-list--sm--BorderTopWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-data-list--sm--BorderTopColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-data-list--MarginLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-data-list__item--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-data-list__item--m-selected--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-data-list__item--m-expanded--before--BackgroundColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-data-list__item--m-selected--before--BackgroundColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-data-list__item--m-selected--BoxShadow: var(--pf-v5-global--BoxShadow--sm-top), var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-data-list__item--m-clickable--OutlineOffset: calc(-1 * var(--pf-v5-global--spacer--xs));
  --pf-v5-c-data-list__item--m-clickable--hover--ZIndex: calc(var(--pf-v5-c-data-list__item--m-selected--ZIndex) + 1);
  --pf-v5-c-data-list__item--m-clickable--hover--BoxShadow: var(--pf-v5-global--BoxShadow--sm-top), var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-data-list__item--m-clickable--focus--BoxShadow: var(--pf-v5-global--BoxShadow--sm-top), var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-data-list__item--m-clickable--active--BoxShadow: var(--pf-v5-global--BoxShadow--sm-top), var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-data-list__item--BorderBottomColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-data-list__item--BorderBottomWidth: 0.5rem;
  --pf-v5-c-data-list__item--m-clickable--hover--item--BorderTopColor: var(--pf-v5-c-data-list__item--BorderBottomColor);
  --pf-v5-c-data-list__item--m-clickable--hover--item--BorderTopWidth: var(--pf-v5-c-data-list__item--BorderBottomWidth);
  --pf-v5-c-data-list__item--sm--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-data-list__item--sm--BorderBottomColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-data-list__item--before--BackgroundColor: transparent;
  --pf-v5-c-data-list__item--before--Width: calc(2 * var(--pf-v5-global--BorderWidth--lg));
  --pf-v5-c-data-list__item--before--Transition: var(--pf-v5-global--Transition);
  --pf-v5-c-data-list__item--before--Top: 0;
  --pf-v5-c-data-list__item--before--sm--Top: calc(var(--pf-v5-c-data-list__item--BorderBottomWidth) * -1);
  --pf-v5-c-data-list__item-row--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-data-list__item-row--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-data-list__item-row--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-data-list__item-row--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-data-list__item-content--md--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-data-list__cell--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-data-list__cell--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-data-list__cell--MarginRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-data-list__cell--md--PaddingBottom: 0;
  --pf-v5-c-data-list__cell--m-icon--MarginRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-data-list__cell--cell--PaddingTop: 0;
  --pf-v5-c-data-list__cell--cell--md--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-data-list__cell--m-icon--cell--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-data-list--cell--MinWidth: initial;
  --pf-v5-c-data-list--cell--Overflow: visible;
  --pf-v5-c-data-list--cell--TextOverflow: clip;
  --pf-v5-c-data-list--cell--WhiteSpace: normal;
  --pf-v5-c-data-list--cell--WordBreak: normal;
  --pf-v5-c-data-list--cell--m-truncate--MinWidth: 5ch;
  --pf-v5-c-data-list__toggle--MarginLeft: calc(var(--pf-v5-global--spacer--sm) * -1);
  --pf-v5-c-data-list__toggle--MarginTop: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-data-list__toggle--MarginBottom: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-data-list__toggle-icon--Height: calc(var(--pf-v5-c-data-list--FontSize) * var(--pf-v5-c-data-list--LineHeight));
  --pf-v5-c-data-list__toggle-icon--Transition: .2s ease-in 0s;
  --pf-v5-c-data-list__toggle-icon--Rotate: 0;
  --pf-v5-c-data-list__item--m-expanded__toggle-icon--Rotate: 90deg;
  --pf-v5-c-data-list__item-draggable-button--BackgroundColor: transparent;
  --pf-v5-c-data-list__item-draggable-button--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-data-list__item-draggable-button--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-data-list__item-draggable-button--MarginTop: calc(var(--pf-v5-global--spacer--lg) * -1);
  --pf-v5-c-data-list__item-draggable-button--MarginBottom: calc(var(--pf-v5-global--spacer--lg) * -1);
  --pf-v5-c-data-list__item-draggable-button--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-data-list__item-draggable-button--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-data-list__item-draggable-button--MarginLeft: calc(var(--pf-v5-global--spacer--md) * -1);
  --pf-v5-c-data-list__item-draggable-button-icon--Color: var(--pf-v5-global--icon--Color--light);
  --pf-v5-c-data-list__item-draggable-button--m-disabled__draggable-icon--Color: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-data-list__item-draggable-button--hover__draggable-icon--Color: var(--pf-v5-global--icon--Color--dark);
  --pf-v5-c-data-list__item-draggable-button--focus__draggable-icon--Color: var(--pf-v5-global--icon--Color--dark);
  --pf-v5-c-data-list__item--m-ghost-row--after--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-data-list__item--m-ghost-row--after--Opacity: .6;
  --pf-v5-c-data-list__item-control--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-data-list__item-control--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-data-list__item-control--MarginRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-data-list__item-control--md--MarginRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-data-list__item-control--not-last-child--MarginRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-data-list__check--Height: calc(var(--pf-v5-c-data-list--FontSize) * var(--pf-v5-c-data-list--LineHeight));
  --pf-v5-c-data-list__check--MarginTop: -0.0625rem;
  --pf-v5-c-data-list__item-action--Display: flex;
  --pf-v5-c-data-list__item-action--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-data-list__item-action--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-data-list__item-action--MarginLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-data-list__item-action--md--MarginLeft: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-data-list__item-action--not-last-child--MarginRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-data-list__item-action__action--MarginTop: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-data-list__action--MarginTop: var(--pf-v5-c-data-list__item-action__action--MarginTop);
  --pf-v5-c-data-list__item-action__action--MarginBottom: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-data-list__expandable-content--MarginRight: calc(var(--pf-v5-c-data-list__expandable-content-body--PaddingRight) * -1);
  --pf-v5-c-data-list__expandable-content--MarginLeft: calc(var(--pf-v5-c-data-list__expandable-content-body--PaddingLeft) * -1);
  --pf-v5-c-data-list__expandable-content--MaxHeight: 37.5rem;
  --pf-v5-c-data-list__expandable-content--before--Top: calc(var(--pf-v5-c-data-list__item--BorderBottomWidth) * -1);
  --pf-v5-c-data-list__expandable-content-body--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-data-list__expandable-content-body--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-data-list__expandable-content-body--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-data-list__expandable-content-body--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-data-list__expandable-content-body--md--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-data-list__expandable-content-body--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-data-list__expandable-content-body--md--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-data-list__expandable-content-body--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-data-list--m-compact--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-data-list--m-compact--LineHeight: var(--pf-v5-global--LineHeight--sm);
  --pf-v5-c-data-list--m-compact__check--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-data-list--m-compact__cell--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-data-list--m-compact__cell--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-data-list--m-compact__cell--md--PaddingBottom: 0;
  --pf-v5-c-data-list--m-compact__cell-cell--PaddingTop: 0;
  --pf-v5-c-data-list--m-compact__cell-cell--md--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-data-list--m-compact__cell--cell--MarginRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-data-list--m-compact__item-control--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-data-list--m-compact__item-control--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-data-list--m-compact__item-control--MarginRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-data-list--m-compact__item-action--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-data-list--m-compact__item-action--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-data-list--m-compact__item-action--MarginLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-data-list--m-compact__item-action__action--MarginTop: calc(var(--pf-v5-c-data-list--m-compact__item-action--PaddingTop) * -1);
  --pf-v5-c-data-list--m-compact__item-action__action--MarginBottom: calc(var(--pf-v5-c-data-list--m-compact__item-action--PaddingBottom) * -1);
  --pf-v5-c-data-list--m-compact__action--MarginTop: var(--pf-v5-c-data-list--m-compact__item-action__action--MarginTop);
  --pf-v5-c-data-list--m-compact__item-content--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-data-list--m-compact__item-draggable-button--MarginTop: calc(var(--pf-v5-global--spacer--sm) * -1);
  --pf-v5-c-data-list--m-compact__item-draggable-button--MarginBottom: calc(var(--pf-v5-global--spacer--sm) * -1);
  --pf-v5-c-data-list--m-compact__item-draggable-button--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-data-list--m-compact__item-draggable-button--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-data-list--m-compact__cell--m-icon--cell--PaddingTop: var(--pf-v5-global--spacer--sm);
  color: var(--pf-v5-global--Color--100);
  font-size: var(--pf-v5-c-data-list--FontSize);
  line-height: var(--pf-v5-c-data-list--LineHeight);
  overflow-wrap: break-word;
  border-block-start: var(--pf-v5-c-data-list--BorderTopWidth) solid var(--pf-v5-c-data-list--BorderTopColor);
}

@media screen and (min-width: 576px) {
  .pf-v5-c-data-list {
    --pf-v5-c-data-list--BorderTopColor: var(--pf-v5-c-data-list--sm--BorderTopColor);
    --pf-v5-c-data-list--BorderTopWidth: var(--pf-v5-c-data-list--sm--BorderTopWidth);
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-c-data-list {
    --pf-v5-c-data-list__item--BorderBottomWidth: var(--pf-v5-c-data-list__item--sm--BorderBottomWidth);
    --pf-v5-c-data-list__item--BorderBottomColor: var(--pf-v5-c-data-list__item--sm--BorderBottomColor);
  }
}

@media (min-width: 576px) {
  .pf-v5-c-data-list {
    --pf-v5-c-data-list__item--before--Top: var(--pf-v5-c-data-list__item--before--sm--Top);
  }
}

.pf-v5-c-data-list.pf-m-compact {
  --pf-v5-c-data-list__check--FontSize: var(--pf-v5-c-data-list--m-compact__check--FontSize);
  --pf-v5-c-data-list__action--MarginTop: var(--pf-v5-c-data-list--m-compact__action--MarginTop);
  --pf-v5-c-data-list--FontSize: var(--pf-v5-c-data-list--m-compact--FontSize);
  --pf-v5-c-data-list__item-action--MarginLeft: var(--pf-v5-c-data-list--m-compact__item-action--MarginLeft);
  --pf-v5-c-data-list__item-action--PaddingTop: var(--pf-v5-c-data-list--m-compact__item-action--PaddingTop);
  --pf-v5-c-data-list__item-action--PaddingBottom: var(--pf-v5-c-data-list--m-compact__item-action--PaddingBottom);
  --pf-v5-c-data-list__item-action__action--MarginTop: var(--pf-v5-c-data-list--m-compact__item-action__action--MarginTop);
  --pf-v5-c-data-list__item-action__action--MarginBottom: var(--pf-v5-c-data-list--m-compact__item-action__action--MarginBottom);
  --pf-v5-c-data-list__item-control--MarginRight: var(--pf-v5-c-data-list--m-compact__item-control--MarginRight);
  --pf-v5-c-data-list__item-control--PaddingTop: var(--pf-v5-c-data-list--m-compact__item-control--PaddingTop);
  --pf-v5-c-data-list__item-control--PaddingBottom: var(--pf-v5-c-data-list--m-compact__item-control--PaddingBottom);
  --pf-v5-c-data-list__item-content--md--PaddingBottom: var(--pf-v5-c-data-list--m-compact__item-content--PaddingBottom);
  --pf-v5-c-data-list__item-draggable-button--MarginTop: var(--pf-v5-c-data-list--m-compact__item-draggable-button--MarginTop);
  --pf-v5-c-data-list__item-draggable-button--MarginBottom: var(--pf-v5-c-data-list--m-compact__item-draggable-button--MarginBottom);
  --pf-v5-c-data-list__item-draggable-button--PaddingTop: var(--pf-v5-c-data-list--m-compact__item-draggable-button--PaddingTop);
  --pf-v5-c-data-list__item-draggable-button--PaddingBottom: var(--pf-v5-c-data-list--m-compact__item-draggable-button--PaddingBottom);
  --pf-v5-c-data-list__cell--m-icon--cell--PaddingTop: var(--pf-v5-c-data-list--m-compact__cell--m-icon--cell--PaddingTop);
  font-size: var(--pf-v5-c-data-list--m-compact--FontSize);
}

.pf-v5-c-data-list.pf-m-compact .pf-v5-c-data-list__cell {
  --pf-v5-c-data-list__cell--PaddingTop: var(--pf-v5-c-data-list--m-compact__cell--PaddingTop);
  --pf-v5-c-data-list__cell--PaddingBottom: var(--pf-v5-c-data-list--m-compact__cell--PaddingBottom);
  --pf-v5-c-data-list__cell--MarginRight: var(--pf-v5-c-data-list--m-compact__cell--cell--MarginRight);
  --pf-v5-c-data-list__cell--cell--PaddingTop: var(--pf-v5-c-data-list--m-compact__cell-cell--PaddingTop);
}

.pf-v5-c-data-list.pf-m-compact .pf-v5-c-data-list__check {
  font-size: var(--pf-v5-c-data-list--m-compact__check--FontSize);
}

.pf-v5-c-data-list.pf-m-drag-over {
  overflow-anchor: none;
}

.pf-v5-c-data-list.pf-m-truncate,
.pf-v5-c-data-list__item-row.pf-m-truncate,
.pf-v5-c-data-list__cell.pf-m-truncate,
.pf-v5-c-data-list__text.pf-m-truncate {
  --pf-v5-c-data-list--cell--MinWidth: var(--pf-v5-c-data-list--cell--m-truncate--MinWidth);
  --pf-v5-c-data-list--cell--Overflow: hidden;
  --pf-v5-c-data-list--cell--TextOverflow: ellipsis;
  --pf-v5-c-data-list--cell--WhiteSpace: nowrap;
}

.pf-v5-c-data-list.pf-m-break-word,
.pf-v5-c-data-list__item-row.pf-m-break-word,
.pf-v5-c-data-list__cell.pf-m-break-word,
.pf-v5-c-data-list__text.pf-m-break-word {
  --pf-v5-c-data-list--cell--WordBreak: break-word;
}

.pf-v5-c-data-list.pf-m-nowrap,
.pf-v5-c-data-list__item-row.pf-m-nowrap,
.pf-v5-c-data-list__cell.pf-m-nowrap,
.pf-v5-c-data-list__text.pf-m-nowrap {
  --pf-v5-c-data-list--cell--WhiteSpace: nowrap;
}

.pf-v5-c-data-list__item {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--pf-v5-c-data-list__item--BackgroundColor);
  border-block-end: var(--pf-v5-c-data-list__item--BorderBottomWidth) solid var(--pf-v5-c-data-list__item--BorderBottomColor);
}

.pf-v5-c-data-list__item::before {
  position: absolute;
  inset-block-start: var(--pf-v5-c-data-list__item--before--Top);
  inset-block-end: 0;
  inset-inline-start: 0;
  width: var(--pf-v5-c-data-list__item--before--Width);
  content: "";
  background-color: var(--pf-v5-c-data-list__item--before--BackgroundColor);
  transition: var(--pf-v5-c-data-list__item--before--Transition);
}

.pf-v5-c-data-list__item.pf-m-clickable {
  cursor: pointer;
  outline-offset: var(--pf-v5-c-data-list__item--m-clickable--OutlineOffset);
}

.pf-v5-c-data-list__item.pf-m-clickable:hover,
.pf-v5-c-data-list__item.pf-m-clickable:focus {
  position: relative;
  z-index: var(--pf-v5-c-data-list__item--m-clickable--hover--ZIndex);
}

.pf-v5-c-data-list__item.pf-m-clickable:hover:not(.pf-m-selected):not(:last-child),
.pf-v5-c-data-list__item.pf-m-clickable:focus:not(.pf-m-selected):not(:last-child) {
  --pf-v5-c-data-list__item--BorderBottomWidth: 0;
}

.pf-v5-c-data-list__item.pf-m-clickable:hover:not(.pf-m-selected):not(:last-child)+.pf-v5-c-data-list__item,
.pf-v5-c-data-list__item.pf-m-clickable:focus:not(.pf-m-selected):not(:last-child)+.pf-v5-c-data-list__item {
  border-block-start: var(--pf-v5-c-data-list__item--m-clickable--hover--item--BorderTopWidth) solid var(--pf-v5-c-data-list__item--m-clickable--hover--item--BorderTopColor);
}

.pf-v5-c-data-list__item.pf-m-clickable:hover {
  box-shadow: var(--pf-v5-c-data-list__item--m-clickable--hover--BoxShadow);
}

.pf-v5-c-data-list__item.pf-m-clickable:focus {
  box-shadow: var(--pf-v5-c-data-list__item--m-clickable--focus--BoxShadow);
}

.pf-v5-c-data-list__item.pf-m-clickable:active {
  box-shadow: var(--pf-v5-c-data-list__item--m-clickable--active--BoxShadow);
}

.pf-v5-c-data-list__item.pf-m-selected {
  --pf-v5-c-data-list__item--before--BackgroundColor: var(--pf-v5-c-data-list__item--m-selected--before--BackgroundColor);
  position: relative;
  z-index: var(--pf-v5-c-data-list__item--m-selected--ZIndex);
  box-shadow: var(--pf-v5-c-data-list__item--m-selected--BoxShadow);
}

.pf-v5-c-data-list__item.pf-m-ghost-row::after {
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  content: "";
  background-color: var(--pf-v5-c-data-list__item--m-ghost-row--after--BackgroundColor);
  opacity: var(--pf-v5-c-data-list__item--m-ghost-row--after--Opacity);
}

.pf-v5-c-data-list__item.pf-m-expanded {
  --pf-v5-c-data-list__toggle-icon--Rotate: var(--pf-v5-c-data-list__item--m-expanded__toggle-icon--Rotate);
}

.pf-v5-c-data-list__item-row {
  display: flex;
  flex-wrap: nowrap;
  padding-inline-start: var(--pf-v5-c-data-list__item-row--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-data-list__item-row--PaddingRight);
}

.pf-v5-c-data-list__item-control {
  display: flex;
  flex-wrap: nowrap;
  padding-block-start: var(--pf-v5-c-data-list__item-control--PaddingTop);
  padding-block-end: var(--pf-v5-c-data-list__item-control--PaddingBottom);
  margin-inline-end: var(--pf-v5-c-data-list__item-control--MarginRight);
}

.pf-v5-c-data-list__item-control>*:not(:last-child) {
  margin-inline-end: var(--pf-v5-c-data-list__item-control--not-last-child--MarginRight);
}

.pf-v5-c-data-list__check {
  display: flex;
  align-items: center;
  align-self: flex-start;
  height: var(--pf-v5-c-data-list__check--Height);
  margin-block-start: var(--pf-v5-c-data-list__check--MarginTop);
}

.pf-v5-c-data-list__check>input {
  cursor: pointer;
}

.pf-v5-c-data-list__item-draggable-button {
  display: flex;
  flex-direction: column;
  padding-block-start: var(--pf-v5-c-data-list__item-draggable-button--PaddingTop);
  padding-block-end: var(--pf-v5-c-data-list__item-draggable-button--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-data-list__item-draggable-button--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-data-list__item-draggable-button--PaddingRight);
  margin-block-start: var(--pf-v5-c-data-list__item-draggable-button--MarginTop);
  margin-block-end: var(--pf-v5-c-data-list__item-draggable-button--MarginBottom);
  margin-inline-start: var(--pf-v5-c-data-list__item-draggable-button--MarginLeft);
  background-color: var(--pf-v5-c-data-list__item-draggable-button--BackgroundColor);
  border: 0;
}

.pf-v5-c-data-list__item-draggable-button:hover {
  --pf-v5-c-data-list__item-draggable-button-icon--Color: var(--pf-v5-c-data-list__item-draggable-button--hover__draggable-icon--Color);
  cursor: grab;
}

.pf-v5-c-data-list__item-draggable-button:focus {
  --pf-v5-c-data-list__item-draggable-button-icon--Color: var(--pf-v5-c-data-list__item-draggable-button--focus__draggable-icon--Color);
}

.pf-v5-c-data-list__item-draggable-button:active {
  cursor: grabbing;
}

.pf-v5-c-data-list__item-draggable-button.pf-m-disabled {
  --pf-v5-c-data-list__item-draggable-button-icon--Color: var(--pf-v5-c-data-list__item-draggable-button--m-disabled__draggable-icon--Color);
  pointer-events: none;
}

.pf-v5-c-data-list__item-draggable-button .pf-v5-c-data-list__item-draggable-icon {
  color: var(--pf-v5-c-data-list__item-draggable-button-icon--Color);
}

.pf-v5-c-data-list__item-action {
  --pf-v5-hidden-visible--visible--Display: var(--pf-v5-c-data-list__item-action--Display);
  align-content: flex-start;
  align-items: flex-start;
  padding-block-start: var(--pf-v5-c-data-list__item-action--PaddingTop);
  padding-block-end: var(--pf-v5-c-data-list__item-action--PaddingBottom);
  margin-inline-start: var(--pf-v5-c-data-list__item-action--MarginLeft);
}

.pf-v5-c-data-list__item-action>*:not(:last-child) {
  margin-inline-end: var(--pf-v5-c-data-list__item-action--not-last-child--MarginRight);
}

.pf-v5-c-data-list__item-action .pf-v5-c-data-list__action {
  margin-block-start: var(--pf-v5-c-data-list__action--MarginTop);
  margin-block-end: var(--pf-v5-c-data-list__item-action__action--MarginBottom);
}

.pf-v5-c-data-list__toggle {
  margin-block-start: var(--pf-v5-c-data-list__toggle--MarginTop);
  margin-block-end: var(--pf-v5-c-data-list__toggle--MarginBottom);
  margin-inline-start: var(--pf-v5-c-data-list__toggle--MarginLeft);
}

.pf-v5-c-data-list__toggle-icon {
  height: var(--pf-v5-c-data-list__toggle-icon--Height);
  pointer-events: none;
  transition: var(--pf-v5-c-data-list__toggle-icon--Transition);
  transform: rotate(var(--pf-v5-c-data-list__toggle-icon--Rotate));
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-data-list__toggle-icon {
  scale: -1 1;
}

.pf-v5-c-data-list__item-content {
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100%;
}

.pf-v5-c-data-list__cell {
  flex: 1;
  grid-column: 1/-1;
  padding-block-start: var(--pf-v5-c-data-list__cell--PaddingTop);
  padding-block-end: var(--pf-v5-c-data-list__cell--PaddingBottom);
}

.pf-v5-c-data-list__cell+.pf-v5-c-data-list__cell {
  flex: 1 0 100%;
  order: 1;
  padding-block-start: var(--pf-v5-c-data-list__cell--cell--PaddingTop);
}

.pf-v5-c-data-list__cell.pf-m-icon {
  flex-grow: 0;
  grid-column: 1/2;
  margin-inline-end: var(--pf-v5-c-data-list__cell--m-icon--MarginRight);
}

.pf-v5-c-data-list__cell.pf-m-icon+.pf-v5-c-data-list__cell {
  grid-column: 2/3;
  padding-block-start: var(--pf-v5-c-data-list__cell--m-icon--cell--PaddingTop);
}

.pf-v5-c-data-list__cell.pf-m-align-right {
  margin-inline-start: 0;
}

.pf-v5-c-data-list__text {
  display: inline-block;
}

.pf-v5-c-data-list__text,
.pf-v5-c-data-list__cell {
  min-width: var(--pf-v5-c-data-list--cell--MinWidth);
  max-width: 100%;
  overflow: var(--pf-v5-c-data-list--cell--Overflow);
  text-overflow: var(--pf-v5-c-data-list--cell--TextOverflow);
  word-break: var(--pf-v5-c-data-list--cell--WordBreak);
  white-space: var(--pf-v5-c-data-list--cell--WhiteSpace);
}

.pf-v5-c-data-list__expandable-content {
  max-height: var(--pf-v5-c-data-list__expandable-content--MaxHeight);
  overflow-y: auto;
}

.pf-v5-c-data-list__expandable-content .pf-v5-c-data-list__expandable-content-body {
  padding-block-start: var(--pf-v5-c-data-list__expandable-content-body--PaddingTop);
  padding-block-end: var(--pf-v5-c-data-list__expandable-content-body--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-data-list__expandable-content-body--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-data-list__expandable-content-body--PaddingRight);
}

.pf-v5-c-data-list__expandable-content .pf-v5-c-data-list__expandable-content-body>.pf-v5-c-data-list {
  margin-inline-start: var(--pf-v5-c-data-list--MarginLeft);
}

.pf-v5-c-data-list__expandable-content .pf-v5-c-data-list__expandable-content-body .pf-v5-c-data-list__item:last-child {
  border-block-end: 0;
}

.pf-v5-c-data-list__expandable-content .pf-v5-c-data-list__expandable-content-body .pf-v5-c-data-list__item-row {
  --pf-v5-c-data-list__item-row--PaddingLeft: 0;
}

.pf-v5-c-data-list__expandable-content .pf-v5-c-data-list__expandable-content-body .pf-v5-c-data-list__expandable-content-body {
  --pf-v5-c-data-list__expandable-content-body--PaddingLeft: 0;
}

.pf-v5-c-data-list__expandable-content .pf-v5-c-data-list__expandable-content-body.pf-m-no-padding {
  padding: 0;
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-data-list {
  --pf-v5-c-data-list--BorderTopColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-data-list--sm--BorderTopColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-data-list__item--BorderBottomColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-data-list__item--sm--BorderBottomColor: var(--pf-v5-global--BorderColor--100);
}

.pf-v5-c-description-list {
  --pf-v5-c-description-list--RowGap: var(--pf-v5-global--gutter--md);
  --pf-v5-c-description-list--ColumnGap: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-description-list--GridTemplateColumns--count: 1;
  --pf-v5-c-description-list--GridTemplateColumns--width: 1fr;
  --pf-v5-c-description-list--GridTemplateColumns--min: 0;
  --pf-v5-c-description-list--GridTemplateColumns: repeat(var(--pf-v5-c-description-list--GridTemplateColumns--count), var(--pf-v5-c-description-list--GridTemplateColumns--width));
  --pf-v5-c-description-list__group--RowGap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-description-list__group--ColumnGap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-description-list__group--GridTemplateColumns: auto;
  --pf-v5-c-description-list__group--GridTemplateRows: auto 1fr;
  --pf-v5-c-description-list__group--GridColumn: auto;
  --pf-v5-c-description-list--m-compact--RowGap: var(--pf-v5-global--gutter);
  --pf-v5-c-description-list--m-compact--ColumnGap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-description-list__term--Display: inline;
  --pf-v5-c-description-list__term--sm--Display: flex;
  --pf-v5-c-description-list__term--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-description-list__term--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-description-list__term--LineHeight: var(--pf-v5-global--LineHeight--sm);
  --pf-v5-c-description-list__term-icon--MinWidth: var(--pf-v5-global--icon--FontSize--sm);
  --pf-v5-c-description-list__term-icon--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-description-list__term-icon--Color: var(--pf-v5-global--icon--Color--light);
  --pf-v5-c-description-list--m-vertical__group--GridTemplateColumns: repeat(var(--pf-v5-c-description-list--GridTemplateColumns--count));
  --pf-v5-c-description-list--m-vertical__group--GridTemplateRows: auto 1fr;
  --pf-v5-c-description-list--m-horizontal__term--width: 12ch;
  --pf-v5-c-description-list--m-horizontal__description--width: minmax(10ch, auto);
  --pf-v5-c-description-list--m-horizontal__group--GridTemplateColumns: var(--pf-v5-c-description-list__term--width) var(--pf-v5-c-description-list--m-horizontal__description--width);
  --pf-v5-c-description-list--m-horizontal__group--GridTemplateRows: auto;
  --pf-v5-c-description-list--m-1-col--GridTemplateColumns--count: 1;
  --pf-v5-c-description-list--m-2-col--GridTemplateColumns--count: 2;
  --pf-v5-c-description-list--m-3-col--GridTemplateColumns--count: 3;
  --pf-v5-c-description-list--m-auto-fit--GridTemplateColumns--min: 15.625rem;
  --pf-v5-c-description-list--m-auto-fit--GridTemplateColumns--minmax--min: var(--pf-v5-c-description-list--m-auto-fit--GridTemplateColumns--min);
  --pf-v5-c-description-list__text--m-help-text--TextDecorationColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-description-list__text--m-help-text--TextDecorationThickness: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-description-list__text--m-help-text--TextDecorationOffset: 0.25rem;
  --pf-v5-c-description-list__text--m-help-text--hover--TextDecorationColor: var(--pf-v5-global--Color--100);
  --pf-v5-c-description-list__text--m-help-text--focus--TextDecorationColor: var(--pf-v5-global--Color--100);
  --pf-v5-c-description-list--m-display-lg__description--FontSize: var(--pf-v5-global--FontSize--lg);
  --pf-v5-c-description-list--m-display-2xl__description--FontSize: var(--pf-v5-global--FontSize--2xl);
  display: grid;
  grid-template-columns: var(--pf-v5-c-description-list--GridTemplateColumns);
  row-gap: var(--pf-v5-c-description-list--RowGap);
  column-gap: var(--pf-v5-c-description-list--ColumnGap);
  align-items: baseline;
}

@media screen and (min-width: 576px) {
  .pf-v5-c-description-list {
    --pf-v5-c-description-list__term--Display: var(--pf-v5-c-description-list__term--sm--Display);
  }
}

.pf-v5-c-description-list[class*=pf-m-horizontal] {
  --pf-v5-c-description-list__term--width: var(--pf-v5-c-description-list--m-horizontal__term--width);
}

@media (min-width: 576px) {
  .pf-v5-c-description-list[class*=pf-m-horizontal] {
    --pf-v5-c-description-list__term--width: var(--pf-v5-c-description-list--m-horizontal__term--width-on-sm, var(--pf-v5-c-description-list--m-horizontal__term--width));
  }
}

@media (min-width: 768px) {
  .pf-v5-c-description-list[class*=pf-m-horizontal] {
    --pf-v5-c-description-list__term--width: var(--pf-v5-c-description-list--m-horizontal__term--width-on-md, var(--pf-v5-c-description-list--m-horizontal__term--width-on-sm, var(--pf-v5-c-description-list--m-horizontal__term--width)));
  }
}

@media (min-width: 992px) {
  .pf-v5-c-description-list[class*=pf-m-horizontal] {
    --pf-v5-c-description-list__term--width: var(--pf-v5-c-description-list--m-horizontal__term--width-on-lg, var(--pf-v5-c-description-list--m-horizontal__term--width-on-md, var(--pf-v5-c-description-list--m-horizontal__term--width-on-sm, var(--pf-v5-c-description-list--m-horizontal__term--width))));
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-description-list[class*=pf-m-horizontal] {
    --pf-v5-c-description-list__term--width: var(--pf-v5-c-description-list--m-horizontal__term--width-on-xl, var(--pf-v5-c-description-list--m-horizontal__term--width-on-lg, var(--pf-v5-c-description-list--m-horizontal__term--width-on-md, var(--pf-v5-c-description-list--m-horizontal__term--width-on-sm, var(--pf-v5-c-description-list--m-horizontal__term--width)))));
  }
}

@media (min-width: 1450px) {
  .pf-v5-c-description-list[class*=pf-m-horizontal] {
    --pf-v5-c-description-list__term--width: var(--pf-v5-c-description-list--m-horizontal__term--width-on-2xl, var(--pf-v5-c-description-list--m-horizontal__term--width-on-xl, var(--pf-v5-c-description-list--m-horizontal__term--width-on-lg, var(--pf-v5-c-description-list--m-horizontal__term--width-on-md, var(--pf-v5-c-description-list--m-horizontal__term--width-on-sm, var(--pf-v5-c-description-list--m-horizontal__term--width))))));
  }
}

.pf-v5-c-description-list.pf-m-inline-grid {
  display: inline-grid;
}

.pf-v5-c-description-list.pf-m-auto-column-widths {
  --pf-v5-c-description-list--GridTemplateColumns--width: minmax(8ch, max-content);
}

.pf-v5-c-description-list.pf-m-auto-fit {
  grid-template-columns: repeat(auto-fit, minmax(var(--pf-v5-c-description-list--m-auto-fit--GridTemplateColumns--minmax--min), 1fr));
  --pf-v5-c-description-list--GridTemplateColumns--minmax--min: var(--pf-v5-c-description-list--GridTemplateColumns--min);
}

@media (min-width: 576px) {
  .pf-v5-c-description-list.pf-m-auto-fit {
    --pf-v5-c-description-list--GridTemplateColumns--minmax--min: var(--pf-v5-c-description-list--GridTemplateColumns--min-on-sm, var(--pf-v5-c-description-list--GridTemplateColumns--min));
  }
}

@media (min-width: 768px) {
  .pf-v5-c-description-list.pf-m-auto-fit {
    --pf-v5-c-description-list--GridTemplateColumns--minmax--min: var(--pf-v5-c-description-list--GridTemplateColumns--min-on-md, var(--pf-v5-c-description-list--GridTemplateColumns--min-on-sm, var(--pf-v5-c-description-list--GridTemplateColumns--min)));
  }
}

@media (min-width: 992px) {
  .pf-v5-c-description-list.pf-m-auto-fit {
    --pf-v5-c-description-list--GridTemplateColumns--minmax--min: var(--pf-v5-c-description-list--GridTemplateColumns--min-on-lg, var(--pf-v5-c-description-list--GridTemplateColumns--min-on-md, var(--pf-v5-c-description-list--GridTemplateColumns--min-on-sm, var(--pf-v5-c-description-list--GridTemplateColumns--min))));
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-description-list.pf-m-auto-fit {
    --pf-v5-c-description-list--GridTemplateColumns--minmax--min: var(--pf-v5-c-description-list--GridTemplateColumns--min-on-xl, var(--pf-v5-c-description-list--GridTemplateColumns--min-on-lg, var(--pf-v5-c-description-list--GridTemplateColumns--min-on-md, var(--pf-v5-c-description-list--GridTemplateColumns--min-on-sm, var(--pf-v5-c-description-list--GridTemplateColumns--min)))));
  }
}

@media (min-width: 1450px) {
  .pf-v5-c-description-list.pf-m-auto-fit {
    --pf-v5-c-description-list--GridTemplateColumns--minmax--min: var(--pf-v5-c-description-list--GridTemplateColumns--min-on-2xl, var(--pf-v5-c-description-list--GridTemplateColumns--min-on-xl, var(--pf-v5-c-description-list--GridTemplateColumns--min-on-lg, var(--pf-v5-c-description-list--GridTemplateColumns--min-on-md, var(--pf-v5-c-description-list--GridTemplateColumns--min-on-sm, var(--pf-v5-c-description-list--GridTemplateColumns--min))))));
  }
}

.pf-v5-c-description-list.pf-m-compact {
  --pf-v5-c-description-list--RowGap: var(--pf-v5-c-description-list--m-compact--RowGap);
  --pf-v5-c-description-list--ColumnGap: var(--pf-v5-c-description-list--m-compact--ColumnGap);
}

.pf-v5-c-description-list.pf-m-fluid {
  --pf-v5-c-description-list--m-horizontal__term--width: fit-content(20ch);
}

.pf-v5-c-description-list.pf-m-fill-columns {
  display: block;
  column-count: var(--pf-v5-c-description-list--GridTemplateColumns--count);
  margin-block-end: calc(var(--pf-v5-c-description-list--RowGap)*-1);
}

.pf-v5-c-description-list.pf-m-fill-columns .pf-v5-c-description-list__group,
.pf-v5-c-description-list.pf-m-fill-columns>.pf-v5-c-card {
  display: inline-grid;
  width: 100%;
  margin-block-end: var(--pf-v5-c-description-list--RowGap);
  break-inside: avoid;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
}

.pf-v5-c-description-list.pf-m-display-lg {
  --pf-v5-c-description-list__description--FontSize: var(--pf-v5-c-description-list--m-display-lg__description--FontSize);
}

.pf-v5-c-description-list.pf-m-display-2xl {
  --pf-v5-c-description-list__description--FontSize: var(--pf-v5-c-description-list--m-display-2xl__description--FontSize);
}

.pf-v5-c-description-list>.pf-v5-c-card {
  align-self: stretch;
  padding-block-start: var(--pf-v5-c-card--first-child--PaddingTop);
  padding-block-end: var(--pf-v5-c-card--child--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-card--child--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-card--child--PaddingRight);
}

.pf-v5-c-description-list__group,
.pf-v5-c-description-list>.pf-v5-c-card {
  display: grid;
  grid-template-rows: var(--pf-v5-c-description-list__group--GridTemplateRows);
  grid-template-columns: var(--pf-v5-c-description-list__group--GridTemplateColumns);
  grid-column: var(--pf-v5-c-description-list__group--GridColumn);
  row-gap: var(--pf-v5-c-description-list__group--RowGap);
  column-gap: var(--pf-v5-c-description-list__group--ColumnGap);
  align-items: baseline;
}

.pf-v5-c-description-list__term,
.pf-v5-c-description-list__description {
  min-width: 0;
  text-align: start;
  overflow-wrap: break-word;
}

.pf-v5-c-description-list__term {
  display: var(--pf-v5-c-description-list__term--Display);
  font-size: var(--pf-v5-c-description-list__term--FontSize);
  font-weight: var(--pf-v5-c-description-list__term--FontWeight);
  line-height: var(--pf-v5-c-description-list__term--LineHeight);
}

.pf-v5-c-description-list__term .pf-v5-c-description-list__text {
  display: inline;
}

.pf-v5-c-description-list__term-icon {
  flex-shrink: 0;
  min-width: var(--pf-v5-c-description-list__term-icon--MinWidth);
  margin-inline-end: var(--pf-v5-c-description-list__term-icon--MarginRight);
  color: var(--pf-v5-c-description-list__term-icon--Color);
}

.pf-v5-c-description-list__description {
  font-size: var(--pf-v5-c-description-list__description--FontSize, inherit);
}

.pf-v5-c-description-list__text {
  min-width: 0;
}

.pf-v5-c-description-list__text.pf-m-help-text {
  text-decoration: underline;
  text-decoration-thickness: var(--pf-v5-c-description-list__text--m-help-text--TextDecorationThickness);
  text-decoration-style: dashed;
  text-decoration-color: var(--pf-v5-c-description-list__text--m-help-text--TextDecorationColor);
  text-underline-offset: var(--pf-v5-c-description-list__text--m-help-text--TextDecorationOffset);
  cursor: pointer;
}

.pf-v5-c-description-list__text.pf-m-help-text:hover {
  --pf-v5-c-description-list__text--m-help-text--TextDecorationColor: var(--pf-v5-c-description-list__text--m-help-text--hover--TextDecorationColor);
}

.pf-v5-c-description-list__text.pf-m-help-text:focus {
  --pf-v5-c-description-list__text--m-help-text--TextDecorationColor: var(--pf-v5-c-description-list__text--m-help-text--focus--TextDecorationColor);
}

.pf-v5-c-description-list.pf-m-1-col {
  --pf-v5-c-description-list--GridTemplateColumns--count: var(--pf-v5-c-description-list--m-1-col--GridTemplateColumns--count);
}

.pf-v5-c-description-list.pf-m-2-col {
  --pf-v5-c-description-list--GridTemplateColumns--count: var(--pf-v5-c-description-list--m-2-col--GridTemplateColumns--count);
}

.pf-v5-c-description-list.pf-m-3-col {
  --pf-v5-c-description-list--GridTemplateColumns--count: var(--pf-v5-c-description-list--m-3-col--GridTemplateColumns--count);
}

.pf-v5-c-description-list.pf-m-horizontal {
  --pf-v5-c-description-list__group--GridTemplateColumns: var(--pf-v5-c-description-list--m-horizontal__group--GridTemplateColumns);
  --pf-v5-c-description-list__group--GridTemplateRows: var(--pf-v5-c-description-list--m-horizontal__group--GridTemplateRows);
}

.pf-v5-c-description-list.pf-m-vertical {
  --pf-v5-c-description-list__group--GridTemplateColumns: var(--pf-v5-c-description-list--m-vertical__group--GridTemplateColumns);
  --pf-v5-c-description-list__group--GridTemplateRows: var(--pf-v5-c-description-list--m-vertical__group--GridTemplateRows);
}

@media (min-width: 576px) {
  .pf-v5-c-description-list.pf-m-1-col-on-sm {
    --pf-v5-c-description-list--GridTemplateColumns--count: var(--pf-v5-c-description-list--m-1-col--GridTemplateColumns--count);
  }

  .pf-v5-c-description-list.pf-m-2-col-on-sm {
    --pf-v5-c-description-list--GridTemplateColumns--count: var(--pf-v5-c-description-list--m-2-col--GridTemplateColumns--count);
  }

  .pf-v5-c-description-list.pf-m-3-col-on-sm {
    --pf-v5-c-description-list--GridTemplateColumns--count: var(--pf-v5-c-description-list--m-3-col--GridTemplateColumns--count);
  }

  .pf-v5-c-description-list.pf-m-horizontal-on-sm {
    --pf-v5-c-description-list__group--GridTemplateColumns: var(--pf-v5-c-description-list--m-horizontal__group--GridTemplateColumns);
    --pf-v5-c-description-list__group--GridTemplateRows: var(--pf-v5-c-description-list--m-horizontal__group--GridTemplateRows);
  }

  .pf-v5-c-description-list.pf-m-vertical-on-sm {
    --pf-v5-c-description-list__group--GridTemplateColumns: var(--pf-v5-c-description-list--m-vertical__group--GridTemplateColumns);
    --pf-v5-c-description-list__group--GridTemplateRows: var(--pf-v5-c-description-list--m-vertical__group--GridTemplateRows);
  }
}

@media (min-width: 768px) {
  .pf-v5-c-description-list.pf-m-1-col-on-md {
    --pf-v5-c-description-list--GridTemplateColumns--count: var(--pf-v5-c-description-list--m-1-col--GridTemplateColumns--count);
  }

  .pf-v5-c-description-list.pf-m-2-col-on-md {
    --pf-v5-c-description-list--GridTemplateColumns--count: var(--pf-v5-c-description-list--m-2-col--GridTemplateColumns--count);
  }

  .pf-v5-c-description-list.pf-m-3-col-on-md {
    --pf-v5-c-description-list--GridTemplateColumns--count: var(--pf-v5-c-description-list--m-3-col--GridTemplateColumns--count);
  }

  .pf-v5-c-description-list.pf-m-horizontal-on-md {
    --pf-v5-c-description-list__group--GridTemplateColumns: var(--pf-v5-c-description-list--m-horizontal__group--GridTemplateColumns);
    --pf-v5-c-description-list__group--GridTemplateRows: var(--pf-v5-c-description-list--m-horizontal__group--GridTemplateRows);
  }

  .pf-v5-c-description-list.pf-m-vertical-on-md {
    --pf-v5-c-description-list__group--GridTemplateColumns: var(--pf-v5-c-description-list--m-vertical__group--GridTemplateColumns);
    --pf-v5-c-description-list__group--GridTemplateRows: var(--pf-v5-c-description-list--m-vertical__group--GridTemplateRows);
  }
}

@media (min-width: 992px) {
  .pf-v5-c-description-list.pf-m-1-col-on-lg {
    --pf-v5-c-description-list--GridTemplateColumns--count: var(--pf-v5-c-description-list--m-1-col--GridTemplateColumns--count);
  }

  .pf-v5-c-description-list.pf-m-2-col-on-lg {
    --pf-v5-c-description-list--GridTemplateColumns--count: var(--pf-v5-c-description-list--m-2-col--GridTemplateColumns--count);
  }

  .pf-v5-c-description-list.pf-m-3-col-on-lg {
    --pf-v5-c-description-list--GridTemplateColumns--count: var(--pf-v5-c-description-list--m-3-col--GridTemplateColumns--count);
  }

  .pf-v5-c-description-list.pf-m-horizontal-on-lg {
    --pf-v5-c-description-list__group--GridTemplateColumns: var(--pf-v5-c-description-list--m-horizontal__group--GridTemplateColumns);
    --pf-v5-c-description-list__group--GridTemplateRows: var(--pf-v5-c-description-list--m-horizontal__group--GridTemplateRows);
  }

  .pf-v5-c-description-list.pf-m-vertical-on-lg {
    --pf-v5-c-description-list__group--GridTemplateColumns: var(--pf-v5-c-description-list--m-vertical__group--GridTemplateColumns);
    --pf-v5-c-description-list__group--GridTemplateRows: var(--pf-v5-c-description-list--m-vertical__group--GridTemplateRows);
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-description-list.pf-m-1-col-on-xl {
    --pf-v5-c-description-list--GridTemplateColumns--count: var(--pf-v5-c-description-list--m-1-col--GridTemplateColumns--count);
  }

  .pf-v5-c-description-list.pf-m-2-col-on-xl {
    --pf-v5-c-description-list--GridTemplateColumns--count: var(--pf-v5-c-description-list--m-2-col--GridTemplateColumns--count);
  }

  .pf-v5-c-description-list.pf-m-3-col-on-xl {
    --pf-v5-c-description-list--GridTemplateColumns--count: var(--pf-v5-c-description-list--m-3-col--GridTemplateColumns--count);
  }

  .pf-v5-c-description-list.pf-m-horizontal-on-xl {
    --pf-v5-c-description-list__group--GridTemplateColumns: var(--pf-v5-c-description-list--m-horizontal__group--GridTemplateColumns);
    --pf-v5-c-description-list__group--GridTemplateRows: var(--pf-v5-c-description-list--m-horizontal__group--GridTemplateRows);
  }

  .pf-v5-c-description-list.pf-m-vertical-on-xl {
    --pf-v5-c-description-list__group--GridTemplateColumns: var(--pf-v5-c-description-list--m-vertical__group--GridTemplateColumns);
    --pf-v5-c-description-list__group--GridTemplateRows: var(--pf-v5-c-description-list--m-vertical__group--GridTemplateRows);
  }
}

@media (min-width: 1450px) {
  .pf-v5-c-description-list.pf-m-1-col-on-2xl {
    --pf-v5-c-description-list--GridTemplateColumns--count: var(--pf-v5-c-description-list--m-1-col--GridTemplateColumns--count);
  }

  .pf-v5-c-description-list.pf-m-2-col-on-2xl {
    --pf-v5-c-description-list--GridTemplateColumns--count: var(--pf-v5-c-description-list--m-2-col--GridTemplateColumns--count);
  }

  .pf-v5-c-description-list.pf-m-3-col-on-2xl {
    --pf-v5-c-description-list--GridTemplateColumns--count: var(--pf-v5-c-description-list--m-3-col--GridTemplateColumns--count);
  }

  .pf-v5-c-description-list.pf-m-horizontal-on-2xl {
    --pf-v5-c-description-list__group--GridTemplateColumns: var(--pf-v5-c-description-list--m-horizontal__group--GridTemplateColumns);
    --pf-v5-c-description-list__group--GridTemplateRows: var(--pf-v5-c-description-list--m-horizontal__group--GridTemplateRows);
  }

  .pf-v5-c-description-list.pf-m-vertical-on-2xl {
    --pf-v5-c-description-list__group--GridTemplateColumns: var(--pf-v5-c-description-list--m-vertical__group--GridTemplateColumns);
    --pf-v5-c-description-list__group--GridTemplateRows: var(--pf-v5-c-description-list--m-vertical__group--GridTemplateRows);
  }
}

.pf-v5-c-draggable {
  --pf-v5-c-draggable--Cursor: auto;
  --pf-v5-c-draggable--m-dragging--Cursor: grabbing;
  --pf-v5-c-draggable--m-dragging--BoxShadow: var(--pf-v5-global--BoxShadow--md);
  --pf-v5-c-draggable--m-dragging--after--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-draggable--m-dragging--after--BorderColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-draggable--m-drag-outside--Cursor: not-allowed;
  --pf-v5-c-draggable--m-drag-outside--after--BorderColor: var(--pf-v5-global--danger-color--100);
  cursor: var(--pf-v5-c-draggable--Cursor);
}

.pf-v5-c-draggable.pf-m-dragging {
  --pf-v5-c-draggable--Cursor: var(--pf-v5-c-draggable--m-dragging--Cursor);
  position: relative;
  background-color: var(--pf-v5-c-draggable--m-dragging--BackgroundColor);
  box-shadow: var(--pf-v5-c-draggable--m-dragging--BoxShadow);
}

.pf-v5-c-draggable.pf-m-dragging::after {
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  content: "";
  border: var(--pf-v5-c-draggable--m-dragging--after--BorderWidth) solid var(--pf-v5-c-draggable--m-dragging--after--BorderColor);
}

.pf-v5-c-draggable.pf-m-drag-outside {
  --pf-v5-c-draggable--m-dragging--Cursor: var(--pf-v5-c-draggable--m-drag-outside--Cursor);
  --pf-v5-c-draggable--m-dragging--after--BorderColor: var(--pf-v5-c-draggable--m-drag-outside--after--BorderColor);
}

.pf-v5-c-droppable {
  --pf-v5-c-droppable--before--BackgroundColor: transparent;
  --pf-v5-c-droppable--before--Opacity: 0;
  --pf-v5-c-droppable--after--BorderWidth: 0;
  --pf-v5-c-droppable--after--BorderColor: transparent;
  --pf-v5-c-droppable--m-dragging--before--BackgroundColor: var(--pf-v5-global--palette--white);
  --pf-v5-c-droppable--m-dragging--before--Opacity: .6;
  --pf-v5-c-droppable--m-dragging--after--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-droppable--m-dragging--after--BorderColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-droppable--m-drag-outside--after--BorderColor: var(--pf-v5-global--danger-color--100);
}

.pf-v5-c-droppable::before,
.pf-v5-c-droppable::after {
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  pointer-events: none;
  content: "";
}

.pf-v5-c-droppable::before {
  background-color: var(--pf-v5-c-droppable--before--BackgroundColor);
  opacity: var(--pf-v5-c-droppable--before--Opacity);
}

.pf-v5-c-droppable::after {
  border: var(--pf-v5-c-droppable--after--BorderWidth, 0) solid var(--pf-v5-c-droppable--after--BorderColor, transparent);
}

.pf-v5-c-droppable.pf-m-dragging {
  --pf-v5-c-droppable--before--BackgroundColor: var(--pf-v5-c-droppable--m-dragging--before--BackgroundColor);
  --pf-v5-c-droppable--before--Opacity: var(--pf-v5-c-droppable--m-dragging--before--Opacity);
  --pf-v5-c-droppable--after--BorderWidth: var(--pf-v5-c-droppable--m-dragging--after--BorderWidth);
  --pf-v5-c-droppable--after--BorderColor: var(--pf-v5-c-droppable--m-dragging--after--BorderColor);
  position: relative;
}

.pf-v5-c-droppable.pf-m-drag-outside {
  --pf-v5-c-droppable--m-dragging--after--BorderColor: var(--pf-v5-c-droppable--m-drag-outside--after--BorderColor);
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-droppable {
  --pf-v5-c-droppable--m-dragging--before--Opacity: .2;
}

.pf-v5-c-dual-list-selector {
  --pf-v5-c-dual-list-selector__header--GridArea: pane-header;
  --pf-v5-c-dual-list-selector__tools--GridArea: pane-tools;
  --pf-v5-c-dual-list-selector__status--GridArea: pane-status;
  --pf-v5-c-dual-list-selector__menu--GridArea: pane-menu;
  --pf-v5-c-dual-list-selector__controls--GridArea: controls;
  --pf-v5-c-dual-list-selector--m-chosen__header--GridArea: pane-header-c;
  --pf-v5-c-dual-list-selector--m-chosen__tools--GridArea: pane-tools-c;
  --pf-v5-c-dual-list-selector--m-chosen__status--GridArea: pane-status-c;
  --pf-v5-c-dual-list-selector--m-chosen__menu--GridArea: pane-menu-c;
  --pf-v5-c-dual-list-selector--GridTemplateColumns--pane--MinMax--min: 12.5rem;
  --pf-v5-c-dual-list-selector--GridTemplateColumns--pane--MinMax--max: 28.125rem;
  --pf-v5-c-dual-list-selector__header--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dual-list-selector__title-text--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-dual-list-selector__tools--MarginBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-dual-list-selector__tools-filter--tools-actions--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dual-list-selector__menu--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-dual-list-selector__menu--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-dual-list-selector__menu--MinHeight: 12.5rem;
  --pf-v5-c-dual-list-selector__menu--MaxHeight: 20rem;
  --pf-v5-c-dual-list-selector__list-item-row--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-dual-list-selector__list-item-row--BackgroundColor: transparent;
  --pf-v5-c-dual-list-selector__list-item-row--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-dual-list-selector__list-item-row--focus-within--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-dual-list-selector__list-item-row--m-selected--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-dual-list-selector__list-item--m-ghost-row--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-dual-list-selector__list-item--m-ghost-row--Opacity: .4;
  --pf-v5-c-dual-list-selector__item--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dual-list-selector__item--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-dual-list-selector__item--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dual-list-selector__item--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-dual-list-selector__item--m-expandable--PaddingLeft: 0;
  --pf-v5-c-dual-list-selector__item--indent--base: calc(var(--pf-v5-global--spacer--md) + var(--pf-v5-global--spacer--sm) + var(--pf-v5-c-dual-list-selector__list-item-row--FontSize));
  --pf-v5-c-dual-list-selector__item--nested-indent--base: calc(var(--pf-v5-c-dual-list-selector__item--indent--base) - var(--pf-v5-global--spacer--md));
  --pf-v5-c-dual-list-selector__draggable--item--PaddingLeft: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-dual-list-selector__item-text--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-dual-list-selector__list-item-row--m-selected__text--Color: var(--pf-v5-global--active-color--100);
  --pf-v5-c-dual-list-selector__list-item-row--m-selected__text--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-dual-list-selector__list-item--m-disabled__item-text--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-dual-list-selector__status--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dual-list-selector__status-text--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-dual-list-selector__status-text--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-dual-list-selector__controls--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-dual-list-selector__controls--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-dual-list-selector__item-toggle--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dual-list-selector__item-toggle--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dual-list-selector__item-toggle--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dual-list-selector__item-toggle--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-dual-list-selector__item-toggle--MarginTop: calc(var(--pf-v5-global--spacer--sm) * -1);
  --pf-v5-c-dual-list-selector__item-toggle--MarginBottom: calc(var(--pf-v5-global--spacer--sm) * -1);
  --pf-v5-c-dual-list-selector__list__list__item-toggle--Left: 0;
  --pf-v5-c-dual-list-selector__list__list__item-toggle--TranslateX: -100%;
  --pf-v5-c-dual-list-selector__item-check--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dual-list-selector__item-count--Marginleft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dual-list-selector__item--c-badge--m-read--BackgroundColor: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-dual-list-selector__item-toggle-icon--Rotate: 0;
  --pf-v5-c-dual-list-selector__list-item--m-expanded__item-toggle-icon--Rotate: 90deg;
  --pf-v5-c-dual-list-selector__item-toggle-icon--Transition: var(--pf-v5-global--Transition);
  --pf-v5-c-dual-list-selector__item-toggle-icon--MinWidth: var(--pf-v5-c-dual-list-selector__list-item-row--FontSize);
  --pf-v5-c-dual-list-selector__list-item--m-disabled__item-toggle-icon--Color: var(--pf-v5-global--disabled-color--200);
  display: grid;
  grid-template-areas: "pane-header . pane-header-c" "pane-tools . pane-tools-c" "pane-status . pane-status-c" "pane-menu controls pane-menu-c";
  grid-template-rows: repeat(3, auto) auto;
  grid-template-columns: minmax(var(--pf-v5-c-dual-list-selector--GridTemplateColumns--pane--MinMax--min), var(--pf-v5-c-dual-list-selector--GridTemplateColumns--pane--MinMax--max)) min-content minmax(var(--pf-v5-c-dual-list-selector--GridTemplateColumns--pane--MinMax--min), var(--pf-v5-c-dual-list-selector--GridTemplateColumns--pane--MinMax--max));
}

.pf-v5-c-dual-list-selector__pane {
  display: contents;
}

.pf-v5-c-dual-list-selector__pane.pf-m-chosen {
  --pf-v5-c-dual-list-selector__header--GridArea: var(--pf-v5-c-dual-list-selector--m-chosen__header--GridArea);
  --pf-v5-c-dual-list-selector__tools--GridArea: var(--pf-v5-c-dual-list-selector--m-chosen__tools--GridArea);
  --pf-v5-c-dual-list-selector__status--GridArea: var(--pf-v5-c-dual-list-selector--m-chosen__status--GridArea);
  --pf-v5-c-dual-list-selector__menu--GridArea: var(--pf-v5-c-dual-list-selector--m-chosen__menu--GridArea);
}

.pf-v5-c-dual-list-selector__header {
  grid-area: var(--pf-v5-c-dual-list-selector__header--GridArea);
  margin-block-end: var(--pf-v5-c-dual-list-selector__header--MarginBottom);
}

.pf-v5-c-dual-list-selector__title-text {
  font-weight: var(--pf-v5-c-dual-list-selector__title-text--FontWeight);
}

.pf-v5-c-dual-list-selector__tools {
  display: flex;
  grid-area: var(--pf-v5-c-dual-list-selector__tools--GridArea);
  margin-block-end: var(--pf-v5-c-dual-list-selector__tools--MarginBottom);
}

.pf-v5-c-dual-list-selector__tools-filter {
  flex-grow: 1;
}

.pf-v5-c-dual-list-selector__tools-actions {
  display: flex;
}

.pf-v5-c-dual-list-selector__tools-filter~.pf-v5-c-dual-list-selector__tools-actions {
  margin-inline-start: var(--pf-v5-c-dual-list-selector__tools-filter--tools-actions--MarginLeft);
}

.pf-v5-c-dual-list-selector__status {
  display: flex;
  grid-area: var(--pf-v5-c-dual-list-selector__status--GridArea);
  margin-block-end: var(--pf-v5-c-dual-list-selector__status--MarginBottom);
}

.pf-v5-c-dual-list-selector__status-text {
  flex-grow: 1;
  font-size: var(--pf-v5-c-dual-list-selector__status-text--FontSize);
  color: var(--pf-v5-c-dual-list-selector__status-text--Color);
}

.pf-v5-c-dual-list-selector__menu {
  grid-area: var(--pf-v5-c-dual-list-selector__menu--GridArea);
  min-height: var(--pf-v5-c-dual-list-selector__menu--MinHeight);
  max-height: var(--pf-v5-c-dual-list-selector__menu--MaxHeight);
  overflow: auto;
  border: var(--pf-v5-c-dual-list-selector__menu--BorderWidth) solid var(--pf-v5-c-dual-list-selector__menu--BorderColor);
}

.pf-v5-c-dual-list-selector__list {
  --pf-v5-c-dual-list-selector__item-toggle-icon--Rotate: 0;
  display: flex;
  flex-direction: column;
}

.pf-v5-c-dual-list-selector__list .pf-v5-c-dual-list-selector__list {
  --pf-v5-c-dual-list-selector__item-toggle--MarginTop: 0;
  --pf-v5-c-dual-list-selector__item-toggle--MarginBottom: 0;
}

.pf-v5-c-dual-list-selector__list .pf-v5-c-dual-list-selector__list .pf-v5-c-dual-list-selector__item-toggle {
  transform: translateX(var(--pf-v5-c-dual-list-selector__list__list__item-toggle--TranslateX));
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: var(--pf-v5-c-dual-list-selector__list__list__item-toggle--Left);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-dual-list-selector__list .pf-v5-c-dual-list-selector__list .pf-v5-c-dual-list-selector__item-toggle {
  transform: translateX(calc(var(--pf-v5-c-dual-list-selector__list__list__item-toggle--TranslateX) * var(--pf-v5-global--inverse--multiplier)));
}

.pf-v5-c-dual-list-selector__list.pf-m-drag-over {
  overflow-anchor: none;
}

.pf-v5-c-dual-list-selector__list-item:focus {
  --pf-v5-c-dual-list-selector__list-item-row--BackgroundColor: var(--pf-v5-c-dual-list-selector__list-item-row--focus-within--BackgroundColor);
}

.pf-v5-c-dual-list-selector__list-item.pf-m-expandable {
  --pf-v5-c-dual-list-selector__item--PaddingLeft: var(--pf-v5-c-dual-list-selector__item--m-expandable--PaddingLeft);
}

.pf-v5-c-dual-list-selector__list-item.pf-m-expanded {
  --pf-v5-c-dual-list-selector__item-toggle-icon--Rotate: var(--pf-v5-c-dual-list-selector__list-item--m-expanded__item-toggle-icon--Rotate);
}

.pf-v5-c-dual-list-selector__list-item.pf-m-disabled {
  --pf-v5-c-dual-list-selector__item-text--Color: var(--pf-v5-c-dual-list-selector__list-item--m-disabled__item-text--Color);
  --pf-v5-c-dual-list-selector__item-toggle-icon--Color: var(--pf-v5-c-dual-list-selector__list-item--m-disabled__item-toggle-icon--Color);
  pointer-events: none;
}

.pf-v5-c-dual-list-selector__list-item-row {
  display: flex;
  font-size: var(--pf-v5-c-dual-list-selector__list-item-row--FontSize);
  background-color: var(--pf-v5-c-dual-list-selector__list-item-row--BackgroundColor);
}

.pf-v5-c-dual-list-selector__list-item-row:hover {
  --pf-v5-c-dual-list-selector__list-item-row--BackgroundColor: var(--pf-v5-c-dual-list-selector__list-item-row--hover--BackgroundColor);
}

.pf-v5-c-dual-list-selector__list-item-row.pf-m-selected {
  --pf-v5-c-dual-list-selector__list-item-row--BackgroundColor: var(--pf-v5-c-dual-list-selector__list-item-row--m-selected--BackgroundColor);
}

.pf-v5-c-dual-list-selector__list-item-row.pf-m-selected .pf-v5-c-dual-list-selector__item-text {
  --pf-v5-c-dual-list-selector__item-text--Color: var(--pf-v5-c-dual-list-selector__list-item-row--m-selected__text--Color);
  font-weight: var(--pf-v5-c-dual-list-selector__list-item-row--m-selected__text--FontWeight);
}

.pf-v5-c-dual-list-selector__list-item-row.pf-m-check {
  --pf-v5-c-dual-list-selector__list-item-row--m-selected--BackgroundColor: transparent;
}

.pf-v5-c-dual-list-selector__list-item-row.pf-m-ghost-row {
  --pf-v5-c-dual-list-selector__list-item-row--BackgroundColor: var(--pf-v5-c-dual-list-selector__list-item--m-ghost-row--BackgroundColor);
  opacity: var(--pf-v5-c-dual-list-selector__list-item--m-ghost-row--Opacity);
}

.pf-v5-c-dual-list-selector__item,
.pf-v5-c-dual-list-selector__main {
  display: flex;
}

.pf-v5-c-dual-list-selector__item,
.pf-v5-c-dual-list-selector__item-main {
  flex-basis: 100%;
}

.pf-v5-c-dual-list-selector__draggable {
  display: flex;
}

.pf-v5-c-dual-list-selector__draggable+.pf-v5-c-dual-list-selector__item {
  --pf-v5-c-dual-list-selector__item--PaddingLeft: var(--pf-v5-c-dual-list-selector__draggable--item--PaddingLeft);
}

.pf-v5-c-dual-list-selector__draggable .pf-v5-c-button {
  --pf-v5-c-button--FontSize: inherit;
  cursor: grab;
}

.pf-v5-c-dual-list-selector__draggable .pf-v5-c-button:active {
  cursor: grabbing;
}

.pf-v5-c-dual-list-selector__item {
  position: relative;
  width: 100%;
  padding-block-start: var(--pf-v5-c-dual-list-selector__item--PaddingTop);
  padding-block-end: var(--pf-v5-c-dual-list-selector__item--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-dual-list-selector__item--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-dual-list-selector__item--PaddingRight);
  cursor: pointer;
}

.pf-v5-c-dual-list-selector__item-count {
  margin-inline-start: var(--pf-v5-c-dual-list-selector__item-count--Marginleft);
}

.pf-v5-c-dual-list-selector__item-count .pf-v5-c-badge.pf-m-read {
  --pf-v5-c-badge--m-read--BackgroundColor: var(--pf-v5-c-dual-list-selector__item--c-badge--m-read--BackgroundColor);
}

.pf-v5-c-dual-list-selector__item-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
  color: var(--pf-v5-c-dual-list-selector__item-text--Color);
}

.pf-v5-c-dual-list-selector__controls {
  grid-area: var(--pf-v5-c-dual-list-selector__controls--GridArea);
  align-self: center;
  padding-inline-start: var(--pf-v5-c-dual-list-selector__controls--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-dual-list-selector__controls--PaddingRight);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) :is(.pf-v5-c-dual-list-selector__controls-item,.pf-v5-c-dual-list-selector__item-toggle-icon) {
  scale: -1 1;
}

.pf-v5-c-dual-list-selector__item-main {
  display: flex;
  min-width: 0;
}

.pf-v5-c-dual-list-selector__item-toggle {
  padding-block-start: var(--pf-v5-c-dual-list-selector__item-toggle--PaddingTop);
  padding-block-end: var(--pf-v5-c-dual-list-selector__item-toggle--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-dual-list-selector__item-toggle--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-dual-list-selector__item-toggle--PaddingRight);
  margin-block-start: var(--pf-v5-c-dual-list-selector__item-toggle--MarginTop);
  margin-block-end: var(--pf-v5-c-dual-list-selector__item-toggle--MarginBottom);
}

.pf-v5-c-dual-list-selector__item-check {
  display: flex;
  align-items: center;
  margin-inline-end: var(--pf-v5-c-dual-list-selector__item-check--MarginRight);
}

.pf-v5-c-dual-list-selector__item-toggle-icon {
  display: inline-block;
  min-width: var(--pf-v5-c-dual-list-selector__item-toggle-icon--MinWidth);
  color: var(--pf-v5-c-dual-list-selector__item-toggle-icon--Color, inherit);
  text-align: center;
  transition: var(--pf-v5-c-dual-list-selector__item-toggle-icon--Transition);
  transform: rotate(var(--pf-v5-c-dual-list-selector__item-toggle-icon--Rotate));
}

.pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item {
  --pf-v5-c-dual-list-selector__item--PaddingLeft: calc(var(--pf-v5-c-dual-list-selector__item--nested-indent--base) * 1 + var(--pf-v5-c-dual-list-selector__item--indent--base));
  --pf-v5-c-dual-list-selector__list__list__item-toggle--Left: var(--pf-v5-c-dual-list-selector__item--PaddingLeft);
}

.pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item {
  --pf-v5-c-dual-list-selector__item--PaddingLeft: calc(var(--pf-v5-c-dual-list-selector__item--nested-indent--base) * 2 + var(--pf-v5-c-dual-list-selector__item--indent--base));
  --pf-v5-c-dual-list-selector__list__list__item-toggle--Left: var(--pf-v5-c-dual-list-selector__item--PaddingLeft);
}

.pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item {
  --pf-v5-c-dual-list-selector__item--PaddingLeft: calc(var(--pf-v5-c-dual-list-selector__item--nested-indent--base) * 3 + var(--pf-v5-c-dual-list-selector__item--indent--base));
  --pf-v5-c-dual-list-selector__list__list__item-toggle--Left: var(--pf-v5-c-dual-list-selector__item--PaddingLeft);
}

.pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item {
  --pf-v5-c-dual-list-selector__item--PaddingLeft: calc(var(--pf-v5-c-dual-list-selector__item--nested-indent--base) * 4 + var(--pf-v5-c-dual-list-selector__item--indent--base));
  --pf-v5-c-dual-list-selector__list__list__item-toggle--Left: var(--pf-v5-c-dual-list-selector__item--PaddingLeft);
}

.pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item {
  --pf-v5-c-dual-list-selector__item--PaddingLeft: calc(var(--pf-v5-c-dual-list-selector__item--nested-indent--base) * 5 + var(--pf-v5-c-dual-list-selector__item--indent--base));
  --pf-v5-c-dual-list-selector__list__list__item-toggle--Left: var(--pf-v5-c-dual-list-selector__item--PaddingLeft);
}

.pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item {
  --pf-v5-c-dual-list-selector__item--PaddingLeft: calc(var(--pf-v5-c-dual-list-selector__item--nested-indent--base) * 6 + var(--pf-v5-c-dual-list-selector__item--indent--base));
  --pf-v5-c-dual-list-selector__list__list__item-toggle--Left: var(--pf-v5-c-dual-list-selector__item--PaddingLeft);
}

.pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item {
  --pf-v5-c-dual-list-selector__item--PaddingLeft: calc(var(--pf-v5-c-dual-list-selector__item--nested-indent--base) * 7 + var(--pf-v5-c-dual-list-selector__item--indent--base));
  --pf-v5-c-dual-list-selector__list__list__item-toggle--Left: var(--pf-v5-c-dual-list-selector__item--PaddingLeft);
}

.pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item {
  --pf-v5-c-dual-list-selector__item--PaddingLeft: calc(var(--pf-v5-c-dual-list-selector__item--nested-indent--base) * 8 + var(--pf-v5-c-dual-list-selector__item--indent--base));
  --pf-v5-c-dual-list-selector__list__list__item-toggle--Left: var(--pf-v5-c-dual-list-selector__item--PaddingLeft);
}

.pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item {
  --pf-v5-c-dual-list-selector__item--PaddingLeft: calc(var(--pf-v5-c-dual-list-selector__item--nested-indent--base) * 9 + var(--pf-v5-c-dual-list-selector__item--indent--base));
  --pf-v5-c-dual-list-selector__list__list__item-toggle--Left: var(--pf-v5-c-dual-list-selector__item--PaddingLeft);
}

.pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item .pf-v5-c-dual-list-selector__list-item {
  --pf-v5-c-dual-list-selector__item--PaddingLeft: calc(var(--pf-v5-c-dual-list-selector__item--nested-indent--base) * 10 + var(--pf-v5-c-dual-list-selector__item--indent--base));
  --pf-v5-c-dual-list-selector__list__list__item-toggle--Left: var(--pf-v5-c-dual-list-selector__item--PaddingLeft);
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-dual-list-selector {
  --pf-v5-c-dual-list-selector__list-item-row--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-dual-list-selector__list-item-row--focus-within--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-dual-list-selector__list-item-row--m-selected--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
}

.pf-v5-c-toolbar {
  --pf-v5-c-toolbar--AlignItems--base: flex-start;
  --pf-v5-c-toolbar--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-toolbar--RowGap--base: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-toolbar--RowGap: var(--pf-v5-c-toolbar--RowGap--base);
  --pf-v5-c-toolbar--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-toolbar--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-toolbar--item--RowGap--base: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-toolbar__item--Display: flex;
  --pf-v5-c-toolbar__item--MinWidth--base: auto;
  --pf-v5-c-toolbar__item--AlignItems: var(--pf-v5-c-toolbar--AlignItems--base);
  --pf-v5-c-toolbar__item--AlignSelf: var(--pf-v5-c-toolbar--AlignItems--base);
  --pf-v5-c-toolbar__group--Display: flex;
  --pf-v5-c-toolbar__group--RowGap: var(--pf-v5-c-toolbar--item--RowGap--base);
  --pf-v5-c-toolbar__group--AlignItems: var(--pf-v5-c-toolbar--AlignItems--base);
  --pf-v5-c-toolbar__group--AlignSelf: auto;
  --pf-v5-c-toolbar--m-sticky--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-toolbar--m-sticky--BoxShadow: var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-toolbar--m-align-items-center--AlignItems: center;
  --pf-v5-c-toolbar--m-align-items-baseline--AlignItems: baseline;
  --pf-v5-c-toolbar--m-align-self-center--AlignSelf: center;
  --pf-v5-c-toolbar--m-align-self-baseline--AlignSelf: baseline;
  --pf-v5-c-toolbar__content--Display: flex;
  --pf-v5-c-toolbar__content--AlignItems: var(--pf-v5-c-toolbar--AlignItems--base);
  --pf-v5-c-toolbar__content--RowGap: var(--pf-v5-c-toolbar--RowGap--base);
  --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-toolbar__content-section--Display: flex;
  --pf-v5-c-toolbar__content-section--AlignItems: var(--pf-v5-c-toolbar--AlignItems--base);
  --pf-v5-c-toolbar__content-section--RowGap: var(--pf-v5-c-toolbar--item--RowGap--base);
  --pf-v5-c-toolbar--m-page-insets--inset: var(--pf-v5-global--spacer--md);
  --pf-v5-c-toolbar--m-page-insets--xl--inset: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-toolbar__expandable-content--Display: grid;
  --pf-v5-c-toolbar__expandable-content--PaddingTop: 0;
  --pf-v5-c-toolbar__expandable-content--PaddingRight: var(--pf-v5-c-toolbar__content--PaddingRight);
  --pf-v5-c-toolbar__expandable-content--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-toolbar__expandable-content--PaddingLeft: var(--pf-v5-c-toolbar__content--PaddingLeft);
  --pf-v5-c-toolbar__expandable-content--lg--PaddingRight: 0;
  --pf-v5-c-toolbar__expandable-content--lg--PaddingBottom: 0;
  --pf-v5-c-toolbar__expandable-content--lg--PaddingLeft: 0;
  --pf-v5-c-toolbar__expandable-content--ZIndex: var(--pf-v5-global--ZIndex--sm);
  --pf-v5-c-toolbar__expandable-content--BoxShadow: var(--pf-v5-global--BoxShadow--md-bottom);
  --pf-v5-c-toolbar__expandable-content--BackgroundColor: var(--pf-v5-c-toolbar--BackgroundColor);
  --pf-v5-c-toolbar__expandable-content--m-expanded--GridRowGap: var(--pf-v5-global--gutter--md);
  --pf-v5-c-toolbar__group--m-chip-container--MarginTop: calc(var(--pf-v5-global--spacer--md) * -1);
  --pf-v5-c-toolbar__group--m-chip-container__item--MarginTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-toolbar--spacer--base: var(--pf-v5-global--spacer--md);
  --pf-v5-c-toolbar__item--spacer: var(--pf-v5-c-toolbar--spacer--base);
  --pf-v5-c-toolbar__item--Width: auto;
  --pf-v5-c-toolbar__group--spacer: var(--pf-v5-c-toolbar--spacer--base);
  --pf-v5-c-toolbar__group--m-toggle-group--spacer: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-toolbar__group--m-toggle-group--m-show--spacer: var(--pf-v5-c-toolbar__group--spacer);
  --pf-v5-c-toolbar__group--m-icon-button-group--spacer: var(--pf-v5-c-toolbar__group--spacer);
  --pf-v5-c-toolbar__group--m-icon-button-group--space-items: 0;
  --pf-v5-c-toolbar__group--m-button-group--spacer: var(--pf-v5-c-toolbar__group--spacer);
  --pf-v5-c-toolbar__group--m-button-group--space-items: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-toolbar__group--m-filter-group--spacer: var(--pf-v5-c-toolbar__group--spacer);
  --pf-v5-c-toolbar__group--m-filter-group--space-items: 0;
  --pf-v5-c-toolbar__item--m-overflow-menu--spacer: var(--pf-v5-c-toolbar__item--spacer);
  --pf-v5-c-toolbar__item--m-bulk-select--spacer: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-toolbar__expand-all-icon--Rotate: 0;
  --pf-v5-c-toolbar__expand-all-icon--Transition: var(--pf-v5-global--Transition);
  --pf-v5-c-toolbar__item--m-expand-all--m-expanded__expand-all-icon--Rotate: 90deg;
  --pf-v5-c-toolbar__item--m-search-filter--spacer: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-toolbar__item--m-chip-group--spacer: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-toolbar__item--m-label--spacer: var(--pf-v5-c-toolbar__item--spacer);
  --pf-v5-c-toolbar__item--m-label--TranslateY: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-toolbar__item--m-label--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-toolbar__item--m-form-element--spacer: var(--pf-v5-c-toolbar__item--spacer);
  --pf-v5-c-toolbar__item--m-form-element--TranslateY: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-toolbar__item--m-form-element--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-toolbar__item--m-form-control--TranslateY: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-toolbar__expandable-content__item--m-label--MarginBottom: calc(-1 * var(--pf-v5-c-toolbar__expandable-content--m-expanded--GridRowGap) + var(--pf-v5-global--spacer--sm));
  --pf-v5-c-toolbar__expandable-content__item--m-label--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-toolbar__toggle--m-expanded__c-button--m-plain--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-toolbar--c-divider--m-vertical--spacer: var(--pf-v5-c-toolbar--spacer--base);
  --pf-v5-c-toolbar--m-full-height--PaddingTop: 0;
  --pf-v5-c-toolbar--m-full-height--PaddingBottom: 0;
  --pf-v5-c-toolbar--m-full-height__item--Display: flex;
  --pf-v5-c-toolbar--m-full-height__item--AlignItems: center;
  position: relative;
  display: grid;
  row-gap: var(--pf-v5-c-toolbar--RowGap);
  padding-block-start: var(--pf-v5-c-toolbar--PaddingTop);
  padding-block-end: var(--pf-v5-c-toolbar--PaddingBottom);
  background-color: var(--pf-v5-c-toolbar--BackgroundColor);
}

@media screen and (min-width: 992px) {
  .pf-v5-c-toolbar {
    --pf-v5-c-toolbar__expandable-content--PaddingRight: var(--pf-v5-c-toolbar__expandable-content--lg--PaddingRight);
    --pf-v5-c-toolbar__expandable-content--PaddingBottom: var(--pf-v5-c-toolbar__expandable-content--lg--PaddingBottom);
    --pf-v5-c-toolbar__expandable-content--PaddingLeft: var(--pf-v5-c-toolbar__expandable-content--lg--PaddingLeft);
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-c-toolbar {
    --pf-v5-c-toolbar--m-page-insets--inset: var(--pf-v5-c-toolbar--m-page-insets--xl--inset);
  }
}

.pf-v5-c-toolbar.pf-m-page-insets {
  --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--m-page-insets--inset);
  --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--m-page-insets--inset);
}

.pf-v5-c-toolbar.pf-m-sticky {
  position: sticky;
  inset-block-start: 0;
  z-index: var(--pf-v5-c-toolbar--m-sticky--ZIndex);
  box-shadow: var(--pf-v5-c-toolbar--m-sticky--BoxShadow);
}

.pf-v5-c-toolbar.pf-m-full-height {
  --pf-v5-c-toolbar--PaddingTop: var(--pf-v5-c-toolbar--m-full-height--PaddingTop);
  --pf-v5-c-toolbar--PaddingBottom: var(--pf-v5-c-toolbar--m-full-height--PaddingTop);
  --pf-v5-c-toolbar__item--Display: var(--pf-v5-c-toolbar--m-full-height__item--Display);
  height: 100%;
}

.pf-v5-c-toolbar.pf-m-full-height,
.pf-v5-c-toolbar.pf-m-full-height .pf-v5-c-toolbar__content,
.pf-v5-c-toolbar.pf-m-full-height .pf-v5-c-toolbar__content-section,
.pf-v5-c-toolbar.pf-m-full-height .pf-v5-c-toolbar__expandable-content,
.pf-v5-c-toolbar.pf-m-full-height .pf-v5-c-toolbar__group {
  min-width: 0;
}

.pf-v5-c-toolbar.pf-m-full-height,
.pf-v5-c-toolbar.pf-m-full-height .pf-v5-c-toolbar__content,
.pf-v5-c-toolbar.pf-m-full-height .pf-v5-c-toolbar__content-section,
.pf-v5-c-toolbar.pf-m-full-height .pf-v5-c-toolbar__group,
.pf-v5-c-toolbar.pf-m-full-height .pf-v5-c-toolbar__item {
  align-self: stretch;
}

.pf-v5-c-toolbar.pf-m-full-height :where(.pf-v5-c-toolbar__item) {
  --pf-v5-c-toolbar__item--AlignItems: var(--pf-v5-c-toolbar--m-full-height__item--AlignItems);
}

.pf-v5-c-toolbar.pf-m-static,
.pf-v5-c-toolbar.pf-m-static .pf-v5-c-toolbar__content {
  position: static;
}

.pf-v5-c-toolbar.pf-m-static .pf-v5-c-toolbar__expandable-content {
  position: absolute;
}

.pf-v5-c-toolbar__content-section>.pf-v5-c-divider,
.pf-v5-c-toolbar__group>.pf-v5-c-divider {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar--c-divider--m-vertical--spacer);
}

.pf-v5-c-toolbar__content-section>.pf-v5-c-divider.pf-m-vertical,
.pf-v5-c-toolbar__group>.pf-v5-c-divider.pf-m-vertical {
  margin-inline-end: var(--pf-v5-c-toolbar--spacer);
}

.pf-v5-c-toolbar__content-section>.pf-v5-c-divider.pf-m-vertical:last-child,
.pf-v5-c-toolbar__group>.pf-v5-c-divider.pf-m-vertical:last-child {
  --pf-v5-c-toolbar--spacer: 0;
}

.pf-v5-c-toolbar__content-section.pf-m-align-items-start,
.pf-v5-c-toolbar__group.pf-m-align-items-start {
  align-items: flex-start;
}

.pf-v5-c-toolbar__content-section.pf-m-align-items-center,
.pf-v5-c-toolbar__group.pf-m-align-items-center {
  --pf-v5-c-toolbar__group--AlignItems: var(--pf-v5-c-toolbar--m-align-items-center--AlignItems);
}

.pf-v5-c-toolbar__content-section.pf-m-align-items-baseline,
.pf-v5-c-toolbar__group.pf-m-align-items-baseline {
  --pf-v5-c-toolbar__group--AlignItems: var(--pf-v5-c-toolbar--m-align-items-baseline--AlignItems);
}

.pf-v5-c-toolbar__content-section.pf-m-align-self-start,
.pf-v5-c-toolbar__group.pf-m-align-self-start {
  align-self: flex-start;
}

.pf-v5-c-toolbar__content-section.pf-m-align-self-center,
.pf-v5-c-toolbar__group.pf-m-align-self-center {
  --pf-v5-c-toolbar__group--AlignSelf: var(--pf-v5-c-toolbar--m-align-self-center--AlignItems);
}

.pf-v5-c-toolbar__content-section.pf-m-align-self-baseline,
.pf-v5-c-toolbar__group.pf-m-align-self-baseline {
  --pf-v5-c-toolbar__group--AlignSelf: var(--pf-v5-c-toolbar--m-align-self-baseline--AlignItems);
}

.pf-v5-c-toolbar__group {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__group--spacer);
  --pf-v5-hidden-visible--visible--Display: var(--pf-v5-c-toolbar__group--Display);
  row-gap: var(--pf-v5-c-toolbar__group--RowGap);
  align-items: var(--pf-v5-c-toolbar__group--AlignItems);
  align-self: var(--pf-v5-c-toolbar__group--AlignSelf);
  margin-inline-end: var(--pf-v5-c-toolbar--spacer);
}

.pf-v5-c-toolbar__group.pf-m-button-group {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__group--m-button-group--spacer);
}

.pf-v5-c-toolbar__group.pf-m-button-group>* {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__group--m-button-group--space-items);
}

.pf-v5-c-toolbar__group.pf-m-icon-button-group {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__group--m-icon-button-group--spacer);
}

.pf-v5-c-toolbar__group.pf-m-icon-button-group>* {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__group--m-icon-button-group--space-items);
}

.pf-v5-c-toolbar__group.pf-m-filter-group {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__group--m-filter-group--spacer);
}

.pf-v5-c-toolbar__group.pf-m-filter-group>* {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__group--m-filter-group--space-items);
}

.pf-v5-c-toolbar__group.pf-m-filter-group>*+* {
  margin-inline-start: -1px;
}

.pf-v5-c-toolbar__group.pf-m-toggle-group {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__group--m-toggle-group--spacer);
}

.pf-v5-c-toolbar__group.pf-m-toggle-group .pf-v5-c-toolbar__group,
.pf-v5-c-toolbar__group.pf-m-toggle-group .pf-v5-c-toolbar__item {
  display: none;
}

.pf-v5-c-toolbar__group.pf-m-toggle-group .pf-v5-c-toolbar__toggle {
  display: inline-block;
}

.pf-v5-c-toolbar__group:last-child {
  --pf-v5-c-toolbar--spacer: 0;
}

.pf-v5-c-toolbar__item {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__item--spacer);
  --pf-v5-c-toolbar__item--Width--base: var(--pf-v5-c-toolbar__item--Width);
  --pf-v5-c-toolbar__item--MinWidth--base: var(--pf-v5-c-toolbar__item--MinWidth);
  --pf-v5-hidden-visible--visible--Display: var(--pf-v5-c-toolbar__item--Display);
  align-items: var(--pf-v5-c-toolbar__item--AlignItems);
  align-self: var(--pf-v5-c-toolbar__item--AlignSelf);
  width: var(--pf-v5-c-toolbar__item--Width--base);
  min-width: var(--pf-v5-c-toolbar__item--MinWidth--base);
  margin-inline-end: var(--pf-v5-c-toolbar--spacer);
}

@media (min-width: 576px) {
  .pf-v5-c-toolbar__item {
    --pf-v5-c-toolbar__item--Width--base: var(--pf-v5-c-toolbar__item--Width-on-sm, var(--pf-v5-c-toolbar__item--Width));
  }
}

@media (min-width: 768px) {
  .pf-v5-c-toolbar__item {
    --pf-v5-c-toolbar__item--Width--base: var(--pf-v5-c-toolbar__item--Width-on-md, var(--pf-v5-c-toolbar__item--Width-on-sm, var(--pf-v5-c-toolbar__item--Width)));
  }
}

@media (min-width: 992px) {
  .pf-v5-c-toolbar__item {
    --pf-v5-c-toolbar__item--Width--base: var(--pf-v5-c-toolbar__item--Width-on-lg, var(--pf-v5-c-toolbar__item--Width-on-md, var(--pf-v5-c-toolbar__item--Width-on-sm, var(--pf-v5-c-toolbar__item--Width))));
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-toolbar__item {
    --pf-v5-c-toolbar__item--Width--base: var(--pf-v5-c-toolbar__item--Width-on-xl, var(--pf-v5-c-toolbar__item--Width-on-lg, var(--pf-v5-c-toolbar__item--Width-on-md, var(--pf-v5-c-toolbar__item--Width-on-sm, var(--pf-v5-c-toolbar__item--Width)))));
  }
}

@media (min-width: 1450px) {
  .pf-v5-c-toolbar__item {
    --pf-v5-c-toolbar__item--Width--base: var(--pf-v5-c-toolbar__item--Width-on-2xl, var(--pf-v5-c-toolbar__item--Width-on-xl, var(--pf-v5-c-toolbar__item--Width-on-lg, var(--pf-v5-c-toolbar__item--Width-on-md, var(--pf-v5-c-toolbar__item--Width-on-sm, var(--pf-v5-c-toolbar__item--Width))))));
  }
}

@media (min-width: 576px) {
  .pf-v5-c-toolbar__item {
    --pf-v5-c-toolbar__item--MinWidth--base: var(--pf-v5-c-toolbar__item--MinWidth-on-sm, var(--pf-v5-c-toolbar__item--MinWidth));
  }
}

@media (min-width: 768px) {
  .pf-v5-c-toolbar__item {
    --pf-v5-c-toolbar__item--MinWidth--base: var(--pf-v5-c-toolbar__item--MinWidth-on-md, var(--pf-v5-c-toolbar__item--MinWidth-on-sm, var(--pf-v5-c-toolbar__item--MinWidth)));
  }
}

@media (min-width: 992px) {
  .pf-v5-c-toolbar__item {
    --pf-v5-c-toolbar__item--MinWidth--base: var(--pf-v5-c-toolbar__item--MinWidth-on-lg, var(--pf-v5-c-toolbar__item--MinWidth-on-md, var(--pf-v5-c-toolbar__item--MinWidth-on-sm, var(--pf-v5-c-toolbar__item--MinWidth))));
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-toolbar__item {
    --pf-v5-c-toolbar__item--MinWidth--base: var(--pf-v5-c-toolbar__item--MinWidth-on-xl, var(--pf-v5-c-toolbar__item--MinWidth-on-lg, var(--pf-v5-c-toolbar__item--MinWidth-on-md, var(--pf-v5-c-toolbar__item--MinWidth-on-sm, var(--pf-v5-c-toolbar__item--MinWidth)))));
  }
}

@media (min-width: 1450px) {
  .pf-v5-c-toolbar__item {
    --pf-v5-c-toolbar__item--MinWidth--base: var(--pf-v5-c-toolbar__item--MinWidth-on-2xl, var(--pf-v5-c-toolbar__item--MinWidth-on-xl, var(--pf-v5-c-toolbar__item--MinWidth-on-lg, var(--pf-v5-c-toolbar__item--MinWidth-on-md, var(--pf-v5-c-toolbar__item--MinWidth-on-sm, var(--pf-v5-c-toolbar__item--MinWidth))))));
  }
}

.pf-v5-c-toolbar__item.pf-m-align-items-start {
  align-items: flex-start;
}

.pf-v5-c-toolbar__item.pf-m-align-items-center {
  align-items: center;
}

.pf-v5-c-toolbar__item.pf-m-align-items-baseline {
  align-items: baseline;
}

.pf-v5-c-toolbar__item.pf-m-align-self-start {
  align-self: flex-start;
}

.pf-v5-c-toolbar__item.pf-m-align-self-center {
  align-self: center;
}

.pf-v5-c-toolbar__item.pf-m-align-self-baseline {
  align-self: baseline;
}

.pf-v5-c-toolbar__item.pf-m-overflow-menu {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__item--m-overflow-menu--spacer);
}

.pf-v5-c-toolbar__item.pf-m-bulk-select {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__item--m-bulk-select--spacer);
}

.pf-v5-c-toolbar__item.pf-m-expand-all.pf-m-expanded {
  --pf-v5-c-toolbar__expand-all-icon--Rotate: var(--pf-v5-c-toolbar__item--m-expand-all--m-expanded__expand-all-icon--Rotate);
}

.pf-v5-c-toolbar__item.pf-m-search-filter {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__item--m-search-filter--spacer);
}

.pf-v5-c-toolbar__item.pf-m-chip-group {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__item--m-chip-group--spacer);
}

.pf-v5-c-toolbar__item.pf-m-label {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__item--m-label--spacer);
  font-weight: var(--pf-v5-c-toolbar__item--m-label--FontWeight);
  transform: translateY(var(--pf-v5-c-toolbar__item--m-label--TranslateY));
}

.pf-v5-c-toolbar__item.pf-m-form-element {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__item--m-form-element--spacer);
  font-weight: var(--pf-v5-c-toolbar__item--m-form-element--FontWeight);
  transform: translateY(var(--pf-v5-c-toolbar__item--m-form-element--TranslateY));
}

.pf-v5-c-toolbar__item.pf-m-pagination {
  margin-inline-start: auto;
}

.pf-v5-c-toolbar__item.pf-m-pagination .pf-v5-c-pagination {
  flex-wrap: nowrap;
}

.pf-v5-c-toolbar__item:last-child {
  --pf-v5-c-toolbar--spacer: 0;
}

.pf-v5-c-toolbar__group.pf-m-overflow-container,
.pf-v5-c-toolbar__item.pf-m-overflow-container {
  flex: 1;
  overflow: hidden;
}

.pf-v5-c-toolbar__expand-all-icon {
  display: inline-block;
  transition: var(--pf-v5-c-toolbar__expand-all-icon--Transition);
  transform: rotate(var(--pf-v5-c-toolbar__expand-all-icon--Rotate));
}

.pf-v5-c-toolbar__content,
.pf-v5-c-toolbar__content-section {
  flex-wrap: wrap;
}

.pf-v5-c-toolbar__content {
  --pf-v5-hidden-visible--visible--Display: var(--pf-v5-c-toolbar__content--Display);
  position: relative;
  row-gap: var(--pf-v5-c-toolbar__content--RowGap);
  align-items: var(--pf-v5-c-toolbar__content--AlignItems);
  padding-inline-start: var(--pf-v5-c-toolbar__content--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-toolbar__content--PaddingRight);
}

.pf-v5-c-toolbar__content-section {
  --pf-v5-hidden-visible--visible--Display: var(--pf-v5-c-toolbar__content-section--Display);
  row-gap: var(--pf-v5-c-toolbar__content-section--RowGap);
  align-items: var(--pf-v5-c-toolbar__content-section--AlignItems);
  width: 100%;
}

.pf-v5-c-toolbar__content-section.pf-m-align-items-start {
  align-items: flex-start;
}

.pf-v5-c-toolbar__content-section.pf-m-align-items-center {
  --pf-v5-c-toolbar__content-section--AlignItems: var(--pf-v5-c-toolbar--m-align-items-center--AlignItems);
}

.pf-v5-c-toolbar__content-section.pf-m-align-items-baseline {
  align-items: baseline;
}

.pf-v5-c-toolbar__expandable-content {
  position: absolute;
  inset-block-start: calc(100% + var(--pf-v5-c-toolbar__content--RowGap));
  inset-inline-start: 0;
  inset-inline-end: 0;
  z-index: var(--pf-v5-c-toolbar__expandable-content--ZIndex);
  display: none;
  width: 100%;
  padding-block-start: var(--pf-v5-c-toolbar__expandable-content--PaddingTop);
  padding-block-end: var(--pf-v5-c-toolbar__expandable-content--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-toolbar__expandable-content--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-toolbar__expandable-content--PaddingRight);
  background-color: var(--pf-v5-c-toolbar__expandable-content--BackgroundColor);
  box-shadow: var(--pf-v5-c-toolbar__expandable-content--BoxShadow);
}

@media screen and (min-width: 992px) {
  .pf-v5-c-toolbar__expandable-content {
    position: static;
    box-shadow: none;
  }
}

.pf-v5-c-toolbar__expandable-content.pf-m-expanded {
  display: grid;
  grid-row-gap: var(--pf-v5-c-toolbar__expandable-content--m-expanded--GridRowGap);
}

.pf-v5-c-toolbar__expandable-content .pf-v5-c-toolbar__group,
.pf-v5-c-toolbar__expandable-content .pf-v5-c-toolbar__item {
  --pf-v5-c-toolbar--spacer: 0;
}

.pf-v5-c-toolbar__expandable-content .pf-v5-c-toolbar__group {
  display: grid;
  grid-row-gap: var(--pf-v5-c-toolbar__expandable-content--m-expanded--GridRowGap);
}

.pf-v5-c-toolbar__expandable-content .pf-v5-c-toolbar__item.pf-m-label {
  margin-block-end: var(--pf-v5-c-toolbar__expandable-content__item--m-label--MarginBottom);
  font-size: var(--pf-v5-c-toolbar__expandable-content__item--m-label--FontSize);
}

.pf-v5-c-toolbar__content.pf-m-chip-container,
.pf-v5-c-toolbar__group.pf-m-chip-container {
  display: flex;
  flex-wrap: wrap;
  grid-row-gap: 0;
  align-items: baseline;
  margin-block-start: var(--pf-v5-c-toolbar__group--m-chip-container--MarginTop);
}

.pf-v5-c-toolbar__content.pf-m-chip-container .pf-v5-c-toolbar__item,
.pf-v5-c-toolbar__group.pf-m-chip-container .pf-v5-c-toolbar__item {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__item--spacer);
  --pf-v5-c-toolbar__item--AlignSelf: auto;
  margin-block-start: var(--pf-v5-c-toolbar__group--m-chip-container__item--MarginTop);
}

.pf-v5-c-toolbar__content.pf-m-chip-container .pf-v5-c-toolbar__group,
.pf-v5-c-toolbar__group.pf-m-chip-container .pf-v5-c-toolbar__group {
  --pf-v5-c-toolbar__group--AlignItems: center;
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__group--spacer);
  display: flex;
  flex-wrap: wrap;
  grid-row-gap: 0;
}

.pf-v5-c-toolbar__content.pf-m-chip-container .pf-v5-c-toolbar__group:last-child,
.pf-v5-c-toolbar__content.pf-m-chip-container .pf-v5-c-toolbar__item:last-child,
.pf-v5-c-toolbar__group.pf-m-chip-container .pf-v5-c-toolbar__group:last-child,
.pf-v5-c-toolbar__group.pf-m-chip-container .pf-v5-c-toolbar__item:last-child {
  --pf-v5-c-toolbar--spacer: 0;
}

.pf-v5-c-toolbar .pf-v5-c-chip-group:last-child {
  --pf-v5-c-chip-group--MarginRight: 0;
}

.pf-v5-c-toolbar .pf-v5-c-chip-group li:last-child {
  --pf-v5-c-chip-group__li--m-toolbar--MarginRight: 0;
}

.pf-v5-c-toolbar__toggle.pf-m-expanded .pf-v5-c-button.pf-m-plain {
  color: var(--pf-v5-c-toolbar__toggle--m-expanded__c-button--m-plain--Color);
}

.pf-m-toggle-group.pf-m-show {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__group--m-toggle-group--m-show--spacer);
}

.pf-m-toggle-group.pf-m-show .pf-v5-c-toolbar__group,
.pf-m-toggle-group.pf-m-show .pf-v5-c-toolbar__item {
  display: flex;
  flex: 0 1 auto;
}

.pf-m-toggle-group.pf-m-show .pf-v5-c-toolbar__toggle {
  display: none;
}

@media (min-width: 576px) {
  .pf-m-toggle-group.pf-m-show-on-sm {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__group--m-toggle-group--m-show--spacer);
  }

  .pf-m-toggle-group.pf-m-show-on-sm .pf-v5-c-toolbar__group,
  .pf-m-toggle-group.pf-m-show-on-sm .pf-v5-c-toolbar__item {
    display: flex;
    flex: 0 1 auto;
  }

  .pf-m-toggle-group.pf-m-show-on-sm .pf-v5-c-toolbar__toggle {
    display: none;
  }
}

@media (min-width: 768px) {
  .pf-m-toggle-group.pf-m-show-on-md {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__group--m-toggle-group--m-show--spacer);
  }

  .pf-m-toggle-group.pf-m-show-on-md .pf-v5-c-toolbar__group,
  .pf-m-toggle-group.pf-m-show-on-md .pf-v5-c-toolbar__item {
    display: flex;
    flex: 0 1 auto;
  }

  .pf-m-toggle-group.pf-m-show-on-md .pf-v5-c-toolbar__toggle {
    display: none;
  }
}

@media (min-width: 992px) {
  .pf-m-toggle-group.pf-m-show-on-lg {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__group--m-toggle-group--m-show--spacer);
  }

  .pf-m-toggle-group.pf-m-show-on-lg .pf-v5-c-toolbar__group,
  .pf-m-toggle-group.pf-m-show-on-lg .pf-v5-c-toolbar__item {
    display: flex;
    flex: 0 1 auto;
  }

  .pf-m-toggle-group.pf-m-show-on-lg .pf-v5-c-toolbar__toggle {
    display: none;
  }
}

@media (min-width: 1200px) {
  .pf-m-toggle-group.pf-m-show-on-xl {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__group--m-toggle-group--m-show--spacer);
  }

  .pf-m-toggle-group.pf-m-show-on-xl .pf-v5-c-toolbar__group,
  .pf-m-toggle-group.pf-m-show-on-xl .pf-v5-c-toolbar__item {
    display: flex;
    flex: 0 1 auto;
  }

  .pf-m-toggle-group.pf-m-show-on-xl .pf-v5-c-toolbar__toggle {
    display: none;
  }
}

@media (min-width: 1450px) {
  .pf-m-toggle-group.pf-m-show-on-2xl {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-c-toolbar__group--m-toggle-group--m-show--spacer);
  }

  .pf-m-toggle-group.pf-m-show-on-2xl .pf-v5-c-toolbar__group,
  .pf-m-toggle-group.pf-m-show-on-2xl .pf-v5-c-toolbar__item {
    display: flex;
    flex: 0 1 auto;
  }

  .pf-m-toggle-group.pf-m-show-on-2xl .pf-v5-c-toolbar__toggle {
    display: none;
  }
}

.pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-right,
.pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-right {
  margin-inline-start: auto;
}

.pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-right~.pf-m-pagination,
.pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-right~.pf-m-pagination {
  margin-inline-start: 0;
}

.pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-left,
.pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-left {
  margin-inline-start: 0;
}

.pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-left~.pf-m-pagination,
.pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-left~.pf-m-pagination {
  margin-inline-start: auto;
}

.pf-v5-c-toolbar .pf-v5-c-toolbar__content-section.pf-m-nowrap,
.pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-nowrap {
  flex-wrap: nowrap;
}

.pf-v5-c-toolbar .pf-v5-c-toolbar__content-section.pf-m-wrap,
.pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-wrap {
  flex-wrap: wrap;
}

@media (min-width: 576px) {
  .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-right-on-sm,
  .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-right-on-sm {
    margin-inline-start: auto;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-right-on-sm~.pf-m-pagination,
  .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-right-on-sm~.pf-m-pagination {
    margin-inline-start: 0;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-left-on-sm,
  .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-left-on-sm {
    margin-inline-start: 0;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-left-on-sm~.pf-m-pagination,
  .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-left-on-sm~.pf-m-pagination {
    margin-inline-start: auto;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__content-section.pf-m-nowrap-on-sm,
  .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-nowrap-on-sm {
    flex-wrap: nowrap;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__content-section.pf-m-wrap-on-sm,
  .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-wrap-on-sm {
    flex-wrap: wrap;
  }
}

@media (min-width: 768px) {
  .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-right-on-md,
  .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-right-on-md {
    margin-inline-start: auto;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-right-on-md~.pf-m-pagination,
  .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-right-on-md~.pf-m-pagination {
    margin-inline-start: 0;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-left-on-md,
  .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-left-on-md {
    margin-inline-start: 0;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-left-on-md~.pf-m-pagination,
  .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-left-on-md~.pf-m-pagination {
    margin-inline-start: auto;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__content-section.pf-m-nowrap-on-md,
  .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-nowrap-on-md {
    flex-wrap: nowrap;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__content-section.pf-m-wrap-on-md,
  .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-wrap-on-md {
    flex-wrap: wrap;
  }
}

@media (min-width: 992px) {
  .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-right-on-lg,
  .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-right-on-lg {
    margin-inline-start: auto;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-right-on-lg~.pf-m-pagination,
  .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-right-on-lg~.pf-m-pagination {
    margin-inline-start: 0;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-left-on-lg,
  .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-left-on-lg {
    margin-inline-start: 0;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-left-on-lg~.pf-m-pagination,
  .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-left-on-lg~.pf-m-pagination {
    margin-inline-start: auto;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__content-section.pf-m-nowrap-on-lg,
  .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-nowrap-on-lg {
    flex-wrap: nowrap;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__content-section.pf-m-wrap-on-lg,
  .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-wrap-on-lg {
    flex-wrap: wrap;
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-right-on-xl,
  .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-right-on-xl {
    margin-inline-start: auto;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-right-on-xl~.pf-m-pagination,
  .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-right-on-xl~.pf-m-pagination {
    margin-inline-start: 0;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-left-on-xl,
  .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-left-on-xl {
    margin-inline-start: 0;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-left-on-xl~.pf-m-pagination,
  .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-left-on-xl~.pf-m-pagination {
    margin-inline-start: auto;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__content-section.pf-m-nowrap-on-xl,
  .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-nowrap-on-xl {
    flex-wrap: nowrap;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__content-section.pf-m-wrap-on-xl,
  .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-wrap-on-xl {
    flex-wrap: wrap;
  }
}

@media (min-width: 1450px) {
  .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-right-on-2xl,
  .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-right-on-2xl {
    margin-inline-start: auto;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-right-on-2xl~.pf-m-pagination,
  .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-right-on-2xl~.pf-m-pagination {
    margin-inline-start: 0;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-left-on-2xl,
  .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-left-on-2xl {
    margin-inline-start: 0;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__item.pf-m-align-left-on-2xl~.pf-m-pagination,
  .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-align-left-on-2xl~.pf-m-pagination {
    margin-inline-start: auto;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__content-section.pf-m-nowrap-on-2xl,
  .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-nowrap-on-2xl {
    flex-wrap: nowrap;
  }

  .pf-v5-c-toolbar .pf-v5-c-toolbar__content-section.pf-m-wrap-on-2xl,
  .pf-v5-c-toolbar .pf-v5-c-toolbar__group.pf-m-wrap-on-2xl {
    flex-wrap: wrap;
  }
}

.pf-v5-c-toolbar .pf-m-space-items-none>* {
  --pf-v5-c-toolbar--spacer: 0;
}

.pf-v5-c-toolbar .pf-m-space-items-none>:last-child {
  --pf-v5-c-toolbar--spacer: 0;
}

.pf-v5-c-toolbar .pf-m-space-items-sm>* {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--sm);
}

.pf-v5-c-toolbar .pf-m-space-items-sm>:last-child {
  --pf-v5-c-toolbar--spacer: 0;
}

.pf-v5-c-toolbar .pf-m-space-items-md>* {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--md);
}

.pf-v5-c-toolbar .pf-m-space-items-md>:last-child {
  --pf-v5-c-toolbar--spacer: 0;
}

.pf-v5-c-toolbar .pf-m-space-items-lg>* {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--lg);
}

.pf-v5-c-toolbar .pf-m-space-items-lg>:last-child {
  --pf-v5-c-toolbar--spacer: 0;
}

@media (min-width: 576px) {
  .pf-v5-c-toolbar .pf-m-space-items-none-on-sm>* {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-space-items-none-on-sm>:last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-space-items-sm-on-sm>* {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-toolbar .pf-m-space-items-sm-on-sm>:last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-space-items-md-on-sm>* {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-toolbar .pf-m-space-items-md-on-sm>:last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-space-items-lg-on-sm>* {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-toolbar .pf-m-space-items-lg-on-sm>:last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }
}

@media (min-width: 768px) {
  .pf-v5-c-toolbar .pf-m-space-items-none-on-md>* {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-space-items-none-on-md>:last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-space-items-sm-on-md>* {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-toolbar .pf-m-space-items-sm-on-md>:last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-space-items-md-on-md>* {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-toolbar .pf-m-space-items-md-on-md>:last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-space-items-lg-on-md>* {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-toolbar .pf-m-space-items-lg-on-md>:last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }
}

@media (min-width: 992px) {
  .pf-v5-c-toolbar .pf-m-space-items-none-on-lg>* {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-space-items-none-on-lg>:last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-space-items-sm-on-lg>* {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-toolbar .pf-m-space-items-sm-on-lg>:last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-space-items-md-on-lg>* {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-toolbar .pf-m-space-items-md-on-lg>:last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-space-items-lg-on-lg>* {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-toolbar .pf-m-space-items-lg-on-lg>:last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-toolbar .pf-m-space-items-none-on-xl>* {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-space-items-none-on-xl>:last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-space-items-sm-on-xl>* {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-toolbar .pf-m-space-items-sm-on-xl>:last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-space-items-md-on-xl>* {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-toolbar .pf-m-space-items-md-on-xl>:last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-space-items-lg-on-xl>* {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-toolbar .pf-m-space-items-lg-on-xl>:last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }
}

@media (min-width: 1450px) {
  .pf-v5-c-toolbar .pf-m-space-items-none-on-2xl>* {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-space-items-none-on-2xl>:last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-space-items-sm-on-2xl>* {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-toolbar .pf-m-space-items-sm-on-2xl>:last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-space-items-md-on-2xl>* {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-toolbar .pf-m-space-items-md-on-2xl>:last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-space-items-lg-on-2xl>* {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-toolbar .pf-m-space-items-lg-on-2xl>:last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }
}

.pf-v5-c-toolbar .pf-m-spacer-none {
  --pf-v5-c-toolbar--spacer: 0;
}

.pf-v5-c-toolbar .pf-m-spacer-none:last-child {
  --pf-v5-c-toolbar--spacer: 0;
}

.pf-v5-c-toolbar .pf-m-spacer-sm {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--sm);
}

.pf-v5-c-toolbar .pf-m-spacer-sm:last-child {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--sm);
}

.pf-v5-c-toolbar .pf-m-spacer-md {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--md);
}

.pf-v5-c-toolbar .pf-m-spacer-md:last-child {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--md);
}

.pf-v5-c-toolbar .pf-m-spacer-lg {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--lg);
}

.pf-v5-c-toolbar .pf-m-spacer-lg:last-child {
  --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--lg);
}

@media (min-width: 576px) {
  .pf-v5-c-toolbar .pf-m-spacer-none-on-sm {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-spacer-none-on-sm:last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-spacer-sm-on-sm {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-toolbar .pf-m-spacer-sm-on-sm:last-child {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-toolbar .pf-m-spacer-md-on-sm {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-toolbar .pf-m-spacer-md-on-sm:last-child {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-toolbar .pf-m-spacer-lg-on-sm {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-toolbar .pf-m-spacer-lg-on-sm:last-child {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--lg);
  }
}

@media (min-width: 768px) {
  .pf-v5-c-toolbar .pf-m-spacer-none-on-md {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-spacer-none-on-md:last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-spacer-sm-on-md {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-toolbar .pf-m-spacer-sm-on-md:last-child {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-toolbar .pf-m-spacer-md-on-md {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-toolbar .pf-m-spacer-md-on-md:last-child {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-toolbar .pf-m-spacer-lg-on-md {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-toolbar .pf-m-spacer-lg-on-md:last-child {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--lg);
  }
}

@media (min-width: 992px) {
  .pf-v5-c-toolbar .pf-m-spacer-none-on-lg {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-spacer-none-on-lg:last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-spacer-sm-on-lg {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-toolbar .pf-m-spacer-sm-on-lg:last-child {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-toolbar .pf-m-spacer-md-on-lg {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-toolbar .pf-m-spacer-md-on-lg:last-child {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-toolbar .pf-m-spacer-lg-on-lg {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-toolbar .pf-m-spacer-lg-on-lg:last-child {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--lg);
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-toolbar .pf-m-spacer-none-on-xl {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-spacer-none-on-xl:last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-spacer-sm-on-xl {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-toolbar .pf-m-spacer-sm-on-xl:last-child {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-toolbar .pf-m-spacer-md-on-xl {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-toolbar .pf-m-spacer-md-on-xl:last-child {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-toolbar .pf-m-spacer-lg-on-xl {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-toolbar .pf-m-spacer-lg-on-xl:last-child {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--lg);
  }
}

@media (min-width: 1450px) {
  .pf-v5-c-toolbar .pf-m-spacer-none-on-2xl {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-spacer-none-on-2xl:last-child {
    --pf-v5-c-toolbar--spacer: 0;
  }

  .pf-v5-c-toolbar .pf-m-spacer-sm-on-2xl {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-toolbar .pf-m-spacer-sm-on-2xl:last-child {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-toolbar .pf-m-spacer-md-on-2xl {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-toolbar .pf-m-spacer-md-on-2xl:last-child {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-toolbar .pf-m-spacer-lg-on-2xl {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-toolbar .pf-m-spacer-lg-on-2xl:last-child {
    --pf-v5-c-toolbar--spacer: var(--pf-v5-global--spacer--lg);
  }
}

.pf-v5-c-toolbar.pf-m-inset-none {
  --pf-v5-c-toolbar--inset: 0;
  --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
  --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
}

.pf-v5-c-toolbar.pf-m-inset-sm {
  --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
  --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
}

.pf-v5-c-toolbar.pf-m-inset-md {
  --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--md);
  --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
  --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
}

.pf-v5-c-toolbar.pf-m-inset-lg {
  --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
  --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
}

.pf-v5-c-toolbar.pf-m-inset-xl {
  --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
  --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
}

.pf-v5-c-toolbar.pf-m-inset-2xl {
  --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
  --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
}

@media (min-width: 576px) {
  .pf-v5-c-toolbar.pf-m-inset-none-on-sm {
    --pf-v5-c-toolbar--inset: 0;
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-sm-on-sm {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-md-on-sm {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--md);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-lg-on-sm {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--lg);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-xl-on-sm {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-2xl-on-sm {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--2xl);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }
}

@media (min-width: 768px) {
  .pf-v5-c-toolbar.pf-m-inset-none-on-md {
    --pf-v5-c-toolbar--inset: 0;
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-sm-on-md {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-md-on-md {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--md);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-lg-on-md {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--lg);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-xl-on-md {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-2xl-on-md {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--2xl);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }
}

@media (min-width: 992px) {
  .pf-v5-c-toolbar.pf-m-inset-none-on-lg {
    --pf-v5-c-toolbar--inset: 0;
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-sm-on-lg {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-md-on-lg {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--md);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-lg-on-lg {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--lg);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-xl-on-lg {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-2xl-on-lg {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--2xl);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-toolbar.pf-m-inset-none-on-xl {
    --pf-v5-c-toolbar--inset: 0;
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-sm-on-xl {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-md-on-xl {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--md);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-lg-on-xl {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--lg);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-xl-on-xl {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-2xl-on-xl {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--2xl);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }
}

@media (min-width: 1450px) {
  .pf-v5-c-toolbar.pf-m-inset-none-on-2xl {
    --pf-v5-c-toolbar--inset: 0;
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-sm-on-2xl {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-md-on-2xl {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--md);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-lg-on-2xl {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--lg);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-xl-on-2xl {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }

  .pf-v5-c-toolbar.pf-m-inset-2xl-on-2xl {
    --pf-v5-c-toolbar--inset: var(--pf-v5-global--spacer--2xl);
    --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-toolbar--inset);
    --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-toolbar--inset);
  }
}

.pf-v5-c-toolbar__content-section>:last-child {
  --pf-v5-c-toolbar--spacer: 0;
}

.pf-v5-c-date-picker {
  --pf-v5-c-date-picker--m-top__calendar--Top: 0;
  --pf-v5-c-date-picker--m-top__calendar--TranslateY: calc(-100% - var(--pf-v5-global--spacer--xs));
  --pf-v5-c-date-picker__helper-text--MarginTop: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-date-picker__input--c-form-control--Width: calc(var(--pf-v5-c-date-picker__input--c-form-control--width-chars) * 1ch + var(--pf-v5-c-date-picker__input--c-form-control--width-base));
  --pf-v5-c-date-picker__input--c-form-control--width-base: calc(var(--pf-v5-global--spacer--xl) + var(--pf-v5-global--spacer--sm));
  --pf-v5-c-date-picker__input--c-form-control--width-chars: 10;
  --pf-v5-c-date-picker__calendar--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-c-date-picker__calendar--BoxShadow: var(--pf-v5-global--BoxShadow--md);
  --pf-v5-c-date-picker__calendar--ZIndex: var(--pf-v5-global--ZIndex--sm);
  --pf-v5-c-date-picker__calendar--Top: calc(100% + var(--pf-v5-global--spacer--xs));
  --pf-v5-c-date-picker__calendar--Right: auto;
  --pf-v5-c-date-picker__calendar--Left: 0;
  --pf-v5-c-date-picker__calendar--m-align-right--Right: 0;
  --pf-v5-c-date-picker__calendar--m-align-right--Left: auto;
  position: relative;
  display: inline-block;
}

.pf-v5-c-date-picker__helper-text {
  margin-block-start: var(--pf-v5-c-date-picker__helper-text--MarginTop);
}

.pf-v5-c-date-picker__input .pf-v5-c-form-control {
  width: var(--pf-v5-c-date-picker__input--c-form-control--Width);
}

.pf-v5-c-date-picker__calendar {
  position: absolute;
  inset-block-start: var(--pf-v5-c-date-picker__calendar--Top);
  inset-inline-start: var(--pf-v5-c-date-picker__calendar--Left);
  inset-inline-end: var(--pf-v5-c-date-picker__calendar--Right);
  z-index: var(--pf-v5-c-date-picker__calendar--ZIndex);
  background-color: var(--pf-v5-c-date-picker__calendar--BackgroundColor);
  box-shadow: var(--pf-v5-c-date-picker__calendar--BoxShadow);
}

.pf-v5-c-date-picker__calendar.pf-m-align-right {
  --pf-v5-c-date-picker__calendar--Right: var(--pf-v5-c-date-picker__calendar--m-align-right--Right);
  --pf-v5-c-date-picker__calendar--Left: var(--pf-v5-c-date-picker__calendar--m-align-right--Left);
}

.pf-v5-c-date-picker.pf-m-top .pf-v5-c-date-picker__calendar {
  --pf-v5-c-date-picker__calendar--Top: var(--pf-v5-c-date-picker--m-top__calendar--Top);
  transform: translateY(var(--pf-v5-c-date-picker--m-top__calendar--TranslateY));
}

.pf-v5-c-date-picker__calendar.pf-m-static {
  --pf-v5-c-date-picker--m-top__calendar--TranslateY: 0;
  position: static;
  inset-block-start: auto;
  inset-block-end: auto;
  inset-inline-start: auto;
  inset-inline-end: auto;
  z-index: auto;
  min-width: min-content;
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-date-picker {
  --pf-v5-c-date-picker__calendar--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-date-picker__calendar--Top: 100%;
}

.pf-v5-c-divider {
  --pf-v5-c-divider--BorderWidth--base: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-divider--BorderColor--base: var(--pf-v5-c-divider--BackgroundColor);
  --pf-v5-c-divider--Height: var(--pf-v5-c-divider--BorderWidth--base);
  --pf-v5-c-divider--BackgroundColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-divider--after--BackgroundColor: var(--pf-v5-c-divider--BorderColor--base);
  --pf-v5-c-divider--after--FlexBasis: 100%;
  --pf-v5-c-divider--after--Inset: 0%;
  --pf-v5-c-divider--m-vertical--after--FlexBasis: 100%;
  --pf-v5-c-divider--m-horizontal--Display: flex;
  --pf-v5-c-divider--m-horizontal--FlexDirection: row;
  --pf-v5-c-divider--m-horizontal--after--Height: var(--pf-v5-c-divider--Height);
  --pf-v5-c-divider--m-horizontal--after--Width: auto;
  --pf-v5-c-divider--m-vertical--Display: inline-flex;
  --pf-v5-c-divider--m-vertical--FlexDirection: column;
  --pf-v5-c-divider--m-vertical--after--Height: auto;
  --pf-v5-c-divider--m-vertical--after--Width: var(--pf-v5-c-divider--BorderWidth--base);
  --pf-v5-hidden-visible--visible--Display: var(--pf-v5-c-divider--Display);
  --pf-v5-c-divider--Display: var(--pf-v5-c-divider--m-horizontal--Display);
  --pf-v5-c-divider--FlexDirection: var(--pf-v5-c-divider--m-horizontal--FlexDirection);
  --pf-v5-c-divider--after--Width: var(--pf-v5-c-divider--m-horizontal--after--Width);
  --pf-v5-c-divider--after--Height: var(--pf-v5-c-divider--m-horizontal--after--Height);
  width: 100%;
  height: auto;
  flex-direction: var(--pf-v5-c-divider--FlexDirection);
  flex-shrink: 0;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  border: 0;
}

.pf-v5-c-divider::after {
  flex-basis: calc(var(--pf-v5-c-divider--after--FlexBasis) - var(--pf-v5-c-divider--after--Inset)*2);
}

.pf-v5-c-divider::after {
  align-self: stretch;
  justify-self: center;
  width: var(--pf-v5-c-divider--after--Width);
  height: var(--pf-v5-c-divider--after--Height);
  content: "";
  background-color: var(--pf-v5-c-divider--after--BackgroundColor);
}

.pf-v5-c-divider.pf-m-vertical {
  --pf-v5-c-divider--Display: var(--pf-v5-c-divider--m-vertical--Display);
  --pf-v5-c-divider--FlexDirection: var(--pf-v5-c-divider--m-vertical--FlexDirection);
  --pf-v5-c-divider--after--Width: var(--pf-v5-c-divider--m-vertical--after--Width);
  --pf-v5-c-divider--after--Height: var(--pf-v5-c-divider--m-vertical--after--Height);
  width: auto;
  height: inherit;
}

.pf-v5-c-divider.pf-m-vertical::after {
  flex-basis: calc(var(--pf-v5-c-divider--m-vertical--after--FlexBasis) - var(--pf-v5-c-divider--after--Inset));
}

.pf-v5-c-divider.pf-m-inset-none {
  --pf-v5-c-divider--after--Inset: 0%;
}

.pf-v5-c-divider.pf-m-inset-xs {
  --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--xs);
}

.pf-v5-c-divider.pf-m-inset-sm {
  --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--sm);
}

.pf-v5-c-divider.pf-m-inset-md {
  --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--md);
}

.pf-v5-c-divider.pf-m-inset-lg {
  --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--lg);
}

.pf-v5-c-divider.pf-m-inset-xl {
  --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--xl);
}

.pf-v5-c-divider.pf-m-inset-2xl {
  --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--2xl);
}

.pf-v5-c-divider.pf-m-inset-3xl {
  --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--3xl);
}

@media (min-width: 576px) {
  .pf-v5-c-divider.pf-m-horizontal-on-sm {
    --pf-v5-c-divider--Display: var(--pf-v5-c-divider--m-horizontal--Display);
    --pf-v5-c-divider--FlexDirection: var(--pf-v5-c-divider--m-horizontal--FlexDirection);
    --pf-v5-c-divider--after--Width: var(--pf-v5-c-divider--m-horizontal--after--Width);
    --pf-v5-c-divider--after--Height: var(--pf-v5-c-divider--m-horizontal--after--Height);
    width: 100%;
    height: auto;
  }

  .pf-v5-c-divider.pf-m-horizontal-on-sm::after {
    flex-basis: calc(var(--pf-v5-c-divider--after--FlexBasis) - var(--pf-v5-c-divider--after--Inset)*2);
  }
}

@media (min-width: 576px) {
  .pf-v5-c-divider.pf-m-vertical-on-sm {
    --pf-v5-c-divider--Display: var(--pf-v5-c-divider--m-vertical--Display);
    --pf-v5-c-divider--FlexDirection: var(--pf-v5-c-divider--m-vertical--FlexDirection);
    --pf-v5-c-divider--after--Width: var(--pf-v5-c-divider--m-vertical--after--Width);
    --pf-v5-c-divider--after--Height: var(--pf-v5-c-divider--m-vertical--after--Height);
    width: auto;
    height: inherit;
  }

  .pf-v5-c-divider.pf-m-vertical-on-sm::after {
    flex-basis: calc(var(--pf-v5-c-divider--m-vertical--after--FlexBasis) - var(--pf-v5-c-divider--after--Inset));
  }
}

@media (min-width: 576px) {
  .pf-v5-c-divider.pf-m-inset-none-on-sm {
    --pf-v5-c-divider--after--Inset: 0%;
  }

  .pf-v5-c-divider.pf-m-inset-xs-on-sm {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--xs);
  }

  .pf-v5-c-divider.pf-m-inset-sm-on-sm {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-divider.pf-m-inset-md-on-sm {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-divider.pf-m-inset-lg-on-sm {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-divider.pf-m-inset-xl-on-sm {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-c-divider.pf-m-inset-2xl-on-sm {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--2xl);
  }

  .pf-v5-c-divider.pf-m-inset-3xl-on-sm {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--3xl);
  }
}

@media (min-width: 768px) {
  .pf-v5-c-divider.pf-m-horizontal-on-md {
    --pf-v5-c-divider--Display: var(--pf-v5-c-divider--m-horizontal--Display);
    --pf-v5-c-divider--FlexDirection: var(--pf-v5-c-divider--m-horizontal--FlexDirection);
    --pf-v5-c-divider--after--Width: var(--pf-v5-c-divider--m-horizontal--after--Width);
    --pf-v5-c-divider--after--Height: var(--pf-v5-c-divider--m-horizontal--after--Height);
    width: 100%;
    height: auto;
  }

  .pf-v5-c-divider.pf-m-horizontal-on-md::after {
    flex-basis: calc(var(--pf-v5-c-divider--after--FlexBasis) - var(--pf-v5-c-divider--after--Inset)*2);
  }
}

@media (min-width: 768px) {
  .pf-v5-c-divider.pf-m-vertical-on-md {
    --pf-v5-c-divider--Display: var(--pf-v5-c-divider--m-vertical--Display);
    --pf-v5-c-divider--FlexDirection: var(--pf-v5-c-divider--m-vertical--FlexDirection);
    --pf-v5-c-divider--after--Width: var(--pf-v5-c-divider--m-vertical--after--Width);
    --pf-v5-c-divider--after--Height: var(--pf-v5-c-divider--m-vertical--after--Height);
    width: auto;
    height: inherit;
  }

  .pf-v5-c-divider.pf-m-vertical-on-md::after {
    flex-basis: calc(var(--pf-v5-c-divider--m-vertical--after--FlexBasis) - var(--pf-v5-c-divider--after--Inset));
  }
}

@media (min-width: 768px) {
  .pf-v5-c-divider.pf-m-inset-none-on-md {
    --pf-v5-c-divider--after--Inset: 0%;
  }

  .pf-v5-c-divider.pf-m-inset-xs-on-md {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--xs);
  }

  .pf-v5-c-divider.pf-m-inset-sm-on-md {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-divider.pf-m-inset-md-on-md {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-divider.pf-m-inset-lg-on-md {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-divider.pf-m-inset-xl-on-md {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-c-divider.pf-m-inset-2xl-on-md {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--2xl);
  }

  .pf-v5-c-divider.pf-m-inset-3xl-on-md {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--3xl);
  }
}

@media (min-width: 992px) {
  .pf-v5-c-divider.pf-m-horizontal-on-lg {
    --pf-v5-c-divider--Display: var(--pf-v5-c-divider--m-horizontal--Display);
    --pf-v5-c-divider--FlexDirection: var(--pf-v5-c-divider--m-horizontal--FlexDirection);
    --pf-v5-c-divider--after--Width: var(--pf-v5-c-divider--m-horizontal--after--Width);
    --pf-v5-c-divider--after--Height: var(--pf-v5-c-divider--m-horizontal--after--Height);
    width: 100%;
    height: auto;
  }

  .pf-v5-c-divider.pf-m-horizontal-on-lg::after {
    flex-basis: calc(var(--pf-v5-c-divider--after--FlexBasis) - var(--pf-v5-c-divider--after--Inset)*2);
  }
}

@media (min-width: 992px) {
  .pf-v5-c-divider.pf-m-vertical-on-lg {
    --pf-v5-c-divider--Display: var(--pf-v5-c-divider--m-vertical--Display);
    --pf-v5-c-divider--FlexDirection: var(--pf-v5-c-divider--m-vertical--FlexDirection);
    --pf-v5-c-divider--after--Width: var(--pf-v5-c-divider--m-vertical--after--Width);
    --pf-v5-c-divider--after--Height: var(--pf-v5-c-divider--m-vertical--after--Height);
    width: auto;
    height: inherit;
  }

  .pf-v5-c-divider.pf-m-vertical-on-lg::after {
    flex-basis: calc(var(--pf-v5-c-divider--m-vertical--after--FlexBasis) - var(--pf-v5-c-divider--after--Inset));
  }
}

@media (min-width: 992px) {
  .pf-v5-c-divider.pf-m-inset-none-on-lg {
    --pf-v5-c-divider--after--Inset: 0%;
  }

  .pf-v5-c-divider.pf-m-inset-xs-on-lg {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--xs);
  }

  .pf-v5-c-divider.pf-m-inset-sm-on-lg {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-divider.pf-m-inset-md-on-lg {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-divider.pf-m-inset-lg-on-lg {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-divider.pf-m-inset-xl-on-lg {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-c-divider.pf-m-inset-2xl-on-lg {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--2xl);
  }

  .pf-v5-c-divider.pf-m-inset-3xl-on-lg {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--3xl);
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-divider.pf-m-horizontal-on-xl {
    --pf-v5-c-divider--Display: var(--pf-v5-c-divider--m-horizontal--Display);
    --pf-v5-c-divider--FlexDirection: var(--pf-v5-c-divider--m-horizontal--FlexDirection);
    --pf-v5-c-divider--after--Width: var(--pf-v5-c-divider--m-horizontal--after--Width);
    --pf-v5-c-divider--after--Height: var(--pf-v5-c-divider--m-horizontal--after--Height);
    width: 100%;
    height: auto;
  }

  .pf-v5-c-divider.pf-m-horizontal-on-xl::after {
    flex-basis: calc(var(--pf-v5-c-divider--after--FlexBasis) - var(--pf-v5-c-divider--after--Inset)*2);
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-divider.pf-m-vertical-on-xl {
    --pf-v5-c-divider--Display: var(--pf-v5-c-divider--m-vertical--Display);
    --pf-v5-c-divider--FlexDirection: var(--pf-v5-c-divider--m-vertical--FlexDirection);
    --pf-v5-c-divider--after--Width: var(--pf-v5-c-divider--m-vertical--after--Width);
    --pf-v5-c-divider--after--Height: var(--pf-v5-c-divider--m-vertical--after--Height);
    width: auto;
    height: inherit;
  }

  .pf-v5-c-divider.pf-m-vertical-on-xl::after {
    flex-basis: calc(var(--pf-v5-c-divider--m-vertical--after--FlexBasis) - var(--pf-v5-c-divider--after--Inset));
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-divider.pf-m-inset-none-on-xl {
    --pf-v5-c-divider--after--Inset: 0%;
  }

  .pf-v5-c-divider.pf-m-inset-xs-on-xl {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--xs);
  }

  .pf-v5-c-divider.pf-m-inset-sm-on-xl {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-divider.pf-m-inset-md-on-xl {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-divider.pf-m-inset-lg-on-xl {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-divider.pf-m-inset-xl-on-xl {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-c-divider.pf-m-inset-2xl-on-xl {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--2xl);
  }

  .pf-v5-c-divider.pf-m-inset-3xl-on-xl {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--3xl);
  }
}

@media (min-width: 1450px) {
  .pf-v5-c-divider.pf-m-horizontal-on-2xl {
    --pf-v5-c-divider--Display: var(--pf-v5-c-divider--m-horizontal--Display);
    --pf-v5-c-divider--FlexDirection: var(--pf-v5-c-divider--m-horizontal--FlexDirection);
    --pf-v5-c-divider--after--Width: var(--pf-v5-c-divider--m-horizontal--after--Width);
    --pf-v5-c-divider--after--Height: var(--pf-v5-c-divider--m-horizontal--after--Height);
    width: 100%;
    height: auto;
  }

  .pf-v5-c-divider.pf-m-horizontal-on-2xl::after {
    flex-basis: calc(var(--pf-v5-c-divider--after--FlexBasis) - var(--pf-v5-c-divider--after--Inset)*2);
  }
}

@media (min-width: 1450px) {
  .pf-v5-c-divider.pf-m-vertical-on-2xl {
    --pf-v5-c-divider--Display: var(--pf-v5-c-divider--m-vertical--Display);
    --pf-v5-c-divider--FlexDirection: var(--pf-v5-c-divider--m-vertical--FlexDirection);
    --pf-v5-c-divider--after--Width: var(--pf-v5-c-divider--m-vertical--after--Width);
    --pf-v5-c-divider--after--Height: var(--pf-v5-c-divider--m-vertical--after--Height);
    width: auto;
    height: inherit;
  }

  .pf-v5-c-divider.pf-m-vertical-on-2xl::after {
    flex-basis: calc(var(--pf-v5-c-divider--m-vertical--after--FlexBasis) - var(--pf-v5-c-divider--after--Inset));
  }
}

@media (min-width: 1450px) {
  .pf-v5-c-divider.pf-m-inset-none-on-2xl {
    --pf-v5-c-divider--after--Inset: 0%;
  }

  .pf-v5-c-divider.pf-m-inset-xs-on-2xl {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--xs);
  }

  .pf-v5-c-divider.pf-m-inset-sm-on-2xl {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-divider.pf-m-inset-md-on-2xl {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-divider.pf-m-inset-lg-on-2xl {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-divider.pf-m-inset-xl-on-2xl {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-c-divider.pf-m-inset-2xl-on-2xl {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--2xl);
  }

  .pf-v5-c-divider.pf-m-inset-3xl-on-2xl {
    --pf-v5-c-divider--after--Inset: var(--pf-v5-global--spacer--3xl);
  }
}

.pf-v5-c-drawer {
  --pf-v5-c-drawer__section--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-drawer__section--m-light-200--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-drawer__content--FlexBasis: 100%;
  --pf-v5-c-drawer__content--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-drawer__content--m-light-200--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-drawer__content--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-drawer__panel--MinWidth: 50%;
  --pf-v5-c-drawer__panel--MaxHeight: auto;
  --pf-v5-c-drawer__panel--ZIndex: var(--pf-v5-global--ZIndex--sm);
  --pf-v5-c-drawer__panel--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-drawer__panel--m-light-200--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-drawer__panel--TransitionDuration: var(--pf-v5-global--TransitionDuration);
  --pf-v5-c-drawer__panel--TransitionProperty: margin, transform, box-shadow, flex-basis;
  --pf-v5-c-drawer__panel--FlexBasis: 100%;
  --pf-v5-c-drawer__panel--md--FlexBasis--min: 1.5rem;
  --pf-v5-c-drawer__panel--md--FlexBasis: 50%;
  --pf-v5-c-drawer__panel--md--FlexBasis--max: 100%;
  --pf-v5-c-drawer__panel--xl--MinWidth: 28.125rem;
  --pf-v5-c-drawer__panel--xl--FlexBasis: 28.125rem;
  --pf-v5-c-drawer--m-panel-bottom__panel--md--MinHeight: 50%;
  --pf-v5-c-drawer--m-panel-bottom__panel--xl--MinHeight: 18.75rem;
  --pf-v5-c-drawer--m-panel-bottom__panel--xl--FlexBasis: 18.75rem;
  --pf-v5-c-drawer__panel--m-resizable--FlexDirection: row;
  --pf-v5-c-drawer__panel--m-resizable--md--FlexBasis--min: var(--pf-v5-c-drawer__splitter--m-vertical--Width);
  --pf-v5-c-drawer__panel--m-resizable--MinWidth: 1.5rem;
  --pf-v5-c-drawer--m-panel-bottom__panel--m-resizable--FlexDirection: column;
  --pf-v5-c-drawer--m-panel-bottom__panel--m-resizable--md--FlexBasis--min: 1.5rem;
  --pf-v5-c-drawer--m-panel-bottom__panel--m-resizable--MinHeight: 1.5rem;
  --pf-v5-c-drawer--child--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-drawer--child--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-drawer--child--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-drawer--child--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-drawer--child--md--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-drawer--child--md--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-drawer--child--md--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-drawer--child--md--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-drawer--child--m-padding--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-drawer--child--m-padding--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-drawer--child--m-padding--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-drawer--child--m-padding--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-drawer--child--m-padding--md--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-drawer--child--m-padding--md--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-drawer--child--m-padding--md--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-drawer--child--m-padding--md--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-drawer__content--child--PaddingTop: 0;
  --pf-v5-c-drawer__content--child--PaddingRight: 0;
  --pf-v5-c-drawer__content--child--PaddingBottom: 0;
  --pf-v5-c-drawer__content--child--PaddingLeft: 0;
  --pf-v5-c-drawer__splitter--Height: 0.5625rem;
  --pf-v5-c-drawer__splitter--Width: 100%;
  --pf-v5-c-drawer__splitter--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-drawer__splitter--Cursor: row-resize;
  --pf-v5-c-drawer__splitter--m-vertical--Height: 100%;
  --pf-v5-c-drawer__splitter--m-vertical--Width: 0.5625rem;
  --pf-v5-c-drawer__splitter--m-vertical--Cursor: col-resize;
  --pf-v5-c-drawer--m-inline__splitter--focus--OutlineOffset: -0.0625rem;
  --pf-v5-c-drawer__splitter--after--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-drawer__splitter--after--border-width--base: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-drawer__splitter--after--BorderTopWidth: 0;
  --pf-v5-c-drawer__splitter--after--BorderRightWidth: var(--pf-v5-c-drawer__splitter--after--border-width--base);
  --pf-v5-c-drawer__splitter--after--BorderBottomWidth: 0;
  --pf-v5-c-drawer__splitter--after--BorderLeftWidth: 0;
  --pf-v5-c-drawer--m-panel-left__splitter--after--BorderLeftWidth: var(--pf-v5-c-drawer__splitter--after--border-width--base);
  --pf-v5-c-drawer--m-panel-bottom__splitter--after--BorderBottomWidth: var(--pf-v5-c-drawer__splitter--after--border-width--base);
  --pf-v5-c-drawer--m-inline__splitter--m-vertical--Width: 0.625rem;
  --pf-v5-c-drawer--m-inline__splitter-handle--Left: 50%;
  --pf-v5-c-drawer--m-inline__splitter--after--BorderRightWidth: var(--pf-v5-c-drawer__splitter--after--border-width--base);
  --pf-v5-c-drawer--m-inline__splitter--after--BorderLeftWidth: var(--pf-v5-c-drawer__splitter--after--border-width--base);
  --pf-v5-c-drawer--m-inline--m-panel-bottom__splitter--Height: 0.625rem;
  --pf-v5-c-drawer--m-inline--m-panel-bottom__splitter-handle--Top: 50%;
  --pf-v5-c-drawer--m-inline--m-panel-bottom__splitter--after--BorderTopWidth: var(--pf-v5-c-drawer__splitter--after--border-width--base);
  --pf-v5-c-drawer__splitter-handle--Top: 50%;
  --pf-v5-c-drawer__splitter-handle--Left: calc(50% - var(--pf-v5-c-drawer__splitter--after--border-width--base));
  --pf-v5-c-drawer--m-panel-left__splitter-handle--Left: 50%;
  --pf-v5-c-drawer--m-panel-bottom__splitter-handle--Top: calc(50% - var(--pf-v5-c-drawer__splitter--after--border-width--base));
  --pf-v5-c-drawer__splitter-handle--after--BorderColor: var(--pf-v5-global--Color--200);
  --pf-v5-c-drawer__splitter-handle--after--BorderTopWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-drawer__splitter-handle--after--BorderRightWidth: 0;
  --pf-v5-c-drawer__splitter-handle--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-drawer__splitter-handle--after--BorderLeftWidth: 0;
  --pf-v5-c-drawer__splitter--hover__splitter-handle--after--BorderColor: var(--pf-v5-global--Color--100);
  --pf-v5-c-drawer__splitter--focus__splitter-handle--after--BorderColor: var(--pf-v5-global--Color--100);
  --pf-v5-c-drawer__splitter--m-vertical__splitter-handle--after--BorderTopWidth: 0;
  --pf-v5-c-drawer__splitter--m-vertical__splitter-handle--after--BorderRightWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-drawer__splitter--m-vertical__splitter-handle--after--BorderBottomWidth: 0;
  --pf-v5-c-drawer__splitter--m-vertical__splitter-handle--after--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-drawer__splitter-handle--after--Width: 0.75rem;
  --pf-v5-c-drawer__splitter-handle--after--Height: 0.25rem;
  --pf-v5-c-drawer__splitter--m-vertical__splitter-handle--after--Width: 0.25rem;
  --pf-v5-c-drawer__splitter--m-vertical__splitter-handle--after--Height: 0.75rem;
  --pf-v5-c-drawer__actions--MarginTop: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-drawer__actions--MarginRight: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-drawer__panel--BoxShadow: none;
  --pf-v5-c-drawer--m-expanded--m-panel-bottom__panel--BoxShadow: var(--pf-v5-global--BoxShadow--lg-top);
  --pf-v5-c-drawer--m-expanded__panel--BoxShadow: var(--pf-v5-global--BoxShadow--lg-left);
  --pf-v5-c-drawer--m-expanded--m-panel-left__panel--BoxShadow: var(--pf-v5-global--BoxShadow--lg-right);
  --pf-v5-c-drawer__panel--after--Width: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-drawer--m-panel-bottom__panel--after--Height: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-drawer__panel--after--BackgroundColor: transparent;
  --pf-v5-c-drawer--m-inline--m-expanded__panel--after--BackgroundColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-drawer--m-inline__panel--PaddingLeft: var(--pf-v5-c-drawer__panel--after--Width);
  --pf-v5-c-drawer--m-panel-left--m-inline__panel--PaddingRight: var(--pf-v5-c-drawer__panel--after--Width);
  --pf-v5-c-drawer--m-panel-bottom--m-inline__panel--PaddingTop: var(--pf-v5-c-drawer__panel--after--Width);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
}

@media screen and (min-width: 768px) {
  .pf-v5-c-drawer {
    --pf-v5-c-drawer--child--PaddingTop: var(--pf-v5-c-drawer--child--md--PaddingTop);
    --pf-v5-c-drawer--child--PaddingRight: var(--pf-v5-c-drawer--child--md--PaddingRight);
    --pf-v5-c-drawer--child--PaddingBottom: var(--pf-v5-c-drawer--child--md--PaddingBottom);
    --pf-v5-c-drawer--child--PaddingLeft: var(--pf-v5-c-drawer--child--md--PaddingLeft);
    --pf-v5-c-drawer--child--m-padding--PaddingTop: var(--pf-v5-c-drawer--child--m-padding--md--PaddingTop);
    --pf-v5-c-drawer--child--m-padding--PaddingRight: var(--pf-v5-c-drawer--child--m-padding--md--PaddingRight);
    --pf-v5-c-drawer--child--m-padding--PaddingBottom: var(--pf-v5-c-drawer--child--m-padding--md--PaddingBottom);
    --pf-v5-c-drawer--child--m-padding--PaddingLeft: var(--pf-v5-c-drawer--child--m-padding--md--PaddingLeft);
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-c-drawer {
    --pf-v5-c-drawer__panel--MinWidth: var(--pf-v5-c-drawer__panel--xl--MinWidth);
  }

  .pf-v5-c-drawer.pf-m-panel-bottom {
    --pf-v5-c-drawer__panel--MinWidth: auto;
    --pf-v5-c-drawer__panel--MinHeight: var(--pf-v5-c-drawer--m-panel-bottom__panel--xl--MinHeight);
  }
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-drawer {
  --pf-v5-c-drawer--m-expanded__panel--BoxShadow: var(--pf-v5-global--BoxShadow--lg-right);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-drawer {
  --pf-v5-c-drawer--m-expanded--m-panel-left__panel--BoxShadow: var(--pf-v5-global--BoxShadow--lg-left);
}

.pf-v5-c-drawer.pf-m-inline>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel:not(.pf-m-no-border,.pf-m-resizable),
.pf-v5-c-drawer.pf-m-static>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel:not(.pf-m-no-border,.pf-m-resizable) {
  padding-inline-start: var(--pf-v5-c-drawer--m-inline__panel--PaddingLeft);
}

.pf-v5-c-drawer.pf-m-panel-left>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
  order: 0;
  margin-inline-end: calc(var(--pf-v5-c-drawer__panel--FlexBasis)*-1);
  transform: translateX(-100%);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-drawer.pf-m-panel-left>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
  transform: translateX(calc(-100% * var(--pf-v5-global--inverse--multiplier)));
}

.pf-v5-c-drawer.pf-m-panel-left>.pf-v5-c-drawer__main>.pf-v5-c-drawer__content {
  order: 1;
}

.pf-v5-c-drawer.pf-m-panel-bottom>.pf-v5-c-drawer__main {
  flex-direction: column;
}

.pf-v5-c-drawer.pf-m-expanded>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
  transform: translateX(-100%);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-drawer.pf-m-expanded>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
  transform: translateX(calc(-100% * var(--pf-v5-global--inverse--multiplier)));
}

.pf-v5-c-drawer.pf-m-expanded.pf-m-panel-left>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
  transform: translateX(0);
}

.pf-v5-c-drawer.pf-m-expanded.pf-m-panel-bottom>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
  transform: translate(0, -100%);
}

.pf-v5-c-drawer.pf-m-resizing {
  --pf-v5-c-drawer__panel--TransitionProperty: none;
  pointer-events: none;
}

.pf-v5-c-drawer.pf-m-resizing .pf-v5-c-drawer__splitter {
  pointer-events: auto;
}

.pf-v5-c-drawer__section {
  flex-grow: 0;
  background-color: var(--pf-v5-c-drawer__section--BackgroundColor);
}

.pf-v5-c-drawer__section.pf-m-no-background {
  --pf-v5-c-drawer__section--BackgroundColor: transparent;
}

.pf-v5-c-drawer__section.pf-m-light-200 {
  --pf-v5-c-drawer__section--BackgroundColor: var(--pf-v5-c-drawer__section--m-light-200--BackgroundColor);
}

.pf-v5-c-drawer__main {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.pf-v5-c-drawer__content,
.pf-v5-c-drawer__panel,
.pf-v5-c-drawer__panel-main {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow: auto;
}

.pf-v5-c-drawer__content {
  z-index: var(--pf-v5-c-drawer__content--ZIndex);
  flex-basis: var(--pf-v5-c-drawer__content--FlexBasis);
  order: 0;
  background-color: var(--pf-v5-c-drawer__content--BackgroundColor);
}

.pf-v5-c-drawer__content.pf-m-no-background {
  --pf-v5-c-drawer__content--BackgroundColor: transparent;
}

.pf-v5-c-drawer__content.pf-m-light-200 {
  --pf-v5-c-drawer__content--BackgroundColor: var(--pf-v5-c-drawer__content--m-light-200--BackgroundColor);
}

.pf-v5-c-drawer__content>.pf-v5-c-drawer__body {
  padding-block-start: var(--pf-v5-c-drawer__content--child--PaddingTop);
  padding-block-end: var(--pf-v5-c-drawer__content--child--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-drawer__content--child--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-drawer__content--child--PaddingRight);
}

.pf-v5-c-drawer__panel {
  position: relative;
  z-index: var(--pf-v5-c-drawer__panel--ZIndex);
  flex-basis: var(--pf-v5-c-drawer__panel--FlexBasis);
  order: 1;
  max-height: var(--pf-v5-c-drawer__panel--MaxHeight);
  overflow: auto;
  background-color: var(--pf-v5-c-drawer__panel--BackgroundColor);
  box-shadow: var(--pf-v5-c-drawer__panel--BoxShadow);
  transition-duration: var(--pf-v5-c-drawer__panel--TransitionDuration);
  transition-property: var(--pf-v5-c-drawer__panel--TransitionProperty);
  -webkit-overflow-scrolling: touch;
}

.pf-v5-c-drawer__panel::after {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  width: var(--pf-v5-c-drawer__panel--after--Width);
  height: 100%;
  content: "";
  background-color: var(--pf-v5-c-drawer__panel--after--BackgroundColor);
}

.pf-v5-c-drawer__panel.pf-m-no-background {
  --pf-v5-c-drawer__panel--BackgroundColor: transparent;
}

.pf-v5-c-drawer__panel.pf-m-light-200 {
  --pf-v5-c-drawer__panel--BackgroundColor: var(--pf-v5-c-drawer__panel--m-light-200--BackgroundColor);
}

@media screen and (min-width: 768px) {
  .pf-v5-c-drawer__panel {
    --pf-v5-c-drawer__panel--FlexBasis: max( var(--pf-v5-c-drawer__panel--md--FlexBasis--min), min(var(--pf-v5-c-drawer__panel--md--FlexBasis), var(--pf-v5-c-drawer__panel--md--FlexBasis--max)) );
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-c-drawer__panel {
    --pf-v5-c-drawer__panel--md--FlexBasis: var(--pf-v5-c-drawer__panel--xl--FlexBasis);
  }

  .pf-v5-c-drawer.pf-m-panel-bottom .pf-v5-c-drawer__panel {
    --pf-v5-c-drawer__panel--md--FlexBasis: var(--pf-v5-c-drawer--m-panel-bottom__panel--xl--FlexBasis);
  }
}

.pf-v5-c-drawer__panel-main {
  flex-grow: 1;
}

@keyframes pf-remove-tab-focus {
  to {
    visibility: hidden;
  }
}

.pf-v5-c-drawer__panel[hidden] {
  animation-name: pf-remove-tab-focus;
  animation-delay: var(--pf-v5-c-drawer__panel--TransitionDuration);
  animation-fill-mode: forwards;
}

.pf-v5-c-drawer__head {
  display: grid;
  grid-template-columns: auto;
  grid-auto-columns: max-content;
}

.pf-v5-c-drawer__head>* {
  grid-column: 1;
}

.pf-v5-c-drawer__actions {
  display: flex;
  grid-row: 1;
  grid-column: 2;
  align-self: baseline;
  margin-block-start: var(--pf-v5-c-drawer__actions--MarginTop);
  margin-inline-end: var(--pf-v5-c-drawer__actions--MarginRight);
}

.pf-v5-c-drawer__body {
  padding-block-start: var(--pf-v5-c-drawer--child--PaddingTop);
  padding-block-end: var(--pf-v5-c-drawer--child--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-drawer--child--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-drawer--child--PaddingRight);
}

.pf-v5-c-drawer__body.pf-m-no-padding {
  padding: 0;
}

.pf-v5-c-drawer__body.pf-m-no-padding>.pf-v5-c-drawer__actions,
.pf-v5-c-drawer__body.pf-m-no-padding>.pf-v5-c-drawer__head>.pf-v5-c-drawer__actions {
  margin-block-start: 0;
  margin-inline-end: 0;
}

.pf-v5-c-drawer__body.pf-m-padding {
  padding-block-start: var(--pf-v5-c-drawer--child--m-padding--PaddingTop);
  padding-block-end: var(--pf-v5-c-drawer--child--m-padding--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-drawer--child--m-padding--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-drawer--child--m-padding--PaddingRight);
}

.pf-v5-c-drawer__body:not(.pf-m-no-padding)+* {
  padding-block-start: 0;
}

.pf-v5-c-drawer__body:last-child {
  flex: 1 1;
}

.pf-v5-c-drawer__body>.pf-v5-c-page__main {
  height: 100%;
}

.pf-v5-c-drawer__splitter {
  position: relative;
  display: none;
  width: var(--pf-v5-c-drawer__splitter--Width);
  height: var(--pf-v5-c-drawer__splitter--Height);
  cursor: var(--pf-v5-c-drawer__splitter--Cursor);
  background-color: var(--pf-v5-c-drawer__splitter--BackgroundColor);
}

.pf-v5-c-drawer__splitter.pf-m-vertical {
  --pf-v5-c-drawer__splitter--Height: var(--pf-v5-c-drawer__splitter--m-vertical--Height);
  --pf-v5-c-drawer__splitter--Width: var(--pf-v5-c-drawer__splitter--m-vertical--Width);
  --pf-v5-c-drawer__splitter--Cursor: var(--pf-v5-c-drawer__splitter--m-vertical--Cursor);
  --pf-v5-c-drawer__splitter-handle--after--Width: var(--pf-v5-c-drawer__splitter--m-vertical__splitter-handle--after--Width);
  --pf-v5-c-drawer__splitter-handle--after--Height: var(--pf-v5-c-drawer__splitter--m-vertical__splitter-handle--after--Height);
  --pf-v5-c-drawer__splitter-handle--after--BorderTopWidth: var(--pf-v5-c-drawer__splitter--m-vertical__splitter-handle--after--BorderTopWidth);
  --pf-v5-c-drawer__splitter-handle--after--BorderRightWidth: var(--pf-v5-c-drawer__splitter--m-vertical__splitter-handle--after--BorderRightWidth);
  --pf-v5-c-drawer__splitter-handle--after--BorderBottomWidth: var(--pf-v5-c-drawer__splitter--m-vertical__splitter-handle--after--BorderBottomWidth);
  --pf-v5-c-drawer__splitter-handle--after--BorderLeftWidth: var(--pf-v5-c-drawer__splitter--m-vertical__splitter-handle--after--BorderLeftWidth);
}

.pf-v5-c-drawer__splitter:hover {
  --pf-v5-c-drawer__splitter-handle--after--BorderColor: var(--pf-v5-c-drawer__splitter--hover__splitter-handle--after--BorderColor);
}

.pf-v5-c-drawer__splitter:focus {
  --pf-v5-c-drawer__splitter-handle--after--BorderColor: var(--pf-v5-c-drawer__splitter--focus__splitter-handle--after--BorderColor);
}

.pf-v5-c-drawer__splitter::after {
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  content: "";
  border: solid var(--pf-v5-c-drawer__splitter--after--BorderColor);
  border-block-start-width: var(--pf-v5-c-drawer__splitter--after--BorderTopWidth);
  border-block-end-width: var(--pf-v5-c-drawer__splitter--after--BorderBottomWidth);
  border-inline-start-width: var(--pf-v5-c-drawer__splitter--after--BorderLeftWidth);
  border-inline-end-width: var(--pf-v5-c-drawer__splitter--after--BorderRightWidth);
}

.pf-v5-c-drawer__splitter-handle {
  position: absolute;
  inset-block-start: var(--pf-v5-c-drawer__splitter-handle--Top);
  inset-inline-start: var(--pf-v5-c-drawer__splitter-handle--Left);
  transform: translate(-50%, -50%);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-drawer__splitter-handle {
  transform: translate(calc(-50% * var(--pf-v5-global--inverse--multiplier)), -50%);
}

.pf-v5-c-drawer__splitter-handle::after {
  display: block;
  width: var(--pf-v5-c-drawer__splitter-handle--after--Width);
  height: var(--pf-v5-c-drawer__splitter-handle--after--Height);
  content: "";
  border-color: var(--pf-v5-c-drawer__splitter-handle--after--BorderColor);
  border-style: solid;
  border-block-start-width: var(--pf-v5-c-drawer__splitter-handle--after--BorderTopWidth);
  border-block-end-width: var(--pf-v5-c-drawer__splitter-handle--after--BorderBottomWidth);
  border-inline-start-width: var(--pf-v5-c-drawer__splitter-handle--after--BorderLeftWidth);
  border-inline-end-width: var(--pf-v5-c-drawer__splitter-handle--after--BorderRightWidth);
}

@media screen and (min-width: 768px) {
  .pf-v5-c-drawer {
    min-width: var(--pf-v5-c-drawer__panel--MinWidth);
  }

  .pf-v5-c-drawer.pf-m-expanded>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    box-shadow: var(--pf-v5-c-drawer--m-expanded__panel--BoxShadow);
  }

  .pf-v5-c-drawer>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel.pf-m-resizable {
    --pf-v5-c-drawer__panel--md--FlexBasis--min: var(--pf-v5-c-drawer__panel--m-resizable--md--FlexBasis--min);
    flex-direction: var(--pf-v5-c-drawer__panel--m-resizable--FlexDirection);
    min-width: var(--pf-v5-c-drawer__panel--m-resizable--MinWidth);
  }

  .pf-v5-c-drawer>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel.pf-m-resizable::after {
    width: 0;
    height: 0;
  }

  .pf-v5-c-drawer>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel.pf-m-resizable>.pf-v5-c-drawer__splitter {
    flex-shrink: 0;
  }

  .pf-v5-c-drawer>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel.pf-m-resizable>.pf-v5-c-drawer__panel-main {
    flex-shrink: 1;
  }

  .pf-v5-c-drawer.pf-m-panel-left {
    --pf-v5-c-drawer--m-expanded__panel--BoxShadow: var(--pf-v5-c-drawer--m-expanded--m-panel-left__panel--BoxShadow);
  }

  .pf-v5-c-drawer.pf-m-panel-left.pf-m-inline>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel:not(.pf-m-no-border,.pf-m-resizable),
  .pf-v5-c-drawer.pf-m-panel-left.pf-m-static>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel:not(.pf-m-no-border,.pf-m-resizable) {
    padding-inline-start: 0;
    padding-inline-end: var(--pf-v5-c-drawer--m-panel-left--m-inline__panel--PaddingRight);
  }

  .pf-v5-c-drawer.pf-m-panel-left.pf-m-expanded>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-panel-left>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel::after {
    inset-inline-start: auto;
    inset-inline-end: 0;
  }

  .pf-v5-c-drawer.pf-m-panel-left>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel.pf-m-resizable>.pf-v5-c-drawer__splitter {
    --pf-v5-c-drawer__splitter-handle--Left: var(--pf-v5-c-drawer--m-panel-left__splitter-handle--Left);
    --pf-v5-c-drawer__splitter--after--BorderRightWidth: 0;
    --pf-v5-c-drawer__splitter--after--BorderLeftWidth: var(--pf-v5-c-drawer--m-panel-left__splitter--after--BorderLeftWidth);
    order: 1;
  }

  .pf-v5-c-drawer.pf-m-panel-bottom {
    --pf-v5-c-drawer--m-expanded__panel--BoxShadow: var(--pf-v5-c-drawer--m-expanded--m-panel-bottom__panel--BoxShadow);
    --pf-v5-c-drawer__panel--MaxHeight: 100%;
    --pf-v5-c-drawer__panel--FlexBasis--min: var(--pf-v5-c-drawer--m-panel-bottom__panel--FlexBasis--min);
    min-width: auto;
    min-height: var(--pf-v5-c-drawer--m-panel-bottom__panel--md--MinHeight);
  }

  .pf-v5-c-drawer.pf-m-panel-bottom.pf-m-inline>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel:not(.pf-m-no-border,.pf-m-resizable),
  .pf-v5-c-drawer.pf-m-panel-bottom.pf-m-static>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel:not(.pf-m-no-border,.pf-m-resizable) {
    padding-block-start: var(--pf-v5-c-drawer--m-panel-bottom--m-inline__panel--PaddingTop);
    padding-inline-start: 0;
  }

  .pf-v5-c-drawer.pf-m-panel-bottom>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel::after {
    inset-block-start: 0;
    inset-inline-start: auto;
    width: 100%;
    height: var(--pf-v5-c-drawer--m-panel-bottom__panel--after--Height);
  }

  .pf-v5-c-drawer.pf-m-panel-bottom>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel.pf-m-resizable {
    --pf-v5-c-drawer__panel--md--FlexBasis--min: var(--pf-v5-c-drawer--m-panel-bottom__panel--m-resizable--md--FlexBasis--min);
    --pf-v5-c-drawer__panel--m-resizable--FlexDirection: var(--pf-v5-c-drawer--m-panel-bottom__panel--m-resizable--FlexDirection);
    --pf-v5-c-drawer__panel--m-resizable--MinWidth: 0;
    min-height: var(--pf-v5-c-drawer--m-panel-bottom__panel--m-resizable--MinHeight);
  }

  .pf-v5-c-drawer.pf-m-panel-bottom>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel.pf-m-resizable>.pf-v5-c-drawer__splitter {
    --pf-v5-c-drawer__splitter-handle--Top: var(--pf-v5-c-drawer--m-panel-bottom__splitter-handle--Top);
    --pf-v5-c-drawer__splitter--after--BorderRightWidth: 0;
    --pf-v5-c-drawer__splitter--after--BorderBottomWidth: var(--pf-v5-c-drawer--m-panel-bottom__splitter--after--BorderBottomWidth);
  }

  .pf-v5-c-drawer.pf-m-inline>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel.pf-m-resizable>.pf-v5-c-drawer__splitter {
    --pf-v5-c-drawer__splitter--m-vertical--Width: var(--pf-v5-c-drawer--m-inline__splitter--m-vertical--Width);
    --pf-v5-c-drawer__splitter-handle--Left: var(--pf-v5-c-drawer--m-inline__splitter-handle--Left);
    --pf-v5-c-drawer__splitter--after--BorderRightWidth: var(--pf-v5-c-drawer--m-inline__splitter--after--BorderRightWidth);
    --pf-v5-c-drawer__splitter--after--BorderLeftWidth: var(--pf-v5-c-drawer--m-inline__splitter--after--BorderLeftWidth);
    outline-offset: var(--pf-v5-c-drawer--m-inline__splitter--focus--OutlineOffset);
  }

  .pf-v5-c-drawer.pf-m-inline.pf-m-panel-bottom>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel.pf-m-resizable>.pf-v5-c-drawer__splitter {
    --pf-v5-c-drawer__splitter--Height: var(--pf-v5-c-drawer--m-inline--m-panel-bottom__splitter--Height);
    --pf-v5-c-drawer__splitter-handle--Top: var(--pf-v5-c-drawer--m-inline--m-panel-bottom__splitter-handle--Top);
    --pf-v5-c-drawer__splitter--after--BorderTopWidth: var(--pf-v5-c-drawer--m-inline--m-panel-bottom__splitter--after--BorderTopWidth);
    --pf-v5-c-drawer__splitter--after--BorderRightWidth: 0;
    --pf-v5-c-drawer__splitter--after--BorderLeftWidth: 0;
  }

  .pf-v5-c-drawer>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel.pf-m-no-border,
  .pf-v5-c-drawer.pf-m-panel-left>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel.pf-m-no-border {
    --pf-v5-c-drawer--m-expanded__panel--BoxShadow: none;
  }

  .pf-v5-c-drawer__splitter {
    display: block;
  }
}

@media (min-width: 768px) {
  .pf-v5-c-drawer__panel.pf-m-width-25 {
    --pf-v5-c-drawer__panel--md--FlexBasis: 25%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-33 {
    --pf-v5-c-drawer__panel--md--FlexBasis: 33%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-50 {
    --pf-v5-c-drawer__panel--md--FlexBasis: 50%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-66 {
    --pf-v5-c-drawer__panel--md--FlexBasis: 66%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-75 {
    --pf-v5-c-drawer__panel--md--FlexBasis: 75%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-100 {
    --pf-v5-c-drawer__panel--md--FlexBasis: 100%;
  }
}

@media (min-width: 992px) {
  .pf-v5-c-drawer__panel.pf-m-width-25-on-lg {
    --pf-v5-c-drawer__panel--md--FlexBasis: 25%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-33-on-lg {
    --pf-v5-c-drawer__panel--md--FlexBasis: 33%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-50-on-lg {
    --pf-v5-c-drawer__panel--md--FlexBasis: 50%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-66-on-lg {
    --pf-v5-c-drawer__panel--md--FlexBasis: 66%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-75-on-lg {
    --pf-v5-c-drawer__panel--md--FlexBasis: 75%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-100-on-lg {
    --pf-v5-c-drawer__panel--md--FlexBasis: 100%;
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-drawer__panel.pf-m-width-25-on-xl {
    --pf-v5-c-drawer__panel--md--FlexBasis: 25%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-33-on-xl {
    --pf-v5-c-drawer__panel--md--FlexBasis: 33%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-50-on-xl {
    --pf-v5-c-drawer__panel--md--FlexBasis: 50%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-66-on-xl {
    --pf-v5-c-drawer__panel--md--FlexBasis: 66%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-75-on-xl {
    --pf-v5-c-drawer__panel--md--FlexBasis: 75%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-100-on-xl {
    --pf-v5-c-drawer__panel--md--FlexBasis: 100%;
  }
}

@media (min-width: 1450px) {
  .pf-v5-c-drawer__panel.pf-m-width-25-on-2xl {
    --pf-v5-c-drawer__panel--md--FlexBasis: 25%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-33-on-2xl {
    --pf-v5-c-drawer__panel--md--FlexBasis: 33%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-50-on-2xl {
    --pf-v5-c-drawer__panel--md--FlexBasis: 50%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-66-on-2xl {
    --pf-v5-c-drawer__panel--md--FlexBasis: 66%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-75-on-2xl {
    --pf-v5-c-drawer__panel--md--FlexBasis: 75%;
  }

  .pf-v5-c-drawer__panel.pf-m-width-100-on-2xl {
    --pf-v5-c-drawer__panel--md--FlexBasis: 100%;
  }
}

@media (min-width: 768px) {
  .pf-v5-c-drawer.pf-m-inline>.pf-v5-c-drawer__main>.pf-v5-c-drawer__content,
  .pf-v5-c-drawer.pf-m-static>.pf-v5-c-drawer__main>.pf-v5-c-drawer__content {
    flex-shrink: 1;
  }

  .pf-v5-c-drawer.pf-m-inline>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel,
  .pf-v5-c-drawer.pf-m-static>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    --pf-v5-c-drawer--m-expanded__panel--BoxShadow: none;
  }

  .pf-v5-c-drawer.pf-m-inline>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel:not(.pf-m-no-border)::after,
  .pf-v5-c-drawer.pf-m-static>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel:not(.pf-m-no-border)::after {
    background-color: var(--pf-v5-c-drawer--m-inline--m-expanded__panel--after--BackgroundColor);
  }

  .pf-v5-c-drawer.pf-m-inline>.pf-v5-c-drawer__main>.pf-v5-c-drawer__content {
    overflow-x: auto;
  }

  .pf-v5-c-drawer.pf-m-inline>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    margin-inline-start: calc(var(--pf-v5-c-drawer__panel--FlexBasis)*-1);
    transform: translateX(100%);
  }

  :where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-drawer.pf-m-inline>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    transform: translateX(calc(100% * var(--pf-v5-global--inverse--multiplier)));
  }

  .pf-v5-c-drawer.pf-m-inline.pf-m-expanded>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    margin-inline-start: 0;
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-inline>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel>.pf-v5-c-drawer__body>.pf-v5-c-drawer__head .pf-v5-c-drawer__close {
    display: unset;
  }

  .pf-v5-c-drawer.pf-m-inline.pf-m-panel-left>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    margin-inline-start: 0;
    margin-inline-end: calc(var(--pf-v5-c-drawer__panel--FlexBasis)*-1);
    transform: translateX(-100%);
  }

  :where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-drawer.pf-m-inline.pf-m-panel-left>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    transform: translateX(calc(-100% * var(--pf-v5-global--inverse--multiplier)));
  }

  .pf-v5-c-drawer.pf-m-inline.pf-m-panel-left.pf-m-expanded>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    margin-inline-end: 0;
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-inline.pf-m-panel-left>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel>.pf-v5-c-drawer__body>.pf-v5-c-drawer__head .pf-v5-c-drawer__close {
    display: unset;
  }

  .pf-v5-c-drawer.pf-m-inline.pf-m-panel-bottom>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    margin-block-end: calc(var(--pf-v5-c-drawer__panel--FlexBasis)*-1);
    transform: translateY(100%);
  }

  .pf-v5-c-drawer.pf-m-inline.pf-m-panel-bottom.pf-m-expanded>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    margin-block-end: 0;
    transform: translateY(0);
  }

  .pf-v5-c-drawer.pf-m-static>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-static.pf-m-panel-left>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    margin-inline-end: 0;
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-static.pf-m-panel-bottom>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-static>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel>.pf-v5-c-drawer__body>.pf-v5-c-drawer__head .pf-v5-c-drawer__close {
    display: none;
  }
}

@media (min-width: 992px) {
  .pf-v5-c-drawer.pf-m-inline-on-lg>.pf-v5-c-drawer__main>.pf-v5-c-drawer__content,
  .pf-v5-c-drawer.pf-m-static-on-lg>.pf-v5-c-drawer__main>.pf-v5-c-drawer__content {
    flex-shrink: 1;
  }

  .pf-v5-c-drawer.pf-m-inline-on-lg>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel,
  .pf-v5-c-drawer.pf-m-static-on-lg>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    --pf-v5-c-drawer--m-expanded__panel--BoxShadow: none;
  }

  .pf-v5-c-drawer.pf-m-inline-on-lg>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel:not(.pf-m-no-border)::after,
  .pf-v5-c-drawer.pf-m-static-on-lg>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel:not(.pf-m-no-border)::after {
    background-color: var(--pf-v5-c-drawer--m-inline--m-expanded__panel--after--BackgroundColor);
  }

  .pf-v5-c-drawer.pf-m-inline-on-lg>.pf-v5-c-drawer__main>.pf-v5-c-drawer__content {
    overflow-x: auto;
  }

  .pf-v5-c-drawer.pf-m-inline-on-lg>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    margin-inline-start: calc(var(--pf-v5-c-drawer__panel--FlexBasis)*-1);
    transform: translateX(100%);
  }

  :where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-drawer.pf-m-inline-on-lg>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    transform: translateX(calc(100% * var(--pf-v5-global--inverse--multiplier)));
  }

  .pf-v5-c-drawer.pf-m-inline-on-lg.pf-m-expanded>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    margin-inline-start: 0;
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-inline-on-lg>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel>.pf-v5-c-drawer__body>.pf-v5-c-drawer__head .pf-v5-c-drawer__close {
    display: unset;
  }

  .pf-v5-c-drawer.pf-m-inline-on-lg.pf-m-panel-left>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    margin-inline-start: 0;
    margin-inline-end: calc(var(--pf-v5-c-drawer__panel--FlexBasis)*-1);
    transform: translateX(-100%);
  }

  :where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-drawer.pf-m-inline-on-lg.pf-m-panel-left>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    transform: translateX(calc(-100% * var(--pf-v5-global--inverse--multiplier)));
  }

  .pf-v5-c-drawer.pf-m-inline-on-lg.pf-m-panel-left.pf-m-expanded>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    margin-inline-end: 0;
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-inline-on-lg.pf-m-panel-left>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel>.pf-v5-c-drawer__body>.pf-v5-c-drawer__head .pf-v5-c-drawer__close {
    display: unset;
  }

  .pf-v5-c-drawer.pf-m-inline-on-lg.pf-m-panel-bottom>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    margin-block-end: calc(var(--pf-v5-c-drawer__panel--FlexBasis)*-1);
    transform: translateY(100%);
  }

  .pf-v5-c-drawer.pf-m-inline-on-lg.pf-m-panel-bottom.pf-m-expanded>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    margin-block-end: 0;
    transform: translateY(0);
  }

  .pf-v5-c-drawer.pf-m-static-on-lg>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-static-on-lg.pf-m-panel-left>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    margin-inline-end: 0;
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-static-on-lg.pf-m-panel-bottom>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-static-on-lg>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel>.pf-v5-c-drawer__body>.pf-v5-c-drawer__head .pf-v5-c-drawer__close {
    display: none;
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-drawer.pf-m-inline-on-xl>.pf-v5-c-drawer__main>.pf-v5-c-drawer__content,
  .pf-v5-c-drawer.pf-m-static-on-xl>.pf-v5-c-drawer__main>.pf-v5-c-drawer__content {
    flex-shrink: 1;
  }

  .pf-v5-c-drawer.pf-m-inline-on-xl>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel,
  .pf-v5-c-drawer.pf-m-static-on-xl>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    --pf-v5-c-drawer--m-expanded__panel--BoxShadow: none;
  }

  .pf-v5-c-drawer.pf-m-inline-on-xl>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel:not(.pf-m-no-border)::after,
  .pf-v5-c-drawer.pf-m-static-on-xl>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel:not(.pf-m-no-border)::after {
    background-color: var(--pf-v5-c-drawer--m-inline--m-expanded__panel--after--BackgroundColor);
  }

  .pf-v5-c-drawer.pf-m-inline-on-xl>.pf-v5-c-drawer__main>.pf-v5-c-drawer__content {
    overflow-x: auto;
  }

  .pf-v5-c-drawer.pf-m-inline-on-xl>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    margin-inline-start: calc(var(--pf-v5-c-drawer__panel--FlexBasis)*-1);
    transform: translateX(100%);
  }

  :where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-drawer.pf-m-inline-on-xl>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    transform: translateX(calc(100% * var(--pf-v5-global--inverse--multiplier)));
  }

  .pf-v5-c-drawer.pf-m-inline-on-xl.pf-m-expanded>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    margin-inline-start: 0;
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-inline-on-xl>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel>.pf-v5-c-drawer__body>.pf-v5-c-drawer__head .pf-v5-c-drawer__close {
    display: unset;
  }

  .pf-v5-c-drawer.pf-m-inline-on-xl.pf-m-panel-left>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    margin-inline-start: 0;
    margin-inline-end: calc(var(--pf-v5-c-drawer__panel--FlexBasis)*-1);
    transform: translateX(-100%);
  }

  :where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-drawer.pf-m-inline-on-xl.pf-m-panel-left>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    transform: translateX(calc(-100% * var(--pf-v5-global--inverse--multiplier)));
  }

  .pf-v5-c-drawer.pf-m-inline-on-xl.pf-m-panel-left.pf-m-expanded>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    margin-inline-end: 0;
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-inline-on-xl.pf-m-panel-left>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel>.pf-v5-c-drawer__body>.pf-v5-c-drawer__head .pf-v5-c-drawer__close {
    display: unset;
  }

  .pf-v5-c-drawer.pf-m-inline-on-xl.pf-m-panel-bottom>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    margin-block-end: calc(var(--pf-v5-c-drawer__panel--FlexBasis)*-1);
    transform: translateY(100%);
  }

  .pf-v5-c-drawer.pf-m-inline-on-xl.pf-m-panel-bottom.pf-m-expanded>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    margin-block-end: 0;
    transform: translateY(0);
  }

  .pf-v5-c-drawer.pf-m-static-on-xl>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-static-on-xl.pf-m-panel-left>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    margin-inline-end: 0;
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-static-on-xl.pf-m-panel-bottom>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-static-on-xl>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel>.pf-v5-c-drawer__body>.pf-v5-c-drawer__head .pf-v5-c-drawer__close {
    display: none;
  }
}

@media (min-width: 1450px) {
  .pf-v5-c-drawer.pf-m-inline-on-2xl>.pf-v5-c-drawer__main>.pf-v5-c-drawer__content,
  .pf-v5-c-drawer.pf-m-static-on-2xl>.pf-v5-c-drawer__main>.pf-v5-c-drawer__content {
    flex-shrink: 1;
  }

  .pf-v5-c-drawer.pf-m-inline-on-2xl>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel,
  .pf-v5-c-drawer.pf-m-static-on-2xl>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    --pf-v5-c-drawer--m-expanded__panel--BoxShadow: none;
  }

  .pf-v5-c-drawer.pf-m-inline-on-2xl>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel:not(.pf-m-no-border)::after,
  .pf-v5-c-drawer.pf-m-static-on-2xl>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel:not(.pf-m-no-border)::after {
    background-color: var(--pf-v5-c-drawer--m-inline--m-expanded__panel--after--BackgroundColor);
  }

  .pf-v5-c-drawer.pf-m-inline-on-2xl>.pf-v5-c-drawer__main>.pf-v5-c-drawer__content {
    overflow-x: auto;
  }

  .pf-v5-c-drawer.pf-m-inline-on-2xl>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    margin-inline-start: calc(var(--pf-v5-c-drawer__panel--FlexBasis)*-1);
    transform: translateX(100%);
  }

  :where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-drawer.pf-m-inline-on-2xl>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    transform: translateX(calc(100% * var(--pf-v5-global--inverse--multiplier)));
  }

  .pf-v5-c-drawer.pf-m-inline-on-2xl.pf-m-expanded>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    margin-inline-start: 0;
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-inline-on-2xl>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel>.pf-v5-c-drawer__body>.pf-v5-c-drawer__head .pf-v5-c-drawer__close {
    display: unset;
  }

  .pf-v5-c-drawer.pf-m-inline-on-2xl.pf-m-panel-left>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    margin-inline-start: 0;
    margin-inline-end: calc(var(--pf-v5-c-drawer__panel--FlexBasis)*-1);
    transform: translateX(-100%);
  }

  :where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-drawer.pf-m-inline-on-2xl.pf-m-panel-left>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    transform: translateX(calc(-100% * var(--pf-v5-global--inverse--multiplier)));
  }

  .pf-v5-c-drawer.pf-m-inline-on-2xl.pf-m-panel-left.pf-m-expanded>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    margin-inline-end: 0;
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-inline-on-2xl.pf-m-panel-left>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel>.pf-v5-c-drawer__body>.pf-v5-c-drawer__head .pf-v5-c-drawer__close {
    display: unset;
  }

  .pf-v5-c-drawer.pf-m-inline-on-2xl.pf-m-panel-bottom>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    margin-block-end: calc(var(--pf-v5-c-drawer__panel--FlexBasis)*-1);
    transform: translateY(100%);
  }

  .pf-v5-c-drawer.pf-m-inline-on-2xl.pf-m-panel-bottom.pf-m-expanded>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    margin-block-end: 0;
    transform: translateY(0);
  }

  .pf-v5-c-drawer.pf-m-static-on-2xl>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-static-on-2xl.pf-m-panel-left>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    margin-inline-end: 0;
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-static-on-2xl.pf-m-panel-bottom>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel {
    transform: translateX(0);
  }

  .pf-v5-c-drawer.pf-m-static-on-2xl>.pf-v5-c-drawer__main>.pf-v5-c-drawer__panel>.pf-v5-c-drawer__body>.pf-v5-c-drawer__head .pf-v5-c-drawer__close {
    display: none;
  }
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-drawer {
  --pf-v5-c-drawer__panel--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-drawer__splitter--BackgroundColor: transparent;
}

:where(.pf-v5-theme-dark) .pf-v5-c-drawer.pf-m-inline,
:where(.pf-v5-theme-dark) .pf-v5-c-drawer.pf-m-static {
  --pf-v5-c-drawer__panel--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
}

.pf-v5-c-dropdown {
  --pf-v5-c-dropdown__toggle--PaddingTop: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-dropdown__toggle--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown__toggle--PaddingBottom: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-dropdown__toggle--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown__toggle--ColumnGap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown__toggle--MinWidth: 0;
  --pf-v5-c-dropdown__toggle--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-dropdown__toggle--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-dropdown__toggle--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-dropdown__toggle--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-dropdown__toggle--BackgroundColor: transparent;
  --pf-v5-c-dropdown__toggle--before--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-dropdown__toggle--before--BorderTopColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-dropdown__toggle--before--BorderRightColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-dropdown__toggle--before--BorderBottomColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-dropdown__toggle--before--BorderLeftColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-dropdown__toggle--hover--before--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-dropdown__toggle--focus--before--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-dropdown__toggle--focus--before--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-dropdown__toggle--active--before--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-dropdown__toggle--active--before--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-dropdown--m-expanded__toggle--before--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-dropdown--m-expanded__toggle--before--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-dropdown__toggle--disabled--BackgroundColor: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-dropdown__toggle--m-plain--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-dropdown__toggle--m-plain--hover--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-dropdown__toggle--m-plain--disabled--Color: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-dropdown__toggle--m-plain--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-dropdown__toggle--m-plain--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-dropdown__toggle--m-plain--child--LineHeight: normal;
  --pf-v5-c-dropdown__toggle--m-primary--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-dropdown__toggle--m-primary--BorderRadius: var(--pf-v5-global--BorderRadius--sm);
  --pf-v5-c-dropdown__toggle--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-dropdown__toggle--m-primary--hover--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-dropdown__toggle--m-primary--focus--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-dropdown__toggle--m-primary--active--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-dropdown--m-expanded__toggle--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-dropdown__toggle--m-primary--disabled--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-dropdown__toggle--m-secondary--Color: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-dropdown__toggle--m-secondary--BorderRadius: var(--pf-v5-global--BorderRadius--sm);
  --pf-v5-c-dropdown__toggle--m-secondary--BackgroundColor: transparent;
  --pf-v5-c-dropdown__toggle--m-secondary--before--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-dropdown__toggle--m-secondary--hover--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-dropdown__toggle--m-secondary--focus--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-dropdown__toggle--m-secondary--active--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-dropdown__toggle--m-secondary--before--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-dropdown__toggle--m-secondary--hover--before--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-dropdown__toggle--m-secondary--focus--before--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-dropdown__toggle--m-secondary--active--before--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-dropdown--m-expanded__toggle--m-secondary--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-dropdown--m-expanded__toggle--m-secondary--before--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-dropdown__toggle-button--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-dropdown__toggle-button--BackgroundColor: transparent;
  --pf-v5-c-dropdown__toggle-progress--Visibility: hidden;
  --pf-v5-c-dropdown__toggle-progress--c-spinner--diameter: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-dropdown__toggle--m-split-button--child--PaddingTop: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-dropdown__toggle--m-split-button--child--PaddingRight: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-dropdown__toggle--m-split-button--child--PaddingBottom: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-dropdown__toggle--m-split-button--child--PaddingLeft: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-dropdown__toggle--m-split-button--child--BackgroundColor: transparent;
  --pf-v5-c-dropdown__toggle--m-split-button--first-child--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown__toggle--m-split-button--last-child--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown__toggle--m-split-button--m-action--child--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown__toggle--m-split-button--m-action--child--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown__toggle--m-split-button--m-action__toggle-button--MarginRight: calc(-1 * var(--pf-v5-global--BorderWidth--sm));
  --pf-v5-c-dropdown__toggle--m-split-button__toggle-text--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown__toggle--m-split-button--child--BorderRadius: var(--pf-v5-global--BorderRadius--sm);
  --pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--BorderRadius: var(--pf-v5-c-dropdown__toggle--m-split-button--child--BorderRadius);
  --pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--BackgroundColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--hover--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--focus--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--active--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--m-expanded--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-dropdown__toggle--m-split-button--m-primary--m-action--child--BorderLeftColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-dropdown__toggle--m-split-button--m-primary--m-action--child--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-dropdown--m-expanded__toggle--m-secondary--m-split-button--child--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-dropdown__toggle--m-secondary--m-split-button--Color: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-dropdown__toggle--m-secondary--m-split-button--hover--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-dropdown__toggle--m-secondary--m-split-button--focus--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-dropdown__toggle--m-secondary--m-split-button--active--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--BorderWidth--base: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--BorderColor--base: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--BorderColor: var(--pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--BorderColor--base);
  --pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--BorderWidth: var(--pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--BorderWidth--base);
  --pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--hover--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--focus--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--active--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-dropdown__toggle-icon--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-dropdown__toggle-icon--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown__toggle-icon--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown__toggle-icon--MarginLeft: 0;
  --pf-v5-c-dropdown--m-top--m-expanded__toggle-icon--Rotate: 180deg;
  --pf-v5-c-dropdown--m-plain__toggle-icon--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-dropdown--m-plain--hover__toggle-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-dropdown__menu--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-c-dropdown__menu--BoxShadow: var(--pf-v5-global--BoxShadow--md);
  --pf-v5-c-dropdown__menu--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown__menu--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown__menu--Top: calc(100% + var(--pf-v5-global--spacer--xs));
  --pf-v5-c-dropdown__menu--ZIndex: var(--pf-v5-global--ZIndex--sm);
  --pf-v5-c-dropdown--m-top__menu--Top: 0;
  --pf-v5-c-dropdown--m-top__menu--TranslateY: calc(-100% - var(--pf-v5-global--spacer--xs));
  --pf-v5-c-dropdown__menu-item--BackgroundColor: transparent;
  --pf-v5-c-dropdown__menu-item--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown__menu-item--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-dropdown__menu-item--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown__menu-item--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-dropdown__menu-item--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-dropdown__menu-item--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-dropdown__menu-item--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-dropdown__menu-item--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-dropdown__menu-item--hover--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-dropdown__menu-item--disabled--Color: var(--pf-v5-global--Color--dark-200);
  --pf-v5-c-dropdown__menu-item--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-300);
  --pf-v5-c-dropdown__menu-item--disabled--BackgroundColor: transparent;
  --pf-v5-c-dropdown__menu-item--m-text--Color: var(--pf-v5-global--Color--dark-200);
  --pf-v5-c-dropdown__menu-item-icon--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown__menu-item-icon--Width: var(--pf-v5-global--icon--FontSize--lg);
  --pf-v5-c-dropdown__menu-item-icon--Height: var(--pf-v5-global--icon--FontSize--lg);
  --pf-v5-c-dropdown__menu-item-description--FontSize: var(--pf-v5-global--FontSize--xs);
  --pf-v5-c-dropdown__menu-item-description--Color: var(--pf-v5-global--Color--dark-200);
  --pf-v5-c-dropdown__group--group--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown__group-title--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown__group-title--PaddingRight: var(--pf-v5-c-dropdown__menu-item--PaddingRight);
  --pf-v5-c-dropdown__group-title--PaddingBottom: var(--pf-v5-c-dropdown__menu-item--PaddingBottom);
  --pf-v5-c-dropdown__group-title--PaddingLeft: var(--pf-v5-c-dropdown__menu-item--PaddingLeft);
  --pf-v5-c-dropdown__group-title--FontSize: var(--pf-v5-global--FontSize--xs);
  --pf-v5-c-dropdown__group-title--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-dropdown__group-title--Color: var(--pf-v5-global--Color--dark-200);
  --pf-v5-c-dropdown--c-divider--MarginTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown--c-divider--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-dropdown__toggle--c-badge__toggle-icon--PaddingRight: 0;
  --pf-v5-c-dropdown__toggle--c-badge__toggle-icon--PaddingLeft: 0;
  --pf-v5-c-dropdown__toggle--c-badge__toggle-icon--MarginLeft: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-dropdown__toggle--c-badge__toggle-icon--MarginRight: 0;
  --pf-v5-c-dropdown--c-menu--Top: calc(100% + var(--pf-v5-global--spacer--xs));
  --pf-v5-c-dropdown--c-menu--ZIndex: var(--pf-v5-global--ZIndex--sm);
  --pf-v5-c-dropdown--m-top--c-menu--Top: 0;
  --pf-v5-c-dropdown--m-top--c-menu--TranslateY: calc(-100% - var(--pf-v5-global--spacer--xs));
  --pf-v5-c-dropdown--m-full-height__toggle--before--BorderTopWidth: 0;
  --pf-v5-c-dropdown--m-full-height__toggle--expanded--before--BorderBottomWidth: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-dropdown--m-full-height__toggle--hover--before--BorderBottomWidth: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-dropdown--m-full-height__toggle--active--before--BorderBottomWidth: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-dropdown--m-full-height__toggle--focus--before--BorderBottomWidth: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-dropdown--m-full-height__toggle--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-dropdown--m-full-height__toggle--PaddingLeft: var(--pf-v5-global--spacer--lg);
  position: relative;
  display: inline-flex;
  align-items: flex-start;
  max-width: 100%;
}

.pf-v5-c-dropdown .pf-v5-c-divider {
  margin-block-start: var(--pf-v5-c-dropdown--c-divider--MarginTop);
  margin-block-end: var(--pf-v5-c-dropdown--c-divider--MarginBottom);
}

.pf-v5-c-dropdown .pf-v5-c-divider:last-child {
  --pf-v5-c-dropdown--c-divider--MarginBottom: 0;
}

.pf-v5-c-dropdown>.pf-v5-c-menu {
  position: absolute;
  inset-block-start: var(--pf-v5-c-dropdown--c-menu--Top);
  z-index: var(--pf-v5-c-dropdown--c-menu--ZIndex);
}

.pf-v5-c-dropdown.pf-m-full-height {
  --pf-v5-c-dropdown__toggle--PaddingRight: var(--pf-v5-c-dropdown--m-full-height__toggle--PaddingRight);
  --pf-v5-c-dropdown__toggle--PaddingLeft: var(--pf-v5-c-dropdown--m-full-height__toggle--PaddingLeft);
  --pf-v5-c-dropdown__toggle--active--before--BorderBottomWidth: var(--pf-v5-c-dropdown--m-full-height__toggle--active--before--BorderBottomWidth);
  --pf-v5-c-dropdown__toggle--focus--before--BorderBottomWidth: var(--pf-v5-c-dropdown--m-full-height__toggle--focus--before--BorderBottomWidth);
  --pf-v5-c-dropdown--m-expanded__toggle--before--BorderBottomWidth: var(--pf-v5-c-dropdown--m-full-height__toggle--expanded--before--BorderBottomWidth);
  display: inline-flex;
  align-items: center;
  height: 100%;
}

.pf-v5-c-dropdown.pf-m-full-height .pf-v5-c-dropdown__toggle {
  align-self: stretch;
}

.pf-v5-c-dropdown.pf-m-full-height .pf-v5-c-dropdown__toggle::before {
  border-block-start-width: var(--pf-v5-c-dropdown--m-full-height__toggle--before--BorderTopWidth);
}

.pf-v5-c-dropdown.pf-m-full-height:hover .pf-v5-c-dropdown__toggle::before {
  border-block-end-width: var(--pf-v5-c-dropdown--m-full-height__toggle--hover--before--BorderBottomWidth);
}

.pf-v5-c-dropdown.pf-m-expanded {
  --pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--BackgroundColor: var(--pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--m-expanded--BackgroundColor);
  --pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--BorderWidth: var(--pf-v5-c-dropdown--m-expanded__toggle--m-secondary--m-split-button--child--before--BorderWidth);
}

.pf-v5-c-dropdown__toggle {
  position: relative;
  display: inline-flex;
  column-gap: var(--pf-v5-c-dropdown__toggle--ColumnGap);
  align-items: center;
  min-width: var(--pf-v5-c-dropdown__toggle--MinWidth);
  max-width: 100%;
  padding-block-start: var(--pf-v5-c-dropdown__toggle--PaddingTop);
  padding-block-end: var(--pf-v5-c-dropdown__toggle--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-dropdown__toggle--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-dropdown__toggle--PaddingRight);
  font-size: var(--pf-v5-c-dropdown__toggle--FontSize);
  font-weight: var(--pf-v5-c-dropdown__toggle--FontWeight);
  line-height: var(--pf-v5-c-dropdown__toggle--LineHeight);
  color: var(--pf-v5-c-dropdown__toggle--Color);
  background-color: var(--pf-v5-c-dropdown__toggle--BackgroundColor);
  border: none;
}

.pf-v5-c-dropdown__toggle::before,
.pf-v5-c-dropdown__toggle.pf-m-action .pf-v5-c-dropdown__toggle-button::before {
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  content: "";
  border: var(--pf-v5-c-dropdown__toggle--before--BorderWidth) solid;
  border-block-start-color: var(--pf-v5-c-dropdown__toggle--before--BorderTopColor);
  border-block-end-color: var(--pf-v5-c-dropdown__toggle--before--BorderBottomColor);
  border-inline-start-color: var(--pf-v5-c-dropdown__toggle--before--BorderLeftColor);
  border-inline-end-color: var(--pf-v5-c-dropdown__toggle--before--BorderRightColor);
}

.pf-v5-c-dropdown__toggle.pf-m-disabled,
.pf-v5-c-dropdown__toggle:disabled {
  --pf-v5-c-dropdown__toggle--m-primary--Color: var(--pf-v5-c-dropdown__toggle--m-primary--disabled--Color);
  --pf-v5-c-dropdown__toggle--before--BorderBottomColor: transparent;
  pointer-events: none;
}

.pf-v5-c-dropdown__toggle.pf-m-disabled:not(.pf-m-plain),
.pf-v5-c-dropdown__toggle.pf-m-disabled.pf-m-plain.pf-m-text,
.pf-v5-c-dropdown__toggle:disabled:not(.pf-m-plain),
.pf-v5-c-dropdown__toggle:disabled.pf-m-plain.pf-m-text {
  --pf-v5-c-dropdown__toggle--BackgroundColor: var(--pf-v5-c-dropdown__toggle--disabled--BackgroundColor);
}

.pf-v5-c-dropdown__toggle.pf-m-disabled:not(.pf-m-plain)::before,
.pf-v5-c-dropdown__toggle.pf-m-disabled.pf-m-plain.pf-m-text::before,
.pf-v5-c-dropdown__toggle:disabled:not(.pf-m-plain)::before,
.pf-v5-c-dropdown__toggle:disabled.pf-m-plain.pf-m-text::before {
  border: 0;
}

.pf-v5-c-dropdown__toggle.pf-m-split-button {
  --pf-v5-c-dropdown__toggle--ColumnGap: 0;
  padding: 0;
}

.pf-v5-c-dropdown__toggle.pf-m-split-button:not(.pf-m-disabled) {
  background-color: var(--pf-v5-c-dropdown__toggle--m-split-button--child--BackgroundColor);
}

.pf-v5-c-dropdown__toggle.pf-m-split-button>* {
  position: relative;
  padding-block-start: var(--pf-v5-c-dropdown__toggle--m-split-button--child--PaddingTop);
  padding-block-end: var(--pf-v5-c-dropdown__toggle--m-split-button--child--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-dropdown__toggle--m-split-button--child--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-dropdown__toggle--m-split-button--child--PaddingRight);
}

.pf-v5-c-dropdown__toggle.pf-m-split-button>*:first-child {
  --pf-v5-c-dropdown__toggle--m-split-button--child--PaddingLeft: var(--pf-v5-c-dropdown__toggle--m-split-button--first-child--PaddingLeft);
}

.pf-v5-c-dropdown__toggle.pf-m-split-button>*:last-child {
  --pf-v5-c-dropdown__toggle--m-split-button--child--PaddingRight: var(--pf-v5-c-dropdown__toggle--m-split-button--last-child--PaddingRight);
}

.pf-v5-c-dropdown__toggle.pf-m-split-button.pf-m-action {
  --pf-v5-c-dropdown__toggle--m-split-button--child--PaddingRight: var(--pf-v5-c-dropdown__toggle--m-split-button--m-action--child--PaddingRight);
  --pf-v5-c-dropdown__toggle--m-split-button--child--PaddingLeft: var(--pf-v5-c-dropdown__toggle--m-split-button--m-action--child--PaddingLeft);
}

.pf-v5-c-dropdown__toggle.pf-m-split-button.pf-m-action .pf-v5-c-dropdown__toggle-button {
  margin-inline-end: var(--pf-v5-c-dropdown__toggle--m-split-button--m-action__toggle-button--MarginRight);
}

.pf-v5-c-dropdown__toggle.pf-m-split-button.pf-m-action .pf-v5-c-dropdown__toggle-button::before {
  border-inline-start: 0;
}

.pf-v5-c-dropdown__toggle.pf-m-split-button.pf-m-action .pf-v5-c-dropdown__toggle-button:last-child {
  --pf-v5-c-dropdown__toggle--m-split-button--m-action__toggle-button--MarginRight: 0;
}

.pf-v5-c-dropdown__toggle.pf-m-split-button.pf-m-action.pf-m-primary>:not(:first-child) {
  border-inline-start: var(--pf-v5-c-dropdown__toggle--m-split-button--m-primary--m-action--child--BorderLeftWidth) solid var(--pf-v5-c-dropdown__toggle--m-split-button--m-primary--m-action--child--BorderLeftColor);
}

.pf-v5-c-dropdown__toggle.pf-m-split-button.pf-m-action.pf-m-primary.pf-m-disabled,
.pf-v5-c-dropdown__toggle.pf-m-split-button.pf-m-action.pf-m-primary[disabled] {
  --pf-v5-c-dropdown__toggle--m-split-button--m-primary--m-action--child--BorderLeftWidth: 0;
}

.pf-v5-c-dropdown__toggle.pf-m-split-button .pf-v5-c-dropdown__toggle-check {
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}

.pf-v5-c-dropdown__toggle.pf-m-split-button .pf-v5-c-dropdown__toggle-check.pf-m-in-progress {
  --pf-v5-c-dropdown__toggle--m-split-button__toggle-check__input--Visibility: hidden;
  --pf-v5-c-dropdown__toggle-progress--Visibility: visible;
}

.pf-v5-c-dropdown__toggle.pf-m-split-button .pf-v5-c-dropdown__toggle-check>input,
.pf-v5-c-dropdown__toggle.pf-m-split-button .pf-v5-c-dropdown__toggle-check .pf-v5-c-check {
  --pf-v5-c-check__input--TranslateY: none;
  align-self: revert;
  width: auto;
  cursor: pointer;
  visibility: var(--pf-v5-c-dropdown__toggle--m-split-button__toggle-check__input--Visibility, unset);
}

.pf-v5-c-dropdown__toggle.pf-m-split-button .pf-v5-c-dropdown__toggle-button {
  color: var(--pf-v5-c-dropdown__toggle-button--Color);
  background-color: var(--pf-v5-c-dropdown__toggle-button--BackgroundColor);
  border: 0;
}

.pf-v5-c-dropdown__toggle.pf-m-split-button .pf-v5-c-dropdown__toggle-text {
  margin-inline-start: var(--pf-v5-c-dropdown__toggle--m-split-button__toggle-text--MarginLeft);
}

.pf-v5-c-dropdown__toggle.pf-m-primary.pf-m-split-button:not(.pf-m-disabled) {
  --pf-v5-c-dropdown__toggle--before--BorderWidth: 0;
  --pf-v5-c-dropdown--m-expanded__toggle--before--BorderBottomWidth: 0;
}

.pf-v5-c-dropdown__toggle.pf-m-primary.pf-m-split-button:not(.pf-m-disabled)>* {
  background-color: var(--pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--BackgroundColor);
  border: 0;
}

.pf-v5-c-dropdown__toggle.pf-m-primary.pf-m-split-button:not(.pf-m-disabled)>*:hover {
  --pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--BackgroundColor: var(--pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--hover--BackgroundColor);
}

.pf-v5-c-dropdown__toggle.pf-m-primary.pf-m-split-button:not(.pf-m-disabled)>*:focus {
  --pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--BackgroundColor: var(--pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--focus--BackgroundColor);
}

.pf-v5-c-dropdown__toggle.pf-m-primary.pf-m-split-button:not(.pf-m-disabled)>*:active,
.pf-v5-c-dropdown__toggle.pf-m-primary.pf-m-split-button:not(.pf-m-disabled)>*.pf-m-active {
  --pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--BackgroundColor: var(--pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--focus--BackgroundColor);
}

.pf-v5-c-dropdown__toggle.pf-m-primary.pf-m-split-button:not(.pf-m-disabled)>:first-child {
  border-start-start-radius: var(--pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--BorderRadius);
  border-end-start-radius: var(--pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--BorderRadius);
}

.pf-v5-c-dropdown__toggle.pf-m-primary.pf-m-split-button:not(.pf-m-disabled)>:last-child {
  border-start-end-radius: var(--pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--BorderRadius);
  border-end-end-radius: var(--pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--BorderRadius);
}

.pf-v5-c-dropdown__toggle:not(.pf-m-action):not(.pf-m-secondary):hover::before,
.pf-v5-c-dropdown__toggle.pf-m-action .pf-v5-c-dropdown__toggle-button:hover::before {
  --pf-v5-c-dropdown__toggle--before--BorderBottomColor: var(--pf-v5-c-dropdown__toggle--hover--before--BorderBottomColor);
}

.pf-v5-c-dropdown__toggle:not(.pf-m-action):not(.pf-m-secondary):focus::before,
.pf-v5-c-dropdown__toggle.pf-m-action .pf-v5-c-dropdown__toggle-button:focus::before {
  --pf-v5-c-dropdown__toggle--before--BorderBottomColor: var(--pf-v5-c-dropdown__toggle--focus--before--BorderBottomColor);
  border-block-end-width: var(--pf-v5-c-dropdown__toggle--focus--before--BorderBottomWidth);
}

.pf-v5-c-dropdown__toggle:not(.pf-m-action):not(.pf-m-secondary):active::before,
.pf-v5-c-dropdown__toggle:not(.pf-m-action):not(.pf-m-secondary).pf-m-active::before,
.pf-v5-c-dropdown__toggle.pf-m-action .pf-v5-c-dropdown__toggle-button:active::before {
  --pf-v5-c-dropdown__toggle--before--BorderBottomColor: var(--pf-v5-c-dropdown__toggle--active--before--BorderBottomColor);
  border-block-end-width: var(--pf-v5-c-dropdown__toggle--active--before--BorderBottomWidth);
}

.pf-m-expanded>.pf-v5-c-dropdown__toggle:not(.pf-m-action):not(.pf-m-secondary)::before,
.pf-m-expanded>.pf-v5-c-dropdown__toggle.pf-m-action .pf-v5-c-dropdown__toggle-button::before {
  --pf-v5-c-dropdown__toggle--before--BorderBottomColor: var(--pf-v5-c-dropdown--m-expanded__toggle--before--BorderBottomColor);
  border-block-end-width: var(--pf-v5-c-dropdown--m-expanded__toggle--before--BorderBottomWidth);
}

.pf-v5-c-dropdown__toggle.pf-m-plain {
  --pf-v5-c-dropdown__toggle-icon--Color: var(--pf-v5-c-dropdown--m-plain__toggle-icon--Color);
}

.pf-v5-c-dropdown__toggle.pf-m-plain:not(.pf-m-text) {
  --pf-v5-c-dropdown__toggle--PaddingRight: var(--pf-v5-c-dropdown__toggle--m-plain--PaddingRight);
  --pf-v5-c-dropdown__toggle--PaddingLeft: var(--pf-v5-c-dropdown__toggle--m-plain--PaddingLeft);
  display: inline-block;
  color: var(--pf-v5-c-dropdown__toggle--m-plain--Color);
}

.pf-v5-c-dropdown__toggle.pf-m-plain:not(.pf-m-text)>* {
  line-height: var(--pf-v5-c-dropdown__toggle--m-plain--child--LineHeight);
}

.pf-v5-c-dropdown__toggle.pf-m-plain::before {
  border: 0;
}

.pf-v5-c-dropdown__toggle.pf-m-plain:hover,
.pf-v5-c-dropdown__toggle.pf-m-plain:focus,
.pf-v5-c-dropdown__toggle.pf-m-plain:active,
.pf-v5-c-dropdown__toggle.pf-m-plain.pf-m-active,
.pf-m-expanded>.pf-v5-c-dropdown__toggle.pf-m-plain {
  --pf-v5-c-dropdown__toggle--m-plain--Color: var(--pf-v5-c-dropdown__toggle--m-plain--hover--Color);
  --pf-v5-c-dropdown--m-plain__toggle-icon--Color: var(--pf-v5-c-dropdown--m-plain--hover__toggle-icon--Color);
}

.pf-v5-c-dropdown__toggle.pf-m-plain.pf-m-disabled,
.pf-v5-c-dropdown__toggle.pf-m-plain:disabled {
  --pf-v5-c-dropdown__toggle--m-plain--Color: var(--pf-v5-c-dropdown__toggle--m-plain--disabled--Color);
}

.pf-v5-c-dropdown__toggle.pf-m-primary {
  --pf-v5-c-dropdown__toggle--Color: var(--pf-v5-c-dropdown__toggle--m-primary--Color);
  --pf-v5-c-dropdown__toggle--BackgroundColor: var(--pf-v5-c-dropdown__toggle--m-primary--BackgroundColor);
  --pf-v5-c-dropdown__toggle-button--Color: var(--pf-v5-c-dropdown__toggle--m-primary--Color);
  border-radius: var(--pf-v5-c-dropdown__toggle--m-primary--BorderRadius);
}

.pf-v5-c-dropdown__toggle.pf-m-primary::before,
.pf-v5-c-dropdown__toggle.pf-m-primary .pf-v5-c-dropdown__toggle-button::before {
  border: 0;
}

.pf-v5-c-dropdown__toggle.pf-m-primary:hover {
  --pf-v5-c-dropdown__toggle--BackgroundColor: var(--pf-v5-c-dropdown__toggle--m-primary--hover--BackgroundColor);
}

.pf-v5-c-dropdown__toggle.pf-m-primary:focus {
  --pf-v5-c-dropdown__toggle--BackgroundColor: var(--pf-v5-c-dropdown__toggle--m-primary--focus--BackgroundColor);
}

.pf-v5-c-dropdown__toggle.pf-m-primary:active,
.pf-v5-c-dropdown__toggle.pf-m-primary.pf-m-active {
  --pf-v5-c-dropdown__toggle--BackgroundColor: var(--pf-v5-c-dropdown__toggle--m-primary--active--BackgroundColor);
}

.pf-m-expanded>.pf-v5-c-dropdown__toggle.pf-m-primary {
  --pf-v5-c-dropdown__toggle--BackgroundColor: var(--pf-v5-c-dropdown--m-expanded__toggle--m-primary--BackgroundColor);
}

.pf-v5-c-dropdown__toggle.pf-m-secondary {
  border-radius: var(--pf-v5-c-dropdown__toggle--m-secondary--BorderRadius);
}

.pf-v5-c-dropdown__toggle.pf-m-secondary:not(.pf-m-disabled):not([disabled]) {
  --pf-v5-c-dropdown__toggle--Color: var(--pf-v5-c-dropdown__toggle--m-secondary--Color);
  --pf-v5-c-dropdown__toggle--BackgroundColor: var(--pf-v5-c-dropdown__toggle--m-secondary--BackgroundColor);
}

.pf-v5-c-dropdown__toggle.pf-m-secondary:not(.pf-m-disabled):not([disabled])::before {
  border: var(--pf-v5-c-dropdown__toggle--m-secondary--before--BorderWidth) solid var(--pf-v5-c-dropdown__toggle--m-secondary--before--BorderColor);
  border-radius: var(--pf-v5-c-dropdown__toggle--m-secondary--BorderRadius);
}

.pf-v5-c-dropdown__toggle.pf-m-secondary:hover {
  --pf-v5-c-dropdown__toggle--m-secondary--before--BorderWidth: var(--pf-v5-c-dropdown__toggle--m-secondary--hover--before--BorderWidth);
}

.pf-v5-c-dropdown__toggle.pf-m-secondary:focus {
  --pf-v5-c-dropdown__toggle--m-secondary--before--BorderWidth: var(--pf-v5-c-dropdown__toggle--m-secondary--focus--before--BorderWidth);
}

.pf-v5-c-dropdown__toggle.pf-m-secondary:active,
.pf-v5-c-dropdown__toggle.pf-m-secondary.pf-m-active {
  --pf-v5-c-dropdown__toggle--m-secondary--before--BorderWidth: var(--pf-v5-c-dropdown__toggle--m-secondary--active--before--BorderWidth);
}

.pf-m-expanded>.pf-v5-c-dropdown__toggle.pf-m-secondary {
  --pf-v5-c-dropdown__toggle--m-secondary--before--BorderWidth: var(--pf-v5-c-dropdown--m-expanded__toggle--m-secondary--before--BorderWidth);
  --pf-v5-c-dropdown__toggle--m-secondary--before--BorderColor: var(--pf-v5-c-dropdown--m-expanded__toggle--m-secondary--before--BorderColor);
}

.pf-v5-c-dropdown__toggle.pf-m-secondary.pf-m-split-button:not(.pf-m-disabled) {
  --pf-v5-c-dropdown__toggle-button--Color: var(--pf-v5-c-dropdown__toggle--m-secondary--m-split-button--Color);
}

.pf-v5-c-dropdown__toggle.pf-m-secondary.pf-m-split-button:not(.pf-m-disabled):hover {
  --pf-v5-c-dropdown__toggle--m-secondary--hover--before--BorderWidth: var(--pf-v5-c-dropdown__toggle--m-secondary--m-split-button--hover--before--BorderWidth);
}

.pf-v5-c-dropdown__toggle.pf-m-secondary.pf-m-split-button:not(.pf-m-disabled):focus {
  --pf-v5-c-dropdown__toggle--m-secondary--focus--before--BorderWidth: var(--pf-v5-c-dropdown__toggle--m-secondary--m-split-button--focus--before--BorderWidth);
}

.pf-v5-c-dropdown__toggle.pf-m-secondary.pf-m-split-button:not(.pf-m-disabled):active {
  --pf-v5-c-dropdown__toggle--m-secondary--active--before--BorderWidth: var(--pf-v5-c-dropdown__toggle--m-secondary--m-split-button--active--before--BorderWidth);
}

.pf-v5-c-dropdown__toggle.pf-m-secondary.pf-m-split-button:not(.pf-m-disabled)>*::before {
  border: var(--pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--BorderWidth) solid var(--pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--BorderColor);
}

.pf-v5-c-dropdown__toggle.pf-m-secondary.pf-m-split-button:not(.pf-m-disabled)>*:hover {
  --pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--BorderWidth: var(--pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--hover--BorderWidth);
}

.pf-v5-c-dropdown__toggle.pf-m-secondary.pf-m-split-button:not(.pf-m-disabled)>*:focus {
  --pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--BorderWidth: var(--pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--focus--BorderWidth);
}

.pf-v5-c-dropdown__toggle.pf-m-secondary.pf-m-split-button:not(.pf-m-disabled)>*:active {
  --pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--BorderWidth: var(--pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--active--BorderWidth);
}

.pf-v5-c-dropdown__toggle.pf-m-secondary.pf-m-split-button:not(.pf-m-disabled)>*:first-child::before {
  border-start-start-radius: var(--pf-v5-c-dropdown__toggle--m-split-button--child--BorderRadius);
  border-end-start-radius: var(--pf-v5-c-dropdown__toggle--m-split-button--child--BorderRadius);
}

.pf-v5-c-dropdown__toggle.pf-m-secondary.pf-m-split-button:not(.pf-m-disabled)>*:last-child::before {
  border-start-end-radius: var(--pf-v5-c-dropdown__toggle--m-split-button--child--BorderRadius);
  border-end-end-radius: var(--pf-v5-c-dropdown__toggle--m-split-button--child--BorderRadius);
}

.pf-v5-c-dropdown__toggle.pf-m-secondary.pf-m-split-button:not(.pf-m-disabled)>*:not(:first-child)::before {
  border-inline-start: var(--pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--BorderWidth--base) solid var(--pf-v5-c-dropdown__toggle--m-secondary--m-split-button--child--before--BorderColor--base);
}

.pf-v5-c-dropdown__toggle.pf-m-secondary.pf-m-split-button:not(.pf-m-disabled)>*:not(:last-child)::before {
  border-inline-end: 0;
}

.pf-v5-c-dropdown__toggle.pf-m-secondary.pf-m-split-button:not(.pf-m-disabled).pf-m-action::before {
  border: 0;
}

.pf-v5-c-dropdown__toggle>.pf-v5-c-badge {
  --pf-v5-c-dropdown__toggle-icon--PaddingRight: var(--pf-v5-c-dropdown__toggle--c-badge__toggle-icon--PaddingRight);
  --pf-v5-c-dropdown__toggle-icon--PaddingLeft: var(--pf-v5-c-dropdown__toggle--c-badge__toggle-icon--PaddingLeft);
  --pf-v5-c-dropdown__toggle-icon--MarginLeft: var(--pf-v5-c-dropdown__toggle--c-badge__toggle-icon--MarginLeft);
}

.pf-v5-c-dropdown__toggle .pf-v5-c-dropdown__toggle-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pf-v5-c-dropdown__toggle-button {
  align-self: baseline;
}

.pf-v5-c-dropdown__toggle-text {
  flex: 1 1 auto;
  text-align: start;
}

.pf-v5-c-dropdown__toggle-icon {
  padding-inline-start: var(--pf-v5-c-dropdown__toggle-icon--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-dropdown__toggle-icon--PaddingRight);
  margin-inline-start: var(--pf-v5-c-dropdown__toggle-icon--MarginLeft);
  line-height: var(--pf-v5-c-dropdown__toggle-icon--LineHeight);
  color: var(--pf-v5-c-dropdown__toggle-icon--Color, inherit);
}

.pf-v5-c-dropdown.pf-m-top.pf-m-expanded .pf-v5-c-dropdown__toggle-icon {
  transform: rotate(var(--pf-v5-c-dropdown--m-top--m-expanded__toggle-icon--Rotate));
}

.pf-v5-c-dropdown__toggle-image {
  display: inline-flex;
  flex-shrink: 0;
  line-height: 1;
}

.pf-v5-c-dropdown__toggle-progress {
  position: absolute;
  inset-inline-start: var(--pf-v5-c-dropdown__toggle--m-split-button--child--PaddingLeft);
  visibility: var(--pf-v5-c-dropdown__toggle-progress--Visibility);
}

.pf-v5-c-dropdown__toggle-progress .pf-v5-c-spinner {
  --pf-v5-c-spinner--diameter: var(--pf-v5-c-dropdown__toggle-progress--c-spinner--diameter);
}

.pf-v5-c-dropdown__menu {
  position: absolute;
  inset-block-start: var(--pf-v5-c-dropdown__menu--Top);
  z-index: var(--pf-v5-c-dropdown__menu--ZIndex);
  padding-block-start: var(--pf-v5-c-dropdown__menu--PaddingTop);
  padding-block-end: var(--pf-v5-c-dropdown__menu--PaddingBottom);
  background: var(--pf-v5-c-dropdown__menu--BackgroundColor);
  background-clip: padding-box;
  box-shadow: var(--pf-v5-c-dropdown__menu--BoxShadow);
}

.pf-v5-c-dropdown__menu.pf-m-static {
  --pf-v5-c-dropdown--m-top__menu--TranslateY: 0;
  position: static;
  inset-block-start: auto;
  inset-block-end: auto;
  inset-inline-start: auto;
  inset-inline-end: auto;
  z-index: auto;
  min-width: min-content;
}

.pf-v5-c-dropdown .pf-v5-c-menu,
.pf-v5-c-dropdown__menu {
  min-width: 100%;
}

.pf-v5-c-dropdown .pf-v5-c-menu.pf-m-align-right,
.pf-v5-c-dropdown__menu.pf-m-align-right {
  inset-inline-end: 0;
}

.pf-v5-c-dropdown .pf-v5-c-menu.pf-m-align-left,
.pf-v5-c-dropdown__menu.pf-m-align-left {
  inset-inline-end: auto;
}

@media (min-width: 576px) {
  .pf-v5-c-dropdown .pf-v5-c-menu.pf-m-align-right-on-sm,
  .pf-v5-c-dropdown__menu.pf-m-align-right-on-sm {
    inset-inline-end: 0;
  }

  .pf-v5-c-dropdown .pf-v5-c-menu.pf-m-align-left-on-sm,
  .pf-v5-c-dropdown__menu.pf-m-align-left-on-sm {
    inset-inline-end: auto;
  }
}

@media (min-width: 768px) {
  .pf-v5-c-dropdown .pf-v5-c-menu.pf-m-align-right-on-md,
  .pf-v5-c-dropdown__menu.pf-m-align-right-on-md {
    inset-inline-end: 0;
  }

  .pf-v5-c-dropdown .pf-v5-c-menu.pf-m-align-left-on-md,
  .pf-v5-c-dropdown__menu.pf-m-align-left-on-md {
    inset-inline-end: auto;
  }
}

@media (min-width: 992px) {
  .pf-v5-c-dropdown .pf-v5-c-menu.pf-m-align-right-on-lg,
  .pf-v5-c-dropdown__menu.pf-m-align-right-on-lg {
    inset-inline-end: 0;
  }

  .pf-v5-c-dropdown .pf-v5-c-menu.pf-m-align-left-on-lg,
  .pf-v5-c-dropdown__menu.pf-m-align-left-on-lg {
    inset-inline-end: auto;
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-dropdown .pf-v5-c-menu.pf-m-align-right-on-xl,
  .pf-v5-c-dropdown__menu.pf-m-align-right-on-xl {
    inset-inline-end: 0;
  }

  .pf-v5-c-dropdown .pf-v5-c-menu.pf-m-align-left-on-xl,
  .pf-v5-c-dropdown__menu.pf-m-align-left-on-xl {
    inset-inline-end: auto;
  }
}

@media (min-width: 1450px) {
  .pf-v5-c-dropdown .pf-v5-c-menu.pf-m-align-right-on-2xl,
  .pf-v5-c-dropdown__menu.pf-m-align-right-on-2xl {
    inset-inline-end: 0;
  }

  .pf-v5-c-dropdown .pf-v5-c-menu.pf-m-align-left-on-2xl,
  .pf-v5-c-dropdown__menu.pf-m-align-left-on-2xl {
    inset-inline-end: auto;
  }
}

.pf-v5-c-dropdown.pf-m-top .pf-v5-c-dropdown__menu {
  --pf-v5-c-dropdown__menu--Top: var(--pf-v5-c-dropdown--m-top__menu--Top);
  transform: translateY(var(--pf-v5-c-dropdown--m-top__menu--TranslateY));
}

.pf-v5-c-dropdown.pf-m-top>.pf-v5-c-menu {
  --pf-v5-c-dropdown--c-menu--Top: var(--pf-v5-c-dropdown--m-top--c-menu--Top);
  transform: translateY(var(--pf-v5-c-dropdown--m-top--c-menu--TranslateY));
}

.pf-v5-c-dropdown__menu-item {
  display: block;
  width: 100%;
  padding-block-start: var(--pf-v5-c-dropdown__menu-item--PaddingTop);
  padding-block-end: var(--pf-v5-c-dropdown__menu-item--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-dropdown__menu-item--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-dropdown__menu-item--PaddingRight);
  font-size: var(--pf-v5-c-dropdown__menu-item--FontSize);
  font-weight: var(--pf-v5-c-dropdown__menu-item--FontWeight);
  line-height: var(--pf-v5-c-dropdown__menu-item--LineHeight);
  color: var(--pf-v5-c-dropdown__menu-item--Color);
  text-align: start;
  white-space: nowrap;
  background-color: var(--pf-v5-c-dropdown__menu-item--BackgroundColor);
  border: none;
}

.pf-v5-c-dropdown__menu-item:hover,
.pf-v5-c-dropdown__menu-item:focus {
  --pf-v5-c-dropdown__menu-item--Color: var(--pf-v5-c-dropdown__menu-item--hover--Color);
  --pf-v5-c-dropdown__menu-item--BackgroundColor: var(--pf-v5-c-dropdown__menu-item--hover--BackgroundColor);
  text-decoration: none;
}

.pf-v5-c-dropdown__menu-item:disabled,
.pf-v5-c-dropdown__menu-item.pf-m-disabled,
.pf-v5-c-dropdown__menu-item.pf-m-aria-disabled {
  --pf-v5-c-dropdown__menu-item--Color: var(--pf-v5-c-dropdown__menu-item--disabled--Color);
  --pf-v5-c-dropdown__menu-item--BackgroundColor: var(--pf-v5-c-dropdown__menu-item--disabled--BackgroundColor);
}

.pf-v5-c-dropdown__menu-item:disabled,
.pf-v5-c-dropdown__menu-item.pf-m-disabled {
  pointer-events: none;
}

.pf-v5-c-dropdown__menu-item.pf-m-aria-disabled {
  cursor: default;
}

.pf-v5-c-dropdown__menu-item.pf-m-icon {
  display: flex;
  align-items: center;
}

.pf-v5-c-dropdown__menu-item.pf-m-icon.pf-m-description {
  flex-direction: column;
  align-items: flex-start;
}

.pf-v5-c-dropdown__menu-item.pf-m-icon .pf-v5-c-dropdown__menu-item-main {
  display: flex;
  align-items: center;
}

.pf-v5-c-dropdown__menu-item.pf-m-text {
  --pf-v5-c-dropdown__menu-item--Color: var(--pf-v5-c-dropdown__menu-item--m-text--Color);
}

.pf-v5-c-dropdown__menu-item.pf-m-text:hover,
.pf-v5-c-dropdown__menu-item.pf-m-text:focus {
  --pf-v5-c-dropdown__menu-item--BackgroundColor: transparent;
}

.pf-v5-c-dropdown__menu-item-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--pf-v5-c-dropdown__menu-item-icon--Width);
  height: var(--pf-v5-c-dropdown__menu-item-icon--Height);
  margin-inline-end: var(--pf-v5-c-dropdown__menu-item-icon--MarginRight);
}

.pf-v5-c-dropdown__menu-item-icon>* {
  max-width: 100%;
  max-height: 100%;
}

.pf-v5-c-dropdown__menu-item-description {
  font-size: var(--pf-v5-c-dropdown__menu-item-description--FontSize);
  color: var(--pf-v5-c-dropdown__menu-item-description--Color);
}

.pf-v5-c-dropdown__group+.pf-v5-c-dropdown__group {
  padding-block-start: var(--pf-v5-c-dropdown__group--group--PaddingTop);
}

.pf-v5-c-dropdown__group-title {
  padding-block-start: var(--pf-v5-c-dropdown__group-title--PaddingTop);
  padding-block-end: var(--pf-v5-c-dropdown__group-title--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-dropdown__group-title--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-dropdown__group-title--PaddingRight);
  font-size: var(--pf-v5-c-dropdown__group-title--FontSize);
  font-weight: var(--pf-v5-c-dropdown__group-title--FontWeight);
  color: var(--pf-v5-c-dropdown__group-title--Color);
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-dropdown {
  --pf-v5-c-dropdown__toggle--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-dropdown__toggle--before--BorderTopColor: transparent;
  --pf-v5-c-dropdown__toggle--before--BorderRightColor: transparent;
  --pf-v5-c-dropdown__toggle--before--BorderBottomColor: var(--pf-v5-global--BorderColor--400);
  --pf-v5-c-dropdown__toggle--before--BorderLeftColor: transparent;
  --pf-v5-c-dropdown__toggle--disabled--BackgroundColor: var(--pf-v5-global--palette--black-500);
  --pf-v5-c-dropdown__toggle--m-split-button--m-primary--child--BackgroundColor: var(--pf-v5-global--primary-color--300);
  --pf-v5-c-dropdown__toggle--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
  --pf-v5-c-dropdown__toggle--m-primary--Color: var(--pf-v5-global--primary-color--400);
  --pf-v5-c-dropdown__menu--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-dropdown__menu--Top: 100%;
  --pf-v5-c-dropdown--m-top__menu--TranslateY: -100%;
  --pf-v5-c-dropdown__menu-item--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
}

:where(.pf-v5-theme-dark) .pf-v5-c-dropdown__toggle:not(.pf-m-plain):disabled,
:where(.pf-v5-theme-dark) .pf-v5-c-dropdown__toggle:not(.pf-m-plain).pf-m-disabled {
  color: var(--pf-v5-global--palette--black-100);
}

:where(.pf-v5-theme-dark) .pf-v5-c-dropdown__toggle.pf-m-plain {
  background: transparent;
}

.pf-v5-c-empty-state {
  --pf-v5-c-empty-state--PaddingTop: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-empty-state--PaddingRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-empty-state--PaddingBottom: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-empty-state--PaddingLeft: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-empty-state--m-xs--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-empty-state--m-xs--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-empty-state--m-xs--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-empty-state--m-xs--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-empty-state__content--MaxWidth: none;
  --pf-v5-c-empty-state--m-xs__content--MaxWidth: 21.875rem;
  --pf-v5-c-empty-state--m-sm__content--MaxWidth: 25rem;
  --pf-v5-c-empty-state--m-lg__content--MaxWidth: 37.5rem;
  --pf-v5-c-empty-state__icon--MarginBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-empty-state__icon--FontSize: var(--pf-v5-global--icon--FontSize--xl);
  --pf-v5-c-empty-state__icon--Color: var(--pf-v5-global--icon--Color--light);
  --pf-v5-c-empty-state--m-xs__icon--MarginBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-empty-state--m-xl__icon--MarginBottom: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-empty-state--m-xl__icon--FontSize: 6.25rem;
  --pf-v5-c-empty-state__title-text--FontFamily: var(--pf-v5-global--FontFamily--heading);
  --pf-v5-c-empty-state__title-text--FontSize: var(--pf-v5-global--FontSize--xl);
  --pf-v5-c-empty-state__title-text--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-empty-state__title-text--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-empty-state--m-xs__title-text--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-empty-state--m-xl__title-text--FontSize: var(--pf-v5-global--FontSize--4xl);
  --pf-v5-c-empty-state--m-xl__title-text--LineHeight: var(--pf-v5-global--LineHeight--sm);
  --pf-v5-c-empty-state__body--MarginTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-empty-state__body--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-empty-state--body--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-empty-state--m-xs__body--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-empty-state--m-xs__body--MarginTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-empty-state--m-xl__body--FontSize: var(--pf-v5-global--FontSize--xl);
  --pf-v5-c-empty-state--m-xl__body--MarginTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-empty-state__footer--RowGap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-empty-state__footer--MarginTop: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-empty-state--m-xs__footer--MarginTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-empty-state__actions--RowGap: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-empty-state__actions--ColumnGap: var(--pf-v5-global--spacer--xs);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block-start: var(--pf-v5-c-empty-state--PaddingTop);
  padding-block-end: var(--pf-v5-c-empty-state--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-empty-state--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-empty-state--PaddingRight);
  text-align: center;
}

.pf-v5-c-empty-state.pf-m-xs {
  --pf-v5-c-empty-state--PaddingTop: var(--pf-v5-c-empty-state--m-xs--PaddingTop);
  --pf-v5-c-empty-state--PaddingRight: var(--pf-v5-c-empty-state--m-xs--PaddingRight);
  --pf-v5-c-empty-state--PaddingBottom: var(--pf-v5-c-empty-state--m-xs--PaddingBottom);
  --pf-v5-c-empty-state--PaddingLeft: var(--pf-v5-c-empty-state--m-xs--PaddingLeft);
  --pf-v5-c-empty-state__title-text--FontSize: var(--pf-v5-c-empty-state--m-xs__title-text--FontSize);
  --pf-v5-c-empty-state__content--MaxWidth: var(--pf-v5-c-empty-state--m-xs__content--MaxWidth);
  --pf-v5-c-empty-state__icon--MarginBottom: var(--pf-v5-c-empty-state--m-xs__icon--MarginBottom);
  --pf-v5-c-empty-state__body--MarginTop: var(--pf-v5-c-empty-state--m-xs__body--MarginTop);
  --pf-v5-c-empty-state--body--FontSize: var(--pf-v5-c-empty-state--m-xs__body--FontSize);
  --pf-v5-c-empty-state__footer--MarginTop: var(--pf-v5-c-empty-state--m-xs__footer--MarginTop);
}

.pf-v5-c-empty-state.pf-m-sm {
  --pf-v5-c-empty-state__content--MaxWidth: var(--pf-v5-c-empty-state--m-sm__content--MaxWidth);
}

.pf-v5-c-empty-state.pf-m-lg {
  --pf-v5-c-empty-state__content--MaxWidth: var(--pf-v5-c-empty-state--m-lg__content--MaxWidth);
}

.pf-v5-c-empty-state.pf-m-xl {
  --pf-v5-c-empty-state__body--MarginTop: var(--pf-v5-c-empty-state--m-xl__body--MarginTop);
  --pf-v5-c-empty-state--body--FontSize: var(--pf-v5-c-empty-state--m-xl__body--FontSize);
  --pf-v5-c-empty-state__icon--MarginBottom: var(--pf-v5-c-empty-state--m-xl__icon--MarginBottom);
  --pf-v5-c-empty-state__icon--FontSize: var(--pf-v5-c-empty-state--m-xl__icon--FontSize);
  --pf-v5-c-empty-state__title-text--FontSize: var(--pf-v5-c-empty-state--m-xl__title-text--FontSize);
  --pf-v5-c-empty-state__title-text--LineHeight: var(--pf-v5-c-empty-state--m-xl__title-text--LineHeight);
}

.pf-v5-c-empty-state.pf-m-full-height {
  height: 100%;
}

.pf-v5-c-empty-state__content {
  max-width: var(--pf-v5-c-empty-state__content--MaxWidth);
}

.pf-v5-c-empty-state__icon {
  margin-block-end: var(--pf-v5-c-empty-state__icon--MarginBottom);
  font-size: var(--pf-v5-c-empty-state__icon--FontSize);
  line-height: 1;
  color: var(--pf-v5-c-empty-state__icon--Color);
}

.pf-v5-c-empty-state__title-text {
  font-family: var(--pf-v5-c-empty-state__title-text--FontFamily);
  font-size: var(--pf-v5-c-empty-state__title-text--FontSize);
  font-weight: var(--pf-v5-c-empty-state__title-text--FontWeight);
  line-height: var(--pf-v5-c-empty-state__title-text--LineHeight);
}

.pf-v5-c-empty-state__body {
  margin-block-start: var(--pf-v5-c-empty-state__body--MarginTop);
  font-size: var(--pf-v5-c-empty-state--body--FontSize);
  color: var(--pf-v5-c-empty-state__body--Color);
}

.pf-v5-c-empty-state__footer {
  display: flex;
  flex-direction: column;
  row-gap: var(--pf-v5-c-empty-state__footer--RowGap);
  align-items: center;
  margin-block-start: var(--pf-v5-c-empty-state__footer--MarginTop);
}

.pf-v5-c-empty-state__actions {
  display: flex;
  flex-wrap: wrap;
  gap: var(--pf-v5-c-empty-state__actions--RowGap) var(--pf-v5-c-empty-state__actions--ColumnGap);
  justify-content: center;
}

.pf-v5-c-expandable-section {
  --pf-v5-c-expandable-section__toggle--PaddingTop: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-expandable-section__toggle--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-expandable-section__toggle--PaddingBottom: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-expandable-section__toggle--PaddingLeft: 0;
  --pf-v5-c-expandable-section__toggle--MarginTop: 0;
  --pf-v5-c-expandable-section__toggle--Color: var(--pf-v5-global--link--Color);
  --pf-v5-c-expandable-section__toggle--hover--Color: var(--pf-v5-global--link--Color--hover);
  --pf-v5-c-expandable-section__toggle--active--Color: var(--pf-v5-global--link--Color--hover);
  --pf-v5-c-expandable-section__toggle--focus--Color: var(--pf-v5-global--link--Color--hover);
  --pf-v5-c-expandable-section__toggle--m-expanded--Color: var(--pf-v5-global--link--Color--hover);
  --pf-v5-c-expandable-section__toggle--BackgroundColor: transparent;
  --pf-v5-c-expandable-section__toggle-icon--MinWidth: 1em;
  --pf-v5-c-expandable-section__toggle-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-expandable-section__toggle-icon--Transition: .2s ease-in 0s;
  --pf-v5-c-expandable-section__toggle-icon--Rotate: 0;
  --pf-v5-c-expandable-section__toggle-icon--m-expand-top--Rotate: 0;
  --pf-v5-c-expandable-section--m-expanded__toggle-icon--Rotate: 90deg;
  --pf-v5-c-expandable-section--m-expanded__toggle-icon--m-expand-top--Rotate: -90deg;
  --pf-v5-c-expandable-section__toggle-text--MarginLeft: calc(var(--pf-v5-global--spacer--xs) + var(--pf-v5-global--spacer--sm));
  --pf-v5-c-expandable-section__content--MarginTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-expandable-section__content--MaxWidth: auto;
  --pf-v5-c-expandable-section--m-limit-width__content--MaxWidth: 46.875rem;
  --pf-v5-c-expandable-section--m-display-lg--BoxShadow: var(--pf-v5-global--BoxShadow--md);
  --pf-v5-c-expandable-section--m-display-lg__toggle--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-expandable-section--m-display-lg__toggle--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-expandable-section--m-display-lg__toggle--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-expandable-section--m-display-lg__toggle--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-expandable-section--m-display-lg__content--MarginTop: 0;
  --pf-v5-c-expandable-section--m-display-lg__content--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-expandable-section--m-display-lg__content--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-expandable-section--m-display-lg__content--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-expandable-section--m-display-lg--after--BackgroundColor: transparent;
  --pf-v5-c-expandable-section--m-display-lg--after--Width: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-expandable-section--m-display-lg--m-expanded--after--BackgroundColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-expandable-section--m-indented__content--PaddingLeft: calc(var(--pf-v5-c-expandable-section__toggle-text--MarginLeft) + var(--pf-v5-c-expandable-section__toggle-icon--MinWidth));
  --pf-v5-c-expandable-section--m-truncate__content--LineClamp: 3;
  --pf-v5-c-expandable-section--m-truncate__toggle--MarginTop: var(--pf-v5-global--spacer--xs);
}

.pf-v5-c-expandable-section.pf-m-expanded {
  --pf-v5-c-expandable-section__toggle--Color: var(--pf-v5-c-expandable-section__toggle--m-expanded--Color);
  --pf-v5-c-expandable-section__toggle-icon--Rotate: var(--pf-v5-c-expandable-section--m-expanded__toggle-icon--Rotate);
  --pf-v5-c-expandable-section__toggle-icon--m-expand-top--Rotate: var(--pf-v5-c-expandable-section--m-expanded__toggle-icon--m-expand-top--Rotate);
  --pf-v5-c-expandable-section--m-display-lg--after--BackgroundColor: var(--pf-v5-c-expandable-section--m-display-lg--m-expanded--after--BackgroundColor);
}

.pf-v5-c-expandable-section.pf-m-detached,
.pf-v5-c-expandable-section.pf-m-truncate {
  --pf-v5-c-expandable-section__content--MarginTop: 0;
}

.pf-v5-c-expandable-section.pf-m-limit-width {
  --pf-v5-c-expandable-section__content--MaxWidth: var(--pf-v5-c-expandable-section--m-limit-width__content--MaxWidth);
}

.pf-v5-c-expandable-section.pf-m-display-lg {
  --pf-v5-c-expandable-section__toggle--PaddingTop: var(--pf-v5-c-expandable-section--m-display-lg__toggle--PaddingTop);
  --pf-v5-c-expandable-section__toggle--PaddingRight: var(--pf-v5-c-expandable-section--m-display-lg__toggle--PaddingRight);
  --pf-v5-c-expandable-section__toggle--PaddingBottom: var(--pf-v5-c-expandable-section--m-display-lg__toggle--PaddingBottom);
  --pf-v5-c-expandable-section__toggle--PaddingLeft: var(--pf-v5-c-expandable-section--m-display-lg__toggle--PaddingLeft);
  --pf-v5-c-expandable-section__content--PaddingRight: var(--pf-v5-c-expandable-section--m-display-lg__content--PaddingRight);
  --pf-v5-c-expandable-section__content--PaddingBottom: var(--pf-v5-c-expandable-section--m-display-lg__content--PaddingBottom);
  --pf-v5-c-expandable-section__content--PaddingLeft: var(--pf-v5-c-expandable-section--m-display-lg__content--PaddingLeft);
  --pf-v5-c-expandable-section__content--MarginTop: var(--pf-v5-c-expandable-section--m-display-lg__content--MarginTop);
  position: relative;
  box-shadow: var(--pf-v5-c-expandable-section--m-display-lg--BoxShadow);
}

.pf-v5-c-expandable-section.pf-m-display-lg::after {
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  width: var(--pf-v5-c-expandable-section--m-display-lg--after--Width);
  content: "";
  background-color: var(--pf-v5-c-expandable-section--m-display-lg--after--BackgroundColor);
}

.pf-v5-c-expandable-section.pf-m-indented {
  --pf-v5-c-expandable-section__content--PaddingLeft: var(--pf-v5-c-expandable-section--m-indented__content--PaddingLeft);
}

.pf-v5-c-expandable-section.pf-m-truncate {
  --pf-v5-c-expandable-section__toggle--MarginTop: var(--pf-v5-c-expandable-section--m-truncate__toggle--MarginTop);
  --pf-v5-c-expandable-section__toggle--PaddingTop: 0;
  --pf-v5-c-expandable-section__toggle--PaddingRight: 0;
  --pf-v5-c-expandable-section__toggle--PaddingBottom: 0;
  --pf-v5-c-expandable-section__toggle-text--MarginLeft: 0;
}

.pf-v5-c-expandable-section.pf-m-truncate:not(.pf-m-expanded) .pf-v5-c-expandable-section__content {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--pf-v5-c-expandable-section--m-truncate__content--LineClamp);
  overflow: hidden;
}

.pf-v5-c-expandable-section.pf-m-detached {
  --pf-v5-c-expandable-section--m-truncate__toggle--MarginTop: 0;
}

.pf-v5-c-expandable-section__toggle {
  display: flex;
  padding-block-start: var(--pf-v5-c-expandable-section__toggle--PaddingTop);
  padding-block-end: var(--pf-v5-c-expandable-section__toggle--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-expandable-section__toggle--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-expandable-section__toggle--PaddingRight);
  margin-block-start: var(--pf-v5-c-expandable-section__toggle--MarginTop);
  color: var(--pf-v5-c-expandable-section__toggle--Color);
  background-color: var(--pf-v5-c-expandable-section__toggle--BackgroundColor);
  border: none;
}

.pf-v5-c-expandable-section__toggle:hover {
  --pf-v5-c-expandable-section__toggle--Color: var(--pf-v5-c-expandable-section__toggle--hover--Color);
}

.pf-v5-c-expandable-section__toggle:active,
.pf-v5-c-expandable-section__toggle.pf-m-active {
  --pf-v5-c-expandable-section__toggle--Color: var(--pf-v5-c-expandable-section__toggle--active--Color);
}

.pf-v5-c-expandable-section__toggle:focus {
  --pf-v5-c-expandable-section__toggle--Color: var(--pf-v5-c-expandable-section__toggle--focus--Color);
}

.pf-v5-c-expandable-section__toggle-icon {
  min-width: var(--pf-v5-c-expandable-section__toggle-icon--MinWidth);
  color: var(--pf-v5-c-expandable-section__toggle-icon--Color);
  transition: var(--pf-v5-c-expandable-section__toggle-icon--Transition);
  transform: rotate(var(--pf-v5-c-expandable-section__toggle-icon--Rotate));
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-expandable-section__toggle-icon {
  scale: -1 1;
}

.pf-v5-c-expandable-section__toggle-icon.pf-m-expand-top {
  --pf-v5-c-expandable-section__toggle-icon--Rotate: var(--pf-v5-c-expandable-section__toggle-icon--m-expand-top--Rotate);
}

.pf-v5-c-expandable-section__toggle-text {
  margin-inline-start: var(--pf-v5-c-expandable-section__toggle-text--MarginLeft);
}

.pf-v5-c-expandable-section__content {
  max-width: var(--pf-v5-c-expandable-section__content--MaxWidth);
  padding-block-end: var(--pf-v5-c-expandable-section__content--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-expandable-section__content--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-expandable-section__content--PaddingRight);
  margin-block-start: var(--pf-v5-c-expandable-section__content--MarginTop);
}

.pf-v5-c-file-upload {
  --pf-v5-c-file-upload--m-loading__file-details--before--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-file-upload--m-loading__file-details--before--Left: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-file-upload--m-loading__file-details--before--Right: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-file-upload--m-loading__file-details--before--Bottom: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-file-upload--m-drag-hover--before--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-file-upload--m-drag-hover--before--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-file-upload--m-drag-hover--before--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-file-upload--m-drag-hover--after--BackgroundColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-file-upload--m-drag-hover--after--Opacity: .1;
  --pf-v5-c-file-upload__file-details__c-form-control--MinHeight: calc(var(--pf-v5-global--spacer--3xl) * 2);
  --pf-v5-c-file-upload__file-select__c-button--m-control--OutlineOffset: calc(-1 * var(--pf-v5-global--spacer--xs));
  position: relative;
  display: flex;
  flex-direction: column;
}

.pf-v5-c-file-upload.pf-m-drag-hover::before {
  position: absolute;
  inset: 0;
  z-index: var(--pf-v5-c-file-upload--m-drag-hover--before--ZIndex);
  content: "";
  border: var(--pf-v5-c-file-upload--m-drag-hover--before--BorderWidth) solid var(--pf-v5-c-file-upload--m-drag-hover--before--BorderColor);
}

.pf-v5-c-file-upload.pf-m-drag-hover::after {
  position: absolute;
  inset: 0;
  content: "";
  background-color: var(--pf-v5-c-file-upload--m-drag-hover--after--BackgroundColor);
  opacity: var(--pf-v5-c-file-upload--m-drag-hover--after--Opacity);
}

.pf-v5-c-file-upload.pf-m-loading .pf-v5-c-file-upload__file-details {
  position: relative;
}

.pf-v5-c-file-upload.pf-m-loading .pf-v5-c-file-upload__file-details::before {
  position: absolute;
  inset-block-start: 0;
  inset-block-end: var(--pf-v5-c-file-upload--m-loading__file-details--before--Left);
  inset-inline-start: var(--pf-v5-c-file-upload--m-loading__file-details--before--Left);
  inset-inline-end: var(--pf-v5-c-file-upload--m-loading__file-details--before--Left);
  content: "";
  background-color: var(--pf-v5-c-file-upload--m-loading__file-details--before--BackgroundColor);
}

.pf-v5-c-file-upload__file-select .pf-v5-c-button.pf-m-control {
  outline-offset: var(--pf-v5-c-file-upload__file-select__c-button--m-control--OutlineOffset);
}

.pf-v5-c-file-upload__file-details {
  position: relative;
  display: flex;
}

.pf-v5-c-file-upload__file-details .pf-v5-c-form-control {
  flex: 1 1 auto;
  min-height: var(--pf-v5-c-file-upload__file-details__c-form-control--MinHeight);
  border-block-start: 0;
}

.pf-v5-c-file-upload__file-details-spinner {
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 50%;
  transform: translate(-50%, -50%);
}

.pf-v5-c-form {
  --pf-v5-c-form--GridGap: var(--pf-v5-global--gutter--md);
  --pf-v5-c-form__group--m-action--MarginTop: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-form--m-horizontal__group-label--md--GridColumnWidth: 9.375rem;
  --pf-v5-c-form--m-horizontal__group-label--md--GridColumnGap: var(--pf-v5-global--spacer--md);
  --pf-v5-c-form--m-horizontal__group-control--md--GridColumnWidth: 1fr;
  --pf-v5-c-form--m-limit-width--MaxWidth: 55rem;
  --pf-v5-c-form--m-horizontal__group-label--md--PaddingTop: calc((((((var(--pf-v5-global--FontSize--md) * var(--pf-v5-global--LineHeight--md)) + (2 * var(--pf-v5-global--BorderWidth--sm))) - var(--pf-v5-global--FontSize--md)) / 2) + var(--pf-v5-global--FontSize--md)) - ((((var(--pf-v5-global--FontSize--sm) * var(--pf-v5-global--LineHeight--sm)) - var(--pf-v5-global--FontSize--sm)) / 2) + var(--pf-v5-global--FontSize--sm)) + var(--pf-v5-global--BorderWidth--sm));
  --pf-v5-c-form__group-label--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form--m-horizontal__group-label--m-no-padding--md--PaddingTop: 0;
  --pf-v5-c-form--m-horizontal__group-label--m-no-padding--md--TranslateY: calc(((((var(--pf-v5-global--FontSize--sm) * var(--pf-v5-global--LineHeight--sm)) - var(--pf-v5-global--FontSize--sm)) / 2) + var(--pf-v5-global--FontSize--sm)) - ((((var(--pf-v5-global--FontSize--md) * var(--pf-v5-global--LineHeight--sm)) - var(--pf-v5-global--FontSize--md)) / 2) + var(--pf-v5-global--FontSize--md)));
  --pf-v5-c-form__label--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-form__label--LineHeight: var(--pf-v5-global--LineHeight--sm);
  --pf-v5-c-form__label--m-disabled--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-form__label--hover--Cursor: pointer;
  --pf-v5-c-form__label--m-disabled--hover--Cursor: not-allowed;
  --pf-v5-c-form__label-text--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-form__label-required--MarginLeft: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-form__label-required--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-form__label-required--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-form__group-label-help--BackgroundColor: transparent;
  --pf-v5-c-form__group-label-help--PaddingTop: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-form__group-label-help--PaddingRight: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-form__group-label-help--PaddingBottom: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-form__group-label-help--PaddingLeft: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-form__group-label-help--MarginTop: calc(var(--pf-v5-c-form__group-label-help--PaddingTop) * -1);
  --pf-v5-c-form__group-label-help--MarginRight: calc(var(--pf-v5-c-form__group-label-help--PaddingRight) * -1);
  --pf-v5-c-form__group-label-help--MarginBottom: calc(var(--pf-v5-c-form__group-label-help--PaddingBottom) * -1);
  --pf-v5-c-form__group-label-help--MarginLeft: calc(var(--pf-v5-c-form__group-label-help--PaddingLeft) * -1 + var(--pf-v5-global--spacer--xs));
  --pf-v5-c-form__group-label-help--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-form__group-label-help--TranslateY: 0.125rem;
  --pf-v5-c-form__group-label-help--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-form__group-label-help--hover--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-form__group-label-help--focus--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-form__group-label--m-info--Gap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form__group-label-info--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-form--m-horizontal__group-label--m-info--Gap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form__group-control--m-inline--child--MarginRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-form__group-control__helper-text--MarginBottom: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-form__group-control--m-stack--Gap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form__group-control--m-stack__helper-text--MarginTop: calc(var(--pf-v5-c-form__group-control--m-stack--Gap) * -1 + var(--pf-v5-c-form__helper-text--MarginTop--base));
  --pf-v5-c-form__actions--child--MarginTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form__actions--child--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form__actions--child--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form__actions--child--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form__actions--MarginTop: calc(var(--pf-v5-c-form__actions--child--MarginTop) * -1);
  --pf-v5-c-form__actions--MarginRight: calc(var(--pf-v5-c-form__actions--child--MarginRight) * -1);
  --pf-v5-c-form__actions--MarginBottom: calc(var(--pf-v5-c-form__actions--child--MarginBottom) * -1);
  --pf-v5-c-form__actions--MarginLeft: calc(var(--pf-v5-c-form__actions--child--MarginLeft) * -1);
  --pf-v5-c-form__helper-text--MarginTop--base: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-form__helper-text--MarginTop: var(--pf-v5-c-form__helper-text--MarginTop--base);
  --pf-v5-c-form__helper-text--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-form__helper-text--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-form__helper-text-icon--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-form__helper-text-icon--MarginRight: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-form__helper-text--m-success--Color: var(--pf-v5-global--success-color--200);
  --pf-v5-c-form__helper-text--m-warning--Color: var(--pf-v5-global--warning-color--200);
  --pf-v5-c-form__helper-text--m-error--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-form__section--MarginTop: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-form__section--Gap: var(--pf-v5-global--gutter--md);
  --pf-v5-c-form__section-title--FontSize: var(--pf-v5-global--FontSize--lg);
  --pf-v5-c-form__section-title--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-form__section-title--MarginBottom: calc(var(--pf-v5-global--spacer--sm) * -1);
  --pf-v5-c-form__field-group--border-width-base: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-form__field-group--BorderTopWidth: var(--pf-v5-c-form__field-group--border-width-base);
  --pf-v5-c-form__field-group--BorderTopColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-form__field-group--BorderBottomWidth: var(--pf-v5-c-form__field-group--border-width-base);
  --pf-v5-c-form__field-group--BorderBottomColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-form__field-group--field-group--MarginTop: calc(var(--pf-v5-c-form--GridGap) * -1);
  --pf-v5-c-form__field-group--GridTemplateColumns--toggle: calc(var(--pf-v5-global--spacer--md) * 2 + var(--pf-v5-c-form__field-group-toggle-icon--MinWidth) + var(--pf-v5-global--spacer--xs));
  --pf-v5-c-form__field-group-toggle--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-form__field-group-toggle--PaddingRight: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-form__field-group__field-group__field-group-toggle--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-form__field-group-header-toggle--BorderWidth--base: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-form__field-group__field-group--field-group__field-group-toggle--after--BorderTopWidth: var(--pf-v5-c-form__field-group-header-toggle--BorderWidth--base);
  --pf-v5-c-form__field-group-toggle-button--MarginTop: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-form__field-group-toggle-button--MarginBottom: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-form__field-group-toggle-icon--Transition: var(--pf-v5-global--Transition);
  --pf-v5-c-form__field-group-toggle-icon--MinWidth: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-form__field-group-toggle-icon--Rotate: 0;
  --pf-v5-c-form__field-group--m-expanded__toggle-icon--Rotate: 90deg;
  --pf-v5-c-form__field-group-header--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-form__field-group-header--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-form__field-group-header--GridColumn: 1 / 3;
  --pf-v5-c-form__field-group__field-group__field-group-header--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-form__field-group__field-group__field-group-header--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-form__field-group-toggle--field-group-header--GridColumn: 2 / 3;
  --pf-v5-c-form__field-group__field-group--field-group__field-group-header--after--BorderTopWidth: var(--pf-v5-c-form__field-group-header-toggle--BorderWidth--base);
  --pf-v5-c-form__field-group-header-description--MarginTop: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-form__field-group-header-description--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-form__field-group-header-actions--MarginTop: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-form__field-group-header-actions--MarginBottom: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-form__field-group-header-actions--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form__field-group-body--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-form__field-group-body--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-form__field-group-body--Gap: var(--pf-v5-c-form--GridGap);
  --pf-v5-c-form__field-group-body--GridColumn: 2 / 3;
  --pf-v5-c-form__field-group__field-group__field-group-body--GridColumn: 1 / 3;
  --pf-v5-c-form__field-group__field-group__field-group-toggle--field-group-body--GridColumn: 2 / 3;
  --pf-v5-c-form__field-group-body__field-group--last-child--MarginBottom: calc(var(--pf-v5-c-form__field-group-body--PaddingBottom) * -1);
  display: grid;
  gap: var(--pf-v5-c-form--GridGap);
}

.pf-v5-c-form.pf-m-horizontal.pf-m-align-right .pf-v5-c-form__label {
  text-align: end;
}

@media screen and (min-width: 768px) {
  .pf-v5-c-form.pf-m-horizontal {
    --pf-v5-c-form__group-label--PaddingBottom: 0;
  }

  .pf-v5-c-form.pf-m-horizontal .pf-v5-c-form__group {
    display: grid;
    grid-template-columns: var(--pf-v5-c-form--m-horizontal__group-label--md--GridColumnWidth) var(--pf-v5-c-form--m-horizontal__group-control--md--GridColumnWidth);
    grid-column-gap: var(--pf-v5-c-form--m-horizontal__group-label--md--GridColumnGap);
  }

  .pf-v5-c-form.pf-m-horizontal .pf-v5-c-form__group-label {
    padding-block-start: var(--pf-v5-c-form--m-horizontal__group-label--md--PaddingTop);
  }

  .pf-v5-c-form.pf-m-horizontal .pf-v5-c-form__group-label.pf-m-no-padding-top {
    --pf-v5-c-form--m-horizontal__group-label--md--PaddingTop: var(--pf-v5-c-form--m-horizontal__group-label--m-no-padding--md--PaddingTop);
    transform: translateY(var(--pf-v5-c-form--m-horizontal__group-label--m-no-padding--md--TranslateY));
  }

  .pf-v5-c-form.pf-m-horizontal .pf-v5-c-form__group-label.pf-m-info {
    flex-direction: column;
    gap: var(--pf-v5-c-form--m-horizontal__group-label--m-info--Gap);
    align-items: flex-start;
  }

  .pf-v5-c-form.pf-m-horizontal .pf-v5-c-form__group-label-main {
    flex-grow: 0;
  }

  .pf-v5-c-form.pf-m-horizontal .pf-v5-c-form__group-control {
    grid-column: 2;
  }
}

@media screen and (min-width: 0) {
  .pf-v5-c-form.pf-m-horizontal-on-xs {
    --pf-v5-c-form__group-label--PaddingBottom: 0;
  }

  .pf-v5-c-form.pf-m-horizontal-on-xs .pf-v5-c-form__group {
    display: grid;
    grid-template-columns: var(--pf-v5-c-form--m-horizontal__group-label--md--GridColumnWidth) var(--pf-v5-c-form--m-horizontal__group-control--md--GridColumnWidth);
    grid-column-gap: var(--pf-v5-c-form--m-horizontal__group-label--md--GridColumnGap);
  }

  .pf-v5-c-form.pf-m-horizontal-on-xs .pf-v5-c-form__group-label {
    padding-block-start: var(--pf-v5-c-form--m-horizontal__group-label--md--PaddingTop);
  }

  .pf-v5-c-form.pf-m-horizontal-on-xs .pf-v5-c-form__group-label.pf-m-no-padding-top {
    --pf-v5-c-form--m-horizontal__group-label--md--PaddingTop: var(--pf-v5-c-form--m-horizontal__group-label--m-no-padding--md--PaddingTop);
    transform: translateY(var(--pf-v5-c-form--m-horizontal__group-label--m-no-padding--md--TranslateY));
  }

  .pf-v5-c-form.pf-m-horizontal-on-xs .pf-v5-c-form__group-label.pf-m-info {
    flex-direction: column;
    gap: var(--pf-v5-c-form--m-horizontal__group-label--m-info--Gap);
    align-items: flex-start;
  }

  .pf-v5-c-form.pf-m-horizontal-on-xs .pf-v5-c-form__group-label-main {
    flex-grow: 0;
  }

  .pf-v5-c-form.pf-m-horizontal-on-xs .pf-v5-c-form__group-control {
    grid-column: 2;
  }
}

@media (min-width: 576px) {
  .pf-v5-c-form.pf-m-horizontal-on-sm {
    --pf-v5-c-form__group-label--PaddingBottom: 0;
  }

  .pf-v5-c-form.pf-m-horizontal-on-sm .pf-v5-c-form__group {
    display: grid;
    grid-template-columns: var(--pf-v5-c-form--m-horizontal__group-label--md--GridColumnWidth) var(--pf-v5-c-form--m-horizontal__group-control--md--GridColumnWidth);
    grid-column-gap: var(--pf-v5-c-form--m-horizontal__group-label--md--GridColumnGap);
  }

  .pf-v5-c-form.pf-m-horizontal-on-sm .pf-v5-c-form__group-label {
    padding-block-start: var(--pf-v5-c-form--m-horizontal__group-label--md--PaddingTop);
  }

  .pf-v5-c-form.pf-m-horizontal-on-sm .pf-v5-c-form__group-label.pf-m-no-padding-top {
    --pf-v5-c-form--m-horizontal__group-label--md--PaddingTop: var(--pf-v5-c-form--m-horizontal__group-label--m-no-padding--md--PaddingTop);
    transform: translateY(var(--pf-v5-c-form--m-horizontal__group-label--m-no-padding--md--TranslateY));
  }

  .pf-v5-c-form.pf-m-horizontal-on-sm .pf-v5-c-form__group-label.pf-m-info {
    flex-direction: column;
    gap: var(--pf-v5-c-form--m-horizontal__group-label--m-info--Gap);
    align-items: flex-start;
  }

  .pf-v5-c-form.pf-m-horizontal-on-sm .pf-v5-c-form__group-label-main {
    flex-grow: 0;
  }

  .pf-v5-c-form.pf-m-horizontal-on-sm .pf-v5-c-form__group-control {
    grid-column: 2;
  }
}

@media (min-width: 768px) {
  .pf-v5-c-form.pf-m-horizontal-on-md {
    --pf-v5-c-form__group-label--PaddingBottom: 0;
  }

  .pf-v5-c-form.pf-m-horizontal-on-md .pf-v5-c-form__group {
    display: grid;
    grid-template-columns: var(--pf-v5-c-form--m-horizontal__group-label--md--GridColumnWidth) var(--pf-v5-c-form--m-horizontal__group-control--md--GridColumnWidth);
    grid-column-gap: var(--pf-v5-c-form--m-horizontal__group-label--md--GridColumnGap);
  }

  .pf-v5-c-form.pf-m-horizontal-on-md .pf-v5-c-form__group-label {
    padding-block-start: var(--pf-v5-c-form--m-horizontal__group-label--md--PaddingTop);
  }

  .pf-v5-c-form.pf-m-horizontal-on-md .pf-v5-c-form__group-label.pf-m-no-padding-top {
    --pf-v5-c-form--m-horizontal__group-label--md--PaddingTop: var(--pf-v5-c-form--m-horizontal__group-label--m-no-padding--md--PaddingTop);
    transform: translateY(var(--pf-v5-c-form--m-horizontal__group-label--m-no-padding--md--TranslateY));
  }

  .pf-v5-c-form.pf-m-horizontal-on-md .pf-v5-c-form__group-label.pf-m-info {
    flex-direction: column;
    gap: var(--pf-v5-c-form--m-horizontal__group-label--m-info--Gap);
    align-items: flex-start;
  }

  .pf-v5-c-form.pf-m-horizontal-on-md .pf-v5-c-form__group-label-main {
    flex-grow: 0;
  }

  .pf-v5-c-form.pf-m-horizontal-on-md .pf-v5-c-form__group-control {
    grid-column: 2;
  }
}

@media (min-width: 992px) {
  .pf-v5-c-form.pf-m-horizontal-on-lg {
    --pf-v5-c-form__group-label--PaddingBottom: 0;
  }

  .pf-v5-c-form.pf-m-horizontal-on-lg .pf-v5-c-form__group {
    display: grid;
    grid-template-columns: var(--pf-v5-c-form--m-horizontal__group-label--md--GridColumnWidth) var(--pf-v5-c-form--m-horizontal__group-control--md--GridColumnWidth);
    grid-column-gap: var(--pf-v5-c-form--m-horizontal__group-label--md--GridColumnGap);
  }

  .pf-v5-c-form.pf-m-horizontal-on-lg .pf-v5-c-form__group-label {
    padding-block-start: var(--pf-v5-c-form--m-horizontal__group-label--md--PaddingTop);
  }

  .pf-v5-c-form.pf-m-horizontal-on-lg .pf-v5-c-form__group-label.pf-m-no-padding-top {
    --pf-v5-c-form--m-horizontal__group-label--md--PaddingTop: var(--pf-v5-c-form--m-horizontal__group-label--m-no-padding--md--PaddingTop);
    transform: translateY(var(--pf-v5-c-form--m-horizontal__group-label--m-no-padding--md--TranslateY));
  }

  .pf-v5-c-form.pf-m-horizontal-on-lg .pf-v5-c-form__group-label.pf-m-info {
    flex-direction: column;
    gap: var(--pf-v5-c-form--m-horizontal__group-label--m-info--Gap);
    align-items: flex-start;
  }

  .pf-v5-c-form.pf-m-horizontal-on-lg .pf-v5-c-form__group-label-main {
    flex-grow: 0;
  }

  .pf-v5-c-form.pf-m-horizontal-on-lg .pf-v5-c-form__group-control {
    grid-column: 2;
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-form.pf-m-horizontal-on-xl {
    --pf-v5-c-form__group-label--PaddingBottom: 0;
  }

  .pf-v5-c-form.pf-m-horizontal-on-xl .pf-v5-c-form__group {
    display: grid;
    grid-template-columns: var(--pf-v5-c-form--m-horizontal__group-label--md--GridColumnWidth) var(--pf-v5-c-form--m-horizontal__group-control--md--GridColumnWidth);
    grid-column-gap: var(--pf-v5-c-form--m-horizontal__group-label--md--GridColumnGap);
  }

  .pf-v5-c-form.pf-m-horizontal-on-xl .pf-v5-c-form__group-label {
    padding-block-start: var(--pf-v5-c-form--m-horizontal__group-label--md--PaddingTop);
  }

  .pf-v5-c-form.pf-m-horizontal-on-xl .pf-v5-c-form__group-label.pf-m-no-padding-top {
    --pf-v5-c-form--m-horizontal__group-label--md--PaddingTop: var(--pf-v5-c-form--m-horizontal__group-label--m-no-padding--md--PaddingTop);
    transform: translateY(var(--pf-v5-c-form--m-horizontal__group-label--m-no-padding--md--TranslateY));
  }

  .pf-v5-c-form.pf-m-horizontal-on-xl .pf-v5-c-form__group-label.pf-m-info {
    flex-direction: column;
    gap: var(--pf-v5-c-form--m-horizontal__group-label--m-info--Gap);
    align-items: flex-start;
  }

  .pf-v5-c-form.pf-m-horizontal-on-xl .pf-v5-c-form__group-label-main {
    flex-grow: 0;
  }

  .pf-v5-c-form.pf-m-horizontal-on-xl .pf-v5-c-form__group-control {
    grid-column: 2;
  }
}

@media (min-width: 1450px) {
  .pf-v5-c-form.pf-m-horizontal-on-2xl {
    --pf-v5-c-form__group-label--PaddingBottom: 0;
  }

  .pf-v5-c-form.pf-m-horizontal-on-2xl .pf-v5-c-form__group {
    display: grid;
    grid-template-columns: var(--pf-v5-c-form--m-horizontal__group-label--md--GridColumnWidth) var(--pf-v5-c-form--m-horizontal__group-control--md--GridColumnWidth);
    grid-column-gap: var(--pf-v5-c-form--m-horizontal__group-label--md--GridColumnGap);
  }

  .pf-v5-c-form.pf-m-horizontal-on-2xl .pf-v5-c-form__group-label {
    padding-block-start: var(--pf-v5-c-form--m-horizontal__group-label--md--PaddingTop);
  }

  .pf-v5-c-form.pf-m-horizontal-on-2xl .pf-v5-c-form__group-label.pf-m-no-padding-top {
    --pf-v5-c-form--m-horizontal__group-label--md--PaddingTop: var(--pf-v5-c-form--m-horizontal__group-label--m-no-padding--md--PaddingTop);
    transform: translateY(var(--pf-v5-c-form--m-horizontal__group-label--m-no-padding--md--TranslateY));
  }

  .pf-v5-c-form.pf-m-horizontal-on-2xl .pf-v5-c-form__group-label.pf-m-info {
    flex-direction: column;
    gap: var(--pf-v5-c-form--m-horizontal__group-label--m-info--Gap);
    align-items: flex-start;
  }

  .pf-v5-c-form.pf-m-horizontal-on-2xl .pf-v5-c-form__group-label-main {
    flex-grow: 0;
  }

  .pf-v5-c-form.pf-m-horizontal-on-2xl .pf-v5-c-form__group-control {
    grid-column: 2;
  }
}

.pf-v5-c-form.pf-m-limit-width {
  max-width: var(--pf-v5-c-form--m-limit-width--MaxWidth);
}

.pf-v5-c-form__group {
  min-width: 0;
}

.pf-v5-c-form__group.pf-m-action {
  margin-block-start: var(--pf-v5-c-form__group--m-action--MarginTop);
  overflow: hidden;
}

.pf-v5-c-form__section {
  display: grid;
  gap: var(--pf-v5-c-form__section--Gap);
}

.pf-v5-c-form__section+.pf-v5-c-form__group:not(.pf-m-action),
.pf-v5-c-form__section:not(:first-child) {
  margin-block-start: var(--pf-v5-c-form__section--MarginTop);
}

.pf-v5-c-form__section-title {
  margin-block-end: var(--pf-v5-c-form__section-title--MarginBottom);
  font-size: var(--pf-v5-c-form__section-title--FontSize);
  font-weight: var(--pf-v5-c-form__section-title--FontWeight);
}

.pf-v5-c-form__group-label {
  --pf-v5-c-form__helper-text--MarginTop: 0;
  padding-block-end: var(--pf-v5-c-form__group-label--PaddingBottom);
}

.pf-v5-c-form__group-label.pf-m-info {
  display: flex;
  gap: var(--pf-v5-c-form__group-label--m-info--Gap);
  align-items: flex-end;
}

.pf-v5-c-form__group-label-main {
  flex-grow: 1;
}

.pf-v5-c-form__group-label-info {
  font-size: var(--pf-v5-c-form__group-label-info--FontSize);
}

.pf-v5-c-form__label {
  font-size: var(--pf-v5-c-form__label--FontSize);
  line-height: var(--pf-v5-c-form__label--LineHeight);
}

.pf-v5-c-form__label::selection {
  background-color: none;
}

.pf-v5-c-form__label:not(.pf-m-disabled):hover {
  cursor: var(--pf-v5-c-form__label--hover--Cursor);
}

.pf-v5-c-form__label.pf-m-disabled {
  color: var(--pf-v5-c-form__label--m-disabled--Color);
}

.pf-v5-c-form__label.pf-m-disabled:hover {
  cursor: var(--pf-v5-c-form__label--m-disabled--hover--Cursor);
}

.pf-v5-c-form__label-text {
  font-weight: var(--pf-v5-c-form__label-text--FontWeight);
}

.pf-v5-c-form__label-required {
  margin-inline-start: var(--pf-v5-c-form__label-required--MarginLeft);
  font-size: var(--pf-v5-c-form__label-required--FontSize);
  color: var(--pf-v5-c-form__label-required--Color);
}

.pf-v5-c-form__group-label-help {
  padding-block-start: var(--pf-v5-c-form__group-label-help--PaddingTop);
  padding-block-end: var(--pf-v5-c-form__group-label-help--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-form__group-label-help--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-form__group-label-help--PaddingRight);
  margin-block-start: var(--pf-v5-c-form__group-label-help--MarginTop);
  margin-block-end: var(--pf-v5-c-form__group-label-help--MarginBottom);
  margin-inline-start: var(--pf-v5-c-form__group-label-help--MarginLeft);
  margin-inline-end: var(--pf-v5-c-form__group-label-help--MarginRight);
  font-size: var(--pf-v5-c-form__group-label-help--FontSize);
  line-height: 1;
  color: var(--pf-v5-c-form__group-label-help--Color);
  cursor: pointer;
  background-color: var(--pf-v5-c-form__group-label-help--BackgroundColor);
  border: 0;
  transform: translateY(var(--pf-v5-c-form__group-label-help--TranslateY));
}

.pf-v5-c-form__group-label-help:hover {
  --pf-v5-c-form__group-label-help--Color: var(--pf-v5-c-form__group-label-help--hover--Color);
}

.pf-v5-c-form__group-label-help:focus-within {
  --pf-v5-c-form__group-label-help--Color: var(--pf-v5-c-form__group-label-help--focus--Color);
}

.pf-v5-c-form__group-control {
  min-width: 0;
}

.pf-v5-c-form__group-control.pf-m-inline {
  display: flex;
  flex-flow: row wrap;
}

.pf-v5-c-form__group-control.pf-m-inline>* {
  margin-inline-end: var(--pf-v5-c-form__group-control--m-inline--child--MarginRight);
}

.pf-v5-c-form__group-control.pf-m-inline>:last-child {
  --pf-v5-c-form__group-control--m-inline--child--MarginRight: 0;
}

.pf-v5-c-form__group-control.pf-m-stack {
  --pf-v5-c-form__helper-text--MarginTop: var(--pf-v5-c-form__group-control--m-stack__helper-text--MarginTop);
  display: grid;
  gap: var(--pf-v5-c-form__group-control--m-stack--Gap);
}

.pf-v5-c-form__group-control .pf-v5-c-form__helper-text:first-child {
  --pf-v5-c-form__helper-text--MarginTop: 0;
  margin-block-end: var(--pf-v5-c-form__group-control__helper-text--MarginBottom);
}

.pf-v5-c-form__helper-text {
  margin-block-start: var(--pf-v5-c-form__helper-text--MarginTop);
  font-size: var(--pf-v5-c-form__helper-text--FontSize);
  color: var(--pf-v5-c-form__helper-text--Color);
}

.pf-v5-c-form__helper-text.pf-m-error {
  --pf-v5-c-form__helper-text--Color: var(--pf-v5-c-form__helper-text--m-error--Color);
}

.pf-v5-c-form__helper-text.pf-m-success {
  --pf-v5-c-form__helper-text--Color: var(--pf-v5-c-form__helper-text--m-success--Color);
}

.pf-v5-c-form__helper-text.pf-m-warning {
  --pf-v5-c-form__helper-text--Color: var(--pf-v5-c-form__helper-text--m-warning--Color);
}

.pf-v5-c-form__helper-text.pf-m-inactive {
  display: none;
}

.pf-v5-c-form__helper-text.pf-m-hidden {
  visibility: hidden;
  opacity: 0;
}

.pf-v5-c-form__helper-text-icon {
  margin-inline-end: var(--pf-v5-c-form__helper-text-icon--MarginRight);
  font-size: var(--pf-v5-c-form__helper-text-icon--FontSize);
}

.pf-v5-c-form__fieldset {
  border: 0;
}

.pf-v5-c-form__actions {
  display: flex;
  flex-wrap: wrap;
  margin-block-start: var(--pf-v5-c-form__actions--MarginTop);
  margin-block-end: var(--pf-v5-c-form__actions--MarginBottom);
  margin-inline-start: var(--pf-v5-c-form__actions--MarginLeft);
  margin-inline-end: var(--pf-v5-c-form__actions--MarginRight);
}

.pf-v5-c-form__actions>* {
  margin-block-start: var(--pf-v5-c-form__actions--child--MarginTop);
  margin-block-end: var(--pf-v5-c-form__actions--child--MarginBottom);
  margin-inline-start: var(--pf-v5-c-form__actions--child--MarginLeft);
  margin-inline-end: var(--pf-v5-c-form__actions--child--MarginRight);
}

.pf-v5-c-form__field-group {
  --pf-v5-c-form__field-group--BorderTopWidth: var(--pf-v5-c-form__field-group--border-width-base);
  display: grid;
  grid-template-columns: minmax(var(--pf-v5-c-form__field-group--GridTemplateColumns--toggle), max-content) 1fr;
  border-block-start: var(--pf-v5-c-form__field-group--BorderTopWidth) solid var(--pf-v5-c-form__field-group--BorderTopColor);
  border-block-end: var(--pf-v5-c-form__field-group--BorderBottomWidth) solid var(--pf-v5-c-form__field-group--BorderBottomColor);
}

.pf-v5-c-form__field-group:last-child {
  --pf-v5-c-form__field-group--BorderBottomWidth: 0;
}

.pf-v5-c-form__field-group+.pf-v5-c-form__field-group,
.pf-v5-c-form__field-group:first-child {
  --pf-v5-c-form__field-group--BorderTopWidth: 0;
}

.pf-v5-c-form__field-group+.pf-v5-c-form__field-group {
  margin-block-start: var(--pf-v5-c-form__field-group--field-group--MarginTop);
}

.pf-v5-c-form__field-group .pf-v5-c-form__field-group {
  --pf-v5-c-form__field-group-body--GridColumn: var(--pf-v5-c-form__field-group__field-group__field-group-body--GridColumn);
  --pf-v5-c-form__field-group-toggle--PaddingTop: var(--pf-v5-c-form__field-group__field-group__field-group-toggle--PaddingTop);
  --pf-v5-c-form__field-group-header--PaddingTop: var(--pf-v5-c-form__field-group__field-group__field-group-header--PaddingTop);
  --pf-v5-c-form__field-group-header--PaddingBottom: var(--pf-v5-c-form__field-group__field-group__field-group-header--PaddingBottom);
  --pf-v5-c-form__field-group-body--PaddingTop: 0;
}

.pf-v5-c-form__field-group .pf-v5-c-form__field-group .pf-v5-c-form__field-group-toggle~.pf-v5-c-form__field-group-body {
  --pf-v5-c-form__field-group-body--GridColumn: var(--pf-v5-c-form__field-group__field-group__field-group-toggle--field-group-body--GridColumn);
}

.pf-v5-c-form__field-group.pf-m-expanded>.pf-v5-c-form__field-group-toggle {
  --pf-v5-c-form__field-group-toggle-icon--Rotate: var(--pf-v5-c-form__field-group--m-expanded__toggle-icon--Rotate);
}

.pf-v5-c-form__field-group-toggle {
  grid-row: 1/2;
  grid-column: 1/2;
  padding-block-start: var(--pf-v5-c-form__field-group-toggle--PaddingTop);
  padding-inline-end: var(--pf-v5-c-form__field-group-toggle--PaddingRight);
}

.pf-v5-c-form__field-group-toggle+.pf-v5-c-form__field-group-header {
  --pf-v5-c-form__field-group-header--GridColumn: var(--pf-v5-c-form__field-group-toggle--field-group-header--GridColumn);
}

.pf-v5-c-form__field-group-toggle-button {
  margin-block-start: var(--pf-v5-c-form__field-group-toggle-button--MarginTop);
  margin-block-end: var(--pf-v5-c-form__field-group-toggle-button--MarginBottom);
}

.pf-v5-c-form__field-group-toggle-icon {
  display: inline-block;
  min-width: var(--pf-v5-c-form__field-group-toggle-icon--MinWidth);
  text-align: center;
  transition: var(--pf-v5-c-form__field-group-toggle-icon--Transition);
  transform: rotate(var(--pf-v5-c-form__field-group-toggle-icon--Rotate));
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-form__field-group-toggle-icon {
  scale: -1 1;
}

.pf-v5-c-form__field-group-header {
  display: flex;
  grid-row: 1/2;
  grid-column: var(--pf-v5-c-form__field-group-header--GridColumn);
  align-items: flex-start;
  padding-block-start: var(--pf-v5-c-form__field-group-header--PaddingTop);
  padding-block-end: var(--pf-v5-c-form__field-group-header--PaddingBottom);
}

.pf-v5-c-form__field-group-header-main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.pf-v5-c-form__field-group-header-title {
  display: flex;
}

.pf-v5-c-form__field-group-header-title-text {
  flex-grow: 1;
}

.pf-v5-c-form__field-group-header-description {
  margin-block-start: var(--pf-v5-c-form__field-group-header-description--MarginTop);
  color: var(--pf-v5-c-form__field-group-header-description--Color);
}

.pf-v5-c-form__field-group-header-actions {
  margin-block-start: var(--pf-v5-c-form__field-group-header-actions--MarginTop);
  margin-block-end: var(--pf-v5-c-form__field-group-header-actions--MarginBottom);
  margin-inline-start: var(--pf-v5-c-form__field-group-header-actions--MarginLeft);
  white-space: nowrap;
}

.pf-v5-c-form__field-group-body {
  display: grid;
  grid-column: var(--pf-v5-c-form__field-group-body--GridColumn);
  gap: var(--pf-v5-c-form__field-group-body--Gap);
  padding-block-start: var(--pf-v5-c-form__field-group-body--PaddingTop);
  padding-block-end: var(--pf-v5-c-form__field-group-body--PaddingBottom);
}

.pf-v5-c-form__field-group-body>.pf-v5-c-form__field-group:first-child {
  --pf-v5-c-form__field-group-toggle--PaddingTop: 0;
  --pf-v5-c-form__field-group-header--PaddingTop: 0;
}

.pf-v5-c-form__field-group-body>.pf-v5-c-form__field-group:last-child {
  margin-block-end: var(--pf-v5-c-form__field-group-body__field-group--last-child--MarginBottom);
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-form {
  --pf-v5-c-form__helper-text--m-success--Color: var(--pf-v5-global--success-color--100);
}

.pf-v5-c-form-control {
  --pf-v5-c-form-control--ColumnGap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form-control--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-form-control--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-form-control--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-form-control--Resize: none;
  --pf-v5-c-form-control--OutlineOffset: -2px;
  --pf-v5-c-form-control--before--BorderTopWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-form-control--before--BorderRightWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-form-control--before--BorderBottomWidth: 0;
  --pf-v5-c-form-control--before--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-form-control--before--BorderTopColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-form-control--before--BorderRightColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-form-control--before--BorderBottomColor: transparent;
  --pf-v5-c-form-control--before--BorderLeftColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-form-control--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-form-control--after--BorderBottomColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-form-control--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-form-control--Width: 100%;
  --pf-v5-c-form-control--inset--base: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form-control--PaddingTop: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-form-control--PaddingBottom: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-form-control--PaddingRight: var(--pf-v5-c-form-control--inset--base);
  --pf-v5-c-form-control--PaddingLeft: var(--pf-v5-c-form-control--inset--base);
  --pf-v5-c-form-control--hover--after--BorderBottomColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-form-control--focus--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-form-control--focus--after--BorderBottomColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-form-control--m-expanded--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-form-control--m-expanded--after--BorderBottomColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-form-control--m-placeholder--Color: var(--pf-v5-global--Color--dark-200);
  --pf-v5-c-form-control--m-placeholder--child--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-form-control--m-disabled--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-form-control--m-disabled--BackgroundColor: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-form-control--m-disabled--after--BorderColor: transparent;
  --pf-v5-c-form-control--m-readonly--BackgroundColor: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-form-control--m-readonly--hover--after--BorderBottomColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-form-control--m-readonly--focus--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-form-control--m-readonly--focus--after--BorderBottomColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-form-control--m-readonly--m-plain--BackgroundColor: transparent;
  --pf-v5-c-form-control--m-readonly--m-plain--inset--base: 0;
  --pf-v5-c-form-control--m-readonly--m-plain--OutlineOffset: 0;
  --pf-v5-c-form-control--m-success--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-form-control--m-success--after--BorderBottomColor: var(--pf-v5-global--success-color--100);
  --pf-v5-c-form-control--m-success--PaddingRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-form-control--m-warning--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-form-control--m-warning--after--BorderBottomColor: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-form-control--m-warning--PaddingRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-form-control--m-error--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-form-control--m-error--after--BorderBottomColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-form-control--m-error--PaddingRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-form-control--m-error--icon--width: var(--pf-v5-c-form-control--FontSize);
  --pf-v5-c-form-control--m-icon--PaddingRight: calc(var(--pf-v5-c-form-control--inset--base) + var(--pf-v5-c-form-control--m-icon--icon--width) + var(--pf-v5-c-form-control--m-icon--icon--spacer));
  --pf-v5-c-form-control--m-icon--icon--width: var(--pf-v5-c-form-control--FontSize);
  --pf-v5-c-form-control--m-icon--icon--spacer: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form-control--m-icon--icon--PaddingRight: calc(var(--pf-v5-c-form-control--inset--base) + var(--pf-v5-c-form-control--m-error--icon--width) + var(--pf-v5-c-form-control--m-icon--icon--spacer) + var(--pf-v5-c-form-control--m-icon--icon--width) + var(--pf-v5-c-form-control--m-icon--icon--spacer));
  --pf-v5-c-form-control__select--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-form-control__select--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form-control__select--m-success--m-status--PaddingRight: var(--pf-v5-global--spacer--3xl);
  --pf-v5-c-form-control__select--m-warning--m-status--PaddingRight: var(--pf-v5-global--spacer--3xl);
  --pf-v5-c-form-control__select--m-error--m-status--PaddingRight: var(--pf-v5-global--spacer--3xl);
  --pf-v5-c-form-control--textarea--Width: var(--pf-v5-c-form-control--Width);
  --pf-v5-c-form-control--textarea--Height: auto;
  --pf-v5-c-form-control__icon--PaddingTop: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-form-control__icon--Color: var(--pf-v5-global--icon--Color--light);
  --pf-v5-c-form-control__icon--m-status--Color: var(--pf-v5-global--icon--Color--light);
  --pf-v5-c-form-control--m-success__icon--m-status--Color: var(--pf-v5-global--success-color--100);
  --pf-v5-c-form-control--m-warning__icon--m-status--Color: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-form-control--m-error__icon--m-status--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-form-control__utilities--Gap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-form-control__utilities--PaddingTop: var(--pf-v5-c-form-control--inset--base);
  --pf-v5-c-form-control__utilities--PaddingRight: var(--pf-v5-c-form-control--inset--base);
  --pf-v5-c-form-control__toggle-icon--PaddingTop: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-form-control__toggle-icon--PaddingRight: var(--pf-v5-c-form-control--inset--base);
  --pf-v5-c-form-control__toggle-icon--PaddingLeft: var(--pf-v5-c-form-control--inset--base);
  --pf-v5-c-form-control__toggle-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-form-control--m-disabled__toggle-icon--Color: var(--pf-v5-global--disabled-color--100);
  color: var(--pf-v5-c-form-control--Color);
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: var(--pf-v5-c-form-control--ColumnGap);
  align-items: start;
  width: var(--pf-v5-c-form-control--Width);
  font-size: var(--pf-v5-c-form-control--FontSize);
  line-height: var(--pf-v5-c-form-control--LineHeight);
  resize: var(--pf-v5-c-form-control--Resize);
  background-color: var(--pf-v5-c-form-control--BackgroundColor);
}

.pf-v5-c-form-control::before,
.pf-v5-c-form-control::after {
  position: absolute;
  inset: 0;
  pointer-events: none;
  content: "";
}

.pf-v5-c-form-control::before {
  border-style: var(--pf-v5-c-form-control--before--BorderStyle, solid);
  border-block-start-color: var(--pf-v5-c-form-control--before--BorderTopColor);
  border-block-start-width: var(--pf-v5-c-form-control--before--BorderTopWidth);
  border-block-end-color: var(--pf-v5-c-form-control--before--BorderBottomColor);
  border-block-end-width: var(--pf-v5-c-form-control--before--BorderBottomWidth);
  border-inline-start-color: var(--pf-v5-c-form-control--before--BorderLeftColor);
  border-inline-start-width: var(--pf-v5-c-form-control--before--BorderLeftWidth);
  border-inline-end-color: var(--pf-v5-c-form-control--before--BorderRightColor);
  border-inline-end-width: var(--pf-v5-c-form-control--before--BorderRightWidth);
}

.pf-v5-c-form-control::after {
  border-block-end: var(--pf-v5-c-form-control--after--BorderBottomWidth) var(--pf-v5-c-form-control--after--BorderStyle, solid) var(--pf-v5-c-form-control--after--BorderBottomColor);
}

.pf-v5-c-form-control>:is(input,select,textarea) {
  grid-row: 1/2;
  grid-column: 1/-1;
  padding-block-start: var(--pf-v5-c-form-control--PaddingTop);
  padding-block-end: var(--pf-v5-c-form-control--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-form-control--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-form-control--PaddingRight);
  color: var(--pf-v5-c-form-control--Color);
  background-color: transparent;
  border: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.pf-v5-c-form-control>:is(input,select,textarea):focus {
  outline-offset: var(--pf-v5-c-form-control--OutlineOffset);
}

.pf-v5-c-form-control>::placeholder {
  color: var(--pf-v5-c-form-control--m-placeholder--Color);
}

.pf-v5-c-form-control>:is(input,select) {
  text-overflow: ellipsis;
}

.pf-v5-c-form-control.pf-m-readonly {
  --pf-v5-c-form-control--BackgroundColor: var(--pf-v5-c-form-control--m-readonly--BackgroundColor);
}

.pf-v5-c-form-control.pf-m-readonly:not(.pf-m-success,.pf-m-warning,.pf-m-error) {
  --pf-v5-c-form-control--hover--after--BorderBottomColor: var(--pf-v5-c-form-control--m-readonly--hover--after--BorderBottomColor);
  --pf-v5-c-form-control--focus--after--BorderBottomWidth: var(--pf-v5-c-form-control--m-readonly--focus--after--BorderBottomWidth);
  --pf-v5-c-form-control--focus--after--BorderBottomColor: var(--pf-v5-c-form-control--m-readonly--focus--after--BorderBottomColor);
}

.pf-v5-c-form-control.pf-m-readonly.pf-m-plain {
  --pf-v5-c-form-control--m-readonly--BackgroundColor: var(--pf-v5-c-form-control--m-readonly--m-plain--BackgroundColor);
  --pf-v5-c-form-control--inset--base: var(--pf-v5-c-form-control--m-readonly--m-plain--inset--base);
  --pf-v5-c-form-control--before--BorderStyle: none;
  --pf-v5-c-form-control--after--BorderStyle: none;
  --pf-v5-c-form-control--OutlineOffset: var(--pf-v5-c-form-control--m-readonly--m-plain--OutlineOffset);
}

.pf-v5-c-form-control:hover {
  --pf-v5-c-form-control--after--BorderBottomColor: var(--pf-v5-c-form-control--hover--after--BorderBottomColor);
}

.pf-v5-c-form-control:focus-within {
  --pf-v5-c-form-control--after--BorderBottomColor: var(--pf-v5-c-form-control--focus--after--BorderBottomColor);
  --pf-v5-c-form-control--after--BorderBottomWidth: var(--pf-v5-c-form-control--focus--after--BorderBottomWidth);
}

.pf-v5-c-form-control.pf-m-expanded {
  --pf-v5-c-form-control--after--BorderBottomColor: var(--pf-v5-c-form-control--m-expanded--after--BorderBottomColor);
  --pf-v5-c-form-control--after--BorderBottomWidth: var(--pf-v5-c-form-control--m-expanded--after--BorderBottomWidth);
}

.pf-v5-c-form-control.pf-m-disabled {
  --pf-v5-c-form-control--BackgroundColor: var(--pf-v5-c-form-control--m-disabled--BackgroundColor);
  --pf-v5-c-form-control--Color: var(--pf-v5-c-form-control--m-disabled--Color);
  --pf-v5-c-form-control--m-placeholder--Color: var(--pf-v5-c-form-control--m-disabled--Color);
  --pf-v5-c-form-control__toggle-icon--Color: var(--pf-v5-c-form-control--m-disabled__toggle-icon--Color);
  --pf-v5-c-form-control--before--BorderStyle: none;
  --pf-v5-c-form-control--after--BorderStyle: none;
  cursor: not-allowed;
}

.pf-v5-c-form-control.pf-m-error {
  --pf-v5-c-form-control--PaddingRight: var(--pf-v5-c-form-control--m-error--PaddingRight);
  --pf-v5-c-form-control--after--BorderBottomColor: var(--pf-v5-c-form-control--m-error--after--BorderBottomColor);
  --pf-v5-c-form-control__icon--m-status--Color: var(--pf-v5-c-form-control--m-error__icon--m-status--Color);
  --pf-v5-c-form-control__select--PaddingRight: var(--pf-v5-c-form-control__select--m-error--m-status--PaddingRight);
  --pf-v5-c-form-control--after--BorderBottomWidth: var(--pf-v5-c-form-control--m-error--after--BorderBottomWidth);
}

.pf-v5-c-form-control.pf-m-error.pf-m-icon {
  --pf-v5-c-form-control--PaddingRight: var(--pf-v5-c-form-control--m-icon--icon--PaddingRight);
}

.pf-v5-c-form-control.pf-m-success {
  --pf-v5-c-form-control--PaddingRight: var(--pf-v5-c-form-control--m-success--PaddingRight);
  --pf-v5-c-form-control--after--BorderBottomColor: var(--pf-v5-c-form-control--m-success--after--BorderBottomColor);
  --pf-v5-c-form-control__icon--m-status--Color: var(--pf-v5-c-form-control--m-success__icon--m-status--Color);
  --pf-v5-c-form-control__select--PaddingRight: var(--pf-v5-c-form-control__select--m-success--m-status--PaddingRight);
  --pf-v5-c-form-control--after--BorderBottomWidth: var(--pf-v5-c-form-control--m-success--after--BorderBottomWidth);
}

.pf-v5-c-form-control.pf-m-success.pf-m-icon {
  --pf-v5-c-form-control--PaddingRight: var(--pf-v5-c-form-control--m-icon--icon--PaddingRight);
}

.pf-v5-c-form-control.pf-m-warning {
  --pf-v5-c-form-control--PaddingRight: var(--pf-v5-c-form-control--m-warning--PaddingRight);
  --pf-v5-c-form-control--after--BorderBottomColor: var(--pf-v5-c-form-control--m-warning--after--BorderBottomColor);
  --pf-v5-c-form-control__icon--m-status--Color: var(--pf-v5-c-form-control--m-warning__icon--m-status--Color);
  --pf-v5-c-form-control__select--PaddingRight: var(--pf-v5-c-form-control__select--m-warning--m-status--PaddingRight);
  --pf-v5-c-form-control--after--BorderBottomWidth: var(--pf-v5-c-form-control--m-warning--after--BorderBottomWidth);
}

.pf-v5-c-form-control.pf-m-warning.pf-m-icon {
  --pf-v5-c-form-control--PaddingRight: var(--pf-v5-c-form-control--m-icon--icon--PaddingRight);
}

.pf-v5-c-form-control.pf-m-icon {
  --pf-v5-c-form-control--PaddingRight: var(--pf-v5-c-form-control--m-icon--PaddingRight);
}

.pf-v5-c-form-control>select {
  --pf-v5-c-form-control--PaddingRight: var(--pf-v5-c-form-control__select--PaddingRight);
  --pf-v5-c-form-control--PaddingLeft: var(--pf-v5-c-form-control__select--PaddingLeft);
  background-color: var(--pf-v5-c-form-control--BackgroundColor);
}

@-moz-document url-prefix()  {
  .pf-v5-c-form-control>select {
    --pf-v5-c-form-control--PaddingRight: calc(var(--pf-v5-c-form-control__select--PaddingRight) - 1px);
    --pf-v5-c-form-control--PaddingLeft: calc(var(--pf-v5-c-form-control__select--PaddingLeft) - 4px);
  }
}

.pf-v5-c-form-control.pf-m-placeholder>select {
  --pf-v5-c-form-control--Color: var(--pf-v5-c-form-control--m-placeholder--Color);
}

.pf-v5-c-form-control.pf-m-placeholder>select * {
  color: var(--pf-v5-c-form-control--m-placeholder--child--Color);
}

.pf-v5-c-form-control.pf-m-placeholder>select *:disabled {
  color: revert;
}

.pf-v5-c-form-control>textarea {
  width: 100%;
  height: 100%;
  vertical-align: bottom;
  resize: none;
}

.pf-v5-c-form-control.pf-m-resize-vertical {
  --pf-v5-c-form-control--Resize: vertical;
  overflow: auto;
}

.pf-v5-c-form-control.pf-m-resize-horizontal {
  --pf-v5-c-form-control--Resize: horizontal;
  overflow: auto;
}

.pf-v5-c-form-control.pf-m-resize-both {
  --pf-v5-c-form-control--Resize: both;
  overflow: auto;
}

.pf-v5-c-form-control__icon {
  padding-block-start: var(--pf-v5-c-form-control__icon--PaddingTop);
  color: var(--pf-v5-c-form-control__icon--Color);
}

.pf-v5-c-form-control__icon.pf-m-status {
  --pf-v5-c-form-control__icon--Color: var(--pf-v5-c-form-control__icon--m-status--Color);
}

.pf-v5-c-form-control__toggle-icon {
  grid-row: 1/2;
  grid-column: 3;
  padding-block-start: var(--pf-v5-c-form-control__toggle-icon--PaddingTop);
  padding-inline-start: var(--pf-v5-c-form-control__toggle-icon--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-form-control__toggle-icon--PaddingRight);
  color: var(--pf-v5-c-form-control__toggle-icon--Color);
  pointer-events: none;
}

.pf-v5-c-form-control__utilities {
  display: flex;
  flex-wrap: nowrap;
  grid-row: 1/2;
  grid-column: 2;
  gap: var(--pf-v5-c-form-control__utilities--Gap);
  padding-inline-end: var(--pf-v5-c-form-control__utilities--PaddingRight);
  pointer-events: none;
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-form-control {
  --pf-v5-c-form-control--before--BorderTopColor: transparent;
  --pf-v5-c-form-control--before--BorderRightColor: transparent;
  --pf-v5-c-form-control--before--BorderLeftColor: transparent;
  --pf-v5-c-form-control--after--BorderBottomColor: var(--pf-v5-global--BorderColor--400);
  --pf-v5-c-form-control--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-form-control--m-disabled--Color: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-form-control--m-disabled--BackgroundColor: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-form-control--m-readonly--BackgroundColor: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-form-control--m-readonly--hover--after--BorderBottomColor: var(--pf-v5-global--BorderColor--400);
  --pf-v5-c-form-control--m-readonly--focus--after--BorderBottomColor: var(--pf-v5-global--BorderColor--400);
  --pf-v5-c-form-control--m-disabled__toggle-icon--Color: var(--pf-v5-global--disabled-color--300);
  color: var(--pf-v5-global--Color--100);
}

:where(.pf-v5-theme-dark) .pf-v5-c-form-control::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.pf-v5-c-hint {
  --pf-v5-c-hint--GridRowGap: var(--pf-v5-global--spacer--md);
  --pf-v5-c-hint--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-hint--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-hint--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-hint--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-hint--BackgroundColor: var(--pf-v5-global--palette--blue-50);
  --pf-v5-c-hint--BorderColor: var(--pf-v5-global--palette--blue-100);
  --pf-v5-c-hint--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-hint--BoxShadow: var(--pf-v5-global--BoxShadow--sm);
  --pf-v5-c-hint--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-hint__title--FontSize: var(--pf-v5-global--FontSize--lg);
  --pf-v5-c-hint__body--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-hint__footer--child--MarginRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-hint__actions--MarginLeft: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-hint__actions--c-dropdown--MarginTop: calc(var(--pf-v5-global--spacer--form-element) * -1);
  display: grid;
  grid-template-columns: 1fr auto;
  grid-row-gap: var(--pf-v5-c-hint--GridRowGap);
  padding-block-start: var(--pf-v5-c-hint--PaddingTop);
  padding-block-end: var(--pf-v5-c-hint--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-hint--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-hint--PaddingRight);
  color: var(--pf-v5-c-hint--Color);
  background-color: var(--pf-v5-c-hint--BackgroundColor);
  border: var(--pf-v5-c-hint--BorderWidth) solid var(--pf-v5-c-hint--BorderColor);
  box-shadow: var(--pf-v5-c-hint--BoxShadow);
}

.pf-v5-c-hint .pf-v5-c-button.pf-m-link.pf-m-inline {
  text-align: start;
  white-space: normal;
}

.pf-v5-c-hint__actions {
  display: inline-grid;
  grid-row: 1;
  grid-column: 2;
  grid-auto-flow: column;
  margin-inline-start: var(--pf-v5-c-hint__actions--MarginLeft);
  text-align: end;
}

.pf-v5-c-hint__actions .pf-v5-c-dropdown .pf-v5-c-dropdown__toggle.pf-m-plain {
  margin-block-start: var(--pf-v5-c-hint__actions--c-dropdown--MarginTop);
}

.pf-v5-c-hint__actions+.pf-v5-c-hint__body {
  grid-column: 1;
}

.pf-v5-c-hint__title {
  font-size: var(--pf-v5-c-hint__title--FontSize);
}

.pf-v5-c-hint__body {
  grid-column: 1/-1;
  font-size: var(--pf-v5-c-hint__body--FontSize);
}

.pf-v5-c-hint__footer {
  grid-column: 1/-1;
}

.pf-v5-c-hint__footer>:not(:last-child) {
  margin-inline-end: var(--pf-v5-c-hint__footer--child--MarginRight);
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-hint {
  --pf-v5-c-hint--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-hint--BorderColor: var(--pf-v5-global--BorderColor--300);
}

.pf-v5-c-helper-text {
  --pf-v5-c-helper-text--Gap: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-helper-text--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-helper-text__item-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-helper-text__item-text--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-helper-text__item-icon--m-indeterminate--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-helper-text__item-text--m-indeterminate--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-helper-text__item-icon--m-warning--Color: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-helper-text__item-text--m-warning--Color: var(--pf-v5-global--warning-color--200);
  --pf-v5-c-helper-text__item-icon--m-success--Color: var(--pf-v5-global--success-color--100);
  --pf-v5-c-helper-text__item-text--m-success--Color: var(--pf-v5-global--success-color--200);
  --pf-v5-c-helper-text__item-icon--m-error--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-helper-text__item-text--m-error--Color: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-helper-text--m-dynamic__item-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-helper-text--m-dynamic__item-text--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-helper-text--m-dynamic--m-indeterminate__item-icon--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-helper-text--m-dynamic--m-indeterminate__item-text--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-helper-text--m-dynamic--m-warning__item-icon--Color: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-helper-text--m-dynamic--m-warning__item-text--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-helper-text--m-dynamic--m-success__item-icon--Color: var(--pf-v5-global--success-color--100);
  --pf-v5-c-helper-text--m-dynamic--m-success__item-text--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-helper-text--m-dynamic--m-error__item-icon--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-helper-text--m-dynamic--m-error__item-text--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-helper-text__item-icon--MarginRight: var(--pf-v5-global--spacer--xs);
  display: grid;
  gap: var(--pf-v5-c-helper-text--Gap);
  font-size: var(--pf-v5-c-helper-text--FontSize);
}

.pf-v5-c-helper-text.pf-m-hidden {
  visibility: hidden;
  opacity: 0;
}

.pf-v5-c-helper-text__item {
  display: flex;
}

.pf-v5-c-helper-text__item.pf-m-indeterminate {
  --pf-v5-c-helper-text__item-icon--Color: var(--pf-v5-c-helper-text__item-icon--m-indeterminate--Color);
  --pf-v5-c-helper-text__item-text--Color: var(--pf-v5-c-helper-text__item-text--m-indeterminate--Color);
  --pf-v5-c-helper-text--m-dynamic__item-icon--Color: var(--pf-v5-c-helper-text--m-dynamic--m-indeterminate__item-icon--Color);
  --pf-v5-c-helper-text--m-dynamic__item-text--Color: var(--pf-v5-c-helper-text--m-dynamic--m-indeterminate__item-text--Color);
}

.pf-v5-c-helper-text__item.pf-m-warning {
  --pf-v5-c-helper-text__item-icon--Color: var(--pf-v5-c-helper-text__item-icon--m-warning--Color);
  --pf-v5-c-helper-text__item-text--Color: var(--pf-v5-c-helper-text__item-text--m-warning--Color);
  --pf-v5-c-helper-text--m-dynamic__item-icon--Color: var(--pf-v5-c-helper-text--m-dynamic--m-warning__item-icon--Color);
  --pf-v5-c-helper-text--m-dynamic__item-text--Color: var(--pf-v5-c-helper-text--m-dynamic--m-warning__item-text--Color);
}

.pf-v5-c-helper-text__item.pf-m-success {
  --pf-v5-c-helper-text__item-icon--Color: var(--pf-v5-c-helper-text__item-icon--m-success--Color);
  --pf-v5-c-helper-text__item-text--Color: var(--pf-v5-c-helper-text__item-text--m-success--Color);
  --pf-v5-c-helper-text--m-dynamic__item-icon--Color: var(--pf-v5-c-helper-text--m-dynamic--m-success__item-icon--Color);
  --pf-v5-c-helper-text--m-dynamic__item-text--Color: var(--pf-v5-c-helper-text--m-dynamic--m-success__item-text--Color);
}

.pf-v5-c-helper-text__item.pf-m-error {
  --pf-v5-c-helper-text__item-icon--Color: var(--pf-v5-c-helper-text__item-icon--m-error--Color);
  --pf-v5-c-helper-text__item-text--Color: var(--pf-v5-c-helper-text__item-text--m-error--Color);
  --pf-v5-c-helper-text--m-dynamic__item-icon--Color: var(--pf-v5-c-helper-text--m-dynamic--m-error__item-icon--Color);
  --pf-v5-c-helper-text--m-dynamic__item-text--Color: var(--pf-v5-c-helper-text--m-dynamic--m-error__item-text--Color);
}

.pf-v5-c-helper-text__item.pf-m-dynamic {
  --pf-v5-c-helper-text__item-text--Color: var(--pf-v5-c-helper-text--m-dynamic__item-text--Color);
  --pf-v5-c-helper-text__item-icon--Color: var(--pf-v5-c-helper-text--m-dynamic__item-icon--Color);
}

.pf-v5-c-helper-text__item-icon {
  margin-inline-end: var(--pf-v5-c-helper-text__item-icon--MarginRight);
  color: var(--pf-v5-c-helper-text__item-icon--Color);
}

.pf-v5-c-helper-text__item-text {
  color: var(--pf-v5-c-helper-text__item-text--Color);
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-helper-text {
  --pf-v5-c-helper-text__item-text--m-success--Color: var(--pf-v5-global--success-color--100);
}

.pf-v5-c-icon {
  --pf-v5-c-icon--Width: 1em;
  --pf-v5-c-icon--Height: 1em;
  --pf-v5-c-icon--m-sm--Width: var(--pf-v5-global--icon--FontSize--sm);
  --pf-v5-c-icon--m-sm--Height: var(--pf-v5-global--icon--FontSize--sm);
  --pf-v5-c-icon--m-md--Width: var(--pf-v5-global--icon--FontSize--md);
  --pf-v5-c-icon--m-md--Height: var(--pf-v5-global--icon--FontSize--md);
  --pf-v5-c-icon--m-lg--Width: var(--pf-v5-global--icon--FontSize--lg);
  --pf-v5-c-icon--m-lg--Height: var(--pf-v5-global--icon--FontSize--lg);
  --pf-v5-c-icon--m-xl--Width: var(--pf-v5-global--icon--FontSize--xl);
  --pf-v5-c-icon--m-xl--Height: var(--pf-v5-global--icon--FontSize--xl);
  --pf-v5-c-icon--m-inline--Width: 1em;
  --pf-v5-c-icon--m-inline--Height: 1em;
  --pf-v5-c-icon__content--svg--VerticalAlign: -.125em;
  --pf-v5-c-icon__content--Color: initial;
  --pf-v5-c-icon__content--m-danger--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-icon__content--m-warning--Color: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-icon__content--m-success--Color: var(--pf-v5-global--success-color--100);
  --pf-v5-c-icon__content--m-info--Color: var(--pf-v5-global--info-color--100);
  --pf-v5-c-icon__content--m-custom--Color: var(--pf-v5-global--custom-color--100);
  --pf-v5-c-icon--m-inline__content--Color: initial;
  --pf-v5-c-icon__content--FontSize: 1em;
  --pf-v5-c-icon--m-sm__content--FontSize: var(--pf-v5-global--icon--FontSize--sm);
  --pf-v5-c-icon--m-md__content--FontSize: var(--pf-v5-global--icon--FontSize--md);
  --pf-v5-c-icon--m-lg__content--FontSize: var(--pf-v5-global--icon--FontSize--lg);
  --pf-v5-c-icon--m-xl__content--FontSize: var(--pf-v5-global--icon--FontSize--xl);
  --pf-v5-c-icon--m-inline__content--FontSize: 1em;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--pf-v5-c-icon--Width);
  height: var(--pf-v5-c-icon--Height);
}

.pf-v5-c-icon.pf-m-inline {
  --pf-v5-c-icon--Width: var(--pf-v5-c-icon--m-inline--Width);
  --pf-v5-c-icon--Height: var(--pf-v5-c-icon--m-inline--Height);
  --pf-v5-c-icon__content--FontSize: var(--pf-v5-c-icon--m-inline__content--FontSize);
  --pf-v5-c-icon__content--Color: var(--pf-v5-c-icon--m-inline__content--Color);
  line-height: 1;
}

.pf-v5-c-icon.pf-m-inline .pf-v5-c-spinner {
  --pf-v5-c-spinner--diameter: 1em;
}

.pf-v5-c-icon.pf-m-sm {
  --pf-v5-c-icon--Width: var(--pf-v5-c-icon--m-sm--Width);
  --pf-v5-c-icon--Height: var(--pf-v5-c-icon--m-sm--Height);
  --pf-v5-c-icon__content--FontSize: var(--pf-v5-c-icon--m-sm__content--FontSize);
}

.pf-v5-c-icon.pf-m-md {
  --pf-v5-c-icon--Width: var(--pf-v5-c-icon--m-md--Width);
  --pf-v5-c-icon--Height: var(--pf-v5-c-icon--m-md--Height);
  --pf-v5-c-icon__content--FontSize: var(--pf-v5-c-icon--m-md__content--FontSize);
}

.pf-v5-c-icon.pf-m-lg {
  --pf-v5-c-icon--Width: var(--pf-v5-c-icon--m-lg--Width);
  --pf-v5-c-icon--Height: var(--pf-v5-c-icon--m-lg--Height);
  --pf-v5-c-icon__content--FontSize: var(--pf-v5-c-icon--m-lg__content--FontSize);
}

.pf-v5-c-icon.pf-m-xl {
  --pf-v5-c-icon--Width: var(--pf-v5-c-icon--m-xl--Width);
  --pf-v5-c-icon--Height: var(--pf-v5-c-icon--m-xl--Height);
  --pf-v5-c-icon__content--FontSize: var(--pf-v5-c-icon--m-xl__content--FontSize);
}

.pf-v5-c-icon.pf-m-in-progress {
  --pf-v5-c-icon__content--Opacity: 0;
  --pf-v5-c-icon__progress--Opacity: 1;
}

.pf-v5-c-icon__content,
.pf-v5-c-icon__progress {
  font-size: var(--pf-v5-c-icon__content--FontSize);
}

.pf-v5-c-icon__content svg,
.pf-v5-c-icon__progress svg {
  vertical-align: var(--pf-v5-c-icon__content--svg--VerticalAlign);
}

.pf-v5-c-icon__content.pf-m-sm,
.pf-v5-c-icon__progress.pf-m-sm {
  --pf-v5-c-icon__content--FontSize: var(--pf-v5-c-icon--m-sm__content--FontSize);
}

.pf-v5-c-icon__content.pf-m-md,
.pf-v5-c-icon__progress.pf-m-md {
  --pf-v5-c-icon__content--FontSize: var(--pf-v5-c-icon--m-md__content--FontSize);
}

.pf-v5-c-icon__content.pf-m-lg,
.pf-v5-c-icon__progress.pf-m-lg {
  --pf-v5-c-icon__content--FontSize: var(--pf-v5-c-icon--m-lg__content--FontSize);
}

.pf-v5-c-icon__content.pf-m-xl,
.pf-v5-c-icon__progress.pf-m-xl {
  --pf-v5-c-icon__content--FontSize: var(--pf-v5-c-icon--m-xl__content--FontSize);
}

.pf-v5-c-icon__content {
  color: var(--pf-v5-c-icon__content--Color, inherit);
  opacity: var(--pf-v5-c-icon__content--Opacity, 1);
}

.pf-v5-c-icon__content.pf-m-danger {
  --pf-v5-c-icon__content--Color: var(--pf-v5-c-icon__content--m-danger--Color);
}

.pf-v5-c-icon__content.pf-m-warning {
  --pf-v5-c-icon__content--Color: var(--pf-v5-c-icon__content--m-warning--Color);
}

.pf-v5-c-icon__content.pf-m-success {
  --pf-v5-c-icon__content--Color: var(--pf-v5-c-icon__content--m-success--Color);
}

.pf-v5-c-icon__content.pf-m-info {
  --pf-v5-c-icon__content--Color: var(--pf-v5-c-icon__content--m-info--Color);
}

.pf-v5-c-icon__content.pf-m-custom {
  --pf-v5-c-icon__content--Color: var(--pf-v5-c-icon__content--m-custom--Color);
}

.pf-v5-c-icon__progress {
  position: absolute;
  inset-block-start: calc(50% + .5*var(--pf-v5-c-icon__content--svg--VerticalAlign));
  opacity: var(--pf-v5-c-icon__progress--Opacity, 0);
  transform: translateY(calc(-50% - 0.5 * var(--pf-v5-c-icon__content--svg--VerticalAlign)));
}

.pf-v5-c-inline-edit {
  --pf-v5-c-inline-edit__group--item--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-inline-edit__action--c-button--m-valid--m-plain--Color: var(--pf-v5-global--link--Color);
  --pf-v5-c-inline-edit__action--c-button--m-valid--m-plain--hover--Color: var(--pf-v5-global--link--Color--hover);
  --pf-v5-c-inline-edit__action--m-icon-group--item--MarginRight: 0;
  --pf-v5-c-inline-edit__group--m-footer--MarginTop: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-inline-edit__label--m-bold--FontWeight: var(--pf-v5-global--FontWeight--bold);
}

.pf-v5-c-inline-edit__group {
  display: flex;
  align-items: baseline;
}

.pf-v5-c-inline-edit__group>* {
  margin-inline-end: var(--pf-v5-c-inline-edit__group--item--MarginRight);
}

.pf-v5-c-inline-edit__group.pf-m-icon-group {
  --pf-v5-c-inline-edit__group--item--MarginRight: var(--pf-v5-c-inline-edit__action--m-icon-group--item--MarginRight);
}

.pf-v5-c-inline-edit__group.pf-m-footer {
  margin-block-start: var(--pf-v5-c-inline-edit__group--m-footer--MarginTop);
}

.pf-v5-c-inline-edit__group.pf-m-column {
  --pf-v5-c-inline-edit__group--item--MarginRight: 0;
  flex-direction: column;
}

.pf-v5-c-inline-edit__group>:last-child {
  --pf-v5-c-inline-edit__group--item--MarginRight: 0;
}

.pf-v5-c-inline-edit__input {
  flex: 1;
}

.pf-v5-c-inline-edit__action.pf-m-valid .pf-v5-c-button.pf-m-plain {
  --pf-v5-c-button--m-plain--Color: var(--pf-v5-c-inline-edit__action--c-button--m-valid--m-plain--Color);
}

.pf-v5-c-inline-edit__action.pf-m-valid .pf-v5-c-button.pf-m-plain:hover {
  --pf-v5-c-button--m-plain--Color: var(--pf-v5-c-inline-edit__action--c-button--m-valid--m-plain--hover--Color);
}

.pf-v5-c-inline-edit__input,
.pf-v5-c-inline-edit__action,
.pf-v5-c-inline-edit__group.pf-m-action-group {
  display: none;
}

.pf-v5-c-inline-edit__action.pf-m-enable-editable {
  display: inline-block;
}

.pf-v5-c-inline-edit.pf-m-inline-editable .pf-v5-c-inline-edit__input,
.pf-v5-c-inline-edit .pf-m-inline-editable .pf-v5-c-inline-edit__input {
  display: block;
}

.pf-v5-c-inline-edit.pf-m-inline-editable .pf-v5-c-inline-edit__action,
.pf-v5-c-inline-edit .pf-m-inline-editable .pf-v5-c-inline-edit__action {
  display: inline-block;
}

.pf-v5-c-inline-edit.pf-m-inline-editable .pf-v5-c-inline-edit__group.pf-m-action-group,
.pf-v5-c-inline-edit .pf-m-inline-editable .pf-v5-c-inline-edit__group.pf-m-action-group {
  display: inline-flex;
}

.pf-v5-c-inline-edit.pf-m-inline-editable .pf-v5-c-inline-edit__value,
.pf-v5-c-inline-edit.pf-m-inline-editable .pf-v5-c-inline-edit__action.pf-m-enable-editable,
.pf-v5-c-inline-edit .pf-m-inline-editable .pf-v5-c-inline-edit__value,
.pf-v5-c-inline-edit .pf-m-inline-editable .pf-v5-c-inline-edit__action.pf-m-enable-editable {
  display: none;
}

.pf-v5-c-inline-edit__label+.pf-v5-c-inline-edit__action.pf-m-enable>.pf-v5-c-button {
  margin-block-start: calc(var(--pf-v5-c-button--PaddingTop)*-1);
  margin-block-end: calc(var(--pf-v5-c-button--PaddingBottom)*-1);
}

.pf-v5-c-inline-edit__label.pf-m-bold {
  font-weight: var(--pf-v5-c-inline-edit__label--m-bold--FontWeight);
}

.pf-v5-c-inline-edit__editable-text br {
  display: none;
}

.pf-v5-c-input-group {
  --pf-v5-c-input-group--child--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-input-group__item--offset: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-input-group__item--MarginLeft: calc(var(--pf-v5-c-input-group__item--offset) * -1);
  --pf-v5-c-input-group__item--BorderWidth--base: var(--pf-v5-c-input-group__item--offset);
  --pf-v5-c-input-group__item--BorderColor--base: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-input-group__item--BorderColor--accent: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-input-group__item--BackgroundColor: transparent;
  --pf-v5-c-input-group__item--m-box--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-input-group__item--m-box--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-input-group__item--m-box--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-input-group__item--m-box--BorderWidth: var(--pf-v5-c-input-group__item--offset);
  --pf-v5-c-input-group__item--m-box--BorderTopColor: var(--pf-v5-c-input-group__item--BorderColor--base);
  --pf-v5-c-input-group__item--m-box--BorderRightColor: var(--pf-v5-c-input-group__item--BorderColor--base);
  --pf-v5-c-input-group__item--m-box--BorderBottomColor: var(--pf-v5-c-input-group__item--BorderColor--accent);
  --pf-v5-c-input-group__item--m-box--BorderLeftColor: var(--pf-v5-c-input-group__item--BorderColor--base);
  --pf-v5-c-input-group__item--m-plain--BackgroundColor: transparent;
  --pf-v5-c-input-group__text--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-input-group__text--Color: var(--pf-v5-global--Color--dark-200);
  --pf-v5-c-input-group__item--m-disabled__text--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-input-group__item--m-disabled--BorderBottomColor: transparent;
  --pf-v5-c-input-group__item--m-disabled--BackgroundColor: var(--pf-v5-global--disabled-color--300);
  display: flex;
  width: 100%;
}

.pf-v5-c-input-group__item {
  position: relative;
  display: flex;
  min-width: var(--pf-v5-c-input-group__item--MinWidth, revert);
  max-width: var(--pf-v5-c-input-group__item--MaxWidth, revert);
  background-color: var(--pf-v5-c-input-group__item--BackgroundColor);
  border-block-start-color: var(--pf-v5-c-input-group__item--m-box--BorderTopColor);
  border-block-end-color: var(--pf-v5-c-input-group__item--m-box--BorderBottomColor);
  border-inline-start-color: var(--pf-v5-c-input-group__item--m-box--BorderLeftColor);
  border-inline-end-color: var(--pf-v5-c-input-group__item--m-box--BorderRightColor);
}

.pf-v5-c-input-group__item:where(:not(:first-child)) {
  margin-inline-start: var(--pf-v5-c-input-group__item--MarginLeft);
}

.pf-v5-c-input-group__item.pf-m-box {
  --pf-v5-c-input-group__item--BackgroundColor: var(--pf-v5-c-input-group__item--m-box--BackgroundColor);
  padding-inline-start: var(--pf-v5-c-input-group__item--m-box--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-input-group__item--m-box--PaddingRight);
  border: var(--pf-v5-c-input-group__item--m-box--BorderWidth) solid;
  border-block-start-color: var(--pf-v5-c-input-group__item--m-box--BorderTopColor);
  border-block-end-color: var(--pf-v5-c-input-group__item--m-box--BorderBottomColor);
  border-inline-start-color: var(--pf-v5-c-input-group__item--m-box--BorderLeftColor);
  border-inline-end-color: var(--pf-v5-c-input-group__item--m-box--BorderRightColor);
}

.pf-v5-c-input-group__item.pf-m-plain {
  --pf-v5-c-input-group__item--MarginLeft: 0;
  --pf-v5-c-input-group__item--BackgroundColor: var(--pf-v5-c-input-group__item--m-plain--BackgroundColor);
  border: none;
}

.pf-v5-c-input-group__item.pf-m-disabled {
  --pf-v5-c-input-group__item--BackgroundColor: var(--pf-v5-c-input-group__item--m-disabled--BackgroundColor);
  --pf-v5-c-input-group__item--m-box--BorderBottomColor: var(--pf-v5-c-input-group__item--m-disabled--BorderBottomColor);
  --pf-v5-c-input-group__text--Color: var(--pf-v5-c-input-group__item--m-disabled__text--Color);
}

.pf-v5-c-input-group__item.pf-m-fill {
  flex-grow: 1;
}

.pf-v5-c-input-group__text {
  align-self: center;
  font-size: var(--pf-v5-c-input-group__text--FontSize);
  color: var(--pf-v5-c-input-group__text--Color);
}

label.pf-v5-c-input-group__text {
  cursor: pointer;
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-input-group {
  --pf-v5-c-input-group__item--BorderLeftWidth: var(--pf-v5-c-input-group__item--BorderWidth--base);
  --pf-v5-c-input-group__item--BorderLeftColor: var(--pf-v5-global--palette--black-700);
  --pf-v5-c-input-group__item--m-box--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-input-group__item--m-box--BorderTopColor: transparent;
  --pf-v5-c-input-group__item--m-box--BorderRightColor: transparent;
  --pf-v5-c-input-group__item--m-box--BorderBottomColor: var(--pf-v5-global--BorderColor--400);
  --pf-v5-c-input-group__item--m-box--BorderLeftColor: transparent;
  --pf-v5-c-input-group__item--m-disabled--BackgroundColor: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-input-group__item--m-disabled--BorderBottomColor: transparent;
  --pf-v5-c-input-group__item--m-disabled__text--Color: var(--pf-v5-global--disabled-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-input-group>*+* {
  --pf-v5-c-input-group__item--m-box--BorderLeftColor: var(--pf-v5-c-input-group__item--BorderLeftColor);
  border-inline-start: var(--pf-v5-c-input-group__item--BorderLeftWidth) solid var(--pf-v5-c-input-group__item--BorderLeftColor);
}

.pf-v5-c-jump-links {
  --pf-v5-c-jump-links__list--Display: flex;
  --pf-v5-c-jump-links__list--PaddingTop: 0;
  --pf-v5-c-jump-links__list--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-jump-links__list--PaddingBottom: 0;
  --pf-v5-c-jump-links__list--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-jump-links--m-vertical__list--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-jump-links--m-vertical__list--PaddingRight: 0;
  --pf-v5-c-jump-links--m-vertical__list--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-jump-links--m-vertical__list--PaddingLeft: 0;
  --pf-v5-c-jump-links__list--FlexDirection: row;
  --pf-v5-c-jump-links--m-vertical__list--FlexDirection: column;
  --pf-v5-c-jump-links__list--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-jump-links__list--before--BorderTopWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-jump-links__list--before--BorderRightWidth: 0;
  --pf-v5-c-jump-links__list--before--BorderBottomWidth: 0;
  --pf-v5-c-jump-links__list--before--BorderLeftWidth: 0;
  --pf-v5-c-jump-links--m-vertical__list--before--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-jump-links--m-vertical__list--before--BorderTopWidth: 0;
  --pf-v5-c-jump-links__list__list--MarginTop: calc(var(--pf-v5-global--spacer--sm) * -1);
  --pf-v5-c-jump-links__link--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-jump-links__link--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-jump-links__link--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-jump-links__link--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-jump-links__list__list__link--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-jump-links__list__list__link--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-jump-links__list__list__link--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-jump-links__link--OutlineOffset: calc(-1 * var(--pf-v5-global--spacer--sm));
  --pf-v5-c-jump-links__link--before--BorderTopWidth: 0;
  --pf-v5-c-jump-links__link--before--BorderRightWidth: 0;
  --pf-v5-c-jump-links__link--before--BorderBottomWidth: 0;
  --pf-v5-c-jump-links__link--before--BorderLeftWidth: 0;
  --pf-v5-c-jump-links__link--before--BorderColor: transparent;
  --pf-v5-c-jump-links__item--m-current__link--before--BorderTopWidth: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-jump-links__item--m-current__link--before--BorderLeftWidth: 0;
  --pf-v5-c-jump-links__item--m-current__link--before--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-jump-links--m-vertical__item--m-current__link--before--BorderTopWidth: 0;
  --pf-v5-c-jump-links--m-vertical__item--m-current__link--before--BorderLeftWidth: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-jump-links__link-text--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-jump-links__link--hover__link-text--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-jump-links__link--focus__link-text--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-jump-links__item--m-current__link-text--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-jump-links__label--MarginBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-jump-links__label--Display: block;
  --pf-v5-c-jump-links__toggle--MarginTop: calc(-1 * var(--pf-v5-global--spacer--form-element));
  --pf-v5-c-jump-links__toggle--MarginBottom--base: calc(-1 * var(--pf-v5-global--spacer--form-element));
  --pf-v5-c-jump-links__toggle--MarginBottom: var(--pf-v5-c-jump-links__toggle--MarginBottom--base);
  --pf-v5-c-jump-links--m-expanded__toggle--MarginBottom: calc(var(--pf-v5-c-jump-links__toggle--MarginBottom--base) + var(--pf-v5-global--spacer--md));
  --pf-v5-c-jump-links__toggle--MarginLeft: calc(-1 * var(--pf-v5-global--spacer--md));
  --pf-v5-c-jump-links__toggle--Display: none;
  --pf-v5-c-jump-links__toggle-icon--Color: currentcolor;
  --pf-v5-c-jump-links__toggle-icon--Transition: var(--pf-v5-global--Transition);
  --pf-v5-c-jump-links__toggle-icon--Rotate: 0;
  --pf-v5-c-jump-links--m-expanded__toggle-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-jump-links--m-expanded__toggle-icon--Rotate: 90deg;
  --pf-v5-c-jump-links__toggle-text--MarginLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-jump-links__toggle-text--Color: var(--pf-v5-global--Color--100);
  display: flex;
}

.pf-v5-c-jump-links.pf-m-center {
  justify-content: center;
}

.pf-v5-c-jump-links.pf-m-center .pf-v5-c-jump-links__main {
  align-items: center;
}

.pf-v5-c-jump-links.pf-m-vertical,
.pf-v5-c-jump-links.pf-m-expandable {
  --pf-v5-c-jump-links__list--PaddingTop: var(--pf-v5-c-jump-links--m-vertical__list--PaddingTop);
  --pf-v5-c-jump-links__list--PaddingRight: var(--pf-v5-c-jump-links--m-vertical__list--PaddingRight);
  --pf-v5-c-jump-links__list--PaddingBottom: var(--pf-v5-c-jump-links--m-vertical__list--PaddingBottom);
  --pf-v5-c-jump-links__list--PaddingLeft: var(--pf-v5-c-jump-links--m-vertical__list--PaddingLeft);
  --pf-v5-c-jump-links__list--before--BorderTopWidth: var(--pf-v5-c-jump-links--m-vertical__list--before--BorderTopWidth);
  --pf-v5-c-jump-links__list--before--BorderLeftWidth: var(--pf-v5-c-jump-links--m-vertical__list--before--BorderLeftWidth);
  --pf-v5-c-jump-links__item--m-current__link--before--BorderTopWidth: var(--pf-v5-c-jump-links--m-vertical__item--m-current__link--before--BorderTopWidth);
  --pf-v5-c-jump-links__item--m-current__link--before--BorderLeftWidth: var(--pf-v5-c-jump-links--m-vertical__item--m-current__link--before--BorderLeftWidth);
  --pf-v5-c-jump-links__list--FlexDirection: var(--pf-v5-c-jump-links--m-vertical__list--FlexDirection);
  flex-direction: column;
}

.pf-v5-c-jump-links.pf-m-expandable {
  --pf-v5-c-jump-links__list--Display: none;
  --pf-v5-c-jump-links__toggle--Display: block;
  --pf-v5-c-jump-links__label--Display: none;
}

.pf-v5-c-jump-links.pf-m-non-expandable {
  --pf-v5-c-jump-links__list--Display: flex;
  --pf-v5-c-jump-links__toggle--Display: none;
  --pf-v5-c-jump-links__label--Display: block;
}

@media (min-width: 576px) {
  .pf-v5-c-jump-links.pf-m-expandable-on-sm {
    --pf-v5-c-jump-links__list--Display: none;
    --pf-v5-c-jump-links__toggle--Display: block;
    --pf-v5-c-jump-links__label--Display: none;
  }

  .pf-v5-c-jump-links.pf-m-non-expandable-on-sm {
    --pf-v5-c-jump-links__list--Display: flex;
    --pf-v5-c-jump-links__toggle--Display: none;
    --pf-v5-c-jump-links__label--Display: block;
  }
}

@media (min-width: 768px) {
  .pf-v5-c-jump-links.pf-m-expandable-on-md {
    --pf-v5-c-jump-links__list--Display: none;
    --pf-v5-c-jump-links__toggle--Display: block;
    --pf-v5-c-jump-links__label--Display: none;
  }

  .pf-v5-c-jump-links.pf-m-non-expandable-on-md {
    --pf-v5-c-jump-links__list--Display: flex;
    --pf-v5-c-jump-links__toggle--Display: none;
    --pf-v5-c-jump-links__label--Display: block;
  }
}

@media (min-width: 992px) {
  .pf-v5-c-jump-links.pf-m-expandable-on-lg {
    --pf-v5-c-jump-links__list--Display: none;
    --pf-v5-c-jump-links__toggle--Display: block;
    --pf-v5-c-jump-links__label--Display: none;
  }

  .pf-v5-c-jump-links.pf-m-non-expandable-on-lg {
    --pf-v5-c-jump-links__list--Display: flex;
    --pf-v5-c-jump-links__toggle--Display: none;
    --pf-v5-c-jump-links__label--Display: block;
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-jump-links.pf-m-expandable-on-xl {
    --pf-v5-c-jump-links__list--Display: none;
    --pf-v5-c-jump-links__toggle--Display: block;
    --pf-v5-c-jump-links__label--Display: none;
  }

  .pf-v5-c-jump-links.pf-m-non-expandable-on-xl {
    --pf-v5-c-jump-links__list--Display: flex;
    --pf-v5-c-jump-links__toggle--Display: none;
    --pf-v5-c-jump-links__label--Display: block;
  }
}

@media (min-width: 1450px) {
  .pf-v5-c-jump-links.pf-m-expandable-on-2xl {
    --pf-v5-c-jump-links__list--Display: none;
    --pf-v5-c-jump-links__toggle--Display: block;
    --pf-v5-c-jump-links__label--Display: none;
  }

  .pf-v5-c-jump-links.pf-m-non-expandable-on-2xl {
    --pf-v5-c-jump-links__list--Display: flex;
    --pf-v5-c-jump-links__toggle--Display: none;
    --pf-v5-c-jump-links__label--Display: block;
  }
}

.pf-v5-c-jump-links.pf-m-expanded {
  --pf-v5-c-jump-links__list--Display: flex;
  --pf-v5-c-jump-links__toggle--MarginBottom: var(--pf-v5-c-jump-links--m-expanded__toggle--MarginBottom);
  --pf-v5-c-jump-links__toggle-icon--Rotate: var(--pf-v5-c-jump-links--m-expanded__toggle-icon--Rotate);
  --pf-v5-c-jump-links__toggle-icon--Color: var(--pf-v5-c-jump-links--m-expanded__toggle-icon--Color);
}

.pf-v5-c-jump-links__list {
  position: relative;
  display: var(--pf-v5-c-jump-links__list--Display);
  flex-direction: var(--pf-v5-c-jump-links__list--FlexDirection);
  padding-block-start: var(--pf-v5-c-jump-links__list--PaddingTop);
  padding-block-end: var(--pf-v5-c-jump-links__list--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-jump-links__list--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-jump-links__list--PaddingRight);
}

.pf-v5-c-jump-links__list::before {
  position: absolute;
  inset: 0;
  pointer-events: none;
  content: "";
  border: solid var(--pf-v5-c-jump-links__list--before--BorderColor);
  border-block-start-width: var(--pf-v5-c-jump-links__list--before--BorderTopWidth);
  border-block-end-width: var(--pf-v5-c-jump-links__list--before--BorderBottomWidth);
  border-inline-start-width: var(--pf-v5-c-jump-links__list--before--BorderLeftWidth);
  border-inline-end-width: var(--pf-v5-c-jump-links__list--before--BorderRightWidth);
}

.pf-v5-c-jump-links__list .pf-v5-c-jump-links__list {
  --pf-v5-c-jump-links__list--PaddingTop: 0;
  --pf-v5-c-jump-links__list--PaddingBottom: 0;
  --pf-v5-c-jump-links__link--PaddingTop: var(--pf-v5-c-jump-links__list__list__link--PaddingTop);
  --pf-v5-c-jump-links__link--PaddingBottom: var(--pf-v5-c-jump-links__list__list__link--PaddingBottom);
  --pf-v5-c-jump-links__link--PaddingLeft: var(--pf-v5-c-jump-links__list__list__link--PaddingLeft);
  margin-block-start: var(--pf-v5-c-jump-links__list__list--MarginTop);
}

.pf-v5-c-jump-links__link {
  position: relative;
  display: flex;
  flex: 1;
  padding-block-start: var(--pf-v5-c-jump-links__link--PaddingTop);
  padding-block-end: var(--pf-v5-c-jump-links__link--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-jump-links__link--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-jump-links__link--PaddingRight);
  text-decoration: none;
  outline-offset: var(--pf-v5-c-jump-links__link--OutlineOffset);
}

.pf-v5-c-jump-links__link:hover {
  --pf-v5-c-jump-links__link-text--Color: var(--pf-v5-c-jump-links__link--hover__link-text--Color);
}

.pf-v5-c-jump-links__link:focus {
  --pf-v5-c-jump-links__link-text--Color: var(--pf-v5-c-jump-links__link--focus__link-text--Color);
}

.pf-v5-c-jump-links__link::before {
  position: absolute;
  inset: 0;
  pointer-events: none;
  content: "";
  border-color: var(--pf-v5-c-jump-links__link--before--BorderColor);
  border-style: solid;
  border-block-start-width: var(--pf-v5-c-jump-links__link--before--BorderTopWidth);
  border-block-end-width: var(--pf-v5-c-jump-links__link--before--BorderBottomWidth);
  border-inline-start-width: var(--pf-v5-c-jump-links__link--before--BorderLeftWidth);
  border-inline-end-width: var(--pf-v5-c-jump-links__link--before--BorderRightWidth);
}

.pf-v5-c-jump-links__item {
  --pf-v5-c-jump-links__list--before--BorderColor: transparent;
}

.pf-v5-c-jump-links__item.pf-m-current>.pf-v5-c-jump-links__link {
  --pf-v5-c-jump-links__link--before--BorderTopWidth: var(--pf-v5-c-jump-links__item--m-current__link--before--BorderTopWidth);
  --pf-v5-c-jump-links__link--before--BorderLeftWidth: var(--pf-v5-c-jump-links__item--m-current__link--before--BorderLeftWidth);
  --pf-v5-c-jump-links__link--before--BorderColor: var(--pf-v5-c-jump-links__item--m-current__link--before--BorderColor);
  --pf-v5-c-jump-links__link-text--Color: var(--pf-v5-c-jump-links__item--m-current__link-text--Color);
}

.pf-v5-c-jump-links__link-text {
  color: var(--pf-v5-c-jump-links__link-text--Color);
}

.pf-v5-c-jump-links__label {
  display: var(--pf-v5-c-jump-links__label--Display);
  margin-block-end: var(--pf-v5-c-jump-links__label--MarginBottom);
}

.pf-v5-c-jump-links__main {
  display: flex;
  flex-direction: column;
}

.pf-v5-c-jump-links__toggle {
  display: var(--pf-v5-c-jump-links__toggle--Display);
  margin-block-start: var(--pf-v5-c-jump-links__toggle--MarginTop);
  margin-block-end: var(--pf-v5-c-jump-links__toggle--MarginBottom);
  margin-inline-start: var(--pf-v5-c-jump-links__toggle--MarginLeft);
}

.pf-v5-c-jump-links__toggle .pf-v5-c-button {
  display: flex;
  align-items: center;
}

.pf-v5-c-jump-links__toggle-icon {
  display: inline-block;
  color: var(--pf-v5-c-jump-links__toggle-icon--Color);
  transition: var(--pf-v5-c-jump-links__toggle-icon--Transition);
  transform: rotate(var(--pf-v5-c-jump-links__toggle-icon--Rotate));
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-jump-links__toggle-icon {
  scale: -1 1;
}

.pf-v5-c-jump-links__toggle-text {
  margin-inline-start: var(--pf-v5-c-jump-links__toggle-text--MarginLeft);
  color: var(--pf-v5-c-jump-links__toggle-text--Color);
}

.pf-v5-c-label {
  --pf-v5-c-label--PaddingTop: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-label--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-label--PaddingBottom: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-label--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-label--MaxWidth: 100%;
  --pf-v5-c-label--BorderRadius: var(--pf-v5-global--BorderRadius--lg);
  --pf-v5-c-label--BackgroundColor: var(--pf-v5-global--palette--black-150);
  --pf-v5-c-label--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-label--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-label__content--before--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-label__content--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-outline--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-label--m-outline__content--before--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-label--m-outline__content--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label__content--link--hover--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-label__content--link--focus--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-label__content--link--hover--before--BorderColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-label__content--link--focus--before--BorderColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-label--m-outline__content--link--hover--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-label--m-outline__content--link--focus--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-label--m-outline__content--link--hover--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-outline__content--link--focus--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-blue--BackgroundColor: var(--pf-v5-global--palette--blue-50);
  --pf-v5-c-label--m-blue__icon--Color: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-label--m-blue__content--Color: var(--pf-v5-global--info-color--200);
  --pf-v5-c-label--m-blue__content--before--BorderColor: var(--pf-v5-global--palette--blue-100);
  --pf-v5-c-label--m-blue__content--link--hover--before--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-label--m-blue__content--link--focus--before--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-label--m-outline--m-blue__content--Color: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-label--m-outline--m-blue__content--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-outline--m-blue__content--link--hover--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-outline--m-blue__content--link--focus--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-green--BackgroundColor: var(--pf-v5-global--palette--green-50);
  --pf-v5-c-label--m-green__icon--Color: var(--pf-v5-global--success-color--100);
  --pf-v5-c-label--m-green__content--Color: var(--pf-v5-global--success-color--200);
  --pf-v5-c-label--m-green__content--before--BorderColor: var(--pf-v5-global--palette--green-100);
  --pf-v5-c-label--m-green__content--link--hover--before--BorderColor: var(--pf-v5-global--success-color--100);
  --pf-v5-c-label--m-green__content--link--focus--before--BorderColor: var(--pf-v5-global--success-color--100);
  --pf-v5-c-label--m-outline--m-green__content--Color: var(--pf-v5-global--success-color--100);
  --pf-v5-c-label--m-outline--m-green__content--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-outline--m-green__content--link--hover--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-outline--m-green__content--link--focus--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-orange--BackgroundColor: var(--pf-v5-global--palette--orange-50);
  --pf-v5-c-label--m-orange__icon--Color: var(--pf-v5-global--palette--orange-300);
  --pf-v5-c-label--m-orange__content--Color: var(--pf-v5-global--palette--orange-700);
  --pf-v5-c-label--m-orange__content--before--BorderColor: var(--pf-v5-global--palette--orange-100);
  --pf-v5-c-label--m-orange__content--link--hover--before--BorderColor: var(--pf-v5-global--palette--orange-300);
  --pf-v5-c-label--m-orange__content--link--focus--before--BorderColor: var(--pf-v5-global--palette--orange-300);
  --pf-v5-c-label--m-outline--m-orange__content--Color: var(--pf-v5-global--palette--orange-500);
  --pf-v5-c-label--m-outline--m-orange__content--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-outline--m-orange__content--link--hover--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-outline--m-orange__content--link--focus--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-red--BackgroundColor: var(--pf-v5-global--palette--red-50);
  --pf-v5-c-label--m-red__icon--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-label--m-red__content--Color: var(--pf-v5-global--palette--red-300);
  --pf-v5-c-label--m-red__content--before--BorderColor: var(--pf-v5-global--palette--red-100);
  --pf-v5-c-label--m-red__content--link--hover--before--BorderColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-label--m-red__content--link--focus--before--BorderColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-label--m-outline--m-red__content--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-label--m-outline--m-red__content--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-outline--m-red__content--link--hover--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-outline--m-red__content--link--focus--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-purple--BackgroundColor: var(--pf-v5-global--palette--purple-50);
  --pf-v5-c-label--m-purple__icon--Color: var(--pf-v5-global--palette--purple-500);
  --pf-v5-c-label--m-purple__content--Color: var(--pf-v5-global--palette--purple-700);
  --pf-v5-c-label--m-purple__content--before--BorderColor: var(--pf-v5-global--palette--purple-100);
  --pf-v5-c-label--m-purple__content--link--hover--before--BorderColor: var(--pf-v5-global--palette--purple-500);
  --pf-v5-c-label--m-purple__content--link--focus--before--BorderColor: var(--pf-v5-global--palette--purple-500);
  --pf-v5-c-label--m-outline--m-purple__content--Color: var(--pf-v5-global--palette--purple-500);
  --pf-v5-c-label--m-outline--m-purple__content--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-outline--m-purple__content--link--hover--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-outline--m-purple__content--link--focus--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-cyan--BackgroundColor: var(--pf-v5-global--palette--cyan-50);
  --pf-v5-c-label--m-cyan__icon--Color: var(--pf-v5-global--custom-color--200);
  --pf-v5-c-label--m-cyan__content--Color: var(--pf-v5-global--custom-color--300);
  --pf-v5-c-label--m-cyan__content--before--BorderColor: var(--pf-v5-global--palette--cyan-100);
  --pf-v5-c-label--m-cyan__content--link--hover--before--BorderColor: var(--pf-v5-global--custom-color--200);
  --pf-v5-c-label--m-cyan__content--link--focus--before--BorderColor: var(--pf-v5-global--custom-color--200);
  --pf-v5-c-label--m-outline--m-cyan__content--Color: var(--pf-v5-global--palette--cyan-400);
  --pf-v5-c-label--m-outline--m-cyan__content--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-outline--m-cyan__content--link--hover--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-outline--m-cyan__content--link--focus--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-gold--BackgroundColor: var(--pf-v5-global--palette--gold-50);
  --pf-v5-c-label--m-gold__icon--Color: var(--pf-v5-global--palette--gold-400);
  --pf-v5-c-label--m-gold__content--Color: var(--pf-v5-global--palette--gold-700);
  --pf-v5-c-label--m-gold__content--before--BorderColor: var(--pf-v5-global--palette--gold-100);
  --pf-v5-c-label--m-gold__content--link--hover--before--BorderColor: var(--pf-v5-global--palette--gold-300);
  --pf-v5-c-label--m-gold__content--link--focus--before--BorderColor: var(--pf-v5-global--palette--gold-300);
  --pf-v5-c-label--m-outline--m-gold__content--Color: var(--pf-v5-global--palette--gold-600);
  --pf-v5-c-label--m-outline--m-gold__content--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-outline--m-gold__content--link--hover--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-outline--m-gold__content--link--focus--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-overflow__content--Color: var(--pf-v5-global--link--Color);
  --pf-v5-c-label--m-overflow__content--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-label--m-overflow__content--before--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-label--m-overflow__content--before--BorderColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-label--m-overflow__content--link--hover--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-label--m-overflow__content--link--hover--before--BorderColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-label--m-overflow__content--link--focus--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-label--m-overflow__content--link--focus--before--BorderColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-label--m-compact--PaddingTop: 0;
  --pf-v5-c-label--m-compact--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-label--m-compact--PaddingBottom: 0;
  --pf-v5-c-label--m-compact--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-label--m-compact--FontSize: var(--pf-v5-global--FontSize--xs);
  --pf-v5-c-label--m-compact--m-editable--TextDecorationOffset: 0.0625rem;
  --pf-v5-c-label__content--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-label__content--BackgroundColor: transparent;
  --pf-v5-c-label__content--MaxWidth: 100%;
  --pf-v5-c-label--m-outline__content--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-label--m-editable__content--MaxWidth: 16ch;
  --pf-v5-c-label__text--MaxWidth: 100%;
  --pf-v5-c-label__icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-label__icon--MarginRight: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-label__actions--FontSize: var(--pf-v5-global--FontSize--xs);
  --pf-v5-c-label__actions--MarginRight: calc(var(--pf-v5-c-label__actions--c-button--PaddingRight) * -1);
  --pf-v5-c-label__actions--c-button--FontSize: var(--pf-v5-global--FontSize--xs);
  --pf-v5-c-label__actions--c-button--MarginTop: calc(var(--pf-v5-c-label__actions--c-button--PaddingTop) * -1);
  --pf-v5-c-label__actions--c-button--MarginBottom: calc(var(--pf-v5-c-label__actions--c-button--PaddingBottom) * -1);
  --pf-v5-c-label__actions--c-button--PaddingTop: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-label__actions--c-button--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-label__actions--c-button--PaddingBottom: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-label__actions--c-button--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-label--m-editable--Cursor: pointer;
  --pf-v5-c-label--m-editable--TextDecoration: underline;
  --pf-v5-c-label--m-editable--TextDecorationStyle: dashed;
  --pf-v5-c-label--m-editable--TextDecorationThickness: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-label--m-editable--TextDecorationOffset: 0.25rem;
  --pf-v5-c-label--m-editable--TextDecorationColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-label--m-editable--hover--TextDecorationColor: var(--pf-v5-global--Color--100);
  --pf-v5-c-label--m-editable--focus--TextDecorationColor: var(--pf-v5-global--Color--100);
  --pf-v5-c-label--m-editable__content--before--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-label--m-editable__content--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-editable__content--hover--before--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-label--m-editable__content--hover--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-editable__content--focus--before--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-label--m-editable__content--focus--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-label--m-editable--m-editable-active--Cursor: auto;
  --pf-v5-c-label--m-editable--m-editable-active--TextDecoration: none;
  --pf-v5-c-label--m-editable--m-editable-active--BackgroundColor: transparent;
  --pf-v5-c-label--m-editable--m-editable-active__content--before--BorderWidth: 0;
  --pf-v5-c-label--m-editable--m-editable-active__content--before--BorderColor: transparent;
  --pf-v5-c-label--m-disabled--BackgroundColor: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-label--m-disabled__content--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-label--m-disabled__icon--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-label--c-button--m-disabled--Color: var(--pf-v5-global--disabled-color--100);
  position: relative;
  max-width: var(--pf-v5-c-label--MaxWidth);
  padding-block-start: var(--pf-v5-c-label--PaddingTop);
  padding-block-end: var(--pf-v5-c-label--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-label--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-label--PaddingRight);
  font-size: var(--pf-v5-c-label--FontSize);
  color: var(--pf-v5-c-label--Color);
  white-space: nowrap;
  background-color: var(--pf-v5-c-label--BackgroundColor);
  border: 0;
  border-radius: var(--pf-v5-c-label--BorderRadius);
}

.pf-v5-c-label.pf-m-compact {
  --pf-v5-c-label--PaddingTop: var(--pf-v5-c-label--m-compact--PaddingTop);
  --pf-v5-c-label--PaddingRight: var(--pf-v5-c-label--m-compact--PaddingRight);
  --pf-v5-c-label--PaddingBottom: var(--pf-v5-c-label--m-compact--PaddingBottom);
  --pf-v5-c-label--PaddingLeft: var(--pf-v5-c-label--m-compact--PaddingLeft);
  --pf-v5-c-label--FontSize: var(--pf-v5-c-label--m-compact--FontSize);
  --pf-v5-c-label--m-editable--TextDecorationOffset: var(--pf-v5-c-label--m-compact--m-editable--TextDecorationOffset);
}

.pf-v5-c-label.pf-m-blue {
  --pf-v5-c-label--BackgroundColor: var(--pf-v5-c-label--m-blue--BackgroundColor);
  --pf-v5-c-label__icon--Color: var(--pf-v5-c-label--m-blue__icon--Color);
  --pf-v5-c-label__content--Color: var(--pf-v5-c-label--m-blue__content--Color);
  --pf-v5-c-label__content--before--BorderColor: var(--pf-v5-c-label--m-blue__content--before--BorderColor);
  --pf-v5-c-label__content--link--hover--before--BorderColor: var(--pf-v5-c-label--m-blue__content--link--hover--before--BorderColor);
  --pf-v5-c-label__content--link--focus--before--BorderColor: var(--pf-v5-c-label--m-blue__content--link--focus--before--BorderColor);
  --pf-v5-c-label--m-outline__content--Color: var(--pf-v5-c-label--m-outline--m-blue__content--Color);
  --pf-v5-c-label--m-outline__content--before--BorderColor: var(--pf-v5-c-label--m-outline--m-blue__content--before--BorderColor);
  --pf-v5-c-label--m-outline__content--link--hover--before--BorderColor: var(--pf-v5-c-label--m-outline--m-blue__content--link--hover--before--BorderColor);
  --pf-v5-c-label--m-outline__content--link--focus--before--BorderColor: var(--pf-v5-c-label--m-outline--m-blue__content--link--focus--before--BorderColor);
  --pf-v5-c-label--m-editable__content--before--BorderColor: var(--pf-v5-c-label--m-blue__content--before--BorderColor);
  --pf-v5-c-label--m-editable__content--hover--before--BorderColor: var(--pf-v5-c-label--m-blue__content--before--BorderColor);
  --pf-v5-c-label--m-editable__content--focus--before--BorderColor: var(--pf-v5-c-label--m-blue__content--before--BorderColor);
}

.pf-v5-c-label.pf-m-green {
  --pf-v5-c-label--BackgroundColor: var(--pf-v5-c-label--m-green--BackgroundColor);
  --pf-v5-c-label__icon--Color: var(--pf-v5-c-label--m-green__icon--Color);
  --pf-v5-c-label__content--Color: var(--pf-v5-c-label--m-green__content--Color);
  --pf-v5-c-label__content--before--BorderColor: var(--pf-v5-c-label--m-green__content--before--BorderColor);
  --pf-v5-c-label__content--link--hover--before--BorderColor: var(--pf-v5-c-label--m-green__content--link--hover--before--BorderColor);
  --pf-v5-c-label__content--link--focus--before--BorderColor: var(--pf-v5-c-label--m-green__content--link--focus--before--BorderColor);
  --pf-v5-c-label--m-outline__content--Color: var(--pf-v5-c-label--m-outline--m-green__content--Color);
  --pf-v5-c-label--m-outline__content--before--BorderColor: var(--pf-v5-c-label--m-outline--m-green__content--before--BorderColor);
  --pf-v5-c-label--m-outline__content--link--hover--before--BorderColor: var(--pf-v5-c-label--m-outline--m-green__content--link--hover--before--BorderColor);
  --pf-v5-c-label--m-outline__content--link--focus--before--BorderColor: var(--pf-v5-c-label--m-outline--m-green__content--link--focus--before--BorderColor);
  --pf-v5-c-label--m-editable__content--before--BorderColor: var(--pf-v5-c-label--m-green__content--before--BorderColor);
  --pf-v5-c-label--m-editable__content--hover--before--BorderColor: var(--pf-v5-c-label--m-green__content--before--BorderColor);
  --pf-v5-c-label--m-editable__content--focus--before--BorderColor: var(--pf-v5-c-label--m-green__content--before--BorderColor);
}

.pf-v5-c-label.pf-m-orange {
  --pf-v5-c-label--BackgroundColor: var(--pf-v5-c-label--m-orange--BackgroundColor);
  --pf-v5-c-label__icon--Color: var(--pf-v5-c-label--m-orange__icon--Color);
  --pf-v5-c-label__content--Color: var(--pf-v5-c-label--m-orange__content--Color);
  --pf-v5-c-label__content--before--BorderColor: var(--pf-v5-c-label--m-orange__content--before--BorderColor);
  --pf-v5-c-label__content--link--hover--before--BorderColor: var(--pf-v5-c-label--m-orange__content--link--hover--before--BorderColor);
  --pf-v5-c-label__content--link--focus--before--BorderColor: var(--pf-v5-c-label--m-orange__content--link--focus--before--BorderColor);
  --pf-v5-c-label--m-outline__content--Color: var(--pf-v5-c-label--m-outline--m-orange__content--Color);
  --pf-v5-c-label--m-outline__content--before--BorderColor: var(--pf-v5-c-label--m-outline--m-orange__content--before--BorderColor);
  --pf-v5-c-label--m-outline__content--link--hover--before--BorderColor: var(--pf-v5-c-label--m-outline--m-orange__content--link--hover--before--BorderColor);
  --pf-v5-c-label--m-outline__content--link--focus--before--BorderColor: var(--pf-v5-c-label--m-outline--m-orange__content--link--focus--before--BorderColor);
  --pf-v5-c-label--m-editable__content--before--BorderColor: var(--pf-v5-c-label--m-orange__content--before--BorderColor);
  --pf-v5-c-label--m-editable__content--hover--before--BorderColor: var(--pf-v5-c-label--m-orange__content--before--BorderColor);
  --pf-v5-c-label--m-editable__content--focus--before--BorderColor: var(--pf-v5-c-label--m-orange__content--before--BorderColor);
}

.pf-v5-c-label.pf-m-red {
  --pf-v5-c-label--BackgroundColor: var(--pf-v5-c-label--m-red--BackgroundColor);
  --pf-v5-c-label__icon--Color: var(--pf-v5-c-label--m-red__icon--Color);
  --pf-v5-c-label__content--Color: var(--pf-v5-c-label--m-red__content--Color);
  --pf-v5-c-label__content--before--BorderColor: var(--pf-v5-c-label--m-red__content--before--BorderColor);
  --pf-v5-c-label__content--link--hover--before--BorderColor: var(--pf-v5-c-label--m-red__content--link--hover--before--BorderColor);
  --pf-v5-c-label__content--link--focus--before--BorderColor: var(--pf-v5-c-label--m-red__content--link--focus--before--BorderColor);
  --pf-v5-c-label--m-outline__content--Color: var(--pf-v5-c-label--m-outline--m-red__content--Color);
  --pf-v5-c-label--m-outline__content--before--BorderColor: var(--pf-v5-c-label--m-outline--m-red__content--before--BorderColor);
  --pf-v5-c-label--m-outline__content--link--hover--before--BorderColor: var(--pf-v5-c-label--m-outline--m-red__content--link--hover--before--BorderColor);
  --pf-v5-c-label--m-outline__content--link--focus--before--BorderColor: var(--pf-v5-c-label--m-outline--m-red__content--link--focus--before--BorderColor);
  --pf-v5-c-label--m-editable__content--before--BorderColor: var(--pf-v5-c-label--m-red__content--before--BorderColor);
  --pf-v5-c-label--m-editable__content--hover--before--BorderColor: var(--pf-v5-c-label--m-red__content--before--BorderColor);
  --pf-v5-c-label--m-editable__content--focus--before--BorderColor: var(--pf-v5-c-label--m-red__content--before--BorderColor);
}

.pf-v5-c-label.pf-m-purple {
  --pf-v5-c-label--BackgroundColor: var(--pf-v5-c-label--m-purple--BackgroundColor);
  --pf-v5-c-label__icon--Color: var(--pf-v5-c-label--m-purple__icon--Color);
  --pf-v5-c-label__content--Color: var(--pf-v5-c-label--m-purple__content--Color);
  --pf-v5-c-label__content--before--BorderColor: var(--pf-v5-c-label--m-purple__content--before--BorderColor);
  --pf-v5-c-label__content--link--hover--before--BorderColor: var(--pf-v5-c-label--m-purple__content--link--hover--before--BorderColor);
  --pf-v5-c-label__content--link--focus--before--BorderColor: var(--pf-v5-c-label--m-purple__content--link--focus--before--BorderColor);
  --pf-v5-c-label--m-outline__content--Color: var(--pf-v5-c-label--m-outline--m-purple__content--Color);
  --pf-v5-c-label--m-outline__content--before--BorderColor: var(--pf-v5-c-label--m-outline--m-purple__content--before--BorderColor);
  --pf-v5-c-label--m-outline__content--link--hover--before--BorderColor: var(--pf-v5-c-label--m-outline--m-purple__content--link--hover--before--BorderColor);
  --pf-v5-c-label--m-outline__content--link--focus--before--BorderColor: var(--pf-v5-c-label--m-outline--m-purple__content--link--focus--before--BorderColor);
  --pf-v5-c-label--m-editable__content--before--BorderColor: var(--pf-v5-c-label--m-purple__content--before--BorderColor);
  --pf-v5-c-label--m-editable__content--hover--before--BorderColor: var(--pf-v5-c-label--m-purple__content--before--BorderColor);
  --pf-v5-c-label--m-editable__content--focus--before--BorderColor: var(--pf-v5-c-label--m-purple__content--before--BorderColor);
}

.pf-v5-c-label.pf-m-cyan {
  --pf-v5-c-label--BackgroundColor: var(--pf-v5-c-label--m-cyan--BackgroundColor);
  --pf-v5-c-label__icon--Color: var(--pf-v5-c-label--m-cyan__icon--Color);
  --pf-v5-c-label__content--Color: var(--pf-v5-c-label--m-cyan__content--Color);
  --pf-v5-c-label__content--before--BorderColor: var(--pf-v5-c-label--m-cyan__content--before--BorderColor);
  --pf-v5-c-label__content--link--hover--before--BorderColor: var(--pf-v5-c-label--m-cyan__content--link--hover--before--BorderColor);
  --pf-v5-c-label__content--link--focus--before--BorderColor: var(--pf-v5-c-label--m-cyan__content--link--focus--before--BorderColor);
  --pf-v5-c-label--m-outline__content--Color: var(--pf-v5-c-label--m-outline--m-cyan__content--Color);
  --pf-v5-c-label--m-outline__content--before--BorderColor: var(--pf-v5-c-label--m-outline--m-cyan__content--before--BorderColor);
  --pf-v5-c-label--m-outline__content--link--hover--before--BorderColor: var(--pf-v5-c-label--m-outline--m-cyan__content--link--hover--before--BorderColor);
  --pf-v5-c-label--m-outline__content--link--focus--before--BorderColor: var(--pf-v5-c-label--m-outline--m-cyan__content--link--focus--before--BorderColor);
  --pf-v5-c-label--m-editable__content--before--BorderColor: var(--pf-v5-c-label--m-cyan__content--before--BorderColor);
  --pf-v5-c-label--m-editable__content--hover--before--BorderColor: var(--pf-v5-c-label--m-cyan__content--before--BorderColor);
  --pf-v5-c-label--m-editable__content--focus--before--BorderColor: var(--pf-v5-c-label--m-cyan__content--before--BorderColor);
}

.pf-v5-c-label.pf-m-gold {
  --pf-v5-c-label--BackgroundColor: var(--pf-v5-c-label--m-gold--BackgroundColor);
  --pf-v5-c-label__icon--Color: var(--pf-v5-c-label--m-gold__icon--Color);
  --pf-v5-c-label__content--Color: var(--pf-v5-c-label--m-gold__content--Color);
  --pf-v5-c-label__content--before--BorderColor: var(--pf-v5-c-label--m-gold__content--before--BorderColor);
  --pf-v5-c-label__content--link--hover--before--BorderColor: var(--pf-v5-c-label--m-gold__content--link--hover--before--BorderColor);
  --pf-v5-c-label__content--link--focus--before--BorderColor: var(--pf-v5-c-label--m-gold__content--link--focus--before--BorderColor);
  --pf-v5-c-label--m-outline__content--Color: var(--pf-v5-c-label--m-outline--m-gold__content--Color);
  --pf-v5-c-label--m-outline__content--before--BorderColor: var(--pf-v5-c-label--m-outline--m-gold__content--before--BorderColor);
  --pf-v5-c-label--m-outline__content--link--hover--before--BorderColor: var(--pf-v5-c-label--m-outline--m-gold__content--link--hover--before--BorderColor);
  --pf-v5-c-label--m-outline__content--link--focus--before--BorderColor: var(--pf-v5-c-label--m-outline--m-gold__content--link--focus--before--BorderColor);
  --pf-v5-c-label--m-editable__content--before--BorderColor: var(--pf-v5-c-label--m-gold__content--before--BorderColor);
  --pf-v5-c-label--m-editable__content--hover--before--BorderColor: var(--pf-v5-c-label--m-gold__content--before--BorderColor);
  --pf-v5-c-label--m-editable__content--focus--before--BorderColor: var(--pf-v5-c-label--m-gold__content--before--BorderColor);
}

.pf-v5-c-label.pf-m-outline {
  --pf-v5-c-label__content--Color: var(--pf-v5-c-label--m-outline__content--Color);
  --pf-v5-c-label__content--before--BorderWidth: var(--pf-v5-c-label--m-outline__content--before--BorderWidth);
  --pf-v5-c-label__content--before--BorderColor: var(--pf-v5-c-label--m-outline__content--before--BorderColor);
  --pf-v5-c-label--BackgroundColor: var(--pf-v5-c-label--m-outline--BackgroundColor);
  --pf-v5-c-label--m-editable__content--before--BorderColor: var(--pf-v5-c-label--m-outline__content--before--BorderColor);
  --pf-v5-c-label--m-editable__content--hover--before--BorderColor: var(--pf-v5-c-label--m-outline__content--before--BorderColor);
  --pf-v5-c-label--m-editable__content--focus--before--BorderColor: var(--pf-v5-c-label--m-outline__content--before--BorderColor);
}

.pf-v5-c-label.pf-m-overflow:hover,
.pf-v5-c-label.pf-m-add:hover,
.pf-v5-c-label.pf-m-outline a.pf-v5-c-label__content:hover,
.pf-v5-c-label.pf-m-outline button.pf-v5-c-label__content:hover {
  --pf-v5-c-label__content--before--BorderWidth: var(--pf-v5-c-label--m-outline__content--link--hover--before--BorderWidth);
  --pf-v5-c-label__content--before--BorderColor: var(--pf-v5-c-label--m-outline__content--link--hover--before--BorderColor);
}

.pf-v5-c-label.pf-m-overflow:focus,
.pf-v5-c-label.pf-m-add:focus,
.pf-v5-c-label.pf-m-outline a.pf-v5-c-label__content:focus,
.pf-v5-c-label.pf-m-outline button.pf-v5-c-label__content:focus {
  --pf-v5-c-label__content--before--BorderWidth: var(--pf-v5-c-label--m-outline__content--link--focus--before--BorderWidth);
  --pf-v5-c-label__content--before--BorderColor: var(--pf-v5-c-label--m-outline__content--link--focus--before--BorderColor);
}

.pf-v5-c-label.pf-m-editable {
  --pf-v5-c-label__content--MaxWidth: var(--pf-v5-c-label--m-editable__content--MaxWidth);
  --pf-v5-c-label__content--before--BorderWidth: var(--pf-v5-c-label--m-editable__content--before--BorderWidth);
  --pf-v5-c-label__content--before--BorderColor: var(--pf-v5-c-label--m-editable__content--before--BorderColor);
  --pf-v5-c-label__content--link--hover--before--BorderWidth: var(--pf-v5-c-label--m-editable__content--hover--before--BorderWidth);
  --pf-v5-c-label__content--link--hover--before--BorderColor: var(--pf-v5-c-label--m-editable__content--hover--before--BorderColor);
  --pf-v5-c-label__content--link--focus--before--BorderWidth: var(--pf-v5-c-label--m-editable__content--focus--before--BorderWidth);
  --pf-v5-c-label__content--link--focus--before--BorderColor: var(--pf-v5-c-label--m-editable__content--focus--before--BorderColor);
  --pf-v5-c-label--m-outline__content--link--hover--before--BorderWidth: var(--pf-v5-c-label--m-editable__content--before--BorderWidth);
  --pf-v5-c-label--m-outline__content--link--focus--before--BorderWidth: var(--pf-v5-c-label--m-editable__content--before--BorderWidth);
  text-decoration: var(--pf-v5-c-label--m-editable--TextDecoration);
  text-decoration-thickness: var(--pf-v5-c-label--m-editable--TextDecorationThickness);
  text-decoration-style: var(--pf-v5-c-label--m-editable--TextDecorationStyle);
  text-decoration-color: var(--pf-v5-c-label--m-editable--TextDecorationColor);
  text-underline-offset: var(--pf-v5-c-label--m-editable--TextDecorationOffset);
  cursor: var(--pf-v5-c-label--m-editable--Cursor);
}

.pf-v5-c-label.pf-m-editable:hover {
  --pf-v5-c-label--m-editable--TextDecorationColor: var(--pf-v5-c-label--m-editable--hover--TextDecorationColor);
}

.pf-v5-c-label.pf-m-editable:focus {
  --pf-v5-c-label--m-editable--TextDecorationColor: var(--pf-v5-c-label--m-editable--focus--TextDecorationColor);
}

.pf-v5-c-label.pf-m-editable-active {
  --pf-v5-c-label--m-editable--Cursor: var(--pf-v5-c-label--m-editable--m-editable-active--Cursor);
  --pf-v5-c-label--m-editable--TextDecoration: var(--pf-v5-c-label--m-editable--m-editable-active--TextDecoration);
  --pf-v5-c-label--BackgroundColor: var(--pf-v5-c-label--m-editable--m-editable-active--BackgroundColor);
}

.pf-v5-c-label.pf-m-overflow,
.pf-v5-c-label.pf-m-add {
  --pf-v5-c-label__content--Color: var(--pf-v5-c-label--m-overflow__content--Color);
  --pf-v5-c-label--BackgroundColor: var(--pf-v5-c-label--m-overflow__content--BackgroundColor);
  --pf-v5-c-label__content--before--BorderWidth: var(--pf-v5-c-label--m-overflow__content--before--BorderWidth);
  --pf-v5-c-label__content--before--BorderColor: var(--pf-v5-c-label--m-overflow__content--before--BorderColor);
  --pf-v5-c-label__content--link--hover--before--BorderWidth: var(--pf-v5-c-label--m-overflow__content--link--hover--before--BorderWidth);
  --pf-v5-c-label__content--link--hover--before--BorderColor: var(--pf-v5-c-label--m-overflow__content--link--hover--before--BorderColor);
  --pf-v5-c-label__content--link--focus--before--BorderWidth: var(--pf-v5-c-label--m-overflow__content--link--focus--before--BorderWidth);
  --pf-v5-c-label__content--link--focus--before--BorderColor: var(--pf-v5-c-label--m-overflow__content--link--focus--before--BorderColor);
}

.pf-v5-c-label.pf-m-disabled {
  --pf-v5-c-label--BackgroundColor: var(--pf-v5-c-label--m-disabled--BackgroundColor);
  --pf-v5-c-label__content--Color: var(--pf-v5-c-label--m-disabled__content--Color);
  --pf-v5-c-label__icon--Color: var(--pf-v5-c-label--m-disabled__icon--Color);
  --pf-v5-c-label__content--before--BorderWidth: 0;
  pointer-events: none;
}

.pf-v5-c-label.pf-m-disabled .pf-v5-c-button {
  --pf-v5-c-button--m-plain--disabled--Color: var(--pf-v5-c-label--c-button--m-disabled--Color);
}

.pf-v5-c-label,
.pf-v5-c-label__content {
  display: inline-flex;
  align-items: center;
}

.pf-v5-c-label__text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: var(--pf-v5-c-label__text--MaxWidth);
}

.pf-v5-c-label__content {
  max-width: var(--pf-v5-c-label__content--MaxWidth);
  overflow: hidden;
  color: var(--pf-v5-c-label__content--Color);
  background-color: var(--pf-v5-c-label__content--BackgroundColor);
  border-width: 0;
}

.pf-v5-c-label__content::before {
  position: absolute;
  inset: 0;
  pointer-events: none;
  content: "";
  border: var(--pf-v5-c-label__content--before--BorderWidth) solid var(--pf-v5-c-label__content--before--BorderColor);
  border-radius: var(--pf-v5-c-label--BorderRadius);
}

input.pf-v5-c-label__content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

a.pf-v5-c-label__content,
button.pf-v5-c-label__content {
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

a.pf-v5-c-label__content,
a.pf-v5-c-label__content:hover,
a.pf-v5-c-label__content:focus,
button.pf-v5-c-label__content,
button.pf-v5-c-label__content:hover,
button.pf-v5-c-label__content:focus {
  text-decoration: none;
}

a.pf-v5-c-label__content:hover,
button.pf-v5-c-label__content:hover {
  --pf-v5-c-label__content--before--BorderWidth: var(--pf-v5-c-label__content--link--hover--before--BorderWidth);
  --pf-v5-c-label__content--before--BorderColor: var(--pf-v5-c-label__content--link--hover--before--BorderColor);
}

a.pf-v5-c-label__content:focus,
button.pf-v5-c-label__content:focus {
  --pf-v5-c-label__content--before--BorderWidth: var(--pf-v5-c-label__content--link--focus--before--BorderWidth);
  --pf-v5-c-label__content--before--BorderColor: var(--pf-v5-c-label__content--link--focus--before--BorderColor);
}

.pf-v5-c-label__icon {
  margin-inline-end: var(--pf-v5-c-label__icon--MarginRight);
  color: var(--pf-v5-c-label__icon--Color);
}

.pf-v5-c-label__actions {
  margin-inline-end: var(--pf-v5-c-label__actions--MarginRight);
  font-size: var(--pf-v5-c-label__actions--FontSize);
}

.pf-v5-c-label__actions .pf-v5-c-button {
  --pf-v5-c-button--FontSize: var(--pf-v5-c-label__actions--c-button--FontSize);
  --pf-v5-c-button--PaddingTop: var(--pf-v5-c-label__actions--c-button--PaddingTop);
  --pf-v5-c-button--PaddingRight: var(--pf-v5-c-label__actions--c-button--PaddingRight);
  --pf-v5-c-button--PaddingBottom: var(--pf-v5-c-label__actions--c-button--PaddingBottom);
  --pf-v5-c-button--PaddingLeft: var(--pf-v5-c-label__actions--c-button--PaddingLeft);
  margin-block-start: var(--pf-v5-c-label__actions--c-button--MarginTop);
  margin-block-end: var(--pf-v5-c-label__actions--c-button--MarginBottom);
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-label {
  --pf-v5-c-label--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-label--m-blue--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-label--m-green--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-label--m-orange--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-label--m-red--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-label--m-purple--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-label--m-cyan--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-label--m-gold--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-label__content--before--BorderWidth: 0;
  --pf-v5-c-label__content--before--BorderColor: transparent;
  --pf-v5-c-label__content--link--hover--before--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-label__content--link--focus--before--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-label--m-blue__content--Color: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-label--m-outline--m-blue__content--before--BorderColor: var(--pf-v5-global--active-color--200);
  --pf-v5-c-label--m-outline--m-blue__content--link--hover--before--BorderColor: var(--pf-v5-global--active-color--200);
  --pf-v5-c-label--m-outline--m-blue__content--link--focus--before--BorderColor: var(--pf-v5-global--active-color--200);
  --pf-v5-c-label--m-green__content--Color: var(--pf-v5-global--success-color--100);
  --pf-v5-c-label--m-outline--m-green__content--before--BorderColor: var(--pf-v5-global--palette--green-100);
  --pf-v5-c-label--m-outline--m-green__content--link--hover--before--BorderColor: var(--pf-v5-global--palette--green-100);
  --pf-v5-c-label--m-outline--m-green__content--link--focus--before--BorderColor: var(--pf-v5-global--palette--green-100);
  --pf-v5-c-label--m-orange__content--Color: var(--pf-v5-global--palette--orange-300);
  --pf-v5-c-label--m-outline--m-orange__content--Color: var(--pf-v5-global--palette--orange-300);
  --pf-v5-c-label--m-outline--m-orange__content--before--BorderColor: var(--pf-v5-global--palette--gold-100);
  --pf-v5-c-label--m-outline--m-orange__content--link--hover--before--BorderColor: var(--pf-v5-global--palette--gold-100);
  --pf-v5-c-label--m-outline--m-orange__content--link--focus--before--BorderColor: var(--pf-v5-global--palette--gold-100);
  --pf-v5-c-label--m-red__content--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-label--m-outline--m-red__content--before--BorderColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-label--m-outline--m-red__content--link--hover--before--BorderColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-label--m-outline--m-red__content--link--focus--before--BorderColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-label--m-purple__content--Color: var(--pf-v5-global--palette--purple-300);
  --pf-v5-c-label--m-purple__icon--Color: var(--pf-v5-global--palette--purple-300);
  --pf-v5-c-label--m-purple__content--link--hover--before--BorderColor: var(--pf-v5-global--palette--purple-300);
  --pf-v5-c-label--m-purple__content--link--focus--before--BorderColor: var(--pf-v5-global--palette--purple-300);
  --pf-v5-c-label--m-outline--m-purple__content--before--BorderColor: var(--pf-v5-global--palette--purple-100);
  --pf-v5-c-label--m-outline--m-purple__content--link--hover--before--BorderColor: var(--pf-v5-global--palette--purple-100);
  --pf-v5-c-label--m-outline--m-purple__content--link--focus--before--BorderColor: var(--pf-v5-global--palette--purple-100);
  --pf-v5-c-label--m-outline--m-purple__content--Color: var(--pf-v5-global--palette--purple-300);
  --pf-v5-c-label--m-cyan__content--Color: var(--pf-v5-global--custom-color--200);
  --pf-v5-c-label--m-outline--m-cyan__content--Color: var(--pf-v5-global--custom-color--200);
  --pf-v5-c-label--m-outline--m-cyan__content--before--BorderColor: var(--pf-v5-global--palette--cyan-100);
  --pf-v5-c-label--m-outline--m-cyan__content--link--hover--before--BorderColor: var(--pf-v5-global--palette--cyan-100);
  --pf-v5-c-label--m-outline--m-cyan__content--link--focus--before--BorderColor: var(--pf-v5-global--palette--cyan-100);
  --pf-v5-c-label--m-gold__content--Color: var(--pf-v5-global--palette--gold-300);
  --pf-v5-c-label--m-outline--m-gold__content--Color: var(--pf-v5-global--palette--gold-300);
  --pf-v5-c-label--m-outline--m-gold__content--before--BorderColor: var(--pf-v5-global--palette--gold-100);
  --pf-v5-c-label--m-outline--m-gold__content--link--hover--before--BorderColor: var(--pf-v5-global--palette--gold-100);
  --pf-v5-c-label--m-outline--m-gold__content--link--focus--before--BorderColor: var(--pf-v5-global--palette--gold-100);
}

.pf-v5-c-label-group {
  --pf-v5-c-label-group--RowGap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-label-group--ColumnGap: 0;
  --pf-v5-c-label-group--m-vertical--RowGap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-label-group--m-vertical--ColumnGap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-label-group__main--RowGap: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-label-group__main--ColumnGap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-label-group--m-vertical__main--RowGap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-label-group--m-vertical__main--ColumnGap: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-label-group__list--RowGap: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-label-group__list--ColumnGap: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-label-group--m-vertical__list--RowGap: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-label-group--m-vertical__list--ColumnGap: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-label-group--m-category--PaddingTop: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-label-group--m-category--PaddingRight: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-label-group--m-category--PaddingBottom: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-label-group--m-category--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-label-group--m-vertical--m-category--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-label-group--m-category--BorderRadius: var(--pf-v5-global--BorderRadius--sm);
  --pf-v5-c-label-group--m-category--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-label-group--m-category--BorderColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-label-group--m-category--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-label-group__label--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-label-group__label--MaxWidth: 18ch;
  --pf-v5-c-label-group__close--MarginTop: calc(var(--pf-v5-global--spacer--xs) * -1);
  --pf-v5-c-label-group__close--MarginBottom: calc(var(--pf-v5-global--spacer--xs) * -1);
  --pf-v5-c-label-group--m-vertical__close--MarginTop: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-label-group--m-vertical__close--MarginRight: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-label-group--m-vertical__close--c-button--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-label-group--m-vertical__close--c-button--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-label-group__textarea--MinWidth: 12.5rem;
  --pf-v5-c-label-group__textarea--PaddingTop: 0.125rem;
  --pf-v5-c-label-group__textarea--PaddingRight: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-label-group__textarea--PaddingBottom: 0;
  --pf-v5-c-label-group__textarea--PaddingLeft: var(--pf-v5-global--spacer--xs);
  display: inline-flex;
  row-gap: var(--pf-v5-c-label-group--RowGap);
  column-gap: var(--pf-v5-c-label-group--ColumnGap);
  align-items: center;
}

.pf-v5-c-label-group.pf-m-category {
  padding-block-start: var(--pf-v5-c-label-group--m-category--PaddingTop);
  padding-block-end: var(--pf-v5-c-label-group--m-category--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-label-group--m-category--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-label-group--m-category--PaddingRight);
  background-color: var(--pf-v5-c-label-group--m-category--BackgroundColor);
  border: var(--pf-v5-c-label-group--m-category--BorderWidth) solid var(--pf-v5-c-label-group--m-category--BorderColor);
  border-radius: var(--pf-v5-c-label-group--m-category--BorderRadius);
}

.pf-v5-c-label-group.pf-m-vertical {
  --pf-v5-c-label-group--RowGap: var(--pf-v5-c-label-group--m-vertical--RowGap);
  --pf-v5-c-label-group--ColumnGap: var(--pf-v5-c-label-group--m-vertical--ColumnGap);
  --pf-v5-c-label-group__main--RowGap: var(--pf-v5-c-label-group--m-vertical__main--RowGap);
  --pf-v5-c-label-group__main--ColumnGap: var(--pf-v5-c-label-group--m-vertical__main--ColumnGap);
  --pf-v5-c-label-group__list--RowGap: var(--pf-v5-c-label-group--m-vertical__list--RowGap);
  --pf-v5-c-label-group__list--ColumnGap: var(--pf-v5-c-label-group--m-vertical__list--ColumnGap);
  --pf-v5-c-label-group__close--MarginTop: var(--pf-v5-c-label-group--m-vertical__close--MarginTop);
  --pf-v5-c-label-group__close--MarginLeft: var(--pf-v5-c-label-group--m-vertical__close--MarginLeft);
  --pf-v5-c-label-group__close--MarginBottom: 0;
  --pf-v5-c-label-group__close--MarginRight: var(--pf-v5-c-label-group--m-vertical__close--MarginRight);
  --pf-v5-c-label-group--m-category--PaddingRight: var(--pf-v5-c-label-group--m-vertical--m-category--PaddingRight);
}

.pf-v5-c-label-group.pf-m-vertical.pf-v5-c-label-group {
  align-items: flex-start;
}

.pf-v5-c-label-group.pf-m-vertical .pf-v5-c-label-group__list {
  flex-direction: column;
  align-items: flex-start;
}

.pf-v5-c-label-group.pf-m-vertical .pf-v5-c-label-group__main {
  flex-direction: column;
}

.pf-v5-c-label-group.pf-m-vertical .pf-v5-c-label-group__close .pf-v5-c-button {
  --pf-v5-c-button--PaddingLeft: var(--pf-v5-c-label-group--m-vertical__close--c-button--PaddingLeft);
  --pf-v5-c-button--PaddingRight: var(--pf-v5-c-label-group--m-vertical__close--c-button--PaddingRight);
}

.pf-v5-c-label-group.pf-m-editable,
.pf-v5-c-label-group.pf-m-editable .pf-v5-c-label-group__main,
.pf-v5-c-label-group.pf-m-editable .pf-v5-c-label-group__list {
  display: flex;
}

.pf-v5-c-label-group.pf-m-editable .pf-v5-c-label-group__main,
.pf-v5-c-label-group.pf-m-editable .pf-v5-c-label-group__list,
.pf-v5-c-label-group.pf-m-editable .pf-v5-c-label-group__list-item.pf-m-textarea,
.pf-v5-c-label-group.pf-m-editable .pf-v5-c-label-group__textarea {
  flex: 1;
}

.pf-v5-c-label-group__main {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  row-gap: var(--pf-v5-c-label-group__main--RowGap);
  column-gap: var(--pf-v5-c-label-group__main--ColumnGap);
  align-items: baseline;
}

.pf-v5-c-label-group__list {
  display: inline-flex;
  flex-wrap: wrap;
  row-gap: var(--pf-v5-c-label-group__list--RowGap);
  column-gap: var(--pf-v5-c-label-group__list--ColumnGap);
}

.pf-v5-c-label-group__list-item {
  display: inline-flex;
}

.pf-v5-c-label-group__label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: var(--pf-v5-c-label-group__label--MaxWidth);
  font-size: var(--pf-v5-c-label-group__label--FontSize);
}

.pf-v5-c-label-group__close {
  display: flex;
  align-self: flex-start;
  margin-block-start: var(--pf-v5-c-label-group__close--MarginTop);
  margin-block-end: var(--pf-v5-c-label-group__close--MarginBottom);
  margin-inline-start: var(--pf-v5-c-label-group__close--MarginLeft);
  margin-inline-end: var(--pf-v5-c-label-group__close--MarginRight);
}

.pf-v5-c-label-group__textarea {
  min-width: var(--pf-v5-c-label-group__textarea--MinWidth);
  padding-block-start: var(--pf-v5-c-label-group__textarea--PaddingTop);
  padding-block-end: var(--pf-v5-c-label-group__textarea--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-label-group__textarea--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-label-group__textarea--PaddingRight);
  white-space: nowrap;
  resize: none;
  border: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.pf-v5-c-label-group__textarea::-webkit-scrollbar {
  display: none;
}

.pf-v5-c-list {
  --pf-v5-c-list--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-list--nested--MarginTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-list--nested--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-list--ul--ListStyle: var(--pf-v5-global--ListStyle);
  --pf-v5-c-list--li--MarginTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-list--m-inline--li--MarginRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-list--m-bordered--li--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-list--m-bordered--li--BorderBottomColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-list--m-bordered--li--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-list__item-icon--MinWidth: var(--pf-v5-global--icon--FontSize--sm);
  --pf-v5-c-list__item-icon--MarginTop: 0.375rem;
  --pf-v5-c-list__item-icon--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-list__item-icon--Color: var(--pf-v5-global--icon--Color--dark);
  --pf-v5-c-list__item-icon--FontSize: var(--pf-v5-global--icon--FontSize--sm);
  --pf-v5-c-list--m-icon-lg__item-icon--MinWidth: var(--pf-v5-global--icon--FontSize--lg);
  --pf-v5-c-list--m-icon-lg__item-icon--MarginRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-list--m-icon-lg__item-icon--FontSize: var(--pf-v5-global--icon--FontSize--lg);
  padding-inline-start: var(--pf-v5-c-list--PaddingLeft);
}

.pf-v5-c-list ol,
.pf-v5-c-list ul {
  margin-block-start: var(--pf-v5-c-list--nested--MarginTop);
  margin-inline-start: var(--pf-v5-c-list--nested--MarginLeft);
}

.pf-v5-c-list li+li {
  margin-block-start: var(--pf-v5-c-list--li--MarginTop);
}

ul.pf-v5-c-list {
  list-style: var(--pf-v5-c-list--ul--ListStyle);
}

.pf-v5-c-list .pf-v5-c-list__item {
  display: flex;
}

.pf-v5-c-list .pf-v5-c-list__item-icon {
  flex-shrink: 0;
  min-width: var(--pf-v5-c-list__item-icon--MinWidth);
  margin-block-start: var(--pf-v5-c-list__item-icon--MarginTop);
  margin-inline-end: var(--pf-v5-c-list__item-icon--MarginRight);
  font-size: var(--pf-v5-c-list__item-icon--FontSize);
  line-height: 1;
  color: var(--pf-v5-c-list__item-icon--Color);
}

.pf-v5-c-list.pf-m-icon-lg {
  --pf-v5-c-list__item-icon--MinWidth: var(--pf-v5-c-list--m-icon-lg__item-icon--MinWidth);
  --pf-v5-c-list__item-icon--MarginTop: 0;
  --pf-v5-c-list__item-icon--MarginRight: var(--pf-v5-c-list--m-icon-lg__item-icon--MarginRight);
  --pf-v5-c-list__item-icon--FontSize: var(--pf-v5-c-list--m-icon-lg__item-icon--FontSize);
}

.pf-v5-c-list.pf-m-plain {
  --pf-v5-c-list--PaddingLeft: 0;
  list-style: none;
}

.pf-v5-c-list.pf-m-inline {
  --pf-v5-c-list--PaddingLeft: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.pf-v5-c-list.pf-m-inline li {
  --pf-v5-c-list--li--MarginTop: 0;
}

.pf-v5-c-list.pf-m-inline li:not(:last-child) {
  margin-inline-end: var(--pf-v5-c-list--m-inline--li--MarginRight);
}

.pf-v5-c-list.pf-m-bordered>* {
  padding-block-end: var(--pf-v5-c-list--m-bordered--li--PaddingBottom);
  border-block-end: var(--pf-v5-c-list--m-bordered--li--BorderBottomWidth) solid var(--pf-v5-c-list--m-bordered--li--BorderBottomColor);
}

.pf-v5-c-list.pf-m-bordered>:last-child {
  --pf-v5-c-list--m-bordered--li--PaddingBottom: 0;
  --pf-v5-c-list--m-bordered--li--BorderBottomWidth: 0;
}

.pf-v5-c-log-viewer {
  --pf-v5-c-log-viewer--Height: 100%;
  --pf-v5-c-log-viewer--MaxHeight: auto;
  --pf-v5-c-log-viewer--m-line-numbers__index--Display: inline;
  --pf-v5-c-log-viewer__header--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-log-viewer__main--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-log-viewer__main--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-log-viewer__main--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-log-viewer__scroll-container--Height: 37.5rem;
  --pf-v5-c-log-viewer__scroll-container--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-log-viewer__scroll-container--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-log-viewer--m-line-numbers__main--before--Top: 0;
  --pf-v5-c-log-viewer--m-line-numbers__main--before--Bottom: 0;
  --pf-v5-c-log-viewer--m-line-numbers__main--before--Width: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-log-viewer--m-line-numbers__main--before--BackgroundColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-log-viewer__list--Height: auto;
  --pf-v5-c-log-viewer--m-line-numbers__list--Left: var(--pf-v5-c-log-viewer__index--Width);
  --pf-v5-c-log-viewer__list--FontFamily: var(--pf-v5-global--FontFamily--monospace);
  --pf-v5-c-log-viewer__list--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-log-viewer__index--Display: none;
  --pf-v5-c-log-viewer__index--Width: 4.0625rem;
  --pf-v5-c-log-viewer__index--PaddingRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-log-viewer__index--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-log-viewer__index--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-log-viewer__index--BackgroundColor: transparent;
  --pf-v5-c-log-viewer--line-number-chars: 4.4;
  --pf-v5-c-log-viewer--m-line-number-chars__index--PaddingRight: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-log-viewer--m-line-number-chars__index--Width: calc(1ch * var(--pf-v5-c-log-viewer--line-number-chars) + var(--pf-v5-c-log-viewer__index--PaddingRight) + var(--pf-v5-c-log-viewer__index--PaddingLeft));
  --pf-v5-c-log-viewer__text--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-log-viewer__text--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-log-viewer__text--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-log-viewer__text--WordBreak: break-all;
  --pf-v5-c-log-viewer__text--WhiteSpace: break-spaces;
  --pf-v5-c-log-viewer__text--LineBreak: anywhere;
  --pf-v5-c-log-viewer--m-nowrap__text--WhiteSpace: nowrap;
  --pf-v5-c-log-viewer__string--m-match--Color: var(--pf-v5-global--palette--gold-700);
  --pf-v5-c-log-viewer__string--m-match--BackgroundColor: var(--pf-v5-global--palette--gold-100);
  --pf-v5-c-log-viewer__string--m-current--Color: var(--pf-v5-global--palette--gold-700);
  --pf-v5-c-log-viewer__string--m-current--BackgroundColor: var(--pf-v5-global--palette--gold-400);
  --pf-v5-c-log-viewer__timestamp--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-log-viewer--c-toolbar--PaddingTop: 0;
  --pf-v5-c-log-viewer--c-toolbar--PaddingBottom: 0;
  --pf-v5-c-log-viewer--c-toolbar__content--PaddingRight: 0;
  --pf-v5-c-log-viewer--c-toolbar__content--PaddingLeft: 0;
  --pf-v5-c-log-viewer--c-toolbar__group--m-toggle-group--spacer: 0;
  --pf-v5-c-log-viewer--c-toolbar__group--m-toggle-group--m-show--spacer: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-log-viewer--m-dark__main--BorderWidth: 0;
  display: flex;
  flex-direction: column;
  height: var(--pf-v5-c-log-viewer--Height);
  max-height: var(--pf-v5-c-log-viewer--MaxHeight);
}

.pf-v5-c-log-viewer.pf-m-dark {
  --pf-v5-c-log-viewer__main--BorderWidth: var(--pf-v5-c-log-viewer--m-dark__main--BorderWidth);
}

.pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main {
  color: var(--pf-v5-global--Color--100);
  --pf-v5-c-log-viewer__main--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-log-viewer__main--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-log-viewer__text--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-log-viewer__index--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-log-viewer--m-line-numbers__main--before--BackgroundColor: var(--pf-v5-global--BorderColor--100);
}

.pf-v5-c-log-viewer.pf-m-wrap-text {
  word-break: break-all;
}

.pf-v5-c-log-viewer.pf-m-nowrap {
  --pf-v5-c-log-viewer__text--WhiteSpace: var(--pf-v5-c-log-viewer--m-nowrap__text--WhiteSpace);
}

.pf-v5-c-log-viewer.pf-m-line-numbers {
  --pf-v5-c-log-viewer__index--Display: var(--pf-v5-c-log-viewer--m-line-numbers__index--Display);
}

.pf-v5-c-log-viewer.pf-m-line-numbers .pf-v5-c-log-viewer__list {
  position: absolute;
  inset-inline-start: var(--pf-v5-c-log-viewer--m-line-numbers__list--Left);
  inset-inline-end: 0;
}

.pf-v5-c-log-viewer.pf-m-line-numbers .pf-v5-c-log-viewer__list::before {
  position: absolute;
  inset-block-start: var(--pf-v5-c-log-viewer--m-line-numbers__main--before--Top);
  inset-block-end: var(--pf-v5-c-log-viewer--m-line-numbers__main--before--Bottom);
  inset-inline-start: 0;
  width: var(--pf-v5-c-log-viewer--m-line-numbers__main--before--Width);
  content: "";
  background: var(--pf-v5-c-log-viewer--m-line-numbers__main--before--BackgroundColor);
}

.pf-v5-c-log-viewer.pf-m-line-number-chars {
  --pf-v5-c-log-viewer__index--PaddingRight: var(--pf-v5-c-log-viewer--m-line-number-chars__index--PaddingRight);
  --pf-v5-c-log-viewer__index--Width: var(--pf-v5-c-log-viewer--m-line-number-chars__index--Width);
}

.pf-v5-c-log-viewer .pf-v5-c-toolbar {
  --pf-v5-c-toolbar--PaddingTop: var(--pf-v5-c-log-viewer--c-toolbar--PaddingTop);
  --pf-v5-c-toolbar--PaddingBottom: var(--pf-v5-c-log-viewer--c-toolbar--PaddingBottom);
  --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-log-viewer--c-toolbar__content--PaddingRight);
  --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-log-viewer--c-toolbar__content--PaddingLeft);
  --pf-v5-c-toolbar__group--m-toggle-group--spacer: 0;
  --pf-v5-c-toolbar__group--m-toggle-group--m-show--spacer: var(--pf-v5-c-log-viewer--c-toolbar__group--m-toggle-group--m-show--spacer);
}

.pf-v5-c-log-viewer .pf-v5-c-toolbar__content-section {
  flex-wrap: nowrap;
}

.pf-v5-c-log-viewer__header {
  margin-block-end: var(--pf-v5-c-log-viewer__header--MarginBottom);
}

.pf-v5-c-log-viewer__main {
  display: flex;
  flex-direction: column;
  min-height: 0;
  background-color: var(--pf-v5-c-log-viewer__main--BackgroundColor);
  border: var(--pf-v5-c-log-viewer__main--BorderWidth) solid var(--pf-v5-c-log-viewer__main--BorderColor);
}

.pf-v5-c-log-viewer__scroll-container {
  position: relative;
  height: var(--pf-v5-c-log-viewer__scroll-container--Height);
  padding-block-start: var(--pf-v5-c-log-viewer__scroll-container--PaddingTop);
  padding-block-end: var(--pf-v5-c-log-viewer__scroll-container--PaddingBottom);
  overflow: auto;
  will-change: transform;
  direction: ltr;
}

.pf-v5-c-log-viewer__list {
  position: relative;
  height: var(--pf-v5-c-log-viewer__list--Height);
  font-family: var(--pf-v5-c-log-viewer__list--FontFamily);
  font-size: var(--pf-v5-c-log-viewer__list--FontSize);
}

.pf-v5-c-log-viewer__list-item {
  inset-inline-start: 0;
  width: 100%;
}

.pf-v5-c-log-viewer__string.pf-m-match {
  color: var(--pf-v5-c-log-viewer__string--m-match--Color, inherit);
  background-color: var(--pf-v5-c-log-viewer__string--m-match--BackgroundColor);
}

.pf-v5-c-log-viewer__string.pf-m-current {
  color: var(--pf-v5-c-log-viewer__string--m-current--Color, inherit);
  background-color: var(--pf-v5-c-log-viewer__string--m-current--BackgroundColor);
}

.pf-v5-c-log-viewer__index {
  position: fixed;
  inset-inline-start: 0;
  display: var(--pf-v5-c-log-viewer__index--Display);
  width: var(--pf-v5-c-log-viewer__index--Width);
  padding-inline-start: var(--pf-v5-c-log-viewer__index--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-log-viewer__index--PaddingRight);
  font-family: var(--pf-v5-c-log-viewer__index--FontFamily, inherit);
  font-size: var(--pf-v5-c-log-viewer__index--FontSize, inherit);
  color: var(--pf-v5-c-log-viewer__index--Color);
  user-select: none;
  background-color: var(--pf-v5-c-log-viewer__index--BackgroundColor);
}

.pf-v5-c-log-viewer__text {
  display: block;
  padding-inline-start: var(--pf-v5-c-log-viewer__text--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-log-viewer__text--PaddingRight);
  font-family: var(--pf-v5-c-log-viewer__text--FontFamily, inherit);
  font-size: var(--pf-v5-c-log-viewer__text--FontSize, inherit);
  color: var(--pf-v5-c-log-viewer__text--Color);
  word-break: var(--pf-v5-c-log-viewer__text--WordBreak);
  white-space: var(--pf-v5-c-log-viewer__text--WhiteSpace);
  line-break: var(--pf-v5-c-log-viewer__text--LineBreak);
}

.pf-v5-c-log-viewer__timestamp {
  font-weight: var(--pf-v5-c-log-viewer__timestamp--FontWeight);
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main {
  color: var(--pf-v5-global--Color--100);
}

.pf-v5-c-login {
  --pf-v5-c-login--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-login--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-login__container--xl--GridColumnGap: var(--pf-v5-global--spacer--3xl);
  --pf-v5-c-login__container--MaxWidth: 31.25rem;
  --pf-v5-c-login__container--xl--MaxWidth: none;
  --pf-v5-c-login__container--PaddingLeft: 6.125rem;
  --pf-v5-c-login__container--PaddingRight: 6.125rem;
  --pf-v5-c-login__container--xl--GridTemplateColumns: 34rem minmax(auto, 34rem);
  --pf-v5-c-login__header--MarginBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-login__header--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-login__header--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-login__header--xl--MarginBottom: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-login__header--xl--MarginTop: var(--pf-v5-global--spacer--3xl);
  --pf-v5-c-login__header--c-brand--MarginBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-login__header--c-brand--xl--MarginBottom: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-login__main--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-c-login__main--MarginBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-login__main--BoxShadow: var(--pf-v5-global--BoxShadow--xl);
  --pf-v5-c-login__main-header--PaddingTop: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-login__main-header--PaddingRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-login__main-header--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-login__main-header--PaddingLeft: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-login__main-header--md--PaddingRight: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-login__main-header--md--PaddingLeft: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-login__main-header--ColumnGap: var(--pf-v5-global--spacer--md);
  --pf-v5-c-login__main-header--RowGap: var(--pf-v5-global--spacer--md);
  --pf-v5-c-login__main-header-desc--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-login__main-header-desc--md--MarginBottom: 0;
  --pf-v5-c-login__main-header-desc--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-login__main-body--PaddingRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-login__main-body--PaddingBottom: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-login__main-body--PaddingLeft: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-login__main-body--md--PaddingRight: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-login__main-body--md--PaddingLeft: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-login__main-footer--PaddingBottom: var(--pf-v5-global--spacer--3xl);
  --pf-v5-c-login__main-footer--c-title--MarginBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-login__main-footer-links--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-login__main-footer-links--PaddingRight: var(--pf-v5-global--spacer--3xl);
  --pf-v5-c-login__main-footer-links--PaddingBottom: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-login__main-footer-links--PaddingLeft: var(--pf-v5-global--spacer--3xl);
  --pf-v5-c-login__main-footer-links-item--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-login__main-footer-links-item--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-login__main-footer-links-item--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-login__main-footer-links-item-link-svg--Fill: var(--pf-v5-global--icon--Color--light);
  --pf-v5-c-login__main-footer-links-item-link-svg--Width: var(--pf-v5-global--icon--FontSize--lg);
  --pf-v5-c-login__main-footer-links-item-link-svg--Height: var(--pf-v5-global--icon--FontSize--lg);
  --pf-v5-c-login__main-footer-links-item-link-svg--hover--Fill: var(--pf-v5-global--icon--Color--dark);
  --pf-v5-c-login__main-footer-band--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-login__main-footer-band--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-login__main-footer-band--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-login__main-footer-band--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-login__main-footer-band--BorderTopColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-login__main-footer-band--BorderTopWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-login__main-footer-band-item--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-login__footer--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-login__footer--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-login__footer--c-list--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-login__footer--c-list--xl--PaddingTop: var(--pf-v5-global--spacer--2xl);
  display: flex;
  justify-content: center;
  min-height: 100vh;
  padding-block-start: var(--pf-v5-c-login--PaddingTop);
  padding-block-end: var(--pf-v5-c-login--PaddingBottom);
}

@media (min-width: 1200px) {
  .pf-v5-c-login {
    --pf-v5-c-login__container--MaxWidth: var(--pf-v5-c-login__container--xl--MaxWidth);
  }
}

@media (min-width: 576px) {
  .pf-v5-c-login {
    --pf-v5-c-login__header--PaddingRight: 0;
    --pf-v5-c-login__header--PaddingLeft: 0;
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-login {
    --pf-v5-c-login__header--MarginBottom: var(--pf-v5-c-login__header--xl--MarginBottom);
    --pf-v5-c-login__header--c-brand--MarginBottom: var(--pf-v5-c-login__header--c-brand--xl--MarginBottom);
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-login {
    --pf-v5-c-login__main--MarginBottom: 0;
  }
}

@media (min-width: 768px) {
  .pf-v5-c-login {
    --pf-v5-c-login__main-header--PaddingRight: var(--pf-v5-c-login__main-header--md--PaddingRight);
    --pf-v5-c-login__main-header--PaddingLeft: var(--pf-v5-c-login__main-header--md--PaddingLeft);
    --pf-v5-c-login__main-header-desc--MarginBottom: var(--pf-v5-c-login__main-header-desc--md--MarginBottom);
  }
}

@media (min-width: 768px) {
  .pf-v5-c-login {
    --pf-v5-c-login__main-body--PaddingRight: var(--pf-v5-c-login__main-body--md--PaddingRight);
    --pf-v5-c-login__main-body--PaddingLeft: var(--pf-v5-c-login__main-body--md--PaddingLeft);
  }
}

@media (min-width: 576px) {
  .pf-v5-c-login {
    --pf-v5-c-login__footer--PaddingRight: 0;
    --pf-v5-c-login__footer--PaddingLeft: 0;
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-login {
    --pf-v5-c-login__footer--c-list--PaddingTop: var(--pf-v5-c-login__footer--c-list--xl--PaddingTop);
  }
}

@media (min-width: 576px) {
  .pf-v5-c-login {
    align-items: center;
  }
}

.pf-v5-c-login__container {
  width: 100%;
  max-width: var(--pf-v5-c-login__container--MaxWidth);
}

@media (min-width: 1200px) {
  .pf-v5-c-login__container {
    display: grid;
    grid-template-areas: "main header" "main footer" "main .";
    grid-template-columns: var(--pf-v5-c-login__container--xl--GridTemplateColumns);
    grid-column-gap: var(--pf-v5-c-login__container--xl--GridColumnGap);
    justify-content: center;
    padding-inline-start: var(--pf-v5-c-login__container--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-login__container--PaddingRight);
  }
}

.pf-v5-c-login__header {
  color: var(--pf-v5-global--Color--100);
  grid-area: header;
  padding-inline-start: var(--pf-v5-c-login__header--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-login__header--PaddingRight);
}

@media (min-width: 1200px) {
  .pf-v5-c-login__header {
    margin-block-start: var(--pf-v5-c-login__header--xl--MarginTop);
  }
}

.pf-v5-c-login__header .pf-v5-c-brand {
  margin-block-end: var(--pf-v5-c-login__header--c-brand--MarginBottom);
}

.pf-v5-c-login__main {
  grid-area: main;
  align-self: start;
  margin-block-end: var(--pf-v5-c-login__main--MarginBottom);
  background-color: var(--pf-v5-c-login__main--BackgroundColor);
  box-shadow: var(--pf-v5-c-login__main--BoxShadow);
}

.pf-v5-c-login__main>:first-child:not(.pf-v5-c-login__main-header) {
  padding-block-start: var(--pf-v5-c-login__main-header--PaddingTop);
}

.pf-v5-c-login__main>:last-child:not(.pf-v5-c-login__main-footer) {
  padding-block-end: var(--pf-v5-c-login__main-footer--PaddingBottom);
}

.pf-v5-c-login__main-header {
  display: grid;
  grid-template-columns: 100%;
  row-gap: var(--pf-v5-c-login__main-header--RowGap);
  column-gap: var(--pf-v5-c-login__main-header--ColumnGap);
  align-items: center;
  padding-block-start: var(--pf-v5-c-login__main-header--PaddingTop);
  padding-block-end: var(--pf-v5-c-login__main-header--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-login__main-header--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-login__main-header--PaddingRight);
}

@media (min-width: 768px) {
  .pf-v5-c-login__main-header {
    grid-template-columns: 1fr auto;
  }
}

.pf-v5-c-login__main-header-utilities,
.pf-v5-c-login__main-header .pf-v5-c-dropdown {
  grid-row: auto;
  grid-column: auto;
}

@media (min-width: 768px) {
  .pf-v5-c-login__main-header-utilities,
  .pf-v5-c-login__main-header .pf-v5-c-dropdown {
    grid-row: 1;
    grid-column: 2/3;
  }
}

.pf-v5-c-login__main-header-utilities .pf-v5-c-dropdown {
  grid-row: auto;
  grid-column: auto;
}

.pf-v5-c-login__main-header-desc {
  grid-column: 1/-1;
  margin-block-end: var(--pf-v5-c-login__main-header-desc--MarginBottom);
  font-size: var(--pf-v5-c-login__main-header-desc--FontSize);
}

.pf-v5-c-login__main-body {
  padding-block-end: var(--pf-v5-c-login__main-body--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-login__main-body--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-login__main-body--PaddingRight);
}

.pf-v5-c-login__main-footer {
  display: flex;
  flex-wrap: wrap;
}

.pf-v5-c-login__main-footer .pf-v5-c-title {
  margin-block-end: var(--pf-v5-c-login__main-footer--c-title--MarginBottom);
  text-align: center;
}

.pf-v5-c-login__main-footer>* {
  flex-basis: 100%;
}

.pf-v5-c-login__main-footer-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-block-start: var(--pf-v5-c-login__main-footer-links--PaddingTop);
  padding-block-end: var(--pf-v5-c-login__main-footer-links--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-login__main-footer-links--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-login__main-footer-links--PaddingRight);
}

.pf-v5-c-login__main-footer-links-item {
  padding-inline-start: var(--pf-v5-c-login__main-footer-links-item--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-login__main-footer-links-item--PaddingRight);
  margin-block-end: var(--pf-v5-c-login__main-footer-links-item--MarginBottom);
}

.pf-v5-c-login__main-footer-links-item-link svg {
  width: 100%;
  max-width: var(--pf-v5-c-login__main-footer-links-item-link-svg--Width);
  height: 100%;
  max-height: var(--pf-v5-c-login__main-footer-links-item-link-svg--Height);
  fill: var(--pf-v5-c-login__main-footer-links-item-link-svg--Fill);
}

.pf-v5-c-login__main-footer-links-item-link:hover svg {
  fill: var(--pf-v5-c-login__main-footer-links-item-link-svg--hover--Fill);
}

.pf-v5-c-login__main-footer-band {
  padding-block-start: var(--pf-v5-c-login__main-footer-band--PaddingTop);
  padding-block-end: var(--pf-v5-c-login__main-footer-band--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-login__main-footer-band--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-login__main-footer-band--PaddingRight);
  text-align: center;
  border-block-start: var(--pf-v5-c-login__main-footer-band--BorderTopWidth) solid var(--pf-v5-c-login__main-footer-band--BorderTopColor);
}

.pf-v5-c-login__main-footer-band>*+* {
  padding-block-start: var(--pf-v5-c-login__main-footer-band-item--PaddingTop);
}

.pf-v5-c-login__footer {
  color: var(--pf-v5-global--Color--100);
  grid-area: footer;
  padding-inline-start: var(--pf-v5-c-login__footer--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-login__footer--PaddingRight);
}

.pf-v5-c-login__footer .pf-v5-c-list a {
  color: unset;
}

.pf-v5-c-login__footer .pf-v5-c-list:not(:only-child) {
  padding-block-start: var(--pf-v5-c-login__footer--c-list--PaddingTop);
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-login {
  --pf-v5-c-login__main--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-login__header,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer {
  color: var(--pf-v5-global--Color--100);
}

.pf-v5-c-masthead {
  --pf-v5-c-masthead--PaddingLeft: var(--pf-v5-c-masthead--inset);
  --pf-v5-c-masthead--PaddingRight: var(--pf-v5-c-masthead--inset);
  --pf-v5-c-masthead--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-100);
  --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--md);
  --pf-v5-c-masthead--xl--inset: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-masthead--m-display-stack--GridTemplateColumns: max-content 1fr;
  --pf-v5-c-masthead--m-display-stack__main--GridColumn: -1 / 1;
  --pf-v5-c-masthead--m-display-stack__main--MinHeight: 4.375rem;
  --pf-v5-c-masthead--m-display-stack__main--Order: -1;
  --pf-v5-c-masthead--m-display-stack__main--FlexBasis: 100%;
  --pf-v5-c-masthead--m-display-stack__main--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-masthead--m-display-stack__main--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-masthead--m-display-stack__main--MarginRight: 0;
  --pf-v5-c-masthead--m-display-stack__main--before--BorderBottom: var(--pf-v5-c-masthead__main--before--BorderBottomWidth) solid var(--pf-v5-c-masthead__main--before--BorderBottomColor);
  --pf-v5-c-masthead--m-display-stack__toggle--GridColumn: 1;
  --pf-v5-c-masthead--m-display-stack__content--GridColumn: 2;
  --pf-v5-c-masthead--m-display-stack__content--MinHeight: auto;
  --pf-v5-c-masthead--m-display-stack__content--Order: 1;
  --pf-v5-c-masthead--m-display-stack__content--PaddingTop: 0;
  --pf-v5-c-masthead--m-display-stack__content--PaddingBottom: 0;
  --pf-v5-c-masthead--m-display-stack__content--MarginLeft: 0;
  --pf-v5-c-masthead--m-display-stack__content--c-nav--m-horizontal--MarginRight: calc(var(--pf-v5-c-masthead--inset) * -1);
  --pf-v5-c-masthead--m-display-stack__content--c-nav--m-horizontal--MarginLeft: calc(var(--pf-v5-c-masthead--inset) * -1);
  --pf-v5-c-masthead--m-display-inline--GridTemplateColumns: max-content max-content 1fr;
  --pf-v5-c-masthead--m-display-inline__main--GridColumn: 2;
  --pf-v5-c-masthead--m-display-inline__main--MinHeight: 4.375rem;
  --pf-v5-c-masthead--m-display-inline__main--Order: 0;
  --pf-v5-c-masthead--m-display-inline__main--FlexBasis: auto;
  --pf-v5-c-masthead--m-display-inline__main--PaddingTop: 0;
  --pf-v5-c-masthead--m-display-inline__main--PaddingBottom: 0;
  --pf-v5-c-masthead--m-display-inline__main--MarginRight: calc(var(--pf-v5-global--spacer--lg) / 2);
  --pf-v5-c-masthead--m-display-inline__main--BorderBottom: 0;
  --pf-v5-c-masthead--m-display-inline__toggle--GridColumn: 1;
  --pf-v5-c-masthead--m-display-inline__content--GridColumn: 3;
  --pf-v5-c-masthead--m-display-inline__content--MinHeight: 4.375rem;
  --pf-v5-c-masthead--m-display-inline__content--Order: 0;
  --pf-v5-c-masthead--m-display-inline__content--PaddingTop: 0;
  --pf-v5-c-masthead--m-display-inline__content--PaddingBottom: 0;
  --pf-v5-c-masthead--m-display-inline__content--MarginLeft: calc(var(--pf-v5-global--spacer--lg) / 2);
  --pf-v5-c-masthead--m-display-inline__content--c-nav--m-horizontal--MarginRight: 0;
  --pf-v5-c-masthead--m-display-inline__content--c-nav--m-horizontal--MarginLeft: 0;
  --pf-v5-c-masthead__main--before--Right: calc(var(--pf-v5-c-masthead--inset) * -1);
  --pf-v5-c-masthead__main--before--Left: calc(var(--pf-v5-c-masthead--inset) * -1);
  --pf-v5-c-masthead__main--before--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-masthead__main--before--BorderBottomColor: var(--pf-v5-global--palette--black-600);
  --pf-v5-c-masthead__toggle--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-masthead__toggle--MarginLeft: calc(var(--pf-v5-global--spacer--md) * -1);
  --pf-v5-c-masthead__toggle--c-button--FontSize: var(--pf-v5-global--FontSize--2xl);
  --pf-v5-c-masthead--m-light--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-masthead--m-light__main--BorderBottomColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-masthead--m-light-200--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-masthead--m-light-200__main--BorderBottomColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-masthead--c-toolbar__item--Display: flex;
  --pf-v5-c-masthead--item-border-color--base: var(--pf-v5-global--palette--black-800);
  --pf-v5-c-masthead--c-context-selector--Width: auto;
  --pf-v5-c-masthead--c-context-selector__toggle--BorderTopColor: var(--pf-v5-c-masthead--item-border-color--base);
  --pf-v5-c-masthead--c-context-selector__toggle--BorderRightColor: var(--pf-v5-c-masthead--item-border-color--base);
  --pf-v5-c-masthead--c-context-selector__toggle--BorderLeftColor: var(--pf-v5-c-masthead--item-border-color--base);
  --pf-v5-c-masthead--c-context-selector--m-full-height__toggle--BorderTopColor: transparent;
  --pf-v5-c-masthead--c-context-selector--m-full-height__toggle--BorderBottomColor: transparent;
  --pf-v5-c-masthead--c-dropdown__toggle--before--BorderTopColor: var(--pf-v5-c-masthead--item-border-color--base);
  --pf-v5-c-masthead--c-dropdown__toggle--before--BorderRightColor: var(--pf-v5-c-masthead--item-border-color--base);
  --pf-v5-c-masthead--c-dropdown__toggle--before--BorderLeftColor: var(--pf-v5-c-masthead--item-border-color--base);
  --pf-v5-c-masthead--c-dropdown--m-full-height__toggle--before--BorderTopColor: transparent;
  --pf-v5-c-masthead--c-dropdown--m-full-height__toggle--before--BorderBottomColor: transparent;
  --pf-v5-c-masthead--c-menu-toggle--before--BorderTopColor: var(--pf-v5-c-masthead--item-border-color--base);
  --pf-v5-c-masthead--c-menu-toggle--before--BorderRightColor: var(--pf-v5-c-masthead--item-border-color--base);
  --pf-v5-c-masthead--c-menu-toggle--before--BorderLeftColor: var(--pf-v5-c-masthead--item-border-color--base);
  --pf-v5-c-masthead--c-toolbar--BackgroundColor: var(--pf-v5-c-masthead--BackgroundColor);
  --pf-v5-c-masthead--c-toolbar--AlignItems--base: center;
  --pf-v5-c-masthead--c-toolbar__content--PaddingRight: 0;
  --pf-v5-c-masthead--c-toolbar__content--PaddingLeft: 0;
  --pf-v5-c-masthead--c-toolbar__expandable-content--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-masthead--c-toolbar__expandable-content--PaddingRight: var(--pf-v5-c-masthead--inset);
  --pf-v5-c-masthead--c-toolbar__expandable-content--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-masthead--c-toolbar__expandable-content--PaddingLeft: var(--pf-v5-c-masthead--inset);
  --pf-v5-c-masthead--c-toolbar__expandable-content--before--BorderTopWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-masthead--c-toolbar__expandable-content--before--BorderTopColor: var(--pf-v5-c-masthead--item-border-color--base);
  --pf-v5-c-masthead--GridTemplateColumns: var(--pf-v5-c-masthead--m-display-stack--GridTemplateColumns);
  --pf-v5-c-masthead__main--GridColumn: var(--pf-v5-c-masthead--m-display-stack__main--GridColumn);
  --pf-v5-c-masthead__main--MinHeight: var(--pf-v5-c-masthead--m-display-stack__main--MinHeight);
  --pf-v5-c-masthead__main--Order: var(--pf-v5-c-masthead--m-display-stack__main--Order);
  --pf-v5-c-masthead__main--FlexBasis: var(--pf-v5-c-masthead--m-display-stack__main--FlexBasis);
  --pf-v5-c-masthead__main--PaddingTop: var(--pf-v5-c-masthead--m-display-stack__main--PaddingTop);
  --pf-v5-c-masthead__main--PaddingBottom: var(--pf-v5-c-masthead--m-display-stack__main--PaddingBottom);
  --pf-v5-c-masthead__main--MarginRight: var(--pf-v5-c-masthead--m-display-stack__main--MarginRight);
  --pf-v5-c-masthead__main--before--BorderBottom: var(--pf-v5-c-masthead--m-display-stack__main--before--BorderBottom);
  --pf-v5-c-masthead__content--GridColumn: var(--pf-v5-c-masthead--m-display-stack__content--GridColumn);
  --pf-v5-c-masthead__content--MinHeight: var(--pf-v5-c-masthead--m-display-stack__content--MinHeight);
  --pf-v5-c-masthead__content--Order: var(--pf-v5-c-masthead--m-display-stack__content--Order);
  --pf-v5-c-masthead__content--PaddingTop: var(--pf-v5-c-masthead--m-display-stack__content--PaddingTop);
  --pf-v5-c-masthead__content--PaddingBottom: var(--pf-v5-c-masthead--m-display-stack__content--PaddingBottom);
  --pf-v5-c-masthead__content--MarginLeft: var(--pf-v5-c-masthead--m-display-stack__content--MarginLeft);
  --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginRight: var(--pf-v5-c-masthead--m-display-stack__content--c-nav--m-horizontal--MarginRight);
  --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginLeft: var(--pf-v5-c-masthead--m-display-stack__content--c-nav--m-horizontal--MarginLeft);
  color: var(--pf-v5-global--Color--100);
  position: relative;
  display: grid;
  grid-template-columns: var(--pf-v5-c-masthead--GridTemplateColumns);
  align-items: center;
  min-width: 0;
  padding-inline-start: var(--pf-v5-c-masthead--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-masthead--PaddingRight);
  background-color: var(--pf-v5-c-masthead--BackgroundColor);
}

@media screen and (min-width: 768px) {
  .pf-v5-c-page:where(:not(.pf-m-resize-observer)) .pf-v5-c-masthead {
    --pf-v5-c-masthead--GridTemplateColumns: var(--pf-v5-c-masthead--m-display-inline--GridTemplateColumns);
    --pf-v5-c-masthead__main--GridColumn: var(--pf-v5-c-masthead--m-display-inline__main--GridColumn);
    --pf-v5-c-masthead__main--MinHeight: var(--pf-v5-c-masthead--m-display-inline__main--MinHeight);
    --pf-v5-c-masthead__main--Order: var(--pf-v5-c-masthead--m-display-inline__main--Order);
    --pf-v5-c-masthead__main--FlexBasis: var(--pf-v5-c-masthead--m-display-inline__main--FlexBasis);
    --pf-v5-c-masthead__main--PaddingTop: var(--pf-v5-c-masthead--m-display-inline__main--PaddingTop);
    --pf-v5-c-masthead__main--PaddingBottom: var(--pf-v5-c-masthead--m-display-inline__main--PaddingBottom);
    --pf-v5-c-masthead__main--MarginRight: var(--pf-v5-c-masthead--m-display-inline__main--MarginRight);
    --pf-v5-c-masthead__main--before--BorderBottom: var(--pf-v5-c-masthead--m-display-inline__main--BorderBottom);
    --pf-v5-c-masthead__content--GridColumn: var(--pf-v5-c-masthead--m-display-inline__content--GridColumn);
    --pf-v5-c-masthead__content--MinHeight: var(--pf-v5-c-masthead--m-display-inline__content--MinHeight);
    --pf-v5-c-masthead__content--Order: var(--pf-v5-c-masthead--m-display-inline__content--Order);
    --pf-v5-c-masthead__content--PaddingTop: var(--pf-v5-c-masthead--m-display-inline__content--PaddingTop);
    --pf-v5-c-masthead__content--PaddingBottom: var(--pf-v5-c-masthead--m-display-inline__content--PaddingBottom);
    --pf-v5-c-masthead__content--MarginLeft: var(--pf-v5-c-masthead--m-display-inline__content--MarginLeft);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginRight: var(--pf-v5-c-masthead--m-display-inline__content--c-nav--m-horizontal--MarginRight);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginLeft: var(--pf-v5-c-masthead--m-display-inline__content--c-nav--m-horizontal--MarginLeft);
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-c-masthead {
    --pf-v5-c-masthead--inset: var(--pf-v5-c-masthead--xl--inset);
  }
}

.pf-v5-c-masthead.pf-m-light {
  color: var(--pf-v5-global--Color--100);
  --pf-v5-c-masthead--BackgroundColor: var(--pf-v5-c-masthead--m-light--BackgroundColor);
  --pf-v5-c-masthead__main--BorderBottomColor: var(--pf-v5-c-masthead--m-light__main--BorderBottomColor);
}

.pf-v5-c-masthead.pf-m-light-200 {
  color: var(--pf-v5-global--Color--100);
  --pf-v5-c-masthead--BackgroundColor: var(--pf-v5-c-masthead--m-light-200--BackgroundColor);
  --pf-v5-c-masthead__main--BorderBottomColor: var(--pf-v5-c-masthead--m-light-200__main--BorderBottomColor);
}

.pf-v5-c-masthead .pf-v5-c-toolbar {
  --pf-v5-c-toolbar--BackgroundColor: var(--pf-v5-c-masthead--c-toolbar--BackgroundColor);
  --pf-v5-c-toolbar--AlignItems--base: var(--pf-v5-c-masthead--c-toolbar--AlignItems--base);
  --pf-v5-c-toolbar__content--PaddingRight: var(--pf-v5-c-masthead--c-toolbar__content--PaddingRight);
  --pf-v5-c-toolbar__content--PaddingLeft: var(--pf-v5-c-masthead--c-toolbar__content--PaddingLeft);
  --pf-v5-c-toolbar__expandable-content--PaddingTop: var(--pf-v5-c-masthead--c-toolbar__expandable-content--PaddingTop);
  --pf-v5-c-toolbar__expandable-content--PaddingRight: var(--pf-v5-c-masthead--c-toolbar__expandable-content--PaddingRight);
  --pf-v5-c-toolbar__expandable-content--PaddingBottom: var(--pf-v5-c-masthead--c-toolbar__expandable-content--PaddingBottom);
  --pf-v5-c-toolbar__expandable-content--PaddingLeft: var(--pf-v5-c-masthead--c-toolbar__expandable-content--PaddingLeft);
  width: 100%;
}

.pf-v5-c-masthead .pf-v5-c-toolbar__content-section {
  flex-wrap: nowrap;
}

.pf-v5-c-masthead .pf-v5-c-toolbar__expandable-content {
  inset-block-start: 100%;
}

.pf-v5-c-masthead .pf-v5-c-toolbar__expandable-content::before {
  position: absolute;
  inset: 0;
  content: "";
  border-block-start: var(--pf-v5-c-masthead--c-toolbar__expandable-content--before--BorderTopWidth) solid var(--pf-v5-c-masthead--c-toolbar__expandable-content--before--BorderTopColor);
  box-shadow: var(--pf-v5-c-toolbar__expandable-content--before--BoxShadow);
}

.pf-v5-c-masthead .pf-v5-c-menu-toggle {
  --pf-v5-c-menu-toggle--before--BorderTopColor: var(--pf-v5-c-masthead--c-menu-toggle--before--BorderTopColor);
  --pf-v5-c-menu-toggle--before--BorderRightColor: var(--pf-v5-c-masthead--c-menu-toggle--before--BorderRightColor);
  --pf-v5-c-menu-toggle--before--BorderLeftColor: var(--pf-v5-c-masthead--c-menu-toggle--before--BorderLeftColor);
  --pf-v5-c-menu-toggle--m-full-height__toggle--after--BorderBottomWidth: 0;
}

.pf-v5-c-masthead .pf-v5-c-context-selector {
  --pf-v5-c-context-selector--Width: var(--pf-v5-c-masthead--c-context-selector--Width);
  --pf-v5-c-context-selector__toggle--BorderTopColor: var(--pf-v5-c-masthead--c-context-selector__toggle--BorderTopColor);
  --pf-v5-c-context-selector__toggle--BorderRightColor: var(--pf-v5-c-masthead--c-context-selector__toggle--BorderRightColor);
  --pf-v5-c-context-selector__toggle--BorderLeftColor: var(--pf-v5-c-masthead--c-context-selector__toggle--BorderLeftColor);
}

.pf-v5-c-masthead .pf-v5-c-context-selector.pf-m-full-height {
  --pf-v5-c-context-selector__toggle--BorderTopColor: var(--pf-v5-c-masthead--c-context-selector--m-full-height__toggle--BorderTopColor);
  --pf-v5-c-context-selector__toggle--BorderBottomColor: var(--pf-v5-c-masthead--c-context-selector--m-full-height__toggle--BorderBottomColor);
}

.pf-v5-c-masthead .pf-v5-c-dropdown {
  --pf-v5-c-dropdown__toggle--before--BorderTopColor: var(--pf-v5-c-masthead--c-dropdown__toggle--before--BorderTopColor);
  --pf-v5-c-dropdown__toggle--before--BorderRightColor: var(--pf-v5-c-masthead--c-dropdown__toggle--before--BorderRightColor);
  --pf-v5-c-dropdown__toggle--before--BorderLeftColor: var(--pf-v5-c-masthead--c-dropdown__toggle--before--BorderLeftColor);
}

.pf-v5-c-masthead .pf-v5-c-dropdown.pf-m-full-height {
  --pf-v5-c-dropdown__toggle--before--BorderTopColor: var(--pf-v5-c-masthead--c-dropdown--m-full-height__toggle--before--BorderTopColor);
  --pf-v5-c-dropdown__toggle--before--BorderBottomColor: var(--pf-v5-c-masthead--c-dropdown--m-full-height__toggle--before--BorderBottomColor);
}

.pf-v5-c-masthead .pf-v5-c-nav {
  align-self: stretch;
}

.pf-v5-c-masthead .pf-v5-c-button.pf-m-plain {
  color: var(--pf-v5-c-button--m-plain--Color);
}

.pf-v5-c-masthead__main {
  position: relative;
  display: flex;
  flex-basis: var(--pf-v5-c-masthead__main--FlexBasis);
  grid-column: var(--pf-v5-c-masthead__main--GridColumn);
  align-items: center;
  align-self: stretch;
  order: var(--pf-v5-c-masthead__main--Order);
  min-height: var(--pf-v5-c-masthead__main--MinHeight);
  padding-block-start: var(--pf-v5-c-masthead__main--PaddingTop);
  padding-block-end: var(--pf-v5-c-masthead__main--PaddingBottom);
  margin-inline-end: var(--pf-v5-c-masthead__main--MarginRight);
}

.pf-v5-c-masthead__main::before {
  position: absolute;
  inset-block-end: 0;
  inset-inline-start: var(--pf-v5-c-masthead__main--before--Left);
  inset-inline-end: var(--pf-v5-c-masthead__main--before--Right);
  content: "";
  border-block-end: var(--pf-v5-c-masthead__main--before--BorderBottom);
}

.pf-v5-c-masthead__main:last-child {
  --pf-v5-c-masthead__main--MarginRight: 0;
}

.pf-v5-c-masthead__content {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  grid-column: var(--pf-v5-c-masthead__content--GridColumn);
  grid-column-end: -1;
  align-items: center;
  align-self: stretch;
  order: var(--pf-v5-c-masthead__content--Order);
  min-height: var(--pf-v5-c-masthead__content--MinHeight);
  padding-block-start: var(--pf-v5-c-masthead__content--PaddingTop);
  padding-block-end: var(--pf-v5-c-masthead__content--PaddingBottom);
  margin-inline-start: var(--pf-v5-c-masthead__content--MarginLeft);
}

.pf-v5-c-masthead__content:only-child {
  --pf-v5-c-masthead__content--MarginLeft: 0;
}

.pf-v5-c-masthead__content .pf-v5-c-nav.pf-m-horizontal {
  margin-inline-start: var(--pf-v5-c-masthead__content--c-nav--m-horizontal--MarginLeft);
  margin-inline-end: var(--pf-v5-c-masthead__content--c-nav--m-horizontal--MarginRight);
}

.pf-v5-c-masthead__toggle~.pf-v5-c-masthead__content {
  --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginLeft: calc(var(--pf-v5-c-masthead__content--MarginLeft) * -1);
}

.pf-v5-c-masthead__main,
.pf-v5-c-masthead__brand,
.pf-v5-c-masthead__content {
  min-width: 0;
}

.pf-v5-c-masthead__brand {
  display: inline-flex;
  align-self: center;
}

.pf-v5-c-masthead__toggle {
  grid-column: var(--pf-v5-c-masthead__toggle--GridColumn);
  align-self: center;
  margin-inline-start: var(--pf-v5-c-masthead__toggle--MarginLeft);
  margin-inline-end: var(--pf-v5-c-masthead__toggle--MarginRight);
}

.pf-v5-c-masthead__toggle .pf-v5-c-button {
  --pf-v5-c-button--FontSize: var(--pf-v5-c-masthead__toggle--c-button--FontSize);
}

.pf-v5-c-masthead.pf-m-display-stack {
  --pf-v5-c-masthead--GridTemplateColumns: var(--pf-v5-c-masthead--m-display-stack--GridTemplateColumns);
  --pf-v5-c-masthead__main--GridColumn: var(--pf-v5-c-masthead--m-display-stack__main--GridColumn);
  --pf-v5-c-masthead__main--MinHeight: var(--pf-v5-c-masthead--m-display-stack__main--MinHeight);
  --pf-v5-c-masthead__main--Order: var(--pf-v5-c-masthead--m-display-stack__main--Order);
  --pf-v5-c-masthead__main--FlexBasis: var(--pf-v5-c-masthead--m-display-stack__main--FlexBasis);
  --pf-v5-c-masthead__main--PaddingTop: var(--pf-v5-c-masthead--m-display-stack__main--PaddingTop);
  --pf-v5-c-masthead__main--PaddingBottom: var(--pf-v5-c-masthead--m-display-stack__main--PaddingBottom);
  --pf-v5-c-masthead__main--MarginRight: var(--pf-v5-c-masthead--m-display-stack__main--MarginRight);
  --pf-v5-c-masthead__main--before--BorderBottom: var(--pf-v5-c-masthead--m-display-stack__main--before--BorderBottom);
  --pf-v5-c-masthead__content--GridColumn: var(--pf-v5-c-masthead--m-display-stack__content--GridColumn);
  --pf-v5-c-masthead__content--MinHeight: var(--pf-v5-c-masthead--m-display-stack__content--MinHeight);
  --pf-v5-c-masthead__content--Order: var(--pf-v5-c-masthead--m-display-stack__content--Order);
  --pf-v5-c-masthead__content--PaddingTop: var(--pf-v5-c-masthead--m-display-stack__content--PaddingTop);
  --pf-v5-c-masthead__content--PaddingBottom: var(--pf-v5-c-masthead--m-display-stack__content--PaddingBottom);
  --pf-v5-c-masthead__content--MarginLeft: var(--pf-v5-c-masthead--m-display-stack__content--MarginLeft);
  --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginRight: var(--pf-v5-c-masthead--m-display-stack__content--c-nav--m-horizontal--MarginRight);
  --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginLeft: var(--pf-v5-c-masthead--m-display-stack__content--c-nav--m-horizontal--MarginLeft);
}

.pf-v5-c-masthead.pf-m-display-inline {
  --pf-v5-c-masthead--GridTemplateColumns: var(--pf-v5-c-masthead--m-display-inline--GridTemplateColumns);
  --pf-v5-c-masthead__main--GridColumn: var(--pf-v5-c-masthead--m-display-inline__main--GridColumn);
  --pf-v5-c-masthead__main--MinHeight: var(--pf-v5-c-masthead--m-display-inline__main--MinHeight);
  --pf-v5-c-masthead__main--Order: var(--pf-v5-c-masthead--m-display-inline__main--Order);
  --pf-v5-c-masthead__main--FlexBasis: var(--pf-v5-c-masthead--m-display-inline__main--FlexBasis);
  --pf-v5-c-masthead__main--PaddingTop: var(--pf-v5-c-masthead--m-display-inline__main--PaddingTop);
  --pf-v5-c-masthead__main--PaddingBottom: var(--pf-v5-c-masthead--m-display-inline__main--PaddingBottom);
  --pf-v5-c-masthead__main--MarginRight: var(--pf-v5-c-masthead--m-display-inline__main--MarginRight);
  --pf-v5-c-masthead__main--before--BorderBottom: var(--pf-v5-c-masthead--m-display-inline__main--BorderBottom);
  --pf-v5-c-masthead__content--GridColumn: var(--pf-v5-c-masthead--m-display-inline__content--GridColumn);
  --pf-v5-c-masthead__content--MinHeight: var(--pf-v5-c-masthead--m-display-inline__content--MinHeight);
  --pf-v5-c-masthead__content--Order: var(--pf-v5-c-masthead--m-display-inline__content--Order);
  --pf-v5-c-masthead__content--PaddingTop: var(--pf-v5-c-masthead--m-display-inline__content--PaddingTop);
  --pf-v5-c-masthead__content--PaddingBottom: var(--pf-v5-c-masthead--m-display-inline__content--PaddingBottom);
  --pf-v5-c-masthead__content--MarginLeft: var(--pf-v5-c-masthead--m-display-inline__content--MarginLeft);
  --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginRight: var(--pf-v5-c-masthead--m-display-inline__content--c-nav--m-horizontal--MarginRight);
  --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginLeft: var(--pf-v5-c-masthead--m-display-inline__content--c-nav--m-horizontal--MarginLeft);
}

.pf-v5-c-masthead.pf-m-inset-none {
  --pf-v5-c-masthead--inset: 0;
}

.pf-v5-c-masthead.pf-m-inset-sm {
  --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--sm);
}

.pf-v5-c-masthead.pf-m-inset-md {
  --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--md);
}

.pf-v5-c-masthead.pf-m-inset-lg {
  --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--lg);
}

.pf-v5-c-masthead.pf-m-inset-xl {
  --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--xl);
}

.pf-v5-c-masthead.pf-m-inset-2xl {
  --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--2xl);
}

@media (min-width: 576px) {
  .pf-v5-c-masthead.pf-m-display-stack-on-sm {
    --pf-v5-c-masthead--GridTemplateColumns: var(--pf-v5-c-masthead--m-display-stack--GridTemplateColumns);
    --pf-v5-c-masthead__main--GridColumn: var(--pf-v5-c-masthead--m-display-stack__main--GridColumn);
    --pf-v5-c-masthead__main--MinHeight: var(--pf-v5-c-masthead--m-display-stack__main--MinHeight);
    --pf-v5-c-masthead__main--Order: var(--pf-v5-c-masthead--m-display-stack__main--Order);
    --pf-v5-c-masthead__main--FlexBasis: var(--pf-v5-c-masthead--m-display-stack__main--FlexBasis);
    --pf-v5-c-masthead__main--PaddingTop: var(--pf-v5-c-masthead--m-display-stack__main--PaddingTop);
    --pf-v5-c-masthead__main--PaddingBottom: var(--pf-v5-c-masthead--m-display-stack__main--PaddingBottom);
    --pf-v5-c-masthead__main--MarginRight: var(--pf-v5-c-masthead--m-display-stack__main--MarginRight);
    --pf-v5-c-masthead__main--before--BorderBottom: var(--pf-v5-c-masthead--m-display-stack__main--before--BorderBottom);
    --pf-v5-c-masthead__content--GridColumn: var(--pf-v5-c-masthead--m-display-stack__content--GridColumn);
    --pf-v5-c-masthead__content--MinHeight: var(--pf-v5-c-masthead--m-display-stack__content--MinHeight);
    --pf-v5-c-masthead__content--Order: var(--pf-v5-c-masthead--m-display-stack__content--Order);
    --pf-v5-c-masthead__content--PaddingTop: var(--pf-v5-c-masthead--m-display-stack__content--PaddingTop);
    --pf-v5-c-masthead__content--PaddingBottom: var(--pf-v5-c-masthead--m-display-stack__content--PaddingBottom);
    --pf-v5-c-masthead__content--MarginLeft: var(--pf-v5-c-masthead--m-display-stack__content--MarginLeft);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginRight: var(--pf-v5-c-masthead--m-display-stack__content--c-nav--m-horizontal--MarginRight);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginLeft: var(--pf-v5-c-masthead--m-display-stack__content--c-nav--m-horizontal--MarginLeft);
  }

  .pf-v5-c-masthead.pf-m-display-inline-on-sm {
    --pf-v5-c-masthead--GridTemplateColumns: var(--pf-v5-c-masthead--m-display-inline--GridTemplateColumns);
    --pf-v5-c-masthead__main--GridColumn: var(--pf-v5-c-masthead--m-display-inline__main--GridColumn);
    --pf-v5-c-masthead__main--MinHeight: var(--pf-v5-c-masthead--m-display-inline__main--MinHeight);
    --pf-v5-c-masthead__main--Order: var(--pf-v5-c-masthead--m-display-inline__main--Order);
    --pf-v5-c-masthead__main--FlexBasis: var(--pf-v5-c-masthead--m-display-inline__main--FlexBasis);
    --pf-v5-c-masthead__main--PaddingTop: var(--pf-v5-c-masthead--m-display-inline__main--PaddingTop);
    --pf-v5-c-masthead__main--PaddingBottom: var(--pf-v5-c-masthead--m-display-inline__main--PaddingBottom);
    --pf-v5-c-masthead__main--MarginRight: var(--pf-v5-c-masthead--m-display-inline__main--MarginRight);
    --pf-v5-c-masthead__main--before--BorderBottom: var(--pf-v5-c-masthead--m-display-inline__main--BorderBottom);
    --pf-v5-c-masthead__content--GridColumn: var(--pf-v5-c-masthead--m-display-inline__content--GridColumn);
    --pf-v5-c-masthead__content--MinHeight: var(--pf-v5-c-masthead--m-display-inline__content--MinHeight);
    --pf-v5-c-masthead__content--Order: var(--pf-v5-c-masthead--m-display-inline__content--Order);
    --pf-v5-c-masthead__content--PaddingTop: var(--pf-v5-c-masthead--m-display-inline__content--PaddingTop);
    --pf-v5-c-masthead__content--PaddingBottom: var(--pf-v5-c-masthead--m-display-inline__content--PaddingBottom);
    --pf-v5-c-masthead__content--MarginLeft: var(--pf-v5-c-masthead--m-display-inline__content--MarginLeft);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginRight: var(--pf-v5-c-masthead--m-display-inline__content--c-nav--m-horizontal--MarginRight);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginLeft: var(--pf-v5-c-masthead--m-display-inline__content--c-nav--m-horizontal--MarginLeft);
  }
}

@media (min-width: 576px) {
  .pf-v5-c-masthead.pf-m-inset-none-on-sm {
    --pf-v5-c-masthead--inset: 0;
  }

  .pf-v5-c-masthead.pf-m-inset-sm-on-sm {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-masthead.pf-m-inset-md-on-sm {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-masthead.pf-m-inset-lg-on-sm {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-masthead.pf-m-inset-xl-on-sm {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-c-masthead.pf-m-inset-2xl-on-sm {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--2xl);
  }
}

@media (min-width: 768px) {
  .pf-v5-c-masthead.pf-m-display-stack-on-md {
    --pf-v5-c-masthead--GridTemplateColumns: var(--pf-v5-c-masthead--m-display-stack--GridTemplateColumns);
    --pf-v5-c-masthead__main--GridColumn: var(--pf-v5-c-masthead--m-display-stack__main--GridColumn);
    --pf-v5-c-masthead__main--MinHeight: var(--pf-v5-c-masthead--m-display-stack__main--MinHeight);
    --pf-v5-c-masthead__main--Order: var(--pf-v5-c-masthead--m-display-stack__main--Order);
    --pf-v5-c-masthead__main--FlexBasis: var(--pf-v5-c-masthead--m-display-stack__main--FlexBasis);
    --pf-v5-c-masthead__main--PaddingTop: var(--pf-v5-c-masthead--m-display-stack__main--PaddingTop);
    --pf-v5-c-masthead__main--PaddingBottom: var(--pf-v5-c-masthead--m-display-stack__main--PaddingBottom);
    --pf-v5-c-masthead__main--MarginRight: var(--pf-v5-c-masthead--m-display-stack__main--MarginRight);
    --pf-v5-c-masthead__main--before--BorderBottom: var(--pf-v5-c-masthead--m-display-stack__main--before--BorderBottom);
    --pf-v5-c-masthead__content--GridColumn: var(--pf-v5-c-masthead--m-display-stack__content--GridColumn);
    --pf-v5-c-masthead__content--MinHeight: var(--pf-v5-c-masthead--m-display-stack__content--MinHeight);
    --pf-v5-c-masthead__content--Order: var(--pf-v5-c-masthead--m-display-stack__content--Order);
    --pf-v5-c-masthead__content--PaddingTop: var(--pf-v5-c-masthead--m-display-stack__content--PaddingTop);
    --pf-v5-c-masthead__content--PaddingBottom: var(--pf-v5-c-masthead--m-display-stack__content--PaddingBottom);
    --pf-v5-c-masthead__content--MarginLeft: var(--pf-v5-c-masthead--m-display-stack__content--MarginLeft);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginRight: var(--pf-v5-c-masthead--m-display-stack__content--c-nav--m-horizontal--MarginRight);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginLeft: var(--pf-v5-c-masthead--m-display-stack__content--c-nav--m-horizontal--MarginLeft);
  }

  .pf-v5-c-masthead.pf-m-display-inline-on-md {
    --pf-v5-c-masthead--GridTemplateColumns: var(--pf-v5-c-masthead--m-display-inline--GridTemplateColumns);
    --pf-v5-c-masthead__main--GridColumn: var(--pf-v5-c-masthead--m-display-inline__main--GridColumn);
    --pf-v5-c-masthead__main--MinHeight: var(--pf-v5-c-masthead--m-display-inline__main--MinHeight);
    --pf-v5-c-masthead__main--Order: var(--pf-v5-c-masthead--m-display-inline__main--Order);
    --pf-v5-c-masthead__main--FlexBasis: var(--pf-v5-c-masthead--m-display-inline__main--FlexBasis);
    --pf-v5-c-masthead__main--PaddingTop: var(--pf-v5-c-masthead--m-display-inline__main--PaddingTop);
    --pf-v5-c-masthead__main--PaddingBottom: var(--pf-v5-c-masthead--m-display-inline__main--PaddingBottom);
    --pf-v5-c-masthead__main--MarginRight: var(--pf-v5-c-masthead--m-display-inline__main--MarginRight);
    --pf-v5-c-masthead__main--before--BorderBottom: var(--pf-v5-c-masthead--m-display-inline__main--BorderBottom);
    --pf-v5-c-masthead__content--GridColumn: var(--pf-v5-c-masthead--m-display-inline__content--GridColumn);
    --pf-v5-c-masthead__content--MinHeight: var(--pf-v5-c-masthead--m-display-inline__content--MinHeight);
    --pf-v5-c-masthead__content--Order: var(--pf-v5-c-masthead--m-display-inline__content--Order);
    --pf-v5-c-masthead__content--PaddingTop: var(--pf-v5-c-masthead--m-display-inline__content--PaddingTop);
    --pf-v5-c-masthead__content--PaddingBottom: var(--pf-v5-c-masthead--m-display-inline__content--PaddingBottom);
    --pf-v5-c-masthead__content--MarginLeft: var(--pf-v5-c-masthead--m-display-inline__content--MarginLeft);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginRight: var(--pf-v5-c-masthead--m-display-inline__content--c-nav--m-horizontal--MarginRight);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginLeft: var(--pf-v5-c-masthead--m-display-inline__content--c-nav--m-horizontal--MarginLeft);
  }
}

@media (min-width: 768px) {
  .pf-v5-c-masthead.pf-m-inset-none-on-md {
    --pf-v5-c-masthead--inset: 0;
  }

  .pf-v5-c-masthead.pf-m-inset-sm-on-md {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-masthead.pf-m-inset-md-on-md {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-masthead.pf-m-inset-lg-on-md {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-masthead.pf-m-inset-xl-on-md {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-c-masthead.pf-m-inset-2xl-on-md {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--2xl);
  }
}

@media (min-width: 992px) {
  .pf-v5-c-masthead.pf-m-display-stack-on-lg {
    --pf-v5-c-masthead--GridTemplateColumns: var(--pf-v5-c-masthead--m-display-stack--GridTemplateColumns);
    --pf-v5-c-masthead__main--GridColumn: var(--pf-v5-c-masthead--m-display-stack__main--GridColumn);
    --pf-v5-c-masthead__main--MinHeight: var(--pf-v5-c-masthead--m-display-stack__main--MinHeight);
    --pf-v5-c-masthead__main--Order: var(--pf-v5-c-masthead--m-display-stack__main--Order);
    --pf-v5-c-masthead__main--FlexBasis: var(--pf-v5-c-masthead--m-display-stack__main--FlexBasis);
    --pf-v5-c-masthead__main--PaddingTop: var(--pf-v5-c-masthead--m-display-stack__main--PaddingTop);
    --pf-v5-c-masthead__main--PaddingBottom: var(--pf-v5-c-masthead--m-display-stack__main--PaddingBottom);
    --pf-v5-c-masthead__main--MarginRight: var(--pf-v5-c-masthead--m-display-stack__main--MarginRight);
    --pf-v5-c-masthead__main--before--BorderBottom: var(--pf-v5-c-masthead--m-display-stack__main--before--BorderBottom);
    --pf-v5-c-masthead__content--GridColumn: var(--pf-v5-c-masthead--m-display-stack__content--GridColumn);
    --pf-v5-c-masthead__content--MinHeight: var(--pf-v5-c-masthead--m-display-stack__content--MinHeight);
    --pf-v5-c-masthead__content--Order: var(--pf-v5-c-masthead--m-display-stack__content--Order);
    --pf-v5-c-masthead__content--PaddingTop: var(--pf-v5-c-masthead--m-display-stack__content--PaddingTop);
    --pf-v5-c-masthead__content--PaddingBottom: var(--pf-v5-c-masthead--m-display-stack__content--PaddingBottom);
    --pf-v5-c-masthead__content--MarginLeft: var(--pf-v5-c-masthead--m-display-stack__content--MarginLeft);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginRight: var(--pf-v5-c-masthead--m-display-stack__content--c-nav--m-horizontal--MarginRight);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginLeft: var(--pf-v5-c-masthead--m-display-stack__content--c-nav--m-horizontal--MarginLeft);
  }

  .pf-v5-c-masthead.pf-m-display-inline-on-lg {
    --pf-v5-c-masthead--GridTemplateColumns: var(--pf-v5-c-masthead--m-display-inline--GridTemplateColumns);
    --pf-v5-c-masthead__main--GridColumn: var(--pf-v5-c-masthead--m-display-inline__main--GridColumn);
    --pf-v5-c-masthead__main--MinHeight: var(--pf-v5-c-masthead--m-display-inline__main--MinHeight);
    --pf-v5-c-masthead__main--Order: var(--pf-v5-c-masthead--m-display-inline__main--Order);
    --pf-v5-c-masthead__main--FlexBasis: var(--pf-v5-c-masthead--m-display-inline__main--FlexBasis);
    --pf-v5-c-masthead__main--PaddingTop: var(--pf-v5-c-masthead--m-display-inline__main--PaddingTop);
    --pf-v5-c-masthead__main--PaddingBottom: var(--pf-v5-c-masthead--m-display-inline__main--PaddingBottom);
    --pf-v5-c-masthead__main--MarginRight: var(--pf-v5-c-masthead--m-display-inline__main--MarginRight);
    --pf-v5-c-masthead__main--before--BorderBottom: var(--pf-v5-c-masthead--m-display-inline__main--BorderBottom);
    --pf-v5-c-masthead__content--GridColumn: var(--pf-v5-c-masthead--m-display-inline__content--GridColumn);
    --pf-v5-c-masthead__content--MinHeight: var(--pf-v5-c-masthead--m-display-inline__content--MinHeight);
    --pf-v5-c-masthead__content--Order: var(--pf-v5-c-masthead--m-display-inline__content--Order);
    --pf-v5-c-masthead__content--PaddingTop: var(--pf-v5-c-masthead--m-display-inline__content--PaddingTop);
    --pf-v5-c-masthead__content--PaddingBottom: var(--pf-v5-c-masthead--m-display-inline__content--PaddingBottom);
    --pf-v5-c-masthead__content--MarginLeft: var(--pf-v5-c-masthead--m-display-inline__content--MarginLeft);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginRight: var(--pf-v5-c-masthead--m-display-inline__content--c-nav--m-horizontal--MarginRight);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginLeft: var(--pf-v5-c-masthead--m-display-inline__content--c-nav--m-horizontal--MarginLeft);
  }
}

@media (min-width: 992px) {
  .pf-v5-c-masthead.pf-m-inset-none-on-lg {
    --pf-v5-c-masthead--inset: 0;
  }

  .pf-v5-c-masthead.pf-m-inset-sm-on-lg {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-masthead.pf-m-inset-md-on-lg {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-masthead.pf-m-inset-lg-on-lg {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-masthead.pf-m-inset-xl-on-lg {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-c-masthead.pf-m-inset-2xl-on-lg {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--2xl);
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-masthead.pf-m-display-stack-on-xl {
    --pf-v5-c-masthead--GridTemplateColumns: var(--pf-v5-c-masthead--m-display-stack--GridTemplateColumns);
    --pf-v5-c-masthead__main--GridColumn: var(--pf-v5-c-masthead--m-display-stack__main--GridColumn);
    --pf-v5-c-masthead__main--MinHeight: var(--pf-v5-c-masthead--m-display-stack__main--MinHeight);
    --pf-v5-c-masthead__main--Order: var(--pf-v5-c-masthead--m-display-stack__main--Order);
    --pf-v5-c-masthead__main--FlexBasis: var(--pf-v5-c-masthead--m-display-stack__main--FlexBasis);
    --pf-v5-c-masthead__main--PaddingTop: var(--pf-v5-c-masthead--m-display-stack__main--PaddingTop);
    --pf-v5-c-masthead__main--PaddingBottom: var(--pf-v5-c-masthead--m-display-stack__main--PaddingBottom);
    --pf-v5-c-masthead__main--MarginRight: var(--pf-v5-c-masthead--m-display-stack__main--MarginRight);
    --pf-v5-c-masthead__main--before--BorderBottom: var(--pf-v5-c-masthead--m-display-stack__main--before--BorderBottom);
    --pf-v5-c-masthead__content--GridColumn: var(--pf-v5-c-masthead--m-display-stack__content--GridColumn);
    --pf-v5-c-masthead__content--MinHeight: var(--pf-v5-c-masthead--m-display-stack__content--MinHeight);
    --pf-v5-c-masthead__content--Order: var(--pf-v5-c-masthead--m-display-stack__content--Order);
    --pf-v5-c-masthead__content--PaddingTop: var(--pf-v5-c-masthead--m-display-stack__content--PaddingTop);
    --pf-v5-c-masthead__content--PaddingBottom: var(--pf-v5-c-masthead--m-display-stack__content--PaddingBottom);
    --pf-v5-c-masthead__content--MarginLeft: var(--pf-v5-c-masthead--m-display-stack__content--MarginLeft);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginRight: var(--pf-v5-c-masthead--m-display-stack__content--c-nav--m-horizontal--MarginRight);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginLeft: var(--pf-v5-c-masthead--m-display-stack__content--c-nav--m-horizontal--MarginLeft);
  }

  .pf-v5-c-masthead.pf-m-display-inline-on-xl {
    --pf-v5-c-masthead--GridTemplateColumns: var(--pf-v5-c-masthead--m-display-inline--GridTemplateColumns);
    --pf-v5-c-masthead__main--GridColumn: var(--pf-v5-c-masthead--m-display-inline__main--GridColumn);
    --pf-v5-c-masthead__main--MinHeight: var(--pf-v5-c-masthead--m-display-inline__main--MinHeight);
    --pf-v5-c-masthead__main--Order: var(--pf-v5-c-masthead--m-display-inline__main--Order);
    --pf-v5-c-masthead__main--FlexBasis: var(--pf-v5-c-masthead--m-display-inline__main--FlexBasis);
    --pf-v5-c-masthead__main--PaddingTop: var(--pf-v5-c-masthead--m-display-inline__main--PaddingTop);
    --pf-v5-c-masthead__main--PaddingBottom: var(--pf-v5-c-masthead--m-display-inline__main--PaddingBottom);
    --pf-v5-c-masthead__main--MarginRight: var(--pf-v5-c-masthead--m-display-inline__main--MarginRight);
    --pf-v5-c-masthead__main--before--BorderBottom: var(--pf-v5-c-masthead--m-display-inline__main--BorderBottom);
    --pf-v5-c-masthead__content--GridColumn: var(--pf-v5-c-masthead--m-display-inline__content--GridColumn);
    --pf-v5-c-masthead__content--MinHeight: var(--pf-v5-c-masthead--m-display-inline__content--MinHeight);
    --pf-v5-c-masthead__content--Order: var(--pf-v5-c-masthead--m-display-inline__content--Order);
    --pf-v5-c-masthead__content--PaddingTop: var(--pf-v5-c-masthead--m-display-inline__content--PaddingTop);
    --pf-v5-c-masthead__content--PaddingBottom: var(--pf-v5-c-masthead--m-display-inline__content--PaddingBottom);
    --pf-v5-c-masthead__content--MarginLeft: var(--pf-v5-c-masthead--m-display-inline__content--MarginLeft);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginRight: var(--pf-v5-c-masthead--m-display-inline__content--c-nav--m-horizontal--MarginRight);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginLeft: var(--pf-v5-c-masthead--m-display-inline__content--c-nav--m-horizontal--MarginLeft);
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-masthead.pf-m-inset-none-on-xl {
    --pf-v5-c-masthead--inset: 0;
  }

  .pf-v5-c-masthead.pf-m-inset-sm-on-xl {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-masthead.pf-m-inset-md-on-xl {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-masthead.pf-m-inset-lg-on-xl {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-masthead.pf-m-inset-xl-on-xl {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-c-masthead.pf-m-inset-2xl-on-xl {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--2xl);
  }
}

@media (min-width: 1450px) {
  .pf-v5-c-masthead.pf-m-display-stack-on-2xl {
    --pf-v5-c-masthead--GridTemplateColumns: var(--pf-v5-c-masthead--m-display-stack--GridTemplateColumns);
    --pf-v5-c-masthead__main--GridColumn: var(--pf-v5-c-masthead--m-display-stack__main--GridColumn);
    --pf-v5-c-masthead__main--MinHeight: var(--pf-v5-c-masthead--m-display-stack__main--MinHeight);
    --pf-v5-c-masthead__main--Order: var(--pf-v5-c-masthead--m-display-stack__main--Order);
    --pf-v5-c-masthead__main--FlexBasis: var(--pf-v5-c-masthead--m-display-stack__main--FlexBasis);
    --pf-v5-c-masthead__main--PaddingTop: var(--pf-v5-c-masthead--m-display-stack__main--PaddingTop);
    --pf-v5-c-masthead__main--PaddingBottom: var(--pf-v5-c-masthead--m-display-stack__main--PaddingBottom);
    --pf-v5-c-masthead__main--MarginRight: var(--pf-v5-c-masthead--m-display-stack__main--MarginRight);
    --pf-v5-c-masthead__main--before--BorderBottom: var(--pf-v5-c-masthead--m-display-stack__main--before--BorderBottom);
    --pf-v5-c-masthead__content--GridColumn: var(--pf-v5-c-masthead--m-display-stack__content--GridColumn);
    --pf-v5-c-masthead__content--MinHeight: var(--pf-v5-c-masthead--m-display-stack__content--MinHeight);
    --pf-v5-c-masthead__content--Order: var(--pf-v5-c-masthead--m-display-stack__content--Order);
    --pf-v5-c-masthead__content--PaddingTop: var(--pf-v5-c-masthead--m-display-stack__content--PaddingTop);
    --pf-v5-c-masthead__content--PaddingBottom: var(--pf-v5-c-masthead--m-display-stack__content--PaddingBottom);
    --pf-v5-c-masthead__content--MarginLeft: var(--pf-v5-c-masthead--m-display-stack__content--MarginLeft);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginRight: var(--pf-v5-c-masthead--m-display-stack__content--c-nav--m-horizontal--MarginRight);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginLeft: var(--pf-v5-c-masthead--m-display-stack__content--c-nav--m-horizontal--MarginLeft);
  }

  .pf-v5-c-masthead.pf-m-display-inline-on-2xl {
    --pf-v5-c-masthead--GridTemplateColumns: var(--pf-v5-c-masthead--m-display-inline--GridTemplateColumns);
    --pf-v5-c-masthead__main--GridColumn: var(--pf-v5-c-masthead--m-display-inline__main--GridColumn);
    --pf-v5-c-masthead__main--MinHeight: var(--pf-v5-c-masthead--m-display-inline__main--MinHeight);
    --pf-v5-c-masthead__main--Order: var(--pf-v5-c-masthead--m-display-inline__main--Order);
    --pf-v5-c-masthead__main--FlexBasis: var(--pf-v5-c-masthead--m-display-inline__main--FlexBasis);
    --pf-v5-c-masthead__main--PaddingTop: var(--pf-v5-c-masthead--m-display-inline__main--PaddingTop);
    --pf-v5-c-masthead__main--PaddingBottom: var(--pf-v5-c-masthead--m-display-inline__main--PaddingBottom);
    --pf-v5-c-masthead__main--MarginRight: var(--pf-v5-c-masthead--m-display-inline__main--MarginRight);
    --pf-v5-c-masthead__main--before--BorderBottom: var(--pf-v5-c-masthead--m-display-inline__main--BorderBottom);
    --pf-v5-c-masthead__content--GridColumn: var(--pf-v5-c-masthead--m-display-inline__content--GridColumn);
    --pf-v5-c-masthead__content--MinHeight: var(--pf-v5-c-masthead--m-display-inline__content--MinHeight);
    --pf-v5-c-masthead__content--Order: var(--pf-v5-c-masthead--m-display-inline__content--Order);
    --pf-v5-c-masthead__content--PaddingTop: var(--pf-v5-c-masthead--m-display-inline__content--PaddingTop);
    --pf-v5-c-masthead__content--PaddingBottom: var(--pf-v5-c-masthead--m-display-inline__content--PaddingBottom);
    --pf-v5-c-masthead__content--MarginLeft: var(--pf-v5-c-masthead--m-display-inline__content--MarginLeft);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginRight: var(--pf-v5-c-masthead--m-display-inline__content--c-nav--m-horizontal--MarginRight);
    --pf-v5-c-masthead__content--c-nav--m-horizontal--MarginLeft: var(--pf-v5-c-masthead--m-display-inline__content--c-nav--m-horizontal--MarginLeft);
  }
}

@media (min-width: 1450px) {
  .pf-v5-c-masthead.pf-m-inset-none-on-2xl {
    --pf-v5-c-masthead--inset: 0;
  }

  .pf-v5-c-masthead.pf-m-inset-sm-on-2xl {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-masthead.pf-m-inset-md-on-2xl {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-masthead.pf-m-inset-lg-on-2xl {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-masthead.pf-m-inset-xl-on-2xl {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-c-masthead.pf-m-inset-2xl-on-2xl {
    --pf-v5-c-masthead--inset: var(--pf-v5-global--spacer--2xl);
  }
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-masthead {
  --pf-v5-c-masthead--BackgroundColor: var(--pf-v5-global--palette--black-1000);
  --pf-v5-c-masthead--item-border-color--base: var(--pf-v5-global--BorderColor--100);
  color: var(--pf-v5-global--Color--100);
}

:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-toolbar {
  --pf-v5-c-toolbar--BackgroundColor: var(--pf-v5-global--palette--black-1000);
}

.pf-v5-c-menu {
  color: var(--pf-v5-global--Color--100);
  --pf-v5-c-menu--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-menu--BoxShadow: var(--pf-v5-global--BoxShadow--md);
  --pf-v5-c-menu--MinWidth: auto;
  --pf-v5-c-menu--Width: auto;
  --pf-v5-c-menu--ZIndex: var(--pf-v5-global--ZIndex--sm);
  --pf-v5-c-menu--Top: auto;
  --pf-v5-c-menu--m-flyout__menu--Top: calc(var(--pf-v5-c-menu__list--PaddingTop) * -1 + var(--pf-v5-c-menu--m-flyout__menu--top-offset));
  --pf-v5-c-menu--m-flyout__menu--Right: auto;
  --pf-v5-c-menu--m-flyout__menu--Bottom: auto;
  --pf-v5-c-menu--m-flyout__menu--Left: calc(100% + var(--pf-v5-c-menu--m-flyout__menu--left-offset));
  --pf-v5-c-menu--m-flyout__menu--m-top--Bottom: calc(var(--pf-v5-c-menu__list--PaddingTop) * -1);
  --pf-v5-c-menu--m-flyout__menu--m-left--Right: calc(100% + var(--pf-v5-c-menu--m-flyout__menu--m-left--right-offset));
  --pf-v5-c-menu--m-plain--BoxShadow: none;
  --pf-v5-c-menu--m-flyout__menu--top-offset: 0px;
  --pf-v5-c-menu--m-flyout__menu--left-offset: 0px;
  --pf-v5-c-menu--m-flyout__menu--m-left--right-offset: 0px;
  --pf-v5-c-menu__content--Height: auto;
  --pf-v5-c-menu__content--MaxHeight: none;
  --pf-v5-c-menu--m-scrollable__content--MaxHeight: 18.75rem;
  --pf-v5-c-menu--c-divider--MarginTop: 0;
  --pf-v5-c-menu--c-divider--MarginBottom: 0;
  --pf-v5-c-menu__list--c-divider--MarginTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__list--c-divider--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__header--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__header--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__header--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__header--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__header--c-menu__item--MarginTop: calc(var(--pf-v5-c-menu__header--PaddingTop) * -1 / 2);
  --pf-v5-c-menu__header--c-menu__item--MarginRight: calc(var(--pf-v5-c-menu__header--PaddingRight) * -1 / 2);
  --pf-v5-c-menu__header--c-menu__item--MarginBottom: calc(var(--pf-v5-c-menu__header--PaddingBottom) * -1 / 2);
  --pf-v5-c-menu__header--c-menu__item--MarginLeft: calc(var(--pf-v5-c-menu__header--PaddingLeft) * -1 / 2);
  --pf-v5-c-menu__header--c-menu__item--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__header--c-menu__item--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__header--c-menu__item--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__header--c-menu__item--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__header--c-menu__item--BackgroundColor: transparent;
  --pf-v5-c-menu__header--c-menu__item--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-menu__header--c-menu__item--focus--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-menu__search--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__search--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__search--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__search--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__header__search--PaddingTop: 0;
  --pf-v5-c-menu__list--Display: block;
  --pf-v5-c-menu__list--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__list--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__list-item--Display: flex;
  --pf-v5-c-menu__list-item--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-menu__list-item--BackgroundColor: transparent;
  --pf-v5-c-menu__list-item--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-menu__list-item--focus-within--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-menu__list-item--m-loading--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__item--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__item--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__item--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__item--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__item--OutlineOffset: calc(0.125rem * -1);
  --pf-v5-c-menu__item--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-menu__item--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-menu__item--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-menu__list-item--m-disabled__item--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-menu__list-item--m-danger__item--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-menu__list-item--m-load__item--Color: var(--pf-v5-global--link--Color);
  --pf-v5-c-menu__group--Display: block;
  --pf-v5-c-menu__group-title--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__group-title--PaddingRight: var(--pf-v5-c-menu__item--PaddingRight);
  --pf-v5-c-menu__group-title--PaddingLeft: var(--pf-v5-c-menu__item--PaddingLeft);
  --pf-v5-c-menu__group-title--FontSize: var(--pf-v5-global--FontSize--xs);
  --pf-v5-c-menu__group-title--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-menu__group-title--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-menu__item-description--FontSize: var(--pf-v5-global--FontSize--xs);
  --pf-v5-c-menu__item-description--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-menu__item-icon--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__item-check--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__item-toggle-icon--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__item-toggle-icon--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__list-item--m-disabled__item-toggle-icon--Color: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-menu__item-text--item-toggle-icon--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__item-toggle-icon--item-text--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__item-select-icon--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__item-select-icon--Color: var(--pf-v5-global--active-color--100);
  --pf-v5-c-menu__item-select-icon--FontSize: var(--pf-v5-global--icon--FontSize--sm);
  --pf-v5-c-menu__item-external-icon--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__item-external-icon--Color: var(--pf-v5-global--link--Color);
  --pf-v5-c-menu__item-external-icon--FontSize: var(--pf-v5-global--icon--FontSize--sm);
  --pf-v5-c-menu__item-external-icon--Opacity: 0;
  --pf-v5-c-menu__item-action--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__item-action--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__item-action--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu__item-action--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__item-action--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-menu__item-action--BackgroundColor: transparent;
  --pf-v5-c-menu__item-action--hover--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-menu__item-action--disabled--Color: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-menu__item-action--m-favorited--Color: var(--pf-v5-global--palette--gold-400);
  --pf-v5-c-menu__item-action--m-favorited--hover--Color: var(--pf-v5-global--palette--gold-500);
  --pf-v5-c-menu__item-action-icon--Height: calc(var(--pf-v5-c-menu__item--FontSize) * var(--pf-v5-c-menu__item--LineHeight));
  --pf-v5-c-menu__item-action--m-favorite__icon--FontSize: var(--pf-v5-global--icon--FontSize--sm);
  --pf-v5-c-menu__breadcrumb--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__breadcrumb--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__breadcrumb--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__breadcrumb--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__breadcrumb--c-breadcrumb__item--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-menu__breadcrumb--c-breadcrumb__heading--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-menu--m-drilldown--c-menu--Top: 0;
  --pf-v5-c-menu--m-drilldown--c-menu--TransitionDuration--transform: var(--pf-v5-global--TransitionDuration);
  --pf-v5-c-menu--m-drilldown--c-menu--Transition: transform var(--pf-v5-c-menu--m-drilldown--c-menu--TransitionDuration--transform);
  --pf-v5-c-menu--m-drilldown__content--TransitionDuration--height: var(--pf-v5-global--TransitionDuration);
  --pf-v5-c-menu--m-drilldown__content--TransitionDuration--transform: var(--pf-v5-global--TransitionDuration);
  --pf-v5-c-menu--m-drilldown__content--Transition: transform var(--pf-v5-c-menu--m-drilldown__content--TransitionDuration--transform), height var(--pf-v5-c-menu--m-drilldown__content--TransitionDuration--height);
  --pf-v5-c-menu--m-drilldown__list--TransitionDuration--transform: var(--pf-v5-global--TransitionDuration);
  --pf-v5-c-menu--m-drilldown__list--Transition: transform var(--pf-v5-c-menu--m-drilldown__list--TransitionDuration--transform);
  --pf-v5-c-menu--m-drilled-in--c-menu__list-item--m-current-path--c-menu--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-menu__footer--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__footer--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__footer--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__footer--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu__footer--BoxShadow: none;
  --pf-v5-c-menu__footer--after--BorderTopWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu__footer--after--BorderTopColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-menu--m-scrollable__footer--BoxShadow: var(--pf-v5-global--BoxShadow--sm-top);
  --pf-v5-c-menu--m-scrollable__footer--after--BorderTopWidth: 0;
  --pf-v5-c-menu--m-scrollable__footer--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu--m-nav--BoxShadow: var(--pf-v5-global--BoxShadow--lg);
  --pf-v5-c-menu--m-nav--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-300);
  --pf-v5-c-menu--m-nav__list--PaddingTop: 0;
  --pf-v5-c-menu--m-nav__list--PaddingBottom: 0;
  --pf-v5-c-menu--m-nav__list-item--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-menu--m-nav__list-item--focus-within--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-menu--m-nav__list-item--active--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-menu--m-nav__item--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-menu--m-nav__item--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-menu--m-nav__item--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-menu--m-nav__item--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-menu--m-nav__item--OutlineOffset: calc(var(--pf-v5-global--spacer--xs) * -1);
  --pf-v5-c-menu--m-nav__item--before--BorderBottomColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-menu--m-nav__item--before--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu--m-nav__item--hover--after--BorderLeftColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-menu--m-nav__item--hover--after--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu--m-nav__item--hover--after--Top: calc(var(--pf-v5-c-menu--m-nav__item--before--BorderBottomWidth) * -1);
  --pf-v5-c-menu--m-nav__item__list-item--first-child__item--hover--after--Top: 0;
  --pf-v5-c-menu--m-nav__item-description--Color: var(--pf-v5-global--Color--light-200);
  --pf-v5-c-menu--m-nav--c-menu--left-offset: 0.25rem;
  --pf-v5-c-menu--m-nav--c-menu--m-top--bottom-offset: 0;
  --pf-v5-c-menu--m-nav--c-menu--m-left--right-offset: 0.25rem;
  --pf-v5-c-menu--m-nav--c-menu--Top: calc(var(--pf-v5-c-menu--m-nav__item--before--BorderBottomWidth) * -1);
  --pf-v5-c-menu--m-nav--c-menu--Left: calc(100% - var(--pf-v5-c-menu--m-nav--c-menu--left-offset));
  --pf-v5-c-menu--m-nav--c-menu--m-left--Right: calc(100% - var(--pf-v5-c-menu--m-nav--c-menu--m-left--right-offset));
  --pf-v5-c-menu--m-nav--c-menu--m-top--Bottom: calc(0 + var(--pf-v5-c-menu--m-nav--c-menu--m-top--bottom-offset));
  --pf-v5-c-menu--m-nav__list-item--first-child--c-menu--Top: 0;
  inset-block-start: var(--pf-v5-c-menu--Top);
  z-index: var(--pf-v5-c-menu--ZIndex);
  width: var(--pf-v5-c-menu--Width);
  min-width: var(--pf-v5-c-menu--MinWidth);
  background-color: var(--pf-v5-c-menu--BackgroundColor);
  box-shadow: var(--pf-v5-c-menu--BoxShadow);
}

.pf-v5-c-menu .pf-v5-c-menu__content .pf-v5-c-menu .pf-v5-c-menu__content {
  overflow: visible;
}

.pf-v5-c-menu .pf-v5-c-divider {
  margin-block-start: var(--pf-v5-c-menu--c-divider--MarginTop);
  margin-block-end: var(--pf-v5-c-menu--c-divider--MarginBottom);
}

.pf-v5-c-menu .pf-v5-c-menu.pf-m-flyout,
.pf-v5-c-menu.pf-m-flyout .pf-v5-c-menu {
  position: absolute;
  inset-block-start: var(--pf-v5-c-menu--m-flyout__menu--Top);
  inset-block-end: var(--pf-v5-c-menu--m-flyout__menu--Bottom);
  inset-inline-start: var(--pf-v5-c-menu--m-flyout__menu--Left);
  inset-inline-end: var(--pf-v5-c-menu--m-flyout__menu--Right);
}

.pf-v5-c-menu .pf-v5-c-menu.pf-m-flyout .pf-v5-c-menu__content,
.pf-v5-c-menu.pf-m-flyout .pf-v5-c-menu .pf-v5-c-menu__content {
  overflow-y: visible;
}

.pf-v5-c-menu.pf-m-top {
  --pf-v5-c-menu--m-flyout__menu--Top: auto;
  --pf-v5-c-menu--m-flyout__menu--Bottom: var(--pf-v5-c-menu--m-flyout__menu--m-top--Bottom);
}

.pf-v5-c-menu.pf-m-left {
  --pf-v5-c-menu--m-flyout__menu--Right: var(--pf-v5-c-menu--m-flyout__menu--m-left--Right);
  --pf-v5-c-menu--m-flyout__menu--Left: auto;
}

.pf-v5-c-menu.pf-m-drilldown {
  display: flex;
  flex-direction: column;
}

.pf-v5-c-menu.pf-m-drilldown[hidden] {
  display: none;
}

.pf-v5-c-menu.pf-m-drilldown>.pf-v5-c-menu__content {
  flex-grow: 1;
  overflow: hidden;
  transition: var(--pf-v5-c-menu--m-drilldown__content--Transition);
}

.pf-v5-c-menu.pf-m-drilldown.pf-m-drilled-in>.pf-v5-c-menu__content>.pf-v5-c-menu__list,
.pf-v5-c-menu.pf-m-drilldown.pf-m-drilled-in>.pf-v5-c-menu__list {
  transform: translateX(-100%);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-menu.pf-m-drilldown.pf-m-drilled-in>.pf-v5-c-menu__content>.pf-v5-c-menu__list,
.pf-v5-c-menu.pf-m-drilldown.pf-m-drilled-in>.pf-v5-c-menu__list {
  transform: translateX(calc(-100% * var(--pf-v5-global--inverse--multiplier)));
}

.pf-v5-c-menu.pf-m-drilldown>.pf-v5-c-menu__content .pf-v5-c-menu {
  --pf-v5-c-menu--BoxShadow: none;
  position: absolute;
  inset-block-start: var(--pf-v5-c-menu--m-drilldown--c-menu--Top);
  inset-inline-start: 100%;
  width: 100%;
  transition: var(--pf-v5-c-menu--m-drilldown--c-menu--Transition);
}

.pf-v5-c-menu.pf-m-drilldown>.pf-v5-c-menu__content .pf-v5-c-menu.pf-m-drilled-in {
  transform: translateX(-100%);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-menu.pf-m-drilldown>.pf-v5-c-menu__content .pf-v5-c-menu.pf-m-drilled-in {
  transform: translateX(calc(-100% * var(--pf-v5-global--inverse--multiplier)));
}

.pf-v5-c-menu.pf-m-drilldown .pf-v5-c-menu__list {
  position: relative;
  overflow: hidden;
  visibility: visible;
  transition: var(--pf-v5-c-menu--m-drilldown__list--Transition);
}

.pf-v5-c-menu.pf-m-drilldown .pf-v5-c-menu__list .pf-v5-c-menu__list {
  --pf-v5-c-menu__list--PaddingTop: 0;
  --pf-v5-c-menu__list--PaddingBottom: 0;
}

.pf-v5-c-menu.pf-m-drilldown .pf-v5-c-menu__list-item.pf-m-current-path .pf-v5-c-menu {
  z-index: var(--pf-v5-c-menu--m-drilled-in--c-menu__list-item--m-current-path--c-menu--ZIndex);
}

.pf-v5-c-menu.pf-m-drilldown .pf-v5-c-menu__list-item.pf-m-current-path>.pf-v5-c-menu {
  overflow: visible;
}

.pf-v5-c-menu.pf-m-drilldown .pf-v5-c-menu__list-item.pf-m-static>.pf-v5-c-menu {
  position: static;
}

.pf-v5-c-menu.pf-m-drilldown .pf-v5-c-menu__list-item.pf-m-static:hover {
  background-color: transparent;
}

.pf-v5-c-menu.pf-m-drilldown .pf-v5-c-menu__list-item:not(.pf-m-current-path) .pf-v5-c-menu {
  display: none;
}

.pf-v5-c-menu.pf-m-drilldown .pf-v5-c-menu__item {
  outline-offset: var(--pf-v5-c-menu__item--OutlineOffset);
}

.pf-v5-c-menu.pf-m-drilled-in>.pf-v5-c-menu__content>.pf-v5-c-menu__list {
  overflow: visible;
  visibility: hidden;
}

.pf-v5-c-menu.pf-m-drilled-in>.pf-v5-c-menu__content>.pf-v5-c-menu__list>.pf-v5-c-divider,
.pf-v5-c-menu.pf-m-drilled-in>.pf-v5-c-menu__content>.pf-v5-c-menu__list>.pf-v5-c-menu__list-item:not(.pf-m-current-path) {
  display: none;
}

.pf-v5-c-menu.pf-m-drilled-in>.pf-v5-c-menu__content>.pf-v5-c-menu__list>.pf-v5-c-menu__list-item.pf-m-current-path {
  visibility: hidden;
}

.pf-v5-c-menu.pf-m-plain {
  --pf-v5-c-menu--BoxShadow: var(--pf-v5-c-menu--m-plain--BoxShadow);
}

.pf-v5-c-menu.pf-m-scrollable {
  --pf-v5-c-menu__content--MaxHeight: var(--pf-v5-c-menu--m-scrollable__content--MaxHeight);
  --pf-v5-c-menu__footer--BoxShadow: var(--pf-v5-c-menu--m-scrollable__footer--BoxShadow);
  --pf-v5-c-menu__footer--after--BorderTopWidth: var(--pf-v5-c-menu--m-scrollable__footer--after--BorderTopWidth);
}

.pf-v5-c-menu.pf-m-scrollable .pf-v5-c-menu__content {
  overflow-y: auto;
}

.pf-v5-c-menu.pf-m-nav,
.pf-v5-c-menu.pf-m-nav .pf-v5-c-menu {
  --pf-v5-c-menu--BackgroundColor: var(--pf-v5-c-menu--m-nav--BackgroundColor);
  --pf-v5-c-menu__list--PaddingTop: var(--pf-v5-c-menu--m-nav__list--PaddingTop);
  --pf-v5-c-menu__list--PaddingBottom: var(--pf-v5-c-menu--m-nav__list--PaddingBottom);
  --pf-v5-c-menu__list-item--hover--BackgroundColor: var(--pf-v5-c-menu--m-nav__list-item--hover--BackgroundColor);
  --pf-v5-c-menu__list-item--focus-within--BackgroundColor: var(--pf-v5-c-menu--m-nav__list-item--focus-within--BackgroundColor);
  --pf-v5-c-menu__list-item--active--BackgroundColor: var(--pf-v5-c-menu--m-nav__list-item--active--BackgroundColor);
  --pf-v5-c-menu__item--Color: var(--pf-v5-c-menu--m-nav__item--Color);
  --pf-v5-c-menu__item--FontSize: var(--pf-v5-c-menu--m-nav__item--FontSize);
  --pf-v5-c-menu__item--OutlineOffset: var(--pf-v5-c-menu--m-nav__item--OutlineOffset);
  --pf-v5-c-menu__item--PaddingRight: var(--pf-v5-c-menu--m-nav__item--PaddingRight);
  --pf-v5-c-menu__item--PaddingLeft: var(--pf-v5-c-menu--m-nav__item--PaddingLeft);
  --pf-v5-c-menu__item-description--Color: var(--pf-v5-c-menu--m-nav__item-description--Color);
  box-shadow: var(--pf-v5-c-menu--m-nav--BoxShadow);
}

.pf-v5-c-menu.pf-m-nav .pf-v5-c-menu__item,
.pf-v5-c-menu.pf-m-nav .pf-v5-c-menu .pf-v5-c-menu__item {
  position: relative;
  outline-offset: var(--pf-v5-c-nav__item--m-flyout--c-menu__item--OutlineOffset);
}

.pf-v5-c-menu.pf-m-nav .pf-v5-c-menu__item::before,
.pf-v5-c-menu.pf-m-nav .pf-v5-c-menu .pf-v5-c-menu__item::before {
  position: absolute;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  content: "";
  border-block-end: var(--pf-v5-c-menu--m-nav__item--before--BorderBottomWidth) solid var(--pf-v5-c-menu--m-nav__item--before--BorderBottomColor);
}

.pf-v5-c-menu.pf-m-nav .pf-v5-c-menu__item:hover::after,
.pf-v5-c-menu.pf-m-nav .pf-v5-c-menu .pf-v5-c-menu__item:hover::after {
  position: absolute;
  inset-block-start: var(--pf-v5-c-menu--m-nav__item--hover--after--Top);
  inset-block-end: 0;
  inset-inline-start: 0;
  content: "";
  border-inline-start: var(--pf-v5-c-menu--m-nav__item--hover--after--BorderLeftWidth) solid var(--pf-v5-c-menu--m-nav__item--hover--after--BorderLeftColor);
}

.pf-v5-c-menu.pf-m-nav .pf-v5-c-menu {
  width: 100%;
}

.pf-v5-c-menu.pf-m-flyout.pf-m-nav,
.pf-v5-c-menu.pf-m-flyout.pf-m-nav .pf-v5-c-menu {
  inset-block-start: var(--pf-v5-c-menu--m-nav--c-menu--Top);
  inset-inline-start: var(--pf-v5-c-menu--m-nav--c-menu--Left);
}

.pf-v5-c-menu.pf-m-flyout.pf-m-nav.pf-m-top,
.pf-v5-c-menu.pf-m-flyout.pf-m-nav .pf-v5-c-menu.pf-m-top {
  --pf-v5-c-menu--m-nav--c-menu--Top: auto;
  inset-block-end: var(--pf-v5-c-menu--m-nav--c-menu--m-top--Bottom);
}

.pf-v5-c-menu.pf-m-flyout.pf-m-nav.pf-m-left,
.pf-v5-c-menu.pf-m-flyout.pf-m-nav .pf-v5-c-menu.pf-m-left {
  --pf-v5-c-menu--m-nav--c-menu--Left: auto;
  inset-inline-end: var(--pf-v5-c-menu--m-nav--c-menu--m-left--Right);
}

.pf-v5-c-menu.pf-m-flyout.pf-m-nav .pf-v5-c-menu__list-item:first-child,
.pf-v5-c-menu.pf-m-flyout.pf-m-nav .pf-v5-c-menu .pf-v5-c-menu__list-item:first-child {
  --pf-v5-c-menu--m-nav__item--hover--after--Top: var(--pf-v5-c-menu--m-nav__item__list-item--first-child__item--hover--after--Top);
}

.pf-v5-c-menu.pf-m-flyout.pf-m-nav .pf-v5-c-menu__list-item:first-child .pf-v5-c-menu,
.pf-v5-c-menu.pf-m-flyout.pf-m-nav .pf-v5-c-menu .pf-v5-c-menu__list-item:first-child .pf-v5-c-menu {
  --pf-v5-c-menu--m-nav--c-menu--Top: var(--pf-v5-c-menu--m-nav__list-item--first-child--c-menu--Top);
}

.pf-v5-c-menu__breadcrumb {
  display: flex;
  align-items: center;
  min-height: var(--pf-v5-c-menu__breadcrumb--MinHeight);
  padding-block-start: var(--pf-v5-c-menu__breadcrumb--PaddingTop);
  padding-block-end: var(--pf-v5-c-menu__breadcrumb--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-menu__breadcrumb--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-menu__breadcrumb--PaddingRight);
}

.pf-v5-c-menu__breadcrumb .pf-v5-c-breadcrumb {
  --pf-v5-c-breadcrumb__item--FontSize: var(--pf-v5-c-menu__breadcrumb--c-breadcrumb__item--FontSize);
  --pf-v5-c-breadcrumb__heading--FontSize: var(--pf-v5-c-menu__breadcrumb--c-breadcrumb__heading--FontSize);
}

.pf-v5-c-menu__content {
  height: var(--pf-v5-c-menu__content--Height);
  max-height: var(--pf-v5-c-menu__content--MaxHeight);
}

.pf-v5-c-menu__content .pf-v5-c-menu__content {
  --pf-v5-c-menu__content--Height: auto;
}

.pf-v5-c-menu__header {
  --pf-v5-c-menu__item--PaddingTop: var(--pf-v5-c-menu__header--c-menu__item--PaddingTop);
  --pf-v5-c-menu__item--PaddingRight: var(--pf-v5-c-menu__header--c-menu__item--PaddingRight);
  --pf-v5-c-menu__item--PaddingBottom: var(--pf-v5-c-menu__header--c-menu__item--PaddingBottom);
  --pf-v5-c-menu__item--PaddingLeft: var(--pf-v5-c-menu__header--c-menu__item--PaddingLeft);
  padding-block-start: var(--pf-v5-c-menu__header--PaddingTop);
  padding-block-end: var(--pf-v5-c-menu__header--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-menu__header--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-menu__header--PaddingRight);
}

.pf-v5-c-menu__header>.pf-v5-c-menu__item {
  --pf-v5-c-menu__item--BackgroundColor: var(--pf-v5-c-menu__header--c-menu__item--BackgroundColor);
  margin-block-start: var(--pf-v5-c-menu__header--c-menu__item--MarginTop);
  margin-block-end: var(--pf-v5-c-menu__header--c-menu__item--MarginBottom);
  margin-inline-start: var(--pf-v5-c-menu__header--c-menu__item--MarginLeft);
  margin-inline-end: var(--pf-v5-c-menu__header--c-menu__item--MarginRight);
}

.pf-v5-c-menu__header>.pf-v5-c-menu__item:hover {
  --pf-v5-c-menu__item--BackgroundColor: var(--pf-v5-c-menu__header--c-menu__item--hover--BackgroundColor);
}

.pf-v5-c-menu__header>.pf-v5-c-menu__item:focus {
  --pf-v5-c-menu__item--BackgroundColor: var(--pf-v5-c-menu__header--c-menu__item--focus--BackgroundColor);
}

.pf-v5-c-menu__header+.pf-v5-c-menu__search {
  --pf-v5-c-menu__search--PaddingTop: var(--pf-v5-c-menu__header__search--PaddingTop);
}

.pf-v5-c-menu__search {
  padding-block-start: var(--pf-v5-c-menu__search--PaddingTop);
  padding-block-end: var(--pf-v5-c-menu__search--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-menu__search--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-menu__search--PaddingRight);
}

.pf-v5-c-menu__list {
  --pf-v5-hidden-visible--visible--Display: var(--pf-v5-c-menu__list--Display);
  padding-block-start: var(--pf-v5-c-menu__list--PaddingTop);
  padding-block-end: var(--pf-v5-c-menu__list--PaddingBottom);
}

.pf-v5-c-menu__list>.pf-v5-c-divider {
  margin-block-start: var(--pf-v5-c-menu__list--c-divider--MarginTop);
  margin-block-end: var(--pf-v5-c-menu__list--c-divider--MarginBottom);
}

.pf-v5-c-menu__list-item {
  --pf-v5-hidden-visible--visible--Display: var(--pf-v5-c-menu__list-item--Display);
  position: relative;
  color: var(--pf-v5-c-menu__list-item--Color);
  background-color: var(--pf-v5-c-menu__list-item--BackgroundColor);
}

.pf-v5-c-menu__list-item:hover {
  --pf-v5-c-menu__list-item--BackgroundColor: var(--pf-v5-c-menu__list-item--hover--BackgroundColor);
  --pf-v5-c-menu__list-item--Color: var(--pf-v5-c-menu__list-item--hover--Color, inherit);
}

.pf-v5-c-menu__list-item:focus-within,
.pf-v5-c-menu__list-item.pf-m-focus {
  --pf-v5-c-menu__list-item--BackgroundColor: var(--pf-v5-c-menu__list-item--focus-within--BackgroundColor);
  --pf-v5-c-menu__list-item--Color: var(--pf-v5-c-menu__list-item--focus-within--Color, inherit);
}

.pf-v5-c-menu__list-item.pf-m-disabled,
.pf-v5-c-menu__list-item.pf-m-aria-disabled {
  --pf-v5-c-menu__list-item--hover--BackgroundColor: transparent;
  --pf-v5-c-menu__item--Color: var(--pf-v5-c-menu__list-item--m-disabled__item--Color);
  --pf-v5-c-menu__item-toggle-icon: var(--pf-v5-c-menu__list-item--m-disabled__item-toggle-icon--Color);
}

.pf-v5-c-menu__list-item.pf-m-disabled {
  --pf-v5-c-menu__list-item--focus-within--BackgroundColor: transparent;
  pointer-events: none;
}

.pf-v5-c-menu__list-item.pf-m-aria-disabled .pf-v5-c-menu__item {
  cursor: default;
}

.pf-v5-c-menu__list-item.pf-m-load {
  --pf-v5-c-menu__list-item--hover--BackgroundColor: transparent;
  --pf-v5-c-menu__list-item--focus-within--BackgroundColor: transparent;
  --pf-v5-c-menu__item--Color: var(--pf-v5-c-menu__list-item--m-load__item--Color);
}

.pf-v5-c-menu__list-item.pf-m-loading {
  --pf-v5-c-menu__list-item--hover--BackgroundColor: transparent;
  --pf-v5-c-menu__list-item--focus-within--BackgroundColor: transparent;
  justify-content: center;
  padding-block-start: var(--pf-v5-c-menu__list-item--m-loading--PaddingTop);
  overflow: hidden;
}

.pf-v5-c-menu__list-item.pf-m-danger {
  --pf-v5-c-menu__item--Color: var(--pf-v5-c-menu__list-item--m-danger__item--Color, inherit);
}

.pf-v5-c-menu__item {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  min-width: 0;
  padding-block-start: var(--pf-v5-c-menu__item--PaddingTop);
  padding-block-end: var(--pf-v5-c-menu__item--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-menu__item--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-menu__item--PaddingRight);
  font-size: var(--pf-v5-c-menu__item--FontSize);
  font-weight: var(--pf-v5-c-menu__item--FontWeight);
  line-height: var(--pf-v5-c-menu__item--LineHeight);
  color: var(--pf-v5-c-menu__item--Color);
  text-align: start;
  background-color: var(--pf-v5-c-menu__item--BackgroundColor);
  border: none;
}

.pf-v5-c-menu__item:hover {
  text-decoration: none;
}

.pf-v5-c-menu__item:hover,
.pf-v5-c-menu__item:focus {
  --pf-v5-c-menu__item-external-icon--Opacity: 1;
}

.pf-v5-c-menu__item.pf-m-selected .pf-v5-c-menu__item-select-icon {
  opacity: 1;
}

label.pf-v5-c-menu__item:where(:not([disabled], .pf-m-disabled, .pf-m-aria-disabled)) {
  cursor: pointer;
}

.pf-v5-c-menu__item-main {
  display: flex;
  align-items: center;
  width: 100%;
}

.pf-v5-c-menu__item-external-icon {
  margin-inline-start: var(--pf-v5-c-menu__item-external-icon--MarginLeft);
  font-size: var(--pf-v5-c-menu__item-external-icon--FontSize);
  color: var(--pf-v5-c-menu__item-external-icon--Color);
  opacity: var(--pf-v5-c-menu__item-external-icon--Opacity);
}

.pf-v5-c-menu__item-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
}

.pf-v5-c-menu__group {
  --pf-v5-hidden-visible--visible--Display: var(--pf-v5-c-menu__group--Display);
}

.pf-v5-c-menu__group-title {
  padding-block-start: var(--pf-v5-c-menu__group-title--PaddingTop);
  padding-inline-start: var(--pf-v5-c-menu__group-title--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-menu__group-title--PaddingRight);
  font-size: var(--pf-v5-c-menu__group-title--FontSize);
  font-weight: var(--pf-v5-c-menu__group-title--FontWeight);
  color: var(--pf-v5-c-menu__group-title--Color);
}

.pf-v5-c-menu__item-description {
  font-size: var(--pf-v5-c-menu__item-description--FontSize);
  color: var(--pf-v5-c-menu__item-description--Color);
  word-break: break-word;
}

.pf-v5-c-menu__item-icon {
  margin-inline-end: var(--pf-v5-c-menu__item-icon--MarginRight);
}

.pf-v5-c-menu__item-check {
  display: flex;
  align-items: center;
  margin-inline-end: var(--pf-v5-c-menu__item-check--MarginRight);
}

.pf-v5-c-menu__item-check .pf-v5-c-check {
  --pf-v5-c-check__input--TranslateY: none;
}

.pf-v5-c-menu__item-toggle-icon {
  padding-inline-start: var(--pf-v5-c-menu__item-toggle-icon--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-menu__item-toggle-icon--PaddingRight);
  color: var(--pf-v5-c-menu__item-toggle-icon, inherit);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-menu__item-toggle-icon {
  scale: -1 1;
}

.pf-v5-c-menu__item-text+.pf-v5-c-menu__item-toggle-icon {
  margin-inline-start: var(--pf-v5-c-menu__item-text--item-toggle-icon--MarginLeft);
}

.pf-v5-c-menu__item-toggle-icon+.pf-v5-c-menu__item-text {
  margin-inline-start: var(--pf-v5-c-menu__item-toggle-icon--item-text--MarginLeft);
}

.pf-v5-c-menu__item-select-icon {
  margin-inline-start: var(--pf-v5-c-menu__item-select-icon--MarginLeft);
  font-size: var(--pf-v5-c-menu__item-select-icon--FontSize);
  color: var(--pf-v5-c-menu__item-select-icon--Color);
  opacity: 0;
}

.pf-v5-c-menu__item-action {
  display: flex;
  padding-block-start: var(--pf-v5-c-menu__item-action--PaddingTop);
  padding-block-end: var(--pf-v5-c-menu__item-action--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-menu__item-action--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-menu__item-action--PaddingRight);
  color: var(--pf-v5-c-menu__item-action--Color);
  background-color: var(--pf-v5-c-menu__item-action--BackgroundColor);
  border: none;
}

.pf-v5-c-menu__item-action.pf-m-favorite .pf-v5-c-menu__item-action-icon {
  font-size: var(--pf-v5-c-menu__item-action--m-favorite__icon--FontSize);
}

.pf-v5-c-menu__item-action.pf-m-favorited {
  --pf-v5-c-menu__item-action--Color: var(--pf-v5-c-menu__item-action--m-favorited--Color);
  --pf-v5-c-menu__item-action--hover--Color: var(--pf-v5-c-menu__item-action--m-favorited--hover--Color);
}

.pf-v5-c-menu__item-action:hover,
.pf-v5-c-menu__item-action:focus {
  --pf-v5-c-menu__item-action--Color: var(--pf-v5-c-menu__item-action--hover--Color);
}

.pf-v5-c-menu__item-action:disabled,
.pf-v5-c-menu__item-action.pf-m-disabled,
.pf-v5-c-menu__item-action.pf-m-aria-disabled {
  --pf-v5-c-menu__item-action--Color: var(--pf-v5-c-menu__item-action--disabled--Color);
}

.pf-v5-c-menu__item-action-icon {
  display: flex;
  align-items: center;
  height: var(--pf-v5-c-menu__item-action-icon--Height);
}

.pf-v5-c-menu__footer {
  position: relative;
  padding-block-start: var(--pf-v5-c-menu__footer--PaddingTop);
  padding-block-end: var(--pf-v5-c-menu__footer--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-menu__footer--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-menu__footer--PaddingRight);
  box-shadow: var(--pf-v5-c-menu__footer--BoxShadow);
}

.pf-v5-c-menu__footer::after {
  position: absolute;
  inset: 0;
  pointer-events: none;
  content: "";
  border-block-start: var(--pf-v5-c-menu__footer--after--BorderTopWidth) solid var(--pf-v5-c-menu__footer--after--BorderTopColor);
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-menu {
  --pf-v5-c-menu--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-menu__list-item--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-menu__list-item--focus-within--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
}

.pf-v5-c-menu-toggle {
  --pf-v5-c-menu-toggle--BorderRadius: 0;
  --pf-v5-c-menu-toggle--PaddingTop: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-menu-toggle--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle--PaddingBottom: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-menu-toggle--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-menu-toggle--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-menu-toggle--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-menu-toggle--BackgroundColor: transparent;
  --pf-v5-c-menu-toggle--before--BorderTopWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu-toggle--before--BorderRightWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu-toggle--before--BorderBottomWidth: 0;
  --pf-v5-c-menu-toggle--before--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu-toggle--before--BorderTopColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-menu-toggle--before--BorderRightColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-menu-toggle--before--BorderBottomColor: transparent;
  --pf-v5-c-menu-toggle--before--BorderLeftColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-menu-toggle--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu-toggle--after--BorderBottomColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-menu-toggle--hover--BackgroundColor: transparent;
  --pf-v5-c-menu-toggle--hover--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu-toggle--hover--after--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-menu-toggle--focus--BackgroundColor: transparent;
  --pf-v5-c-menu-toggle--focus--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-menu-toggle--focus--after--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-menu-toggle--active--BackgroundColor: transparent;
  --pf-v5-c-menu-toggle--active--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-menu-toggle--active--after--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-menu-toggle--m-expanded--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-menu-toggle--m-expanded--BackgroundColor: transparent;
  --pf-v5-c-menu-toggle--m-expanded--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-menu-toggle--m-expanded--after--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-menu-toggle--disabled--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-menu-toggle--disabled--BackgroundColor: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-menu-toggle--m-primary--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-menu-toggle--m-primary--BorderRadius: var(--pf-v5-global--BorderRadius--sm);
  --pf-v5-c-menu-toggle--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-menu-toggle--m-primary--hover--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-menu-toggle--m-primary--focus--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-menu-toggle--m-primary--active--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-menu-toggle--m-primary--m-expanded--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-menu-toggle--m-primary--m-expanded--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-menu-toggle--m-secondary--Color: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-menu-toggle--m-secondary--BorderRadius: var(--pf-v5-global--BorderRadius--sm);
  --pf-v5-c-menu-toggle--m-secondary--BackgroundColor: transparent;
  --pf-v5-c-menu-toggle--m-secondary--hover--BackgroundColor: transparent;
  --pf-v5-c-menu-toggle--m-secondary--focus--BackgroundColor: transparent;
  --pf-v5-c-menu-toggle--m-secondary--active--BackgroundColor: transparent;
  --pf-v5-c-menu-toggle--m-secondary--before--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu-toggle--m-secondary--hover--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-menu-toggle--m-secondary--focus--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-menu-toggle--m-secondary--active--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-menu-toggle--m-secondary--before--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-menu-toggle--m-secondary--hover--before--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-menu-toggle--m-secondary--focus--before--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-menu-toggle--m-secondary--active--before--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-menu-toggle--m-secondary--m-expanded--BackgroundColor: transparent;
  --pf-v5-c-menu-toggle--m-secondary--m-expanded--Color: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-menu-toggle--m-expanded__toggle--m-secondary--before--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-menu-toggle--m-expanded__toggle--m-secondary--before--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-menu-toggle--m-plain--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-menu-toggle--m-plain--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu-toggle--m-plain--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu-toggle--m-plain--hover--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-menu-toggle--m-plain--focus--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-menu-toggle--m-plain--active--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-menu-toggle--m-plain--disabled--Color: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-menu-toggle--m-plain--m-expanded--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-menu-toggle__icon--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle__count--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle__controls--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu-toggle__controls--MarginLeft: auto;
  --pf-v5-c-menu-toggle__controls--MarginRight: 0;
  --pf-v5-c-menu-toggle__toggle-icon--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle--m-plain__toggle-icon--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-menu-toggle--m-plain--hover__toggle-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-menu-toggle--m-plain--active__toggle-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-menu-toggle--m-plain--focus__toggle-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-menu-toggle--m-plain--m-expanded__toggle-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-menu-toggle--m-full-height--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-menu-toggle--m-full-height--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-menu-toggle--m-full-height__toggle--before--BorderTopWidth: 0;
  --pf-v5-c-menu-toggle--m-full-height__toggle--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu-toggle--m-full-height--m-expanded--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-menu-toggle--m-full-height--hover--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-menu-toggle--m-full-height--focus--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-menu-toggle--m-full-height--active--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-menu-toggle--m-split-button--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-menu-toggle--m-split-button--child--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-menu-toggle--m-split-button--child--disabled--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-menu-toggle--m-split-button--child--disabled--BackgroundColor: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-menu-toggle--m-split-button--first-child--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle--m-split-button--last-child--PaddingLeft: 0;
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderLeftColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--after--Left: 0;
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--after--BorderBottomColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--hover--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--hover--after--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--focus--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--focus--after--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--active--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--active--after--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderRadius: var(--pf-v5-global--BorderRadius--sm);
  --pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--BackgroundColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--hover--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--focus--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--active--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--BorderLeftColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--m-expanded--child--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-menu-toggle--m-split-button--m-action--m-secondary--child--BorderLeftColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-menu-toggle__button--BackgroundColor: transparent;
  --pf-v5-c-menu-toggle__button--AlignSelf: baseline;
  --pf-v5-c-menu-toggle__button--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle__button--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle__button__controls--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle__button__controls--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle__button--m-text--PaddingInlineStart: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-menu-toggle--m-typeahead__controls--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle--m-typeahead__controls--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle--m-typeahead--c-text-input-group__utilities--c-button--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-menu-toggle--m-typeahead__button--AlignSelf: center;
  --pf-v5-c-menu-toggle__status-icon--MarginInlineEnd: var(--pf-v5-global--spacer--md);
  --pf-v5-c-menu-toggle--m-success--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-menu-toggle--m-success--after--BorderBottomColor: var(--pf-v5-global--success-color--100);
  --pf-v5-c-menu-toggle--m-success__status-icon--Color: var(--pf-v5-global--success-color--100);
  --pf-v5-c-menu-toggle--m-warning--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-menu-toggle--m-warning--after--BorderBottomColor: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-menu-toggle--m-warning__status-icon--Color: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-menu-toggle--m-danger--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-menu-toggle--m-danger--after--BorderBottomColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-menu-toggle--m-danger__status-icon--Color: var(--pf-v5-global--danger-color--100);
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  padding-block-start: var(--pf-v5-c-menu-toggle--PaddingTop);
  padding-block-end: var(--pf-v5-c-menu-toggle--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-menu-toggle--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-menu-toggle--PaddingRight);
  font-size: var(--pf-v5-c-menu-toggle--FontSize);
  line-height: var(--pf-v5-c-menu-toggle--LineHeight);
  color: var(--pf-v5-c-menu-toggle--Color);
  cursor: pointer;
  background-color: var(--pf-v5-c-menu-toggle--BackgroundColor);
  border: 0;
  border-radius: var(--pf-v5-c-menu-toggle--BorderRadius);
}

.pf-v5-c-menu-toggle::before,
.pf-v5-c-menu-toggle::after {
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  pointer-events: none;
  content: "";
}

.pf-v5-c-menu-toggle::before {
  border-style: solid;
  border-block-start-color: var(--pf-v5-c-menu-toggle--before--BorderTopColor);
  border-block-start-width: var(--pf-v5-c-menu-toggle--before--BorderTopWidth);
  border-block-end-color: var(--pf-v5-c-menu-toggle--before--BorderBottomColor);
  border-block-end-width: var(--pf-v5-c-menu-toggle--before--BorderBottomWidth);
  border-inline-start-color: var(--pf-v5-c-menu-toggle--before--BorderLeftColor);
  border-inline-start-width: var(--pf-v5-c-menu-toggle--before--BorderLeftWidth);
  border-inline-end-color: var(--pf-v5-c-menu-toggle--before--BorderRightColor);
  border-inline-end-width: var(--pf-v5-c-menu-toggle--before--BorderRightWidth);
}

.pf-v5-c-menu-toggle::after {
  border-block-end: var(--pf-v5-c-menu-toggle--after--BorderBottomWidth) solid var(--pf-v5-c-menu-toggle--after--BorderBottomColor);
}

.pf-v5-c-menu-toggle.pf-m-primary {
  --pf-v5-c-menu-toggle--BorderRadius: var(--pf-v5-c-menu-toggle--m-primary--BorderRadius);
  --pf-v5-c-menu-toggle--Color: var(--pf-v5-c-menu-toggle--m-primary--Color);
  --pf-v5-c-menu-toggle--BackgroundColor: var(--pf-v5-c-menu-toggle--m-primary--BackgroundColor);
  --pf-v5-c-menu-toggle--hover--BackgroundColor: var(--pf-v5-c-menu-toggle--m-primary--hover--BackgroundColor);
  --pf-v5-c-menu-toggle--focus--BackgroundColor: var(--pf-v5-c-menu-toggle--m-primary--focus--BackgroundColor);
  --pf-v5-c-menu-toggle--active--BackgroundColor: var(--pf-v5-c-menu-toggle--m-primary--active--BackgroundColor);
  --pf-v5-c-menu-toggle--m-expanded--Color: var(--pf-v5-c-menu-toggle--m-primary--m-expanded--Color);
  --pf-v5-c-menu-toggle--m-expanded--BackgroundColor: var(--pf-v5-c-menu-toggle--m-primary--m-expanded--BackgroundColor);
}

.pf-v5-c-menu-toggle.pf-m-primary,
.pf-v5-c-menu-toggle.pf-m-primary::before {
  border-radius: var(--pf-v5-c-menu-toggle--m-primary--BorderRadius);
}

.pf-v5-c-menu-toggle.pf-m-secondary {
  --pf-v5-c-menu-toggle--Color: var(--pf-v5-c-menu-toggle--m-secondary--Color);
  --pf-v5-c-menu-toggle--BackgroundColor: var(--pf-v5-c-menu-toggle--m-secondary--BackgroundColor);
  --pf-v5-c-menu-toggle--hover--BackgroundColor: var(--pf-v5-c-menu-toggle--m-secondary--hover--BackgroundColor);
  --pf-v5-c-menu-toggle--focus--BackgroundColor: var(--pf-v5-c-menu-toggle--m-secondary--focus--BackgroundColor);
  --pf-v5-c-menu-toggle--active--BackgroundColor: var(--pf-v5-c-menu-toggle--m-secondary--active--BackgroundColor);
  --pf-v5-c-menu-toggle--m-expanded--BackgroundColor: var(--pf-v5-c-menu-toggle--m-secondary--m-expanded--BackgroundColor);
  --pf-v5-c-menu-toggle--m-expanded--Color: var(--pf-v5-c-menu-toggle--m-secondary--m-expanded--Color);
}

.pf-v5-c-menu-toggle.pf-m-secondary,
.pf-v5-c-menu-toggle.pf-m-secondary::before {
  border-radius: var(--pf-v5-c-menu-toggle--m-secondary--BorderRadius);
}

.pf-v5-c-menu-toggle.pf-m-secondary::before {
  border-color: var(--pf-v5-c-menu-toggle--m-secondary--before--BorderColor);
  border-width: var(--pf-v5-c-menu-toggle--m-secondary--before--BorderWidth);
}

.pf-v5-c-menu-toggle.pf-m-secondary::after {
  border: 0;
}

.pf-v5-c-menu-toggle.pf-m-secondary:hover {
  --pf-v5-c-menu-toggle--m-secondary--before--BorderColor: var(--pf-v5-c-menu-toggle--m-secondary--hover--before--BorderColor);
  --pf-v5-c-menu-toggle--m-secondary--before--BorderWidth: var(--pf-v5-c-menu-toggle--m-secondary--hover--before--BorderWidth);
}

.pf-v5-c-menu-toggle.pf-m-secondary:focus {
  --pf-v5-c-menu-toggle--m-secondary--before--BorderColor: var(--pf-v5-c-menu-toggle--m-secondary--focus--before--BorderColor);
  --pf-v5-c-menu-toggle--m-secondary--before--BorderWidth: var(--pf-v5-c-menu-toggle--m-secondary--focus--before--BorderWidth);
}

.pf-v5-c-menu-toggle.pf-m-secondary:active {
  --pf-v5-c-menu-toggle--m-secondary--before--BorderColor: var(--pf-v5-c-menu-toggle--m-secondary--active--before--BorderColor);
  --pf-v5-c-menu-toggle--m-secondary--before--BorderWidth: var(--pf-v5-c-menu-toggle--m-secondary--active--before--BorderWidth);
}

.pf-v5-c-menu-toggle.pf-m-secondary.pf-m-expanded {
  --pf-v5-c-menu-toggle--m-secondary--before--BorderColor: var(--pf-v5-c-menu-toggle--m-expanded__toggle--m-secondary--before--BorderColor);
  --pf-v5-c-menu-toggle--m-secondary--before--BorderWidth: var(--pf-v5-c-menu-toggle--m-expanded__toggle--m-secondary--before--BorderWidth);
}

.pf-v5-c-menu-toggle.pf-m-plain {
  --pf-v5-c-menu-toggle__toggle-icon--Color: var(--pf-v5-c-menu-toggle--m-plain__toggle-icon--Color);
}

.pf-v5-c-menu-toggle.pf-m-plain:not(.pf-m-text) {
  --pf-v5-c-menu-toggle--PaddingRight: var(--pf-v5-c-menu-toggle--m-plain--PaddingRight);
  --pf-v5-c-menu-toggle--PaddingLeft: var(--pf-v5-c-menu-toggle--m-plain--PaddingLeft);
  --pf-v5-c-menu-toggle--disabled--BackgroundColor: transparent;
  display: inline-block;
  color: var(--pf-v5-c-menu-toggle--m-plain--Color);
}

.pf-v5-c-menu-toggle.pf-m-full-height {
  --pf-v5-c-menu-toggle--PaddingRight: var(--pf-v5-c-menu-toggle--m-full-height--PaddingRight);
  --pf-v5-c-menu-toggle--PaddingLeft: var(--pf-v5-c-menu-toggle--m-full-height--PaddingLeft);
  --pf-v5-c-menu-toggle--before--BorderTopWidth: var(--pf-v5-c-menu-toggle--m-full-height__toggle--before--BorderTopWidth);
  --pf-v5-c-menu-toggle--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--m-full-height__toggle--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--m-expanded--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--m-full-height--m-expanded--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--hover--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--m-full-height--hover--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--focus--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--m-full-height--focus--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--active--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--m-full-height--active--after--BorderBottomWidth);
  align-items: center;
  height: 100%;
}

.pf-v5-c-menu-toggle:hover {
  --pf-v5-c-menu-toggle--BackgroundColor: var(--pf-v5-c-menu-toggle--hover--BackgroundColor);
  --pf-v5-c-menu-toggle--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--hover--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--after--BorderBottomColor: var(--pf-v5-c-menu-toggle--hover--after--BorderBottomColor);
  --pf-v5-c-menu-toggle--m-plain__toggle-icon--Color: var(--pf-v5-c-menu-toggle--m-plain--hover__toggle-icon--Color);
  --pf-v5-c-menu-toggle--m-plain--Color: var(--pf-v5-c-menu-toggle--m-plain--hover--Color);
}

.pf-v5-c-menu-toggle:focus,
.pf-v5-c-menu-toggle:focus-within {
  --pf-v5-c-menu-toggle--BackgroundColor: var(--pf-v5-c-menu-toggle--focus--BackgroundColor);
  --pf-v5-c-menu-toggle--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--focus--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--after--BorderBottomColor: var(--pf-v5-c-menu-toggle--focus--after--BorderBottomColor);
  --pf-v5-c-menu-toggle--m-plain__toggle-icon--Color: var(--pf-v5-c-menu-toggle--m-plain--focus__toggle-icon--Color);
  --pf-v5-c-menu-toggle--m-plain--Color: var(--pf-v5-c-menu-toggle--m-plain--focus--Color);
}

.pf-v5-c-menu-toggle:active {
  --pf-v5-c-menu-toggle--BackgroundColor: var(--pf-v5-c-menu-toggle--active--BackgroundColor);
  --pf-v5-c-menu-toggle--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--active--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--after--BorderBottomColor: var(--pf-v5-c-menu-toggle--active--after--BorderBottomColor);
  --pf-v5-c-menu-toggle--m-plain__toggle-icon--Color: var(--pf-v5-c-menu-toggle--m-plain--active__toggle-icon--Color);
  --pf-v5-c-menu-toggle--m-plain--Color: var(--pf-v5-c-menu-toggle--m-plain--active--Color);
}

.pf-v5-c-menu-toggle.pf-m-expanded {
  --pf-v5-c-menu-toggle--Color: var(--pf-v5-c-menu-toggle--m-expanded--Color);
  --pf-v5-c-menu-toggle--BackgroundColor: var(--pf-v5-c-menu-toggle--m-expanded--BackgroundColor);
  --pf-v5-c-menu-toggle--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--m-expanded--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--after--BorderBottomColor: var(--pf-v5-c-menu-toggle--m-expanded--after--BorderBottomColor);
  --pf-v5-c-menu-toggle--m-plain__toggle-icon--Color: var(--pf-v5-c-menu-toggle--m-plain--m-expanded__toggle-icon--Color);
  --pf-v5-c-menu-toggle--m-plain--Color: var(--pf-v5-c-menu-toggle--m-plain--m-expanded--Color);
}

.pf-v5-c-menu-toggle:disabled,
.pf-v5-c-menu-toggle.pf-m-disabled {
  --pf-v5-c-menu-toggle--Color: var(--pf-v5-c-menu-toggle--disabled--Color);
  --pf-v5-c-menu-toggle--BackgroundColor: var(--pf-v5-c-menu-toggle--disabled--BackgroundColor);
  --pf-v5-c-menu-toggle--m-plain--Color: var(--pf-v5-c-menu-toggle--m-plain--disabled--Color);
  pointer-events: none;
}

.pf-v5-c-menu-toggle.pf-m-primary::before,
.pf-v5-c-menu-toggle.pf-m-primary::after,
.pf-v5-c-menu-toggle.pf-m-plain::before,
.pf-v5-c-menu-toggle.pf-m-plain::after,
.pf-v5-c-menu-toggle:disabled::before,
.pf-v5-c-menu-toggle:disabled::after {
  border: 0;
}

.pf-v5-c-menu-toggle.pf-m-typeahead {
  --pf-v5-c-menu-toggle__button__controls--MarginRight: var(--pf-v5-c-menu-toggle--m-typeahead__controls--MarginRight);
  --pf-v5-c-menu-toggle__button__controls--MarginLeft: var(--pf-v5-c-menu-toggle--m-typeahead__controls--MarginLeft);
  --pf-v5-c-menu-toggle__button--AlignSelf: var(--pf-v5-c-menu-toggle--m-typeahead__button--AlignSelf);
  align-items: stretch;
  padding: 0;
}

.pf-v5-c-menu-toggle.pf-m-typeahead .pf-v5-c-text-input-group {
  --pf-v5-c-text-input-group__utilities--c-button--PaddingRight: var(--pf-v5-c-menu-toggle--m-typeahead--c-text-input-group__utilities--c-button--PaddingRight);
  --pf-v5-c-text-input-group__utilities--MarginRight: 0;
  flex: 1;
}

.pf-v5-c-menu-toggle.pf-m-split-button {
  padding: 0;
}

.pf-v5-c-menu-toggle.pf-m-split-button>* {
  position: relative;
  padding-block-start: var(--pf-v5-c-menu-toggle--PaddingTop);
  padding-block-end: var(--pf-v5-c-menu-toggle--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-menu-toggle--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-menu-toggle--PaddingRight);
}

.pf-v5-c-menu-toggle.pf-m-split-button>*:first-child {
  padding-inline-end: var(--pf-v5-c-menu-toggle--m-split-button--first-child--PaddingRight);
}

.pf-v5-c-menu-toggle.pf-m-split-button:where(:not(.pf-m-action))>:last-child {
  padding-inline-start: var(--pf-v5-c-menu-toggle--m-split-button--last-child--PaddingLeft);
}

.pf-v5-c-menu-toggle.pf-m-split-button>.pf-v5-c-check {
  --pf-v5-c-menu-toggle--PaddingRight: 0;
  --pf-v5-c-check__label--Color: currentcolor;
  --pf-v5-c-check__label--disabled--Color: currentcolor;
  align-items: center;
  align-self: stretch;
}

.pf-v5-c-menu-toggle.pf-m-split-button>.pf-v5-c-check .pf-v5-c-check__input {
  --pf-v5-c-check__input--TranslateY: 0;
  align-self: center;
}

.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action:where(:not(.pf-m-primary, .pf-m-secondary, .pf-m-disabled))>*::after {
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--after--Left);
  inset-inline-end: 0;
  pointer-events: none;
  content: "";
  border-block-end: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--after--BorderBottomWidth) solid var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--after--BorderBottomColor);
}

.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action:where(:not(.pf-m-primary, .pf-m-secondary, .pf-m-disabled))>*:hover {
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--hover--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--after--BorderBottomColor: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--hover--after--BorderBottomColor);
}

.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action:where(:not(.pf-m-primary, .pf-m-secondary, .pf-m-disabled))>*:focus {
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--focus--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--after--BorderBottomColor: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--focus--after--BorderBottomColor);
}

.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action:where(:not(.pf-m-primary, .pf-m-secondary, .pf-m-disabled))>*:active,
.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action:where(:not(.pf-m-primary, .pf-m-secondary, .pf-m-disabled))>*.pf-m-active {
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--active--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--after--BorderBottomColor: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--active--after--BorderBottomColor);
}

.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-primary {
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderLeftColor: var(--pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--BorderLeftColor);
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--after--BorderBottomWidth: 0;
}

.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-primary>:where(:not(.pf-m-disabled):not([disabled])) {
  background-color: var(--pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--BackgroundColor);
}

.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-primary>:where(:not(.pf-m-disabled):not([disabled])):hover {
  --pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--BackgroundColor: var(--pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--hover--BackgroundColor);
}

.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-primary>:where(:not(.pf-m-disabled):not([disabled])):focus {
  --pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--BackgroundColor: var(--pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--focus--BackgroundColor);
}

.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-primary>:where(:not(.pf-m-disabled):not([disabled])):active,
.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-primary>:where(:not(.pf-m-disabled):not([disabled])).pf-m-active {
  --pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--BackgroundColor: var(--pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--active--BackgroundColor);
}

.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-primary.pf-m-expanded {
  --pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--BackgroundColor: var(--pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--m-expanded--child--BackgroundColor);
}

.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-secondary {
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderLeftColor: var(--pf-v5-c-menu-toggle--m-split-button--m-action--m-secondary--child--BorderLeftColor);
}

.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-primary,
.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-secondary {
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--after--BorderBottomWidth: 0;
}

.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-primary>:first-child,
.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-secondary>:first-child {
  border-start-start-radius: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderRadius);
  border-end-start-radius: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderRadius);
}

.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-primary>:last-child,
.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action.pf-m-secondary>:last-child {
  border-start-end-radius: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderRadius);
  border-end-end-radius: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderRadius);
}

.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action>:not(:first-child) {
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--after--Left: calc(var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderLeftWidth) * -1);
  border-inline-start: var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderLeftWidth) solid var(--pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderLeftColor);
}

.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-action:not(.pf-m-expanded) {
  --pf-v5-c-menu-toggle--after--BorderBottomColor: transparent;
}

.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-disabled,
.pf-v5-c-menu-toggle.pf-m-split-button:disabled {
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderLeftColor: transparent;
}

.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-disabled::before,
.pf-v5-c-menu-toggle.pf-m-split-button.pf-m-disabled::after,
.pf-v5-c-menu-toggle.pf-m-split-button:disabled::before,
.pf-v5-c-menu-toggle.pf-m-split-button:disabled::after {
  content: none;
}

.pf-v5-c-menu-toggle.pf-m-split-button>.pf-m-disabled,
.pf-v5-c-menu-toggle.pf-m-split-button>:disabled {
  --pf-v5-c-menu-toggle--m-split-button--m-action--child--BorderLeftColor: transparent;
  color: var(--pf-v5-c-menu-toggle--m-split-button--child--disabled--Color);
  background-color: var(--pf-v5-c-menu-toggle--m-split-button--child--disabled--BackgroundColor);
}

.pf-v5-c-menu-toggle.pf-m-typeahead,
.pf-v5-c-menu-toggle.pf-m-split-button {
  --pf-v5-c-menu-toggle__toggle-icon--MarginRight: 0;
}

.pf-v5-c-menu-toggle.pf-m-full-width {
  width: 100%;
}

.pf-v5-c-menu-toggle.pf-m-success {
  --pf-v5-c-menu-toggle--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--m-success--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--after--BorderBottomColor: var(--pf-v5-c-menu-toggle--m-success--after--BorderBottomColor);
  --pf-v5-c-menu-toggle__status-icon--Color: var(--pf-v5-c-menu-toggle--m-success__status-icon--Color);
}

.pf-v5-c-menu-toggle.pf-m-warning {
  --pf-v5-c-menu-toggle--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--m-warning--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--after--BorderBottomColor: var(--pf-v5-c-menu-toggle--m-warning--after--BorderBottomColor);
  --pf-v5-c-menu-toggle__status-icon--Color: var(--pf-v5-c-menu-toggle--m-warning__status-icon--Color);
}

.pf-v5-c-menu-toggle.pf-m-danger {
  --pf-v5-c-menu-toggle--after--BorderBottomWidth: var(--pf-v5-c-menu-toggle--m-danger--after--BorderBottomWidth);
  --pf-v5-c-menu-toggle--after--BorderBottomColor: var(--pf-v5-c-menu-toggle--m-danger--after--BorderBottomColor);
  --pf-v5-c-menu-toggle__status-icon--Color: var(--pf-v5-c-menu-toggle--m-danger__status-icon--Color);
}

.pf-v5-c-menu-toggle__button {
  --pf-v5-c-menu-toggle__controls--PaddingLeft: 0;
  --pf-v5-c-menu-toggle__controls--MarginRight: var(--pf-v5-c-menu-toggle__button__controls--MarginRight);
  --pf-v5-c-menu-toggle__controls--MarginLeft: var(--pf-v5-c-menu-toggle__button__controls--MarginLeft);
  align-self: var(--pf-v5-c-menu-toggle__button--AlignSelf);
  padding-inline-start: var(--pf-v5-c-menu-toggle__button--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-menu-toggle__button--PaddingRight);
  color: inherit;
  background-color: var(--pf-v5-c-menu-toggle__button--BackgroundColor);
  border: 0;
}

.pf-v5-c-menu-toggle__button.pf-m-text {
  --pf-v5-c-menu-toggle--m-split-button--last-child--PaddingLeft: var(--pf-v5-c-menu-toggle__button--m-text--PaddingInlineStart);
  display: inline-flex;
  align-items: baseline;
  padding-inline-start: var(--pf-v5-c-menu-toggle__button--m-text--PaddingInlineStart);
}

.pf-v5-c-menu-toggle__icon {
  flex-shrink: 0;
  align-self: center;
  margin-inline-end: var(--pf-v5-c-menu-toggle__icon--MarginRight);
  line-height: 1;
}

.pf-v5-c-menu-toggle__text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
}

.pf-v5-c-menu-toggle__count {
  display: flex;
  flex-wrap: nowrap;
  margin-inline-start: var(--pf-v5-c-menu-toggle__count--MarginLeft);
}

.pf-v5-c-menu-toggle__controls {
  display: flex;
  padding-inline-start: var(--pf-v5-c-menu-toggle__controls--PaddingLeft);
  margin-inline-start: var(--pf-v5-c-menu-toggle__controls--MarginLeft);
  margin-inline-end: var(--pf-v5-c-menu-toggle__controls--MarginRight);
}

.pf-v5-c-menu-toggle__toggle-icon {
  margin-inline-end: var(--pf-v5-c-menu-toggle__toggle-icon--MarginRight);
  color: var(--pf-v5-c-menu-toggle__toggle-icon--Color, inherit);
}

.pf-v5-c-menu-toggle__status-icon {
  margin-inline-end: var(--pf-v5-c-menu-toggle__status-icon--MarginInlineEnd);
  color: var(--pf-v5-c-menu-toggle__status-icon--Color, inherit);
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-menu-toggle {
  --pf-v5-c-menu-toggle--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-menu-toggle--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-menu-toggle--active--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-menu-toggle--focus--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-menu-toggle--m-expanded--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-menu-toggle--before--BorderTopColor: transparent;
  --pf-v5-c-menu-toggle--before--BorderRightColor: transparent;
  --pf-v5-c-menu-toggle--before--BorderBottomColor: transparent;
  --pf-v5-c-menu-toggle--before--BorderLeftColor: transparent;
  --pf-v5-c-menu-toggle--after--BorderBottomColor: var(--pf-v5-global--BorderColor--400);
  --pf-v5-c-menu-toggle--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
  --pf-v5-c-menu-toggle--disabled--BackgroundColor: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-menu-toggle--disabled--Color: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-menu-toggle--m-split-button--m-action--m-primary--child--BackgroundColor: var(--pf-v5-global--primary-color--300);
  --pf-v5-c-menu-toggle--m-split-button--child--disabled--Color: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-menu-toggle--m-split-button--child--disabled--BackgroundColor: var(--pf-v5-global--disabled-color--200);
}

:where(.pf-v5-theme-dark) .pf-v5-c-menu-toggle.pf-m-plain,
:where(.pf-v5-theme-dark) .pf-v5-c-menu-toggle.pf-m-full-height {
  background: transparent;
}

.pf-v5-c-modal-box {
  --pf-v5-c-modal-box--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-modal-box--BoxShadow: var(--pf-v5-global--BoxShadow--xl);
  --pf-v5-c-modal-box--ZIndex: var(--pf-v5-global--ZIndex--xl);
  --pf-v5-c-modal-box--Width: 100%;
  --pf-v5-c-modal-box--MaxWidth: calc(100% - var(--pf-v5-global--spacer--xl));
  --pf-v5-c-modal-box--m-sm--sm--MaxWidth: 35rem;
  --pf-v5-c-modal-box--m-md--Width: 52.5rem;
  --pf-v5-c-modal-box--m-lg--lg--MaxWidth: 70rem;
  --pf-v5-c-modal-box--MaxHeight: calc(100% - var(--pf-v5-global--spacer--2xl));
  --pf-v5-c-modal-box--m-align-top--spacer: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-modal-box--m-align-top--xl--spacer: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-modal-box--m-align-top--MarginTop: var(--pf-v5-c-modal-box--m-align-top--spacer);
  --pf-v5-c-modal-box--m-align-top--MaxHeight: calc(100% - min(var(--pf-v5-c-modal-box--m-align-top--spacer), var(--pf-v5-global--spacer--2xl)) - var(--pf-v5-c-modal-box--m-align-top--spacer));
  --pf-v5-c-modal-box--m-align-top--MaxWidth: calc(100% - min(var(--pf-v5-c-modal-box--m-align-top--spacer) * 2, var(--pf-v5-global--spacer--xl)));
  --pf-v5-c-modal-box--m-danger__title-icon--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-modal-box--m-warning__title-icon--Color: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-modal-box--m-success__title-icon--Color: var(--pf-v5-global--success-color--100);
  --pf-v5-c-modal-box--m-info__title-icon--Color: var(--pf-v5-global--info-color--100);
  --pf-v5-c-modal-box--m-custom__title-icon--Color: var(--pf-v5-global--custom-color--200);
  --pf-v5-c-modal-box__header--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-modal-box__header--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-modal-box__header--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-modal-box__header--last-child--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-modal-box__title--LineHeight: var(--pf-v5-global--LineHeight--sm);
  --pf-v5-c-modal-box__title--FontFamily: var(--pf-v5-global--FontFamily--heading);
  --pf-v5-c-modal-box__title--FontSize: var(--pf-v5-global--FontSize--2xl);
  --pf-v5-c-modal-box__title-icon--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-modal-box__title-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-modal-box__description--PaddingTop: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-modal-box__body--MinHeight: calc(var(--pf-v5-global--FontSize--md) * var(--pf-v5-global--LineHeight--md));
  --pf-v5-c-modal-box__body--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-modal-box__body--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-modal-box__body--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-modal-box__body--last-child--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-modal-box__header--body--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-modal-box__close--Top: calc(var(--pf-v5-global--spacer--lg));
  --pf-v5-c-modal-box__close--Right: var(--pf-v5-global--spacer--md);
  --pf-v5-c-modal-box__close--sibling--MarginRight: calc(var(--pf-v5-global--spacer--xl) + var(--pf-v5-global--spacer--sm));
  --pf-v5-c-modal-box__footer--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-modal-box__footer--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-modal-box__footer--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-modal-box__footer--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-modal-box__footer--c-button--MarginRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-modal-box__footer--c-button--sm--MarginRight: calc(var(--pf-v5-c-modal-box__footer--c-button--MarginRight) / 2);
  position: relative;
  z-index: var(--pf-v5-c-modal-box--ZIndex);
  display: flex;
  flex-direction: column;
  width: var(--pf-v5-c-modal-box--Width);
  max-width: var(--pf-v5-c-modal-box--MaxWidth);
  max-height: var(--pf-v5-c-modal-box--MaxHeight);
  background-color: var(--pf-v5-c-modal-box--BackgroundColor);
  box-shadow: var(--pf-v5-c-modal-box--BoxShadow);
}

@media (min-width: 1200px) {
  .pf-v5-c-modal-box {
    --pf-v5-c-modal-box--m-align-top--spacer: var(--pf-v5-c-modal-box--m-align-top--xl--spacer);
  }
}

.pf-v5-c-modal-box.pf-m-sm {
  --pf-v5-c-modal-box--Width: var(--pf-v5-c-modal-box--m-sm--sm--MaxWidth);
}

.pf-v5-c-modal-box.pf-m-md {
  --pf-v5-c-modal-box--Width: var(--pf-v5-c-modal-box--m-md--Width);
}

.pf-v5-c-modal-box.pf-m-lg {
  --pf-v5-c-modal-box--Width: var(--pf-v5-c-modal-box--m-lg--lg--MaxWidth);
}

.pf-v5-c-modal-box.pf-m-align-top {
  inset-block-start: var(--pf-v5-c-modal-box--m-align-top--MarginTop);
  align-self: flex-start;
  max-width: var(--pf-v5-c-modal-box--m-align-top--MaxWidth);
  max-height: var(--pf-v5-c-modal-box--m-align-top--MaxHeight);
}

.pf-v5-c-modal-box__title.pf-m-danger,
.pf-v5-c-modal-box.pf-m-danger {
  --pf-v5-c-modal-box__title-icon--Color: var(--pf-v5-c-modal-box--m-danger__title-icon--Color);
}

.pf-v5-c-modal-box__title.pf-m-warning,
.pf-v5-c-modal-box.pf-m-warning {
  --pf-v5-c-modal-box__title-icon--Color: var(--pf-v5-c-modal-box--m-warning__title-icon--Color);
}

.pf-v5-c-modal-box__title.pf-m-success,
.pf-v5-c-modal-box.pf-m-success {
  --pf-v5-c-modal-box__title-icon--Color: var(--pf-v5-c-modal-box--m-success__title-icon--Color);
}

.pf-v5-c-modal-box__title.pf-m-custom,
.pf-v5-c-modal-box.pf-m-custom {
  --pf-v5-c-modal-box__title-icon--Color: var(--pf-v5-c-modal-box--m-custom__title-icon--Color);
}

.pf-v5-c-modal-box__title.pf-m-info,
.pf-v5-c-modal-box.pf-m-info {
  --pf-v5-c-modal-box__title-icon--Color: var(--pf-v5-c-modal-box--m-info__title-icon--Color);
}

.pf-v5-c-modal-box__close {
  position: absolute;
  inset-block-start: var(--pf-v5-c-modal-box__close--Top);
  inset-inline-end: var(--pf-v5-c-modal-box__close--Right);
}

.pf-v5-c-modal-box__close+* {
  margin-inline-end: var(--pf-v5-c-modal-box__close--sibling--MarginRight);
}

.pf-v5-c-modal-box__header {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding-block-start: var(--pf-v5-c-modal-box__header--PaddingTop);
  padding-inline-start: var(--pf-v5-c-modal-box__header--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-modal-box__header--PaddingRight);
}

.pf-v5-c-modal-box__header.pf-m-help {
  display: flex;
  flex-direction: row;
}

.pf-v5-c-modal-box__header:last-child {
  padding-block-end: var(--pf-v5-c-modal-box__header--last-child--PaddingBottom);
}

.pf-v5-c-modal-box__header+.pf-v5-c-modal-box__body {
  --pf-v5-c-modal-box__body--PaddingTop: var(--pf-v5-c-modal-box__header--body--PaddingTop);
}

.pf-v5-c-modal-box__header-main {
  flex-grow: 1;
  min-width: 0;
}

.pf-v5-c-modal-box__title,
.pf-v5-c-modal-box__title-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pf-v5-c-modal-box__title {
  flex: 0 0 auto;
  font-family: var(--pf-v5-c-modal-box__title--FontFamily);
  font-size: var(--pf-v5-c-modal-box__title--FontSize);
  line-height: var(--pf-v5-c-modal-box__title--LineHeight);
}

.pf-v5-c-modal-box__title.pf-m-icon {
  display: flex;
}

.pf-v5-c-modal-box__title-icon {
  margin-inline-end: var(--pf-v5-c-modal-box__title-icon--MarginRight);
  color: var(--pf-v5-c-modal-box__title-icon--Color);
}

.pf-v5-c-modal-box__description {
  padding-block-start: var(--pf-v5-c-modal-box__description--PaddingTop);
}

.pf-v5-c-modal-box__body {
  flex: 1 1 auto;
  min-height: var(--pf-v5-c-modal-box__body--MinHeight);
  padding-block-start: var(--pf-v5-c-modal-box__body--PaddingTop);
  padding-inline-start: var(--pf-v5-c-modal-box__body--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-modal-box__body--PaddingRight);
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;
  word-break: break-word;
  -webkit-overflow-scrolling: touch;
}

.pf-v5-c-modal-box__body:last-child {
  padding-block-end: var(--pf-v5-c-modal-box__body--last-child--PaddingBottom);
}

.pf-v5-c-modal-box__footer {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  padding-block-start: var(--pf-v5-c-modal-box__footer--PaddingTop);
  padding-block-end: var(--pf-v5-c-modal-box__footer--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-modal-box__footer--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-modal-box__footer--PaddingRight);
}

.pf-v5-c-modal-box__footer>.pf-v5-c-button:not(:last-child) {
  margin-inline-end: var(--pf-v5-c-modal-box__footer--c-button--MarginRight);
}

@media screen and (min-width: 576px) {
  .pf-v5-c-modal-box__footer>.pf-v5-c-button:not(:last-child) {
    --pf-v5-c-modal-box__footer--c-button--MarginRight: var(--pf-v5-c-modal-box__footer--c-button--sm--MarginRight);
  }
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-modal-box {
  --pf-v5-c-modal-box--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
}

.pf-v5-c-multiple-file-upload {
  --pf-v5-c-multiple-file-upload--GridTemplateColumns: 1fr;
  --pf-v5-c-multiple-file-upload--Gap: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-multiple-file-upload__main--TextAlign: center;
  --pf-v5-c-multiple-file-upload__main--GridTemplateColumns: auto;
  --pf-v5-c-multiple-file-upload__main--GridTemplateRows: auto;
  --pf-v5-c-multiple-file-upload__main--GridTemplateAreas: "title" "upload" "info";
  --pf-v5-c-multiple-file-upload__main--Gap: var(--pf-v5-global--spacer--md);
  --pf-v5-c-multiple-file-upload__main--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-multiple-file-upload__main--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-multiple-file-upload__main--PaddingBottom: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-multiple-file-upload__main--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-multiple-file-upload__main--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-multiple-file-upload__main--BorderStyle: dashed;
  --pf-v5-c-multiple-file-upload__main--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-multiple-file-upload__main--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-multiple-file-upload__title--Display: grid;
  --pf-v5-c-multiple-file-upload__title--GridTemplateColumns: auto;
  --pf-v5-c-multiple-file-upload__title--Gap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-multiple-file-upload__title-icon--Color: var(--pf-v5-global--icon--Color--dark);
  --pf-v5-c-multiple-file-upload__title-text-separator--Display: block;
  --pf-v5-c-multiple-file-upload__title-text-separator--MarginTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-multiple-file-upload__info--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-multiple-file-upload__info--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-multiple-file-upload__info--MarginTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-multiple-file-upload--m-drag-over__main--BorderStyle: solid;
  --pf-v5-c-multiple-file-upload--m-drag-over__main--BorderColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-multiple-file-upload--m-drag-over__main--BackgroundColor: var(--pf-v5-global--palette--blue-50);
  --pf-v5-c-multiple-file-upload--m-drag-over__main__title-icon--Color: var(--pf-v5-global--active-color--100);
  --pf-v5-c-multiple-file-upload--m-drag-over__main__title-text--Color: var(--pf-v5-global--active-color--100);
  --pf-v5-c-multiple-file-upload--m-drag-over__main__info--Color: var(--pf-v5-global--active-color--100);
  --pf-v5-c-multiple-file-upload--m-horizontal--GridTemplateColumns: fit-content(100%);
  --pf-v5-c-multiple-file-upload--m-horizontal__main--TextAlign: start;
  --pf-v5-c-multiple-file-upload--m-horizontal__main--GridTemplateColumns: 1fr auto;
  --pf-v5-c-multiple-file-upload--m-horizontal__main--GridTemplateAreas: "title upload" "info upload";
  --pf-v5-c-multiple-file-upload--m-horizontal__main--Gap: var(--pf-v5-global--spacer--sm) var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-multiple-file-upload--m-horizontal__main--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-multiple-file-upload--m-horizontal__title--GridTemplateColumns: auto 1fr;
  --pf-v5-c-multiple-file-upload--m-horizontal__title--Gap: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-multiple-file-upload--m-horizontal__title-text-separator--Display: inline;
  --pf-v5-c-multiple-file-upload--m-horizontal__title-text-separator--MarginTop: 0;
  --pf-v5-c-multiple-file-upload--m-horizontal__info--MarginTop: 0;
  --pf-v5-c-multiple-file-upload__status-progress--GridTemplateColumns: auto 1fr;
  --pf-v5-c-multiple-file-upload__status-progress--Gap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-multiple-file-upload__status-progress-icon--Color: var(--pf-v5-global--icon--Color--dark);
  --pf-v5-c-multiple-file-upload__status-item--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-multiple-file-upload__status-item--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-multiple-file-upload__status-item--first-child--PaddingTop: 0;
  --pf-v5-c-multiple-file-upload__status-item--GridTemplateColumns: auto 1fr auto;
  --pf-v5-c-multiple-file-upload__status-item--Gap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-multiple-file-upload__status-item--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-multiple-file-upload__status-item--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-multiple-file-upload__status-item-icon--Color: var(--pf-v5-global--icon--Color--dark);
  --pf-v5-c-multiple-file-upload__status-item-close--MarginTop: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-multiple-file-upload__status-item-progress--GridTemplateColumns: fit-content(100%) max-content;
  --pf-v5-c-multiple-file-upload__status-item-progress--Gap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-multiple-file-upload__status-item-progress-text--Color: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-multiple-file-upload__status-item-progress-size--Color: var(--pf-v5-global--Color--200);
  display: grid;
  grid-template-columns: var(--pf-v5-c-multiple-file-upload--GridTemplateColumns);
  gap: var(--pf-v5-c-multiple-file-upload--Gap);
}

.pf-v5-c-multiple-file-upload.pf-m-horizontal {
  --pf-v5-c-multiple-file-upload--GridTemplateColumns: var(--pf-v5-c-multiple-file-upload--m-horizontal--GridTemplateColumns);
  --pf-v5-c-multiple-file-upload__main--TextAlign: var(--pf-v5-c-multiple-file-upload--m-horizontal__main--TextAlign);
  --pf-v5-c-multiple-file-upload__main--GridTemplateColumns: var(--pf-v5-c-multiple-file-upload--m-horizontal__main--GridTemplateColumns);
  --pf-v5-c-multiple-file-upload__main--GridTemplateAreas: var(--pf-v5-c-multiple-file-upload--m-horizontal__main--GridTemplateAreas);
  --pf-v5-c-multiple-file-upload__main--Gap: var(--pf-v5-c-multiple-file-upload--m-horizontal__main--Gap);
  --pf-v5-c-multiple-file-upload__main--PaddingBottom: var(--pf-v5-c-multiple-file-upload--m-horizontal__main--PaddingBottom);
  --pf-v5-c-multiple-file-upload__title--GridTemplateColumns: var(--pf-v5-c-multiple-file-upload--m-horizontal__title--GridTemplateColumns);
  --pf-v5-c-multiple-file-upload__title--Gap: var(--pf-v5-c-multiple-file-upload--m-horizontal__title--Gap);
  --pf-v5-c-multiple-file-upload__title-text-separator--Display: var(--pf-v5-c-multiple-file-upload--m-horizontal__title-text-separator--Display);
  --pf-v5-c-multiple-file-upload__title-text-separator--MarginTop: var(--pf-v5-c-multiple-file-upload--m-horizontal__title-text-separator--MarginTop);
  --pf-v5-c-multiple-file-upload__info--MarginTop: var(--pf-v5-c-multiple-file-upload--m-horizontal__info--MarginTop);
}

.pf-v5-c-multiple-file-upload.pf-m-drag-over {
  --pf-v5-c-multiple-file-upload__main--BorderStyle: var(--pf-v5-c-multiple-file-upload--m-drag-over__main--BorderStyle);
  --pf-v5-c-multiple-file-upload__main--BorderColor: var(--pf-v5-c-multiple-file-upload--m-drag-over__main--BorderColor);
  --pf-v5-c-multiple-file-upload__main--BackgroundColor: var(--pf-v5-c-multiple-file-upload--m-drag-over__main--BackgroundColor);
  --pf-v5-c-multiple-file-upload__title-icon--Color: var(--pf-v5-c-multiple-file-upload--m-drag-over__main__title-icon--Color);
  --pf-v5-c-multiple-file-upload__title-text--Color: var(--pf-v5-c-multiple-file-upload--m-drag-over__main__title-text--Color);
  --pf-v5-c-multiple-file-upload__info--Color: var(--pf-v5-c-multiple-file-upload--m-drag-over__main__info--Color);
}

.pf-v5-c-multiple-file-upload__main {
  display: grid;
  grid-template-areas: var(--pf-v5-c-multiple-file-upload__main--GridTemplateAreas);
  grid-template-rows: var(--pf-v5-c-multiple-file-upload__main--GridTemplateRows);
  grid-template-columns: var(--pf-v5-c-multiple-file-upload__main--GridTemplateColumns);
  gap: var(--pf-v5-c-multiple-file-upload__main--Gap);
  padding-block-start: var(--pf-v5-c-multiple-file-upload__main--PaddingTop);
  padding-block-end: var(--pf-v5-c-multiple-file-upload__main--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-multiple-file-upload__main--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-multiple-file-upload__main--PaddingRight);
  text-align: var(--pf-v5-c-multiple-file-upload__main--TextAlign);
  background-color: var(--pf-v5-c-multiple-file-upload__main--BackgroundColor);
  border: var(--pf-v5-c-multiple-file-upload__main--BorderWidth) var(--pf-v5-c-multiple-file-upload__main--BorderStyle) var(--pf-v5-c-multiple-file-upload__main--BorderColor);
}

.pf-v5-c-multiple-file-upload__title {
  display: var(--pf-v5-c-multiple-file-upload__title--Display);
  grid-area: title;
  grid-template-columns: var(--pf-v5-c-multiple-file-upload__title--GridTemplateColumns);
  gap: var(--pf-v5-c-multiple-file-upload__title--Gap);
}

.pf-v5-c-multiple-file-upload__title-icon {
  color: var(--pf-v5-c-multiple-file-upload__title-icon--Color);
}

.pf-v5-c-multiple-file-upload__title-text {
  color: var(--pf-v5-c-multiple-file-upload__title-text--Color, inherit);
}

.pf-v5-c-multiple-file-upload__title-text-separator {
  display: var(--pf-v5-c-multiple-file-upload__title-text-separator--Display);
  margin-block-start: var(--pf-v5-c-multiple-file-upload__title-text-separator--MarginTop);
}

.pf-v5-c-multiple-file-upload__upload {
  grid-area: upload;
}

.pf-v5-c-multiple-file-upload__info {
  grid-area: info;
  margin-block-start: var(--pf-v5-c-multiple-file-upload__info--MarginTop);
  font-size: var(--pf-v5-c-multiple-file-upload__info--FontSize);
  color: var(--pf-v5-c-multiple-file-upload__info--Color);
}

.pf-v5-c-multiple-file-upload__status,
.pf-v5-c-multiple-file-upload__status-item-main {
  min-width: 0;
}

.pf-v5-c-multiple-file-upload__status-progress {
  display: grid;
  grid-template-columns: var(--pf-v5-c-multiple-file-upload__status-progress--GridTemplateColumns);
  gap: var(--pf-v5-c-multiple-file-upload__status-progress--Gap);
}

.pf-v5-c-multiple-file-upload__status-progress-icon {
  color: var(--pf-v5-c-multiple-file-upload__status-progress-icon--Color);
}

.pf-v5-c-multiple-file-upload__status-item {
  display: grid;
  grid-template-columns: var(--pf-v5-c-multiple-file-upload__status-item--GridTemplateColumns);
  gap: var(--pf-v5-c-multiple-file-upload__status-item--Gap);
  padding-block-start: var(--pf-v5-c-multiple-file-upload__status-item--PaddingTop);
  padding-block-end: var(--pf-v5-c-multiple-file-upload__status-item--PaddingBottom);
  border-block-end: var(--pf-v5-c-multiple-file-upload__status-item--BorderWidth) solid var(--pf-v5-c-multiple-file-upload__status-item--BorderColor);
}

.pf-v5-c-multiple-file-upload__status-item:first-child {
  --pf-v5-c-multiple-file-upload__status-item--PaddingTop: var(--pf-v5-c-multiple-file-upload__status-item--first-child--PaddingTop);
}

.pf-v5-c-multiple-file-upload__status-item-icon {
  color: var(--pf-v5-c-multiple-file-upload__status-item-icon--Color);
}

.pf-v5-c-multiple-file-upload__status-item-close {
  margin-block-start: var(--pf-v5-c-multiple-file-upload__status-item-close--MarginTop);
}

.pf-v5-c-multiple-file-upload__status-item-progress {
  display: grid;
  grid-template-columns: var(--pf-v5-c-multiple-file-upload__status-item-progress--GridTemplateColumns);
  gap: var(--pf-v5-c-multiple-file-upload__status-item-progress--Gap);
}

.pf-v5-c-multiple-file-upload__status-item-progress-text {
  color: var(--pf-v5-c-multiple-file-upload__status-item-progress-text--Color);
}

.pf-v5-c-multiple-file-upload__status-item-progress-size {
  color: var(--pf-v5-c-multiple-file-upload__status-item-progress-size--Color);
}

.pf-v5-c-nav {
  --pf-v5-c-nav--Transition: var(--pf-v5-global--Transition);
  --pf-v5-c-nav--m-light__item--before--BorderColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-nav--m-light__item--m-current--not--m-expanded__link--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-300);
  --pf-v5-c-nav--m-light__link--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-nav--m-light__link--hover--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-nav--m-light__link--focus--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-nav--m-light__link--active--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-nav--m-light__link--m-current--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-nav--m-light__link--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-300);
  --pf-v5-c-nav--m-light__link--focus--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-300);
  --pf-v5-c-nav--m-light__link--active--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-300);
  --pf-v5-c-nav--m-light__link--m-current--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-300);
  --pf-v5-c-nav--m-light__link--before--BorderColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-nav--m-light__link--after--BorderColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-nav--m-light__link--m-current--after--BorderColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-nav--m-light__section-title--Color: var(--pf-v5-global--Color--dark-200);
  --pf-v5-c-nav--m-light__section-title--BorderBottomColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-nav--m-light--c-divider--BackgroundColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-nav--m-light__subnav__link--hover--after--BorderColor: var(--pf-v5-global--BorderColor--dark-100);
  --pf-v5-c-nav--m-light__subnav__link--focus--after--BorderColor: var(--pf-v5-global--BorderColor--dark-100);
  --pf-v5-c-nav--m-light__subnav__link--active--after--BorderColor: var(--pf-v5-global--BorderColor--dark-100);
  --pf-v5-c-nav--m-light__subnav__link--m-current--after--BorderColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-nav__list--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav__list--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav__item--MarginTop: 0;
  --pf-v5-c-nav__item--m-current--not--m-expanded__link--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-400);
  --pf-v5-c-nav__link--m-current--not--m-expanded__link--after--BorderWidth: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-nav__item__item__link--PaddingRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-nav__item__item__toggle--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-nav__item__toggle-icon--Rotate: 0;
  --pf-v5-c-nav__item--m-expanded__toggle-icon--Rotate: 90deg;
  --pf-v5-c-nav__item--before--BorderColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-nav__item--before--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-nav__link--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-nav__link--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-nav__link--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav__link--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav__link--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav__link--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav__link--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav__link--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav__link--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-nav__link--hover--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-nav__link--focus--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-nav__link--active--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-nav__link--m-current--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-nav__link--BackgroundColor: transparent;
  --pf-v5-c-nav__link--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-nav__link--focus--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-nav__link--active--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-nav__link--m-current--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-400);
  --pf-v5-c-nav__link--OutlineOffset: calc(var(--pf-v5-global--spacer--xs) * -1);
  --pf-v5-c-nav__link--before--BorderColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-nav__link--before--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-nav__link--hover--before--BorderBottomWidth: 0;
  --pf-v5-c-nav__link--focus--before--BorderBottomWidth: 0;
  --pf-v5-c-nav__link--active--before--BorderBottomWidth: 0;
  --pf-v5-c-nav__link--m-current--before--BorderBottomWidth: 0;
  --pf-v5-c-nav__link--after--BorderColor: var(--pf-v5-global--active-color--400);
  --pf-v5-c-nav__link--hover--after--BorderColor: var(--pf-v5-global--active-color--400);
  --pf-v5-c-nav__link--focus--after--BorderColor: var(--pf-v5-global--active-color--400);
  --pf-v5-c-nav__link--active--after--BorderColor: var(--pf-v5-global--active-color--400);
  --pf-v5-c-nav__link--m-current--after--BorderColor: var(--pf-v5-global--active-color--400);
  --pf-v5-c-nav__link--after--BorderLeftWidth: 0;
  --pf-v5-c-nav__link--hover--after--BorderLeftWidth: 0;
  --pf-v5-c-nav__link--focus--after--BorderLeftWidth: 0;
  --pf-v5-c-nav__link--active--after--BorderLeftWidth: 0;
  --pf-v5-c-nav__link--m-current--after--BorderLeftWidth: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-nav--m-horizontal__link--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav--m-horizontal__link--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav--m-horizontal__link--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav--m-horizontal__link--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav--m-horizontal__link--lg--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav--m-horizontal__link--lg--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav--m-horizontal__link--Right: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav--m-horizontal__link--Left: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav--m-horizontal__link--Color: var(--pf-v5-global--Color--light-200);
  --pf-v5-c-nav--m-horizontal__link--hover--Color: var(--pf-v5-global--active-color--400);
  --pf-v5-c-nav--m-horizontal__link--focus--Color: var(--pf-v5-global--active-color--400);
  --pf-v5-c-nav--m-horizontal__link--active--Color: var(--pf-v5-global--active-color--400);
  --pf-v5-c-nav--m-horizontal__link--m-current--Color: var(--pf-v5-global--active-color--400);
  --pf-v5-c-nav--m-horizontal__link--BackgroundColor: transparent;
  --pf-v5-c-nav--m-horizontal__link--hover--BackgroundColor: transparent;
  --pf-v5-c-nav--m-horizontal__link--focus--BackgroundColor: transparent;
  --pf-v5-c-nav--m-horizontal__link--active--BackgroundColor: transparent;
  --pf-v5-c-nav--m-horizontal__link--m-current--BackgroundColor: transparent;
  --pf-v5-c-nav--m-horizontal__link--before--BorderColor: var(--pf-v5-global--active-color--400);
  --pf-v5-c-nav--m-horizontal__link--before--BorderWidth: 0;
  --pf-v5-c-nav--m-horizontal__link--hover--before--BorderWidth: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-nav--m-horizontal__link--focus--before--BorderWidth: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-nav--m-horizontal__link--active--before--BorderWidth: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-nav--m-horizontal__link--m-current--before--BorderWidth: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-nav--m-tertiary__link--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav--m-tertiary__link--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav--m-tertiary__link--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav--m-tertiary__link--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav--m-tertiary__link--Right: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav--m-tertiary__link--Left: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav--m-tertiary__link--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-nav--m-tertiary__link--hover--Color: var(--pf-v5-global--active-color--100);
  --pf-v5-c-nav--m-tertiary__link--focus--Color: var(--pf-v5-global--active-color--100);
  --pf-v5-c-nav--m-tertiary__link--active--Color: var(--pf-v5-global--active-color--100);
  --pf-v5-c-nav--m-tertiary__link--m-current--Color: var(--pf-v5-global--active-color--100);
  --pf-v5-c-nav--m-tertiary__link--BackgroundColor: transparent;
  --pf-v5-c-nav--m-tertiary__link--hover--BackgroundColor: transparent;
  --pf-v5-c-nav--m-tertiary__link--focus--BackgroundColor: transparent;
  --pf-v5-c-nav--m-tertiary__link--active--BackgroundColor: transparent;
  --pf-v5-c-nav--m-tertiary__link--m-current--BackgroundColor: transparent;
  --pf-v5-c-nav--m-tertiary__link--before--BorderColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-nav--m-tertiary__link--before--BorderWidth: 0;
  --pf-v5-c-nav--m-tertiary__link--hover--before--BorderWidth: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-nav--m-tertiary__link--focus--before--BorderWidth: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-nav--m-tertiary__link--active--before--BorderWidth: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-nav--m-tertiary__link--m-current--before--BorderWidth: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-nav--m-tertiary__scroll-button--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-nav--m-tertiary__scroll-button--hover--Color: var(--pf-v5-global--active-color--100);
  --pf-v5-c-nav--m-tertiary__scroll-button--focus--Color: var(--pf-v5-global--active-color--100);
  --pf-v5-c-nav--m-tertiary__scroll-button--active--Color: var(--pf-v5-global--active-color--100);
  --pf-v5-c-nav--m-tertiary__scroll-button--disabled--Color: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-nav--m-tertiary__scroll-button--before--BorderColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-nav--m-tertiary__scroll-button--disabled--before--BorderColor: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-nav--m-horizontal-subnav__link--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav--m-horizontal-subnav__link--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav--m-horizontal-subnav__link--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav--m-horizontal-subnav__link--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav--m-horizontal-subnav__link--xl--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav--m-horizontal-subnav__link--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav--m-horizontal-subnav__link--xl--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav--m-horizontal-subnav__link--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav--m-horizontal-subnav__link--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-nav--m-horizontal-subnav__link--Color: var(--pf-v5-global--Color--light-200);
  --pf-v5-c-nav--m-horizontal-subnav__link--hover--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-nav--m-horizontal-subnav__link--focus--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-nav--m-horizontal-subnav__link--active--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-nav--m-horizontal-subnav__link--m-current--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-nav--m-horizontal-subnav__link--BackgroundColor: transparent;
  --pf-v5-c-nav--m-horizontal-subnav__link--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-nav--m-horizontal-subnav__link--focus--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-nav--m-horizontal-subnav__link--active--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-nav--m-horizontal-subnav__link--m-current--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-400);
  --pf-v5-c-nav--m-horizontal-subnav__link--before--BorderColor: transparent;
  --pf-v5-c-nav--m-horizontal-subnav__link--after--BorderColor: var(--pf-v5-global--palette--black-800);
  --pf-v5-c-nav--m-horizontal-subnav__link--hover--after--BorderColor: var(--pf-v5-global--palette--black-800);
  --pf-v5-c-nav--m-horizontal-subnav__link--active--after--BorderColor: var(--pf-v5-global--palette--black-800);
  --pf-v5-c-nav--m-horizontal-subnav__link--m-current--after--BorderColor: var(--pf-v5-global--palette--black-800);
  --pf-v5-c-nav--m-horizontal-subnav__link--after--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-nav--m-horizontal-subnav__link--hover--after--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-nav--m-horizontal-subnav__link--active--after--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-nav--m-horizontal-subnav__link--m-current--after--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-nav__subnav--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav__subnav--xl--PaddingLeft: var(--pf-v5-c-nav__link--PaddingLeft);
  --pf-v5-c-nav__subnav__link--MarginTop: 0;
  --pf-v5-c-nav__subnav__link--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav__subnav__link--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav__subnav__link--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav__subnav__link--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav__subnav__link--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-nav__subnav__link--hover--after--BorderColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-nav__subnav__link--focus--after--BorderColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-nav__subnav__link--active--after--BorderColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-nav__subnav__link--m-current--after--BorderColor: var(--pf-v5-global--active-color--400);
  --pf-v5-c-nav__subnav__link--hover--after--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-nav__subnav__link--focus--after--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-nav__subnav__link--active--after--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-nav__subnav__link--m-current--after--BorderWidth: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-nav__subnav--MaxHeight: 0;
  --pf-v5-c-nav__subnav__subnav--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav__subnav__subnav__link--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav__subnav__subnav__link--FontSize: var(--pf-v5-global--FontSize--xs);
  --pf-v5-c-nav__item--m-expanded__subnav--MaxHeight: 100%;
  --pf-v5-c-nav__subnav--c-divider--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav__subnav--c-divider--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav__section--first-child--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav__section--last-child--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav__section__item--MarginTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav__section__link--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav__section__link--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav__section__link--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav__section__link--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav__section__link--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav__section__link--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav__section__link--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-nav__section__link--before--BorderBottomWidth: 0;
  --pf-v5-c-nav__section__link--hover--after--BorderColor: transparent;
  --pf-v5-c-nav__section__link--focus--after--BorderColor: transparent;
  --pf-v5-c-nav__section__link--active--after--BorderColor: transparent;
  --pf-v5-c-nav__section__link--m-current--after--BorderColor: var(--pf-v5-global--active-color--400);
  --pf-v5-c-nav__section__link--hover--after--BorderWidth: 0;
  --pf-v5-c-nav__section__link--focus--after--BorderWidth: 0;
  --pf-v5-c-nav__section__link--active--after--BorderWidth: 0;
  --pf-v5-c-nav__section__link--m-current--after--BorderWidth: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-nav__section--section--MarginTop: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-nav__section-title--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav__section-title--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav__section-title--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav__section-title--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav__section-title--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav__section-title--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav__section-title--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav__section-title--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-nav__section-title--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-nav__section-title--BorderBottomColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-nav__section-title--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-nav__scroll-button--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-nav__scroll-button--hover--Color: var(--pf-v5-global--active-color--400);
  --pf-v5-c-nav__scroll-button--focus--Color: var(--pf-v5-global--active-color--400);
  --pf-v5-c-nav__scroll-button--active--Color: var(--pf-v5-global--active-color--400);
  --pf-v5-c-nav__scroll-button--disabled--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-nav__scroll-button--BackgroundColor: transparent;
  --pf-v5-c-nav__scroll-button--Width: var(--pf-v5-global--target-size--MinWidth);
  --pf-v5-c-nav__scroll-button--OutlineOffset: calc(-1 * var(--pf-v5-global--spacer--xs));
  --pf-v5-c-nav__scroll-button--Transition: margin .125s, transform .125s, opacity .125s;
  --pf-v5-c-nav__scroll-button--before--BorderColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-nav__scroll-button--before--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-nav__scroll-button--before--BorderRightWidth: 0;
  --pf-v5-c-nav__scroll-button--before--BorderLeftWidth: 0;
  --pf-v5-c-nav__scroll-button--disabled--before--BorderColor: transparent;
  --pf-v5-c-nav__toggle--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav__toggle--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav__toggle--FontSize: var(--pf-v5-global--icon--FontSize--md);
  --pf-v5-c-nav__toggle-icon--Transition: var(--pf-v5-global--TransitionDuration);
  --pf-v5-c-nav--c-divider--MarginTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav--c-divider--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav--c-divider--PaddingRight: 0;
  --pf-v5-c-nav--c-divider--PaddingLeft: 0;
  --pf-v5-c-nav--c-divider--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-nav__list--ScrollSnapTypeAxis: x;
  --pf-v5-c-nav__list--ScrollSnapTypeStrictness: proximity;
  --pf-v5-c-nav__list--ScrollSnapType: var(--pf-v5-c-nav__list--ScrollSnapTypeAxis) var(--pf-v5-c-nav__list--ScrollSnapTypeStrictness);
  --pf-v5-c-nav__item--ScrollSnapAlign: end;
  --pf-v5-c-nav__item--m-flyout--c-menu--top-offset: 0px;
  --pf-v5-c-nav__item--m-flyout--c-menu--left-offset: 0.25rem;
  --pf-v5-c-nav__item--m-flyout--c-menu--m-top--bottom-offset: 0px;
  --pf-v5-c-nav__item--m-flyout--c-menu--m-left--right-offset: 0.25rem;
  --pf-v5-c-nav__item--m-flyout--MarginTop: 0;
  --pf-v5-c-nav__item--m-flyout--hover__link--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-nav__item--m-flyout--focus__link--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-nav__item--m-flyout--active__link--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-nav__item--m-flyout--hover__link--before--BorderWidth: 0;
  --pf-v5-c-nav__item--m-flyout--focus__link--before--BorderWidth: 0;
  --pf-v5-c-nav__item--m-flyout--active__link--before--BorderWidth: 0;
  --pf-v5-c-nav__item--m-flyout__item--last-child__link--before--BorderBottomWidth: 0;
  --pf-v5-c-nav__item--m-flyout--c-menu--Top: calc(0px + var(--pf-v5-c-nav__item--m-flyout--c-menu--top-offset));
  --pf-v5-c-nav__item--m-flyout--c-menu--c-menu--Top: calc(var(--pf-v5-c-nav__item--m-flyout--c-menu--Top) - var(--pf-v5-c-nav__item--before--BorderWidth));
  --pf-v5-c-nav__item--m-flyout--c-menu__list-item--first-child--c-menu--Top: 0;
  --pf-v5-c-nav__item--m-flyout--c-menu--Left: calc(100% - var(--pf-v5-c-nav__item--m-flyout--c-menu--left-offset));
  --pf-v5-c-nav__item--m-flyout--c-menu--m-left--Right: calc(100% - var(--pf-v5-c-nav__item--m-flyout--c-menu--m-left--right-offset));
  --pf-v5-c-nav__item--m-flyout--c-menu--m-top--Bottom: calc(0 + var(--pf-v5-c-nav__item--m-flyout--c-menu--m-top--bottom-offset));
  --pf-v5-c-nav__item--m-flyout--c-menu--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-300);
  --pf-v5-c-nav__item--m-flyout--c-menu__item--Color: var(--pf-v5-c-nav__link--Color);
  --pf-v5-c-nav__item--m-flyout--c-menu__item--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav__item--m-flyout--c-menu__item--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav__item--m-flyout--c-menu__item--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav__item--m-flyout--c-menu__item--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav__item--m-flyout--c-menu__item-description--Color: var(--pf-v5-global--Color--light-200);
  --pf-v5-c-nav__item--m-flyout--c-menu__item--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-nav__item--m-flyout--c-menu__item--OutlineOffset: var(--pf-v5-c-nav__link--OutlineOffset);
  --pf-v5-c-nav__item--m-flyout--c-menu__item--before--BorderBottomWidth: var(--pf-v5-c-nav__item--before--BorderWidth);
  --pf-v5-c-nav__item--m-flyout--c-menu__item--before--BorderBottomColor: var(--pf-v5-c-nav__item--before--BorderColor);
  --pf-v5-c-nav__item--m-flyout--c-menu__item--hover--after--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-nav__item--m-flyout--c-menu__item--hover--after--BorderLeftColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-nav__item--m-flyout--c-menu__item--hover--after--Top: calc(-1 * var(--pf-v5-c-nav__item--m-flyout--c-menu__item--before--BorderBottomWidth));
  --pf-v5-c-nav__item--m-flyout--c-menu__list-item--hover--BackgroundColor: var(--pf-v5-c-nav__link--hover--BackgroundColor);
  --pf-v5-c-nav__item--m-flyout--c-menu__list-item--focus-within--BackgroundColor: var(--pf-v5-c-nav__link--focus--BackgroundColor);
  --pf-v5-c-nav__item--m-flyout--c-menu__list-item--active--BackgroundColor: var(--pf-v5-c-nav__link--active--BackgroundColor);
  --pf-v5-c-nav__item--m-flyout--c-menu__list-item--first-child__item--hover--after--Top: 0;
  --pf-v5-c-nav__item--m-flyout--c-menu--BoxShadow: var(--pf-v5-global--BoxShadow--lg);
  --pf-v5-c-nav--c-menu--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-300);
  --pf-v5-c-nav--c-menu__list--PaddingTop: 0;
  --pf-v5-c-nav--c-menu__list--PaddingBottom: 0;
  --pf-v5-c-nav--c-menu__list-item--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-nav--c-menu__list-item--hover--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-nav--c-menu__list-item--active--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-nav--c-menu__list-item--focus-within--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-nav--c-menu__list-item--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-nav--c-menu__list-item--focus-within--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-nav--c-menu__list-item--m-drill-up--before--BorderBottomWidth: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-nav--c-menu__list-item--m-drill-up__item--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-nav--c-menu__item--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav--c-menu__item--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav--c-menu__item--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav--c-menu__item--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-nav--c-menu__item--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav--c-menu__item--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav--c-menu__item--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-nav--c-menu__item--OutlineOffset: calc(var(--pf-v5-global--spacer--xs) * -1);
  --pf-v5-c-nav--c-menu__item--before--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-nav--c-menu__item--before--BorderBottomColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-nav--c-menu__item--after--BorderLeftWidth: 0;
  --pf-v5-c-nav--c-menu__item--after--BorderLeftColor: transparent;
  --pf-v5-c-nav--c-menu__item--m-current--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-400);
  --pf-v5-c-nav--c-menu__item--m-current--after--BorderLeftWidth: var(--pf-v5-global--BorderWidth--xl);
  --pf-v5-c-nav--c-menu__item--m-current--after--BorderColor: var(--pf-v5-global--active-color--400);
  --pf-v5-c-nav--c-menu__item-description--Color: var(--pf-v5-global--Color--400);
  --pf-v5-c-nav--c-menu--m-flyout--left-offset: 0.25rem;
  --pf-v5-c-nav--c-menu--m-flyout--m-left--right-offset: 0.25rem;
  --pf-v5-c-nav--c-menu--m-flyout--Top: 0;
  --pf-v5-c-nav--c-menu--m-flyout--c-menu--Top: calc(var(--pf-v5-c-nav--c-menu--m-flyout--Top) * -1);
  --pf-v5-c-nav--c-menu--m-flyout--c-menu--Right: auto;
  --pf-v5-c-nav--c-menu--m-flyout--c-menu--Bottom: auto;
  --pf-v5-c-nav--c-menu--m-flyout--c-menu--Left: calc(100% - var(--pf-v5-c-nav--c-menu--m-flyout--left-offset));
  --pf-v5-c-nav--c-menu--m-flyout--m-left--Right: calc(100% - var(--pf-v5-c-nav--c-menu--m-flyout--m-left--right-offset));
  --pf-v5-c-nav--c-menu--m-flyout--m-top--Bottom: 0;
  --pf-v5-c-nav--c-menu--m-flyout--BoxShadow: var(--pf-v5-global--BoxShadow--lg);
  --pf-v5-c-nav--c-menu--m-flyout__item--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav--c-menu--m-flyout__item--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav--c-menu--m-flyout__item--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-nav--c-menu--m-flyout__item--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-nav--c-menu--m-flyout__item--hover--after--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-nav--c-menu--m-flyout__item--hover--after--BorderLeftColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-nav__toggle--m-start--MarginRight: var(--pf-v5-global--spacer--sm);
  position: relative;
}

@media screen and (min-width: 1200px) {
  .pf-v5-c-nav {
    --pf-v5-c-nav__link--PaddingRight: var(--pf-v5-c-nav__link--xl--PaddingRight);
    --pf-v5-c-nav__link--PaddingLeft: var(--pf-v5-c-nav__link--xl--PaddingLeft);
    --pf-v5-c-nav--m-horizontal-subnav__link--PaddingTop: var(--pf-v5-c-nav--m-horizontal-subnav__link--xl--PaddingTop);
    --pf-v5-c-nav--m-horizontal-subnav__link--PaddingRight: var(--pf-v5-c-nav--m-horizontal-subnav__link--xl--PaddingRight);
    --pf-v5-c-nav--m-horizontal-subnav__link--PaddingBottom: var(--pf-v5-c-nav--m-horizontal-subnav__link--xl--PaddingBottom);
    --pf-v5-c-nav--m-horizontal-subnav__link--PaddingLeft: var(--pf-v5-c-nav--m-horizontal-subnav__link--xl--PaddingLeft);
    --pf-v5-c-nav__section__link--PaddingRight: var(--pf-v5-c-nav__section__link--xl--PaddingRight);
    --pf-v5-c-nav__section__link--PaddingLeft: var(--pf-v5-c-nav__section__link--xl--PaddingLeft);
    --pf-v5-c-nav__section-title--PaddingRight: var(--pf-v5-c-nav__section-title--xl--PaddingRight);
    --pf-v5-c-nav__section-title--PaddingLeft: var(--pf-v5-c-nav__section-title--xl--PaddingLeft);
    --pf-v5-c-nav__subnav--PaddingLeft: var(--pf-v5-c-nav__subnav--xl--PaddingLeft);
    --pf-v5-c-nav--c-menu__item--PaddingRight: var(--pf-v5-c-nav--c-menu__item--xl--PaddingRight);
    --pf-v5-c-nav--c-menu__item--PaddingLeft: var(--pf-v5-c-nav--c-menu__item--xl--PaddingLeft);
  }
}

.pf-v5-c-nav .pf-v5-c-menu {
  --pf-v5-c-menu--MinWidth: 100%;
  --pf-v5-c-menu--BackgroundColor: var(--pf-v5-c-nav--c-menu--BackgroundColor);
  --pf-v5-c-menu__list--PaddingTop: var(--pf-v5-c-nav--c-menu__list--PaddingTop);
  --pf-v5-c-menu__list--PaddingBottom: var(--pf-v5-c-nav--c-menu__list--PaddingBottom);
  --pf-v5-c-menu__item--PaddingTop: var(--pf-v5-c-nav--c-menu__item--PaddingTop);
  --pf-v5-c-menu__item--PaddingRight: var(--pf-v5-c-nav--c-menu__item--PaddingRight);
  --pf-v5-c-menu__item--PaddingBottom: var(--pf-v5-c-nav--c-menu__item--PaddingBottom);
  --pf-v5-c-menu__item--PaddingLeft: var(--pf-v5-c-nav--c-menu__item--PaddingLeft);
  --pf-v5-c-menu__list-item--Color: var(--pf-v5-c-nav--c-menu__list-item--Color);
  --pf-v5-c-menu__list-item--hover--Color: var(--pf-v5-c-nav--c-menu__list-item--hover--Color);
  --pf-v5-c-menu__list-item--active--Color: var(--pf-v5-c-nav--c-menu__list-item--active--Color);
  --pf-v5-c-menu__list-item--focus-within--Color: var(--pf-v5-c-nav--c-menu__list-item--focus-within--Color);
  --pf-v5-c-menu__list-item--hover--BackgroundColor: var(--pf-v5-c-nav--c-menu__list-item--hover--BackgroundColor);
  --pf-v5-c-menu__list-item--focus-within--BackgroundColor: var(--pf-v5-c-nav--c-menu__list-item--focus-within--BackgroundColor);
  --pf-v5-c-menu__item-description--Color: var(--pf-v5-c-nav--c-menu__item-description--Color);
}

.pf-v5-c-nav .pf-v5-c-menu:first-child>.pf-v5-c-menu__content {
  border-block-end: var(--pf-v5-c-nav--c-menu__item--before--BorderBottomWidth) solid var(--pf-v5-c-nav--c-menu__item--before--BorderBottomColor);
}

.pf-v5-c-nav .pf-v5-c-menu.pf-m-flyout,
.pf-v5-c-nav .pf-v5-c-menu.pf-m-flyout .pf-v5-c-menu {
  --pf-v5-c-menu--BoxShadow: var(--pf-v5-c-nav--c-menu--m-flyout--BoxShadow);
  --pf-v5-c-menu__list--PaddingTop: 0;
  --pf-v5-c-menu__list--PaddingBottom: 0;
  --pf-v5-c-menu__item--FontSize: var(--pf-v5-c-nav--c-menu__item--FontSize);
  --pf-v5-c-menu__item--Color: var(--pf-v5-c-nav--c-menu__item--Color, inherit);
  --pf-v5-c-menu__item--PaddingTop: var(--pf-v5-c-nav--c-menu--m-flyout__item--PaddingTop);
  --pf-v5-c-menu__item--PaddingRight: var(--pf-v5-c-nav--c-menu--m-flyout__item--PaddingRight);
  --pf-v5-c-menu__item--PaddingBottom: var(--pf-v5-c-nav--c-menu--m-flyout__item--PaddingBottom);
  --pf-v5-c-menu__item--PaddingLeft: var(--pf-v5-c-nav--c-menu--m-flyout__item--PaddingLeft);
  inset-block-start: var(--pf-v5-c-nav--c-menu--m-flyout--c-menu--Top);
  inset-block-end: var(--pf-v5-c-nav--c-menu--m-flyout--c-menu--Bottom);
  inset-inline-start: var(--pf-v5-c-nav--c-menu--m-flyout--c-menu--Left);
  inset-inline-end: var(--pf-v5-c-nav--c-menu--m-flyout--c-menu--Right);
}

.pf-v5-c-nav .pf-v5-c-menu.pf-m-flyout .pf-v5-c-menu__item:hover {
  --pf-v5-c-nav--c-menu__item--after--BorderLeftWidth: var(--pf-v5-c-nav--c-menu--m-flyout__item--hover--after--BorderLeftWidth);
  --pf-v5-c-nav--c-menu__item--after--BorderLeftColor: var(--pf-v5-c-nav--c-menu--m-flyout__item--hover--after--BorderLeftColor);
}

.pf-v5-c-nav .pf-v5-c-menu.pf-m-top {
  --pf-v5-c-nav--c-menu--m-flyout--c-menu--Top: auto;
  --pf-v5-c-nav--c-menu--m-flyout--c-menu--Bottom: var(--pf-v5-c-nav--c-menu--m-flyout--m-top--Bottom);
}

.pf-v5-c-nav .pf-v5-c-menu.pf-m-left {
  --pf-v5-c-nav--c-menu--m-flyout--c-menu--Right: var(--pf-v5-c-nav--c-menu--m-flyout--m-left--Right);
  --pf-v5-c-nav--c-menu--m-flyout--c-menu--Left: auto;
}

.pf-v5-c-nav .pf-v5-c-menu .pf-v5-c-divider {
  margin-block-start: 0;
  margin-block-end: 0;
}

.pf-v5-c-nav .pf-v5-c-menu__item {
  position: relative;
  outline-offset: var(--pf-v5-c-nav--c-menu__item--OutlineOffset);
}

.pf-v5-c-nav .pf-v5-c-menu__item::before,
.pf-v5-c-nav .pf-v5-c-menu__item::after {
  position: absolute;
  inset-block-start: 0;
  content: "";
}

.pf-v5-c-nav .pf-v5-c-menu__item::before {
  inset-block-end: calc(var(--pf-v5-c-nav--c-menu__item--before--BorderBottomWidth)*-1);
  inset-inline-start: 0;
  inset-inline-end: 0;
  border-block-end: var(--pf-v5-c-nav--c-menu__item--before--BorderBottomWidth) solid var(--pf-v5-c-nav--c-menu__item--before--BorderBottomColor);
}

.pf-v5-c-nav .pf-v5-c-menu__item::after {
  inset-block-end: 0;
  inset-inline-start: 0;
  border-inline-start: var(--pf-v5-c-nav--c-menu__item--after--BorderLeftWidth) solid var(--pf-v5-c-nav--c-menu__item--after--BorderLeftColor);
}

.pf-v5-c-nav .pf-v5-c-menu__item.pf-m-current {
  --pf-v5-c-nav--c-menu__item--after--BorderLeftWidth: var(--pf-v5-c-nav--c-menu__item--m-current--after--BorderLeftWidth);
  --pf-v5-c-nav--c-menu__item--after--BorderLeftColor: var(--pf-v5-c-nav--c-menu__item--m-current--after--BorderColor);
  background-color: var(--pf-v5-c-nav--c-menu__item--m-current--BackgroundColor);
}

.pf-v5-c-nav .pf-v5-c-menu__list-item:where(.pf-m-drill-up) {
  --pf-v5-c-nav--c-menu__item--before--BorderBottomWidth: var(--pf-v5-c-nav--c-menu__list-item--m-drill-up--before--BorderBottomWidth);
}

.pf-v5-c-nav .pf-v5-c-menu__list-item:where(.pf-m-drill-up)>.pf-v5-c-menu__item {
  --pf-v5-c-menu__item--FontWeight: var(--pf-v5-c-nav--c-menu__list-item--m-drill-up__item--FontWeight);
}

.pf-v5-c-nav .pf-v5-c-menu__item-toggle-icon {
  margin-inline-start: calc(var(--pf-v5-c-menu__item-toggle-icon--PaddingLeft)*-1);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-nav .pf-v5-c-menu__item-toggle-icon {
  scale: -1 1;
}

.pf-v5-c-nav.pf-m-horizontal .pf-v5-c-nav__link::after,
.pf-v5-c-nav.pf-m-tertiary .pf-v5-c-nav__link::after {
  content: none;
}

.pf-v5-c-nav.pf-m-horizontal,
.pf-v5-c-nav.pf-m-tertiary,
.pf-v5-c-nav.pf-m-horizontal-subnav {
  overflow: hidden;
}

.pf-v5-c-nav.pf-m-horizontal,
.pf-v5-c-nav.pf-m-horizontal .pf-v5-c-nav__list,
.pf-v5-c-nav.pf-m-tertiary,
.pf-v5-c-nav.pf-m-tertiary .pf-v5-c-nav__list,
.pf-v5-c-nav.pf-m-horizontal-subnav,
.pf-v5-c-nav.pf-m-horizontal-subnav .pf-v5-c-nav__list {
  position: relative;
  display: flex;
}

.pf-v5-c-nav.pf-m-horizontal .pf-v5-c-nav__list,
.pf-v5-c-nav.pf-m-tertiary .pf-v5-c-nav__list,
.pf-v5-c-nav.pf-m-horizontal-subnav .pf-v5-c-nav__list {
  --pf-v5-c-nav__list--PaddingTop: 0;
  --pf-v5-c-nav__list--PaddingBottom: 0;
  flex: 1;
  max-width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  scroll-snap-type: var(--pf-v5-c-nav__list--ScrollSnapType);
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.pf-v5-c-nav.pf-m-horizontal .pf-v5-c-nav__list::-webkit-scrollbar,
.pf-v5-c-nav.pf-m-tertiary .pf-v5-c-nav__list::-webkit-scrollbar,
.pf-v5-c-nav.pf-m-horizontal-subnav .pf-v5-c-nav__list::-webkit-scrollbar {
  display: none;
}

.pf-v5-c-nav.pf-m-horizontal .pf-v5-c-nav__item,
.pf-v5-c-nav.pf-m-tertiary .pf-v5-c-nav__item,
.pf-v5-c-nav.pf-m-horizontal-subnav .pf-v5-c-nav__item {
  display: flex;
  scroll-snap-align: var(--pf-v5-c-nav__item--ScrollSnapAlign);
}

.pf-v5-c-nav.pf-m-horizontal .pf-v5-c-nav__link,
.pf-v5-c-nav.pf-m-tertiary .pf-v5-c-nav__link,
.pf-v5-c-nav.pf-m-horizontal-subnav .pf-v5-c-nav__link {
  align-items: center;
  align-self: stretch;
  white-space: nowrap;
}

.pf-v5-c-nav.pf-m-horizontal .pf-v5-c-nav__link::before,
.pf-v5-c-nav.pf-m-tertiary .pf-v5-c-nav__link::before,
.pf-v5-c-nav.pf-m-horizontal-subnav .pf-v5-c-nav__link::before {
  inset-block-start: auto;
  inset-block-end: 0;
}

.pf-v5-c-nav.pf-m-horizontal .pf-v5-c-nav__link::before {
  inset-inline-start: var(--pf-v5-c-nav--m-horizontal__link--Left);
  inset-inline-end: var(--pf-v5-c-nav--m-horizontal__link--Right);
}

.pf-v5-c-nav.pf-m-tertiary .pf-v5-c-nav__link::before {
  inset-inline-start: var(--pf-v5-c-nav--m-tertiary__link--Left);
  inset-inline-end: var(--pf-v5-c-nav--m-tertiary__link--Right);
}

.pf-v5-c-nav.pf-m-light {
  --pf-v5-c-nav__item--before--BorderColor: var(--pf-v5-c-nav--m-light__item--before--BorderColor);
  --pf-v5-c-nav__item--m-current--not--m-expanded__link--BackgroundColor: var(--pf-v5-c-nav--m-light__item--m-current--not--m-expanded__link--BackgroundColor);
  --pf-v5-c-nav__link--Color: var(--pf-v5-c-nav--m-light__link--Color);
  --pf-v5-c-nav__link--hover--Color: var(--pf-v5-c-nav--m-light__link--hover--Color);
  --pf-v5-c-nav__link--focus--Color: var(--pf-v5-c-nav--m-light__link--focus--Color);
  --pf-v5-c-nav__link--active--Color: var(--pf-v5-c-nav--m-light__link--active--Color);
  --pf-v5-c-nav__link--m-current--Color: var(--pf-v5-c-nav--m-light__link--m-current--Color);
  --pf-v5-c-nav__link--hover--BackgroundColor: var(--pf-v5-c-nav--m-light__link--hover--BackgroundColor);
  --pf-v5-c-nav__link--focus--BackgroundColor: var(--pf-v5-c-nav--m-light__link--focus--BackgroundColor);
  --pf-v5-c-nav__link--active--BackgroundColor: var(--pf-v5-c-nav--m-light__link--active--BackgroundColor);
  --pf-v5-c-nav__link--m-current--BackgroundColor: var(--pf-v5-c-nav--m-light__link--m-current--BackgroundColor);
  --pf-v5-c-nav__link--before--BorderColor: var(--pf-v5-c-nav--m-light__link--before--BorderColor);
  --pf-v5-c-nav__link--after--BorderColor: var(--pf-v5-c-nav--m-light__link--after--BorderColor);
  --pf-v5-c-nav__link--m-current--after--BorderColor: var(--pf-v5-c-nav--m-light__link--m-current--after--BorderColor);
  --pf-v5-c-nav__subnav__link--hover--after--BorderColor: var(--pf-v5-c-nav--m-light__subnav__link--hover--after--BorderColor);
  --pf-v5-c-nav__subnav__link--focus--after--BorderColor: var(--pf-v5-c-nav--m-light__subnav__link--focus--after--BorderColor);
  --pf-v5-c-nav__subnav__link--active--after--BorderColor: var(--pf-v5-c-nav--m-light__subnav__link--active--after--BorderColor);
  --pf-v5-c-nav__subnav__link--m-current--after--BorderColor: var(--pf-v5-c-nav--m-light__subnav__link--m-current--after--BorderColor);
  --pf-v5-c-nav__section-title--Color: var(--pf-v5-c-nav--m-light__section-title--Color);
  --pf-v5-c-nav__section-title--BorderBottomColor: var(--pf-v5-c-nav--m-light__section-title--BorderBottomColor);
}

.pf-v5-c-nav.pf-m-light .pf-v5-c-divider {
  --pf-v5-c-divider--after--BackgroundColor: var(--pf-v5-c-nav--m-light--c-divider--BackgroundColor);
}

.pf-v5-c-nav.pf-m-horizontal {
  --pf-v5-c-nav__link--PaddingTop: var(--pf-v5-c-nav--m-horizontal__link--PaddingTop);
  --pf-v5-c-nav__link--PaddingRight: var(--pf-v5-c-nav--m-horizontal__link--PaddingRight);
  --pf-v5-c-nav__link--PaddingBottom: var(--pf-v5-c-nav--m-horizontal__link--PaddingBottom);
  --pf-v5-c-nav__link--PaddingLeft: var(--pf-v5-c-nav--m-horizontal__link--PaddingLeft);
  --pf-v5-c-nav__link--Right: var(--pf-v5-c-nav--m-horizontal__link--Right);
  --pf-v5-c-nav__link--Left: var(--pf-v5-c-nav--m-horizontal__link--Left);
  --pf-v5-c-nav__link--Color: var(--pf-v5-c-nav--m-horizontal__link--Color);
  --pf-v5-c-nav__link--hover--Color: var(--pf-v5-c-nav--m-horizontal__link--hover--Color);
  --pf-v5-c-nav__link--active--Color: var(--pf-v5-c-nav--m-horizontal__link--active--Color);
  --pf-v5-c-nav__link--focus--Color: var(--pf-v5-c-nav--m-horizontal__link--focus--Color);
  --pf-v5-c-nav__link--m-current--Color: var(--pf-v5-c-nav--m-horizontal__link--m-current--Color);
  --pf-v5-c-nav__link--BackgroundColor: var(--pf-v5-c-nav--m-horizontal__link--BackgroundColor);
  --pf-v5-c-nav__link--hover--BackgroundColor: var(--pf-v5-c-nav--m-horizontal__link--hover--BackgroundColor);
  --pf-v5-c-nav__link--focus--BackgroundColor: var(--pf-v5-c-nav--m-horizontal__link--focus--BackgroundColor);
  --pf-v5-c-nav__link--active--BackgroundColor: var(--pf-v5-c-nav--m-horizontal__link--active--BackgroundColor);
  --pf-v5-c-nav__link--m-current--BackgroundColor: var(--pf-v5-c-nav--m-horizontal__link--m-current--BackgroundColor);
  --pf-v5-c-nav__link--before--BorderColor: var(--pf-v5-c-nav--m-horizontal__link--before--BorderColor);
  --pf-v5-c-nav__link--before--BorderBottomWidth: var(--pf-v5-c-nav--m-horizontal__link--before--BorderWidth);
  --pf-v5-c-nav__link--hover--before--BorderBottomWidth: var(--pf-v5-c-nav--m-horizontal__link--hover--before--BorderWidth);
  --pf-v5-c-nav__link--focus--before--BorderBottomWidth: var(--pf-v5-c-nav--m-horizontal__link--focus--before--BorderWidth);
  --pf-v5-c-nav__link--active--before--BorderBottomWidth: var(--pf-v5-c-nav--m-horizontal__link--active--before--BorderWidth);
  --pf-v5-c-nav__link--m-current--before--BorderBottomWidth: var(--pf-v5-c-nav--m-horizontal__link--m-current--before--BorderWidth);
}

.pf-v5-c-nav.pf-m-tertiary {
  --pf-v5-c-nav__link--PaddingTop: var(--pf-v5-c-nav--m-tertiary__link--PaddingTop);
  --pf-v5-c-nav__link--PaddingRight: var(--pf-v5-c-nav--m-tertiary__link--PaddingRight);
  --pf-v5-c-nav__link--PaddingBottom: var(--pf-v5-c-nav--m-tertiary__link--PaddingBottom);
  --pf-v5-c-nav__link--PaddingLeft: var(--pf-v5-c-nav--m-tertiary__link--PaddingLeft);
  --pf-v5-c-nav__link--Right: var(--pf-v5-c-nav--m-tertiary__link--Right);
  --pf-v5-c-nav__link--Left: var(--pf-v5-c-nav--m-tertiary__link--Left);
  --pf-v5-c-nav__link--Color: var(--pf-v5-c-nav--m-tertiary__link--Color);
  --pf-v5-c-nav__link--hover--Color: var(--pf-v5-c-nav--m-tertiary__link--hover--Color);
  --pf-v5-c-nav__link--active--Color: var(--pf-v5-c-nav--m-tertiary__link--active--Color);
  --pf-v5-c-nav__link--focus--Color: var(--pf-v5-c-nav--m-tertiary__link--focus--Color);
  --pf-v5-c-nav__link--m-current--Color: var(--pf-v5-c-nav--m-tertiary__link--m-current--Color);
  --pf-v5-c-nav__link--BackgroundColor: var(--pf-v5-c-nav--m-tertiary__link--BackgroundColor);
  --pf-v5-c-nav__link--hover--BackgroundColor: var(--pf-v5-c-nav--m-tertiary__link--hover--BackgroundColor);
  --pf-v5-c-nav__link--focus--BackgroundColor: var(--pf-v5-c-nav--m-tertiary__link--focus--BackgroundColor);
  --pf-v5-c-nav__link--active--BackgroundColor: var(--pf-v5-c-nav--m-tertiary__link--active--BackgroundColor);
  --pf-v5-c-nav__link--m-current--BackgroundColor: var(--pf-v5-c-nav--m-tertiary__link--m-current--BackgroundColor);
  --pf-v5-c-nav__link--before--BorderColor: var(--pf-v5-c-nav--m-tertiary__link--before--BorderColor);
  --pf-v5-c-nav__link--before--BorderBottomWidth: var(--pf-v5-c-nav--m-tertiary__link--before--BorderWidth);
  --pf-v5-c-nav__link--hover--before--BorderBottomWidth: var(--pf-v5-c-nav--m-tertiary__link--hover--before--BorderWidth);
  --pf-v5-c-nav__link--focus--before--BorderBottomWidth: var(--pf-v5-c-nav--m-tertiary__link--focus--before--BorderWidth);
  --pf-v5-c-nav__link--active--before--BorderBottomWidth: var(--pf-v5-c-nav--m-tertiary__link--active--before--BorderWidth);
  --pf-v5-c-nav__link--m-current--before--BorderBottomWidth: var(--pf-v5-c-nav--m-tertiary__link--m-current--before--BorderWidth);
  --pf-v5-c-nav__scroll-button--Color: var(--pf-v5-c-nav--m-tertiary__scroll-button--Color);
  --pf-v5-c-nav__scroll-button--hover--Color: var(--pf-v5-c-nav--m-tertiary__scroll-button--hover--Color);
  --pf-v5-c-nav__scroll-button--focus--Color: var(--pf-v5-c-nav--m-tertiary__scroll-button--focus--Color);
  --pf-v5-c-nav__scroll-button--active--Color: var(--pf-v5-c-nav--m-tertiary__scroll-button--active--Color);
  --pf-v5-c-nav__scroll-button--disabled--Color: var(--pf-v5-c-nav--m-tertiary__scroll-button--disabled--Color);
  --pf-v5-c-nav__scroll-button--before--BorderColor: var(--pf-v5-c-nav--m-tertiary__scroll-button--before--BorderColor);
  --pf-v5-c-nav__scroll-button--disabled--before--BorderColor: var(--pf-v5-c-nav--m-tertiary__scroll-button--disabled--before--BorderColor);
}

.pf-v5-c-nav.pf-m-horizontal-subnav {
  --pf-v5-c-nav__link--FontSize: var(--pf-v5-c-nav--m-horizontal-subnav__link--FontSize);
  --pf-v5-c-nav__link--PaddingTop: var(--pf-v5-c-nav--m-horizontal-subnav__link--PaddingTop);
  --pf-v5-c-nav__link--PaddingRight: var(--pf-v5-c-nav--m-horizontal-subnav__link--PaddingRight);
  --pf-v5-c-nav__link--PaddingBottom: var(--pf-v5-c-nav--m-horizontal-subnav__link--PaddingBottom);
  --pf-v5-c-nav__link--PaddingLeft: var(--pf-v5-c-nav--m-horizontal-subnav__link--PaddingLeft);
  --pf-v5-c-nav__link--Color: var(--pf-v5-c-nav--m-horizontal-subnav__link--Color);
  --pf-v5-c-nav__link--Right: var(--pf-v5-c-nav--m-horizontal-subnav__link--Right);
  --pf-v5-c-nav__link--Left: var(--pf-v5-c-nav--m-horizontal-subnav__link--Left);
  --pf-v5-c-nav__link--hover--Color: var(--pf-v5-c-nav--m-horizontal-subnav__link--hover--Color);
  --pf-v5-c-nav__link--active--Color: var(--pf-v5-c-nav--m-horizontal-subnav__link--active--Color);
  --pf-v5-c-nav__link--focus--Color: var(--pf-v5-c-nav--m-horizontal-subnav__link--focus--Color);
  --pf-v5-c-nav__link--m-current--Color: var(--pf-v5-c-nav--m-horizontal-subnav__link--m-current--Color);
  --pf-v5-c-nav__link--BackgroundColor: var(--pf-v5-c-nav--m-horizontal-subnav__link--BackgroundColor);
  --pf-v5-c-nav__link--hover--BackgroundColor: var(--pf-v5-c-nav--m-horizontal-subnav__link--hover--BackgroundColor);
  --pf-v5-c-nav__link--focus--BackgroundColor: var(--pf-v5-c-nav--m-horizontal-subnav__link--focus--BackgroundColor);
  --pf-v5-c-nav__link--active--BackgroundColor: var(--pf-v5-c-nav--m-horizontal-subnav__link--active--BackgroundColor);
  --pf-v5-c-nav__link--m-current--BackgroundColor: var(--pf-v5-c-nav--m-horizontal-subnav__link--m-current--BackgroundColor);
  --pf-v5-c-nav__link--before--BorderColor: var(--pf-v5-c-nav--m-horizontal-subnav__link--before--BorderColor);
  --pf-v5-c-nav__link--after--BorderColor: var(--pf-v5-c-nav--m-horizontal-subnav__link--after--BorderColor);
  --pf-v5-c-nav__link--hover--after--BorderColor: var(--pf-v5-c-nav--m-horizontal-subnav__link--hover--after--BorderColor);
  --pf-v5-c-nav__link--active--after--BorderColor: var(--pf-v5-c-nav--m-horizontal-subnav__link--active--after--BorderColor);
  --pf-v5-c-nav__link--m-current--after--BorderColor: var(--pf-v5-c-nav--m-horizontal-subnav__link--m-current--after--BorderColor);
  --pf-v5-c-nav__link--after--BorderLeftWidth: var(--pf-v5-c-nav--m-horizontal-subnav__link--after--BorderLeftWidth);
  --pf-v5-c-nav__link--hover--after--BorderLeftWidth: var(--pf-v5-c-nav--m-horizontal-subnav__link--hover--after--BorderLeftWidth);
  --pf-v5-c-nav__link--active--after--BorderLeftWidth: var(--pf-v5-c-nav--m-horizontal-subnav__link--active--after--BorderLeftWidth);
  --pf-v5-c-nav__link--m-current--after--BorderLeftWidth: var(--pf-v5-c-nav--m-horizontal-subnav__link--m-current--after--BorderLeftWidth);
}

.pf-v5-c-nav.pf-m-horizontal-subnav.pf-m-scrollable .pf-v5-c-nav__item:first-child {
  --pf-v5-c-nav__link--after--BorderLeftWidth: 0;
  --pf-v5-c-nav__link--hover--after--BorderLeftWidth: 0;
  --pf-v5-c-nav__link--active--after--BorderLeftWidth: 0;
  --pf-v5-c-nav__link--m-current--after--BorderLeftWidth: 0;
}

.pf-v5-c-nav .pf-v5-c-divider {
  --pf-v5-c-divider--after--BackgroundColor: var(--pf-v5-c-nav--c-divider--BackgroundColor);
  padding-inline-start: var(--pf-v5-c-nav--c-divider--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-nav--c-divider--PaddingRight);
  margin-block-start: var(--pf-v5-c-nav--c-divider--MarginTop);
  margin-block-end: var(--pf-v5-c-nav--c-divider--MarginBottom);
}

.pf-v5-c-nav.pf-m-scrollable .pf-v5-c-nav__scroll-button {
  opacity: 1;
}

.pf-v5-c-nav.pf-m-scrollable .pf-v5-c-nav__scroll-button:nth-of-type(1) {
  margin-inline-end: 0;
  transform: translateX(0);
}

.pf-v5-c-nav.pf-m-scrollable .pf-v5-c-nav__scroll-button:nth-of-type(2) {
  margin-inline-start: 0;
  transform: translateX(0);
}

.pf-v5-c-nav.pf-m-overflow-hidden {
  overflow: hidden;
}

.pf-v5-c-nav__list {
  position: relative;
  display: block;
  padding-block-start: var(--pf-v5-c-nav__list--PaddingTop);
  padding-block-end: var(--pf-v5-c-nav__list--PaddingBottom);
}

.pf-v5-c-nav__item {
  position: relative;
}

.pf-v5-c-nav__item.pf-m-expandable {
  --pf-v5-c-nav__link--before--BorderBottomWidth: 0;
}

.pf-v5-c-nav__item.pf-m-expandable::before {
  position: absolute;
  inset-block-end: calc(var(--pf-v5-c-nav__item--before--BorderWidth)*-1);
  inset-inline-start: 0;
  inset-inline-end: 0;
  content: "";
  border-block-end: var(--pf-v5-c-nav__item--before--BorderWidth) solid var(--pf-v5-c-nav__item--before--BorderColor);
}

.pf-v5-c-nav__item:not(:first-child) {
  margin-block-start: var(--pf-v5-c-nav__item--MarginTop);
}

.pf-v5-c-nav__item .pf-v5-c-nav__item:not(.pf-m-expanded) .pf-v5-c-nav__toggle-icon {
  transform: rotate(0);
}

.pf-v5-c-nav__item .pf-v5-c-nav__item.pf-m-expandable {
  --pf-v5-c-nav__toggle--FontSize: var(--pf-v5-c-nav__item__item__toggle--FontSize);
  --pf-v5-c-nav__link--PaddingRight: var(--pf-v5-c-nav__item__item__link--PaddingRight);
}

@media screen and (min-width: 1200px) {
  .pf-v5-c-nav__item .pf-v5-c-nav__item.pf-m-expandable::before {
    border-block-end: none;
  }
}

.pf-v5-c-nav__item .pf-v5-c-nav__item.pf-m-expandable .pf-v5-c-nav__list::before {
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  pointer-events: none;
  content: "";
  border-inline-start: var(--pf-v5-c-nav__item--before--BorderWidth) solid var(--pf-v5-c-nav__item--before--BorderColor);
}

.pf-v5-c-nav__item .pf-v5-c-nav__toggle-icon {
  transform: rotate(var(--pf-v5-c-nav__item__toggle-icon--Rotate));
}

.pf-v5-c-nav__item.pf-m-flyout:hover {
  --pf-v5-c-nav__link--BackgroundColor: var(--pf-v5-c-nav__item--m-flyout--hover__link--BackgroundColor);
  --pf-v5-c-nav__link--before--BorderBottomWidth: var(--pf-v5-c-nav__item--m-flyout--hover__link--before--BorderWidth);
}

.pf-v5-c-nav__item.pf-m-flyout:focus {
  --pf-v5-c-nav__link--BackgroundColor: var(--pf-v5-c-nav__item--m-flyout--focus__link--BackgroundColor);
  --pf-v5-c-nav__link--before--BorderBottomWidth: var(--pf-v5-c-nav__item--m-flyout--focus__link--before--BorderWidth);
}

.pf-v5-c-nav__item.pf-m-flyout:active {
  --pf-v5-c-nav__link--BackgroundColor: var(--pf-v5-c-nav__item--m-flyout--active__link--BackgroundColor);
  --pf-v5-c-nav__link--before--BorderBottomWidth: var(--pf-v5-c-nav__item--m-flyout--active__link--before--BorderWidth);
}

.pf-v5-c-nav__item.pf-m-flyout .pf-v5-c-menu {
  --pf-v5-c-menu--MinWidth: 100%;
  --pf-v5-c-menu--BoxShadow: var(--pf-v5-c-nav__item--m-flyout--c-menu--BoxShadow);
  --pf-v5-c-menu--BackgroundColor: var(--pf-v5-c-nav__item--m-flyout--c-menu--BackgroundColor);
  --pf-v5-c-menu__list--PaddingTop: 0;
  --pf-v5-c-menu__list--PaddingBottom: 0;
  --pf-v5-c-menu__list-item--hover--BackgroundColor: var(--pf-v5-c-nav__item--m-flyout--c-menu__list-item--hover--BackgroundColor);
  --pf-v5-c-menu__list-item--focus-within--BackgroundColor: var(--pf-v5-c-nav__item--m-flyout--c-menu__list-item--focus-within--BackgroundColor);
  --pf-v5-c-menu__list-item--active--BackgroundColor: var(--pf-v5-c-nav__item--m-flyout--c-menu__list-item--active--BackgroundColor);
  --pf-v5-c-menu__item--FontSize: var(--pf-v5-c-nav__item--m-flyout--c-menu__item--FontSize);
  --pf-v5-c-menu__item--Color: var(--pf-v5-c-nav__item--m-flyout--c-menu__item--Color);
  --pf-v5-c-menu__item--PaddingTop: var(--pf-v5-c-nav__item--m-flyout--c-menu__item--PaddingTop);
  --pf-v5-c-menu__item--PaddingRight: var(--pf-v5-c-nav__item--m-flyout--c-menu__item--PaddingRight);
  --pf-v5-c-menu__item--PaddingBottom: var(--pf-v5-c-nav__item--m-flyout--c-menu__item--PaddingBottom);
  --pf-v5-c-menu__item--PaddingLeft: var(--pf-v5-c-nav__item--m-flyout--c-menu__item--PaddingLeft);
  --pf-v5-c-menu__item-description--Color: var(--pf-v5-c-nav__item--m-flyout--c-menu__item-description--Color);
  --pf-v5-c-nav--c-menu--m-flyout--c-menu--Bottom: var(--pf-v5-c-nav__item--m-flyout--c-menu--m-top--Bottom);
  --pf-v5-c-nav--c-menu--m-flyout--c-menu--Right: var(--pf-v5-c-nav__item--m-flyout--c-menu--m-left--Right);
  position: absolute;
  inset-block-start: var(--pf-v5-c-nav__item--m-flyout--c-menu--Top);
  inset-inline-start: var(--pf-v5-c-nav__item--m-flyout--c-menu--Left);
}

.pf-v5-c-nav__item.pf-m-flyout .pf-v5-c-menu .pf-v5-c-menu {
  inset-block-start: var(--pf-v5-c-nav__item--m-flyout--c-menu--c-menu--Top);
}

.pf-v5-c-nav__item.pf-m-flyout .pf-v5-c-menu .pf-v5-c-menu__list-item:first-child {
  --pf-v5-c-nav__item--m-flyout--c-menu--c-menu--Top: var(--pf-v5-c-nav__item--m-flyout--c-menu__list-item--first-child--c-menu--Top);
  --pf-v5-c-nav__item--m-flyout--c-menu__item--hover--after--Top: var(--pf-v5-c-nav__item--m-flyout--c-menu__list-item--first-child__item--hover--after--Top);
}

.pf-v5-c-nav__item.pf-m-flyout .pf-v5-c-menu .pf-v5-c-menu__item {
  position: relative;
  outline-offset: var(--pf-v5-c-nav__item--m-flyout--c-menu__item--OutlineOffset);
}

.pf-v5-c-nav__item.pf-m-flyout .pf-v5-c-menu .pf-v5-c-menu__item::before {
  position: absolute;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  content: "";
  border-block-end: var(--pf-v5-c-nav__item--m-flyout--c-menu__item--before--BorderBottomWidth) solid var(--pf-v5-c-nav__item--m-flyout--c-menu__item--before--BorderBottomColor);
}

.pf-v5-c-nav__item.pf-m-flyout .pf-v5-c-menu .pf-v5-c-menu__item:hover::after {
  position: absolute;
  inset-block-start: var(--pf-v5-c-nav__item--m-flyout--c-menu__item--hover--after--Top);
  inset-block-end: 0;
  inset-inline-start: 0;
  content: "";
  border-inline-start: var(--pf-v5-c-nav__item--m-flyout--c-menu__item--hover--after--BorderLeftWidth) solid var(--pf-v5-c-nav__item--m-flyout--c-menu__item--hover--after--BorderLeftColor);
}

.pf-v5-c-nav__item.pf-m-drilldown.pf-m-expanded>.pf-v5-c-nav__subnav {
  inset-inline-start: 0;
  z-index: var(--pf-v5-c-nav__item--m-drilldown--m-expanded__subnav--ZIndex);
}

.pf-v5-c-nav__link {
  position: relative;
  display: flex;
  align-items: baseline;
  padding-block-start: var(--pf-v5-c-nav__link--PaddingTop);
  padding-block-end: var(--pf-v5-c-nav__link--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-nav__link--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-nav__link--PaddingRight);
  font-size: var(--pf-v5-c-nav__link--FontSize);
  font-weight: var(--pf-v5-c-nav__link--FontWeight);
  color: var(--pf-v5-c-nav__link--Color);
  background-color: var(--pf-v5-c-nav__link--BackgroundColor);
  outline-offset: var(--pf-v5-c-nav__link--OutlineOffset);
}

.pf-v5-c-nav__link::after,
.pf-v5-c-nav__link::before {
  position: absolute;
  content: "";
  border: 0 solid;
}

.pf-v5-c-nav__link::before {
  inset-block-end: calc(var(--pf-v5-c-nav__link--before--BorderBottomWidth)*-1);
  inset-inline-start: 0;
  inset-inline-end: 0;
  border-color: var(--pf-v5-c-nav__link--before--BorderColor);
  border-block-end-width: var(--pf-v5-c-nav__link--before--BorderBottomWidth);
}

.pf-v5-c-nav__link::after {
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  border: 0 solid;
  border-color: var(--pf-v5-c-nav__link--after--BorderColor);
  border-inline-start-width: var(--pf-v5-c-nav__link--after--BorderLeftWidth);
}

.pf-v5-c-nav__link:hover,
.pf-v5-c-nav__link.pf-m-hover {
  color: var(--pf-v5-c-nav__link--hover--Color);
  background-color: var(--pf-v5-c-nav__link--hover--BackgroundColor);
}

.pf-v5-c-nav__link:hover::before,
.pf-v5-c-nav__link.pf-m-hover::before {
  border-block-end-width: var(--pf-v5-c-nav__link--hover--before--BorderBottomWidth);
}

.pf-v5-c-nav__link:hover::after,
.pf-v5-c-nav__link.pf-m-hover::after {
  border-color: var(--pf-v5-c-nav__link--hover--after--BorderColor);
  border-inline-start-width: var(--pf-v5-c-nav__link--hover--after--BorderLeftWidth);
}

.pf-v5-c-nav__link:focus {
  color: var(--pf-v5-c-nav__link--focus--Color);
  background-color: var(--pf-v5-c-nav__link--focus--BackgroundColor);
}

.pf-v5-c-nav__link:focus::before {
  border-block-end-width: var(--pf-v5-c-nav__link--focus--before--BorderBottomWidth);
}

.pf-v5-c-nav__link:focus::after {
  border-color: var(--pf-v5-c-nav__link--focus--after--BorderColor);
  border-inline-start-width: var(--pf-v5-c-nav__link--focus--after--BorderLeftWidth);
}

.pf-v5-c-nav__link:active {
  color: var(--pf-v5-c-nav__link--active--Color);
  background-color: var(--pf-v5-c-nav__link--active--BackgroundColor);
}

.pf-v5-c-nav__link:active::before {
  border-block-end-width: var(--pf-v5-c-nav__link--active--before--BorderBottomWidth);
}

.pf-v5-c-nav__link:active::after {
  border-color: var(--pf-v5-c-nav__link--active--after--BorderColor);
  border-inline-start-width: var(--pf-v5-c-nav__link--active--after--BorderLeftWidth);
}

.pf-v5-c-nav__link.pf-m-current,
.pf-v5-c-nav__link.pf-m-current:hover,
.pf-v5-c-nav__item.pf-m-current:not(.pf-m-expanded) .pf-v5-c-nav__link {
  color: var(--pf-v5-c-nav__link--m-current--Color);
  background-color: var(--pf-v5-c-nav__link--m-current--BackgroundColor);
}

.pf-v5-c-nav__link.pf-m-current::before,
.pf-v5-c-nav__link.pf-m-current:hover::before,
.pf-v5-c-nav__item.pf-m-current:not(.pf-m-expanded) .pf-v5-c-nav__link::before {
  border-block-end-width: var(--pf-v5-c-nav__link--m-current--before--BorderBottomWidth);
}

.pf-v5-c-nav__link.pf-m-current::after,
.pf-v5-c-nav__link.pf-m-current:hover::after,
.pf-v5-c-nav__item.pf-m-current:not(.pf-m-expanded) .pf-v5-c-nav__link::after {
  border-color: var(--pf-v5-c-nav__link--m-current--after--BorderColor);
  border-inline-start-width: var(--pf-v5-c-nav__link--m-current--after--BorderLeftWidth);
}

.pf-v5-c-nav__link,
.pf-v5-c-nav__link:hover,
.pf-v5-c-nav__link:focus,
.pf-v5-c-nav__link:active {
  width: 100%;
  text-decoration: none;
  border: none;
}

.pf-v5-c-nav__subnav {
  --pf-v5-c-nav__list--PaddingTop: 0;
  --pf-v5-c-nav__list--PaddingBottom: 0;
  --pf-v5-c-nav__link--PaddingTop: var(--pf-v5-c-nav__subnav__link--PaddingTop);
  --pf-v5-c-nav__link--PaddingRight: var(--pf-v5-c-nav__subnav__link--PaddingRight);
  --pf-v5-c-nav__link--PaddingBottom: var(--pf-v5-c-nav__subnav__link--PaddingBottom);
  --pf-v5-c-nav__link--PaddingLeft: var(--pf-v5-c-nav__subnav__link--PaddingLeft);
  --pf-v5-c-nav__link--FontSize: var(--pf-v5-c-nav__subnav__link--FontSize);
  --pf-v5-c-nav__link--hover--after--BorderColor: var(--pf-v5-c-nav__subnav__link--hover--after--BorderColor);
  --pf-v5-c-nav__link--focus--after--BorderColor: var(--pf-v5-c-nav__subnav__link--focus--after--BorderColor);
  --pf-v5-c-nav__link--active--after--BorderColor: var(--pf-v5-c-nav__subnav__link--active--after--BorderColor);
  --pf-v5-c-nav__link--m-current--after--BorderColor: var(--pf-v5-c-nav__subnav__link--m-current--after--BorderColor);
  --pf-v5-c-nav__link--hover--after--BorderLeftWidth: var(--pf-v5-c-nav__subnav__link--hover--after--BorderWidth);
  --pf-v5-c-nav__link--focus--after--BorderLeftWidth: var(--pf-v5-c-nav__subnav__link--focus--after--BorderWidth);
  --pf-v5-c-nav__link--active--after--BorderLeftWidth: var(--pf-v5-c-nav__subnav__link--active--after--BorderWidth);
  --pf-v5-c-nav__link--m-current--after--BorderLeftWidth: var(--pf-v5-c-nav__subnav__link--m-current--after--BorderWidth);
  --pf-v5-c-nav--c-divider--PaddingRight: var(--pf-v5-c-nav__subnav--c-divider--PaddingRight);
  --pf-v5-c-nav--c-divider--PaddingLeft: var(--pf-v5-c-nav__subnav--c-divider--PaddingLeft);
  max-height: var(--pf-v5-c-nav__subnav--MaxHeight);
  padding-block-end: var(--pf-v5-c-nav__subnav--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-nav__subnav--PaddingLeft);
  transition: var(--pf-v5-c-nav--Transition);
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.pf-v5-c-nav__subnav .pf-v5-c-nav__subnav {
  --pf-v5-c-nav__link--FontSize: var(--pf-v5-c-nav__subnav__subnav__link--FontSize);
  --pf-v5-c-nav__link--PaddingLeft: var(--pf-v5-c-nav__subnav__subnav__link--PaddingLeft);
  --pf-v5-c-nav__subnav--PaddingLeft: var(--pf-v5-c-nav__subnav__subnav--PaddingLeft);
}

.pf-v5-c-nav__subnav.pf-m-flyout {
  width: 100%;
}

.pf-v5-c-nav__item.pf-m-expanded .pf-v5-c-nav__subnav {
  --pf-v5-c-nav__subnav--MaxHeight: var(--pf-v5-c-nav__item--m-expanded__subnav--MaxHeight);
  overflow-y: auto;
  opacity: 1;
}

.pf-v5-c-nav__subnav::-webkit-scrollbar {
  display: none;
}

.pf-v5-c-nav__toggle {
  flex: none;
  padding-inline-start: var(--pf-v5-c-nav__toggle--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-nav__toggle--PaddingRight);
  margin-inline-start: auto;
  font-size: var(--pf-v5-c-nav__toggle--FontSize);
  line-height: 1;
}

.pf-v5-c-nav__toggle.pf-m-start {
  margin-inline-start: calc(var(--pf-v5-c-nav__toggle--PaddingRight)*-1);
  margin-inline-end: var(--pf-v5-c-nav__toggle--m-start--MarginRight);
}

.pf-v5-c-nav__toggle-icon {
  display: inline-block;
  transition: var(--pf-v5-c-nav__toggle-icon--Transition);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-nav__toggle-icon {
  scale: -1 1;
}

.pf-v5-c-nav__item.pf-m-expanded .pf-v5-c-nav__toggle-icon {
  transform: rotate(var(--pf-v5-c-nav__item--m-expanded__toggle-icon--Rotate));
}

.pf-v5-c-nav__section {
  --pf-v5-c-nav__item--MarginTop: var(--pf-v5-c-nav__section__item--MarginTop);
  --pf-v5-c-nav__link--PaddingTop: var(--pf-v5-c-nav__section__link--PaddingTop);
  --pf-v5-c-nav__link--PaddingRight: var(--pf-v5-c-nav__section__link--PaddingRight);
  --pf-v5-c-nav__link--PaddingBottom: var(--pf-v5-c-nav__section__link--PaddingBottom);
  --pf-v5-c-nav__link--PaddingLeft: var(--pf-v5-c-nav__section__link--PaddingLeft);
  --pf-v5-c-nav__link--FontSize: var(--pf-v5-c-nav__section__link--FontSize);
  --pf-v5-c-nav__link--before--BorderBottomWidth: var(--pf-v5-c-nav__section__link--before--BorderBottomWidth);
  --pf-v5-c-nav__link--hover--after--BorderColor: var(--pf-v5-c-nav__section__link--hover--after--BorderColor);
  --pf-v5-c-nav__link--focus--after--BorderColor: var(--pf-v5-c-nav__section__link--focus--after--BorderColor);
  --pf-v5-c-nav__link--active--after--BorderColor: var(--pf-v5-c-nav__section__link--active--after--BorderColor);
  --pf-v5-c-nav__link--m-current--after--BorderColor: var(--pf-v5-c-nav__section__link--m-current--after--BorderColor);
  --pf-v5-c-nav__link--hover--after--BorderLeftWidth: var(--pf-v5-c-nav__section__link--hover--after--BorderWidth);
  --pf-v5-c-nav__link--focus--after--BorderLeftWidth: var(--pf-v5-c-nav__section__link--focus--after--BorderWidth);
  --pf-v5-c-nav__link--active--after--BorderLeftWidth: var(--pf-v5-c-nav__section__link--active--after--BorderWidth);
  --pf-v5-c-nav__link--m-current--after--BorderLeftWidth: var(--pf-v5-c-nav__section__link--m-current--after--BorderWidth);
  --pf-v5-c-nav__list--PaddingTop: 0;
  --pf-v5-c-nav__list--PaddingBottom: 0;
  --pf-v5-c-nav--c-divider--MarginBottom: 0;
}

.pf-v5-c-nav__section:first-child {
  padding-block-start: var(--pf-v5-c-nav__section--first-child--PaddingTop);
}

.pf-v5-c-nav__section+.pf-v5-c-nav__section {
  margin-block-start: var(--pf-v5-c-nav__section--section--MarginTop);
}

.pf-v5-c-nav__section:last-child {
  padding-block-end: var(--pf-v5-c-nav__section--last-child--PaddingBottom);
}

.pf-v5-c-nav__section-title {
  padding-block-start: var(--pf-v5-c-nav__section-title--PaddingTop);
  padding-block-end: var(--pf-v5-c-nav__section-title--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-nav__section-title--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-nav__section-title--PaddingRight);
  margin-block-end: var(--pf-v5-c-nav__section-title--MarginBottom);
  font-size: var(--pf-v5-c-nav__section-title--FontSize);
  color: var(--pf-v5-c-nav__section-title--Color);
  border-block-end: var(--pf-v5-c-nav__section-title--BorderBottomWidth) solid var(--pf-v5-c-nav__section-title--BorderBottomColor);
}

.pf-v5-c-nav__scroll-button {
  flex: none;
  width: var(--pf-v5-c-nav__scroll-button--Width);
  color: var(--pf-v5-c-nav__scroll-button--Color);
  background-color: var(--pf-v5-c-nav__scroll-button--BackgroundColor);
  border: 0;
  outline-offset: var(--pf-v5-c-nav__scroll-button--OutlineOffset);
  opacity: 0;
  transition: var(--pf-v5-c-nav__scroll-button--Transition);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-nav__scroll-button>* {
  scale: -1 1;
}

.pf-v5-c-nav__scroll-button::before {
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  border: 0 solid var(--pf-v5-c-nav__scroll-button--before--BorderColor);
  border-inline-start-width: var(--pf-v5-c-nav__scroll-button--before--BorderLeftWidth);
  border-inline-end-width: var(--pf-v5-c-nav__scroll-button--before--BorderRightWidth);
}

.pf-v5-c-nav__scroll-button:hover {
  color: var(--pf-v5-c-nav__scroll-button--hover--Color);
}

.pf-v5-c-nav__scroll-button:focus {
  color: var(--pf-v5-c-nav__scroll-button--focus--Color);
}

.pf-v5-c-nav__scroll-button:active {
  color: var(--pf-v5-c-nav__scroll-button--active--Color);
}

.pf-v5-c-nav__scroll-button:disabled {
  color: var(--pf-v5-c-nav__scroll-button--disabled--Color);
  border-color: var(--pf-v5-c-nav__scroll-button--disabled--before--BorderColor);
}

.pf-v5-c-nav__scroll-button:nth-of-type(1) {
  --pf-v5-c-nav__scroll-button--before--BorderRightWidth: var(--pf-v5-c-nav__scroll-button--before--BorderWidth);
  margin-inline-end: calc(var(--pf-v5-c-nav__scroll-button--Width)*-1);
  transform: translateX(-100%);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-nav__scroll-button:nth-of-type(1) {
  transform: translateX(calc(-100% * var(--pf-v5-global--inverse--multiplier)));
}

.pf-v5-c-nav__scroll-button:nth-of-type(1)::before {
  inset-inline-end: 0;
}

.pf-v5-c-nav__scroll-button:nth-of-type(2) {
  --pf-v5-c-nav__scroll-button--before--BorderLeftWidth: var(--pf-v5-c-nav__scroll-button--before--BorderWidth);
  margin-inline-start: calc(var(--pf-v5-c-nav__scroll-button--Width)*-1);
  transform: translateX(100%);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-nav__scroll-button:nth-of-type(2) {
  transform: translateX(calc(100% * var(--pf-v5-global--inverse--multiplier)));
}

.pf-v5-c-nav__scroll-button:nth-of-type(2)::before {
  inset-inline-start: 0;
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-nav {
  --pf-v5-c-nav__item--item__link--after--Top: -1px;
  --pf-v5-c-nav__item--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-nav__link--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-nav__link--focus--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-nav__link--active--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-nav__link--m-current--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-nav__link--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-nav--m-tertiary__scroll-button--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-nav__section-title--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-nav__section-title--BorderBottomColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-nav__scroll-button--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-nav--c-divider--BackgroundColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-nav__link--hover--before--BorderBottomWidth: 0;
  --pf-v5-c-nav__link--focus--before--BorderBottomWidth: 0;
  --pf-v5-c-nav__link--active--before--BorderBottomWidth: 0;
  --pf-v5-c-nav__link--m-current--before--BorderBottomWidth: 0;
}

:where(.pf-v5-theme-dark) .pf-v5-c-nav__link::before {
  inset-block-end: 0;
}

:where(.pf-v5-theme-dark) .pf-v5-c-nav__link::after {
  inset-block-start: var(--pf-v5-c-nav__item--item__link--after--Top);
}

:where(.pf-v5-theme-dark) .pf-v5-c-nav__item+.pf-v5-c-nav__item {
  --pf-v5-c-nav__link--after--Top: var(--pf-v5-c-nav__item--item__link--after--Top);
}

:where(.pf-v5-theme-dark) .pf-v5-c-nav__subnav {
  --pf-v5-c-nav__item--item__link--after--Top: 0;
}

:where(.pf-v5-theme-dark) .pf-v5-c-nav__section {
  --pf-v5-c-nav__item--item__link--after--Top: 0;
}

.pf-v5-c-notification-badge {
  --pf-v5-c-notification-badge--PaddingTop: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-notification-badge--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-notification-badge--PaddingBottom: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-notification-badge--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-notification-badge--MarginTop: calc(-1 * var(--pf-v5-global--spacer--form-element));
  --pf-v5-c-notification-badge--MarginRight: calc(-1 * var(--pf-v5-global--spacer--md));
  --pf-v5-c-notification-badge--MarginBottom: calc(-1 * var(--pf-v5-global--spacer--form-element));
  --pf-v5-c-notification-badge--MarginLeft: calc(-1 * var(--pf-v5-global--spacer--md));
  --pf-v5-c-notification-badge--after--BorderColor: transparent;
  --pf-v5-c-notification-badge--after--BorderRadius: var(--pf-v5-global--BorderRadius--sm);
  --pf-v5-c-notification-badge--after--BorderWidth: 0;
  --pf-v5-c-notification-badge--after--Top: 0;
  --pf-v5-c-notification-badge--after--Right: 0;
  --pf-v5-c-notification-badge--after--Width: auto;
  --pf-v5-c-notification-badge--after--Height: auto;
  --pf-v5-c-notification-badge--after--BackgroundColor: transparent;
  --pf-v5-c-notification-badge--after--TranslateX: 0;
  --pf-v5-c-notification-badge--after--TranslateY: 0;
  --pf-v5-c-notification-badge__i--Width: auto;
  --pf-v5-c-notification-badge__i--Height: auto;
  --pf-v5-c-notification-badge--m-read--after--BorderColor: transparent;
  --pf-v5-c-notification-badge--m-read--after--BackgroundColor: transparent;
  --pf-v5-c-notification-badge--m-read--m-expanded--after--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-notification-badge--m-unread--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-notification-badge--m-unread--after--BackgroundColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-notification-badge--m-unread--hover--after--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-notification-badge--m-unread--m-expanded--after--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-notification-badge--m-attention--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-notification-badge--m-attention--after--BackgroundColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-notification-badge--m-attention--hover--after--BackgroundColor: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-notification-badge--m-attention--m-expanded--after--BackgroundColor: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-notification-badge__count--MarginLeft: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-notification-badge--pf-v5-pficon-attention-bell--LineHeight: var(--pf-v5-global--LineHeight--sm);
  position: relative;
  display: inline-block;
  padding-block-start: var(--pf-v5-c-notification-badge--PaddingTop);
  padding-block-end: var(--pf-v5-c-notification-badge--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-notification-badge--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-notification-badge--PaddingRight);
  margin-block-start: var(--pf-v5-c-notification-badge--MarginTop);
  margin-block-end: var(--pf-v5-c-notification-badge--MarginBottom);
  margin-inline-start: var(--pf-v5-c-notification-badge--MarginLeft);
  margin-inline-end: var(--pf-v5-c-notification-badge--MarginRight);
  background-color: var(--pf-v5-c-notification-badge--after--BackgroundColor);
  border-radius: var(--pf-v5-c-notification-badge--after--BorderRadius);
}

.pf-v5-c-notification-badge::before {
  position: absolute;
  inset-block-start: var(--pf-v5-c-notification-badge--after--Top);
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: var(--pf-v5-c-notification-badge--after--Right);
  width: var(--pf-v5-c-notification-badge--after--Width);
  height: var(--pf-v5-c-notification-badge--after--Height);
  content: "";
  border: var(--pf-v5-c-notification-badge--after--BorderWidth) solid var(--pf-v5-c-notification-badge--after--BorderColor);
  border-radius: var(--pf-v5-c-notification-badge--after--BorderRadius);
  transform: translate(var(--pf-v5-c-notification-badge--after--TranslateX), var(--pf-v5-c-notification-badge--after--TranslateY));
}

.pf-v5-c-notification-badge>i {
  width: var(--pf-v5-c-notification-badge__i--Width);
  height: var(--pf-v5-c-notification-badge__i--Height);
}

.pf-v5-c-notification-badge>* {
  position: relative;
}

.pf-v5-c-notification-badge .pf-v5-c-icon-attention-bell,
.pf-v5-c-notification-badge .pf-v5-c-icon-bell {
  display: inline-block;
  line-height: var(--pf-v5-c-notification-badge--pf-v5-pficon-attention-bell--LineHeight);
}

.pf-v5-c-notification-badge .pf-v5-c-icon-attention-bell::before,
.pf-v5-c-notification-badge .pf-v5-c-icon-bell::before {
  vertical-align: bottom;
}

.pf-v5-c-notification-badge.pf-m-read {
  --pf-v5-c-notification-badge--after--BackgroundColor: var(--pf-v5-c-notification-badge--m-read--after--BackgroundColor);
  --pf-v5-c-notification-badge--after--BorderColor: var(--pf-v5-c-notification-badge--m-read--after--BorderColor);
  color: var(--pf-v5-c-notification-badge--m-read--Color, inherit);
}

.pf-v5-c-notification-badge.pf-m-unread {
  --pf-v5-c-notification-badge--after--BackgroundColor: var(--pf-v5-c-notification-badge--m-unread--after--BackgroundColor);
  color: var(--pf-v5-c-notification-badge--m-unread--Color);
}

.pf-v5-c-notification-badge.pf-m-unread:hover {
  --pf-v5-c-notification-badge--after--BackgroundColor: var(--pf-v5-c-notification-badge--m-unread--hover--after--BackgroundColor);
}

.pf-v5-c-notification-badge.pf-m-attention {
  --pf-v5-c-notification-badge--after--BackgroundColor: var(--pf-v5-c-notification-badge--m-attention--after--BackgroundColor);
  color: var(--pf-v5-c-notification-badge--m-attention--Color);
}

.pf-v5-c-notification-badge.pf-m-attention:hover {
  --pf-v5-c-notification-badge--m-attention--after--BackgroundColor: var(--pf-v5-c-notification-badge--m-attention--hover--after--BackgroundColor);
}

.pf-v5-c-notification-badge.pf-m-expanded {
  --pf-v5-c-notification-badge--m-read--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-notification-badge--m-read--after--BackgroundColor: var(--pf-v5-c-notification-badge--m-read--m-expanded--after--BackgroundColor);
  --pf-v5-c-notification-badge--m-unread--after--BackgroundColor: var(--pf-v5-c-notification-badge--m-unread--m-expanded--after--BackgroundColor);
  --pf-v5-c-notification-badge--m-attention--after--BackgroundColor: var(--pf-v5-c-notification-badge--m-attention--m-expanded--after--BackgroundColor);
}

.pf-v5-c-notification-badge__count {
  margin-inline-start: var(--pf-v5-c-notification-badge__count--MarginLeft);
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-notification-badge {
  --pf-v5-c-notification-badge--m-read--m-expanded--after--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-notification-badge--m-unread--Color: var(--pf-v5-global--primary-color--400);
  --pf-v5-c-notification-badge--m-unread--after--BackgroundColor: var(--pf-v5-global--primary-color--300);
  --pf-v5-c-notification-badge--m-attention--Color: var(--pf-v5-global--palette--black-900);
}

.pf-v5-c-notification-drawer {
  --pf-v5-c-notification-drawer--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-notification-drawer__header--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-notification-drawer__header--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-notification-drawer__header--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-notification-drawer__header--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-notification-drawer__header--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-notification-drawer__header--BoxShadow: var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-notification-drawer__header--ZIndex: var(--pf-v5-global--ZIndex--sm);
  --pf-v5-c-notification-drawer__header-title--FontSize: var(--pf-v5-global--FontSize--xl);
  --pf-v5-c-notification-drawer__header-status--MarginLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-notification-drawer__body--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-notification-drawer__list-item--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-notification-drawer__list-item--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-notification-drawer__list-item--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-notification-drawer__list-item--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-notification-drawer__list-item--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-notification-drawer__list-item--BoxShadow: inset var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-notification-drawer__list-item--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-notification-drawer__list-item--BorderBottomColor: transparent;
  --pf-v5-c-notification-drawer__list-item--OutlineOffset: -0.25rem;
  --pf-v5-c-notification-drawer__list-item--before--Width: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-notification-drawer__list-item--before--Top: 0;
  --pf-v5-c-notification-drawer__list-item--before--Bottom: calc(var(--pf-v5-c-notification-drawer__list-item--BorderBottomWidth) * -1);
  --pf-v5-c-notification-drawer__list-item--m-info__list-item-header-icon--Color: var(--pf-v5-global--info-color--100);
  --pf-v5-c-notification-drawer__list-item--m-info__list-item--before--BackgroundColor: var(--pf-v5-global--info-color--100);
  --pf-v5-c-notification-drawer__list-item--m-warning__list-item-header-icon--Color: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-notification-drawer__list-item--m-warning__list-item--before--BackgroundColor: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-notification-drawer__list-item--m-danger__list-item-header-icon--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-notification-drawer__list-item--m-danger__list-item--before--BackgroundColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-notification-drawer__list-item--m-success__list-item-header-icon--Color: var(--pf-v5-global--success-color--100);
  --pf-v5-c-notification-drawer__list-item--m-success__list-item--before--BackgroundColor: var(--pf-v5-global--success-color--100);
  --pf-v5-c-notification-drawer__list-item--m-custom__list-item-header-icon--Color: var(--pf-v5-global--custom-color--200);
  --pf-v5-c-notification-drawer__list-item--m-custom__list-item--before--BackgroundColor: var(--pf-v5-global--custom-color--200);
  --pf-v5-c-notification-drawer__list-item--m-read--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-notification-drawer__list-item--m-read--BorderBottomColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-notification-drawer__list-item--m-read--before--Top: calc(var(--pf-v5-c-notification-drawer__list-item--BorderBottomWidth) * -1);
  --pf-v5-c-notification-drawer__list-item--m-read--before--Bottom: 0;
  --pf-v5-c-notification-drawer__list-item--m-read--before--BackgroundColor: transparent;
  --pf-v5-c-notification-drawer__list-item--list-item--m-read--before--Top: 0;
  --pf-v5-c-notification-drawer__list-item--list-item--m-read--BoxShadow: inset var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-notification-drawer__list-item--m-hoverable--hover--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-notification-drawer__list-item--m-hoverable--hover--BoxShadow: var(--pf-v5-global--BoxShadow--md-top), var(--pf-v5-global--BoxShadow--md-bottom);
  --pf-v5-c-notification-drawer__list-item-header--MarginBottom: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-notification-drawer__list-item-header-icon--Color: inherit;
  --pf-v5-c-notification-drawer__list-item-header-icon--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-notification-drawer__list-item-header-title--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-notification-drawer__list-item-header-title--max-lines: 1;
  --pf-v5-c-notification-drawer__list-item--m-read__list-item-header-title--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-notification-drawer__list-item-description--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-notification-drawer__list-item-timestamp--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-notification-drawer__list-item-timestamp--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-notification-drawer__group--m-expanded--group--BorderTopWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-notification-drawer__group--m-expanded--group--BorderTopColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-notification-drawer__group--m-expanded--MinHeight: 0;
  --pf-v5-c-notification-drawer__group-toggle--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-notification-drawer__group-toggle--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-notification-drawer__group-toggle--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-notification-drawer__group-toggle--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-notification-drawer__group-toggle--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-notification-drawer__group-toggle--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-notification-drawer__group-toggle--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-notification-drawer__group-toggle--OutlineOffset: -0.25rem;
  --pf-v5-c-notification-drawer__group-toggle-title--MarginRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-notification-drawer__group-toggle-title--max-lines: 1;
  --pf-v5-c-notification-drawer__group-toggle-count--MarginRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-notification-drawer__group-toggle-icon--MarginRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-notification-drawer__group-toggle-icon--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-notification-drawer__group-toggle-icon--Transition: .2s ease-in 0s;
  --pf-v5-c-notification-drawer__group--m-expanded__group-toggle-icon--Rotate: 90deg;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--pf-v5-c-notification-drawer--BackgroundColor);
}

.pf-v5-c-notification-drawer__header {
  position: relative;
  z-index: var(--pf-v5-c-notification-drawer__header--ZIndex);
  display: flex;
  flex-shrink: 0;
  align-items: baseline;
  padding-block-start: var(--pf-v5-c-notification-drawer__header--PaddingTop);
  padding-block-end: var(--pf-v5-c-notification-drawer__header--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-notification-drawer__header--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-notification-drawer__header--PaddingRight);
  background-color: var(--pf-v5-c-notification-drawer__header--BackgroundColor);
  box-shadow: var(--pf-v5-c-notification-drawer__header--BoxShadow);
}

.pf-v5-c-notification-drawer__header-title {
  font-size: var(--pf-v5-c-notification-drawer__header-title--FontSize);
}

.pf-v5-c-notification-drawer__header-status {
  margin-inline-start: var(--pf-v5-c-notification-drawer__header-status--MarginLeft);
}

.pf-v5-c-notification-drawer__header-action {
  display: flex;
  align-items: center;
  margin-inline-start: auto;
}

.pf-v5-c-notification-drawer__body {
  flex: 1;
  overflow-y: auto;
  box-shadow: var(--pf-v5-c-notification-drawer__body--ZIndex);
}

.pf-v5-c-notification-drawer__list-item {
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
  padding-block-start: var(--pf-v5-c-notification-drawer__list-item--PaddingTop);
  padding-block-end: var(--pf-v5-c-notification-drawer__list-item--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-notification-drawer__list-item--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-notification-drawer__list-item--PaddingRight);
  background-color: var(--pf-v5-c-notification-drawer__list-item--BackgroundColor);
  border-block-end: var(--pf-v5-c-notification-drawer__list-item--BorderBottomWidth) solid var(--pf-v5-c-notification-drawer__list-item--BorderBottomColor);
  outline-offset: var(--pf-v5-c-notification-drawer__list-item--OutlineOffset);
  box-shadow: var(--pf-v5-c-notification-drawer__list-item--BoxShadow);
}

.pf-v5-c-notification-drawer__list-item.pf-m-read,
.pf-v5-c-notification-drawer__list-item:first-child {
  --pf-v5-c-notification-drawer__list-item--BoxShadow: none;
}

.pf-v5-c-notification-drawer__list-item:not(.pf-m-read)+.pf-v5-c-notification-drawer__list-item.pf-m-read {
  --pf-v5-c-notification-drawer__list-item--BoxShadow: var(--pf-v5-c-notification-drawer__list-item--list-item--m-read--BoxShadow);
  --pf-v5-c-notification-drawer__list-item--before--Top: var(--pf-v5-c-notification-drawer__list-item--list-item--m-read--before--Top);
}

.pf-v5-c-notification-drawer__list-item::before {
  position: absolute;
  inset-block-start: var(--pf-v5-c-notification-drawer__list-item--before--Top);
  inset-block-end: var(--pf-v5-c-notification-drawer__list-item--before--Bottom);
  width: var(--pf-v5-c-notification-drawer__list-item--before--Width);
  content: "";
  background-color: var(--pf-v5-c-notification-drawer__list-item--before--BackgroundColor);
}

.pf-v5-c-notification-drawer__list-item.pf-m-info {
  --pf-v5-c-notification-drawer__list-item--before--BackgroundColor: var(--pf-v5-c-notification-drawer__list-item--m-info__list-item--before--BackgroundColor);
  --pf-v5-c-notification-drawer__list-item-header-icon--Color: var(--pf-v5-c-notification-drawer__list-item--m-info__list-item-header-icon--Color);
}

.pf-v5-c-notification-drawer__list-item.pf-m-warning {
  --pf-v5-c-notification-drawer__list-item--before--BackgroundColor: var(--pf-v5-c-notification-drawer__list-item--m-warning__list-item--before--BackgroundColor);
  --pf-v5-c-notification-drawer__list-item-header-icon--Color: var(--pf-v5-c-notification-drawer__list-item--m-warning__list-item-header-icon--Color);
}

.pf-v5-c-notification-drawer__list-item.pf-m-danger {
  --pf-v5-c-notification-drawer__list-item--before--BackgroundColor: var(--pf-v5-c-notification-drawer__list-item--m-danger__list-item--before--BackgroundColor);
  --pf-v5-c-notification-drawer__list-item-header-icon--Color: var(--pf-v5-c-notification-drawer__list-item--m-danger__list-item-header-icon--Color);
}

.pf-v5-c-notification-drawer__list-item.pf-m-success {
  --pf-v5-c-notification-drawer__list-item--before--BackgroundColor: var(--pf-v5-c-notification-drawer__list-item--m-success__list-item--before--BackgroundColor);
  --pf-v5-c-notification-drawer__list-item-header-icon--Color: var(--pf-v5-c-notification-drawer__list-item--m-success__list-item-header-icon--Color);
}

.pf-v5-c-notification-drawer__list-item.pf-m-custom {
  --pf-v5-c-notification-drawer__list-item--before--BackgroundColor: var(--pf-v5-c-notification-drawer__list-item--m-custom__list-item--before--BackgroundColor);
  --pf-v5-c-notification-drawer__list-item-header-icon--Color: var(--pf-v5-c-notification-drawer__list-item--m-custom__list-item-header-icon--Color);
}

.pf-v5-c-notification-drawer__list-item.pf-m-read {
  --pf-v5-c-notification-drawer__list-item--BorderBottomColor: var(--pf-v5-c-notification-drawer__list-item--m-read--BorderBottomColor);
  --pf-v5-c-notification-drawer__list-item--BackgroundColor: var(--pf-v5-c-notification-drawer__list-item--m-read--BackgroundColor);
  --pf-v5-c-notification-drawer__list-item--before--Top: var(--pf-v5-c-notification-drawer__list-item--m-read--before--Top);
  --pf-v5-c-notification-drawer__list-item--before--Bottom: var(--pf-v5-c-notification-drawer__list-item--m-read--before--Bottom);
  --pf-v5-c-notification-drawer__list-item--before--BackgroundColor: var(--pf-v5-c-notification-drawer__list-item--m-read--before--BackgroundColor);
  --pf-v5-c-notification-drawer__list-item-header-title--FontWeight: var(--pf-v5-c-notification-drawer__list-item--m-read__list-item-header-title--FontWeight);
  position: relative;
}

.pf-v5-c-notification-drawer__list-item.pf-m-hoverable {
  cursor: pointer;
}

.pf-v5-c-notification-drawer__list-item.pf-m-hoverable:hover {
  z-index: var(--pf-v5-c-notification-drawer__list-item--m-hoverable--hover--ZIndex);
  box-shadow: var(--pf-v5-c-notification-drawer__list-item--m-hoverable--hover--BoxShadow);
}

.pf-v5-c-notification-drawer__list-item-header {
  display: flex;
  grid-row: 1/2;
  grid-column: 1/2;
  align-items: baseline;
  margin-block-end: var(--pf-v5-c-notification-drawer__list-item-header--MarginBottom);
}

.pf-v5-c-notification-drawer__list-item-header-icon {
  margin-inline-end: var(--pf-v5-c-notification-drawer__list-item-header-icon--MarginRight);
  color: var(--pf-v5-c-notification-drawer__list-item-header-icon--Color);
}

.pf-v5-c-notification-drawer__list-item-header-title {
  font-weight: var(--pf-v5-c-notification-drawer__list-item-header-title--FontWeight);
  word-break: break-word;
}

.pf-v5-c-notification-drawer__list-item-header-title.pf-m-truncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--pf-v5-c-notification-drawer__list-item-header-title--max-lines);
  overflow: hidden;
}

.pf-v5-c-notification-drawer__list-item-action {
  grid-row: 1/3;
  grid-column: 2/3;
}

.pf-v5-c-notification-drawer__list-item-description {
  grid-row: 2/3;
  grid-column: 1/2;
  margin-block-end: var(--pf-v5-c-notification-drawer__list-item-description--MarginBottom);
  word-break: break-word;
}

.pf-v5-c-notification-drawer__list-item-timestamp {
  grid-row: 3/4;
  grid-column: 1/2;
  font-size: var(--pf-v5-c-notification-drawer__list-item-timestamp--FontSize);
  color: var(--pf-v5-c-notification-drawer__list-item-timestamp--Color);
}

.pf-v5-c-notification-drawer__group-list {
  display: flex;
  flex-direction: column;
}

.pf-v5-c-notification-drawer__group.pf-m-expanded {
  min-height: var(--pf-v5-c-notification-drawer__group--m-expanded--MinHeight);
}

.pf-v5-c-notification-drawer__group.pf-m-expanded+.pf-v5-c-notification-drawer__group {
  border-block-start: var(--pf-v5-c-notification-drawer__group--m-expanded--group--BorderTopWidth) solid var(--pf-v5-c-notification-drawer__group--m-expanded--group--BorderTopColor);
}

.pf-v5-c-notification-drawer__group .pf-v5-c-notification-drawer__list-item:last-child {
  --pf-v5-c-notification-drawer__list-item--BorderBottomWidth: 0;
  --pf-v5-c-notification-drawer__list-item--before--Bottom: 0;
}

.pf-v5-c-notification-drawer__group-toggle {
  display: flex;
  align-items: baseline;
  width: 100%;
  padding-block-start: var(--pf-v5-c-notification-drawer__group-toggle--PaddingTop);
  padding-block-end: var(--pf-v5-c-notification-drawer__group-toggle--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-notification-drawer__group-toggle--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-notification-drawer__group-toggle--PaddingRight);
  background-color: var(--pf-v5-c-notification-drawer__group-toggle--BackgroundColor);
  border: 0 solid var(--pf-v5-c-notification-drawer__group-toggle--BorderColor);
  border-block-end-width: var(--pf-v5-c-notification-drawer__group-toggle--BorderBottomWidth);
  outline-offset: var(--pf-v5-c-notification-drawer__group-toggle--OutlineOffset);
}

.pf-v5-c-notification-drawer__group-toggle-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--pf-v5-c-notification-drawer__group-toggle-title--max-lines);
  overflow: hidden;
  flex: 1;
  margin-inline-end: var(--pf-v5-c-notification-drawer__group-toggle-title--MarginRight);
  text-align: start;
  word-break: break-word;
}

.pf-v5-c-notification-drawer__group-toggle-count {
  margin-inline-start: auto;
  margin-inline-end: var(--pf-v5-c-notification-drawer__group-toggle-count--MarginRight);
}

.pf-v5-c-notification-drawer__group-toggle-icon {
  margin-inline-end: var(--pf-v5-c-notification-drawer__group-toggle-icon--MarginRight);
  color: var(--pf-v5-c-notification-drawer__group-toggle-icon--Color);
  transition: var(--pf-v5-c-notification-drawer__group-toggle-icon--Transition);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-notification-drawer__group-toggle-icon {
  scale: -1 1;
}

.pf-v5-c-notification-drawer__group.pf-m-expanded .pf-v5-c-notification-drawer__group-toggle-icon {
  transform: rotate(var(--pf-v5-c-notification-drawer__group--m-expanded__group-toggle-icon--Rotate));
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-notification-drawer {
  --pf-v5-c-notification-drawer--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-notification-drawer__header--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-notification-drawer__list-item--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-notification-drawer__list-item--BoxShadow: none;
  --pf-v5-c-notification-drawer__list-item--BorderBottomColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-notification-drawer__list-item--m-read--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-notification-drawer__list-item--list-item--m-read--BoxShadow: none;
  --pf-v5-c-notification-drawer__group-toggle--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
}

.pf-v5-c-options-menu {
  --pf-v5-c-options-menu__toggle--BackgroundColor: transparent;
  --pf-v5-c-options-menu__toggle--PaddingTop: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-options-menu__toggle--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-options-menu__toggle--PaddingBottom: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-options-menu__toggle--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-options-menu__toggle--ColumnGap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-options-menu__toggle--MinWidth: 0;
  --pf-v5-c-options-menu__toggle--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-options-menu__toggle--BorderTopColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-options-menu__toggle--BorderRightColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-options-menu__toggle--BorderBottomColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-options-menu__toggle--BorderLeftColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-options-menu__toggle--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-options-menu__toggle--hover--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-options-menu__toggle--active--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-options-menu__toggle--active--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-options-menu__toggle--focus--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-options-menu__toggle--focus--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-options-menu__toggle--expanded--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-options-menu__toggle--expanded--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-options-menu__toggle--disabled--BackgroundColor: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-options-menu__toggle--m-plain--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-options-menu__toggle--m-plain--hover--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-options-menu__toggle--m-plain--disabled--Color: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-options-menu__toggle--m-plain--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-options-menu__toggle--m-plain--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-options-menu__toggle-icon--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-options-menu__toggle-icon--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-options-menu--m-top--m-expanded__toggle-icon--Rotate: 180deg;
  --pf-v5-c-options-menu--m-plain__toggle-icon--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-options-menu--m-plain--hover__toggle-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-options-menu__toggle-button--BackgroundColor: transparent;
  --pf-v5-c-options-menu__toggle-button--PaddingTop: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-options-menu__toggle-button--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-options-menu__toggle-button--PaddingBottom: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-options-menu__toggle-button--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-options-menu__menu--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-c-options-menu__menu--BoxShadow: var(--pf-v5-global--BoxShadow--md);
  --pf-v5-c-options-menu__menu--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-options-menu__menu--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-options-menu__menu--Top: calc(100% + var(--pf-v5-global--spacer--xs));
  --pf-v5-c-options-menu__menu--ZIndex: var(--pf-v5-global--ZIndex--sm);
  --pf-v5-c-options-menu--m-top__menu--Top: 0;
  --pf-v5-c-options-menu--m-top__menu--TranslateY: calc(-100% - var(--pf-v5-global--spacer--xs));
  --pf-v5-c-options-menu__menu-item--BackgroundColor: transparent;
  --pf-v5-c-options-menu__menu-item--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-options-menu__menu-item--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-options-menu__menu-item--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-options-menu__menu-item--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-options-menu__menu-item--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-options-menu__menu-item--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-options-menu__menu-item--disabled--Color: var(--pf-v5-global--Color--dark-200);
  --pf-v5-c-options-menu__menu-item--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-300);
  --pf-v5-c-options-menu__menu-item--disabled--BackgroundColor: transparent;
  --pf-v5-c-options-menu__menu-item-icon--Color: var(--pf-v5-global--active-color--100);
  --pf-v5-c-options-menu__menu-item-icon--FontSize: var(--pf-v5-global--icon--FontSize--sm);
  --pf-v5-c-options-menu__menu-item-icon--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-options-menu__group--group--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-options-menu__group-title--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-options-menu__group-title--PaddingRight: var(--pf-v5-c-options-menu__menu-item--PaddingRight);
  --pf-v5-c-options-menu__group-title--PaddingBottom: var(--pf-v5-c-options-menu__menu-item--PaddingBottom);
  --pf-v5-c-options-menu__group-title--PaddingLeft: var(--pf-v5-c-options-menu__menu-item--PaddingLeft);
  --pf-v5-c-options-menu__group-title--FontSize: var(--pf-v5-global--FontSize--xs);
  --pf-v5-c-options-menu__group-title--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-options-menu__group-title--Color: var(--pf-v5-global--Color--dark-200);
  --pf-v5-c-options-menu--c-divider--MarginTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-options-menu--c-divider--MarginBottom: var(--pf-v5-global--spacer--sm);
  position: relative;
  display: inline-block;
  max-width: 100%;
}

.pf-v5-c-options-menu .pf-v5-c-divider {
  margin-block-start: var(--pf-v5-c-options-menu--c-divider--MarginTop);
  margin-block-end: var(--pf-v5-c-options-menu--c-divider--MarginBottom);
}

.pf-v5-c-options-menu .pf-v5-c-divider:last-child {
  --pf-v5-c-options-menu--c-divider--MarginBottom: 0;
}

.pf-v5-c-options-menu__toggle:not(.pf-m-plain)::before,
.pf-v5-c-options-menu.pf-m-text:not(.pf-m-plain) .pf-v5-c-options-menu__toggle-button::before {
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  content: "";
  border: var(--pf-v5-c-options-menu__toggle--BorderWidth) solid;
  border-block-start-color: var(--pf-v5-c-options-menu__toggle--BorderTopColor);
  border-block-end-color: var(--pf-v5-c-options-menu__toggle--BorderBottomColor);
  border-inline-start-color: var(--pf-v5-c-options-menu__toggle--BorderLeftColor);
  border-inline-end-color: var(--pf-v5-c-options-menu__toggle--BorderRightColor);
}

.pf-v5-c-options-menu__toggle:not(.pf-m-plain):hover::before,
.pf-v5-c-options-menu.pf-m-text:not(.pf-m-plain) .pf-v5-c-options-menu__toggle-button:hover::before {
  --pf-v5-c-options-menu__toggle--BorderBottomColor: var(--pf-v5-c-options-menu__toggle--hover--BorderBottomColor);
}

.pf-v5-c-options-menu__toggle:not(.pf-m-plain):active::before,
.pf-v5-c-options-menu__toggle:not(.pf-m-plain).pf-m-active::before,
.pf-v5-c-options-menu.pf-m-text:not(.pf-m-plain) .pf-v5-c-options-menu__toggle-button:active::before,
.pf-v5-c-options-menu.pf-m-text:not(.pf-m-plain) .pf-v5-c-options-menu__toggle-button.pf-m-active::before {
  --pf-v5-c-options-menu__toggle--BorderBottomColor: var(--pf-v5-c-options-menu__toggle--active--BorderBottomColor);
  border-block-end-width: var(--pf-v5-c-options-menu__toggle--active--BorderBottomWidth);
}

.pf-v5-c-options-menu__toggle:not(.pf-m-plain):focus::before,
.pf-v5-c-options-menu.pf-m-text:not(.pf-m-plain) .pf-v5-c-options-menu__toggle-button:focus::before {
  --pf-v5-c-options-menu__toggle--BorderBottomColor: var(--pf-v5-c-options-menu__toggle--focus--BorderBottomColor);
  border-block-end-width: var(--pf-v5-c-options-menu__toggle--focus--BorderBottomWidth);
}

.pf-v5-c-options-menu__toggle {
  position: relative;
  display: flex;
  column-gap: var(--pf-v5-c-options-menu__toggle--ColumnGap);
  align-items: center;
  min-width: var(--pf-v5-c-options-menu__toggle--MinWidth);
  max-width: 100%;
  padding-block-start: var(--pf-v5-c-options-menu__toggle--PaddingTop);
  padding-block-end: var(--pf-v5-c-options-menu__toggle--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-options-menu__toggle--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-options-menu__toggle--PaddingRight);
  color: var(--pf-v5-c-options-menu__toggle--Color);
  background-color: var(--pf-v5-c-options-menu__toggle--BackgroundColor);
  border: none;
}

.pf-v5-c-options-menu.pf-m-expanded>.pf-v5-c-options-menu__toggle::before {
  --pf-v5-c-options-menu__toggle--BorderBottomColor: var(--pf-v5-c-options-menu__toggle--expanded--BorderBottomColor);
  border-block-end-width: var(--pf-v5-c-options-menu__toggle--expanded--BorderBottomWidth);
}

.pf-v5-c-options-menu__toggle.pf-m-plain {
  --pf-v5-c-options-menu__toggle-icon--Color: var(--pf-v5-c-options-menu--m-plain__toggle-icon--Color);
}

.pf-v5-c-options-menu__toggle.pf-m-plain:not(.pf-m-text) {
  --pf-v5-c-options-menu__toggle--PaddingRight: var(--pf-v5-c-options-menu__toggle--m-plain--PaddingRight);
  --pf-v5-c-options-menu__toggle--PaddingLeft: var(--pf-v5-c-options-menu__toggle--m-plain--PaddingLeft);
  display: inline-block;
  color: var(--pf-v5-c-options-menu__toggle--m-plain--Color);
}

.pf-v5-c-options-menu__toggle.pf-m-plain:hover,
.pf-v5-c-options-menu__toggle.pf-m-plain:active,
.pf-v5-c-options-menu__toggle.pf-m-plain.pf-m-active,
.pf-v5-c-options-menu__toggle.pf-m-plain:focus,
.pf-v5-c-options-menu.pf-m-expanded>.pf-v5-c-options-menu__toggle.pf-m-plain {
  --pf-v5-c-options-menu__toggle--m-plain--Color: var(--pf-v5-c-options-menu__toggle--m-plain--hover--Color);
  --pf-v5-c-options-menu--m-plain__toggle-icon--Color: var(--pf-v5-c-options-menu--m-plain--hover__toggle-icon--Color);
}

.pf-v5-c-options-menu__toggle.pf-m-plain.pf-m-disabled,
.pf-v5-c-options-menu__toggle.pf-m-plain:disabled {
  --pf-v5-c-options-menu__toggle--m-plain--Color: var(--pf-v5-c-options-menu__toggle--m-plain--disabled--Color);
}

.pf-v5-c-options-menu__toggle.pf-m-disabled,
.pf-v5-c-options-menu__toggle:disabled {
  pointer-events: none;
}

.pf-v5-c-options-menu__toggle.pf-m-disabled:not(.pf-m-plain),
.pf-v5-c-options-menu__toggle.pf-m-disabled.pf-m-text,
.pf-v5-c-options-menu__toggle:disabled:not(.pf-m-plain),
.pf-v5-c-options-menu__toggle:disabled.pf-m-text {
  --pf-v5-c-options-menu__toggle--BackgroundColor: var(--pf-v5-c-options-menu__toggle--disabled--BackgroundColor);
}

.pf-v5-c-options-menu__toggle.pf-m-disabled::before,
.pf-v5-c-options-menu__toggle:disabled::before {
  border: 0;
}

.pf-v5-c-options-menu__toggle-button-icon {
  position: relative;
}

.pf-v5-c-options-menu__toggle-button {
  padding-block-start: var(--pf-v5-c-options-menu__toggle-button--PaddingTop);
  padding-block-end: var(--pf-v5-c-options-menu__toggle-button--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-options-menu__toggle-button--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-options-menu__toggle-button--PaddingRight);
  background-color: var(--pf-v5-c-options-menu__toggle-button--BackgroundColor);
  border: 0;
}

.pf-v5-c-options-menu__toggle-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1 1 auto;
  text-align: start;
}

.pf-v5-c-options-menu__toggle-icon,
.pf-v5-c-options-menu__toggle-button-icon {
  color: var(--pf-v5-c-options-menu__toggle-icon--Color, inherit);
}

.pf-v5-c-options-menu__toggle-icon {
  padding-inline-start: var(--pf-v5-c-options-menu__toggle-icon--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-options-menu__toggle-icon--PaddingRight);
}

.pf-v5-c-options-menu.pf-m-top.pf-m-expanded .pf-v5-c-options-menu__toggle-icon {
  transform: rotate(var(--pf-v5-c-options-menu--m-top--m-expanded__toggle-icon--Rotate));
}

.pf-v5-c-options-menu__menu {
  position: absolute;
  inset-block-start: var(--pf-v5-c-options-menu__menu--Top);
  z-index: var(--pf-v5-c-options-menu__menu--ZIndex);
  min-width: 100%;
  padding-block-start: var(--pf-v5-c-options-menu__menu--PaddingTop);
  padding-block-end: var(--pf-v5-c-options-menu__menu--PaddingBottom);
  background-color: var(--pf-v5-c-options-menu__menu--BackgroundColor);
  background-clip: padding-box;
  box-shadow: var(--pf-v5-c-options-menu__menu--BoxShadow);
}

.pf-v5-c-options-menu__menu.pf-m-align-right {
  inset-inline-end: 0;
}

.pf-v5-c-options-menu.pf-m-top .pf-v5-c-options-menu__menu {
  --pf-v5-c-options-menu__menu--Top: var(--pf-v5-c-options-menu--m-top__menu--Top);
  transform: translateY(var(--pf-v5-c-options-menu--m-top__menu--TranslateY));
}

.pf-v5-c-options-menu__menu.pf-m-static {
  --pf-v5-c-options-menu--m-top__menu--TranslateY: 0;
  position: static;
  inset-block-start: auto;
  inset-block-end: auto;
  inset-inline-start: auto;
  inset-inline-end: auto;
  z-index: auto;
  min-width: min-content;
}

.pf-v5-c-options-menu__menu-item {
  display: flex;
  align-items: baseline;
  width: 100%;
  padding-block-start: var(--pf-v5-c-options-menu__menu-item--PaddingTop);
  padding-block-end: var(--pf-v5-c-options-menu__menu-item--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-options-menu__menu-item--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-options-menu__menu-item--PaddingRight);
  font-size: var(--pf-v5-c-options-menu__menu-item--FontSize);
  color: var(--pf-v5-c-options-menu__menu-item--Color);
  white-space: nowrap;
  background-color: var(--pf-v5-c-options-menu__menu-item--BackgroundColor);
  border: none;
}

.pf-v5-c-options-menu__menu-item:hover,
.pf-v5-c-options-menu__menu-item:focus {
  text-decoration: none;
  background-color: var(--pf-v5-c-options-menu__menu-item--hover--BackgroundColor);
}

.pf-v5-c-options-menu__menu-item:disabled,
.pf-v5-c-options-menu__menu-item.pf-m-disabled {
  color: var(--pf-v5-c-options-menu__menu-item--disabled--Color);
  pointer-events: none;
  background-color: var(--pf-v5-c-options-menu__menu-item--disabled--BackgroundColor);
}

.pf-v5-c-options-menu__menu-item-icon {
  align-self: center;
  width: auto;
  padding-inline-start: var(--pf-v5-c-options-menu__menu-item-icon--PaddingLeft);
  margin-inline-start: auto;
  font-size: var(--pf-v5-c-options-menu__menu-item-icon--FontSize);
  color: var(--pf-v5-c-options-menu__menu-item-icon--Color);
}

.pf-v5-c-options-menu__group+.pf-v5-c-options-menu__group {
  padding-block-start: var(--pf-v5-c-options-menu__group--group--PaddingTop);
}

.pf-v5-c-options-menu__group-title {
  padding-block-start: var(--pf-v5-c-options-menu__group-title--PaddingTop);
  padding-block-end: var(--pf-v5-c-options-menu__group-title--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-options-menu__group-title--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-options-menu__group-title--PaddingRight);
  font-size: var(--pf-v5-c-options-menu__group-title--FontSize);
  font-weight: var(--pf-v5-c-options-menu__group-title--FontWeight);
  color: var(--pf-v5-c-options-menu__group-title--Color);
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-options-menu {
  --pf-v5-c-options-menu__toggle--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-options-menu__toggle--BorderTopColor: transparent;
  --pf-v5-c-options-menu__toggle--BorderRightColor: transparent;
  --pf-v5-c-options-menu__toggle--BorderBottomColor: var(--pf-v5-global--BorderColor--400);
  --pf-v5-c-options-menu__toggle--BorderLeftColor: transparent;
  --pf-v5-c-options-menu__toggle--disabled--BackgroundColor: var(--pf-v5-global--palette--black-500);
  --pf-v5-c-options-menu__menu--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-options-menu__menu-item--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-options-menu__menu--Top: 100%;
}

:where(.pf-v5-theme-dark) .pf-v5-c-options-menu__toggle.pf-m-plain {
  background: transparent;
}

.pf-v5-c-overflow-menu {
  --pf-v5-c-overflow-menu--spacer--base: var(--pf-v5-global--spacer--md);
  --pf-v5-c-overflow-menu--spacer: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-overflow-menu__group--spacer: var(--pf-v5-c-overflow-menu--spacer--base);
  --pf-v5-c-overflow-menu__item--spacer: var(--pf-v5-c-overflow-menu--spacer--base);
  --pf-v5-c-overflow-menu--c-divider--m-vertical--spacer: var(--pf-v5-c-overflow-menu--spacer--base);
  --pf-v5-c-overflow-menu__group--m-button-group--spacer: var(--pf-v5-c-overflow-menu--spacer--base);
  --pf-v5-c-overflow-menu__group--m-button-group--space-items: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-overflow-menu__group--m-icon-button-group--spacer: var(--pf-v5-c-overflow-menu--spacer--base);
  --pf-v5-c-overflow-menu__group--m-icon-button-group--space-items: 0;
  display: inline-flex;
  align-items: center;
}

.pf-v5-c-overflow-menu__content {
  display: flex;
  align-items: center;
}

.pf-v5-c-overflow-menu__group {
  --pf-v5-c-overflow-menu--spacer: var(--pf-v5-c-overflow-menu__group--spacer);
  display: flex;
  align-items: center;
}

.pf-v5-c-overflow-menu__group.pf-m-button-group {
  --pf-v5-c-overflow-menu--spacer: var(--pf-v5-c-overflow-menu__group--m-button-group--spacer);
}

.pf-v5-c-overflow-menu__group.pf-m-button-group>* {
  --pf-v5-c-overflow-menu--spacer: var(--pf-v5-c-overflow-menu__group--m-button-group--space-items);
}

.pf-v5-c-overflow-menu__group.pf-m-icon-button-group {
  --pf-v5-c-overflow-menu--spacer: var(--pf-v5-c-overflow-menu__group--m-icon-button-group--spacer);
}

.pf-v5-c-overflow-menu__group.pf-m-icon-button-group>* {
  --pf-v5-c-overflow-menu--spacer: var(--pf-v5-c-overflow-menu__group--m-icon-button-group--space-items);
}

.pf-v5-c-overflow-menu__item {
  --pf-v5-c-overflow-menu--spacer: var(--pf-v5-c-overflow-menu__item--spacer);
}

.pf-v5-c-overflow-menu__content,
.pf-v5-c-overflow-menu__control,
.pf-v5-c-overflow-menu__group,
.pf-v5-c-overflow-menu__item {
  margin-inline-end: var(--pf-v5-c-overflow-menu--spacer);
}

.pf-v5-c-overflow-menu__content:last-child,
.pf-v5-c-overflow-menu__control:last-child,
.pf-v5-c-overflow-menu__group:last-child,
.pf-v5-c-overflow-menu__item:last-child {
  --pf-v5-c-overflow-menu--spacer: 0;
}

.pf-v5-c-overflow-menu>.pf-v5-c-divider,
.pf-v5-c-overflow-menu__group>.pf-v5-c-divider {
  --pf-v5-c-overflow-menu--spacer: var(--pf-v5-c-overflow-menu--c-divider--m-vertical--spacer);
}

.pf-v5-c-overflow-menu>.pf-v5-c-divider.pf-m-vertical,
.pf-v5-c-overflow-menu__group>.pf-v5-c-divider.pf-m-vertical {
  margin-inline-end: var(--pf-v5-c-overflow-menu--spacer);
}

.pf-v5-c-overflow-menu>.pf-v5-c-divider.pf-m-vertical:last-child,
.pf-v5-c-overflow-menu__group>.pf-v5-c-divider.pf-m-vertical:last-child {
  --pf-v5-c-overflow-menu--spacer: 0;
}

:root {
  --pf-v5-c-page--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-300);
  --pf-v5-c-page--inset: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page--xl--inset: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-page__header--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-100);
  --pf-v5-c-page__header--ZIndex: var(--pf-v5-global--ZIndex--md);
  --pf-v5-c-page__header--MinHeight: 4.75rem;
  --pf-v5-c-page__header-brand--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__header-brand--xl--PaddingRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-page__header-brand--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-page__header-sidebar-toggle__c-button--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-page__header-sidebar-toggle__c-button--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-page__header-sidebar-toggle__c-button--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-page__header-sidebar-toggle__c-button--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-page__header-sidebar-toggle__c-button--MarginRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__header-sidebar-toggle__c-button--MarginLeft: calc(var(--pf-v5-c-page__header-sidebar-toggle__c-button--PaddingLeft) * -1);
  --pf-v5-c-page__header-sidebar-toggle__c-button--FontSize: var(--pf-v5-global--FontSize--2xl);
  --pf-v5-c-page__header-brand-link--c-brand--MaxHeight: 3.75rem;
  --pf-v5-c-page__header-nav--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-300);
  --pf-v5-c-page__header-nav--xl--BackgroundColor: transparent;
  --pf-v5-c-page__header-nav--xl--PaddingRight: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-page__header-nav--xl--PaddingLeft: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-page__header-tools--MarginRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__header-tools--xl--MarginRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-page__header-tools--c-avatar--MarginLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__header-tools-group--MarginLeft: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-page__header-tools-group--Display: flex;
  --pf-v5-c-page__header-tools-item--Display: block;
  --pf-v5-c-page__header-tools-item--c-notification-badge--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-page__header-tools--c-button--notification-badge--m-unread--after--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-page__header-tools--c-button--notification-badge--m-attention--after--BackgroundColor: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-page__header-tools--c-button--m-selected--notification-badge--m-unread--after--BackgroundColor: var(--pf-v5-global--primary-color--200);
  --pf-v5-c-page__header-tools--c-button--m-selected--notification-badge--m-attention--after--BackgroundColor: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-page__header-tools--c-button--m-selected--before--Width: auto;
  --pf-v5-c-page__header-tools--c-button--m-selected--before--Height: auto;
  --pf-v5-c-page__header-tools--c-button--m-selected--before--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-page__header-tools--c-button--m-selected--before--BorderRadius: var(--pf-v5-global--BorderRadius--sm);
  --pf-v5-c-page__header-tools--c-button--m-selected--c-notification-badge--m-unread--after--BorderColor: transparent;
  --pf-v5-c-page__sidebar--ZIndex: var(--pf-v5-global--ZIndex--sm);
  --pf-v5-c-page__sidebar--Width: 18.125rem;
  --pf-v5-c-page__sidebar--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-300);
  --pf-v5-c-page__sidebar--m-light--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-c-page__sidebar--m-light--BorderRightWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-page__sidebar--m-light--BorderRightColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-page__sidebar--BoxShadow: var(--pf-v5-global--BoxShadow--lg-right);
  --pf-v5-c-page__sidebar--Transition: var(--pf-v5-global--Transition);
  --pf-v5-c-page__sidebar--TranslateX: -100%;
  --pf-v5-c-page__sidebar--TranslateZ: 0;
  --pf-v5-c-page__sidebar--m-expanded--TranslateX: 0;
  --pf-v5-c-page__sidebar--xl--TranslateX: 0;
  --pf-v5-c-page__sidebar-body--PaddingRight: 0;
  --pf-v5-c-page__sidebar-body--PaddingLeft: 0;
  --pf-v5-c-page__sidebar-body--m-page-insets--PaddingRight: var(--pf-v5-c-page--inset);
  --pf-v5-c-page__sidebar-body--m-page-insets--PaddingLeft: var(--pf-v5-c-page--inset);
  --pf-v5-c-page__sidebar-body--m-menu--BorderTopColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-page__sidebar-body--m-menu--BorderTopWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-page__sidebar-body--m-menu--c-context-selector--BorderBottomColor: var(--pf-v5-global--BackgroundColor--dark-200);
  --pf-v5-c-page__main--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-page__main-section--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__main-section--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__main-section--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__main-section--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__main-section--xl--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-page__main-section--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-page__main-section--xl--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-page__main-section--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-page__main-breadcrumb--main-section--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__main-section--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-300);
  --pf-v5-c-page__main-section--m-light--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-c-page__main-section--m-light-100--BackgroundColor: var(--pf-v5-global--BackgroundColor--150);
  --pf-v5-c-page__main-section--m-dark-100--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-transparent-100);
  --pf-v5-c-page__main-section--m-dark-200--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-transparent-200);
  --pf-v5-c-page__main-breadcrumb--page__main-tabs--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__main-nav--page__main-tabs--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page--section--m-limit-width--MaxWidth: calc(125rem - var(--pf-v5-c-page__sidebar--Width));
  --pf-v5-c-page--section--m-sticky-top--ZIndex: var(--pf-v5-global--ZIndex--md);
  --pf-v5-c-page--section--m-sticky-top--BoxShadow: var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-page--section--m-sticky-bottom--ZIndex: var(--pf-v5-global--ZIndex--md);
  --pf-v5-c-page--section--m-sticky-bottom--BoxShadow: var(--pf-v5-global--BoxShadow--sm-top);
  --pf-v5-c-page--section--m-shadow-bottom--BoxShadow: var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-page--section--m-shadow-bottom--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-page--section--m-shadow-top--BoxShadow: var(--pf-v5-global--BoxShadow--sm-top);
  --pf-v5-c-page--section--m-shadow-top--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-page__main-nav--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-c-page__main-nav--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__main-nav--PaddingRight: 0;
  --pf-v5-c-page__main-nav--PaddingLeft: 0;
  --pf-v5-c-page__main-nav--m-sticky-top--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__main-nav--xl--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-page__main-nav--xl--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-page__main-subnav--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-300);
  --pf-v5-c-page__main-subnav--BorderTopWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-page__main-subnav--BorderTopColor: var(--pf-v5-global--palette--black-800);
  --pf-v5-c-page__main-subnav--BorderLeftWidth: 0;
  --pf-v5-c-page__main-subnav--BorderLeftColor: var(--pf-v5-global--palette--black-800);
  --pf-v5-c-page__sidebar--main__main-subnav--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-page__sidebar--m-collapsed--main__main-subnav--BorderLeftWidth: 0;
  --pf-v5-c-page__main-breadcrumb--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-c-page__main-breadcrumb--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__main-breadcrumb--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__main-breadcrumb--PaddingBottom: 0;
  --pf-v5-c-page__main-breadcrumb--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__main-breadcrumb--m-sticky-top--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-page__main-breadcrumb--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-page__main-breadcrumb--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-page__main-tabs--PaddingTop: 0;
  --pf-v5-c-page__main-tabs--PaddingRight: 0;
  --pf-v5-c-page__main-tabs--PaddingBottom: 0;
  --pf-v5-c-page__main-tabs--PaddingLeft: 0;
  --pf-v5-c-page__main-tabs--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-c-page__main-wizard--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-c-page__main-wizard--BorderTopColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-page__main-wizard--BorderTopWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-page__main-wizard--m-light-200--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
}

@media (min-width: 1200px) {
  :root {
    --pf-v5-c-page__header-brand--PaddingLeft: var(--pf-v5-c-page__header-brand--xl--PaddingLeft);
    --pf-v5-c-page--inset: var(--pf-v5-c-page--xl--inset);
  }
}

@media screen and (min-width: 1200px) {
  :root {
    --pf-v5-c-page__header-nav--BackgroundColor: var(--pf-v5-c-page__header-nav--xl--BackgroundColor);
    --pf-v5-c-page__header-nav--PaddingRight: var(--pf-v5-c-page__header-nav--xl--PaddingRight);
    --pf-v5-c-page__header-nav--PaddingLeft: var(--pf-v5-c-page__header-nav--xl--PaddingLeft);
  }
}

@media screen and (min-width: 1200px) {
  :root {
    --pf-v5-c-page__header-tools--MarginRight: var(--pf-v5-c-page__header-tools--xl--MarginRight);
  }
}

@media screen and (min-width: 1200px) {
  :root {
    --pf-v5-c-page__sidebar--TranslateX: var(--pf-v5-c-page__sidebar--xl--TranslateX);
  }
}

@media screen and (min-width: 1200px) {
  :root {
    --pf-v5-c-page__main-section--PaddingTop: var(--pf-v5-c-page__main-section--xl--PaddingTop);
    --pf-v5-c-page__main-section--PaddingRight: var(--pf-v5-c-page__main-section--xl--PaddingRight);
    --pf-v5-c-page__main-section--PaddingBottom: var(--pf-v5-c-page__main-section--xl--PaddingBottom);
    --pf-v5-c-page__main-section--PaddingLeft: var(--pf-v5-c-page__main-section--xl--PaddingLeft);
  }
}

@media screen and (min-width: 1200px) {
  :root {
    --pf-v5-c-page__main-nav--PaddingRight: var(--pf-v5-c-page__main-nav--xl--PaddingRight);
    --pf-v5-c-page__main-nav--PaddingLeft: var(--pf-v5-c-page__main-nav--xl--PaddingLeft);
  }
}

@media screen and (min-width: 1200px) {
  :root {
    --pf-v5-c-page__main-breadcrumb--PaddingRight: var(--pf-v5-c-page__main-breadcrumb--xl--PaddingRight);
    --pf-v5-c-page__main-breadcrumb--PaddingLeft: var(--pf-v5-c-page__main-breadcrumb--xl--PaddingLeft);
  }
}

.pf-v5-c-page {
  display: grid;
  grid-template-areas: "header" "main";
  grid-template-rows: max-content 1fr;
  grid-template-columns: 1fr;
  height: 100vh;
  height: 100dvh;
  max-height: 100%;
  background-color: var(--pf-v5-c-page--BackgroundColor);
}

@media (min-width: 1200px) {
  .pf-v5-c-page {
    grid-template-areas: "header header" "nav main";
    grid-template-columns: max-content 1fr;
  }
}

.pf-v5-c-page__header,
.pf-v5-c-page>.pf-v5-c-masthead {
  z-index: var(--pf-v5-c-page__header--ZIndex);
  grid-area: header;
}

.pf-v5-c-page__header {
  color: var(--pf-v5-global--Color--100);
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  min-width: 0;
  min-height: var(--pf-v5-c-page__header--MinHeight);
  background-color: var(--pf-v5-c-page__header--BackgroundColor);
}

.pf-v5-c-page__header>* {
  display: flex;
  align-items: center;
}

@media screen and (min-width: 992px) {
  .pf-v5-c-page__header {
    grid-template-columns: auto 1fr auto;
  }
}

.pf-v5-c-page__header .pf-v5-c-masthead {
  grid-column: span 2;
  align-self: stretch;
}

.pf-v5-c-page__header-brand {
  grid-column: 1/2;
  padding-inline-start: var(--pf-v5-c-page__header-brand--PaddingLeft);
}

@media (min-width: 1200px) {
  .pf-v5-c-page__header-brand {
    padding-inline-end: var(--pf-v5-c-page__header-brand--xl--PaddingRight);
  }
}

.pf-v5-c-page__header-brand-link {
  display: flex;
  flex: 1;
  align-items: center;
}

.pf-v5-c-page__header-brand-link .pf-v5-c-brand {
  max-height: var(--pf-v5-c-page__header-brand-link--c-brand--MaxHeight);
}

.pf-v5-c-page__header-brand-toggle .pf-v5-c-button {
  padding-block-start: var(--pf-v5-c-page__header-sidebar-toggle__c-button--PaddingTop);
  padding-block-end: var(--pf-v5-c-page__header-sidebar-toggle__c-button--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-page__header-sidebar-toggle__c-button--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__header-sidebar-toggle__c-button--PaddingRight);
  margin-inline-start: var(--pf-v5-c-page__header-sidebar-toggle__c-button--MarginLeft);
  margin-inline-end: var(--pf-v5-c-page__header-sidebar-toggle__c-button--MarginRight);
  font-size: var(--pf-v5-c-page__header-sidebar-toggle__c-button--FontSize);
  line-height: 1;
}

.pf-v5-c-page__header-nav {
  grid-row: 2/3;
  grid-column: 1/-1;
  align-self: stretch;
  min-width: 0;
  padding-inline-start: var(--pf-v5-c-page__header-nav--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__header-nav--PaddingRight);
  background-color: var(--pf-v5-c-page__header-nav--BackgroundColor);
}

@media screen and (min-width: 1200px) {
  .pf-v5-c-page__header-nav {
    grid-row: 1/2;
    grid-column: 2/3;
  }
}

.pf-v5-c-page__header-nav .pf-v5-c-nav {
  align-self: stretch;
}

.pf-v5-c-page__header-tools {
  grid-column: 2/3;
  margin-inline-start: auto;
  margin-inline-end: var(--pf-v5-c-page__header-tools--MarginRight);
}

.pf-v5-c-page__header-tools .pf-v5-c-avatar {
  margin-inline-start: var(--pf-v5-c-page__header-tools--c-avatar--MarginLeft);
}

@media screen and (min-width: 992px) {
  .pf-v5-c-page__header-tools {
    grid-column: 3/4;
  }
}

.pf-v5-c-page__header-tools-group {
  --pf-v5-hidden-visible--visible--Display: var(--pf-v5-c-page__header-tools-group--Display);
  align-items: center;
}

.pf-v5-c-page__header-tools-group+.pf-v5-c-page__header-tools-group {
  margin-inline-start: var(--pf-v5-c-page__header-tools-group--MarginLeft);
}

.pf-v5-c-page__header-tools-item {
  --pf-v5-hidden-visible--visible--Display: var(--pf-v5-c-page__header-tools-item--Display);
}

.pf-v5-c-page__header-tools-item .pf-v5-c-notification-badge.pf-m-read:hover {
  --pf-v5-c-notification-badge--after--BackgroundColor: var(--pf-v5-c-page__header-tools-item--c-notification-badge--hover--BackgroundColor);
}

.pf-v5-c-page__header-tools-item.pf-m-selected .pf-v5-c-button {
  background-color: var(--pf-v5-c-page__header-tools--c-button--m-selected--before--BackgroundColor);
  border-radius: var(--pf-v5-c-page__header-tools--c-button--m-selected--before--BorderRadius);
}

.pf-v5-c-page__header-tools-item.pf-m-selected .pf-v5-c-button::before {
  position: absolute;
  inset: 0;
  width: var(--pf-v5-c-page__header-tools--c-button--m-selected--before--Width);
  height: var(--pf-v5-c-page__header-tools--c-button--m-selected--before--Height);
  content: "";
}

.pf-v5-c-page__header-tools-item.pf-m-selected .pf-v5-c-button .pf-v5-c-notification-badge.pf-m-unread {
  --pf-v5-c-notification-badge--after--BackgroundColor: var(--pf-v5-c-page__header-tools--c-button--m-selected--notification-badge--m-unread--after--BackgroundColor);
}

.pf-v5-c-page__header-tools-item.pf-m-selected .pf-v5-c-button .pf-v5-c-notification-badge.pf-m-unread::after {
  border-color: var(--pf-v5-c-page__header-tools--c-button--m-selected--c-notification-badge--m-unread--after--BorderColor);
}

.pf-v5-c-page__header-tools-item.pf-m-selected .pf-v5-c-button .pf-v5-c-notification-badge.pf-m-attention {
  --pf-v5-c-notification-badge--after--BackgroundColor: var(--pf-v5-global--danger-color--200);
}

.pf-v5-c-page__header-tools-item .pf-v5-c-button:focus .pf-v5-c-notification-badge.pf-m-unread {
  --pf-v5-c-notification-badge--after--BackgroundColor: var(--pf-v5-c-page__header-tools--c-button--notification-badge--m-unread--after--BackgroundColor);
}

.pf-v5-c-page__header-tools-item .pf-v5-c-button:focus .pf-v5-c-notification-badge.pf-m-attention {
  --pf-v5-c-notification-badge--after--BackgroundColor: var(--pf-v5-c-page__header-tools--c-button--notification-badge--m-attention--after--BackgroundColor);
}

.pf-v5-c-page__sidebar {
  color: var(--pf-v5-global--Color--100);
  z-index: var(--pf-v5-c-page__sidebar--ZIndex);
  display: flex;
  flex-direction: column;
  grid-area: nav;
  grid-row-start: 2;
  grid-column-start: 1;
  width: var(--pf-v5-c-page__sidebar--Width);
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background-color: var(--pf-v5-c-page__sidebar--BackgroundColor);
  transition: var(--pf-v5-c-page__sidebar--Transition);
  transform: translateX(var(--pf-v5-c-page__sidebar--TranslateX)) translateZ(var(--pf-v5-c-page__sidebar--TranslateZ));
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-page__sidebar {
  transform: translateX(calc(var(--pf-v5-c-page__sidebar--TranslateX) * var(--pf-v5-global--inverse--multiplier))) translateZ(var(--pf-v5-c-page__sidebar--TranslateZ));
}

.pf-v5-c-page__sidebar.pf-m-expanded {
  --pf-v5-c-page__sidebar--TranslateX: var(--pf-v5-c-page__sidebar--m-expanded--TranslateX);
  box-shadow: var(--pf-v5-c-page__sidebar--BoxShadow);
}

@media screen and (min-width: 1200px) {
  .pf-v5-c-page__sidebar.pf-m-expanded {
    --pf-v5-c-page__sidebar--BoxShadow: none;
  }
}

.pf-v5-c-page__sidebar.pf-m-collapsed {
  max-width: 0;
  overflow: hidden;
}

.pf-v5-c-page__sidebar.pf-m-light {
  color: var(--pf-v5-global--Color--100);
  --pf-v5-c-page__sidebar--BackgroundColor: var(--pf-v5-c-page__sidebar--m-light--BackgroundColor);
  border-inline-end: var(--pf-v5-c-page__sidebar--m-light--BorderRightWidth) solid var(--pf-v5-c-page__sidebar--m-light--BorderRightColor);
}

.pf-v5-c-page__sidebar-body {
  padding-inline-start: var(--pf-v5-c-page__sidebar-body--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__sidebar-body--PaddingRight);
}

.pf-v5-c-page__sidebar-body:last-child {
  flex-grow: 1;
}

.pf-v5-c-page__sidebar-body.pf-m-menu {
  background-color: var(--pf-v5-global--palette--black-900);
  border-block-start: var(--pf-v5-c-page__sidebar-body--m-menu--BorderTopWidth) solid var(--pf-v5-c-page__sidebar-body--m-menu--BorderTopColor);
}

.pf-v5-c-page__sidebar-body.pf-m-menu+.pf-v5-c-page__sidebar-body.pf-m-menu {
  --pf-v5-c-page__sidebar-body--m-menu--BorderTopWidth: 0;
}

.pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector {
  --pf-v5-c-context-selector__toggle--BorderTopColor: transparent;
  --pf-v5-c-context-selector__toggle--BorderRightColor: transparent;
  --pf-v5-c-context-selector__toggle--BorderBottomColor: var(--pf-v5-c-page__sidebar-body--m-menu--c-context-selector--BorderBottomColor);
  --pf-v5-c-context-selector__toggle--BorderLeftColor: transparent;
  --pf-v5-c-context-selector__menu--Top: 100%;
  color: var(--pf-v5-global--Color--100);
  width: 100%;
}

.pf-v5-c-page__sidebar-body.pf-m-page-insets {
  --pf-v5-c-page__sidebar-body--PaddingRight: var(--pf-v5-c-page__sidebar-body--m-page-insets--PaddingRight);
  --pf-v5-c-page__sidebar-body--PaddingLeft: var(--pf-v5-c-page__sidebar-body--m-page-insets--PaddingLeft);
}

.pf-v5-c-page__sidebar-body.pf-m-inset-none {
  --pf-v5-c-page__sidebar-body--PaddingRight: 0;
  --pf-v5-c-page__sidebar-body--PaddingLeft: 0;
}

.pf-v5-c-page__sidebar-body.pf-m-fill {
  flex-grow: 1;
}

.pf-v5-c-page__sidebar-body.pf-m-no-fill {
  flex-grow: 0;
}

.pf-v5-c-page__main-nav.pf-m-limit-width,
.pf-v5-c-page__main-breadcrumb.pf-m-limit-width,
.pf-v5-c-page__main-tabs.pf-m-limit-width,
.pf-v5-c-page__main-section.pf-m-limit-width,
.pf-v5-c-page__main-wizard.pf-m-limit-width {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.pf-v5-c-page__main-nav.pf-m-limit-width>.pf-v5-c-page__main-body,
.pf-v5-c-page__main-breadcrumb.pf-m-limit-width>.pf-v5-c-page__main-body,
.pf-v5-c-page__main-tabs.pf-m-limit-width>.pf-v5-c-page__main-body,
.pf-v5-c-page__main-section.pf-m-limit-width>.pf-v5-c-page__main-body,
.pf-v5-c-page__main-wizard.pf-m-limit-width>.pf-v5-c-page__main-body {
  flex: 1;
  max-width: var(--pf-v5-c-page--section--m-limit-width--MaxWidth);
}

.pf-v5-c-page__main-nav.pf-m-limit-width.pf-m-align-center,
.pf-v5-c-page__main-breadcrumb.pf-m-limit-width.pf-m-align-center,
.pf-v5-c-page__main-tabs.pf-m-limit-width.pf-m-align-center,
.pf-v5-c-page__main-section.pf-m-limit-width.pf-m-align-center,
.pf-v5-c-page__main-wizard.pf-m-limit-width.pf-m-align-center {
  align-items: center;
}

.pf-v5-c-page__main-nav.pf-m-limit-width.pf-m-align-center>.pf-v5-c-page__main-body,
.pf-v5-c-page__main-breadcrumb.pf-m-limit-width.pf-m-align-center>.pf-v5-c-page__main-body,
.pf-v5-c-page__main-tabs.pf-m-limit-width.pf-m-align-center>.pf-v5-c-page__main-body,
.pf-v5-c-page__main-section.pf-m-limit-width.pf-m-align-center>.pf-v5-c-page__main-body,
.pf-v5-c-page__main-wizard.pf-m-limit-width.pf-m-align-center>.pf-v5-c-page__main-body {
  width: 100%;
}

.pf-v5-c-page__main-nav,
.pf-v5-c-page__main-breadcrumb,
.pf-v5-c-page__main-tabs,
.pf-v5-c-page__main-section,
.pf-v5-c-page__main-wizard,
.pf-v5-c-page__main-group,
.pf-v5-c-page__main-subnav {
  flex-shrink: 0;
}

.pf-v5-c-page__main-nav.pf-m-overflow-scroll,
.pf-v5-c-page__main-breadcrumb.pf-m-overflow-scroll,
.pf-v5-c-page__main-tabs.pf-m-overflow-scroll,
.pf-v5-c-page__main-section.pf-m-overflow-scroll,
.pf-v5-c-page__main-wizard.pf-m-overflow-scroll,
.pf-v5-c-page__main-group.pf-m-overflow-scroll,
.pf-v5-c-page__main-subnav.pf-m-overflow-scroll {
  position: relative;
  flex-shrink: 1;
  overflow: auto;
}

.pf-v5-c-page__main-nav.pf-m-shadow-bottom,
.pf-v5-c-page__main-breadcrumb.pf-m-shadow-bottom,
.pf-v5-c-page__main-tabs.pf-m-shadow-bottom,
.pf-v5-c-page__main-section.pf-m-shadow-bottom,
.pf-v5-c-page__main-wizard.pf-m-shadow-bottom,
.pf-v5-c-page__main-group.pf-m-shadow-bottom,
.pf-v5-c-page__main-subnav.pf-m-shadow-bottom {
  z-index: var(--pf-v5-c-page--section--m-shadow-bottom--ZIndex);
  box-shadow: var(--pf-v5-c-page--section--m-shadow-bottom--BoxShadow);
}

.pf-v5-c-page__main-nav.pf-m-shadow-top,
.pf-v5-c-page__main-breadcrumb.pf-m-shadow-top,
.pf-v5-c-page__main-tabs.pf-m-shadow-top,
.pf-v5-c-page__main-section.pf-m-shadow-top,
.pf-v5-c-page__main-wizard.pf-m-shadow-top,
.pf-v5-c-page__main-group.pf-m-shadow-top,
.pf-v5-c-page__main-subnav.pf-m-shadow-top {
  z-index: var(--pf-v5-c-page--section--m-shadow-top--ZIndex);
  box-shadow: var(--pf-v5-c-page--section--m-shadow-top--BoxShadow);
}

.pf-v5-c-page__main-nav.pf-m-sticky-top,
.pf-v5-c-page__main-breadcrumb.pf-m-sticky-top,
.pf-v5-c-page__main-tabs.pf-m-sticky-top,
.pf-v5-c-page__main-section.pf-m-sticky-top,
.pf-v5-c-page__main-wizard.pf-m-sticky-top,
.pf-v5-c-page__main-group.pf-m-sticky-top,
.pf-v5-c-page__main-subnav.pf-m-sticky-top {
  position: sticky;
  inset-block-start: 0;
  z-index: var(--pf-v5-c-page--section--m-sticky-top--ZIndex);
  box-shadow: var(--pf-v5-c-page--section--m-sticky-top--BoxShadow);
}

.pf-v5-c-page__main-nav.pf-m-sticky-bottom,
.pf-v5-c-page__main-breadcrumb.pf-m-sticky-bottom,
.pf-v5-c-page__main-tabs.pf-m-sticky-bottom,
.pf-v5-c-page__main-section.pf-m-sticky-bottom,
.pf-v5-c-page__main-wizard.pf-m-sticky-bottom,
.pf-v5-c-page__main-group.pf-m-sticky-bottom,
.pf-v5-c-page__main-subnav.pf-m-sticky-bottom {
  position: sticky;
  inset-block-end: 0;
  z-index: var(--pf-v5-c-page--section--m-sticky-bottom--ZIndex);
  box-shadow: var(--pf-v5-c-page--section--m-sticky-bottom--BoxShadow);
}

@media (min-height: 0) {
  .pf-v5-c-page__main-nav.pf-m-sticky-top-on-sm-height,
  .pf-v5-c-page__main-breadcrumb.pf-m-sticky-top-on-sm-height,
  .pf-v5-c-page__main-tabs.pf-m-sticky-top-on-sm-height,
  .pf-v5-c-page__main-section.pf-m-sticky-top-on-sm-height,
  .pf-v5-c-page__main-wizard.pf-m-sticky-top-on-sm-height,
  .pf-v5-c-page__main-group.pf-m-sticky-top-on-sm-height,
  .pf-v5-c-page__main-subnav.pf-m-sticky-top-on-sm-height {
    position: sticky;
    inset-block-start: 0;
    z-index: var(--pf-v5-c-page--section--m-sticky-top--ZIndex);
    box-shadow: var(--pf-v5-c-page--section--m-sticky-top--BoxShadow);
  }

  .pf-v5-c-page__main-nav.pf-m-sticky-bottom-on-sm-height,
  .pf-v5-c-page__main-breadcrumb.pf-m-sticky-bottom-on-sm-height,
  .pf-v5-c-page__main-tabs.pf-m-sticky-bottom-on-sm-height,
  .pf-v5-c-page__main-section.pf-m-sticky-bottom-on-sm-height,
  .pf-v5-c-page__main-wizard.pf-m-sticky-bottom-on-sm-height,
  .pf-v5-c-page__main-group.pf-m-sticky-bottom-on-sm-height,
  .pf-v5-c-page__main-subnav.pf-m-sticky-bottom-on-sm-height {
    position: sticky;
    inset-block-end: 0;
    z-index: var(--pf-v5-c-page--section--m-sticky-bottom--ZIndex);
    box-shadow: var(--pf-v5-c-page--section--m-sticky-bottom--BoxShadow);
  }
}

@media (min-height: 40rem) {
  .pf-v5-c-page__main-nav.pf-m-sticky-top-on-md-height,
  .pf-v5-c-page__main-breadcrumb.pf-m-sticky-top-on-md-height,
  .pf-v5-c-page__main-tabs.pf-m-sticky-top-on-md-height,
  .pf-v5-c-page__main-section.pf-m-sticky-top-on-md-height,
  .pf-v5-c-page__main-wizard.pf-m-sticky-top-on-md-height,
  .pf-v5-c-page__main-group.pf-m-sticky-top-on-md-height,
  .pf-v5-c-page__main-subnav.pf-m-sticky-top-on-md-height {
    position: sticky;
    inset-block-start: 0;
    z-index: var(--pf-v5-c-page--section--m-sticky-top--ZIndex);
    box-shadow: var(--pf-v5-c-page--section--m-sticky-top--BoxShadow);
  }

  .pf-v5-c-page__main-nav.pf-m-sticky-bottom-on-md-height,
  .pf-v5-c-page__main-breadcrumb.pf-m-sticky-bottom-on-md-height,
  .pf-v5-c-page__main-tabs.pf-m-sticky-bottom-on-md-height,
  .pf-v5-c-page__main-section.pf-m-sticky-bottom-on-md-height,
  .pf-v5-c-page__main-wizard.pf-m-sticky-bottom-on-md-height,
  .pf-v5-c-page__main-group.pf-m-sticky-bottom-on-md-height,
  .pf-v5-c-page__main-subnav.pf-m-sticky-bottom-on-md-height {
    position: sticky;
    inset-block-end: 0;
    z-index: var(--pf-v5-c-page--section--m-sticky-bottom--ZIndex);
    box-shadow: var(--pf-v5-c-page--section--m-sticky-bottom--BoxShadow);
  }
}

@media (min-height: 48rem) {
  .pf-v5-c-page__main-nav.pf-m-sticky-top-on-lg-height,
  .pf-v5-c-page__main-breadcrumb.pf-m-sticky-top-on-lg-height,
  .pf-v5-c-page__main-tabs.pf-m-sticky-top-on-lg-height,
  .pf-v5-c-page__main-section.pf-m-sticky-top-on-lg-height,
  .pf-v5-c-page__main-wizard.pf-m-sticky-top-on-lg-height,
  .pf-v5-c-page__main-group.pf-m-sticky-top-on-lg-height,
  .pf-v5-c-page__main-subnav.pf-m-sticky-top-on-lg-height {
    position: sticky;
    inset-block-start: 0;
    z-index: var(--pf-v5-c-page--section--m-sticky-top--ZIndex);
    box-shadow: var(--pf-v5-c-page--section--m-sticky-top--BoxShadow);
  }

  .pf-v5-c-page__main-nav.pf-m-sticky-bottom-on-lg-height,
  .pf-v5-c-page__main-breadcrumb.pf-m-sticky-bottom-on-lg-height,
  .pf-v5-c-page__main-tabs.pf-m-sticky-bottom-on-lg-height,
  .pf-v5-c-page__main-section.pf-m-sticky-bottom-on-lg-height,
  .pf-v5-c-page__main-wizard.pf-m-sticky-bottom-on-lg-height,
  .pf-v5-c-page__main-group.pf-m-sticky-bottom-on-lg-height,
  .pf-v5-c-page__main-subnav.pf-m-sticky-bottom-on-lg-height {
    position: sticky;
    inset-block-end: 0;
    z-index: var(--pf-v5-c-page--section--m-sticky-bottom--ZIndex);
    box-shadow: var(--pf-v5-c-page--section--m-sticky-bottom--BoxShadow);
  }
}

@media (min-height: 60rem) {
  .pf-v5-c-page__main-nav.pf-m-sticky-top-on-xl-height,
  .pf-v5-c-page__main-breadcrumb.pf-m-sticky-top-on-xl-height,
  .pf-v5-c-page__main-tabs.pf-m-sticky-top-on-xl-height,
  .pf-v5-c-page__main-section.pf-m-sticky-top-on-xl-height,
  .pf-v5-c-page__main-wizard.pf-m-sticky-top-on-xl-height,
  .pf-v5-c-page__main-group.pf-m-sticky-top-on-xl-height,
  .pf-v5-c-page__main-subnav.pf-m-sticky-top-on-xl-height {
    position: sticky;
    inset-block-start: 0;
    z-index: var(--pf-v5-c-page--section--m-sticky-top--ZIndex);
    box-shadow: var(--pf-v5-c-page--section--m-sticky-top--BoxShadow);
  }

  .pf-v5-c-page__main-nav.pf-m-sticky-bottom-on-xl-height,
  .pf-v5-c-page__main-breadcrumb.pf-m-sticky-bottom-on-xl-height,
  .pf-v5-c-page__main-tabs.pf-m-sticky-bottom-on-xl-height,
  .pf-v5-c-page__main-section.pf-m-sticky-bottom-on-xl-height,
  .pf-v5-c-page__main-wizard.pf-m-sticky-bottom-on-xl-height,
  .pf-v5-c-page__main-group.pf-m-sticky-bottom-on-xl-height,
  .pf-v5-c-page__main-subnav.pf-m-sticky-bottom-on-xl-height {
    position: sticky;
    inset-block-end: 0;
    z-index: var(--pf-v5-c-page--section--m-sticky-bottom--ZIndex);
    box-shadow: var(--pf-v5-c-page--section--m-sticky-bottom--BoxShadow);
  }
}

@media (min-height: 80rem) {
  .pf-v5-c-page__main-nav.pf-m-sticky-top-on-2xl-height,
  .pf-v5-c-page__main-breadcrumb.pf-m-sticky-top-on-2xl-height,
  .pf-v5-c-page__main-tabs.pf-m-sticky-top-on-2xl-height,
  .pf-v5-c-page__main-section.pf-m-sticky-top-on-2xl-height,
  .pf-v5-c-page__main-wizard.pf-m-sticky-top-on-2xl-height,
  .pf-v5-c-page__main-group.pf-m-sticky-top-on-2xl-height,
  .pf-v5-c-page__main-subnav.pf-m-sticky-top-on-2xl-height {
    position: sticky;
    inset-block-start: 0;
    z-index: var(--pf-v5-c-page--section--m-sticky-top--ZIndex);
    box-shadow: var(--pf-v5-c-page--section--m-sticky-top--BoxShadow);
  }

  .pf-v5-c-page__main-nav.pf-m-sticky-bottom-on-2xl-height,
  .pf-v5-c-page__main-breadcrumb.pf-m-sticky-bottom-on-2xl-height,
  .pf-v5-c-page__main-tabs.pf-m-sticky-bottom-on-2xl-height,
  .pf-v5-c-page__main-section.pf-m-sticky-bottom-on-2xl-height,
  .pf-v5-c-page__main-wizard.pf-m-sticky-bottom-on-2xl-height,
  .pf-v5-c-page__main-group.pf-m-sticky-bottom-on-2xl-height,
  .pf-v5-c-page__main-subnav.pf-m-sticky-bottom-on-2xl-height {
    position: sticky;
    inset-block-end: 0;
    z-index: var(--pf-v5-c-page--section--m-sticky-bottom--ZIndex);
    box-shadow: var(--pf-v5-c-page--section--m-sticky-bottom--BoxShadow);
  }
}

.pf-v5-c-page__main,
.pf-v5-c-page__drawer {
  z-index: var(--pf-v5-c-page__main--ZIndex);
  grid-area: main;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.pf-v5-c-page__main:focus,
.pf-v5-c-page__drawer:focus {
  outline: 0;
}

.pf-v5-c-page__main,
.pf-v5-c-page__main-drawer,
.pf-v5-c-page__main-group {
  display: flex;
  flex-direction: column;
}

.pf-v5-c-page__main-nav {
  padding-block-start: var(--pf-v5-c-page__main-nav--PaddingTop);
  padding-inline-start: var(--pf-v5-c-page__main-nav--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__main-nav--PaddingRight);
  background-color: var(--pf-v5-c-page__main-nav--BackgroundColor);
}

.pf-v5-c-page__main-nav.pf-m-sticky-top,
.pf-v5-c-page__main-group.pf-m-sticky-top .pf-v5-c-page__main-nav:last-child {
  padding-block-end: var(--pf-v5-c-page__main-nav--m-sticky-top--PaddingBottom);
}

@media (min-height: 0) {
  .pf-v5-c-page__main-nav.pf-m-sticky-top-on-sm-height,
  .pf-v5-c-page__main-group.pf-m-sticky-top-on-sm-height .pf-v5-c-page__main-nav:last-child {
    padding-block-end: var(--pf-v5-c-page__main-nav--m-sticky-top--PaddingBottom);
  }
}

@media (min-height: 40rem) {
  .pf-v5-c-page__main-nav.pf-m-sticky-top-on-md-height,
  .pf-v5-c-page__main-group.pf-m-sticky-top-on-md-height .pf-v5-c-page__main-nav:last-child {
    padding-block-end: var(--pf-v5-c-page__main-nav--m-sticky-top--PaddingBottom);
  }
}

@media (min-height: 48rem) {
  .pf-v5-c-page__main-nav.pf-m-sticky-top-on-lg-height,
  .pf-v5-c-page__main-group.pf-m-sticky-top-on-lg-height .pf-v5-c-page__main-nav:last-child {
    padding-block-end: var(--pf-v5-c-page__main-nav--m-sticky-top--PaddingBottom);
  }
}

@media (min-height: 60rem) {
  .pf-v5-c-page__main-nav.pf-m-sticky-top-on-xl-height,
  .pf-v5-c-page__main-group.pf-m-sticky-top-on-xl-height .pf-v5-c-page__main-nav:last-child {
    padding-block-end: var(--pf-v5-c-page__main-nav--m-sticky-top--PaddingBottom);
  }
}

@media (min-height: 80rem) {
  .pf-v5-c-page__main-nav.pf-m-sticky-top-on-2xl-height,
  .pf-v5-c-page__main-group.pf-m-sticky-top-on-2xl-height .pf-v5-c-page__main-nav:last-child {
    padding-block-end: var(--pf-v5-c-page__main-nav--m-sticky-top--PaddingBottom);
  }
}

.pf-v5-c-page__main-subnav {
  background-color: var(--pf-v5-c-page__main-subnav--BackgroundColor);
  border-block-start: var(--pf-v5-c-page__main-subnav--BorderTopWidth) solid var(--pf-v5-c-page__main-subnav--BorderTopColor);
  border-inline-start: var(--pf-v5-c-page__main-subnav--BorderLeftWidth) solid var(--pf-v5-c-page__main-subnav--BorderLeftColor);
}

@media screen and (min-width: 1200px) {
  .pf-v5-c-page__sidebar~.pf-v5-c-page__main {
    --pf-v5-c-page__main-subnav--BorderLeftWidth: var(--pf-v5-c-page__sidebar--main__main-subnav--BorderLeftWidth);
  }
}

.pf-v5-c-page__sidebar.pf-m-collapsed~.pf-v5-c-page__main {
  --pf-v5-c-page__main-subnav--BorderLeftWidth: var(--pf-v5-c-page__sidebar--m-collapsed--main__main-subnav--BorderLeftWidth);
}

.pf-v5-c-page__main-breadcrumb {
  padding-block-start: var(--pf-v5-c-page__main-breadcrumb--PaddingTop);
  padding-block-end: var(--pf-v5-c-page__main-breadcrumb--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-page__main-breadcrumb--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__main-breadcrumb--PaddingRight);
  background-color: var(--pf-v5-c-page__main-breadcrumb--BackgroundColor);
}

.pf-v5-c-page__main-breadcrumb+.pf-v5-c-page__main-section {
  --pf-v5-c-page__main-section--PaddingTop: var(--pf-v5-c-page__main-breadcrumb--main-section--PaddingTop);
}

.pf-v5-c-page__main-breadcrumb.pf-m-sticky-top,
.pf-v5-c-page__main-group.pf-m-sticky-top .pf-v5-c-page__main-breadcrumb:last-child {
  --pf-v5-c-page__main-breadcrumb--PaddingBottom: var(--pf-v5-c-page__main-breadcrumb--m-sticky-top--PaddingBottom);
}

@media (min-height: 0) {
  .pf-v5-c-page__main-breadcrumb.pf-m-sticky-top-on-sm-height,
  .pf-v5-c-page__main-group.pf-m-sticky-top-on-sm-height .pf-v5-c-page__main-breadcrumb:last-child {
    --pf-v5-c-page__main-breadcrumb--PaddingBottom: var(--pf-v5-c-page__main-breadcrumb--m-sticky-top--PaddingBottom);
  }
}

@media (min-height: 40rem) {
  .pf-v5-c-page__main-breadcrumb.pf-m-sticky-top-on-md-height,
  .pf-v5-c-page__main-group.pf-m-sticky-top-on-md-height .pf-v5-c-page__main-breadcrumb:last-child {
    --pf-v5-c-page__main-breadcrumb--PaddingBottom: var(--pf-v5-c-page__main-breadcrumb--m-sticky-top--PaddingBottom);
  }
}

@media (min-height: 48rem) {
  .pf-v5-c-page__main-breadcrumb.pf-m-sticky-top-on-lg-height,
  .pf-v5-c-page__main-group.pf-m-sticky-top-on-lg-height .pf-v5-c-page__main-breadcrumb:last-child {
    --pf-v5-c-page__main-breadcrumb--PaddingBottom: var(--pf-v5-c-page__main-breadcrumb--m-sticky-top--PaddingBottom);
  }
}

@media (min-height: 60rem) {
  .pf-v5-c-page__main-breadcrumb.pf-m-sticky-top-on-xl-height,
  .pf-v5-c-page__main-group.pf-m-sticky-top-on-xl-height .pf-v5-c-page__main-breadcrumb:last-child {
    --pf-v5-c-page__main-breadcrumb--PaddingBottom: var(--pf-v5-c-page__main-breadcrumb--m-sticky-top--PaddingBottom);
  }
}

@media (min-height: 80rem) {
  .pf-v5-c-page__main-breadcrumb.pf-m-sticky-top-on-2xl-height,
  .pf-v5-c-page__main-group.pf-m-sticky-top-on-2xl-height .pf-v5-c-page__main-breadcrumb:last-child {
    --pf-v5-c-page__main-breadcrumb--PaddingBottom: var(--pf-v5-c-page__main-breadcrumb--m-sticky-top--PaddingBottom);
  }
}

.pf-v5-c-page__main-tabs {
  padding-block-start: var(--pf-v5-c-page__main-tabs--PaddingTop);
  padding-block-end: var(--pf-v5-c-page__main-tabs--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-page__main-tabs--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__main-tabs--PaddingRight);
  background-color: var(--pf-v5-c-page__main-tabs--BackgroundColor);
}

.pf-v5-c-page__main-nav+.pf-v5-c-page__main-tabs {
  --pf-v5-c-page__main-tabs--PaddingTop: var(--pf-v5-c-page__main-nav--page__main-tabs--PaddingTop);
}

.pf-v5-c-page__main-breadcrumb+.pf-v5-c-page__main-tabs {
  --pf-v5-c-page__main-tabs--PaddingTop: var(--pf-v5-c-page__main-breadcrumb--page__main-tabs--PaddingTop);
}

.pf-v5-c-page__main-section:last-child,
.pf-v5-c-page__main-section:only-child,
.pf-v5-c-page__main-section.pf-m-fill,
.pf-v5-c-page__main-group:last-child,
.pf-v5-c-page__main-group:only-child,
.pf-v5-c-page__main-group.pf-m-fill,
.pf-v5-c-page__main-wizard:last-child,
.pf-v5-c-page__main-wizard:only-child,
.pf-v5-c-page__main-wizard.pf-m-fill {
  flex-grow: 1;
}

.pf-v5-c-page__main-section.pf-m-no-fill,
.pf-v5-c-page__main-group.pf-m-no-fill,
.pf-v5-c-page__main-wizard.pf-m-no-fill {
  flex-grow: 0;
}

.pf-v5-c-page__main-section {
  padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
  padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
  background-color: var(--pf-v5-c-page__main-section--BackgroundColor);
}

.pf-v5-c-page__main-section.pf-m-light {
  --pf-v5-c-page__main-section--BackgroundColor: var(--pf-v5-c-page__main-section--m-light--BackgroundColor);
}

.pf-v5-c-page__main-section.pf-m-light-100 {
  --pf-v5-c-page__main-section--BackgroundColor: var(--pf-v5-c-page__main-section--m-light-100--BackgroundColor);
}

.pf-v5-c-page__main-section[class*=pf-m-dark-] {
  color: var(--pf-v5-global--Color--100);
}

.pf-v5-c-page__main-section.pf-m-dark-100 {
  --pf-v5-c-page__main-section--BackgroundColor: var(--pf-v5-c-page__main-section--m-dark-100--BackgroundColor);
}

.pf-v5-c-page__main-section.pf-m-dark-200 {
  --pf-v5-c-page__main-section--BackgroundColor: var(--pf-v5-c-page__main-section--m-dark-200--BackgroundColor);
}

.pf-v5-c-page__main-section.pf-m-padding {
  padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
  padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
}

.pf-v5-c-page__main-section.pf-m-padding.pf-m-limit-width {
  padding: 0;
}

.pf-v5-c-page__main-section.pf-m-padding.pf-m-limit-width .pf-v5-c-page__main-body {
  padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
  padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
}

.pf-v5-c-page__main-section.pf-m-no-padding,
.pf-v5-c-page__main-section.pf-m-no-padding.pf-m-limit-width .pf-v5-c-page__main-body {
  padding: 0;
}

@media (min-width: 576px) {
  .pf-v5-c-page__main-section.pf-m-padding-on-sm {
    padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
    padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
  }

  .pf-v5-c-page__main-section.pf-m-padding-on-sm.pf-m-limit-width {
    padding: 0;
  }

  .pf-v5-c-page__main-section.pf-m-padding-on-sm.pf-m-limit-width .pf-v5-c-page__main-body {
    padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
    padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
  }

  .pf-v5-c-page__main-section.pf-m-no-padding-on-sm,
  .pf-v5-c-page__main-section.pf-m-no-padding-on-sm.pf-m-limit-width .pf-v5-c-page__main-body {
    padding: 0;
  }
}

@media (min-width: 768px) {
  .pf-v5-c-page__main-section.pf-m-padding-on-md {
    padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
    padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
  }

  .pf-v5-c-page__main-section.pf-m-padding-on-md.pf-m-limit-width {
    padding: 0;
  }

  .pf-v5-c-page__main-section.pf-m-padding-on-md.pf-m-limit-width .pf-v5-c-page__main-body {
    padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
    padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
  }

  .pf-v5-c-page__main-section.pf-m-no-padding-on-md,
  .pf-v5-c-page__main-section.pf-m-no-padding-on-md.pf-m-limit-width .pf-v5-c-page__main-body {
    padding: 0;
  }
}

@media (min-width: 992px) {
  .pf-v5-c-page__main-section.pf-m-padding-on-lg {
    padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
    padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
  }

  .pf-v5-c-page__main-section.pf-m-padding-on-lg.pf-m-limit-width {
    padding: 0;
  }

  .pf-v5-c-page__main-section.pf-m-padding-on-lg.pf-m-limit-width .pf-v5-c-page__main-body {
    padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
    padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
  }

  .pf-v5-c-page__main-section.pf-m-no-padding-on-lg,
  .pf-v5-c-page__main-section.pf-m-no-padding-on-lg.pf-m-limit-width .pf-v5-c-page__main-body {
    padding: 0;
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-page__main-section.pf-m-padding-on-xl {
    padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
    padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
  }

  .pf-v5-c-page__main-section.pf-m-padding-on-xl.pf-m-limit-width {
    padding: 0;
  }

  .pf-v5-c-page__main-section.pf-m-padding-on-xl.pf-m-limit-width .pf-v5-c-page__main-body {
    padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
    padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
  }

  .pf-v5-c-page__main-section.pf-m-no-padding-on-xl,
  .pf-v5-c-page__main-section.pf-m-no-padding-on-xl.pf-m-limit-width .pf-v5-c-page__main-body {
    padding: 0;
  }
}

@media (min-width: 1450px) {
  .pf-v5-c-page__main-section.pf-m-padding-on-2xl {
    padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
    padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
  }

  .pf-v5-c-page__main-section.pf-m-padding-on-2xl.pf-m-limit-width {
    padding: 0;
  }

  .pf-v5-c-page__main-section.pf-m-padding-on-2xl.pf-m-limit-width .pf-v5-c-page__main-body {
    padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
    padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
  }

  .pf-v5-c-page__main-section.pf-m-no-padding-on-2xl,
  .pf-v5-c-page__main-section.pf-m-no-padding-on-2xl.pf-m-limit-width .pf-v5-c-page__main-body {
    padding: 0;
  }
}

.pf-v5-c-page__main-wizard {
  flex: 1 1;
  min-height: 0;
  background-color: var(--pf-v5-c-page__main-wizard--BackgroundColor);
  border-block-start: var(--pf-v5-c-page__main-wizard--BorderTopWidth) solid var(--pf-v5-c-page__main-wizard--BorderTopColor);
}

.pf-v5-c-page__main-wizard:first-child {
  --pf-v5-c-page__main-wizard--BorderTopWidth: 0;
}

.pf-v5-c-page__main-wizard.pf-m-light-200 {
  --pf-v5-c-page__main-wizard--BackgroundColor: var(--pf-v5-c-page__main-wizard--m-light-200--BackgroundColor);
}

.pf-v5-c-page__main-wizard .pf-v5-c-page__main-body {
  min-height: 0;
}

.pf-v5-c-page__main-group {
  flex-shrink: 0;
}

.pf-v5-c-page__main-nav .pf-v5-c-page__main-body {
  padding-block-start: var(--pf-v5-c-page__main-nav--PaddingTop);
  padding-inline-start: var(--pf-v5-c-page__main-nav--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__main-nav--PaddingRight);
}

.pf-v5-c-page__main-breadcrumb .pf-v5-c-page__main-body {
  padding-block-start: var(--pf-v5-c-page__main-breadcrumb--PaddingTop);
  padding-block-end: var(--pf-v5-c-page__main-breadcrumb--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-page__main-breadcrumb--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__main-breadcrumb--PaddingRight);
}

.pf-v5-c-page__main-section .pf-v5-c-page__main-body {
  padding-block-start: var(--pf-v5-c-page__main-section--PaddingTop);
  padding-block-end: var(--pf-v5-c-page__main-section--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-page__main-section--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__main-section--PaddingRight);
}

.pf-v5-c-page__main-tabs .pf-v5-c-page__main-body {
  padding-block-start: var(--pf-v5-c-page__main-tabs--PaddingTop);
  padding-block-end: var(--pf-v5-c-page__main-tabs--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-page__main-tabs--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-page__main-tabs--PaddingRight);
}

.pf-v5-c-page__drawer {
  grid-area: main;
}

.pf-v5-c-page__drawer>.pf-v5-c-drawer {
  flex: 1 0 auto;
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark):root {
  --pf-v5-c-page__main-section--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-page__header-tools--c-button--m-selected--before--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-page__sidebar--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-page__header--BackgroundColor: var(--pf-v5-global--palette--black-1000);
}

:where(.pf-v5-theme-dark) .pf-v5-c-page__header-tools-item.pf-m-selected .pf-v5-c-button {
  color: var(--pf-v5-global--Color--100);
}

:where(.pf-v5-theme-dark) .pf-v5-c-page__header-tools-item.pf-m-selected .pf-v5-c-button .pf-v5-c-notification-badge:hover::before {
  background-color: var(--pf-v5-c-page__header-tools--c-button--m-selected--before--BackgroundColor);
}

:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar {
  border-inline-end: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
}

:where(.pf-v5-theme-dark) .pf-v5-c-page__main-nav.pf-m-sticky-top,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-nav.pf-m-sticky-bottom {
  --pf-v5-c-page__main-nav--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-page__main-breadcrumb.pf-m-sticky-top,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-breadcrumb.pf-m-sticky-bottom {
  --pf-v5-c-page__main-breadcrumb--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-page__main-group.pf-m-sticky-top,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-group.pf-m-sticky-bottom {
  --pf-v5-c-page__main-section--m-light--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-page__main-section--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-page__main-breadcrumb--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-page__main-nav--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section.pf-m-sticky-top,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section.pf-m-sticky-bottom {
  --pf-v5-c-page__main-section--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-],
:where(.pf-v5-theme-dark) .pf-v5-c-page__header {
  color: var(--pf-v5-global--Color--100);
}

.pf-v5-c-pagination {
  --pf-v5-c-pagination--inset: 0;
  --pf-v5-c-pagination--PaddingRight: var(--pf-v5-c-pagination--inset);
  --pf-v5-c-pagination--PaddingLeft: var(--pf-v5-c-pagination--inset);
  --pf-v5-c-pagination--m-page-insets--inset: var(--pf-v5-global--spacer--md);
  --pf-v5-c-pagination--m-page-insets--xl--inset: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-pagination--child--MarginRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-pagination--m-bottom--child--MarginRight: 0;
  --pf-v5-c-pagination--m-bottom--child--md--MarginRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-pagination--m-compact--child--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-pagination__nav--Display: none;
  --pf-v5-c-pagination--m-display-summary__nav--Display: none;
  --pf-v5-c-pagination--m-display-full__nav--Display: inline-flex;
  --pf-v5-c-pagination__nav-control--c-button--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-pagination__nav-control--c-button--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-pagination__nav-control--c-button--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-pagination--m-bottom__nav-control--c-button--OutlineOffset: calc(var(--pf-v5-global--spacer--xs) * -1);
  --pf-v5-c-pagination--m-bottom__nav-control--c-button--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-pagination--m-bottom__nav-control--c-button--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-pagination--m-bottom__nav-control--c-button--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-pagination--m-bottom__nav-control--c-button--md--PaddingTop: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-pagination--m-bottom__nav-control--c-button--md--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-pagination--m-bottom__nav-control--c-button--md--PaddingBottom: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-pagination--m-bottom__nav-control--c-button--md--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-pagination--m-compact__nav-control--nav-control--MarginLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-pagination__nav-page-select--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-pagination__nav-page-select--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-pagination__nav-page-select--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-pagination__nav-page-select--child--MarginRight: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-pagination__nav-page-select--c-form-control--width-base: calc(var(--pf-v5-global--spacer--sm) * 2 + var(--pf-v5-global--BorderWidth--sm) * 2);
  --pf-v5-c-pagination__nav-page-select--c-form-control--width-chars: 2;
  --pf-v5-c-pagination__nav-page-select--c-form-control--Width: calc(var(--pf-v5-c-pagination__nav-page-select--c-form-control--width-base) + (var(--pf-v5-c-pagination__nav-page-select--c-form-control--width-chars) * 1ch));
  --pf-v5-c-pagination__total-items--Display: block;
  --pf-v5-c-pagination--m-display-summary__total-items--Display: block;
  --pf-v5-c-pagination--m-display-full__total-items--Display: none;
  --pf-v5-c-pagination--m-sticky--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-pagination--m-sticky--BoxShadow: var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-pagination--m-sticky--md--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-pagination--m-sticky--md--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-pagination--m-sticky--md--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-pagination--m-sticky--md--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-pagination--m-sticky--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-pagination--m-sticky--Top: 0;
  --pf-v5-c-pagination--m-bottom--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-pagination--m-bottom--BoxShadow: var(--pf-v5-global--BoxShadow--sm-top);
  --pf-v5-c-pagination--m-bottom--Bottom: 0;
  --pf-v5-c-pagination--m-bottom--md--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-pagination--m-bottom--md--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-pagination--m-bottom--md--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-pagination--m-bottom--md--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-pagination--m-bottom--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-pagination--m-bottom--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-pagination--m-bottom--m-sticky--BoxShadow: var(--pf-v5-global--BoxShadow--sm-top);
  --pf-v5-c-pagination--c-menu-toggle--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-pagination--c-menu-toggle--Display--base: inline-flex;
  --pf-v5-c-pagination--c-menu-toggle--Display: none;
  --pf-v5-c-pagination--c-menu-toggle--md--Display: var(--pf-v5-c-pagination--c-menu-toggle--Display--base);
  --pf-v5-c-pagination--m-display-summary--c-menu-toggle--Display: none;
  --pf-v5-c-pagination--m-display-full--c-menu-toggle--Display: var(--pf-v5-c-pagination--c-menu-toggle--Display--base);
  --pf-v5-c-pagination--m-bottom--c-menu-toggle--Display: var(--pf-v5-c-pagination--c-menu-toggle--Display--base);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding-inline-start: var(--pf-v5-c-pagination--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-pagination--PaddingRight);
}

@media screen and (min-width: 768px) {
  .pf-v5-c-pagination {
    --pf-v5-c-pagination--m-bottom__nav-control--c-button--PaddingTop: var(--pf-v5-c-pagination--m-bottom__nav-control--c-button--md--PaddingTop);
    --pf-v5-c-pagination--m-bottom__nav-control--c-button--PaddingRight: var(--pf-v5-c-pagination--m-bottom__nav-control--c-button--md--PaddingRight);
    --pf-v5-c-pagination--m-bottom__nav-control--c-button--PaddingBottom: var(--pf-v5-c-pagination--m-bottom__nav-control--c-button--md--PaddingBottom);
    --pf-v5-c-pagination--m-bottom__nav-control--c-button--PaddingLeft: var(--pf-v5-c-pagination--m-bottom__nav-control--c-button--md--PaddingLeft);
    --pf-v5-c-pagination--m-bottom--child--MarginRight: var(--pf-v5-c-pagination--m-bottom--child--md--MarginRight);
    --pf-v5-c-pagination--m-bottom__nav-control--c-button--OutlineOffset: 0;
    --pf-v5-c-pagination--m-bottom--BoxShadow: none;
    --pf-v5-c-pagination--c-menu-toggle--Display: var(--pf-v5-c-pagination--c-menu-toggle--md--Display);
    --pf-v5-c-pagination__nav--Display: inline-flex;
    --pf-v5-c-pagination__total-items--Display: none;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-c-pagination {
    --pf-v5-c-pagination--m-bottom--md--PaddingRight: var(--pf-v5-c-pagination--m-bottom--xl--PaddingRight);
    --pf-v5-c-pagination--m-bottom--md--PaddingLeft: var(--pf-v5-c-pagination--m-bottom--xl--PaddingLeft);
    --pf-v5-c-pagination__scroll-button--Width: var(--pf-v5-c-pagination__scroll-button--xl--Width);
    --pf-v5-c-pagination--m-page-insets--inset: var(--pf-v5-c-pagination--m-page-insets--xl--inset);
  }
}

.pf-v5-c-pagination>*:not(:last-child):not(.pf-v5-c-pagination__total-items) {
  margin-inline-end: var(--pf-v5-c-pagination--child--MarginRight);
}

.pf-v5-c-pagination .pf-v5-c-menu-toggle {
  display: var(--pf-v5-c-pagination--c-menu-toggle--Display);
  font-size: var(--pf-v5-c-pagination--c-menu-toggle--FontSize);
}

.pf-v5-c-pagination.pf-m-bottom {
  --pf-v5-c-pagination--child--MarginRight: var(--pf-v5-c-pagination--m-bottom--child--MarginRight);
  --pf-v5-c-pagination__nav-control--c-button--PaddingRight: var(--pf-v5-c-pagination--m-bottom__nav-control--c-button--PaddingRight);
  --pf-v5-c-pagination__nav-control--c-button--PaddingLeft: var(--pf-v5-c-pagination--m-bottom__nav-control--c-button--PaddingRight);
  --pf-v5-c-pagination--m-sticky--BoxShadow: var(--pf-v5-c-pagination--m-bottom--m-sticky--BoxShadow);
  --pf-v5-c-pagination--m-sticky--Top: auto;
  position: sticky;
  inset-block-end: var(--pf-v5-c-pagination--m-bottom--Bottom);
  justify-content: center;
  background-color: var(--pf-v5-c-pagination--m-bottom--BackgroundColor);
  box-shadow: var(--pf-v5-c-pagination--m-bottom--BoxShadow);
}

.pf-v5-c-pagination.pf-m-bottom .pf-v5-c-pagination__nav-control .pf-v5-c-button {
  --pf-v5-c-button--PaddingTop: var(--pf-v5-c-pagination--m-bottom__nav-control--c-button--PaddingTop);
  --pf-v5-c-button--PaddingBottom: var(--pf-v5-c-pagination--m-bottom__nav-control--c-button--PaddingBottom);
  outline-offset: var(--pf-v5-c-pagination--m-bottom__nav-control--c-button--OutlineOffset);
}

.pf-v5-c-pagination.pf-m-bottom.pf-m-static {
  --pf-v5-c-pagination--m-bottom--MarginTop: 0;
  --pf-v5-c-pagination--m-bottom--BorderTopWidth: 0;
  position: relative;
  box-shadow: none;
}

.pf-v5-c-pagination.pf-m-bottom .pf-v5-c-pagination__nav-control.pf-m-first,
.pf-v5-c-pagination.pf-m-bottom .pf-v5-c-pagination__nav-control.pf-m-last,
.pf-v5-c-pagination.pf-m-bottom .pf-v5-c-pagination__nav-page-select {
  display: none;
}

.pf-v5-c-pagination.pf-m-bottom .pf-v5-c-menu-toggle {
  position: absolute;
  display: var(--pf-v5-c-pagination--m-bottom--c-menu-toggle--Display);
}

.pf-v5-c-pagination.pf-m-bottom .pf-v5-c-pagination__nav {
  display: flex;
  flex-basis: 100%;
  justify-content: space-between;
}

@media screen and (min-width: 768px) {
  .pf-v5-c-pagination.pf-m-bottom {
    --pf-v5-c-pagination--m-bottom--BorderTopWidth: 0;
    --pf-v5-c-pagination--m-bottom--MarginTop: 0;
    --pf-v5-c-pagination--m-bottom--Bottom: auto;
    position: relative;
    justify-content: flex-end;
    padding-block-start: var(--pf-v5-c-pagination--m-bottom--md--PaddingTop);
    padding-block-end: var(--pf-v5-c-pagination--m-bottom--md--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-pagination--m-bottom--md--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-pagination--m-bottom--md--PaddingRight);
  }

  .pf-v5-c-pagination.pf-m-bottom .pf-v5-c-pagination__nav-control.pf-m-first,
  .pf-v5-c-pagination.pf-m-bottom .pf-v5-c-pagination__nav-control.pf-m-last,
  .pf-v5-c-pagination.pf-m-bottom .pf-v5-c-pagination__nav-page-select {
    display: block;
  }

  .pf-v5-c-pagination.pf-m-bottom .pf-v5-c-pagination__nav-page-select {
    display: inline-flex;
  }

  .pf-v5-c-pagination.pf-m-bottom .pf-v5-c-menu-toggle {
    position: relative;
  }

  .pf-v5-c-pagination.pf-m-bottom .pf-v5-c-pagination__nav {
    display: inline-flex;
    flex-basis: auto;
  }
}

.pf-v5-c-pagination.pf-m-sticky {
  --pf-v5-c-pagination--m-bottom--Bottom: 0;
  position: sticky;
  inset-block-start: var(--pf-v5-c-pagination--m-sticky--Top);
  z-index: var(--pf-v5-c-pagination--m-sticky--ZIndex);
  padding-block-start: var(--pf-v5-c-pagination--m-sticky--PaddingTop);
  padding-block-end: var(--pf-v5-c-pagination--m-sticky--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-pagination--m-sticky--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-pagination--m-sticky--PaddingRight);
  background-color: var(--pf-v5-c-pagination--m-sticky--BackgroundColor);
  box-shadow: var(--pf-v5-c-pagination--m-sticky--BoxShadow);
}

@media screen and (min-width: 768px) {
  .pf-v5-c-pagination.pf-m-sticky {
    padding-block-start: var(--pf-v5-c-pagination--m-sticky--md--PaddingTop);
    padding-block-end: var(--pf-v5-c-pagination--m-sticky--md--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-pagination--m-sticky--md--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-pagination--m-sticky--md--PaddingRight);
  }
}

.pf-v5-c-pagination.pf-m-compact {
  --pf-v5-c-pagination--child--MarginRight: var(--pf-v5-c-pagination--m-compact--child--MarginRight);
}

.pf-v5-c-pagination.pf-m-page-insets {
  --pf-v5-c-pagination--inset: var(--pf-v5-c-pagination--m-page-insets--inset);
}

.pf-v5-c-pagination__nav {
  display: var(--pf-v5-c-pagination__nav--Display);
  justify-content: flex-end;
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-pagination__nav-control {
  scale: -1 1;
}

.pf-v5-c-pagination__nav-control .pf-v5-c-button {
  padding-inline-start: var(--pf-v5-c-pagination__nav-control--c-button--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-pagination__nav-control--c-button--PaddingRight);
  font-size: var(--pf-v5-c-pagination__nav-control--c-button--FontSize);
}

.pf-v5-c-pagination.pf-m-compact .pf-v5-c-pagination__nav-control+.pf-v5-c-pagination__nav-control {
  margin-inline-start: var(--pf-v5-c-pagination--m-compact__nav-control--nav-control--MarginLeft);
}

.pf-v5-c-pagination__nav-page-select {
  display: flex;
  align-items: center;
  padding-inline-start: var(--pf-v5-c-pagination__nav-page-select--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-pagination__nav-page-select--PaddingRight);
}

.pf-v5-c-pagination__nav-page-select>* {
  font-size: var(--pf-v5-c-pagination__nav-page-select--FontSize);
  white-space: nowrap;
}

.pf-v5-c-pagination__nav-page-select>*:not(:last-child) {
  margin-inline-end: var(--pf-v5-c-pagination__nav-page-select--child--MarginRight);
}

.pf-v5-c-pagination__nav-page-select .pf-v5-c-form-control {
  width: var(--pf-v5-c-pagination__nav-page-select--c-form-control--Width);
}

.pf-v5-c-pagination__total-items {
  display: var(--pf-v5-c-pagination__total-items--Display);
}

.pf-v5-c-pagination.pf-m-display-summary {
  --pf-v5-c-pagination__nav--Display: var(--pf-v5-c-pagination--m-display-summary__nav--Display);
  --pf-v5-c-pagination--c-menu-toggle--Display: var(--pf-v5-c-pagination--m-display-summary--c-menu-toggle--Display);
  --pf-v5-c-pagination__total-items--Display: var(--pf-v5-c-pagination--m-display-summary__total-items--Display);
}

.pf-v5-c-pagination.pf-m-display-full {
  --pf-v5-c-pagination__nav--Display: var(--pf-v5-c-pagination--m-display-full__nav--Display);
  --pf-v5-c-pagination--c-menu-toggle--Display: var(--pf-v5-c-pagination--m-display-full--c-menu-toggle--Display);
  --pf-v5-c-pagination__total-items--Display: var(--pf-v5-c-pagination--m-display-full__total-items--Display);
}

.pf-v5-c-pagination.pf-m-inset-none {
  --pf-v5-c-pagination--inset: 0;
}

.pf-v5-c-pagination.pf-m-inset-sm {
  --pf-v5-c-pagination--inset: var(--pf-v5-global--spacer--sm);
}

.pf-v5-c-pagination.pf-m-inset-md {
  --pf-v5-c-pagination--inset: var(--pf-v5-global--spacer--md);
}

.pf-v5-c-pagination.pf-m-inset-lg {
  --pf-v5-c-pagination--inset: var(--pf-v5-global--spacer--lg);
}

.pf-v5-c-pagination.pf-m-inset-xl {
  --pf-v5-c-pagination--inset: var(--pf-v5-global--spacer--xl);
}

.pf-v5-c-pagination.pf-m-inset-2xl {
  --pf-v5-c-pagination--inset: var(--pf-v5-global--spacer--2xl);
}

@media (min-width: 576px) {
  .pf-v5-c-pagination.pf-m-display-summary-on-sm {
    --pf-v5-c-pagination__nav--Display: var(--pf-v5-c-pagination--m-display-summary__nav--Display);
    --pf-v5-c-pagination--c-menu-toggle--Display: var(--pf-v5-c-pagination--m-display-summary--c-menu-toggle--Display);
    --pf-v5-c-pagination__total-items--Display: var(--pf-v5-c-pagination--m-display-summary__total-items--Display);
  }

  .pf-v5-c-pagination.pf-m-display-full-on-sm {
    --pf-v5-c-pagination__nav--Display: var(--pf-v5-c-pagination--m-display-full__nav--Display);
    --pf-v5-c-pagination--c-menu-toggle--Display: var(--pf-v5-c-pagination--m-display-full--c-menu-toggle--Display);
    --pf-v5-c-pagination__total-items--Display: var(--pf-v5-c-pagination--m-display-full__total-items--Display);
  }

  .pf-v5-c-pagination.pf-m-inset-none-on-sm {
    --pf-v5-c-pagination--inset: 0;
  }

  .pf-v5-c-pagination.pf-m-inset-sm-on-sm {
    --pf-v5-c-pagination--inset: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-pagination.pf-m-inset-md-on-sm {
    --pf-v5-c-pagination--inset: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-pagination.pf-m-inset-lg-on-sm {
    --pf-v5-c-pagination--inset: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-pagination.pf-m-inset-xl-on-sm {
    --pf-v5-c-pagination--inset: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-c-pagination.pf-m-inset-2xl-on-sm {
    --pf-v5-c-pagination--inset: var(--pf-v5-global--spacer--2xl);
  }
}

@media (min-width: 768px) {
  .pf-v5-c-pagination.pf-m-display-summary-on-md {
    --pf-v5-c-pagination__nav--Display: var(--pf-v5-c-pagination--m-display-summary__nav--Display);
    --pf-v5-c-pagination--c-menu-toggle--Display: var(--pf-v5-c-pagination--m-display-summary--c-menu-toggle--Display);
    --pf-v5-c-pagination__total-items--Display: var(--pf-v5-c-pagination--m-display-summary__total-items--Display);
  }

  .pf-v5-c-pagination.pf-m-display-full-on-md {
    --pf-v5-c-pagination__nav--Display: var(--pf-v5-c-pagination--m-display-full__nav--Display);
    --pf-v5-c-pagination--c-menu-toggle--Display: var(--pf-v5-c-pagination--m-display-full--c-menu-toggle--Display);
    --pf-v5-c-pagination__total-items--Display: var(--pf-v5-c-pagination--m-display-full__total-items--Display);
  }

  .pf-v5-c-pagination.pf-m-inset-none-on-md {
    --pf-v5-c-pagination--inset: 0;
  }

  .pf-v5-c-pagination.pf-m-inset-sm-on-md {
    --pf-v5-c-pagination--inset: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-pagination.pf-m-inset-md-on-md {
    --pf-v5-c-pagination--inset: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-pagination.pf-m-inset-lg-on-md {
    --pf-v5-c-pagination--inset: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-pagination.pf-m-inset-xl-on-md {
    --pf-v5-c-pagination--inset: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-c-pagination.pf-m-inset-2xl-on-md {
    --pf-v5-c-pagination--inset: var(--pf-v5-global--spacer--2xl);
  }
}

@media (min-width: 992px) {
  .pf-v5-c-pagination.pf-m-display-summary-on-lg {
    --pf-v5-c-pagination__nav--Display: var(--pf-v5-c-pagination--m-display-summary__nav--Display);
    --pf-v5-c-pagination--c-menu-toggle--Display: var(--pf-v5-c-pagination--m-display-summary--c-menu-toggle--Display);
    --pf-v5-c-pagination__total-items--Display: var(--pf-v5-c-pagination--m-display-summary__total-items--Display);
  }

  .pf-v5-c-pagination.pf-m-display-full-on-lg {
    --pf-v5-c-pagination__nav--Display: var(--pf-v5-c-pagination--m-display-full__nav--Display);
    --pf-v5-c-pagination--c-menu-toggle--Display: var(--pf-v5-c-pagination--m-display-full--c-menu-toggle--Display);
    --pf-v5-c-pagination__total-items--Display: var(--pf-v5-c-pagination--m-display-full__total-items--Display);
  }

  .pf-v5-c-pagination.pf-m-inset-none-on-lg {
    --pf-v5-c-pagination--inset: 0;
  }

  .pf-v5-c-pagination.pf-m-inset-sm-on-lg {
    --pf-v5-c-pagination--inset: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-pagination.pf-m-inset-md-on-lg {
    --pf-v5-c-pagination--inset: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-pagination.pf-m-inset-lg-on-lg {
    --pf-v5-c-pagination--inset: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-pagination.pf-m-inset-xl-on-lg {
    --pf-v5-c-pagination--inset: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-c-pagination.pf-m-inset-2xl-on-lg {
    --pf-v5-c-pagination--inset: var(--pf-v5-global--spacer--2xl);
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-pagination.pf-m-display-summary-on-xl {
    --pf-v5-c-pagination__nav--Display: var(--pf-v5-c-pagination--m-display-summary__nav--Display);
    --pf-v5-c-pagination--c-menu-toggle--Display: var(--pf-v5-c-pagination--m-display-summary--c-menu-toggle--Display);
    --pf-v5-c-pagination__total-items--Display: var(--pf-v5-c-pagination--m-display-summary__total-items--Display);
  }

  .pf-v5-c-pagination.pf-m-display-full-on-xl {
    --pf-v5-c-pagination__nav--Display: var(--pf-v5-c-pagination--m-display-full__nav--Display);
    --pf-v5-c-pagination--c-menu-toggle--Display: var(--pf-v5-c-pagination--m-display-full--c-menu-toggle--Display);
    --pf-v5-c-pagination__total-items--Display: var(--pf-v5-c-pagination--m-display-full__total-items--Display);
  }

  .pf-v5-c-pagination.pf-m-inset-none-on-xl {
    --pf-v5-c-pagination--inset: 0;
  }

  .pf-v5-c-pagination.pf-m-inset-sm-on-xl {
    --pf-v5-c-pagination--inset: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-pagination.pf-m-inset-md-on-xl {
    --pf-v5-c-pagination--inset: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-pagination.pf-m-inset-lg-on-xl {
    --pf-v5-c-pagination--inset: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-pagination.pf-m-inset-xl-on-xl {
    --pf-v5-c-pagination--inset: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-c-pagination.pf-m-inset-2xl-on-xl {
    --pf-v5-c-pagination--inset: var(--pf-v5-global--spacer--2xl);
  }
}

@media (min-width: 1450px) {
  .pf-v5-c-pagination.pf-m-display-summary-on-2xl {
    --pf-v5-c-pagination__nav--Display: var(--pf-v5-c-pagination--m-display-summary__nav--Display);
    --pf-v5-c-pagination--c-menu-toggle--Display: var(--pf-v5-c-pagination--m-display-summary--c-menu-toggle--Display);
    --pf-v5-c-pagination__total-items--Display: var(--pf-v5-c-pagination--m-display-summary__total-items--Display);
  }

  .pf-v5-c-pagination.pf-m-display-full-on-2xl {
    --pf-v5-c-pagination__nav--Display: var(--pf-v5-c-pagination--m-display-full__nav--Display);
    --pf-v5-c-pagination--c-menu-toggle--Display: var(--pf-v5-c-pagination--m-display-full--c-menu-toggle--Display);
    --pf-v5-c-pagination__total-items--Display: var(--pf-v5-c-pagination--m-display-full__total-items--Display);
  }

  .pf-v5-c-pagination.pf-m-inset-none-on-2xl {
    --pf-v5-c-pagination--inset: 0;
  }

  .pf-v5-c-pagination.pf-m-inset-sm-on-2xl {
    --pf-v5-c-pagination--inset: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-pagination.pf-m-inset-md-on-2xl {
    --pf-v5-c-pagination--inset: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-pagination.pf-m-inset-lg-on-2xl {
    --pf-v5-c-pagination--inset: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-pagination.pf-m-inset-xl-on-2xl {
    --pf-v5-c-pagination--inset: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-c-pagination.pf-m-inset-2xl-on-2xl {
    --pf-v5-c-pagination--inset: var(--pf-v5-global--spacer--2xl);
  }
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-pagination {
  --pf-v5-c-pagination--m-sticky--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
}

.pf-v5-c-panel {
  --pf-v5-c-panel--Width: auto;
  --pf-v5-c-panel--MinWidth: auto;
  --pf-v5-c-panel--MaxWidth: none;
  --pf-v5-c-panel--ZIndex: auto;
  --pf-v5-c-panel--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-panel--BoxShadow: none;
  --pf-v5-c-panel--before--BorderWidth: 0;
  --pf-v5-c-panel--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-panel--m-bordered--before--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-panel--m-raised--BoxShadow: var(--pf-v5-global--BoxShadow--md);
  --pf-v5-c-panel--m-raised--ZIndex: var(--pf-v5-global--ZIndex--sm);
  --pf-v5-c-panel--m-raised--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-panel__header--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-panel__header--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-panel__header--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-panel__header--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-panel__main--MaxHeight: none;
  --pf-v5-c-panel__main--Overflow: visible;
  --pf-v5-c-panel__main-body--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-panel__main-body--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-panel__main-body--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-panel__main-body--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-panel__footer--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-panel__footer--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-panel__footer--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-panel__footer--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-panel__footer--BoxShadow: none;
  --pf-v5-c-panel--m-scrollable__main--MaxHeight: 18.75rem;
  --pf-v5-c-panel--m-scrollable__main--Overflow: auto;
  --pf-v5-c-panel--m-scrollable__footer--BoxShadow: 0 -0.3125rem 0.25rem -0.25rem rgba(3, 3, 3, 0.16);
  position: relative;
  z-index: var(--pf-v5-c-panel--ZIndex);
  width: var(--pf-v5-c-panel--Width);
  min-width: var(--pf-v5-c-panel--MinWidth);
  max-width: var(--pf-v5-c-panel--MaxWidth);
  background-color: var(--pf-v5-c-panel--BackgroundColor);
  box-shadow: var(--pf-v5-c-panel--BoxShadow);
}

.pf-v5-c-panel::before {
  position: absolute;
  inset: 0;
  pointer-events: none;
  content: "";
  border: var(--pf-v5-c-panel--before--BorderWidth) solid var(--pf-v5-c-panel--before--BorderColor);
}

.pf-v5-c-panel.pf-m-bordered {
  --pf-v5-c-panel--before--BorderWidth: var(--pf-v5-c-panel--m-bordered--before--BorderWidth);
}

.pf-v5-c-panel.pf-m-raised {
  --pf-v5-c-panel--BackgroundColor: var(--pf-v5-c-panel--m-raised--BackgroundColor);
  --pf-v5-c-panel--BoxShadow: var(--pf-v5-c-panel--m-raised--BoxShadow);
  --pf-v5-c-panel--ZIndex: var(--pf-v5-c-panel--m-raised--ZIndex);
}

.pf-v5-c-panel.pf-m-scrollable {
  --pf-v5-c-panel__main--MaxHeight: var(--pf-v5-c-panel--m-scrollable__main--MaxHeight);
  --pf-v5-c-panel__main--Overflow: var(--pf-v5-c-panel--m-scrollable__main--Overflow);
  --pf-v5-c-panel__footer--BoxShadow: var(--pf-v5-c-panel--m-scrollable__footer--BoxShadow);
}

.pf-v5-c-panel__header {
  padding-block-start: var(--pf-v5-c-panel__header--PaddingTop);
  padding-block-end: var(--pf-v5-c-panel__header--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-panel__header--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-panel__header--PaddingRight);
}

.pf-v5-c-panel__main {
  max-height: var(--pf-v5-c-panel__main--MaxHeight);
  overflow: var(--pf-v5-c-panel__main--Overflow);
}

.pf-v5-c-panel__main-body {
  padding-block-start: var(--pf-v5-c-panel__main-body--PaddingTop);
  padding-block-end: var(--pf-v5-c-panel__main-body--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-panel__main-body--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-panel__main-body--PaddingRight);
}

.pf-v5-c-panel__footer {
  padding-block-start: var(--pf-v5-c-panel__footer--PaddingTop);
  padding-block-end: var(--pf-v5-c-panel__footer--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-panel__footer--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-panel__footer--PaddingRight);
  box-shadow: var(--pf-v5-c-panel__footer--BoxShadow);
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-panel {
  --pf-v5-c-panel--m-raised--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
}

.pf-v5-c-popover {
  --pf-v5-c-popover--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-popover--MinWidth: calc(var(--pf-v5-c-popover__content--PaddingLeft) + var(--pf-v5-c-popover__content--PaddingRight) + 18.75rem);
  --pf-v5-c-popover--MaxWidth: calc(var(--pf-v5-c-popover__content--PaddingLeft) + var(--pf-v5-c-popover__content--PaddingRight) + 18.75rem);
  --pf-v5-c-popover--BoxShadow: var(--pf-v5-global--BoxShadow--lg);
  --pf-v5-c-popover--m-danger__title-icon--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-popover--m-warning__title-icon--Color: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-popover--m-success__title-icon--Color: var(--pf-v5-global--success-color--100);
  --pf-v5-c-popover--m-info__title-icon--Color: var(--pf-v5-global--info-color--100);
  --pf-v5-c-popover--m-custom__title-icon--Color: var(--pf-v5-global--custom-color--200);
  --pf-v5-c-popover--m-danger__title-text--Color: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-popover--m-warning__title-text--Color: var(--pf-v5-global--warning-color--200);
  --pf-v5-c-popover--m-success__title-text--Color: var(--pf-v5-global--success-color--200);
  --pf-v5-c-popover--m-info__title-text--Color: var(--pf-v5-global--info-color--200);
  --pf-v5-c-popover--m-custom__title-text--Color: var(--pf-v5-global--custom-color--300);
  --pf-v5-c-popover__content--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-popover__content--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-popover__content--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-popover__content--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-popover__content--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-popover__arrow--Width: var(--pf-v5-global--arrow--width-lg);
  --pf-v5-c-popover__arrow--Height: var(--pf-v5-global--arrow--width-lg);
  --pf-v5-c-popover__arrow--BoxShadow: var(--pf-v5-global--BoxShadow--lg);
  --pf-v5-c-popover__arrow--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-popover__arrow--m-top--TranslateX: -50%;
  --pf-v5-c-popover__arrow--m-top--TranslateY: 50%;
  --pf-v5-c-popover__arrow--m-top--Rotate: 45deg;
  --pf-v5-c-popover__arrow--m-right--TranslateX: -50%;
  --pf-v5-c-popover__arrow--m-right--TranslateY: -50%;
  --pf-v5-c-popover__arrow--m-right--Rotate: 45deg;
  --pf-v5-c-popover__arrow--m-bottom--TranslateX: -50%;
  --pf-v5-c-popover__arrow--m-bottom--TranslateY: -50%;
  --pf-v5-c-popover__arrow--m-bottom--Rotate: 45deg;
  --pf-v5-c-popover__arrow--m-left--TranslateX: 50%;
  --pf-v5-c-popover__arrow--m-left--TranslateY: -50%;
  --pf-v5-c-popover__arrow--m-left--Rotate: 45deg;
  --pf-v5-c-popover__close--Top: calc(var(--pf-v5-c-popover__content--PaddingTop) - var(--pf-v5-global--spacer--form-element));
  --pf-v5-c-popover__close--Right: calc(var(--pf-v5-c-popover__content--PaddingRight) - var(--pf-v5-global--spacer--md));
  --pf-v5-c-popover__close--sibling--PaddingRight: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-popover__header--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-popover__title-text--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-popover__title-text--FontFamily: var(--pf-v5-global--FontFamily--heading);
  --pf-v5-c-popover__title-text--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-popover__title-icon--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-popover__title-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-popover__title-icon--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-popover__footer--MarginTop: var(--pf-v5-global--spacer--md);
  position: relative;
  min-width: var(--pf-v5-c-popover--MinWidth);
  max-width: var(--pf-v5-c-popover--MaxWidth);
  font-size: var(--pf-v5-c-popover--FontSize);
  box-shadow: var(--pf-v5-c-popover--BoxShadow);
}

.pf-v5-c-popover.pf-m-no-padding {
  --pf-v5-c-popover__content--PaddingTop: 0px;
  --pf-v5-c-popover__content--PaddingRight: 0px;
  --pf-v5-c-popover__content--PaddingBottom: 0px;
  --pf-v5-c-popover__content--PaddingLeft: 0px;
}

.pf-v5-c-popover.pf-m-width-auto {
  --pf-v5-c-popover--MinWidth: auto;
  --pf-v5-c-popover--MaxWidth: none;
}

.pf-v5-c-popover:is(.pf-m-top,.pf-m-top-left,.pf-m-top-right) {
  --pf-v5-c-popover__arrow--Bottom: var(--pf-v5-c-popover--m-top--Bottom, 0);
  --pf-v5-c-popover__arrow--Left: var(--pf-v5-c-popover--m-top--Left, 50%);
  --pf-v5-c-popover__arrow--TranslateX: var(--pf-v5-c-popover__arrow--m-top--TranslateX);
  --pf-v5-c-popover__arrow--TranslateY: var(--pf-v5-c-popover__arrow--m-top--TranslateY);
  --pf-v5-c-popover__arrow--Rotate: var(--pf-v5-c-popover__arrow--m-top--Rotate);
}

.pf-v5-c-popover:is(.pf-m-bottom,.pf-m-bottom-left,.pf-m-bottom-right) {
  --pf-v5-c-popover__arrow--Top: var(--pf-v5-c-popover--m-bottom--Top, 0);
  --pf-v5-c-popover__arrow--Left: var(--pf-v5-c-popover--m-bottom--Left, 50%);
  --pf-v5-c-popover__arrow--TranslateX: var(--pf-v5-c-popover__arrow--m-bottom--TranslateX);
  --pf-v5-c-popover__arrow--TranslateY: var(--pf-v5-c-popover__arrow--m-bottom--TranslateY);
  --pf-v5-c-popover__arrow--Rotate: var(--pf-v5-c-popover__arrow--m-bottom--Rotate);
}

.pf-v5-c-popover:is(.pf-m-left,.pf-m-left-top,.pf-m-left-bottom) {
  --pf-v5-c-popover__arrow--Top: var(--pf-v5-c-popover--m-left--Top, 50%);
  --pf-v5-c-popover__arrow--Right: var(--pf-v5-c-popover--m-left--Right, 0);
  --pf-v5-c-popover__arrow--TranslateX: var(--pf-v5-c-popover__arrow--m-left--TranslateX);
  --pf-v5-c-popover__arrow--TranslateY: var(--pf-v5-c-popover__arrow--m-left--TranslateY);
  --pf-v5-c-popover__arrow--Rotate: var(--pf-v5-c-popover__arrow--m-left--Rotate);
}

.pf-v5-c-popover:is(.pf-m-right,.pf-m-right-top,.pf-m-right-bottom) {
  --pf-v5-c-popover__arrow--Top: var(--pf-v5-c-popover--m-right--Top, 50%);
  --pf-v5-c-popover__arrow--Left: var(--pf-v5-c-popover--m-right--Left, 0);
  --pf-v5-c-popover__arrow--TranslateX: var(--pf-v5-c-popover__arrow--m-right--TranslateX);
  --pf-v5-c-popover__arrow--TranslateY: var(--pf-v5-c-popover__arrow--m-right--TranslateY);
  --pf-v5-c-popover__arrow--Rotate: var(--pf-v5-c-popover__arrow--m-right--Rotate);
}

.pf-v5-c-popover:is(.pf-m-left-top,.pf-m-right-top) {
  --pf-v5-c-popover__arrow--Top: 0;
  --pf-v5-c-popover__arrow--TranslateY: var(--pf-v5-c-popover__arrow--m-top--TranslateY);
}

.pf-v5-c-popover:is(.pf-m-left-bottom,.pf-m-right-bottom) {
  --pf-v5-c-popover__arrow--Top: auto;
  --pf-v5-c-popover__arrow--Bottom: 0;
}

.pf-v5-c-popover:is(.pf-m-top-left,.pf-m-bottom-left) {
  --pf-v5-c-popover__arrow--Left: 0;
  --pf-v5-c-popover__arrow--TranslateX: var(--pf-v5-c-popover__arrow--m-left--TranslateX);
}

.pf-v5-c-popover:is(.pf-m-top-right,.pf-m-bottom-right) {
  --pf-v5-c-popover__arrow--Right: 0;
  --pf-v5-c-popover__arrow--Left: auto;
}

.pf-v5-c-popover.pf-m-danger {
  --pf-v5-c-popover__title-icon--Color: var(--pf-v5-c-popover--m-danger__title-icon--Color);
  --pf-v5-c-popover__title-text--Color: var(--pf-v5-c-popover--m-danger__title-text--Color);
}

.pf-v5-c-popover.pf-m-warning {
  --pf-v5-c-popover__title-icon--Color: var(--pf-v5-c-popover--m-warning__title-icon--Color);
  --pf-v5-c-popover__title-text--Color: var(--pf-v5-c-popover--m-warning__title-text--Color);
}

.pf-v5-c-popover.pf-m-success {
  --pf-v5-c-popover__title-icon--Color: var(--pf-v5-c-popover--m-success__title-icon--Color);
  --pf-v5-c-popover__title-text--Color: var(--pf-v5-c-popover--m-success__title-text--Color);
}

.pf-v5-c-popover.pf-m-custom {
  --pf-v5-c-popover__title-icon--Color: var(--pf-v5-c-popover--m-custom__title-icon--Color);
  --pf-v5-c-popover__title-text--Color: var(--pf-v5-c-popover--m-custom__title-text--Color);
}

.pf-v5-c-popover.pf-m-info {
  --pf-v5-c-popover__title-icon--Color: var(--pf-v5-c-popover--m-info__title-icon--Color);
  --pf-v5-c-popover__title-text--Color: var(--pf-v5-c-popover--m-info__title-text--Color);
}

.pf-v5-c-popover__content {
  position: relative;
  padding-block-start: var(--pf-v5-c-popover__content--PaddingTop);
  padding-block-end: var(--pf-v5-c-popover__content--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-popover__content--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-popover__content--PaddingRight);
  background-color: var(--pf-v5-c-popover__content--BackgroundColor);
}

.pf-v5-c-popover__close {
  position: absolute;
  inset-block-start: var(--pf-v5-c-popover__close--Top);
  inset-inline-end: var(--pf-v5-c-popover__close--Right);
}

.pf-v5-c-popover__close+* {
  padding-inline-end: var(--pf-v5-c-popover__close--sibling--PaddingRight);
}

.pf-v5-c-popover__arrow {
  position: absolute;
  top: var(--pf-v5-c-popover__arrow--Top, auto);
  right: var(--pf-v5-c-popover__arrow--Right, auto);
  bottom: var(--pf-v5-c-popover__arrow--Bottom, auto);
  left: var(--pf-v5-c-popover__arrow--Left, auto);
  width: var(--pf-v5-c-popover__arrow--Width);
  height: var(--pf-v5-c-popover__arrow--Height);
  pointer-events: none;
  background-color: var(--pf-v5-c-popover__arrow--BackgroundColor);
  box-shadow: var(--pf-v5-c-popover__arrow--BoxShadow);
  transform: translateX(var(--pf-v5-c-popover__arrow--TranslateX, 0)) translateY(var(--pf-v5-c-popover__arrow--TranslateY, 0)) rotate(var(--pf-v5-c-popover__arrow--Rotate, 0));
}

.pf-v5-c-popover__header {
  margin-block-end: var(--pf-v5-c-popover__header--MarginBottom);
}

.pf-v5-c-popover__title {
  display: flex;
  flex: 0 0 auto;
}

.pf-v5-c-popover__title-icon {
  margin-inline-end: var(--pf-v5-c-popover__title-icon--MarginRight);
  font-size: var(--pf-v5-c-popover__title-icon--FontSize);
  color: var(--pf-v5-c-popover__title-icon--Color);
}

.pf-v5-c-popover__title-text {
  font-family: var(--pf-v5-c-popover__title-text--FontFamily);
  font-size: var(--pf-v5-c-popover__title-text--FontSize);
  line-height: var(--pf-v5-c-popover__title-text--LineHeight);
  color: var(--pf-v5-c-popover__title-text--Color, inherit);
}

.pf-v5-c-popover__body {
  word-wrap: break-word;
}

.pf-v5-c-popover__footer {
  margin-block-start: var(--pf-v5-c-popover__footer--MarginTop);
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-popover {
  --pf-v5-c-popover__content--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-popover__arrow--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-popover--m-custom__title-text--Color: var(--pf-v5-global--custom-color--200);
  --pf-v5-c-popover--m-info__title-text--Color: var(--pf-v5-global--info-color--100);
  --pf-v5-c-popover--m-success__title-text--Color: var(--pf-v5-global--success-color--100);
}

.pf-v5-c-progress {
  --pf-v5-c-progress--GridGap: var(--pf-v5-global--spacer--md);
  --pf-v5-c-progress__bar--before--BackgroundColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-progress__bar--Height: var(--pf-v5-global--spacer--md);
  --pf-v5-c-progress__bar--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-c-progress__measure--m-static-width--MinWidth: 4.5ch;
  --pf-v5-c-progress__status--Gap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-progress__status-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-progress__bar--before--Opacity: .2;
  --pf-v5-c-progress__indicator--Height: var(--pf-v5-c-progress__bar--Height);
  --pf-v5-c-progress__indicator--BackgroundColor: var(--pf-v5-c-progress__bar--before--BackgroundColor);
  --pf-v5-c-progress__helper-text--MarginTop: calc(var(--pf-v5-global--spacer--xs) - var(--pf-v5-c-progress--GridGap));
  --pf-v5-c-progress--m-success__bar--BackgroundColor: var(--pf-v5-global--success-color--100);
  --pf-v5-c-progress--m-warning__bar--BackgroundColor: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-progress--m-danger__bar--BackgroundColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-progress--m-success__status-icon--Color: var(--pf-v5-global--success-color--100);
  --pf-v5-c-progress--m-warning__status-icon--Color: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-progress--m-danger__status-icon--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-progress--m-inside__indicator--MinWidth: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-progress--m-inside__measure--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-progress--m-success--m-inside__measure--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-progress--m-warning--m-inside__measure--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-progress--m-inside__measure--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-progress--m-outside__measure--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-progress--m-sm__bar--Height: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-progress--m-sm__description--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-progress--m-sm__measure--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-progress--m-lg__bar--Height: var(--pf-v5-global--spacer--lg);
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: auto auto;
  grid-gap: var(--pf-v5-c-progress--GridGap);
  align-items: end;
}

.pf-v5-c-progress.pf-m-sm {
  --pf-v5-c-progress__bar--Height: var(--pf-v5-c-progress--m-sm__bar--Height);
}

.pf-v5-c-progress.pf-m-sm .pf-v5-c-progress__description {
  font-size: var(--pf-v5-c-progress--m-sm__description--FontSize);
}

.pf-v5-c-progress.pf-m-sm .pf-v5-c-progress__measure {
  font-size: var(--pf-v5-c-progress--m-sm__measure--FontSize);
}

.pf-v5-c-progress.pf-m-lg {
  --pf-v5-c-progress__bar--Height: var(--pf-v5-c-progress--m-lg__bar--Height);
}

.pf-v5-c-progress.pf-m-inside .pf-v5-c-progress__indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: var(--pf-v5-c-progress--m-inside__indicator--MinWidth);
}

.pf-v5-c-progress.pf-m-inside .pf-v5-c-progress__measure {
  font-size: var(--pf-v5-c-progress--m-inside__measure--FontSize);
  color: var(--pf-v5-c-progress--m-inside__measure--Color);
  text-align: center;
}

.pf-v5-c-progress.pf-m-outside .pf-v5-c-progress__description {
  grid-column: 1/3;
}

.pf-v5-c-progress.pf-m-outside .pf-v5-c-progress__status {
  grid-row: 2/3;
  grid-column: 2/3;
  align-self: center;
}

.pf-v5-c-progress.pf-m-outside .pf-v5-c-progress__measure {
  display: inline-block;
  font-size: var(--pf-v5-c-progress--m-outside__measure--FontSize);
}

.pf-v5-c-progress.pf-m-outside .pf-v5-c-progress__measure.pf-m-static-width {
  min-width: var(--pf-v5-c-progress__measure--m-static-width--MinWidth);
  text-align: start;
}

.pf-v5-c-progress.pf-m-outside .pf-v5-c-progress__bar,
.pf-v5-c-progress.pf-m-outside .pf-v5-c-progress__indicator {
  grid-column: 1/2;
}

.pf-v5-c-progress.pf-m-singleline {
  grid-template-rows: 1fr;
}

.pf-v5-c-progress.pf-m-singleline .pf-v5-c-progress__description {
  display: none;
}

.pf-v5-c-progress.pf-m-singleline .pf-v5-c-progress__bar {
  grid-row: 1/2;
  grid-column: 1/2;
}

.pf-v5-c-progress.pf-m-singleline .pf-v5-c-progress__status {
  grid-row: 1/2;
  grid-column: 2/3;
}

.pf-v5-c-progress.pf-m-outside,
.pf-v5-c-progress.pf-m-singleline {
  grid-template-columns: 1fr fit-content(50%);
}

.pf-v5-c-progress.pf-m-success {
  --pf-v5-c-progress__bar--before--BackgroundColor: var(--pf-v5-c-progress--m-success__bar--BackgroundColor);
  --pf-v5-c-progress__status-icon--Color: var(--pf-v5-c-progress--m-success__status-icon--Color);
  --pf-v5-c-progress--m-inside__measure--Color: var(--pf-v5-c-progress--m-success--m-inside__measure--Color);
}

.pf-v5-c-progress.pf-m-warning {
  --pf-v5-c-progress__bar--before--BackgroundColor: var(--pf-v5-c-progress--m-warning__bar--BackgroundColor);
  --pf-v5-c-progress__status-icon--Color: var(--pf-v5-c-progress--m-warning__status-icon--Color);
  --pf-v5-c-progress--m-inside__measure--Color: var(--pf-v5-c-progress--m-warning--m-inside__measure--Color);
}

.pf-v5-c-progress.pf-m-danger {
  --pf-v5-c-progress__bar--before--BackgroundColor: var(--pf-v5-c-progress--m-danger__bar--BackgroundColor);
  --pf-v5-c-progress__status-icon--Color: var(--pf-v5-c-progress--m-danger__status-icon--Color);
}

.pf-v5-c-progress__description {
  grid-column: 1/2;
  word-break: break-word;
}

.pf-v5-c-progress__description.pf-m-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pf-v5-c-progress__status {
  display: flex;
  grid-row: 1/2;
  grid-column: 2/3;
  gap: var(--pf-v5-c-progress__status--Gap);
  align-items: flex-start;
  justify-content: flex-end;
  text-align: end;
  word-break: break-word;
}

.pf-v5-c-progress__status-icon {
  color: var(--pf-v5-c-progress__status-icon--Color);
}

.pf-v5-c-progress__bar::before,
.pf-v5-c-progress__indicator {
  top: 0;
  left: 0;
}

.pf-v5-c-progress__bar {
  position: relative;
  grid-row: 2/3;
  grid-column: 1/3;
  align-self: center;
  height: var(--pf-v5-c-progress__bar--Height);
  background-color: var(--pf-v5-c-progress__bar--BackgroundColor);
}

.pf-v5-c-progress__bar::before {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  background-color: var(--pf-v5-c-progress__bar--before--BackgroundColor);
  opacity: var(--pf-v5-c-progress__bar--before--Opacity);
}

.pf-v5-c-progress__indicator {
  position: absolute;
  height: var(--pf-v5-c-progress__indicator--Height);
  background-color: var(--pf-v5-c-progress__indicator--BackgroundColor);
}

.pf-v5-c-progress__helper-text {
  grid-row: 3/4;
  grid-column: 1/3;
  margin-block-start: var(--pf-v5-c-progress__helper-text--MarginTop);
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-progress {
  --pf-v5-c-progress--m-inside__measure--Color: var(--pf-v5-global--palette--black-900);
  --pf-v5-c-progress--m-success--m-inside__measure--Color: var(--pf-v5-global--palette--black-900);
  --pf-v5-c-progress--m-warning--m-inside__measure--Color: var(--pf-v5-global--palette--black-900);
}

.pf-v5-c-progress-stepper {
  --pf-v5-c-progress-stepper--m-vertical--GridAutoFlow: row;
  --pf-v5-c-progress-stepper--m-vertical--GridTemplateColumns: auto 1fr;
  --pf-v5-c-progress-stepper--m-vertical__step-connector--before--Top: 0;
  --pf-v5-c-progress-stepper--m-vertical__step-connector--before--Left: calc(var(--pf-v5-c-progress-stepper__step-icon--Width) / 2);
  --pf-v5-c-progress-stepper--m-vertical__step-connector--before--Width: auto;
  --pf-v5-c-progress-stepper--m-vertical__step-connector--before--Height: 100%;
  --pf-v5-c-progress-stepper--m-vertical__step-connector--before--BorderRightWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-progress-stepper--m-vertical__step-connector--before--BorderRightColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-progress-stepper--m-vertical__step-connector--before--BorderBottomWidth: 0;
  --pf-v5-c-progress-stepper--m-vertical__step-connector--before--BorderBottomColor: transparent;
  --pf-v5-c-progress-stepper--m-vertical__step-connector--before--Transform: translateX(-50%);
  --pf-v5-c-progress-stepper--m-vertical__step-main--MarginTop: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-progress-stepper--m-vertical__step-main--MarginRight: 0;
  --pf-v5-c-progress-stepper--m-vertical__step-main--MarginBottom: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-progress-stepper--m-vertical__step-main--MarginLeft: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-progress-stepper--m-vertical--m-compact--GridTemplateColumns: 1fr;
  --pf-v5-c-progress-stepper--m-vertical--m-compact__step-connector--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-progress-stepper--m-vertical--m-compact__step-connector--GridRow: auto;
  --pf-v5-c-progress-stepper--m-vertical--m-compact__step-main--MarginTop: 0;
  --pf-v5-c-progress-stepper--m-vertical--m-compact__step-main--MarginRight: 0;
  --pf-v5-c-progress-stepper--m-vertical--m-compact__step-main--MarginLeft: 0;
  --pf-v5-c-progress-stepper--m-vertical--m-center__step-main--MarginRight: 0;
  --pf-v5-c-progress-stepper--m-vertical--m-center__step-main--MarginLeft: 0;
  --pf-v5-c-progress-stepper--m-horizontal--GridAutoFlow: column;
  --pf-v5-c-progress-stepper--m-horizontal--GridTemplateColumns: initial;
  --pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Top: calc(var(--pf-v5-c-progress-stepper__step-icon--Height) / 2);
  --pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Left: 0;
  --pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Width: 100%;
  --pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Height: auto;
  --pf-v5-c-progress-stepper--m-horizontal__step-connector--before--BorderRightWidth: 0;
  --pf-v5-c-progress-stepper--m-horizontal__step-connector--before--BorderRightColor: unset;
  --pf-v5-c-progress-stepper--m-horizontal__step-connector--before--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-progress-stepper--m-horizontal__step-connector--before--BorderBottomColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Transform: translateY(-50%);
  --pf-v5-c-progress-stepper--m-horizontal__step-main--MarginTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-progress-stepper--m-horizontal__step-main--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-progress-stepper--m-horizontal__step-main--MarginBottom: 0;
  --pf-v5-c-progress-stepper--m-horizontal__step-main--MarginLeft: 0;
  --pf-v5-c-progress-stepper--m-horizontal--m-compact--GridTemplateColumns: repeat(auto-fill, 1.75rem);
  --pf-v5-c-progress-stepper--m-horizontal--m-compact__step-connector--PaddingBottom: 0;
  --pf-v5-c-progress-stepper--m-horizontal--m-compact__step-connector--GridRow: 2;
  --pf-v5-c-progress-stepper--m-compact--GridAutoFlow: row;
  --pf-v5-c-progress-stepper--m-compact__step-main--MarginTop: 0;
  --pf-v5-c-progress-stepper--m-compact__step-main--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-progress-stepper--m-compact__step-connector--MinWidth: 1.75rem;
  --pf-v5-c-progress-stepper--m-compact__step-icon--Width: 1.125rem;
  --pf-v5-c-progress-stepper--m-compact__step-icon--FontSize: var(--pf-v5-global--icon--FontSize--sm);
  --pf-v5-c-progress-stepper--m-compact__step-title--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-progress-stepper--m-compact__step-title--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-progress-stepper--m-compact__pficon--MarginTop: 2px;
  --pf-v5-c-progress-stepper--m-compact__fa-exclamation-triangle--MarginTop: -3px;
  --pf-v5-c-progress-stepper--m-center__step-connector--before--Left: 50%;
  --pf-v5-c-progress-stepper--m-center--GridTemplateColumns: 1fr;
  --pf-v5-c-progress-stepper--m-center__step-connector--JustifyContent: center;
  --pf-v5-c-progress-stepper--m-center__step-main--MarginRight: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-progress-stepper--m-center__step-main--MarginLeft: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-progress-stepper--m-center__step-main--TextAlign: center;
  --pf-v5-c-progress-stepper--m-center__step-description--MarginRight: 0;
  --pf-v5-c-progress-stepper--m-center__step-description--MarginLeft: 0;
  --pf-v5-c-progress-stepper--GridTemplateRows: auto 1fr;
  --pf-v5-c-progress-stepper__step-connector--JustifyContent: flex-start;
  --pf-v5-c-progress-stepper__step-icon--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-progress-stepper__step-icon--Width: 1.75rem;
  --pf-v5-c-progress-stepper__step-icon--Height: var(--pf-v5-c-progress-stepper__step-icon--Width);
  --pf-v5-c-progress-stepper__step-icon--FontSize: var(--pf-v5-global--icon--FontSize--md);
  --pf-v5-c-progress-stepper__step-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-progress-stepper__step-icon--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-200);
  --pf-v5-c-progress-stepper__step-icon--BorderWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-progress-stepper__step-icon--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-progress-stepper__pficon--MarginTop: 3px;
  --pf-v5-c-progress-stepper__fa-exclamation-triangle--MarginTop: -5px;
  --pf-v5-c-progress-stepper__step-title--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-progress-stepper__step-title--TextAlign: start;
  --pf-v5-c-progress-stepper__step-title--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-progress-stepper__step-title--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-progress-stepper__step--m-current__step-title--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-progress-stepper__step--m-current__step-title--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-progress-stepper__step--m-pending__step-title--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-progress-stepper__step--m-danger__step-title--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-progress-stepper__step-title--m-help-text--TextDecorationColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-progress-stepper__step-title--m-help-text--TextDecorationThickness: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-progress-stepper__step-title--m-help-text--TextUnderlineOffset: 0.25rem;
  --pf-v5-c-progress-stepper__step-title--m-help-text--hover--TextDecorationColor: var(--pf-v5-global--Color--100);
  --pf-v5-c-progress-stepper__step-title--m-help-text--focus--TextDecorationColor: var(--pf-v5-global--Color--100);
  --pf-v5-c-progress-stepper__step-title--m-help-text--hover--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-progress-stepper__step-title--m-help-text--focus--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-progress-stepper__step--m-danger__step-title--m-help-text--hover--Color: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-progress-stepper__step--m-danger__step-title--m-help-text--focus--Color: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-progress-stepper__step--m-danger__step-title--m-help-text--TextDecorationColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-progress-stepper__step--m-danger__step-title--m-help-text--hover--TextDecorationColor: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-progress-stepper__step--m-danger__step-title--m-help-text--focus--TextDecorationColor: var(--pf-v5-global--danger-color--200);
  --pf-v5-c-progress-stepper__step-description--MarginTop: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-progress-stepper__step-description--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-progress-stepper__step-description--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-progress-stepper__step-description--TextAlign: start;
  --pf-v5-c-progress-stepper--GridAutoFlow: var(--pf-v5-c-progress-stepper--m-vertical--GridAutoFlow);
  --pf-v5-c-progress-stepper--GridTemplateColumns: var(--pf-v5-c-progress-stepper--m-vertical--GridTemplateColumns);
  --pf-v5-c-progress-stepper__step-connector--before--Top: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--Top);
  --pf-v5-c-progress-stepper__step-connector--before--Left: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--Left);
  --pf-v5-c-progress-stepper__step-connector--before--Width: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--Width);
  --pf-v5-c-progress-stepper__step-connector--before--Height: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--Height);
  --pf-v5-c-progress-stepper__step-connector--before--BorderRightWidth: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--BorderRightWidth);
  --pf-v5-c-progress-stepper__step-connector--before--BorderRightColor: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--BorderRightColor);
  --pf-v5-c-progress-stepper__step-connector--before--BorderBottomWidth: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--BorderBottomWidth);
  --pf-v5-c-progress-stepper__step-connector--before--BorderBottomColor: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--BorderBottomColor);
  --pf-v5-c-progress-stepper__step-connector--before--Transform: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--Transform);
  --pf-v5-c-progress-stepper__step-main--MarginTop: var(--pf-v5-c-progress-stepper--m-vertical__step-main--MarginTop);
  --pf-v5-c-progress-stepper__step-main--MarginRight: var(--pf-v5-c-progress-stepper--m-vertical__step-main--MarginRight);
  --pf-v5-c-progress-stepper__step-main--MarginBottom: var(--pf-v5-c-progress-stepper--m-vertical__step-main--MarginBottom);
  --pf-v5-c-progress-stepper__step-main--MarginLeft: var(--pf-v5-c-progress-stepper--m-vertical__step-main--MarginLeft);
  --pf-v5-c-progress-stepper--m-compact--GridTemplateColumns: var(--pf-v5-c-progress-stepper--m-vertical--m-compact--GridTemplateColumns);
  --pf-v5-c-progress-stepper--m-compact__step-connector--GridRow: var(--pf-v5-c-progress-stepper--m-vertical--m-compact__step-connector--GridRow);
  --pf-v5-c-progress-stepper--m-compact__step-connector--PaddingBottom: var(--pf-v5-c-progress-stepper--m-vertical--m-compact__step-connector--PaddingBottom);
  --pf-v5-c-progress-stepper--m-center__step-connector--before--Content: none;
  --pf-v5-c-progress-stepper--m-center__step-main--before--Content: "";
  position: relative;
  display: grid;
  grid-template-rows: var(--pf-v5-c-progress-stepper--GridTemplateRows);
  grid-template-columns: var(--pf-v5-c-progress-stepper--GridTemplateColumns);
  grid-auto-columns: 1fr;
  grid-auto-flow: var(--pf-v5-c-progress-stepper--GridAutoFlow);
}

@media screen and (min-width: 768px) {
  .pf-v5-c-progress-stepper {
    --pf-v5-c-progress-stepper--GridAutoFlow: var(--pf-v5-c-progress-stepper--m-horizontal--GridAutoFlow);
    --pf-v5-c-progress-stepper--GridTemplateColumns: var(--pf-v5-c-progress-stepper--m-horizontal--GridTemplateColumns);
    --pf-v5-c-progress-stepper__step-connector--before--Top: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Top);
    --pf-v5-c-progress-stepper__step-connector--before--Left: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Left);
    --pf-v5-c-progress-stepper__step-connector--before--Width: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Width);
    --pf-v5-c-progress-stepper__step-connector--before--Height: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Height);
    --pf-v5-c-progress-stepper__step-connector--before--BorderRightWidth: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--BorderRightWidth);
    --pf-v5-c-progress-stepper__step-connector--before--BorderRightColor: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--BorderRightColor);
    --pf-v5-c-progress-stepper__step-connector--before--BorderBottomWidth: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--BorderBottomWidth);
    --pf-v5-c-progress-stepper__step-connector--before--BorderBottomColor: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--BorderBottomColor);
    --pf-v5-c-progress-stepper__step-connector--before--Transform: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Transform);
    --pf-v5-c-progress-stepper__step-main--MarginTop: var(--pf-v5-c-progress-stepper--m-horizontal__step-main--MarginTop);
    --pf-v5-c-progress-stepper__step-main--MarginRight: var(--pf-v5-c-progress-stepper--m-horizontal__step-main--MarginRight);
    --pf-v5-c-progress-stepper__step-main--MarginBottom: var(--pf-v5-c-progress-stepper--m-horizontal__step-main--MarginBottom);
    --pf-v5-c-progress-stepper__step-main--MarginLeft: var(--pf-v5-c-progress-stepper--m-horizontal__step-main--MarginLeft);
    --pf-v5-c-progress-stepper--m-compact--GridTemplateColumns: var(--pf-v5-c-progress-stepper--m-horizontal--m-compact--GridTemplateColumns);
    --pf-v5-c-progress-stepper--m-compact__step-connector--GridRow: var(--pf-v5-c-progress-stepper--m-horizontal--m-compact__step-connector--GridRow);
    --pf-v5-c-progress-stepper--m-compact__step-connector--PaddingBottom: var(--pf-v5-c-progress-stepper--m-horizontal--m-compact__step-connector--PaddingBottom);
    --pf-v5-c-progress-stepper--m-center__step-connector--before--Content: "";
    --pf-v5-c-progress-stepper--m-center__step-main--before--Content: none;
  }
}

.pf-v5-c-progress-stepper.pf-m-center {
  --pf-v5-c-progress-stepper__step-connector--JustifyContent: var(--pf-v5-c-progress-stepper--m-center__step-connector--JustifyContent);
  --pf-v5-c-progress-stepper__step-main--MarginRight: var(--pf-v5-c-progress-stepper--m-center__step-main--MarginRight);
  --pf-v5-c-progress-stepper__step-main--MarginLeft: var(--pf-v5-c-progress-stepper--m-center__step-main--MarginLeft);
  --pf-v5-c-progress-stepper--step-main--TextAlign: var(--pf-v5-c-progress-stepper--m-center__step-main--TextAlign, auto);
  --pf-v5-c-progress-stepper__step-title--TextAlign: var(--pf-v5-c-progress-stepper--m-center__step-title--TextAlign, auto);
  --pf-v5-c-progress-stepper__step-description--MarginRight: var(--pf-v5-c-progress-stepper--m-center__step-description--MarginRight);
  --pf-v5-c-progress-stepper__step-description--MarginLeft: var(--pf-v5-c-progress-stepper--m-center__step-description--MarginLeft);
  --pf-v5-c-progress-stepper__step-description--TextAlign: var(--pf-v5-c-progress-stepper--m-center__step-description--TextAlign, auto);
  --pf-v5-c-progress-stepper--m-vertical__step-main--MarginRight: var(--pf-v5-c-progress-stepper--m-vertical--m-center__step-main--MarginRight);
  --pf-v5-c-progress-stepper--m-vertical__step-main--MarginLeft: var(--pf-v5-c-progress-stepper--m-vertical--m-center__step-main--MarginLeft);
  grid-template-columns: var(--pf-v5-c-progress-stepper--m-center--GridTemplateColumns);
}

.pf-v5-c-progress-stepper.pf-m-center .pf-v5-c-progress-stepper__step-main {
  position: relative;
}

.pf-v5-c-progress-stepper.pf-m-center .pf-v5-c-progress-stepper__step:not(:last-of-type)>.pf-v5-c-progress-stepper__step-connector::before {
  inset-inline-start: var(--pf-v5-c-progress-stepper--m-center__step-connector--before--Left);
}

.pf-v5-c-progress-stepper.pf-m-center:not(.pf-m-compact) .pf-v5-c-progress-stepper__step:not(:last-of-type)>.pf-v5-c-progress-stepper__step-main::before {
  content: var(--pf-v5-c-progress-stepper--m-center__step-main--before--Content);
}

.pf-v5-c-progress-stepper.pf-m-center:not(.pf-m-compact) .pf-v5-c-progress-stepper__step:not(:last-of-type)>.pf-v5-c-progress-stepper__step-connector::before {
  content: var(--pf-v5-c-progress-stepper--m-center__step-connector--before--Content);
}

.pf-v5-c-progress-stepper.pf-m-compact {
  --pf-v5-c-progress-stepper__step-main--MarginTop: var(--pf-v5-c-progress-stepper--m-compact__step-main--MarginTop);
  --pf-v5-c-progress-stepper__step-main--MarginBottom: var(--pf-v5-c-progress-stepper--m-compact__step-main--MarginBottom);
  --pf-v5-c-progress-stepper__step-icon--Width: var(--pf-v5-c-progress-stepper--m-compact__step-icon--Width);
  --pf-v5-c-progress-stepper__step-icon--FontSize: var(--pf-v5-c-progress-stepper--m-compact__step-icon--FontSize);
  --pf-v5-c-progress-stepper__step-title--FontSize: var(--pf-v5-c-progress-stepper--m-compact__step-title--FontSize);
  --pf-v5-c-progress-stepper__step--m-current__step-title--FontWeight: var(--pf-v5-c-progress-stepper--m-compact__step-title--FontWeight);
  --pf-v5-c-progress-stepper__pficon--MarginTop: var(--pf-v5-c-progress-stepper--m-compact__pficon--MarginTop);
  --pf-v5-c-progress-stepper__fa-exclamation-triangle--MarginTop: var(--pf-v5-c-progress-stepper--m-compact__fa-exclamation-triangle--MarginTop);
  --pf-v5-c-progress-stepper--m-vertical__step-main--MarginTop: var(--pf-v5-c-progress-stepper--m-vertical--m-compact__step-main--MarginTop);
  --pf-v5-c-progress-stepper--m-vertical__step-main--MarginRight: var(--pf-v5-c-progress-stepper--m-vertical--m-compact__step-main--MarginRight);
  --pf-v5-c-progress-stepper--m-vertical__step-main--MarginLeft: var(--pf-v5-c-progress-stepper--m-vertical--m-compact__step-main--MarginLeft);
  display: inline-grid;
  grid-template-columns: var(--pf-v5-c-progress-stepper--m-compact--GridTemplateColumns);
  grid-auto-flow: var(--pf-v5-c-progress-stepper--m-compact--GridAutoFlow);
}

.pf-v5-c-progress-stepper.pf-m-compact .pf-v5-c-progress-stepper__step-connector {
  grid-row: var(--pf-v5-c-progress-stepper--m-compact__step-connector--GridRow);
  min-width: var(--pf-v5-c-progress-stepper--m-compact__step-connector--MinWidth);
  padding-block-end: var(--pf-v5-c-progress-stepper--m-compact__step-connector--PaddingBottom);
}

.pf-v5-c-progress-stepper.pf-m-compact .pf-v5-c-progress-stepper__step-main {
  margin-block-end: var(--pf-v5-c-progress-stepper--m-compact__step-main--MarginBottom);
}

.pf-v5-c-progress-stepper.pf-m-compact .pf-v5-c-progress-stepper__step:not(.pf-m-current) .pf-v5-c-progress-stepper__step-main {
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.pf-v5-c-progress-stepper.pf-m-compact .pf-v5-c-progress-stepper__step.pf-m-current .pf-v5-c-progress-stepper__step-main {
  grid-column: 1/-1;
  grid-row: 1/2;
}

.pf-v5-c-progress-stepper.pf-m-compact .pf-v5-c-progress-stepper__step-description {
  display: none;
}

.pf-v5-c-progress-stepper__step {
  display: contents;
}

.pf-v5-c-progress-stepper__step.pf-m-current {
  --pf-v5-c-progress-stepper__step-title--FontWeight: var(--pf-v5-c-progress-stepper__step--m-current__step-title--FontWeight);
  --pf-v5-c-progress-stepper__step-title--Color: var(--pf-v5-c-progress-stepper__step--m-current__step-title--Color);
}

.pf-v5-c-progress-stepper__step.pf-m-pending {
  --pf-v5-c-progress-stepper__step-title--Color: var(--pf-v5-c-progress-stepper__step--m-pending__step-title--Color);
}

.pf-v5-c-progress-stepper__step.pf-m-success {
  --pf-v5-c-progress-stepper__step-icon--Color: var(--pf-v5-global--success-color--100);
}

.pf-v5-c-progress-stepper__step.pf-m-danger {
  --pf-v5-c-progress-stepper__step-icon--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-progress-stepper__step-title--Color: var(--pf-v5-c-progress-stepper__step--m-danger__step-title--Color);
  --pf-v5-c-progress-stepper__step-title--m-help-text--hover--Color: var(--pf-v5-c-progress-stepper__step--m-danger__step-title--m-help-text--hover--Color);
  --pf-v5-c-progress-stepper__step-title--m-help-text--focus--Color: var(--pf-v5-c-progress-stepper__step--m-danger__step-title--m-help-text--focus--Color);
  --pf-v5-c-progress-stepper__step-title--m-help-text--TextDecorationColor: var(--pf-v5-c-progress-stepper__step--m-danger__step-title--m-help-text--TextDecorationColor);
  --pf-v5-c-progress-stepper__step-title--m-help-text--hover--TextDecorationColor: var(--pf-v5-c-progress-stepper__step--m-danger__step-title--m-help-text--hover--TextDecorationColor);
  --pf-v5-c-progress-stepper__step-title--m-help-text--focus--TextDecorationColor: var(--pf-v5-c-progress-stepper__step--m-danger__step-title--m-help-text--focus--TextDecorationColor);
}

.pf-v5-c-progress-stepper__step.pf-m-warning {
  --pf-v5-c-progress-stepper__step-icon--Color: var(--pf-v5-global--warning-color--100);
}

.pf-v5-c-progress-stepper__step.pf-m-info {
  --pf-v5-c-progress-stepper__step-icon--Color: var(--pf-v5-global--info-color--100);
}

.pf-v5-c-progress-stepper__step:last-child {
  --pf-v5-c-progress-stepper__step-main--MarginBottom: 0;
}

.pf-v5-c-progress-stepper__step-connector {
  position: relative;
  display: flex;
  justify-content: var(--pf-v5-c-progress-stepper__step-connector--JustifyContent);
  width: 100%;
}

.pf-v5-c-progress-stepper__step:not(:last-of-type)>.pf-v5-c-progress-stepper__step-connector::before {
  position: absolute;
  inset-block-start: var(--pf-v5-c-progress-stepper__step-connector--before--Top);
  inset-inline-start: var(--pf-v5-c-progress-stepper__step-connector--before--Left);
  width: var(--pf-v5-c-progress-stepper__step-connector--before--Width);
  height: var(--pf-v5-c-progress-stepper__step-connector--before--Height);
  content: "";
  border-block-end: var(--pf-v5-c-progress-stepper__step-connector--before--BorderBottomWidth) solid var(--pf-v5-c-progress-stepper__step-connector--before--BorderBottomColor);
  border-inline-end: var(--pf-v5-c-progress-stepper__step-connector--before--BorderRightWidth) solid var(--pf-v5-c-progress-stepper__step-connector--before--BorderRightColor);
  transform: var(--pf-v5-c-progress-stepper__step-connector--before--Transform);
}

.pf-v5-c-progress-stepper__step-icon {
  z-index: var(--pf-v5-c-progress-stepper__step-icon--ZIndex);
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--pf-v5-c-progress-stepper__step-icon--Width);
  height: var(--pf-v5-c-progress-stepper__step-icon--Height);
  font-size: var(--pf-v5-c-progress-stepper__step-icon--FontSize);
  color: var(--pf-v5-c-progress-stepper__step-icon--Color);
  background-color: var(--pf-v5-c-progress-stepper__step-icon--BackgroundColor);
  border: var(--pf-v5-c-progress-stepper__step-icon--BorderWidth) solid var(--pf-v5-c-progress-stepper__step-icon--BorderColor);
  border-radius: 50%;
}

.pf-v5-c-progress-stepper__step-icon .pf-v5-pficon {
  margin-block-start: var(--pf-v5-c-progress-stepper__pficon--MarginTop);
}

.pf-v5-c-progress-stepper__step-icon .fa-exclamation-triangle {
  margin-block-start: var(--pf-v5-c-progress-stepper__fa-exclamation-triangle--MarginTop);
}

.pf-v5-c-progress-stepper__step-main {
  min-width: 0;
  margin-block-start: var(--pf-v5-c-progress-stepper__step-main--MarginTop);
  margin-block-end: var(--pf-v5-c-progress-stepper__step-main--MarginBottom);
  margin-inline-start: var(--pf-v5-c-progress-stepper__step-main--MarginLeft);
  margin-inline-end: var(--pf-v5-c-progress-stepper__step-main--MarginRight);
  text-align: var(--pf-v5-c-progress-stepper--step-main--TextAlign, auto);
  overflow-wrap: anywhere;
}

.pf-v5-c-progress-stepper__step:not(:last-of-type)>.pf-v5-c-progress-stepper__step-main::before {
  position: absolute;
  inset-block-start: calc(100% + var(--pf-v5-c-progress-stepper__step-main--MarginTop));
  inset-inline-start: calc(50% - var(--pf-v5-c-progress-stepper__step-connector--before--BorderRightWidth)/2);
  width: auto;
  height: calc(var(--pf-v5-c-progress-stepper__step-main--MarginTop) + var(--pf-v5-c-progress-stepper__step-main--MarginBottom));
  border-inline-end: var(--pf-v5-c-progress-stepper__step-connector--before--BorderRightWidth) solid var(--pf-v5-c-progress-stepper__step-connector--before--BorderRightColor);
}

.pf-v5-c-progress-stepper__step-title {
  font-size: var(--pf-v5-c-progress-stepper__step-title--FontSize);
  font-weight: var(--pf-v5-c-progress-stepper__step-title--FontWeight);
  color: var(--pf-v5-c-progress-stepper__step-title--Color);
  text-align: var(--pf-v5-c-progress-stepper__step-title--TextAlign);
  background: none;
  border: 0;
}

.pf-v5-c-progress-stepper__step-title.pf-m-help-text {
  padding: 0;
  text-decoration: underline;
  text-decoration-thickness: var(--pf-v5-c-progress-stepper__step-title--m-help-text--TextDecorationThickness);
  text-decoration-style: dashed;
  text-decoration-color: var(--pf-v5-c-progress-stepper__step-title--m-help-text--TextDecorationColor);
  text-underline-offset: var(--pf-v5-c-progress-stepper__step-title--m-help-text--TextUnderlineOffset);
  cursor: pointer;
}

.pf-v5-c-progress-stepper__step-title.pf-m-help-text:hover {
  --pf-v5-c-progress-stepper__step-title--Color: var(--pf-v5-c-progress-stepper__step-title--m-help-text--hover--Color);
  --pf-v5-c-progress-stepper__step-title--m-help-text--TextDecorationColor: var(--pf-v5-c-progress-stepper__step-title--m-help-text--hover--TextDecorationColor);
}

.pf-v5-c-progress-stepper__step-title.pf-m-help-text:focus {
  --pf-v5-c-progress-stepper__step-title--Color: var(--pf-v5-c-progress-stepper__step-title--m-help-text--focus--Color);
  --pf-v5-c-progress-stepper__step-title--m-help-text--TextDecorationColor: var(--pf-v5-c-progress-stepper__step-title--m-help-text--focus--TextDecorationColor);
}

.pf-v5-c-progress-stepper__step-description {
  margin-block-start: var(--pf-v5-c-progress-stepper__step-description--MarginTop);
  font-size: var(--pf-v5-c-progress-stepper__step-description--FontSize);
  color: var(--pf-v5-c-progress-stepper__step-description--Color);
  text-align: var(--pf-v5-c-progress-stepper__step-description--TextAlign);
}

.pf-v5-c-progress-stepper.pf-m-horizontal {
  --pf-v5-c-progress-stepper--GridAutoFlow: var(--pf-v5-c-progress-stepper--m-horizontal--GridAutoFlow);
  --pf-v5-c-progress-stepper--GridTemplateColumns: var(--pf-v5-c-progress-stepper--m-horizontal--GridTemplateColumns);
  --pf-v5-c-progress-stepper__step-connector--before--Top: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Top);
  --pf-v5-c-progress-stepper__step-connector--before--Left: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Left);
  --pf-v5-c-progress-stepper__step-connector--before--Width: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Width);
  --pf-v5-c-progress-stepper__step-connector--before--Height: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Height);
  --pf-v5-c-progress-stepper__step-connector--before--BorderRightWidth: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--BorderRightWidth);
  --pf-v5-c-progress-stepper__step-connector--before--BorderRightColor: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--BorderRightColor);
  --pf-v5-c-progress-stepper__step-connector--before--BorderBottomWidth: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--BorderBottomWidth);
  --pf-v5-c-progress-stepper__step-connector--before--BorderBottomColor: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--BorderBottomColor);
  --pf-v5-c-progress-stepper__step-connector--before--Transform: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Transform);
  --pf-v5-c-progress-stepper__step-main--MarginTop: var(--pf-v5-c-progress-stepper--m-horizontal__step-main--MarginTop);
  --pf-v5-c-progress-stepper__step-main--MarginRight: var(--pf-v5-c-progress-stepper--m-horizontal__step-main--MarginRight);
  --pf-v5-c-progress-stepper__step-main--MarginBottom: var(--pf-v5-c-progress-stepper--m-horizontal__step-main--MarginBottom);
  --pf-v5-c-progress-stepper__step-main--MarginLeft: var(--pf-v5-c-progress-stepper--m-horizontal__step-main--MarginLeft);
  --pf-v5-c-progress-stepper--m-compact--GridTemplateColumns: var(--pf-v5-c-progress-stepper--m-horizontal--m-compact--GridTemplateColumns);
  --pf-v5-c-progress-stepper--m-compact__step-connector--GridRow: var(--pf-v5-c-progress-stepper--m-horizontal--m-compact__step-connector--GridRow);
  --pf-v5-c-progress-stepper--m-compact__step-connector--PaddingBottom: var(--pf-v5-c-progress-stepper--m-horizontal--m-compact__step-connector--PaddingBottom);
  --pf-v5-c-progress-stepper--m-center__step-connector--before--Content: "";
  --pf-v5-c-progress-stepper--m-center__step-main--before--Content: none;
}

.pf-v5-c-progress-stepper.pf-m-vertical {
  --pf-v5-c-progress-stepper--GridAutoFlow: var(--pf-v5-c-progress-stepper--m-vertical--GridAutoFlow);
  --pf-v5-c-progress-stepper--GridTemplateColumns: var(--pf-v5-c-progress-stepper--m-vertical--GridTemplateColumns);
  --pf-v5-c-progress-stepper__step-connector--before--Top: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--Top);
  --pf-v5-c-progress-stepper__step-connector--before--Left: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--Left);
  --pf-v5-c-progress-stepper__step-connector--before--Width: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--Width);
  --pf-v5-c-progress-stepper__step-connector--before--Height: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--Height);
  --pf-v5-c-progress-stepper__step-connector--before--BorderRightWidth: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--BorderRightWidth);
  --pf-v5-c-progress-stepper__step-connector--before--BorderRightColor: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--BorderRightColor);
  --pf-v5-c-progress-stepper__step-connector--before--BorderBottomWidth: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--BorderBottomWidth);
  --pf-v5-c-progress-stepper__step-connector--before--BorderBottomColor: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--BorderBottomColor);
  --pf-v5-c-progress-stepper__step-connector--before--Transform: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--Transform);
  --pf-v5-c-progress-stepper__step-main--MarginTop: var(--pf-v5-c-progress-stepper--m-vertical__step-main--MarginTop);
  --pf-v5-c-progress-stepper__step-main--MarginRight: var(--pf-v5-c-progress-stepper--m-vertical__step-main--MarginRight);
  --pf-v5-c-progress-stepper__step-main--MarginBottom: var(--pf-v5-c-progress-stepper--m-vertical__step-main--MarginBottom);
  --pf-v5-c-progress-stepper__step-main--MarginLeft: var(--pf-v5-c-progress-stepper--m-vertical__step-main--MarginLeft);
  --pf-v5-c-progress-stepper--m-compact--GridTemplateColumns: var(--pf-v5-c-progress-stepper--m-vertical--m-compact--GridTemplateColumns);
  --pf-v5-c-progress-stepper--m-compact__step-connector--GridRow: var(--pf-v5-c-progress-stepper--m-vertical--m-compact__step-connector--GridRow);
  --pf-v5-c-progress-stepper--m-compact__step-connector--PaddingBottom: var(--pf-v5-c-progress-stepper--m-vertical--m-compact__step-connector--PaddingBottom);
  --pf-v5-c-progress-stepper--m-center__step-connector--before--Content: none;
  --pf-v5-c-progress-stepper--m-center__step-main--before--Content: "";
}

@media (min-width: 576px) {
  .pf-v5-c-progress-stepper.pf-m-horizontal-on-sm {
    --pf-v5-c-progress-stepper--GridAutoFlow: var(--pf-v5-c-progress-stepper--m-horizontal--GridAutoFlow);
    --pf-v5-c-progress-stepper--GridTemplateColumns: var(--pf-v5-c-progress-stepper--m-horizontal--GridTemplateColumns);
    --pf-v5-c-progress-stepper__step-connector--before--Top: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Top);
    --pf-v5-c-progress-stepper__step-connector--before--Left: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Left);
    --pf-v5-c-progress-stepper__step-connector--before--Width: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Width);
    --pf-v5-c-progress-stepper__step-connector--before--Height: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Height);
    --pf-v5-c-progress-stepper__step-connector--before--BorderRightWidth: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--BorderRightWidth);
    --pf-v5-c-progress-stepper__step-connector--before--BorderRightColor: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--BorderRightColor);
    --pf-v5-c-progress-stepper__step-connector--before--BorderBottomWidth: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--BorderBottomWidth);
    --pf-v5-c-progress-stepper__step-connector--before--BorderBottomColor: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--BorderBottomColor);
    --pf-v5-c-progress-stepper__step-connector--before--Transform: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Transform);
    --pf-v5-c-progress-stepper__step-main--MarginTop: var(--pf-v5-c-progress-stepper--m-horizontal__step-main--MarginTop);
    --pf-v5-c-progress-stepper__step-main--MarginRight: var(--pf-v5-c-progress-stepper--m-horizontal__step-main--MarginRight);
    --pf-v5-c-progress-stepper__step-main--MarginBottom: var(--pf-v5-c-progress-stepper--m-horizontal__step-main--MarginBottom);
    --pf-v5-c-progress-stepper__step-main--MarginLeft: var(--pf-v5-c-progress-stepper--m-horizontal__step-main--MarginLeft);
    --pf-v5-c-progress-stepper--m-compact--GridTemplateColumns: var(--pf-v5-c-progress-stepper--m-horizontal--m-compact--GridTemplateColumns);
    --pf-v5-c-progress-stepper--m-compact__step-connector--GridRow: var(--pf-v5-c-progress-stepper--m-horizontal--m-compact__step-connector--GridRow);
    --pf-v5-c-progress-stepper--m-compact__step-connector--PaddingBottom: var(--pf-v5-c-progress-stepper--m-horizontal--m-compact__step-connector--PaddingBottom);
    --pf-v5-c-progress-stepper--m-center__step-connector--before--Content: "";
    --pf-v5-c-progress-stepper--m-center__step-main--before--Content: none;
  }

  .pf-v5-c-progress-stepper.pf-m-vertical-on-sm {
    --pf-v5-c-progress-stepper--GridAutoFlow: var(--pf-v5-c-progress-stepper--m-vertical--GridAutoFlow);
    --pf-v5-c-progress-stepper--GridTemplateColumns: var(--pf-v5-c-progress-stepper--m-vertical--GridTemplateColumns);
    --pf-v5-c-progress-stepper__step-connector--before--Top: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--Top);
    --pf-v5-c-progress-stepper__step-connector--before--Left: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--Left);
    --pf-v5-c-progress-stepper__step-connector--before--Width: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--Width);
    --pf-v5-c-progress-stepper__step-connector--before--Height: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--Height);
    --pf-v5-c-progress-stepper__step-connector--before--BorderRightWidth: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--BorderRightWidth);
    --pf-v5-c-progress-stepper__step-connector--before--BorderRightColor: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--BorderRightColor);
    --pf-v5-c-progress-stepper__step-connector--before--BorderBottomWidth: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--BorderBottomWidth);
    --pf-v5-c-progress-stepper__step-connector--before--BorderBottomColor: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--BorderBottomColor);
    --pf-v5-c-progress-stepper__step-connector--before--Transform: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--Transform);
    --pf-v5-c-progress-stepper__step-main--MarginTop: var(--pf-v5-c-progress-stepper--m-vertical__step-main--MarginTop);
    --pf-v5-c-progress-stepper__step-main--MarginRight: var(--pf-v5-c-progress-stepper--m-vertical__step-main--MarginRight);
    --pf-v5-c-progress-stepper__step-main--MarginBottom: var(--pf-v5-c-progress-stepper--m-vertical__step-main--MarginBottom);
    --pf-v5-c-progress-stepper__step-main--MarginLeft: var(--pf-v5-c-progress-stepper--m-vertical__step-main--MarginLeft);
    --pf-v5-c-progress-stepper--m-compact--GridTemplateColumns: var(--pf-v5-c-progress-stepper--m-vertical--m-compact--GridTemplateColumns);
    --pf-v5-c-progress-stepper--m-compact__step-connector--GridRow: var(--pf-v5-c-progress-stepper--m-vertical--m-compact__step-connector--GridRow);
    --pf-v5-c-progress-stepper--m-compact__step-connector--PaddingBottom: var(--pf-v5-c-progress-stepper--m-vertical--m-compact__step-connector--PaddingBottom);
    --pf-v5-c-progress-stepper--m-center__step-connector--before--Content: none;
    --pf-v5-c-progress-stepper--m-center__step-main--before--Content: "";
  }
}

@media (min-width: 768px) {
  .pf-v5-c-progress-stepper.pf-m-horizontal-on-md {
    --pf-v5-c-progress-stepper--GridAutoFlow: var(--pf-v5-c-progress-stepper--m-horizontal--GridAutoFlow);
    --pf-v5-c-progress-stepper--GridTemplateColumns: var(--pf-v5-c-progress-stepper--m-horizontal--GridTemplateColumns);
    --pf-v5-c-progress-stepper__step-connector--before--Top: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Top);
    --pf-v5-c-progress-stepper__step-connector--before--Left: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Left);
    --pf-v5-c-progress-stepper__step-connector--before--Width: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Width);
    --pf-v5-c-progress-stepper__step-connector--before--Height: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Height);
    --pf-v5-c-progress-stepper__step-connector--before--BorderRightWidth: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--BorderRightWidth);
    --pf-v5-c-progress-stepper__step-connector--before--BorderRightColor: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--BorderRightColor);
    --pf-v5-c-progress-stepper__step-connector--before--BorderBottomWidth: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--BorderBottomWidth);
    --pf-v5-c-progress-stepper__step-connector--before--BorderBottomColor: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--BorderBottomColor);
    --pf-v5-c-progress-stepper__step-connector--before--Transform: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Transform);
    --pf-v5-c-progress-stepper__step-main--MarginTop: var(--pf-v5-c-progress-stepper--m-horizontal__step-main--MarginTop);
    --pf-v5-c-progress-stepper__step-main--MarginRight: var(--pf-v5-c-progress-stepper--m-horizontal__step-main--MarginRight);
    --pf-v5-c-progress-stepper__step-main--MarginBottom: var(--pf-v5-c-progress-stepper--m-horizontal__step-main--MarginBottom);
    --pf-v5-c-progress-stepper__step-main--MarginLeft: var(--pf-v5-c-progress-stepper--m-horizontal__step-main--MarginLeft);
    --pf-v5-c-progress-stepper--m-compact--GridTemplateColumns: var(--pf-v5-c-progress-stepper--m-horizontal--m-compact--GridTemplateColumns);
    --pf-v5-c-progress-stepper--m-compact__step-connector--GridRow: var(--pf-v5-c-progress-stepper--m-horizontal--m-compact__step-connector--GridRow);
    --pf-v5-c-progress-stepper--m-compact__step-connector--PaddingBottom: var(--pf-v5-c-progress-stepper--m-horizontal--m-compact__step-connector--PaddingBottom);
    --pf-v5-c-progress-stepper--m-center__step-connector--before--Content: "";
    --pf-v5-c-progress-stepper--m-center__step-main--before--Content: none;
  }

  .pf-v5-c-progress-stepper.pf-m-vertical-on-md {
    --pf-v5-c-progress-stepper--GridAutoFlow: var(--pf-v5-c-progress-stepper--m-vertical--GridAutoFlow);
    --pf-v5-c-progress-stepper--GridTemplateColumns: var(--pf-v5-c-progress-stepper--m-vertical--GridTemplateColumns);
    --pf-v5-c-progress-stepper__step-connector--before--Top: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--Top);
    --pf-v5-c-progress-stepper__step-connector--before--Left: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--Left);
    --pf-v5-c-progress-stepper__step-connector--before--Width: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--Width);
    --pf-v5-c-progress-stepper__step-connector--before--Height: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--Height);
    --pf-v5-c-progress-stepper__step-connector--before--BorderRightWidth: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--BorderRightWidth);
    --pf-v5-c-progress-stepper__step-connector--before--BorderRightColor: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--BorderRightColor);
    --pf-v5-c-progress-stepper__step-connector--before--BorderBottomWidth: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--BorderBottomWidth);
    --pf-v5-c-progress-stepper__step-connector--before--BorderBottomColor: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--BorderBottomColor);
    --pf-v5-c-progress-stepper__step-connector--before--Transform: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--Transform);
    --pf-v5-c-progress-stepper__step-main--MarginTop: var(--pf-v5-c-progress-stepper--m-vertical__step-main--MarginTop);
    --pf-v5-c-progress-stepper__step-main--MarginRight: var(--pf-v5-c-progress-stepper--m-vertical__step-main--MarginRight);
    --pf-v5-c-progress-stepper__step-main--MarginBottom: var(--pf-v5-c-progress-stepper--m-vertical__step-main--MarginBottom);
    --pf-v5-c-progress-stepper__step-main--MarginLeft: var(--pf-v5-c-progress-stepper--m-vertical__step-main--MarginLeft);
    --pf-v5-c-progress-stepper--m-compact--GridTemplateColumns: var(--pf-v5-c-progress-stepper--m-vertical--m-compact--GridTemplateColumns);
    --pf-v5-c-progress-stepper--m-compact__step-connector--GridRow: var(--pf-v5-c-progress-stepper--m-vertical--m-compact__step-connector--GridRow);
    --pf-v5-c-progress-stepper--m-compact__step-connector--PaddingBottom: var(--pf-v5-c-progress-stepper--m-vertical--m-compact__step-connector--PaddingBottom);
    --pf-v5-c-progress-stepper--m-center__step-connector--before--Content: none;
    --pf-v5-c-progress-stepper--m-center__step-main--before--Content: "";
  }
}

@media (min-width: 992px) {
  .pf-v5-c-progress-stepper.pf-m-horizontal-on-lg {
    --pf-v5-c-progress-stepper--GridAutoFlow: var(--pf-v5-c-progress-stepper--m-horizontal--GridAutoFlow);
    --pf-v5-c-progress-stepper--GridTemplateColumns: var(--pf-v5-c-progress-stepper--m-horizontal--GridTemplateColumns);
    --pf-v5-c-progress-stepper__step-connector--before--Top: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Top);
    --pf-v5-c-progress-stepper__step-connector--before--Left: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Left);
    --pf-v5-c-progress-stepper__step-connector--before--Width: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Width);
    --pf-v5-c-progress-stepper__step-connector--before--Height: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Height);
    --pf-v5-c-progress-stepper__step-connector--before--BorderRightWidth: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--BorderRightWidth);
    --pf-v5-c-progress-stepper__step-connector--before--BorderRightColor: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--BorderRightColor);
    --pf-v5-c-progress-stepper__step-connector--before--BorderBottomWidth: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--BorderBottomWidth);
    --pf-v5-c-progress-stepper__step-connector--before--BorderBottomColor: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--BorderBottomColor);
    --pf-v5-c-progress-stepper__step-connector--before--Transform: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Transform);
    --pf-v5-c-progress-stepper__step-main--MarginTop: var(--pf-v5-c-progress-stepper--m-horizontal__step-main--MarginTop);
    --pf-v5-c-progress-stepper__step-main--MarginRight: var(--pf-v5-c-progress-stepper--m-horizontal__step-main--MarginRight);
    --pf-v5-c-progress-stepper__step-main--MarginBottom: var(--pf-v5-c-progress-stepper--m-horizontal__step-main--MarginBottom);
    --pf-v5-c-progress-stepper__step-main--MarginLeft: var(--pf-v5-c-progress-stepper--m-horizontal__step-main--MarginLeft);
    --pf-v5-c-progress-stepper--m-compact--GridTemplateColumns: var(--pf-v5-c-progress-stepper--m-horizontal--m-compact--GridTemplateColumns);
    --pf-v5-c-progress-stepper--m-compact__step-connector--GridRow: var(--pf-v5-c-progress-stepper--m-horizontal--m-compact__step-connector--GridRow);
    --pf-v5-c-progress-stepper--m-compact__step-connector--PaddingBottom: var(--pf-v5-c-progress-stepper--m-horizontal--m-compact__step-connector--PaddingBottom);
    --pf-v5-c-progress-stepper--m-center__step-connector--before--Content: "";
    --pf-v5-c-progress-stepper--m-center__step-main--before--Content: none;
  }

  .pf-v5-c-progress-stepper.pf-m-vertical-on-lg {
    --pf-v5-c-progress-stepper--GridAutoFlow: var(--pf-v5-c-progress-stepper--m-vertical--GridAutoFlow);
    --pf-v5-c-progress-stepper--GridTemplateColumns: var(--pf-v5-c-progress-stepper--m-vertical--GridTemplateColumns);
    --pf-v5-c-progress-stepper__step-connector--before--Top: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--Top);
    --pf-v5-c-progress-stepper__step-connector--before--Left: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--Left);
    --pf-v5-c-progress-stepper__step-connector--before--Width: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--Width);
    --pf-v5-c-progress-stepper__step-connector--before--Height: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--Height);
    --pf-v5-c-progress-stepper__step-connector--before--BorderRightWidth: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--BorderRightWidth);
    --pf-v5-c-progress-stepper__step-connector--before--BorderRightColor: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--BorderRightColor);
    --pf-v5-c-progress-stepper__step-connector--before--BorderBottomWidth: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--BorderBottomWidth);
    --pf-v5-c-progress-stepper__step-connector--before--BorderBottomColor: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--BorderBottomColor);
    --pf-v5-c-progress-stepper__step-connector--before--Transform: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--Transform);
    --pf-v5-c-progress-stepper__step-main--MarginTop: var(--pf-v5-c-progress-stepper--m-vertical__step-main--MarginTop);
    --pf-v5-c-progress-stepper__step-main--MarginRight: var(--pf-v5-c-progress-stepper--m-vertical__step-main--MarginRight);
    --pf-v5-c-progress-stepper__step-main--MarginBottom: var(--pf-v5-c-progress-stepper--m-vertical__step-main--MarginBottom);
    --pf-v5-c-progress-stepper__step-main--MarginLeft: var(--pf-v5-c-progress-stepper--m-vertical__step-main--MarginLeft);
    --pf-v5-c-progress-stepper--m-compact--GridTemplateColumns: var(--pf-v5-c-progress-stepper--m-vertical--m-compact--GridTemplateColumns);
    --pf-v5-c-progress-stepper--m-compact__step-connector--GridRow: var(--pf-v5-c-progress-stepper--m-vertical--m-compact__step-connector--GridRow);
    --pf-v5-c-progress-stepper--m-compact__step-connector--PaddingBottom: var(--pf-v5-c-progress-stepper--m-vertical--m-compact__step-connector--PaddingBottom);
    --pf-v5-c-progress-stepper--m-center__step-connector--before--Content: none;
    --pf-v5-c-progress-stepper--m-center__step-main--before--Content: "";
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-progress-stepper.pf-m-horizontal-on-xl {
    --pf-v5-c-progress-stepper--GridAutoFlow: var(--pf-v5-c-progress-stepper--m-horizontal--GridAutoFlow);
    --pf-v5-c-progress-stepper--GridTemplateColumns: var(--pf-v5-c-progress-stepper--m-horizontal--GridTemplateColumns);
    --pf-v5-c-progress-stepper__step-connector--before--Top: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Top);
    --pf-v5-c-progress-stepper__step-connector--before--Left: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Left);
    --pf-v5-c-progress-stepper__step-connector--before--Width: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Width);
    --pf-v5-c-progress-stepper__step-connector--before--Height: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Height);
    --pf-v5-c-progress-stepper__step-connector--before--BorderRightWidth: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--BorderRightWidth);
    --pf-v5-c-progress-stepper__step-connector--before--BorderRightColor: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--BorderRightColor);
    --pf-v5-c-progress-stepper__step-connector--before--BorderBottomWidth: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--BorderBottomWidth);
    --pf-v5-c-progress-stepper__step-connector--before--BorderBottomColor: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--BorderBottomColor);
    --pf-v5-c-progress-stepper__step-connector--before--Transform: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Transform);
    --pf-v5-c-progress-stepper__step-main--MarginTop: var(--pf-v5-c-progress-stepper--m-horizontal__step-main--MarginTop);
    --pf-v5-c-progress-stepper__step-main--MarginRight: var(--pf-v5-c-progress-stepper--m-horizontal__step-main--MarginRight);
    --pf-v5-c-progress-stepper__step-main--MarginBottom: var(--pf-v5-c-progress-stepper--m-horizontal__step-main--MarginBottom);
    --pf-v5-c-progress-stepper__step-main--MarginLeft: var(--pf-v5-c-progress-stepper--m-horizontal__step-main--MarginLeft);
    --pf-v5-c-progress-stepper--m-compact--GridTemplateColumns: var(--pf-v5-c-progress-stepper--m-horizontal--m-compact--GridTemplateColumns);
    --pf-v5-c-progress-stepper--m-compact__step-connector--GridRow: var(--pf-v5-c-progress-stepper--m-horizontal--m-compact__step-connector--GridRow);
    --pf-v5-c-progress-stepper--m-compact__step-connector--PaddingBottom: var(--pf-v5-c-progress-stepper--m-horizontal--m-compact__step-connector--PaddingBottom);
    --pf-v5-c-progress-stepper--m-center__step-connector--before--Content: "";
    --pf-v5-c-progress-stepper--m-center__step-main--before--Content: none;
  }

  .pf-v5-c-progress-stepper.pf-m-vertical-on-xl {
    --pf-v5-c-progress-stepper--GridAutoFlow: var(--pf-v5-c-progress-stepper--m-vertical--GridAutoFlow);
    --pf-v5-c-progress-stepper--GridTemplateColumns: var(--pf-v5-c-progress-stepper--m-vertical--GridTemplateColumns);
    --pf-v5-c-progress-stepper__step-connector--before--Top: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--Top);
    --pf-v5-c-progress-stepper__step-connector--before--Left: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--Left);
    --pf-v5-c-progress-stepper__step-connector--before--Width: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--Width);
    --pf-v5-c-progress-stepper__step-connector--before--Height: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--Height);
    --pf-v5-c-progress-stepper__step-connector--before--BorderRightWidth: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--BorderRightWidth);
    --pf-v5-c-progress-stepper__step-connector--before--BorderRightColor: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--BorderRightColor);
    --pf-v5-c-progress-stepper__step-connector--before--BorderBottomWidth: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--BorderBottomWidth);
    --pf-v5-c-progress-stepper__step-connector--before--BorderBottomColor: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--BorderBottomColor);
    --pf-v5-c-progress-stepper__step-connector--before--Transform: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--Transform);
    --pf-v5-c-progress-stepper__step-main--MarginTop: var(--pf-v5-c-progress-stepper--m-vertical__step-main--MarginTop);
    --pf-v5-c-progress-stepper__step-main--MarginRight: var(--pf-v5-c-progress-stepper--m-vertical__step-main--MarginRight);
    --pf-v5-c-progress-stepper__step-main--MarginBottom: var(--pf-v5-c-progress-stepper--m-vertical__step-main--MarginBottom);
    --pf-v5-c-progress-stepper__step-main--MarginLeft: var(--pf-v5-c-progress-stepper--m-vertical__step-main--MarginLeft);
    --pf-v5-c-progress-stepper--m-compact--GridTemplateColumns: var(--pf-v5-c-progress-stepper--m-vertical--m-compact--GridTemplateColumns);
    --pf-v5-c-progress-stepper--m-compact__step-connector--GridRow: var(--pf-v5-c-progress-stepper--m-vertical--m-compact__step-connector--GridRow);
    --pf-v5-c-progress-stepper--m-compact__step-connector--PaddingBottom: var(--pf-v5-c-progress-stepper--m-vertical--m-compact__step-connector--PaddingBottom);
    --pf-v5-c-progress-stepper--m-center__step-connector--before--Content: none;
    --pf-v5-c-progress-stepper--m-center__step-main--before--Content: "";
  }
}

@media (min-width: 1450px) {
  .pf-v5-c-progress-stepper.pf-m-horizontal-on-2xl {
    --pf-v5-c-progress-stepper--GridAutoFlow: var(--pf-v5-c-progress-stepper--m-horizontal--GridAutoFlow);
    --pf-v5-c-progress-stepper--GridTemplateColumns: var(--pf-v5-c-progress-stepper--m-horizontal--GridTemplateColumns);
    --pf-v5-c-progress-stepper__step-connector--before--Top: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Top);
    --pf-v5-c-progress-stepper__step-connector--before--Left: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Left);
    --pf-v5-c-progress-stepper__step-connector--before--Width: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Width);
    --pf-v5-c-progress-stepper__step-connector--before--Height: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Height);
    --pf-v5-c-progress-stepper__step-connector--before--BorderRightWidth: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--BorderRightWidth);
    --pf-v5-c-progress-stepper__step-connector--before--BorderRightColor: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--BorderRightColor);
    --pf-v5-c-progress-stepper__step-connector--before--BorderBottomWidth: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--BorderBottomWidth);
    --pf-v5-c-progress-stepper__step-connector--before--BorderBottomColor: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--BorderBottomColor);
    --pf-v5-c-progress-stepper__step-connector--before--Transform: var(--pf-v5-c-progress-stepper--m-horizontal__step-connector--before--Transform);
    --pf-v5-c-progress-stepper__step-main--MarginTop: var(--pf-v5-c-progress-stepper--m-horizontal__step-main--MarginTop);
    --pf-v5-c-progress-stepper__step-main--MarginRight: var(--pf-v5-c-progress-stepper--m-horizontal__step-main--MarginRight);
    --pf-v5-c-progress-stepper__step-main--MarginBottom: var(--pf-v5-c-progress-stepper--m-horizontal__step-main--MarginBottom);
    --pf-v5-c-progress-stepper__step-main--MarginLeft: var(--pf-v5-c-progress-stepper--m-horizontal__step-main--MarginLeft);
    --pf-v5-c-progress-stepper--m-compact--GridTemplateColumns: var(--pf-v5-c-progress-stepper--m-horizontal--m-compact--GridTemplateColumns);
    --pf-v5-c-progress-stepper--m-compact__step-connector--GridRow: var(--pf-v5-c-progress-stepper--m-horizontal--m-compact__step-connector--GridRow);
    --pf-v5-c-progress-stepper--m-compact__step-connector--PaddingBottom: var(--pf-v5-c-progress-stepper--m-horizontal--m-compact__step-connector--PaddingBottom);
    --pf-v5-c-progress-stepper--m-center__step-connector--before--Content: "";
    --pf-v5-c-progress-stepper--m-center__step-main--before--Content: none;
  }

  .pf-v5-c-progress-stepper.pf-m-vertical-on-2xl {
    --pf-v5-c-progress-stepper--GridAutoFlow: var(--pf-v5-c-progress-stepper--m-vertical--GridAutoFlow);
    --pf-v5-c-progress-stepper--GridTemplateColumns: var(--pf-v5-c-progress-stepper--m-vertical--GridTemplateColumns);
    --pf-v5-c-progress-stepper__step-connector--before--Top: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--Top);
    --pf-v5-c-progress-stepper__step-connector--before--Left: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--Left);
    --pf-v5-c-progress-stepper__step-connector--before--Width: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--Width);
    --pf-v5-c-progress-stepper__step-connector--before--Height: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--Height);
    --pf-v5-c-progress-stepper__step-connector--before--BorderRightWidth: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--BorderRightWidth);
    --pf-v5-c-progress-stepper__step-connector--before--BorderRightColor: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--BorderRightColor);
    --pf-v5-c-progress-stepper__step-connector--before--BorderBottomWidth: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--BorderBottomWidth);
    --pf-v5-c-progress-stepper__step-connector--before--BorderBottomColor: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--BorderBottomColor);
    --pf-v5-c-progress-stepper__step-connector--before--Transform: var(--pf-v5-c-progress-stepper--m-vertical__step-connector--before--Transform);
    --pf-v5-c-progress-stepper__step-main--MarginTop: var(--pf-v5-c-progress-stepper--m-vertical__step-main--MarginTop);
    --pf-v5-c-progress-stepper__step-main--MarginRight: var(--pf-v5-c-progress-stepper--m-vertical__step-main--MarginRight);
    --pf-v5-c-progress-stepper__step-main--MarginBottom: var(--pf-v5-c-progress-stepper--m-vertical__step-main--MarginBottom);
    --pf-v5-c-progress-stepper__step-main--MarginLeft: var(--pf-v5-c-progress-stepper--m-vertical__step-main--MarginLeft);
    --pf-v5-c-progress-stepper--m-compact--GridTemplateColumns: var(--pf-v5-c-progress-stepper--m-vertical--m-compact--GridTemplateColumns);
    --pf-v5-c-progress-stepper--m-compact__step-connector--GridRow: var(--pf-v5-c-progress-stepper--m-vertical--m-compact__step-connector--GridRow);
    --pf-v5-c-progress-stepper--m-compact__step-connector--PaddingBottom: var(--pf-v5-c-progress-stepper--m-vertical--m-compact__step-connector--PaddingBottom);
    --pf-v5-c-progress-stepper--m-center__step-connector--before--Content: none;
    --pf-v5-c-progress-stepper--m-center__step-main--before--Content: "";
  }
}

.pf-v5-c-radio {
  --pf-v5-c-radio--GridGap: var(--pf-v5-global--spacer--xs) var(--pf-v5-global--spacer--sm);
  --pf-v5-c-radio__label--disabled--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-radio__label--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-radio__label--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-radio__label--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-radio__label--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-radio__input--TranslateY: calc((var(--pf-v5-c-radio__label--LineHeight) * var(--pf-v5-c-radio__label--FontSize) / 2 ) - 50%);
  --pf-v5-c-radio__input--TranslateY--moz: var(--pf-v5-c-radio__input--TranslateY);
  --pf-v5-c-radio__input--first-child--MarginLeft: 0.0625rem;
  --pf-v5-c-radio__input--last-child--MarginRight: 0.0625rem;
  --pf-v5-c-radio__description--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-radio__description--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-radio__body--MarginTop: var(--pf-v5-global--spacer--sm);
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: var(--pf-v5-c-radio--GridGap);
  align-items: baseline;
}

.pf-v5-c-radio.pf-m-standalone {
  --pf-v5-c-radio--GridGap: 0;
  --pf-v5-c-radio__input--TranslateY: none;
  display: inline-grid;
}

.pf-v5-c-radio__input {
  align-self: start;
  -moz-transform: translateY(var(--pf-v5-c-radio__input--TranslateY--moz));
  transform: translateY(var(--pf-v5-c-radio__input--TranslateY));
}

.pf-v5-c-radio__input:first-child {
  margin-inline-start: var(--pf-v5-c-radio__input--first-child--MarginLeft);
}

.pf-v5-c-radio__input:last-child {
  margin-inline-end: var(--pf-v5-c-radio__input--last-child--MarginRight);
}

.pf-v5-c-radio__label {
  font-size: var(--pf-v5-c-radio__label--FontSize);
  font-weight: var(--pf-v5-c-radio__label--FontWeight);
  line-height: var(--pf-v5-c-radio__label--LineHeight);
  color: var(--pf-v5-c-radio__label--Color);
}

.pf-v5-c-radio__description {
  grid-column: 2;
  font-size: var(--pf-v5-c-radio__description--FontSize);
  color: var(--pf-v5-c-radio__description--Color);
}

.pf-v5-c-radio__body {
  grid-column: 2;
  margin-block-start: var(--pf-v5-c-radio__body--MarginTop);
}

.pf-v5-c-radio__label,
.pf-v5-c-radio__input {
  justify-self: start;
}

label.pf-v5-c-radio,
.pf-v5-c-radio__label,
.pf-v5-c-radio__input {
  cursor: pointer;
}

.pf-v5-c-radio__label:disabled,
.pf-v5-c-radio__label.pf-m-disabled,
.pf-v5-c-radio__input:disabled,
.pf-v5-c-radio__input.pf-m-disabled {
  --pf-v5-c-radio__label--Color: var(--pf-v5-c-radio__label--disabled--Color);
  cursor: not-allowed;
}

.pf-v5-c-select {
  color: var(--pf-v5-global--Color--100);
  --pf-v5-c-select__toggle--PaddingTop: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-select__toggle--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-select__toggle--PaddingBottom: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-select__toggle--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-select__toggle--MinWidth: var(--pf-v5-global--target-size--MinWidth);
  --pf-v5-c-select__toggle--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-select__toggle--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-select__toggle--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-select__toggle--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-select__toggle--before--BorderTopWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-select__toggle--before--BorderRightWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-select__toggle--before--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-select__toggle--before--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-select__toggle--before--BorderWidth: initial;
  --pf-v5-c-select__toggle--before--BorderTopColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-select__toggle--before--BorderRightColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-select__toggle--before--BorderBottomColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-select__toggle--before--BorderLeftColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-select__toggle--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-select__toggle--hover--before--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-select__toggle--focus--before--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-select__toggle--focus--before--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-select__toggle--active--before--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-select__toggle--active--before--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-select__toggle--m-expanded--before--BorderBottomColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-select__toggle--m-expanded--before--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-select__toggle--disabled--BackgroundColor: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-select__toggle--m-plain--before--BorderColor: transparent;
  --pf-v5-c-select__toggle--m-placeholder--Color: transparent;
  --pf-v5-c-select--m-invalid__toggle--before--BorderBottomColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-select--m-invalid__toggle--before--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-select--m-invalid__toggle--hover--before--BorderBottomColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-select--m-invalid__toggle--focus--before--BorderBottomColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-select--m-invalid__toggle--active--before--BorderBottomColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-select--m-invalid__toggle--m-expanded--before--BorderBottomColor: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-select--m-invalid__toggle-status-icon--Color: var(--pf-v5-global--danger-color--100);
  --pf-v5-c-select--m-success__toggle--before--BorderBottomColor: var(--pf-v5-global--success-color--100);
  --pf-v5-c-select--m-success__toggle--before--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-select--m-success__toggle--hover--before--BorderBottomColor: var(--pf-v5-global--success-color--100);
  --pf-v5-c-select--m-success__toggle--focus--before--BorderBottomColor: var(--pf-v5-global--success-color--100);
  --pf-v5-c-select--m-success__toggle--active--before--BorderBottomColor: var(--pf-v5-global--success-color--100);
  --pf-v5-c-select--m-success__toggle--m-expanded--before--BorderBottomColor: var(--pf-v5-global--success-color--100);
  --pf-v5-c-select--m-success__toggle-status-icon--Color: var(--pf-v5-global--success-color--100);
  --pf-v5-c-select--m-warning__toggle--before--BorderBottomColor: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-select--m-warning__toggle--before--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-select--m-warning__toggle--hover--before--BorderBottomColor: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-select--m-warning__toggle--focus--before--BorderBottomColor: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-select--m-warning__toggle--active--before--BorderBottomColor: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-select--m-warning__toggle--m-expanded--before--BorderBottomColor: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-select--m-warning__toggle-status-icon--Color: var(--pf-v5-global--warning-color--100);
  --pf-v5-c-select__toggle-wrapper--not-last-child--MarginRight: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-select__toggle-wrapper--MaxWidth: calc(100% - var(--pf-v5-global--spacer--lg));
  --pf-v5-c-select__toggle-wrapper--c-chip-group--MarginTop: 0.3125rem;
  --pf-v5-c-select__toggle-wrapper--c-chip-group--MarginBottom: 0.3125rem;
  --pf-v5-c-select__toggle-typeahead--FlexBasis: 10em;
  --pf-v5-c-select__toggle-typeahead--BackgroundColor: transparent;
  --pf-v5-c-select__toggle-typeahead--BorderTop: var(--pf-v5-global--BorderWidth--sm) solid transparent;
  --pf-v5-c-select__toggle-typeahead--BorderRight: none;
  --pf-v5-c-select__toggle-typeahead--BorderLeft: none;
  --pf-v5-c-select__toggle-typeahead--MinWidth: 7.5rem;
  --pf-v5-c-select__toggle--m-placeholder__toggle-text--Color: var(--pf-v5-global--Color--dark-200);
  --pf-v5-c-select__toggle-icon--toggle-text--MarginLeft: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-select__toggle-badge--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-select__toggle-status-icon--MarginLeft: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-select__toggle-status-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-select__toggle-arrow--MarginLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-select__toggle-arrow--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-select__toggle-arrow--with-clear--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-select__toggle-arrow--m-top--m-expanded__toggle-arrow--Rotate: 180deg;
  --pf-v5-c-select--m-plain__toggle-arrow--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-select--m-plain--hover__toggle-arrow--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-select__toggle-clear--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-select__toggle-clear--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-select__toggle-clear--toggle-button--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-select__toggle-button--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-select__menu--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-c-select__menu--BoxShadow: var(--pf-v5-global--BoxShadow--md);
  --pf-v5-c-select__menu--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-select__menu--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-select__menu--Top: calc(100% + var(--pf-v5-global--spacer--xs));
  --pf-v5-c-select__menu--ZIndex: var(--pf-v5-global--ZIndex--sm);
  --pf-v5-c-select__menu--Width: auto;
  --pf-v5-c-select__menu--MinWidth: 100%;
  --pf-v5-c-select__menu--m-top--TranslateY: calc(-100% - var(--pf-v5-global--spacer--xs));
  --pf-v5-c-select__list-item--m-loading--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-select__menu-item--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-select__menu-item--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-select__menu-item--m-selected--PaddingRight: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-select__menu-item--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-select__menu-item--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-select__menu-item--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-select__menu-item--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-select__menu-item--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-select__menu-item--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-select__menu-item--disabled--Color: var(--pf-v5-global--Color--dark-200);
  --pf-v5-c-select__menu-item--Width: 100%;
  --pf-v5-c-select__menu-item--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-300);
  --pf-v5-c-select__menu-item--focus--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-300);
  --pf-v5-c-select__menu-item--disabled--BackgroundColor: transparent;
  --pf-v5-c-select__menu-item--m-link--Width: auto;
  --pf-v5-c-select__menu-item--m-link--hover--BackgroundColor: transparent;
  --pf-v5-c-select__menu-item--m-link--focus--BackgroundColor: transparent;
  --pf-v5-c-select__menu-item--m-action--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-select__menu-item--m-action--hover--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-select__menu-item--m-action--focus--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-select__menu-item--m-action--disabled--Color: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-select__menu-item--m-action--Width: auto;
  --pf-v5-c-select__menu-item--m-action--FontSize: var(--pf-v5-global--icon--FontSize--sm);
  --pf-v5-c-select__menu-item--m-action--hover--BackgroundColor: transparent;
  --pf-v5-c-select__menu-item--m-action--focus--BackgroundColor: transparent;
  --pf-v5-c-select__menu-item--hover__menu-item--m-action--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-select__menu-item--m-favorite-action--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-select__menu-item--m-favorite-action--hover--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-select__menu-item--m-favorite-action--focus--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-select__menu-wrapper--m-favorite__menu-item--m-favorite-action--Color: var(--pf-v5-global--palette--gold-400);
  --pf-v5-c-select__menu-wrapper--m-favorite__menu-item--m-favorite-action--hover--Color: var(--pf-v5-global--palette--gold-500);
  --pf-v5-c-select__menu-wrapper--m-favorite__menu-item--m-favorite-action--focus--Color: var(--pf-v5-global--palette--gold-500);
  --pf-v5-c-select__menu-item--m-load--Color: var(--pf-v5-global--link--Color);
  --pf-v5-c-select__menu-item-icon--Color: var(--pf-v5-global--active-color--100);
  --pf-v5-c-select__menu-item-icon--FontSize: var(--pf-v5-global--icon--FontSize--sm);
  --pf-v5-c-select__menu-item-icon--Right: var(--pf-v5-global--spacer--md);
  --pf-v5-c-select__menu-item-icon--Top: 50%;
  --pf-v5-c-select__menu-item-icon--TranslateY: -50%;
  --pf-v5-c-select__menu-item-action-icon--MinHeight: calc(var(--pf-v5-c-select__menu-item--FontSize) * var(--pf-v5-c-select__menu-item--LineHeight));
  --pf-v5-c-select__menu-item--match--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-select__menu-search--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-select__menu-search--PaddingRight: var(--pf-v5-c-select__menu-item--PaddingRight);
  --pf-v5-c-select__menu-search--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-select__menu-search--PaddingLeft: var(--pf-v5-c-select__menu-item--PaddingLeft);
  --pf-v5-c-select__menu-group--menu-group--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-select__menu-group-title--PaddingTop: var(--pf-v5-c-select__menu-item--PaddingTop);
  --pf-v5-c-select__menu-group-title--PaddingRight: var(--pf-v5-c-select__menu-item--PaddingRight);
  --pf-v5-c-select__menu-group-title--PaddingBottom: var(--pf-v5-c-select__menu-item--PaddingBottom);
  --pf-v5-c-select__menu-group-title--PaddingLeft: var(--pf-v5-c-select__menu-item--PaddingLeft);
  --pf-v5-c-select__menu-group-title--FontSize: var(--pf-v5-global--FontSize--xs);
  --pf-v5-c-select__menu-group-title--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-select__menu-group-title--Color: var(--pf-v5-global--Color--dark-200);
  --pf-v5-c-select__menu-item-count--MarginLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-select__menu-item-count--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-select__menu-item-count--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-select__menu-item--disabled__menu-item-count--Color: var(--pf-v5-global--Color--dark-200);
  --pf-v5-c-select__menu-item-description--FontSize: var(--pf-v5-global--FontSize--xs);
  --pf-v5-c-select__menu-item-description--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-select__menu-item-description--PaddingRight: var(--pf-v5-c-select__menu-item--PaddingRight);
  --pf-v5-c-select__menu-item-main--PaddingRight: var(--pf-v5-c-select__menu-item--PaddingRight);
  --pf-v5-c-select__menu-item--m-selected__menu-item-main--PaddingRight: var(--pf-v5-c-select__menu-item--m-selected--PaddingRight);
  --pf-v5-c-select__menu-footer--BoxShadow: var(--pf-v5-global--BoxShadow--sm-top);
  --pf-v5-c-select__menu-footer--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-select__menu-footer--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-select__menu-footer--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-select__menu-footer--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-select__menu-footer--MarginTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-select__menu-footer--MarginBottom: calc(var(--pf-v5-global--spacer--sm) * -1);
  --pf-v5-c-select-menu--c-divider--MarginTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-select-menu--c-divider--MarginBottom: var(--pf-v5-global--spacer--sm);
  position: relative;
  display: inline-block;
  width: 100%;
}

.pf-v5-c-select .pf-v5-c-divider {
  margin-block-start: var(--pf-v5-c-select-menu--c-divider--MarginTop);
  margin-block-end: var(--pf-v5-c-select-menu--c-divider--MarginBottom);
}

.pf-v5-c-select .pf-v5-c-divider:last-child {
  --pf-v5-c-select-menu--c-divider--MarginBottom: 0;
}

.pf-v5-c-select.pf-m-invalid {
  --pf-v5-c-select__toggle--before--BorderBottomColor: var(--pf-v5-c-select--m-invalid__toggle--before--BorderBottomColor);
  --pf-v5-c-select__toggle--before--BorderBottomWidth: var(--pf-v5-c-select--m-invalid__toggle--before--BorderBottomWidth);
  --pf-v5-c-select__toggle--hover--before--BorderBottomColor: var(--pf-v5-c-select--m-invalid__toggle--hover--before--BorderBottomColor);
  --pf-v5-c-select__toggle--focus--before--BorderBottomColor: var(--pf-v5-c-select--m-invalid__toggle--focus--before--BorderBottomColor);
  --pf-v5-c-select__toggle--active--before--BorderBottomColor: var(--pf-v5-c-select--m-invalid__toggle--active--before--BorderBottomColor);
  --pf-v5-c-select__toggle--m-expanded--before--BorderBottomColor: var(--pf-v5-c-select--m-invalid__toggle--m-expanded--before--BorderBottomColor);
  --pf-v5-c-select__toggle-status-icon--Color: var(--pf-v5-c-select--m-invalid__toggle-status-icon--Color);
}

.pf-v5-c-select.pf-m-success {
  --pf-v5-c-select__toggle--before--BorderBottomColor: var(--pf-v5-c-select--m-success__toggle--before--BorderBottomColor);
  --pf-v5-c-select__toggle--before--BorderBottomWidth: var(--pf-v5-c-select--m-success__toggle--before--BorderBottomWidth);
  --pf-v5-c-select__toggle--hover--before--BorderBottomColor: var(--pf-v5-c-select--m-success__toggle--hover--before--BorderBottomColor);
  --pf-v5-c-select__toggle--focus--before--BorderBottomColor: var(--pf-v5-c-select--m-success__toggle--focus--before--BorderBottomColor);
  --pf-v5-c-select__toggle--active--before--BorderBottomColor: var(--pf-v5-c-select--m-success__toggle--active--before--BorderBottomColor);
  --pf-v5-c-select__toggle--m-expanded--before--BorderBottomColor: var(--pf-v5-c-select--m-success__toggle--m-expanded--before--BorderBottomColor);
  --pf-v5-c-select__toggle-status-icon--Color: var(--pf-v5-c-select--m-success__toggle-status-icon--Color);
}

.pf-v5-c-select.pf-m-warning {
  --pf-v5-c-select__toggle--before--BorderBottomColor: var(--pf-v5-c-select--m-warning__toggle--before--BorderBottomColor);
  --pf-v5-c-select__toggle--before--BorderBottomWidth: var(--pf-v5-c-select--m-warning__toggle--before--BorderBottomWidth);
  --pf-v5-c-select__toggle--hover--before--BorderBottomColor: var(--pf-v5-c-select--m-warning__toggle--hover--before--BorderBottomColor);
  --pf-v5-c-select__toggle--focus--before--BorderBottomColor: var(--pf-v5-c-select--m-warning__toggle--focus--before--BorderBottomColor);
  --pf-v5-c-select__toggle--active--before--BorderBottomColor: var(--pf-v5-c-select--m-warning__toggle--active--before--BorderBottomColor);
  --pf-v5-c-select__toggle--m-expanded--before--BorderBottomColor: var(--pf-v5-c-select--m-warning__toggle--m-expanded--before--BorderBottomColor);
  --pf-v5-c-select__toggle-status-icon--Color: var(--pf-v5-c-select--m-warning__toggle-status-icon--Color);
}

.pf-v5-c-select__menu-search+.pf-v5-c-divider {
  --pf-v5-c-select-menu--c-divider--MarginTop: 0;
}

.pf-v5-c-select__toggle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-width: var(--pf-v5-c-select__toggle--MinWidth);
  padding-block-start: var(--pf-v5-c-select__toggle--PaddingTop);
  padding-block-end: var(--pf-v5-c-select__toggle--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-select__toggle--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-select__toggle--PaddingRight);
  font-size: var(--pf-v5-c-select__toggle--FontSize);
  font-weight: var(--pf-v5-c-select__toggle--FontWeight);
  line-height: var(--pf-v5-c-select__toggle--LineHeight);
  color: var(--pf-v5-c-select__toggle--Color);
  white-space: nowrap;
  cursor: pointer;
  background-color: var(--pf-v5-c-select__toggle--BackgroundColor);
  border: none;
}

.pf-v5-c-select__toggle.pf-m-disabled,
.pf-v5-c-select__toggle:disabled {
  --pf-v5-c-select__toggle--BackgroundColor: var(--pf-v5-c-select__toggle--disabled--BackgroundColor);
  pointer-events: none;
}

.pf-v5-c-select__toggle.pf-m-disabled::before,
.pf-v5-c-select__toggle:disabled::before {
  border: 0;
}

.pf-v5-c-select__toggle::before {
  --pf-v5-c-select__toggle--before--BorderWidth-base: var(--pf-v5-c-select__toggle--before--BorderTopWidth) var(--pf-v5-c-select__toggle--before--BorderRightWidth) var(--pf-v5-c-select__toggle--before--BorderBottomWidth) var(--pf-v5-c-select__toggle--before--BorderLeftWidth);
  position: absolute;
  inset: 0;
  content: "";
  border-style: solid;
  border-width: var(--pf-v5-c-select__toggle--before--BorderWidth, var(--pf-v5-c-select__toggle--before--BorderWidth-base));
  border-block-start-color: var(--pf-v5-c-select__toggle--before--BorderTopColor);
  border-block-end-color: var(--pf-v5-c-select__toggle--before--BorderBottomColor);
  border-inline-start-color: var(--pf-v5-c-select__toggle--before--BorderLeftColor);
  border-inline-end-color: var(--pf-v5-c-select__toggle--before--BorderRightColor);
}

.pf-v5-c-select__toggle:hover {
  --pf-v5-c-select__toggle--before--BorderBottomColor: var(--pf-v5-c-select__toggle--hover--before--BorderBottomColor);
}

.pf-v5-c-select__toggle:focus,
.pf-v5-c-select__toggle:focus-within {
  --pf-v5-c-select__toggle--before--BorderBottomColor: var(--pf-v5-c-select__toggle--focus--before--BorderBottomColor);
  --pf-v5-c-select__toggle--before--BorderBottomWidth: var(--pf-v5-c-select__toggle--focus--before--BorderBottomWidth);
}

.pf-v5-c-select__toggle:active,
.pf-v5-c-select__toggle.pf-m-active {
  --pf-v5-c-select__toggle--before--BorderBottomColor: var(--pf-v5-c-select__toggle--active--before--BorderBottomColor);
  --pf-v5-c-select__toggle--before--BorderBottomWidth: var(--pf-v5-c-select__toggle--active--before--BorderBottomWidth);
}

.pf-m-expanded>.pf-v5-c-select__toggle {
  --pf-v5-c-select__toggle--before--BorderBottomColor: var(--pf-v5-c-select__toggle--m-expanded--before--BorderBottomColor);
  --pf-v5-c-select__toggle--before--BorderBottomWidth: var(--pf-v5-c-select__toggle--m-expanded--before--BorderBottomWidth);
}

.pf-v5-c-select__toggle.pf-m-plain {
  --pf-v5-c-select__toggle-arrow--Color: var(--pf-v5-c-select--m-plain__toggle-arrow--Color);
}

.pf-v5-c-select__toggle.pf-m-plain::before {
  border-color: var(--pf-v5-c-select__toggle--m-plain--before--BorderColor);
}

.pf-v5-c-select__toggle.pf-m-plain:hover,
.pf-v5-c-select__toggle.pf-m-plain:active,
.pf-v5-c-select__toggle.pf-m-plain.pf-m-active,
.pf-v5-c-select__toggle.pf-m-plain:focus,
.pf-v5-c-select.pf-m-expanded>.pf-v5-c-select__toggle.pf-m-plain {
  --pf-v5-c-select--m-plain__toggle-arrow--Color: var(--pf-v5-c-select--m-plain--hover__toggle-arrow--Color);
}

.pf-v5-c-select__toggle.pf-m-typeahead {
  --pf-v5-c-select__toggle--PaddingTop: 0;
  --pf-v5-c-select__toggle--PaddingRight: 0;
  --pf-v5-c-select__toggle--PaddingBottom: 0;
}

.pf-v5-c-select__toggle.pf-m-typeahead .pf-v5-c-form-control {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  --pf-v5-c-form-control--invalid--BackgroundUrl: none;
  position: relative;
}

.pf-v5-c-select__toggle .pf-v5-c-select__toggle-clear {
  padding-inline-start: var(--pf-v5-c-select__toggle-clear--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-select__toggle-clear--PaddingRight);
  margin-inline-start: auto;
}

.pf-v5-c-select__toggle .pf-v5-c-select__toggle-button {
  color: var(--pf-v5-c-select__toggle-button--Color);
}

.pf-v5-c-select__toggle .pf-v5-c-select__toggle-clear+.pf-v5-c-select__toggle-button {
  padding-inline-start: var(--pf-v5-c-select__toggle-clear--toggle-button--PaddingLeft);
}

.pf-v5-c-select__toggle.pf-m-placeholder {
  --pf-v5-c-select__toggle-text--Color: var(--pf-v5-c-select__toggle--m-placeholder__toggle-text--Color);
}

.pf-v5-c-select__toggle-arrow {
  color: var(--pf-v5-c-select__toggle-arrow--Color, inherit);
}

*+.pf-v5-c-select__toggle-arrow {
  margin-inline-start: var(--pf-v5-c-select__toggle-arrow--MarginLeft);
  margin-inline-end: var(--pf-v5-c-select__toggle-arrow--MarginRight);
}

.pf-v5-c-select.pf-m-top.pf-m-expanded .pf-v5-c-select__toggle-arrow {
  transform: rotate(var(--pf-v5-c-select__toggle-arrow--m-top--m-expanded__toggle-arrow--Rotate));
}

.pf-v5-c-select__toggle-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--pf-v5-c-select__toggle-text--Color, inherit);
}

.pf-v5-c-select__toggle-wrapper {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  max-width: var(--pf-v5-c-select__toggle-wrapper--MaxWidth);
  white-space: normal;
}

.pf-v5-c-select__toggle-wrapper>:not(:last-child) {
  margin-inline-end: var(--pf-v5-c-select__toggle-wrapper--not-last-child--MarginRight);
}

.pf-v5-c-select__toggle-wrapper .pf-v5-c-chip-group {
  margin-block-start: var(--pf-v5-c-select__toggle-wrapper--c-chip-group--MarginTop);
  margin-block-end: var(--pf-v5-c-select__toggle-wrapper--c-chip-group--MarginBottom);
}

.pf-v5-c-select__toggle-wrapper>.pf-v5-c-select__toggle-typeahead:first-child {
  margin-inline-start: calc(-1*var(--pf-v5-c-select__toggle--PaddingLeft));
}

.pf-v5-c-select__toggle-icon+.pf-v5-c-select__toggle-text {
  margin-inline-start: var(--pf-v5-c-select__toggle-icon--toggle-text--MarginLeft);
}

.pf-v5-c-select__toggle-status-icon {
  margin-inline-start: var(--pf-v5-c-select__toggle-status-icon--MarginLeft);
  color: var(--pf-v5-c-select__toggle-status-icon--Color);
}

.pf-v5-c-select__toggle-badge {
  display: flex;
  padding-inline-start: var(--pf-v5-c-select__toggle-badge--PaddingLeft);
}

.pf-v5-c-select__toggle-typeahead {
  flex-basis: var(--pf-v5-c-select__toggle-typeahead--FlexBasis);
  flex-grow: 1;
  flex-shrink: 0;
  min-width: var(--pf-v5-c-select__toggle-typeahead--MinWidth);
}

.pf-v5-c-select__toggle-typeahead.pf-v5-c-form-control {
  background-color: var(--pf-v5-c-select__toggle-typeahead--BackgroundColor);
}

.pf-v5-c-select__toggle-typeahead.pf-v5-c-form-control::before,
.pf-v5-c-select__toggle-typeahead.pf-v5-c-form-control::after {
  border: 0;
}

.pf-v5-c-select__menu {
  position: absolute;
  inset-block-start: var(--pf-v5-c-select__menu--Top);
  z-index: var(--pf-v5-c-select__menu--ZIndex);
  width: var(--pf-v5-c-select__menu--Width);
  min-width: var(--pf-v5-c-select__menu--MinWidth);
  padding-block-start: var(--pf-v5-c-select__menu--PaddingTop);
  padding-block-end: var(--pf-v5-c-select__menu--PaddingBottom);
  background-color: var(--pf-v5-c-select__menu--BackgroundColor);
  background-clip: padding-box;
  box-shadow: var(--pf-v5-c-select__menu--BoxShadow);
}

.pf-v5-c-select__menu.pf-m-align-right {
  inset-inline-end: 0;
}

.pf-v5-c-select.pf-m-top .pf-v5-c-select__menu {
  inset-block-start: 0;
  transform: translateY(var(--pf-v5-c-select__menu--m-top--TranslateY));
}

.pf-v5-c-select__menu.pf-m-static {
  --pf-v5-c-select__menu--m-top--TranslateY: 0;
  position: static;
  inset: auto;
  z-index: auto;
  min-width: min-content;
}

.pf-v5-c-select__menu-fieldset {
  border: 0;
}

.pf-v5-c-select__menu-wrapper {
  display: flex;
}

.pf-v5-c-select__menu-wrapper.pf-m-favorite .pf-v5-c-select__menu-item.pf-m-favorite-action {
  --pf-v5-c-select__menu-item--m-favorite-action--Color: var(--pf-v5-c-select__menu-wrapper--m-favorite__menu-item--m-favorite-action--Color);
  --pf-v5-c-select__menu-item--m-favorite-action--hover--Color: var(--pf-v5-c-select__menu-wrapper--m-favorite__menu-item--m-favorite-action--hover--Color);
  --pf-v5-c-select__menu-item--m-favorite-action--focus--Color: var(--pf-v5-c-select__menu-wrapper--m-favorite__menu-item--m-favorite-action--focus--Color);
}

.pf-v5-c-select__menu-item {
  position: relative;
  width: var(--pf-v5-c-select__menu-item--Width);
  padding-block-start: var(--pf-v5-c-select__menu-item--PaddingTop);
  padding-block-end: var(--pf-v5-c-select__menu-item--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-select__menu-item--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-select__menu-item--PaddingRight);
  font-size: var(--pf-v5-c-select__menu-item--FontSize);
  font-weight: var(--pf-v5-c-select__menu-item--FontWeight);
  line-height: var(--pf-v5-c-select__menu-item--LineHeight);
  color: var(--pf-v5-c-select__menu-item--Color);
  text-align: start;
  white-space: nowrap;
  background-color: transparent;
  border: none;
}

.pf-v5-c-select__menu-item:hover,
.pf-v5-c-select__menu-item:focus,
.pf-v5-c-select__menu-item.pf-m-focus {
  --pf-v5-c-select__menu-item--m-action--Color: var(--pf-v5-c-select__menu-item--hover__menu-item--m-action--Color);
  text-decoration: none;
}

.pf-v5-c-select__menu-wrapper:hover,
.pf-v5-c-select__menu-item:hover {
  background-color: var(--pf-v5-c-select__menu-item--hover--BackgroundColor);
}

.pf-v5-c-select__menu-wrapper:focus-within,
.pf-v5-c-select__menu-wrapper.pf-m-focus,
.pf-v5-c-select__menu-item:focus,
.pf-v5-c-select__menu-item.pf-m-focus {
  position: relative;
  background-color: var(--pf-v5-c-select__menu-item--focus--BackgroundColor);
}

.pf-v5-c-select__menu-item.pf-m-link {
  --pf-v5-c-select__menu-item--PaddingRight: 0;
  --pf-v5-c-select__menu-item-main--PaddingRight: 0;
  --pf-v5-c-select__menu-item-description--PaddingRight: 0;
  --pf-v5-c-select__menu-item--Width: var(--pf-v5-c-select__menu-item--m-link--Width);
  --pf-v5-c-select__menu-item--hover--BackgroundColor: var(--pf-v5-c-select__menu-item--m-link--hover--BackgroundColor);
  --pf-v5-c-select__menu-item--focus--BackgroundColor: var(--pf-v5-c-select__menu-item--m-link--focus--BackgroundColor);
  flex-grow: 1;
}

.pf-v5-c-select__menu-item.pf-m-action {
  --pf-v5-c-select__menu-item--Color: var(--pf-v5-c-select__menu-item--m-action--Color);
  --pf-v5-c-select__menu-item--Width: var(--pf-v5-c-select__menu-item--m-action--Width);
  --pf-v5-c-select__menu-item--hover--BackgroundColor: var(--pf-v5-c-select__menu-item--m-action--hover--BackgroundColor);
  --pf-v5-c-select__menu-item--focus--BackgroundColor: var(--pf-v5-c-select__menu-item--m-action--focus--BackgroundColor);
  display: flex;
  align-items: flex-start;
  font-size: var(--pf-v5-c-select__menu-item--m-action--FontSize);
}

.pf-v5-c-select__menu-item.pf-m-action:hover {
  --pf-v5-c-select__menu-item--m-action--Color: var(--pf-v5-c-select__menu-item--m-action--hover--Color);
}

.pf-v5-c-select__menu-item.pf-m-action:focus {
  --pf-v5-c-select__menu-item--m-action--Color: var(--pf-v5-c-select__menu-item--m-action--focus--Color);
}

.pf-v5-c-select__menu-item.pf-m-action:disabled {
  --pf-v5-c-select__menu-item--disabled--Color: var(--pf-v5-c-select__menu-item--m-action--disabled--Color);
}

.pf-v5-c-select__menu-item.pf-m-favorite-action {
  --pf-v5-c-select__menu-item--m-action--Color: var(--pf-v5-c-select__menu-item--m-favorite-action--Color);
  --pf-v5-c-select__menu-item--m-action--hover--Color: var(--pf-v5-c-select__menu-item--m-favorite-action--hover--Color);
  --pf-v5-c-select__menu-item--m-action--focus--Color: var(--pf-v5-c-select__menu-item--m-favorite-action--focus--Color);
}

.pf-v5-c-select__menu-item.pf-m-selected {
  --pf-v5-c-select__menu-item--PaddingRight: var(--pf-v5-c-select__menu-item--m-selected--PaddingRight);
  --pf-v5-c-select__menu-item-main--PaddingRight: var(--pf-v5-c-select__menu-item--m-selected__menu-item-main--PaddingRight);
}

.pf-v5-c-select__menu-item.pf-m-description {
  white-space: normal;
}

.pf-v5-c-select__menu-item.pf-m-description:not(.pf-v5-c-check) {
  --pf-v5-c-select__menu-item--PaddingRight: 0;
}

.pf-v5-c-select__menu-item.pf-m-description .pf-v5-c-check__label {
  white-space: nowrap;
}

.pf-v5-c-select__menu-wrapper.pf-m-disabled,
.pf-v5-c-select__menu-item:disabled,
.pf-v5-c-select__menu-item.pf-m-disabled {
  --pf-v5-c-select__menu-item-count--Color: var(--pf-v5-c-select__menu-item--disabled__menu-item-count--Color);
  color: var(--pf-v5-c-select__menu-item--disabled--Color);
  pointer-events: none;
  background-color: var(--pf-v5-c-select__menu-item--disabled--BackgroundColor);
}

.pf-v5-c-select__menu-item.pf-v5-c-check .pf-v5-c-check__label {
  width: 100%;
}

.pf-v5-c-select__menu-item.pf-m-load {
  --pf-v5-c-select__menu-item--hover--BackgroundColor: transparent;
  --pf-v5-c-select__menu-item--focus--BackgroundColor: transparent;
  --pf-v5-c-select__menu-item--Color: var(--pf-v5-c-select__menu-item--m-load--Color);
}

.pf-v5-c-select__list-item.pf-m-loading {
  padding-block-start: var(--pf-v5-c-select__list-item--m-loading--PaddingTop);
  text-align: center;
}

.pf-v5-c-select__menu-item-main {
  position: relative;
  display: block;
  padding-inline-end: var(--pf-v5-c-select__menu-item-main--PaddingRight);
  white-space: nowrap;
}

.pf-v5-c-select__menu-item-row {
  display: flex;
}

.pf-v5-c-select__menu-item-text {
  flex-grow: 1;
}

.pf-v5-c-select__menu-item-count {
  align-self: center;
  margin-inline-start: var(--pf-v5-c-select__menu-item-count--MarginLeft);
  font-size: var(--pf-v5-c-select__menu-item-count--FontSize);
  color: var(--pf-v5-c-select__menu-item-count--Color);
}

.pf-v5-c-select__menu-item-description {
  display: block;
  padding-inline-end: var(--pf-v5-c-select__menu-item-description--PaddingRight);
  font-size: var(--pf-v5-c-select__menu-item-description--FontSize);
  color: var(--pf-v5-c-select__menu-item-description--Color);
}

.pf-v5-c-select__menu-item-icon {
  position: absolute;
  inset-block-start: var(--pf-v5-c-select__menu-item-icon--Top);
  inset-inline-end: var(--pf-v5-c-select__menu-item-icon--Right);
  font-size: var(--pf-v5-c-select__menu-item-icon--FontSize);
  color: var(--pf-v5-c-select__menu-item-icon--Color);
  transform: translateY(var(--pf-v5-c-select__menu-item-icon--TranslateY));
}

.pf-v5-c-select__menu-item-action-icon {
  display: flex;
  align-items: center;
  min-height: var(--pf-v5-c-select__menu-item-action-icon--MinHeight);
}

.pf-v5-c-select__menu-item--match {
  font-weight: var(--pf-v5-c-select__menu-item--match--FontWeight);
  background-color: inherit;
}

.pf-v5-c-select__menu-group+.pf-v5-c-select__menu-group {
  padding-block-start: var(--pf-v5-c-select__menu-group--menu-group--PaddingTop);
}

.pf-v5-c-select__menu-search {
  padding-block-start: var(--pf-v5-c-select__menu-search--PaddingTop);
  padding-block-end: var(--pf-v5-c-select__menu-search--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-select__menu-search--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-select__menu-search--PaddingRight);
}

.pf-v5-c-select__menu-group-title {
  padding-block-start: var(--pf-v5-c-select__menu-group-title--PaddingTop);
  padding-block-end: var(--pf-v5-c-select__menu-group-title--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-select__menu-group-title--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-select__menu-group-title--PaddingRight);
  font-size: var(--pf-v5-c-select__menu-group-title--FontSize);
  font-weight: var(--pf-v5-c-select__menu-group-title--FontWeight);
  color: var(--pf-v5-c-select__menu-group-title--Color);
}

.pf-v5-c-select__menu-footer {
  padding-block-start: var(--pf-v5-c-select__menu-footer--PaddingTop);
  padding-block-end: var(--pf-v5-c-select__menu-footer--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-select__menu-footer--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-select__menu-footer--PaddingRight);
  margin-block-start: var(--pf-v5-c-select__menu-footer--MarginTop);
  margin-block-end: var(--pf-v5-c-select__menu-footer--MarginBottom);
  box-shadow: var(--pf-v5-c-select__menu-footer--BoxShadow);
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-select {
  --pf-v5-c-select__toggle--disabled--BackgroundColor: var(--pf-v5-global--palette--black-500);
  --pf-v5-c-select__toggle--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-select__toggle--before--BorderTopColor: transparent;
  --pf-v5-c-select__toggle--before--BorderRightColor: transparent;
  --pf-v5-c-select__toggle--before--BorderBottomColor: var(--pf-v5-global--BorderColor--400);
  --pf-v5-c-select__toggle--before--BorderLeftColor: transparent;
  --pf-v5-c-select__menu--Top: 100%;
  --pf-v5-c-select__menu--m-top--TranslateY: -100%;
  --pf-v5-c-select__menu-item--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-select__menu--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-select__toggle:disabled,
:where(.pf-v5-theme-dark) .pf-v5-c-select__toggle.pf-m-disabled {
  color: var(--pf-v5-global--palette--black-100);
}

.pf-v5-c-sidebar {
  --pf-v5-c-sidebar--inset: var(--pf-v5-global--spacer--md);
  --pf-v5-c-sidebar--xl--inset: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-sidebar--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-sidebar--BorderWidth--base: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-sidebar--BorderColor--base: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-sidebar__panel--PaddingTop: 0;
  --pf-v5-c-sidebar__panel--PaddingRight: 0;
  --pf-v5-c-sidebar__panel--PaddingBottom: 0;
  --pf-v5-c-sidebar__panel--PaddingLeft: 0;
  --pf-v5-c-sidebar__panel--Order: -1;
  --pf-v5-c-sidebar__panel--m-padding--PaddingTop: var(--pf-v5-c-sidebar--inset);
  --pf-v5-c-sidebar__panel--m-padding--PaddingRight: var(--pf-v5-c-sidebar--inset);
  --pf-v5-c-sidebar__panel--m-padding--PaddingBottom: var(--pf-v5-c-sidebar--inset);
  --pf-v5-c-sidebar__panel--m-padding--PaddingLeft: var(--pf-v5-c-sidebar--inset);
  --pf-v5-c-sidebar__content--PaddingTop: 0;
  --pf-v5-c-sidebar__content--PaddingRight: 0;
  --pf-v5-c-sidebar__content--PaddingBottom: 0;
  --pf-v5-c-sidebar__content--PaddingLeft: 0;
  --pf-v5-c-sidebar__content--Order: 1;
  --pf-v5-c-sidebar__content--m-padding--PaddingTop: var(--pf-v5-c-sidebar--inset);
  --pf-v5-c-sidebar__content--m-padding--PaddingRight: var(--pf-v5-c-sidebar--inset);
  --pf-v5-c-sidebar__content--m-padding--PaddingBottom: var(--pf-v5-c-sidebar--inset);
  --pf-v5-c-sidebar__content--m-padding--PaddingLeft: var(--pf-v5-c-sidebar--inset);
  --pf-v5-c-sidebar__main--FlexDirection: column;
  --pf-v5-c-sidebar__main--md--FlexDirection: row;
  --pf-v5-c-sidebar__main--AlignItems: stretch;
  --pf-v5-c-sidebar__main--md--AlignItems: flex-start;
  --pf-v5-c-sidebar__main--child--MarginTop: 0;
  --pf-v5-c-sidebar--m-gutter__main--Gap: var(--pf-v5-c-sidebar--inset);
  --pf-v5-c-sidebar__main--m-border--before--Display: none;
  --pf-v5-c-sidebar__main--m-border--before--md--Display: block;
  --pf-v5-c-sidebar__main--m-border--before--BorderWidth: var(--pf-v5-c-sidebar--BorderWidth--base);
  --pf-v5-c-sidebar__main--m-border--before--BorderColor: var(--pf-v5-c-sidebar--BorderColor--base);
  --pf-v5-c-sidebar--m-panel-right__panel--Order: -1;
  --pf-v5-c-sidebar--m-panel-right__panel--md--Order: 1;
  --pf-v5-c-sidebar--m-panel-right__content--md--Order: -1;
  --pf-v5-c-sidebar--m-stack__main--FlexDirection: column;
  --pf-v5-c-sidebar--m-stack__main--AlignItems: stretch;
  --pf-v5-c-sidebar--m-stack__panel--Position: sticky;
  --pf-v5-c-sidebar--m-stack__panel--Top: 0;
  --pf-v5-c-sidebar--m-stack__panel--BoxShadow: var(--pf-v5-c-sidebar__panel--BoxShadow--base);
  --pf-v5-c-sidebar--m-stack--m-panel-right__panel--Order: -1;
  --pf-v5-c-sidebar--m-split__main--AlignItems: flex-start;
  --pf-v5-c-sidebar--m-split__main--FlexDirection: row;
  --pf-v5-c-sidebar--m-split__panel--Position: static;
  --pf-v5-c-sidebar--m-split__panel--Top: auto;
  --pf-v5-c-sidebar--m-split--m-panel-right__panel--Order: 1;
  --pf-v5-c-sidebar--m-split__main--m-border--before--Display: block;
  --pf-v5-c-sidebar__panel--FlexBasis--base: auto;
  --pf-v5-c-sidebar__panel--BoxShadow--base: 0 0.25rem 0.25rem -0.25rem rgba(3, 3, 3, 0.16);
  --pf-v5-c-sidebar__panel--BoxShadow: var(--pf-v5-c-sidebar__panel--BoxShadow--base);
  --pf-v5-c-sidebar__panel--Top: 0;
  --pf-v5-c-sidebar__panel--md--Top: auto;
  --pf-v5-c-sidebar__panel--Position: sticky;
  --pf-v5-c-sidebar__panel--md--Position: static;
  --pf-v5-c-sidebar__panel--FlexBasis-base: auto;
  --pf-v5-c-sidebar__panel--FlexBasis: var(--pf-v5-c-sidebar__panel--FlexBasis-base);
  --pf-v5-c-sidebar__panel--md--FlexBasis: 15.625rem;
  --pf-v5-c-sidebar__panel--m-split--FlexBasis: 15.625rem;
  --pf-v5-c-sidebar__panel--m-stack--FlexBasis: auto;
  --pf-v5-c-sidebar__panel--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-sidebar__panel--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-sidebar__content--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-sidebar__panel--m-sticky--Top: 0;
  --pf-v5-c-sidebar__panel--m-sticky--Position: sticky;
  height: 100%;
}

@media (min-width: 768px) {
  .pf-v5-c-sidebar {
    --pf-v5-c-sidebar__main--FlexDirection: var(--pf-v5-c-sidebar__main--md--FlexDirection);
    --pf-v5-c-sidebar__main--AlignItems: var(--pf-v5-c-sidebar__main--md--AlignItems);
    --pf-v5-c-sidebar__main--m-border--before--Display: var(--pf-v5-c-sidebar__main--m-border--before--md--Display);
    --pf-v5-c-sidebar__panel--BoxShadow: none;
    --pf-v5-c-sidebar__panel--FlexBasis: var(--pf-v5-c-sidebar__panel--md--FlexBasis);
    --pf-v5-c-sidebar__panel--Top: var(--pf-v5-c-sidebar__panel--md--Top);
    --pf-v5-c-sidebar__panel--Position: var(--pf-v5-c-sidebar__panel--md--Position);
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-sidebar {
    --pf-v5-c-sidebar--inset: var(--pf-v5-c-sidebar--xl--inset);
  }
}

.pf-v5-c-sidebar.pf-m-gutter>.pf-v5-c-sidebar__main {
  gap: var(--pf-v5-c-sidebar--m-gutter__main--Gap);
}

.pf-v5-c-sidebar.pf-m-panel-right {
  --pf-v5-c-sidebar__panel--Order: var(--pf-v5-c-sidebar--m-panel-right__panel--Order);
  --pf-v5-c-sidebar__content--Order: var(--pf-v5-c-sidebar--m-panel-right__content--Order);
}

@media (min-width: 768px) {
  .pf-v5-c-sidebar.pf-m-panel-right {
    --pf-v5-c-sidebar--m-panel-right__panel--Order: var(--pf-v5-c-sidebar--m-panel-right__panel--md--Order);
    --pf-v5-c-sidebar--m-panel-right__content--Order: var(--pf-v5-c-sidebar--m-panel-right__content--md--Order);
  }
}

.pf-v5-c-sidebar.pf-m-stack {
  --pf-v5-c-sidebar__main--FlexDirection: var(--pf-v5-c-sidebar--m-stack__main--FlexDirection);
  --pf-v5-c-sidebar__main--AlignItems: var(--pf-v5-c-sidebar--m-stack__main--AlignItems);
  --pf-v5-c-sidebar__panel--Position: var(--pf-v5-c-sidebar--m-stack__panel--Position);
  --pf-v5-c-sidebar__panel--Top: var(--pf-v5-c-sidebar--m-stack__panel--Top);
  --pf-v5-c-sidebar__panel--BoxShadow: var(--pf-v5-c-sidebar--m-stack__panel--BoxShadow);
  --pf-v5-c-sidebar__panel--FlexBasis: var(--pf-v5-c-sidebar__panel--m-stack--FlexBasis);
  --pf-v5-c-sidebar__main--m-border--before--Display: none;
  --pf-v5-c-sidebar--m-panel-right__panel--Order: var(--pf-v5-c-sidebar--m-stack--m-panel-right__panel--Order);
}

.pf-v5-c-sidebar.pf-m-split {
  --pf-v5-c-sidebar__main--FlexDirection: var(--pf-v5-c-sidebar--m-split__main--FlexDirection);
  --pf-v5-c-sidebar__main--AlignItems: var(--pf-v5-c-sidebar--m-split__main--AlignItems);
  --pf-v5-c-sidebar__panel--Position: var(--pf-v5-c-sidebar--m-split__panel--Position);
  --pf-v5-c-sidebar__panel--Top: var(--pf-v5-c-sidebar--m-split__panel--Top);
  --pf-v5-c-sidebar__panel--BoxShadow: none;
  --pf-v5-c-sidebar__panel--FlexBasis: var(--pf-v5-c-sidebar__panel--m-split--FlexBasis);
  --pf-v5-c-sidebar__main--m-border--before--Display: var(--pf-v5-c-sidebar--m-split__main--m-border--before--Display);
  --pf-v5-c-sidebar--m-panel-right__panel--Order: var(--pf-v5-c-sidebar--m-split--m-panel-right__panel--Order);
}

.pf-v5-c-sidebar__main {
  display: flex;
  flex-direction: var(--pf-v5-c-sidebar__main--FlexDirection);
  align-items: var(--pf-v5-c-sidebar__main--AlignItems);
}

.pf-v5-c-sidebar__main.pf-m-border::before {
  display: var(--pf-v5-c-sidebar__main--m-border--before--Display);
  flex: 0 0 var(--pf-v5-c-sidebar__main--m-border--before--BorderWidth);
  align-self: stretch;
  content: "";
  background-color: var(--pf-v5-c-sidebar__main--m-border--before--BorderColor);
}

.pf-v5-c-sidebar__panel {
  position: var(--pf-v5-c-sidebar__panel--Position);
  inset-block-start: var(--pf-v5-c-sidebar__panel--Top);
  z-index: var(--pf-v5-c-sidebar__panel--ZIndex);
  flex-basis: var(--pf-v5-c-sidebar__panel--FlexBasis);
  flex-shrink: 0;
  order: var(--pf-v5-c-sidebar__panel--Order);
  padding-block-start: var(--pf-v5-c-sidebar__panel--PaddingTop);
  padding-block-end: var(--pf-v5-c-sidebar__panel--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-sidebar__panel--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-sidebar__panel--PaddingRight);
  background-color: var(--pf-v5-c-sidebar__panel--BackgroundColor);
  box-shadow: var(--pf-v5-c-sidebar__panel--BoxShadow);
}

.pf-v5-c-sidebar__panel.pf-m-padding {
  --pf-v5-c-sidebar__panel--PaddingTop: var(--pf-v5-c-sidebar__panel--m-padding--PaddingTop);
  --pf-v5-c-sidebar__panel--PaddingRight: var(--pf-v5-c-sidebar__panel--m-padding--PaddingRight);
  --pf-v5-c-sidebar__panel--PaddingBottom: var(--pf-v5-c-sidebar__panel--m-padding--PaddingBottom);
  --pf-v5-c-sidebar__panel--PaddingLeft: var(--pf-v5-c-sidebar__panel--m-padding--PaddingLeft);
}

.pf-v5-c-sidebar__panel.pf-m-sticky {
  --pf-v5-c-sidebar__panel--Position: var(--pf-v5-c-sidebar__panel--m-sticky--Position);
  --pf-v5-c-sidebar__panel--Top: var(--pf-v5-c-sidebar__panel--m-sticky--Top);
}

.pf-v5-c-sidebar__panel.pf-m-static {
  --pf-v5-c-sidebar__panel--Position: static;
  --pf-v5-c-sidebar__panel--Top: auto;
}

.pf-v5-c-sidebar__content {
  flex-grow: 1;
  order: var(--pf-v5-c-sidebar__content--Order);
  padding-block-start: var(--pf-v5-c-sidebar__content--PaddingTop);
  padding-block-end: var(--pf-v5-c-sidebar__content--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-sidebar__content--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-sidebar__content--PaddingRight);
  background-color: var(--pf-v5-c-sidebar__content--BackgroundColor);
}

.pf-v5-c-sidebar__content.pf-m-padding {
  --pf-v5-c-sidebar__content--PaddingTop: var(--pf-v5-c-sidebar__content--m-padding--PaddingTop);
  --pf-v5-c-sidebar__content--PaddingRight: var(--pf-v5-c-sidebar__content--m-padding--PaddingRight);
  --pf-v5-c-sidebar__content--PaddingBottom: var(--pf-v5-c-sidebar__content--m-padding--PaddingBottom);
  --pf-v5-c-sidebar__content--PaddingLeft: var(--pf-v5-c-sidebar__content--m-padding--PaddingTop);
}

.pf-v5-c-sidebar__content.pf-m-no-background {
  --pf-v5-c-sidebar__content--BackgroundColor: transparent;
}

.pf-v5-c-sidebar__content+.pf-v5-c-sidebar__panel {
  --pf-v5-c-sidebar__panel--Order: 1;
}

.pf-v5-c-sidebar__content :where(&:first-child) {
  --pf-v5-c-sidebar__content--Order: -1;
}

.pf-v5-c-sidebar.pf-m-no-background,
.pf-v5-c-sidebar__panel.pf-m-no-background,
.pf-v5-c-sidebar__content.pf-m-no-background {
  background-color: transparent;
}

.pf-v5-c-sidebar__panel.pf-m-width-default {
  --pf-v5-c-sidebar__panel--FlexBasis: var(--pf-v5-c-sidebar__panel--FlexBasis--base);
}

.pf-v5-c-sidebar__panel.pf-m-width-25 {
  --pf-v5-c-sidebar__panel--FlexBasis: 25%;
}

.pf-v5-c-sidebar__panel.pf-m-width-33 {
  --pf-v5-c-sidebar__panel--FlexBasis: 33%;
}

.pf-v5-c-sidebar__panel.pf-m-width-50 {
  --pf-v5-c-sidebar__panel--FlexBasis: 50%;
}

.pf-v5-c-sidebar__panel.pf-m-width-66 {
  --pf-v5-c-sidebar__panel--FlexBasis: 66%;
}

.pf-v5-c-sidebar__panel.pf-m-width-75 {
  --pf-v5-c-sidebar__panel--FlexBasis: 75%;
}

.pf-v5-c-sidebar__panel.pf-m-width-100 {
  --pf-v5-c-sidebar__panel--FlexBasis: 100%;
}

@media (min-width: 576px) {
  .pf-v5-c-sidebar__panel.pf-m-width-default-on-sm {
    --pf-v5-c-sidebar__panel--FlexBasis: var(--pf-v5-c-sidebar__panel--FlexBasis--base);
  }

  .pf-v5-c-sidebar__panel.pf-m-width-25-on-sm {
    --pf-v5-c-sidebar__panel--FlexBasis: 25%;
  }

  .pf-v5-c-sidebar__panel.pf-m-width-33-on-sm {
    --pf-v5-c-sidebar__panel--FlexBasis: 33%;
  }

  .pf-v5-c-sidebar__panel.pf-m-width-50-on-sm {
    --pf-v5-c-sidebar__panel--FlexBasis: 50%;
  }

  .pf-v5-c-sidebar__panel.pf-m-width-66-on-sm {
    --pf-v5-c-sidebar__panel--FlexBasis: 66%;
  }

  .pf-v5-c-sidebar__panel.pf-m-width-75-on-sm {
    --pf-v5-c-sidebar__panel--FlexBasis: 75%;
  }

  .pf-v5-c-sidebar__panel.pf-m-width-100-on-sm {
    --pf-v5-c-sidebar__panel--FlexBasis: 100%;
  }
}

@media (min-width: 768px) {
  .pf-v5-c-sidebar__panel.pf-m-width-default-on-md {
    --pf-v5-c-sidebar__panel--FlexBasis: var(--pf-v5-c-sidebar__panel--FlexBasis--base);
  }

  .pf-v5-c-sidebar__panel.pf-m-width-25-on-md {
    --pf-v5-c-sidebar__panel--FlexBasis: 25%;
  }

  .pf-v5-c-sidebar__panel.pf-m-width-33-on-md {
    --pf-v5-c-sidebar__panel--FlexBasis: 33%;
  }

  .pf-v5-c-sidebar__panel.pf-m-width-50-on-md {
    --pf-v5-c-sidebar__panel--FlexBasis: 50%;
  }

  .pf-v5-c-sidebar__panel.pf-m-width-66-on-md {
    --pf-v5-c-sidebar__panel--FlexBasis: 66%;
  }

  .pf-v5-c-sidebar__panel.pf-m-width-75-on-md {
    --pf-v5-c-sidebar__panel--FlexBasis: 75%;
  }

  .pf-v5-c-sidebar__panel.pf-m-width-100-on-md {
    --pf-v5-c-sidebar__panel--FlexBasis: 100%;
  }
}

@media (min-width: 992px) {
  .pf-v5-c-sidebar__panel.pf-m-width-default-on-lg {
    --pf-v5-c-sidebar__panel--FlexBasis: var(--pf-v5-c-sidebar__panel--FlexBasis--base);
  }

  .pf-v5-c-sidebar__panel.pf-m-width-25-on-lg {
    --pf-v5-c-sidebar__panel--FlexBasis: 25%;
  }

  .pf-v5-c-sidebar__panel.pf-m-width-33-on-lg {
    --pf-v5-c-sidebar__panel--FlexBasis: 33%;
  }

  .pf-v5-c-sidebar__panel.pf-m-width-50-on-lg {
    --pf-v5-c-sidebar__panel--FlexBasis: 50%;
  }

  .pf-v5-c-sidebar__panel.pf-m-width-66-on-lg {
    --pf-v5-c-sidebar__panel--FlexBasis: 66%;
  }

  .pf-v5-c-sidebar__panel.pf-m-width-75-on-lg {
    --pf-v5-c-sidebar__panel--FlexBasis: 75%;
  }

  .pf-v5-c-sidebar__panel.pf-m-width-100-on-lg {
    --pf-v5-c-sidebar__panel--FlexBasis: 100%;
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-sidebar__panel.pf-m-width-default-on-xl {
    --pf-v5-c-sidebar__panel--FlexBasis: var(--pf-v5-c-sidebar__panel--FlexBasis--base);
  }

  .pf-v5-c-sidebar__panel.pf-m-width-25-on-xl {
    --pf-v5-c-sidebar__panel--FlexBasis: 25%;
  }

  .pf-v5-c-sidebar__panel.pf-m-width-33-on-xl {
    --pf-v5-c-sidebar__panel--FlexBasis: 33%;
  }

  .pf-v5-c-sidebar__panel.pf-m-width-50-on-xl {
    --pf-v5-c-sidebar__panel--FlexBasis: 50%;
  }

  .pf-v5-c-sidebar__panel.pf-m-width-66-on-xl {
    --pf-v5-c-sidebar__panel--FlexBasis: 66%;
  }

  .pf-v5-c-sidebar__panel.pf-m-width-75-on-xl {
    --pf-v5-c-sidebar__panel--FlexBasis: 75%;
  }

  .pf-v5-c-sidebar__panel.pf-m-width-100-on-xl {
    --pf-v5-c-sidebar__panel--FlexBasis: 100%;
  }
}

@media (min-width: 1450px) {
  .pf-v5-c-sidebar__panel.pf-m-width-default-on-2xl {
    --pf-v5-c-sidebar__panel--FlexBasis: var(--pf-v5-c-sidebar__panel--FlexBasis--base);
  }

  .pf-v5-c-sidebar__panel.pf-m-width-25-on-2xl {
    --pf-v5-c-sidebar__panel--FlexBasis: 25%;
  }

  .pf-v5-c-sidebar__panel.pf-m-width-33-on-2xl {
    --pf-v5-c-sidebar__panel--FlexBasis: 33%;
  }

  .pf-v5-c-sidebar__panel.pf-m-width-50-on-2xl {
    --pf-v5-c-sidebar__panel--FlexBasis: 50%;
  }

  .pf-v5-c-sidebar__panel.pf-m-width-66-on-2xl {
    --pf-v5-c-sidebar__panel--FlexBasis: 66%;
  }

  .pf-v5-c-sidebar__panel.pf-m-width-75-on-2xl {
    --pf-v5-c-sidebar__panel--FlexBasis: 75%;
  }

  .pf-v5-c-sidebar__panel.pf-m-width-100-on-2xl {
    --pf-v5-c-sidebar__panel--FlexBasis: 100%;
  }
}

.pf-v5-c-simple-list {
  --pf-v5-c-simple-list__item-link--PaddingTop: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-simple-list__item-link--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-simple-list__item-link--PaddingBottom: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-simple-list__item-link--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-simple-list__item-link--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-simple-list__item-link--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-simple-list__item-link--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-simple-list__item-link--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-simple-list__item-link--m-current--Color: var(--pf-v5-global--link--Color);
  --pf-v5-c-simple-list__item-link--m-current--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-simple-list__item-link--m-current--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-simple-list__item-link--hover--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-simple-list__item-link--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-simple-list__item-link--focus--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-simple-list__item-link--focus--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-simple-list__item-link--focus--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-simple-list__item-link--active--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-simple-list__item-link--active--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-simple-list__item-link--active--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-simple-list__title--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-simple-list__title--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-simple-list__title--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-simple-list__title--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-simple-list__title--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-simple-list__title--Color: var(--pf-v5-global--Color--dark-200);
  --pf-v5-c-simple-list__title--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-simple-list__section--section--MarginTop: var(--pf-v5-global--spacer--sm);
}

.pf-v5-c-simple-list__item-link {
  display: block;
  width: 100%;
  padding-block-start: var(--pf-v5-c-simple-list__item-link--PaddingTop);
  padding-block-end: var(--pf-v5-c-simple-list__item-link--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-simple-list__item-link--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-simple-list__item-link--PaddingRight);
  font-size: var(--pf-v5-c-simple-list__item-link--FontSize);
  font-weight: var(--pf-v5-c-simple-list__item-link--FontWeight);
  color: var(--pf-v5-c-simple-list__item-link--Color);
  text-align: start;
  background-color: var(--pf-v5-c-simple-list__item-link--BackgroundColor);
  border: none;
}

.pf-v5-c-simple-list__item-link:hover {
  --pf-v5-c-simple-list__item-link--BackgroundColor: var(--pf-v5-c-simple-list__item-link--hover--BackgroundColor);
  --pf-v5-c-simple-list__item-link--Color: var(--pf-v5-c-simple-list__item-link--hover--Color);
  text-decoration: none;
}

.pf-v5-c-simple-list__item-link:focus {
  --pf-v5-c-simple-list__item-link--FontWeight: var(--pf-v5-c-simple-list__item-link--focus--FontWeight);
  --pf-v5-c-simple-list__item-link--BackgroundColor: var(--pf-v5-c-simple-list__item-link--focus--BackgroundColor);
  --pf-v5-c-simple-list__item-link--Color: var(--pf-v5-c-simple-list__item-link--focus--Color);
}

.pf-v5-c-simple-list__item-link:active {
  --pf-v5-c-simple-list__item-link--FontWeight: var(--pf-v5-c-simple-list__item-link--active--FontWeight);
  --pf-v5-c-simple-list__item-link--BackgroundColor: var(--pf-v5-c-simple-list__item-link--active--BackgroundColor);
  --pf-v5-c-simple-list__item-link--Color: var(--pf-v5-c-simple-list__item-link--active--Color);
}

.pf-v5-c-simple-list__item-link.pf-m-current {
  --pf-v5-c-simple-list__item-link--FontWeight: var(--pf-v5-c-simple-list__item-link--m-current--FontWeight);
  --pf-v5-c-simple-list__item-link--BackgroundColor: var(--pf-v5-c-simple-list__item-link--m-current--BackgroundColor);
  --pf-v5-c-simple-list__item-link--Color: var(--pf-v5-c-simple-list__item-link--m-current--Color);
}

.pf-v5-c-simple-list__title {
  padding-block-start: var(--pf-v5-c-simple-list__title--PaddingTop);
  padding-block-end: var(--pf-v5-c-simple-list__title--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-simple-list__title--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-simple-list__title--PaddingRight);
  font-size: var(--pf-v5-c-simple-list__title--FontSize);
  font-weight: var(--pf-v5-c-simple-list__title--FontWeight);
  color: var(--pf-v5-c-simple-list__title--Color);
}

.pf-v5-c-simple-list__section+.pf-v5-c-simple-list__section {
  margin-block-start: var(--pf-v5-c-simple-list__section--section--MarginTop);
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-simple-list {
  --pf-v5-c-simple-list__item-link--m-current--Color: var(--pf-v5-global--active-color--100);
  --pf-v5-c-simple-list__item-link--m-current--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-simple-list__item-link--hover--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-simple-list__item-link--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-simple-list__item-link--focus--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-simple-list__item-link--focus--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-simple-list__item-link--active--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-simple-list__item-link--active--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
}

.pf-v5-c-skeleton {
  --pf-v5-c-skeleton--BackgroundColor: var(--pf-v5-global--palette--black-150);
  --pf-v5-c-skeleton--Width: auto;
  --pf-v5-c-skeleton--Height: auto;
  --pf-v5-c-skeleton--BorderRadius: var(--pf-v5-global--BorderRadius--sm);
  --pf-v5-c-skeleton--before--PaddingBottom: 0;
  --pf-v5-c-skeleton--before--Height: auto;
  --pf-v5-c-skeleton--before--Content: " ";
  --pf-v5-c-skeleton--after--LinearGradientAngle: 90deg;
  --pf-v5-c-skeleton--after--LinearGradientColorStop1: var(--pf-v5-global--palette--black-150);
  --pf-v5-c-skeleton--after--LinearGradientColorStop2: #ededed;
  --pf-v5-c-skeleton--after--LinearGradientColorStop3: var(--pf-v5-global--palette--black-150);
  --pf-v5-c-skeleton--after--TranslateX: -100%;
  --pf-v5-c-skeleton--after--AnimationName: pf-v5-c-skeleton-loading;
  --pf-v5-c-skeleton--after--AnimationDuration: 2s;
  --pf-v5-c-skeleton--after--AnimationIterationCount: infinite;
  --pf-v5-c-skeleton--after--AnimationTimingFunction: linear;
  --pf-v5-c-skeleton--after--AnimationDelay: .5s;
  --pf-v5-c-skeleton--m-circle--BorderRadius: var(--pf-v5-global--BorderRadius--lg);
  --pf-v5-c-skeleton--m-circle--before--PaddingBottom: 100%;
  --pf-v5-c-skeleton--m-text-4xl--Height: calc(var(--pf-v5-global--FontSize--4xl) * var(--pf-v5-global--LineHeight--sm));
  --pf-v5-c-skeleton--m-text-3xl--Height: calc(var(--pf-v5-global--FontSize--3xl) * var(--pf-v5-global--LineHeight--sm));
  --pf-v5-c-skeleton--m-text-2xl--Height: calc(var(--pf-v5-global--FontSize--2xl) * var(--pf-v5-global--LineHeight--sm));
  --pf-v5-c-skeleton--m-text-xl--Height: calc(var(--pf-v5-global--FontSize--xl) * var(--pf-v5-global--LineHeight--sm));
  --pf-v5-c-skeleton--m-text-lg--Height: calc(var(--pf-v5-global--FontSize--lg) * var(--pf-v5-global--LineHeight--md));
  --pf-v5-c-skeleton--m-text-md--Height: calc(var(--pf-v5-global--FontSize--md) * var(--pf-v5-global--LineHeight--md));
  --pf-v5-c-skeleton--m-text-sm--Height: calc(var(--pf-v5-global--FontSize--sm) * var(--pf-v5-global--LineHeight--md));
  --pf-v5-c-skeleton--m-width-sm--Width: 6.25rem;
  --pf-v5-c-skeleton--m-width-md--Width: 12.5rem;
  --pf-v5-c-skeleton--m-width-lg--Width: 18.75rem;
  --pf-v5-c-skeleton--m-width-25--Width: 25%;
  --pf-v5-c-skeleton--m-width-33--Width: calc(100% / 3);
  --pf-v5-c-skeleton--m-width-50--Width: 50%;
  --pf-v5-c-skeleton--m-width-66--Width: calc(100% / 3 * 2);
  --pf-v5-c-skeleton--m-width-75--Width: 75%;
  --pf-v5-c-skeleton--m-height-sm--Height: 6.25rem;
  --pf-v5-c-skeleton--m-height-md--Height: 12.5rem;
  --pf-v5-c-skeleton--m-height-lg--Height: 18.75rem;
  --pf-v5-c-skeleton--m-height-25--Height: 25%;
  --pf-v5-c-skeleton--m-height-33--Height: calc(100% / 3);
  --pf-v5-c-skeleton--m-height-50--Height: 50%;
  --pf-v5-c-skeleton--m-height-66--Height: calc(100% / 3 * 2);
  --pf-v5-c-skeleton--m-height-75--Height: 75%;
  --pf-v5-c-skeleton--m-height-100--Height: 100%;
  position: relative;
  width: var(--pf-v5-c-skeleton--Width);
  height: var(--pf-v5-c-skeleton--Height);
  overflow: hidden;
  background-color: var(--pf-v5-c-skeleton--BackgroundColor);
  border-radius: var(--pf-v5-c-skeleton--BorderRadius);
  transform: translate(0);
}

.pf-v5-c-skeleton::before {
  display: block;
  height: var(--pf-v5-c-skeleton--before--Height);
  padding-block-end: var(--pf-v5-c-skeleton--before--PaddingBottom);
  content: var(--pf-v5-c-skeleton--before--Content);
}

.pf-v5-c-skeleton::after {
  position: absolute;
  inset: 0;
  display: block;
  content: "";
  background: linear-gradient(var(--pf-v5-c-skeleton--after--LinearGradientAngle), var(--pf-v5-c-skeleton--after--LinearGradientColorStop1), var(--pf-v5-c-skeleton--after--LinearGradientColorStop2), var(--pf-v5-c-skeleton--after--LinearGradientColorStop3));
  transform: translate3d(var(--pf-v5-c-skeleton--after--TranslateX), 0, 0);
  animation: var(--pf-v5-c-skeleton--after--AnimationName) var(--pf-v5-c-skeleton--after--AnimationDuration) var(--pf-v5-c-skeleton--after--AnimationTimingFunction) var(--pf-v5-c-skeleton--after--AnimationDelay) var(--pf-v5-c-skeleton--after--AnimationIterationCount);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-skeleton::after {
  scale: -1 1;
}

.pf-v5-c-skeleton.pf-m-circle {
  --pf-v5-c-skeleton--BorderRadius: var(--pf-v5-c-skeleton--m-circle--BorderRadius);
}

.pf-v5-c-skeleton.pf-m-square,
.pf-v5-c-skeleton.pf-m-circle {
  --pf-v5-c-skeleton--before--Height: 0;
  --pf-v5-c-skeleton--before--PaddingBottom: var(--pf-v5-c-skeleton--m-circle--before--PaddingBottom);
}

.pf-v5-c-skeleton.pf-m-width-sm {
  --pf-v5-c-skeleton--Width: var(--pf-v5-c-skeleton--m-width-sm--Width);
}

.pf-v5-c-skeleton.pf-m-width-md {
  --pf-v5-c-skeleton--Width: var(--pf-v5-c-skeleton--m-width-md--Width);
}

.pf-v5-c-skeleton.pf-m-width-lg {
  --pf-v5-c-skeleton--Width: var(--pf-v5-c-skeleton--m-width-lg--Width);
}

.pf-v5-c-skeleton.pf-m-width-25 {
  --pf-v5-c-skeleton--Width: var(--pf-v5-c-skeleton--m-width-25--Width);
}

.pf-v5-c-skeleton.pf-m-width-33 {
  --pf-v5-c-skeleton--Width: var(--pf-v5-c-skeleton--m-width-33--Width);
}

.pf-v5-c-skeleton.pf-m-width-50 {
  --pf-v5-c-skeleton--Width: var(--pf-v5-c-skeleton--m-width-50--Width);
}

.pf-v5-c-skeleton.pf-m-width-66 {
  --pf-v5-c-skeleton--Width: var(--pf-v5-c-skeleton--m-width-66--Width);
}

.pf-v5-c-skeleton.pf-m-width-75 {
  --pf-v5-c-skeleton--Width: var(--pf-v5-c-skeleton--m-width-75--Width);
}

.pf-v5-c-skeleton.pf-m-height-sm {
  --pf-v5-c-skeleton--Height: var(--pf-v5-c-skeleton--m-height-sm--Height);
}

.pf-v5-c-skeleton.pf-m-height-md {
  --pf-v5-c-skeleton--Height: var(--pf-v5-c-skeleton--m-height-md--Height);
}

.pf-v5-c-skeleton.pf-m-height-lg {
  --pf-v5-c-skeleton--Height: var(--pf-v5-c-skeleton--m-height-lg--Height);
}

.pf-v5-c-skeleton.pf-m-height-25 {
  --pf-v5-c-skeleton--Height: var(--pf-v5-c-skeleton--m-height-25--Height);
}

.pf-v5-c-skeleton.pf-m-height-33 {
  --pf-v5-c-skeleton--Height: var(--pf-v5-c-skeleton--m-height-33--Height);
}

.pf-v5-c-skeleton.pf-m-height-50 {
  --pf-v5-c-skeleton--Height: var(--pf-v5-c-skeleton--m-height-50--Height);
}

.pf-v5-c-skeleton.pf-m-height-66 {
  --pf-v5-c-skeleton--Height: var(--pf-v5-c-skeleton--m-height-66--Height);
}

.pf-v5-c-skeleton.pf-m-height-75 {
  --pf-v5-c-skeleton--Height: var(--pf-v5-c-skeleton--m-height-75--Height);
}

.pf-v5-c-skeleton.pf-m-height-100 {
  --pf-v5-c-skeleton--Height: var(--pf-v5-c-skeleton--m-height-100--Height);
}

.pf-v5-c-skeleton.pf-m-text-4xl {
  --pf-v5-c-skeleton--Height: var(--pf-v5-c-skeleton--m-text-4xl--Height);
}

.pf-v5-c-skeleton.pf-m-text-3xl {
  --pf-v5-c-skeleton--Height: var(--pf-v5-c-skeleton--m-text-3xl--Height);
}

.pf-v5-c-skeleton.pf-m-text-2xl {
  --pf-v5-c-skeleton--Height: var(--pf-v5-c-skeleton--m-text-2xl--Height);
}

.pf-v5-c-skeleton.pf-m-text-xl {
  --pf-v5-c-skeleton--Height: var(--pf-v5-c-skeleton--m-text-xl--Height);
}

.pf-v5-c-skeleton.pf-m-text-lg {
  --pf-v5-c-skeleton--Height: var(--pf-v5-c-skeleton--m-text-lg--Height);
}

.pf-v5-c-skeleton.pf-m-text-md {
  --pf-v5-c-skeleton--Height: var(--pf-v5-c-skeleton--m-text-md--Height);
}

.pf-v5-c-skeleton.pf-m-text-sm {
  --pf-v5-c-skeleton--Height: var(--pf-v5-c-skeleton--m-text-sm--Height);
}

@keyframes pf-v5-c-skeleton-loading {
  0% {
    transform: translateX(-100%);
  }

  60% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(100%);
  }
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-skeleton {
  --pf-v5-c-skeleton--BackgroundColor: var(--pf-v5-global--palette--black-600);
  --pf-v5-c-skeleton--after--LinearGradientColorStop1: var(--pf-v5-global--palette--black-600);
  --pf-v5-c-skeleton--after--LinearGradientColorStop2: var(--pf-v5-global--palette--black-500);
  --pf-v5-c-skeleton--after--LinearGradientColorStop3: var(--pf-v5-global--palette--black-600);
}

.pf-v5-c-skip-to-content {
  --pf-v5-c-skip-to-content--Top: var(--pf-v5-global--spacer--md);
  --pf-v5-c-skip-to-content--ZIndex: var(--pf-v5-global--ZIndex--2xl);
  --pf-v5-c-skip-to-content--focus--Left: var(--pf-v5-global--spacer--md);
  position: absolute;
  inset-block-start: var(--pf-v5-c-skip-to-content--Top);
  inset-inline-start: -300%;
  z-index: var(--pf-v5-c-skip-to-content--ZIndex);
}

.pf-v5-c-skip-to-content:focus-within {
  inset-inline-start: var(--pf-v5-c-skip-to-content--focus--Left);
}

.pf-v5-c-slider {
  --pf-v5-c-slider--value: 0;
  --pf-v5-c-slider__step--Left: 0;
  --pf-v5-c-slider__rail--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-slider__rail--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-slider__rail-track--Height: 0.25rem;
  --pf-v5-c-slider__rail-track--before--base--BackgroundColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-slider__rail-track--before--fill--BackgroundColor: var(--pf-v5-global--active-color--300);
  --pf-v5-c-slider__rail-track--before--BorderRadius: var(--pf-v5-global--BorderRadius--lg);
  --pf-v5-c-slider__rail-track--before--fill--BackgroundColor--gradient-stop: var(--pf-v5-c-slider--value);
  --pf-v5-c-slider__rail-track--before--fill--direction: right;
  --pf-v5-c-slider__steps--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-slider__steps--Height: var(--pf-v5-c-slider__steps--FontSize);
  --pf-v5-c-slider__step-tick--Top: var(--pf-v5-global--spacer--md);
  --pf-v5-c-slider__step-tick--Width: 0.25rem;
  --pf-v5-c-slider__step-tick--Height: 0.25rem;
  --pf-v5-c-slider__step-tick--BackgroundColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-slider__step-tick--TranslateX: -50%;
  --pf-v5-c-slider__step-tick--BorderRadius: var(--pf-v5-global--BorderRadius--lg);
  --pf-v5-c-slider__step--m-active__slider-tick--BackgroundColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-slider__step--first-child__step-tick--TranslateX: 0;
  --pf-v5-c-slider__step--last-child__step-tick--TranslateX: -100%;
  --pf-v5-c-slider__step-label--TranslateX: -50%;
  --pf-v5-c-slider__step-label--Top: calc(var(--pf-v5-global--spacer--xl) + var(--pf-v5-c-slider__rail-track--Height));
  --pf-v5-c-slider__step--first-child__step-label--TranslateX: 0;
  --pf-v5-c-slider__step--last-child__step-label--TranslateX: -100%;
  --pf-v5-c-slider__thumb--Top: calc(var(--pf-v5-c-slider__rail-track--Height) / 2 + var(--pf-v5-global--spacer--md));
  --pf-v5-c-slider__thumb--Width: 1rem;
  --pf-v5-c-slider__thumb--Height: 1rem;
  --pf-v5-c-slider__thumb--Left: var(--pf-v5-c-slider--value);
  --pf-v5-c-slider__thumb--BackgroundColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-slider__thumb--TranslateX: -50%;
  --pf-v5-c-slider__thumb--TranslateY: -50%;
  --pf-v5-c-slider__thumb--BorderRadius: var(--pf-v5-global--BorderRadius--lg);
  --pf-v5-c-slider__thumb--BoxShadow--base: 0 0 0 2px var(--pf-v5-global--BackgroundColor--100), 0 0 0 3px var(--pf-v5-global--primary-color--100);
  --pf-v5-c-slider__thumb--hover--BoxShadow: var(--pf-v5-c-slider__thumb--BoxShadow--base);
  --pf-v5-c-slider__thumb--focus--BoxShadow: var(--pf-v5-c-slider__thumb--BoxShadow--base);
  --pf-v5-c-slider__thumb--active--BoxShadow: var(--pf-v5-c-slider__thumb--BoxShadow--base), 0 0 2px 5px var(--pf-v5-global--active-color--200);
  --pf-v5-c-slider__thumb--before--Width: var(--pf-v5-global--target-size--MinWidth);
  --pf-v5-c-slider__thumb--before--Height: var(--pf-v5-global--target-size--MinHeight);
  --pf-v5-c-slider__thumb--before--BorderRadius: var(--pf-v5-global--BorderRadius--lg);
  --pf-v5-c-slider__thumb--before--TranslateX: -50%;
  --pf-v5-c-slider__thumb--before--TranslateY: -50%;
  --pf-v5-c-slider__value--MarginLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-slider__value--c-form-control--width-base: 3.5ch;
  --pf-v5-c-slider__value--c-form-control--width-chars: 3;
  --pf-v5-c-slider__value--c-form-control--Width: calc(var(--pf-v5-c-slider__value--c-form-control--width-base) + (var(--pf-v5-c-slider__value--c-form-control--width-chars) * 1ch));
  --pf-v5-c-slider__value--m-floating--TranslateX: -50%;
  --pf-v5-c-slider__value--m-floating--TranslateY: -100%;
  --pf-v5-c-slider__value--m-floating--Left: var(--pf-v5-c-slider--value);
  --pf-v5-c-slider__value--m-floating--ZIndex: var(--pf-v5-global--ZIndex--sm);
  --pf-v5-c-slider__actions--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-slider__main--actions--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-slider--m-disabled__rail-track--before--fill--BackgroundColor: var(--pf-v5-global--palette--black-500);
  --pf-v5-c-slider--m-disabled__step--m-active__slider-tick--BackgroundColor: var(--pf-v5-global--palette--black-700);
  --pf-v5-c-slider--m-disabled__thumb--BackgroundColor: var(--pf-v5-global--palette--black-700);
  display: flex;
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-slider {
  --pf-v5-c-slider__rail-track--before--fill--direction: left;
}

.pf-v5-c-slider.pf-m-disabled {
  --pf-v5-c-slider__rail-track--before--fill--BackgroundColor: var(--pf-v5-c-slider--m-disabled__rail-track--before--fill--BackgroundColor);
  --pf-v5-c-slider__step--m-active__slider-tick--BackgroundColor: var(--pf-v5-c-slider--m-disabled__step--m-active__slider-tick--BackgroundColor);
  --pf-v5-c-slider__thumb--BackgroundColor: var(--pf-v5-c-slider--m-disabled__thumb--BackgroundColor);
}

.pf-v5-c-slider.pf-m-disabled .pf-v5-c-slider__rail,
.pf-v5-c-slider.pf-m-disabled .pf-v5-c-slider__thumb {
  cursor: not-allowed;
}

.pf-v5-c-slider.pf-m-disabled .pf-v5-c-slider__thumb {
  --pf-v5-c-slider__thumb--BoxShadow: none;
}

.pf-v5-c-slider__main {
  position: relative;
  flex-grow: 1;
}

.pf-v5-c-slider__rail {
  padding-block-start: var(--pf-v5-c-slider__rail--PaddingTop);
  padding-block-end: var(--pf-v5-c-slider__rail--PaddingBottom);
}

.pf-v5-c-slider__rail-track {
  position: relative;
  height: var(--pf-v5-c-slider__rail-track--Height);
}

.pf-v5-c-slider__rail-track::before {
  position: absolute;
  inset: 0;
  content: "";
  background: linear-gradient(to var(--pf-v5-c-slider__rail-track--before--fill--direction), var(--pf-v5-c-slider__rail-track--before--fill--BackgroundColor), var(--pf-v5-c-slider__rail-track--before--fill--BackgroundColor) var(--pf-v5-c-slider__rail-track--before--fill--BackgroundColor--gradient-stop), var(--pf-v5-c-slider__rail-track--before--base--BackgroundColor) var(--pf-v5-c-slider__rail-track--before--fill--BackgroundColor--gradient-stop));
  border-radius: var(--pf-v5-c-slider__rail-track--before--BorderRadius);
}

.pf-v5-c-slider__steps {
  height: var(--pf-v5-c-slider__steps--Height);
  font-size: var(--pf-v5-c-slider__steps--FontSize);
  line-height: 1;
}

.pf-v5-c-slider__step {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: var(--pf-v5-c-slider__step--Left);
  content: "";
}

.pf-v5-c-slider__step.pf-m-active {
  --pf-v5-c-slider__step-tick--BackgroundColor: var(--pf-v5-c-slider__step--m-active__slider-tick--BackgroundColor);
}

.pf-v5-c-slider__step:first-child {
  --pf-v5-c-slider__step-tick--TranslateX: var(--pf-v5-c-slider__step--first-child__step-tick--TranslateX);
  --pf-v5-c-slider__step-label--TranslateX: var(--pf-v5-c-slider__step--first-child__step-label--TranslateX);
}

.pf-v5-c-slider__step:last-child {
  --pf-v5-c-slider__step-tick--TranslateX: var(--pf-v5-c-slider__step--last-child__step-tick--TranslateX);
  --pf-v5-c-slider__step-label--TranslateX: var(--pf-v5-c-slider__step--last-child__step-label--TranslateX);
}

.pf-v5-c-slider__step-tick {
  transform: translateX(var(--pf-v5-c-slider__step-tick--TranslateX));
  position: absolute;
  inset-block-start: var(--pf-v5-c-slider__step-tick--Top);
  inset-inline-start: 0;
  width: var(--pf-v5-c-slider__step-tick--Width);
  height: var(--pf-v5-c-slider__step-tick--Height);
  background-color: var(--pf-v5-c-slider__step-tick--BackgroundColor);
  border-radius: var(--pf-v5-c-slider__step-tick--BorderRadius);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-slider__step-tick {
  transform: translateX(calc(var(--pf-v5-c-slider__step-tick--TranslateX) * var(--pf-v5-global--inverse--multiplier)));
}

.pf-v5-c-slider__step-label {
  transform: translateX(var(--pf-v5-c-slider__step-label--TranslateX));
  position: absolute;
  inset-block-start: var(--pf-v5-c-slider__step-label--Top);
  word-break: normal;
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-slider__step-label {
  transform: translateX(calc(var(--pf-v5-c-slider__step-label--TranslateX) * var(--pf-v5-global--inverse--multiplier)));
}

.pf-v5-c-slider__thumb {
  transform: translate(var(--pf-v5-c-slider__thumb--TranslateX), var(--pf-v5-c-slider__thumb--TranslateY));
  position: absolute;
  inset-block-start: var(--pf-v5-c-slider__thumb--Top);
  inset-inline-start: var(--pf-v5-c-slider__thumb--Left);
  width: var(--pf-v5-c-slider__thumb--Width);
  height: var(--pf-v5-c-slider__thumb--Height);
  cursor: pointer;
  background-color: var(--pf-v5-c-slider__thumb--BackgroundColor);
  border-radius: var(--pf-v5-c-slider__thumb--BorderRadius);
  box-shadow: var(--pf-v5-c-slider__thumb--BoxShadow);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-slider__thumb {
  transform: translate(calc(var(--pf-v5-c-slider__thumb--TranslateX) * var(--pf-v5-global--inverse--multiplier)), var(--pf-v5-c-slider__thumb--TranslateY));
}

.pf-v5-c-slider__thumb::before {
  transform: translate(var(--pf-v5-c-slider__thumb--before--TranslateX), var(--pf-v5-c-slider__thumb--before--TranslateY));
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 50%;
  width: var(--pf-v5-c-slider__thumb--before--Width);
  height: var(--pf-v5-c-slider__thumb--before--Height);
  content: "";
  border-radius: var(--pf-v5-c-slider__thumb--before--BorderRadius);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-slider__thumb::before {
  transform: translate(calc(var(--pf-v5-c-slider__thumb--before--TranslateX) * var(--pf-v5-global--inverse--multiplier)), var(--pf-v5-c-slider__thumb--before--TranslateY));
}

.pf-v5-c-slider__thumb:hover {
  --pf-v5-c-slider__thumb--BoxShadow: var(--pf-v5-c-slider__thumb--hover--BoxShadow);
}

.pf-v5-c-slider__thumb:focus {
  --pf-v5-c-slider__thumb--BoxShadow: var(--pf-v5-c-slider__thumb--focus--BoxShadow);
  outline: 0;
}

.pf-v5-c-slider__thumb:active {
  --pf-v5-c-slider__thumb--BoxShadow: var(--pf-v5-c-slider__thumb--active--BoxShadow);
}

.pf-v5-c-slider__value {
  margin-inline-start: var(--pf-v5-c-slider__value--MarginLeft);
}

.pf-v5-c-slider__value.pf-m-floating {
  transform: translate(var(--pf-v5-c-slider__value--m-floating--TranslateX), var(--pf-v5-c-slider__value--m-floating--TranslateY));
  --pf-v5-c-slider__value--MarginLeft: 0;
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: var(--pf-v5-c-slider__value--m-floating--Left);
  z-index: var(--pf-v5-c-slider__value--m-floating--ZIndex);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-slider__value.pf-m-floating {
  transform: translate(calc(var(--pf-v5-c-slider__value--m-floating--TranslateX) * var(--pf-v5-global--inverse--multiplier)), var(--pf-v5-c-slider__value--m-floating--TranslateY));
}

.pf-v5-c-slider__value .pf-v5-c-form-control {
  width: var(--pf-v5-c-slider__value--c-form-control--Width);
}

.pf-v5-c-slider__actions {
  display: flex;
  margin-inline-end: var(--pf-v5-c-slider__actions--MarginRight);
}

.pf-v5-c-slider__main~.pf-v5-c-slider__actions {
  --pf-v5-c-slider__actions--MarginRight: 0;
  margin-inline-start: var(--pf-v5-c-slider__main--actions--MarginLeft);
}

.pf-v5-c-spinner {
  --pf-v5-c-spinner--diameter: var(--pf-v5-global--icon--FontSize--xl);
  --pf-v5-c-spinner--Width: var(--pf-v5-c-spinner--diameter);
  --pf-v5-c-spinner--Height: var(--pf-v5-c-spinner--diameter);
  --pf-v5-c-spinner--Color: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-spinner--m-sm--diameter: var(--pf-v5-global--icon--FontSize--sm);
  --pf-v5-c-spinner--m-md--diameter: var(--pf-v5-global--icon--FontSize--md);
  --pf-v5-c-spinner--m-lg--diameter: var(--pf-v5-global--icon--FontSize--lg);
  --pf-v5-c-spinner--m-xl--diameter: var(--pf-v5-global--icon--FontSize--xl);
  --pf-v5-c-spinner--m-inline--diameter: 1em;
  width: var(--pf-v5-c-spinner--Width);
  height: var(--pf-v5-c-spinner--Height);
  overflow: hidden;
}

.pf-v5-c-spinner.pf-m-inline {
  --pf-v5-c-spinner--diameter: var(--pf-v5-c-spinner--m-inline--diameter);
}

.pf-v5-c-spinner.pf-m-sm {
  --pf-v5-c-spinner--diameter: var(--pf-v5-c-spinner--m-sm--diameter);
}

.pf-v5-c-spinner.pf-m-md {
  --pf-v5-c-spinner--diameter: var(--pf-v5-c-spinner--m-md--diameter);
}

.pf-v5-c-spinner.pf-m-lg {
  --pf-v5-c-spinner--diameter: var(--pf-v5-c-spinner--m-lg--diameter);
}

.pf-v5-c-spinner.pf-m-xl {
  --pf-v5-c-spinner--diameter: var(--pf-v5-c-spinner--m-xl--diameter);
}

svg.pf-v5-c-spinner {
  --pf-v5-c-spinner--diameter: var(--pf-v5-global--icon--FontSize--xl);
  --pf-v5-c-spinner--AnimationDuration: 1.4s;
  --pf-v5-c-spinner--AnimationTimingFunction: linear;
  --pf-v5-c-spinner--stroke-width: 10;
  --pf-v5-c-spinner__path--Stroke: var(--pf-v5-c-spinner--Color);
  --pf-v5-c-spinner__path--StrokeWidth: var(--pf-v5-c-spinner--stroke-width);
  --pf-v5-c-spinner__path--AnimationTimingFunction: ease-in-out;
  --pf-v5-c-spinner--m-sm--diameter: var(--pf-v5-global--icon--FontSize--sm);
  --pf-v5-c-spinner--m-md--diameter: var(--pf-v5-global--icon--FontSize--md);
  --pf-v5-c-spinner--m-lg--diameter: var(--pf-v5-global--icon--FontSize--lg);
  --pf-v5-c-spinner--m-xl--diameter: var(--pf-v5-global--icon--FontSize--xl);
  animation: pf-v5-c-spinner-animation-rotate calc(var(--pf-v5-c-spinner--AnimationDuration)*2) var(--pf-v5-c-spinner--AnimationTimingFunction) infinite;
}

svg.pf-v5-c-spinner.pf-m-sm {
  --pf-v5-c-spinner--diameter: var(--pf-v5-c-spinner--m-sm--diameter);
}

svg.pf-v5-c-spinner.pf-m-md {
  --pf-v5-c-spinner--diameter: var(--pf-v5-c-spinner--m-md--diameter);
}

svg.pf-v5-c-spinner.pf-m-lg {
  --pf-v5-c-spinner--diameter: var(--pf-v5-c-spinner--m-lg--diameter);
}

svg.pf-v5-c-spinner.pf-m-xl {
  --pf-v5-c-spinner--diameter: var(--pf-v5-c-spinner--m-xl--diameter);
}

.pf-v5-c-spinner__path {
  width: 100%;
  height: 100%;
  stroke: var(--pf-v5-c-spinner--Color);
  stroke-dasharray: 283;
  stroke-dashoffset: 280;
  stroke-linecap: round;
  stroke-width: var(--pf-v5-c-spinner--stroke-width);
  transform-origin: 50% 50%;
  animation: pf-v5-c-spinner-animation-dash var(--pf-v5-c-spinner--AnimationDuration) var(--pf-v5-c-spinner__path--AnimationTimingFunction) infinite;
}

@keyframes pf-v5-c-spinner-animation-rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes pf-v5-c-spinner-animation-dash {
  0% {
    stroke-dashoffset: 280;
    transform: rotate(0);
  }

  15% {
    stroke-width: calc(var(--pf-v5-c-spinner__path--StrokeWidth) - 4);
  }

  40% {
    stroke-dasharray: 220;
    stroke-dashoffset: 150;
  }

  100% {
    stroke-dashoffset: 280;
    transform: rotate(720deg);
  }
}

.pf-v5-c-switch {
  --pf-v5-c-switch--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-switch__label--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-switch--ColumnGap: var(--pf-v5-c-switch__label--PaddingLeft);
  --pf-v5-c-switch__toggle-icon--FontSize: calc(var(--pf-v5-c-switch--FontSize) * .625);
  --pf-v5-c-switch__toggle-icon--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-switch__toggle-icon--Left: calc(var(--pf-v5-c-switch--FontSize) * .4);
  --pf-v5-c-switch__toggle-icon--Offset: 0.125rem;
  --pf-v5-c-switch__input--disabled__toggle-icon--Color: var(--pf-v5-global--palette--black-150);
  --pf-v5-c-switch--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-switch--Height: auto;
  --pf-v5-c-switch__input--checked__toggle--BackgroundColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-switch__input--checked__toggle--before--TranslateX: calc(100% + var(--pf-v5-c-switch__toggle-icon--Offset));
  --pf-v5-c-switch__input--checked__label--Color: var(--pf-v5-global--Color--dark-100);
  --pf-v5-c-switch__input--not-checked__label--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-switch__input--disabled__label--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-switch__input--disabled__toggle--BackgroundColor: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-switch__input--disabled__toggle--before--BackgroundColor: var(--pf-v5-global--palette--black-150);
  --pf-v5-c-switch__input--focus__toggle--OutlineWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-switch__input--focus__toggle--OutlineOffset: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-switch__input--focus__toggle--OutlineColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-switch__toggle--Height: calc(var(--pf-v5-c-switch--FontSize) * var(--pf-v5-c-switch--LineHeight));
  --pf-v5-c-switch__toggle--BackgroundColor: var(--pf-v5-global--palette--black-500);
  --pf-v5-c-switch__toggle--BorderRadius: var(--pf-v5-c-switch__toggle--Height);
  --pf-v5-c-switch__toggle--before--Width: calc(var(--pf-v5-c-switch--FontSize) - var(--pf-v5-c-switch__toggle-icon--Offset));
  --pf-v5-c-switch__toggle--before--Height: var(--pf-v5-c-switch__toggle--before--Width);
  --pf-v5-c-switch__toggle--before--Top: calc((var(--pf-v5-c-switch__toggle--Height) - var(--pf-v5-c-switch__toggle--before--Height)) / 2);
  --pf-v5-c-switch__toggle--before--Left: var(--pf-v5-c-switch__toggle--before--Top);
  --pf-v5-c-switch__toggle--before--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-switch__toggle--before--BorderRadius: var(--pf-v5-global--BorderRadius--lg);
  --pf-v5-c-switch__toggle--before--BoxShadow: var(--pf-v5-global--BoxShadow--md);
  --pf-v5-c-switch__toggle--before--Transition: transform .25s ease 0s;
  --pf-v5-c-switch__toggle--Width: calc(var(--pf-v5-c-switch__toggle--Height) + var(--pf-v5-c-switch__toggle-icon--Offset) + var(--pf-v5-c-switch__toggle--before--Width));
  --pf-v5-c-switch__label--Color: var(--pf-v5-global--Color--dark-100);
  position: relative;
  display: inline-grid;
  grid-template-columns: auto;
  grid-auto-columns: 1fr;
  column-gap: var(--pf-v5-c-switch--ColumnGap);
  height: var(--pf-v5-c-switch--Height);
  font-size: var(--pf-v5-c-switch--FontSize);
  line-height: var(--pf-v5-c-switch--LineHeight);
  vertical-align: middle;
  cursor: pointer;
}

.pf-v5-c-switch.pf-m-reverse .pf-v5-c-switch__label,
.pf-v5-c-switch.pf-m-reverse .pf-v5-c-switch__toggle {
  grid-row: 1;
}

.pf-v5-c-switch.pf-m-reverse .pf-v5-c-switch__label {
  grid-column: 1;
}

.pf-v5-c-switch__input {
  position: absolute;
  cursor: pointer;
  opacity: 0;
}

.pf-v5-c-switch__input:focus-visible~.pf-v5-c-switch__toggle {
  outline: var(--pf-v5-c-switch__input--focus__toggle--OutlineWidth) solid var(--pf-v5-c-switch__input--focus__toggle--OutlineColor);
  outline-offset: var(--pf-v5-c-switch__input--focus__toggle--OutlineOffset);
}

.pf-v5-c-switch__input:checked~.pf-v5-c-switch__label {
  color: var(--pf-v5-c-switch__input--checked__label--Color);
}

.pf-v5-c-switch__input:checked~.pf-v5-c-switch__toggle {
  background-color: var(--pf-v5-c-switch__input--checked__toggle--BackgroundColor);
}

.pf-v5-c-switch__input:checked~.pf-v5-c-switch__toggle::before {
  transform: translateX(var(--pf-v5-c-switch__input--checked__toggle--before--TranslateX));
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-switch__input:checked~.pf-v5-c-switch__toggle::before {
  transform: translateX(calc(var(--pf-v5-c-switch__input--checked__toggle--before--TranslateX) * var(--pf-v5-global--inverse--multiplier)));
}

.pf-v5-c-switch__input:checked~.pf-m-off {
  display: none;
}

.pf-v5-c-switch__input:not(:checked)~.pf-v5-c-switch__label {
  color: var(--pf-v5-c-switch__input--not-checked__label--Color);
}

.pf-v5-c-switch__input:not(:checked)~.pf-v5-c-switch__toggle .pf-v5-c-switch__toggle-icon {
  display: none;
}

.pf-v5-c-switch__input:not(:checked)~.pf-m-on {
  display: none;
}

.pf-v5-c-switch__input:disabled {
  cursor: not-allowed;
}

.pf-v5-c-switch__input:disabled~.pf-v5-c-switch__label {
  color: var(--pf-v5-c-switch__input--disabled__label--Color);
  cursor: not-allowed;
}

.pf-v5-c-switch__input:disabled~.pf-v5-c-switch__toggle {
  --pf-v5-c-switch__toggle-icon--Color: var(--pf-v5-c-switch__input--disabled__toggle-icon--Color);
  cursor: not-allowed;
  background-color: var(--pf-v5-c-switch__input--disabled__toggle--BackgroundColor);
}

.pf-v5-c-switch__input:disabled~.pf-v5-c-switch__toggle::before {
  background-color: var(--pf-v5-c-switch__input--disabled__toggle--before--BackgroundColor);
}

.pf-v5-c-switch__toggle {
  position: relative;
  display: inline-block;
  width: var(--pf-v5-c-switch__toggle--Width);
  height: var(--pf-v5-c-switch__toggle--Height);
  background-color: var(--pf-v5-c-switch__toggle--BackgroundColor);
  border-radius: var(--pf-v5-c-switch__toggle--BorderRadius);
}

.pf-v5-c-switch__toggle::before {
  position: absolute;
  inset-block-start: var(--pf-v5-c-switch__toggle--before--Top);
  inset-inline-start: var(--pf-v5-c-switch__toggle--before--Left);
  display: block;
  width: var(--pf-v5-c-switch__toggle--before--Width);
  height: var(--pf-v5-c-switch__toggle--before--Height);
  content: "";
  background-color: var(--pf-v5-c-switch__toggle--before--BackgroundColor);
  border-radius: var(--pf-v5-c-switch__toggle--before--BorderRadius);
  box-shadow: var(--pf-v5-c-switch__toggle--before--BoxShadow);
  transition: var(--pf-v5-c-switch__toggle--before--Transition);
}

.pf-v5-c-switch__toggle-icon {
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: var(--pf-v5-c-switch__toggle-icon--Left);
  display: flex;
  align-items: center;
  font-size: var(--pf-v5-c-switch__toggle-icon--FontSize);
  color: var(--pf-v5-c-switch__toggle-icon--Color);
}

.pf-v5-c-switch__label {
  display: inline-block;
  grid-column: 2;
  color: var(--pf-v5-c-switch__label--Color);
  vertical-align: top;
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-switch {
  --pf-v5-c-switch__toggle-icon--Color: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-switch__input--not-checked__label--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-switch__input--disabled__toggle--before--BackgroundColor: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-switch__toggle--before--BoxShadow: none;
  --pf-v5-c-switch__input--disabled__toggle-icon--Color: var(--pf-v5-global--disabled-color--100);
}

.pf-v5-c-tab-content {
  --pf-v5-c-tab-content__body--PaddingTop: 0;
  --pf-v5-c-tab-content__body--PaddingRight: 0;
  --pf-v5-c-tab-content__body--PaddingBottom: 0;
  --pf-v5-c-tab-content__body--PaddingLeft: 0;
  --pf-v5-c-tab-content__body--m-padding--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-tab-content__body--m-padding--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-tab-content__body--m-padding--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-tab-content__body--m-padding--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-tab-content__body--m-padding--xl--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-tab-content__body--m-padding--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-tab-content__body--m-padding--xl--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-tab-content__body--m-padding--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-tab-content--m-light-300: var(--pf-v5-global--BackgroundColor--light-300);
}

@media screen and (min-width: 1200px) {
  .pf-v5-c-tab-content {
    --pf-v5-c-tab-content__body--m-padding--PaddingTop: var(--pf-v5-c-tab-content__body--m-padding--xl--PaddingTop);
    --pf-v5-c-tab-content__body--m-padding--PaddingRight: var(--pf-v5-c-tab-content__body--m-padding--xl--PaddingRight);
    --pf-v5-c-tab-content__body--m-padding--PaddingBottom: var(--pf-v5-c-tab-content__body--m-padding--xl--PaddingBottom);
    --pf-v5-c-tab-content__body--m-padding--PaddingLeft: var(--pf-v5-c-tab-content__body--m-padding--xl--PaddingLeft);
  }
}

.pf-v5-c-tab-content.pf-m-light-300 {
  background-color: var(--pf-v5-c-tab-content--m-light-300);
}

.pf-v5-c-tab-content__body {
  padding-block-start: var(--pf-v5-c-tab-content__body--PaddingTop);
  padding-block-end: var(--pf-v5-c-tab-content__body--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-tab-content__body--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-tab-content__body--PaddingRight);
}

.pf-v5-c-tab-content__body.pf-m-padding {
  --pf-v5-c-tab-content__body--PaddingTop: var(--pf-v5-c-tab-content__body--m-padding--PaddingTop);
  --pf-v5-c-tab-content__body--PaddingRight: var(--pf-v5-c-tab-content__body--m-padding--PaddingRight);
  --pf-v5-c-tab-content__body--PaddingBottom: var(--pf-v5-c-tab-content__body--m-padding--PaddingBottom);
  --pf-v5-c-tab-content__body--PaddingLeft: var(--pf-v5-c-tab-content__body--m-padding--PaddingLeft);
}

.pf-v5-c-table[class*=pf-m-grid] {
  --pf-v5-c-table--responsive--BorderColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-table__tbody--responsive--border-width--base: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table__tbody--after--border-width--base: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-table__tbody--after--BorderLeftWidth: 0;
  --pf-v5-c-table__tbody--after--BorderColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-table__tr--responsive--border-width--base: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table__tr--responsive--last-child--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-table__tr--responsive--GridColumnGap: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table__tr--responsive--MarginTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table__tr--responsive--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table__tr--responsive--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table__tr--responsive--xl--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table__tr--responsive--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table__tr--responsive--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table__tr--responsive--xl--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table__tr--responsive--nested-table--PaddingTop: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-table__tr--responsive--nested-table--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table__tr--responsive--nested-table--PaddingBottom: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-table__tr--responsive--nested-table--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table__tbody--after__tr--BorderLeftWidth: 0;
  --pf-v5-c-table__tbody--after__tr--BorderLeftColor: transparent;
  --pf-v5-c-table__tbody--m-expanded--after__tr--BorderLeftWidth: var(--pf-v5-c-table__expandable-row--after--border-width--base);
  --pf-v5-c-table__tbody--m-expanded--after__tr--BorderLeftColor: var(--pf-v5-global--active-color--400);
  --pf-v5-c-table__tbody--m-selected--after__tr--BorderLeftWidth: var(--pf-v5-c-table__expandable-row--after--border-width--base);
  --pf-v5-c-table__tbody--m-selected--after__tr--BorderLeftColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-table--m-grid--cell--hidden-visible--Display: grid;
  --pf-v5-c-table--m-grid--cell--PaddingTop: 0;
  --pf-v5-c-table--m-grid--cell--PaddingRight: 0;
  --pf-v5-c-table--m-grid--cell--PaddingBottom: 0;
  --pf-v5-c-table--m-grid--cell--PaddingLeft: 0;
  --pf-v5-c-table-td--responsive--GridColumnGap: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table--cell--responsive--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table--cell--responsive--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table--cell--first-child--responsive--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table--cell--responsive--PaddingRight: 0;
  --pf-v5-c-table--cell--responsive--PaddingLeft: 0;
  --pf-v5-c-table--m-compact__tr--responsive--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table--m-compact__tr--responsive--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table--m-compact__tr__td--responsive--PaddingTop: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-table--m-compact__tr__td--responsive--PaddingBottom: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-table--m-compact__check--responsive--MarginTop: 0.4375rem;
  --pf-v5-c-table--m-compact__action--responsive--MarginTop: calc(var(--pf-v5-global--spacer--xs) * -1);
  --pf-v5-c-table--m-compact__action--responsive--MarginBottom: calc(var(--pf-v5-global--spacer--xs) * -1);
  --pf-v5-c-table--m-compact__toggle--c-button--responsive--MarginBottom: calc(0.375rem * -1);
  --pf-v5-c-table__expandable-row-content--responsive--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table__expandable-row-content--responsive--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table__expandable-row-content--responsive--xl--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table__expandable-row-content--responsive--xl--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table__expandable-row-content--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-table__check--responsive--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table__check--responsive--MarginTop: 0.875rem;
  --pf-v5-c-table--m-grid__favorite--MarginTop: 0.5rem;
  --pf-v5-c-table--m-grid__check--favorite--MarginLeft: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-table--m-grid__action--MarginTop: 0.375rem;
  --pf-v5-c-table__action--responsive--MarginLeft: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-table--m-grid__favorite--action--MarginLeft: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-table--m-grid__check--favorite--action--MarginLeft: calc(var(--pf-v5-c-table--m-grid__check--favorite--MarginLeft) + var(--pf-v5-c-table--m-grid__favorite--action--MarginLeft));
  --pf-v5-c-table__toggle__icon--Transition: .2s ease-in 0s;
  --pf-v5-c-table__toggle--m-expanded__icon--Rotate: 180deg;
}

@media screen and (max-width: 1200px) {
  .pf-v5-c-table[class*=pf-m-grid] {
    --pf-v5-c-table__tr--responsive--PaddingRight: var(--pf-v5-c-table__tr--responsive--xl--PaddingRight);
    --pf-v5-c-table__tr--responsive--PaddingLeft: var(--pf-v5-c-table__tr--responsive--xl--PaddingLeft);
  }
}

@media screen and (max-width: 1200px) {
  .pf-v5-c-table[class*=pf-m-grid] {
    --pf-v5-c-table__expandable-row-content--responsive--PaddingRight: var(--pf-v5-c-table__expandable-row-content--responsive--xl--PaddingRight);
    --pf-v5-c-table__expandable-row-content--responsive--PaddingLeft: var(--pf-v5-c-table__expandable-row-content--responsive--xl--PaddingLeft);
  }
}

.pf-m-grid.pf-v5-c-table {
  --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-grid--cell--PaddingTop);
  --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table--m-grid--cell--PaddingRight);
  --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-grid--cell--PaddingBottom);
  --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table--m-grid--cell--PaddingLeft);
  --pf-v5-c-table__favorite--c-button--MarginTop: auto;
  --pf-v5-c-table__favorite--c-button--MarginRight: auto;
  --pf-v5-c-table__favorite--c-button--MarginBottom: auto;
  --pf-v5-c-table__favorite--c-button--MarginLeft: auto;
  display: grid;
  border: none;
}

.pf-m-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr)>* {
  width: auto;
  min-width: 0;
  max-width: none;
  overflow: visible;
  text-overflow: clip;
  white-space: normal;
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__text {
  position: relative;
  width: auto;
  min-width: 0;
  max-width: none;
  overflow: var(--pf-v5-c-table--cell--Overflow);
  text-overflow: var(--pf-v5-c-table--cell--TextOverflow);
  white-space: var(--pf-v5-c-table--cell--WhiteSpace);
}

.pf-m-grid.pf-v5-c-table thead:where(.pf-v5-c-table__thead) {
  display: none;
}

.pf-m-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) {
  display: block;
}

.pf-m-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody):first-of-type {
  border-block-start: var(--pf-v5-c-table__tbody--responsive--border-width--base) solid var(--pf-v5-c-table--responsive--BorderColor);
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table.pf-m-compact>tbody:where(.pf-v5-c-table__tbody) {
  border-block-start: 0;
}

.pf-m-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
  border-block-end: var(--pf-v5-c-table__tr--responsive--border-width--base) solid var(--pf-v5-c-table--responsive--BorderColor);
}

.pf-m-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr):last-child,
.pf-m-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody):last-of-type:not(:only-of-type)>tr:where(.pf-v5-c-table__tr) {
  border-block-end-width: var(--pf-v5-c-table__tr--responsive--last-child--BorderBottomWidth);
}

.pf-m-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded {
  border-block-end: var(--pf-v5-c-table--border-width--base) solid var(--pf-v5-c-table--BorderColor);
}

.pf-m-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
  border-block-end: 0;
}

.pf-m-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded:not(:last-of-type) {
  border-block-end: var(--pf-v5-c-table__tbody--responsive--border-width--base) solid var(--pf-v5-c-table--responsive--BorderColor);
}

.pf-m-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-selected {
  --pf-v5-c-table__expandable-row--after--BorderLeftWidth: 0;
  --pf-v5-c-table__expandable-row--after--BorderColor: transparent;
}

.pf-m-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
  display: grid;
  grid-template-columns: 1fr;
  height: auto;
  grid-auto-columns: max-content;
  grid-column-gap: var(--pf-v5-c-table__tr--responsive--GridColumnGap);
  padding-block-start: var(--pf-v5-c-table__tr--responsive--PaddingTop);
  padding-inline-end: var(--pf-v5-c-table__tr--responsive--PaddingRight);
  padding-block-end: var(--pf-v5-c-table__tr--responsive--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-table__tr--responsive--PaddingLeft);
}

.pf-m-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row)>* {
  padding-block-start: var(--pf-v5-c-table--cell--responsive--PaddingTop);
  padding-inline-end: var(--pf-v5-c-table--cell--responsive--PaddingRight);
  padding-block-end: var(--pf-v5-c-table--cell--responsive--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-table--cell--responsive--PaddingLeft);
}

.pf-m-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row)>*:first-child {
  --pf-v5-c-table--cell--responsive--PaddingTop: var(--pf-v5-c-table--cell--first-child--responsive--PaddingTop);
}

.pf-m-grid.pf-v5-c-table.pf-m-compact {
  --pf-v5-c-table__tr--responsive--PaddingTop: var(--pf-v5-c-table--m-compact__tr--responsive--PaddingTop);
  --pf-v5-c-table__tr--responsive--PaddingBottom: var(--pf-v5-c-table--m-compact__tr--responsive--PaddingBottom);
  --pf-v5-c-table--cell--responsive--PaddingTop: var(--pf-v5-c-table--m-compact__tr__td--responsive--PaddingTop);
  --pf-v5-c-table--cell--responsive--PaddingBottom: var(--pf-v5-c-table--m-compact__tr__td--responsive--PaddingBottom);
  --pf-v5-c-table__check--responsive--MarginTop: var(--pf-v5-c-table--m-compact__check--responsive--MarginTop);
  --pf-v5-c-table__check--input--MarginTop: 0;
}

.pf-m-grid.pf-v5-c-table.pf-m-compact .pf-v5-c-table__action {
  margin-block-start: var(--pf-v5-c-table--m-compact__action--responsive--MarginTop);
  margin-block-end: var(--pf-v5-c-table--m-compact__action--responsive--MarginTop);
}

.pf-m-grid.pf-v5-c-table.pf-m-compact .pf-v5-c-table__toggle .pf-v5-c-button {
  margin-block-end: var(--pf-v5-c-table--m-compact__toggle--c-button--responsive--MarginBottom);
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__icon>* {
  text-align: start;
}

.pf-m-grid.pf-v5-c-table :where(.pf-v5-c-table__th, .pf-v5-c-table__td)[data-label] {
  --pf-v5-c-table--cell--hidden-visible--Display: var(--pf-v5-c-table--m-grid--cell--hidden-visible--Display);
  grid-column: 1;
  grid-column-gap: var(--pf-v5-c-table-td--responsive--GridColumnGap);
  grid-template-columns: 1fr minmax(0, 1.5fr);
  align-items: start;
}

.pf-m-grid.pf-v5-c-table :where(.pf-v5-c-table__th, .pf-v5-c-table__td)[data-label]>* {
  grid-column: 2;
}

.pf-m-grid.pf-v5-c-table :where(.pf-v5-c-table__th, .pf-v5-c-table__td)[data-label]::before {
  font-weight: bold;
  text-align: start;
  content: attr(data-label);
}

.pf-m-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr)>*:first-child {
  --pf-v5-c-table--cell--PaddingLeft: 0;
}

.pf-m-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr)>*:last-child {
  --pf-v5-c-table--cell--PaddingRight: 0;
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table {
  --pf-v5-c-table__tr--responsive--PaddingTop: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingTop);
  --pf-v5-c-table__tr--responsive--PaddingRight: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingRight);
  --pf-v5-c-table__tr--responsive--PaddingBottom: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingBottom);
  --pf-v5-c-table__tr--responsive--PaddingLeft: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingLeft);
  border: 0;
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row)+tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
  --pf-v5-c-table__tr--responsive--PaddingTop: 0;
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__compound-expansion-toggle {
  --pf-v5-c-table__compound-expansion-toggle__button--before--BorderRightWidth: 0;
  --pf-v5-c-table__compound-expansion-toggle__button--before--BorderLeftWidth: 0;
  --pf-v5-c-table__compound-expansion-toggle__button--after--Top: 100%;
}

.pf-m-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) {
  position: relative;
}

.pf-m-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody)::after {
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  content: "";
  border: 0;
  border-inline-start: var(--pf-v5-c-table__tbody--after--BorderLeftWidth) solid var(--pf-v5-c-table__tbody--after--BorderColor);
}

.pf-m-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded {
  --pf-v5-c-table__tbody--after--BorderLeftWidth: var(--pf-v5-c-table__tbody--after--border-width--base);
}

.pf-m-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded tbody:where(.pf-v5-c-table__tbody) {
  --pf-v5-c-table__tbody--after--BorderLeftWidth: 0;
}

.pf-m-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody)>tr:where(.pf-v5-c-table__tr)>:first-child:not(.pf-v5-c-table__check)::after {
  --pf-v5-c-table__expandable-row--after--BorderLeftWidth: 0;
  position: static;
  width: auto;
  background-color: transparent;
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__expandable-row {
  --pf-v5-c-table--cell--responsive--PaddingTop: 0;
  --pf-v5-c-table--cell--responsive--PaddingRight: 0;
  --pf-v5-c-table--cell--responsive--PaddingBottom: 0;
  --pf-v5-c-table--cell--responsive--PaddingLeft: 0;
  --pf-v5-c-table--cell--PaddingRight: 0;
  --pf-v5-c-table--cell--PaddingLeft: 0;
  display: block;
  max-height: var(--pf-v5-c-table__expandable-row--MaxHeight);
  overflow-y: auto;
  border-block-end: none;
  box-shadow: none;
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__expandable-row>* {
  position: static;
  display: block;
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__expandable-row.pf-m-expanded {
  border-block-start-color: var(--pf-v5-c-table--BorderColor);
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__expandable-row>:first-child:not(.pf-v5-c-table__check)::after {
  content: none;
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__expandable-row th:where(.pf-v5-c-table__th).pf-m-no-padding .pf-v5-c-table__expandable-row-content,
.pf-m-grid.pf-v5-c-table .pf-v5-c-table__expandable-row td:where(.pf-v5-c-table__td).pf-m-no-padding .pf-v5-c-table__expandable-row-content {
  padding: 0;
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__expandable-row:not(.pf-m-expanded) {
  display: none;
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__expandable-row .pf-v5-c-table__expandable-row-content {
  padding-inline-end: var(--pf-v5-c-table__expandable-row-content--responsive--PaddingRight);
  padding-inline-start: var(--pf-v5-c-table__expandable-row-content--responsive--PaddingLeft);
}

.pf-m-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable {
  --pf-v5-c-table__tbody--after--BorderLeftWidth: 0;
  --pf-v5-c-table__tbody--after--BorderLeftColor: transparent;
}

.pf-m-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable,
.pf-m-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable>tr:where(.pf-v5-c-table__tr) {
  position: relative;
}

.pf-m-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable>tr:where(.pf-v5-c-table__tr)::after {
  content: "";
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  width: var(--pf-v5-c-table__tbody--after__tr--BorderLeftWidth);
  background-color: var(--pf-v5-c-table__tbody--after__tr--BorderLeftColor);
}

.pf-m-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable.pf-m-expanded {
  --pf-v5-c-table__tbody--after__tr--BorderLeftWidth: var(--pf-v5-c-table__tbody--m-expanded--after__tr--BorderLeftWidth);
  --pf-v5-c-table__tbody--after__tr--BorderLeftColor: var(--pf-v5-c-table__tbody--m-expanded--after__tr--BorderLeftColor);
}

.pf-m-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable.pf-m-selected {
  --pf-v5-c-table__tbody--after__tr--BorderLeftWidth: var(--pf-v5-c-table__tbody--m-selected--after__tr--BorderLeftWidth);
  --pf-v5-c-table__tbody--after__tr--BorderLeftColor: var(--pf-v5-c-table__tbody--m-selected--after__tr--BorderLeftColor);
}

.pf-m-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-selected::after {
  content: "";
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  width: var(--pf-v5-c-table__tr--m-selected--after--BorderLeftWidth);
  background-color: var(--pf-v5-c-table__tr--m-selected--after--BorderLeftColor);
}

.pf-m-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__toggle,
.pf-m-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__check,
.pf-m-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__favorite,
.pf-m-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__action {
  width: auto;
  padding: 0;
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__toggle {
  grid-row-start: 20;
  grid-column: -1;
  justify-self: end;
  padding-inline-end: 0;
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__toggle::after {
  content: none;
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__button {
  --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-grid--cell--PaddingTop);
  --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table--m-grid--cell--PaddingRight);
  --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-grid--cell--PaddingBottom);
  --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table--m-grid--cell--PaddingLeft);
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__check,
.pf-m-grid.pf-v5-c-table .pf-v5-c-table__favorite,
.pf-m-grid.pf-v5-c-table .pf-v5-c-table__action {
  grid-row-start: 1;
  grid-column-start: 2;
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__check {
  margin-block-start: var(--pf-v5-c-table__check--responsive--MarginTop);
  margin-inline-start: var(--pf-v5-c-table__check--responsive--MarginLeft);
  line-height: 1;
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__check~.pf-v5-c-table__favorite {
  margin-inline-start: var(--pf-v5-c-table--m-grid__check--favorite--MarginLeft);
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__check~.pf-v5-c-table__favorite~.pf-v5-c-table__action {
  margin-inline-start: var(--pf-v5-c-table--m-grid__check--favorite--action--MarginLeft);
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__check~.pf-v5-c-table__action {
  margin-inline-start: var(--pf-v5-c-table__action--responsive--MarginLeft);
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__check label {
  display: inline-block;
  margin: 0;
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__favorite {
  margin-block-start: var(--pf-v5-c-table--m-grid__favorite--MarginTop);
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__favorite~.pf-v5-c-table__action {
  margin-inline-start: var(--pf-v5-c-table--m-grid__favorite--action--MarginLeft);
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__action {
  margin-block-start: var(--pf-v5-c-table--m-grid__action--MarginTop);
  text-align: end;
}

@media screen and (max-width: 576px) {
  .pf-m-grid.pf-v5-c-table .pf-v5-c-table__action {
    grid-row-start: 1;
    grid-column-start: 2;
    margin-inline-start: 0;
  }
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__inline-edit-action {
  grid-column: 2;
  grid-row: 2;
}

.pf-m-grid.pf-v5-c-table .pf-v5-c-table__toggle-icon {
  transition: var(--pf-v5-c-table__toggle__icon--Transition);
}

.pf-v5-c-button.pf-m-expanded>.pf-m-grid.pf-v5-c-table .pf-v5-c-table__toggle-icon {
  transform: rotate(var(--pf-v5-c-table__toggle--m-expanded__icon--Rotate));
}

.pf-m-grid.pf-v5-c-table :where(.pf-v5-c-table, .pf-v5-c-table__thead, .pf-v5-c-table__tbody, .pf-v5-c-table__tr, .pf-v5-c-table__th, .pf-v5-c-table__td, .pf-v5-c-table__text).pf-m-nowrap {
  --pf-v5-c-table--cell--Overflow: auto;
}

.pf-m-grid.pf-v5-c-table :where(.pf-v5-c-table, .pf-v5-c-table__thead, .pf-v5-c-table__tbody, .pf-v5-c-table__tr, .pf-v5-c-table__th, .pf-v5-c-table__td, .pf-v5-c-table__text).pf-m-fit-content {
  width: auto;
  white-space: normal;
}

.pf-m-grid.pf-v5-c-table :where(.pf-v5-c-table, .pf-v5-c-table__thead, .pf-v5-c-table__tbody, .pf-v5-c-table__tr, .pf-v5-c-table__th, .pf-v5-c-table__td, .pf-v5-c-table__text).pf-m-truncate {
  --pf-v5-c-table--cell--MaxWidth: 100%;
}

.pf-m-grid.pf-v5-c-table [class*=pf-m-width] {
  --pf-v5-c-table--cell--Width: auto;
}

@media screen and (max-width: 768px) {
  .pf-m-grid-md.pf-v5-c-table {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-grid--cell--PaddingTop);
    --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table--m-grid--cell--PaddingRight);
    --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-grid--cell--PaddingBottom);
    --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table--m-grid--cell--PaddingLeft);
    --pf-v5-c-table__favorite--c-button--MarginTop: auto;
    --pf-v5-c-table__favorite--c-button--MarginRight: auto;
    --pf-v5-c-table__favorite--c-button--MarginBottom: auto;
    --pf-v5-c-table__favorite--c-button--MarginLeft: auto;
    display: grid;
    border: none;
  }

  .pf-m-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr)>* {
    width: auto;
    min-width: 0;
    max-width: none;
    overflow: visible;
    text-overflow: clip;
    white-space: normal;
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__text {
    position: relative;
    width: auto;
    min-width: 0;
    max-width: none;
    overflow: var(--pf-v5-c-table--cell--Overflow);
    text-overflow: var(--pf-v5-c-table--cell--TextOverflow);
    white-space: var(--pf-v5-c-table--cell--WhiteSpace);
  }

  .pf-m-grid-md.pf-v5-c-table thead:where(.pf-v5-c-table__thead) {
    display: none;
  }

  .pf-m-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) {
    display: block;
  }

  .pf-m-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody):first-of-type {
    border-block-start: var(--pf-v5-c-table__tbody--responsive--border-width--base) solid var(--pf-v5-c-table--responsive--BorderColor);
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table.pf-m-compact>tbody:where(.pf-v5-c-table__tbody) {
    border-block-start: 0;
  }

  .pf-m-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
    border-block-end: var(--pf-v5-c-table__tr--responsive--border-width--base) solid var(--pf-v5-c-table--responsive--BorderColor);
  }

  .pf-m-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr):last-child,
  .pf-m-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody):last-of-type:not(:only-of-type)>tr:where(.pf-v5-c-table__tr) {
    border-block-end-width: var(--pf-v5-c-table__tr--responsive--last-child--BorderBottomWidth);
  }

  .pf-m-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded {
    border-block-end: var(--pf-v5-c-table--border-width--base) solid var(--pf-v5-c-table--BorderColor);
  }

  .pf-m-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
    border-block-end: 0;
  }

  .pf-m-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded:not(:last-of-type) {
    border-block-end: var(--pf-v5-c-table__tbody--responsive--border-width--base) solid var(--pf-v5-c-table--responsive--BorderColor);
  }

  .pf-m-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-selected {
    --pf-v5-c-table__expandable-row--after--BorderLeftWidth: 0;
    --pf-v5-c-table__expandable-row--after--BorderColor: transparent;
  }

  .pf-m-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
    display: grid;
    grid-template-columns: 1fr;
    height: auto;
    grid-auto-columns: max-content;
    grid-column-gap: var(--pf-v5-c-table__tr--responsive--GridColumnGap);
    padding-block-start: var(--pf-v5-c-table__tr--responsive--PaddingTop);
    padding-inline-end: var(--pf-v5-c-table__tr--responsive--PaddingRight);
    padding-block-end: var(--pf-v5-c-table__tr--responsive--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-table__tr--responsive--PaddingLeft);
  }

  .pf-m-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row)>* {
    padding-block-start: var(--pf-v5-c-table--cell--responsive--PaddingTop);
    padding-inline-end: var(--pf-v5-c-table--cell--responsive--PaddingRight);
    padding-block-end: var(--pf-v5-c-table--cell--responsive--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-table--cell--responsive--PaddingLeft);
  }

  .pf-m-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row)>*:first-child {
    --pf-v5-c-table--cell--responsive--PaddingTop: var(--pf-v5-c-table--cell--first-child--responsive--PaddingTop);
  }

  .pf-m-grid-md.pf-v5-c-table.pf-m-compact {
    --pf-v5-c-table__tr--responsive--PaddingTop: var(--pf-v5-c-table--m-compact__tr--responsive--PaddingTop);
    --pf-v5-c-table__tr--responsive--PaddingBottom: var(--pf-v5-c-table--m-compact__tr--responsive--PaddingBottom);
    --pf-v5-c-table--cell--responsive--PaddingTop: var(--pf-v5-c-table--m-compact__tr__td--responsive--PaddingTop);
    --pf-v5-c-table--cell--responsive--PaddingBottom: var(--pf-v5-c-table--m-compact__tr__td--responsive--PaddingBottom);
    --pf-v5-c-table__check--responsive--MarginTop: var(--pf-v5-c-table--m-compact__check--responsive--MarginTop);
    --pf-v5-c-table__check--input--MarginTop: 0;
  }

  .pf-m-grid-md.pf-v5-c-table.pf-m-compact .pf-v5-c-table__action {
    margin-block-start: var(--pf-v5-c-table--m-compact__action--responsive--MarginTop);
    margin-block-end: var(--pf-v5-c-table--m-compact__action--responsive--MarginTop);
  }

  .pf-m-grid-md.pf-v5-c-table.pf-m-compact .pf-v5-c-table__toggle .pf-v5-c-button {
    margin-block-end: var(--pf-v5-c-table--m-compact__toggle--c-button--responsive--MarginBottom);
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__icon>* {
    text-align: start;
  }

  .pf-m-grid-md.pf-v5-c-table :where(.pf-v5-c-table__th, .pf-v5-c-table__td)[data-label] {
    --pf-v5-c-table--cell--hidden-visible--Display: var(--pf-v5-c-table--m-grid--cell--hidden-visible--Display);
    grid-column: 1;
    grid-column-gap: var(--pf-v5-c-table-td--responsive--GridColumnGap);
    grid-template-columns: 1fr minmax(0, 1.5fr);
    align-items: start;
  }

  .pf-m-grid-md.pf-v5-c-table :where(.pf-v5-c-table__th, .pf-v5-c-table__td)[data-label]>* {
    grid-column: 2;
  }

  .pf-m-grid-md.pf-v5-c-table :where(.pf-v5-c-table__th, .pf-v5-c-table__td)[data-label]::before {
    font-weight: bold;
    text-align: start;
    content: attr(data-label);
  }

  .pf-m-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr)>*:first-child {
    --pf-v5-c-table--cell--PaddingLeft: 0;
  }

  .pf-m-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr)>*:last-child {
    --pf-v5-c-table--cell--PaddingRight: 0;
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table {
    --pf-v5-c-table__tr--responsive--PaddingTop: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingTop);
    --pf-v5-c-table__tr--responsive--PaddingRight: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingRight);
    --pf-v5-c-table__tr--responsive--PaddingBottom: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingBottom);
    --pf-v5-c-table__tr--responsive--PaddingLeft: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingLeft);
    border: 0;
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row)+tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
    --pf-v5-c-table__tr--responsive--PaddingTop: 0;
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__compound-expansion-toggle {
    --pf-v5-c-table__compound-expansion-toggle__button--before--BorderRightWidth: 0;
    --pf-v5-c-table__compound-expansion-toggle__button--before--BorderLeftWidth: 0;
    --pf-v5-c-table__compound-expansion-toggle__button--after--Top: 100%;
  }

  .pf-m-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) {
    position: relative;
  }

  .pf-m-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody)::after {
    position: absolute;
    inset-block-start: 0;
    inset-block-end: 0;
    inset-inline-start: 0;
    content: "";
    border: 0;
    border-inline-start: var(--pf-v5-c-table__tbody--after--BorderLeftWidth) solid var(--pf-v5-c-table__tbody--after--BorderColor);
  }

  .pf-m-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded {
    --pf-v5-c-table__tbody--after--BorderLeftWidth: var(--pf-v5-c-table__tbody--after--border-width--base);
  }

  .pf-m-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded tbody:where(.pf-v5-c-table__tbody) {
    --pf-v5-c-table__tbody--after--BorderLeftWidth: 0;
  }

  .pf-m-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody)>tr:where(.pf-v5-c-table__tr)>:first-child:not(.pf-v5-c-table__check)::after {
    --pf-v5-c-table__expandable-row--after--BorderLeftWidth: 0;
    position: static;
    width: auto;
    background-color: transparent;
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__expandable-row {
    --pf-v5-c-table--cell--responsive--PaddingTop: 0;
    --pf-v5-c-table--cell--responsive--PaddingRight: 0;
    --pf-v5-c-table--cell--responsive--PaddingBottom: 0;
    --pf-v5-c-table--cell--responsive--PaddingLeft: 0;
    --pf-v5-c-table--cell--PaddingRight: 0;
    --pf-v5-c-table--cell--PaddingLeft: 0;
    display: block;
    max-height: var(--pf-v5-c-table__expandable-row--MaxHeight);
    overflow-y: auto;
    border-block-end: none;
    box-shadow: none;
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__expandable-row>* {
    position: static;
    display: block;
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__expandable-row.pf-m-expanded {
    border-block-start-color: var(--pf-v5-c-table--BorderColor);
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__expandable-row>:first-child:not(.pf-v5-c-table__check)::after {
    content: none;
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__expandable-row th:where(.pf-v5-c-table__th).pf-m-no-padding .pf-v5-c-table__expandable-row-content,
  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__expandable-row td:where(.pf-v5-c-table__td).pf-m-no-padding .pf-v5-c-table__expandable-row-content {
    padding: 0;
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__expandable-row:not(.pf-m-expanded) {
    display: none;
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__expandable-row .pf-v5-c-table__expandable-row-content {
    padding-inline-end: var(--pf-v5-c-table__expandable-row-content--responsive--PaddingRight);
    padding-inline-start: var(--pf-v5-c-table__expandable-row-content--responsive--PaddingLeft);
  }

  .pf-m-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable {
    --pf-v5-c-table__tbody--after--BorderLeftWidth: 0;
    --pf-v5-c-table__tbody--after--BorderLeftColor: transparent;
  }

  .pf-m-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable,
  .pf-m-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable>tr:where(.pf-v5-c-table__tr) {
    position: relative;
  }

  .pf-m-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable>tr:where(.pf-v5-c-table__tr)::after {
    content: "";
    position: absolute;
    inset-block-start: 0;
    inset-block-end: 0;
    inset-inline-start: 0;
    width: var(--pf-v5-c-table__tbody--after__tr--BorderLeftWidth);
    background-color: var(--pf-v5-c-table__tbody--after__tr--BorderLeftColor);
  }

  .pf-m-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable.pf-m-expanded {
    --pf-v5-c-table__tbody--after__tr--BorderLeftWidth: var(--pf-v5-c-table__tbody--m-expanded--after__tr--BorderLeftWidth);
    --pf-v5-c-table__tbody--after__tr--BorderLeftColor: var(--pf-v5-c-table__tbody--m-expanded--after__tr--BorderLeftColor);
  }

  .pf-m-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable.pf-m-selected {
    --pf-v5-c-table__tbody--after__tr--BorderLeftWidth: var(--pf-v5-c-table__tbody--m-selected--after__tr--BorderLeftWidth);
    --pf-v5-c-table__tbody--after__tr--BorderLeftColor: var(--pf-v5-c-table__tbody--m-selected--after__tr--BorderLeftColor);
  }

  .pf-m-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-selected::after {
    content: "";
    position: absolute;
    inset-block-start: 0;
    inset-block-end: 0;
    inset-inline-start: 0;
    width: var(--pf-v5-c-table__tr--m-selected--after--BorderLeftWidth);
    background-color: var(--pf-v5-c-table__tr--m-selected--after--BorderLeftColor);
  }

  .pf-m-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__toggle,
  .pf-m-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__check,
  .pf-m-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__favorite,
  .pf-m-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__action {
    width: auto;
    padding: 0;
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__toggle {
    grid-row-start: 20;
    grid-column: -1;
    justify-self: end;
    padding-inline-end: 0;
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__toggle::after {
    content: none;
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__button {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-grid--cell--PaddingTop);
    --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table--m-grid--cell--PaddingRight);
    --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-grid--cell--PaddingBottom);
    --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table--m-grid--cell--PaddingLeft);
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__check,
  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__favorite,
  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__action {
    grid-row-start: 1;
    grid-column-start: 2;
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__check {
    margin-block-start: var(--pf-v5-c-table__check--responsive--MarginTop);
    margin-inline-start: var(--pf-v5-c-table__check--responsive--MarginLeft);
    line-height: 1;
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__check~.pf-v5-c-table__favorite {
    margin-inline-start: var(--pf-v5-c-table--m-grid__check--favorite--MarginLeft);
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__check~.pf-v5-c-table__favorite~.pf-v5-c-table__action {
    margin-inline-start: var(--pf-v5-c-table--m-grid__check--favorite--action--MarginLeft);
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__check~.pf-v5-c-table__action {
    margin-inline-start: var(--pf-v5-c-table__action--responsive--MarginLeft);
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__check label {
    display: inline-block;
    margin: 0;
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__favorite {
    margin-block-start: var(--pf-v5-c-table--m-grid__favorite--MarginTop);
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__favorite~.pf-v5-c-table__action {
    margin-inline-start: var(--pf-v5-c-table--m-grid__favorite--action--MarginLeft);
  }

  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__action {
    margin-block-start: var(--pf-v5-c-table--m-grid__action--MarginTop);
    text-align: end;
  }
}

@media screen and (max-width: 768px)and (max-width: 576px) {
  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__action {
    grid-row-start: 1;
    grid-column-start: 2;
    margin-inline-start: 0;
  }
}

@media screen and (max-width: 768px) {
  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__inline-edit-action {
    grid-column: 2;
    grid-row: 2;
  }
}

@media screen and (max-width: 768px) {
  .pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__toggle-icon {
    transition: var(--pf-v5-c-table__toggle__icon--Transition);
  }

  .pf-v5-c-button.pf-m-expanded>.pf-m-grid-md.pf-v5-c-table .pf-v5-c-table__toggle-icon {
    transform: rotate(var(--pf-v5-c-table__toggle--m-expanded__icon--Rotate));
  }
}

@media screen and (max-width: 768px) {
  .pf-m-grid-md.pf-v5-c-table :where(.pf-v5-c-table, .pf-v5-c-table__thead, .pf-v5-c-table__tbody, .pf-v5-c-table__tr, .pf-v5-c-table__th, .pf-v5-c-table__td, .pf-v5-c-table__text).pf-m-nowrap {
    --pf-v5-c-table--cell--Overflow: auto;
  }

  .pf-m-grid-md.pf-v5-c-table :where(.pf-v5-c-table, .pf-v5-c-table__thead, .pf-v5-c-table__tbody, .pf-v5-c-table__tr, .pf-v5-c-table__th, .pf-v5-c-table__td, .pf-v5-c-table__text).pf-m-fit-content {
    width: auto;
    white-space: normal;
  }

  .pf-m-grid-md.pf-v5-c-table :where(.pf-v5-c-table, .pf-v5-c-table__thead, .pf-v5-c-table__tbody, .pf-v5-c-table__tr, .pf-v5-c-table__th, .pf-v5-c-table__td, .pf-v5-c-table__text).pf-m-truncate {
    --pf-v5-c-table--cell--MaxWidth: 100%;
  }
}

@media screen and (max-width: 768px) {
  .pf-m-grid-md.pf-v5-c-table [class*=pf-m-width] {
    --pf-v5-c-table--cell--Width: auto;
  }
}

@media screen and (max-width: 992px) {
  .pf-m-grid-lg.pf-v5-c-table {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-grid--cell--PaddingTop);
    --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table--m-grid--cell--PaddingRight);
    --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-grid--cell--PaddingBottom);
    --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table--m-grid--cell--PaddingLeft);
    --pf-v5-c-table__favorite--c-button--MarginTop: auto;
    --pf-v5-c-table__favorite--c-button--MarginRight: auto;
    --pf-v5-c-table__favorite--c-button--MarginBottom: auto;
    --pf-v5-c-table__favorite--c-button--MarginLeft: auto;
    display: grid;
    border: none;
  }

  .pf-m-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr)>* {
    width: auto;
    min-width: 0;
    max-width: none;
    overflow: visible;
    text-overflow: clip;
    white-space: normal;
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__text {
    position: relative;
    width: auto;
    min-width: 0;
    max-width: none;
    overflow: var(--pf-v5-c-table--cell--Overflow);
    text-overflow: var(--pf-v5-c-table--cell--TextOverflow);
    white-space: var(--pf-v5-c-table--cell--WhiteSpace);
  }

  .pf-m-grid-lg.pf-v5-c-table thead:where(.pf-v5-c-table__thead) {
    display: none;
  }

  .pf-m-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) {
    display: block;
  }

  .pf-m-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody):first-of-type {
    border-block-start: var(--pf-v5-c-table__tbody--responsive--border-width--base) solid var(--pf-v5-c-table--responsive--BorderColor);
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table.pf-m-compact>tbody:where(.pf-v5-c-table__tbody) {
    border-block-start: 0;
  }

  .pf-m-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
    border-block-end: var(--pf-v5-c-table__tr--responsive--border-width--base) solid var(--pf-v5-c-table--responsive--BorderColor);
  }

  .pf-m-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr):last-child,
  .pf-m-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody):last-of-type:not(:only-of-type)>tr:where(.pf-v5-c-table__tr) {
    border-block-end-width: var(--pf-v5-c-table__tr--responsive--last-child--BorderBottomWidth);
  }

  .pf-m-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded {
    border-block-end: var(--pf-v5-c-table--border-width--base) solid var(--pf-v5-c-table--BorderColor);
  }

  .pf-m-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
    border-block-end: 0;
  }

  .pf-m-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded:not(:last-of-type) {
    border-block-end: var(--pf-v5-c-table__tbody--responsive--border-width--base) solid var(--pf-v5-c-table--responsive--BorderColor);
  }

  .pf-m-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-selected {
    --pf-v5-c-table__expandable-row--after--BorderLeftWidth: 0;
    --pf-v5-c-table__expandable-row--after--BorderColor: transparent;
  }

  .pf-m-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
    display: grid;
    grid-template-columns: 1fr;
    height: auto;
    grid-auto-columns: max-content;
    grid-column-gap: var(--pf-v5-c-table__tr--responsive--GridColumnGap);
    padding-block-start: var(--pf-v5-c-table__tr--responsive--PaddingTop);
    padding-inline-end: var(--pf-v5-c-table__tr--responsive--PaddingRight);
    padding-block-end: var(--pf-v5-c-table__tr--responsive--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-table__tr--responsive--PaddingLeft);
  }

  .pf-m-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row)>* {
    padding-block-start: var(--pf-v5-c-table--cell--responsive--PaddingTop);
    padding-inline-end: var(--pf-v5-c-table--cell--responsive--PaddingRight);
    padding-block-end: var(--pf-v5-c-table--cell--responsive--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-table--cell--responsive--PaddingLeft);
  }

  .pf-m-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row)>*:first-child {
    --pf-v5-c-table--cell--responsive--PaddingTop: var(--pf-v5-c-table--cell--first-child--responsive--PaddingTop);
  }

  .pf-m-grid-lg.pf-v5-c-table.pf-m-compact {
    --pf-v5-c-table__tr--responsive--PaddingTop: var(--pf-v5-c-table--m-compact__tr--responsive--PaddingTop);
    --pf-v5-c-table__tr--responsive--PaddingBottom: var(--pf-v5-c-table--m-compact__tr--responsive--PaddingBottom);
    --pf-v5-c-table--cell--responsive--PaddingTop: var(--pf-v5-c-table--m-compact__tr__td--responsive--PaddingTop);
    --pf-v5-c-table--cell--responsive--PaddingBottom: var(--pf-v5-c-table--m-compact__tr__td--responsive--PaddingBottom);
    --pf-v5-c-table__check--responsive--MarginTop: var(--pf-v5-c-table--m-compact__check--responsive--MarginTop);
    --pf-v5-c-table__check--input--MarginTop: 0;
  }

  .pf-m-grid-lg.pf-v5-c-table.pf-m-compact .pf-v5-c-table__action {
    margin-block-start: var(--pf-v5-c-table--m-compact__action--responsive--MarginTop);
    margin-block-end: var(--pf-v5-c-table--m-compact__action--responsive--MarginTop);
  }

  .pf-m-grid-lg.pf-v5-c-table.pf-m-compact .pf-v5-c-table__toggle .pf-v5-c-button {
    margin-block-end: var(--pf-v5-c-table--m-compact__toggle--c-button--responsive--MarginBottom);
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__icon>* {
    text-align: start;
  }

  .pf-m-grid-lg.pf-v5-c-table :where(.pf-v5-c-table__th, .pf-v5-c-table__td)[data-label] {
    --pf-v5-c-table--cell--hidden-visible--Display: var(--pf-v5-c-table--m-grid--cell--hidden-visible--Display);
    grid-column: 1;
    grid-column-gap: var(--pf-v5-c-table-td--responsive--GridColumnGap);
    grid-template-columns: 1fr minmax(0, 1.5fr);
    align-items: start;
  }

  .pf-m-grid-lg.pf-v5-c-table :where(.pf-v5-c-table__th, .pf-v5-c-table__td)[data-label]>* {
    grid-column: 2;
  }

  .pf-m-grid-lg.pf-v5-c-table :where(.pf-v5-c-table__th, .pf-v5-c-table__td)[data-label]::before {
    font-weight: bold;
    text-align: start;
    content: attr(data-label);
  }

  .pf-m-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr)>*:first-child {
    --pf-v5-c-table--cell--PaddingLeft: 0;
  }

  .pf-m-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr)>*:last-child {
    --pf-v5-c-table--cell--PaddingRight: 0;
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table {
    --pf-v5-c-table__tr--responsive--PaddingTop: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingTop);
    --pf-v5-c-table__tr--responsive--PaddingRight: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingRight);
    --pf-v5-c-table__tr--responsive--PaddingBottom: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingBottom);
    --pf-v5-c-table__tr--responsive--PaddingLeft: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingLeft);
    border: 0;
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row)+tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
    --pf-v5-c-table__tr--responsive--PaddingTop: 0;
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__compound-expansion-toggle {
    --pf-v5-c-table__compound-expansion-toggle__button--before--BorderRightWidth: 0;
    --pf-v5-c-table__compound-expansion-toggle__button--before--BorderLeftWidth: 0;
    --pf-v5-c-table__compound-expansion-toggle__button--after--Top: 100%;
  }

  .pf-m-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) {
    position: relative;
  }

  .pf-m-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody)::after {
    position: absolute;
    inset-block-start: 0;
    inset-block-end: 0;
    inset-inline-start: 0;
    content: "";
    border: 0;
    border-inline-start: var(--pf-v5-c-table__tbody--after--BorderLeftWidth) solid var(--pf-v5-c-table__tbody--after--BorderColor);
  }

  .pf-m-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded {
    --pf-v5-c-table__tbody--after--BorderLeftWidth: var(--pf-v5-c-table__tbody--after--border-width--base);
  }

  .pf-m-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded tbody:where(.pf-v5-c-table__tbody) {
    --pf-v5-c-table__tbody--after--BorderLeftWidth: 0;
  }

  .pf-m-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody)>tr:where(.pf-v5-c-table__tr)>:first-child:not(.pf-v5-c-table__check)::after {
    --pf-v5-c-table__expandable-row--after--BorderLeftWidth: 0;
    position: static;
    width: auto;
    background-color: transparent;
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__expandable-row {
    --pf-v5-c-table--cell--responsive--PaddingTop: 0;
    --pf-v5-c-table--cell--responsive--PaddingRight: 0;
    --pf-v5-c-table--cell--responsive--PaddingBottom: 0;
    --pf-v5-c-table--cell--responsive--PaddingLeft: 0;
    --pf-v5-c-table--cell--PaddingRight: 0;
    --pf-v5-c-table--cell--PaddingLeft: 0;
    display: block;
    max-height: var(--pf-v5-c-table__expandable-row--MaxHeight);
    overflow-y: auto;
    border-block-end: none;
    box-shadow: none;
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__expandable-row>* {
    position: static;
    display: block;
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__expandable-row.pf-m-expanded {
    border-block-start-color: var(--pf-v5-c-table--BorderColor);
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__expandable-row>:first-child:not(.pf-v5-c-table__check)::after {
    content: none;
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__expandable-row th:where(.pf-v5-c-table__th).pf-m-no-padding .pf-v5-c-table__expandable-row-content,
  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__expandable-row td:where(.pf-v5-c-table__td).pf-m-no-padding .pf-v5-c-table__expandable-row-content {
    padding: 0;
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__expandable-row:not(.pf-m-expanded) {
    display: none;
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__expandable-row .pf-v5-c-table__expandable-row-content {
    padding-inline-end: var(--pf-v5-c-table__expandable-row-content--responsive--PaddingRight);
    padding-inline-start: var(--pf-v5-c-table__expandable-row-content--responsive--PaddingLeft);
  }

  .pf-m-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable {
    --pf-v5-c-table__tbody--after--BorderLeftWidth: 0;
    --pf-v5-c-table__tbody--after--BorderLeftColor: transparent;
  }

  .pf-m-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable,
  .pf-m-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable>tr:where(.pf-v5-c-table__tr) {
    position: relative;
  }

  .pf-m-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable>tr:where(.pf-v5-c-table__tr)::after {
    content: "";
    position: absolute;
    inset-block-start: 0;
    inset-block-end: 0;
    inset-inline-start: 0;
    width: var(--pf-v5-c-table__tbody--after__tr--BorderLeftWidth);
    background-color: var(--pf-v5-c-table__tbody--after__tr--BorderLeftColor);
  }

  .pf-m-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable.pf-m-expanded {
    --pf-v5-c-table__tbody--after__tr--BorderLeftWidth: var(--pf-v5-c-table__tbody--m-expanded--after__tr--BorderLeftWidth);
    --pf-v5-c-table__tbody--after__tr--BorderLeftColor: var(--pf-v5-c-table__tbody--m-expanded--after__tr--BorderLeftColor);
  }

  .pf-m-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable.pf-m-selected {
    --pf-v5-c-table__tbody--after__tr--BorderLeftWidth: var(--pf-v5-c-table__tbody--m-selected--after__tr--BorderLeftWidth);
    --pf-v5-c-table__tbody--after__tr--BorderLeftColor: var(--pf-v5-c-table__tbody--m-selected--after__tr--BorderLeftColor);
  }

  .pf-m-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-selected::after {
    content: "";
    position: absolute;
    inset-block-start: 0;
    inset-block-end: 0;
    inset-inline-start: 0;
    width: var(--pf-v5-c-table__tr--m-selected--after--BorderLeftWidth);
    background-color: var(--pf-v5-c-table__tr--m-selected--after--BorderLeftColor);
  }

  .pf-m-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__toggle,
  .pf-m-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__check,
  .pf-m-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__favorite,
  .pf-m-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__action {
    width: auto;
    padding: 0;
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__toggle {
    grid-row-start: 20;
    grid-column: -1;
    justify-self: end;
    padding-inline-end: 0;
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__toggle::after {
    content: none;
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__button {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-grid--cell--PaddingTop);
    --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table--m-grid--cell--PaddingRight);
    --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-grid--cell--PaddingBottom);
    --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table--m-grid--cell--PaddingLeft);
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__check,
  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__favorite,
  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__action {
    grid-row-start: 1;
    grid-column-start: 2;
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__check {
    margin-block-start: var(--pf-v5-c-table__check--responsive--MarginTop);
    margin-inline-start: var(--pf-v5-c-table__check--responsive--MarginLeft);
    line-height: 1;
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__check~.pf-v5-c-table__favorite {
    margin-inline-start: var(--pf-v5-c-table--m-grid__check--favorite--MarginLeft);
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__check~.pf-v5-c-table__favorite~.pf-v5-c-table__action {
    margin-inline-start: var(--pf-v5-c-table--m-grid__check--favorite--action--MarginLeft);
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__check~.pf-v5-c-table__action {
    margin-inline-start: var(--pf-v5-c-table__action--responsive--MarginLeft);
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__check label {
    display: inline-block;
    margin: 0;
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__favorite {
    margin-block-start: var(--pf-v5-c-table--m-grid__favorite--MarginTop);
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__favorite~.pf-v5-c-table__action {
    margin-inline-start: var(--pf-v5-c-table--m-grid__favorite--action--MarginLeft);
  }

  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__action {
    margin-block-start: var(--pf-v5-c-table--m-grid__action--MarginTop);
    text-align: end;
  }
}

@media screen and (max-width: 992px)and (max-width: 576px) {
  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__action {
    grid-row-start: 1;
    grid-column-start: 2;
    margin-inline-start: 0;
  }
}

@media screen and (max-width: 992px) {
  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__inline-edit-action {
    grid-column: 2;
    grid-row: 2;
  }
}

@media screen and (max-width: 992px) {
  .pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__toggle-icon {
    transition: var(--pf-v5-c-table__toggle__icon--Transition);
  }

  .pf-v5-c-button.pf-m-expanded>.pf-m-grid-lg.pf-v5-c-table .pf-v5-c-table__toggle-icon {
    transform: rotate(var(--pf-v5-c-table__toggle--m-expanded__icon--Rotate));
  }
}

@media screen and (max-width: 992px) {
  .pf-m-grid-lg.pf-v5-c-table :where(.pf-v5-c-table, .pf-v5-c-table__thead, .pf-v5-c-table__tbody, .pf-v5-c-table__tr, .pf-v5-c-table__th, .pf-v5-c-table__td, .pf-v5-c-table__text).pf-m-nowrap {
    --pf-v5-c-table--cell--Overflow: auto;
  }

  .pf-m-grid-lg.pf-v5-c-table :where(.pf-v5-c-table, .pf-v5-c-table__thead, .pf-v5-c-table__tbody, .pf-v5-c-table__tr, .pf-v5-c-table__th, .pf-v5-c-table__td, .pf-v5-c-table__text).pf-m-fit-content {
    width: auto;
    white-space: normal;
  }

  .pf-m-grid-lg.pf-v5-c-table :where(.pf-v5-c-table, .pf-v5-c-table__thead, .pf-v5-c-table__tbody, .pf-v5-c-table__tr, .pf-v5-c-table__th, .pf-v5-c-table__td, .pf-v5-c-table__text).pf-m-truncate {
    --pf-v5-c-table--cell--MaxWidth: 100%;
  }
}

@media screen and (max-width: 992px) {
  .pf-m-grid-lg.pf-v5-c-table [class*=pf-m-width] {
    --pf-v5-c-table--cell--Width: auto;
  }
}

@media screen and (max-width: 1200px) {
  .pf-m-grid-xl.pf-v5-c-table {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-grid--cell--PaddingTop);
    --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table--m-grid--cell--PaddingRight);
    --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-grid--cell--PaddingBottom);
    --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table--m-grid--cell--PaddingLeft);
    --pf-v5-c-table__favorite--c-button--MarginTop: auto;
    --pf-v5-c-table__favorite--c-button--MarginRight: auto;
    --pf-v5-c-table__favorite--c-button--MarginBottom: auto;
    --pf-v5-c-table__favorite--c-button--MarginLeft: auto;
    display: grid;
    border: none;
  }

  .pf-m-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)>* {
    width: auto;
    min-width: 0;
    max-width: none;
    overflow: visible;
    text-overflow: clip;
    white-space: normal;
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__text {
    position: relative;
    width: auto;
    min-width: 0;
    max-width: none;
    overflow: var(--pf-v5-c-table--cell--Overflow);
    text-overflow: var(--pf-v5-c-table--cell--TextOverflow);
    white-space: var(--pf-v5-c-table--cell--WhiteSpace);
  }

  .pf-m-grid-xl.pf-v5-c-table thead:where(.pf-v5-c-table__thead) {
    display: none;
  }

  .pf-m-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) {
    display: block;
  }

  .pf-m-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody):first-of-type {
    border-block-start: var(--pf-v5-c-table__tbody--responsive--border-width--base) solid var(--pf-v5-c-table--responsive--BorderColor);
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table.pf-m-compact>tbody:where(.pf-v5-c-table__tbody) {
    border-block-start: 0;
  }

  .pf-m-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
    border-block-end: var(--pf-v5-c-table__tr--responsive--border-width--base) solid var(--pf-v5-c-table--responsive--BorderColor);
  }

  .pf-m-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):last-child,
  .pf-m-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody):last-of-type:not(:only-of-type)>tr:where(.pf-v5-c-table__tr) {
    border-block-end-width: var(--pf-v5-c-table__tr--responsive--last-child--BorderBottomWidth);
  }

  .pf-m-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded {
    border-block-end: var(--pf-v5-c-table--border-width--base) solid var(--pf-v5-c-table--BorderColor);
  }

  .pf-m-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
    border-block-end: 0;
  }

  .pf-m-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded:not(:last-of-type) {
    border-block-end: var(--pf-v5-c-table__tbody--responsive--border-width--base) solid var(--pf-v5-c-table--responsive--BorderColor);
  }

  .pf-m-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-selected {
    --pf-v5-c-table__expandable-row--after--BorderLeftWidth: 0;
    --pf-v5-c-table__expandable-row--after--BorderColor: transparent;
  }

  .pf-m-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
    display: grid;
    grid-template-columns: 1fr;
    height: auto;
    grid-auto-columns: max-content;
    grid-column-gap: var(--pf-v5-c-table__tr--responsive--GridColumnGap);
    padding-block-start: var(--pf-v5-c-table__tr--responsive--PaddingTop);
    padding-inline-end: var(--pf-v5-c-table__tr--responsive--PaddingRight);
    padding-block-end: var(--pf-v5-c-table__tr--responsive--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-table__tr--responsive--PaddingLeft);
  }

  .pf-m-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row)>* {
    padding-block-start: var(--pf-v5-c-table--cell--responsive--PaddingTop);
    padding-inline-end: var(--pf-v5-c-table--cell--responsive--PaddingRight);
    padding-block-end: var(--pf-v5-c-table--cell--responsive--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-table--cell--responsive--PaddingLeft);
  }

  .pf-m-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row)>*:first-child {
    --pf-v5-c-table--cell--responsive--PaddingTop: var(--pf-v5-c-table--cell--first-child--responsive--PaddingTop);
  }

  .pf-m-grid-xl.pf-v5-c-table.pf-m-compact {
    --pf-v5-c-table__tr--responsive--PaddingTop: var(--pf-v5-c-table--m-compact__tr--responsive--PaddingTop);
    --pf-v5-c-table__tr--responsive--PaddingBottom: var(--pf-v5-c-table--m-compact__tr--responsive--PaddingBottom);
    --pf-v5-c-table--cell--responsive--PaddingTop: var(--pf-v5-c-table--m-compact__tr__td--responsive--PaddingTop);
    --pf-v5-c-table--cell--responsive--PaddingBottom: var(--pf-v5-c-table--m-compact__tr__td--responsive--PaddingBottom);
    --pf-v5-c-table__check--responsive--MarginTop: var(--pf-v5-c-table--m-compact__check--responsive--MarginTop);
    --pf-v5-c-table__check--input--MarginTop: 0;
  }

  .pf-m-grid-xl.pf-v5-c-table.pf-m-compact .pf-v5-c-table__action {
    margin-block-start: var(--pf-v5-c-table--m-compact__action--responsive--MarginTop);
    margin-block-end: var(--pf-v5-c-table--m-compact__action--responsive--MarginTop);
  }

  .pf-m-grid-xl.pf-v5-c-table.pf-m-compact .pf-v5-c-table__toggle .pf-v5-c-button {
    margin-block-end: var(--pf-v5-c-table--m-compact__toggle--c-button--responsive--MarginBottom);
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__icon>* {
    text-align: start;
  }

  .pf-m-grid-xl.pf-v5-c-table :where(.pf-v5-c-table__th, .pf-v5-c-table__td)[data-label] {
    --pf-v5-c-table--cell--hidden-visible--Display: var(--pf-v5-c-table--m-grid--cell--hidden-visible--Display);
    grid-column: 1;
    grid-column-gap: var(--pf-v5-c-table-td--responsive--GridColumnGap);
    grid-template-columns: 1fr minmax(0, 1.5fr);
    align-items: start;
  }

  .pf-m-grid-xl.pf-v5-c-table :where(.pf-v5-c-table__th, .pf-v5-c-table__td)[data-label]>* {
    grid-column: 2;
  }

  .pf-m-grid-xl.pf-v5-c-table :where(.pf-v5-c-table__th, .pf-v5-c-table__td)[data-label]::before {
    font-weight: bold;
    text-align: start;
    content: attr(data-label);
  }

  .pf-m-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)>*:first-child {
    --pf-v5-c-table--cell--PaddingLeft: 0;
  }

  .pf-m-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)>*:last-child {
    --pf-v5-c-table--cell--PaddingRight: 0;
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table {
    --pf-v5-c-table__tr--responsive--PaddingTop: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingTop);
    --pf-v5-c-table__tr--responsive--PaddingRight: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingRight);
    --pf-v5-c-table__tr--responsive--PaddingBottom: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingBottom);
    --pf-v5-c-table__tr--responsive--PaddingLeft: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingLeft);
    border: 0;
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row)+tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
    --pf-v5-c-table__tr--responsive--PaddingTop: 0;
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__compound-expansion-toggle {
    --pf-v5-c-table__compound-expansion-toggle__button--before--BorderRightWidth: 0;
    --pf-v5-c-table__compound-expansion-toggle__button--before--BorderLeftWidth: 0;
    --pf-v5-c-table__compound-expansion-toggle__button--after--Top: 100%;
  }

  .pf-m-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) {
    position: relative;
  }

  .pf-m-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody)::after {
    position: absolute;
    inset-block-start: 0;
    inset-block-end: 0;
    inset-inline-start: 0;
    content: "";
    border: 0;
    border-inline-start: var(--pf-v5-c-table__tbody--after--BorderLeftWidth) solid var(--pf-v5-c-table__tbody--after--BorderColor);
  }

  .pf-m-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded {
    --pf-v5-c-table__tbody--after--BorderLeftWidth: var(--pf-v5-c-table__tbody--after--border-width--base);
  }

  .pf-m-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded tbody:where(.pf-v5-c-table__tbody) {
    --pf-v5-c-table__tbody--after--BorderLeftWidth: 0;
  }

  .pf-m-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody)>tr:where(.pf-v5-c-table__tr)>:first-child:not(.pf-v5-c-table__check)::after {
    --pf-v5-c-table__expandable-row--after--BorderLeftWidth: 0;
    position: static;
    width: auto;
    background-color: transparent;
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__expandable-row {
    --pf-v5-c-table--cell--responsive--PaddingTop: 0;
    --pf-v5-c-table--cell--responsive--PaddingRight: 0;
    --pf-v5-c-table--cell--responsive--PaddingBottom: 0;
    --pf-v5-c-table--cell--responsive--PaddingLeft: 0;
    --pf-v5-c-table--cell--PaddingRight: 0;
    --pf-v5-c-table--cell--PaddingLeft: 0;
    display: block;
    max-height: var(--pf-v5-c-table__expandable-row--MaxHeight);
    overflow-y: auto;
    border-block-end: none;
    box-shadow: none;
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__expandable-row>* {
    position: static;
    display: block;
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__expandable-row.pf-m-expanded {
    border-block-start-color: var(--pf-v5-c-table--BorderColor);
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__expandable-row>:first-child:not(.pf-v5-c-table__check)::after {
    content: none;
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__expandable-row th:where(.pf-v5-c-table__th).pf-m-no-padding .pf-v5-c-table__expandable-row-content,
  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__expandable-row td:where(.pf-v5-c-table__td).pf-m-no-padding .pf-v5-c-table__expandable-row-content {
    padding: 0;
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__expandable-row:not(.pf-m-expanded) {
    display: none;
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__expandable-row .pf-v5-c-table__expandable-row-content {
    padding-inline-end: var(--pf-v5-c-table__expandable-row-content--responsive--PaddingRight);
    padding-inline-start: var(--pf-v5-c-table__expandable-row-content--responsive--PaddingLeft);
  }

  .pf-m-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable {
    --pf-v5-c-table__tbody--after--BorderLeftWidth: 0;
    --pf-v5-c-table__tbody--after--BorderLeftColor: transparent;
  }

  .pf-m-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable,
  .pf-m-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable>tr:where(.pf-v5-c-table__tr) {
    position: relative;
  }

  .pf-m-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable>tr:where(.pf-v5-c-table__tr)::after {
    content: "";
    position: absolute;
    inset-block-start: 0;
    inset-block-end: 0;
    inset-inline-start: 0;
    width: var(--pf-v5-c-table__tbody--after__tr--BorderLeftWidth);
    background-color: var(--pf-v5-c-table__tbody--after__tr--BorderLeftColor);
  }

  .pf-m-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable.pf-m-expanded {
    --pf-v5-c-table__tbody--after__tr--BorderLeftWidth: var(--pf-v5-c-table__tbody--m-expanded--after__tr--BorderLeftWidth);
    --pf-v5-c-table__tbody--after__tr--BorderLeftColor: var(--pf-v5-c-table__tbody--m-expanded--after__tr--BorderLeftColor);
  }

  .pf-m-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable.pf-m-selected {
    --pf-v5-c-table__tbody--after__tr--BorderLeftWidth: var(--pf-v5-c-table__tbody--m-selected--after__tr--BorderLeftWidth);
    --pf-v5-c-table__tbody--after__tr--BorderLeftColor: var(--pf-v5-c-table__tbody--m-selected--after__tr--BorderLeftColor);
  }

  .pf-m-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-selected::after {
    content: "";
    position: absolute;
    inset-block-start: 0;
    inset-block-end: 0;
    inset-inline-start: 0;
    width: var(--pf-v5-c-table__tr--m-selected--after--BorderLeftWidth);
    background-color: var(--pf-v5-c-table__tr--m-selected--after--BorderLeftColor);
  }

  .pf-m-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__toggle,
  .pf-m-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__check,
  .pf-m-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__favorite,
  .pf-m-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__action {
    width: auto;
    padding: 0;
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__toggle {
    grid-row-start: 20;
    grid-column: -1;
    justify-self: end;
    padding-inline-end: 0;
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__toggle::after {
    content: none;
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__button {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-grid--cell--PaddingTop);
    --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table--m-grid--cell--PaddingRight);
    --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-grid--cell--PaddingBottom);
    --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table--m-grid--cell--PaddingLeft);
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__check,
  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__favorite,
  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__action {
    grid-row-start: 1;
    grid-column-start: 2;
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__check {
    margin-block-start: var(--pf-v5-c-table__check--responsive--MarginTop);
    margin-inline-start: var(--pf-v5-c-table__check--responsive--MarginLeft);
    line-height: 1;
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__check~.pf-v5-c-table__favorite {
    margin-inline-start: var(--pf-v5-c-table--m-grid__check--favorite--MarginLeft);
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__check~.pf-v5-c-table__favorite~.pf-v5-c-table__action {
    margin-inline-start: var(--pf-v5-c-table--m-grid__check--favorite--action--MarginLeft);
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__check~.pf-v5-c-table__action {
    margin-inline-start: var(--pf-v5-c-table__action--responsive--MarginLeft);
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__check label {
    display: inline-block;
    margin: 0;
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__favorite {
    margin-block-start: var(--pf-v5-c-table--m-grid__favorite--MarginTop);
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__favorite~.pf-v5-c-table__action {
    margin-inline-start: var(--pf-v5-c-table--m-grid__favorite--action--MarginLeft);
  }

  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__action {
    margin-block-start: var(--pf-v5-c-table--m-grid__action--MarginTop);
    text-align: end;
  }
}

@media screen and (max-width: 1200px)and (max-width: 576px) {
  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__action {
    grid-row-start: 1;
    grid-column-start: 2;
    margin-inline-start: 0;
  }
}

@media screen and (max-width: 1200px) {
  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__inline-edit-action {
    grid-column: 2;
    grid-row: 2;
  }
}

@media screen and (max-width: 1200px) {
  .pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__toggle-icon {
    transition: var(--pf-v5-c-table__toggle__icon--Transition);
  }

  .pf-v5-c-button.pf-m-expanded>.pf-m-grid-xl.pf-v5-c-table .pf-v5-c-table__toggle-icon {
    transform: rotate(var(--pf-v5-c-table__toggle--m-expanded__icon--Rotate));
  }
}

@media screen and (max-width: 1200px) {
  .pf-m-grid-xl.pf-v5-c-table :where(.pf-v5-c-table, .pf-v5-c-table__thead, .pf-v5-c-table__tbody, .pf-v5-c-table__tr, .pf-v5-c-table__th, .pf-v5-c-table__td, .pf-v5-c-table__text).pf-m-nowrap {
    --pf-v5-c-table--cell--Overflow: auto;
  }

  .pf-m-grid-xl.pf-v5-c-table :where(.pf-v5-c-table, .pf-v5-c-table__thead, .pf-v5-c-table__tbody, .pf-v5-c-table__tr, .pf-v5-c-table__th, .pf-v5-c-table__td, .pf-v5-c-table__text).pf-m-fit-content {
    width: auto;
    white-space: normal;
  }

  .pf-m-grid-xl.pf-v5-c-table :where(.pf-v5-c-table, .pf-v5-c-table__thead, .pf-v5-c-table__tbody, .pf-v5-c-table__tr, .pf-v5-c-table__th, .pf-v5-c-table__td, .pf-v5-c-table__text).pf-m-truncate {
    --pf-v5-c-table--cell--MaxWidth: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .pf-m-grid-xl.pf-v5-c-table [class*=pf-m-width] {
    --pf-v5-c-table--cell--Width: auto;
  }
}

@media screen and (max-width: 1450px) {
  .pf-m-grid-2xl.pf-v5-c-table {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-grid--cell--PaddingTop);
    --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table--m-grid--cell--PaddingRight);
    --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-grid--cell--PaddingBottom);
    --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table--m-grid--cell--PaddingLeft);
    --pf-v5-c-table__favorite--c-button--MarginTop: auto;
    --pf-v5-c-table__favorite--c-button--MarginRight: auto;
    --pf-v5-c-table__favorite--c-button--MarginBottom: auto;
    --pf-v5-c-table__favorite--c-button--MarginLeft: auto;
    display: grid;
    border: none;
  }

  .pf-m-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)>* {
    width: auto;
    min-width: 0;
    max-width: none;
    overflow: visible;
    text-overflow: clip;
    white-space: normal;
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__text {
    position: relative;
    width: auto;
    min-width: 0;
    max-width: none;
    overflow: var(--pf-v5-c-table--cell--Overflow);
    text-overflow: var(--pf-v5-c-table--cell--TextOverflow);
    white-space: var(--pf-v5-c-table--cell--WhiteSpace);
  }

  .pf-m-grid-2xl.pf-v5-c-table thead:where(.pf-v5-c-table__thead) {
    display: none;
  }

  .pf-m-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) {
    display: block;
  }

  .pf-m-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody):first-of-type {
    border-block-start: var(--pf-v5-c-table__tbody--responsive--border-width--base) solid var(--pf-v5-c-table--responsive--BorderColor);
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table.pf-m-compact>tbody:where(.pf-v5-c-table__tbody) {
    border-block-start: 0;
  }

  .pf-m-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
    border-block-end: var(--pf-v5-c-table__tr--responsive--border-width--base) solid var(--pf-v5-c-table--responsive--BorderColor);
  }

  .pf-m-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):last-child,
  .pf-m-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody):last-of-type:not(:only-of-type)>tr:where(.pf-v5-c-table__tr) {
    border-block-end-width: var(--pf-v5-c-table__tr--responsive--last-child--BorderBottomWidth);
  }

  .pf-m-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded {
    border-block-end: var(--pf-v5-c-table--border-width--base) solid var(--pf-v5-c-table--BorderColor);
  }

  .pf-m-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
    border-block-end: 0;
  }

  .pf-m-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded:not(:last-of-type) {
    border-block-end: var(--pf-v5-c-table__tbody--responsive--border-width--base) solid var(--pf-v5-c-table--responsive--BorderColor);
  }

  .pf-m-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-selected {
    --pf-v5-c-table__expandable-row--after--BorderLeftWidth: 0;
    --pf-v5-c-table__expandable-row--after--BorderColor: transparent;
  }

  .pf-m-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
    display: grid;
    grid-template-columns: 1fr;
    height: auto;
    grid-auto-columns: max-content;
    grid-column-gap: var(--pf-v5-c-table__tr--responsive--GridColumnGap);
    padding-block-start: var(--pf-v5-c-table__tr--responsive--PaddingTop);
    padding-inline-end: var(--pf-v5-c-table__tr--responsive--PaddingRight);
    padding-block-end: var(--pf-v5-c-table__tr--responsive--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-table__tr--responsive--PaddingLeft);
  }

  .pf-m-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row)>* {
    padding-block-start: var(--pf-v5-c-table--cell--responsive--PaddingTop);
    padding-inline-end: var(--pf-v5-c-table--cell--responsive--PaddingRight);
    padding-block-end: var(--pf-v5-c-table--cell--responsive--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-table--cell--responsive--PaddingLeft);
  }

  .pf-m-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row)>*:first-child {
    --pf-v5-c-table--cell--responsive--PaddingTop: var(--pf-v5-c-table--cell--first-child--responsive--PaddingTop);
  }

  .pf-m-grid-2xl.pf-v5-c-table.pf-m-compact {
    --pf-v5-c-table__tr--responsive--PaddingTop: var(--pf-v5-c-table--m-compact__tr--responsive--PaddingTop);
    --pf-v5-c-table__tr--responsive--PaddingBottom: var(--pf-v5-c-table--m-compact__tr--responsive--PaddingBottom);
    --pf-v5-c-table--cell--responsive--PaddingTop: var(--pf-v5-c-table--m-compact__tr__td--responsive--PaddingTop);
    --pf-v5-c-table--cell--responsive--PaddingBottom: var(--pf-v5-c-table--m-compact__tr__td--responsive--PaddingBottom);
    --pf-v5-c-table__check--responsive--MarginTop: var(--pf-v5-c-table--m-compact__check--responsive--MarginTop);
    --pf-v5-c-table__check--input--MarginTop: 0;
  }

  .pf-m-grid-2xl.pf-v5-c-table.pf-m-compact .pf-v5-c-table__action {
    margin-block-start: var(--pf-v5-c-table--m-compact__action--responsive--MarginTop);
    margin-block-end: var(--pf-v5-c-table--m-compact__action--responsive--MarginTop);
  }

  .pf-m-grid-2xl.pf-v5-c-table.pf-m-compact .pf-v5-c-table__toggle .pf-v5-c-button {
    margin-block-end: var(--pf-v5-c-table--m-compact__toggle--c-button--responsive--MarginBottom);
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__icon>* {
    text-align: start;
  }

  .pf-m-grid-2xl.pf-v5-c-table :where(.pf-v5-c-table__th, .pf-v5-c-table__td)[data-label] {
    --pf-v5-c-table--cell--hidden-visible--Display: var(--pf-v5-c-table--m-grid--cell--hidden-visible--Display);
    grid-column: 1;
    grid-column-gap: var(--pf-v5-c-table-td--responsive--GridColumnGap);
    grid-template-columns: 1fr minmax(0, 1.5fr);
    align-items: start;
  }

  .pf-m-grid-2xl.pf-v5-c-table :where(.pf-v5-c-table__th, .pf-v5-c-table__td)[data-label]>* {
    grid-column: 2;
  }

  .pf-m-grid-2xl.pf-v5-c-table :where(.pf-v5-c-table__th, .pf-v5-c-table__td)[data-label]::before {
    font-weight: bold;
    text-align: start;
    content: attr(data-label);
  }

  .pf-m-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)>*:first-child {
    --pf-v5-c-table--cell--PaddingLeft: 0;
  }

  .pf-m-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)>*:last-child {
    --pf-v5-c-table--cell--PaddingRight: 0;
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table {
    --pf-v5-c-table__tr--responsive--PaddingTop: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingTop);
    --pf-v5-c-table__tr--responsive--PaddingRight: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingRight);
    --pf-v5-c-table__tr--responsive--PaddingBottom: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingBottom);
    --pf-v5-c-table__tr--responsive--PaddingLeft: var(--pf-v5-c-table__tr--responsive--nested-table--PaddingLeft);
    border: 0;
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row)+tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
    --pf-v5-c-table__tr--responsive--PaddingTop: 0;
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__compound-expansion-toggle {
    --pf-v5-c-table__compound-expansion-toggle__button--before--BorderRightWidth: 0;
    --pf-v5-c-table__compound-expansion-toggle__button--before--BorderLeftWidth: 0;
    --pf-v5-c-table__compound-expansion-toggle__button--after--Top: 100%;
  }

  .pf-m-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) {
    position: relative;
  }

  .pf-m-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody)::after {
    position: absolute;
    inset-block-start: 0;
    inset-block-end: 0;
    inset-inline-start: 0;
    content: "";
    border: 0;
    border-inline-start: var(--pf-v5-c-table__tbody--after--BorderLeftWidth) solid var(--pf-v5-c-table__tbody--after--BorderColor);
  }

  .pf-m-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded {
    --pf-v5-c-table__tbody--after--BorderLeftWidth: var(--pf-v5-c-table__tbody--after--border-width--base);
  }

  .pf-m-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded tbody:where(.pf-v5-c-table__tbody) {
    --pf-v5-c-table__tbody--after--BorderLeftWidth: 0;
  }

  .pf-m-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody)>tr:where(.pf-v5-c-table__tr)>:first-child:not(.pf-v5-c-table__check)::after {
    --pf-v5-c-table__expandable-row--after--BorderLeftWidth: 0;
    position: static;
    width: auto;
    background-color: transparent;
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__expandable-row {
    --pf-v5-c-table--cell--responsive--PaddingTop: 0;
    --pf-v5-c-table--cell--responsive--PaddingRight: 0;
    --pf-v5-c-table--cell--responsive--PaddingBottom: 0;
    --pf-v5-c-table--cell--responsive--PaddingLeft: 0;
    --pf-v5-c-table--cell--PaddingRight: 0;
    --pf-v5-c-table--cell--PaddingLeft: 0;
    display: block;
    max-height: var(--pf-v5-c-table__expandable-row--MaxHeight);
    overflow-y: auto;
    border-block-end: none;
    box-shadow: none;
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__expandable-row>* {
    position: static;
    display: block;
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__expandable-row.pf-m-expanded {
    border-block-start-color: var(--pf-v5-c-table--BorderColor);
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__expandable-row>:first-child:not(.pf-v5-c-table__check)::after {
    content: none;
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__expandable-row th:where(.pf-v5-c-table__th).pf-m-no-padding .pf-v5-c-table__expandable-row-content,
  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__expandable-row td:where(.pf-v5-c-table__td).pf-m-no-padding .pf-v5-c-table__expandable-row-content {
    padding: 0;
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__expandable-row:not(.pf-m-expanded) {
    display: none;
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__expandable-row .pf-v5-c-table__expandable-row-content {
    padding-inline-end: var(--pf-v5-c-table__expandable-row-content--responsive--PaddingRight);
    padding-inline-start: var(--pf-v5-c-table__expandable-row-content--responsive--PaddingLeft);
  }

  .pf-m-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable {
    --pf-v5-c-table__tbody--after--BorderLeftWidth: 0;
    --pf-v5-c-table__tbody--after--BorderLeftColor: transparent;
  }

  .pf-m-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable,
  .pf-m-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable>tr:where(.pf-v5-c-table__tr) {
    position: relative;
  }

  .pf-m-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable>tr:where(.pf-v5-c-table__tr)::after {
    content: "";
    position: absolute;
    inset-block-start: 0;
    inset-block-end: 0;
    inset-inline-start: 0;
    width: var(--pf-v5-c-table__tbody--after__tr--BorderLeftWidth);
    background-color: var(--pf-v5-c-table__tbody--after__tr--BorderLeftColor);
  }

  .pf-m-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable.pf-m-expanded {
    --pf-v5-c-table__tbody--after__tr--BorderLeftWidth: var(--pf-v5-c-table__tbody--m-expanded--after__tr--BorderLeftWidth);
    --pf-v5-c-table__tbody--after__tr--BorderLeftColor: var(--pf-v5-c-table__tbody--m-expanded--after__tr--BorderLeftColor);
  }

  .pf-m-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-hoverable.pf-m-selected {
    --pf-v5-c-table__tbody--after__tr--BorderLeftWidth: var(--pf-v5-c-table__tbody--m-selected--after__tr--BorderLeftWidth);
    --pf-v5-c-table__tbody--after__tr--BorderLeftColor: var(--pf-v5-c-table__tbody--m-selected--after__tr--BorderLeftColor);
  }

  .pf-m-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-selected::after {
    content: "";
    position: absolute;
    inset-block-start: 0;
    inset-block-end: 0;
    inset-inline-start: 0;
    width: var(--pf-v5-c-table__tr--m-selected--after--BorderLeftWidth);
    background-color: var(--pf-v5-c-table__tr--m-selected--after--BorderLeftColor);
  }

  .pf-m-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__toggle,
  .pf-m-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__check,
  .pf-m-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__favorite,
  .pf-m-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) .pf-v5-c-table__action {
    width: auto;
    padding: 0;
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__toggle {
    grid-row-start: 20;
    grid-column: -1;
    justify-self: end;
    padding-inline-end: 0;
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__toggle::after {
    content: none;
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__button {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-grid--cell--PaddingTop);
    --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table--m-grid--cell--PaddingRight);
    --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-grid--cell--PaddingBottom);
    --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table--m-grid--cell--PaddingLeft);
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__check,
  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__favorite,
  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__action {
    grid-row-start: 1;
    grid-column-start: 2;
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__check {
    margin-block-start: var(--pf-v5-c-table__check--responsive--MarginTop);
    margin-inline-start: var(--pf-v5-c-table__check--responsive--MarginLeft);
    line-height: 1;
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__check~.pf-v5-c-table__favorite {
    margin-inline-start: var(--pf-v5-c-table--m-grid__check--favorite--MarginLeft);
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__check~.pf-v5-c-table__favorite~.pf-v5-c-table__action {
    margin-inline-start: var(--pf-v5-c-table--m-grid__check--favorite--action--MarginLeft);
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__check~.pf-v5-c-table__action {
    margin-inline-start: var(--pf-v5-c-table__action--responsive--MarginLeft);
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__check label {
    display: inline-block;
    margin: 0;
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__favorite {
    margin-block-start: var(--pf-v5-c-table--m-grid__favorite--MarginTop);
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__favorite~.pf-v5-c-table__action {
    margin-inline-start: var(--pf-v5-c-table--m-grid__favorite--action--MarginLeft);
  }

  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__action {
    margin-block-start: var(--pf-v5-c-table--m-grid__action--MarginTop);
    text-align: end;
  }
}

@media screen and (max-width: 1450px)and (max-width: 576px) {
  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__action {
    grid-row-start: 1;
    grid-column-start: 2;
    margin-inline-start: 0;
  }
}

@media screen and (max-width: 1450px) {
  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__inline-edit-action {
    grid-column: 2;
    grid-row: 2;
  }
}

@media screen and (max-width: 1450px) {
  .pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__toggle-icon {
    transition: var(--pf-v5-c-table__toggle__icon--Transition);
  }

  .pf-v5-c-button.pf-m-expanded>.pf-m-grid-2xl.pf-v5-c-table .pf-v5-c-table__toggle-icon {
    transform: rotate(var(--pf-v5-c-table__toggle--m-expanded__icon--Rotate));
  }
}

@media screen and (max-width: 1450px) {
  .pf-m-grid-2xl.pf-v5-c-table :where(.pf-v5-c-table, .pf-v5-c-table__thead, .pf-v5-c-table__tbody, .pf-v5-c-table__tr, .pf-v5-c-table__th, .pf-v5-c-table__td, .pf-v5-c-table__text).pf-m-nowrap {
    --pf-v5-c-table--cell--Overflow: auto;
  }

  .pf-m-grid-2xl.pf-v5-c-table :where(.pf-v5-c-table, .pf-v5-c-table__thead, .pf-v5-c-table__tbody, .pf-v5-c-table__tr, .pf-v5-c-table__th, .pf-v5-c-table__td, .pf-v5-c-table__text).pf-m-fit-content {
    width: auto;
    white-space: normal;
  }

  .pf-m-grid-2xl.pf-v5-c-table :where(.pf-v5-c-table, .pf-v5-c-table__thead, .pf-v5-c-table__tbody, .pf-v5-c-table__tr, .pf-v5-c-table__th, .pf-v5-c-table__td, .pf-v5-c-table__text).pf-m-truncate {
    --pf-v5-c-table--cell--MaxWidth: 100%;
  }
}

@media screen and (max-width: 1450px) {
  .pf-m-grid-2xl.pf-v5-c-table [class*=pf-m-width] {
    --pf-v5-c-table--cell--Width: auto;
  }
}

.pf-v5-c-table {
  --pf-v5-c-table--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-table--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-table--border-width--base: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-table__caption--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-table__caption--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-table__caption--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table__caption--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table__caption--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table__caption--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table__caption--xl--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table__caption--xl--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table__thead--cell--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-table__thead--cell--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-table__tbody--cell--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table__tbody--cell--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table__tr--BoxShadow--top--base: 0 -0.1875rem 0.25rem -0.125rem rgba(3,3,3,.08);
  --pf-v5-c-table--cell--Padding--base: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table--cell--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-table--cell--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-table--cell--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--cell--Padding--base);
  --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table--cell--Padding--base);
  --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--cell--Padding--base);
  --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table--cell--Padding--base);
  --pf-v5-c-table--cell--first-last-child--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table--cell--first-last-child--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table--cell--first-last-child--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table--cell--first-last-child--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table__tr--m-first-cell-offset-reset--cell--PaddingLeft: var(--pf-v5-c-table--cell--Padding--base);
  --pf-v5-c-table--cell--MinWidth: 0;
  --pf-v5-c-table--cell--MaxWidth: none;
  --pf-v5-c-table--cell--Width: auto;
  --pf-v5-c-table--cell--Overflow: visible;
  --pf-v5-c-table--cell--TextOverflow: clip;
  --pf-v5-c-table--cell--WhiteSpace: normal;
  --pf-v5-c-table--cell--WordBreak: normal;
  --pf-v5-c-table--cell--m-border-right--before--BorderRightWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-table--cell--m-border-right--before--BorderRightColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-table--cell--m-border-left--before--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-table--cell--m-border-left--before--BorderLeftColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-table--cell--m-help--MinWidth: 11ch;
  --pf-v5-c-table--m-truncate--cell--MaxWidth: 1px;
  --pf-v5-c-table--m-truncate--cell--MinWidth: calc(5ch + var(--pf-v5-c-table--cell--PaddingRight) + var(--pf-v5-c-table--cell--PaddingLeft));
  --pf-v5-c-table--cell--hidden-visible--Display: table-cell;
  --pf-v5-c-table__toggle--c-button--MarginTop: calc(0.375rem * -1);
  --pf-v5-c-table__toggle--c-button--MarginBottom: calc(0.375rem * -1);
  --pf-v5-c-table__toggle--c-button__toggle-icon--Rotate: 270deg;
  --pf-v5-c-table__toggle--c-button__toggle-icon--Transition: .2s ease-in 0s;
  --pf-v5-c-table__toggle--c-button--m-expanded__toggle-icon--Rotate: 360deg;
  --pf-v5-c-table__button--BackgroundColor: transparent;
  --pf-v5-c-table__button--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-table__button--hover--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-table__button--focus--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-table__button--active--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-table__button--OutlineOffset: calc(var(--pf-v5-global--BorderWidth--lg) * -1);
  --pf-v5-c-table--m-compact__toggle--PaddingTop: 0;
  --pf-v5-c-table--m-compact__toggle--PaddingBottom: 0;
  --pf-v5-c-table__check--input--MarginTop: 0.1875rem;
  --pf-v5-c-table__thead__check--input--TranslateY: var(--pf-v5-c-table__check--input--MarginTop);
  --pf-v5-c-table__check--input--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-table--cell--m-favorite--Color: var(--pf-v5-global--Color--light-300);
  --pf-v5-c-table__favorite--c-button--Color: var(--pf-v5-global--Color--light-300);
  --pf-v5-c-table__favorite--c-button--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-table__favorite--c-button--MarginTop: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-table__favorite--c-button--MarginRight: calc(var(--pf-v5-global--spacer--md) * -1);
  --pf-v5-c-table__favorite--c-button--MarginBottom: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-table__favorite--c-button--MarginLeft: calc(var(--pf-v5-global--spacer--md) * -1);
  --pf-v5-c-table__favorite--m-favorited--c-button--Color: var(--pf-v5-global--palette--gold-400);
  --pf-v5-c-table__sort--m-favorite__button__text--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-table__sort--m-favorite__button--hover__text--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-table__sort--m-favorite__button--focus__text--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-table__sort--m-favorite__button--active__text--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-table__draggable--c-button--MarginTop: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-table__draggable--c-button--MarginRight: calc(var(--pf-v5-global--spacer--md) * -1);
  --pf-v5-c-table__draggable--c-button--MarginBottom: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-table__draggable--c-button--MarginLeft: calc(var(--pf-v5-global--spacer--md) * -1);
  --pf-v5-c-table__tr--m-ghost-row--Opacity: .4;
  --pf-v5-c-table__tr--m-ghost-row--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-table__action--PaddingTop: 0;
  --pf-v5-c-table__action--PaddingRight: 0;
  --pf-v5-c-table__action--PaddingBottom: 0;
  --pf-v5-c-table__action--PaddingLeft: 0;
  --pf-v5-c-table__inline-edit-action--PaddingTop: 0;
  --pf-v5-c-table__inline-edit-action--PaddingRight: 0;
  --pf-v5-c-table__inline-edit-action--PaddingBottom: 0;
  --pf-v5-c-table__inline-edit-action--PaddingLeft: 0;
  --pf-v5-c-table__expandable-row--Transition: var(--pf-v5-global--Transition);
  --pf-v5-c-table__expandable-row--MaxHeight: 28.125rem;
  --pf-v5-c-table__expandable-row-content--Transition: var(--pf-v5-global--Transition);
  --pf-v5-c-table__expandable-row-content--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table__expandable-row-content--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table__expandable-row--after--Top: calc(var(--pf-v5-c-table--border-width--base) * -1);
  --pf-v5-c-table__expandable-row--after--Bottom: calc(var(--pf-v5-c-table--border-width--base) * -1);
  --pf-v5-c-table__expandable-row--after--border-width--base: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-table__expandable-row--after--BorderLeftWidth: 0;
  --pf-v5-c-table__expandable-row--after--BorderColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-table__icon-inline--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table__sort--MinWidth: calc(6ch + var(--pf-v5-c-table--cell--PaddingRight) + var(--pf-v5-c-table--cell--PaddingLeft) + var(--pf-v5-c-table__sort-indicator--MarginLeft));
  --pf-v5-c-table__sort__button--PaddingTop: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-table__sort__button--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table__sort__button--PaddingBottom: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-table__sort__button--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table__sort__button--MarginTop: calc(var(--pf-v5-c-table__sort__button--PaddingTop) * -1);
  --pf-v5-c-table__sort__button--MarginBottom: calc(var(--pf-v5-c-table__sort__button--PaddingBottom) * -1);
  --pf-v5-c-table__sort__button--MarginLeft: calc(var(--pf-v5-c-table__sort__button--PaddingLeft) * -1);
  --pf-v5-c-table__sort__button--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-table__sort--m-selected__button--Color: var(--pf-v5-global--active-color--100);
  --pf-v5-c-table__sort--m-help--MinWidth: 15ch;
  --pf-v5-c-table__sort__button__text--Color: currentcolor;
  --pf-v5-c-table__sort__button--hover__text--Color: currentcolor;
  --pf-v5-c-table__sort__button--focus__text--Color: currentcolor;
  --pf-v5-c-table__sort__button--active__text--Color: currentcolor;
  --pf-v5-c-table__sort-indicator--Color: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-table__sort-indicator--MarginLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table__sort--m-selected__sort-indicator--Color: var(--pf-v5-global--active-color--100);
  --pf-v5-c-table__sort__button--hover__sort-indicator--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-table__sort__button--active__sort-indicator--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-table__sort__button--focus__sort-indicator--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-table__th--m-help--MinWidth: 11ch;
  --pf-v5-c-table__column-help--MarginLeft: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-table__column-help--TranslateY: 0.125rem;
  --pf-v5-c-table__column-help--c-button--MarginTop: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-table__column-help--c-button--MarginBottom: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-table__column-help--c-button--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table__column-help--c-button--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table__compound-expansion-toggle__button--Color: var(--pf-v5-global--active-color--100);
  --pf-v5-c-table__compound-expansion-toggle__button--hover--Color: var(--pf-v5-global--link--Color--hover);
  --pf-v5-c-table__compound-expansion-toggle__button--focus--Color: var(--pf-v5-global--link--Color--hover);
  --pf-v5-c-table__compound-expansion-toggle__button--active--Color: var(--pf-v5-global--link--Color--hover);
  --pf-v5-c-table__compound-expansion-toggle__button--before--border-width--base: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-table__compound-expansion-toggle__button--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-table__compound-expansion-toggle__button--before--BorderRightWidth: 0;
  --pf-v5-c-table__compound-expansion-toggle__button--before--BorderLeftWidth: 0;
  --pf-v5-c-table__compound-expansion-toggle__button--before--Bottom: calc(var(--pf-v5-c-table__compound-expansion-toggle__button--before--border-width--base) * -1);
  --pf-v5-c-table__compound-expansion-toggle__button--before--Left: calc(var(--pf-v5-c-table__compound-expansion-toggle__button--before--border-width--base) * -1);
  --pf-v5-c-table__compound-expansion-toggle__button--after--border-width--base: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-table__compound-expansion-toggle__button--after--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-table__compound-expansion-toggle__button--after--BorderTopWidth: 0;
  --pf-v5-c-table__compound-expansion-toggle__button--after--Top: calc(var(--pf-v5-c-table__compound-expansion-toggle__button--before--border-width--base) * -1);
  --pf-v5-c-table__compound-expansion-toggle__button--after--Left: calc(var(--pf-v5-c-table__compound-expansion-toggle__button--before--border-width--base) * -1);
  --pf-v5-c-table--m-compact__th--PaddingTop: calc(var(--pf-v5-global--spacer--sm) + var(--pf-v5-global--spacer--xs));
  --pf-v5-c-table--m-compact__th--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table--m-compact--cell--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table--m-compact--cell--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table--m-compact--cell--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table--m-compact--cell--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table--m-compact--cell--first-last-child--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table--m-compact--cell--first-last-child--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table--m-compact--cell--first-last-child--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table--m-compact--cell--first-last-child--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table--m-compact--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-table--m-compact__expandable-row-content--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table--m-compact__expandable-row-content--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table--m-compact__expandable-row-content--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table--m-compact__expandable-row-content--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-table--nested--first-last-child--PaddingRight: var(--pf-v5-c-table--m-compact--cell--first-last-child--PaddingLeft);
  --pf-v5-c-table--nested--first-last-child--PaddingLeft: var(--pf-v5-c-table--m-compact--cell--first-last-child--PaddingRight);
  --pf-v5-c-table__expandable-row--m-expanded--BorderBottomColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-table__tr--m-clickable--BoxShadow--top: var(--pf-v5-c-table__tr--BoxShadow--top--base);
  --pf-v5-c-table__tr--m-clickable--BackgroundColor: transparent;
  --pf-v5-c-table__tr--m-clickable--BoxShadow: none;
  --pf-v5-c-table__tr--m-clickable--OutlineOffset: calc(-1 * var(--pf-v5-global--spacer--xs));
  --pf-v5-c-table__tr--m-clickable--hover--BoxShadow: var(--pf-v5-c-table__tr--m-clickable--BoxShadow--top), var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-table__tr--m-clickable--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-table__tr--m-clickable--focus--BoxShadow: var(--pf-v5-c-table__tr--m-clickable--BoxShadow--top), var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-table__tr--m-clickable--focus--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-table__tr--m-clickable--active--BoxShadow: var(--pf-v5-c-table__tr--m-clickable--BoxShadow--top), var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-table__tr--m-clickable--active--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-table__tr--m-clickable--m-selected--BoxShadow: var(--pf-v5-global--BoxShadow--sm-bottom) inset, var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-table__tr--m-selected--BoxShadow--top: var(--pf-v5-c-table__tr--BoxShadow--top--base);
  --pf-v5-c-table__tr--m-selected--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-table__tr--m-selected--BoxShadow: var(--pf-v5-c-table__tr--m-selected--BoxShadow--top), var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-table__tr--m-selected--OutlineOffset: calc(-1 * var(--pf-v5-global--spacer--xs));
  --pf-v5-c-table__tr--m-selected--after--BorderLeftWidth: calc(2 * var(--pf-v5-c-table__expandable-row--after--border-width--base));
  --pf-v5-c-table__tr--m-selected--after--BorderLeftColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-table__tr--m-selected--m-selected--BoxShadow: var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-table__tr--m-selected--hover__tr--m-selected--BoxShadow: var(--pf-v5-global--BoxShadow--sm-bottom) inset, var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-table__tr--m-selected__tr--m-selected--hover--BoxShadow: var(--pf-v5-c-table__tr--m-selected--BoxShadow--top), var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-table__tbody--m-clickable--BoxShadow--top: var(--pf-v5-c-table__tr--BoxShadow--top--base);
  --pf-v5-c-table__tbody--m-clickable--BoxShadow: none;
  --pf-v5-c-table__tbody--m-clickable--BackgroundColor: transparent;
  --pf-v5-c-table__tbody--m-clickable--OutlineOffset: calc(-1 * var(--pf-v5-global--spacer--xs));
  --pf-v5-c-table__tbody--m-clickable--hover--BoxShadow: var(--pf-v5-c-table__tbody--m-clickable--BoxShadow--top), var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-table__tbody--m-clickable--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-table__tbody--m-clickable--focus--BoxShadow: var(--pf-v5-c-table__tbody--m-clickable--BoxShadow--top), var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-table__tbody--m-clickable--focus--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-table__tbody--m-clickable--active--BoxShadow: var(--pf-v5-c-table__tbody--m-clickable--BoxShadow--top), var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-table__tbody--m-clickable--active--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-table__tbody--m-clickable--m-expanded--BorderColor: var(--pf-v5-global--active-color--400);
  --pf-v5-c-table__tbody--m-clickable--m-selected--hover__tr--BoxShadow: var(--pf-v5-global--BoxShadow--sm-bottom) inset, var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-table__tbody--m-selected--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-table__tbody--m-selected--BoxShadow--top: var(--pf-v5-c-table__tr--BoxShadow--top--base);
  --pf-v5-c-table__tbody--m-selected--BoxShadow: var(--pf-v5-c-table__tbody--m-selected--BoxShadow--top), var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-table__tbody--m-selected--OutlineOffset: calc(-1 * var(--pf-v5-global--spacer--xs));
  --pf-v5-c-table__tbody--m-selected--after--BorderLeftWidth: calc(2 * var(--pf-v5-c-table__expandable-row--after--border-width--base));
  --pf-v5-c-table__tbody--m-selected--after--BorderLeftColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-table__tbody--m-selected--m-selected--BoxShadow: var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-table__tbody--m-selected--hover__tbody--m-selected--BoxShadow: var(--pf-v5-global--BoxShadow--sm-bottom) inset, var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-table__tbody--m-selected__tbody--m-selected--hover--BoxShadow: var(--pf-v5-c-table__tr--m-selected--BoxShadow--top), var(--pf-v5-global--BoxShadow--sm-bottom);
  --pf-v5-c-table__thead--m-nested-column-header--button--OutlineOffset: -0.1875rem;
  --pf-v5-c-table__thead--m-nested-column-header__tr--PaddingTop: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-table__thead--m-nested-column-header__tr--PaddingBottom: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-table__subhead--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-table--m-striped__tr--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-200);
  --pf-v5-c-table--m-sticky-header--cell--ZIndex: var(--pf-v5-global--ZIndex--xs);
  color: var(--pf-v5-global--Color--100);
  width: 100%;
  background-color: var(--pf-v5-c-table--BackgroundColor);
}

@media screen and (max-width: 1200px) {
  .pf-v5-c-table {
    --pf-v5-c-table__caption--PaddingRight: var(--pf-v5-c-table__caption--xl--PaddingRight);
    --pf-v5-c-table__caption--PaddingLeft: var(--pf-v5-c-table__caption--xl--PaddingLeft);
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-c-table {
    --pf-v5-c-table--cell--first-last-child--PaddingRight: var(--pf-v5-c-table--cell--first-last-child--xl--PaddingRight);
    --pf-v5-c-table--cell--first-last-child--PaddingLeft: var(--pf-v5-c-table--cell--first-last-child--xl--PaddingLeft);
    --pf-v5-c-table--m-compact--cell--first-last-child--PaddingLeft: var(--pf-v5-c-table--m-compact--cell--first-last-child--xl--PaddingLeft);
    --pf-v5-c-table--m-compact--cell--first-last-child--PaddingRight: var(--pf-v5-c-table--m-compact--cell--first-last-child--xl--PaddingRight);
  }
}

.pf-v5-c-table.pf-m-fixed {
  table-layout: fixed;
}

.pf-v5-c-table.pf-m-sticky-header {
  position: relative;
}

.pf-v5-c-table.pf-m-sticky-header thead:where(.pf-v5-c-table__thead) tr:where(.pf-v5-c-table__tr) {
  border-block-end: 0;
}

.pf-v5-c-table.pf-m-sticky-header thead:where(.pf-v5-c-table__thead) tr:where(.pf-v5-c-table__tr)>* {
  z-index: var(--pf-v5-c-table--m-sticky-header--cell--ZIndex);
}

.pf-v5-c-table.pf-m-sticky-header>thead:where(.pf-v5-c-table__thead):not(.pf-m-nested-column-header)>tr:where(.pf-v5-c-table__tr)>* {
  position: sticky;
  inset-block-start: 0;
  background: var(--pf-v5-c-table--BackgroundColor);
}

.pf-v5-c-table.pf-m-sticky-header>thead:where(.pf-v5-c-table__thead):not(.pf-m-nested-column-header)>tr:where(.pf-v5-c-table__tr)>*::after {
  position: absolute;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  content: "";
  border-block-end: var(--pf-v5-c-table--border-width--base) solid var(--pf-v5-c-table--BorderColor);
}

.pf-v5-c-table.pf-m-sticky-header>.pf-m-nested-column-header {
  position: sticky;
  inset-block-start: 0;
  z-index: var(--pf-v5-global--ZIndex--xs);
  background: var(--pf-v5-c-table--BackgroundColor);
}

.pf-v5-c-table.pf-m-sticky-header>.pf-m-nested-column-header>.pf-m-border-row {
  height: var(--pf-v5-c-table--border-width--base);
  background-color: var(--pf-v5-c-table--BorderColor);
}

.pf-v5-c-table.pf-m-sticky-header>.pf-m-nested-column-header tr:where(.pf-v5-c-table__tr):not(:nth-last-child(2)) th:where(.pf-v5-c-table__th):not([rowspan]),
.pf-v5-c-table.pf-m-sticky-header>.pf-m-nested-column-header tr:where(.pf-v5-c-table__tr):not(:nth-last-child(2)) td:where(.pf-v5-c-table__td):not([rowspan]) {
  --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table__thead--m-nested-column-header__tr--PaddingBottom);
}

.pf-v5-c-table:not(.pf-m-sticky-header)>.pf-m-nested-column-header tr:where(.pf-v5-c-table__tr):not(:last-child) {
  border-block-end: 0;
}

.pf-v5-c-table:not(.pf-m-sticky-header)>.pf-m-nested-column-header tr:where(.pf-v5-c-table__tr):not(:last-child) th:where(.pf-v5-c-table__th):not([rowspan]),
.pf-v5-c-table:not(.pf-m-sticky-header)>.pf-m-nested-column-header tr:where(.pf-v5-c-table__tr):not(:last-child) td:where(.pf-v5-c-table__td):not([rowspan]) {
  --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table__thead--m-nested-column-header__tr--PaddingBottom);
}

.pf-v5-c-table.pf-m-striped:not(.pf-m-expandable)>tbody:where(.pf-v5-c-table__tbody)>tr:where(.pf-v5-c-table__tr):nth-child(odd),
.pf-v5-c-table.pf-m-striped.pf-m-expandable>tbody:where(.pf-v5-c-table__tbody):nth-of-type(odd)>tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row),
.pf-v5-c-table>.pf-m-striped>tr:nth-child(odd),
.pf-v5-c-table>.pf-m-striped-even>tr:nth-child(even),
.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-striped {
  --pf-v5-c-table__sticky-column--BackgroundColor: var(--pf-v5-c-table--m-striped__tr--BackgroundColor);
  background: var(--pf-v5-c-table--m-striped__tr--BackgroundColor);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
  border-block-end: var(--pf-v5-c-table--border-width--base) solid var(--pf-v5-c-table--BorderColor);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row).pf-m-ghost-row {
  background-color: var(--pf-v5-c-table__tr--m-ghost-row--BackgroundColor);
  opacity: var(--pf-v5-c-table__tr--m-ghost-row--Opacity);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr)>* {
  --pf-v5-hidden-visible--visible--Display: var(--pf-v5-c-table--cell--hidden-visible--Display);
  position: relative;
  width: var(--pf-v5-c-table--cell--Width);
  min-width: var(--pf-v5-c-table--cell--MinWidth);
  max-width: var(--pf-v5-c-table--cell--MaxWidth);
  padding-block-start: var(--pf-v5-c-table--cell--PaddingTop);
  padding-block-end: var(--pf-v5-c-table--cell--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-table--cell--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-table--cell--PaddingRight);
  overflow: var(--pf-v5-c-table--cell--Overflow);
  font-size: var(--pf-v5-c-table--cell--FontSize);
  font-weight: var(--pf-v5-c-table--cell--FontWeight);
  color: var(--pf-v5-c-table--cell--Color);
  text-overflow: var(--pf-v5-c-table--cell--TextOverflow);
  word-break: var(--pf-v5-c-table--cell--WordBreak);
  white-space: var(--pf-v5-c-table--cell--WhiteSpace);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr)>*:first-child {
  --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table--cell--first-last-child--PaddingLeft);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr)>*:last-child {
  --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table--cell--first-last-child--PaddingRight);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr)>*.pf-m-center {
  text-align: center;
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr)>*:empty {
  width: auto;
  min-width: 0;
  padding: 0;
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr)>*.pf-m-help {
  --pf-v5-c-table--cell--MinWidth: var(--pf-v5-c-table--cell--m-help--MinWidth);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr)>*.pf-m-favorite {
  --pf-v5-c-table__button--Color: var(--pf-v5-c-table--cell--m-favorite--Color);
  --pf-v5-c-table__sort--MinWidth: fit-content;
  --pf-v5-c-table--cell--MaxWidth: fit-content;
  --pf-v5-c-table--cell--Overflow: visible;
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr)>*.pf-m-border-right::before,
.pf-v5-c-table tr:where(.pf-v5-c-table__tr)>*.pf-m-border-left::before {
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  pointer-events: none;
  content: "";
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr)>*.pf-m-border-right::before {
  border-inline-end: var(--pf-v5-c-table--cell--m-border-right--before--BorderRightWidth) solid var(--pf-v5-c-table--cell--m-border-right--before--BorderRightColor);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr)>*.pf-m-border-left::before {
  border-inline-start: var(--pf-v5-c-table--cell--m-border-left--before--BorderLeftWidth) solid var(--pf-v5-c-table--cell--m-border-left--before--BorderLeftColor);
}

.pf-v5-c-table caption:where(.pf-v5-c-table__caption) {
  padding-block-start: var(--pf-v5-c-table__caption--PaddingTop);
  padding-block-end: var(--pf-v5-c-table__caption--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-table__caption--PaddingLeft);
  font-size: var(--pf-v5-c-table__caption--FontSize);
  color: var(--pf-v5-c-table__caption--Color);
  text-align: start;
  background-color: var(--pf-v5-c-table--BackgroundColor);
}

.pf-v5-c-table thead:where(.pf-v5-c-table__thead) {
  --pf-v5-c-table--cell--FontSize: var(--pf-v5-c-table__thead--cell--FontSize);
  --pf-v5-c-table--cell--FontWeight: var(--pf-v5-c-table__thead--cell--FontWeight);
  vertical-align: bottom;
}

.pf-v5-c-table thead:where(.pf-v5-c-table__thead).pf-m-nested-column-header button:where(.pf-v5-c-button) {
  outline-offset: var(--pf-v5-c-table__thead--m-nested-column-header--button--OutlineOffset);
}

.pf-v5-c-table thead:where(.pf-v5-c-table__thead).pf-m-nested-column-header th:where(.pf-v5-c-table__th),
.pf-v5-c-table thead:where(.pf-v5-c-table__thead).pf-m-nested-column-header td:where(.pf-v5-c-table__td),
.pf-v5-c-table thead:where(.pf-v5-c-table__thead).pf-m-nested-column-header input:where(.pf-v5-c-check__input) {
  vertical-align: middle;
}

.pf-v5-c-table thead:where(.pf-v5-c-table__thead).pf-m-nested-column-header .pf-v5-c-table__check label {
  display: flex;
  align-items: center;
}

.pf-v5-c-table thead:where(.pf-v5-c-table__thead).pf-m-nested-column-header tr:where(.pf-v5-c-table__tr):not(:first-child) th:where(.pf-v5-c-table__th):not([rowspan]),
.pf-v5-c-table thead:where(.pf-v5-c-table__thead).pf-m-nested-column-header tr:where(.pf-v5-c-table__tr):not(:first-child) td:where(.pf-v5-c-table__td):not([rowspan]) {
  --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table__thead--m-nested-column-header__tr--PaddingTop);
}

.pf-v5-c-table thead:where(.pf-v5-c-table__thead) .pf-v5-c-table__subhead {
  --pf-v5-c-table__sort__button__text--Color: var(--pf-v5-c-table__subhead--Color);
  color: var(--pf-v5-c-table__subhead--Color);
}

.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) {
  --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table__tbody--cell--PaddingTop);
  --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table__tbody--cell--PaddingBottom);
}

.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody)>tr:where(.pf-v5-c-table__tr)>* {
  overflow-wrap: break-word;
  vertical-align: baseline;
}

.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody)>tr:where(.pf-v5-c-table__tr)>:first-child::after {
  position: absolute;
  inset-block-start: var(--pf-v5-c-table__expandable-row--after--Top);
  inset-block-end: var(--pf-v5-c-table__expandable-row--after--Bottom);
  inset-inline-start: 0;
  content: "";
  background-color: transparent;
  border-inline-start: var(--pf-v5-c-table__expandable-row--after--BorderLeftWidth) solid var(--pf-v5-c-table__expandable-row--after--BorderColor);
}

.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) .pf-v5-c-table__check input:where(.pf-v5-c-check__input) {
  margin-block-start: var(--pf-v5-c-table__check--input--MarginTop);
  vertical-align: top;
}

.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-expanded>:first-child:not(.pf-v5-c-table__control-row) {
  border-block-end-width: 0;
}

.pf-v5-c-table .pf-v5-c-table__compound-expansion-toggle,
.pf-v5-c-table .pf-v5-c-table__compound-expansion-toggle:first-child,
.pf-v5-c-table .pf-v5-c-table__compound-expansion-toggle:last-child {
  padding: 0;
}

.pf-v5-c-table .pf-v5-c-table__sort {
  min-width: var(--pf-v5-c-table__sort--MinWidth);
}

.pf-v5-c-table :where(.pf-v5-c-table__th, .pf-v5-c-table__td).pf-m-help {
  min-width: var(--pf-v5-c-table__th--m-help--MinWidth);
}

.pf-v5-c-table>thead,
.pf-v5-c-table :where([class*="pf-v5-c-table"]).pf-m-truncate,
.pf-v5-c-table :where(&)>:is(thead,tbody).pf-m-truncate {
  --pf-v5-c-table--cell--MinWidth: var(--pf-v5-c-table--m-truncate--cell--MinWidth);
  --pf-v5-c-table--cell--MaxWidth: var(--pf-v5-c-table--m-truncate--cell--MaxWidth);
  --pf-v5-c-table--cell--Overflow: hidden;
  --pf-v5-c-table--cell--TextOverflow: ellipsis;
  --pf-v5-c-table--cell--WhiteSpace: nowrap;
}

.pf-v5-c-table :where([class*="pf-v5-c-table"]).pf-m-wrap,
.pf-v5-c-table :where(&)>:is(thead,tbody).pf-m-wrap {
  --pf-v5-c-table--cell--MinWidth: 0;
  --pf-v5-c-table--cell--MaxWidth: none;
  --pf-v5-c-table--cell--Overflow: visible;
  --pf-v5-c-table--cell--TextOverflow: clip;
  --pf-v5-c-table--cell--WhiteSpace: normal;
}

.pf-v5-c-table :where([class*="pf-v5-c-table"]).pf-m-nowrap,
.pf-v5-c-table :where(&)>:is(thead,tbody).pf-m-nowrap {
  --pf-v5-c-table--cell--MinWidth: 0;
  --pf-v5-c-table--cell--MaxWidth: none;
  --pf-v5-c-table--cell--Overflow: visible;
  --pf-v5-c-table--cell--TextOverflow: clip;
  --pf-v5-c-table--cell--WhiteSpace: nowrap;
}

.pf-v5-c-table :where([class*="pf-v5-c-table"]) .pf-v5-c-table__icon,
.pf-v5-c-table :where([class*="pf-v5-c-table"]).pf-m-fit-content,
.pf-v5-c-table :where(&)>:is(thead,tbody) .pf-v5-c-table__icon,
.pf-v5-c-table :where(&)>:is(thead,tbody).pf-m-fit-content {
  --pf-v5-c-table--cell--MinWidth: fit-content;
  --pf-v5-c-table--cell--MaxWidth: none;
  --pf-v5-c-table--cell--Width: 1%;
  --pf-v5-c-table--cell--Overflow: visible;
  --pf-v5-c-table--cell--TextOverflow: clip;
  --pf-v5-c-table--cell--WhiteSpace: nowrap;
}

.pf-v5-c-table :where([class*="pf-v5-c-table"]).pf-m-break-word,
.pf-v5-c-table :where(&)>:is(thead,tbody).pf-m-break-word {
  --pf-v5-c-table--cell--WordBreak: break-word;
  --pf-v5-c-table--cell--WhiteSpace: normal;
}

.pf-v5-c-table.pf-m-no-border-rows>tbody:where(.pf-v5-c-table__tbody)>tr:where(.pf-v5-c-table__tr) {
  border-block-end: 0;
}

.pf-v5-c-table.pf-m-no-border-rows>tbody:where(.pf-v5-c-table__tbody)>tr:where(.pf-v5-c-table__tr)>:first-child::after {
  border-inline-start: 0;
}

.pf-v5-c-table.pf-m-no-border-rows>tbody:where(.pf-v5-c-table__tbody):not(.pf-m-expanded) .pf-v5-c-table__compound-expansion-toggle .pf-v5-c-table__button::before {
  display: none;
}

.pf-v5-c-table.pf-m-no-border-rows>tbody:where(.pf-v5-c-table__tbody).pf-m-expanded>.pf-v5-c-table__control-row {
  border-block-end: var(--pf-v5-c-table--border-width--base) solid var(--pf-v5-c-table--BorderColor);
}

.pf-v5-c-table.pf-m-no-border-rows>tbody:where(.pf-v5-c-table__tbody) .pf-v5-c-table__control-row>.pf-v5-c-table__compound-expansion-toggle:first-child>::before {
  border-inline-start-width: 0;
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-clickable {
  cursor: pointer;
  background-color: var(--pf-v5-c-table__tr--m-clickable--BackgroundColor);
  outline-offset: var(--pf-v5-c-table__tr--m-clickable--OutlineOffset);
  box-shadow: var(--pf-v5-c-table__tr--m-clickable--BoxShadow);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-clickable:hover:not(.pf-m-selected)+tr:where(.pf-v5-c-table__tr).pf-m-selected,
.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-clickable:focus:not(.pf-m-selected)+tr:where(.pf-v5-c-table__tr).pf-m-selected,
.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-clickable:active:not(.pf-m-selected)+tr:where(.pf-v5-c-table__tr).pf-m-selected {
  box-shadow: var(--pf-v5-c-table__tr--m-clickable--m-selected--BoxShadow);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-clickable:hover {
  --pf-v5-c-table__tr--m-clickable--BoxShadow: var(--pf-v5-c-table__tr--m-clickable--hover--BoxShadow);
  --pf-v5-c-table__tr--m-clickable--BackgroundColor: var(--pf-v5-c-table__tr--m-clickable--hover--BackgroundColor);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-clickable:focus {
  --pf-v5-c-table__tr--m-clickable--BoxShadow: var(--pf-v5-c-table__tr--m-clickable--focus--BoxShadow);
  --pf-v5-c-table__tr--m-clickable--BackgroundColor: var(--pf-v5-c-table__tr--m-clickable--focus--BackgroundColor);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-clickable:active {
  --pf-v5-c-table__tr--m-clickable--BoxShadow: var(--pf-v5-c-table__tr--m-clickable--active--BoxShadow);
  --pf-v5-c-table__tr--m-clickable--BackgroundColor: var(--pf-v5-c-table__tr--m-clickable--active--BackgroundColor);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-selected {
  --pf-v5-c-table__expandable-row--after--BorderLeftWidth: var(--pf-v5-c-table__tr--m-selected--after--BorderLeftWidth);
  --pf-v5-c-table__expandable-row--after--BorderColor: var(--pf-v5-c-table__tr--m-selected--after--BorderLeftColor);
  position: relative;
  background-color: var(--pf-v5-c-table__tr--m-selected--BackgroundColor);
  outline-offset: var(--pf-v5-c-table__tr--m-selected--OutlineOffset);
  box-shadow: var(--pf-v5-c-table__tr--m-selected--BoxShadow);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-selected+tr:where(.pf-v5-c-table__tr).pf-m-selected {
  box-shadow: var(--pf-v5-c-table__tr--m-selected--m-selected--BoxShadow);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-selected+tr:where(.pf-v5-c-table__tr).pf-m-selected:hover {
  box-shadow: var(--pf-v5-c-table__tr--m-selected__tr--m-selected--hover--BoxShadow);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-selected:hover+tr:where(.pf-v5-c-table__tr).pf-m-selected {
  box-shadow: var(--pf-v5-c-table__tr--m-selected--hover__tr--m-selected--BoxShadow);
}

.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-first-cell-offset-reset {
  --pf-v5-c-table--cell--first-last-child--PaddingLeft: var(--pf-v5-c-table__tr--m-first-cell-offset-reset--cell--PaddingLeft);
}

.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-clickable {
  cursor: pointer;
  background-color: var(--pf-v5-c-table__tbody--m-clickable--BackgroundColor);
  outline-offset: var(--pf-v5-c-table__tbody--m-clickable--OutlineOffset);
  box-shadow: var(--pf-v5-c-table__tbody--m-clickable--BoxShadow);
}

.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-clickable.pf-m-expanded:not(.pf-m-selected) {
  --pf-v5-c-table__expandable-row--after--BorderColor: var(--pf-v5-c-table__tbody--m-clickable--m-expanded--BorderColor);
}

.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-clickable:hover:not(.pf-m-selected)+tbody:where(.pf-v5-c-table__tbody).pf-m-selected,
.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-clickable:focus:not(.pf-m-selected)+tbody:where(.pf-v5-c-table__tbody).pf-m-selected,
.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-clickable:active:not(.pf-m-selected)+tbody:where(.pf-v5-c-table__tbody).pf-m-selected {
  box-shadow: var(--pf-v5-c-table__tbody--m-clickable--m-selected--hover__tr--BoxShadow);
}

.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-clickable:hover {
  --pf-v5-c-table__tbody--m-clickable--BoxShadow: var(--pf-v5-c-table__tbody--m-clickable--hover--BoxShadow);
  --pf-v5-c-table__tbody--m-clickable--BackgroundColor: var(--pf-v5-c-table__tbody--m-clickable--hover--BackgroundColor);
}

.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-clickable:focus {
  --pf-v5-c-table__tbody--m-clickable--BoxShadow: var(--pf-v5-c-table__tbody--m-clickable--focus--BoxShadow);
  --pf-v5-c-table__tbody--m-clickable--BackgroundColor: var(--pf-v5-c-table__tbody--m-clickable--focus--BackgroundColor);
}

.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-clickable:active {
  --pf-v5-c-table__tbody--m-clickable--BoxShadow: var(--pf-v5-c-table__tbody--m-clickable--active--BoxShadow);
  --pf-v5-c-table__tbody--m-clickable--BackgroundColor: var(--pf-v5-c-table__tbody--m-clickable--active--BackgroundColor);
}

.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-selected {
  --pf-v5-c-table__expandable-row--after--BorderLeftWidth: var(--pf-v5-c-table__tbody--m-selected--after--BorderLeftWidth);
  --pf-v5-c-table__expandable-row--after--BorderColor: var(--pf-v5-c-table__tbody--m-selected--after--BorderLeftColor);
  background-color: var(--pf-v5-c-table__tbody--m-selected--BackgroundColor);
  outline-offset: var(--pf-v5-c-table__tbody--m-selected--OutlineOffset);
  box-shadow: var(--pf-v5-c-table__tbody--m-selected--BoxShadow);
}

.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-selected+tbody:where(.pf-v5-c-table__tbody).pf-m-selected {
  --pf-v5-c-table__tbody--m-selected--BoxShadow: var(--pf-v5-c-table__tbody--m-selected--m-selected--BoxShadow);
}

.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-selected+tbody:where(.pf-v5-c-table__tbody).pf-m-selected:hover {
  box-shadow: var(--pf-v5-c-table__tbody--m-selected__tbody--m-selected--hover--BoxShadow);
}

.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody).pf-m-selected:hover+tbody:where(.pf-v5-c-table__tbody).pf-m-selected {
  box-shadow: var(--pf-v5-c-table__tbody--m-selected--hover__tbody--m-selected--BoxShadow);
}

.pf-v5-c-table.pf-m-drag-over {
  overflow-anchor: none;
}

.pf-v5-c-table__text {
  --pf-v5-c-table--cell--MaxWidth: 100%;
  position: relative;
  display: block;
  width: var(--pf-v5-c-table--cell--Width);
  min-width: var(--pf-v5-c-table--cell--MinWidth);
  max-width: var(--pf-v5-c-table--cell--MaxWidth);
  overflow: var(--pf-v5-c-table--cell--Overflow);
  text-overflow: var(--pf-v5-c-table--cell--TextOverflow);
  word-break: var(--pf-v5-c-table--cell--WordBreak);
  white-space: var(--pf-v5-c-table--cell--WhiteSpace);
}

.pf-v5-c-table__text.pf-m-truncate {
  --pf-v5-c-table--cell--MinWidth: 100%;
}

.pf-v5-c-table__text.pf-m-truncate>* {
  overflow: var(--pf-v5-c-table--cell--Overflow);
  text-overflow: var(--pf-v5-c-table--cell--TextOverflow);
  white-space: var(--pf-v5-c-table--cell--WhiteSpace);
}

.pf-v5-c-table__button {
  position: static;
  width: 100%;
  padding-block-start: var(--pf-v5-c-table--cell--PaddingTop);
  padding-block-end: var(--pf-v5-c-table--cell--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-table--cell--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-table--cell--PaddingRight);
  font-size: inherit;
  font-weight: inherit;
  color: var(--pf-v5-c-table__button--Color);
  text-align: start;
  white-space: inherit;
  user-select: text;
  background-color: var(--pf-v5-c-table__button--BackgroundColor);
  border: 0;
}

.pf-v5-c-table__button::before {
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  cursor: pointer;
  content: "";
}

.pf-v5-c-table__button:hover {
  color: var(--pf-v5-c-table__button--hover--Color);
}

.pf-v5-c-table__button:focus {
  color: var(--pf-v5-c-table__button--focus--Color);
}

.pf-v5-c-table__button:active {
  color: var(--pf-v5-c-table__button--active--Color);
}

.pf-v5-c-table__sort .pf-v5-c-table__text,
.pf-v5-c-table__compound-expansion-toggle .pf-v5-c-table__text {
  display: block;
  width: auto;
  overflow: var(--pf-v5-c-table--cell--Overflow);
  text-overflow: var(--pf-v5-c-table--cell--TextOverflow);
  white-space: var(--pf-v5-c-table--cell--WhiteSpace);
}

.pf-v5-c-table__sort .pf-v5-c-table__text {
  --pf-v5-c-table--cell--MinWidth: 0;
}

.pf-v5-c-table__button-content,
.pf-v5-c-table__column-help {
  display: grid;
  grid-template-columns: auto max-content;
  align-items: end;
  justify-content: start;
}

.pf-v5-c-table__button-content .pf-v5-c-table__text,
.pf-v5-c-table__column-help .pf-v5-c-table__text {
  min-width: auto;
}

.pf-v5-c-table thead:where(.pf-v5-c-table__thead).pf-m-nowrap .pf-v5-c-table__button-content,
.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-nowrap .pf-v5-c-table__button-content,
.pf-v5-c-table th:where(.pf-v5-c-table__th).pf-m-nowrap .pf-v5-c-table__button-content,
.pf-v5-c-table thead:where(.pf-v5-c-table__thead).pf-m-nowrap .pf-v5-c-table__column-help,
.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-nowrap .pf-v5-c-table__column-help,
.pf-v5-c-table th:where(.pf-v5-c-table__th).pf-m-nowrap .pf-v5-c-table__column-help {
  grid-template-columns: min-content max-content;
}

.pf-v5-c-table thead:where(.pf-v5-c-table__thead).pf-m-fit-content .pf-v5-c-table__button-content,
.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-fit-content .pf-v5-c-table__button-content,
.pf-v5-c-table th:where(.pf-v5-c-table__th).pf-m-fit-content .pf-v5-c-table__button-content,
.pf-v5-c-table thead:where(.pf-v5-c-table__thead).pf-m-fit-content .pf-v5-c-table__column-help,
.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-fit-content .pf-v5-c-table__column-help,
.pf-v5-c-table th:where(.pf-v5-c-table__th).pf-m-fit-content .pf-v5-c-table__column-help {
  grid-template-columns: fit-content max-content;
}

.pf-v5-c-table thead:where(.pf-v5-c-table__thead).pf-m-wrap .pf-v5-c-table__button-content,
.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-wrap .pf-v5-c-table__button-content,
.pf-v5-c-table th:where(.pf-v5-c-table__th).pf-m-wrap .pf-v5-c-table__button-content,
.pf-v5-c-table thead:where(.pf-v5-c-table__thead).pf-m-truncate .pf-v5-c-table__button-content,
.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-truncate .pf-v5-c-table__button-content,
.pf-v5-c-table th:where(.pf-v5-c-table__th).pf-m-truncate .pf-v5-c-table__button-content,
.pf-v5-c-table thead:where(.pf-v5-c-table__thead).pf-m-wrap .pf-v5-c-table__column-help,
.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-wrap .pf-v5-c-table__column-help,
.pf-v5-c-table th:where(.pf-v5-c-table__th).pf-m-wrap .pf-v5-c-table__column-help,
.pf-v5-c-table thead:where(.pf-v5-c-table__thead).pf-m-truncate .pf-v5-c-table__column-help,
.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-truncate .pf-v5-c-table__column-help,
.pf-v5-c-table th:where(.pf-v5-c-table__th).pf-m-truncate .pf-v5-c-table__column-help {
  grid-template-columns: auto max-content;
}

.pf-v5-c-table .pf-v5-c-table__toggle,
.pf-v5-c-table .pf-v5-c-table__action,
.pf-v5-c-table .pf-v5-c-table__inline-edit-action {
  --pf-v5-c-table--cell--PaddingBottom: 0;
}

.pf-v5-c-table .pf-v5-c-table__check,
.pf-v5-c-table .pf-v5-c-table__toggle,
.pf-v5-c-table .pf-v5-c-table__action,
.pf-v5-c-table .pf-v5-c-table__favorite,
.pf-v5-c-table th:where(.pf-v5-c-table__th).pf-m-favorite,
.pf-v5-c-table .pf-v5-c-table__inline-edit-action,
.pf-v5-c-table .pf-v5-c-table__draggable {
  --pf-v5-c-table--cell--MinWidth: 0;
  --pf-v5-c-table--cell--Width: 1%;
}

.pf-v5-c-table__toggle {
  --pf-v5-c-table--cell--PaddingRight: 0;
  --pf-v5-c-table--cell--PaddingLeft: 0;
  vertical-align: top;
}

.pf-v5-c-table__toggle .pf-v5-c-button {
  margin-block-start: var(--pf-v5-c-table__toggle--c-button--MarginTop);
  margin-block-end: var(--pf-v5-c-table__toggle--c-button--MarginBottom);
}

.pf-v5-c-table__toggle .pf-v5-c-button.pf-m-expanded .pf-v5-c-table__toggle-icon {
  transform: rotate(var(--pf-v5-c-table__toggle--c-button--m-expanded__toggle-icon--Rotate));
}

.pf-v5-c-table__toggle .pf-v5-c-table__toggle-icon {
  transition: var(--pf-v5-c-table__toggle--c-button__toggle-icon--Transition);
  transform: rotate(var(--pf-v5-c-table__toggle--c-button__toggle-icon--Rotate));
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-table__toggle .pf-v5-c-table__toggle-icon {
  scale: -1 1;
}

.pf-v5-c-table__toggle svg {
  pointer-events: none;
}

.pf-v5-c-table__check {
  --pf-v5-c-table--cell--FontSize: var(--pf-v5-c-table__check--input--FontSize);
}

.pf-v5-c-table__check label:not([disabled]),
.pf-v5-c-table__check input:not([disabled]) {
  cursor: pointer;
}

.pf-v5-c-table__check label {
  display: inline-flex;
  padding: inherit;
  margin-block-start: calc(var(--pf-v5-c-table--cell--PaddingTop)*-1);
  margin-block-end: calc(var(--pf-v5-c-table--cell--PaddingBottom)*-1);
  margin-inline-start: calc(var(--pf-v5-c-table--cell--PaddingLeft)*-1);
  margin-inline-end: calc(var(--pf-v5-c-table--cell--PaddingRight)*-1);
}

.pf-v5-c-table__thead .pf-v5-c-check.pf-m-standalone {
  --pf-v5-c-check__input--TranslateY: var(--pf-v5-c-table__thead__check--input--TranslateY);
}

.pf-v5-c-table__favorite .pf-v5-c-button {
  --pf-v5-c-button--m-plain--Color: var(--pf-v5-c-table__favorite--c-button--Color);
  --pf-v5-c-button--FontSize: var(--pf-v5-c-table__favorite--c-button--FontSize);
  margin-block-start: var(--pf-v5-c-table__favorite--c-button--MarginTop);
  margin-block-end: var(--pf-v5-c-table__favorite--c-button--MarginBottom);
  margin-inline-start: var(--pf-v5-c-table__favorite--c-button--MarginLeft);
  margin-inline-end: var(--pf-v5-c-table__favorite--c-button--MarginRight);
}

.pf-m-favorited.pf-v5-c-table__favorite .pf-v5-c-button {
  --pf-v5-c-button--m-plain--Color: var(--pf-v5-c-table__favorite--m-favorited--c-button--Color);
}

.pf-v5-c-table__draggable .pf-v5-c-button {
  margin-block-start: var(--pf-v5-c-table__draggable--c-button--MarginTop);
  margin-block-end: var(--pf-v5-c-table__draggable--c-button--MarginBottom);
  margin-inline-start: var(--pf-v5-c-table__draggable--c-button--MarginLeft);
  margin-inline-end: var(--pf-v5-c-table__draggable--c-button--MarginRight);
  cursor: grab;
}

.pf-v5-c-table__draggable .pf-v5-c-button:active {
  cursor: grabbing;
}

.pf-v5-c-table__action,
.pf-v5-c-table__inline-edit-action {
  --pf-v5-c-table--cell--PaddingTop: 0;
  --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table__action--PaddingRight);
  --pf-v5-c-table--cell--PaddingBottom: 0;
  --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table__action--PaddingLeft);
  padding-block-start: 0;
  padding-block-end: 0;
  vertical-align: middle;
}

.pf-v5-c-table__action {
  text-align: end;
}

.pf-v5-c-table__inline-edit-action {
  --pf-v5-c-table--cell--PaddingLeft: 0;
  --pf-v5-c-table--cell--PaddingRight: 0;
  text-align: end;
}

.pf-v5-c-table__compound-expansion-toggle {
  --pf-v5-c-table__button--Color: var(--pf-v5-c-table__compound-expansion-toggle__button--Color);
  --pf-v5-c-table__button--hover--Color: var(--pf-v5-c-table__compound-expansion-toggle__button--hover--Color);
  --pf-v5-c-table__button--focus--Color: var(--pf-v5-c-table__compound-expansion-toggle__button--focus--Color);
  --pf-v5-c-table__button--active--Color: var(--pf-v5-c-table__compound-expansion-toggle__button--active--Color);
  position: relative;
}

.pf-v5-c-table__compound-expansion-toggle.pf-m-truncate {
  overflow: visible;
}

.pf-v5-c-table__compound-expansion-toggle .pf-v5-c-table__button {
  min-width: 100%;
  overflow: hidden;
}

.pf-v5-c-table__compound-expansion-toggle .pf-v5-c-table__button:hover,
.pf-v5-c-table__compound-expansion-toggle .pf-v5-c-table__button:focus,
.pf-v5-c-table__compound-expansion-toggle .pf-v5-c-table__button:active {
  outline: 0;
}

.pf-v5-c-table__compound-expansion-toggle .pf-v5-c-table__button::before,
.pf-v5-c-table__compound-expansion-toggle .pf-v5-c-table__button::after {
  position: absolute;
  inset-inline-end: 0;
  content: "";
  border-style: solid;
  border-width: 0;
}

.pf-v5-c-table__compound-expansion-toggle .pf-v5-c-table__button::before {
  inset-block-start: 0;
  inset-block-end: var(--pf-v5-c-table__compound-expansion-toggle__button--before--Bottom);
  inset-inline-start: var(--pf-v5-c-table__compound-expansion-toggle__button--before--Left);
  border-color: var(--pf-v5-c-table__compound-expansion-toggle__button--before--BorderColor);
  border-inline-start-width: var(--pf-v5-c-table__compound-expansion-toggle__button--before--BorderLeftWidth);
  border-inline-end-width: var(--pf-v5-c-table__compound-expansion-toggle__button--before--BorderRightWidth);
}

.pf-v5-c-table__compound-expansion-toggle .pf-v5-c-table__button::after {
  inset-block-start: var(--pf-v5-c-table__compound-expansion-toggle__button--after--Top);
  inset-inline-start: var(--pf-v5-c-table__compound-expansion-toggle__button--after--Left);
  pointer-events: none;
  border-color: var(--pf-v5-c-table__compound-expansion-toggle__button--after--BorderColor);
  border-block-start-width: var(--pf-v5-c-table__compound-expansion-toggle__button--after--BorderTopWidth);
}

.pf-v5-c-table__compound-expansion-toggle:hover,
.pf-v5-c-table__compound-expansion-toggle:focus-within,
.pf-v5-c-table__compound-expansion-toggle.pf-m-expanded {
  --pf-v5-c-table__compound-expansion-toggle__button--before--BorderRightWidth: var(--pf-v5-c-table__compound-expansion-toggle__button--before--border-width--base);
  --pf-v5-c-table__compound-expansion-toggle__button--before--BorderLeftWidth: var(--pf-v5-c-table__compound-expansion-toggle__button--before--border-width--base);
  --pf-v5-c-table__compound-expansion-toggle__button--after--BorderTopWidth: var(--pf-v5-c-table__compound-expansion-toggle__button--after--border-width--base);
}

.pf-v5-c-table__compound-expansion-toggle:first-child {
  --pf-v5-c-table__compound-expansion-toggle__button--before--Left: 0;
  --pf-v5-c-table__compound-expansion-toggle__button--after--Left: 0;
}

.pf-v5-c-table__compound-expansion-toggle.pf-m-expanded .pf-v5-c-table__button::before {
  border-block-end: var(--pf-v5-c-table--BackgroundColor) solid var(--pf-v5-c-table__compound-expansion-toggle__button--before--border-width--base);
}

.pf-v5-c-table__compound-expansion-toggle.pf-m-expanded:first-child {
  --pf-v5-c-table__compound-expansion-toggle__button--before--BorderLeftWidth: 0;
}

.pf-v5-c-table__compound-expansion-toggle:focus-within {
  outline-offset: var(--pf-v5-c-table__button--OutlineOffset);
}

@media (-webkit-min-device-pixel-ratio: 0) {
  .pf-v5-c-table__compound-expansion-toggle:focus-within {
    outline-style: auto;
    outline-color: -webkit-focus-ring-color;
  }
}

.pf-v5-c-table__column-help-action {
  margin-inline-start: var(--pf-v5-c-table__column-help--MarginLeft);
  transform: translateY(var(--pf-v5-c-table__column-help--TranslateY));
}

.pf-v5-c-table__column-help-action .pf-v5-c-button {
  --pf-v5-c-button--PaddingRight: var(--pf-v5-c-table__column-help--c-button--PaddingRight);
  --pf-v5-c-button--PaddingLeft: var(--pf-v5-c-table__column-help--c-button--PaddingLeft);
  margin-block-start: var(--pf-v5-c-table__column-help--c-button--MarginTop);
  margin-block-end: var(--pf-v5-c-table__column-help--c-button--MarginBottom);
  font-size: inherit;
  line-height: 1;
}

.pf-v5-c-table__sort .pf-v5-c-table__button {
  --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table__sort__button--PaddingTop);
  --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table__sort__button--PaddingRight);
  --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table__sort__button--PaddingBottom);
  --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table__sort__button--PaddingLeft);
  display: flex;
  width: auto;
  margin-block-start: var(--pf-v5-c-table__sort__button--MarginTop);
  margin-block-end: var(--pf-v5-c-table__sort__button--MarginBottom);
  margin-inline-start: var(--pf-v5-c-table__sort__button--MarginLeft);
}

.pf-v5-c-table__sort .pf-v5-c-table__button:hover {
  --pf-v5-c-table__sort-indicator--Color: var(--pf-v5-c-table__sort__button--hover__sort-indicator--Color);
  --pf-v5-c-table__sort__button__text--Color: var(--pf-v5-c-table__sort__button--hover__text--Color);
}

.pf-v5-c-table__sort .pf-v5-c-table__button:focus {
  --pf-v5-c-table__sort-indicator--Color: var(--pf-v5-c-table__sort__button--focus__sort-indicator--Color);
  --pf-v5-c-table__sort__button__text--Color: var(--pf-v5-c-table__sort__button--focus__text--Color);
}

.pf-v5-c-table__sort .pf-v5-c-table__button:active {
  --pf-v5-c-table__sort-indicator--Color: var(--pf-v5-c-table__sort__button--active__sort-indicator--Color);
  --pf-v5-c-table__sort__button__text--Color: var(--pf-v5-c-table__sort__button--active__text--Color);
}

.pf-v5-c-table__sort .pf-v5-c-table__button .pf-v5-c-table__text {
  color: var(--pf-v5-c-table__sort__button__text--Color);
}

.pf-v5-c-table__sort.pf-m-selected .pf-v5-c-table__button {
  --pf-v5-c-table__sort-indicator--Color: var(--pf-v5-c-table__sort--m-selected__sort-indicator--Color);
  --pf-v5-c-table__sort__button__text--Color: var(--pf-v5-c-table__sort--m-selected__button__text--Color);
  color: var(--pf-v5-c-table__sort--m-selected__button--Color);
}

.pf-v5-c-table__sort.pf-m-help {
  --pf-v5-c-table__th--m-help--MinWidth: var(--pf-v5-c-table__sort--m-help--MinWidth);
}

.pf-v5-c-table__sort.pf-m-favorite {
  --pf-v5-c-table__sort__button__text--Color: var(--pf-v5-c-table__sort--m-favorite__button__text--Color);
  --pf-v5-c-table__sort__button--hover__text--Color: var(--pf-v5-c-table__sort--m-favorite__button--hover__text--Color);
  --pf-v5-c-table__sort__button--focus__text--Color: var(--pf-v5-c-table__sort--m-favorite__button--focus__text--Color);
  --pf-v5-c-table__sort__button--active__text--Color: var(--pf-v5-c-table__sort--m-favorite__button--active__text--Color);
  --pf-v5-c-table__sort--m-selected__button__text--Color: currentcolor;
}

.pf-v5-c-table__sort-indicator {
  grid-column: 2;
  margin-inline-start: var(--pf-v5-c-table__sort-indicator--MarginLeft);
  color: var(--pf-v5-c-table__sort-indicator--Color);
  pointer-events: none;
}

.pf-v5-c-table__expandable-row {
  --pf-v5-c-table--cell--PaddingTop: 0;
  --pf-v5-c-table--cell--PaddingBottom: 0;
  position: relative;
  border-block-end: 0 solid transparent;
  box-shadow: 0 0 0 0 transparent;
}

.pf-v5-c-table__expandable-row,
.pf-v5-c-table__expandable-row td:where(.pf-v5-c-table__td):first-child::after {
  transition: var(--pf-v5-c-table__expandable-row--Transition);
}

.pf-v5-c-table__expandable-row td:where(.pf-v5-c-table__td).pf-m-no-padding,
.pf-v5-c-table__expandable-row th:where(.pf-v5-c-table__th).pf-m-no-padding {
  padding-block-start: 0;
  padding-inline-end: 0;
  padding-block-end: 0;
  padding-inline-start: var(--pf-v5-c-table__expandable-row--after--border-width--base);
}

.pf-v5-c-table__expandable-row td:where(.pf-v5-c-table__td).pf-m-no-padding .pf-v5-c-table__expandable-row-content,
.pf-v5-c-table__expandable-row th:where(.pf-v5-c-table__th).pf-m-no-padding .pf-v5-c-table__expandable-row-content {
  padding: 0;
}

.pf-v5-c-table__expandable-row .pf-v5-c-table__expandable-row-content {
  padding-block-start: var(--pf-v5-c-table__expandable-row-content--PaddingTop);
  padding-block-end: var(--pf-v5-c-table__expandable-row-content--PaddingBottom);
}

.pf-v5-c-table__expandable-row.pf-m-expanded {
  border-block-end-color: var(--pf-v5-c-table__expandable-row--m-expanded--BorderBottomColor);
  border-block-end-width: var(--pf-v5-c-table--border-width--base);
  box-shadow: var(--pf-v5-c-table__expandable-row--m-expanded--BoxShadow);
}

.pf-v5-c-table__expandable-row:not(.pf-m-expanded) {
  display: none;
}

.pf-v5-c-table .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>*:first-child {
  --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table--nested--first-last-child--PaddingLeft);
}

.pf-v5-c-table .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>*:last-child {
  --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table--nested--first-last-child--PaddingRight);
}

.pf-v5-c-table.pf-m-compact {
  --pf-v5-c-table--cell--FontSize: var(--pf-v5-c-table--m-compact--FontSize);
  --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-compact--cell--PaddingTop);
  --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-compact--cell--PaddingBottom);
  --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table--m-compact--cell--PaddingLeft);
}

.pf-v5-c-table.pf-m-compact tr:where(.pf-v5-c-table__tr) {
  --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table--m-compact--cell--PaddingLeft);
  --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table--m-compact--cell--PaddingRight);
}

.pf-v5-c-table.pf-m-compact tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) {
  --pf-v5-c-table--cell--FontSize: var(--pf-v5-c-table--m-compact--FontSize);
  --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-compact--cell--PaddingTop);
  --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-compact--cell--PaddingBottom);
}

.pf-v5-c-table.pf-m-compact tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row)>*:first-child {
  --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table--m-compact--cell--first-last-child--PaddingLeft);
}

.pf-v5-c-table.pf-m-compact tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row)>*:last-child {
  --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table--m-compact--cell--first-last-child--PaddingRight);
}

.pf-v5-c-table.pf-m-compact thead:where(.pf-v5-c-table__thead) th:where(.pf-v5-c-table__th),
.pf-v5-c-table.pf-m-compact thead:where(.pf-v5-c-table__thead) .pf-v5-c-table__toggle {
  --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-compact__th--PaddingTop);
  --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-compact__th--PaddingBottom);
}

.pf-v5-c-table.pf-m-compact .pf-v5-c-table__action {
  --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table__action--PaddingTop);
  --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table__action--PaddingBottom);
  --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table__action--PaddingLeft);
}

.pf-v5-c-table.pf-m-compact .pf-v5-c-table__toggle {
  --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-compact__toggle--PaddingTop);
  --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-compact__toggle--PaddingBottom);
}

.pf-v5-c-table.pf-m-compact .pf-v5-c-table__icon {
  width: auto;
  min-width: 0;
  text-align: center;
}

.pf-v5-c-table .pf-v5-c-table.pf-m-compact tr:where(.pf-v5-c-table__tr)>*:first-child {
  --pf-v5-c-table--cell--PaddingLeft: var(--pf-v5-c-table--nested--first-last-child--PaddingLeft);
}

.pf-v5-c-table .pf-v5-c-table.pf-m-compact tr:where(.pf-v5-c-table__tr)>*:last-child {
  --pf-v5-c-table--cell--PaddingRight: var(--pf-v5-c-table--nested--first-last-child--PaddingRight);
}

.pf-v5-c-table.pf-m-compact .pf-v5-c-table__expandable-row-content {
  --pf-v5-c-table__expandable-row-content--PaddingTop: var(--pf-v5-c-table--m-compact__expandable-row-content--PaddingTop);
  --pf-v5-c-table__expandable-row-content--PaddingBottom: var(--pf-v5-c-table--m-compact__expandable-row-content--PaddingBottom);
}

.pf-v5-c-table__icon-inline {
  display: flex;
  align-items: center;
}

.pf-v5-c-table__icon-inline>:not(:last-child) {
  margin-inline-end: var(--pf-v5-c-table__icon-inline--MarginRight);
}

.pf-v5-c-table .pf-m-width-10 {
  --pf-v5-c-table--cell--Width: 10%;
}

.pf-v5-c-table .pf-m-width-15 {
  --pf-v5-c-table--cell--Width: 15%;
}

.pf-v5-c-table .pf-m-width-20 {
  --pf-v5-c-table--cell--Width: 20%;
}

.pf-v5-c-table .pf-m-width-25 {
  --pf-v5-c-table--cell--Width: 25%;
}

.pf-v5-c-table .pf-m-width-30 {
  --pf-v5-c-table--cell--Width: 30%;
}

.pf-v5-c-table .pf-m-width-35 {
  --pf-v5-c-table--cell--Width: 35%;
}

.pf-v5-c-table .pf-m-width-40 {
  --pf-v5-c-table--cell--Width: 40%;
}

.pf-v5-c-table .pf-m-width-45 {
  --pf-v5-c-table--cell--Width: 45%;
}

.pf-v5-c-table .pf-m-width-50 {
  --pf-v5-c-table--cell--Width: 50%;
}

.pf-v5-c-table .pf-m-width-60 {
  --pf-v5-c-table--cell--Width: 60%;
}

.pf-v5-c-table .pf-m-width-70 {
  --pf-v5-c-table--cell--Width: 70%;
}

.pf-v5-c-table .pf-m-width-80 {
  --pf-v5-c-table--cell--Width: 80%;
}

.pf-v5-c-table .pf-m-width-90 {
  --pf-v5-c-table--cell--Width: 90%;
}

.pf-v5-c-table .pf-m-width-100 {
  --pf-v5-c-table--cell--Width: 100%;
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-table {
  --pf-v5-c-table--m-striped__tr--BackgroundColor: var(--pf-v5-global--BackgroundColor--150);
}

:where(.pf-v5-theme-dark) .pf-v5-c-table__favorite .pf-v5-c-button:hover,
:where(.pf-v5-theme-dark) .pf-v5-c-table__favorite .pf-v5-c-button:focus {
  --pf-v5-c-button--m-plain--Color: var(--pf-v5-global--palette--gold-100);
}

.pf-v5-c-table {
  --pf-v5-c-table__sticky-cell--MinWidth--base: 12.5rem;
  --pf-v5-c-table__sticky-cell--MinWidth: var(--pf-v5-c-table__sticky-cell--MinWidth--base);
  --pf-v5-c-table__sticky-cell--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-table__sticky-cell--Right: auto;
  --pf-v5-c-table__sticky-cell--Left: auto;
  --pf-v5-c-table__sticky-cell--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-table__sticky-cell--m-border-right--before--BorderRightWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-table__sticky-cell--m-border-right--before--BorderRightColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-table__sticky-cell--m-border-left--before--BorderLeftWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-table__sticky-cell--m-border-left--before--BorderLeftColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-table__sticky-cell--m-right--Right: 0;
  --pf-v5-c-table__sticky-cell--m-left--Left: 0;
  --pf-v5-c-table--m-sticky-header__sticky-cell--ZIndex: calc(var(--pf-v5-c-table__sticky-cell--ZIndex) + 1);
}

.pf-v5-c-table .pf-v5-c-table__sticky-cell {
  --pf-v5-c-table--cell--Overflow: visible;
  --pf-v5-c-table--m-sticky-header--cell--ZIndex: var(--pf-v5-c-table--m-sticky-header__sticky-cell--ZIndex);
  position: sticky;
  inset-inline-start: var(--pf-v5-c-table__sticky-cell--Left);
  inset-inline-end: var(--pf-v5-c-table__sticky-cell--Right);
  z-index: var(--pf-v5-c-table__sticky-cell--ZIndex);
  min-width: var(--pf-v5-c-table__sticky-cell--MinWidth);
  background-color: var(--pf-v5-c-table__sticky-cell--BackgroundColor);
  background-clip: padding-box;
}

.pf-v5-c-table .pf-v5-c-table__sticky-cell.pf-m-border-right::before {
  --pf-v5-c-table--cell--m-border-right--before--BorderRightWidth: var(--pf-v5-c-table__sticky-cell--m-border-right--before--BorderRightWidth);
  --pf-v5-c-table--cell--m-border-right--before--BorderRightColor: var(--pf-v5-c-table__sticky-cell--m-border-right--before--BorderRightColor);
}

.pf-v5-c-table .pf-v5-c-table__sticky-cell.pf-m-border-left::before {
  --pf-v5-c-table--cell--m-border-left--before--BorderLeftWidth: var(--pf-v5-c-table__sticky-cell--m-border-left--before--BorderLeftWidth);
  --pf-v5-c-table--cell--m-border-left--before--BorderLeftColor: var(--pf-v5-c-table__sticky-cell--m-border-left--before--BorderLeftColor);
}

.pf-v5-c-table .pf-v5-c-table__sticky-cell.pf-m-right,
.pf-v5-c-table .pf-v5-c-table__sticky-cell.pf-m-inline-end {
  --pf-v5-c-table__sticky-cell--Right: var(--pf-v5-c-table__sticky-cell--m-right--Right);
}

.pf-v5-c-table .pf-v5-c-table__sticky-cell.pf-m-left,
.pf-v5-c-table .pf-v5-c-table__sticky-cell.pf-m-inline-start {
  --pf-v5-c-table__sticky-cell--Left: var(--pf-v5-c-table__sticky-cell--m-left--Left);
}

.pf-v5-c-scroll-outer-wrapper {
  --pf-v5-c-scroll-outer-wrapper--MinHeight: 25rem;
  --pf-v5-c-scroll-outer-wrapper--MaxHeight: 100%;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-height: var(--pf-v5-c-scroll-outer-wrapper--MinHeight);
  max-height: var(--pf-v5-c-scroll-outer-wrapper--MaxHeight);
  overflow: hidden;
}

.pf-v5-c-scroll-inner-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
}

.pf-v5-c-table {
  --pf-v5-c-table__tree-view-main--indent--base: calc(var(--pf-v5-global--spacer--md) * 2 + var(--pf-v5-c-table__tree-view-icon--MinWidth));
  --pf-v5-c-table__tree-view-main--nested-indent--base: calc(var(--pf-v5-c-table__tree-view-main--indent--base) - var(--pf-v5-global--spacer--md));
  --pf-v5-c-table__tree-view-main--PaddingLeft: var(--pf-v5-c-table__tree-view-main--indent--base);
  --pf-v5-c-table__tree-view-main--MarginLeft: calc(var(--pf-v5-c-table--cell--PaddingLeft) * -1);
  --pf-v5-c-table__tree-view-main--c-table__check--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table__tree-view-main--c-table__check--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table__tree-view-main--c-table__check--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table__tree-view-icon--MinWidth: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-table__tree-view-icon--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table--m-tree-view__toggle--Position: absolute;
  --pf-v5-c-table--m-tree-view__toggle--Left: var(--pf-v5-c-table__tree-view-main--PaddingLeft);
  --pf-v5-c-table--m-tree-view__toggle--TranslateX: -100%;
  --pf-v5-c-table--m-tree-view__toggle__toggle-icon--MinWidth: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-table--m-no-inset__tree-view-main--PaddingLeft: 0;
  --pf-v5-c-table--m-no-inset__tree-view-main--MarginLeft: 0;
}

.pf-v5-c-table.pf-m-tree-view>tbody>tr {
  --pf-v5-c-table--m-tree-view__toggle--Left: var(--pf-v5-c-table__tree-view-main--PaddingLeft);
}

.pf-v5-c-table.pf-m-tree-view>tbody>tr.pf-m-no-inset {
  --pf-v5-c-table__tree-view-main--PaddingLeft: var(--pf-v5-c-table--m-no-inset__tree-view-main--PaddingLeft);
  --pf-v5-c-table__tree-view-main--MarginLeft: var(--pf-v5-c-table--m-no-inset__tree-view-main--MarginLeft);
}

.pf-v5-c-table.pf-m-tree-view.pf-m-no-inset {
  --pf-v5-c-table__tree-view-main--PaddingLeft: var(--pf-v5-c-table--m-no-inset__tree-view-main--PaddingLeft);
  --pf-v5-c-table__tree-view-main--MarginLeft: var(--pf-v5-c-table--m-no-inset__tree-view-main--MarginLeft);
}

.pf-v5-c-table.pf-m-tree-view tr:where(.pf-v5-c-table__tr)[aria-level="2"] {
  --pf-v5-c-table__tree-view-main--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 1 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

.pf-v5-c-table.pf-m-tree-view tr:where(.pf-v5-c-table__tr)[aria-level="3"] {
  --pf-v5-c-table__tree-view-main--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 2 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

.pf-v5-c-table.pf-m-tree-view tr:where(.pf-v5-c-table__tr)[aria-level="4"] {
  --pf-v5-c-table__tree-view-main--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 3 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

.pf-v5-c-table.pf-m-tree-view tr:where(.pf-v5-c-table__tr)[aria-level="5"] {
  --pf-v5-c-table__tree-view-main--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 4 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

.pf-v5-c-table.pf-m-tree-view tr:where(.pf-v5-c-table__tr)[aria-level="6"] {
  --pf-v5-c-table__tree-view-main--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 5 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

.pf-v5-c-table.pf-m-tree-view tr:where(.pf-v5-c-table__tr)[aria-level="7"] {
  --pf-v5-c-table__tree-view-main--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 6 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

.pf-v5-c-table.pf-m-tree-view tr:where(.pf-v5-c-table__tr)[aria-level="8"] {
  --pf-v5-c-table__tree-view-main--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 7 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

.pf-v5-c-table.pf-m-tree-view tr:where(.pf-v5-c-table__tr)[aria-level="9"] {
  --pf-v5-c-table__tree-view-main--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 8 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

.pf-v5-c-table.pf-m-tree-view tr:where(.pf-v5-c-table__tr)[aria-level="10"] {
  --pf-v5-c-table__tree-view-main--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 9 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

.pf-v5-c-table__tree-view-main {
  position: relative;
  display: flex;
  align-items: baseline;
  min-width: 0;
  padding-inline-start: var(--pf-v5-c-table__tree-view-main--PaddingLeft);
  margin-inline-start: var(--pf-v5-c-table__tree-view-main--MarginLeft);
  text-align: start;
  cursor: pointer;
}

.pf-v5-c-table__tree-view-main>.pf-v5-c-table__toggle {
  transform: translateX(var(--pf-v5-c-table--m-tree-view__toggle--TranslateX));
  position: var(--pf-v5-c-table--m-tree-view__toggle--Position);
  inset-inline-start: var(--pf-v5-c-table--m-tree-view__toggle--Left);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-table__tree-view-main>.pf-v5-c-table__toggle {
  transform: translateX(calc(var(--pf-v5-c-table--m-tree-view__toggle--TranslateX) * var(--pf-v5-global--inverse--multiplier)));
}

.pf-v5-c-table__tree-view-main>.pf-v5-c-table__toggle .pf-v5-c-table__toggle-icon {
  min-width: var(--pf-v5-c-table--m-tree-view__toggle__toggle-icon--MinWidth);
}

.pf-v5-c-table__tree-view-main>.pf-v5-c-table__check {
  margin-inline-end: var(--pf-v5-c-table__tree-view-main--c-table__check--MarginRight);
}

.pf-v5-c-table__tree-view-main>.pf-v5-c-table__check label {
  padding-inline-start: var(--pf-v5-c-table__tree-view-main--c-table__check--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-table__tree-view-main--c-table__check--PaddingRight);
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: calc(var(--pf-v5-c-table__tree-view-main--c-table__check--MarginRight)*-1);
}

.pf-v5-c-table__tree-view-text {
  display: flex;
}

.pf-v5-c-table__tree-view-icon {
  min-width: var(--pf-v5-c-table__tree-view-icon--MinWidth);
  margin-inline-end: var(--pf-v5-c-table__tree-view-icon--MarginRight);
}

.pf-v5-c-table__tree-view-details-toggle {
  display: none;
}

.pf-m-tree-view-grid.pf-v5-c-table {
  --pf-v5-c-table--m-tree-view-grid--tr--OutlineOffset: calc(-1 * var(--pf-v5-global--spacer--xs));
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: var(--pf-v5-c-table__tree-view-main--indent--base);
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--GridColumnGap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingLeft: 0;
  --pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-table--m-tree-view-grid__td--data-label--GridTemplateColumns: repeat(auto-fit, minmax(150px, 1fr));
  --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight: 0;
  --pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order: 4;
  --pf-v5-c-table__tree-view-text--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-table__tbody--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingTop);
  --pf-v5-c-table__tbody--cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingBottom);
  --pf-v5-c-table__tree-view-details-toggle--MarginTop: calc(0.375rem * -1);
  --pf-v5-c-table__tree-view-details-toggle--MarginBottom: calc(0.375rem * -1);
  --pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginTop: calc(0.375rem * -1);
  --pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginBottom: calc(0.375rem * -1);
}

.pf-m-tree-view-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) tr:where(.pf-v5-c-table__tr) {
  position: relative;
  border: none;
  outline-offset: var(--pf-v5-c-table--m-tree-view-grid--tr--OutlineOffset);
}

.pf-m-tree-view-grid.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) tr:where(.pf-v5-c-table__tr)::before {
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft);
  inset-inline-end: 0;
  content: "";
  border-block-end: var(--pf-v5-c-table--border-width--base) solid var(--pf-v5-c-table--BorderColor);
}

.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not([hidden]) {
  display: grid;
  grid-template-columns: 1fr max-content;
}

.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-expanded] .pf-v5-c-table__tree-view-title-cell {
  --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop);
}

.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-expanded] .pf-v5-c-table__tree-view-title-cell~.pf-v5-c-table__action {
  --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop);
}

.pf-m-tree-view-grid.pf-v5-c-table td:where(.pf-v5-c-table__td):not(.pf-v5-c-table__tree-view-title-cell) {
  --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop);
  --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft);
}

.pf-m-tree-view-grid.pf-v5-c-table .pf-v5-c-table__tree-view-text {
  padding-inline-end: var(--pf-v5-c-table__tree-view-text--PaddingRight);
}

.pf-m-tree-view-grid.pf-v5-c-table thead:where(.pf-v5-c-table__thead) th:where(.pf-v5-c-table__th) {
  display: none;
}

.pf-m-tree-view-grid.pf-v5-c-table td:where(.pf-v5-c-table__td) {
  display: none;
}

.pf-m-tree-view-grid.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label] {
  grid-template-columns: var(--pf-v5-c-table--m-tree-view-grid__td--data-label--GridTemplateColumns);
  grid-column: 1;
  grid-column-gap: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--GridColumnGap);
  align-items: start;
}

.pf-m-tree-view-grid.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label]>* {
  grid-column: 2;
}

.pf-m-tree-view-grid.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label]::before {
  font-weight: var(--pf-v5-global--FontWeight--bold);
  text-align: start;
  content: attr(data-label);
}

.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-tree-view-details-expanded {
  padding-block-end: var(--pf-v5-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom);
}

.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-tree-view-details-expanded td:where(.pf-v5-c-table__td)[data-label] {
  display: grid;
}

.pf-m-tree-view-grid.pf-v5-c-table .pf-v5-c-table__tree-view-title-header-cell {
  display: block;
}

.pf-m-tree-view-grid.pf-v5-c-table .pf-v5-c-table__tree-view-details-toggle,
.pf-m-tree-view-grid.pf-v5-c-table .pf-v5-c-table__action {
  display: inline-block;
}

.pf-m-tree-view-grid.pf-v5-c-table .pf-v5-c-table__action {
  --pf-v5-c-table--cell--Width: auto;
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingLeft);
  --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingTop);
  --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingBottom);
  grid-row: 1;
  grid-column: 2;
}

.pf-m-tree-view-grid.pf-v5-c-table .pf-v5-c-table__action>.pf-v5-c-dropdown {
  margin-block-start: var(--pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginTop);
  margin-block-end: var(--pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginBottom);
}

.pf-m-tree-view-grid.pf-v5-c-table .pf-v5-c-table__tree-view-main>.pf-v5-c-table__check {
  order: var(--pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order);
  margin-inline-start: auto;
  margin-inline-end: var(--pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight);
}

.pf-m-tree-view-grid.pf-v5-c-table .pf-v5-c-table__tree-view-details-toggle {
  margin-block-start: var(--pf-v5-c-table__tree-view-details-toggle--MarginTop);
  margin-block-end: var(--pf-v5-c-table__tree-view-details-toggle--MarginBottom);
}

.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="2"] {
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 1 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="3"] {
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 2 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="4"] {
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 3 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="5"] {
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 4 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="6"] {
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 5 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="7"] {
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 6 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="8"] {
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 7 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="9"] {
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 8 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

.pf-m-tree-view-grid.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="10"] {
  --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 9 + var(--pf-v5-c-table__tree-view-main--indent--base));
}

@media screen and (max-width: 768px) {
  .pf-m-tree-view-grid-md.pf-v5-c-table {
    --pf-v5-c-table--m-tree-view-grid--tr--OutlineOffset: calc(-1 * var(--pf-v5-global--spacer--xs));
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingTop: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingBottom: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: var(--pf-v5-c-table__tree-view-main--indent--base);
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--GridColumnGap: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingTop: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingBottom: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingLeft: 0;
    --pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-table--m-tree-view-grid__td--data-label--GridTemplateColumns: repeat(auto-fit, minmax(150px, 1fr));
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-v5-global--spacer--xs);
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-v5-global--spacer--xs);
    --pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight: 0;
    --pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order: 4;
    --pf-v5-c-table__tree-view-text--PaddingRight: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-table__tbody--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingTop);
    --pf-v5-c-table__tbody--cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingBottom);
    --pf-v5-c-table__tree-view-details-toggle--MarginTop: calc(0.375rem * -1);
    --pf-v5-c-table__tree-view-details-toggle--MarginBottom: calc(0.375rem * -1);
    --pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginTop: calc(0.375rem * -1);
    --pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginBottom: calc(0.375rem * -1);
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) tr:where(.pf-v5-c-table__tr) {
    position: relative;
    border: none;
    outline-offset: var(--pf-v5-c-table--m-tree-view-grid--tr--OutlineOffset);
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) tr:where(.pf-v5-c-table__tr)::before {
    position: absolute;
    inset-block-start: 0;
    inset-block-end: 0;
    inset-inline-start: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft);
    inset-inline-end: 0;
    content: "";
    border-block-end: var(--pf-v5-c-table--border-width--base) solid var(--pf-v5-c-table--BorderColor);
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not([hidden]) {
    display: grid;
    grid-template-columns: 1fr max-content;
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-expanded] .pf-v5-c-table__tree-view-title-cell {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop);
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-expanded] .pf-v5-c-table__tree-view-title-cell~.pf-v5-c-table__action {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop);
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table td:where(.pf-v5-c-table__td):not(.pf-v5-c-table__tree-view-title-cell) {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop);
    --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft);
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table .pf-v5-c-table__tree-view-text {
    padding-inline-end: var(--pf-v5-c-table__tree-view-text--PaddingRight);
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table thead:where(.pf-v5-c-table__thead) th:where(.pf-v5-c-table__th) {
    display: none;
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table td:where(.pf-v5-c-table__td) {
    display: none;
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label] {
    grid-template-columns: var(--pf-v5-c-table--m-tree-view-grid__td--data-label--GridTemplateColumns);
    grid-column: 1;
    grid-column-gap: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--GridColumnGap);
    align-items: start;
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label]>* {
    grid-column: 2;
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label]::before {
    font-weight: var(--pf-v5-global--FontWeight--bold);
    text-align: start;
    content: attr(data-label);
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-tree-view-details-expanded {
    padding-block-end: var(--pf-v5-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom);
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-tree-view-details-expanded td:where(.pf-v5-c-table__td)[data-label] {
    display: grid;
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table .pf-v5-c-table__tree-view-title-header-cell {
    display: block;
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table .pf-v5-c-table__tree-view-details-toggle,
  .pf-m-tree-view-grid-md.pf-v5-c-table .pf-v5-c-table__action {
    display: inline-block;
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table .pf-v5-c-table__action {
    --pf-v5-c-table--cell--Width: auto;
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingLeft);
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingTop);
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingBottom);
    grid-row: 1;
    grid-column: 2;
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table .pf-v5-c-table__action>.pf-v5-c-dropdown {
    margin-block-start: var(--pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginTop);
    margin-block-end: var(--pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginBottom);
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table .pf-v5-c-table__tree-view-main>.pf-v5-c-table__check {
    order: var(--pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order);
    margin-inline-start: auto;
    margin-inline-end: var(--pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight);
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table .pf-v5-c-table__tree-view-details-toggle {
    margin-block-start: var(--pf-v5-c-table__tree-view-details-toggle--MarginTop);
    margin-block-end: var(--pf-v5-c-table__tree-view-details-toggle--MarginBottom);
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="2"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 1 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="3"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 2 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="4"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 3 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="5"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 4 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="6"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 5 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="7"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 6 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="8"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 7 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="9"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 8 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-md.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="10"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 9 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
}

@media screen and (max-width: 992px) {
  .pf-m-tree-view-grid-lg.pf-v5-c-table {
    --pf-v5-c-table--m-tree-view-grid--tr--OutlineOffset: calc(-1 * var(--pf-v5-global--spacer--xs));
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingTop: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingBottom: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: var(--pf-v5-c-table__tree-view-main--indent--base);
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--GridColumnGap: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingTop: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingBottom: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingLeft: 0;
    --pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-table--m-tree-view-grid__td--data-label--GridTemplateColumns: repeat(auto-fit, minmax(150px, 1fr));
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-v5-global--spacer--xs);
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-v5-global--spacer--xs);
    --pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight: 0;
    --pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order: 4;
    --pf-v5-c-table__tree-view-text--PaddingRight: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-table__tbody--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingTop);
    --pf-v5-c-table__tbody--cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingBottom);
    --pf-v5-c-table__tree-view-details-toggle--MarginTop: calc(0.375rem * -1);
    --pf-v5-c-table__tree-view-details-toggle--MarginBottom: calc(0.375rem * -1);
    --pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginTop: calc(0.375rem * -1);
    --pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginBottom: calc(0.375rem * -1);
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) tr:where(.pf-v5-c-table__tr) {
    position: relative;
    border: none;
    outline-offset: var(--pf-v5-c-table--m-tree-view-grid--tr--OutlineOffset);
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) tr:where(.pf-v5-c-table__tr)::before {
    position: absolute;
    inset-block-start: 0;
    inset-block-end: 0;
    inset-inline-start: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft);
    inset-inline-end: 0;
    content: "";
    border-block-end: var(--pf-v5-c-table--border-width--base) solid var(--pf-v5-c-table--BorderColor);
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not([hidden]) {
    display: grid;
    grid-template-columns: 1fr max-content;
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-expanded] .pf-v5-c-table__tree-view-title-cell {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop);
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-expanded] .pf-v5-c-table__tree-view-title-cell~.pf-v5-c-table__action {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop);
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table td:where(.pf-v5-c-table__td):not(.pf-v5-c-table__tree-view-title-cell) {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop);
    --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft);
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table .pf-v5-c-table__tree-view-text {
    padding-inline-end: var(--pf-v5-c-table__tree-view-text--PaddingRight);
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table thead:where(.pf-v5-c-table__thead) th:where(.pf-v5-c-table__th) {
    display: none;
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table td:where(.pf-v5-c-table__td) {
    display: none;
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label] {
    grid-template-columns: var(--pf-v5-c-table--m-tree-view-grid__td--data-label--GridTemplateColumns);
    grid-column: 1;
    grid-column-gap: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--GridColumnGap);
    align-items: start;
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label]>* {
    grid-column: 2;
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label]::before {
    font-weight: var(--pf-v5-global--FontWeight--bold);
    text-align: start;
    content: attr(data-label);
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-tree-view-details-expanded {
    padding-block-end: var(--pf-v5-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom);
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-tree-view-details-expanded td:where(.pf-v5-c-table__td)[data-label] {
    display: grid;
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table .pf-v5-c-table__tree-view-title-header-cell {
    display: block;
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table .pf-v5-c-table__tree-view-details-toggle,
  .pf-m-tree-view-grid-lg.pf-v5-c-table .pf-v5-c-table__action {
    display: inline-block;
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table .pf-v5-c-table__action {
    --pf-v5-c-table--cell--Width: auto;
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingLeft);
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingTop);
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingBottom);
    grid-row: 1;
    grid-column: 2;
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table .pf-v5-c-table__action>.pf-v5-c-dropdown {
    margin-block-start: var(--pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginTop);
    margin-block-end: var(--pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginBottom);
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table .pf-v5-c-table__tree-view-main>.pf-v5-c-table__check {
    order: var(--pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order);
    margin-inline-start: auto;
    margin-inline-end: var(--pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight);
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table .pf-v5-c-table__tree-view-details-toggle {
    margin-block-start: var(--pf-v5-c-table__tree-view-details-toggle--MarginTop);
    margin-block-end: var(--pf-v5-c-table__tree-view-details-toggle--MarginBottom);
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="2"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 1 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="3"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 2 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="4"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 3 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="5"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 4 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="6"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 5 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="7"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 6 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="8"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 7 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="9"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 8 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-lg.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="10"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 9 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
}

@media screen and (max-width: 1200px) {
  .pf-m-tree-view-grid-xl.pf-v5-c-table {
    --pf-v5-c-table--m-tree-view-grid--tr--OutlineOffset: calc(-1 * var(--pf-v5-global--spacer--xs));
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingTop: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingBottom: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: var(--pf-v5-c-table__tree-view-main--indent--base);
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--GridColumnGap: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingTop: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingBottom: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingLeft: 0;
    --pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-table--m-tree-view-grid__td--data-label--GridTemplateColumns: repeat(auto-fit, minmax(150px, 1fr));
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-v5-global--spacer--xs);
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-v5-global--spacer--xs);
    --pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight: 0;
    --pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order: 4;
    --pf-v5-c-table__tree-view-text--PaddingRight: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-table__tbody--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingTop);
    --pf-v5-c-table__tbody--cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingBottom);
    --pf-v5-c-table__tree-view-details-toggle--MarginTop: calc(0.375rem * -1);
    --pf-v5-c-table__tree-view-details-toggle--MarginBottom: calc(0.375rem * -1);
    --pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginTop: calc(0.375rem * -1);
    --pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginBottom: calc(0.375rem * -1);
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) tr:where(.pf-v5-c-table__tr) {
    position: relative;
    border: none;
    outline-offset: var(--pf-v5-c-table--m-tree-view-grid--tr--OutlineOffset);
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) tr:where(.pf-v5-c-table__tr)::before {
    position: absolute;
    inset-block-start: 0;
    inset-block-end: 0;
    inset-inline-start: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft);
    inset-inline-end: 0;
    content: "";
    border-block-end: var(--pf-v5-c-table--border-width--base) solid var(--pf-v5-c-table--BorderColor);
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not([hidden]) {
    display: grid;
    grid-template-columns: 1fr max-content;
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-expanded] .pf-v5-c-table__tree-view-title-cell {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop);
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-expanded] .pf-v5-c-table__tree-view-title-cell~.pf-v5-c-table__action {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop);
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table td:where(.pf-v5-c-table__td):not(.pf-v5-c-table__tree-view-title-cell) {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop);
    --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft);
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table .pf-v5-c-table__tree-view-text {
    padding-inline-end: var(--pf-v5-c-table__tree-view-text--PaddingRight);
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table thead:where(.pf-v5-c-table__thead) th:where(.pf-v5-c-table__th) {
    display: none;
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table td:where(.pf-v5-c-table__td) {
    display: none;
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label] {
    grid-template-columns: var(--pf-v5-c-table--m-tree-view-grid__td--data-label--GridTemplateColumns);
    grid-column: 1;
    grid-column-gap: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--GridColumnGap);
    align-items: start;
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label]>* {
    grid-column: 2;
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label]::before {
    font-weight: var(--pf-v5-global--FontWeight--bold);
    text-align: start;
    content: attr(data-label);
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-tree-view-details-expanded {
    padding-block-end: var(--pf-v5-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom);
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-tree-view-details-expanded td:where(.pf-v5-c-table__td)[data-label] {
    display: grid;
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table .pf-v5-c-table__tree-view-title-header-cell {
    display: block;
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table .pf-v5-c-table__tree-view-details-toggle,
  .pf-m-tree-view-grid-xl.pf-v5-c-table .pf-v5-c-table__action {
    display: inline-block;
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table .pf-v5-c-table__action {
    --pf-v5-c-table--cell--Width: auto;
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingLeft);
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingTop);
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingBottom);
    grid-row: 1;
    grid-column: 2;
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table .pf-v5-c-table__action>.pf-v5-c-dropdown {
    margin-block-start: var(--pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginTop);
    margin-block-end: var(--pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginBottom);
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table .pf-v5-c-table__tree-view-main>.pf-v5-c-table__check {
    order: var(--pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order);
    margin-inline-start: auto;
    margin-inline-end: var(--pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight);
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table .pf-v5-c-table__tree-view-details-toggle {
    margin-block-start: var(--pf-v5-c-table__tree-view-details-toggle--MarginTop);
    margin-block-end: var(--pf-v5-c-table__tree-view-details-toggle--MarginBottom);
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="2"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 1 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="3"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 2 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="4"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 3 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="5"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 4 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="6"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 5 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="7"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 6 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="8"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 7 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="9"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 8 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="10"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 9 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
}

@media screen and (max-width: 1450px) {
  .pf-m-tree-view-grid-2xl.pf-v5-c-table {
    --pf-v5-c-table--m-tree-view-grid--tr--OutlineOffset: calc(-1 * var(--pf-v5-global--spacer--xs));
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingTop: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingBottom: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: var(--pf-v5-c-table__tree-view-main--indent--base);
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--GridColumnGap: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingTop: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingBottom: var(--pf-v5-global--spacer--md);
    --pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingLeft: 0;
    --pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-table--m-tree-view-grid__td--data-label--GridTemplateColumns: repeat(auto-fit, minmax(150px, 1fr));
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-v5-global--spacer--xs);
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-v5-global--spacer--xs);
    --pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight: 0;
    --pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order: 4;
    --pf-v5-c-table__tree-view-text--PaddingRight: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-table__tbody--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingTop);
    --pf-v5-c-table__tbody--cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingBottom);
    --pf-v5-c-table__tree-view-details-toggle--MarginTop: calc(0.375rem * -1);
    --pf-v5-c-table__tree-view-details-toggle--MarginBottom: calc(0.375rem * -1);
    --pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginTop: calc(0.375rem * -1);
    --pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginBottom: calc(0.375rem * -1);
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) tr:where(.pf-v5-c-table__tr) {
    position: relative;
    border: none;
    outline-offset: var(--pf-v5-c-table--m-tree-view-grid--tr--OutlineOffset);
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table tbody:where(.pf-v5-c-table__tbody) tr:where(.pf-v5-c-table__tr)::before {
    position: absolute;
    inset-block-start: 0;
    inset-block-end: 0;
    inset-inline-start: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft);
    inset-inline-end: 0;
    content: "";
    border-block-end: var(--pf-v5-c-table--border-width--base) solid var(--pf-v5-c-table--BorderColor);
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr):not([hidden]) {
    display: grid;
    grid-template-columns: 1fr max-content;
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-expanded] .pf-v5-c-table__tree-view-title-cell {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop);
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-expanded] .pf-v5-c-table__tree-view-title-cell~.pf-v5-c-table__action {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop);
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table td:where(.pf-v5-c-table__td):not(.pf-v5-c-table__tree-view-title-cell) {
    --pf-v5-c-table--cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop);
    --pf-v5-c-table--cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom);
    padding-inline-start: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft);
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table .pf-v5-c-table__tree-view-text {
    padding-inline-end: var(--pf-v5-c-table__tree-view-text--PaddingRight);
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table thead:where(.pf-v5-c-table__thead) th:where(.pf-v5-c-table__th) {
    display: none;
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table td:where(.pf-v5-c-table__td) {
    display: none;
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label] {
    grid-template-columns: var(--pf-v5-c-table--m-tree-view-grid__td--data-label--GridTemplateColumns);
    grid-column: 1;
    grid-column-gap: var(--pf-v5-c-table--m-tree-view-grid__tbody--cell--GridColumnGap);
    align-items: start;
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label]>* {
    grid-column: 2;
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table td:where(.pf-v5-c-table__td)[data-label]::before {
    font-weight: var(--pf-v5-global--FontWeight--bold);
    text-align: start;
    content: attr(data-label);
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-tree-view-details-expanded {
    padding-block-end: var(--pf-v5-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom);
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr).pf-m-tree-view-details-expanded td:where(.pf-v5-c-table__td)[data-label] {
    display: grid;
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table .pf-v5-c-table__tree-view-title-header-cell {
    display: block;
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table .pf-v5-c-table__tree-view-details-toggle,
  .pf-m-tree-view-grid-2xl.pf-v5-c-table .pf-v5-c-table__action {
    display: inline-block;
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table .pf-v5-c-table__action {
    --pf-v5-c-table--cell--Width: auto;
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingLeft);
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingTop);
    --pf-v5-c-table--m-tree-view-grid__td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-v5-c-table--m-tree-view-grid--c-table__action--PaddingBottom);
    grid-row: 1;
    grid-column: 2;
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table .pf-v5-c-table__action>.pf-v5-c-dropdown {
    margin-block-start: var(--pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginTop);
    margin-block-end: var(--pf-v5-c-table--m-tree-view-grid--c-dropdown--MarginBottom);
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table .pf-v5-c-table__tree-view-main>.pf-v5-c-table__check {
    order: var(--pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order);
    margin-inline-start: auto;
    margin-inline-end: var(--pf-v5-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight);
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table .pf-v5-c-table__tree-view-details-toggle {
    margin-block-start: var(--pf-v5-c-table__tree-view-details-toggle--MarginTop);
    margin-block-end: var(--pf-v5-c-table__tree-view-details-toggle--MarginBottom);
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="2"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 1 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="3"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 2 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="4"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 3 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="5"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 4 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="6"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 5 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="7"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 6 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="8"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 7 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="9"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 8 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }

  .pf-m-tree-view-grid-2xl.pf-v5-c-table tr:where(.pf-v5-c-table__tr)[aria-level="10"] {
    --pf-v5-c-table--m-tree-view-grid__tbody--cell--PaddingLeft: calc(var(--pf-v5-c-table__tree-view-main--nested-indent--base) * 9 + var(--pf-v5-c-table__tree-view-main--indent--base));
  }
}

.pf-v5-c-tabs {
  --pf-v5-c-tabs--inset: 0;
  --pf-v5-c-tabs--Width: auto;
  --pf-v5-c-tabs--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-tabs--before--border-width--base: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-tabs--before--BorderTopWidth: 0;
  --pf-v5-c-tabs--before--BorderRightWidth: 0;
  --pf-v5-c-tabs--before--BorderBottomWidth: var(--pf-v5-c-tabs--before--border-width--base);
  --pf-v5-c-tabs--before--BorderLeftWidth: 0;
  --pf-v5-c-tabs--m-vertical--inset: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-tabs--m-page-insets--inset: var(--pf-v5-global--spacer--md);
  --pf-v5-c-tabs--m-page-insets--xl--inset: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-tabs--m-vertical--Width: 100%;
  --pf-v5-c-tabs--m-vertical--MaxWidth: 15.625rem;
  --pf-v5-c-tabs--m-vertical--m-box--inset: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-tabs--m-vertical__list--before--BorderColor: var(--pf-v5-c-tabs--before--BorderColor);
  --pf-v5-c-tabs--m-vertical__list--before--BorderTopWidth: 0;
  --pf-v5-c-tabs--m-vertical__list--before--BorderRightWidth: 0;
  --pf-v5-c-tabs--m-vertical__list--before--BorderBottomWidth: 0;
  --pf-v5-c-tabs--m-vertical__list--before--BorderLeftWidth: var(--pf-v5-c-tabs--before--border-width--base);
  --pf-v5-c-tabs--m-box__item--m-current--first-child__link--before--BorderLeftWidth: var(--pf-v5-c-tabs__link--before--border-width--base);
  --pf-v5-c-tabs--m-box__item--m-current--last-child__link--before--BorderRightWidth: var(--pf-v5-c-tabs--before--border-width--base);
  --pf-v5-c-tabs--m-color-scheme--light-300__link--BackgroundColor: transparent;
  --pf-v5-c-tabs--m-color-scheme--light-300__item--m-current__link--BackgroundColor: var(--pf-v5-global--BackgroundColor--light-300);
  --pf-v5-c-tabs--m-color-scheme--light-300__link--disabled--BackgroundColor: var(--pf-v5-global--palette--black-150);
  --pf-v5-c-tabs__list--Display: flex;
  --pf-v5-c-tabs__item--m-action--before--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-tabs__link--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-tabs__link--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-tabs__link--BackgroundColor: transparent;
  --pf-v5-c-tabs__link--OutlineOffset: calc(-1 * 0.375rem);
  --pf-v5-c-tabs__link--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-tabs__link--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-tabs__link--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-tabs__link--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-tabs__link--disabled--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-tabs__link--disabled--BackgroundColor: var(--pf-v5-global--palette--black-150);
  --pf-v5-c-tabs__item--m-current__link--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-tabs__item--m-current__link--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-tabs--m-vertical__link--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-tabs--m-vertical__link--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-tabs--m-box__link--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-tabs--m-box__link--disabled--BackgroundColor: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-tabs--m-box__item-action--c-button--disabled--BackgroundColor: var(--pf-v5-global--palette--black-400);
  --pf-v5-c-tabs--m-secondary__link--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-tabs__item--m-action__link--PaddingRight: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-tabs__link--before--border-color--base: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-tabs__link--before--border-width--base: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-tabs__link--before--BorderTopColor: var(--pf-v5-c-tabs__link--before--border-color--base);
  --pf-v5-c-tabs__link--before--BorderRightColor: var(--pf-v5-c-tabs__link--before--border-color--base);
  --pf-v5-c-tabs__link--before--BorderBottomColor: var(--pf-v5-c-tabs__link--before--border-color--base);
  --pf-v5-c-tabs__link--before--BorderLeftColor: var(--pf-v5-c-tabs__link--before--border-color--base);
  --pf-v5-c-tabs__link--before--BorderTopWidth: 0;
  --pf-v5-c-tabs__link--before--BorderRightWidth: 0;
  --pf-v5-c-tabs__link--before--BorderBottomWidth: 0;
  --pf-v5-c-tabs__link--before--BorderLeftWidth: 0;
  --pf-v5-c-tabs__link--before--Left: calc(var(--pf-v5-c-tabs__link--before--border-width--base) * -1);
  --pf-v5-c-tabs__link--disabled--before--BorderRightWidth: 0;
  --pf-v5-c-tabs__link--disabled--before--BorderBottomWidth: var(--pf-v5-c-tabs--before--border-width--base);
  --pf-v5-c-tabs__link--disabled--before--BorderLeftWidth: 0;
  --pf-v5-c-tabs__link--after--Top: auto;
  --pf-v5-c-tabs__link--after--Right: 0;
  --pf-v5-c-tabs__link--after--Bottom: 0;
  --pf-v5-c-tabs__link--after--BorderColor: var(--pf-v5-global--BorderColor--light-100);
  --pf-v5-c-tabs__link--after--BorderWidth: 0;
  --pf-v5-c-tabs__link--after--BorderTopWidth: 0;
  --pf-v5-c-tabs__link--after--BorderRightWidth: 0;
  --pf-v5-c-tabs__link--after--BorderLeftWidth: 0;
  --pf-v5-c-tabs__link--hover--after--BorderWidth: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-tabs__link--focus--after--BorderWidth: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-tabs__link--active--after--BorderWidth: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-tabs__item--m-current__link--after--BorderColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-tabs__item--m-current__link--after--BorderWidth: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-tabs__link--child--MarginRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-tabs__scroll-button--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-tabs__scroll-button--hover--Color: var(--pf-v5-global--active-color--100);
  --pf-v5-c-tabs__scroll-button--disabled--Color: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-tabs__scroll-button--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-tabs__scroll-button--Width: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-tabs__scroll-button--xl--Width: var(--pf-v5-global--spacer--3xl);
  --pf-v5-c-tabs__scroll-button--OutlineOffset: calc(-1 * var(--pf-v5-global--spacer--xs));
  --pf-v5-c-tabs__scroll-button--TransitionDuration--margin: .125s;
  --pf-v5-c-tabs__scroll-button--TransitionDuration--transform: .125s;
  --pf-v5-c-tabs__scroll-button--TransitionDuration--opacity: .125s;
  --pf-v5-c-tabs__scroll-button--before--BorderColor: var(--pf-v5-c-tabs--before--BorderColor);
  --pf-v5-c-tabs__scroll-button--before--border-width--base: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-tabs__scroll-button--before--BorderRightWidth: 0;
  --pf-v5-c-tabs__scroll-button--before--BorderBottomWidth: var(--pf-v5-c-tabs__scroll-button--before--border-width--base);
  --pf-v5-c-tabs__scroll-button--before--BorderLeftWidth: 0;
  --pf-v5-c-tabs__list--ScrollSnapTypeAxis: x;
  --pf-v5-c-tabs__list--ScrollSnapTypeStrictness: proximity;
  --pf-v5-c-tabs__list--ScrollSnapType: var(--pf-v5-c-tabs__list--ScrollSnapTypeAxis) var(--pf-v5-c-tabs__list--ScrollSnapTypeStrictness);
  --pf-v5-c-tabs__item--ScrollSnapAlign: end;
  --pf-v5-c-tabs--m-vertical__list--ScrollSnapTypeAxis: y;
  --pf-v5-c-tabs__toggle--Display: flex;
  --pf-v5-c-tabs__toggle--MarginBottom: 0;
  --pf-v5-c-tabs--m-expanded__toggle--MarginBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-tabs__toggle-icon--Color: currentcolor;
  --pf-v5-c-tabs__toggle-icon--Transition: var(--pf-v5-global--Transition);
  --pf-v5-c-tabs__toggle-icon--Rotate: 0;
  --pf-v5-c-tabs__toggle-text--MarginLeft: 0;
  --pf-v5-c-tabs__toggle-button__toggle-text--MarginLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-tabs__toggle-button__toggle-text--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-tabs__toggle-button--MarginTop: calc(-1 * var(--pf-v5-global--spacer--form-element));
  --pf-v5-c-tabs__toggle-button--MarginBottom: calc(-1 * var(--pf-v5-global--spacer--form-element));
  --pf-v5-c-tabs__toggle-button--MarginLeft: calc(-1 * var(--pf-v5-global--spacer--md));
  --pf-v5-c-tabs--m-expanded__toggle-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-tabs--m-expanded__toggle-icon--Rotate: 90deg;
  --pf-v5-c-tabs__item-action--c-button--FontSize: var(--pf-v5-global--FontSize--xs);
  --pf-v5-c-tabs--m-secondary__item-action--c-button--FontSize: var(--pf-v5-global--icon--FontSize--sm);
  --pf-v5-c-tabs__item-action--c-button--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-tabs__item-action--c-button--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-tabs__item-action--c-button--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-tabs__item-action--c-button--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-tabs__item-action--last-child--c-button--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-tabs__item-action--c-button--OutlineOffset: -0.1875rem;
  --pf-v5-c-tabs__item-action-icon--MarginTop: 0.125rem;
  --pf-v5-c-tabs__add--before--BorderColor: var(--pf-v5-c-tabs__link--before--border-color--base);
  --pf-v5-c-tabs__add--before--BorderLeftWidth: var(--pf-v5-c-tabs__link--before--border-width--base);
  --pf-v5-c-tabs__add--c-button--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-tabs--m-secondary__add--c-button--FontSize: var(--pf-v5-global--FontSize--xs);
  --pf-v5-c-tabs__add--c-button--PaddingTop: var(--pf-v5-c-tabs__link--PaddingTop);
  --pf-v5-c-tabs__add--c-button--PaddingBottom: var(--pf-v5-c-tabs__link--PaddingBottom);
  --pf-v5-c-tabs__add--c-button--OutlineOffset: calc(-1 * var(--pf-v5-global--spacer--xs));
  --pf-v5-c-tabs__link-toggle-icon--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-tabs__link-toggle-icon--Transition: .2s ease-in 0s;
  --pf-v5-c-tabs__link-toggle-icon--Rotate: 0;
  --pf-v5-c-tabs__link-toggle-icon--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-tabs__link--m-expanded__toggle-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-tabs__link--m-expanded__toggle-icon--Rotate: 90deg;
  --pf-v5-c-tabs__link--hover__toggle-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-tabs__link--active__toggle-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-tabs__link--focus__toggle-icon--Color: var(--pf-v5-global--Color--100);
  position: relative;
  display: flex;
  width: var(--pf-v5-c-tabs--Width);
  padding-inline-start: var(--pf-v5-c-tabs--inset);
  padding-inline-end: var(--pf-v5-c-tabs--inset);
  overflow: hidden;
}

@media screen and (min-width: 1200px) {
  .pf-v5-c-tabs {
    --pf-v5-c-tabs__scroll-button--Width: var(--pf-v5-c-tabs__scroll-button--xl--Width);
    --pf-v5-c-tabs--m-page-insets--inset: var(--pf-v5-c-tabs--m-page-insets--xl--inset);
  }
}

.pf-v5-c-tabs::before {
  position: absolute;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  border: solid var(--pf-v5-c-tabs--before--BorderColor);
  border-block-start-width: var(--pf-v5-c-tabs--before--BorderTopWidth);
  border-block-end-width: var(--pf-v5-c-tabs--before--BorderBottomWidth);
  border-inline-start-width: var(--pf-v5-c-tabs--before--BorderLeftWidth);
  border-inline-end-width: var(--pf-v5-c-tabs--before--BorderRightWidth);
}

.pf-v5-c-tabs.pf-m-fill .pf-v5-c-tabs__list {
  flex-basis: 100%;
}

.pf-v5-c-tabs.pf-m-fill .pf-v5-c-tabs__item {
  flex-grow: 1;
}

.pf-v5-c-tabs.pf-m-fill .pf-v5-c-tabs__item:first-child {
  --pf-v5-c-tabs--m-box__item--m-current--first-child__link--before--BorderLeftWidth: 0;
}

.pf-v5-c-tabs.pf-m-fill .pf-v5-c-tabs__item:last-child {
  --pf-v5-c-tabs--m-box__item--m-current--last-child__link--before--BorderRightWidth: 0;
}

.pf-v5-c-tabs.pf-m-fill .pf-v5-c-tabs__link {
  flex-basis: 100%;
  justify-content: center;
}

.pf-v5-c-tabs.pf-m-scrollable .pf-v5-c-tabs__scroll-button {
  opacity: 1;
}

.pf-v5-c-tabs.pf-m-scrollable .pf-v5-c-tabs__scroll-button:nth-of-type(1) {
  margin-inline-end: 0;
  transform: translateX(0);
}

.pf-v5-c-tabs.pf-m-scrollable .pf-v5-c-tabs__scroll-button:nth-of-type(2) {
  margin-inline-start: 0;
  transform: translateX(0);
}

.pf-v5-c-tabs.pf-m-no-border-bottom {
  --pf-v5-c-tabs--before--BorderBottomWidth: 0;
  --pf-v5-c-tabs__link--disabled--before--BorderBottomWidth: 0;
}

.pf-v5-c-tabs.pf-m-box .pf-v5-c-tabs__link,
.pf-v5-c-tabs.pf-m-vertical .pf-v5-c-tabs__link {
  --pf-v5-c-tabs__link--after--BorderBottomWidth: 0;
}

.pf-v5-c-tabs.pf-m-box {
  --pf-v5-c-tabs__link--BackgroundColor: var(--pf-v5-c-tabs--m-box__link--BackgroundColor);
  --pf-v5-c-tabs__link--disabled--BackgroundColor: var(--pf-v5-c-tabs--m-box__link--disabled--BackgroundColor);
  --pf-v5-c-tabs__link--before--BorderBottomWidth: var(--pf-v5-c-tabs__link--before--border-width--base);
  --pf-v5-c-tabs__link--before--BorderRightWidth: var(--pf-v5-c-tabs__link--before--border-width--base);
  --pf-v5-c-tabs__link--disabled--before--BorderRightWidth: var(--pf-v5-c-tabs__link--before--border-width--base);
  --pf-v5-c-tabs__link--after--Top: 0;
  --pf-v5-c-tabs__link--after--Bottom: auto;
}

.pf-v5-c-tabs.pf-m-box .pf-v5-c-tabs__link {
  --pf-v5-c-tabs__link--after--BorderTopWidth: var(--pf-v5-c-tabs__link--after--BorderWidth);
}

.pf-v5-c-tabs.pf-m-box .pf-v5-c-tabs__item:last-child {
  --pf-v5-c-tabs__link--before--BorderRightWidth: 0;
}

.pf-v5-c-tabs.pf-m-box .pf-v5-c-tabs__item.pf-m-current {
  --pf-v5-c-tabs__link--BackgroundColor: var(--pf-v5-c-tabs__item--m-current__link--BackgroundColor);
  --pf-v5-c-tabs__link--before--BorderBottomColor: var(--pf-v5-c-tabs__link--BackgroundColor);
}

.pf-v5-c-tabs.pf-m-box .pf-v5-c-tabs__item.pf-m-current:first-child .pf-v5-c-tabs__link::before {
  border-inline-start-width: var(--pf-v5-c-tabs--m-box__item--m-current--first-child__link--before--BorderLeftWidth);
}

.pf-v5-c-tabs.pf-m-box .pf-v5-c-tabs__item.pf-m-current:last-child .pf-v5-c-tabs__link::before {
  border-inline-end-width: var(--pf-v5-c-tabs--m-box__item--m-current--last-child__link--before--BorderRightWidth);
}

.pf-v5-c-tabs.pf-m-box.pf-m-scrollable .pf-v5-c-tabs__item.pf-m-current:first-child .pf-v5-c-tabs__link::before {
  inset-inline-start: calc(var(--pf-v5-c-tabs__link--before--border-width--base)*-1);
}

.pf-v5-c-tabs.pf-m-box.pf-m-scrollable .pf-v5-c-tabs__scroll-button:nth-of-type(2)::before {
  inset-inline-start: calc(var(--pf-v5-c-tabs__link--before--border-width--base)*-1);
}

.pf-v5-c-tabs.pf-m-box .pf-v5-c-tabs__item.pf-m-current+.pf-v5-c-tabs__item {
  --pf-v5-c-tabs__link--before--Left: 0;
}

.pf-v5-c-tabs.pf-m-box.pf-m-color-scheme--light-300 {
  --pf-v5-c-tabs__link--BackgroundColor: var(--pf-v5-c-tabs--m-color-scheme--light-300__link--BackgroundColor);
  --pf-v5-c-tabs__item--m-current__link--BackgroundColor: var(--pf-v5-c-tabs--m-color-scheme--light-300__item--m-current__link--BackgroundColor);
  --pf-v5-c-tabs__link--disabled--BackgroundColor: var(--pf-v5-c-tabs--m-color-scheme--light-300__link--disabled--BackgroundColor);
}

.pf-v5-c-tabs.pf-m-box .pf-v5-c-tabs__item-action .pf-v5-c-button {
  --pf-v5-c-button--m-plain--disabled--Color: var(--pf-v5-c-tabs--m-box__item-action--c-button--disabled--BackgroundColor);
}

.pf-v5-c-tabs.pf-m-vertical {
  --pf-v5-c-tabs--Width: var(--pf-v5-c-tabs--m-vertical--Width);
  --pf-v5-c-tabs--inset: var(--pf-v5-c-tabs--m-vertical--inset);
  --pf-v5-c-tabs--before--BorderBottomWidth: 0;
  --pf-v5-c-tabs__link--PaddingTop: var(--pf-v5-c-tabs--m-vertical__link--PaddingTop);
  --pf-v5-c-tabs__link--PaddingBottom: var(--pf-v5-c-tabs--m-vertical__link--PaddingBottom);
  --pf-v5-c-tabs__link--before--Left: 0;
  --pf-v5-c-tabs__link--disabled--before--BorderBottomWidth: 0;
  --pf-v5-c-tabs__link--disabled--before--BorderLeftWidth: var(--pf-v5-c-tabs--before--border-width--base);
  --pf-v5-c-tabs__link--after--Top: 0;
  --pf-v5-c-tabs__link--after--Bottom: 0;
  --pf-v5-c-tabs__link--after--Right: auto;
  --pf-v5-c-tabs__list--ScrollSnapTypeAxis: var(--pf-v5-c-tabs--m-vertical__list--ScrollSnapTypeAxis);
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
  overflow: visible;
}

.pf-v5-c-tabs.pf-m-vertical .pf-v5-c-tabs__list {
  position: relative;
  flex-direction: column;
  flex-grow: 1;
  max-width: var(--pf-v5-c-tabs--m-vertical--MaxWidth);
}

.pf-v5-c-tabs.pf-m-vertical .pf-v5-c-tabs__list::before {
  position: absolute;
  inset-inline-end: auto;
  border: solid var(--pf-v5-c-tabs--m-vertical__list--before--BorderColor);
  border-block-start-width: var(--pf-v5-c-tabs--m-vertical__list--before--BorderTopWidth);
  border-block-end-width: var(--pf-v5-c-tabs--m-vertical__list--before--BorderBottomWidth);
  border-inline-start-width: var(--pf-v5-c-tabs--m-vertical__list--before--BorderLeftWidth);
  border-inline-end-width: var(--pf-v5-c-tabs--m-vertical__list--before--BorderRightWidth);
}

.pf-v5-c-tabs.pf-m-vertical .pf-v5-c-tabs__item:first-child {
  margin-block-start: var(--pf-v5-c-tabs--inset);
}

.pf-v5-c-tabs.pf-m-vertical .pf-v5-c-tabs__item:last-child {
  margin-block-end: var(--pf-v5-c-tabs--inset);
}

.pf-v5-c-tabs.pf-m-vertical .pf-v5-c-tabs__link {
  --pf-v5-c-tabs__link--after--BorderTopWidth: 0;
  --pf-v5-c-tabs__link--after--BorderLeftWidth: var(--pf-v5-c-tabs__link--after--BorderWidth);
  max-width: 100%;
  text-align: start;
}

.pf-v5-c-tabs.pf-m-vertical .pf-v5-c-tabs__item-text {
  max-width: 100%;
  overflow-wrap: break-word;
}

.pf-v5-c-tabs.pf-m-vertical.pf-m-expandable {
  --pf-v5-c-tabs__list--Display: none;
  --pf-v5-c-tabs__toggle--Display: flex;
}

.pf-v5-c-tabs.pf-m-vertical.pf-m-non-expandable {
  --pf-v5-c-tabs__list--Display: flex;
  --pf-v5-c-tabs__toggle--Display: none;
}

@media (min-width: 576px) {
  .pf-v5-c-tabs.pf-m-vertical.pf-m-expandable-on-sm {
    --pf-v5-c-tabs__list--Display: none;
    --pf-v5-c-tabs__toggle--Display: flex;
  }

  .pf-v5-c-tabs.pf-m-vertical.pf-m-non-expandable-on-sm {
    --pf-v5-c-tabs__list--Display: flex;
    --pf-v5-c-tabs__toggle--Display: none;
  }
}

@media (min-width: 768px) {
  .pf-v5-c-tabs.pf-m-vertical.pf-m-expandable-on-md {
    --pf-v5-c-tabs__list--Display: none;
    --pf-v5-c-tabs__toggle--Display: flex;
  }

  .pf-v5-c-tabs.pf-m-vertical.pf-m-non-expandable-on-md {
    --pf-v5-c-tabs__list--Display: flex;
    --pf-v5-c-tabs__toggle--Display: none;
  }
}

@media (min-width: 992px) {
  .pf-v5-c-tabs.pf-m-vertical.pf-m-expandable-on-lg {
    --pf-v5-c-tabs__list--Display: none;
    --pf-v5-c-tabs__toggle--Display: flex;
  }

  .pf-v5-c-tabs.pf-m-vertical.pf-m-non-expandable-on-lg {
    --pf-v5-c-tabs__list--Display: flex;
    --pf-v5-c-tabs__toggle--Display: none;
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-tabs.pf-m-vertical.pf-m-expandable-on-xl {
    --pf-v5-c-tabs__list--Display: none;
    --pf-v5-c-tabs__toggle--Display: flex;
  }

  .pf-v5-c-tabs.pf-m-vertical.pf-m-non-expandable-on-xl {
    --pf-v5-c-tabs__list--Display: flex;
    --pf-v5-c-tabs__toggle--Display: none;
  }
}

@media (min-width: 1450px) {
  .pf-v5-c-tabs.pf-m-vertical.pf-m-expandable-on-2xl {
    --pf-v5-c-tabs__list--Display: none;
    --pf-v5-c-tabs__toggle--Display: flex;
  }

  .pf-v5-c-tabs.pf-m-vertical.pf-m-non-expandable-on-2xl {
    --pf-v5-c-tabs__list--Display: flex;
    --pf-v5-c-tabs__toggle--Display: none;
  }
}

.pf-v5-c-tabs.pf-m-vertical.pf-m-expanded {
  --pf-v5-c-tabs__list--Display: flex;
  --pf-v5-c-tabs__toggle--MarginBottom: var(--pf-v5-c-tabs--m-expanded__toggle--MarginBottom);
  --pf-v5-c-tabs__toggle-icon--Color: var(--pf-v5-c-tabs--m-expanded__toggle-icon--Color);
  --pf-v5-c-tabs__toggle-icon--Rotate: var(--pf-v5-c-tabs--m-expanded__toggle-icon--Rotate);
}

.pf-v5-c-tabs.pf-m-box.pf-m-vertical {
  --pf-v5-c-tabs--inset: var(--pf-v5-c-tabs--m-vertical--m-box--inset);
  --pf-v5-c-tabs--m-vertical__list--before--BorderLeftWidth: 0;
  --pf-v5-c-tabs--m-vertical__list--before--BorderRightWidth: var(--pf-v5-c-tabs--before--border-width--base);
  --pf-v5-c-tabs__link--disabled--before--BorderRightWidth: var(--pf-v5-c-tabs--before--border-width--base);
  --pf-v5-c-tabs__link--disabled--before--BorderBottomWidth: var(--pf-v5-c-tabs--before--border-width--base);
  --pf-v5-c-tabs__link--disabled--before--BorderLeftWidth: 0;
}

.pf-v5-c-tabs.pf-m-box.pf-m-vertical .pf-v5-c-tabs__list::before {
  inset-inline-start: auto;
  inset-inline-end: 0;
}

.pf-v5-c-tabs.pf-m-box.pf-m-vertical .pf-v5-c-tabs__item:last-child {
  --pf-v5-c-tabs__link--before--BorderBottomWidth: 0;
  --pf-v5-c-tabs__link--before--BorderRightWidth: var(--pf-v5-c-tabs__link--before--border-width--base);
}

.pf-v5-c-tabs.pf-m-box.pf-m-vertical .pf-v5-c-tabs__item.pf-m-current {
  --pf-v5-c-tabs__link--before--BorderRightColor: var(--pf-v5-c-tabs__item--m-current__link--BackgroundColor);
  --pf-v5-c-tabs__link--before--BorderBottomColor: var(--pf-v5-c-tabs__link--before--border-color--base);
  --pf-v5-c-tabs__link--before--BorderBottomWidth: var(--pf-v5-c-tabs__link--before--border-width--base);
}

.pf-v5-c-tabs.pf-m-box.pf-m-vertical .pf-v5-c-tabs__item.pf-m-current:first-child {
  --pf-v5-c-tabs__link--before--BorderTopWidth: var(--pf-v5-c-tabs__link--before--border-width--base);
}

.pf-v5-c-tabs.pf-m-box.pf-m-vertical .pf-v5-c-tabs__item:first-child.pf-m-current {
  --pf-v5-c-tabs__link--before--BorderTopWidth: var(--pf-v5-c-tabs__link--before--border-width--base);
}

.pf-v5-c-tabs.pf-m-box.pf-m-vertical .pf-v5-c-tabs__link::after {
  inset-block-start: calc(var(--pf-v5-c-tabs__link--before--border-width--base)*-1);
}

.pf-v5-c-tabs.pf-m-box.pf-m-vertical .pf-v5-c-tabs__item:first-child .pf-v5-c-tabs__link::after,
.pf-v5-c-tabs.pf-m-box.pf-m-vertical .pf-v5-c-tabs__item.pf-m-current+.pf-v5-c-tabs__item .pf-v5-c-tabs__link::after {
  inset-block-start: 0;
}

.pf-v5-c-tabs.pf-m-secondary {
  --pf-v5-c-tabs__link--FontSize: var(--pf-v5-c-tabs--m-secondary__link--FontSize);
  --pf-v5-c-tabs__item-action--c-button--FontSize: var(--pf-v5-c-tabs--m-secondary__item-action--c-button--FontSize);
  --pf-v5-c-tabs__add--c-button--FontSize: var(--pf-v5-c-tabs--m-secondary__add--c-button--FontSize);
}

.pf-v5-c-tabs.pf-m-page-insets {
  --pf-v5-c-tabs--inset: var(--pf-v5-c-tabs--m-page-insets--inset);
}

.pf-v5-c-tabs.pf-m-overflow,
.pf-v5-c-tabs.pf-m-overflow .pf-v5-c-tabs__list {
  overflow: visible;
}

.pf-v5-c-tabs__toggle {
  display: var(--pf-v5-c-tabs__toggle--Display);
  align-items: center;
  margin-block-end: var(--pf-v5-c-tabs__toggle--MarginBottom);
}

.pf-v5-c-tabs__toggle-button {
  --pf-v5-c-tabs__toggle-text--MarginLeft: var(--pf-v5-c-tabs__toggle-button__toggle-text--MarginLeft);
  --pf-v5-c-tabs__toggle-text--Color: var(--pf-v5-c-tabs__toggle-button__toggle-text--Color);
  margin-block-start: var(--pf-v5-c-tabs__toggle-button--MarginTop);
  margin-block-end: var(--pf-v5-c-tabs__toggle-button--MarginBottom);
  margin-inline-start: var(--pf-v5-c-tabs__toggle-button--MarginLeft);
}

.pf-v5-c-tabs__toggle-button .pf-v5-c-button {
  display: flex;
  text-align: start;
  white-space: normal;
}

.pf-v5-c-tabs__toggle-icon {
  display: inline-block;
  color: var(--pf-v5-c-tabs__toggle-icon--Color);
  transition: var(--pf-v5-c-tabs__toggle-icon--Transition);
  transform: rotate(var(--pf-v5-c-tabs__toggle-icon--Rotate));
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-tabs__toggle-icon {
  scale: -1 1;
}

.pf-v5-c-tabs__toggle-text {
  margin-inline-start: var(--pf-v5-c-tabs__toggle-text--MarginLeft);
  color: var(--pf-v5-c-tabs__toggle-text--Color, inherit);
}

.pf-v5-c-tabs__list {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  position: relative;
  display: var(--pf-v5-c-tabs__list--Display);
  max-width: 100%;
  overflow-x: auto;
  scroll-behavior: smooth;
  scroll-snap-type: var(--pf-v5-c-tabs__list--ScrollSnapType);
  -webkit-overflow-scrolling: touch;
}

.pf-v5-c-tabs__list::-webkit-scrollbar {
  display: none;
}

.pf-v5-c-tabs__item {
  display: flex;
  flex: none;
  scroll-snap-align: var(--pf-v5-c-tabs__item--ScrollSnapAlign);
}

.pf-v5-c-tabs__item.pf-m-current {
  --pf-v5-c-tabs__link--Color: var(--pf-v5-c-tabs__item--m-current__link--Color);
  --pf-v5-c-tabs__link--after--BorderColor: var(--pf-v5-c-tabs__item--m-current__link--after--BorderColor);
  --pf-v5-c-tabs__link--after--BorderWidth: var(--pf-v5-c-tabs__item--m-current__link--after--BorderWidth);
}

.pf-v5-c-tabs__item.pf-m-action {
  --pf-v5-c-tabs__link--PaddingRight: var(--pf-v5-c-tabs__item--m-action__link--PaddingRight);
  position: relative;
}

.pf-v5-c-tabs__item.pf-m-action::before {
  z-index: var(--pf-v5-c-tabs__item--m-action--before--ZIndex);
}

.pf-v5-c-tabs__item.pf-m-action .pf-v5-c-tabs__link::before,
.pf-v5-c-tabs__item.pf-m-action .pf-v5-c-tabs__link::after {
  content: revert;
}

.pf-v5-c-tabs::before,
.pf-v5-c-tabs__list::before,
.pf-v5-c-tabs__link::before,
.pf-v5-c-tabs__link::after,
.pf-v5-c-tabs__item.pf-m-action::before,
.pf-v5-c-tabs__item.pf-m-action::after,
.pf-v5-c-tabs__scroll-button::before,
.pf-v5-c-tabs__add::before {
  position: absolute;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  content: "";
  border-style: solid;
}

.pf-v5-c-tabs__list::before,
.pf-v5-c-tabs__link::before,
.pf-v5-c-tabs__link::after,
.pf-v5-c-tabs__item.pf-m-action::before,
.pf-v5-c-tabs__item.pf-m-action::after,
.pf-v5-c-tabs__scroll-button::before,
.pf-v5-c-tabs__add::before {
  inset-block-start: 0;
}

.pf-v5-c-tabs__link,
.pf-v5-c-tabs__scroll-button,
.pf-v5-c-tabs__list::before,
.pf-v5-c-tabs__add::before {
  border: 0;
}

.pf-v5-c-tabs__link {
  position: relative;
  display: flex;
  flex: 1;
  padding-block-start: var(--pf-v5-c-tabs__link--PaddingTop);
  padding-block-end: var(--pf-v5-c-tabs__link--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-tabs__link--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-tabs__link--PaddingRight);
  font-size: var(--pf-v5-c-tabs__link--FontSize);
  color: var(--pf-v5-c-tabs__link--Color);
  text-decoration: none;
  outline-offset: var(--pf-v5-c-tabs__link--OutlineOffset);
}

.pf-v5-c-tabs__item.pf-m-action,
.pf-v5-c-tabs__link {
  --pf-v5-c-tabs__link--after--BorderBottomWidth: var(--pf-v5-c-tabs__link--after--BorderWidth);
  background-color: var(--pf-v5-c-tabs__link--BackgroundColor);
}

.pf-v5-c-tabs__item.pf-m-action::before,
.pf-v5-c-tabs__link::before {
  pointer-events: none;
  border-block-start-color: var(--pf-v5-c-tabs__link--before--BorderTopColor);
  border-block-start-width: var(--pf-v5-c-tabs__link--before--BorderTopWidth);
  border-block-end-color: var(--pf-v5-c-tabs__link--before--BorderBottomColor);
  border-block-end-width: var(--pf-v5-c-tabs__link--before--BorderBottomWidth);
  border-inline-start-color: var(--pf-v5-c-tabs__link--before--BorderLeftColor);
  border-inline-start-width: var(--pf-v5-c-tabs__link--before--BorderLeftWidth);
  border-inline-end-color: var(--pf-v5-c-tabs__link--before--BorderRightColor);
  border-inline-end-width: var(--pf-v5-c-tabs__link--before--BorderRightWidth);
}

.pf-v5-c-tabs__item.pf-m-action::after,
.pf-v5-c-tabs__link::after {
  inset-block-start: var(--pf-v5-c-tabs__link--after--Top);
  inset-block-end: var(--pf-v5-c-tabs__link--after--Bottom);
  inset-inline-start: var(--pf-v5-c-tabs__link--before--Left);
  inset-inline-end: var(--pf-v5-c-tabs__link--after--Right);
  border-color: var(--pf-v5-c-tabs__link--after--BorderColor);
  border-block-start-width: var(--pf-v5-c-tabs__link--after--BorderTopWidth);
  border-block-end-width: var(--pf-v5-c-tabs__link--after--BorderBottomWidth);
  border-inline-start-width: var(--pf-v5-c-tabs__link--after--BorderLeftWidth);
  border-inline-end-width: var(--pf-v5-c-tabs__link--after--BorderRightWidth);
}

.pf-v5-c-tabs__item.pf-m-action:hover,
.pf-v5-c-tabs__link:hover {
  --pf-v5-c-tabs__link--after--BorderWidth: var(--pf-v5-c-tabs__link--hover--after--BorderWidth);
}

.pf-v5-c-tabs__item.pf-m-action:focus-within,
.pf-v5-c-tabs__link:focus {
  --pf-v5-c-tabs__link--after--BorderWidth: var(--pf-v5-c-tabs__link--focus--after--BorderWidth);
}

.pf-v5-c-tabs__item.pf-m-action:active,
.pf-v5-c-tabs__link:active {
  --pf-v5-c-tabs__link--after--BorderWidth: var(--pf-v5-c-tabs__link--active--after--BorderWidth);
}

.pf-v5-c-tabs__link:hover {
  --pf-v5-c-tabs__link-toggle-icon--Color: var(--pf-v5-c-tabs__link--hover__toggle-icon--Color);
}

.pf-v5-c-tabs__link:focus {
  --pf-v5-c-tabs__link-toggle-icon--Color: var(--pf-v5-c-tabs__link--focus__toggle-icon--Color);
}

.pf-v5-c-tabs__link:active,
.pf-v5-c-tabs__link.pf-m-active {
  --pf-v5-c-tabs__link-toggle-icon--Color: var(--pf-v5-c-tabs__link--active__toggle-icon--Color);
}

.pf-v5-c-tabs__link:disabled,
.pf-v5-c-tabs__link.pf-m-disabled {
  pointer-events: none;
}

.pf-v5-c-tabs__item.pf-m-action.pf-m-disabled,
.pf-v5-c-tabs__link:disabled,
.pf-v5-c-tabs__link.pf-m-disabled,
.pf-v5-c-tabs__link.pf-m-aria-disabled {
  --pf-v5-c-tabs__link--Color: var(--pf-v5-c-tabs__link--disabled--Color);
  --pf-v5-c-tabs__link--BackgroundColor: var(--pf-v5-c-tabs__link--disabled--BackgroundColor);
  --pf-v5-c-tabs__link--before--BorderRightWidth: var(--pf-v5-c-tabs__link--disabled--before--BorderRightWidth);
  --pf-v5-c-tabs__link--before--BorderBottomWidth: var(--pf-v5-c-tabs__link--disabled--before--BorderBottomWidth);
  --pf-v5-c-tabs__link--before--BorderLeftWidth: var(--pf-v5-c-tabs__link--disabled--before--BorderLeftWidth);
  --pf-v5-c-tabs__link--after--BorderWidth: 0;
}

.pf-v5-c-tabs__link.pf-m-aria-disabled {
  cursor: default;
}

.pf-v5-c-tabs__link .pf-v5-c-tabs__item-icon,
.pf-v5-c-tabs__link .pf-v5-c-tabs__item-text {
  margin-inline-end: var(--pf-v5-c-tabs__link--child--MarginRight);
}

.pf-v5-c-tabs__link .pf-v5-c-tabs__item-icon:last-child,
.pf-v5-c-tabs__link .pf-v5-c-tabs__item-text:last-child {
  --pf-v5-c-tabs__link--child--MarginRight: 0;
}

.pf-v5-c-tabs__link.pf-m-expanded {
  --pf-v5-c-tabs__link-toggle-icon--Color: var(--pf-v5-c-tabs__link--m-expanded__toggle-icon--Color);
  --pf-v5-c-tabs__link-toggle-icon--Rotate: var(--pf-v5-c-tabs__link--m-expanded__toggle-icon--Rotate);
}

.pf-v5-c-tabs__link-toggle-icon {
  align-self: end;
  font-size: var(--pf-v5-c-tabs__link-toggle-icon--FontSize);
  color: var(--pf-v5-c-tabs__link-toggle-icon--Color);
  transition: var(--pf-v5-c-tabs__link-toggle-icon--Transition);
  transform: rotate(var(--pf-v5-c-tabs__link-toggle-icon--Rotate));
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-tabs__link-toggle-icon {
  scale: -1 1;
}

.pf-v5-c-tabs__item-action {
  display: flex;
}

.pf-v5-c-tabs__item-action .pf-v5-c-button {
  --pf-v5-c-button--FontSize: var(--pf-v5-c-tabs__item-action--c-button--FontSize);
  --pf-v5-c-button--PaddingTop: var(--pf-v5-c-tabs__item-action--c-button--PaddingTop);
  --pf-v5-c-button--PaddingRight: var(--pf-v5-c-tabs__item-action--c-button--PaddingRight);
  --pf-v5-c-button--PaddingBottom: var(--pf-v5-c-tabs__item-action--c-button--PaddingBottom);
  --pf-v5-c-button--PaddingLeft: var(--pf-v5-c-tabs__item-action--c-button--PaddingLeft);
  outline-offset: var(--pf-v5-c-tabs__item-action--c-button--OutlineOffset);
}

.pf-v5-c-tabs__item-action:last-child {
  --pf-v5-c-tabs__item-action--c-button--PaddingRight: var(--pf-v5-c-tabs__item-action--last-child--c-button--PaddingRight);
}

.pf-v5-c-tabs__item-action-icon {
  display: inline-block;
  margin-block-start: var(--pf-v5-c-tabs__item-action-icon--MarginTop);
}

.pf-v5-c-tabs__scroll-button {
  flex: none;
  width: var(--pf-v5-c-tabs__scroll-button--Width);
  line-height: 1;
  color: var(--pf-v5-c-tabs__scroll-button--Color);
  background-color: var(--pf-v5-c-tabs__scroll-button--BackgroundColor);
  outline-offset: var(--pf-v5-c-tabs__scroll-button--OutlineOffset);
  opacity: 0;
  transition: margin var(--pf-v5-c-tabs__scroll-button--TransitionDuration--margin),transform var(--pf-v5-c-tabs__scroll-button--TransitionDuration--transform),opacity var(--pf-v5-c-tabs__scroll-button--TransitionDuration--opacity);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-tabs__scroll-button>* {
  scale: -1 1;
}

.pf-v5-c-tabs__scroll-button:hover,
.pf-v5-c-tabs__scroll-button:active,
.pf-v5-c-tabs__scroll-button:focus {
  --pf-v5-c-tabs__scroll-button--Color: var(--pf-v5-c-tabs__scroll-button--hover--Color);
}

.pf-v5-c-tabs__scroll-button::before {
  border-color: var(--pf-v5-c-tabs__scroll-button--before--BorderColor);
  border-block-start-width: 0;
  border-block-end-width: var(--pf-v5-c-tabs__scroll-button--before--BorderBottomWidth);
  border-inline-start-width: var(--pf-v5-c-tabs__scroll-button--before--BorderLeftWidth);
  border-inline-end-width: var(--pf-v5-c-tabs__scroll-button--before--BorderRightWidth);
}

.pf-v5-c-tabs__scroll-button:nth-of-type(1) {
  --pf-v5-c-tabs__scroll-button--before--BorderRightWidth: var(--pf-v5-c-tabs__scroll-button--before--border-width--base);
  margin-inline-end: calc(var(--pf-v5-c-tabs__scroll-button--Width)*-1);
  transform: translateX(-100%);
}

.pf-v5-c-tabs__scroll-button:nth-of-type(2) {
  --pf-v5-c-tabs__scroll-button--before--BorderLeftWidth: var(--pf-v5-c-tabs__scroll-button--before--border-width--base);
  margin-inline-start: calc(var(--pf-v5-c-tabs__scroll-button--Width)*-1);
  transform: translateX(100%);
}

.pf-v5-c-tabs__scroll-button:disabled {
  --pf-v5-c-tabs__scroll-button--Color: var(--pf-v5-c-tabs__scroll-button--disabled--Color);
  pointer-events: none;
}

.pf-v5-c-tabs__add {
  position: relative;
  display: flex;
}

.pf-v5-c-tabs__add::before {
  border-inline-start: var(--pf-v5-c-tabs__add--before--BorderLeftWidth) solid var(--pf-v5-c-tabs__add--before--BorderColor);
}

.pf-v5-c-tabs__add .pf-v5-c-button {
  --pf-v5-c-button--FontSize: var(--pf-v5-c-tabs__add--c-button--FontSize);
  --pf-v5-c-button--PaddingTop: var(--pf-v5-c-tabs__add--c-button--PaddingTop);
  --pf-v5-c-button--PaddingBottom: var(--pf-v5-c-tabs__add--c-button--PaddingBottom);
  outline-offset: var(--pf-v5-c-tabs__add--c-button--OutlineOffset);
}

.pf-v5-c-tabs.pf-m-inset-none {
  --pf-v5-c-tabs--inset: 0;
  --pf-v5-c-tabs--m-vertical--inset: 0;
  --pf-v5-c-tabs--m-vertical--m-box--inset: 0;
}

.pf-v5-c-tabs.pf-m-inset-sm {
  --pf-v5-c-tabs--inset: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-tabs--m-vertical--inset: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-tabs--m-vertical--m-box--inset: var(--pf-v5-global--spacer--sm);
}

.pf-v5-c-tabs.pf-m-inset-md {
  --pf-v5-c-tabs--inset: var(--pf-v5-global--spacer--md);
  --pf-v5-c-tabs--m-vertical--inset: var(--pf-v5-global--spacer--md);
  --pf-v5-c-tabs--m-vertical--m-box--inset: var(--pf-v5-global--spacer--md);
}

.pf-v5-c-tabs.pf-m-inset-lg {
  --pf-v5-c-tabs--inset: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-tabs--m-vertical--inset: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-tabs--m-vertical--m-box--inset: var(--pf-v5-global--spacer--lg);
}

.pf-v5-c-tabs.pf-m-inset-xl {
  --pf-v5-c-tabs--inset: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-tabs--m-vertical--inset: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-tabs--m-vertical--m-box--inset: var(--pf-v5-global--spacer--xl);
}

.pf-v5-c-tabs.pf-m-inset-2xl {
  --pf-v5-c-tabs--inset: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-tabs--m-vertical--inset: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-tabs--m-vertical--m-box--inset: var(--pf-v5-global--spacer--2xl);
}

@media (min-width: 576px) {
  .pf-v5-c-tabs.pf-m-inset-none-on-sm {
    --pf-v5-c-tabs--inset: 0;
    --pf-v5-c-tabs--m-vertical--inset: 0;
    --pf-v5-c-tabs--m-vertical--m-box--inset: 0;
  }

  .pf-v5-c-tabs.pf-m-inset-sm-on-sm {
    --pf-v5-c-tabs--inset: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-tabs--m-vertical--inset: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-tabs--m-vertical--m-box--inset: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-tabs.pf-m-inset-md-on-sm {
    --pf-v5-c-tabs--inset: var(--pf-v5-global--spacer--md);
    --pf-v5-c-tabs--m-vertical--inset: var(--pf-v5-global--spacer--md);
    --pf-v5-c-tabs--m-vertical--m-box--inset: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-tabs.pf-m-inset-lg-on-sm {
    --pf-v5-c-tabs--inset: var(--pf-v5-global--spacer--lg);
    --pf-v5-c-tabs--m-vertical--inset: var(--pf-v5-global--spacer--lg);
    --pf-v5-c-tabs--m-vertical--m-box--inset: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-tabs.pf-m-inset-xl-on-sm {
    --pf-v5-c-tabs--inset: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-tabs--m-vertical--inset: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-tabs--m-vertical--m-box--inset: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-c-tabs.pf-m-inset-2xl-on-sm {
    --pf-v5-c-tabs--inset: var(--pf-v5-global--spacer--2xl);
    --pf-v5-c-tabs--m-vertical--inset: var(--pf-v5-global--spacer--2xl);
    --pf-v5-c-tabs--m-vertical--m-box--inset: var(--pf-v5-global--spacer--2xl);
  }
}

@media (min-width: 768px) {
  .pf-v5-c-tabs.pf-m-inset-none-on-md {
    --pf-v5-c-tabs--inset: 0;
    --pf-v5-c-tabs--m-vertical--inset: 0;
    --pf-v5-c-tabs--m-vertical--m-box--inset: 0;
  }

  .pf-v5-c-tabs.pf-m-inset-sm-on-md {
    --pf-v5-c-tabs--inset: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-tabs--m-vertical--inset: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-tabs--m-vertical--m-box--inset: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-tabs.pf-m-inset-md-on-md {
    --pf-v5-c-tabs--inset: var(--pf-v5-global--spacer--md);
    --pf-v5-c-tabs--m-vertical--inset: var(--pf-v5-global--spacer--md);
    --pf-v5-c-tabs--m-vertical--m-box--inset: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-tabs.pf-m-inset-lg-on-md {
    --pf-v5-c-tabs--inset: var(--pf-v5-global--spacer--lg);
    --pf-v5-c-tabs--m-vertical--inset: var(--pf-v5-global--spacer--lg);
    --pf-v5-c-tabs--m-vertical--m-box--inset: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-tabs.pf-m-inset-xl-on-md {
    --pf-v5-c-tabs--inset: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-tabs--m-vertical--inset: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-tabs--m-vertical--m-box--inset: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-c-tabs.pf-m-inset-2xl-on-md {
    --pf-v5-c-tabs--inset: var(--pf-v5-global--spacer--2xl);
    --pf-v5-c-tabs--m-vertical--inset: var(--pf-v5-global--spacer--2xl);
    --pf-v5-c-tabs--m-vertical--m-box--inset: var(--pf-v5-global--spacer--2xl);
  }
}

@media (min-width: 992px) {
  .pf-v5-c-tabs.pf-m-inset-none-on-lg {
    --pf-v5-c-tabs--inset: 0;
    --pf-v5-c-tabs--m-vertical--inset: 0;
    --pf-v5-c-tabs--m-vertical--m-box--inset: 0;
  }

  .pf-v5-c-tabs.pf-m-inset-sm-on-lg {
    --pf-v5-c-tabs--inset: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-tabs--m-vertical--inset: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-tabs--m-vertical--m-box--inset: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-tabs.pf-m-inset-md-on-lg {
    --pf-v5-c-tabs--inset: var(--pf-v5-global--spacer--md);
    --pf-v5-c-tabs--m-vertical--inset: var(--pf-v5-global--spacer--md);
    --pf-v5-c-tabs--m-vertical--m-box--inset: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-tabs.pf-m-inset-lg-on-lg {
    --pf-v5-c-tabs--inset: var(--pf-v5-global--spacer--lg);
    --pf-v5-c-tabs--m-vertical--inset: var(--pf-v5-global--spacer--lg);
    --pf-v5-c-tabs--m-vertical--m-box--inset: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-tabs.pf-m-inset-xl-on-lg {
    --pf-v5-c-tabs--inset: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-tabs--m-vertical--inset: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-tabs--m-vertical--m-box--inset: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-c-tabs.pf-m-inset-2xl-on-lg {
    --pf-v5-c-tabs--inset: var(--pf-v5-global--spacer--2xl);
    --pf-v5-c-tabs--m-vertical--inset: var(--pf-v5-global--spacer--2xl);
    --pf-v5-c-tabs--m-vertical--m-box--inset: var(--pf-v5-global--spacer--2xl);
  }
}

@media (min-width: 1200px) {
  .pf-v5-c-tabs.pf-m-inset-none-on-xl {
    --pf-v5-c-tabs--inset: 0;
    --pf-v5-c-tabs--m-vertical--inset: 0;
    --pf-v5-c-tabs--m-vertical--m-box--inset: 0;
  }

  .pf-v5-c-tabs.pf-m-inset-sm-on-xl {
    --pf-v5-c-tabs--inset: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-tabs--m-vertical--inset: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-tabs--m-vertical--m-box--inset: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-tabs.pf-m-inset-md-on-xl {
    --pf-v5-c-tabs--inset: var(--pf-v5-global--spacer--md);
    --pf-v5-c-tabs--m-vertical--inset: var(--pf-v5-global--spacer--md);
    --pf-v5-c-tabs--m-vertical--m-box--inset: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-tabs.pf-m-inset-lg-on-xl {
    --pf-v5-c-tabs--inset: var(--pf-v5-global--spacer--lg);
    --pf-v5-c-tabs--m-vertical--inset: var(--pf-v5-global--spacer--lg);
    --pf-v5-c-tabs--m-vertical--m-box--inset: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-tabs.pf-m-inset-xl-on-xl {
    --pf-v5-c-tabs--inset: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-tabs--m-vertical--inset: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-tabs--m-vertical--m-box--inset: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-c-tabs.pf-m-inset-2xl-on-xl {
    --pf-v5-c-tabs--inset: var(--pf-v5-global--spacer--2xl);
    --pf-v5-c-tabs--m-vertical--inset: var(--pf-v5-global--spacer--2xl);
    --pf-v5-c-tabs--m-vertical--m-box--inset: var(--pf-v5-global--spacer--2xl);
  }
}

@media (min-width: 1450px) {
  .pf-v5-c-tabs.pf-m-inset-none-on-2xl {
    --pf-v5-c-tabs--inset: 0;
    --pf-v5-c-tabs--m-vertical--inset: 0;
    --pf-v5-c-tabs--m-vertical--m-box--inset: 0;
  }

  .pf-v5-c-tabs.pf-m-inset-sm-on-2xl {
    --pf-v5-c-tabs--inset: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-tabs--m-vertical--inset: var(--pf-v5-global--spacer--sm);
    --pf-v5-c-tabs--m-vertical--m-box--inset: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-c-tabs.pf-m-inset-md-on-2xl {
    --pf-v5-c-tabs--inset: var(--pf-v5-global--spacer--md);
    --pf-v5-c-tabs--m-vertical--inset: var(--pf-v5-global--spacer--md);
    --pf-v5-c-tabs--m-vertical--m-box--inset: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-tabs.pf-m-inset-lg-on-2xl {
    --pf-v5-c-tabs--inset: var(--pf-v5-global--spacer--lg);
    --pf-v5-c-tabs--m-vertical--inset: var(--pf-v5-global--spacer--lg);
    --pf-v5-c-tabs--m-vertical--m-box--inset: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-c-tabs.pf-m-inset-xl-on-2xl {
    --pf-v5-c-tabs--inset: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-tabs--m-vertical--inset: var(--pf-v5-global--spacer--xl);
    --pf-v5-c-tabs--m-vertical--m-box--inset: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-c-tabs.pf-m-inset-2xl-on-2xl {
    --pf-v5-c-tabs--inset: var(--pf-v5-global--spacer--2xl);
    --pf-v5-c-tabs--m-vertical--inset: var(--pf-v5-global--spacer--2xl);
    --pf-v5-c-tabs--m-vertical--m-box--inset: var(--pf-v5-global--spacer--2xl);
  }
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-tabs {
  --pf-v5-c-tabs__scroll-button--BackgroundColor: transparent;
  --pf-v5-c-tabs__link--disabled--BackgroundColor: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-tabs__link--disabled--Color: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-tabs--m-color-scheme--light-300__link--disabled--BackgroundColor: var(--pf-v5-global--disabled-color--200);
}

.pf-v5-c-text-input-group {
  --pf-v5-c-text-input-group--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-text-input-group__text--before--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-text-input-group__text--before--BorderColor: var(--pf-v5-global--BorderColor--300);
  --pf-v5-c-text-input-group__text--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-text-input-group__text--after--BorderBottomColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-text-input-group--hover__text--after--BorderBottomColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-text-input-group__text--focus-within--after--BorderBottomWidth: var(--pf-v5-global--BorderWidth--md);
  --pf-v5-c-text-input-group__text--focus-within--after--BorderBottomColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-text-input-group__main--first-child--not--text-input--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-text-input-group__main--m-icon__text-input--PaddingLeft: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-text-input-group__main--RowGap: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-text-input-group__main--ColumnGap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-text-input-group--c-chip-group__main--PaddingTop: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-text-input-group--c-chip-group__main--PaddingRight: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-text-input-group--c-chip-group__main--PaddingBottom: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-text-input-group__text-input--PaddingTop: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-text-input-group__text-input--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-text-input-group__text-input--PaddingBottom: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-text-input-group__text-input--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-text-input-group__text-input--MinWidth: 12ch;
  --pf-v5-c-text-input-group__text-input--m-hint--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-text-input-group__text-input--placeholder--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-text-input-group__text-input--BackgroundColor: transparent;
  --pf-v5-c-text-input-group__icon--Left: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-text-input-group__icon--Color: var(--pf-v5-global--icon--Color--dark);
  --pf-v5-c-text-input-group__icon--TranslateY: -50%;
  --pf-v5-c-text-input-group__utilities--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-text-input-group__utilities--MarginLeft: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-text-input-group__utilities--child--MarginLeft: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-text-input-group__utilities--c-button--PaddingRight: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-text-input-group__utilities--c-button--PaddingLeft: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-text-input-group--m-disabled--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-text-input-group--m-disabled--BackgroundColor: var(--pf-v5-global--disabled-color--300);
  position: relative;
  display: flex;
  width: 100%;
  color: var(--pf-v5-c-text-input-group--Color, inherit);
  background-color: var(--pf-v5-c-text-input-group--BackgroundColor);
}

.pf-v5-c-text-input-group:hover {
  --pf-v5-c-text-input-group__text--after--BorderBottomColor: var(--pf-v5-c-text-input-group--hover__text--after--BorderBottomColor);
}

.pf-v5-c-text-input-group.pf-m-disabled {
  --pf-v5-c-text-input-group__text--before--BorderWidth: 0;
  --pf-v5-c-text-input-group__text--after--BorderBottomWidth: 0;
  --pf-v5-c-text-input-group--Color: var(--pf-v5-c-text-input-group--m-disabled--Color);
  --pf-v5-c-text-input-group__icon--Color: var(--pf-v5-c-text-input-group--m-disabled--Color);
  --pf-v5-c-text-input-group--BackgroundColor: var(--pf-v5-c-text-input-group--m-disabled--BackgroundColor);
  pointer-events: none;
}

.pf-v5-c-text-input-group.pf-m-plain {
  --pf-v5-c-text-input-group--BackgroundColor: transparent;
}

.pf-v5-c-text-input-group.pf-m-plain .pf-v5-c-text-input-group__text::before,
.pf-v5-c-text-input-group.pf-m-plain .pf-v5-c-text-input-group__text::after {
  border: 0;
}

.pf-v5-c-text-input-group__main {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: var(--pf-v5-c-text-input-group__main--RowGap) var(--pf-v5-c-text-input-group__main--ColumnGap);
  min-width: 0;
}

.pf-v5-c-text-input-group__main.pf-m-icon {
  --pf-v5-c-text-input-group__text-input--PaddingLeft: var(--pf-v5-c-text-input-group__main--m-icon__text-input--PaddingLeft);
}

.pf-v5-c-text-input-group__main>:first-child:not(.pf-v5-c-text-input-group__text) {
  margin-inline-start: var(--pf-v5-c-text-input-group__main--first-child--not--text-input--MarginLeft);
}

.pf-v5-c-text-input-group__main .pf-v5-c-chip-group__main {
  padding-block-start: var(--pf-v5-c-text-input-group--c-chip-group__main--PaddingTop);
  padding-block-end: var(--pf-v5-c-text-input-group--c-chip-group__main--PaddingBottom);
  padding-inline-end: var(--pf-v5-c-text-input-group--c-chip-group__main--PaddingRight);
}

.pf-v5-c-text-input-group__text {
  display: inline-grid;
  flex: 1;
  grid-template-areas: "text-input";
  grid-template-columns: 1fr;
}

.pf-v5-c-text-input-group__text::before,
.pf-v5-c-text-input-group__text::after {
  position: absolute;
  inset: 0;
  pointer-events: none;
  content: "";
}

.pf-v5-c-text-input-group__text::before {
  border: var(--pf-v5-c-text-input-group__text--before--BorderWidth) solid var(--pf-v5-c-text-input-group__text--before--BorderColor);
}

.pf-v5-c-text-input-group__text::after {
  border-block-end: var(--pf-v5-c-text-input-group__text--after--BorderBottomWidth) solid var(--pf-v5-c-text-input-group__text--after--BorderBottomColor);
}

.pf-v5-c-text-input-group__text:focus-within {
  --pf-v5-c-text-input-group__text--after--BorderBottomWidth: var(--pf-v5-c-text-input-group__text--focus-within--after--BorderBottomWidth);
  --pf-v5-c-text-input-group__text--after--BorderBottomColor: var(--pf-v5-c-text-input-group__text--focus-within--after--BorderBottomColor);
}

.pf-v5-c-text-input-group__icon {
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: var(--pf-v5-c-text-input-group__icon--Left);
  color: var(--pf-v5-c-text-input-group__icon--Color);
  transform: translateY(var(--pf-v5-c-text-input-group__icon--TranslateY));
}

.pf-v5-c-text-input-group__text-input {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  width: 100%;
  min-width: var(--pf-v5-c-text-input-group__text-input--MinWidth);
  padding-block-start: var(--pf-v5-c-text-input-group__text-input--PaddingTop);
  padding-block-end: var(--pf-v5-c-text-input-group__text-input--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-text-input-group__text-input--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-text-input-group__text-input--PaddingRight);
  background-color: var(--pf-v5-c-text-input-group__text-input--BackgroundColor);
  border: 0;
}

.pf-v5-c-text-input-group__text-input,
.pf-v5-c-text-input-group__text-input.pf-m-hint {
  grid-area: text-input;
}

.pf-v5-c-text-input-group__text-input.pf-m-hint {
  color: var(--pf-v5-c-text-input-group__text-input--m-hint--Color);
}

.pf-v5-c-text-input-group__text-input::placeholder {
  color: var(--pf-v5-c-text-input-group__text-input--placeholder--Color);
}

.pf-v5-c-text-input-group__utilities {
  display: flex;
  align-items: center;
  margin-inline-start: var(--pf-v5-c-text-input-group__utilities--MarginLeft);
  margin-inline-end: var(--pf-v5-c-text-input-group__utilities--MarginRight);
}

.pf-v5-c-text-input-group__utilities>*+* {
  margin-inline-start: var(--pf-v5-c-text-input-group__utilities--child--MarginLeft);
}

.pf-v5-c-text-input-group__utilities .pf-v5-c-button {
  --pf-v5-c-button--PaddingRight: var(--pf-v5-c-text-input-group__utilities--c-button--PaddingRight);
  --pf-v5-c-button--PaddingLeft: var(--pf-v5-c-text-input-group__utilities--c-button--PaddingLeft);
}

.pf-v5-c-text-input-group__group {
  display: flex;
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-text-input-group {
  --pf-v5-c-text-input-group--BackgroundColor: var(--pf-v5-global--BackgroundColor--400);
  --pf-v5-c-text-input-group--m-disabled--Color: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-text-input-group--m-disabled--BackgroundColor: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-text-input-group__text--before--BorderWidth: 0;
  --pf-v5-c-text-input-group__text--after--BorderBottomColor: var(--pf-v5-global--BorderColor--400);
}

.pf-v5-c-tile {
  --pf-v5-c-tile--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-tile--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-tile--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-tile--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-tile--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-tile--Transition: none;
  --pf-v5-c-tile--TranslateY: 0;
  --pf-v5-c-tile--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-tile--before--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-tile--after--Height: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-tile--after--BackgroundColor: transparent;
  --pf-v5-c-tile--after--Transition: none;
  --pf-v5-c-tile--after--ScaleY: 1;
  --pf-v5-c-tile--after--TranslateY: 0;
  --pf-v5-c-tile__icon--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-tile__icon--FontSize: var(--pf-v5-global--icon--FontSize--md);
  --pf-v5-c-tile__icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-tile__title--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-tile__body--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-tile__body--FontSize: var(--pf-v5-global--FontSize--xs);
  --pf-v5-c-tile--hover--after--BackgroundColor: var(--pf-v5-global--active-color--400);
  --pf-v5-c-tile--hover__icon--Color: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-tile--hover__title--Color: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-tile--focus--after--BackgroundColor: var(--pf-v5-global--active-color--400);
  --pf-v5-c-tile--focus__icon--Color: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-tile--focus__title--Color: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-tile--m-selected--TranslateY: calc(-1 * var(--pf-v5-c-tile--m-selected--after--ScaleY) * var(--pf-v5-c-tile--m-selected--after--Height));
  --pf-v5-c-tile--m-selected--Transition: var(--pf-v5-global--Transition);
  --pf-v5-c-tile--m-selected--after--Height: var(--pf-v5-global--BorderWidth--lg);
  --pf-v5-c-tile--m-selected--after--BackgroundColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-tile--m-selected--after--Transition: var(--pf-v5-global--Transition);
  --pf-v5-c-tile--m-selected--after--ScaleY: 2;
  --pf-v5-c-tile--m-selected__icon--Color: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-tile--m-selected__title--Color: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-tile--m-disabled--BackgroundColor: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-tile--m-disabled__icon--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-tile--m-disabled__title--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-tile--m-disabled__body--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-tile__header--m-stacked__icon--MarginBottom: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-tile__header--m-stacked__icon--FontSize: var(--pf-v5-global--icon--FontSize--lg);
  --pf-v5-c-tile--m-display-lg__header--m-stacked__icon--FontSize: var(--pf-v5-global--icon--FontSize--xl);
  position: relative;
  display: inline-grid;
  grid-template-rows: min-content;
  padding-block-start: var(--pf-v5-c-tile--PaddingTop);
  padding-block-end: var(--pf-v5-c-tile--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-tile--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-tile--PaddingRight);
  text-align: center;
  cursor: pointer;
  background-color: var(--pf-v5-c-tile--BackgroundColor);
  transition: var(--pf-v5-c-tile--Transition);
  transform: translateY(var(--pf-v5-c-tile--TranslateY));
}

.pf-v5-c-tile::before,
.pf-v5-c-tile::after {
  position: absolute;
  pointer-events: none;
  content: "";
}

.pf-v5-c-tile::before {
  inset: 0;
  border: var(--pf-v5-c-tile--before--BorderWidth) solid var(--pf-v5-c-tile--before--BorderColor);
}

.pf-v5-c-tile::after {
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  height: var(--pf-v5-c-tile--after--Height);
  background-color: var(--pf-v5-c-tile--after--BackgroundColor);
  transition: var(--pf-v5-c-tile--after--Transition);
  transform: scaleY(var(--pf-v5-c-tile--after--ScaleY)) translateY(var(--pf-v5-c-tile--after--TranslateY));
}

.pf-v5-c-tile:hover {
  --pf-v5-c-tile__title--Color: var(--pf-v5-c-tile--hover__title--Color);
  --pf-v5-c-tile__icon--Color: var(--pf-v5-c-tile--hover__icon--Color);
  --pf-v5-c-tile--after--BackgroundColor: var(--pf-v5-c-tile--hover--after--BackgroundColor);
}

.pf-v5-c-tile:focus {
  --pf-v5-c-tile__title--Color: var(--pf-v5-c-tile--focus__title--Color);
  --pf-v5-c-tile__icon--Color: var(--pf-v5-c-tile--focus__icon--Color);
  --pf-v5-c-tile--after--BackgroundColor: var(--pf-v5-c-tile--focus--after--BackgroundColor);
}

.pf-v5-c-tile:active,
.pf-v5-c-tile.pf-m-selected {
  --pf-v5-c-tile__title--Color: var(--pf-v5-c-tile--m-selected__title--Color);
  --pf-v5-c-tile__icon--Color: var(--pf-v5-c-tile--m-selected__icon--Color);
  --pf-v5-c-tile--TranslateY: var(--pf-v5-c-tile--m-selected--TranslateY);
  --pf-v5-c-tile--Transition: var(--pf-v5-c-tile--m-selected--Transition);
  --pf-v5-c-tile--after--Height: var(--pf-v5-c-tile--m-selected--after--Height);
  --pf-v5-c-tile--after--BackgroundColor: var(--pf-v5-c-tile--m-selected--after--BackgroundColor);
  --pf-v5-c-tile--after--Transition: var(--pf-v5-c-tile--m-selected--after--Transition);
  --pf-v5-c-tile--after--ScaleY: var(--pf-v5-c-tile--m-selected--after--ScaleY);
}

.pf-v5-c-tile.pf-m-disabled {
  --pf-v5-c-tile--BackgroundColor: var(--pf-v5-c-tile--m-disabled--BackgroundColor);
  --pf-v5-c-tile__title--Color: var(--pf-v5-c-tile--m-disabled__title--Color);
  --pf-v5-c-tile__body--Color: var(--pf-v5-c-tile--m-disabled__body--Color);
  --pf-v5-c-tile--before--BorderWidth: 0;
  --pf-v5-c-tile__icon--Color: var(--pf-v5-c-tile--m-disabled__icon--Color);
  pointer-events: none;
}

.pf-v5-c-tile.pf-m-display-lg .pf-v5-c-tile__header.pf-m-stacked {
  --pf-v5-c-tile__icon--FontSize: var(--pf-v5-c-tile--m-display-lg__header--m-stacked__icon--FontSize);
}

.pf-v5-c-tile__header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pf-v5-c-tile__header.pf-m-stacked {
  --pf-v5-c-tile__icon--MarginRight: 0;
  --pf-v5-c-tile__icon--FontSize: var(--pf-v5-c-tile__header--m-stacked__icon--FontSize);
  flex-direction: column;
  justify-content: initial;
}

.pf-v5-c-tile__header.pf-m-stacked .pf-v5-c-tile__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-block-end: var(--pf-v5-c-tile__header--m-stacked__icon--MarginBottom);
}

.pf-v5-c-tile__title {
  color: var(--pf-v5-c-tile__title--Color);
}

.pf-v5-c-tile__body {
  font-size: var(--pf-v5-c-tile__body--FontSize);
  color: var(--pf-v5-c-tile__body--Color);
}

.pf-v5-c-tile__icon {
  margin-inline-end: var(--pf-v5-c-tile__icon--MarginRight);
  font-size: var(--pf-v5-c-tile__icon--FontSize);
  color: var(--pf-v5-c-tile__icon--Color);
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-tile {
  --pf-v5-c-tile--m-disabled--BackgroundColor: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-tile--m-disabled__title--Color: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-tile--m-disabled__icon--Color: var(--pf-v5-global--disabled-color--300);
  --pf-v5-c-tile--m-disabled__body--Color: var(--pf-v5-global--disabled-color--300);
}

.pf-v5-c-timestamp {
  --pf-v5-c-timestamp--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-timestamp--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-timestamp--OutlineOffset: 0.1875rem;
  --pf-v5-c-timestamp--m-help-text--TextDecorationLine: underline;
  --pf-v5-c-timestamp--m-help-text--TextDecorationStyle: dashed;
  --pf-v5-c-timestamp--m-help-text--TextDecorationThickness: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-timestamp--m-help-text--TextUnderlineOffset: 0.25rem;
  --pf-v5-c-timestamp--m-help-text--TextDecorationColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-timestamp--m-help-text--hover--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-timestamp--m-help-text--focus--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-timestamp--m-help-text--hover--TextDecorationColor: var(--pf-v5-global--Color--100);
  --pf-v5-c-timestamp--m-help-text--focus--TextDecorationColor: var(--pf-v5-global--Color--100);
  display: inline-block;
  font-size: var(--pf-v5-c-timestamp--FontSize);
  color: var(--pf-v5-c-timestamp--Color);
  outline-offset: var(--pf-v5-c-timestamp--OutlineOffset);
}

.pf-v5-c-timestamp.pf-m-help-text {
  text-decoration-line: var(--pf-v5-c-timestamp--m-help-text--TextDecorationLine);
  text-decoration-thickness: var(--pf-v5-c-timestamp--m-help-text--TextDecorationThickness);
  text-decoration-style: var(--pf-v5-c-timestamp--m-help-text--TextDecorationStyle);
  text-decoration-color: var(--pf-v5-c-timestamp--m-help-text--TextDecorationColor);
  text-underline-offset: var(--pf-v5-c-timestamp--m-help-text--TextUnderlineOffset);
  cursor: pointer;
}

.pf-v5-c-timestamp.pf-m-help-text:hover {
  --pf-v5-c-timestamp--Color: var(--pf-v5-c-timestamp--m-help-text--hover--Color);
  --pf-v5-c-timestamp--m-help-text--TextDecorationColor: var(--pf-v5-c-timestamp--m-help-text--hover--TextDecorationColor);
}

.pf-v5-c-timestamp.pf-m-help-text:focus {
  --pf-v5-c-timestamp--Color: var(--pf-v5-c-timestamp--m-help-text--focus--Color);
  --pf-v5-c-timestamp--m-help-text--TextDecorationColor: var(--pf-v5-c-timestamp--m-help-text--focus--TextDecorationColor);
}

.pf-v5-c-title {
  --pf-v5-c-title--FontFamily: var(--pf-v5-global--FontFamily--heading);
  --pf-v5-c-title--m-4xl--LineHeight: var(--pf-v5-global--LineHeight--sm);
  --pf-v5-c-title--m-4xl--FontSize: var(--pf-v5-global--FontSize--4xl);
  --pf-v5-c-title--m-4xl--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-title--m-3xl--LineHeight: var(--pf-v5-global--LineHeight--sm);
  --pf-v5-c-title--m-3xl--FontSize: var(--pf-v5-global--FontSize--3xl);
  --pf-v5-c-title--m-3xl--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-title--m-2xl--LineHeight: var(--pf-v5-global--LineHeight--sm);
  --pf-v5-c-title--m-2xl--FontSize: var(--pf-v5-global--FontSize--2xl);
  --pf-v5-c-title--m-2xl--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-title--m-xl--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-title--m-xl--FontSize: var(--pf-v5-global--FontSize--xl);
  --pf-v5-c-title--m-xl--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-title--m-lg--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-title--m-lg--FontSize: var(--pf-v5-global--FontSize--lg);
  --pf-v5-c-title--m-lg--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-title--m-md--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-title--m-md--FontSize: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-title--m-md--FontWeight: var(--pf-v5-global--FontWeight--normal);
  font-family: var(--pf-v5-c-title--FontFamily);
  word-break: break-word;
}

.pf-v5-c-title.pf-m-4xl {
  font-size: var(--pf-v5-c-title--m-4xl--FontSize);
  font-weight: var(--pf-v5-c-title--m-4xl--FontWeight);
  line-height: var(--pf-v5-c-title--m-4xl--LineHeight);
}

.pf-v5-c-title.pf-m-3xl {
  font-size: var(--pf-v5-c-title--m-3xl--FontSize);
  font-weight: var(--pf-v5-c-title--m-3xl--FontWeight);
  line-height: var(--pf-v5-c-title--m-3xl--LineHeight);
}

.pf-v5-c-title.pf-m-2xl {
  font-size: var(--pf-v5-c-title--m-2xl--FontSize);
  font-weight: var(--pf-v5-c-title--m-2xl--FontWeight);
  line-height: var(--pf-v5-c-title--m-2xl--LineHeight);
}

.pf-v5-c-title.pf-m-xl {
  font-size: var(--pf-v5-c-title--m-xl--FontSize);
  font-weight: var(--pf-v5-c-title--m-xl--FontWeight);
  line-height: var(--pf-v5-c-title--m-xl--LineHeight);
}

.pf-v5-c-title.pf-m-lg {
  font-size: var(--pf-v5-c-title--m-lg--FontSize);
  font-weight: var(--pf-v5-c-title--m-lg--FontWeight);
  line-height: var(--pf-v5-c-title--m-lg--LineHeight);
}

.pf-v5-c-title.pf-m-md {
  font-size: var(--pf-v5-c-title--m-md--FontSize);
  font-weight: var(--pf-v5-c-title--m-md--FontWeight);
  line-height: var(--pf-v5-c-title--m-md--LineHeight);
}

.pf-v5-c-toggle-group {
  --pf-v5-c-toggle-group__button--PaddingTop: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-toggle-group__button--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-toggle-group__button--PaddingBottom: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-toggle-group__button--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-toggle-group__button--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-toggle-group__button--LineHeight: calc(var(--pf-v5-global--FontSize--md) * var(--pf-v5-global--LineHeight--md));
  --pf-v5-c-toggle-group__button--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-toggle-group__button--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-toggle-group__button--ZIndex: auto;
  --pf-v5-c-toggle-group__button--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-toggle-group__button--hover--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-toggle-group__button--hover--before--BorderColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-toggle-group__button--focus--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-toggle-group__button--focus--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-toggle-group__button--focus--before--BorderColor: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-toggle-group__button--disabled--BackgroundColor: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-toggle-group__button--disabled--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-toggle-group__button--before--BorderWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-toggle-group__button--before--BorderColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-toggle-group__item--item--MarginLeft: calc(-1 * var(--pf-v5-global--BorderWidth--sm));
  --pf-v5-c-toggle-group__item--first-child__button--BorderTopLeftRadius: var(--pf-v5-global--BorderRadius--sm);
  --pf-v5-c-toggle-group__item--first-child__button--BorderBottomLeftRadius: var(--pf-v5-global--BorderRadius--sm);
  --pf-v5-c-toggle-group__item--last-child__button--BorderTopRightRadius: var(--pf-v5-global--BorderRadius--sm);
  --pf-v5-c-toggle-group__item--last-child__button--BorderBottomRightRadius: var(--pf-v5-global--BorderRadius--sm);
  --pf-v5-c-toggle-group__icon--text--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-toggle-group__button--m-selected--BackgroundColor: var(--pf-v5-global--palette--blue-50);
  --pf-v5-c-toggle-group__button--m-selected--Color: initial;
  --pf-v5-c-toggle-group__button--m-selected--before--BorderColor: var(--pf-v5-global--primary-color--100);
  --pf-v5-c-toggle-group__button--m-selected--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-toggle-group--m-compact__button--PaddingTop: 0;
  --pf-v5-c-toggle-group--m-compact__button--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-toggle-group--m-compact__button--PaddingBottom: 0;
  --pf-v5-c-toggle-group--m-compact__button--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-toggle-group--m-compact__button--FontSize: var(--pf-v5-global--FontSize--xs);
  display: flex;
}

.pf-v5-c-toggle-group.pf-m-compact {
  --pf-v5-c-toggle-group__button--PaddingTop: var(--pf-v5-c-toggle-group--m-compact__button--PaddingTop);
  --pf-v5-c-toggle-group__button--PaddingRight: var(--pf-v5-c-toggle-group--m-compact__button--PaddingRight);
  --pf-v5-c-toggle-group__button--PaddingBottom: var(--pf-v5-c-toggle-group--m-compact__button--PaddingBottom);
  --pf-v5-c-toggle-group__button--PaddingLeft: var(--pf-v5-c-toggle-group--m-compact__button--PaddingLeft);
  --pf-v5-c-toggle-group__button--FontSize: var(--pf-v5-c-toggle-group--m-compact__button--FontSize);
}

.pf-v5-c-toggle-group__item+.pf-v5-c-toggle-group__item {
  margin-inline-start: var(--pf-v5-c-toggle-group__item--item--MarginLeft);
}

.pf-v5-c-toggle-group__item:first-child .pf-v5-c-toggle-group__button,
.pf-v5-c-toggle-group__item:first-child .pf-v5-c-toggle-group__button::before {
  border-start-start-radius: var(--pf-v5-c-toggle-group__item--first-child__button--BorderTopLeftRadius);
  border-end-start-radius: var(--pf-v5-c-toggle-group__item--first-child__button--BorderBottomLeftRadius);
}

.pf-v5-c-toggle-group__item:last-child .pf-v5-c-toggle-group__button,
.pf-v5-c-toggle-group__item:last-child .pf-v5-c-toggle-group__button::before {
  border-start-end-radius: var(--pf-v5-c-toggle-group__item--last-child__button--BorderTopRightRadius);
  border-end-end-radius: var(--pf-v5-c-toggle-group__item--last-child__button--BorderBottomRightRadius);
}

.pf-v5-c-toggle-group__button {
  position: relative;
  z-index: var(--pf-v5-c-toggle-group__button--ZIndex);
  display: inline-flex;
  padding-block-start: var(--pf-v5-c-toggle-group__button--PaddingTop);
  padding-block-end: var(--pf-v5-c-toggle-group__button--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-toggle-group__button--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-toggle-group__button--PaddingRight);
  font-size: var(--pf-v5-c-toggle-group__button--FontSize);
  line-height: var(--pf-v5-c-toggle-group__button--LineHeight);
  color: var(--pf-v5-c-toggle-group__button--Color);
  background-color: var(--pf-v5-c-toggle-group__button--BackgroundColor);
  border: 0;
}

.pf-v5-c-toggle-group__button::before {
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  pointer-events: none;
  content: "";
  border: var(--pf-v5-c-toggle-group__button--before--BorderWidth) solid var(--pf-v5-c-toggle-group__button--before--BorderColor);
}

.pf-v5-c-toggle-group__button:hover {
  --pf-v5-c-toggle-group__button--BackgroundColor: var(--pf-v5-c-toggle-group__button--hover--BackgroundColor);
  --pf-v5-c-toggle-group__button--ZIndex: var(--pf-v5-c-toggle-group__button--hover--ZIndex);
  --pf-v5-c-toggle-group__button--before--BorderColor: var(--pf-v5-c-toggle-group__button--hover--before--BorderColor);
  text-decoration: none;
}

.pf-v5-c-toggle-group__button:focus {
  --pf-v5-c-toggle-group__button--BackgroundColor: var(--pf-v5-c-toggle-group__button--focus--BackgroundColor);
  --pf-v5-c-toggle-group__button--ZIndex: var(--pf-v5-c-toggle-group__button--focus--ZIndex);
  --pf-v5-c-toggle-group__button--before--BorderColor: var(--pf-v5-c-toggle-group__button--focus--before--BorderColor);
}

.pf-v5-c-toggle-group__button.pf-m-selected {
  --pf-v5-c-toggle-group__button--BackgroundColor: var(--pf-v5-c-toggle-group__button--m-selected--BackgroundColor);
  --pf-v5-c-toggle-group__button--Color: var(--pf-v5-c-toggle-group__button--m-selected--Color, inherit);
  --pf-v5-c-toggle-group__button--ZIndex: var(--pf-v5-c-toggle-group__button--m-selected--ZIndex);
  --pf-v5-c-toggle-group__button--before--BorderColor: var(--pf-v5-c-toggle-group__button--m-selected--before--BorderColor);
}

.pf-v5-c-toggle-group__button:disabled,
.pf-v5-c-toggle-group__button.pf-m-disabled {
  --pf-v5-c-toggle-group__button--BackgroundColor: var(--pf-v5-c-toggle-group__button--disabled--BackgroundColor);
  --pf-v5-c-toggle-group__button--Color: var(--pf-v5-c-toggle-group__button--disabled--Color);
  pointer-events: none;
}

.pf-v5-c-toggle-group__icon+.pf-v5-c-toggle-group__text,
.pf-v5-c-toggle-group__text+.pf-v5-c-toggle-group__icon {
  margin-inline-start: var(--pf-v5-c-toggle-group__icon--text--MarginLeft);
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-toggle-group {
  --pf-v5-c-toggle-group__button--hover--BackgroundColor: var(--pf-v5-global--palette--black-700);
  --pf-v5-c-toggle-group__button--focus--BackgroundColor: var(--pf-v5-global--palette--black-700);
  --pf-v5-c-toggle-group__button--disabled--BackgroundColor: var(--pf-v5-global--palette--black-600);
  --pf-v5-c-toggle-group__button--disabled--Color: var(--pf-v5-global--palette--black-100);
  --pf-v5-c-toggle-group__button--m-selected--BackgroundColor: var(--pf-v5-global--primary-color--300);
  --pf-v5-c-toggle-group__button--m-selected--Color: var(--pf-v5-global--primary-color--400);
}

.pf-v5-c-tooltip {
  --pf-v5-c-tooltip--MaxWidth: 18.75rem;
  --pf-v5-c-tooltip--BoxShadow: var(--pf-v5-global--BoxShadow--md);
  --pf-v5-c-tooltip__content--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-tooltip__content--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-tooltip__content--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-tooltip__content--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-tooltip__content--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-tooltip__content--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-100);
  --pf-v5-c-tooltip__content--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-tooltip__arrow--Width: 0.9375rem;
  --pf-v5-c-tooltip__arrow--Height: 0.9375rem;
  --pf-v5-c-tooltip__arrow--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-100);
  --pf-v5-c-tooltip__arrow--BoxShadow: var(--pf-v5-global--BoxShadow--md);
  --pf-v5-c-tooltip__arrow--m-top--TranslateX: -50%;
  --pf-v5-c-tooltip__arrow--m-top--TranslateY: 50%;
  --pf-v5-c-tooltip__arrow--m-top--Rotate: 45deg;
  --pf-v5-c-tooltip__arrow--m-right--TranslateX: -50%;
  --pf-v5-c-tooltip__arrow--m-right--TranslateY: -50%;
  --pf-v5-c-tooltip__arrow--m-right--Rotate: 45deg;
  --pf-v5-c-tooltip__arrow--m-bottom--TranslateX: -50%;
  --pf-v5-c-tooltip__arrow--m-bottom--TranslateY: -50%;
  --pf-v5-c-tooltip__arrow--m-bottom--Rotate: 45deg;
  --pf-v5-c-tooltip__arrow--m-left--TranslateX: 50%;
  --pf-v5-c-tooltip__arrow--m-left--TranslateY: -50%;
  --pf-v5-c-tooltip__arrow--m-left--Rotate: 45deg;
  position: relative;
  max-width: var(--pf-v5-c-tooltip--MaxWidth);
  box-shadow: var(--pf-v5-c-tooltip--BoxShadow);
}

.pf-v5-c-tooltip:is(.pf-m-top,.pf-m-top-left,.pf-m-top-right) {
  --pf-v5-c-tooltip__arrow--Bottom: var(--pf-v5-c-tooltip--m-top--Bottom, 0);
  --pf-v5-c-tooltip__arrow--Left: var(--pf-v5-c-tooltip--m-top--Left, 50%);
  --pf-v5-c-tooltip__arrow--TranslateX: var(--pf-v5-c-tooltip__arrow--m-top--TranslateX);
  --pf-v5-c-tooltip__arrow--TranslateY: var(--pf-v5-c-tooltip__arrow--m-top--TranslateY);
  --pf-v5-c-tooltip__arrow--Rotate: var(--pf-v5-c-tooltip__arrow--m-top--Rotate);
}

.pf-v5-c-tooltip:is(.pf-m-bottom,.pf-m-bottom-left,.pf-m-bottom-right) {
  --pf-v5-c-tooltip__arrow--Top: var(--pf-v5-c-tooltip--m-bottom--Top, 0);
  --pf-v5-c-tooltip__arrow--Left: var(--pf-v5-c-tooltip--m-bottom--Left, 50%);
  --pf-v5-c-tooltip__arrow--TranslateX: var(--pf-v5-c-tooltip__arrow--m-bottom--TranslateX);
  --pf-v5-c-tooltip__arrow--TranslateY: var(--pf-v5-c-tooltip__arrow--m-bottom--TranslateY);
  --pf-v5-c-tooltip__arrow--Rotate: var(--pf-v5-c-tooltip__arrow--m-bottom--Rotate);
}

.pf-v5-c-tooltip:is(.pf-m-left,.pf-m-left-top,.pf-m-left-bottom) {
  --pf-v5-c-tooltip__arrow--Top: var(--pf-v5-c-tooltip--m-left--Top, 50%);
  --pf-v5-c-tooltip__arrow--Right: var(--pf-v5-c-tooltip--m-left--Right, 0);
  --pf-v5-c-tooltip__arrow--TranslateX: var(--pf-v5-c-tooltip__arrow--m-left--TranslateX);
  --pf-v5-c-tooltip__arrow--TranslateY: var(--pf-v5-c-tooltip__arrow--m-left--TranslateY);
  --pf-v5-c-tooltip__arrow--Rotate: var(--pf-v5-c-tooltip__arrow--m-left--Rotate);
}

.pf-v5-c-tooltip:is(.pf-m-right,.pf-m-right-top,.pf-m-right-bottom) {
  --pf-v5-c-tooltip__arrow--Top: var(--pf-v5-c-tooltip--m-right--Top, 50%);
  --pf-v5-c-tooltip__arrow--Left: var(--pf-v5-c-tooltip--m-right--Left, 0);
  --pf-v5-c-tooltip__arrow--TranslateX: var(--pf-v5-c-tooltip__arrow--m-right--TranslateX);
  --pf-v5-c-tooltip__arrow--TranslateY: var(--pf-v5-c-tooltip__arrow--m-right--TranslateY);
  --pf-v5-c-tooltip__arrow--Rotate: var(--pf-v5-c-tooltip__arrow--m-right--Rotate);
}

.pf-v5-c-tooltip:is(.pf-m-left-top,.pf-m-right-top) {
  --pf-v5-c-tooltip__arrow--Top: 0;
  --pf-v5-c-tooltip__arrow--TranslateY: var(--pf-v5-c-tooltip__arrow--m-top--TranslateY);
}

.pf-v5-c-tooltip:is(.pf-m-left-bottom,.pf-m-right-bottom) {
  --pf-v5-c-tooltip__arrow--Top: auto;
  --pf-v5-c-tooltip__arrow--Bottom: 0;
}

.pf-v5-c-tooltip:is(.pf-m-top-left,.pf-m-bottom-left) {
  --pf-v5-c-tooltip__arrow--Left: 0;
  --pf-v5-c-tooltip__arrow--TranslateX: var(--pf-v5-c-tooltip__arrow--m-left--TranslateX);
}

.pf-v5-c-tooltip:is(.pf-m-top-right,.pf-m-bottom-right) {
  --pf-v5-c-tooltip__arrow--Right: 0;
  --pf-v5-c-tooltip__arrow--Left: auto;
}

.pf-v5-c-tooltip__content {
  position: relative;
  padding-block-start: var(--pf-v5-c-tooltip__content--PaddingTop);
  padding-block-end: var(--pf-v5-c-tooltip__content--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-tooltip__content--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-tooltip__content--PaddingRight);
  font-size: var(--pf-v5-c-tooltip__content--FontSize);
  color: var(--pf-v5-c-tooltip__content--Color);
  text-align: center;
  word-break: break-word;
  background-color: var(--pf-v5-c-tooltip__content--BackgroundColor);
}

.pf-v5-c-tooltip__content.pf-m-text-align-left {
  text-align: start;
}

.pf-v5-c-tooltip__arrow {
  position: absolute;
  top: var(--pf-v5-c-tooltip__arrow--Top, auto);
  right: var(--pf-v5-c-tooltip__arrow--Right, auto);
  bottom: var(--pf-v5-c-tooltip__arrow--Bottom, auto);
  left: var(--pf-v5-c-tooltip__arrow--Left, auto);
  width: var(--pf-v5-c-tooltip__arrow--Width);
  height: var(--pf-v5-c-tooltip__arrow--Height);
  pointer-events: none;
  background-color: var(--pf-v5-c-tooltip__arrow--BackgroundColor);
  box-shadow: var(--pf-v5-c-tooltip__arrow--BoxShadow);
  transform: translateX(var(--pf-v5-c-tooltip__arrow--TranslateX, 0)) translateY(var(--pf-v5-c-tooltip__arrow--TranslateY, 0)) rotate(var(--pf-v5-c-tooltip__arrow--Rotate, 0));
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-tooltip {
  --pf-v5-c-tooltip__content--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-tooltip__arrow--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
}

.pf-v5-c-truncate {
  --pf-v5-c-truncate--MinWidth: 12ch;
  --pf-v5-c-truncate__start--MinWidth: 6ch;
  display: inline-grid;
  grid-auto-flow: column;
  align-items: baseline;
  min-width: var(--pf-v5-c-truncate--MinWidth);
}

.pf-v5-c-truncate__start,
.pf-v5-c-truncate__end {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pf-v5-c-truncate__start {
  min-width: var(--pf-v5-c-truncate__start--MinWidth);
}

.pf-v5-c-truncate__end {
  direction: rtl;
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-truncate__end {
  direction: ltr;
}

.pf-v5-c-truncate__start+.pf-v5-c-truncate__end {
  overflow: visible;
  direction: ltr;
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-truncate__start+.pf-v5-c-truncate__end {
  direction: rtl;
}

@supports (-webkit-hyphens: none) {
  .pf-v5-c-truncate__end {
    direction: ltr;
  }

  :where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-truncate__end {
    direction: rtl;
  }
}

.pf-v5-c-number-input {
  --pf-v5-c-number-input__unit--c-input-group--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-number-input__icon--FontSize: var(--pf-v5-global--FontSize--xs);
  --pf-v5-c-number-input--c-form-control--width-base: calc(var(--pf-v5-global--spacer--sm) * 2 + var(--pf-v5-global--BorderWidth--sm) * 2);
  --pf-v5-c-number-input--c-form-control--width-icon: 0px;
  --pf-v5-c-number-input--m-status--c-form-control--width-icon: var(--pf-v5-global--spacer--xl);
  --pf-v5-c-number-input--c-form-control--width-chars: 4;
  --pf-v5-c-number-input--c-form-control--Width: calc( calc( var(--pf-v5-c-number-input--c-form-control--width-base) + var(--pf-v5-c-number-input--c-form-control--width-chars) * 1ch ) + var(--pf-v5-c-number-input--c-form-control--width-icon) );
  display: inline-flex;
  align-items: center;
}

.pf-v5-c-number-input.pf-m-status {
  --pf-v5-c-number-input--c-form-control--width-icon: var(--pf-v5-c-number-input--m-status--c-form-control--width-icon);
}

.pf-v5-c-number-input .pf-v5-c-form-control {
  width: var(--pf-v5-c-number-input--c-form-control--Width);
}

.pf-v5-c-number-input .pf-v5-c-form-control>:is(input) {
  text-align: end;
}

.pf-v5-c-input-group+.pf-v5-c-number-input__unit,
.pf-v5-c-number-input__unit+.pf-v5-c-input-group {
  margin-inline-start: var(--pf-v5-c-number-input__unit--c-input-group--MarginLeft);
}

.pf-v5-c-number-input__icon {
  display: flex;
  font-size: var(--pf-v5-c-number-input__icon--FontSize);
}

.pf-v5-c-tree-view {
  --pf-v5-c-tree-view--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-tree-view--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-tree-view__node--indent--base: calc(var(--pf-v5-global--spacer--md) * 2 + var(--pf-v5-c-tree-view__node-toggle-icon--MinWidth));
  --pf-v5-c-tree-view__node--nested-indent--base: calc(var(--pf-v5-c-tree-view__node--indent--base) - var(--pf-v5-global--spacer--md));
  --pf-v5-c-tree-view__node--PaddingTop--base: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-tree-view__node--PaddingTop: var(--pf-v5-c-tree-view__node--PaddingTop--base);
  --pf-v5-c-tree-view__node--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-tree-view__node--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-tree-view__node--PaddingLeft: var(--pf-v5-c-tree-view__node--indent--base);
  --pf-v5-c-tree-view__node--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-tree-view__node--m-current--Color: var(--pf-v5-global--link--Color);
  --pf-v5-c-tree-view__node--m-current--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-tree-view__node--BackgroundColor: transparent;
  --pf-v5-c-tree-view__node-container--Display: contents;
  --pf-v5-c-tree-view__node-content--RowGap: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-tree-view__node-content--Overflow: visible;
  --pf-v5-c-tree-view__node--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-tree-view__node--focus--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-tree-view__list-item__list-item__node-toggle--Top: var(--pf-v5-c-tree-view__node--PaddingTop--base);
  --pf-v5-c-tree-view__list-item__list-item__node-toggle--Left: var(--pf-v5-c-tree-view__node--PaddingLeft);
  --pf-v5-c-tree-view__list-item__list-item__node-toggle--TranslateX: -100%;
  --pf-v5-c-tree-view__node-toggle--Position: absolute;
  --pf-v5-c-tree-view__node-toggle--Color--base: var(--pf-v5-global--Color--200);
  --pf-v5-c-tree-view__node-toggle--Color: var(--pf-v5-c-tree-view__node-toggle--Color--base);
  --pf-v5-c-tree-view__node-toggle--hover--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-tree-view__node-toggle--focus--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-tree-view__node-toggle--active--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-tree-view__node-toggle--BackgroundColor: transparent;
  --pf-v5-c-tree-view__list-item--m-expanded__node-toggle--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-tree-view__node-toggle-icon--MinWidth: var(--pf-v5-global--FontSize--md);
  --pf-v5-c-tree-view__node-toggle-icon--Transition: transform var(--pf-v5-global--TransitionDuration) var(--pf-v5-global--TimingFunction);
  --pf-v5-c-tree-view__node-toggle-button--PaddingTop: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-tree-view__node-toggle-button--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-tree-view__node-toggle-button--PaddingBottom: var(--pf-v5-global--spacer--form-element);
  --pf-v5-c-tree-view__node-toggle-button--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-tree-view__node-toggle-button--MarginTop: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-tree-view__node-toggle-button--MarginBottom: calc(var(--pf-v5-global--spacer--form-element) * -1);
  --pf-v5-c-tree-view__node-check--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-tree-view__node-count--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-tree-view__node-count--c-badge--m-read--BackgroundColor: var(--pf-v5-global--disabled-color--200);
  --pf-v5-c-tree-view__search--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-tree-view__search--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-tree-view__search--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-tree-view__search--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-tree-view__node-icon--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-tree-view__node-icon--Color: var(--pf-v5-global--icon--Color--light);
  --pf-v5-c-tree-view__node-toggle-icon--base--Rotate: 0;
  --pf-v5-c-tree-view__node-toggle-icon--Rotate: var(--pf-v5-c-tree-view__node-toggle-icon--base--Rotate);
  --pf-v5-c-tree-view__list-item--m-expanded__node-toggle-icon--Rotate: 90deg;
  --pf-v5-c-tree-view__node-text--max-lines: 1;
  --pf-v5-c-tree-view__node-title--FontWeight: var(--pf-v5-global--FontWeight--bold);
  --pf-v5-c-tree-view__action--MarginLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-tree-view--m-guides--guide--Left: var(--pf-v5-c-tree-view--m-guides--guide-left--base);
  --pf-v5-c-tree-view--m-guides--guide-color--base: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-tree-view--m-guides--guide-width--base: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-tree-view--m-guides--guide-left--base: calc(var(--pf-v5-c-tree-view__node--PaddingLeft) - var(--pf-v5-c-tree-view--m-guides__list-node--guide-width--base));
  --pf-v5-c-tree-view--m-guides--guide-left--base--offset: calc(var(--pf-v5-c-tree-view__list-item__list-item__node-toggle--Left) + var(--pf-v5-c-tree-view__node-toggle-icon--MinWidth) / 2);
  --pf-v5-c-tree-view--m-guides__list-node--guide-width--base: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-tree-view--m-guides__list-item--before--Top: 0;
  --pf-v5-c-tree-view--m-guides__list-item--before--Width: var(--pf-v5-c-tree-view--m-guides--guide-width--base);
  --pf-v5-c-tree-view--m-guides__list-item--before--Height: 100%;
  --pf-v5-c-tree-view--m-guides__list-item--before--BackgroundColor: var(--pf-v5-c-tree-view--m-guides--guide-color--base);
  --pf-v5-c-tree-view--m-guides__list-item--last-child--before--Top: var(--pf-v5-c-tree-view--m-guides__node--before--Top);
  --pf-v5-c-tree-view--m-guides__list-item--last-child--before--Height: var(--pf-v5-c-tree-view--m-guides__list-item--last-child--before--Top);
  --pf-v5-c-tree-view--m-guides__list-item--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-tree-view--m-guides__node--before--Width: 1rem;
  --pf-v5-c-tree-view--m-guides__node--before--Height: var(--pf-v5-c-tree-view--m-guides--guide-width--base);
  --pf-v5-c-tree-view--m-guides__node--before--Top: 1.125rem;
  --pf-v5-c-tree-view--m-guides__node--before--BackgroundColor: var(--pf-v5-c-tree-view--m-guides--guide-color--base);
  --pf-v5-c-tree-view--m-compact--base-border--Left--offset: var(--pf-v5-global--spacer--md);
  --pf-v5-c-tree-view--m-compact--base-border--Left: calc(var(--pf-v5-c-tree-view__node--PaddingLeft) - var(--pf-v5-c-tree-view--m-compact--base-border--Left--offset));
  --pf-v5-c-tree-view--m-compact__node--indent--base: var(--pf-v5-c-tree-view__node--indent--base);
  --pf-v5-c-tree-view--m-compact__node--nested-indent--base: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-tree-view--m-compact--border--Left: var(--pf-v5-c-tree-view--m-compact--base-border--Left);
  --pf-v5-c-tree-view--m-compact__node--PaddingTop: 0;
  --pf-v5-c-tree-view--m-compact__node--PaddingBottom: 0;
  --pf-v5-c-tree-view--m-compact__node--nested--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-tree-view--m-compact__node--nested--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-tree-view--m-compact__list-item__list-item__node-toggle--Top: calc(var(--pf-v5-c-tree-view--m-compact__node-container--PaddingTop));
  --pf-v5-c-tree-view--m-compact__list-item--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-tree-view--m-compact__list-item--BorderBottomColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-tree-view--m-compact__list-item--before--Top: 0;
  --pf-v5-c-tree-view--m-compact__list-item--last-child--before--Height: var(--pf-v5-c-tree-view--m-compact__node--before--Top);
  --pf-v5-c-tree-view--m-compact__list-item--nested--before--Top: calc(var(--pf-v5-c-tree-view__node--PaddingTop--base) * -1);
  --pf-v5-c-tree-view--m-compact__list-item--nested--last-child--before--Height: calc(var(--pf-v5-c-tree-view--m-compact__node--before--Top) + var(--pf-v5-c-tree-view__node--PaddingTop--base));
  --pf-v5-c-tree-view--m-compact__node--PaddingLeft: var(--pf-v5-c-tree-view--m-compact__node--indent--base);
  --pf-v5-c-tree-view--m-compact__node--before--Top: calc(var(--pf-v5-c-tree-view--m-compact__node-container--PaddingTop) + var(--pf-v5-c-tree-view--m-compact__node--nested--PaddingTop) + 0.25rem);
  --pf-v5-c-tree-view--m-compact__node--level-2--PaddingLeft: var(--pf-v5-c-tree-view--m-compact__node--indent--base);
  --pf-v5-c-tree-view--m-compact__node-toggle--nested--MarginRight: calc(var(--pf-v5-c-tree-view__node-toggle-button--PaddingLeft) * -.5);
  --pf-v5-c-tree-view--m-compact__node-toggle--nested--MarginLeft: calc(var(--pf-v5-c-tree-view__node-toggle-button--PaddingLeft) * -1.5);
  --pf-v5-c-tree-view--m-compact__node-container--Display: flex;
  --pf-v5-c-tree-view--m-compact__node-container--PaddingBottom--base: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-tree-view--m-compact__node-container--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-tree-view--m-compact__node-container--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-tree-view--m-compact__node-container--PaddingBottom: var(--pf-v5-c-tree-view--m-compact__node-container--PaddingBottom--base);
  --pf-v5-c-tree-view--m-compact__node-container--PaddingLeft: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-tree-view--m-compact__node-container--nested--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-tree-view--m-compact__node-container--nested--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-tree-view--m-compact__node-container--nested--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-tree-view--m-compact__node-container--nested--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-tree-view--m-compact__node-container--nested--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-tree-view--m-compact__list-item--m-expanded__node-container--PaddingBottom: 0;
  --pf-v5-c-tree-view--m-no-background__node-container--BackgroundColor: transparent;
  --pf-v5-c-tree-view--m-compact--m-no-background--base-border--Left--offset: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-tree-view--m-compact--m-no-background--base-border--Left: calc(var(--pf-v5-c-tree-view__node--PaddingLeft) - var(--pf-v5-c-tree-view--m-compact--m-no-background--base-border--Left--offset));
  --pf-v5-c-tree-view--m-compact--m-no-background__node--indent--base: var(--pf-v5-c-tree-view__node--indent--base);
  --pf-v5-c-tree-view--m-compact--m-no-background__node--nested-indent--base: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-tree-view--m-compact--m-no-background__node--nested--PaddingTop: 0;
  --pf-v5-c-tree-view--m-compact--m-no-background__node--nested--PaddingBottom: 0;
  --pf-v5-c-tree-view--m-compact--m-no-background__node--before--Top: calc(var(--pf-v5-c-tree-view--m-compact__node-container--nested--PaddingTop) + var(--pf-v5-c-tree-view--m-compact__node--nested--PaddingTop) + 0.25rem);
  padding-block-start: var(--pf-v5-c-tree-view--PaddingTop);
  padding-block-end: var(--pf-v5-c-tree-view--PaddingBottom);
}

.pf-v5-c-tree-view.pf-m-compact .pf-v5-c-tree-view__list-item,
.pf-v5-c-tree-view.pf-m-guides .pf-v5-c-tree-view__list-item {
  position: relative;
}

.pf-v5-c-tree-view.pf-m-compact .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item::before,
.pf-v5-c-tree-view.pf-m-compact .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__node::before,
.pf-v5-c-tree-view.pf-m-guides .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item::before,
.pf-v5-c-tree-view.pf-m-guides .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__node::before {
  position: absolute;
  inset-inline-start: var(--pf-v5-c-tree-view--m-guides--guide--Left);
  content: "";
}

.pf-v5-c-tree-view.pf-m-compact .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item::before,
.pf-v5-c-tree-view.pf-m-guides .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item::before {
  inset-block-start: var(--pf-v5-c-tree-view--m-guides__list-item--before--Top);
  z-index: var(--pf-v5-c-tree-view--m-guides__list-item--ZIndex);
  width: var(--pf-v5-c-tree-view--m-guides__list-item--before--Width);
  height: var(--pf-v5-c-tree-view--m-guides__list-item--before--Height);
  background-color: var(--pf-v5-c-tree-view--m-guides__list-item--before--BackgroundColor);
}

.pf-v5-c-tree-view.pf-m-compact .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__node::before,
.pf-v5-c-tree-view.pf-m-guides .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__node::before {
  inset-block-start: var(--pf-v5-c-tree-view--m-guides__node--before--Top);
  width: var(--pf-v5-c-tree-view--m-guides__node--before--Width);
  height: var(--pf-v5-c-tree-view--m-guides__node--before--Height);
  background-color: var(--pf-v5-c-tree-view--m-guides__node--before--BackgroundColor);
}

.pf-v5-c-tree-view.pf-m-compact .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item:last-child::before,
.pf-v5-c-tree-view.pf-m-guides .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item:last-child::before {
  height: var(--pf-v5-c-tree-view--m-guides__list-item--last-child--before--Height);
}

.pf-v5-c-tree-view.pf-m-compact .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item,
.pf-v5-c-tree-view.pf-m-guides .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item {
  --pf-v5-c-tree-view--m-guides--guide--Left: var(--pf-v5-c-tree-view--m-guides--border--nested--Left);
}

.pf-v5-c-tree-view.pf-m-compact .pf-v5-c-tree-view__list-item:last-child,
.pf-v5-c-tree-view.pf-m-guides .pf-v5-c-tree-view__list-item:last-child {
  --pf-v5-c-tree-view--m-compact__list-item--BorderBottomWidth: 0;
}

.pf-v5-c-tree-view.pf-m-compact {
  --pf-v5-c-tree-view__node--PaddingTop: var(--pf-v5-c-tree-view--m-compact__node--PaddingTop);
  --pf-v5-c-tree-view__node--PaddingBottom: var(--pf-v5-c-tree-view--m-compact__node--PaddingBottom);
  --pf-v5-c-tree-view__node-container--Display: var(--pf-v5-c-tree-view--m-compact__node-container--Display);
  --pf-v5-c-tree-view__node--hover--BackgroundColor: transparent;
  --pf-v5-c-tree-view__node--focus--BackgroundColor: transparent;
  --pf-v5-c-tree-view__list-item__list-item__node-toggle--Top: var(--pf-v5-c-tree-view--m-compact__list-item__list-item__node-toggle--Top);
}

.pf-v5-c-tree-view.pf-m-compact .pf-v5-c-tree-view__list-item {
  border-block-end: var(--pf-v5-c-tree-view--m-compact__list-item--BorderBottomWidth) solid var(--pf-v5-c-tree-view--m-compact__list-item--BorderBottomColor);
}

.pf-v5-c-tree-view.pf-m-compact .pf-v5-c-tree-view__list-item.pf-m-expanded {
  --pf-v5-c-tree-view--m-compact__node-container--PaddingBottom: var(--pf-v5-c-tree-view--m-compact__list-item--m-expanded__node-container--PaddingBottom);
}

.pf-v5-c-tree-view.pf-m-compact .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item {
  --pf-v5-c-tree-view__node--PaddingTop: var(--pf-v5-c-tree-view--m-compact__node--nested--PaddingTop);
  --pf-v5-c-tree-view__node--PaddingBottom: var(--pf-v5-c-tree-view--m-compact__node--nested--PaddingBottom);
  --pf-v5-c-tree-view__node-toggle--Position: static;
  --pf-v5-c-tree-view__node--PaddingLeft: var(--pf-v5-c-tree-view--m-compact__node--level-2--PaddingLeft);
  --pf-v5-c-tree-view__list-item__list-item__node-toggle--TranslateX: 0;
  --pf-v5-c-tree-view--m-compact__list-item--BorderBottomWidth: 0;
  --pf-v5-c-tree-view--m-compact__node-container--PaddingBottom: var(--pf-v5-c-tree-view--m-compact__node-container--PaddingBottom--base);
}

.pf-v5-c-tree-view.pf-m-compact .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item::before,
.pf-v5-c-tree-view.pf-m-compact .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__node::before {
  inset-inline-start: var(--pf-v5-c-tree-view--m-compact--border--Left);
}

.pf-v5-c-tree-view.pf-m-compact .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item::before {
  inset-block-start: var(--pf-v5-c-tree-view--m-compact__list-item--before--Top);
}

.pf-v5-c-tree-view.pf-m-compact .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__node::before {
  inset-block-start: var(--pf-v5-c-tree-view--m-compact__node--before--Top);
}

.pf-v5-c-tree-view.pf-m-compact .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item:last-child::before {
  height: var(--pf-v5-c-tree-view--m-compact__list-item--last-child--before--Height);
}

.pf-v5-c-tree-view.pf-m-compact .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item {
  --pf-v5-c-tree-view__node--PaddingLeft: var(--pf-v5-c-tree-view--m-compact__node--PaddingLeft);
  --pf-v5-c-tree-view--m-compact--border--Left: var(--pf-v5-c-tree-view--m-compact--border--nested--Left);
  --pf-v5-c-tree-view--m-compact__list-item--before--Top: var(--pf-v5-c-tree-view--m-compact__list-item--nested--before--Top);
  --pf-v5-c-tree-view--m-compact__list-item--last-child--before--Height: var(--pf-v5-c-tree-view--m-compact__list-item--nested--last-child--before--Height);
}

.pf-v5-c-tree-view.pf-m-compact .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__node-container {
  padding-block-start: var(--pf-v5-c-tree-view--m-compact__node-container--nested--PaddingTop);
  padding-block-end: var(--pf-v5-c-tree-view--m-compact__node-container--nested--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-tree-view--m-compact__node-container--nested--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-tree-view--m-compact__node-container--nested--PaddingRight);
  background-color: var(--pf-v5-c-tree-view--m-compact__node-container--nested--BackgroundColor);
}

.pf-v5-c-tree-view.pf-m-compact .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__node-toggle {
  margin-inline-start: var(--pf-v5-c-tree-view--m-compact__node-toggle--nested--MarginLeft);
  margin-inline-end: var(--pf-v5-c-tree-view--m-compact__node-toggle--nested--MarginRight);
}

.pf-v5-c-tree-view.pf-m-compact .pf-v5-c-tree-view__node-container {
  padding-block-start: var(--pf-v5-c-tree-view--m-compact__node-container--PaddingTop);
  padding-block-end: var(--pf-v5-c-tree-view--m-compact__node-container--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-tree-view--m-compact__node-container--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-tree-view--m-compact__node-container--PaddingRight);
}

.pf-v5-c-tree-view.pf-m-compact .pf-v5-c-tree-view__list-item:not([aria-expanded])>.pf-v5-c-tree-view__content>.pf-v5-c-tree-view__node,
.pf-v5-c-tree-view.pf-m-compact .pf-v5-c-tree-view__list-item:not([aria-expanded])>.pf-v5-c-tree-view__content>.pf-v5-c-tree-view__node>.pf-v5-c-tree-view__node-container {
  cursor: default;
}

.pf-v5-c-tree-view.pf-m-compact.pf-m-no-background {
  --pf-v5-c-tree-view--m-compact__node--before--Top: var(--pf-v5-c-tree-view--m-compact--m-no-background__node--before--Top);
}

.pf-v5-c-tree-view.pf-m-compact.pf-m-no-background .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item {
  --pf-v5-c-tree-view__node--PaddingTop: var(--pf-v5-c-tree-view--m-compact--m-no-background__node--nested--PaddingTop);
  --pf-v5-c-tree-view__node--PaddingBottom: var(--pf-v5-c-tree-view--m-compact--m-no-background__node--nested--PaddingBottom);
}

.pf-v5-c-tree-view.pf-m-compact.pf-m-no-background .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item {
  --pf-v5-c-tree-view--m-compact--border--Left: var(--pf-v5-c-tree-view--m-compact--m-no-background--border--nested--Left);
  --pf-v5-c-tree-view__node--PaddingLeft: var(--pf-v5-c-tree-view--m-compact--m-no-background__node--PaddingLeft);
}

.pf-v5-c-tree-view.pf-m-no-background {
  --pf-v5-c-tree-view--m-compact__node-container--nested--BackgroundColor: var(--pf-v5-c-tree-view--m-no-background__node-container--BackgroundColor);
}

.pf-v5-c-tree-view__node-toggle-icon {
  display: inline-block;
  min-width: var(--pf-v5-c-tree-view__node-toggle-icon--MinWidth);
  text-align: center;
  transition: var(--pf-v5-c-tree-view__node-toggle-icon--Transition);
  transform: rotate(var(--pf-v5-c-tree-view__node-toggle-icon--Rotate));
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-tree-view__node-toggle-icon {
  scale: -1 1;
}

.pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item {
  --pf-v5-c-tree-view__node-toggle-icon--Rotate: var(--pf-v5-c-tree-view__node-toggle-icon--base--Rotate);
  --pf-v5-c-tree-view__node-toggle--Color: var(--pf-v5-c-tree-view__node-toggle--Color--base);
}

.pf-v5-c-tree-view__list-item.pf-m-expanded {
  --pf-v5-c-tree-view__node-toggle--Color: var(--pf-v5-c-tree-view__list-item--m-expanded__node-toggle--Color);
  --pf-v5-c-tree-view__node-toggle-icon--Rotate: var(--pf-v5-c-tree-view__list-item--m-expanded__node-toggle-icon--Rotate);
}

.pf-v5-c-tree-view__node,
.pf-v5-c-tree-view__node-container {
  flex: 1 1;
  align-items: flex-start;
  min-width: 0;
  text-align: start;
  cursor: pointer;
  border: 0;
}

.pf-v5-c-tree-view__node {
  position: relative;
  display: flex;
  padding-block-start: var(--pf-v5-c-tree-view__node--PaddingTop);
  padding-block-end: var(--pf-v5-c-tree-view__node--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-tree-view__node--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-tree-view__node--PaddingRight);
  color: var(--pf-v5-c-tree-view__node--Color);
  background-color: var(--pf-v5-c-tree-view__node--BackgroundColor);
}

.pf-v5-c-tree-view__node.pf-m-current {
  --pf-v5-c-tree-view__node--Color: var(--pf-v5-c-tree-view__node--m-current--Color);
  font-weight: var(--pf-v5-c-tree-view__node--m-current--FontWeight);
}

.pf-v5-c-tree-view__node:focus {
  --pf-v5-c-tree-view__node--BackgroundColor: var(--pf-v5-c-tree-view__node--focus--BackgroundColor);
}

.pf-v5-c-tree-view__node .pf-v5-c-tree-view__node-count {
  margin-inline-start: var(--pf-v5-c-tree-view__node-count--MarginLeft);
}

.pf-v5-c-tree-view__node .pf-v5-c-tree-view__node-count .pf-v5-c-badge.pf-m-read {
  --pf-v5-c-badge--m-read--BackgroundColor: var(--pf-v5-c-tree-view__node-count--c-badge--m-read--BackgroundColor);
}

.pf-v5-c-tree-view__node:not(.pf-m-selectable):hover,
.pf-v5-c-tree-view__node.pf-m-selectable .pf-v5-c-tree-view__node-toggle:hover {
  --pf-v5-c-tree-view__node-toggle--Color: var(--pf-v5-c-tree-view__node-toggle--hover--Color);
}

.pf-v5-c-tree-view__node:not(.pf-m-selectable):focus,
.pf-v5-c-tree-view__node.pf-m-selectable .pf-v5-c-tree-view__node-toggle:focus {
  --pf-v5-c-tree-view__node-toggle--Color: var(--pf-v5-c-tree-view__node-toggle--focus--Color);
}

.pf-v5-c-tree-view__node:not(.pf-m-selectable):active,
.pf-v5-c-tree-view__node.pf-m-selectable .pf-v5-c-tree-view__node-toggle:active {
  --pf-v5-c-tree-view__node-toggle--Color: var(--pf-v5-c-tree-view__node-toggle--active--Color);
}

.pf-v5-c-tree-view__node-container {
  display: var(--pf-v5-c-tree-view__node-container--Display);
  flex-grow: 1;
}

.pf-v5-c-tree-view__node-content {
  display: flex;
  flex-direction: column;
  overflow: var(--pf-v5-c-tree-view__node-content--Overflow);
}

.pf-v5-c-tree-view__node-content>*+* {
  margin-block-start: var(--pf-v5-c-tree-view__node-content--RowGap);
}

.pf-v5-c-tree-view__node-check {
  margin-inline-end: var(--pf-v5-c-tree-view__node-check--MarginRight);
}

.pf-v5-c-tree-view__node-toggle {
  position: var(--pf-v5-c-tree-view__node-toggle--Position);
  inset-block-start: var(--pf-v5-c-tree-view__list-item__list-item__node-toggle--Top);
  inset-inline-start: var(--pf-v5-c-tree-view__list-item__list-item__node-toggle--Left);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-block-start: var(--pf-v5-c-tree-view__node-toggle-button--PaddingTop);
  padding-block-end: var(--pf-v5-c-tree-view__node-toggle-button--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-tree-view__node-toggle-button--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-tree-view__node-toggle-button--PaddingRight);
  margin-block-start: var(--pf-v5-c-tree-view__node-toggle-button--MarginTop);
  margin-block-end: var(--pf-v5-c-tree-view__node-toggle-button--MarginBottom);
  color: var(--pf-v5-c-tree-view__node-toggle--Color);
  background-color: var(--pf-v5-c-tree-view__node-toggle--BackgroundColor);
  border: 0;
  transform: translateX(var(--pf-v5-c-tree-view__list-item__list-item__node-toggle--TranslateX));
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-tree-view__node-toggle {
  transform: translateX(calc(var(--pf-v5-c-tree-view__list-item__list-item__node-toggle--TranslateX) * var(--pf-v5-global--inverse--multiplier)));
}

.pf-v5-c-tree-view__node-title.pf-m-truncate,
.pf-v5-c-tree-view__node-text.pf-m-truncate {
  --pf-v5-c-tree-view__node-content--Overflow: hidden;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pf-v5-c-tree-view.pf-m-truncate .pf-v5-c-tree-view__node-title,
.pf-v5-c-tree-view.pf-m-truncate .pf-v5-c-tree-view__node-text {
  --pf-v5-c-tree-view__node-content--Overflow: hidden;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pf-v5-c-tree-view__node-text {
  font-weight: inherit;
  color: inherit;
  text-align: start;
  background-color: transparent;
  border: 0;
}

label.pf-v5-c-tree-view__node-text {
  cursor: pointer;
}

.pf-v5-c-tree-view__node-title {
  font-weight: var(--pf-v5-c-tree-view__node-title--FontWeight);
}

.pf-v5-c-tree-view__search {
  padding-block-start: var(--pf-v5-c-tree-view__search--PaddingTop);
  padding-block-end: var(--pf-v5-c-tree-view__search--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-tree-view__search--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-tree-view__search--PaddingRight);
}

.pf-v5-c-tree-view__node-icon {
  padding-inline-end: var(--pf-v5-c-tree-view__node-icon--PaddingRight);
  color: var(--pf-v5-c-tree-view__node-icon--Color);
}

.pf-v5-c-tree-view__content {
  display: flex;
  align-items: center;
}

.pf-v5-c-tree-view__content:hover,
.pf-v5-c-tree-view__content:focus-within {
  background-color: var(--pf-v5-c-tree-view__node--hover--BackgroundColor);
}

.pf-v5-c-tree-view__action {
  margin-inline-start: var(--pf-v5-c-tree-view__action--MarginLeft);
}

.pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item {
  --pf-v5-c-tree-view__list-item__list-item__node-toggle--Left: var(--pf-v5-c-tree-view__node--PaddingLeft);
  --pf-v5-c-tree-view__node--PaddingLeft: calc(var(--pf-v5-c-tree-view__node--nested-indent--base) * 1 + var(--pf-v5-c-tree-view__node--indent--base));
  --pf-v5-c-tree-view--m-guides--border--nested--Left: calc(var(--pf-v5-c-tree-view__node--PaddingLeft) - var(--pf-v5-c-tree-view--m-guides--guide-left--base--offset));
}

.pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item {
  --pf-v5-c-tree-view__list-item__list-item__node-toggle--Left: var(--pf-v5-c-tree-view__node--PaddingLeft);
  --pf-v5-c-tree-view__node--PaddingLeft: calc(var(--pf-v5-c-tree-view__node--nested-indent--base) * 2 + var(--pf-v5-c-tree-view__node--indent--base));
  --pf-v5-c-tree-view--m-guides--border--nested--Left: calc(var(--pf-v5-c-tree-view__node--PaddingLeft) - var(--pf-v5-c-tree-view--m-guides--guide-left--base--offset));
  --pf-v5-c-tree-view--m-compact__node--PaddingLeft: calc(var(--pf-v5-c-tree-view--m-compact__node--nested-indent--base) * 1 + var(--pf-v5-c-tree-view--m-compact__node--indent--base));
  --pf-v5-c-tree-view--m-compact--border--nested--Left: calc(var(--pf-v5-c-tree-view__node--PaddingLeft) - var(--pf-v5-c-tree-view--m-compact--base-border--Left--offset));
  --pf-v5-c-tree-view--m-compact--m-no-background__node--PaddingLeft: calc(var(--pf-v5-c-tree-view--m-compact--m-no-background__node--nested-indent--base) * 1 + var(--pf-v5-c-tree-view--m-compact--m-no-background__node--indent--base));
  --pf-v5-c-tree-view--m-compact--m-no-background--border--nested--Left: calc(var(--pf-v5-c-tree-view__node--PaddingLeft) - var(--pf-v5-c-tree-view--m-compact--m-no-background--base-border--Left--offset));
}

.pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item {
  --pf-v5-c-tree-view__list-item__list-item__node-toggle--Left: var(--pf-v5-c-tree-view__node--PaddingLeft);
  --pf-v5-c-tree-view__node--PaddingLeft: calc(var(--pf-v5-c-tree-view__node--nested-indent--base) * 3 + var(--pf-v5-c-tree-view__node--indent--base));
  --pf-v5-c-tree-view--m-guides--border--nested--Left: calc(var(--pf-v5-c-tree-view__node--PaddingLeft) - var(--pf-v5-c-tree-view--m-guides--guide-left--base--offset));
  --pf-v5-c-tree-view--m-compact__node--PaddingLeft: calc(var(--pf-v5-c-tree-view--m-compact__node--nested-indent--base) * 2 + var(--pf-v5-c-tree-view--m-compact__node--indent--base));
  --pf-v5-c-tree-view--m-compact--border--nested--Left: calc(var(--pf-v5-c-tree-view__node--PaddingLeft) - var(--pf-v5-c-tree-view--m-compact--base-border--Left--offset));
  --pf-v5-c-tree-view--m-compact--m-no-background__node--PaddingLeft: calc(var(--pf-v5-c-tree-view--m-compact--m-no-background__node--nested-indent--base) * 2 + var(--pf-v5-c-tree-view--m-compact--m-no-background__node--indent--base));
  --pf-v5-c-tree-view--m-compact--m-no-background--border--nested--Left: calc(var(--pf-v5-c-tree-view__node--PaddingLeft) - var(--pf-v5-c-tree-view--m-compact--m-no-background--base-border--Left--offset));
}

.pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item {
  --pf-v5-c-tree-view__list-item__list-item__node-toggle--Left: var(--pf-v5-c-tree-view__node--PaddingLeft);
  --pf-v5-c-tree-view__node--PaddingLeft: calc(var(--pf-v5-c-tree-view__node--nested-indent--base) * 4 + var(--pf-v5-c-tree-view__node--indent--base));
  --pf-v5-c-tree-view--m-guides--border--nested--Left: calc(var(--pf-v5-c-tree-view__node--PaddingLeft) - var(--pf-v5-c-tree-view--m-guides--guide-left--base--offset));
  --pf-v5-c-tree-view--m-compact__node--PaddingLeft: calc(var(--pf-v5-c-tree-view--m-compact__node--nested-indent--base) * 3 + var(--pf-v5-c-tree-view--m-compact__node--indent--base));
  --pf-v5-c-tree-view--m-compact--border--nested--Left: calc(var(--pf-v5-c-tree-view__node--PaddingLeft) - var(--pf-v5-c-tree-view--m-compact--base-border--Left--offset));
  --pf-v5-c-tree-view--m-compact--m-no-background__node--PaddingLeft: calc(var(--pf-v5-c-tree-view--m-compact--m-no-background__node--nested-indent--base) * 3 + var(--pf-v5-c-tree-view--m-compact--m-no-background__node--indent--base));
  --pf-v5-c-tree-view--m-compact--m-no-background--border--nested--Left: calc(var(--pf-v5-c-tree-view__node--PaddingLeft) - var(--pf-v5-c-tree-view--m-compact--m-no-background--base-border--Left--offset));
}

.pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item {
  --pf-v5-c-tree-view__list-item__list-item__node-toggle--Left: var(--pf-v5-c-tree-view__node--PaddingLeft);
  --pf-v5-c-tree-view__node--PaddingLeft: calc(var(--pf-v5-c-tree-view__node--nested-indent--base) * 5 + var(--pf-v5-c-tree-view__node--indent--base));
  --pf-v5-c-tree-view--m-guides--border--nested--Left: calc(var(--pf-v5-c-tree-view__node--PaddingLeft) - var(--pf-v5-c-tree-view--m-guides--guide-left--base--offset));
  --pf-v5-c-tree-view--m-compact__node--PaddingLeft: calc(var(--pf-v5-c-tree-view--m-compact__node--nested-indent--base) * 4 + var(--pf-v5-c-tree-view--m-compact__node--indent--base));
  --pf-v5-c-tree-view--m-compact--border--nested--Left: calc(var(--pf-v5-c-tree-view__node--PaddingLeft) - var(--pf-v5-c-tree-view--m-compact--base-border--Left--offset));
  --pf-v5-c-tree-view--m-compact--m-no-background__node--PaddingLeft: calc(var(--pf-v5-c-tree-view--m-compact--m-no-background__node--nested-indent--base) * 4 + var(--pf-v5-c-tree-view--m-compact--m-no-background__node--indent--base));
  --pf-v5-c-tree-view--m-compact--m-no-background--border--nested--Left: calc(var(--pf-v5-c-tree-view__node--PaddingLeft) - var(--pf-v5-c-tree-view--m-compact--m-no-background--base-border--Left--offset));
}

.pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item {
  --pf-v5-c-tree-view__list-item__list-item__node-toggle--Left: var(--pf-v5-c-tree-view__node--PaddingLeft);
  --pf-v5-c-tree-view__node--PaddingLeft: calc(var(--pf-v5-c-tree-view__node--nested-indent--base) * 6 + var(--pf-v5-c-tree-view__node--indent--base));
  --pf-v5-c-tree-view--m-guides--border--nested--Left: calc(var(--pf-v5-c-tree-view__node--PaddingLeft) - var(--pf-v5-c-tree-view--m-guides--guide-left--base--offset));
  --pf-v5-c-tree-view--m-compact__node--PaddingLeft: calc(var(--pf-v5-c-tree-view--m-compact__node--nested-indent--base) * 5 + var(--pf-v5-c-tree-view--m-compact__node--indent--base));
  --pf-v5-c-tree-view--m-compact--border--nested--Left: calc(var(--pf-v5-c-tree-view__node--PaddingLeft) - var(--pf-v5-c-tree-view--m-compact--base-border--Left--offset));
  --pf-v5-c-tree-view--m-compact--m-no-background__node--PaddingLeft: calc(var(--pf-v5-c-tree-view--m-compact--m-no-background__node--nested-indent--base) * 5 + var(--pf-v5-c-tree-view--m-compact--m-no-background__node--indent--base));
  --pf-v5-c-tree-view--m-compact--m-no-background--border--nested--Left: calc(var(--pf-v5-c-tree-view__node--PaddingLeft) - var(--pf-v5-c-tree-view--m-compact--m-no-background--base-border--Left--offset));
}

.pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item {
  --pf-v5-c-tree-view__list-item__list-item__node-toggle--Left: var(--pf-v5-c-tree-view__node--PaddingLeft);
  --pf-v5-c-tree-view__node--PaddingLeft: calc(var(--pf-v5-c-tree-view__node--nested-indent--base) * 7 + var(--pf-v5-c-tree-view__node--indent--base));
  --pf-v5-c-tree-view--m-guides--border--nested--Left: calc(var(--pf-v5-c-tree-view__node--PaddingLeft) - var(--pf-v5-c-tree-view--m-guides--guide-left--base--offset));
  --pf-v5-c-tree-view--m-compact__node--PaddingLeft: calc(var(--pf-v5-c-tree-view--m-compact__node--nested-indent--base) * 6 + var(--pf-v5-c-tree-view--m-compact__node--indent--base));
  --pf-v5-c-tree-view--m-compact--border--nested--Left: calc(var(--pf-v5-c-tree-view__node--PaddingLeft) - var(--pf-v5-c-tree-view--m-compact--base-border--Left--offset));
  --pf-v5-c-tree-view--m-compact--m-no-background__node--PaddingLeft: calc(var(--pf-v5-c-tree-view--m-compact--m-no-background__node--nested-indent--base) * 6 + var(--pf-v5-c-tree-view--m-compact--m-no-background__node--indent--base));
  --pf-v5-c-tree-view--m-compact--m-no-background--border--nested--Left: calc(var(--pf-v5-c-tree-view__node--PaddingLeft) - var(--pf-v5-c-tree-view--m-compact--m-no-background--base-border--Left--offset));
}

.pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item {
  --pf-v5-c-tree-view__list-item__list-item__node-toggle--Left: var(--pf-v5-c-tree-view__node--PaddingLeft);
  --pf-v5-c-tree-view__node--PaddingLeft: calc(var(--pf-v5-c-tree-view__node--nested-indent--base) * 8 + var(--pf-v5-c-tree-view__node--indent--base));
  --pf-v5-c-tree-view--m-guides--border--nested--Left: calc(var(--pf-v5-c-tree-view__node--PaddingLeft) - var(--pf-v5-c-tree-view--m-guides--guide-left--base--offset));
  --pf-v5-c-tree-view--m-compact__node--PaddingLeft: calc(var(--pf-v5-c-tree-view--m-compact__node--nested-indent--base) * 7 + var(--pf-v5-c-tree-view--m-compact__node--indent--base));
  --pf-v5-c-tree-view--m-compact--border--nested--Left: calc(var(--pf-v5-c-tree-view__node--PaddingLeft) - var(--pf-v5-c-tree-view--m-compact--base-border--Left--offset));
  --pf-v5-c-tree-view--m-compact--m-no-background__node--PaddingLeft: calc(var(--pf-v5-c-tree-view--m-compact--m-no-background__node--nested-indent--base) * 7 + var(--pf-v5-c-tree-view--m-compact--m-no-background__node--indent--base));
  --pf-v5-c-tree-view--m-compact--m-no-background--border--nested--Left: calc(var(--pf-v5-c-tree-view__node--PaddingLeft) - var(--pf-v5-c-tree-view--m-compact--m-no-background--base-border--Left--offset));
}

.pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item {
  --pf-v5-c-tree-view__list-item__list-item__node-toggle--Left: var(--pf-v5-c-tree-view__node--PaddingLeft);
  --pf-v5-c-tree-view__node--PaddingLeft: calc(var(--pf-v5-c-tree-view__node--nested-indent--base) * 9 + var(--pf-v5-c-tree-view__node--indent--base));
  --pf-v5-c-tree-view--m-guides--border--nested--Left: calc(var(--pf-v5-c-tree-view__node--PaddingLeft) - var(--pf-v5-c-tree-view--m-guides--guide-left--base--offset));
  --pf-v5-c-tree-view--m-compact__node--PaddingLeft: calc(var(--pf-v5-c-tree-view--m-compact__node--nested-indent--base) * 8 + var(--pf-v5-c-tree-view--m-compact__node--indent--base));
  --pf-v5-c-tree-view--m-compact--border--nested--Left: calc(var(--pf-v5-c-tree-view__node--PaddingLeft) - var(--pf-v5-c-tree-view--m-compact--base-border--Left--offset));
  --pf-v5-c-tree-view--m-compact--m-no-background__node--PaddingLeft: calc(var(--pf-v5-c-tree-view--m-compact--m-no-background__node--nested-indent--base) * 8 + var(--pf-v5-c-tree-view--m-compact--m-no-background__node--indent--base));
  --pf-v5-c-tree-view--m-compact--m-no-background--border--nested--Left: calc(var(--pf-v5-c-tree-view__node--PaddingLeft) - var(--pf-v5-c-tree-view--m-compact--m-no-background--base-border--Left--offset));
}

.pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item .pf-v5-c-tree-view__list-item {
  --pf-v5-c-tree-view__list-item__list-item__node-toggle--Left: var(--pf-v5-c-tree-view__node--PaddingLeft);
  --pf-v5-c-tree-view__node--PaddingLeft: calc(var(--pf-v5-c-tree-view__node--nested-indent--base) * 10 + var(--pf-v5-c-tree-view__node--indent--base));
  --pf-v5-c-tree-view--m-guides--border--nested--Left: calc(var(--pf-v5-c-tree-view__node--PaddingLeft) - var(--pf-v5-c-tree-view--m-guides--guide-left--base--offset));
  --pf-v5-c-tree-view--m-compact__node--PaddingLeft: calc(var(--pf-v5-c-tree-view--m-compact__node--nested-indent--base) * 9 + var(--pf-v5-c-tree-view--m-compact__node--indent--base));
  --pf-v5-c-tree-view--m-compact--border--nested--Left: calc(var(--pf-v5-c-tree-view__node--PaddingLeft) - var(--pf-v5-c-tree-view--m-compact--base-border--Left--offset));
  --pf-v5-c-tree-view--m-compact--m-no-background__node--PaddingLeft: calc(var(--pf-v5-c-tree-view--m-compact--m-no-background__node--nested-indent--base) * 9 + var(--pf-v5-c-tree-view--m-compact--m-no-background__node--indent--base));
  --pf-v5-c-tree-view--m-compact--m-no-background--border--nested--Left: calc(var(--pf-v5-c-tree-view__node--PaddingLeft) - var(--pf-v5-c-tree-view--m-compact--m-no-background--base-border--Left--offset));
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-tree-view {
  --pf-v5-c-tree-view__node--hover--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
  --pf-v5-c-tree-view__node--focus--BackgroundColor: var(--pf-v5-global--BackgroundColor--300);
}

.pf-v5-c-wizard {
  --pf-v5-c-wizard--Height: 100%;
  --pf-v5-c-modal-box--c-wizard--FlexBasis: 47.625rem;
  --pf-v5-c-wizard__header--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-100);
  --pf-v5-c-wizard__header--ZIndex: auto;
  --pf-v5-c-wizard__header--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-wizard__header--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-wizard__header--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-wizard__header--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-wizard__header--lg--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-wizard__header--lg--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-wizard__header--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-wizard__header--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-wizard__close--Top: calc(var(--pf-v5-global--spacer--lg) - var(--pf-v5-global--spacer--form-element));
  --pf-v5-c-wizard__close--Right: 0;
  --pf-v5-c-wizard__close--xl--Right: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-wizard__close--FontSize: var(--pf-v5-global--FontSize--xl);
  --pf-v5-c-wizard__title--PaddingRight: var(--pf-v5-global--spacer--2xl);
  --pf-v5-c-wizard__title-text--FontSize: var(--pf-v5-global--FontSize--3xl);
  --pf-v5-c-wizard__title-text--FontFamily: var(--pf-v5-global--FontFamily--heading);
  --pf-v5-c-wizard__title-text--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-wizard__title-text--LineHeight: var(--pf-v5-global--LineHeight--sm);
  --pf-v5-c-wizard__description--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-wizard__description--Color: var(--pf-v5-global--Color--light-200);
  --pf-v5-c-wizard__nav-link--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-wizard__nav-link--BackgroundColor: transparent;
  --pf-v5-c-wizard__nav-link--TextDecoration: var(--pf-v5-global--link--TextDecoration);
  --pf-v5-c-wizard__nav-link--hover--Color: var(--pf-v5-global--link--Color);
  --pf-v5-c-wizard__nav-link--focus--Color: var(--pf-v5-global--link--Color);
  --pf-v5-c-wizard__nav-link--m-current--Color: var(--pf-v5-global--link--Color);
  --pf-v5-c-wizard__nav-link--m-current--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-wizard__nav-link--m-disabled--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-wizard__nav-list__nav-list__nav-link--m-current--FontWeight: var(--pf-v5-global--FontWeight--normal);
  --pf-v5-c-wizard__nav-link-toggle--PaddingRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-wizard__nav-link-toggle--PaddingLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-wizard__nav-link-toggle--Color: var(--pf-v5-global--Color--200);
  --pf-v5-c-wizard__nav-link--hover__nav-link-toggle-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-wizard__nav-link--focus__nav-link-toggle-icon--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-wizard__nav-link-toggle-icon--Transition: var(--pf-v5-global--Transition);
  --pf-v5-c-wizard__nav-link-toggle-icon--Rotate: 0;
  --pf-v5-c-wizard__nav-item--m-expanded__link-toggle-icon--Rotate: 90deg;
  --pf-v5-c-wizard__nav-link--before--Width: 1.5rem;
  --pf-v5-c-wizard__nav-link--before--Height: 1.5rem;
  --pf-v5-c-wizard__nav-link--before--Top: 0;
  --pf-v5-c-wizard__nav-link--before--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-wizard__nav-link--before--BorderRadius: var(--pf-v5-global--BorderRadius--lg);
  --pf-v5-c-wizard__nav-link--before--Color: var(--pf-v5-global--Color--100);
  --pf-v5-c-wizard__nav-link--before--FontSize: var(--pf-v5-global--FontSize--sm);
  --pf-v5-c-wizard__nav-link--before--TranslateX: calc(-100% - var(--pf-v5-global--spacer--sm));
  --pf-v5-c-wizard__nav-link--m-current--before--BackgroundColor: var(--pf-v5-global--active-color--100);
  --pf-v5-c-wizard__nav-link--m-current--before--Color: var(--pf-v5-global--Color--light-100);
  --pf-v5-c-wizard__nav-link--m-disabled--before--BackgroundColor: transparent;
  --pf-v5-c-wizard__nav-link--m-disabled--before--Color: var(--pf-v5-global--disabled-color--100);
  --pf-v5-c-wizard__toggle--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-wizard__toggle--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-wizard__toggle--BoxShadow: var(--pf-v5-global--BoxShadow--md-bottom);
  --pf-v5-c-wizard__toggle--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-wizard__toggle--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-wizard__toggle--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-wizard__toggle--PaddingLeft: calc(var(--pf-v5-global--spacer--md) + var(--pf-v5-c-wizard__nav-link--before--Width) + var(--pf-v5-global--spacer--sm));
  --pf-v5-c-wizard__toggle--m-expanded--BorderBottomWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-wizard__toggle--m-expanded--BorderBottomColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-wizard--m-in-page__toggle--xl--PaddingLeft: calc(var(--pf-v5-global--spacer--xl) + var(--pf-v5-c-wizard__nav-link--before--Width) + var(--pf-v5-global--spacer--sm));
  --pf-v5-c-wizard__toggle-num--before--Top: 0;
  --pf-v5-c-wizard__toggle-list-item--not-last-child--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-wizard__toggle-list-item--MarginBottom: var(--pf-v5-global--spacer--xs);
  --pf-v5-c-wizard__toggle-list--MarginRight: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-wizard__toggle-list--MarginBottom: calc(var(--pf-v5-c-wizard__toggle-list-item--MarginBottom) * -1);
  --pf-v5-c-wizard__toggle-separator--MarginLeft: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-wizard__toggle-separator--Color: var(--pf-v5-global--BorderColor--200);
  --pf-v5-c-wizard__toggle-icon--LineHeight: var(--pf-v5-global--LineHeight--md);
  --pf-v5-c-wizard__toggle--m-expanded__toggle-icon--Rotate: 180deg;
  --pf-v5-c-wizard__nav--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-wizard__nav--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-wizard__nav--BoxShadow: var(--pf-v5-global--BoxShadow--md-bottom);
  --pf-v5-c-wizard__nav--Width: 100%;
  --pf-v5-c-wizard__nav--lg--Width: 15.625rem;
  --pf-v5-c-wizard__nav--lg--BorderRightWidth: var(--pf-v5-global--BorderWidth--sm);
  --pf-v5-c-wizard__nav--lg--BorderRightColor: var(--pf-v5-global--BorderColor--100);
  --pf-v5-c-wizard__nav-list--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-wizard__nav-list--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-wizard__nav-list--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-wizard__nav-list--PaddingLeft: calc(var(--pf-v5-global--spacer--md) + var(--pf-v5-c-wizard__nav-link--before--Width) + var(--pf-v5-global--spacer--sm));
  --pf-v5-c-wizard__nav-list--lg--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-wizard__nav-list--lg--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-wizard__nav-list--lg--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-wizard__nav-list--xl--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-wizard__nav-list--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-wizard__nav-list--xl--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-wizard__nav-list--xl--PaddingLeft: calc(var(--pf-v5-global--spacer--lg) + var(--pf-v5-c-wizard__nav-link--before--Width) + var(--pf-v5-global--spacer--sm));
  --pf-v5-c-wizard__nav-list--nested--MarginLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-wizard__nav-list--nested--MarginTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-wizard__nav-item--MarginTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-wizard__outer-wrap--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-wizard__outer-wrap--lg--PaddingLeft: var(--pf-v5-c-wizard__nav--Width);
  --pf-v5-c-wizard__outer-wrap--MinHeight: 15.625rem;
  --pf-v5-c-wizard__main--ZIndex: auto;
  --pf-v5-c-wizard__main-body--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-wizard__main-body--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-wizard__main-body--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-wizard__main-body--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-wizard__main-body--xl--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-wizard__main-body--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-wizard__main-body--xl--PaddingBottom: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-wizard__main-body--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-wizard__footer--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  --pf-v5-c-wizard__footer--ZIndex: var(--pf-v5-global--ZIndex--xs);
  --pf-v5-c-wizard__footer--PaddingTop: var(--pf-v5-global--spacer--md);
  --pf-v5-c-wizard__footer--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-wizard__footer--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-wizard__footer--PaddingLeft: var(--pf-v5-global--spacer--md);
  --pf-v5-c-wizard__footer--xl--PaddingTop: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-wizard__footer--xl--PaddingRight: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-wizard__footer--xl--PaddingBottom: var(--pf-v5-global--spacer--md);
  --pf-v5-c-wizard__footer--xl--PaddingLeft: var(--pf-v5-global--spacer--lg);
  --pf-v5-c-wizard__footer--BoxShadow: var(--pf-v5-global--BoxShadow--sm-top);
  --pf-v5-c-wizard__footer--child--MarginRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-wizard__footer--child--MarginBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-wizard__footer-cancel--MarginLeft: calc(var(--pf-v5-global--spacer--2xl) - var(--pf-v5-c-wizard__footer--child--MarginRight));
  position: relative;
  display: flex;
  flex-direction: column;
  height: var(--pf-v5-c-wizard--Height);
}

@media screen and (min-width: 992px) {
  .pf-v5-c-wizard {
    --pf-v5-c-wizard__header--PaddingRight: var(--pf-v5-c-wizard__header--lg--PaddingRight);
    --pf-v5-c-wizard__header--PaddingLeft: var(--pf-v5-c-wizard__header--lg--PaddingLeft);
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-c-wizard {
    --pf-v5-c-wizard__header--PaddingRight: var(--pf-v5-c-wizard__header--xl--PaddingRight);
    --pf-v5-c-wizard__header--PaddingLeft: var(--pf-v5-c-wizard__header--xl--PaddingLeft);
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-c-wizard {
    --pf-v5-c-wizard__close--Right: var(--pf-v5-c-wizard__close--xl--Right);
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-c-wizard {
    --pf-v5-c-wizard__nav--Width: var(--pf-v5-c-wizard__nav--lg--Width);
    --pf-v5-c-wizard__nav--BoxShadow: none;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-c-wizard {
    --pf-v5-c-wizard__nav-list--PaddingTop: var(--pf-v5-c-wizard__nav-list--lg--PaddingTop);
    --pf-v5-c-wizard__nav-list--PaddingRight: var(--pf-v5-c-wizard__nav-list--lg--PaddingRight);
    --pf-v5-c-wizard__nav-list--PaddingBottom: var(--pf-v5-c-wizard__nav-list--lg--PaddingBottom);
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-c-wizard {
    --pf-v5-c-wizard__nav-list--PaddingTop: var(--pf-v5-c-wizard__nav-list--xl--PaddingTop);
    --pf-v5-c-wizard__nav-list--PaddingRight: var(--pf-v5-c-wizard__nav-list--xl--PaddingRight);
    --pf-v5-c-wizard__nav-list--PaddingBottom: var(--pf-v5-c-wizard__nav-list--xl--PaddingBottom);
    --pf-v5-c-wizard__nav-list--PaddingLeft: var(--pf-v5-c-wizard__nav-list--xl--PaddingLeft);
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-c-wizard {
    --pf-v5-c-wizard__main-body--PaddingTop: var(--pf-v5-c-wizard__main-body--xl--PaddingTop);
    --pf-v5-c-wizard__main-body--PaddingRight: var(--pf-v5-c-wizard__main-body--xl--PaddingRight);
    --pf-v5-c-wizard__main-body--PaddingBottom: var(--pf-v5-c-wizard__main-body--xl--PaddingBottom);
    --pf-v5-c-wizard__main-body--PaddingLeft: var(--pf-v5-c-wizard__main-body--xl--PaddingLeft);
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-c-wizard {
    --pf-v5-c-wizard__footer--PaddingTop: var(--pf-v5-c-wizard__footer--xl--PaddingTop);
    --pf-v5-c-wizard__footer--PaddingRight: var(--pf-v5-c-wizard__footer--xl--PaddingRight);
    --pf-v5-c-wizard__footer--PaddingBottom: var(--pf-v5-c-wizard__footer--xl--PaddingBottom);
    --pf-v5-c-wizard__footer--PaddingLeft: var(--pf-v5-c-wizard__footer--xl--PaddingLeft);
  }
}

.pf-v5-c-modal-box .pf-v5-c-wizard {
  flex: 1 1 var(--pf-v5-c-modal-box--c-wizard--FlexBasis);
  min-height: 0;
}

.pf-v5-c-wizard>:not(.pf-v5-c-wizard__outer-wrap):not(.pf-v5-c-drawer) {
  flex-shrink: 0;
}

.pf-v5-c-wizard.pf-m-finished {
  --pf-v5-c-wizard__outer-wrap--lg--PaddingLeft: 0;
}

.pf-v5-c-wizard.pf-m-finished .pf-v5-c-wizard__nav,
.pf-v5-c-wizard.pf-m-finished .pf-v5-c-wizard__footer,
.pf-v5-c-wizard.pf-m-finished .pf-v5-c-wizard__toggle {
  display: none;
}

.pf-v5-c-wizard__header {
  color: var(--pf-v5-global--Color--100);
  position: relative;
  z-index: var(--pf-v5-c-wizard__header--ZIndex);
  padding-block-start: var(--pf-v5-c-wizard__header--PaddingTop);
  padding-block-end: var(--pf-v5-c-wizard__header--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-wizard__header--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-wizard__header--PaddingRight);
  background-color: var(--pf-v5-c-wizard__header--BackgroundColor);
}

.pf-v5-c-wizard__header .pf-v5-c-wizard__close {
  position: absolute;
  inset-block-start: var(--pf-v5-c-wizard__close--Top);
  inset-inline-end: var(--pf-v5-c-wizard__close--Right);
}

.pf-v5-c-wizard__header .pf-v5-c-wizard__close button {
  font-size: var(--pf-v5-c-wizard__close--FontSize);
}

.pf-v5-c-wizard__title {
  padding-inline-end: var(--pf-v5-c-wizard__title--PaddingRight);
  word-wrap: break-word;
}

.pf-v5-c-wizard__title-text {
  font-family: var(--pf-v5-c-wizard__title-text--FontFamily);
  font-size: var(--pf-v5-c-wizard__title-text--FontSize);
  font-weight: var(--pf-v5-c-wizard__title-text--FontWeight);
  line-height: var(--pf-v5-c-wizard__title-text--LineHeight);
}

.pf-v5-c-wizard__description {
  display: none;
  padding-block-start: var(--pf-v5-c-wizard__description--PaddingTop);
  color: var(--pf-v5-c-wizard__description--Color);
}

@media screen and (min-width: 992px) {
  .pf-v5-c-wizard__description {
    display: block;
  }
}

.pf-v5-c-wizard__toggle {
  position: relative;
  z-index: var(--pf-v5-c-wizard__toggle--ZIndex);
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-block-start: var(--pf-v5-c-wizard__toggle--PaddingTop);
  padding-block-end: var(--pf-v5-c-wizard__toggle--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-wizard__toggle--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-wizard__toggle--PaddingRight);
  background-color: var(--pf-v5-c-wizard__toggle--BackgroundColor);
  border: 0;
  box-shadow: var(--pf-v5-c-wizard__toggle--BoxShadow);
}

@media screen and (min-width: 992px) {
  .pf-v5-c-wizard__toggle {
    display: none;
  }
}

.pf-v5-c-wizard__toggle.pf-m-expanded {
  --pf-v5-c-wizard__toggle--BoxShadow: none;
  border-block-end: var(--pf-v5-c-wizard__toggle--m-expanded--BorderBottomWidth) solid var(--pf-v5-c-wizard__toggle--m-expanded--BorderBottomColor);
}

.pf-v5-c-wizard__toggle.pf-m-expanded .pf-v5-c-wizard__toggle-icon {
  transform: rotate(var(--pf-v5-c-wizard__toggle--m-expanded__toggle-icon--Rotate));
}

.pf-v5-c-wizard__toggle-list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  margin-block-end: var(--pf-v5-c-wizard__toggle-list--MarginBottom);
  margin-inline-end: var(--pf-v5-c-wizard__toggle-list--MarginRight);
  list-style: none;
}

.pf-v5-c-wizard__toggle-list-item {
  margin-block-end: var(--pf-v5-c-wizard__toggle-list-item--MarginBottom);
  text-align: start;
  word-break: break-word;
}

.pf-v5-c-wizard__toggle-list-item:not(:last-child) {
  margin-inline-end: var(--pf-v5-c-wizard__toggle-list-item--not-last-child--MarginRight);
}

.pf-v5-c-wizard__toggle-num {
  --pf-v5-c-wizard__nav-link--before--Top: var(--pf-v5-c-wizard__toggle-num--before--Top);
}

.pf-v5-c-wizard__toggle-separator {
  margin-inline-start: var(--pf-v5-c-wizard__toggle-separator--MarginLeft);
  color: var(--pf-v5-c-wizard__toggle-separator--Color);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-wizard__toggle-separator {
  scale: -1 1;
}

.pf-v5-c-wizard__toggle-icon {
  line-height: var(--pf-v5-c-wizard__toggle-icon--LineHeight);
}

.pf-v5-c-wizard__outer-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: var(--pf-v5-c-wizard__outer-wrap--MinHeight);
  background-color: var(--pf-v5-c-wizard__outer-wrap--BackgroundColor);
}

@media screen and (min-width: 992px) {
  .pf-v5-c-wizard__outer-wrap {
    padding-inline-start: var(--pf-v5-c-wizard__outer-wrap--lg--PaddingLeft);
  }
}

.pf-v5-c-wizard__inner-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
}

@media screen and (min-width: 992px) {
  .pf-v5-c-wizard__inner-wrap {
    position: static;
  }
}

.pf-v5-c-wizard__nav {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  z-index: var(--pf-v5-c-wizard__nav--ZIndex);
  display: none;
  width: var(--pf-v5-c-wizard__nav--Width);
  max-height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background-color: var(--pf-v5-c-wizard__nav--BackgroundColor);
  box-shadow: var(--pf-v5-c-wizard__nav--BoxShadow);
}

.pf-v5-c-wizard__nav.pf-m-expanded {
  display: block;
}

@media screen and (min-width: 992px) {
  .pf-v5-c-wizard__nav {
    display: block;
    height: 100%;
    border-inline-end: var(--pf-v5-c-wizard__nav--lg--BorderRightWidth) solid var(--pf-v5-c-wizard__nav--lg--BorderRightColor);
  }
}

.pf-v5-c-wizard__nav-list {
  padding-block-start: var(--pf-v5-c-wizard__nav-list--PaddingTop);
  padding-block-end: var(--pf-v5-c-wizard__nav-list--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-wizard__nav-list--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-wizard__nav-list--PaddingRight);
  list-style: none;
  counter-reset: wizard-nav-count;
}

.pf-v5-c-wizard__nav-list .pf-v5-c-wizard__nav-list {
  padding: 0;
  margin-block-start: var(--pf-v5-c-wizard__nav-list--nested--MarginTop);
  margin-inline-start: var(--pf-v5-c-wizard__nav-list--nested--MarginLeft);
}

.pf-v5-c-wizard__nav-list .pf-v5-c-wizard__nav-list .pf-v5-c-wizard__nav-link::before {
  content: none;
}

.pf-v5-c-wizard__nav-list .pf-v5-c-wizard__nav-list .pf-v5-c-wizard__nav-link.pf-m-current {
  font-weight: var(--pf-v5-c-wizard__nav-list__nav-list__nav-link--m-current--FontWeight);
}

.pf-v5-c-wizard__nav-item+.pf-v5-c-wizard__nav-item {
  margin-block-start: var(--pf-v5-c-wizard__nav-item--MarginTop);
}

.pf-v5-c-wizard__nav-item.pf-m-expandable>.pf-v5-c-wizard__nav-link {
  display: flex;
}

.pf-v5-c-wizard__nav-item.pf-m-expandable>.pf-v5-c-wizard__nav-list {
  display: none;
}

.pf-v5-c-wizard__nav-item.pf-m-expanded>.pf-v5-c-wizard__nav-list {
  display: block;
}

.pf-v5-c-wizard__nav-item.pf-m-expanded>.pf-v5-c-wizard__nav-link {
  --pf-v5-c-wizard__nav-link-toggle-icon--Rotate: var(--pf-v5-c-wizard__nav-item--m-expanded__link-toggle-icon--Rotate);
}

.pf-v5-c-wizard__nav-link {
  position: relative;
  display: inline-block;
  width: 100%;
  color: var(--pf-v5-c-wizard__nav-link--Color);
  text-align: start;
  text-decoration: var(--pf-v5-c-wizard__nav-link--TextDecoration);
  word-break: break-word;
  background-color: var(--pf-v5-c-wizard__nav-link--BackgroundColor);
  border: 0;
}

.pf-v5-c-wizard__toggle-num,
.pf-v5-c-wizard__nav-link::before {
  transform: translateX(var(--pf-v5-c-wizard__nav-link--before--TranslateX));
  position: absolute;
  inset-block-start: var(--pf-v5-c-wizard__nav-link--before--Top);
  inset-inline-start: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--pf-v5-c-wizard__nav-link--before--Width);
  height: var(--pf-v5-c-wizard__nav-link--before--Height);
  font-size: var(--pf-v5-c-wizard__nav-link--before--FontSize);
  line-height: 1;
  color: var(--pf-v5-c-wizard__nav-link--before--Color);
  background-color: var(--pf-v5-c-wizard__nav-link--before--BackgroundColor);
  border-radius: var(--pf-v5-c-wizard__nav-link--before--BorderRadius);
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-wizard__toggle-num,
.pf-v5-c-wizard__nav-link::before {
  transform: translateX(calc(var(--pf-v5-c-wizard__nav-link--before--TranslateX) * var(--pf-v5-global--inverse--multiplier)));
}

.pf-v5-c-wizard__nav-link::before {
  inset-block-start: 0;
  content: counter(wizard-nav-count);
  counter-increment: wizard-nav-count;
}

.pf-v5-c-wizard__nav-link:hover {
  --pf-v5-c-wizard__nav-link--Color: var(--pf-v5-c-wizard__nav-link--hover--Color);
  --pf-v5-c-wizard__nav-link-toggle--Color: var(--pf-v5-c-wizard__nav-link--hover__nav-link-toggle-icon--Color);
}

.pf-v5-c-wizard__nav-link:focus {
  --pf-v5-c-wizard__nav-link--Color: var(--pf-v5-c-wizard__nav-link--focus--Color);
  --pf-v5-c-wizard__nav-link-toggle--Color: var(--pf-v5-c-wizard__nav-link--focus__nav-link-toggle-icon--Color);
}

.pf-v5-c-wizard__nav-link.pf-m-current {
  --pf-v5-c-wizard__nav-link--Color: var(--pf-v5-c-wizard__nav-link--m-current--Color);
  font-weight: var(--pf-v5-c-wizard__nav-link--m-current--FontWeight);
}

.pf-v5-c-wizard__toggle-num,
.pf-v5-c-wizard__nav-link.pf-m-current::before {
  --pf-v5-c-wizard__nav-link--before--BackgroundColor: var(--pf-v5-c-wizard__nav-link--m-current--before--BackgroundColor);
  --pf-v5-c-wizard__nav-link--before--Color: var(--pf-v5-c-wizard__nav-link--m-current--before--Color);
}

.pf-v5-c-wizard__nav-link:disabled,
.pf-v5-c-wizard__nav-link.pf-m-disabled {
  --pf-v5-c-wizard__nav-link--Color: var(--pf-v5-c-wizard__nav-link--m-disabled--Color);
  pointer-events: none;
}

.pf-v5-c-wizard__nav-link:disabled::before,
.pf-v5-c-wizard__nav-link.pf-m-disabled::before {
  --pf-v5-c-wizard__nav-link--before--BackgroundColor: var(--pf-v5-c-wizard__nav-link--m-disabled--before--BackgroundColor);
  --pf-v5-c-wizard__nav-link--before--Color: var(--pf-v5-c-wizard__nav-link--m-disabled--before--Color);
}

.pf-v5-c-wizard__nav-link-text {
  flex-grow: 1;
}

.pf-v5-c-wizard__nav-link-toggle {
  padding-inline-start: var(--pf-v5-c-wizard__nav-link-toggle--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-wizard__nav-link-toggle--PaddingRight);
  color: var(--pf-v5-c-wizard__nav-link-toggle--Color);
}

.pf-v5-c-wizard__nav-link-toggle-icon {
  display: inline-block;
  transition: var(--pf-v5-c-wizard__nav-link-toggle-icon--Transition);
  transform: rotate(var(--pf-v5-c-wizard__nav-link-toggle-icon--Rotate));
}

:where(.pf-v5-m-dir-rtl, [dir="rtl"]) .pf-v5-c-wizard__nav-link-toggle-icon {
  scale: -1 1;
}

.pf-v5-c-wizard__main {
  z-index: var(--pf-v5-c-wizard__main--ZIndex);
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: auto;
  word-break: break-word;
}

.pf-v5-c-wizard__main-body {
  padding-block-start: var(--pf-v5-c-wizard__main-body--PaddingTop);
  padding-block-end: var(--pf-v5-c-wizard__main-body--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-wizard__main-body--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-wizard__main-body--PaddingRight);
}

.pf-v5-c-wizard__main-body.pf-m-no-padding {
  padding: 0;
}

.pf-v5-c-wizard__footer {
  position: relative;
  z-index: var(--pf-v5-c-wizard__footer--ZIndex);
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  padding-block-start: var(--pf-v5-c-wizard__footer--PaddingTop);
  padding-block-end: var(--pf-v5-c-wizard__footer--PaddingBottom);
  padding-inline-start: var(--pf-v5-c-wizard__footer--PaddingLeft);
  padding-inline-end: var(--pf-v5-c-wizard__footer--PaddingRight);
  background-color: var(--pf-v5-c-wizard__footer--BackgroundColor);
}

.pf-v5-c-wizard__footer>* {
  margin-block-end: var(--pf-v5-c-wizard__footer--child--MarginBottom);
}

.pf-v5-c-wizard__footer>*:not(:last-child) {
  margin-inline-end: var(--pf-v5-c-wizard__footer--child--MarginRight);
}

.pf-v5-c-page__main-wizard .pf-v5-c-wizard__footer,
.pf-v5-c-modal-box .pf-v5-c-wizard__footer,
.pf-v5-c-drawer>.pf-v5-c-wizard__footer {
  box-shadow: var(--pf-v5-c-wizard__footer--BoxShadow);
}

.pf-v5-c-wizard__footer-cancel {
  margin-inline-start: var(--pf-v5-c-wizard__footer-cancel--MarginLeft);
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard {
  --pf-v5-c-wizard__header--BackgroundColor: var(--pf-v5-global--palette--black-1000);
  --pf-v5-c-wizard__nav-link--before--BackgroundColor: var(--pf-v5-global--palette--black-500);
}

:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header {
  color: var(--pf-v5-global--Color--100);
}

.pf-v5-l-bullseye {
  --pf-v5-l-bullseye--Padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: var(--pf-v5-l-bullseye--Padding);
  margin: 0;
}

.pf-v5-l-flex {
  --pf-v5-l-flex--Display: flex;
  --pf-v5-l-flex--FlexWrap: wrap;
  --pf-v5-l-flex--AlignItems: baseline;
  --pf-v5-l-flex--m-row--AlignItems: baseline;
  --pf-v5-l-flex--m-row-reverse--AlignItems: baseline;
  --pf-v5-l-flex--item--Order: 0;
  --pf-v5-l-flex--spacer--column--base: var(--pf-v5-global--spacer--lg);
  --pf-v5-l-flex--spacer--row--base: var(--pf-v5-global--spacer--sm);
  --pf-v5-l-flex--RowGap: var(--pf-v5-l-flex--spacer--row--base);
  --pf-v5-l-flex--ColumnGap: 0;
  --pf-v5-l-flex--m-gap--RowGap: var(--pf-v5-l-flex--spacer--row--base);
  --pf-v5-l-flex--m-gap--ColumnGap: var(--pf-v5-l-flex--spacer--column--base);
  --pf-v5-l-flex--m-row-gap--RowGap: var(--pf-v5-l-flex--spacer--row--base);
  --pf-v5-l-flex--m-column-gap--ColumnGap: var(--pf-v5-l-flex--spacer--column--base);
  --pf-v5-l-flex--spacer--none: 0;
  --pf-v5-l-flex--spacer--xs: var(--pf-v5-global--spacer--xs);
  --pf-v5-l-flex--spacer--sm: var(--pf-v5-global--spacer--sm);
  --pf-v5-l-flex--spacer--md: var(--pf-v5-global--spacer--md);
  --pf-v5-l-flex--spacer--lg: var(--pf-v5-global--spacer--lg);
  --pf-v5-l-flex--spacer--xl: var(--pf-v5-global--spacer--xl);
  --pf-v5-l-flex--spacer--2xl: var(--pf-v5-global--spacer--2xl);
  --pf-v5-l-flex--spacer--3xl: var(--pf-v5-global--spacer--3xl);
  --pf-v5-l-flex--spacer--4xl: var(--pf-v5-global--spacer--4xl);
  display: var(--pf-v5-l-flex--Display);
  flex-wrap: var(--pf-v5-l-flex--FlexWrap);
  gap: var(--pf-v5-l-flex--RowGap) var(--pf-v5-l-flex--ColumnGap);
  align-items: var(--pf-v5-l-flex--AlignItems);
}

.pf-v5-l-flex>* {
  --pf-v5-l-flex--spacer: initial;
  --pf-v5-l-flex--spacer--column: var(--pf-v5-l-flex--spacer, var(--pf-v5-l-flex--spacer--column--base));
  --pf-v5-l-flex--spacer--row: var(--pf-v5-l-flex--spacer, var(--pf-v5-l-flex--spacer--row--base));
  order: var(--pf-v5-l-flex--item--Order);
  max-width: 100%;
  margin-inline-end: var(--pf-v5-l-flex--spacer--column);
}

@media screen and (min-width: 576px) {
  .pf-v5-l-flex>* {
    order: var(--pf-v5-l-flex--item--Order-on-sm, var(--pf-v5-l-flex--item--Order));
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-l-flex>* {
    order: var(--pf-v5-l-flex--item--Order-on-md, var(--pf-v5-l-flex--item--Order-on-sm, var(--pf-v5-l-flex--item--Order)));
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-l-flex>* {
    order: var(--pf-v5-l-flex--item--Order-on-lg, var(--pf-v5-l-flex--item--Order-on-md, var(--pf-v5-l-flex--item--Order-on-sm, var(--pf-v5-l-flex--item--Order))));
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-l-flex>* {
    order: var(--pf-v5-l-flex--item--Order-on-xl, var(--pf-v5-l-flex--item--Order-on-lg, var(--pf-v5-l-flex--item--Order-on-md, var(--pf-v5-l-flex--item--Order-on-sm, var(--pf-v5-l-flex--item--Order)))));
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-l-flex>* {
    order: var(--pf-v5-l-flex--item--Order-on-2xl, var(--pf-v5-l-flex--item--Order-on-xl, var(--pf-v5-l-flex--item--Order-on-lg, var(--pf-v5-l-flex--item--Order-on-md, var(--pf-v5-l-flex--item--Order-on-sm, var(--pf-v5-l-flex--item--Order))))));
  }
}

.pf-v5-l-flex>*:last-child {
  --pf-v5-l-flex--spacer: 0;
}

.pf-v5-l-flex.pf-m-flex {
  display: var(--pf-v5-l-flex--Display);
}

.pf-v5-l-flex.pf-m-inline-flex {
  --pf-v5-l-flex--Display: inline-flex;
}

.pf-v5-l-flex.pf-m-column {
  --pf-v5-l-flex--RowGap: 0;
  --pf-v5-l-flex--ColumnGap: var(--pf-v5-l-flex--spacer--column--base);
  flex-direction: column;
  align-items: normal;
}

.pf-v5-l-flex.pf-m-column>* {
  margin-block-start: 0;
  margin-block-end: var(--pf-v5-l-flex--spacer--row);
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.pf-v5-l-flex.pf-m-column-reverse {
  flex-direction: column-reverse;
  align-items: normal;
}

.pf-v5-l-flex.pf-m-column-reverse>* {
  margin-block-start: var(--pf-v5-l-flex--spacer--row);
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.pf-v5-l-flex.pf-m-row {
  flex-direction: row;
  align-items: var(--pf-v5-l-flex--m-row--AlignItems);
}

.pf-v5-l-flex.pf-m-row>* {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: var(--pf-v5-l-flex--spacer--column);
}

.pf-v5-l-flex.pf-m-row-reverse {
  flex-direction: row-reverse;
  align-items: var(--pf-v5-l-flex--m-row-reverse--AlignItems);
}

.pf-v5-l-flex.pf-m-row-reverse>* {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: var(--pf-v5-l-flex--spacer--column);
  margin-inline-end: 0;
}

.pf-v5-l-flex.pf-m-wrap {
  flex-wrap: wrap;
}

.pf-v5-l-flex.pf-m-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.pf-v5-l-flex.pf-m-nowrap {
  flex-wrap: nowrap;
}

.pf-v5-l-flex.pf-m-justify-content-flex-start {
  justify-content: flex-start;
}

.pf-v5-l-flex.pf-m-justify-content-flex-end {
  justify-content: flex-end;
}

.pf-v5-l-flex.pf-m-justify-content-center {
  justify-content: center;
}

.pf-v5-l-flex.pf-m-justify-content-space-between {
  justify-content: space-between;
}

.pf-v5-l-flex.pf-m-justify-content-space-around {
  justify-content: space-around;
}

.pf-v5-l-flex.pf-m-justify-content-space-evenly {
  justify-content: space-evenly;
}

.pf-v5-l-flex.pf-m-align-items-flex-start {
  align-items: flex-start;
}

.pf-v5-l-flex.pf-m-align-items-flex-end {
  align-items: flex-end;
}

.pf-v5-l-flex.pf-m-align-items-center {
  align-items: center;
}

.pf-v5-l-flex.pf-m-align-items-stretch {
  align-items: stretch;
}

.pf-v5-l-flex.pf-m-align-items-baseline {
  align-items: baseline;
}

.pf-v5-l-flex.pf-m-align-content-flex-start {
  align-content: flex-start;
}

.pf-v5-l-flex.pf-m-align-content-flex-end {
  align-content: flex-end;
}

.pf-v5-l-flex.pf-m-align-content-center {
  align-content: center;
}

.pf-v5-l-flex.pf-m-align-content-stretch {
  align-content: stretch;
}

.pf-v5-l-flex.pf-m-align-content-space-between {
  align-content: space-between;
}

.pf-v5-l-flex.pf-m-align-content-space-around {
  align-content: space-around;
}

.pf-v5-l-flex>.pf-m-align-right {
  margin-inline-start: auto;
}

.pf-v5-l-flex>.pf-m-align-left {
  margin-inline-start: 0;
}

.pf-v5-l-flex>.pf-m-grow {
  flex-grow: 1;
}

.pf-v5-l-flex>.pf-m-shrink {
  flex-shrink: 1;
}

.pf-v5-l-flex>.pf-m-full-width {
  width: 100%;
  margin-inline-end: 0;
}

.pf-v5-l-flex>.pf-m-flex-1 {
  flex: 1 0 0;
}

.pf-v5-l-flex>.pf-m-flex-2 {
  flex: 2 0 0;
}

.pf-v5-l-flex>.pf-m-flex-3 {
  flex: 3 0 0;
}

.pf-v5-l-flex>.pf-m-flex-4 {
  flex: 4 0 0;
}

.pf-v5-l-flex>.pf-m-flex-default {
  flex: 0 1 auto;
}

.pf-v5-l-flex>.pf-m-flex-none {
  flex: none;
}

.pf-v5-l-flex>.pf-m-align-self-flex-start {
  align-self: flex-start;
}

.pf-v5-l-flex>.pf-m-align-self-flex-end {
  align-self: flex-end;
}

.pf-v5-l-flex>.pf-m-align-self-center {
  align-self: center;
}

.pf-v5-l-flex>.pf-m-align-self-baseline {
  align-self: baseline;
}

.pf-v5-l-flex>.pf-m-align-self-stretch {
  align-self: stretch;
}

@media (min-width: 576px) {
  .pf-v5-l-flex.pf-m-flex-on-sm {
    display: var(--pf-v5-l-flex--Display);
  }

  .pf-v5-l-flex.pf-m-inline-flex-on-sm {
    --pf-v5-l-flex--Display: inline-flex;
  }

  .pf-v5-l-flex.pf-m-column-on-sm {
    --pf-v5-l-flex--RowGap: 0;
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-l-flex--spacer--column--base);
    flex-direction: column;
    align-items: normal;
  }

  .pf-v5-l-flex.pf-m-column-on-sm>* {
    margin-block-start: 0;
    margin-block-end: var(--pf-v5-l-flex--spacer--row);
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  .pf-v5-l-flex.pf-m-column-reverse-on-sm {
    flex-direction: column-reverse;
    align-items: normal;
  }

  .pf-v5-l-flex.pf-m-column-reverse-on-sm>* {
    margin-block-start: var(--pf-v5-l-flex--spacer--row);
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  .pf-v5-l-flex.pf-m-row-on-sm {
    flex-direction: row;
    align-items: var(--pf-v5-l-flex--m-row--AlignItems);
  }

  .pf-v5-l-flex.pf-m-row-on-sm>* {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: var(--pf-v5-l-flex--spacer--column);
  }

  .pf-v5-l-flex.pf-m-row-reverse-on-sm {
    flex-direction: row-reverse;
    align-items: var(--pf-v5-l-flex--m-row-reverse--AlignItems);
  }

  .pf-v5-l-flex.pf-m-row-reverse-on-sm>* {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: var(--pf-v5-l-flex--spacer--column);
    margin-inline-end: 0;
  }

  .pf-v5-l-flex.pf-m-wrap-on-sm {
    flex-wrap: wrap;
  }

  .pf-v5-l-flex.pf-m-wrap-reverse-on-sm {
    flex-wrap: wrap-reverse;
  }

  .pf-v5-l-flex.pf-m-nowrap-on-sm {
    flex-wrap: nowrap;
  }

  .pf-v5-l-flex.pf-m-justify-content-flex-start-on-sm {
    justify-content: flex-start;
  }

  .pf-v5-l-flex.pf-m-justify-content-flex-end-on-sm {
    justify-content: flex-end;
  }

  .pf-v5-l-flex.pf-m-justify-content-center-on-sm {
    justify-content: center;
  }

  .pf-v5-l-flex.pf-m-justify-content-space-between-on-sm {
    justify-content: space-between;
  }

  .pf-v5-l-flex.pf-m-justify-content-space-around-on-sm {
    justify-content: space-around;
  }

  .pf-v5-l-flex.pf-m-justify-content-space-evenly-on-sm {
    justify-content: space-evenly;
  }

  .pf-v5-l-flex.pf-m-align-items-flex-start-on-sm {
    align-items: flex-start;
  }

  .pf-v5-l-flex.pf-m-align-items-flex-end-on-sm {
    align-items: flex-end;
  }

  .pf-v5-l-flex.pf-m-align-items-center-on-sm {
    align-items: center;
  }

  .pf-v5-l-flex.pf-m-align-items-stretch-on-sm {
    align-items: stretch;
  }

  .pf-v5-l-flex.pf-m-align-items-baseline-on-sm {
    align-items: baseline;
  }

  .pf-v5-l-flex.pf-m-align-content-flex-start-on-sm {
    align-content: flex-start;
  }

  .pf-v5-l-flex.pf-m-align-content-flex-end-on-sm {
    align-content: flex-end;
  }

  .pf-v5-l-flex.pf-m-align-content-center-on-sm {
    align-content: center;
  }

  .pf-v5-l-flex.pf-m-align-content-stretch-on-sm {
    align-content: stretch;
  }

  .pf-v5-l-flex.pf-m-align-content-space-between-on-sm {
    align-content: space-between;
  }

  .pf-v5-l-flex.pf-m-align-content-space-around-on-sm {
    align-content: space-around;
  }

  .pf-v5-l-flex>.pf-m-align-right-on-sm {
    margin-inline-start: auto;
  }

  .pf-v5-l-flex>.pf-m-align-left-on-sm {
    margin-inline-start: 0;
  }

  .pf-v5-l-flex>.pf-m-grow-on-sm {
    flex-grow: 1;
  }

  .pf-v5-l-flex>.pf-m-shrink-on-sm {
    flex-shrink: 1;
  }

  .pf-v5-l-flex>.pf-m-full-width-on-sm {
    width: 100%;
    margin-inline-end: 0;
  }

  .pf-v5-l-flex>.pf-m-flex-1-on-sm {
    flex: 1 0 0;
  }

  .pf-v5-l-flex>.pf-m-flex-2-on-sm {
    flex: 2 0 0;
  }

  .pf-v5-l-flex>.pf-m-flex-3-on-sm {
    flex: 3 0 0;
  }

  .pf-v5-l-flex>.pf-m-flex-4-on-sm {
    flex: 4 0 0;
  }

  .pf-v5-l-flex>.pf-m-flex-default-on-sm {
    flex: 0 1 auto;
  }

  .pf-v5-l-flex>.pf-m-flex-none-on-sm {
    flex: none;
  }

  .pf-v5-l-flex>.pf-m-align-self-flex-start-on-sm {
    align-self: flex-start;
  }

  .pf-v5-l-flex>.pf-m-align-self-flex-end-on-sm {
    align-self: flex-end;
  }

  .pf-v5-l-flex>.pf-m-align-self-center-on-sm {
    align-self: center;
  }

  .pf-v5-l-flex>.pf-m-align-self-baseline-on-sm {
    align-self: baseline;
  }

  .pf-v5-l-flex>.pf-m-align-self-stretch-on-sm {
    align-self: stretch;
  }
}

@media (min-width: 768px) {
  .pf-v5-l-flex.pf-m-flex-on-md {
    display: var(--pf-v5-l-flex--Display);
  }

  .pf-v5-l-flex.pf-m-inline-flex-on-md {
    --pf-v5-l-flex--Display: inline-flex;
  }

  .pf-v5-l-flex.pf-m-column-on-md {
    --pf-v5-l-flex--RowGap: 0;
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-l-flex--spacer--column--base);
    flex-direction: column;
    align-items: normal;
  }

  .pf-v5-l-flex.pf-m-column-on-md>* {
    margin-block-start: 0;
    margin-block-end: var(--pf-v5-l-flex--spacer--row);
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  .pf-v5-l-flex.pf-m-column-reverse-on-md {
    flex-direction: column-reverse;
    align-items: normal;
  }

  .pf-v5-l-flex.pf-m-column-reverse-on-md>* {
    margin-block-start: var(--pf-v5-l-flex--spacer--row);
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  .pf-v5-l-flex.pf-m-row-on-md {
    flex-direction: row;
    align-items: var(--pf-v5-l-flex--m-row--AlignItems);
  }

  .pf-v5-l-flex.pf-m-row-on-md>* {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: var(--pf-v5-l-flex--spacer--column);
  }

  .pf-v5-l-flex.pf-m-row-reverse-on-md {
    flex-direction: row-reverse;
    align-items: var(--pf-v5-l-flex--m-row-reverse--AlignItems);
  }

  .pf-v5-l-flex.pf-m-row-reverse-on-md>* {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: var(--pf-v5-l-flex--spacer--column);
    margin-inline-end: 0;
  }

  .pf-v5-l-flex.pf-m-wrap-on-md {
    flex-wrap: wrap;
  }

  .pf-v5-l-flex.pf-m-wrap-reverse-on-md {
    flex-wrap: wrap-reverse;
  }

  .pf-v5-l-flex.pf-m-nowrap-on-md {
    flex-wrap: nowrap;
  }

  .pf-v5-l-flex.pf-m-justify-content-flex-start-on-md {
    justify-content: flex-start;
  }

  .pf-v5-l-flex.pf-m-justify-content-flex-end-on-md {
    justify-content: flex-end;
  }

  .pf-v5-l-flex.pf-m-justify-content-center-on-md {
    justify-content: center;
  }

  .pf-v5-l-flex.pf-m-justify-content-space-between-on-md {
    justify-content: space-between;
  }

  .pf-v5-l-flex.pf-m-justify-content-space-around-on-md {
    justify-content: space-around;
  }

  .pf-v5-l-flex.pf-m-justify-content-space-evenly-on-md {
    justify-content: space-evenly;
  }

  .pf-v5-l-flex.pf-m-align-items-flex-start-on-md {
    align-items: flex-start;
  }

  .pf-v5-l-flex.pf-m-align-items-flex-end-on-md {
    align-items: flex-end;
  }

  .pf-v5-l-flex.pf-m-align-items-center-on-md {
    align-items: center;
  }

  .pf-v5-l-flex.pf-m-align-items-stretch-on-md {
    align-items: stretch;
  }

  .pf-v5-l-flex.pf-m-align-items-baseline-on-md {
    align-items: baseline;
  }

  .pf-v5-l-flex.pf-m-align-content-flex-start-on-md {
    align-content: flex-start;
  }

  .pf-v5-l-flex.pf-m-align-content-flex-end-on-md {
    align-content: flex-end;
  }

  .pf-v5-l-flex.pf-m-align-content-center-on-md {
    align-content: center;
  }

  .pf-v5-l-flex.pf-m-align-content-stretch-on-md {
    align-content: stretch;
  }

  .pf-v5-l-flex.pf-m-align-content-space-between-on-md {
    align-content: space-between;
  }

  .pf-v5-l-flex.pf-m-align-content-space-around-on-md {
    align-content: space-around;
  }

  .pf-v5-l-flex>.pf-m-align-right-on-md {
    margin-inline-start: auto;
  }

  .pf-v5-l-flex>.pf-m-align-left-on-md {
    margin-inline-start: 0;
  }

  .pf-v5-l-flex>.pf-m-grow-on-md {
    flex-grow: 1;
  }

  .pf-v5-l-flex>.pf-m-shrink-on-md {
    flex-shrink: 1;
  }

  .pf-v5-l-flex>.pf-m-full-width-on-md {
    width: 100%;
    margin-inline-end: 0;
  }

  .pf-v5-l-flex>.pf-m-flex-1-on-md {
    flex: 1 0 0;
  }

  .pf-v5-l-flex>.pf-m-flex-2-on-md {
    flex: 2 0 0;
  }

  .pf-v5-l-flex>.pf-m-flex-3-on-md {
    flex: 3 0 0;
  }

  .pf-v5-l-flex>.pf-m-flex-4-on-md {
    flex: 4 0 0;
  }

  .pf-v5-l-flex>.pf-m-flex-default-on-md {
    flex: 0 1 auto;
  }

  .pf-v5-l-flex>.pf-m-flex-none-on-md {
    flex: none;
  }

  .pf-v5-l-flex>.pf-m-align-self-flex-start-on-md {
    align-self: flex-start;
  }

  .pf-v5-l-flex>.pf-m-align-self-flex-end-on-md {
    align-self: flex-end;
  }

  .pf-v5-l-flex>.pf-m-align-self-center-on-md {
    align-self: center;
  }

  .pf-v5-l-flex>.pf-m-align-self-baseline-on-md {
    align-self: baseline;
  }

  .pf-v5-l-flex>.pf-m-align-self-stretch-on-md {
    align-self: stretch;
  }
}

@media (min-width: 992px) {
  .pf-v5-l-flex.pf-m-flex-on-lg {
    display: var(--pf-v5-l-flex--Display);
  }

  .pf-v5-l-flex.pf-m-inline-flex-on-lg {
    --pf-v5-l-flex--Display: inline-flex;
  }

  .pf-v5-l-flex.pf-m-column-on-lg {
    --pf-v5-l-flex--RowGap: 0;
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-l-flex--spacer--column--base);
    flex-direction: column;
    align-items: normal;
  }

  .pf-v5-l-flex.pf-m-column-on-lg>* {
    margin-block-start: 0;
    margin-block-end: var(--pf-v5-l-flex--spacer--row);
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  .pf-v5-l-flex.pf-m-column-reverse-on-lg {
    flex-direction: column-reverse;
    align-items: normal;
  }

  .pf-v5-l-flex.pf-m-column-reverse-on-lg>* {
    margin-block-start: var(--pf-v5-l-flex--spacer--row);
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  .pf-v5-l-flex.pf-m-row-on-lg {
    flex-direction: row;
    align-items: var(--pf-v5-l-flex--m-row--AlignItems);
  }

  .pf-v5-l-flex.pf-m-row-on-lg>* {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: var(--pf-v5-l-flex--spacer--column);
  }

  .pf-v5-l-flex.pf-m-row-reverse-on-lg {
    flex-direction: row-reverse;
    align-items: var(--pf-v5-l-flex--m-row-reverse--AlignItems);
  }

  .pf-v5-l-flex.pf-m-row-reverse-on-lg>* {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: var(--pf-v5-l-flex--spacer--column);
    margin-inline-end: 0;
  }

  .pf-v5-l-flex.pf-m-wrap-on-lg {
    flex-wrap: wrap;
  }

  .pf-v5-l-flex.pf-m-wrap-reverse-on-lg {
    flex-wrap: wrap-reverse;
  }

  .pf-v5-l-flex.pf-m-nowrap-on-lg {
    flex-wrap: nowrap;
  }

  .pf-v5-l-flex.pf-m-justify-content-flex-start-on-lg {
    justify-content: flex-start;
  }

  .pf-v5-l-flex.pf-m-justify-content-flex-end-on-lg {
    justify-content: flex-end;
  }

  .pf-v5-l-flex.pf-m-justify-content-center-on-lg {
    justify-content: center;
  }

  .pf-v5-l-flex.pf-m-justify-content-space-between-on-lg {
    justify-content: space-between;
  }

  .pf-v5-l-flex.pf-m-justify-content-space-around-on-lg {
    justify-content: space-around;
  }

  .pf-v5-l-flex.pf-m-justify-content-space-evenly-on-lg {
    justify-content: space-evenly;
  }

  .pf-v5-l-flex.pf-m-align-items-flex-start-on-lg {
    align-items: flex-start;
  }

  .pf-v5-l-flex.pf-m-align-items-flex-end-on-lg {
    align-items: flex-end;
  }

  .pf-v5-l-flex.pf-m-align-items-center-on-lg {
    align-items: center;
  }

  .pf-v5-l-flex.pf-m-align-items-stretch-on-lg {
    align-items: stretch;
  }

  .pf-v5-l-flex.pf-m-align-items-baseline-on-lg {
    align-items: baseline;
  }

  .pf-v5-l-flex.pf-m-align-content-flex-start-on-lg {
    align-content: flex-start;
  }

  .pf-v5-l-flex.pf-m-align-content-flex-end-on-lg {
    align-content: flex-end;
  }

  .pf-v5-l-flex.pf-m-align-content-center-on-lg {
    align-content: center;
  }

  .pf-v5-l-flex.pf-m-align-content-stretch-on-lg {
    align-content: stretch;
  }

  .pf-v5-l-flex.pf-m-align-content-space-between-on-lg {
    align-content: space-between;
  }

  .pf-v5-l-flex.pf-m-align-content-space-around-on-lg {
    align-content: space-around;
  }

  .pf-v5-l-flex>.pf-m-align-right-on-lg {
    margin-inline-start: auto;
  }

  .pf-v5-l-flex>.pf-m-align-left-on-lg {
    margin-inline-start: 0;
  }

  .pf-v5-l-flex>.pf-m-grow-on-lg {
    flex-grow: 1;
  }

  .pf-v5-l-flex>.pf-m-shrink-on-lg {
    flex-shrink: 1;
  }

  .pf-v5-l-flex>.pf-m-full-width-on-lg {
    width: 100%;
    margin-inline-end: 0;
  }

  .pf-v5-l-flex>.pf-m-flex-1-on-lg {
    flex: 1 0 0;
  }

  .pf-v5-l-flex>.pf-m-flex-2-on-lg {
    flex: 2 0 0;
  }

  .pf-v5-l-flex>.pf-m-flex-3-on-lg {
    flex: 3 0 0;
  }

  .pf-v5-l-flex>.pf-m-flex-4-on-lg {
    flex: 4 0 0;
  }

  .pf-v5-l-flex>.pf-m-flex-default-on-lg {
    flex: 0 1 auto;
  }

  .pf-v5-l-flex>.pf-m-flex-none-on-lg {
    flex: none;
  }

  .pf-v5-l-flex>.pf-m-align-self-flex-start-on-lg {
    align-self: flex-start;
  }

  .pf-v5-l-flex>.pf-m-align-self-flex-end-on-lg {
    align-self: flex-end;
  }

  .pf-v5-l-flex>.pf-m-align-self-center-on-lg {
    align-self: center;
  }

  .pf-v5-l-flex>.pf-m-align-self-baseline-on-lg {
    align-self: baseline;
  }

  .pf-v5-l-flex>.pf-m-align-self-stretch-on-lg {
    align-self: stretch;
  }
}

@media (min-width: 1200px) {
  .pf-v5-l-flex.pf-m-flex-on-xl {
    display: var(--pf-v5-l-flex--Display);
  }

  .pf-v5-l-flex.pf-m-inline-flex-on-xl {
    --pf-v5-l-flex--Display: inline-flex;
  }

  .pf-v5-l-flex.pf-m-column-on-xl {
    --pf-v5-l-flex--RowGap: 0;
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-l-flex--spacer--column--base);
    flex-direction: column;
    align-items: normal;
  }

  .pf-v5-l-flex.pf-m-column-on-xl>* {
    margin-block-start: 0;
    margin-block-end: var(--pf-v5-l-flex--spacer--row);
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  .pf-v5-l-flex.pf-m-column-reverse-on-xl {
    flex-direction: column-reverse;
    align-items: normal;
  }

  .pf-v5-l-flex.pf-m-column-reverse-on-xl>* {
    margin-block-start: var(--pf-v5-l-flex--spacer--row);
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  .pf-v5-l-flex.pf-m-row-on-xl {
    flex-direction: row;
    align-items: var(--pf-v5-l-flex--m-row--AlignItems);
  }

  .pf-v5-l-flex.pf-m-row-on-xl>* {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: var(--pf-v5-l-flex--spacer--column);
  }

  .pf-v5-l-flex.pf-m-row-reverse-on-xl {
    flex-direction: row-reverse;
    align-items: var(--pf-v5-l-flex--m-row-reverse--AlignItems);
  }

  .pf-v5-l-flex.pf-m-row-reverse-on-xl>* {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: var(--pf-v5-l-flex--spacer--column);
    margin-inline-end: 0;
  }

  .pf-v5-l-flex.pf-m-wrap-on-xl {
    flex-wrap: wrap;
  }

  .pf-v5-l-flex.pf-m-wrap-reverse-on-xl {
    flex-wrap: wrap-reverse;
  }

  .pf-v5-l-flex.pf-m-nowrap-on-xl {
    flex-wrap: nowrap;
  }

  .pf-v5-l-flex.pf-m-justify-content-flex-start-on-xl {
    justify-content: flex-start;
  }

  .pf-v5-l-flex.pf-m-justify-content-flex-end-on-xl {
    justify-content: flex-end;
  }

  .pf-v5-l-flex.pf-m-justify-content-center-on-xl {
    justify-content: center;
  }

  .pf-v5-l-flex.pf-m-justify-content-space-between-on-xl {
    justify-content: space-between;
  }

  .pf-v5-l-flex.pf-m-justify-content-space-around-on-xl {
    justify-content: space-around;
  }

  .pf-v5-l-flex.pf-m-justify-content-space-evenly-on-xl {
    justify-content: space-evenly;
  }

  .pf-v5-l-flex.pf-m-align-items-flex-start-on-xl {
    align-items: flex-start;
  }

  .pf-v5-l-flex.pf-m-align-items-flex-end-on-xl {
    align-items: flex-end;
  }

  .pf-v5-l-flex.pf-m-align-items-center-on-xl {
    align-items: center;
  }

  .pf-v5-l-flex.pf-m-align-items-stretch-on-xl {
    align-items: stretch;
  }

  .pf-v5-l-flex.pf-m-align-items-baseline-on-xl {
    align-items: baseline;
  }

  .pf-v5-l-flex.pf-m-align-content-flex-start-on-xl {
    align-content: flex-start;
  }

  .pf-v5-l-flex.pf-m-align-content-flex-end-on-xl {
    align-content: flex-end;
  }

  .pf-v5-l-flex.pf-m-align-content-center-on-xl {
    align-content: center;
  }

  .pf-v5-l-flex.pf-m-align-content-stretch-on-xl {
    align-content: stretch;
  }

  .pf-v5-l-flex.pf-m-align-content-space-between-on-xl {
    align-content: space-between;
  }

  .pf-v5-l-flex.pf-m-align-content-space-around-on-xl {
    align-content: space-around;
  }

  .pf-v5-l-flex>.pf-m-align-right-on-xl {
    margin-inline-start: auto;
  }

  .pf-v5-l-flex>.pf-m-align-left-on-xl {
    margin-inline-start: 0;
  }

  .pf-v5-l-flex>.pf-m-grow-on-xl {
    flex-grow: 1;
  }

  .pf-v5-l-flex>.pf-m-shrink-on-xl {
    flex-shrink: 1;
  }

  .pf-v5-l-flex>.pf-m-full-width-on-xl {
    width: 100%;
    margin-inline-end: 0;
  }

  .pf-v5-l-flex>.pf-m-flex-1-on-xl {
    flex: 1 0 0;
  }

  .pf-v5-l-flex>.pf-m-flex-2-on-xl {
    flex: 2 0 0;
  }

  .pf-v5-l-flex>.pf-m-flex-3-on-xl {
    flex: 3 0 0;
  }

  .pf-v5-l-flex>.pf-m-flex-4-on-xl {
    flex: 4 0 0;
  }

  .pf-v5-l-flex>.pf-m-flex-default-on-xl {
    flex: 0 1 auto;
  }

  .pf-v5-l-flex>.pf-m-flex-none-on-xl {
    flex: none;
  }

  .pf-v5-l-flex>.pf-m-align-self-flex-start-on-xl {
    align-self: flex-start;
  }

  .pf-v5-l-flex>.pf-m-align-self-flex-end-on-xl {
    align-self: flex-end;
  }

  .pf-v5-l-flex>.pf-m-align-self-center-on-xl {
    align-self: center;
  }

  .pf-v5-l-flex>.pf-m-align-self-baseline-on-xl {
    align-self: baseline;
  }

  .pf-v5-l-flex>.pf-m-align-self-stretch-on-xl {
    align-self: stretch;
  }
}

@media (min-width: 1450px) {
  .pf-v5-l-flex.pf-m-flex-on-2xl {
    display: var(--pf-v5-l-flex--Display);
  }

  .pf-v5-l-flex.pf-m-inline-flex-on-2xl {
    --pf-v5-l-flex--Display: inline-flex;
  }

  .pf-v5-l-flex.pf-m-column-on-2xl {
    --pf-v5-l-flex--RowGap: 0;
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-l-flex--spacer--column--base);
    flex-direction: column;
    align-items: normal;
  }

  .pf-v5-l-flex.pf-m-column-on-2xl>* {
    margin-block-start: 0;
    margin-block-end: var(--pf-v5-l-flex--spacer--row);
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  .pf-v5-l-flex.pf-m-column-reverse-on-2xl {
    flex-direction: column-reverse;
    align-items: normal;
  }

  .pf-v5-l-flex.pf-m-column-reverse-on-2xl>* {
    margin-block-start: var(--pf-v5-l-flex--spacer--row);
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  .pf-v5-l-flex.pf-m-row-on-2xl {
    flex-direction: row;
    align-items: var(--pf-v5-l-flex--m-row--AlignItems);
  }

  .pf-v5-l-flex.pf-m-row-on-2xl>* {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: var(--pf-v5-l-flex--spacer--column);
  }

  .pf-v5-l-flex.pf-m-row-reverse-on-2xl {
    flex-direction: row-reverse;
    align-items: var(--pf-v5-l-flex--m-row-reverse--AlignItems);
  }

  .pf-v5-l-flex.pf-m-row-reverse-on-2xl>* {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: var(--pf-v5-l-flex--spacer--column);
    margin-inline-end: 0;
  }

  .pf-v5-l-flex.pf-m-wrap-on-2xl {
    flex-wrap: wrap;
  }

  .pf-v5-l-flex.pf-m-wrap-reverse-on-2xl {
    flex-wrap: wrap-reverse;
  }

  .pf-v5-l-flex.pf-m-nowrap-on-2xl {
    flex-wrap: nowrap;
  }

  .pf-v5-l-flex.pf-m-justify-content-flex-start-on-2xl {
    justify-content: flex-start;
  }

  .pf-v5-l-flex.pf-m-justify-content-flex-end-on-2xl {
    justify-content: flex-end;
  }

  .pf-v5-l-flex.pf-m-justify-content-center-on-2xl {
    justify-content: center;
  }

  .pf-v5-l-flex.pf-m-justify-content-space-between-on-2xl {
    justify-content: space-between;
  }

  .pf-v5-l-flex.pf-m-justify-content-space-around-on-2xl {
    justify-content: space-around;
  }

  .pf-v5-l-flex.pf-m-justify-content-space-evenly-on-2xl {
    justify-content: space-evenly;
  }

  .pf-v5-l-flex.pf-m-align-items-flex-start-on-2xl {
    align-items: flex-start;
  }

  .pf-v5-l-flex.pf-m-align-items-flex-end-on-2xl {
    align-items: flex-end;
  }

  .pf-v5-l-flex.pf-m-align-items-center-on-2xl {
    align-items: center;
  }

  .pf-v5-l-flex.pf-m-align-items-stretch-on-2xl {
    align-items: stretch;
  }

  .pf-v5-l-flex.pf-m-align-items-baseline-on-2xl {
    align-items: baseline;
  }

  .pf-v5-l-flex.pf-m-align-content-flex-start-on-2xl {
    align-content: flex-start;
  }

  .pf-v5-l-flex.pf-m-align-content-flex-end-on-2xl {
    align-content: flex-end;
  }

  .pf-v5-l-flex.pf-m-align-content-center-on-2xl {
    align-content: center;
  }

  .pf-v5-l-flex.pf-m-align-content-stretch-on-2xl {
    align-content: stretch;
  }

  .pf-v5-l-flex.pf-m-align-content-space-between-on-2xl {
    align-content: space-between;
  }

  .pf-v5-l-flex.pf-m-align-content-space-around-on-2xl {
    align-content: space-around;
  }

  .pf-v5-l-flex>.pf-m-align-right-on-2xl {
    margin-inline-start: auto;
  }

  .pf-v5-l-flex>.pf-m-align-left-on-2xl {
    margin-inline-start: 0;
  }

  .pf-v5-l-flex>.pf-m-grow-on-2xl {
    flex-grow: 1;
  }

  .pf-v5-l-flex>.pf-m-shrink-on-2xl {
    flex-shrink: 1;
  }

  .pf-v5-l-flex>.pf-m-full-width-on-2xl {
    width: 100%;
    margin-inline-end: 0;
  }

  .pf-v5-l-flex>.pf-m-flex-1-on-2xl {
    flex: 1 0 0;
  }

  .pf-v5-l-flex>.pf-m-flex-2-on-2xl {
    flex: 2 0 0;
  }

  .pf-v5-l-flex>.pf-m-flex-3-on-2xl {
    flex: 3 0 0;
  }

  .pf-v5-l-flex>.pf-m-flex-4-on-2xl {
    flex: 4 0 0;
  }

  .pf-v5-l-flex>.pf-m-flex-default-on-2xl {
    flex: 0 1 auto;
  }

  .pf-v5-l-flex>.pf-m-flex-none-on-2xl {
    flex: none;
  }

  .pf-v5-l-flex>.pf-m-align-self-flex-start-on-2xl {
    align-self: flex-start;
  }

  .pf-v5-l-flex>.pf-m-align-self-flex-end-on-2xl {
    align-self: flex-end;
  }

  .pf-v5-l-flex>.pf-m-align-self-center-on-2xl {
    align-self: center;
  }

  .pf-v5-l-flex>.pf-m-align-self-baseline-on-2xl {
    align-self: baseline;
  }

  .pf-v5-l-flex>.pf-m-align-self-stretch-on-2xl {
    align-self: stretch;
  }
}

.pf-v5-l-flex.pf-m-space-items-none>* {
  --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--none);
}

.pf-v5-l-flex.pf-m-space-items-none>:last-child {
  --pf-v5-l-flex--spacer: 0;
}

.pf-v5-l-flex.pf-m-space-items-xs>* {
  --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--xs);
}

.pf-v5-l-flex.pf-m-space-items-xs>:last-child {
  --pf-v5-l-flex--spacer: 0;
}

.pf-v5-l-flex.pf-m-space-items-sm>* {
  --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--sm);
}

.pf-v5-l-flex.pf-m-space-items-sm>:last-child {
  --pf-v5-l-flex--spacer: 0;
}

.pf-v5-l-flex.pf-m-space-items-md>* {
  --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--md);
}

.pf-v5-l-flex.pf-m-space-items-md>:last-child {
  --pf-v5-l-flex--spacer: 0;
}

.pf-v5-l-flex.pf-m-space-items-lg>* {
  --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--lg);
}

.pf-v5-l-flex.pf-m-space-items-lg>:last-child {
  --pf-v5-l-flex--spacer: 0;
}

.pf-v5-l-flex.pf-m-space-items-xl>* {
  --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--xl);
}

.pf-v5-l-flex.pf-m-space-items-xl>:last-child {
  --pf-v5-l-flex--spacer: 0;
}

.pf-v5-l-flex.pf-m-space-items-2xl>* {
  --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--2xl);
}

.pf-v5-l-flex.pf-m-space-items-2xl>:last-child {
  --pf-v5-l-flex--spacer: 0;
}

.pf-v5-l-flex.pf-m-space-items-3xl>* {
  --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--3xl);
}

.pf-v5-l-flex.pf-m-space-items-3xl>:last-child {
  --pf-v5-l-flex--spacer: 0;
}

.pf-v5-l-flex.pf-m-space-items-4xl>* {
  --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--4xl);
}

.pf-v5-l-flex.pf-m-space-items-4xl>:last-child {
  --pf-v5-l-flex--spacer: 0;
}

@media (min-width: 576px) {
  .pf-v5-l-flex.pf-m-space-items-none-on-sm>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--none);
  }

  .pf-v5-l-flex.pf-m-space-items-none-on-sm>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-xs-on-sm>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--xs);
  }

  .pf-v5-l-flex.pf-m-space-items-xs-on-sm>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-sm-on-sm>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--sm);
  }

  .pf-v5-l-flex.pf-m-space-items-sm-on-sm>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-md-on-sm>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--md);
  }

  .pf-v5-l-flex.pf-m-space-items-md-on-sm>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-lg-on-sm>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--lg);
  }

  .pf-v5-l-flex.pf-m-space-items-lg-on-sm>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-xl-on-sm>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--xl);
  }

  .pf-v5-l-flex.pf-m-space-items-xl-on-sm>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-2xl-on-sm>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--2xl);
  }

  .pf-v5-l-flex.pf-m-space-items-2xl-on-sm>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-3xl-on-sm>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--3xl);
  }

  .pf-v5-l-flex.pf-m-space-items-3xl-on-sm>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-4xl-on-sm>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--4xl);
  }

  .pf-v5-l-flex.pf-m-space-items-4xl-on-sm>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }
}

@media (min-width: 768px) {
  .pf-v5-l-flex.pf-m-space-items-none-on-md>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--none);
  }

  .pf-v5-l-flex.pf-m-space-items-none-on-md>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-xs-on-md>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--xs);
  }

  .pf-v5-l-flex.pf-m-space-items-xs-on-md>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-sm-on-md>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--sm);
  }

  .pf-v5-l-flex.pf-m-space-items-sm-on-md>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-md-on-md>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--md);
  }

  .pf-v5-l-flex.pf-m-space-items-md-on-md>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-lg-on-md>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--lg);
  }

  .pf-v5-l-flex.pf-m-space-items-lg-on-md>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-xl-on-md>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--xl);
  }

  .pf-v5-l-flex.pf-m-space-items-xl-on-md>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-2xl-on-md>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--2xl);
  }

  .pf-v5-l-flex.pf-m-space-items-2xl-on-md>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-3xl-on-md>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--3xl);
  }

  .pf-v5-l-flex.pf-m-space-items-3xl-on-md>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-4xl-on-md>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--4xl);
  }

  .pf-v5-l-flex.pf-m-space-items-4xl-on-md>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }
}

@media (min-width: 992px) {
  .pf-v5-l-flex.pf-m-space-items-none-on-lg>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--none);
  }

  .pf-v5-l-flex.pf-m-space-items-none-on-lg>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-xs-on-lg>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--xs);
  }

  .pf-v5-l-flex.pf-m-space-items-xs-on-lg>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-sm-on-lg>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--sm);
  }

  .pf-v5-l-flex.pf-m-space-items-sm-on-lg>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-md-on-lg>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--md);
  }

  .pf-v5-l-flex.pf-m-space-items-md-on-lg>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-lg-on-lg>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--lg);
  }

  .pf-v5-l-flex.pf-m-space-items-lg-on-lg>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-xl-on-lg>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--xl);
  }

  .pf-v5-l-flex.pf-m-space-items-xl-on-lg>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-2xl-on-lg>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--2xl);
  }

  .pf-v5-l-flex.pf-m-space-items-2xl-on-lg>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-3xl-on-lg>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--3xl);
  }

  .pf-v5-l-flex.pf-m-space-items-3xl-on-lg>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-4xl-on-lg>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--4xl);
  }

  .pf-v5-l-flex.pf-m-space-items-4xl-on-lg>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }
}

@media (min-width: 1200px) {
  .pf-v5-l-flex.pf-m-space-items-none-on-xl>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--none);
  }

  .pf-v5-l-flex.pf-m-space-items-none-on-xl>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-xs-on-xl>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--xs);
  }

  .pf-v5-l-flex.pf-m-space-items-xs-on-xl>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-sm-on-xl>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--sm);
  }

  .pf-v5-l-flex.pf-m-space-items-sm-on-xl>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-md-on-xl>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--md);
  }

  .pf-v5-l-flex.pf-m-space-items-md-on-xl>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-lg-on-xl>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--lg);
  }

  .pf-v5-l-flex.pf-m-space-items-lg-on-xl>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-xl-on-xl>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--xl);
  }

  .pf-v5-l-flex.pf-m-space-items-xl-on-xl>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-2xl-on-xl>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--2xl);
  }

  .pf-v5-l-flex.pf-m-space-items-2xl-on-xl>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-3xl-on-xl>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--3xl);
  }

  .pf-v5-l-flex.pf-m-space-items-3xl-on-xl>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-4xl-on-xl>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--4xl);
  }

  .pf-v5-l-flex.pf-m-space-items-4xl-on-xl>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }
}

@media (min-width: 1450px) {
  .pf-v5-l-flex.pf-m-space-items-none-on-2xl>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--none);
  }

  .pf-v5-l-flex.pf-m-space-items-none-on-2xl>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-xs-on-2xl>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--xs);
  }

  .pf-v5-l-flex.pf-m-space-items-xs-on-2xl>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-sm-on-2xl>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--sm);
  }

  .pf-v5-l-flex.pf-m-space-items-sm-on-2xl>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-md-on-2xl>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--md);
  }

  .pf-v5-l-flex.pf-m-space-items-md-on-2xl>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-lg-on-2xl>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--lg);
  }

  .pf-v5-l-flex.pf-m-space-items-lg-on-2xl>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-xl-on-2xl>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--xl);
  }

  .pf-v5-l-flex.pf-m-space-items-xl-on-2xl>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-2xl-on-2xl>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--2xl);
  }

  .pf-v5-l-flex.pf-m-space-items-2xl-on-2xl>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-3xl-on-2xl>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--3xl);
  }

  .pf-v5-l-flex.pf-m-space-items-3xl-on-2xl>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }

  .pf-v5-l-flex.pf-m-space-items-4xl-on-2xl>* {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--4xl);
  }

  .pf-v5-l-flex.pf-m-space-items-4xl-on-2xl>:last-child {
    --pf-v5-l-flex--spacer: 0;
  }
}

.pf-v5-l-flex .pf-m-spacer-none {
  --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--none);
}

.pf-v5-l-flex .pf-m-spacer-none:last-child {
  --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--none);
}

.pf-v5-l-flex .pf-m-spacer-xs {
  --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--xs);
}

.pf-v5-l-flex .pf-m-spacer-xs:last-child {
  --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--xs);
}

.pf-v5-l-flex .pf-m-spacer-sm {
  --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--sm);
}

.pf-v5-l-flex .pf-m-spacer-sm:last-child {
  --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--sm);
}

.pf-v5-l-flex .pf-m-spacer-md {
  --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--md);
}

.pf-v5-l-flex .pf-m-spacer-md:last-child {
  --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--md);
}

.pf-v5-l-flex .pf-m-spacer-lg {
  --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--lg);
}

.pf-v5-l-flex .pf-m-spacer-lg:last-child {
  --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--lg);
}

.pf-v5-l-flex .pf-m-spacer-xl {
  --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--xl);
}

.pf-v5-l-flex .pf-m-spacer-xl:last-child {
  --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--xl);
}

.pf-v5-l-flex .pf-m-spacer-2xl {
  --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--2xl);
}

.pf-v5-l-flex .pf-m-spacer-2xl:last-child {
  --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--2xl);
}

.pf-v5-l-flex .pf-m-spacer-3xl {
  --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--3xl);
}

.pf-v5-l-flex .pf-m-spacer-3xl:last-child {
  --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--3xl);
}

.pf-v5-l-flex .pf-m-spacer-4xl {
  --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--4xl);
}

.pf-v5-l-flex .pf-m-spacer-4xl:last-child {
  --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--4xl);
}

@media (min-width: 576px) {
  .pf-v5-l-flex .pf-m-spacer-none-on-sm {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--none);
  }

  .pf-v5-l-flex .pf-m-spacer-none-on-sm:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--none);
  }

  .pf-v5-l-flex .pf-m-spacer-xs-on-sm {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--xs);
  }

  .pf-v5-l-flex .pf-m-spacer-xs-on-sm:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--xs);
  }

  .pf-v5-l-flex .pf-m-spacer-sm-on-sm {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--sm);
  }

  .pf-v5-l-flex .pf-m-spacer-sm-on-sm:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--sm);
  }

  .pf-v5-l-flex .pf-m-spacer-md-on-sm {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--md);
  }

  .pf-v5-l-flex .pf-m-spacer-md-on-sm:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--md);
  }

  .pf-v5-l-flex .pf-m-spacer-lg-on-sm {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--lg);
  }

  .pf-v5-l-flex .pf-m-spacer-lg-on-sm:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--lg);
  }

  .pf-v5-l-flex .pf-m-spacer-xl-on-sm {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--xl);
  }

  .pf-v5-l-flex .pf-m-spacer-xl-on-sm:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--xl);
  }

  .pf-v5-l-flex .pf-m-spacer-2xl-on-sm {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--2xl);
  }

  .pf-v5-l-flex .pf-m-spacer-2xl-on-sm:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--2xl);
  }

  .pf-v5-l-flex .pf-m-spacer-3xl-on-sm {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--3xl);
  }

  .pf-v5-l-flex .pf-m-spacer-3xl-on-sm:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--3xl);
  }

  .pf-v5-l-flex .pf-m-spacer-4xl-on-sm {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--4xl);
  }

  .pf-v5-l-flex .pf-m-spacer-4xl-on-sm:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--4xl);
  }
}

@media (min-width: 768px) {
  .pf-v5-l-flex .pf-m-spacer-none-on-md {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--none);
  }

  .pf-v5-l-flex .pf-m-spacer-none-on-md:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--none);
  }

  .pf-v5-l-flex .pf-m-spacer-xs-on-md {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--xs);
  }

  .pf-v5-l-flex .pf-m-spacer-xs-on-md:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--xs);
  }

  .pf-v5-l-flex .pf-m-spacer-sm-on-md {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--sm);
  }

  .pf-v5-l-flex .pf-m-spacer-sm-on-md:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--sm);
  }

  .pf-v5-l-flex .pf-m-spacer-md-on-md {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--md);
  }

  .pf-v5-l-flex .pf-m-spacer-md-on-md:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--md);
  }

  .pf-v5-l-flex .pf-m-spacer-lg-on-md {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--lg);
  }

  .pf-v5-l-flex .pf-m-spacer-lg-on-md:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--lg);
  }

  .pf-v5-l-flex .pf-m-spacer-xl-on-md {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--xl);
  }

  .pf-v5-l-flex .pf-m-spacer-xl-on-md:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--xl);
  }

  .pf-v5-l-flex .pf-m-spacer-2xl-on-md {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--2xl);
  }

  .pf-v5-l-flex .pf-m-spacer-2xl-on-md:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--2xl);
  }

  .pf-v5-l-flex .pf-m-spacer-3xl-on-md {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--3xl);
  }

  .pf-v5-l-flex .pf-m-spacer-3xl-on-md:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--3xl);
  }

  .pf-v5-l-flex .pf-m-spacer-4xl-on-md {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--4xl);
  }

  .pf-v5-l-flex .pf-m-spacer-4xl-on-md:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--4xl);
  }
}

@media (min-width: 992px) {
  .pf-v5-l-flex .pf-m-spacer-none-on-lg {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--none);
  }

  .pf-v5-l-flex .pf-m-spacer-none-on-lg:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--none);
  }

  .pf-v5-l-flex .pf-m-spacer-xs-on-lg {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--xs);
  }

  .pf-v5-l-flex .pf-m-spacer-xs-on-lg:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--xs);
  }

  .pf-v5-l-flex .pf-m-spacer-sm-on-lg {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--sm);
  }

  .pf-v5-l-flex .pf-m-spacer-sm-on-lg:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--sm);
  }

  .pf-v5-l-flex .pf-m-spacer-md-on-lg {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--md);
  }

  .pf-v5-l-flex .pf-m-spacer-md-on-lg:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--md);
  }

  .pf-v5-l-flex .pf-m-spacer-lg-on-lg {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--lg);
  }

  .pf-v5-l-flex .pf-m-spacer-lg-on-lg:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--lg);
  }

  .pf-v5-l-flex .pf-m-spacer-xl-on-lg {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--xl);
  }

  .pf-v5-l-flex .pf-m-spacer-xl-on-lg:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--xl);
  }

  .pf-v5-l-flex .pf-m-spacer-2xl-on-lg {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--2xl);
  }

  .pf-v5-l-flex .pf-m-spacer-2xl-on-lg:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--2xl);
  }

  .pf-v5-l-flex .pf-m-spacer-3xl-on-lg {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--3xl);
  }

  .pf-v5-l-flex .pf-m-spacer-3xl-on-lg:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--3xl);
  }

  .pf-v5-l-flex .pf-m-spacer-4xl-on-lg {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--4xl);
  }

  .pf-v5-l-flex .pf-m-spacer-4xl-on-lg:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--4xl);
  }
}

@media (min-width: 1200px) {
  .pf-v5-l-flex .pf-m-spacer-none-on-xl {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--none);
  }

  .pf-v5-l-flex .pf-m-spacer-none-on-xl:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--none);
  }

  .pf-v5-l-flex .pf-m-spacer-xs-on-xl {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--xs);
  }

  .pf-v5-l-flex .pf-m-spacer-xs-on-xl:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--xs);
  }

  .pf-v5-l-flex .pf-m-spacer-sm-on-xl {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--sm);
  }

  .pf-v5-l-flex .pf-m-spacer-sm-on-xl:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--sm);
  }

  .pf-v5-l-flex .pf-m-spacer-md-on-xl {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--md);
  }

  .pf-v5-l-flex .pf-m-spacer-md-on-xl:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--md);
  }

  .pf-v5-l-flex .pf-m-spacer-lg-on-xl {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--lg);
  }

  .pf-v5-l-flex .pf-m-spacer-lg-on-xl:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--lg);
  }

  .pf-v5-l-flex .pf-m-spacer-xl-on-xl {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--xl);
  }

  .pf-v5-l-flex .pf-m-spacer-xl-on-xl:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--xl);
  }

  .pf-v5-l-flex .pf-m-spacer-2xl-on-xl {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--2xl);
  }

  .pf-v5-l-flex .pf-m-spacer-2xl-on-xl:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--2xl);
  }

  .pf-v5-l-flex .pf-m-spacer-3xl-on-xl {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--3xl);
  }

  .pf-v5-l-flex .pf-m-spacer-3xl-on-xl:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--3xl);
  }

  .pf-v5-l-flex .pf-m-spacer-4xl-on-xl {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--4xl);
  }

  .pf-v5-l-flex .pf-m-spacer-4xl-on-xl:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--4xl);
  }
}

@media (min-width: 1450px) {
  .pf-v5-l-flex .pf-m-spacer-none-on-2xl {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--none);
  }

  .pf-v5-l-flex .pf-m-spacer-none-on-2xl:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--none);
  }

  .pf-v5-l-flex .pf-m-spacer-xs-on-2xl {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--xs);
  }

  .pf-v5-l-flex .pf-m-spacer-xs-on-2xl:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--xs);
  }

  .pf-v5-l-flex .pf-m-spacer-sm-on-2xl {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--sm);
  }

  .pf-v5-l-flex .pf-m-spacer-sm-on-2xl:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--sm);
  }

  .pf-v5-l-flex .pf-m-spacer-md-on-2xl {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--md);
  }

  .pf-v5-l-flex .pf-m-spacer-md-on-2xl:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--md);
  }

  .pf-v5-l-flex .pf-m-spacer-lg-on-2xl {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--lg);
  }

  .pf-v5-l-flex .pf-m-spacer-lg-on-2xl:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--lg);
  }

  .pf-v5-l-flex .pf-m-spacer-xl-on-2xl {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--xl);
  }

  .pf-v5-l-flex .pf-m-spacer-xl-on-2xl:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--xl);
  }

  .pf-v5-l-flex .pf-m-spacer-2xl-on-2xl {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--2xl);
  }

  .pf-v5-l-flex .pf-m-spacer-2xl-on-2xl:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--2xl);
  }

  .pf-v5-l-flex .pf-m-spacer-3xl-on-2xl {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--3xl);
  }

  .pf-v5-l-flex .pf-m-spacer-3xl-on-2xl:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--3xl);
  }

  .pf-v5-l-flex .pf-m-spacer-4xl-on-2xl {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--4xl);
  }

  .pf-v5-l-flex .pf-m-spacer-4xl-on-2xl:last-child {
    --pf-v5-l-flex--spacer: var(--pf-v5-l-flex--spacer--4xl);
  }
}

.pf-v5-l-flex.pf-m-gap {
  --pf-v5-l-flex--RowGap: var(--pf-v5-l-flex--m-gap--RowGap);
  --pf-v5-l-flex--ColumnGap: var(--pf-v5-l-flex--m-gap--ColumnGap);
}

.pf-v5-l-flex.pf-m-gap-none {
  --pf-v5-l-flex--RowGap: 0;
  --pf-v5-l-flex--ColumnGap: 0;
}

.pf-v5-l-flex.pf-m-gap-xs {
  --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--xs);
  --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--xs);
}

.pf-v5-l-flex.pf-m-gap-sm {
  --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--sm);
  --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--sm);
}

.pf-v5-l-flex.pf-m-gap-md {
  --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--md);
  --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--md);
}

.pf-v5-l-flex.pf-m-gap-lg {
  --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--lg);
  --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--lg);
}

.pf-v5-l-flex.pf-m-gap-xl {
  --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--xl);
  --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--xl);
}

.pf-v5-l-flex.pf-m-gap-2xl {
  --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--2xl);
  --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--2xl);
}

.pf-v5-l-flex.pf-m-gap-3xl {
  --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--3xl);
  --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--3xl);
}

.pf-v5-l-flex.pf-m-gap-4xl {
  --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--4xl);
  --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--4xl);
}

.pf-v5-l-flex:is(.pf-m-gap,.pf-m-gap-none,.pf-m-gap-xs,.pf-m-gap-sm,.pf-m-gap-md,.pf-m-gap-lg,.pf-m-gap-xl,.pf-m-gap-2xl,.pf-m-gap-3xl,.pf-m-gap-4xl)>* {
  --pf-v5-l-flex--spacer--row: 0;
  --pf-v5-l-flex--spacer--column: 0;
}

@media (min-width: 576px) {
  .pf-v5-l-flex.pf-m-gap-on-sm {
    --pf-v5-l-flex--RowGap: var(--pf-v5-l-flex--m-gap--RowGap);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-l-flex--m-gap--ColumnGap);
  }

  .pf-v5-l-flex.pf-m-gap-none-on-sm {
    --pf-v5-l-flex--RowGap: 0;
    --pf-v5-l-flex--ColumnGap: 0;
  }

  .pf-v5-l-flex.pf-m-gap-xs-on-sm {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--xs);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--xs);
  }

  .pf-v5-l-flex.pf-m-gap-sm-on-sm {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--sm);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-l-flex.pf-m-gap-md-on-sm {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--md);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-l-flex.pf-m-gap-lg-on-sm {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--lg);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-l-flex.pf-m-gap-xl-on-sm {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--xl);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-l-flex.pf-m-gap-2xl-on-sm {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--2xl);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--2xl);
  }

  .pf-v5-l-flex.pf-m-gap-3xl-on-sm {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--3xl);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--3xl);
  }

  .pf-v5-l-flex.pf-m-gap-4xl-on-sm {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--4xl);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--4xl);
  }

  .pf-v5-l-flex:is(.pf-m-gap-on-sm,.pf-m-gap-none-on-sm,.pf-m-gap-xs-on-sm,.pf-m-gap-sm-on-sm,.pf-m-gap-md-on-sm,.pf-m-gap-lg-on-sm,.pf-m-gap-xl-on-sm,.pf-m-gap-2xl-on-sm,.pf-m-gap-3xl-on-sm,.pf-m-gap-4xl-on-sm)>* {
    --pf-v5-l-flex--spacer--row: 0;
    --pf-v5-l-flex--spacer--column: 0;
  }
}

@media (min-width: 768px) {
  .pf-v5-l-flex.pf-m-gap-on-md {
    --pf-v5-l-flex--RowGap: var(--pf-v5-l-flex--m-gap--RowGap);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-l-flex--m-gap--ColumnGap);
  }

  .pf-v5-l-flex.pf-m-gap-none-on-md {
    --pf-v5-l-flex--RowGap: 0;
    --pf-v5-l-flex--ColumnGap: 0;
  }

  .pf-v5-l-flex.pf-m-gap-xs-on-md {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--xs);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--xs);
  }

  .pf-v5-l-flex.pf-m-gap-sm-on-md {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--sm);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-l-flex.pf-m-gap-md-on-md {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--md);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-l-flex.pf-m-gap-lg-on-md {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--lg);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-l-flex.pf-m-gap-xl-on-md {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--xl);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-l-flex.pf-m-gap-2xl-on-md {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--2xl);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--2xl);
  }

  .pf-v5-l-flex.pf-m-gap-3xl-on-md {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--3xl);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--3xl);
  }

  .pf-v5-l-flex.pf-m-gap-4xl-on-md {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--4xl);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--4xl);
  }

  .pf-v5-l-flex:is(.pf-m-gap-on-md,.pf-m-gap-none-on-md,.pf-m-gap-xs-on-md,.pf-m-gap-sm-on-md,.pf-m-gap-md-on-md,.pf-m-gap-lg-on-md,.pf-m-gap-xl-on-md,.pf-m-gap-2xl-on-md,.pf-m-gap-3xl-on-md,.pf-m-gap-4xl-on-md)>* {
    --pf-v5-l-flex--spacer--row: 0;
    --pf-v5-l-flex--spacer--column: 0;
  }
}

@media (min-width: 992px) {
  .pf-v5-l-flex.pf-m-gap-on-lg {
    --pf-v5-l-flex--RowGap: var(--pf-v5-l-flex--m-gap--RowGap);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-l-flex--m-gap--ColumnGap);
  }

  .pf-v5-l-flex.pf-m-gap-none-on-lg {
    --pf-v5-l-flex--RowGap: 0;
    --pf-v5-l-flex--ColumnGap: 0;
  }

  .pf-v5-l-flex.pf-m-gap-xs-on-lg {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--xs);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--xs);
  }

  .pf-v5-l-flex.pf-m-gap-sm-on-lg {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--sm);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-l-flex.pf-m-gap-md-on-lg {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--md);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-l-flex.pf-m-gap-lg-on-lg {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--lg);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-l-flex.pf-m-gap-xl-on-lg {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--xl);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-l-flex.pf-m-gap-2xl-on-lg {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--2xl);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--2xl);
  }

  .pf-v5-l-flex.pf-m-gap-3xl-on-lg {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--3xl);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--3xl);
  }

  .pf-v5-l-flex.pf-m-gap-4xl-on-lg {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--4xl);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--4xl);
  }

  .pf-v5-l-flex:is(.pf-m-gap-on-lg,.pf-m-gap-none-on-lg,.pf-m-gap-xs-on-lg,.pf-m-gap-sm-on-lg,.pf-m-gap-md-on-lg,.pf-m-gap-lg-on-lg,.pf-m-gap-xl-on-lg,.pf-m-gap-2xl-on-lg,.pf-m-gap-3xl-on-lg,.pf-m-gap-4xl-on-lg)>* {
    --pf-v5-l-flex--spacer--row: 0;
    --pf-v5-l-flex--spacer--column: 0;
  }
}

@media (min-width: 1200px) {
  .pf-v5-l-flex.pf-m-gap-on-xl {
    --pf-v5-l-flex--RowGap: var(--pf-v5-l-flex--m-gap--RowGap);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-l-flex--m-gap--ColumnGap);
  }

  .pf-v5-l-flex.pf-m-gap-none-on-xl {
    --pf-v5-l-flex--RowGap: 0;
    --pf-v5-l-flex--ColumnGap: 0;
  }

  .pf-v5-l-flex.pf-m-gap-xs-on-xl {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--xs);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--xs);
  }

  .pf-v5-l-flex.pf-m-gap-sm-on-xl {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--sm);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-l-flex.pf-m-gap-md-on-xl {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--md);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-l-flex.pf-m-gap-lg-on-xl {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--lg);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-l-flex.pf-m-gap-xl-on-xl {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--xl);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-l-flex.pf-m-gap-2xl-on-xl {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--2xl);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--2xl);
  }

  .pf-v5-l-flex.pf-m-gap-3xl-on-xl {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--3xl);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--3xl);
  }

  .pf-v5-l-flex.pf-m-gap-4xl-on-xl {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--4xl);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--4xl);
  }

  .pf-v5-l-flex:is(.pf-m-gap-on-xl,.pf-m-gap-none-on-xl,.pf-m-gap-xs-on-xl,.pf-m-gap-sm-on-xl,.pf-m-gap-md-on-xl,.pf-m-gap-lg-on-xl,.pf-m-gap-xl-on-xl,.pf-m-gap-2xl-on-xl,.pf-m-gap-3xl-on-xl,.pf-m-gap-4xl-on-xl)>* {
    --pf-v5-l-flex--spacer--row: 0;
    --pf-v5-l-flex--spacer--column: 0;
  }
}

@media (min-width: 1450px) {
  .pf-v5-l-flex.pf-m-gap-on-2xl {
    --pf-v5-l-flex--RowGap: var(--pf-v5-l-flex--m-gap--RowGap);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-l-flex--m-gap--ColumnGap);
  }

  .pf-v5-l-flex.pf-m-gap-none-on-2xl {
    --pf-v5-l-flex--RowGap: 0;
    --pf-v5-l-flex--ColumnGap: 0;
  }

  .pf-v5-l-flex.pf-m-gap-xs-on-2xl {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--xs);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--xs);
  }

  .pf-v5-l-flex.pf-m-gap-sm-on-2xl {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--sm);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-l-flex.pf-m-gap-md-on-2xl {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--md);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-l-flex.pf-m-gap-lg-on-2xl {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--lg);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-l-flex.pf-m-gap-xl-on-2xl {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--xl);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-l-flex.pf-m-gap-2xl-on-2xl {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--2xl);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--2xl);
  }

  .pf-v5-l-flex.pf-m-gap-3xl-on-2xl {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--3xl);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--3xl);
  }

  .pf-v5-l-flex.pf-m-gap-4xl-on-2xl {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--4xl);
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--4xl);
  }

  .pf-v5-l-flex:is(.pf-m-gap-on-2xl,.pf-m-gap-none-on-2xl,.pf-m-gap-xs-on-2xl,.pf-m-gap-sm-on-2xl,.pf-m-gap-md-on-2xl,.pf-m-gap-lg-on-2xl,.pf-m-gap-xl-on-2xl,.pf-m-gap-2xl-on-2xl,.pf-m-gap-3xl-on-2xl,.pf-m-gap-4xl-on-2xl)>* {
    --pf-v5-l-flex--spacer--row: 0;
    --pf-v5-l-flex--spacer--column: 0;
  }
}

.pf-v5-l-flex.pf-m-row-gap {
  --pf-v5-l-flex--RowGap: var(--pf-v5-l-flex--m-row-gap--RowGap);
}

.pf-v5-l-flex.pf-m-row-gap-none {
  --pf-v5-l-flex--RowGap: 0;
}

.pf-v5-l-flex.pf-m-row-gap-xs {
  --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--xs);
}

.pf-v5-l-flex.pf-m-row-gap-sm {
  --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--sm);
}

.pf-v5-l-flex.pf-m-row-gap-md {
  --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--md);
}

.pf-v5-l-flex.pf-m-row-gap-lg {
  --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--lg);
}

.pf-v5-l-flex.pf-m-row-gap-xl {
  --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--xl);
}

.pf-v5-l-flex.pf-m-row-gap-2xl {
  --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--2xl);
}

.pf-v5-l-flex.pf-m-row-gap-3xl {
  --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--3xl);
}

.pf-v5-l-flex.pf-m-row-gap-4xl {
  --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--4xl);
}

.pf-v5-l-flex:is(.pf-m-row-gap,.pf-m-row-gap-none,.pf-m-row-gap-xs,.pf-m-row-gap-sm,.pf-m-row-gap-md,.pf-m-row-gap-lg,.pf-m-row-gap-xl,.pf-m-row-gap-2xl,.pf-m-row-gap-3xl,.pf-m-row-gap-4xl)>* {
  --pf-v5-l-flex--spacer--row: 0;
}

@media (min-width: 576px) {
  .pf-v5-l-flex.pf-m-row-gap-on-sm {
    --pf-v5-l-flex--RowGap: var(--pf-v5-l-flex--m-row-gap--RowGap);
  }

  .pf-v5-l-flex.pf-m-row-gap-none-on-sm {
    --pf-v5-l-flex--RowGap: 0;
  }

  .pf-v5-l-flex.pf-m-row-gap-xs-on-sm {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--xs);
  }

  .pf-v5-l-flex.pf-m-row-gap-sm-on-sm {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-l-flex.pf-m-row-gap-md-on-sm {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-l-flex.pf-m-row-gap-lg-on-sm {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-l-flex.pf-m-row-gap-xl-on-sm {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-l-flex.pf-m-row-gap-2xl-on-sm {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--2xl);
  }

  .pf-v5-l-flex.pf-m-row-gap-3xl-on-sm {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--3xl);
  }

  .pf-v5-l-flex.pf-m-row-gap-4xl-on-sm {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--4xl);
  }

  .pf-v5-l-flex:is(.pf-m-row-gap-on-sm,.pf-m-row-gap-none-on-sm,.pf-m-row-gap-xs-on-sm,.pf-m-row-gap-sm-on-sm,.pf-m-row-gap-md-on-sm,.pf-m-row-gap-lg-on-sm,.pf-m-row-gap-xl-on-sm,.pf-m-row-gap-2xl-on-sm,.pf-m-row-gap-3xl-on-sm,.pf-m-row-gap-4xl-on-sm)>* {
    --pf-v5-l-flex--spacer--row: 0;
  }
}

@media (min-width: 768px) {
  .pf-v5-l-flex.pf-m-row-gap-on-md {
    --pf-v5-l-flex--RowGap: var(--pf-v5-l-flex--m-row-gap--RowGap);
  }

  .pf-v5-l-flex.pf-m-row-gap-none-on-md {
    --pf-v5-l-flex--RowGap: 0;
  }

  .pf-v5-l-flex.pf-m-row-gap-xs-on-md {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--xs);
  }

  .pf-v5-l-flex.pf-m-row-gap-sm-on-md {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-l-flex.pf-m-row-gap-md-on-md {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-l-flex.pf-m-row-gap-lg-on-md {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-l-flex.pf-m-row-gap-xl-on-md {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-l-flex.pf-m-row-gap-2xl-on-md {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--2xl);
  }

  .pf-v5-l-flex.pf-m-row-gap-3xl-on-md {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--3xl);
  }

  .pf-v5-l-flex.pf-m-row-gap-4xl-on-md {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--4xl);
  }

  .pf-v5-l-flex:is(.pf-m-row-gap-on-md,.pf-m-row-gap-none-on-md,.pf-m-row-gap-xs-on-md,.pf-m-row-gap-sm-on-md,.pf-m-row-gap-md-on-md,.pf-m-row-gap-lg-on-md,.pf-m-row-gap-xl-on-md,.pf-m-row-gap-2xl-on-md,.pf-m-row-gap-3xl-on-md,.pf-m-row-gap-4xl-on-md)>* {
    --pf-v5-l-flex--spacer--row: 0;
  }
}

@media (min-width: 992px) {
  .pf-v5-l-flex.pf-m-row-gap-on-lg {
    --pf-v5-l-flex--RowGap: var(--pf-v5-l-flex--m-row-gap--RowGap);
  }

  .pf-v5-l-flex.pf-m-row-gap-none-on-lg {
    --pf-v5-l-flex--RowGap: 0;
  }

  .pf-v5-l-flex.pf-m-row-gap-xs-on-lg {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--xs);
  }

  .pf-v5-l-flex.pf-m-row-gap-sm-on-lg {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-l-flex.pf-m-row-gap-md-on-lg {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-l-flex.pf-m-row-gap-lg-on-lg {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-l-flex.pf-m-row-gap-xl-on-lg {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-l-flex.pf-m-row-gap-2xl-on-lg {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--2xl);
  }

  .pf-v5-l-flex.pf-m-row-gap-3xl-on-lg {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--3xl);
  }

  .pf-v5-l-flex.pf-m-row-gap-4xl-on-lg {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--4xl);
  }

  .pf-v5-l-flex:is(.pf-m-row-gap-on-lg,.pf-m-row-gap-none-on-lg,.pf-m-row-gap-xs-on-lg,.pf-m-row-gap-sm-on-lg,.pf-m-row-gap-md-on-lg,.pf-m-row-gap-lg-on-lg,.pf-m-row-gap-xl-on-lg,.pf-m-row-gap-2xl-on-lg,.pf-m-row-gap-3xl-on-lg,.pf-m-row-gap-4xl-on-lg)>* {
    --pf-v5-l-flex--spacer--row: 0;
  }
}

@media (min-width: 1200px) {
  .pf-v5-l-flex.pf-m-row-gap-on-xl {
    --pf-v5-l-flex--RowGap: var(--pf-v5-l-flex--m-row-gap--RowGap);
  }

  .pf-v5-l-flex.pf-m-row-gap-none-on-xl {
    --pf-v5-l-flex--RowGap: 0;
  }

  .pf-v5-l-flex.pf-m-row-gap-xs-on-xl {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--xs);
  }

  .pf-v5-l-flex.pf-m-row-gap-sm-on-xl {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-l-flex.pf-m-row-gap-md-on-xl {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-l-flex.pf-m-row-gap-lg-on-xl {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-l-flex.pf-m-row-gap-xl-on-xl {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-l-flex.pf-m-row-gap-2xl-on-xl {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--2xl);
  }

  .pf-v5-l-flex.pf-m-row-gap-3xl-on-xl {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--3xl);
  }

  .pf-v5-l-flex.pf-m-row-gap-4xl-on-xl {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--4xl);
  }

  .pf-v5-l-flex:is(.pf-m-row-gap-on-xl,.pf-m-row-gap-none-on-xl,.pf-m-row-gap-xs-on-xl,.pf-m-row-gap-sm-on-xl,.pf-m-row-gap-md-on-xl,.pf-m-row-gap-lg-on-xl,.pf-m-row-gap-xl-on-xl,.pf-m-row-gap-2xl-on-xl,.pf-m-row-gap-3xl-on-xl,.pf-m-row-gap-4xl-on-xl)>* {
    --pf-v5-l-flex--spacer--row: 0;
  }
}

@media (min-width: 1450px) {
  .pf-v5-l-flex.pf-m-row-gap-on-2xl {
    --pf-v5-l-flex--RowGap: var(--pf-v5-l-flex--m-row-gap--RowGap);
  }

  .pf-v5-l-flex.pf-m-row-gap-none-on-2xl {
    --pf-v5-l-flex--RowGap: 0;
  }

  .pf-v5-l-flex.pf-m-row-gap-xs-on-2xl {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--xs);
  }

  .pf-v5-l-flex.pf-m-row-gap-sm-on-2xl {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-l-flex.pf-m-row-gap-md-on-2xl {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-l-flex.pf-m-row-gap-lg-on-2xl {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-l-flex.pf-m-row-gap-xl-on-2xl {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-l-flex.pf-m-row-gap-2xl-on-2xl {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--2xl);
  }

  .pf-v5-l-flex.pf-m-row-gap-3xl-on-2xl {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--3xl);
  }

  .pf-v5-l-flex.pf-m-row-gap-4xl-on-2xl {
    --pf-v5-l-flex--RowGap: var(--pf-v5-global--spacer--4xl);
  }

  .pf-v5-l-flex:is(.pf-m-row-gap-on-2xl,.pf-m-row-gap-none-on-2xl,.pf-m-row-gap-xs-on-2xl,.pf-m-row-gap-sm-on-2xl,.pf-m-row-gap-md-on-2xl,.pf-m-row-gap-lg-on-2xl,.pf-m-row-gap-xl-on-2xl,.pf-m-row-gap-2xl-on-2xl,.pf-m-row-gap-3xl-on-2xl,.pf-m-row-gap-4xl-on-2xl)>* {
    --pf-v5-l-flex--spacer--row: 0;
  }
}

.pf-v5-l-flex.pf-m-column-gap {
  --pf-v5-l-flex--ColumnGap: var(--pf-v5-l-flex--m-column-gap--ColumnGap);
}

.pf-v5-l-flex.pf-m-column-gap-none {
  --pf-v5-l-flex--ColumnGap: 0;
}

.pf-v5-l-flex.pf-m-column-gap-xs {
  --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--xs);
}

.pf-v5-l-flex.pf-m-column-gap-sm {
  --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--sm);
}

.pf-v5-l-flex.pf-m-column-gap-md {
  --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--md);
}

.pf-v5-l-flex.pf-m-column-gap-lg {
  --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--lg);
}

.pf-v5-l-flex.pf-m-column-gap-xl {
  --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--xl);
}

.pf-v5-l-flex.pf-m-column-gap-2xl {
  --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--2xl);
}

.pf-v5-l-flex.pf-m-column-gap-3xl {
  --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--3xl);
}

.pf-v5-l-flex.pf-m-column-gap-4xl {
  --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--4xl);
}

.pf-v5-l-flex:is(.pf-m-column-gap,.pf-m-column-gap-none,.pf-m-column-gap-xs,.pf-m-column-gap-sm,.pf-m-column-gap-md,.pf-m-column-gap-lg,.pf-m-column-gap-xl,.pf-m-column-gap-2xl,.pf-m-column-gap-3xl,.pf-m-column-gap-4xl)>* {
  --pf-v5-l-flex--spacer--column: 0;
}

@media (min-width: 576px) {
  .pf-v5-l-flex.pf-m-column-gap-on-sm {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-l-flex--m-column-gap--ColumnGap);
  }

  .pf-v5-l-flex.pf-m-column-gap-none-on-sm {
    --pf-v5-l-flex--ColumnGap: 0;
  }

  .pf-v5-l-flex.pf-m-column-gap-xs-on-sm {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--xs);
  }

  .pf-v5-l-flex.pf-m-column-gap-sm-on-sm {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-l-flex.pf-m-column-gap-md-on-sm {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-l-flex.pf-m-column-gap-lg-on-sm {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-l-flex.pf-m-column-gap-xl-on-sm {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-l-flex.pf-m-column-gap-2xl-on-sm {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--2xl);
  }

  .pf-v5-l-flex.pf-m-column-gap-3xl-on-sm {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--3xl);
  }

  .pf-v5-l-flex.pf-m-column-gap-4xl-on-sm {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--4xl);
  }

  .pf-v5-l-flex:is(.pf-m-column-gap-on-sm,.pf-m-column-gap-none-on-sm,.pf-m-column-gap-xs-on-sm,.pf-m-column-gap-sm-on-sm,.pf-m-column-gap-md-on-sm,.pf-m-column-gap-lg-on-sm,.pf-m-column-gap-xl-on-sm,.pf-m-column-gap-2xl-on-sm,.pf-m-column-gap-3xl-on-sm,.pf-m-column-gap-4xl-on-sm)>* {
    --pf-v5-l-flex--spacer--column: 0;
  }
}

@media (min-width: 768px) {
  .pf-v5-l-flex.pf-m-column-gap-on-md {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-l-flex--m-column-gap--ColumnGap);
  }

  .pf-v5-l-flex.pf-m-column-gap-none-on-md {
    --pf-v5-l-flex--ColumnGap: 0;
  }

  .pf-v5-l-flex.pf-m-column-gap-xs-on-md {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--xs);
  }

  .pf-v5-l-flex.pf-m-column-gap-sm-on-md {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-l-flex.pf-m-column-gap-md-on-md {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-l-flex.pf-m-column-gap-lg-on-md {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-l-flex.pf-m-column-gap-xl-on-md {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-l-flex.pf-m-column-gap-2xl-on-md {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--2xl);
  }

  .pf-v5-l-flex.pf-m-column-gap-3xl-on-md {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--3xl);
  }

  .pf-v5-l-flex.pf-m-column-gap-4xl-on-md {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--4xl);
  }

  .pf-v5-l-flex:is(.pf-m-column-gap-on-md,.pf-m-column-gap-none-on-md,.pf-m-column-gap-xs-on-md,.pf-m-column-gap-sm-on-md,.pf-m-column-gap-md-on-md,.pf-m-column-gap-lg-on-md,.pf-m-column-gap-xl-on-md,.pf-m-column-gap-2xl-on-md,.pf-m-column-gap-3xl-on-md,.pf-m-column-gap-4xl-on-md)>* {
    --pf-v5-l-flex--spacer--column: 0;
  }
}

@media (min-width: 992px) {
  .pf-v5-l-flex.pf-m-column-gap-on-lg {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-l-flex--m-column-gap--ColumnGap);
  }

  .pf-v5-l-flex.pf-m-column-gap-none-on-lg {
    --pf-v5-l-flex--ColumnGap: 0;
  }

  .pf-v5-l-flex.pf-m-column-gap-xs-on-lg {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--xs);
  }

  .pf-v5-l-flex.pf-m-column-gap-sm-on-lg {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-l-flex.pf-m-column-gap-md-on-lg {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-l-flex.pf-m-column-gap-lg-on-lg {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-l-flex.pf-m-column-gap-xl-on-lg {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-l-flex.pf-m-column-gap-2xl-on-lg {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--2xl);
  }

  .pf-v5-l-flex.pf-m-column-gap-3xl-on-lg {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--3xl);
  }

  .pf-v5-l-flex.pf-m-column-gap-4xl-on-lg {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--4xl);
  }

  .pf-v5-l-flex:is(.pf-m-column-gap-on-lg,.pf-m-column-gap-none-on-lg,.pf-m-column-gap-xs-on-lg,.pf-m-column-gap-sm-on-lg,.pf-m-column-gap-md-on-lg,.pf-m-column-gap-lg-on-lg,.pf-m-column-gap-xl-on-lg,.pf-m-column-gap-2xl-on-lg,.pf-m-column-gap-3xl-on-lg,.pf-m-column-gap-4xl-on-lg)>* {
    --pf-v5-l-flex--spacer--column: 0;
  }
}

@media (min-width: 1200px) {
  .pf-v5-l-flex.pf-m-column-gap-on-xl {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-l-flex--m-column-gap--ColumnGap);
  }

  .pf-v5-l-flex.pf-m-column-gap-none-on-xl {
    --pf-v5-l-flex--ColumnGap: 0;
  }

  .pf-v5-l-flex.pf-m-column-gap-xs-on-xl {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--xs);
  }

  .pf-v5-l-flex.pf-m-column-gap-sm-on-xl {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-l-flex.pf-m-column-gap-md-on-xl {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-l-flex.pf-m-column-gap-lg-on-xl {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-l-flex.pf-m-column-gap-xl-on-xl {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-l-flex.pf-m-column-gap-2xl-on-xl {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--2xl);
  }

  .pf-v5-l-flex.pf-m-column-gap-3xl-on-xl {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--3xl);
  }

  .pf-v5-l-flex.pf-m-column-gap-4xl-on-xl {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--4xl);
  }

  .pf-v5-l-flex:is(.pf-m-column-gap-on-xl,.pf-m-column-gap-none-on-xl,.pf-m-column-gap-xs-on-xl,.pf-m-column-gap-sm-on-xl,.pf-m-column-gap-md-on-xl,.pf-m-column-gap-lg-on-xl,.pf-m-column-gap-xl-on-xl,.pf-m-column-gap-2xl-on-xl,.pf-m-column-gap-3xl-on-xl,.pf-m-column-gap-4xl-on-xl)>* {
    --pf-v5-l-flex--spacer--column: 0;
  }
}

@media (min-width: 1450px) {
  .pf-v5-l-flex.pf-m-column-gap-on-2xl {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-l-flex--m-column-gap--ColumnGap);
  }

  .pf-v5-l-flex.pf-m-column-gap-none-on-2xl {
    --pf-v5-l-flex--ColumnGap: 0;
  }

  .pf-v5-l-flex.pf-m-column-gap-xs-on-2xl {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--xs);
  }

  .pf-v5-l-flex.pf-m-column-gap-sm-on-2xl {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--sm);
  }

  .pf-v5-l-flex.pf-m-column-gap-md-on-2xl {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-l-flex.pf-m-column-gap-lg-on-2xl {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--lg);
  }

  .pf-v5-l-flex.pf-m-column-gap-xl-on-2xl {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--xl);
  }

  .pf-v5-l-flex.pf-m-column-gap-2xl-on-2xl {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--2xl);
  }

  .pf-v5-l-flex.pf-m-column-gap-3xl-on-2xl {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--3xl);
  }

  .pf-v5-l-flex.pf-m-column-gap-4xl-on-2xl {
    --pf-v5-l-flex--ColumnGap: var(--pf-v5-global--spacer--4xl);
  }

  .pf-v5-l-flex:is(.pf-m-column-gap-on-2xl,.pf-m-column-gap-none-on-2xl,.pf-m-column-gap-xs-on-2xl,.pf-m-column-gap-sm-on-2xl,.pf-m-column-gap-md-on-2xl,.pf-m-column-gap-lg-on-2xl,.pf-m-column-gap-xl-on-2xl,.pf-m-column-gap-2xl-on-2xl,.pf-m-column-gap-3xl-on-2xl,.pf-m-column-gap-4xl-on-2xl)>* {
    --pf-v5-l-flex--spacer--column: 0;
  }
}

.pf-v5-l-gallery {
  --pf-v5-l-gallery--m-gutter--GridGap: var(--pf-v5-global--gutter);
  --pf-v5-l-gallery--GridTemplateColumns--min: 250px;
  --pf-v5-l-gallery--GridTemplateColumns--minmax--min: var(--pf-v5-l-gallery--GridTemplateColumns--min);
  --pf-v5-l-gallery--GridTemplateColumns--max: 1fr;
  --pf-v5-l-gallery--GridTemplateColumns--minmax--max: var(--pf-v5-l-gallery--GridTemplateColumns--max);
  --pf-v5-l-gallery--GridTemplateColumns: repeat(auto-fill, minmax(var(--pf-v5-l-gallery--GridTemplateColumns--minmax--min), var(--pf-v5-l-gallery--GridTemplateColumns--minmax--max)));
  --pf-v5-l-gallery--GridTemplateRows: auto;
  display: grid;
  grid-template-rows: var(--pf-v5-l-gallery--GridTemplateRows);
  grid-template-columns: var(--pf-v5-l-gallery--GridTemplateColumns);
  --pf-v5-l-gallery--GridTemplateColumns--minmax--min: var(--pf-v5-l-gallery--GridTemplateColumns--min);
  --pf-v5-l-gallery--GridTemplateColumns--minmax--max: var(--pf-v5-l-gallery--GridTemplateColumns--max);
}

.pf-v5-l-gallery.pf-m-gutter {
  grid-gap: var(--pf-v5-l-gallery--m-gutter--GridGap);
}

@media (min-width: 576px) {
  .pf-v5-l-gallery {
    --pf-v5-l-gallery--GridTemplateColumns--minmax--min: var(--pf-v5-l-gallery--GridTemplateColumns--min-on-sm, var(--pf-v5-l-gallery--GridTemplateColumns--min));
  }
}

@media (min-width: 768px) {
  .pf-v5-l-gallery {
    --pf-v5-l-gallery--GridTemplateColumns--minmax--min: var(--pf-v5-l-gallery--GridTemplateColumns--min-on-md, var(--pf-v5-l-gallery--GridTemplateColumns--min-on-sm, var(--pf-v5-l-gallery--GridTemplateColumns--min)));
  }
}

@media (min-width: 992px) {
  .pf-v5-l-gallery {
    --pf-v5-l-gallery--GridTemplateColumns--minmax--min: var(--pf-v5-l-gallery--GridTemplateColumns--min-on-lg, var(--pf-v5-l-gallery--GridTemplateColumns--min-on-md, var(--pf-v5-l-gallery--GridTemplateColumns--min-on-sm, var(--pf-v5-l-gallery--GridTemplateColumns--min))));
  }
}

@media (min-width: 1200px) {
  .pf-v5-l-gallery {
    --pf-v5-l-gallery--GridTemplateColumns--minmax--min: var(--pf-v5-l-gallery--GridTemplateColumns--min-on-xl, var(--pf-v5-l-gallery--GridTemplateColumns--min-on-lg, var(--pf-v5-l-gallery--GridTemplateColumns--min-on-md, var(--pf-v5-l-gallery--GridTemplateColumns--min-on-sm, var(--pf-v5-l-gallery--GridTemplateColumns--min)))));
  }
}

@media (min-width: 1450px) {
  .pf-v5-l-gallery {
    --pf-v5-l-gallery--GridTemplateColumns--minmax--min: var(--pf-v5-l-gallery--GridTemplateColumns--min-on-2xl, var(--pf-v5-l-gallery--GridTemplateColumns--min-on-xl, var(--pf-v5-l-gallery--GridTemplateColumns--min-on-lg, var(--pf-v5-l-gallery--GridTemplateColumns--min-on-md, var(--pf-v5-l-gallery--GridTemplateColumns--min-on-sm, var(--pf-v5-l-gallery--GridTemplateColumns--min))))));
  }
}

@media (min-width: 576px) {
  .pf-v5-l-gallery {
    --pf-v5-l-gallery--GridTemplateColumns--minmax--max: var(--pf-v5-l-gallery--GridTemplateColumns--max-on-sm, var(--pf-v5-l-gallery--GridTemplateColumns--max));
  }
}

@media (min-width: 768px) {
  .pf-v5-l-gallery {
    --pf-v5-l-gallery--GridTemplateColumns--minmax--max: var(--pf-v5-l-gallery--GridTemplateColumns--max-on-md, var(--pf-v5-l-gallery--GridTemplateColumns--max-on-sm, var(--pf-v5-l-gallery--GridTemplateColumns--max)));
  }
}

@media (min-width: 992px) {
  .pf-v5-l-gallery {
    --pf-v5-l-gallery--GridTemplateColumns--minmax--max: var(--pf-v5-l-gallery--GridTemplateColumns--max-on-lg, var(--pf-v5-l-gallery--GridTemplateColumns--max-on-md, var(--pf-v5-l-gallery--GridTemplateColumns--max-on-sm, var(--pf-v5-l-gallery--GridTemplateColumns--max))));
  }
}

@media (min-width: 1200px) {
  .pf-v5-l-gallery {
    --pf-v5-l-gallery--GridTemplateColumns--minmax--max: var(--pf-v5-l-gallery--GridTemplateColumns--max-on-xl, var(--pf-v5-l-gallery--GridTemplateColumns--max-on-lg, var(--pf-v5-l-gallery--GridTemplateColumns--max-on-md, var(--pf-v5-l-gallery--GridTemplateColumns--max-on-sm, var(--pf-v5-l-gallery--GridTemplateColumns--max)))));
  }
}

@media (min-width: 1450px) {
  .pf-v5-l-gallery {
    --pf-v5-l-gallery--GridTemplateColumns--minmax--max: var(--pf-v5-l-gallery--GridTemplateColumns--max-on-2xl, var(--pf-v5-l-gallery--GridTemplateColumns--max-on-xl, var(--pf-v5-l-gallery--GridTemplateColumns--max-on-lg, var(--pf-v5-l-gallery--GridTemplateColumns--max-on-md, var(--pf-v5-l-gallery--GridTemplateColumns--max-on-sm, var(--pf-v5-l-gallery--GridTemplateColumns--max))))));
  }
}

.pf-v5-l-grid {
  --pf-v5-l-grid--m-gutter--GridGap: var(--pf-v5-global--gutter);
  --pf-v5-l-grid__item--GridColumnStart: auto;
  --pf-v5-l-grid__item--GridColumnEnd: span 12;
  --pf-v5-l-grid--item--Order: 0;
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
}

.pf-v5-l-grid>*,
.pf-v5-l-grid .pf-v5-l-grid__item {
  grid-column-start: var(--pf-v5-l-grid__item--GridColumnStart);
  grid-column-end: var(--pf-v5-l-grid__item--GridColumnEnd);
  min-width: 0;
  min-height: 0;
  order: var(--pf-v5-l-grid--item--Order);
}

@media (min-width: 576px) {
  .pf-v5-l-grid>*,
  .pf-v5-l-grid .pf-v5-l-grid__item {
    order: var(--pf-v5-l-grid--item--Order-on-sm, var(--pf-v5-l-grid--item--Order));
  }
}

@media (min-width: 768px) {
  .pf-v5-l-grid>*,
  .pf-v5-l-grid .pf-v5-l-grid__item {
    order: var(--pf-v5-l-grid--item--Order-on-md, var(--pf-v5-l-grid--item--Order-on-sm, var(--pf-v5-l-grid--item--Order)));
  }
}

@media (min-width: 992px) {
  .pf-v5-l-grid>*,
  .pf-v5-l-grid .pf-v5-l-grid__item {
    order: var(--pf-v5-l-grid--item--Order-on-lg, var(--pf-v5-l-grid--item--Order-on-md, var(--pf-v5-l-grid--item--Order-on-sm, var(--pf-v5-l-grid--item--Order))));
  }
}

@media (min-width: 1200px) {
  .pf-v5-l-grid>*,
  .pf-v5-l-grid .pf-v5-l-grid__item {
    order: var(--pf-v5-l-grid--item--Order-on-xl, var(--pf-v5-l-grid--item--Order-on-lg, var(--pf-v5-l-grid--item--Order-on-md, var(--pf-v5-l-grid--item--Order-on-sm, var(--pf-v5-l-grid--item--Order)))));
  }
}

@media (min-width: 1450px) {
  .pf-v5-l-grid>*,
  .pf-v5-l-grid .pf-v5-l-grid__item {
    order: var(--pf-v5-l-grid--item--Order-on-2xl, var(--pf-v5-l-grid--item--Order-on-xl, var(--pf-v5-l-grid--item--Order-on-lg, var(--pf-v5-l-grid--item--Order-on-md, var(--pf-v5-l-grid--item--Order-on-sm, var(--pf-v5-l-grid--item--Order))))));
  }
}

.pf-v5-l-grid.pf-m-all-1-col>* {
  --pf-v5-l-grid__item--GridColumnEnd: span 1;
}

.pf-v5-l-grid.pf-m-all-2-col>* {
  --pf-v5-l-grid__item--GridColumnEnd: span 2;
}

.pf-v5-l-grid.pf-m-all-3-col>* {
  --pf-v5-l-grid__item--GridColumnEnd: span 3;
}

.pf-v5-l-grid.pf-m-all-4-col>* {
  --pf-v5-l-grid__item--GridColumnEnd: span 4;
}

.pf-v5-l-grid.pf-m-all-5-col>* {
  --pf-v5-l-grid__item--GridColumnEnd: span 5;
}

.pf-v5-l-grid.pf-m-all-6-col>* {
  --pf-v5-l-grid__item--GridColumnEnd: span 6;
}

.pf-v5-l-grid.pf-m-all-7-col>* {
  --pf-v5-l-grid__item--GridColumnEnd: span 7;
}

.pf-v5-l-grid.pf-m-all-8-col>* {
  --pf-v5-l-grid__item--GridColumnEnd: span 8;
}

.pf-v5-l-grid.pf-m-all-9-col>* {
  --pf-v5-l-grid__item--GridColumnEnd: span 9;
}

.pf-v5-l-grid.pf-m-all-10-col>* {
  --pf-v5-l-grid__item--GridColumnEnd: span 10;
}

.pf-v5-l-grid.pf-m-all-11-col>* {
  --pf-v5-l-grid__item--GridColumnEnd: span 11;
}

.pf-v5-l-grid.pf-m-all-12-col>* {
  --pf-v5-l-grid__item--GridColumnEnd: span 12;
}

@media screen and (min-width: 576px) {
  .pf-v5-l-grid.pf-m-all-1-col-on-sm>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 1;
  }

  .pf-v5-l-grid.pf-m-all-2-col-on-sm>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 2;
  }

  .pf-v5-l-grid.pf-m-all-3-col-on-sm>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 3;
  }

  .pf-v5-l-grid.pf-m-all-4-col-on-sm>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 4;
  }

  .pf-v5-l-grid.pf-m-all-5-col-on-sm>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 5;
  }

  .pf-v5-l-grid.pf-m-all-6-col-on-sm>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 6;
  }

  .pf-v5-l-grid.pf-m-all-7-col-on-sm>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 7;
  }

  .pf-v5-l-grid.pf-m-all-8-col-on-sm>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 8;
  }

  .pf-v5-l-grid.pf-m-all-9-col-on-sm>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 9;
  }

  .pf-v5-l-grid.pf-m-all-10-col-on-sm>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 10;
  }

  .pf-v5-l-grid.pf-m-all-11-col-on-sm>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 11;
  }

  .pf-v5-l-grid.pf-m-all-12-col-on-sm>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 12;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-l-grid.pf-m-all-1-col-on-md>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 1;
  }

  .pf-v5-l-grid.pf-m-all-2-col-on-md>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 2;
  }

  .pf-v5-l-grid.pf-m-all-3-col-on-md>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 3;
  }

  .pf-v5-l-grid.pf-m-all-4-col-on-md>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 4;
  }

  .pf-v5-l-grid.pf-m-all-5-col-on-md>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 5;
  }

  .pf-v5-l-grid.pf-m-all-6-col-on-md>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 6;
  }

  .pf-v5-l-grid.pf-m-all-7-col-on-md>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 7;
  }

  .pf-v5-l-grid.pf-m-all-8-col-on-md>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 8;
  }

  .pf-v5-l-grid.pf-m-all-9-col-on-md>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 9;
  }

  .pf-v5-l-grid.pf-m-all-10-col-on-md>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 10;
  }

  .pf-v5-l-grid.pf-m-all-11-col-on-md>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 11;
  }

  .pf-v5-l-grid.pf-m-all-12-col-on-md>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 12;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-l-grid.pf-m-all-1-col-on-lg>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 1;
  }

  .pf-v5-l-grid.pf-m-all-2-col-on-lg>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 2;
  }

  .pf-v5-l-grid.pf-m-all-3-col-on-lg>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 3;
  }

  .pf-v5-l-grid.pf-m-all-4-col-on-lg>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 4;
  }

  .pf-v5-l-grid.pf-m-all-5-col-on-lg>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 5;
  }

  .pf-v5-l-grid.pf-m-all-6-col-on-lg>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 6;
  }

  .pf-v5-l-grid.pf-m-all-7-col-on-lg>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 7;
  }

  .pf-v5-l-grid.pf-m-all-8-col-on-lg>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 8;
  }

  .pf-v5-l-grid.pf-m-all-9-col-on-lg>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 9;
  }

  .pf-v5-l-grid.pf-m-all-10-col-on-lg>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 10;
  }

  .pf-v5-l-grid.pf-m-all-11-col-on-lg>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 11;
  }

  .pf-v5-l-grid.pf-m-all-12-col-on-lg>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 12;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-l-grid.pf-m-all-1-col-on-xl>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 1;
  }

  .pf-v5-l-grid.pf-m-all-2-col-on-xl>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 2;
  }

  .pf-v5-l-grid.pf-m-all-3-col-on-xl>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 3;
  }

  .pf-v5-l-grid.pf-m-all-4-col-on-xl>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 4;
  }

  .pf-v5-l-grid.pf-m-all-5-col-on-xl>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 5;
  }

  .pf-v5-l-grid.pf-m-all-6-col-on-xl>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 6;
  }

  .pf-v5-l-grid.pf-m-all-7-col-on-xl>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 7;
  }

  .pf-v5-l-grid.pf-m-all-8-col-on-xl>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 8;
  }

  .pf-v5-l-grid.pf-m-all-9-col-on-xl>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 9;
  }

  .pf-v5-l-grid.pf-m-all-10-col-on-xl>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 10;
  }

  .pf-v5-l-grid.pf-m-all-11-col-on-xl>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 11;
  }

  .pf-v5-l-grid.pf-m-all-12-col-on-xl>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 12;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-l-grid.pf-m-all-1-col-on-2xl>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 1;
  }

  .pf-v5-l-grid.pf-m-all-2-col-on-2xl>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 2;
  }

  .pf-v5-l-grid.pf-m-all-3-col-on-2xl>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 3;
  }

  .pf-v5-l-grid.pf-m-all-4-col-on-2xl>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 4;
  }

  .pf-v5-l-grid.pf-m-all-5-col-on-2xl>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 5;
  }

  .pf-v5-l-grid.pf-m-all-6-col-on-2xl>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 6;
  }

  .pf-v5-l-grid.pf-m-all-7-col-on-2xl>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 7;
  }

  .pf-v5-l-grid.pf-m-all-8-col-on-2xl>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 8;
  }

  .pf-v5-l-grid.pf-m-all-9-col-on-2xl>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 9;
  }

  .pf-v5-l-grid.pf-m-all-10-col-on-2xl>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 10;
  }

  .pf-v5-l-grid.pf-m-all-11-col-on-2xl>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 11;
  }

  .pf-v5-l-grid.pf-m-all-12-col-on-2xl>* {
    --pf-v5-l-grid__item--GridColumnEnd: span 12;
  }
}

.pf-v5-l-grid>.pf-m-1-col {
  --pf-v5-l-grid__item--GridColumnEnd: span 1;
}

.pf-v5-l-grid>.pf-m-2-col {
  --pf-v5-l-grid__item--GridColumnEnd: span 2;
}

.pf-v5-l-grid>.pf-m-3-col {
  --pf-v5-l-grid__item--GridColumnEnd: span 3;
}

.pf-v5-l-grid>.pf-m-4-col {
  --pf-v5-l-grid__item--GridColumnEnd: span 4;
}

.pf-v5-l-grid>.pf-m-5-col {
  --pf-v5-l-grid__item--GridColumnEnd: span 5;
}

.pf-v5-l-grid>.pf-m-6-col {
  --pf-v5-l-grid__item--GridColumnEnd: span 6;
}

.pf-v5-l-grid>.pf-m-7-col {
  --pf-v5-l-grid__item--GridColumnEnd: span 7;
}

.pf-v5-l-grid>.pf-m-8-col {
  --pf-v5-l-grid__item--GridColumnEnd: span 8;
}

.pf-v5-l-grid>.pf-m-9-col {
  --pf-v5-l-grid__item--GridColumnEnd: span 9;
}

.pf-v5-l-grid>.pf-m-10-col {
  --pf-v5-l-grid__item--GridColumnEnd: span 10;
}

.pf-v5-l-grid>.pf-m-11-col {
  --pf-v5-l-grid__item--GridColumnEnd: span 11;
}

.pf-v5-l-grid>.pf-m-12-col {
  --pf-v5-l-grid__item--GridColumnEnd: span 12;
}

.pf-v5-l-grid>.pf-m-offset-1-col {
  --pf-v5-l-grid__item--GridColumnStart: col-start calc(1 + 1);
}

.pf-v5-l-grid>.pf-m-offset-2-col {
  --pf-v5-l-grid__item--GridColumnStart: col-start calc(2 + 1);
}

.pf-v5-l-grid>.pf-m-offset-3-col {
  --pf-v5-l-grid__item--GridColumnStart: col-start calc(3 + 1);
}

.pf-v5-l-grid>.pf-m-offset-4-col {
  --pf-v5-l-grid__item--GridColumnStart: col-start calc(4 + 1);
}

.pf-v5-l-grid>.pf-m-offset-5-col {
  --pf-v5-l-grid__item--GridColumnStart: col-start calc(5 + 1);
}

.pf-v5-l-grid>.pf-m-offset-6-col {
  --pf-v5-l-grid__item--GridColumnStart: col-start calc(6 + 1);
}

.pf-v5-l-grid>.pf-m-offset-7-col {
  --pf-v5-l-grid__item--GridColumnStart: col-start calc(7 + 1);
}

.pf-v5-l-grid>.pf-m-offset-8-col {
  --pf-v5-l-grid__item--GridColumnStart: col-start calc(8 + 1);
}

.pf-v5-l-grid>.pf-m-offset-9-col {
  --pf-v5-l-grid__item--GridColumnStart: col-start calc(9 + 1);
}

.pf-v5-l-grid>.pf-m-offset-10-col {
  --pf-v5-l-grid__item--GridColumnStart: col-start calc(10 + 1);
}

.pf-v5-l-grid>.pf-m-offset-11-col {
  --pf-v5-l-grid__item--GridColumnStart: col-start calc(11 + 1);
}

.pf-v5-l-grid>.pf-m-offset-12-col {
  --pf-v5-l-grid__item--GridColumnStart: col-start calc(12 + 1);
}

.pf-v5-l-grid>.pf-m-1-row {
  grid-row: span 1;
}

.pf-v5-l-grid>.pf-m-2-row {
  grid-row: span 2;
}

.pf-v5-l-grid>.pf-m-3-row {
  grid-row: span 3;
}

.pf-v5-l-grid>.pf-m-4-row {
  grid-row: span 4;
}

.pf-v5-l-grid>.pf-m-5-row {
  grid-row: span 5;
}

.pf-v5-l-grid>.pf-m-6-row {
  grid-row: span 6;
}

.pf-v5-l-grid>.pf-m-7-row {
  grid-row: span 7;
}

.pf-v5-l-grid>.pf-m-8-row {
  grid-row: span 8;
}

.pf-v5-l-grid>.pf-m-9-row {
  grid-row: span 9;
}

.pf-v5-l-grid>.pf-m-10-row {
  grid-row: span 10;
}

.pf-v5-l-grid>.pf-m-11-row {
  grid-row: span 11;
}

.pf-v5-l-grid>.pf-m-12-row {
  grid-row: span 12;
}

@media screen and (min-width: 576px) {
  .pf-v5-l-grid>.pf-m-1-col-on-sm {
    --pf-v5-l-grid__item--GridColumnEnd: span 1;
  }

  .pf-v5-l-grid>.pf-m-2-col-on-sm {
    --pf-v5-l-grid__item--GridColumnEnd: span 2;
  }

  .pf-v5-l-grid>.pf-m-3-col-on-sm {
    --pf-v5-l-grid__item--GridColumnEnd: span 3;
  }

  .pf-v5-l-grid>.pf-m-4-col-on-sm {
    --pf-v5-l-grid__item--GridColumnEnd: span 4;
  }

  .pf-v5-l-grid>.pf-m-5-col-on-sm {
    --pf-v5-l-grid__item--GridColumnEnd: span 5;
  }

  .pf-v5-l-grid>.pf-m-6-col-on-sm {
    --pf-v5-l-grid__item--GridColumnEnd: span 6;
  }

  .pf-v5-l-grid>.pf-m-7-col-on-sm {
    --pf-v5-l-grid__item--GridColumnEnd: span 7;
  }

  .pf-v5-l-grid>.pf-m-8-col-on-sm {
    --pf-v5-l-grid__item--GridColumnEnd: span 8;
  }

  .pf-v5-l-grid>.pf-m-9-col-on-sm {
    --pf-v5-l-grid__item--GridColumnEnd: span 9;
  }

  .pf-v5-l-grid>.pf-m-10-col-on-sm {
    --pf-v5-l-grid__item--GridColumnEnd: span 10;
  }

  .pf-v5-l-grid>.pf-m-11-col-on-sm {
    --pf-v5-l-grid__item--GridColumnEnd: span 11;
  }

  .pf-v5-l-grid>.pf-m-12-col-on-sm {
    --pf-v5-l-grid__item--GridColumnEnd: span 12;
  }

  .pf-v5-l-grid>.pf-m-offset-1-col-on-sm {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(1 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-2-col-on-sm {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(2 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-3-col-on-sm {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(3 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-4-col-on-sm {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(4 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-5-col-on-sm {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(5 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-6-col-on-sm {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(6 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-7-col-on-sm {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(7 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-8-col-on-sm {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(8 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-9-col-on-sm {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(9 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-10-col-on-sm {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(10 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-11-col-on-sm {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(11 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-12-col-on-sm {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(12 + 1);
  }

  .pf-v5-l-grid>.pf-m-1-row-on-sm {
    grid-row: span 1;
  }

  .pf-v5-l-grid>.pf-m-2-row-on-sm {
    grid-row: span 2;
  }

  .pf-v5-l-grid>.pf-m-3-row-on-sm {
    grid-row: span 3;
  }

  .pf-v5-l-grid>.pf-m-4-row-on-sm {
    grid-row: span 4;
  }

  .pf-v5-l-grid>.pf-m-5-row-on-sm {
    grid-row: span 5;
  }

  .pf-v5-l-grid>.pf-m-6-row-on-sm {
    grid-row: span 6;
  }

  .pf-v5-l-grid>.pf-m-7-row-on-sm {
    grid-row: span 7;
  }

  .pf-v5-l-grid>.pf-m-8-row-on-sm {
    grid-row: span 8;
  }

  .pf-v5-l-grid>.pf-m-9-row-on-sm {
    grid-row: span 9;
  }

  .pf-v5-l-grid>.pf-m-10-row-on-sm {
    grid-row: span 10;
  }

  .pf-v5-l-grid>.pf-m-11-row-on-sm {
    grid-row: span 11;
  }

  .pf-v5-l-grid>.pf-m-12-row-on-sm {
    grid-row: span 12;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-l-grid>.pf-m-1-col-on-md {
    --pf-v5-l-grid__item--GridColumnEnd: span 1;
  }

  .pf-v5-l-grid>.pf-m-2-col-on-md {
    --pf-v5-l-grid__item--GridColumnEnd: span 2;
  }

  .pf-v5-l-grid>.pf-m-3-col-on-md {
    --pf-v5-l-grid__item--GridColumnEnd: span 3;
  }

  .pf-v5-l-grid>.pf-m-4-col-on-md {
    --pf-v5-l-grid__item--GridColumnEnd: span 4;
  }

  .pf-v5-l-grid>.pf-m-5-col-on-md {
    --pf-v5-l-grid__item--GridColumnEnd: span 5;
  }

  .pf-v5-l-grid>.pf-m-6-col-on-md {
    --pf-v5-l-grid__item--GridColumnEnd: span 6;
  }

  .pf-v5-l-grid>.pf-m-7-col-on-md {
    --pf-v5-l-grid__item--GridColumnEnd: span 7;
  }

  .pf-v5-l-grid>.pf-m-8-col-on-md {
    --pf-v5-l-grid__item--GridColumnEnd: span 8;
  }

  .pf-v5-l-grid>.pf-m-9-col-on-md {
    --pf-v5-l-grid__item--GridColumnEnd: span 9;
  }

  .pf-v5-l-grid>.pf-m-10-col-on-md {
    --pf-v5-l-grid__item--GridColumnEnd: span 10;
  }

  .pf-v5-l-grid>.pf-m-11-col-on-md {
    --pf-v5-l-grid__item--GridColumnEnd: span 11;
  }

  .pf-v5-l-grid>.pf-m-12-col-on-md {
    --pf-v5-l-grid__item--GridColumnEnd: span 12;
  }

  .pf-v5-l-grid>.pf-m-offset-1-col-on-md {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(1 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-2-col-on-md {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(2 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-3-col-on-md {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(3 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-4-col-on-md {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(4 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-5-col-on-md {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(5 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-6-col-on-md {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(6 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-7-col-on-md {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(7 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-8-col-on-md {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(8 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-9-col-on-md {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(9 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-10-col-on-md {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(10 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-11-col-on-md {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(11 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-12-col-on-md {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(12 + 1);
  }

  .pf-v5-l-grid>.pf-m-1-row-on-md {
    grid-row: span 1;
  }

  .pf-v5-l-grid>.pf-m-2-row-on-md {
    grid-row: span 2;
  }

  .pf-v5-l-grid>.pf-m-3-row-on-md {
    grid-row: span 3;
  }

  .pf-v5-l-grid>.pf-m-4-row-on-md {
    grid-row: span 4;
  }

  .pf-v5-l-grid>.pf-m-5-row-on-md {
    grid-row: span 5;
  }

  .pf-v5-l-grid>.pf-m-6-row-on-md {
    grid-row: span 6;
  }

  .pf-v5-l-grid>.pf-m-7-row-on-md {
    grid-row: span 7;
  }

  .pf-v5-l-grid>.pf-m-8-row-on-md {
    grid-row: span 8;
  }

  .pf-v5-l-grid>.pf-m-9-row-on-md {
    grid-row: span 9;
  }

  .pf-v5-l-grid>.pf-m-10-row-on-md {
    grid-row: span 10;
  }

  .pf-v5-l-grid>.pf-m-11-row-on-md {
    grid-row: span 11;
  }

  .pf-v5-l-grid>.pf-m-12-row-on-md {
    grid-row: span 12;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-l-grid>.pf-m-1-col-on-lg {
    --pf-v5-l-grid__item--GridColumnEnd: span 1;
  }

  .pf-v5-l-grid>.pf-m-2-col-on-lg {
    --pf-v5-l-grid__item--GridColumnEnd: span 2;
  }

  .pf-v5-l-grid>.pf-m-3-col-on-lg {
    --pf-v5-l-grid__item--GridColumnEnd: span 3;
  }

  .pf-v5-l-grid>.pf-m-4-col-on-lg {
    --pf-v5-l-grid__item--GridColumnEnd: span 4;
  }

  .pf-v5-l-grid>.pf-m-5-col-on-lg {
    --pf-v5-l-grid__item--GridColumnEnd: span 5;
  }

  .pf-v5-l-grid>.pf-m-6-col-on-lg {
    --pf-v5-l-grid__item--GridColumnEnd: span 6;
  }

  .pf-v5-l-grid>.pf-m-7-col-on-lg {
    --pf-v5-l-grid__item--GridColumnEnd: span 7;
  }

  .pf-v5-l-grid>.pf-m-8-col-on-lg {
    --pf-v5-l-grid__item--GridColumnEnd: span 8;
  }

  .pf-v5-l-grid>.pf-m-9-col-on-lg {
    --pf-v5-l-grid__item--GridColumnEnd: span 9;
  }

  .pf-v5-l-grid>.pf-m-10-col-on-lg {
    --pf-v5-l-grid__item--GridColumnEnd: span 10;
  }

  .pf-v5-l-grid>.pf-m-11-col-on-lg {
    --pf-v5-l-grid__item--GridColumnEnd: span 11;
  }

  .pf-v5-l-grid>.pf-m-12-col-on-lg {
    --pf-v5-l-grid__item--GridColumnEnd: span 12;
  }

  .pf-v5-l-grid>.pf-m-offset-1-col-on-lg {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(1 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-2-col-on-lg {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(2 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-3-col-on-lg {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(3 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-4-col-on-lg {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(4 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-5-col-on-lg {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(5 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-6-col-on-lg {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(6 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-7-col-on-lg {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(7 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-8-col-on-lg {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(8 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-9-col-on-lg {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(9 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-10-col-on-lg {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(10 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-11-col-on-lg {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(11 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-12-col-on-lg {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(12 + 1);
  }

  .pf-v5-l-grid>.pf-m-1-row-on-lg {
    grid-row: span 1;
  }

  .pf-v5-l-grid>.pf-m-2-row-on-lg {
    grid-row: span 2;
  }

  .pf-v5-l-grid>.pf-m-3-row-on-lg {
    grid-row: span 3;
  }

  .pf-v5-l-grid>.pf-m-4-row-on-lg {
    grid-row: span 4;
  }

  .pf-v5-l-grid>.pf-m-5-row-on-lg {
    grid-row: span 5;
  }

  .pf-v5-l-grid>.pf-m-6-row-on-lg {
    grid-row: span 6;
  }

  .pf-v5-l-grid>.pf-m-7-row-on-lg {
    grid-row: span 7;
  }

  .pf-v5-l-grid>.pf-m-8-row-on-lg {
    grid-row: span 8;
  }

  .pf-v5-l-grid>.pf-m-9-row-on-lg {
    grid-row: span 9;
  }

  .pf-v5-l-grid>.pf-m-10-row-on-lg {
    grid-row: span 10;
  }

  .pf-v5-l-grid>.pf-m-11-row-on-lg {
    grid-row: span 11;
  }

  .pf-v5-l-grid>.pf-m-12-row-on-lg {
    grid-row: span 12;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-l-grid>.pf-m-1-col-on-xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 1;
  }

  .pf-v5-l-grid>.pf-m-2-col-on-xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 2;
  }

  .pf-v5-l-grid>.pf-m-3-col-on-xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 3;
  }

  .pf-v5-l-grid>.pf-m-4-col-on-xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 4;
  }

  .pf-v5-l-grid>.pf-m-5-col-on-xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 5;
  }

  .pf-v5-l-grid>.pf-m-6-col-on-xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 6;
  }

  .pf-v5-l-grid>.pf-m-7-col-on-xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 7;
  }

  .pf-v5-l-grid>.pf-m-8-col-on-xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 8;
  }

  .pf-v5-l-grid>.pf-m-9-col-on-xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 9;
  }

  .pf-v5-l-grid>.pf-m-10-col-on-xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 10;
  }

  .pf-v5-l-grid>.pf-m-11-col-on-xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 11;
  }

  .pf-v5-l-grid>.pf-m-12-col-on-xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 12;
  }

  .pf-v5-l-grid>.pf-m-offset-1-col-on-xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(1 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-2-col-on-xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(2 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-3-col-on-xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(3 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-4-col-on-xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(4 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-5-col-on-xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(5 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-6-col-on-xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(6 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-7-col-on-xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(7 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-8-col-on-xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(8 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-9-col-on-xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(9 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-10-col-on-xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(10 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-11-col-on-xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(11 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-12-col-on-xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(12 + 1);
  }

  .pf-v5-l-grid>.pf-m-1-row-on-xl {
    grid-row: span 1;
  }

  .pf-v5-l-grid>.pf-m-2-row-on-xl {
    grid-row: span 2;
  }

  .pf-v5-l-grid>.pf-m-3-row-on-xl {
    grid-row: span 3;
  }

  .pf-v5-l-grid>.pf-m-4-row-on-xl {
    grid-row: span 4;
  }

  .pf-v5-l-grid>.pf-m-5-row-on-xl {
    grid-row: span 5;
  }

  .pf-v5-l-grid>.pf-m-6-row-on-xl {
    grid-row: span 6;
  }

  .pf-v5-l-grid>.pf-m-7-row-on-xl {
    grid-row: span 7;
  }

  .pf-v5-l-grid>.pf-m-8-row-on-xl {
    grid-row: span 8;
  }

  .pf-v5-l-grid>.pf-m-9-row-on-xl {
    grid-row: span 9;
  }

  .pf-v5-l-grid>.pf-m-10-row-on-xl {
    grid-row: span 10;
  }

  .pf-v5-l-grid>.pf-m-11-row-on-xl {
    grid-row: span 11;
  }

  .pf-v5-l-grid>.pf-m-12-row-on-xl {
    grid-row: span 12;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-l-grid>.pf-m-1-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 1;
  }

  .pf-v5-l-grid>.pf-m-2-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 2;
  }

  .pf-v5-l-grid>.pf-m-3-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 3;
  }

  .pf-v5-l-grid>.pf-m-4-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 4;
  }

  .pf-v5-l-grid>.pf-m-5-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 5;
  }

  .pf-v5-l-grid>.pf-m-6-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 6;
  }

  .pf-v5-l-grid>.pf-m-7-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 7;
  }

  .pf-v5-l-grid>.pf-m-8-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 8;
  }

  .pf-v5-l-grid>.pf-m-9-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 9;
  }

  .pf-v5-l-grid>.pf-m-10-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 10;
  }

  .pf-v5-l-grid>.pf-m-11-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 11;
  }

  .pf-v5-l-grid>.pf-m-12-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnEnd: span 12;
  }

  .pf-v5-l-grid>.pf-m-offset-1-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(1 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-2-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(2 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-3-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(3 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-4-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(4 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-5-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(5 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-6-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(6 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-7-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(7 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-8-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(8 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-9-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(9 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-10-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(10 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-11-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(11 + 1);
  }

  .pf-v5-l-grid>.pf-m-offset-12-col-on-2xl {
    --pf-v5-l-grid__item--GridColumnStart: col-start calc(12 + 1);
  }

  .pf-v5-l-grid>.pf-m-1-row-on-2xl {
    grid-row: span 1;
  }

  .pf-v5-l-grid>.pf-m-2-row-on-2xl {
    grid-row: span 2;
  }

  .pf-v5-l-grid>.pf-m-3-row-on-2xl {
    grid-row: span 3;
  }

  .pf-v5-l-grid>.pf-m-4-row-on-2xl {
    grid-row: span 4;
  }

  .pf-v5-l-grid>.pf-m-5-row-on-2xl {
    grid-row: span 5;
  }

  .pf-v5-l-grid>.pf-m-6-row-on-2xl {
    grid-row: span 6;
  }

  .pf-v5-l-grid>.pf-m-7-row-on-2xl {
    grid-row: span 7;
  }

  .pf-v5-l-grid>.pf-m-8-row-on-2xl {
    grid-row: span 8;
  }

  .pf-v5-l-grid>.pf-m-9-row-on-2xl {
    grid-row: span 9;
  }

  .pf-v5-l-grid>.pf-m-10-row-on-2xl {
    grid-row: span 10;
  }

  .pf-v5-l-grid>.pf-m-11-row-on-2xl {
    grid-row: span 11;
  }

  .pf-v5-l-grid>.pf-m-12-row-on-2xl {
    grid-row: span 12;
  }
}

.pf-v5-l-grid.pf-m-gutter {
  grid-gap: var(--pf-v5-l-grid--m-gutter--GridGap);
}

.pf-v5-l-level {
  --pf-v5-l-level--m-gutter--Gap: var(--pf-v5-global--gutter);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.pf-v5-l-level.pf-m-gutter {
  gap: var(--pf-v5-l-level--m-gutter--Gap);
}

.pf-v5-l-split {
  --pf-v5-l-stack--m-gutter--Gap: var(--pf-v5-global--gutter);
  display: flex;
  padding: 0;
  margin: 0;
}

.pf-v5-l-split.pf-m-wrap {
  flex-wrap: wrap;
}

.pf-v5-l-split__item.pf-m-fill {
  flex-grow: 1;
}

.pf-v5-l-split.pf-m-gutter {
  gap: var(--pf-v5-l-stack--m-gutter--Gap);
}

.pf-v5-l-stack {
  --pf-v5-l-stack--m-gutter--Gap: var(--pf-v5-global--gutter);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.pf-v5-l-stack__item.pf-m-fill {
  flex-grow: 1;
}

.pf-v5-l-stack.pf-m-gutter {
  gap: var(--pf-v5-l-stack--m-gutter--Gap);
}

.pf-v5-chart svg g[clip-path] {
  mix-blend-mode: multiply;
}

:where(:root) {
  --pf-v5-chart-color-blue-100: #8bc1f7;
  --pf-v5-chart-color-blue-200: #519de9;
  --pf-v5-chart-color-blue-300: #06c;
  --pf-v5-chart-color-blue-400: #004b95;
  --pf-v5-chart-color-blue-500: #002f5d;
  --pf-v5-chart-color-green-100: #bde2b9;
  --pf-v5-chart-color-green-200: #7cc674;
  --pf-v5-chart-color-green-300: #4cb140;
  --pf-v5-chart-color-green-400: #38812f;
  --pf-v5-chart-color-green-500: #23511e;
  --pf-v5-chart-color-cyan-100: #a2d9d9;
  --pf-v5-chart-color-cyan-200: #73c5c5;
  --pf-v5-chart-color-cyan-300: #009596;
  --pf-v5-chart-color-cyan-400: #005f60;
  --pf-v5-chart-color-cyan-500: #003737;
  --pf-v5-chart-color-purple-100: #b2b0ea;
  --pf-v5-chart-color-purple-200: #8481dd;
  --pf-v5-chart-color-purple-300: #5752d1;
  --pf-v5-chart-color-purple-400: #3c3d99;
  --pf-v5-chart-color-purple-500: #2a265f;
  --pf-v5-chart-color-gold-100: #f9e0a2;
  --pf-v5-chart-color-gold-200: #f6d173;
  --pf-v5-chart-color-gold-300: #f4c145;
  --pf-v5-chart-color-gold-400: #f0ab00;
  --pf-v5-chart-color-gold-500: #c58c00;
  --pf-v5-chart-color-orange-100: #f4b678;
  --pf-v5-chart-color-orange-200: #ef9234;
  --pf-v5-chart-color-orange-300: #ec7a08;
  --pf-v5-chart-color-orange-400: #c46100;
  --pf-v5-chart-color-orange-500: #8f4700;
  --pf-v5-chart-color-red-100: #c9190b;
  --pf-v5-chart-color-red-200: #a30000;
  --pf-v5-chart-color-red-300: #7d1007;
  --pf-v5-chart-color-red-400: #470000;
  --pf-v5-chart-color-red-500: #2c0000;
  --pf-v5-chart-color-black-100: #aaabac;
  --pf-v5-chart-color-black-200: #868789;
  --pf-v5-chart-color-black-300: #57585a;
  --pf-v5-chart-color-black-400: #444548;
  --pf-v5-chart-color-black-500: #36373a;
  --pf-v5-chart-global--FontSize--xs: 12;
  --pf-v5-chart-global--FontSize--sm: 14;
  --pf-v5-chart-global--FontSize--lg: 18;
  --pf-v5-chart-global--FontSize--2xl: 24;
  --pf-v5-chart-global--FontFamily: "RedHatText", helvetica, arial, sans-serif;
  --pf-v5-chart-global--letter-spacing: normal;
  --pf-v5-chart-global--label--Padding: 10;
  --pf-v5-chart-global--label--Margin: 8;
  --pf-v5-chart-global--label--stroke: transparent;
  --pf-v5-chart-global--label--text-anchor: middle;
  --pf-v5-chart-global--label--stroke--Width: 0;
  --pf-v5-chart-global--label--Fill: #0f1214;
  --pf-v5-chart-global--layout--Padding: 50;
  --pf-v5-chart-global--layout--Height: 300;
  --pf-v5-chart-global--layout--Width: 450;
  --pf-v5-chart-global--stroke--Width--xs: 1;
  --pf-v5-chart-global--stroke--Width--sm: 2;
  --pf-v5-chart-global--BorderWidth--xs: 1;
  --pf-v5-chart-global--BorderWidth--sm: 2;
  --pf-v5-chart-global--BorderWidth--lg: 8;
  --pf-v5-chart-global--stroke-line-cap: round;
  --pf-v5-chart-global--stroke-line-join: round;
  --pf-v5-chart-global--danger--Color--100: #fe5142;
  --pf-v5-chart-global--warning--Color--100: #ec7a08;
  --pf-v5-chart-global--warning--Color--200: #f0ab00;
  --pf-v5-chart-global--success--Color--100: #06c;
  --pf-v5-chart-global--Fill--Color--900: #0f1214;
  --pf-v5-chart-global--Fill--Color--700: #26292d;
  --pf-v5-chart-global--Fill--Color--500: #444548;
  --pf-v5-chart-global--Fill--Color--400: #57585a;
  --pf-v5-chart-global--Fill--Color--300: #868789;
  --pf-v5-chart-global--Fill--Color--200: #aaabac;
  --pf-v5-chart-global--Fill--Color--white: #fff;
  --pf-v5-chart-area--Opacity: 0.3;
  --pf-v5-chart-area--stroke--Width: 2;
  --pf-v5-chart-area--data--Fill: #0f1214;
  --pf-v5-chart-axis--axis--stroke--Width: 1;
  --pf-v5-chart-axis--axis--stroke--Color: #868789;
  --pf-v5-chart-axis--axis--Fill: transparent;
  --pf-v5-chart-axis--axis-label--Padding: 40;
  --pf-v5-chart-axis--axis-label--stroke--Color: transparent;
  --pf-v5-chart-axis--grid--Fill: none;
  --pf-v5-chart-axis--grid--stroke--Color: #868789;
  --pf-v5-chart-axis--grid--PointerEvents: painted;
  --pf-v5-chart-axis--tick--Fill: transparent;
  --pf-v5-chart-axis--tick--Size: 5;
  --pf-v5-chart-axis--tick--Width: 1;
  --pf-v5-chart-axis--tick--stroke--Color: #868789;
  --pf-v5-chart-axis--tick-label--Fill: #26292d;
  --pf-v5-chart-bar--Width: 10;
  --pf-v5-chart-bar--data--stroke: none;
  --pf-v5-chart-bar--data--Fill: #0f1214;
  --pf-v5-chart-bar--data--Padding: 8;
  --pf-v5-chart-bar--data-stroke--Width: 0;
  --pf-v5-chart-boxplot--max--Padding: 8;
  --pf-v5-chart-boxplot--max--stroke--Color: #0f1214;
  --pf-v5-chart-boxplot--max--stroke--Width: 1;
  --pf-v5-chart-boxplot--median--Padding: 8;
  --pf-v5-chart-boxplot--median--stroke--Color: #0f1214;
  --pf-v5-chart-boxplot--median--stroke--Width: 1;
  --pf-v5-chart-boxplot--min--Padding: 8;
  --pf-v5-chart-boxplot--min--stroke--Width: 1;
  --pf-v5-chart-boxplot--min--stroke--Color: #0f1214;
  --pf-v5-chart-boxplot--lower-quartile--Padding: 8;
  --pf-v5-chart-boxplot--lower-quartile--Fill: #444548;
  --pf-v5-chart-boxplot--upper-quartile--Padding: 8;
  --pf-v5-chart-boxplot--upper-quartile--Fill: #444548;
  --pf-v5-chart-boxplot--box--Width: 20;
  --pf-v5-chart-bullet--axis--tick--count: 5;
  --pf-v5-chart-bullet--comparative-measure--Fill--Color: #26292d;
  --pf-v5-chart-bullet--comparative-measure--stroke--Color: #26292d;
  --pf-v5-chart-bullet--comparative-measure--stroke--Width: 1;
  --pf-v5-chart-bullet--comparative-measure--error--Fill--Color: #fe5142;
  --pf-v5-chart-bullet--comparative-measure--error--stroke--Color: #fe5142;
  --pf-v5-chart-bullet--comparative-measure--error--stroke--Width: 2;
  --pf-v5-chart-bullet--comparative-measure--error--Width: 30;
  --pf-v5-chart-bullet--comparative-measure--warning--Fill--Color: #ec7a08;
  --pf-v5-chart-bullet--comparative-measure--warning--stroke--Color: #ec7a08;
  --pf-v5-chart-bullet--comparative-measure--warning--stroke--Width: 2;
  --pf-v5-chart-bullet--comparative-measure--warning--Width: 30;
  --pf-v5-chart-bullet--comparative-measure--Width: 30;
  --pf-v5-chart-bullet--group-title--divider--Fill--Color: #aaabac;
  --pf-v5-chart-bullet--group-title--divider--stroke--Color: #aaabac;
  --pf-v5-chart-bullet--group-title--divider--stroke--Width: 2;
  --pf-v5-chart-bullet--Height: 140;
  --pf-v5-chart-bullet--label--title--Fill: #0f1214;
  --pf-v5-chart-bullet--label--grouptitle--Fill: #0f1214;
  --pf-v5-chart-bullet--label--subtitle--Fill: #57585a;
  --pf-v5-chart-bullet--primary-measure--dot--size: 6;
  --pf-v5-chart-bullet--primary-measure--segmented--Width: 9;
  --pf-v5-chart-bullet--negative-measure--ColorScale--100: #c9190b;
  --pf-v5-chart-bullet--negative-measure--ColorScale--200: #a30000;
  --pf-v5-chart-bullet--negative-measure--ColorScale--300: #7d1007;
  --pf-v5-chart-bullet--negative-measure--ColorScale--400: #470000;
  --pf-v5-chart-bullet--negative-measure--ColorScale--500: #2c0000;
  --pf-v5-chart-bullet--qualitative-range--Width: 30;
  --pf-v5-chart-bullet--qualitative-range--ColorScale--100: #aaabac;
  --pf-v5-chart-bullet--qualitative-range--ColorScale--200: #868789;
  --pf-v5-chart-bullet--qualitative-range--ColorScale--300: #57585a;
  --pf-v5-chart-bullet--qualitative-range--ColorScale--400: #444548;
  --pf-v5-chart-bullet--qualitative-range--ColorScale--500: #36373a;
  --pf-v5-chart-candelstick--data--stroke--Width: 1;
  --pf-v5-chart-candelstick--data--stroke--Color: #0f1214;
  --pf-v5-chart-candelstick--candle--positive--Color: #fff;
  --pf-v5-chart-candelstick--candle--negative--Color: #0f1214;
  --pf-v5-chart-container--cursor--line--Fill: #0f1214;
  --pf-v5-chart-donut--label--title--Fill: #0f1214;
  --pf-v5-chart-donut--label--subtitle--Fill: #57585a;
  --pf-v5-chart-donut--label--subtitle--position: center;
  --pf-v5-chart-donut--pie--Height: 230;
  --pf-v5-chart-donut--pie--angle--Padding: 1;
  --pf-v5-chart-donut--pie--Padding: 20;
  --pf-v5-chart-donut--pie--Width: 230;
  --pf-v5-chart-donut--threshold--first--Color: #aaabac;
  --pf-v5-chart-donut--threshold--second--Color: #868789;
  --pf-v5-chart-donut--threshold--third--Color: #57585a;
  --pf-v5-chart-donut--threshold--warning--Color: #f0ab00;
  --pf-v5-chart-donut--threshold--danger--Color: #fe5142;
  --pf-v5-chart-donut--threshold--dynamic--pie--Height: 202;
  --pf-v5-chart-donut--threshold--dynamic--pie--Padding: 20;
  --pf-v5-chart-donut--threshold--dynamic--pie--Width: 202;
  --pf-v5-chart-donut--threshold--static--pie--Height: 230;
  --pf-v5-chart-donut--threshold--static--pie--angle--Padding: 1;
  --pf-v5-chart-donut--threshold--static--pie--Padding: 20;
  --pf-v5-chart-donut--threshold--static--pie--Width: 230;
  --pf-v5-chart-donut--utilization--dynamic--pie--Height: 230;
  --pf-v5-chart-donut--utilization--dynamic--pie--angle--Padding: 1;
  --pf-v5-chart-donut--utilization--dynamic--pie--Padding: 20;
  --pf-v5-chart-donut--utilization--dynamic--pie--Width: 230;
  --pf-v5-chart-donut--utilization--static--pie--Padding: 20;
  --pf-v5-chart-errorbar--BorderWidth: 8;
  --pf-v5-chart-errorbar--data--Fill: transparent;
  --pf-v5-chart-errorbar--data--Opacity: 1;
  --pf-v5-chart-errorbar--data-stroke--Width: 2;
  --pf-v5-chart-errorbar--data-stroke--Color: #0f1214;
  --pf-v5-chart-legend--gutter--Width: 20;
  --pf-v5-chart-legend--orientation: horizontal;
  --pf-v5-chart-legend--position: right;
  --pf-v5-chart-legend--title--orientation: top;
  --pf-v5-chart-legend--data--type: square;
  --pf-v5-chart-legend--title--Padding: 2;
  --pf-v5-chart-legend--Margin: 16;
  --pf-v5-chart-line--data--Fill: transparent;
  --pf-v5-chart-line--data--Opacity: 1;
  --pf-v5-chart-line--data--stroke--Width: 2;
  --pf-v5-chart-line--data--stroke--Color: #0f1214;
  --pf-v5-chart-pie--Padding: 20;
  --pf-v5-chart-pie--data--Padding: 8;
  --pf-v5-chart-pie--data--stroke--Width: 1;
  --pf-v5-chart-pie--data--stroke--Color: transparent;
  --pf-v5-chart-pie--labels--Padding: 8;
  --pf-v5-chart-pie--Height: 230;
  --pf-v5-chart-pie--Width: 230;
  --pf-v5-chart-scatter--data--stroke--Color: transparent;
  --pf-v5-chart-scatter--data--stroke--Width: 0;
  --pf-v5-chart-scatter--data--Opacity: 1;
  --pf-v5-chart-scatter--data--Fill: #0f1214;
  --pf-v5-chart-scatter--active--size: 5;
  --pf-v5-chart-scatter--size: 3;
  --pf-v5-chart-stack--data--stroke--Width: 1;
  --pf-v5-chart-threshold--stroke-dash-array: 4,2;
  --pf-v5-chart-threshold--stroke--Width: 1.5;
  --pf-v5-chart-tooltip--corner-radius: 0;
  --pf-v5-chart-tooltip--pointer-length: 10;
  --pf-v5-chart-tooltip--Fill: #aaabac;
  --pf-v5-chart-tooltip--flyoutStyle--corner-radius: 0;
  --pf-v5-chart-tooltip--flyoutStyle--stroke--Width: 0;
  --pf-v5-chart-tooltip--flyoutStyle--PointerEvents: none;
  --pf-v5-chart-tooltip--flyoutStyle--stroke--Color: #0f1214;
  --pf-v5-chart-tooltip--flyoutStyle--Fill: #0f1214;
  --pf-v5-chart-tooltip--pointer--Width: 20;
  --pf-v5-chart-tooltip--Padding: 8;
  --pf-v5-chart-tooltip--PointerEvents: none;
  --pf-v5-chart-voronoi--data--Fill: transparent;
  --pf-v5-chart-voronoi--data--stroke--Color: transparent;
  --pf-v5-chart-voronoi--data--stroke--Width: 0;
  --pf-v5-chart-voronoi--labels--Padding: 8;
  --pf-v5-chart-voronoi--labels--Fill: #aaabac;
  --pf-v5-chart-voronoi--labels--PointerEvents: none;
  --pf-v5-chart-voronoi--flyout--stroke--Width: 1;
  --pf-v5-chart-voronoi--flyout--PointerEvents: none;
  --pf-v5-chart-voronoi--flyout--stroke--Color: #0f1214;
  --pf-v5-chart-voronoi--flyout--stroke--Fill: #0f1214;
  --pf-v5-chart-voronoi--flyout--PointerEvents: none;
  --pf-v5-chart-theme--blue--ColorScale--100: #06c;
  --pf-v5-chart-theme--blue--ColorScale--200: #8bc1f7;
  --pf-v5-chart-theme--blue--ColorScale--300: #002f5d;
  --pf-v5-chart-theme--blue--ColorScale--400: #519de9;
  --pf-v5-chart-theme--blue--ColorScale--500: #004b95;
  --pf-v5-chart-theme--cyan--ColorScale--100: #009596;
  --pf-v5-chart-theme--cyan--ColorScale--200: #a2d9d9;
  --pf-v5-chart-theme--cyan--ColorScale--300: #003737;
  --pf-v5-chart-theme--cyan--ColorScale--400: #73c5c5;
  --pf-v5-chart-theme--cyan--ColorScale--500: #005f60;
  --pf-v5-chart-theme--gold--ColorScale--100: #f4c145;
  --pf-v5-chart-theme--gold--ColorScale--200: #f9e0a2;
  --pf-v5-chart-theme--gold--ColorScale--300: #c58c00;
  --pf-v5-chart-theme--gold--ColorScale--400: #f6d173;
  --pf-v5-chart-theme--gold--ColorScale--500: #f0ab00;
  --pf-v5-chart-theme--gray--ColorScale--100: #57585a;
  --pf-v5-chart-theme--gray--ColorScale--200: #aaabac;
  --pf-v5-chart-theme--gray--ColorScale--300: #36373a;
  --pf-v5-chart-theme--gray--ColorScale--400: #868789;
  --pf-v5-chart-theme--gray--ColorScale--500: #444548;
  --pf-v5-chart-theme--green--ColorScale--100: #4cb140;
  --pf-v5-chart-theme--green--ColorScale--200: #bde2b9;
  --pf-v5-chart-theme--green--ColorScale--300: #23511e;
  --pf-v5-chart-theme--green--ColorScale--400: #7cc674;
  --pf-v5-chart-theme--green--ColorScale--500: #38812f;
  --pf-v5-chart-theme--multi-color-ordered--ColorScale--100: #06c;
  --pf-v5-chart-theme--multi-color-ordered--ColorScale--200: #4cb140;
  --pf-v5-chart-theme--multi-color-ordered--ColorScale--300: #009596;
  --pf-v5-chart-theme--multi-color-ordered--ColorScale--400: #f4c145;
  --pf-v5-chart-theme--multi-color-ordered--ColorScale--500: #ec7a08;
  --pf-v5-chart-theme--multi-color-ordered--ColorScale--600: #8bc1f7;
  --pf-v5-chart-theme--multi-color-ordered--ColorScale--700: #23511e;
  --pf-v5-chart-theme--multi-color-ordered--ColorScale--800: #a2d9d9;
  --pf-v5-chart-theme--multi-color-ordered--ColorScale--900: #f9e0a2;
  --pf-v5-chart-theme--multi-color-ordered--ColorScale--1000: #8f4700;
  --pf-v5-chart-theme--multi-color-ordered--ColorScale--1100: #002f5d;
  --pf-v5-chart-theme--multi-color-ordered--ColorScale--1200: #bde2b9;
  --pf-v5-chart-theme--multi-color-ordered--ColorScale--1300: #003737;
  --pf-v5-chart-theme--multi-color-ordered--ColorScale--1400: #c58c00;
  --pf-v5-chart-theme--multi-color-ordered--ColorScale--1500: #f4b678;
  --pf-v5-chart-theme--multi-color-ordered--ColorScale--1600: #519de9;
  --pf-v5-chart-theme--multi-color-ordered--ColorScale--1700: #38812f;
  --pf-v5-chart-theme--multi-color-ordered--ColorScale--1800: #73c5c5;
  --pf-v5-chart-theme--multi-color-ordered--ColorScale--1900: #f6d173;
  --pf-v5-chart-theme--multi-color-ordered--ColorScale--2000: #c46100;
  --pf-v5-chart-theme--multi-color-ordered--ColorScale--2100: #004b95;
  --pf-v5-chart-theme--multi-color-ordered--ColorScale--2200: #7cc674;
  --pf-v5-chart-theme--multi-color-ordered--ColorScale--2300: #005f60;
  --pf-v5-chart-theme--multi-color-ordered--ColorScale--2400: #f0ab00;
  --pf-v5-chart-theme--multi-color-ordered--ColorScale--2500: #ef9234;
  --pf-v5-chart-theme--multi-color-unordered--ColorScale--100: #06c;
  --pf-v5-chart-theme--multi-color-unordered--ColorScale--200: #f4c145;
  --pf-v5-chart-theme--multi-color-unordered--ColorScale--300: #4cb140;
  --pf-v5-chart-theme--multi-color-unordered--ColorScale--400: #5752d1;
  --pf-v5-chart-theme--multi-color-unordered--ColorScale--500: #ec7a08;
  --pf-v5-chart-theme--multi-color-unordered--ColorScale--600: #009596;
  --pf-v5-chart-theme--multi-color-unordered--ColorScale--700: #57585a;
  --pf-v5-chart-theme--multi-color-unordered--ColorScale--800: #8bc1f7;
  --pf-v5-chart-theme--multi-color-unordered--ColorScale--900: #c58c00;
  --pf-v5-chart-theme--multi-color-unordered--ColorScale--1000: #bde2b9;
  --pf-v5-chart-theme--multi-color-unordered--ColorScale--1100: #2a265f;
  --pf-v5-chart-theme--multi-color-unordered--ColorScale--1200: #f4b678;
  --pf-v5-chart-theme--multi-color-unordered--ColorScale--1300: #003737;
  --pf-v5-chart-theme--multi-color-unordered--ColorScale--1400: #aaabac;
  --pf-v5-chart-theme--multi-color-unordered--ColorScale--1500: #002f5d;
  --pf-v5-chart-theme--multi-color-unordered--ColorScale--1600: #f9e0a2;
  --pf-v5-chart-theme--multi-color-unordered--ColorScale--1700: #23511e;
  --pf-v5-chart-theme--multi-color-unordered--ColorScale--1800: #b2b0ea;
  --pf-v5-chart-theme--multi-color-unordered--ColorScale--1900: #8f4700;
  --pf-v5-chart-theme--multi-color-unordered--ColorScale--2000: #a2d9d9;
  --pf-v5-chart-theme--multi-color-unordered--ColorScale--2100: #36373a;
  --pf-v5-chart-theme--multi-color-unordered--ColorScale--2200: #519de9;
  --pf-v5-chart-theme--multi-color-unordered--ColorScale--2300: #f0ab00;
  --pf-v5-chart-theme--multi-color-unordered--ColorScale--2400: #7cc674;
  --pf-v5-chart-theme--multi-color-unordered--ColorScale--2500: #3c3d99;
  --pf-v5-chart-theme--multi-color-unordered--ColorScale--2600: #ef9234;
  --pf-v5-chart-theme--multi-color-unordered--ColorScale--2700: #005f60;
  --pf-v5-chart-theme--multi-color-unordered--ColorScale--2800: #868789;
  --pf-v5-chart-theme--multi-color-unordered--ColorScale--2900: #004b95;
  --pf-v5-chart-theme--multi-color-unordered--ColorScale--3000: #f6d173;
  --pf-v5-chart-theme--multi-color-unordered--ColorScale--3100: #38812f;
  --pf-v5-chart-theme--multi-color-unordered--ColorScale--3200: #8481dd;
  --pf-v5-chart-theme--multi-color-unordered--ColorScale--3300: #c46100;
  --pf-v5-chart-theme--multi-color-unordered--ColorScale--3400: #73c5c5;
  --pf-v5-chart-theme--multi-color-unordered--ColorScale--3500: #444548;
  --pf-v5-chart-theme--orange--ColorScale--100: #ec7a08;
  --pf-v5-chart-theme--orange--ColorScale--200: #f4b678;
  --pf-v5-chart-theme--orange--ColorScale--300: #8f4700;
  --pf-v5-chart-theme--orange--ColorScale--400: #ef9234;
  --pf-v5-chart-theme--orange--ColorScale--500: #c46100;
  --pf-v5-chart-theme--purple--ColorScale--100: #5752d1;
  --pf-v5-chart-theme--purple--ColorScale--200: #b2b0ea;
  --pf-v5-chart-theme--purple--ColorScale--300: #2a265f;
  --pf-v5-chart-theme--purple--ColorScale--400: #8481dd;
  --pf-v5-chart-theme--purple--ColorScale--500: #3c3d99;
}

.pf-v5-t-light,
.pf-v5-c-accordion,
.pf-v5-c-alert,
.pf-v5-c-banner.pf-m-blue,
.pf-v5-c-banner.pf-m-gold,
.pf-v5-c-calendar-month,
.pf-v5-c-chip,
.pf-v5-c-chip-group,
.pf-v5-c-context-selector__menu,
.pf-v5-c-data-list,
.pf-v5-c-form-control,
.pf-v5-c-masthead.pf-m-light,
.pf-v5-c-masthead.pf-m-light-200,
.pf-v5-c-menu,
.pf-v5-c-page__sidebar.pf-m-light,
.pf-v5-c-select,
.pf-v5-c-table {
  --pf-v5-global--Color--100: var(--pf-v5-global--Color--dark-100);
  --pf-v5-global--Color--200: var(--pf-v5-global--Color--dark-200);
  --pf-v5-global--BorderColor--100: var(--pf-v5-global--BorderColor--dark-100);
  --pf-v5-global--primary-color--100: var(--pf-v5-global--primary-color--dark-100);
  --pf-v5-global--link--Color: var(--pf-v5-global--link--Color--dark);
  --pf-v5-global--link--Color--hover: var(--pf-v5-global--link--Color--dark--hover);
  --pf-v5-global--BackgroundColor--100: var(--pf-v5-global--BackgroundColor--light-100);
  --pf-v5-global--icon--Color--light: var(--pf-v5-global--icon--Color--light--dark);
  --pf-v5-global--icon--Color--dark: var(--pf-v5-global--icon--Color--dark--dark);
}

.pf-v5-t-dark,
.pf-v5-c-about-modal-box,
.pf-v5-c-banner,
.pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main,
.pf-v5-c-login__header,
.pf-v5-c-login__footer,
.pf-v5-c-masthead,
.pf-v5-c-masthead .pf-v5-c-button.pf-m-plain,
.pf-v5-c-page__header,
.pf-v5-c-page__sidebar,
.pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector,
.pf-v5-c-page__main-section[class*=pf-m-dark-],
.pf-v5-c-wizard__header {
  --pf-v5-global--Color--100: var(--pf-v5-global--Color--light-100);
  --pf-v5-global--Color--200: var(--pf-v5-global--Color--light-200);
  --pf-v5-global--BorderColor--100: var(--pf-v5-global--BorderColor--light-100);
  --pf-v5-global--primary-color--100: var(--pf-v5-global--primary-color--light-100);
  --pf-v5-global--link--Color: var(--pf-v5-global--link--Color--light);
  --pf-v5-global--link--Color--hover: var(--pf-v5-global--link--Color--light);
  --pf-v5-global--BackgroundColor--100: var(--pf-v5-global--BackgroundColor--dark-100);
  --pf-v5-global--icon--Color--light: var(--pf-v5-global--icon--Color--light--light);
  --pf-v5-global--icon--Color--dark: var(--pf-v5-global--icon--Color--dark--light);
}

.pf-v5-t-dark .pf-v5-c-button,
.pf-v5-c-about-modal-box .pf-v5-c-button,
.pf-v5-c-banner .pf-v5-c-button,
.pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
.pf-v5-c-login__header .pf-v5-c-button,
.pf-v5-c-login__footer .pf-v5-c-button,
.pf-v5-c-masthead .pf-v5-c-button,
.pf-v5-c-masthead .pf-v5-c-button.pf-m-plain .pf-v5-c-button,
.pf-v5-c-page__header .pf-v5-c-button,
.pf-v5-c-page__sidebar .pf-v5-c-button,
.pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
.pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
.pf-v5-c-wizard__header .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--dark-100);
}

.pf-v5-c-data-list__item-action,
.pf-v5-c-toolbar__group,
.pf-v5-c-toolbar__item,
.pf-v5-c-toolbar__content,
.pf-v5-c-toolbar__content-section,
.pf-v5-c-divider,
.pf-v5-c-menu__list,
.pf-v5-c-menu__list-item,
.pf-v5-c-menu__group,
.pf-v5-c-page__header-tools-group,
.pf-v5-c-page__header-tools-item,
.pf-v5-c-table tr:where(.pf-v5-c-table__tr)>* {
  --pf-v5-hidden-visible--hidden--Display: none;
  --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  display: var(--pf-v5-hidden-visible--Display);
}

.pf-m-hidden.pf-v5-c-data-list__item-action,
.pf-m-hidden.pf-v5-c-toolbar__group,
.pf-m-hidden.pf-v5-c-toolbar__item,
.pf-m-hidden.pf-v5-c-toolbar__content,
.pf-m-hidden.pf-v5-c-toolbar__content-section,
.pf-m-hidden.pf-v5-c-divider,
.pf-m-hidden.pf-v5-c-menu__list,
.pf-m-hidden.pf-v5-c-menu__list-item,
.pf-m-hidden.pf-v5-c-menu__group,
.pf-m-hidden.pf-v5-c-page__header-tools-group,
.pf-m-hidden.pf-v5-c-page__header-tools-item,
.pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-hidden {
  --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
}

@media screen and (min-width: 576px) {
  .pf-m-hidden-on-sm.pf-v5-c-data-list__item-action,
  .pf-m-hidden-on-sm.pf-v5-c-toolbar__group,
  .pf-m-hidden-on-sm.pf-v5-c-toolbar__item,
  .pf-m-hidden-on-sm.pf-v5-c-toolbar__content,
  .pf-m-hidden-on-sm.pf-v5-c-toolbar__content-section,
  .pf-m-hidden-on-sm.pf-v5-c-divider,
  .pf-m-hidden-on-sm.pf-v5-c-menu__list,
  .pf-m-hidden-on-sm.pf-v5-c-menu__list-item,
  .pf-m-hidden-on-sm.pf-v5-c-menu__group,
  .pf-m-hidden-on-sm.pf-v5-c-page__header-tools-group,
  .pf-m-hidden-on-sm.pf-v5-c-page__header-tools-item,
  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-hidden-on-sm {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-m-visible-on-sm.pf-v5-c-data-list__item-action,
  .pf-m-visible-on-sm.pf-v5-c-toolbar__group,
  .pf-m-visible-on-sm.pf-v5-c-toolbar__item,
  .pf-m-visible-on-sm.pf-v5-c-toolbar__content,
  .pf-m-visible-on-sm.pf-v5-c-toolbar__content-section,
  .pf-m-visible-on-sm.pf-v5-c-divider,
  .pf-m-visible-on-sm.pf-v5-c-menu__list,
  .pf-m-visible-on-sm.pf-v5-c-menu__list-item,
  .pf-m-visible-on-sm.pf-v5-c-menu__group,
  .pf-m-visible-on-sm.pf-v5-c-page__header-tools-group,
  .pf-m-visible-on-sm.pf-v5-c-page__header-tools-item,
  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-visible-on-sm {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (min-width: 768px) {
  .pf-m-hidden-on-md.pf-v5-c-data-list__item-action,
  .pf-m-hidden-on-md.pf-v5-c-toolbar__group,
  .pf-m-hidden-on-md.pf-v5-c-toolbar__item,
  .pf-m-hidden-on-md.pf-v5-c-toolbar__content,
  .pf-m-hidden-on-md.pf-v5-c-toolbar__content-section,
  .pf-m-hidden-on-md.pf-v5-c-divider,
  .pf-m-hidden-on-md.pf-v5-c-menu__list,
  .pf-m-hidden-on-md.pf-v5-c-menu__list-item,
  .pf-m-hidden-on-md.pf-v5-c-menu__group,
  .pf-m-hidden-on-md.pf-v5-c-page__header-tools-group,
  .pf-m-hidden-on-md.pf-v5-c-page__header-tools-item,
  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-hidden-on-md {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-m-visible-on-md.pf-v5-c-data-list__item-action,
  .pf-m-visible-on-md.pf-v5-c-toolbar__group,
  .pf-m-visible-on-md.pf-v5-c-toolbar__item,
  .pf-m-visible-on-md.pf-v5-c-toolbar__content,
  .pf-m-visible-on-md.pf-v5-c-toolbar__content-section,
  .pf-m-visible-on-md.pf-v5-c-divider,
  .pf-m-visible-on-md.pf-v5-c-menu__list,
  .pf-m-visible-on-md.pf-v5-c-menu__list-item,
  .pf-m-visible-on-md.pf-v5-c-menu__group,
  .pf-m-visible-on-md.pf-v5-c-page__header-tools-group,
  .pf-m-visible-on-md.pf-v5-c-page__header-tools-item,
  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-visible-on-md {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (min-width: 992px) {
  .pf-m-hidden-on-lg.pf-v5-c-data-list__item-action,
  .pf-m-hidden-on-lg.pf-v5-c-toolbar__group,
  .pf-m-hidden-on-lg.pf-v5-c-toolbar__item,
  .pf-m-hidden-on-lg.pf-v5-c-toolbar__content,
  .pf-m-hidden-on-lg.pf-v5-c-toolbar__content-section,
  .pf-m-hidden-on-lg.pf-v5-c-divider,
  .pf-m-hidden-on-lg.pf-v5-c-menu__list,
  .pf-m-hidden-on-lg.pf-v5-c-menu__list-item,
  .pf-m-hidden-on-lg.pf-v5-c-menu__group,
  .pf-m-hidden-on-lg.pf-v5-c-page__header-tools-group,
  .pf-m-hidden-on-lg.pf-v5-c-page__header-tools-item,
  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-hidden-on-lg {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-m-visible-on-lg.pf-v5-c-data-list__item-action,
  .pf-m-visible-on-lg.pf-v5-c-toolbar__group,
  .pf-m-visible-on-lg.pf-v5-c-toolbar__item,
  .pf-m-visible-on-lg.pf-v5-c-toolbar__content,
  .pf-m-visible-on-lg.pf-v5-c-toolbar__content-section,
  .pf-m-visible-on-lg.pf-v5-c-divider,
  .pf-m-visible-on-lg.pf-v5-c-menu__list,
  .pf-m-visible-on-lg.pf-v5-c-menu__list-item,
  .pf-m-visible-on-lg.pf-v5-c-menu__group,
  .pf-m-visible-on-lg.pf-v5-c-page__header-tools-group,
  .pf-m-visible-on-lg.pf-v5-c-page__header-tools-item,
  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-visible-on-lg {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (min-width: 1200px) {
  .pf-m-hidden-on-xl.pf-v5-c-data-list__item-action,
  .pf-m-hidden-on-xl.pf-v5-c-toolbar__group,
  .pf-m-hidden-on-xl.pf-v5-c-toolbar__item,
  .pf-m-hidden-on-xl.pf-v5-c-toolbar__content,
  .pf-m-hidden-on-xl.pf-v5-c-toolbar__content-section,
  .pf-m-hidden-on-xl.pf-v5-c-divider,
  .pf-m-hidden-on-xl.pf-v5-c-menu__list,
  .pf-m-hidden-on-xl.pf-v5-c-menu__list-item,
  .pf-m-hidden-on-xl.pf-v5-c-menu__group,
  .pf-m-hidden-on-xl.pf-v5-c-page__header-tools-group,
  .pf-m-hidden-on-xl.pf-v5-c-page__header-tools-item,
  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-hidden-on-xl {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-m-visible-on-xl.pf-v5-c-data-list__item-action,
  .pf-m-visible-on-xl.pf-v5-c-toolbar__group,
  .pf-m-visible-on-xl.pf-v5-c-toolbar__item,
  .pf-m-visible-on-xl.pf-v5-c-toolbar__content,
  .pf-m-visible-on-xl.pf-v5-c-toolbar__content-section,
  .pf-m-visible-on-xl.pf-v5-c-divider,
  .pf-m-visible-on-xl.pf-v5-c-menu__list,
  .pf-m-visible-on-xl.pf-v5-c-menu__list-item,
  .pf-m-visible-on-xl.pf-v5-c-menu__group,
  .pf-m-visible-on-xl.pf-v5-c-page__header-tools-group,
  .pf-m-visible-on-xl.pf-v5-c-page__header-tools-item,
  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-visible-on-xl {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

@media screen and (min-width: 1450px) {
  .pf-m-hidden-on-2xl.pf-v5-c-data-list__item-action,
  .pf-m-hidden-on-2xl.pf-v5-c-toolbar__group,
  .pf-m-hidden-on-2xl.pf-v5-c-toolbar__item,
  .pf-m-hidden-on-2xl.pf-v5-c-toolbar__content,
  .pf-m-hidden-on-2xl.pf-v5-c-toolbar__content-section,
  .pf-m-hidden-on-2xl.pf-v5-c-divider,
  .pf-m-hidden-on-2xl.pf-v5-c-menu__list,
  .pf-m-hidden-on-2xl.pf-v5-c-menu__list-item,
  .pf-m-hidden-on-2xl.pf-v5-c-menu__group,
  .pf-m-hidden-on-2xl.pf-v5-c-page__header-tools-group,
  .pf-m-hidden-on-2xl.pf-v5-c-page__header-tools-item,
  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-hidden-on-2xl {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--hidden--Display);
  }

  .pf-m-visible-on-2xl.pf-v5-c-data-list__item-action,
  .pf-m-visible-on-2xl.pf-v5-c-toolbar__group,
  .pf-m-visible-on-2xl.pf-v5-c-toolbar__item,
  .pf-m-visible-on-2xl.pf-v5-c-toolbar__content,
  .pf-m-visible-on-2xl.pf-v5-c-toolbar__content-section,
  .pf-m-visible-on-2xl.pf-v5-c-divider,
  .pf-m-visible-on-2xl.pf-v5-c-menu__list,
  .pf-m-visible-on-2xl.pf-v5-c-menu__list-item,
  .pf-m-visible-on-2xl.pf-v5-c-menu__group,
  .pf-m-visible-on-2xl.pf-v5-c-page__header-tools-group,
  .pf-m-visible-on-2xl.pf-v5-c-page__header-tools-item,
  .pf-v5-c-table tr:where(.pf-v5-c-table__tr)>.pf-m-visible-on-2xl {
    --pf-v5-hidden-visible--Display: var(--pf-v5-hidden-visible--visible--Display);
  }
}

.pf-v5-c-pagination__nav-page-select .pf-v5-c-form-control>:is(input),
.pf-v5-c-number-input .pf-v5-c-form-control>:is(input) {
  appearance: textfield;
}

.pf-v5-c-pagination__nav-page-select .pf-v5-c-form-control>:is(input)::-webkit-inner-spin-button,
.pf-v5-c-number-input .pf-v5-c-form-control>:is(input)::-webkit-inner-spin-button,
.pf-v5-c-pagination__nav-page-select .pf-v5-c-form-control>:is(input)::-webkit-outer-spin-button,
.pf-v5-c-number-input .pf-v5-c-form-control>:is(input)::-webkit-outer-spin-button {
  margin: 0;
  appearance: none;
}

.pf-v5-u-screen-reader {
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.pf-v5-u-visible {
  position: static;
  overflow: visible;
  clip: auto;
  white-space: normal;
  border: inherit;
}

.pf-v5-u-hidden {
  display: none !important;
}

@media screen and (min-width: 576px) {
  .pf-v5-u-screen-reader-on-sm {
    position: fixed;
    inset-block-start: 0;
    inset-inline-start: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-visible-on-sm {
    position: static;
    overflow: visible;
    clip: auto;
    white-space: normal;
    border: inherit;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-hidden-on-sm {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-screen-reader-on-md {
    position: fixed;
    inset-block-start: 0;
    inset-inline-start: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-visible-on-md {
    position: static;
    overflow: visible;
    clip: auto;
    white-space: normal;
    border: inherit;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-hidden-on-md {
    display: none !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-screen-reader-on-lg {
    position: fixed;
    inset-block-start: 0;
    inset-inline-start: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-visible-on-lg {
    position: static;
    overflow: visible;
    clip: auto;
    white-space: normal;
    border: inherit;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-hidden-on-lg {
    display: none !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-screen-reader-on-xl {
    position: fixed;
    inset-block-start: 0;
    inset-inline-start: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-visible-on-xl {
    position: static;
    overflow: visible;
    clip: auto;
    white-space: normal;
    border: inherit;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-hidden-on-xl {
    display: none !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-screen-reader-on-2xl {
    position: fixed;
    inset-block-start: 0;
    inset-inline-start: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-visible-on-2xl {
    position: static;
    overflow: visible;
    clip: auto;
    white-space: normal;
    border: inherit;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-hidden-on-2xl {
    display: none !important;
  }
}

.pf-v5-u-text-align-left {
  text-align: left !important;
}

.pf-v5-u-text-align-center {
  text-align: center !important;
}

.pf-v5-u-text-align-right {
  text-align: right !important;
}

.pf-v5-u-text-align-justify {
  text-align: justify !important;
}

@media screen and (min-width: 576px) {
  .pf-v5-u-text-align-left-on-sm {
    text-align: left !important;
  }

  .pf-v5-u-text-align-center-on-sm {
    text-align: center !important;
  }

  .pf-v5-u-text-align-right-on-sm {
    text-align: right !important;
  }

  .pf-v5-u-text-align-justify-on-sm {
    text-align: justify !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-text-align-left-on-md {
    text-align: left !important;
  }

  .pf-v5-u-text-align-center-on-md {
    text-align: center !important;
  }

  .pf-v5-u-text-align-right-on-md {
    text-align: right !important;
  }

  .pf-v5-u-text-align-justify-on-md {
    text-align: justify !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-text-align-left-on-lg {
    text-align: left !important;
  }

  .pf-v5-u-text-align-center-on-lg {
    text-align: center !important;
  }

  .pf-v5-u-text-align-right-on-lg {
    text-align: right !important;
  }

  .pf-v5-u-text-align-justify-on-lg {
    text-align: justify !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-text-align-left-on-xl {
    text-align: left !important;
  }

  .pf-v5-u-text-align-center-on-xl {
    text-align: center !important;
  }

  .pf-v5-u-text-align-right-on-xl {
    text-align: right !important;
  }

  .pf-v5-u-text-align-justify-on-xl {
    text-align: justify !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-text-align-left-on-2xl {
    text-align: left !important;
  }

  .pf-v5-u-text-align-center-on-2xl {
    text-align: center !important;
  }

  .pf-v5-u-text-align-right-on-2xl {
    text-align: right !important;
  }

  .pf-v5-u-text-align-justify-on-2xl {
    text-align: justify !important;
  }
}

.pf-v5-u-background-color-100 {
  background-color: var(--pf-v5-global--BackgroundColor--100) !important;
}

.pf-v5-u-background-color-200 {
  background-color: var(--pf-v5-global--BackgroundColor--200) !important;
}

.pf-v5-u-background-color-active-color-100 {
  background-color: var(--pf-v5-global--active-color--100) !important;
}

.pf-v5-u-background-color-active-color-300 {
  background-color: var(--pf-v5-global--active-color--300) !important;
}

.pf-v5-u-background-color-primary-color-200 {
  background-color: var(--pf-v5-global--primary-color--200) !important;
}

.pf-v5-u-background-color-dark-100 {
  background-color: var(--pf-v5-global--BackgroundColor--dark-100) !important;
}

.pf-v5-u-background-color-dark-200 {
  background-color: var(--pf-v5-global--BackgroundColor--dark-200) !important;
}

.pf-v5-u-background-color-dark-300 {
  background-color: var(--pf-v5-global--BackgroundColor--dark-300) !important;
}

.pf-v5-u-background-color-dark-400 {
  background-color: var(--pf-v5-global--BackgroundColor--dark-400) !important;
}

.pf-v5-u-background-color-disabled-color-200 {
  background-color: var(--pf-v5-global--disabled-color--200) !important;
}

.pf-v5-u-background-color-disabled-color-300 {
  background-color: var(--pf-v5-global--disabled-color--300) !important;
}

.pf-v5-u-background-color-default {
  background-color: var(--pf-v5-global--palette--cyan-50) !important;
}

.pf-v5-u-background-color-success {
  background-color: var(--pf-v5-global--palette--green-50) !important;
}

.pf-v5-u-background-color-info {
  background-color: var(--pf-v5-global--palette--blue-50) !important;
}

.pf-v5-u-background-color-warning {
  background-color: var(--pf-v5-global--palette--gold-50) !important;
}

.pf-v5-u-background-color-danger {
  background-color: var(--pf-v5-global--palette--red-50) !important;
}

@media screen and (min-width: 576px) {
  .pf-v5-u-background-color-100-on-sm {
    background-color: var(--pf-v5-global--BackgroundColor--100) !important;
  }

  .pf-v5-u-background-color-200-on-sm {
    background-color: var(--pf-v5-global--BackgroundColor--200) !important;
  }

  .pf-v5-u-background-color-active-color-100-on-sm {
    background-color: var(--pf-v5-global--active-color--100) !important;
  }

  .pf-v5-u-background-color-active-color-300-on-sm {
    background-color: var(--pf-v5-global--active-color--300) !important;
  }

  .pf-v5-u-background-color-primary-color-200-on-sm {
    background-color: var(--pf-v5-global--primary-color--200) !important;
  }

  .pf-v5-u-background-color-dark-100-on-sm {
    background-color: var(--pf-v5-global--BackgroundColor--dark-100) !important;
  }

  .pf-v5-u-background-color-dark-200-on-sm {
    background-color: var(--pf-v5-global--BackgroundColor--dark-200) !important;
  }

  .pf-v5-u-background-color-dark-300-on-sm {
    background-color: var(--pf-v5-global--BackgroundColor--dark-300) !important;
  }

  .pf-v5-u-background-color-dark-400-on-sm {
    background-color: var(--pf-v5-global--BackgroundColor--dark-400) !important;
  }

  .pf-v5-u-background-color-disabled-color-200-on-sm {
    background-color: var(--pf-v5-global--disabled-color--200) !important;
  }

  .pf-v5-u-background-color-disabled-color-300-on-sm {
    background-color: var(--pf-v5-global--disabled-color--300) !important;
  }

  .pf-v5-u-background-color-default-on-sm {
    background-color: var(--pf-v5-global--palette--cyan-50) !important;
  }

  .pf-v5-u-background-color-success-on-sm {
    background-color: var(--pf-v5-global--palette--green-50) !important;
  }

  .pf-v5-u-background-color-info-on-sm {
    background-color: var(--pf-v5-global--palette--blue-50) !important;
  }

  .pf-v5-u-background-color-warning-on-sm {
    background-color: var(--pf-v5-global--palette--gold-50) !important;
  }

  .pf-v5-u-background-color-danger-on-sm {
    background-color: var(--pf-v5-global--palette--red-50) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-background-color-100-on-md {
    background-color: var(--pf-v5-global--BackgroundColor--100) !important;
  }

  .pf-v5-u-background-color-200-on-md {
    background-color: var(--pf-v5-global--BackgroundColor--200) !important;
  }

  .pf-v5-u-background-color-active-color-100-on-md {
    background-color: var(--pf-v5-global--active-color--100) !important;
  }

  .pf-v5-u-background-color-active-color-300-on-md {
    background-color: var(--pf-v5-global--active-color--300) !important;
  }

  .pf-v5-u-background-color-primary-color-200-on-md {
    background-color: var(--pf-v5-global--primary-color--200) !important;
  }

  .pf-v5-u-background-color-dark-100-on-md {
    background-color: var(--pf-v5-global--BackgroundColor--dark-100) !important;
  }

  .pf-v5-u-background-color-dark-200-on-md {
    background-color: var(--pf-v5-global--BackgroundColor--dark-200) !important;
  }

  .pf-v5-u-background-color-dark-300-on-md {
    background-color: var(--pf-v5-global--BackgroundColor--dark-300) !important;
  }

  .pf-v5-u-background-color-dark-400-on-md {
    background-color: var(--pf-v5-global--BackgroundColor--dark-400) !important;
  }

  .pf-v5-u-background-color-disabled-color-200-on-md {
    background-color: var(--pf-v5-global--disabled-color--200) !important;
  }

  .pf-v5-u-background-color-disabled-color-300-on-md {
    background-color: var(--pf-v5-global--disabled-color--300) !important;
  }

  .pf-v5-u-background-color-default-on-md {
    background-color: var(--pf-v5-global--palette--cyan-50) !important;
  }

  .pf-v5-u-background-color-success-on-md {
    background-color: var(--pf-v5-global--palette--green-50) !important;
  }

  .pf-v5-u-background-color-info-on-md {
    background-color: var(--pf-v5-global--palette--blue-50) !important;
  }

  .pf-v5-u-background-color-warning-on-md {
    background-color: var(--pf-v5-global--palette--gold-50) !important;
  }

  .pf-v5-u-background-color-danger-on-md {
    background-color: var(--pf-v5-global--palette--red-50) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-background-color-100-on-lg {
    background-color: var(--pf-v5-global--BackgroundColor--100) !important;
  }

  .pf-v5-u-background-color-200-on-lg {
    background-color: var(--pf-v5-global--BackgroundColor--200) !important;
  }

  .pf-v5-u-background-color-active-color-100-on-lg {
    background-color: var(--pf-v5-global--active-color--100) !important;
  }

  .pf-v5-u-background-color-active-color-300-on-lg {
    background-color: var(--pf-v5-global--active-color--300) !important;
  }

  .pf-v5-u-background-color-primary-color-200-on-lg {
    background-color: var(--pf-v5-global--primary-color--200) !important;
  }

  .pf-v5-u-background-color-dark-100-on-lg {
    background-color: var(--pf-v5-global--BackgroundColor--dark-100) !important;
  }

  .pf-v5-u-background-color-dark-200-on-lg {
    background-color: var(--pf-v5-global--BackgroundColor--dark-200) !important;
  }

  .pf-v5-u-background-color-dark-300-on-lg {
    background-color: var(--pf-v5-global--BackgroundColor--dark-300) !important;
  }

  .pf-v5-u-background-color-dark-400-on-lg {
    background-color: var(--pf-v5-global--BackgroundColor--dark-400) !important;
  }

  .pf-v5-u-background-color-disabled-color-200-on-lg {
    background-color: var(--pf-v5-global--disabled-color--200) !important;
  }

  .pf-v5-u-background-color-disabled-color-300-on-lg {
    background-color: var(--pf-v5-global--disabled-color--300) !important;
  }

  .pf-v5-u-background-color-default-on-lg {
    background-color: var(--pf-v5-global--palette--cyan-50) !important;
  }

  .pf-v5-u-background-color-success-on-lg {
    background-color: var(--pf-v5-global--palette--green-50) !important;
  }

  .pf-v5-u-background-color-info-on-lg {
    background-color: var(--pf-v5-global--palette--blue-50) !important;
  }

  .pf-v5-u-background-color-warning-on-lg {
    background-color: var(--pf-v5-global--palette--gold-50) !important;
  }

  .pf-v5-u-background-color-danger-on-lg {
    background-color: var(--pf-v5-global--palette--red-50) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-background-color-100-on-xl {
    background-color: var(--pf-v5-global--BackgroundColor--100) !important;
  }

  .pf-v5-u-background-color-200-on-xl {
    background-color: var(--pf-v5-global--BackgroundColor--200) !important;
  }

  .pf-v5-u-background-color-active-color-100-on-xl {
    background-color: var(--pf-v5-global--active-color--100) !important;
  }

  .pf-v5-u-background-color-active-color-300-on-xl {
    background-color: var(--pf-v5-global--active-color--300) !important;
  }

  .pf-v5-u-background-color-primary-color-200-on-xl {
    background-color: var(--pf-v5-global--primary-color--200) !important;
  }

  .pf-v5-u-background-color-dark-100-on-xl {
    background-color: var(--pf-v5-global--BackgroundColor--dark-100) !important;
  }

  .pf-v5-u-background-color-dark-200-on-xl {
    background-color: var(--pf-v5-global--BackgroundColor--dark-200) !important;
  }

  .pf-v5-u-background-color-dark-300-on-xl {
    background-color: var(--pf-v5-global--BackgroundColor--dark-300) !important;
  }

  .pf-v5-u-background-color-dark-400-on-xl {
    background-color: var(--pf-v5-global--BackgroundColor--dark-400) !important;
  }

  .pf-v5-u-background-color-disabled-color-200-on-xl {
    background-color: var(--pf-v5-global--disabled-color--200) !important;
  }

  .pf-v5-u-background-color-disabled-color-300-on-xl {
    background-color: var(--pf-v5-global--disabled-color--300) !important;
  }

  .pf-v5-u-background-color-default-on-xl {
    background-color: var(--pf-v5-global--palette--cyan-50) !important;
  }

  .pf-v5-u-background-color-success-on-xl {
    background-color: var(--pf-v5-global--palette--green-50) !important;
  }

  .pf-v5-u-background-color-info-on-xl {
    background-color: var(--pf-v5-global--palette--blue-50) !important;
  }

  .pf-v5-u-background-color-warning-on-xl {
    background-color: var(--pf-v5-global--palette--gold-50) !important;
  }

  .pf-v5-u-background-color-danger-on-xl {
    background-color: var(--pf-v5-global--palette--red-50) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-background-color-100-on-2xl {
    background-color: var(--pf-v5-global--BackgroundColor--100) !important;
  }

  .pf-v5-u-background-color-200-on-2xl {
    background-color: var(--pf-v5-global--BackgroundColor--200) !important;
  }

  .pf-v5-u-background-color-active-color-100-on-2xl {
    background-color: var(--pf-v5-global--active-color--100) !important;
  }

  .pf-v5-u-background-color-active-color-300-on-2xl {
    background-color: var(--pf-v5-global--active-color--300) !important;
  }

  .pf-v5-u-background-color-primary-color-200-on-2xl {
    background-color: var(--pf-v5-global--primary-color--200) !important;
  }

  .pf-v5-u-background-color-dark-100-on-2xl {
    background-color: var(--pf-v5-global--BackgroundColor--dark-100) !important;
  }

  .pf-v5-u-background-color-dark-200-on-2xl {
    background-color: var(--pf-v5-global--BackgroundColor--dark-200) !important;
  }

  .pf-v5-u-background-color-dark-300-on-2xl {
    background-color: var(--pf-v5-global--BackgroundColor--dark-300) !important;
  }

  .pf-v5-u-background-color-dark-400-on-2xl {
    background-color: var(--pf-v5-global--BackgroundColor--dark-400) !important;
  }

  .pf-v5-u-background-color-disabled-color-200-on-2xl {
    background-color: var(--pf-v5-global--disabled-color--200) !important;
  }

  .pf-v5-u-background-color-disabled-color-300-on-2xl {
    background-color: var(--pf-v5-global--disabled-color--300) !important;
  }

  .pf-v5-u-background-color-default-on-2xl {
    background-color: var(--pf-v5-global--palette--cyan-50) !important;
  }

  .pf-v5-u-background-color-success-on-2xl {
    background-color: var(--pf-v5-global--palette--green-50) !important;
  }

  .pf-v5-u-background-color-info-on-2xl {
    background-color: var(--pf-v5-global--palette--blue-50) !important;
  }

  .pf-v5-u-background-color-warning-on-2xl {
    background-color: var(--pf-v5-global--palette--gold-50) !important;
  }

  .pf-v5-u-background-color-danger-on-2xl {
    background-color: var(--pf-v5-global--palette--red-50) !important;
  }
}

:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-u-background-color-default {
  background-color: var(--pf-v5-global--BackgroundColor--100) !important;
}

:where(.pf-v5-theme-dark) .pf-v5-u-background-color-success {
  background-color: var(--pf-v5-global--BackgroundColor--100) !important;
}

:where(.pf-v5-theme-dark) .pf-v5-u-background-color-info {
  background-color: var(--pf-v5-global--BackgroundColor--100) !important;
}

:where(.pf-v5-theme-dark) .pf-v5-u-background-color-warning {
  background-color: var(--pf-v5-global--BackgroundColor--100) !important;
}

:where(.pf-v5-theme-dark) .pf-v5-u-background-color-danger {
  background-color: var(--pf-v5-global--BackgroundColor--100) !important;
}

@media screen and (min-width: 576px) {
  :where(.pf-v5-theme-dark) .pf-v5-u-background-color-default-on-sm {
    background-color: var(--pf-v5-global--BackgroundColor--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-background-color-success-on-sm {
    background-color: var(--pf-v5-global--BackgroundColor--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-background-color-info-on-sm {
    background-color: var(--pf-v5-global--BackgroundColor--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-background-color-warning-on-sm {
    background-color: var(--pf-v5-global--BackgroundColor--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-background-color-danger-on-sm {
    background-color: var(--pf-v5-global--BackgroundColor--100) !important;
  }
}

@media screen and (min-width: 768px) {
  :where(.pf-v5-theme-dark) .pf-v5-u-background-color-default-on-md {
    background-color: var(--pf-v5-global--BackgroundColor--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-background-color-success-on-md {
    background-color: var(--pf-v5-global--BackgroundColor--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-background-color-info-on-md {
    background-color: var(--pf-v5-global--BackgroundColor--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-background-color-warning-on-md {
    background-color: var(--pf-v5-global--BackgroundColor--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-background-color-danger-on-md {
    background-color: var(--pf-v5-global--BackgroundColor--100) !important;
  }
}

@media screen and (min-width: 992px) {
  :where(.pf-v5-theme-dark) .pf-v5-u-background-color-default-on-lg {
    background-color: var(--pf-v5-global--BackgroundColor--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-background-color-success-on-lg {
    background-color: var(--pf-v5-global--BackgroundColor--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-background-color-info-on-lg {
    background-color: var(--pf-v5-global--BackgroundColor--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-background-color-warning-on-lg {
    background-color: var(--pf-v5-global--BackgroundColor--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-background-color-danger-on-lg {
    background-color: var(--pf-v5-global--BackgroundColor--100) !important;
  }
}

@media screen and (min-width: 1200px) {
  :where(.pf-v5-theme-dark) .pf-v5-u-background-color-default-on-xl {
    background-color: var(--pf-v5-global--BackgroundColor--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-background-color-success-on-xl {
    background-color: var(--pf-v5-global--BackgroundColor--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-background-color-info-on-xl {
    background-color: var(--pf-v5-global--BackgroundColor--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-background-color-warning-on-xl {
    background-color: var(--pf-v5-global--BackgroundColor--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-background-color-danger-on-xl {
    background-color: var(--pf-v5-global--BackgroundColor--100) !important;
  }
}

@media screen and (min-width: 1450px) {
  :where(.pf-v5-theme-dark) .pf-v5-u-background-color-default-on-2xl {
    background-color: var(--pf-v5-global--BackgroundColor--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-background-color-success-on-2xl {
    background-color: var(--pf-v5-global--BackgroundColor--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-background-color-info-on-2xl {
    background-color: var(--pf-v5-global--BackgroundColor--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-background-color-warning-on-2xl {
    background-color: var(--pf-v5-global--BackgroundColor--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-background-color-danger-on-2xl {
    background-color: var(--pf-v5-global--BackgroundColor--100) !important;
  }
}

.pf-v5-u-box-shadow-sm {
  box-shadow: var(--pf-v5-global--BoxShadow--sm) !important;
}

.pf-v5-u-box-shadow-sm-top {
  box-shadow: var(--pf-v5-global--BoxShadow--sm-top) !important;
}

.pf-v5-u-box-shadow-sm-right {
  box-shadow: var(--pf-v5-global--BoxShadow--sm-right) !important;
}

.pf-v5-u-box-shadow-sm-bottom {
  box-shadow: var(--pf-v5-global--BoxShadow--sm-bottom) !important;
}

.pf-v5-u-box-shadow-sm-left {
  box-shadow: var(--pf-v5-global--BoxShadow--sm-left) !important;
}

.pf-v5-u-box-shadow-md {
  box-shadow: var(--pf-v5-global--BoxShadow--md) !important;
}

.pf-v5-u-box-shadow-md-top {
  box-shadow: var(--pf-v5-global--BoxShadow--md-top) !important;
}

.pf-v5-u-box-shadow-md-right {
  box-shadow: var(--pf-v5-global--BoxShadow--md-right) !important;
}

.pf-v5-u-box-shadow-md-bottom {
  box-shadow: var(--pf-v5-global--BoxShadow--md-bottom) !important;
}

.pf-v5-u-box-shadow-md-left {
  box-shadow: var(--pf-v5-global--BoxShadow--md-left) !important;
}

.pf-v5-u-box-shadow-lg {
  box-shadow: var(--pf-v5-global--BoxShadow--lg) !important;
}

.pf-v5-u-box-shadow-lg-top {
  box-shadow: var(--pf-v5-global--BoxShadow--lg-top) !important;
}

.pf-v5-u-box-shadow-lg-right {
  box-shadow: var(--pf-v5-global--BoxShadow--lg-right) !important;
}

.pf-v5-u-box-shadow-lg-bottom {
  box-shadow: var(--pf-v5-global--BoxShadow--lg-bottom) !important;
}

.pf-v5-u-box-shadow-lg-left {
  box-shadow: var(--pf-v5-global--BoxShadow--lg-left) !important;
}

.pf-v5-u-box-shadow-xl {
  box-shadow: var(--pf-v5-global--BoxShadow--xl) !important;
}

.pf-v5-u-box-shadow-xl-top {
  box-shadow: var(--pf-v5-global--BoxShadow--xl-top) !important;
}

.pf-v5-u-box-shadow-xl-right {
  box-shadow: var(--pf-v5-global--BoxShadow--xl-right) !important;
}

.pf-v5-u-box-shadow-xl-bottom {
  box-shadow: var(--pf-v5-global--BoxShadow--xl-bottom) !important;
}

.pf-v5-u-box-shadow-xl-left {
  box-shadow: var(--pf-v5-global--BoxShadow--xl-left) !important;
}

.pf-v5-u-box-shadow-inset {
  box-shadow: var(--pf-v5-global--BoxShadow--inset) !important;
}

.pf-v5-u-display-none {
  display: none !important;
}

.pf-v5-u-display-inline-block {
  display: inline-block !important;
}

.pf-v5-u-display-block {
  display: block !important;
}

.pf-v5-u-display-inline {
  display: inline !important;
}

.pf-v5-u-display-table {
  display: table !important;
}

.pf-v5-u-display-table-cell {
  display: table-cell !important;
}

.pf-v5-u-display-table-row {
  display: table-row !important;
}

.pf-v5-u-display-flex {
  display: flex !important;
}

.pf-v5-u-display-inline-flex {
  display: inline-flex !important;
}

.pf-v5-u-display-grid {
  display: grid !important;
}

.pf-v5-u-display-inline-grid {
  display: inline-grid !important;
}

@media screen and (min-width: 576px) {
  .pf-v5-u-display-none-on-sm {
    display: none !important;
  }

  .pf-v5-u-display-inline-block-on-sm {
    display: inline-block !important;
  }

  .pf-v5-u-display-block-on-sm {
    display: block !important;
  }

  .pf-v5-u-display-inline-on-sm {
    display: inline !important;
  }

  .pf-v5-u-display-table-on-sm {
    display: table !important;
  }

  .pf-v5-u-display-table-cell-on-sm {
    display: table-cell !important;
  }

  .pf-v5-u-display-table-row-on-sm {
    display: table-row !important;
  }

  .pf-v5-u-display-flex-on-sm {
    display: flex !important;
  }

  .pf-v5-u-display-inline-flex-on-sm {
    display: inline-flex !important;
  }

  .pf-v5-u-display-grid-on-sm {
    display: grid !important;
  }

  .pf-v5-u-display-inline-grid-on-sm {
    display: inline-grid !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-display-none-on-md {
    display: none !important;
  }

  .pf-v5-u-display-inline-block-on-md {
    display: inline-block !important;
  }

  .pf-v5-u-display-block-on-md {
    display: block !important;
  }

  .pf-v5-u-display-inline-on-md {
    display: inline !important;
  }

  .pf-v5-u-display-table-on-md {
    display: table !important;
  }

  .pf-v5-u-display-table-cell-on-md {
    display: table-cell !important;
  }

  .pf-v5-u-display-table-row-on-md {
    display: table-row !important;
  }

  .pf-v5-u-display-flex-on-md {
    display: flex !important;
  }

  .pf-v5-u-display-inline-flex-on-md {
    display: inline-flex !important;
  }

  .pf-v5-u-display-grid-on-md {
    display: grid !important;
  }

  .pf-v5-u-display-inline-grid-on-md {
    display: inline-grid !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-display-none-on-lg {
    display: none !important;
  }

  .pf-v5-u-display-inline-block-on-lg {
    display: inline-block !important;
  }

  .pf-v5-u-display-block-on-lg {
    display: block !important;
  }

  .pf-v5-u-display-inline-on-lg {
    display: inline !important;
  }

  .pf-v5-u-display-table-on-lg {
    display: table !important;
  }

  .pf-v5-u-display-table-cell-on-lg {
    display: table-cell !important;
  }

  .pf-v5-u-display-table-row-on-lg {
    display: table-row !important;
  }

  .pf-v5-u-display-flex-on-lg {
    display: flex !important;
  }

  .pf-v5-u-display-inline-flex-on-lg {
    display: inline-flex !important;
  }

  .pf-v5-u-display-grid-on-lg {
    display: grid !important;
  }

  .pf-v5-u-display-inline-grid-on-lg {
    display: inline-grid !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-display-none-on-xl {
    display: none !important;
  }

  .pf-v5-u-display-inline-block-on-xl {
    display: inline-block !important;
  }

  .pf-v5-u-display-block-on-xl {
    display: block !important;
  }

  .pf-v5-u-display-inline-on-xl {
    display: inline !important;
  }

  .pf-v5-u-display-table-on-xl {
    display: table !important;
  }

  .pf-v5-u-display-table-cell-on-xl {
    display: table-cell !important;
  }

  .pf-v5-u-display-table-row-on-xl {
    display: table-row !important;
  }

  .pf-v5-u-display-flex-on-xl {
    display: flex !important;
  }

  .pf-v5-u-display-inline-flex-on-xl {
    display: inline-flex !important;
  }

  .pf-v5-u-display-grid-on-xl {
    display: grid !important;
  }

  .pf-v5-u-display-inline-grid-on-xl {
    display: inline-grid !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-display-none-on-2xl {
    display: none !important;
  }

  .pf-v5-u-display-inline-block-on-2xl {
    display: inline-block !important;
  }

  .pf-v5-u-display-block-on-2xl {
    display: block !important;
  }

  .pf-v5-u-display-inline-on-2xl {
    display: inline !important;
  }

  .pf-v5-u-display-table-on-2xl {
    display: table !important;
  }

  .pf-v5-u-display-table-cell-on-2xl {
    display: table-cell !important;
  }

  .pf-v5-u-display-table-row-on-2xl {
    display: table-row !important;
  }

  .pf-v5-u-display-flex-on-2xl {
    display: flex !important;
  }

  .pf-v5-u-display-inline-flex-on-2xl {
    display: inline-flex !important;
  }

  .pf-v5-u-display-grid-on-2xl {
    display: grid !important;
  }

  .pf-v5-u-display-inline-grid-on-2xl {
    display: inline-grid !important;
  }
}

.pf-v5-u-flex-direction-column {
  flex-direction: column !important;
}

.pf-v5-u-flex-direction-column-reverse {
  flex-direction: column-reverse !important;
}

.pf-v5-u-flex-direction-row {
  flex-direction: row !important;
}

.pf-v5-u-flex-direction-row-reverse {
  flex-direction: row-reverse !important;
}

@media screen and (min-width: 576px) {
  .pf-v5-u-flex-direction-column-on-sm {
    flex-direction: column !important;
  }

  .pf-v5-u-flex-direction-column-reverse-on-sm {
    flex-direction: column-reverse !important;
  }

  .pf-v5-u-flex-direction-row-on-sm {
    flex-direction: row !important;
  }

  .pf-v5-u-flex-direction-row-reverse-on-sm {
    flex-direction: row-reverse !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-flex-direction-column-on-md {
    flex-direction: column !important;
  }

  .pf-v5-u-flex-direction-column-reverse-on-md {
    flex-direction: column-reverse !important;
  }

  .pf-v5-u-flex-direction-row-on-md {
    flex-direction: row !important;
  }

  .pf-v5-u-flex-direction-row-reverse-on-md {
    flex-direction: row-reverse !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-flex-direction-column-on-lg {
    flex-direction: column !important;
  }

  .pf-v5-u-flex-direction-column-reverse-on-lg {
    flex-direction: column-reverse !important;
  }

  .pf-v5-u-flex-direction-row-on-lg {
    flex-direction: row !important;
  }

  .pf-v5-u-flex-direction-row-reverse-on-lg {
    flex-direction: row-reverse !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-flex-direction-column-on-xl {
    flex-direction: column !important;
  }

  .pf-v5-u-flex-direction-column-reverse-on-xl {
    flex-direction: column-reverse !important;
  }

  .pf-v5-u-flex-direction-row-on-xl {
    flex-direction: row !important;
  }

  .pf-v5-u-flex-direction-row-reverse-on-xl {
    flex-direction: row-reverse !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-flex-direction-column-on-2xl {
    flex-direction: column !important;
  }

  .pf-v5-u-flex-direction-column-reverse-on-2xl {
    flex-direction: column-reverse !important;
  }

  .pf-v5-u-flex-direction-row-on-2xl {
    flex-direction: row !important;
  }

  .pf-v5-u-flex-direction-row-reverse-on-2xl {
    flex-direction: row-reverse !important;
  }
}

.pf-v5-u-flex-wrap {
  flex-wrap: wrap !important;
}

.pf-v5-u-flex-nowrap {
  flex-wrap: nowrap !important;
}

.pf-v5-u-flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

@media screen and (min-width: 576px) {
  .pf-v5-u-flex-wrap-on-sm {
    flex-wrap: wrap !important;
  }

  .pf-v5-u-flex-nowrap-on-sm {
    flex-wrap: nowrap !important;
  }

  .pf-v5-u-flex-wrap-reverse-on-sm {
    flex-wrap: wrap-reverse !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-flex-wrap-on-md {
    flex-wrap: wrap !important;
  }

  .pf-v5-u-flex-nowrap-on-md {
    flex-wrap: nowrap !important;
  }

  .pf-v5-u-flex-wrap-reverse-on-md {
    flex-wrap: wrap-reverse !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-flex-wrap-on-lg {
    flex-wrap: wrap !important;
  }

  .pf-v5-u-flex-nowrap-on-lg {
    flex-wrap: nowrap !important;
  }

  .pf-v5-u-flex-wrap-reverse-on-lg {
    flex-wrap: wrap-reverse !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-flex-wrap-on-xl {
    flex-wrap: wrap !important;
  }

  .pf-v5-u-flex-nowrap-on-xl {
    flex-wrap: nowrap !important;
  }

  .pf-v5-u-flex-wrap-reverse-on-xl {
    flex-wrap: wrap-reverse !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-flex-wrap-on-2xl {
    flex-wrap: wrap !important;
  }

  .pf-v5-u-flex-nowrap-on-2xl {
    flex-wrap: nowrap !important;
  }

  .pf-v5-u-flex-wrap-reverse-on-2xl {
    flex-wrap: wrap-reverse !important;
  }
}

.pf-v5-u-align-items-flex-start {
  align-items: flex-start !important;
}

.pf-v5-u-align-items-flex-end {
  align-items: flex-end !important;
}

.pf-v5-u-align-items-center {
  align-items: center !important;
}

.pf-v5-u-align-items-baseline {
  align-items: baseline !important;
}

.pf-v5-u-align-items-stretch {
  align-items: stretch !important;
}

@media screen and (min-width: 576px) {
  .pf-v5-u-align-items-flex-start-on-sm {
    align-items: flex-start !important;
  }

  .pf-v5-u-align-items-flex-end-on-sm {
    align-items: flex-end !important;
  }

  .pf-v5-u-align-items-center-on-sm {
    align-items: center !important;
  }

  .pf-v5-u-align-items-baseline-on-sm {
    align-items: baseline !important;
  }

  .pf-v5-u-align-items-stretch-on-sm {
    align-items: stretch !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-align-items-flex-start-on-md {
    align-items: flex-start !important;
  }

  .pf-v5-u-align-items-flex-end-on-md {
    align-items: flex-end !important;
  }

  .pf-v5-u-align-items-center-on-md {
    align-items: center !important;
  }

  .pf-v5-u-align-items-baseline-on-md {
    align-items: baseline !important;
  }

  .pf-v5-u-align-items-stretch-on-md {
    align-items: stretch !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-align-items-flex-start-on-lg {
    align-items: flex-start !important;
  }

  .pf-v5-u-align-items-flex-end-on-lg {
    align-items: flex-end !important;
  }

  .pf-v5-u-align-items-center-on-lg {
    align-items: center !important;
  }

  .pf-v5-u-align-items-baseline-on-lg {
    align-items: baseline !important;
  }

  .pf-v5-u-align-items-stretch-on-lg {
    align-items: stretch !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-align-items-flex-start-on-xl {
    align-items: flex-start !important;
  }

  .pf-v5-u-align-items-flex-end-on-xl {
    align-items: flex-end !important;
  }

  .pf-v5-u-align-items-center-on-xl {
    align-items: center !important;
  }

  .pf-v5-u-align-items-baseline-on-xl {
    align-items: baseline !important;
  }

  .pf-v5-u-align-items-stretch-on-xl {
    align-items: stretch !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-align-items-flex-start-on-2xl {
    align-items: flex-start !important;
  }

  .pf-v5-u-align-items-flex-end-on-2xl {
    align-items: flex-end !important;
  }

  .pf-v5-u-align-items-center-on-2xl {
    align-items: center !important;
  }

  .pf-v5-u-align-items-baseline-on-2xl {
    align-items: baseline !important;
  }

  .pf-v5-u-align-items-stretch-on-2xl {
    align-items: stretch !important;
  }
}

.pf-v5-u-align-self-flex-start {
  align-self: flex-start !important;
}

.pf-v5-u-align-self-flex-end {
  align-self: flex-end !important;
}

.pf-v5-u-align-self-center {
  align-self: center !important;
}

.pf-v5-u-align-self-baseline {
  align-self: baseline !important;
}

.pf-v5-u-align-self-stretch {
  align-self: stretch !important;
}

@media screen and (min-width: 576px) {
  .pf-v5-u-align-self-flex-start-on-sm {
    align-self: flex-start !important;
  }

  .pf-v5-u-align-self-flex-end-on-sm {
    align-self: flex-end !important;
  }

  .pf-v5-u-align-self-center-on-sm {
    align-self: center !important;
  }

  .pf-v5-u-align-self-baseline-on-sm {
    align-self: baseline !important;
  }

  .pf-v5-u-align-self-stretch-on-sm {
    align-self: stretch !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-align-self-flex-start-on-md {
    align-self: flex-start !important;
  }

  .pf-v5-u-align-self-flex-end-on-md {
    align-self: flex-end !important;
  }

  .pf-v5-u-align-self-center-on-md {
    align-self: center !important;
  }

  .pf-v5-u-align-self-baseline-on-md {
    align-self: baseline !important;
  }

  .pf-v5-u-align-self-stretch-on-md {
    align-self: stretch !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-align-self-flex-start-on-lg {
    align-self: flex-start !important;
  }

  .pf-v5-u-align-self-flex-end-on-lg {
    align-self: flex-end !important;
  }

  .pf-v5-u-align-self-center-on-lg {
    align-self: center !important;
  }

  .pf-v5-u-align-self-baseline-on-lg {
    align-self: baseline !important;
  }

  .pf-v5-u-align-self-stretch-on-lg {
    align-self: stretch !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-align-self-flex-start-on-xl {
    align-self: flex-start !important;
  }

  .pf-v5-u-align-self-flex-end-on-xl {
    align-self: flex-end !important;
  }

  .pf-v5-u-align-self-center-on-xl {
    align-self: center !important;
  }

  .pf-v5-u-align-self-baseline-on-xl {
    align-self: baseline !important;
  }

  .pf-v5-u-align-self-stretch-on-xl {
    align-self: stretch !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-align-self-flex-start-on-2xl {
    align-self: flex-start !important;
  }

  .pf-v5-u-align-self-flex-end-on-2xl {
    align-self: flex-end !important;
  }

  .pf-v5-u-align-self-center-on-2xl {
    align-self: center !important;
  }

  .pf-v5-u-align-self-baseline-on-2xl {
    align-self: baseline !important;
  }

  .pf-v5-u-align-self-stretch-on-2xl {
    align-self: stretch !important;
  }
}

.pf-v5-u-align-content-flex-start {
  align-content: flex-start !important;
}

.pf-v5-u-align-content-flex-end {
  align-content: flex-end !important;
}

.pf-v5-u-align-content-center {
  align-content: center !important;
}

.pf-v5-u-align-content-space-between {
  align-content: space-between !important;
}

.pf-v5-u-align-content-space-around {
  align-content: space-around !important;
}

.pf-v5-u-align-content-stretch {
  align-content: stretch !important;
}

@media screen and (min-width: 576px) {
  .pf-v5-u-align-content-flex-start-on-sm {
    align-content: flex-start !important;
  }

  .pf-v5-u-align-content-flex-end-on-sm {
    align-content: flex-end !important;
  }

  .pf-v5-u-align-content-center-on-sm {
    align-content: center !important;
  }

  .pf-v5-u-align-content-space-between-on-sm {
    align-content: space-between !important;
  }

  .pf-v5-u-align-content-space-around-on-sm {
    align-content: space-around !important;
  }

  .pf-v5-u-align-content-stretch-on-sm {
    align-content: stretch !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-align-content-flex-start-on-md {
    align-content: flex-start !important;
  }

  .pf-v5-u-align-content-flex-end-on-md {
    align-content: flex-end !important;
  }

  .pf-v5-u-align-content-center-on-md {
    align-content: center !important;
  }

  .pf-v5-u-align-content-space-between-on-md {
    align-content: space-between !important;
  }

  .pf-v5-u-align-content-space-around-on-md {
    align-content: space-around !important;
  }

  .pf-v5-u-align-content-stretch-on-md {
    align-content: stretch !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-align-content-flex-start-on-lg {
    align-content: flex-start !important;
  }

  .pf-v5-u-align-content-flex-end-on-lg {
    align-content: flex-end !important;
  }

  .pf-v5-u-align-content-center-on-lg {
    align-content: center !important;
  }

  .pf-v5-u-align-content-space-between-on-lg {
    align-content: space-between !important;
  }

  .pf-v5-u-align-content-space-around-on-lg {
    align-content: space-around !important;
  }

  .pf-v5-u-align-content-stretch-on-lg {
    align-content: stretch !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-align-content-flex-start-on-xl {
    align-content: flex-start !important;
  }

  .pf-v5-u-align-content-flex-end-on-xl {
    align-content: flex-end !important;
  }

  .pf-v5-u-align-content-center-on-xl {
    align-content: center !important;
  }

  .pf-v5-u-align-content-space-between-on-xl {
    align-content: space-between !important;
  }

  .pf-v5-u-align-content-space-around-on-xl {
    align-content: space-around !important;
  }

  .pf-v5-u-align-content-stretch-on-xl {
    align-content: stretch !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-align-content-flex-start-on-2xl {
    align-content: flex-start !important;
  }

  .pf-v5-u-align-content-flex-end-on-2xl {
    align-content: flex-end !important;
  }

  .pf-v5-u-align-content-center-on-2xl {
    align-content: center !important;
  }

  .pf-v5-u-align-content-space-between-on-2xl {
    align-content: space-between !important;
  }

  .pf-v5-u-align-content-space-around-on-2xl {
    align-content: space-around !important;
  }

  .pf-v5-u-align-content-stretch-on-2xl {
    align-content: stretch !important;
  }
}

.pf-v5-u-justify-content-flex-start {
  justify-content: flex-start !important;
}

.pf-v5-u-justify-content-flex-end {
  justify-content: flex-end !important;
}

.pf-v5-u-justify-content-center {
  justify-content: center !important;
}

.pf-v5-u-justify-content-space-between {
  justify-content: space-between !important;
}

.pf-v5-u-justify-content-space-around {
  justify-content: space-around !important;
}

.pf-v5-u-justify-content-stretch {
  justify-content: stretch !important;
}

@media screen and (min-width: 576px) {
  .pf-v5-u-justify-content-flex-start-on-sm {
    justify-content: flex-start !important;
  }

  .pf-v5-u-justify-content-flex-end-on-sm {
    justify-content: flex-end !important;
  }

  .pf-v5-u-justify-content-center-on-sm {
    justify-content: center !important;
  }

  .pf-v5-u-justify-content-space-between-on-sm {
    justify-content: space-between !important;
  }

  .pf-v5-u-justify-content-space-around-on-sm {
    justify-content: space-around !important;
  }

  .pf-v5-u-justify-content-stretch-on-sm {
    justify-content: stretch !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-justify-content-flex-start-on-md {
    justify-content: flex-start !important;
  }

  .pf-v5-u-justify-content-flex-end-on-md {
    justify-content: flex-end !important;
  }

  .pf-v5-u-justify-content-center-on-md {
    justify-content: center !important;
  }

  .pf-v5-u-justify-content-space-between-on-md {
    justify-content: space-between !important;
  }

  .pf-v5-u-justify-content-space-around-on-md {
    justify-content: space-around !important;
  }

  .pf-v5-u-justify-content-stretch-on-md {
    justify-content: stretch !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-justify-content-flex-start-on-lg {
    justify-content: flex-start !important;
  }

  .pf-v5-u-justify-content-flex-end-on-lg {
    justify-content: flex-end !important;
  }

  .pf-v5-u-justify-content-center-on-lg {
    justify-content: center !important;
  }

  .pf-v5-u-justify-content-space-between-on-lg {
    justify-content: space-between !important;
  }

  .pf-v5-u-justify-content-space-around-on-lg {
    justify-content: space-around !important;
  }

  .pf-v5-u-justify-content-stretch-on-lg {
    justify-content: stretch !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-justify-content-flex-start-on-xl {
    justify-content: flex-start !important;
  }

  .pf-v5-u-justify-content-flex-end-on-xl {
    justify-content: flex-end !important;
  }

  .pf-v5-u-justify-content-center-on-xl {
    justify-content: center !important;
  }

  .pf-v5-u-justify-content-space-between-on-xl {
    justify-content: space-between !important;
  }

  .pf-v5-u-justify-content-space-around-on-xl {
    justify-content: space-around !important;
  }

  .pf-v5-u-justify-content-stretch-on-xl {
    justify-content: stretch !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-justify-content-flex-start-on-2xl {
    justify-content: flex-start !important;
  }

  .pf-v5-u-justify-content-flex-end-on-2xl {
    justify-content: flex-end !important;
  }

  .pf-v5-u-justify-content-center-on-2xl {
    justify-content: center !important;
  }

  .pf-v5-u-justify-content-space-between-on-2xl {
    justify-content: space-between !important;
  }

  .pf-v5-u-justify-content-space-around-on-2xl {
    justify-content: space-around !important;
  }

  .pf-v5-u-justify-content-stretch-on-2xl {
    justify-content: stretch !important;
  }
}

.pf-v5-u-flex-shrink-1 {
  flex-shrink: 1 !important;
}

.pf-v5-u-flex-grow-1 {
  flex-grow: 1 !important;
}

.pf-v5-u-flex-shrink-0 {
  flex-shrink: 0 !important;
}

.pf-v5-u-flex-grow-0 {
  flex-grow: 0 !important;
}

@media screen and (min-width: 576px) {
  .pf-v5-u-flex-shrink-1-on-sm {
    flex-shrink: 1 !important;
  }

  .pf-v5-u-flex-grow-1-on-sm {
    flex-grow: 1 !important;
  }

  .pf-v5-u-flex-shrink-0-on-sm {
    flex-shrink: 0 !important;
  }

  .pf-v5-u-flex-grow-0-on-sm {
    flex-grow: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-flex-shrink-1-on-md {
    flex-shrink: 1 !important;
  }

  .pf-v5-u-flex-grow-1-on-md {
    flex-grow: 1 !important;
  }

  .pf-v5-u-flex-shrink-0-on-md {
    flex-shrink: 0 !important;
  }

  .pf-v5-u-flex-grow-0-on-md {
    flex-grow: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-flex-shrink-1-on-lg {
    flex-shrink: 1 !important;
  }

  .pf-v5-u-flex-grow-1-on-lg {
    flex-grow: 1 !important;
  }

  .pf-v5-u-flex-shrink-0-on-lg {
    flex-shrink: 0 !important;
  }

  .pf-v5-u-flex-grow-0-on-lg {
    flex-grow: 0 !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-flex-shrink-1-on-xl {
    flex-shrink: 1 !important;
  }

  .pf-v5-u-flex-grow-1-on-xl {
    flex-grow: 1 !important;
  }

  .pf-v5-u-flex-shrink-0-on-xl {
    flex-shrink: 0 !important;
  }

  .pf-v5-u-flex-grow-0-on-xl {
    flex-grow: 0 !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-flex-shrink-1-on-2xl {
    flex-shrink: 1 !important;
  }

  .pf-v5-u-flex-grow-1-on-2xl {
    flex-grow: 1 !important;
  }

  .pf-v5-u-flex-shrink-0-on-2xl {
    flex-shrink: 0 !important;
  }

  .pf-v5-u-flex-grow-0-on-2xl {
    flex-grow: 0 !important;
  }
}

.pf-v5-u-flex-basis-0 {
  flex-basis: 0 !important;
}

.pf-v5-u-flex-basis-auto {
  flex-basis: auto !important;
}

@media screen and (min-width: 576px) {
  .pf-v5-u-flex-basis-0-on-sm {
    flex-basis: 0 !important;
  }

  .pf-v5-u-flex-basis-auto-on-sm {
    flex-basis: auto !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-flex-basis-0-on-md {
    flex-basis: 0 !important;
  }

  .pf-v5-u-flex-basis-auto-on-md {
    flex-basis: auto !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-flex-basis-0-on-lg {
    flex-basis: 0 !important;
  }

  .pf-v5-u-flex-basis-auto-on-lg {
    flex-basis: auto !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-flex-basis-0-on-xl {
    flex-basis: 0 !important;
  }

  .pf-v5-u-flex-basis-auto-on-xl {
    flex-basis: auto !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-flex-basis-0-on-2xl {
    flex-basis: 0 !important;
  }

  .pf-v5-u-flex-basis-auto-on-2xl {
    flex-basis: auto !important;
  }
}

.pf-v5-u-flex-none {
  flex: none !important;
}

.pf-v5-u-flex-1 {
  flex: 1 !important;
}

@media screen and (min-width: 576px) {
  .pf-v5-u-flex-none-on-sm {
    flex: none !important;
  }

  .pf-v5-u-flex-1-on-sm {
    flex: 1 !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-flex-none-on-md {
    flex: none !important;
  }

  .pf-v5-u-flex-1-on-md {
    flex: 1 !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-flex-none-on-lg {
    flex: none !important;
  }

  .pf-v5-u-flex-1-on-lg {
    flex: 1 !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-flex-none-on-xl {
    flex: none !important;
  }

  .pf-v5-u-flex-1-on-xl {
    flex: 1 !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-flex-none-on-2xl {
    flex: none !important;
  }

  .pf-v5-u-flex-1-on-2xl {
    flex: 1 !important;
  }
}

.pf-v5-u-flex-fill {
  flex: 1 1 auto !important;
}

@media screen and (min-width: 576px) {
  .pf-v5-u-flex-fill-on-sm {
    flex: 1 1 auto !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-flex-fill-on-md {
    flex: 1 1 auto !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-flex-fill-on-lg {
    flex: 1 1 auto !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-flex-fill-on-xl {
    flex: 1 1 auto !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-flex-fill-on-2xl {
    flex: 1 1 auto !important;
  }
}

.pf-v5-u-float-left {
  float: left !important;
}

.pf-v5-u-float-right {
  float: right !important;
}

@media screen and (min-width: 576px) {
  .pf-v5-u-float-left-on-sm {
    float: left !important;
  }

  .pf-v5-u-float-right-on-sm {
    float: right !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-float-left-on-md {
    float: left !important;
  }

  .pf-v5-u-float-right-on-md {
    float: right !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-float-left-on-lg {
    float: left !important;
  }

  .pf-v5-u-float-right-on-lg {
    float: right !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-float-left-on-xl {
    float: left !important;
  }

  .pf-v5-u-float-right-on-xl {
    float: right !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-float-left-on-2xl {
    float: left !important;
  }

  .pf-v5-u-float-right-on-2xl {
    float: right !important;
  }
}

.pf-v5-u-w-auto {
  width: auto !important;
}

.pf-v5-u-w-initial {
  width: initial !important;
}

.pf-v5-u-w-inherit {
  width: inherit !important;
}

.pf-v5-u-w-0 {
  width: 0% !important;
}

.pf-v5-u-w-25 {
  width: 25% !important;
}

.pf-v5-u-w-33 {
  width: 33.3333333333% !important;
}

.pf-v5-u-w-50 {
  width: 50% !important;
}

.pf-v5-u-w-66 {
  width: 66.6666666667% !important;
}

.pf-v5-u-w-75 {
  width: 75% !important;
}

.pf-v5-u-w-100 {
  width: 100% !important;
}

.pf-v5-u-w-25vw {
  width: 25vw !important;
}

.pf-v5-u-w-33vw {
  width: 33.3333333333vw !important;
}

.pf-v5-u-w-50vw {
  width: 50vw !important;
}

.pf-v5-u-w-66vw {
  width: 66.6666666667vw !important;
}

.pf-v5-u-w-75vw {
  width: 75vw !important;
}

.pf-v5-u-w-100vw {
  width: 100vw !important;
}

@media screen and (min-width: 576px) {
  .pf-v5-u-w-auto-on-sm {
    width: auto !important;
  }

  .pf-v5-u-w-initial-on-sm {
    width: initial !important;
  }

  .pf-v5-u-w-inherit-on-sm {
    width: inherit !important;
  }

  .pf-v5-u-w-0-on-sm {
    width: 0% !important;
  }

  .pf-v5-u-w-25-on-sm {
    width: 25% !important;
  }

  .pf-v5-u-w-33-on-sm {
    width: 33.3333333333% !important;
  }

  .pf-v5-u-w-50-on-sm {
    width: 50% !important;
  }

  .pf-v5-u-w-66-on-sm {
    width: 66.6666666667% !important;
  }

  .pf-v5-u-w-75-on-sm {
    width: 75% !important;
  }

  .pf-v5-u-w-100-on-sm {
    width: 100% !important;
  }

  .pf-v5-u-w-25vw-on-sm {
    width: 25vw !important;
  }

  .pf-v5-u-w-33vw-on-sm {
    width: 33.3333333333vw !important;
  }

  .pf-v5-u-w-50vw-on-sm {
    width: 50vw !important;
  }

  .pf-v5-u-w-66vw-on-sm {
    width: 66.6666666667vw !important;
  }

  .pf-v5-u-w-75vw-on-sm {
    width: 75vw !important;
  }

  .pf-v5-u-w-100vw-on-sm {
    width: 100vw !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-w-auto-on-md {
    width: auto !important;
  }

  .pf-v5-u-w-initial-on-md {
    width: initial !important;
  }

  .pf-v5-u-w-inherit-on-md {
    width: inherit !important;
  }

  .pf-v5-u-w-0-on-md {
    width: 0% !important;
  }

  .pf-v5-u-w-25-on-md {
    width: 25% !important;
  }

  .pf-v5-u-w-33-on-md {
    width: 33.3333333333% !important;
  }

  .pf-v5-u-w-50-on-md {
    width: 50% !important;
  }

  .pf-v5-u-w-66-on-md {
    width: 66.6666666667% !important;
  }

  .pf-v5-u-w-75-on-md {
    width: 75% !important;
  }

  .pf-v5-u-w-100-on-md {
    width: 100% !important;
  }

  .pf-v5-u-w-25vw-on-md {
    width: 25vw !important;
  }

  .pf-v5-u-w-33vw-on-md {
    width: 33.3333333333vw !important;
  }

  .pf-v5-u-w-50vw-on-md {
    width: 50vw !important;
  }

  .pf-v5-u-w-66vw-on-md {
    width: 66.6666666667vw !important;
  }

  .pf-v5-u-w-75vw-on-md {
    width: 75vw !important;
  }

  .pf-v5-u-w-100vw-on-md {
    width: 100vw !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-w-auto-on-lg {
    width: auto !important;
  }

  .pf-v5-u-w-initial-on-lg {
    width: initial !important;
  }

  .pf-v5-u-w-inherit-on-lg {
    width: inherit !important;
  }

  .pf-v5-u-w-0-on-lg {
    width: 0% !important;
  }

  .pf-v5-u-w-25-on-lg {
    width: 25% !important;
  }

  .pf-v5-u-w-33-on-lg {
    width: 33.3333333333% !important;
  }

  .pf-v5-u-w-50-on-lg {
    width: 50% !important;
  }

  .pf-v5-u-w-66-on-lg {
    width: 66.6666666667% !important;
  }

  .pf-v5-u-w-75-on-lg {
    width: 75% !important;
  }

  .pf-v5-u-w-100-on-lg {
    width: 100% !important;
  }

  .pf-v5-u-w-25vw-on-lg {
    width: 25vw !important;
  }

  .pf-v5-u-w-33vw-on-lg {
    width: 33.3333333333vw !important;
  }

  .pf-v5-u-w-50vw-on-lg {
    width: 50vw !important;
  }

  .pf-v5-u-w-66vw-on-lg {
    width: 66.6666666667vw !important;
  }

  .pf-v5-u-w-75vw-on-lg {
    width: 75vw !important;
  }

  .pf-v5-u-w-100vw-on-lg {
    width: 100vw !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-w-auto-on-xl {
    width: auto !important;
  }

  .pf-v5-u-w-initial-on-xl {
    width: initial !important;
  }

  .pf-v5-u-w-inherit-on-xl {
    width: inherit !important;
  }

  .pf-v5-u-w-0-on-xl {
    width: 0% !important;
  }

  .pf-v5-u-w-25-on-xl {
    width: 25% !important;
  }

  .pf-v5-u-w-33-on-xl {
    width: 33.3333333333% !important;
  }

  .pf-v5-u-w-50-on-xl {
    width: 50% !important;
  }

  .pf-v5-u-w-66-on-xl {
    width: 66.6666666667% !important;
  }

  .pf-v5-u-w-75-on-xl {
    width: 75% !important;
  }

  .pf-v5-u-w-100-on-xl {
    width: 100% !important;
  }

  .pf-v5-u-w-25vw-on-xl {
    width: 25vw !important;
  }

  .pf-v5-u-w-33vw-on-xl {
    width: 33.3333333333vw !important;
  }

  .pf-v5-u-w-50vw-on-xl {
    width: 50vw !important;
  }

  .pf-v5-u-w-66vw-on-xl {
    width: 66.6666666667vw !important;
  }

  .pf-v5-u-w-75vw-on-xl {
    width: 75vw !important;
  }

  .pf-v5-u-w-100vw-on-xl {
    width: 100vw !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-w-auto-on-2xl {
    width: auto !important;
  }

  .pf-v5-u-w-initial-on-2xl {
    width: initial !important;
  }

  .pf-v5-u-w-inherit-on-2xl {
    width: inherit !important;
  }

  .pf-v5-u-w-0-on-2xl {
    width: 0% !important;
  }

  .pf-v5-u-w-25-on-2xl {
    width: 25% !important;
  }

  .pf-v5-u-w-33-on-2xl {
    width: 33.3333333333% !important;
  }

  .pf-v5-u-w-50-on-2xl {
    width: 50% !important;
  }

  .pf-v5-u-w-66-on-2xl {
    width: 66.6666666667% !important;
  }

  .pf-v5-u-w-75-on-2xl {
    width: 75% !important;
  }

  .pf-v5-u-w-100-on-2xl {
    width: 100% !important;
  }

  .pf-v5-u-w-25vw-on-2xl {
    width: 25vw !important;
  }

  .pf-v5-u-w-33vw-on-2xl {
    width: 33.3333333333vw !important;
  }

  .pf-v5-u-w-50vw-on-2xl {
    width: 50vw !important;
  }

  .pf-v5-u-w-66vw-on-2xl {
    width: 66.6666666667vw !important;
  }

  .pf-v5-u-w-75vw-on-2xl {
    width: 75vw !important;
  }

  .pf-v5-u-w-100vw-on-2xl {
    width: 100vw !important;
  }
}

.pf-v5-u-h-auto {
  height: auto !important;
}

.pf-v5-u-h-initial {
  height: initial !important;
}

.pf-v5-u-h-inherit {
  height: inherit !important;
}

.pf-v5-u-h-0 {
  height: 0% !important;
}

.pf-v5-u-h-25 {
  height: 25% !important;
}

.pf-v5-u-h-33 {
  height: 33.3333333333% !important;
}

.pf-v5-u-h-50 {
  height: 50% !important;
}

.pf-v5-u-h-66 {
  height: 66.6666666667% !important;
}

.pf-v5-u-h-75 {
  height: 75% !important;
}

.pf-v5-u-h-100 {
  height: 100% !important;
}

.pf-v5-u-h-25vh {
  height: 25vh !important;
}

.pf-v5-u-h-33vh {
  height: 33.3333333333vh !important;
}

.pf-v5-u-h-50vh {
  height: 50vh !important;
}

.pf-v5-u-h-66vh {
  height: 66.6666666667vh !important;
}

.pf-v5-u-h-75vh {
  height: 75vh !important;
}

.pf-v5-u-h-100vh {
  height: 100vh !important;
}

@media screen and (min-width: 576px) {
  .pf-v5-u-h-auto-on-sm {
    height: auto !important;
  }

  .pf-v5-u-h-initial-on-sm {
    height: initial !important;
  }

  .pf-v5-u-h-inherit-on-sm {
    height: inherit !important;
  }

  .pf-v5-u-h-0-on-sm {
    height: 0% !important;
  }

  .pf-v5-u-h-25-on-sm {
    height: 25% !important;
  }

  .pf-v5-u-h-33-on-sm {
    height: 33.3333333333% !important;
  }

  .pf-v5-u-h-50-on-sm {
    height: 50% !important;
  }

  .pf-v5-u-h-66-on-sm {
    height: 66.6666666667% !important;
  }

  .pf-v5-u-h-75-on-sm {
    height: 75% !important;
  }

  .pf-v5-u-h-100-on-sm {
    height: 100% !important;
  }

  .pf-v5-u-h-25vh-on-sm {
    height: 25vh !important;
  }

  .pf-v5-u-h-33vh-on-sm {
    height: 33.3333333333vh !important;
  }

  .pf-v5-u-h-50vh-on-sm {
    height: 50vh !important;
  }

  .pf-v5-u-h-66vh-on-sm {
    height: 66.6666666667vh !important;
  }

  .pf-v5-u-h-75vh-on-sm {
    height: 75vh !important;
  }

  .pf-v5-u-h-100vh-on-sm {
    height: 100vh !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-h-auto-on-md {
    height: auto !important;
  }

  .pf-v5-u-h-initial-on-md {
    height: initial !important;
  }

  .pf-v5-u-h-inherit-on-md {
    height: inherit !important;
  }

  .pf-v5-u-h-0-on-md {
    height: 0% !important;
  }

  .pf-v5-u-h-25-on-md {
    height: 25% !important;
  }

  .pf-v5-u-h-33-on-md {
    height: 33.3333333333% !important;
  }

  .pf-v5-u-h-50-on-md {
    height: 50% !important;
  }

  .pf-v5-u-h-66-on-md {
    height: 66.6666666667% !important;
  }

  .pf-v5-u-h-75-on-md {
    height: 75% !important;
  }

  .pf-v5-u-h-100-on-md {
    height: 100% !important;
  }

  .pf-v5-u-h-25vh-on-md {
    height: 25vh !important;
  }

  .pf-v5-u-h-33vh-on-md {
    height: 33.3333333333vh !important;
  }

  .pf-v5-u-h-50vh-on-md {
    height: 50vh !important;
  }

  .pf-v5-u-h-66vh-on-md {
    height: 66.6666666667vh !important;
  }

  .pf-v5-u-h-75vh-on-md {
    height: 75vh !important;
  }

  .pf-v5-u-h-100vh-on-md {
    height: 100vh !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-h-auto-on-lg {
    height: auto !important;
  }

  .pf-v5-u-h-initial-on-lg {
    height: initial !important;
  }

  .pf-v5-u-h-inherit-on-lg {
    height: inherit !important;
  }

  .pf-v5-u-h-0-on-lg {
    height: 0% !important;
  }

  .pf-v5-u-h-25-on-lg {
    height: 25% !important;
  }

  .pf-v5-u-h-33-on-lg {
    height: 33.3333333333% !important;
  }

  .pf-v5-u-h-50-on-lg {
    height: 50% !important;
  }

  .pf-v5-u-h-66-on-lg {
    height: 66.6666666667% !important;
  }

  .pf-v5-u-h-75-on-lg {
    height: 75% !important;
  }

  .pf-v5-u-h-100-on-lg {
    height: 100% !important;
  }

  .pf-v5-u-h-25vh-on-lg {
    height: 25vh !important;
  }

  .pf-v5-u-h-33vh-on-lg {
    height: 33.3333333333vh !important;
  }

  .pf-v5-u-h-50vh-on-lg {
    height: 50vh !important;
  }

  .pf-v5-u-h-66vh-on-lg {
    height: 66.6666666667vh !important;
  }

  .pf-v5-u-h-75vh-on-lg {
    height: 75vh !important;
  }

  .pf-v5-u-h-100vh-on-lg {
    height: 100vh !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-h-auto-on-xl {
    height: auto !important;
  }

  .pf-v5-u-h-initial-on-xl {
    height: initial !important;
  }

  .pf-v5-u-h-inherit-on-xl {
    height: inherit !important;
  }

  .pf-v5-u-h-0-on-xl {
    height: 0% !important;
  }

  .pf-v5-u-h-25-on-xl {
    height: 25% !important;
  }

  .pf-v5-u-h-33-on-xl {
    height: 33.3333333333% !important;
  }

  .pf-v5-u-h-50-on-xl {
    height: 50% !important;
  }

  .pf-v5-u-h-66-on-xl {
    height: 66.6666666667% !important;
  }

  .pf-v5-u-h-75-on-xl {
    height: 75% !important;
  }

  .pf-v5-u-h-100-on-xl {
    height: 100% !important;
  }

  .pf-v5-u-h-25vh-on-xl {
    height: 25vh !important;
  }

  .pf-v5-u-h-33vh-on-xl {
    height: 33.3333333333vh !important;
  }

  .pf-v5-u-h-50vh-on-xl {
    height: 50vh !important;
  }

  .pf-v5-u-h-66vh-on-xl {
    height: 66.6666666667vh !important;
  }

  .pf-v5-u-h-75vh-on-xl {
    height: 75vh !important;
  }

  .pf-v5-u-h-100vh-on-xl {
    height: 100vh !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-h-auto-on-2xl {
    height: auto !important;
  }

  .pf-v5-u-h-initial-on-2xl {
    height: initial !important;
  }

  .pf-v5-u-h-inherit-on-2xl {
    height: inherit !important;
  }

  .pf-v5-u-h-0-on-2xl {
    height: 0% !important;
  }

  .pf-v5-u-h-25-on-2xl {
    height: 25% !important;
  }

  .pf-v5-u-h-33-on-2xl {
    height: 33.3333333333% !important;
  }

  .pf-v5-u-h-50-on-2xl {
    height: 50% !important;
  }

  .pf-v5-u-h-66-on-2xl {
    height: 66.6666666667% !important;
  }

  .pf-v5-u-h-75-on-2xl {
    height: 75% !important;
  }

  .pf-v5-u-h-100-on-2xl {
    height: 100% !important;
  }

  .pf-v5-u-h-25vh-on-2xl {
    height: 25vh !important;
  }

  .pf-v5-u-h-33vh-on-2xl {
    height: 33.3333333333vh !important;
  }

  .pf-v5-u-h-50vh-on-2xl {
    height: 50vh !important;
  }

  .pf-v5-u-h-66vh-on-2xl {
    height: 66.6666666667vh !important;
  }

  .pf-v5-u-h-75vh-on-2xl {
    height: 75vh !important;
  }

  .pf-v5-u-h-100vh-on-2xl {
    height: 100vh !important;
  }
}

.pf-v5-u-min-width {
  --pf-v5-u-min-width--MinWidth: 0;
  min-width: var(--pf-v5-u-min-width--MinWidth) !important;
}

@media (min-width: 576px) {
  .pf-v5-u-min-width {
    min-width: var(--pf-v5-u-min-width--MinWidth-on-sm, var(--pf-v5-u-min-width--MinWidth)) !important;
  }
}

@media (min-width: 768px) {
  .pf-v5-u-min-width {
    min-width: var(--pf-v5-u-min-width--MinWidth-on-md, var(--pf-v5-u-min-width--MinWidth-on-sm, var(--pf-v5-u-min-width--MinWidth))) !important;
  }
}

@media (min-width: 992px) {
  .pf-v5-u-min-width {
    min-width: var(--pf-v5-u-min-width--MinWidth-on-lg, var(--pf-v5-u-min-width--MinWidth-on-md, var(--pf-v5-u-min-width--MinWidth-on-sm, var(--pf-v5-u-min-width--MinWidth)))) !important;
  }
}

@media (min-width: 1200px) {
  .pf-v5-u-min-width {
    min-width: var(--pf-v5-u-min-width--MinWidth-on-xl, var(--pf-v5-u-min-width--MinWidth-on-lg, var(--pf-v5-u-min-width--MinWidth-on-md, var(--pf-v5-u-min-width--MinWidth-on-sm, var(--pf-v5-u-min-width--MinWidth))))) !important;
  }
}

@media (min-width: 1450px) {
  .pf-v5-u-min-width {
    min-width: var(--pf-v5-u-min-width--MinWidth-on-2xl, var(--pf-v5-u-min-width--MinWidth-on-xl, var(--pf-v5-u-min-width--MinWidth-on-lg, var(--pf-v5-u-min-width--MinWidth-on-md, var(--pf-v5-u-min-width--MinWidth-on-sm, var(--pf-v5-u-min-width--MinWidth)))))) !important;
  }
}

.pf-v5-u-max-width {
  --pf-v5-u-max-width--MaxWidth: auto;
  max-width: var(--pf-v5-u-max-width--MaxWidth) !important;
}

@media (min-width: 576px) {
  .pf-v5-u-max-width {
    max-width: var(--pf-v5-u-max-width--MaxWidth-on-sm, var(--pf-v5-u-max-width--MaxWidth)) !important;
  }
}

@media (min-width: 768px) {
  .pf-v5-u-max-width {
    max-width: var(--pf-v5-u-max-width--MaxWidth-on-md, var(--pf-v5-u-max-width--MaxWidth-on-sm, var(--pf-v5-u-max-width--MaxWidth))) !important;
  }
}

@media (min-width: 992px) {
  .pf-v5-u-max-width {
    max-width: var(--pf-v5-u-max-width--MaxWidth-on-lg, var(--pf-v5-u-max-width--MaxWidth-on-md, var(--pf-v5-u-max-width--MaxWidth-on-sm, var(--pf-v5-u-max-width--MaxWidth)))) !important;
  }
}

@media (min-width: 1200px) {
  .pf-v5-u-max-width {
    max-width: var(--pf-v5-u-max-width--MaxWidth-on-xl, var(--pf-v5-u-max-width--MaxWidth-on-lg, var(--pf-v5-u-max-width--MaxWidth-on-md, var(--pf-v5-u-max-width--MaxWidth-on-sm, var(--pf-v5-u-max-width--MaxWidth))))) !important;
  }
}

@media (min-width: 1450px) {
  .pf-v5-u-max-width {
    max-width: var(--pf-v5-u-max-width--MaxWidth-on-2xl, var(--pf-v5-u-max-width--MaxWidth-on-xl, var(--pf-v5-u-max-width--MaxWidth-on-lg, var(--pf-v5-u-max-width--MaxWidth-on-md, var(--pf-v5-u-max-width--MaxWidth-on-sm, var(--pf-v5-u-max-width--MaxWidth)))))) !important;
  }
}

.pf-v5-u-min-height {
  --pf-v5-u-min-height--MinHeight: 0;
  min-height: var(--pf-v5-u-min-height--MinHeight) !important;
}

@media (min-width: 576px) {
  .pf-v5-u-min-height {
    min-height: var(--pf-v5-u-min-height--MinHeight-on-sm, var(--pf-v5-u-min-height--MinHeight)) !important;
  }
}

@media (min-width: 768px) {
  .pf-v5-u-min-height {
    min-height: var(--pf-v5-u-min-height--MinHeight-on-md, var(--pf-v5-u-min-height--MinHeight-on-sm, var(--pf-v5-u-min-height--MinHeight))) !important;
  }
}

@media (min-width: 992px) {
  .pf-v5-u-min-height {
    min-height: var(--pf-v5-u-min-height--MinHeight-on-lg, var(--pf-v5-u-min-height--MinHeight-on-md, var(--pf-v5-u-min-height--MinHeight-on-sm, var(--pf-v5-u-min-height--MinHeight)))) !important;
  }
}

@media (min-width: 1200px) {
  .pf-v5-u-min-height {
    min-height: var(--pf-v5-u-min-height--MinHeight-on-xl, var(--pf-v5-u-min-height--MinHeight-on-lg, var(--pf-v5-u-min-height--MinHeight-on-md, var(--pf-v5-u-min-height--MinHeight-on-sm, var(--pf-v5-u-min-height--MinHeight))))) !important;
  }
}

@media (min-width: 1450px) {
  .pf-v5-u-min-height {
    min-height: var(--pf-v5-u-min-height--MinHeight-on-2xl, var(--pf-v5-u-min-height--MinHeight-on-xl, var(--pf-v5-u-min-height--MinHeight-on-lg, var(--pf-v5-u-min-height--MinHeight-on-md, var(--pf-v5-u-min-height--MinHeight-on-sm, var(--pf-v5-u-min-height--MinHeight)))))) !important;
  }
}

.pf-v5-u-max-height {
  --pf-v5-u-max-height--MaxHeight: auto;
  max-height: var(--pf-v5-u-max-height--MaxHeight) !important;
}

@media (min-width: 576px) {
  .pf-v5-u-max-height {
    max-height: var(--pf-v5-u-max-height--MaxHeight-on-sm, var(--pf-v5-u-max-height--MaxHeight)) !important;
  }
}

@media (min-width: 768px) {
  .pf-v5-u-max-height {
    max-height: var(--pf-v5-u-max-height--MaxHeight-on-md, var(--pf-v5-u-max-height--MaxHeight-on-sm, var(--pf-v5-u-max-height--MaxHeight))) !important;
  }
}

@media (min-width: 992px) {
  .pf-v5-u-max-height {
    max-height: var(--pf-v5-u-max-height--MaxHeight-on-lg, var(--pf-v5-u-max-height--MaxHeight-on-md, var(--pf-v5-u-max-height--MaxHeight-on-sm, var(--pf-v5-u-max-height--MaxHeight)))) !important;
  }
}

@media (min-width: 1200px) {
  .pf-v5-u-max-height {
    max-height: var(--pf-v5-u-max-height--MaxHeight-on-xl, var(--pf-v5-u-max-height--MaxHeight-on-lg, var(--pf-v5-u-max-height--MaxHeight-on-md, var(--pf-v5-u-max-height--MaxHeight-on-sm, var(--pf-v5-u-max-height--MaxHeight))))) !important;
  }
}

@media (min-width: 1450px) {
  .pf-v5-u-max-height {
    max-height: var(--pf-v5-u-max-height--MaxHeight-on-2xl, var(--pf-v5-u-max-height--MaxHeight-on-xl, var(--pf-v5-u-max-height--MaxHeight-on-lg, var(--pf-v5-u-max-height--MaxHeight-on-md, var(--pf-v5-u-max-height--MaxHeight-on-sm, var(--pf-v5-u-max-height--MaxHeight)))))) !important;
  }
}

.pf-v5-u-m-auto {
  margin: auto !important;
}

.pf-v5-u-m-0 {
  margin: 0 !important;
}

.pf-v5-u-m-xs {
  margin: var(--pf-v5-global--spacer--xs) !important;
}

.pf-v5-u-m-sm {
  margin: var(--pf-v5-global--spacer--sm) !important;
}

.pf-v5-u-m-md {
  margin: var(--pf-v5-global--spacer--md) !important;
}

.pf-v5-u-m-lg {
  margin: var(--pf-v5-global--spacer--lg) !important;
}

.pf-v5-u-m-xl {
  margin: var(--pf-v5-global--spacer--xl) !important;
}

.pf-v5-u-m-2xl {
  margin: var(--pf-v5-global--spacer--2xl) !important;
}

.pf-v5-u-m-3xl {
  margin: var(--pf-v5-global--spacer--3xl) !important;
}

.pf-v5-u-m-4xl {
  margin: var(--pf-v5-global--spacer--4xl) !important;
}

.pf-v5-u-mt-auto {
  margin-block-start: auto !important;
}

.pf-v5-u-mt-0 {
  margin-block-start: 0 !important;
}

.pf-v5-u-mt-xs {
  margin-block-start: var(--pf-v5-global--spacer--xs) !important;
}

.pf-v5-u-mt-sm {
  margin-block-start: var(--pf-v5-global--spacer--sm) !important;
}

.pf-v5-u-mt-md {
  margin-block-start: var(--pf-v5-global--spacer--md) !important;
}

.pf-v5-u-mt-lg {
  margin-block-start: var(--pf-v5-global--spacer--lg) !important;
}

.pf-v5-u-mt-xl {
  margin-block-start: var(--pf-v5-global--spacer--xl) !important;
}

.pf-v5-u-mt-2xl {
  margin-block-start: var(--pf-v5-global--spacer--2xl) !important;
}

.pf-v5-u-mt-3xl {
  margin-block-start: var(--pf-v5-global--spacer--3xl) !important;
}

.pf-v5-u-mt-4xl {
  margin-block-start: var(--pf-v5-global--spacer--4xl) !important;
}

.pf-v5-u-mr-auto {
  margin-inline-end: auto !important;
}

.pf-v5-u-mr-0 {
  margin-inline-end: 0 !important;
}

.pf-v5-u-mr-xs {
  margin-inline-end: var(--pf-v5-global--spacer--xs) !important;
}

.pf-v5-u-mr-sm {
  margin-inline-end: var(--pf-v5-global--spacer--sm) !important;
}

.pf-v5-u-mr-md {
  margin-inline-end: var(--pf-v5-global--spacer--md) !important;
}

.pf-v5-u-mr-lg {
  margin-inline-end: var(--pf-v5-global--spacer--lg) !important;
}

.pf-v5-u-mr-xl {
  margin-inline-end: var(--pf-v5-global--spacer--xl) !important;
}

.pf-v5-u-mr-2xl {
  margin-inline-end: var(--pf-v5-global--spacer--2xl) !important;
}

.pf-v5-u-mr-3xl {
  margin-inline-end: var(--pf-v5-global--spacer--3xl) !important;
}

.pf-v5-u-mr-4xl {
  margin-inline-end: var(--pf-v5-global--spacer--4xl) !important;
}

.pf-v5-u-mb-auto {
  margin-block-end: auto !important;
}

.pf-v5-u-mb-0 {
  margin-block-end: 0 !important;
}

.pf-v5-u-mb-xs {
  margin-block-end: var(--pf-v5-global--spacer--xs) !important;
}

.pf-v5-u-mb-sm {
  margin-block-end: var(--pf-v5-global--spacer--sm) !important;
}

.pf-v5-u-mb-md {
  margin-block-end: var(--pf-v5-global--spacer--md) !important;
}

.pf-v5-u-mb-lg {
  margin-block-end: var(--pf-v5-global--spacer--lg) !important;
}

.pf-v5-u-mb-xl {
  margin-block-end: var(--pf-v5-global--spacer--xl) !important;
}

.pf-v5-u-mb-2xl {
  margin-block-end: var(--pf-v5-global--spacer--2xl) !important;
}

.pf-v5-u-mb-3xl {
  margin-block-end: var(--pf-v5-global--spacer--3xl) !important;
}

.pf-v5-u-mb-4xl {
  margin-block-end: var(--pf-v5-global--spacer--4xl) !important;
}

.pf-v5-u-ml-auto {
  margin-inline-start: auto !important;
}

.pf-v5-u-ml-0 {
  margin-inline-start: 0 !important;
}

.pf-v5-u-ml-xs {
  margin-inline-start: var(--pf-v5-global--spacer--xs) !important;
}

.pf-v5-u-ml-sm {
  margin-inline-start: var(--pf-v5-global--spacer--sm) !important;
}

.pf-v5-u-ml-md {
  margin-inline-start: var(--pf-v5-global--spacer--md) !important;
}

.pf-v5-u-ml-lg {
  margin-inline-start: var(--pf-v5-global--spacer--lg) !important;
}

.pf-v5-u-ml-xl {
  margin-inline-start: var(--pf-v5-global--spacer--xl) !important;
}

.pf-v5-u-ml-2xl {
  margin-inline-start: var(--pf-v5-global--spacer--2xl) !important;
}

.pf-v5-u-ml-3xl {
  margin-inline-start: var(--pf-v5-global--spacer--3xl) !important;
}

.pf-v5-u-ml-4xl {
  margin-inline-start: var(--pf-v5-global--spacer--4xl) !important;
}

.pf-v5-u-mx-auto {
  margin-inline: auto !important;
}

.pf-v5-u-mx-0 {
  margin-inline: 0 !important;
}

.pf-v5-u-mx-xs {
  margin-inline: var(--pf-v5-global--spacer--xs) !important;
}

.pf-v5-u-mx-sm {
  margin-inline: var(--pf-v5-global--spacer--sm) !important;
}

.pf-v5-u-mx-md {
  margin-inline: var(--pf-v5-global--spacer--md) !important;
}

.pf-v5-u-mx-lg {
  margin-inline: var(--pf-v5-global--spacer--lg) !important;
}

.pf-v5-u-mx-xl {
  margin-inline: var(--pf-v5-global--spacer--xl) !important;
}

.pf-v5-u-mx-2xl {
  margin-inline: var(--pf-v5-global--spacer--2xl) !important;
}

.pf-v5-u-mx-3xl {
  margin-inline: var(--pf-v5-global--spacer--3xl) !important;
}

.pf-v5-u-mx-4xl {
  margin-inline: var(--pf-v5-global--spacer--4xl) !important;
}

.pf-v5-u-my-auto {
  margin-block: auto !important;
}

.pf-v5-u-my-0 {
  margin-block: 0 !important;
}

.pf-v5-u-my-xs {
  margin-block: var(--pf-v5-global--spacer--xs) !important;
}

.pf-v5-u-my-sm {
  margin-block: var(--pf-v5-global--spacer--sm) !important;
}

.pf-v5-u-my-md {
  margin-block: var(--pf-v5-global--spacer--md) !important;
}

.pf-v5-u-my-lg {
  margin-block: var(--pf-v5-global--spacer--lg) !important;
}

.pf-v5-u-my-xl {
  margin-block: var(--pf-v5-global--spacer--xl) !important;
}

.pf-v5-u-my-2xl {
  margin-block: var(--pf-v5-global--spacer--2xl) !important;
}

.pf-v5-u-my-3xl {
  margin-block: var(--pf-v5-global--spacer--3xl) !important;
}

.pf-v5-u-my-4xl {
  margin-block: var(--pf-v5-global--spacer--4xl) !important;
}

.pf-v5-u-p-auto {
  padding: auto !important;
}

.pf-v5-u-p-0 {
  padding: 0 !important;
}

.pf-v5-u-p-xs {
  padding: var(--pf-v5-global--spacer--xs) !important;
}

.pf-v5-u-p-sm {
  padding: var(--pf-v5-global--spacer--sm) !important;
}

.pf-v5-u-p-md {
  padding: var(--pf-v5-global--spacer--md) !important;
}

.pf-v5-u-p-lg {
  padding: var(--pf-v5-global--spacer--lg) !important;
}

.pf-v5-u-p-xl {
  padding: var(--pf-v5-global--spacer--xl) !important;
}

.pf-v5-u-p-2xl {
  padding: var(--pf-v5-global--spacer--2xl) !important;
}

.pf-v5-u-p-3xl {
  padding: var(--pf-v5-global--spacer--3xl) !important;
}

.pf-v5-u-p-4xl {
  padding: var(--pf-v5-global--spacer--4xl) !important;
}

.pf-v5-u-pt-auto {
  padding-block-start: auto !important;
}

.pf-v5-u-pt-0 {
  padding-block-start: 0 !important;
}

.pf-v5-u-pt-xs {
  padding-block-start: var(--pf-v5-global--spacer--xs) !important;
}

.pf-v5-u-pt-sm {
  padding-block-start: var(--pf-v5-global--spacer--sm) !important;
}

.pf-v5-u-pt-md {
  padding-block-start: var(--pf-v5-global--spacer--md) !important;
}

.pf-v5-u-pt-lg {
  padding-block-start: var(--pf-v5-global--spacer--lg) !important;
}

.pf-v5-u-pt-xl {
  padding-block-start: var(--pf-v5-global--spacer--xl) !important;
}

.pf-v5-u-pt-2xl {
  padding-block-start: var(--pf-v5-global--spacer--2xl) !important;
}

.pf-v5-u-pt-3xl {
  padding-block-start: var(--pf-v5-global--spacer--3xl) !important;
}

.pf-v5-u-pt-4xl {
  padding-block-start: var(--pf-v5-global--spacer--4xl) !important;
}

.pf-v5-u-pr-auto {
  padding-inline-end: auto !important;
}

.pf-v5-u-pr-0 {
  padding-inline-end: 0 !important;
}

.pf-v5-u-pr-xs {
  padding-inline-end: var(--pf-v5-global--spacer--xs) !important;
}

.pf-v5-u-pr-sm {
  padding-inline-end: var(--pf-v5-global--spacer--sm) !important;
}

.pf-v5-u-pr-md {
  padding-inline-end: var(--pf-v5-global--spacer--md) !important;
}

.pf-v5-u-pr-lg {
  padding-inline-end: var(--pf-v5-global--spacer--lg) !important;
}

.pf-v5-u-pr-xl {
  padding-inline-end: var(--pf-v5-global--spacer--xl) !important;
}

.pf-v5-u-pr-2xl {
  padding-inline-end: var(--pf-v5-global--spacer--2xl) !important;
}

.pf-v5-u-pr-3xl {
  padding-inline-end: var(--pf-v5-global--spacer--3xl) !important;
}

.pf-v5-u-pr-4xl {
  padding-inline-end: var(--pf-v5-global--spacer--4xl) !important;
}

.pf-v5-u-pb-auto {
  padding-block-end: auto !important;
}

.pf-v5-u-pb-0 {
  padding-block-end: 0 !important;
}

.pf-v5-u-pb-xs {
  padding-block-end: var(--pf-v5-global--spacer--xs) !important;
}

.pf-v5-u-pb-sm {
  padding-block-end: var(--pf-v5-global--spacer--sm) !important;
}

.pf-v5-u-pb-md {
  padding-block-end: var(--pf-v5-global--spacer--md) !important;
}

.pf-v5-u-pb-lg {
  padding-block-end: var(--pf-v5-global--spacer--lg) !important;
}

.pf-v5-u-pb-xl {
  padding-block-end: var(--pf-v5-global--spacer--xl) !important;
}

.pf-v5-u-pb-2xl {
  padding-block-end: var(--pf-v5-global--spacer--2xl) !important;
}

.pf-v5-u-pb-3xl {
  padding-block-end: var(--pf-v5-global--spacer--3xl) !important;
}

.pf-v5-u-pb-4xl {
  padding-block-end: var(--pf-v5-global--spacer--4xl) !important;
}

.pf-v5-u-pl-auto {
  padding-inline-start: auto !important;
}

.pf-v5-u-pl-0 {
  padding-inline-start: 0 !important;
}

.pf-v5-u-pl-xs {
  padding-inline-start: var(--pf-v5-global--spacer--xs) !important;
}

.pf-v5-u-pl-sm {
  padding-inline-start: var(--pf-v5-global--spacer--sm) !important;
}

.pf-v5-u-pl-md {
  padding-inline-start: var(--pf-v5-global--spacer--md) !important;
}

.pf-v5-u-pl-lg {
  padding-inline-start: var(--pf-v5-global--spacer--lg) !important;
}

.pf-v5-u-pl-xl {
  padding-inline-start: var(--pf-v5-global--spacer--xl) !important;
}

.pf-v5-u-pl-2xl {
  padding-inline-start: var(--pf-v5-global--spacer--2xl) !important;
}

.pf-v5-u-pl-3xl {
  padding-inline-start: var(--pf-v5-global--spacer--3xl) !important;
}

.pf-v5-u-pl-4xl {
  padding-inline-start: var(--pf-v5-global--spacer--4xl) !important;
}

.pf-v5-u-px-auto {
  padding-inline: auto !important;
}

.pf-v5-u-px-0 {
  padding-inline: 0 !important;
}

.pf-v5-u-px-xs {
  padding-inline: var(--pf-v5-global--spacer--xs) !important;
}

.pf-v5-u-px-sm {
  padding-inline: var(--pf-v5-global--spacer--sm) !important;
}

.pf-v5-u-px-md {
  padding-inline: var(--pf-v5-global--spacer--md) !important;
}

.pf-v5-u-px-lg {
  padding-inline: var(--pf-v5-global--spacer--lg) !important;
}

.pf-v5-u-px-xl {
  padding-inline: var(--pf-v5-global--spacer--xl) !important;
}

.pf-v5-u-px-2xl {
  padding-inline: var(--pf-v5-global--spacer--2xl) !important;
}

.pf-v5-u-px-3xl {
  padding-inline: var(--pf-v5-global--spacer--3xl) !important;
}

.pf-v5-u-px-4xl {
  padding-inline: var(--pf-v5-global--spacer--4xl) !important;
}

.pf-v5-u-py-auto {
  padding-block: auto !important;
}

.pf-v5-u-py-0 {
  padding-block: 0 !important;
}

.pf-v5-u-py-xs {
  padding-block: var(--pf-v5-global--spacer--xs) !important;
}

.pf-v5-u-py-sm {
  padding-block: var(--pf-v5-global--spacer--sm) !important;
}

.pf-v5-u-py-md {
  padding-block: var(--pf-v5-global--spacer--md) !important;
}

.pf-v5-u-py-lg {
  padding-block: var(--pf-v5-global--spacer--lg) !important;
}

.pf-v5-u-py-xl {
  padding-block: var(--pf-v5-global--spacer--xl) !important;
}

.pf-v5-u-py-2xl {
  padding-block: var(--pf-v5-global--spacer--2xl) !important;
}

.pf-v5-u-py-3xl {
  padding-block: var(--pf-v5-global--spacer--3xl) !important;
}

.pf-v5-u-py-4xl {
  padding-block: var(--pf-v5-global--spacer--4xl) !important;
}

@media screen and (min-width: 576px) {
  .pf-v5-u-m-auto-on-sm {
    margin: auto !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-m-0-on-sm {
    margin: 0 !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-m-xs-on-sm {
    margin: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-m-sm-on-sm {
    margin: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-m-md-on-sm {
    margin: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-m-lg-on-sm {
    margin: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-m-xl-on-sm {
    margin: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-m-2xl-on-sm {
    margin: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-m-3xl-on-sm {
    margin: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-m-4xl-on-sm {
    margin: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mt-auto-on-sm {
    margin-block-start: auto !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mt-0-on-sm {
    margin-block-start: 0 !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mt-xs-on-sm {
    margin-block-start: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mt-sm-on-sm {
    margin-block-start: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mt-md-on-sm {
    margin-block-start: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mt-lg-on-sm {
    margin-block-start: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mt-xl-on-sm {
    margin-block-start: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mt-2xl-on-sm {
    margin-block-start: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mt-3xl-on-sm {
    margin-block-start: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mt-4xl-on-sm {
    margin-block-start: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mr-auto-on-sm {
    margin-inline-end: auto !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mr-0-on-sm {
    margin-inline-end: 0 !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mr-xs-on-sm {
    margin-inline-end: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mr-sm-on-sm {
    margin-inline-end: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mr-md-on-sm {
    margin-inline-end: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mr-lg-on-sm {
    margin-inline-end: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mr-xl-on-sm {
    margin-inline-end: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mr-2xl-on-sm {
    margin-inline-end: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mr-3xl-on-sm {
    margin-inline-end: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mr-4xl-on-sm {
    margin-inline-end: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mb-auto-on-sm {
    margin-block-end: auto !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mb-0-on-sm {
    margin-block-end: 0 !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mb-xs-on-sm {
    margin-block-end: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mb-sm-on-sm {
    margin-block-end: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mb-md-on-sm {
    margin-block-end: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mb-lg-on-sm {
    margin-block-end: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mb-xl-on-sm {
    margin-block-end: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mb-2xl-on-sm {
    margin-block-end: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mb-3xl-on-sm {
    margin-block-end: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mb-4xl-on-sm {
    margin-block-end: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-ml-auto-on-sm {
    margin-inline-start: auto !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-ml-0-on-sm {
    margin-inline-start: 0 !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-ml-xs-on-sm {
    margin-inline-start: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-ml-sm-on-sm {
    margin-inline-start: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-ml-md-on-sm {
    margin-inline-start: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-ml-lg-on-sm {
    margin-inline-start: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-ml-xl-on-sm {
    margin-inline-start: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-ml-2xl-on-sm {
    margin-inline-start: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-ml-3xl-on-sm {
    margin-inline-start: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-ml-4xl-on-sm {
    margin-inline-start: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mx-auto-on-sm {
    margin-inline: auto !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mx-0-on-sm {
    margin-inline: 0 !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mx-xs-on-sm {
    margin-inline: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mx-sm-on-sm {
    margin-inline: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mx-md-on-sm {
    margin-inline: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mx-lg-on-sm {
    margin-inline: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mx-xl-on-sm {
    margin-inline: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mx-2xl-on-sm {
    margin-inline: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mx-3xl-on-sm {
    margin-inline: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-mx-4xl-on-sm {
    margin-inline: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-my-auto-on-sm {
    margin-block: auto !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-my-0-on-sm {
    margin-block: 0 !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-my-xs-on-sm {
    margin-block: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-my-sm-on-sm {
    margin-block: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-my-md-on-sm {
    margin-block: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-my-lg-on-sm {
    margin-block: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-my-xl-on-sm {
    margin-block: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-my-2xl-on-sm {
    margin-block: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-my-3xl-on-sm {
    margin-block: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-my-4xl-on-sm {
    margin-block: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-p-auto-on-sm {
    padding: auto !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-p-0-on-sm {
    padding: 0 !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-p-xs-on-sm {
    padding: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-p-sm-on-sm {
    padding: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-p-md-on-sm {
    padding: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-p-lg-on-sm {
    padding: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-p-xl-on-sm {
    padding: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-p-2xl-on-sm {
    padding: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-p-3xl-on-sm {
    padding: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-p-4xl-on-sm {
    padding: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pt-auto-on-sm {
    padding-block-start: auto !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pt-0-on-sm {
    padding-block-start: 0 !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pt-xs-on-sm {
    padding-block-start: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pt-sm-on-sm {
    padding-block-start: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pt-md-on-sm {
    padding-block-start: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pt-lg-on-sm {
    padding-block-start: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pt-xl-on-sm {
    padding-block-start: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pt-2xl-on-sm {
    padding-block-start: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pt-3xl-on-sm {
    padding-block-start: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pt-4xl-on-sm {
    padding-block-start: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pr-auto-on-sm {
    padding-inline-end: auto !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pr-0-on-sm {
    padding-inline-end: 0 !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pr-xs-on-sm {
    padding-inline-end: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pr-sm-on-sm {
    padding-inline-end: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pr-md-on-sm {
    padding-inline-end: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pr-lg-on-sm {
    padding-inline-end: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pr-xl-on-sm {
    padding-inline-end: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pr-2xl-on-sm {
    padding-inline-end: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pr-3xl-on-sm {
    padding-inline-end: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pr-4xl-on-sm {
    padding-inline-end: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pb-auto-on-sm {
    padding-block-end: auto !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pb-0-on-sm {
    padding-block-end: 0 !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pb-xs-on-sm {
    padding-block-end: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pb-sm-on-sm {
    padding-block-end: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pb-md-on-sm {
    padding-block-end: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pb-lg-on-sm {
    padding-block-end: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pb-xl-on-sm {
    padding-block-end: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pb-2xl-on-sm {
    padding-block-end: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pb-3xl-on-sm {
    padding-block-end: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pb-4xl-on-sm {
    padding-block-end: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pl-auto-on-sm {
    padding-inline-start: auto !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pl-0-on-sm {
    padding-inline-start: 0 !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pl-xs-on-sm {
    padding-inline-start: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pl-sm-on-sm {
    padding-inline-start: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pl-md-on-sm {
    padding-inline-start: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pl-lg-on-sm {
    padding-inline-start: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pl-xl-on-sm {
    padding-inline-start: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pl-2xl-on-sm {
    padding-inline-start: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pl-3xl-on-sm {
    padding-inline-start: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-pl-4xl-on-sm {
    padding-inline-start: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-px-auto-on-sm {
    padding-inline: auto !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-px-0-on-sm {
    padding-inline: 0 !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-px-xs-on-sm {
    padding-inline: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-px-sm-on-sm {
    padding-inline: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-px-md-on-sm {
    padding-inline: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-px-lg-on-sm {
    padding-inline: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-px-xl-on-sm {
    padding-inline: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-px-2xl-on-sm {
    padding-inline: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-px-3xl-on-sm {
    padding-inline: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-px-4xl-on-sm {
    padding-inline: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-py-auto-on-sm {
    padding-block: auto !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-py-0-on-sm {
    padding-block: 0 !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-py-xs-on-sm {
    padding-block: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-py-sm-on-sm {
    padding-block: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-py-md-on-sm {
    padding-block: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-py-lg-on-sm {
    padding-block: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-py-xl-on-sm {
    padding-block: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-py-2xl-on-sm {
    padding-block: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-py-3xl-on-sm {
    padding-block: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 576px) {
  .pf-v5-u-py-4xl-on-sm {
    padding-block: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-m-auto-on-md {
    margin: auto !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-m-0-on-md {
    margin: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-m-xs-on-md {
    margin: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-m-sm-on-md {
    margin: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-m-md-on-md {
    margin: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-m-lg-on-md {
    margin: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-m-xl-on-md {
    margin: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-m-2xl-on-md {
    margin: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-m-3xl-on-md {
    margin: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-m-4xl-on-md {
    margin: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mt-auto-on-md {
    margin-block-start: auto !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mt-0-on-md {
    margin-block-start: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mt-xs-on-md {
    margin-block-start: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mt-sm-on-md {
    margin-block-start: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mt-md-on-md {
    margin-block-start: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mt-lg-on-md {
    margin-block-start: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mt-xl-on-md {
    margin-block-start: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mt-2xl-on-md {
    margin-block-start: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mt-3xl-on-md {
    margin-block-start: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mt-4xl-on-md {
    margin-block-start: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mr-auto-on-md {
    margin-inline-end: auto !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mr-0-on-md {
    margin-inline-end: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mr-xs-on-md {
    margin-inline-end: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mr-sm-on-md {
    margin-inline-end: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mr-md-on-md {
    margin-inline-end: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mr-lg-on-md {
    margin-inline-end: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mr-xl-on-md {
    margin-inline-end: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mr-2xl-on-md {
    margin-inline-end: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mr-3xl-on-md {
    margin-inline-end: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mr-4xl-on-md {
    margin-inline-end: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mb-auto-on-md {
    margin-block-end: auto !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mb-0-on-md {
    margin-block-end: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mb-xs-on-md {
    margin-block-end: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mb-sm-on-md {
    margin-block-end: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mb-md-on-md {
    margin-block-end: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mb-lg-on-md {
    margin-block-end: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mb-xl-on-md {
    margin-block-end: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mb-2xl-on-md {
    margin-block-end: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mb-3xl-on-md {
    margin-block-end: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mb-4xl-on-md {
    margin-block-end: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-ml-auto-on-md {
    margin-inline-start: auto !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-ml-0-on-md {
    margin-inline-start: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-ml-xs-on-md {
    margin-inline-start: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-ml-sm-on-md {
    margin-inline-start: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-ml-md-on-md {
    margin-inline-start: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-ml-lg-on-md {
    margin-inline-start: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-ml-xl-on-md {
    margin-inline-start: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-ml-2xl-on-md {
    margin-inline-start: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-ml-3xl-on-md {
    margin-inline-start: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-ml-4xl-on-md {
    margin-inline-start: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mx-auto-on-md {
    margin-inline: auto !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mx-0-on-md {
    margin-inline: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mx-xs-on-md {
    margin-inline: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mx-sm-on-md {
    margin-inline: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mx-md-on-md {
    margin-inline: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mx-lg-on-md {
    margin-inline: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mx-xl-on-md {
    margin-inline: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mx-2xl-on-md {
    margin-inline: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mx-3xl-on-md {
    margin-inline: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-mx-4xl-on-md {
    margin-inline: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-my-auto-on-md {
    margin-block: auto !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-my-0-on-md {
    margin-block: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-my-xs-on-md {
    margin-block: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-my-sm-on-md {
    margin-block: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-my-md-on-md {
    margin-block: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-my-lg-on-md {
    margin-block: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-my-xl-on-md {
    margin-block: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-my-2xl-on-md {
    margin-block: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-my-3xl-on-md {
    margin-block: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-my-4xl-on-md {
    margin-block: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-p-auto-on-md {
    padding: auto !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-p-0-on-md {
    padding: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-p-xs-on-md {
    padding: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-p-sm-on-md {
    padding: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-p-md-on-md {
    padding: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-p-lg-on-md {
    padding: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-p-xl-on-md {
    padding: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-p-2xl-on-md {
    padding: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-p-3xl-on-md {
    padding: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-p-4xl-on-md {
    padding: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pt-auto-on-md {
    padding-block-start: auto !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pt-0-on-md {
    padding-block-start: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pt-xs-on-md {
    padding-block-start: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pt-sm-on-md {
    padding-block-start: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pt-md-on-md {
    padding-block-start: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pt-lg-on-md {
    padding-block-start: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pt-xl-on-md {
    padding-block-start: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pt-2xl-on-md {
    padding-block-start: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pt-3xl-on-md {
    padding-block-start: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pt-4xl-on-md {
    padding-block-start: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pr-auto-on-md {
    padding-inline-end: auto !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pr-0-on-md {
    padding-inline-end: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pr-xs-on-md {
    padding-inline-end: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pr-sm-on-md {
    padding-inline-end: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pr-md-on-md {
    padding-inline-end: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pr-lg-on-md {
    padding-inline-end: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pr-xl-on-md {
    padding-inline-end: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pr-2xl-on-md {
    padding-inline-end: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pr-3xl-on-md {
    padding-inline-end: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pr-4xl-on-md {
    padding-inline-end: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pb-auto-on-md {
    padding-block-end: auto !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pb-0-on-md {
    padding-block-end: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pb-xs-on-md {
    padding-block-end: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pb-sm-on-md {
    padding-block-end: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pb-md-on-md {
    padding-block-end: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pb-lg-on-md {
    padding-block-end: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pb-xl-on-md {
    padding-block-end: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pb-2xl-on-md {
    padding-block-end: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pb-3xl-on-md {
    padding-block-end: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pb-4xl-on-md {
    padding-block-end: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pl-auto-on-md {
    padding-inline-start: auto !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pl-0-on-md {
    padding-inline-start: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pl-xs-on-md {
    padding-inline-start: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pl-sm-on-md {
    padding-inline-start: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pl-md-on-md {
    padding-inline-start: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pl-lg-on-md {
    padding-inline-start: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pl-xl-on-md {
    padding-inline-start: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pl-2xl-on-md {
    padding-inline-start: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pl-3xl-on-md {
    padding-inline-start: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-pl-4xl-on-md {
    padding-inline-start: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-px-auto-on-md {
    padding-inline: auto !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-px-0-on-md {
    padding-inline: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-px-xs-on-md {
    padding-inline: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-px-sm-on-md {
    padding-inline: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-px-md-on-md {
    padding-inline: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-px-lg-on-md {
    padding-inline: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-px-xl-on-md {
    padding-inline: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-px-2xl-on-md {
    padding-inline: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-px-3xl-on-md {
    padding-inline: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-px-4xl-on-md {
    padding-inline: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-py-auto-on-md {
    padding-block: auto !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-py-0-on-md {
    padding-block: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-py-xs-on-md {
    padding-block: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-py-sm-on-md {
    padding-block: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-py-md-on-md {
    padding-block: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-py-lg-on-md {
    padding-block: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-py-xl-on-md {
    padding-block: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-py-2xl-on-md {
    padding-block: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-py-3xl-on-md {
    padding-block: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-py-4xl-on-md {
    padding-block: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-m-auto-on-lg {
    margin: auto !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-m-0-on-lg {
    margin: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-m-xs-on-lg {
    margin: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-m-sm-on-lg {
    margin: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-m-md-on-lg {
    margin: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-m-lg-on-lg {
    margin: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-m-xl-on-lg {
    margin: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-m-2xl-on-lg {
    margin: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-m-3xl-on-lg {
    margin: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-m-4xl-on-lg {
    margin: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mt-auto-on-lg {
    margin-block-start: auto !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mt-0-on-lg {
    margin-block-start: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mt-xs-on-lg {
    margin-block-start: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mt-sm-on-lg {
    margin-block-start: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mt-md-on-lg {
    margin-block-start: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mt-lg-on-lg {
    margin-block-start: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mt-xl-on-lg {
    margin-block-start: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mt-2xl-on-lg {
    margin-block-start: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mt-3xl-on-lg {
    margin-block-start: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mt-4xl-on-lg {
    margin-block-start: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mr-auto-on-lg {
    margin-inline-end: auto !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mr-0-on-lg {
    margin-inline-end: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mr-xs-on-lg {
    margin-inline-end: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mr-sm-on-lg {
    margin-inline-end: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mr-md-on-lg {
    margin-inline-end: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mr-lg-on-lg {
    margin-inline-end: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mr-xl-on-lg {
    margin-inline-end: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mr-2xl-on-lg {
    margin-inline-end: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mr-3xl-on-lg {
    margin-inline-end: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mr-4xl-on-lg {
    margin-inline-end: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mb-auto-on-lg {
    margin-block-end: auto !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mb-0-on-lg {
    margin-block-end: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mb-xs-on-lg {
    margin-block-end: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mb-sm-on-lg {
    margin-block-end: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mb-md-on-lg {
    margin-block-end: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mb-lg-on-lg {
    margin-block-end: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mb-xl-on-lg {
    margin-block-end: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mb-2xl-on-lg {
    margin-block-end: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mb-3xl-on-lg {
    margin-block-end: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mb-4xl-on-lg {
    margin-block-end: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-ml-auto-on-lg {
    margin-inline-start: auto !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-ml-0-on-lg {
    margin-inline-start: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-ml-xs-on-lg {
    margin-inline-start: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-ml-sm-on-lg {
    margin-inline-start: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-ml-md-on-lg {
    margin-inline-start: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-ml-lg-on-lg {
    margin-inline-start: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-ml-xl-on-lg {
    margin-inline-start: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-ml-2xl-on-lg {
    margin-inline-start: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-ml-3xl-on-lg {
    margin-inline-start: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-ml-4xl-on-lg {
    margin-inline-start: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mx-auto-on-lg {
    margin-inline: auto !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mx-0-on-lg {
    margin-inline: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mx-xs-on-lg {
    margin-inline: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mx-sm-on-lg {
    margin-inline: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mx-md-on-lg {
    margin-inline: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mx-lg-on-lg {
    margin-inline: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mx-xl-on-lg {
    margin-inline: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mx-2xl-on-lg {
    margin-inline: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mx-3xl-on-lg {
    margin-inline: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-mx-4xl-on-lg {
    margin-inline: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-my-auto-on-lg {
    margin-block: auto !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-my-0-on-lg {
    margin-block: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-my-xs-on-lg {
    margin-block: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-my-sm-on-lg {
    margin-block: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-my-md-on-lg {
    margin-block: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-my-lg-on-lg {
    margin-block: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-my-xl-on-lg {
    margin-block: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-my-2xl-on-lg {
    margin-block: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-my-3xl-on-lg {
    margin-block: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-my-4xl-on-lg {
    margin-block: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-p-auto-on-lg {
    padding: auto !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-p-0-on-lg {
    padding: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-p-xs-on-lg {
    padding: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-p-sm-on-lg {
    padding: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-p-md-on-lg {
    padding: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-p-lg-on-lg {
    padding: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-p-xl-on-lg {
    padding: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-p-2xl-on-lg {
    padding: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-p-3xl-on-lg {
    padding: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-p-4xl-on-lg {
    padding: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pt-auto-on-lg {
    padding-block-start: auto !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pt-0-on-lg {
    padding-block-start: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pt-xs-on-lg {
    padding-block-start: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pt-sm-on-lg {
    padding-block-start: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pt-md-on-lg {
    padding-block-start: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pt-lg-on-lg {
    padding-block-start: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pt-xl-on-lg {
    padding-block-start: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pt-2xl-on-lg {
    padding-block-start: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pt-3xl-on-lg {
    padding-block-start: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pt-4xl-on-lg {
    padding-block-start: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pr-auto-on-lg {
    padding-inline-end: auto !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pr-0-on-lg {
    padding-inline-end: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pr-xs-on-lg {
    padding-inline-end: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pr-sm-on-lg {
    padding-inline-end: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pr-md-on-lg {
    padding-inline-end: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pr-lg-on-lg {
    padding-inline-end: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pr-xl-on-lg {
    padding-inline-end: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pr-2xl-on-lg {
    padding-inline-end: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pr-3xl-on-lg {
    padding-inline-end: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pr-4xl-on-lg {
    padding-inline-end: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pb-auto-on-lg {
    padding-block-end: auto !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pb-0-on-lg {
    padding-block-end: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pb-xs-on-lg {
    padding-block-end: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pb-sm-on-lg {
    padding-block-end: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pb-md-on-lg {
    padding-block-end: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pb-lg-on-lg {
    padding-block-end: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pb-xl-on-lg {
    padding-block-end: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pb-2xl-on-lg {
    padding-block-end: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pb-3xl-on-lg {
    padding-block-end: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pb-4xl-on-lg {
    padding-block-end: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pl-auto-on-lg {
    padding-inline-start: auto !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pl-0-on-lg {
    padding-inline-start: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pl-xs-on-lg {
    padding-inline-start: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pl-sm-on-lg {
    padding-inline-start: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pl-md-on-lg {
    padding-inline-start: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pl-lg-on-lg {
    padding-inline-start: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pl-xl-on-lg {
    padding-inline-start: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pl-2xl-on-lg {
    padding-inline-start: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pl-3xl-on-lg {
    padding-inline-start: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-pl-4xl-on-lg {
    padding-inline-start: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-px-auto-on-lg {
    padding-inline: auto !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-px-0-on-lg {
    padding-inline: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-px-xs-on-lg {
    padding-inline: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-px-sm-on-lg {
    padding-inline: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-px-md-on-lg {
    padding-inline: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-px-lg-on-lg {
    padding-inline: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-px-xl-on-lg {
    padding-inline: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-px-2xl-on-lg {
    padding-inline: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-px-3xl-on-lg {
    padding-inline: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-px-4xl-on-lg {
    padding-inline: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-py-auto-on-lg {
    padding-block: auto !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-py-0-on-lg {
    padding-block: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-py-xs-on-lg {
    padding-block: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-py-sm-on-lg {
    padding-block: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-py-md-on-lg {
    padding-block: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-py-lg-on-lg {
    padding-block: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-py-xl-on-lg {
    padding-block: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-py-2xl-on-lg {
    padding-block: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-py-3xl-on-lg {
    padding-block: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-py-4xl-on-lg {
    padding-block: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-m-auto-on-xl {
    margin: auto !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-m-0-on-xl {
    margin: 0 !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-m-xs-on-xl {
    margin: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-m-sm-on-xl {
    margin: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-m-md-on-xl {
    margin: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-m-lg-on-xl {
    margin: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-m-xl-on-xl {
    margin: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-m-2xl-on-xl {
    margin: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-m-3xl-on-xl {
    margin: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-m-4xl-on-xl {
    margin: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mt-auto-on-xl {
    margin-block-start: auto !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mt-0-on-xl {
    margin-block-start: 0 !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mt-xs-on-xl {
    margin-block-start: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mt-sm-on-xl {
    margin-block-start: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mt-md-on-xl {
    margin-block-start: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mt-lg-on-xl {
    margin-block-start: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mt-xl-on-xl {
    margin-block-start: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mt-2xl-on-xl {
    margin-block-start: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mt-3xl-on-xl {
    margin-block-start: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mt-4xl-on-xl {
    margin-block-start: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mr-auto-on-xl {
    margin-inline-end: auto !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mr-0-on-xl {
    margin-inline-end: 0 !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mr-xs-on-xl {
    margin-inline-end: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mr-sm-on-xl {
    margin-inline-end: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mr-md-on-xl {
    margin-inline-end: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mr-lg-on-xl {
    margin-inline-end: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mr-xl-on-xl {
    margin-inline-end: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mr-2xl-on-xl {
    margin-inline-end: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mr-3xl-on-xl {
    margin-inline-end: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mr-4xl-on-xl {
    margin-inline-end: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mb-auto-on-xl {
    margin-block-end: auto !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mb-0-on-xl {
    margin-block-end: 0 !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mb-xs-on-xl {
    margin-block-end: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mb-sm-on-xl {
    margin-block-end: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mb-md-on-xl {
    margin-block-end: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mb-lg-on-xl {
    margin-block-end: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mb-xl-on-xl {
    margin-block-end: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mb-2xl-on-xl {
    margin-block-end: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mb-3xl-on-xl {
    margin-block-end: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mb-4xl-on-xl {
    margin-block-end: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-ml-auto-on-xl {
    margin-inline-start: auto !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-ml-0-on-xl {
    margin-inline-start: 0 !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-ml-xs-on-xl {
    margin-inline-start: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-ml-sm-on-xl {
    margin-inline-start: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-ml-md-on-xl {
    margin-inline-start: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-ml-lg-on-xl {
    margin-inline-start: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-ml-xl-on-xl {
    margin-inline-start: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-ml-2xl-on-xl {
    margin-inline-start: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-ml-3xl-on-xl {
    margin-inline-start: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-ml-4xl-on-xl {
    margin-inline-start: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mx-auto-on-xl {
    margin-inline: auto !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mx-0-on-xl {
    margin-inline: 0 !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mx-xs-on-xl {
    margin-inline: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mx-sm-on-xl {
    margin-inline: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mx-md-on-xl {
    margin-inline: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mx-lg-on-xl {
    margin-inline: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mx-xl-on-xl {
    margin-inline: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mx-2xl-on-xl {
    margin-inline: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mx-3xl-on-xl {
    margin-inline: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-mx-4xl-on-xl {
    margin-inline: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-my-auto-on-xl {
    margin-block: auto !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-my-0-on-xl {
    margin-block: 0 !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-my-xs-on-xl {
    margin-block: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-my-sm-on-xl {
    margin-block: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-my-md-on-xl {
    margin-block: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-my-lg-on-xl {
    margin-block: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-my-xl-on-xl {
    margin-block: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-my-2xl-on-xl {
    margin-block: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-my-3xl-on-xl {
    margin-block: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-my-4xl-on-xl {
    margin-block: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-p-auto-on-xl {
    padding: auto !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-p-0-on-xl {
    padding: 0 !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-p-xs-on-xl {
    padding: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-p-sm-on-xl {
    padding: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-p-md-on-xl {
    padding: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-p-lg-on-xl {
    padding: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-p-xl-on-xl {
    padding: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-p-2xl-on-xl {
    padding: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-p-3xl-on-xl {
    padding: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-p-4xl-on-xl {
    padding: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pt-auto-on-xl {
    padding-block-start: auto !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pt-0-on-xl {
    padding-block-start: 0 !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pt-xs-on-xl {
    padding-block-start: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pt-sm-on-xl {
    padding-block-start: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pt-md-on-xl {
    padding-block-start: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pt-lg-on-xl {
    padding-block-start: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pt-xl-on-xl {
    padding-block-start: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pt-2xl-on-xl {
    padding-block-start: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pt-3xl-on-xl {
    padding-block-start: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pt-4xl-on-xl {
    padding-block-start: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pr-auto-on-xl {
    padding-inline-end: auto !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pr-0-on-xl {
    padding-inline-end: 0 !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pr-xs-on-xl {
    padding-inline-end: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pr-sm-on-xl {
    padding-inline-end: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pr-md-on-xl {
    padding-inline-end: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pr-lg-on-xl {
    padding-inline-end: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pr-xl-on-xl {
    padding-inline-end: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pr-2xl-on-xl {
    padding-inline-end: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pr-3xl-on-xl {
    padding-inline-end: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pr-4xl-on-xl {
    padding-inline-end: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pb-auto-on-xl {
    padding-block-end: auto !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pb-0-on-xl {
    padding-block-end: 0 !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pb-xs-on-xl {
    padding-block-end: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pb-sm-on-xl {
    padding-block-end: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pb-md-on-xl {
    padding-block-end: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pb-lg-on-xl {
    padding-block-end: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pb-xl-on-xl {
    padding-block-end: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pb-2xl-on-xl {
    padding-block-end: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pb-3xl-on-xl {
    padding-block-end: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pb-4xl-on-xl {
    padding-block-end: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pl-auto-on-xl {
    padding-inline-start: auto !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pl-0-on-xl {
    padding-inline-start: 0 !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pl-xs-on-xl {
    padding-inline-start: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pl-sm-on-xl {
    padding-inline-start: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pl-md-on-xl {
    padding-inline-start: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pl-lg-on-xl {
    padding-inline-start: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pl-xl-on-xl {
    padding-inline-start: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pl-2xl-on-xl {
    padding-inline-start: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pl-3xl-on-xl {
    padding-inline-start: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-pl-4xl-on-xl {
    padding-inline-start: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-px-auto-on-xl {
    padding-inline: auto !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-px-0-on-xl {
    padding-inline: 0 !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-px-xs-on-xl {
    padding-inline: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-px-sm-on-xl {
    padding-inline: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-px-md-on-xl {
    padding-inline: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-px-lg-on-xl {
    padding-inline: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-px-xl-on-xl {
    padding-inline: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-px-2xl-on-xl {
    padding-inline: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-px-3xl-on-xl {
    padding-inline: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-px-4xl-on-xl {
    padding-inline: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-py-auto-on-xl {
    padding-block: auto !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-py-0-on-xl {
    padding-block: 0 !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-py-xs-on-xl {
    padding-block: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-py-sm-on-xl {
    padding-block: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-py-md-on-xl {
    padding-block: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-py-lg-on-xl {
    padding-block: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-py-xl-on-xl {
    padding-block: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-py-2xl-on-xl {
    padding-block: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-py-3xl-on-xl {
    padding-block: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-py-4xl-on-xl {
    padding-block: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-m-auto-on-2xl {
    margin: auto !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-m-0-on-2xl {
    margin: 0 !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-m-xs-on-2xl {
    margin: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-m-sm-on-2xl {
    margin: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-m-md-on-2xl {
    margin: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-m-lg-on-2xl {
    margin: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-m-xl-on-2xl {
    margin: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-m-2xl-on-2xl {
    margin: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-m-3xl-on-2xl {
    margin: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-m-4xl-on-2xl {
    margin: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mt-auto-on-2xl {
    margin-block-start: auto !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mt-0-on-2xl {
    margin-block-start: 0 !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mt-xs-on-2xl {
    margin-block-start: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mt-sm-on-2xl {
    margin-block-start: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mt-md-on-2xl {
    margin-block-start: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mt-lg-on-2xl {
    margin-block-start: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mt-xl-on-2xl {
    margin-block-start: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mt-2xl-on-2xl {
    margin-block-start: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mt-3xl-on-2xl {
    margin-block-start: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mt-4xl-on-2xl {
    margin-block-start: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mr-auto-on-2xl {
    margin-inline-end: auto !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mr-0-on-2xl {
    margin-inline-end: 0 !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mr-xs-on-2xl {
    margin-inline-end: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mr-sm-on-2xl {
    margin-inline-end: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mr-md-on-2xl {
    margin-inline-end: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mr-lg-on-2xl {
    margin-inline-end: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mr-xl-on-2xl {
    margin-inline-end: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mr-2xl-on-2xl {
    margin-inline-end: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mr-3xl-on-2xl {
    margin-inline-end: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mr-4xl-on-2xl {
    margin-inline-end: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mb-auto-on-2xl {
    margin-block-end: auto !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mb-0-on-2xl {
    margin-block-end: 0 !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mb-xs-on-2xl {
    margin-block-end: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mb-sm-on-2xl {
    margin-block-end: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mb-md-on-2xl {
    margin-block-end: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mb-lg-on-2xl {
    margin-block-end: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mb-xl-on-2xl {
    margin-block-end: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mb-2xl-on-2xl {
    margin-block-end: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mb-3xl-on-2xl {
    margin-block-end: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mb-4xl-on-2xl {
    margin-block-end: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-ml-auto-on-2xl {
    margin-inline-start: auto !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-ml-0-on-2xl {
    margin-inline-start: 0 !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-ml-xs-on-2xl {
    margin-inline-start: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-ml-sm-on-2xl {
    margin-inline-start: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-ml-md-on-2xl {
    margin-inline-start: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-ml-lg-on-2xl {
    margin-inline-start: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-ml-xl-on-2xl {
    margin-inline-start: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-ml-2xl-on-2xl {
    margin-inline-start: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-ml-3xl-on-2xl {
    margin-inline-start: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-ml-4xl-on-2xl {
    margin-inline-start: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mx-auto-on-2xl {
    margin-inline: auto !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mx-0-on-2xl {
    margin-inline: 0 !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mx-xs-on-2xl {
    margin-inline: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mx-sm-on-2xl {
    margin-inline: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mx-md-on-2xl {
    margin-inline: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mx-lg-on-2xl {
    margin-inline: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mx-xl-on-2xl {
    margin-inline: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mx-2xl-on-2xl {
    margin-inline: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mx-3xl-on-2xl {
    margin-inline: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-mx-4xl-on-2xl {
    margin-inline: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-my-auto-on-2xl {
    margin-block: auto !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-my-0-on-2xl {
    margin-block: 0 !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-my-xs-on-2xl {
    margin-block: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-my-sm-on-2xl {
    margin-block: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-my-md-on-2xl {
    margin-block: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-my-lg-on-2xl {
    margin-block: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-my-xl-on-2xl {
    margin-block: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-my-2xl-on-2xl {
    margin-block: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-my-3xl-on-2xl {
    margin-block: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-my-4xl-on-2xl {
    margin-block: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-p-auto-on-2xl {
    padding: auto !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-p-0-on-2xl {
    padding: 0 !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-p-xs-on-2xl {
    padding: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-p-sm-on-2xl {
    padding: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-p-md-on-2xl {
    padding: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-p-lg-on-2xl {
    padding: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-p-xl-on-2xl {
    padding: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-p-2xl-on-2xl {
    padding: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-p-3xl-on-2xl {
    padding: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-p-4xl-on-2xl {
    padding: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pt-auto-on-2xl {
    padding-block-start: auto !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pt-0-on-2xl {
    padding-block-start: 0 !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pt-xs-on-2xl {
    padding-block-start: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pt-sm-on-2xl {
    padding-block-start: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pt-md-on-2xl {
    padding-block-start: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pt-lg-on-2xl {
    padding-block-start: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pt-xl-on-2xl {
    padding-block-start: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pt-2xl-on-2xl {
    padding-block-start: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pt-3xl-on-2xl {
    padding-block-start: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pt-4xl-on-2xl {
    padding-block-start: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pr-auto-on-2xl {
    padding-inline-end: auto !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pr-0-on-2xl {
    padding-inline-end: 0 !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pr-xs-on-2xl {
    padding-inline-end: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pr-sm-on-2xl {
    padding-inline-end: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pr-md-on-2xl {
    padding-inline-end: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pr-lg-on-2xl {
    padding-inline-end: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pr-xl-on-2xl {
    padding-inline-end: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pr-2xl-on-2xl {
    padding-inline-end: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pr-3xl-on-2xl {
    padding-inline-end: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pr-4xl-on-2xl {
    padding-inline-end: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pb-auto-on-2xl {
    padding-block-end: auto !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pb-0-on-2xl {
    padding-block-end: 0 !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pb-xs-on-2xl {
    padding-block-end: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pb-sm-on-2xl {
    padding-block-end: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pb-md-on-2xl {
    padding-block-end: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pb-lg-on-2xl {
    padding-block-end: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pb-xl-on-2xl {
    padding-block-end: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pb-2xl-on-2xl {
    padding-block-end: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pb-3xl-on-2xl {
    padding-block-end: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pb-4xl-on-2xl {
    padding-block-end: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pl-auto-on-2xl {
    padding-inline-start: auto !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pl-0-on-2xl {
    padding-inline-start: 0 !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pl-xs-on-2xl {
    padding-inline-start: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pl-sm-on-2xl {
    padding-inline-start: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pl-md-on-2xl {
    padding-inline-start: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pl-lg-on-2xl {
    padding-inline-start: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pl-xl-on-2xl {
    padding-inline-start: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pl-2xl-on-2xl {
    padding-inline-start: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pl-3xl-on-2xl {
    padding-inline-start: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-pl-4xl-on-2xl {
    padding-inline-start: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-px-auto-on-2xl {
    padding-inline: auto !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-px-0-on-2xl {
    padding-inline: 0 !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-px-xs-on-2xl {
    padding-inline: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-px-sm-on-2xl {
    padding-inline: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-px-md-on-2xl {
    padding-inline: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-px-lg-on-2xl {
    padding-inline: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-px-xl-on-2xl {
    padding-inline: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-px-2xl-on-2xl {
    padding-inline: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-px-3xl-on-2xl {
    padding-inline: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-px-4xl-on-2xl {
    padding-inline: var(--pf-v5-global--spacer--4xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-py-auto-on-2xl {
    padding-block: auto !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-py-0-on-2xl {
    padding-block: 0 !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-py-xs-on-2xl {
    padding-block: var(--pf-v5-global--spacer--xs) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-py-sm-on-2xl {
    padding-block: var(--pf-v5-global--spacer--sm) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-py-md-on-2xl {
    padding-block: var(--pf-v5-global--spacer--md) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-py-lg-on-2xl {
    padding-block: var(--pf-v5-global--spacer--lg) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-py-xl-on-2xl {
    padding-block: var(--pf-v5-global--spacer--xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-py-2xl-on-2xl {
    padding-block: var(--pf-v5-global--spacer--2xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-py-3xl-on-2xl {
    padding-block: var(--pf-v5-global--spacer--3xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-py-4xl-on-2xl {
    padding-block: var(--pf-v5-global--spacer--4xl) !important;
  }
}

.pf-v5-u-font-family-text {
  font-family: var(--pf-v5-global--FontFamily--text) !important;
}

.pf-v5-u-font-family-heading {
  font-family: var(--pf-v5-global--FontFamily--heading) !important;
}

.pf-v5-u-font-family-monospace {
  font-family: var(--pf-v5-global--FontFamily--monospace) !important;
}

.pf-v5-u-font-family-text-vf {
  font-family: var(--pf-v5-global--FontFamily--text--vf) !important;
}

.pf-v5-u-font-family-heading-vf {
  font-family: var(--pf-v5-global--FontFamily--heading--vf) !important;
}

.pf-v5-u-font-family-monospace-vf {
  font-family: var(--pf-v5-global--FontFamily--monospace--vf) !important;
}

.pf-v5-u-font-size-xs {
  font-size: var(--pf-v5-global--FontSize--xs) !important;
}

.pf-v5-u-font-size-sm {
  font-size: var(--pf-v5-global--FontSize--sm) !important;
}

.pf-v5-u-font-size-md {
  font-size: var(--pf-v5-global--FontSize--md) !important;
}

.pf-v5-u-font-size-lg {
  font-size: var(--pf-v5-global--FontSize--lg) !important;
}

.pf-v5-u-font-size-xl {
  font-size: var(--pf-v5-global--FontSize--xl) !important;
}

.pf-v5-u-font-size-2xl {
  font-size: var(--pf-v5-global--FontSize--2xl) !important;
}

.pf-v5-u-font-size-3xl {
  font-size: var(--pf-v5-global--FontSize--3xl) !important;
}

.pf-v5-u-font-size-4xl {
  font-size: var(--pf-v5-global--FontSize--4xl) !important;
}

@media screen and (min-width: 576px) {
  .pf-v5-u-font-size-xs-on-sm {
    font-size: var(--pf-v5-global--FontSize--xs) !important;
  }

  .pf-v5-u-font-size-sm-on-sm {
    font-size: var(--pf-v5-global--FontSize--sm) !important;
  }

  .pf-v5-u-font-size-md-on-sm {
    font-size: var(--pf-v5-global--FontSize--md) !important;
  }

  .pf-v5-u-font-size-lg-on-sm {
    font-size: var(--pf-v5-global--FontSize--lg) !important;
  }

  .pf-v5-u-font-size-xl-on-sm {
    font-size: var(--pf-v5-global--FontSize--xl) !important;
  }

  .pf-v5-u-font-size-2xl-on-sm {
    font-size: var(--pf-v5-global--FontSize--2xl) !important;
  }

  .pf-v5-u-font-size-3xl-on-sm {
    font-size: var(--pf-v5-global--FontSize--3xl) !important;
  }

  .pf-v5-u-font-size-4xl-on-sm {
    font-size: var(--pf-v5-global--FontSize--4xl) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-font-size-xs-on-md {
    font-size: var(--pf-v5-global--FontSize--xs) !important;
  }

  .pf-v5-u-font-size-sm-on-md {
    font-size: var(--pf-v5-global--FontSize--sm) !important;
  }

  .pf-v5-u-font-size-md-on-md {
    font-size: var(--pf-v5-global--FontSize--md) !important;
  }

  .pf-v5-u-font-size-lg-on-md {
    font-size: var(--pf-v5-global--FontSize--lg) !important;
  }

  .pf-v5-u-font-size-xl-on-md {
    font-size: var(--pf-v5-global--FontSize--xl) !important;
  }

  .pf-v5-u-font-size-2xl-on-md {
    font-size: var(--pf-v5-global--FontSize--2xl) !important;
  }

  .pf-v5-u-font-size-3xl-on-md {
    font-size: var(--pf-v5-global--FontSize--3xl) !important;
  }

  .pf-v5-u-font-size-4xl-on-md {
    font-size: var(--pf-v5-global--FontSize--4xl) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-font-size-xs-on-lg {
    font-size: var(--pf-v5-global--FontSize--xs) !important;
  }

  .pf-v5-u-font-size-sm-on-lg {
    font-size: var(--pf-v5-global--FontSize--sm) !important;
  }

  .pf-v5-u-font-size-md-on-lg {
    font-size: var(--pf-v5-global--FontSize--md) !important;
  }

  .pf-v5-u-font-size-lg-on-lg {
    font-size: var(--pf-v5-global--FontSize--lg) !important;
  }

  .pf-v5-u-font-size-xl-on-lg {
    font-size: var(--pf-v5-global--FontSize--xl) !important;
  }

  .pf-v5-u-font-size-2xl-on-lg {
    font-size: var(--pf-v5-global--FontSize--2xl) !important;
  }

  .pf-v5-u-font-size-3xl-on-lg {
    font-size: var(--pf-v5-global--FontSize--3xl) !important;
  }

  .pf-v5-u-font-size-4xl-on-lg {
    font-size: var(--pf-v5-global--FontSize--4xl) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-font-size-xs-on-xl {
    font-size: var(--pf-v5-global--FontSize--xs) !important;
  }

  .pf-v5-u-font-size-sm-on-xl {
    font-size: var(--pf-v5-global--FontSize--sm) !important;
  }

  .pf-v5-u-font-size-md-on-xl {
    font-size: var(--pf-v5-global--FontSize--md) !important;
  }

  .pf-v5-u-font-size-lg-on-xl {
    font-size: var(--pf-v5-global--FontSize--lg) !important;
  }

  .pf-v5-u-font-size-xl-on-xl {
    font-size: var(--pf-v5-global--FontSize--xl) !important;
  }

  .pf-v5-u-font-size-2xl-on-xl {
    font-size: var(--pf-v5-global--FontSize--2xl) !important;
  }

  .pf-v5-u-font-size-3xl-on-xl {
    font-size: var(--pf-v5-global--FontSize--3xl) !important;
  }

  .pf-v5-u-font-size-4xl-on-xl {
    font-size: var(--pf-v5-global--FontSize--4xl) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-font-size-xs-on-2xl {
    font-size: var(--pf-v5-global--FontSize--xs) !important;
  }

  .pf-v5-u-font-size-sm-on-2xl {
    font-size: var(--pf-v5-global--FontSize--sm) !important;
  }

  .pf-v5-u-font-size-md-on-2xl {
    font-size: var(--pf-v5-global--FontSize--md) !important;
  }

  .pf-v5-u-font-size-lg-on-2xl {
    font-size: var(--pf-v5-global--FontSize--lg) !important;
  }

  .pf-v5-u-font-size-xl-on-2xl {
    font-size: var(--pf-v5-global--FontSize--xl) !important;
  }

  .pf-v5-u-font-size-2xl-on-2xl {
    font-size: var(--pf-v5-global--FontSize--2xl) !important;
  }

  .pf-v5-u-font-size-3xl-on-2xl {
    font-size: var(--pf-v5-global--FontSize--3xl) !important;
  }

  .pf-v5-u-font-size-4xl-on-2xl {
    font-size: var(--pf-v5-global--FontSize--4xl) !important;
  }
}

.pf-v5-u-font-weight-normal {
  font-weight: var(--pf-v5-global--FontWeight--normal) !important;
}

.pf-v5-u-font-weight-bold {
  font-weight: var(--pf-v5-global--FontWeight--bold) !important;
}

@media screen and (min-width: 576px) {
  .pf-v5-u-font-weight-normal-on-sm {
    font-weight: var(--pf-v5-global--FontWeight--normal) !important;
  }

  .pf-v5-u-font-weight-bold-on-sm {
    font-weight: var(--pf-v5-global--FontWeight--bold) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-font-weight-normal-on-md {
    font-weight: var(--pf-v5-global--FontWeight--normal) !important;
  }

  .pf-v5-u-font-weight-bold-on-md {
    font-weight: var(--pf-v5-global--FontWeight--bold) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-font-weight-normal-on-lg {
    font-weight: var(--pf-v5-global--FontWeight--normal) !important;
  }

  .pf-v5-u-font-weight-bold-on-lg {
    font-weight: var(--pf-v5-global--FontWeight--bold) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-font-weight-normal-on-xl {
    font-weight: var(--pf-v5-global--FontWeight--normal) !important;
  }

  .pf-v5-u-font-weight-bold-on-xl {
    font-weight: var(--pf-v5-global--FontWeight--bold) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-font-weight-normal-on-2xl {
    font-weight: var(--pf-v5-global--FontWeight--normal) !important;
  }

  .pf-v5-u-font-weight-bold-on-2xl {
    font-weight: var(--pf-v5-global--FontWeight--bold) !important;
  }
}

.pf-v5-u-color-100 {
  color: var(--pf-v5-global--Color--100) !important;
}

.pf-v5-u-color-200 {
  color: var(--pf-v5-global--Color--200) !important;
}

.pf-v5-u-color-300 {
  color: var(--pf-v5-global--Color--300) !important;
}

.pf-v5-u-color-400 {
  color: var(--pf-v5-global--Color--400) !important;
}

.pf-v5-u-active-color-100 {
  color: var(--pf-v5-global--active-color--100) !important;
}

.pf-v5-u-active-color-400 {
  color: var(--pf-v5-global--active-color--400) !important;
}

.pf-v5-u-primary-color-100 {
  color: var(--pf-v5-global--primary-color--100) !important;
}

.pf-v5-u-color-light-100 {
  color: var(--pf-v5-global--Color--light-100) !important;
}

.pf-v5-u-color-light-200 {
  color: var(--pf-v5-global--Color--light-200) !important;
}

.pf-v5-u-link-color {
  color: var(--pf-v5-global--link--Color) !important;
}

.pf-v5-u-link-color-hover {
  color: var(--pf-v5-global--link--Color--hover) !important;
}

.pf-v5-u-link-color-light {
  color: var(--pf-v5-global--link--Color--light) !important;
}

.pf-v5-u-link-color-light-hover {
  color: var(--pf-v5-global--link--Color--light--hover) !important;
}

.pf-v5-u-link-color-dark {
  color: var(--pf-v5-global--link--Color--dark) !important;
}

.pf-v5-u-link-color-dark-hover {
  color: var(--pf-v5-global--link--Color--dark--hover) !important;
}

.pf-v5-u-link-color-visited {
  color: var(--pf-v5-global--link--Color--visited) !important;
}

.pf-v5-u-custom-color-100 {
  color: var(--pf-v5-global--custom-color--100) !important;
}

.pf-v5-u-custom-color-200 {
  color: var(--pf-v5-global--custom-color--200) !important;
}

.pf-v5-u-custom-color-300 {
  color: var(--pf-v5-global--custom-color--300) !important;
}

.pf-v5-u-success-color-100 {
  color: var(--pf-v5-global--success-color--100) !important;
}

.pf-v5-u-success-color-200 {
  color: var(--pf-v5-global--success-color--200) !important;
}

.pf-v5-u-info-color-100 {
  color: var(--pf-v5-global--info-color--100) !important;
}

.pf-v5-u-info-color-200 {
  color: var(--pf-v5-global--info-color--200) !important;
}

.pf-v5-u-warning-color-100 {
  color: var(--pf-v5-global--warning-color--100) !important;
}

.pf-v5-u-warning-color-200 {
  color: var(--pf-v5-global--warning-color--200) !important;
}

.pf-v5-u-danger-color-100 {
  color: var(--pf-v5-global--danger-color--100) !important;
}

.pf-v5-u-danger-color-200 {
  color: var(--pf-v5-global--danger-color--200) !important;
}

.pf-v5-u-danger-color-300 {
  color: var(--pf-v5-global--danger-color--300) !important;
}

.pf-v5-u-disabled-color-100 {
  color: var(--pf-v5-global--disabled-color--100) !important;
}

.pf-v5-u-disabled-color-200 {
  color: var(--pf-v5-global--disabled-color--200) !important;
}

.pf-v5-u-icon-color-light {
  color: var(--pf-v5-global--icon--Color--light) !important;
}

.pf-v5-u-icon-color-dark {
  color: var(--pf-v5-global--icon--Color--dark) !important;
}

@media screen and (min-width: 576px) {
  .pf-v5-u-color-100-on-sm {
    color: var(--pf-v5-global--Color--100) !important;
  }

  .pf-v5-u-color-200-on-sm {
    color: var(--pf-v5-global--Color--200) !important;
  }

  .pf-v5-u-color-300-on-sm {
    color: var(--pf-v5-global--Color--300) !important;
  }

  .pf-v5-u-color-400-on-sm {
    color: var(--pf-v5-global--Color--400) !important;
  }

  .pf-v5-u-active-color-100-on-sm {
    color: var(--pf-v5-global--active-color--100) !important;
  }

  .pf-v5-u-active-color-400-on-sm {
    color: var(--pf-v5-global--active-color--400) !important;
  }

  .pf-v5-u-primary-color-100-on-sm {
    color: var(--pf-v5-global--primary-color--100) !important;
  }

  .pf-v5-u-color-light-100-on-sm {
    color: var(--pf-v5-global--Color--light-100) !important;
  }

  .pf-v5-u-color-light-200-on-sm {
    color: var(--pf-v5-global--Color--light-200) !important;
  }

  .pf-v5-u-link-color-on-sm {
    color: var(--pf-v5-global--link--Color) !important;
  }

  .pf-v5-u-link-color-hover-on-sm {
    color: var(--pf-v5-global--link--Color--hover) !important;
  }

  .pf-v5-u-link-color-light-on-sm {
    color: var(--pf-v5-global--link--Color--light) !important;
  }

  .pf-v5-u-link-color-light-hover-on-sm {
    color: var(--pf-v5-global--link--Color--light--hover) !important;
  }

  .pf-v5-u-link-color-dark-on-sm {
    color: var(--pf-v5-global--link--Color--dark) !important;
  }

  .pf-v5-u-link-color-dark-hover-on-sm {
    color: var(--pf-v5-global--link--Color--dark--hover) !important;
  }

  .pf-v5-u-link-color-visited-on-sm {
    color: var(--pf-v5-global--link--Color--visited) !important;
  }

  .pf-v5-u-custom-color-100-on-sm {
    color: var(--pf-v5-global--custom-color--100) !important;
  }

  .pf-v5-u-custom-color-200-on-sm {
    color: var(--pf-v5-global--custom-color--200) !important;
  }

  .pf-v5-u-custom-color-300-on-sm {
    color: var(--pf-v5-global--custom-color--300) !important;
  }

  .pf-v5-u-success-color-100-on-sm {
    color: var(--pf-v5-global--success-color--100) !important;
  }

  .pf-v5-u-success-color-200-on-sm {
    color: var(--pf-v5-global--success-color--200) !important;
  }

  .pf-v5-u-info-color-100-on-sm {
    color: var(--pf-v5-global--info-color--100) !important;
  }

  .pf-v5-u-info-color-200-on-sm {
    color: var(--pf-v5-global--info-color--200) !important;
  }

  .pf-v5-u-warning-color-100-on-sm {
    color: var(--pf-v5-global--warning-color--100) !important;
  }

  .pf-v5-u-warning-color-200-on-sm {
    color: var(--pf-v5-global--warning-color--200) !important;
  }

  .pf-v5-u-danger-color-100-on-sm {
    color: var(--pf-v5-global--danger-color--100) !important;
  }

  .pf-v5-u-danger-color-200-on-sm {
    color: var(--pf-v5-global--danger-color--200) !important;
  }

  .pf-v5-u-danger-color-300-on-sm {
    color: var(--pf-v5-global--danger-color--300) !important;
  }

  .pf-v5-u-disabled-color-100-on-sm {
    color: var(--pf-v5-global--disabled-color--100) !important;
  }

  .pf-v5-u-disabled-color-200-on-sm {
    color: var(--pf-v5-global--disabled-color--200) !important;
  }

  .pf-v5-u-icon-color-light-on-sm {
    color: var(--pf-v5-global--icon--Color--light) !important;
  }

  .pf-v5-u-icon-color-dark-on-sm {
    color: var(--pf-v5-global--icon--Color--dark) !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-color-100-on-md {
    color: var(--pf-v5-global--Color--100) !important;
  }

  .pf-v5-u-color-200-on-md {
    color: var(--pf-v5-global--Color--200) !important;
  }

  .pf-v5-u-color-300-on-md {
    color: var(--pf-v5-global--Color--300) !important;
  }

  .pf-v5-u-color-400-on-md {
    color: var(--pf-v5-global--Color--400) !important;
  }

  .pf-v5-u-active-color-100-on-md {
    color: var(--pf-v5-global--active-color--100) !important;
  }

  .pf-v5-u-active-color-400-on-md {
    color: var(--pf-v5-global--active-color--400) !important;
  }

  .pf-v5-u-primary-color-100-on-md {
    color: var(--pf-v5-global--primary-color--100) !important;
  }

  .pf-v5-u-color-light-100-on-md {
    color: var(--pf-v5-global--Color--light-100) !important;
  }

  .pf-v5-u-color-light-200-on-md {
    color: var(--pf-v5-global--Color--light-200) !important;
  }

  .pf-v5-u-link-color-on-md {
    color: var(--pf-v5-global--link--Color) !important;
  }

  .pf-v5-u-link-color-hover-on-md {
    color: var(--pf-v5-global--link--Color--hover) !important;
  }

  .pf-v5-u-link-color-light-on-md {
    color: var(--pf-v5-global--link--Color--light) !important;
  }

  .pf-v5-u-link-color-light-hover-on-md {
    color: var(--pf-v5-global--link--Color--light--hover) !important;
  }

  .pf-v5-u-link-color-dark-on-md {
    color: var(--pf-v5-global--link--Color--dark) !important;
  }

  .pf-v5-u-link-color-dark-hover-on-md {
    color: var(--pf-v5-global--link--Color--dark--hover) !important;
  }

  .pf-v5-u-link-color-visited-on-md {
    color: var(--pf-v5-global--link--Color--visited) !important;
  }

  .pf-v5-u-custom-color-100-on-md {
    color: var(--pf-v5-global--custom-color--100) !important;
  }

  .pf-v5-u-custom-color-200-on-md {
    color: var(--pf-v5-global--custom-color--200) !important;
  }

  .pf-v5-u-custom-color-300-on-md {
    color: var(--pf-v5-global--custom-color--300) !important;
  }

  .pf-v5-u-success-color-100-on-md {
    color: var(--pf-v5-global--success-color--100) !important;
  }

  .pf-v5-u-success-color-200-on-md {
    color: var(--pf-v5-global--success-color--200) !important;
  }

  .pf-v5-u-info-color-100-on-md {
    color: var(--pf-v5-global--info-color--100) !important;
  }

  .pf-v5-u-info-color-200-on-md {
    color: var(--pf-v5-global--info-color--200) !important;
  }

  .pf-v5-u-warning-color-100-on-md {
    color: var(--pf-v5-global--warning-color--100) !important;
  }

  .pf-v5-u-warning-color-200-on-md {
    color: var(--pf-v5-global--warning-color--200) !important;
  }

  .pf-v5-u-danger-color-100-on-md {
    color: var(--pf-v5-global--danger-color--100) !important;
  }

  .pf-v5-u-danger-color-200-on-md {
    color: var(--pf-v5-global--danger-color--200) !important;
  }

  .pf-v5-u-danger-color-300-on-md {
    color: var(--pf-v5-global--danger-color--300) !important;
  }

  .pf-v5-u-disabled-color-100-on-md {
    color: var(--pf-v5-global--disabled-color--100) !important;
  }

  .pf-v5-u-disabled-color-200-on-md {
    color: var(--pf-v5-global--disabled-color--200) !important;
  }

  .pf-v5-u-icon-color-light-on-md {
    color: var(--pf-v5-global--icon--Color--light) !important;
  }

  .pf-v5-u-icon-color-dark-on-md {
    color: var(--pf-v5-global--icon--Color--dark) !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-color-100-on-lg {
    color: var(--pf-v5-global--Color--100) !important;
  }

  .pf-v5-u-color-200-on-lg {
    color: var(--pf-v5-global--Color--200) !important;
  }

  .pf-v5-u-color-300-on-lg {
    color: var(--pf-v5-global--Color--300) !important;
  }

  .pf-v5-u-color-400-on-lg {
    color: var(--pf-v5-global--Color--400) !important;
  }

  .pf-v5-u-active-color-100-on-lg {
    color: var(--pf-v5-global--active-color--100) !important;
  }

  .pf-v5-u-active-color-400-on-lg {
    color: var(--pf-v5-global--active-color--400) !important;
  }

  .pf-v5-u-primary-color-100-on-lg {
    color: var(--pf-v5-global--primary-color--100) !important;
  }

  .pf-v5-u-color-light-100-on-lg {
    color: var(--pf-v5-global--Color--light-100) !important;
  }

  .pf-v5-u-color-light-200-on-lg {
    color: var(--pf-v5-global--Color--light-200) !important;
  }

  .pf-v5-u-link-color-on-lg {
    color: var(--pf-v5-global--link--Color) !important;
  }

  .pf-v5-u-link-color-hover-on-lg {
    color: var(--pf-v5-global--link--Color--hover) !important;
  }

  .pf-v5-u-link-color-light-on-lg {
    color: var(--pf-v5-global--link--Color--light) !important;
  }

  .pf-v5-u-link-color-light-hover-on-lg {
    color: var(--pf-v5-global--link--Color--light--hover) !important;
  }

  .pf-v5-u-link-color-dark-on-lg {
    color: var(--pf-v5-global--link--Color--dark) !important;
  }

  .pf-v5-u-link-color-dark-hover-on-lg {
    color: var(--pf-v5-global--link--Color--dark--hover) !important;
  }

  .pf-v5-u-link-color-visited-on-lg {
    color: var(--pf-v5-global--link--Color--visited) !important;
  }

  .pf-v5-u-custom-color-100-on-lg {
    color: var(--pf-v5-global--custom-color--100) !important;
  }

  .pf-v5-u-custom-color-200-on-lg {
    color: var(--pf-v5-global--custom-color--200) !important;
  }

  .pf-v5-u-custom-color-300-on-lg {
    color: var(--pf-v5-global--custom-color--300) !important;
  }

  .pf-v5-u-success-color-100-on-lg {
    color: var(--pf-v5-global--success-color--100) !important;
  }

  .pf-v5-u-success-color-200-on-lg {
    color: var(--pf-v5-global--success-color--200) !important;
  }

  .pf-v5-u-info-color-100-on-lg {
    color: var(--pf-v5-global--info-color--100) !important;
  }

  .pf-v5-u-info-color-200-on-lg {
    color: var(--pf-v5-global--info-color--200) !important;
  }

  .pf-v5-u-warning-color-100-on-lg {
    color: var(--pf-v5-global--warning-color--100) !important;
  }

  .pf-v5-u-warning-color-200-on-lg {
    color: var(--pf-v5-global--warning-color--200) !important;
  }

  .pf-v5-u-danger-color-100-on-lg {
    color: var(--pf-v5-global--danger-color--100) !important;
  }

  .pf-v5-u-danger-color-200-on-lg {
    color: var(--pf-v5-global--danger-color--200) !important;
  }

  .pf-v5-u-danger-color-300-on-lg {
    color: var(--pf-v5-global--danger-color--300) !important;
  }

  .pf-v5-u-disabled-color-100-on-lg {
    color: var(--pf-v5-global--disabled-color--100) !important;
  }

  .pf-v5-u-disabled-color-200-on-lg {
    color: var(--pf-v5-global--disabled-color--200) !important;
  }

  .pf-v5-u-icon-color-light-on-lg {
    color: var(--pf-v5-global--icon--Color--light) !important;
  }

  .pf-v5-u-icon-color-dark-on-lg {
    color: var(--pf-v5-global--icon--Color--dark) !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-color-100-on-xl {
    color: var(--pf-v5-global--Color--100) !important;
  }

  .pf-v5-u-color-200-on-xl {
    color: var(--pf-v5-global--Color--200) !important;
  }

  .pf-v5-u-color-300-on-xl {
    color: var(--pf-v5-global--Color--300) !important;
  }

  .pf-v5-u-color-400-on-xl {
    color: var(--pf-v5-global--Color--400) !important;
  }

  .pf-v5-u-active-color-100-on-xl {
    color: var(--pf-v5-global--active-color--100) !important;
  }

  .pf-v5-u-active-color-400-on-xl {
    color: var(--pf-v5-global--active-color--400) !important;
  }

  .pf-v5-u-primary-color-100-on-xl {
    color: var(--pf-v5-global--primary-color--100) !important;
  }

  .pf-v5-u-color-light-100-on-xl {
    color: var(--pf-v5-global--Color--light-100) !important;
  }

  .pf-v5-u-color-light-200-on-xl {
    color: var(--pf-v5-global--Color--light-200) !important;
  }

  .pf-v5-u-link-color-on-xl {
    color: var(--pf-v5-global--link--Color) !important;
  }

  .pf-v5-u-link-color-hover-on-xl {
    color: var(--pf-v5-global--link--Color--hover) !important;
  }

  .pf-v5-u-link-color-light-on-xl {
    color: var(--pf-v5-global--link--Color--light) !important;
  }

  .pf-v5-u-link-color-light-hover-on-xl {
    color: var(--pf-v5-global--link--Color--light--hover) !important;
  }

  .pf-v5-u-link-color-dark-on-xl {
    color: var(--pf-v5-global--link--Color--dark) !important;
  }

  .pf-v5-u-link-color-dark-hover-on-xl {
    color: var(--pf-v5-global--link--Color--dark--hover) !important;
  }

  .pf-v5-u-link-color-visited-on-xl {
    color: var(--pf-v5-global--link--Color--visited) !important;
  }

  .pf-v5-u-custom-color-100-on-xl {
    color: var(--pf-v5-global--custom-color--100) !important;
  }

  .pf-v5-u-custom-color-200-on-xl {
    color: var(--pf-v5-global--custom-color--200) !important;
  }

  .pf-v5-u-custom-color-300-on-xl {
    color: var(--pf-v5-global--custom-color--300) !important;
  }

  .pf-v5-u-success-color-100-on-xl {
    color: var(--pf-v5-global--success-color--100) !important;
  }

  .pf-v5-u-success-color-200-on-xl {
    color: var(--pf-v5-global--success-color--200) !important;
  }

  .pf-v5-u-info-color-100-on-xl {
    color: var(--pf-v5-global--info-color--100) !important;
  }

  .pf-v5-u-info-color-200-on-xl {
    color: var(--pf-v5-global--info-color--200) !important;
  }

  .pf-v5-u-warning-color-100-on-xl {
    color: var(--pf-v5-global--warning-color--100) !important;
  }

  .pf-v5-u-warning-color-200-on-xl {
    color: var(--pf-v5-global--warning-color--200) !important;
  }

  .pf-v5-u-danger-color-100-on-xl {
    color: var(--pf-v5-global--danger-color--100) !important;
  }

  .pf-v5-u-danger-color-200-on-xl {
    color: var(--pf-v5-global--danger-color--200) !important;
  }

  .pf-v5-u-danger-color-300-on-xl {
    color: var(--pf-v5-global--danger-color--300) !important;
  }

  .pf-v5-u-disabled-color-100-on-xl {
    color: var(--pf-v5-global--disabled-color--100) !important;
  }

  .pf-v5-u-disabled-color-200-on-xl {
    color: var(--pf-v5-global--disabled-color--200) !important;
  }

  .pf-v5-u-icon-color-light-on-xl {
    color: var(--pf-v5-global--icon--Color--light) !important;
  }

  .pf-v5-u-icon-color-dark-on-xl {
    color: var(--pf-v5-global--icon--Color--dark) !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-color-100-on-2xl {
    color: var(--pf-v5-global--Color--100) !important;
  }

  .pf-v5-u-color-200-on-2xl {
    color: var(--pf-v5-global--Color--200) !important;
  }

  .pf-v5-u-color-300-on-2xl {
    color: var(--pf-v5-global--Color--300) !important;
  }

  .pf-v5-u-color-400-on-2xl {
    color: var(--pf-v5-global--Color--400) !important;
  }

  .pf-v5-u-active-color-100-on-2xl {
    color: var(--pf-v5-global--active-color--100) !important;
  }

  .pf-v5-u-active-color-400-on-2xl {
    color: var(--pf-v5-global--active-color--400) !important;
  }

  .pf-v5-u-primary-color-100-on-2xl {
    color: var(--pf-v5-global--primary-color--100) !important;
  }

  .pf-v5-u-color-light-100-on-2xl {
    color: var(--pf-v5-global--Color--light-100) !important;
  }

  .pf-v5-u-color-light-200-on-2xl {
    color: var(--pf-v5-global--Color--light-200) !important;
  }

  .pf-v5-u-link-color-on-2xl {
    color: var(--pf-v5-global--link--Color) !important;
  }

  .pf-v5-u-link-color-hover-on-2xl {
    color: var(--pf-v5-global--link--Color--hover) !important;
  }

  .pf-v5-u-link-color-light-on-2xl {
    color: var(--pf-v5-global--link--Color--light) !important;
  }

  .pf-v5-u-link-color-light-hover-on-2xl {
    color: var(--pf-v5-global--link--Color--light--hover) !important;
  }

  .pf-v5-u-link-color-dark-on-2xl {
    color: var(--pf-v5-global--link--Color--dark) !important;
  }

  .pf-v5-u-link-color-dark-hover-on-2xl {
    color: var(--pf-v5-global--link--Color--dark--hover) !important;
  }

  .pf-v5-u-link-color-visited-on-2xl {
    color: var(--pf-v5-global--link--Color--visited) !important;
  }

  .pf-v5-u-custom-color-100-on-2xl {
    color: var(--pf-v5-global--custom-color--100) !important;
  }

  .pf-v5-u-custom-color-200-on-2xl {
    color: var(--pf-v5-global--custom-color--200) !important;
  }

  .pf-v5-u-custom-color-300-on-2xl {
    color: var(--pf-v5-global--custom-color--300) !important;
  }

  .pf-v5-u-success-color-100-on-2xl {
    color: var(--pf-v5-global--success-color--100) !important;
  }

  .pf-v5-u-success-color-200-on-2xl {
    color: var(--pf-v5-global--success-color--200) !important;
  }

  .pf-v5-u-info-color-100-on-2xl {
    color: var(--pf-v5-global--info-color--100) !important;
  }

  .pf-v5-u-info-color-200-on-2xl {
    color: var(--pf-v5-global--info-color--200) !important;
  }

  .pf-v5-u-warning-color-100-on-2xl {
    color: var(--pf-v5-global--warning-color--100) !important;
  }

  .pf-v5-u-warning-color-200-on-2xl {
    color: var(--pf-v5-global--warning-color--200) !important;
  }

  .pf-v5-u-danger-color-100-on-2xl {
    color: var(--pf-v5-global--danger-color--100) !important;
  }

  .pf-v5-u-danger-color-200-on-2xl {
    color: var(--pf-v5-global--danger-color--200) !important;
  }

  .pf-v5-u-danger-color-300-on-2xl {
    color: var(--pf-v5-global--danger-color--300) !important;
  }

  .pf-v5-u-disabled-color-100-on-2xl {
    color: var(--pf-v5-global--disabled-color--100) !important;
  }

  .pf-v5-u-disabled-color-200-on-2xl {
    color: var(--pf-v5-global--disabled-color--200) !important;
  }

  .pf-v5-u-icon-color-light-on-2xl {
    color: var(--pf-v5-global--icon--Color--light) !important;
  }

  .pf-v5-u-icon-color-dark-on-2xl {
    color: var(--pf-v5-global--icon--Color--dark) !important;
  }
}

.pf-v5-u-text-break-word {
  word-break: break-word !important;
}

.pf-v5-u-text-nowrap {
  white-space: nowrap !important;
}

.pf-v5-u-text-wrap {
  white-space: normal !important;
}

@media screen and (min-width: 576px) {
  .pf-v5-u-text-break-word-on-sm {
    word-break: break-word !important;
  }

  .pf-v5-u-text-nowrap-on-sm {
    white-space: nowrap !important;
  }

  .pf-v5-u-text-wrap-on-sm {
    white-space: normal !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-text-break-word-on-md {
    word-break: break-word !important;
  }

  .pf-v5-u-text-nowrap-on-md {
    white-space: nowrap !important;
  }

  .pf-v5-u-text-wrap-on-md {
    white-space: normal !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-text-break-word-on-lg {
    word-break: break-word !important;
  }

  .pf-v5-u-text-nowrap-on-lg {
    white-space: nowrap !important;
  }

  .pf-v5-u-text-wrap-on-lg {
    white-space: normal !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-text-break-word-on-xl {
    word-break: break-word !important;
  }

  .pf-v5-u-text-nowrap-on-xl {
    white-space: nowrap !important;
  }

  .pf-v5-u-text-wrap-on-xl {
    white-space: normal !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-text-break-word-on-2xl {
    word-break: break-word !important;
  }

  .pf-v5-u-text-nowrap-on-2xl {
    white-space: nowrap !important;
  }

  .pf-v5-u-text-wrap-on-2xl {
    white-space: normal !important;
  }
}

.pf-v5-u-text-truncate {
  min-width: 0 !important;
  max-width: 100% !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

:where(.pf-v5-theme-dark) .pf-v5-c-about-modal-box .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-banner .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-log-viewer.pf-m-dark .pf-v5-c-log-viewer__main .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-login__footer .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar-body.pf-m-menu .pf-v5-c-context-selector .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__main-section[class*=pf-m-dark-] .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-page__header .pf-v5-c-button,
:where(.pf-v5-theme-dark) .pf-v5-c-wizard__header .pf-v5-c-button {
  --pf-v5-c-button--m-primary--BackgroundColor: var(--pf-v5-global--primary-color--300);
}

:where(.pf-v5-theme-dark) .pf-v5-u-color-300 {
  color: var(--pf-v5-global--Color--100) !important;
}

:where(.pf-v5-theme-dark) .pf-v5-u-color-light-100 {
  color: var(--pf-v5-global--Color-100) !important;
}

:where(.pf-v5-theme-dark) .pf-v5-u-color-light-200 {
  color: var(--pf-v5-global--Color-200) !important;
}

:where(.pf-v5-theme-dark) .pf-v5-u-custom-color-300 {
  color: var(--pf-v5-global--custom-color--100) !important;
}

:where(.pf-v5-theme-dark) .pf-v5-u-success-color-200 {
  color: var(--pf-v5-global--success-color--100) !important;
}

:where(.pf-v5-theme-dark) .pf-v5-u-info-color-200 {
  color: var(--pf-v5-global--info-color--100) !important;
}

:where(.pf-v5-theme-dark) .pf-v5-u-danger-color-300 {
  color: var(--pf-v5-global--danger-color--100) !important;
}

:where(.pf-v5-theme-dark) .pf-v5-u-icon-color-dark {
  color: var(--pf-v5-global--icon--Color--dark) !important;
}

@media screen and (min-width: 576px) {
  :where(.pf-v5-theme-dark) .pf-v5-u-color-300-on-sm {
    color: var(--pf-v5-global--Color--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-color-light-100-on-sm {
    color: var(--pf-v5-global--Color-100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-color-light-200-on-sm {
    color: var(--pf-v5-global--Color-200) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-custom-color-300-on-sm {
    color: var(--pf-v5-global--custom-color--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-success-color-200-on-sm {
    color: var(--pf-v5-global--success-color--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-info-color-200-on-sm {
    color: var(--pf-v5-global--info-color--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-danger-color-300-on-sm {
    color: var(--pf-v5-global--danger-color--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-icon-color-dark-on-sm {
    color: var(--pf-v5-global--icon--Color--dark) !important;
  }
}

@media screen and (min-width: 768px) {
  :where(.pf-v5-theme-dark) .pf-v5-u-color-300-on-md {
    color: var(--pf-v5-global--Color--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-color-light-100-on-md {
    color: var(--pf-v5-global--Color-100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-color-light-200-on-md {
    color: var(--pf-v5-global--Color-200) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-custom-color-300-on-md {
    color: var(--pf-v5-global--custom-color--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-success-color-200-on-md {
    color: var(--pf-v5-global--success-color--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-info-color-200-on-md {
    color: var(--pf-v5-global--info-color--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-danger-color-300-on-md {
    color: var(--pf-v5-global--danger-color--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-icon-color-dark-on-md {
    color: var(--pf-v5-global--icon--Color--dark) !important;
  }
}

@media screen and (min-width: 992px) {
  :where(.pf-v5-theme-dark) .pf-v5-u-color-300-on-lg {
    color: var(--pf-v5-global--Color--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-color-light-100-on-lg {
    color: var(--pf-v5-global--Color-100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-color-light-200-on-lg {
    color: var(--pf-v5-global--Color-200) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-custom-color-300-on-lg {
    color: var(--pf-v5-global--custom-color--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-success-color-200-on-lg {
    color: var(--pf-v5-global--success-color--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-info-color-200-on-lg {
    color: var(--pf-v5-global--info-color--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-danger-color-300-on-lg {
    color: var(--pf-v5-global--danger-color--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-icon-color-dark-on-lg {
    color: var(--pf-v5-global--icon--Color--dark) !important;
  }
}

@media screen and (min-width: 1200px) {
  :where(.pf-v5-theme-dark) .pf-v5-u-color-300-on-xl {
    color: var(--pf-v5-global--Color--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-color-light-100-on-xl {
    color: var(--pf-v5-global--Color-100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-color-light-200-on-xl {
    color: var(--pf-v5-global--Color-200) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-custom-color-300-on-xl {
    color: var(--pf-v5-global--custom-color--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-success-color-200-on-xl {
    color: var(--pf-v5-global--success-color--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-info-color-200-on-xl {
    color: var(--pf-v5-global--info-color--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-danger-color-300-on-xl {
    color: var(--pf-v5-global--danger-color--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-icon-color-dark-on-xl {
    color: var(--pf-v5-global--icon--Color--dark) !important;
  }
}

@media screen and (min-width: 1450px) {
  :where(.pf-v5-theme-dark) .pf-v5-u-color-300-on-2xl {
    color: var(--pf-v5-global--Color--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-color-light-100-on-2xl {
    color: var(--pf-v5-global--Color-100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-color-light-200-on-2xl {
    color: var(--pf-v5-global--Color-200) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-custom-color-300-on-2xl {
    color: var(--pf-v5-global--custom-color--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-success-color-200-on-2xl {
    color: var(--pf-v5-global--success-color--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-info-color-200-on-2xl {
    color: var(--pf-v5-global--info-color--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-danger-color-300-on-2xl {
    color: var(--pf-v5-global--danger-color--100) !important;
  }

  :where(.pf-v5-theme-dark) .pf-v5-u-icon-color-dark-on-2xl {
    color: var(--pf-v5-global--icon--Color--dark) !important;
  }
}

.pf-v5-u-w-8 {
  width: 8.3333333333% !important;
}

.pf-v5-u-w-10 {
  width: 10% !important;
}

.pf-v5-u-w-16 {
  width: 16.6666666667% !important;
}

.pf-v5-u-w-42 {
  width: 41.6666666667% !important;
}

.pf-v5-u-w-58 {
  width: 58.3333333333% !important;
}

.pf-v5-u-w-83 {
  width: 83.3333333333% !important;
}

.pf-v5-u-w-92 {
  width: 91.6666666667% !important;
}

@media screen and (min-width: 576px) {
  .pf-v5-u-w-8-on-sm {
    width: 8.3333333333% !important;
  }

  .pf-v5-u-w-10-on-sm {
    width: 10% !important;
  }

  .pf-v5-u-w-16-on-sm {
    width: 16.6666666667% !important;
  }

  .pf-v5-u-w-42-on-sm {
    width: 41.6666666667% !important;
  }

  .pf-v5-u-w-58-on-sm {
    width: 58.3333333333% !important;
  }

  .pf-v5-u-w-83-on-sm {
    width: 83.3333333333% !important;
  }

  .pf-v5-u-w-92-on-sm {
    width: 91.6666666667% !important;
  }
}

@media screen and (min-width: 768px) {
  .pf-v5-u-w-8-on-md {
    width: 8.3333333333% !important;
  }

  .pf-v5-u-w-10-on-md {
    width: 10% !important;
  }

  .pf-v5-u-w-16-on-md {
    width: 16.6666666667% !important;
  }

  .pf-v5-u-w-42-on-md {
    width: 41.6666666667% !important;
  }

  .pf-v5-u-w-58-on-md {
    width: 58.3333333333% !important;
  }

  .pf-v5-u-w-83-on-md {
    width: 83.3333333333% !important;
  }

  .pf-v5-u-w-92-on-md {
    width: 91.6666666667% !important;
  }
}

@media screen and (min-width: 992px) {
  .pf-v5-u-w-8-on-lg {
    width: 8.3333333333% !important;
  }

  .pf-v5-u-w-10-on-lg {
    width: 10% !important;
  }

  .pf-v5-u-w-16-on-lg {
    width: 16.6666666667% !important;
  }

  .pf-v5-u-w-42-on-lg {
    width: 41.6666666667% !important;
  }

  .pf-v5-u-w-58-on-lg {
    width: 58.3333333333% !important;
  }

  .pf-v5-u-w-83-on-lg {
    width: 83.3333333333% !important;
  }

  .pf-v5-u-w-92-on-lg {
    width: 91.6666666667% !important;
  }
}

@media screen and (min-width: 1200px) {
  .pf-v5-u-w-8-on-xl {
    width: 8.3333333333% !important;
  }

  .pf-v5-u-w-10-on-xl {
    width: 10% !important;
  }

  .pf-v5-u-w-16-on-xl {
    width: 16.6666666667% !important;
  }

  .pf-v5-u-w-42-on-xl {
    width: 41.6666666667% !important;
  }

  .pf-v5-u-w-58-on-xl {
    width: 58.3333333333% !important;
  }

  .pf-v5-u-w-83-on-xl {
    width: 83.3333333333% !important;
  }

  .pf-v5-u-w-92-on-xl {
    width: 91.6666666667% !important;
  }
}

@media screen and (min-width: 1450px) {
  .pf-v5-u-w-8-on-2xl {
    width: 8.3333333333% !important;
  }

  .pf-v5-u-w-10-on-2xl {
    width: 10% !important;
  }

  .pf-v5-u-w-16-on-2xl {
    width: 16.6666666667% !important;
  }

  .pf-v5-u-w-42-on-2xl {
    width: 41.6666666667% !important;
  }

  .pf-v5-u-w-58-on-2xl {
    width: 58.3333333333% !important;
  }

  .pf-v5-u-w-83-on-2xl {
    width: 83.3333333333% !important;
  }

  .pf-v5-u-w-92-on-2xl {
    width: 91.6666666667% !important;
  }
}


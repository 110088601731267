:root {
  --pf-v5-global--FontSize--md: 14px;
  --pf-v5-global--FontSize--sm: 13px;
}

.ocs-synced-editor-field__editor-toggle {
  border-bottom: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
  border-top: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
  margin-left: -1rem;
  margin-right: -1rem;
  padding: var(--pf-v5-global--spacer--sm) var(--pf-v5-global--spacer--lg);
}

@media (min-width: 1200px) {
  .ocs-synced-editor-field__editor-toggle {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
}

@media (min-width: 1200px) {
  .ocs-synced-editor-field__editor-toggle {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.ocs-synced-editor-field__editor-toggle.margin {
  margin: var(--pf-v5-global--spacer--md) 0;
}

.ocs-synced-editor-field__yaml-warning {
  margin: 1.5rem 1.5rem 0;
}


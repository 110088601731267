:root {
  --pf-v5-global--FontSize--md: 14px;
  --pf-v5-global--FontSize--sm: 13px;
}

:root {
  --popover-border-radius: 12px;
  --popover-button-size: var(--pf-v5-global--spacer--2xl);
  --popover-margin: var(--pf-v5-global--spacer--lg);
  --popover-bottom: calc( var(--popover-button-size) + var(--popover-margin) + var(--pf-v5-global--spacer--sm) );
}

.lightspeed__popover,
.lightspeed__popover-button {
  box-shadow: var(--pf-v5-global--BoxShadow--lg);
  position: absolute;
  right: var(--popover-margin);
  z-index: 199;
}

.lightspeed__popover {
  border-radius: 12px;
  border: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
  bottom: var(--popover-bottom);
  overflow: hidden;
}

.lightspeed__popover--collapsed {
  top: calc(var(--popover-margin)*5);
  width: 90%;
}

@media (min-width: 576px) {
  .lightspeed__popover--collapsed {
    height: 70vh;
    top: auto;
  }
}

@media (min-width: 768px) {
  .lightspeed__popover--collapsed {
    width: 50%;
  }
}

@media (min-width: 992px)and (min-height: 992px) {
  .lightspeed__popover--collapsed {
    height: 60vh;
  }
}

@media (min-width: 992px)and (min-height: 1200px) {
  .lightspeed__popover--collapsed {
    height: 55vh;
  }
}

@media (min-width: 1200px) {
  .lightspeed__popover--collapsed {
    width: 40%;
  }
}

@media (min-width: 1450px) {
  .lightspeed__popover--collapsed {
    width: 35%;
  }
}

.lightspeed__popover--expanded {
  height: calc(100% - var(--popover-bottom) - var(--popover-margin));
  width: calc(100% - 2*var(--popover-margin));
}

.lightspeed__popover-button {
  background-image: url("./logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: calc(var(--popover-button-size) + 3px);
  border-radius: var(--popover-border-radius) !important;
  bottom: var(--popover-margin);
  box-shadow: var(--pf-v5-global--BoxShadow--lg);
  height: var(--popover-button-size);
  padding: 0;
  position: absolute !important;
  right: var(--popover-margin);
  width: var(--popover-button-size);
}

.lightspeed__popover-button::after {
  border-width: 0 !important;
}

.pf-v5-theme-dark .lightspeed__popover-button {
  background-image: url("./logo-dark.svg");
  background-color: var(--pf-v5-global--BackgroundColor--100) !important;
}

.lightspeed__welcome-logo {
  --logo-size: var(--pf-v5-global--spacer--4xl);
  background-image: url("./logo.svg");
  height: var(--logo-size);
  margin: 0 0 var(--pf-v5-global--spacer--xl);
  width: var(--logo-size);
}

.pf-v5-theme-dark .lightspeed__welcome-logo {
  background-image: url("./logo-dark.svg");
}


:root {
  --pf-v5-global--FontSize--md: 14px;
  --pf-v5-global--FontSize--sm: 13px;
}

.co-synced-editor__editor-toggle {
  border-bottom: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
  border-top: var(--pf-v5-global--BorderWidth--sm) solid var(--pf-v5-global--BorderColor--100);
  padding: var(--pf-v5-global--spacer--sm) var(--pf-v5-global--spacer--lg);
}

@media (min-width: 1200px) {
  .co-synced-editor__editor-toggle {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.co-synced-editor__editor-toggle-label {
  font-size: 13px;
  margin-bottom: 0;
}

.co-synced-editor__yaml-warning {
  margin: 1.5rem 1.5rem 0;
}


:root {
  --pf-v5-global--FontSize--md: 14px;
  --pf-v5-global--FontSize--sm: 13px;
}

.ocs-form-footer {
  padding: var(--pf-v5-global--spacer--md) 1rem;
}

@media (min-width: 1200px) {
  .ocs-form-footer {
    padding: var(--pf-v5-global--spacer--md) 1.5rem;
  }
}

.ocs-form-footer__sticky {
  position: sticky;
  background-color: var(--pf-v5-global--BackgroundColor--100);
  bottom: 0;
}

.ocs-form-footer__shadow {
  box-shadow: var(--pf-v5-global--BoxShadow--sm-top);
}


@import '~@patternfly/patternfly/components/ClipboardCopy/clipboard-copy.css';
@import '~@patternfly/patternfly/components/CodeBlock/code-block.css';


.ocs-code-block__pre {
  /* override the styles applied by showdown while parsing <pre /> */
  display: flex;
  border: none;
  border-radius: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
}

.ocs-markdown-execute-snippet {

  &__button {
    & > .co-check-icon {
      display: none;
    }

    & > .co-play-icon {
      display: inline;
    }

    &[data-executed] {
      & > .co-check-icon {
        display: inline;
      }

      & > .co-play-icon {
        display: none;
      }
    }
  }
}
